import { ReactElement } from 'react';

import { NotesCarousel, renderNoteCardSkeletons } from '@halo-modules/app';

export interface DashboardWatchlistSkeletonProps {
  items?: number;
  ribbon?: boolean;
  width?: number;
}

export const DashboardWatchlistSkeleton = (props: DashboardWatchlistSkeletonProps): ReactElement => (
  <NotesCarousel>{renderNoteCardSkeletons(props)}</NotesCarousel>
);
