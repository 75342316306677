import { HaloAppState } from '@halo-stores/types';
import { createSelector } from '@reduxjs/toolkit';

// TODO: Update return value type to be an enum
export const selectStatus = createSelector(
  ({ Websocket }: HaloAppState) => Websocket.status,
  (status) => status,
);

export const selectError = createSelector(
  ({ Websocket }: HaloAppState) => Websocket.error,
  (error) => error,
);

export const selectDelay = createSelector(
  ({ Websocket }: HaloAppState) => Websocket.timeout,
  (timeout) => timeout.delay,
);
