import { getClientConfiguration, request } from '@halo-data-sources/clients';
import { ApiCreateIssuerQuoteResponseModel, ApiDeclineIssuerQuoteResponseModel } from '@halo-data-sources/models';

const ISSUER_PATH = '/issuer/v1/issuer';
const EXECUTION_AUCTION_ISSUER_PATH = '/execution/auction/issuer';

export const declineIssuerQuote = async (
  issuerId: number,
  auctionId: number,
  comment?: string,
): Promise<ApiDeclineIssuerQuoteResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ISSUER_PATH);

  const response = await request<ApiDeclineIssuerQuoteResponseModel>(
    `${clientConfiguration.basePath}/auction/decline/${auctionId}/issuer/${issuerId}`,
    {
      ...clientConfiguration.requestInit,
      method: 'PUT',
      body: JSON.stringify({
        comment,
        optOut: true,
        _csrf_token: clientConfiguration.csrfToken,
      }),
    },
  );

  return response;
};

export const createIssuerQuote = async (
  auctionId: number,
  issuerId: number,
  quote: number,
  comment?: string,
): Promise<ApiCreateIssuerQuoteResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_ISSUER_PATH);

  const response = await request<ApiCreateIssuerQuoteResponseModel>(
    `${clientConfiguration.basePath}/quote/${auctionId}/issuer/${issuerId}`,
    {
      ...clientConfiguration.requestInit,
      method: 'POST',
      body: JSON.stringify({
        comment,
        answer: quote,
        _csrf_token: clientConfiguration.csrfToken,
      }),
    },
  );

  return response;
};
