import { ReactElement } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import {
  useOrderTicketContentPicker,
  useOrderTicketSalesPrice,
  useWhiteLabelDateConfiguration,
} from '@halo-common/hooks';
import { useCalendarQuery } from '@halo-data-sources/queries';
import { Stack, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';

import { TicketDetail } from './TicketDetail';

export const TicketDetails = (): ReactElement => {
  const configureDateTime = useWhiteLabelDateConfiguration();

  const selectedCalendar = useAtomValue(calendarSelectedAtom);
  const { data: calendar } = useCalendarQuery(selectedCalendar?.id);

  const { isJanney, isPershingDev, isPershing, hardcodePar, isAdvisoryAccountSelected, isBrokerageAccountSelected } =
    useOrderTicketContentPicker();

  const calculatedSalesCredit = useOrderTicketSalesPrice(calendar);

  const [salesCredit, wholesalerOfferingPrice] = useCurrencyConverter({
    salesCredit: calculatedSalesCredit,
    wholesalerSalesPrice: calendar?.wholesaler?.salePrice,
  });

  const isAccountSelected = isAdvisoryAccountSelected || isBrokerageAccountSelected;

  const cusip = calendar?.cusip;
  const offeringPrice = isAccountSelected ? wholesalerOfferingPrice : '-';
  const parsedOfferingPrice = hardcodePar ? 'Par' : offeringPrice;
  const parsedSalesCredit = typeof calculatedSalesCredit === 'number' ? salesCredit : '-';
  const settlementDate = configureDateTime(calendar?.settleDate)?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);

  const cusipDetail = <TicketDetail key="cusipKey" label="CUSIP" value={cusip} />;
  const transactionDetail = <TicketDetail key="transactionKey" label="Transaction" value="Buy" />;
  const settlementDateDetail = <TicketDetail key="settleDateKey" label="Settlement Date" value={settlementDate} />;
  const salesCreditDetail = <TicketDetail key="scKey" label="Total Sales Credit" value={parsedSalesCredit} />;
  const offeringPriceDetail = <TicketDetail key="opKey" label="Offering Price" value={parsedOfferingPrice} />;

  // TODO: Add these below when ready
  // const firmAccountDetail = <TicketDetail label="Firm Account" />;
  // const ibdDetail = <TicketDetail label="IBD" />;

  let details: Array<ReactElement> = [];

  if (isJanney) {
    details = [cusipDetail, transactionDetail, settlementDateDetail, salesCreditDetail, offeringPriceDetail];
  } else if (isPershing || isPershingDev) {
    details = [cusipDetail, transactionDetail, offeringPriceDetail];
  }

  return (
    <Stack direction="row" spacing={2} xs={2} wrap="wrap">
      {details}
    </Stack>
  );
};
