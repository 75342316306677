import { anchorElAtom } from '@halo-atoms/common';
import { portfolioPositionPopoverAtom } from '@halo-atoms/portfolio';
import { DeletePositionPopoverAnchor } from '@halo-common/modals';
import { translations } from '@halo-common/translations';
import { deletePortfolioPosition } from '@halo-data-sources/clients';
import { PDMKeyFactory } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';

const deletePositionMutationFn = async (position_id: number) => {
  await deletePortfolioPosition(position_id);
};

export const useDeletePositionMutation = (): UseMutationResult<void, Error, number> => {
  const queryClient = useQueryClient();
  const [anchorElMap, setAnchorElMap] = useAtom(anchorElAtom);
  const { enqueueErrorEvent, enqueueSuccessEvent } = useSnackbar();
  const { termsheetId } = useAtomValue(portfolioPositionPopoverAtom);

  return useMutation<void, Error, number>({
    mutationFn: deletePositionMutationFn,
    onSuccess: () => {
      if (termsheetId) {
        const queryKey = PDMKeyFactory.allocations({ termsheetId });
        void queryClient.invalidateQueries({ queryKey });
      }

      setAnchorElMap({ ...anchorElMap, [DeletePositionPopoverAnchor]: null });
      enqueueSuccessEvent({ message: translations.pdm.postTrade.allocationDeleteSuccess });
    },
    onError: () => enqueueErrorEvent({ message: translations.messages.error }),
  });
};
