import { ReactElement } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { fixedIncomeSelectedAtom } from '@halo-atoms/fixedIncome';
import { ExecutionFixedIncomeDetailModal } from '@halo-common/modals';
import { useFixedIncomeCalendarQuery } from '@halo-data-sources/queries';
import { FIXED_INCOME_ALLOCATION_MODAL_NAME } from '@halo-modules/app';
import { useAtom } from 'jotai';

export const FIXED_INCOME_DETAIL_MODAL_NAME = 'fixedIncomePdm';

export const FixedIncomeDetailsModal = (): ReactElement => {
  const [modalMap, setModalMap] = useAtom(modalAtom);
  const [selectedFixedIncome, setSelectedFixedIncome] = useAtom(fixedIncomeSelectedAtom);

  const { data } = useFixedIncomeCalendarQuery(selectedFixedIncome);

  const handleClose = () => {
    setModalMap({ [FIXED_INCOME_DETAIL_MODAL_NAME]: false });
    setSelectedFixedIncome(null);
  };

  const handleSubmitIndication = () => {
    setModalMap({ [FIXED_INCOME_ALLOCATION_MODAL_NAME]: true });
  };

  return (
    <ExecutionFixedIncomeDetailModal
      open={modalMap[FIXED_INCOME_DETAIL_MODAL_NAME]}
      onClose={handleClose}
      product={data}
      onSubmitIndication={handleSubmitIndication}
    />
  );
};
