import { accountModalAtom, portfolioAccountManagerAtom } from '@halo-atoms/portfolio';
import { AccountModel, HouseholdModel } from '@halo-common/models';
import { createHousehold, updateAccount } from '@halo-data-sources/clients';
import { HttpError } from '@halo-data-sources/errors';
import { ApiAccountMapper } from '@halo-data-sources/mappers';
import { AccountQueryKeyFactory } from '@halo-data-sources/queries';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';

export type UpdateAccountToNewHouseholdPayload = {
  accountId: number;
  adviseeId: number | null;
  accountName: string;
  householdName?: string | undefined;
};

export type UseUpdateAccountToNewHouseholdResult = {
  account: AccountModel | null;
  household: HouseholdModel;
};

const updateAccountToNewHouseholdMutation = async ({ accountId, ...payload }: UpdateAccountToNewHouseholdPayload) => {
  const householdName = payload.householdName ?? '';
  const householdResult = await createHousehold(householdName);

  const newHousehold = ApiAccountMapper.toHouseholdModel(householdResult.advisee);

  const updatedPayload = { ...payload, adviseeId: newHousehold?.id ?? null };

  const result = await updateAccount(accountId, updatedPayload);

  return { account: ApiAccountMapper.toAccountModel(result.account), household: newHousehold };
};

export const useUpdateAccountToNewHouseholdMutation = (): UseMutationResult<
  UseUpdateAccountToNewHouseholdResult,
  HttpError,
  UpdateAccountToNewHouseholdPayload
> => {
  const queryClient = useQueryClient();

  const { query } = useAtomValue(portfolioAccountManagerAtom);

  const { modalStepMap } = useAtomValue(accountModalAtom);
  const { handleNextAtom } = modalStepMap;
  const handleNext = useSetAtom(handleNextAtom);

  return useMutation<UseUpdateAccountToNewHouseholdResult, HttpError, UpdateAccountToNewHouseholdPayload>({
    mutationFn: updateAccountToNewHouseholdMutation,
    onSuccess: async (data) => {
      if (!data.account) return undefined;

      const key = AccountQueryKeyFactory.paged(query);
      await queryClient.refetchQueries({ queryKey: key });

      const householdKey = AccountQueryKeyFactory.households();
      queryClient.setQueryData<Array<HouseholdModel>>(householdKey, (prev = []) => [...prev, data.household]);

      handleNext();
    },
    onError: (error, data) => {
      if (error.status === 500) return 'Failed to add account.';
      const accountName = data?.accountName;
      if (error.status === 409) return `An account with this number and custodian already exists: ${accountName}`;
    },
  });
};
