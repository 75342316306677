import { selectedWatchlistAtom } from '@halo-atoms/watchlists';
import { WatchlistModel } from '@halo-common/models';
import { useLegacyRouter } from '@halo-common/providers';
import { watchlistTypeaheadSelectedAtom } from '@halo-common/smartComponents';
import { createWatchlist } from '@halo-data-sources/clients';
import { HttpError } from '@halo-data-sources/errors';
import { ApiWatchlistMapper, WatchlistMapper } from '@halo-data-sources/mappers';
import { WatchlistQueryKeyFactory } from '@halo-data-sources/queries';
import { IconographyProps } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';

export type CreateWatchlistMutationOptions = {
  navigateToWatchlist?: boolean;
  addToTypeahead?: boolean;
};

export type CreateWatchlistMutationPayload = {
  name: string;
  icon: IconographyProps['iconName'];
  product?: {
    id: number;
    type: string;
  };
};

export type CreateWatchlistMutationResult = {
  watchlist: WatchlistModel;
  message: string;
};

const createWatchlistMutationFn = async (payload?: CreateWatchlistMutationPayload) => {
  if (!payload) return null;

  try {
    const icon = WatchlistMapper.toApiWatchlistIconName(payload.icon);
    const response = await createWatchlist(payload.name, icon);

    return {
      watchlist: ApiWatchlistMapper.toWatchlistModel(response),
      message: 'Successfully created watchlist',
    };
  } catch (e) {
    const parsedError = e as HttpError;
    // NOTE: BE sends the wrong code for duplicates.
    const isDuplicateError = parsedError.status === 400;
    const msg = isDuplicateError
      ? 'A Watchlist with this name already exists, please choose a different name.'
      : parsedError.message;

    throw new Error(msg);
  }
};

export const useCreateWatchlistMutation = (
  options?: CreateWatchlistMutationOptions,
): UseMutationResult<CreateWatchlistMutationResult | null, Error, CreateWatchlistMutationPayload | undefined> => {
  const navigate = options?.navigateToWatchlist;
  const add = options?.addToTypeahead;

  const legacyRouter = useLegacyRouter();
  const queryClient = useQueryClient();

  const setSelectedWatchlist = useSetAtom(selectedWatchlistAtom);
  const setSelectedWatchlists = useSetAtom(watchlistTypeaheadSelectedAtom);

  return useMutation({
    mutationFn: createWatchlistMutationFn,
    onSuccess: (data) => {
      if (!data) return undefined;

      if (add) setSelectedWatchlists((prev) => [...prev, data.watchlist]);

      if (navigate) {
        setSelectedWatchlist(data.watchlist);
        legacyRouter.addQueryParam('watchlist', data.watchlist.id.toString());
      }

      const watchlistsKey = WatchlistQueryKeyFactory.all();
      queryClient.setQueryData<Array<WatchlistModel>>(watchlistsKey, (prev) => {
        if (!prev) return undefined;
        return [...prev, data.watchlist];
      });
    },
  });
};
