import { ReactElement } from 'react';

import { orderBookAuctionCommentPopoverAtom, orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { ActionButton } from '@halo-common/components';
import { AuctionStatusEnum } from '@halo-common/enums';
import { useCancelAuctionMutation } from '@halo-data-sources/mutations';
import { useBuyerAuctionQuery } from '@halo-data-sources/queries';
import { Iconography } from '@halodomination/halo-fe-common';
import { IconButton, Popover, Stack, Typography } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';

const popoverPaperProps = {
  sx: {
    p: 2,
    width: 548,
  },
};

const iconButtonSx = {
  mt: -1,
  mr: -1,
};

export const AuctionDetailsCancelPopover = (): ReactElement => {
  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);
  const [orderBookAuctionCommentPopover, setOrderBookAuctionCommentPopover] = useAtom(
    orderBookAuctionCommentPopoverAtom,
  );

  const auctionId = selectedAuctionId ? String(selectedAuctionId) : undefined;

  const { data: auction } = useBuyerAuctionQuery(auctionId);
  const { mutate: cancelAuction, isPending: loadingCancel } = useCancelAuctionMutation();

  const anchorEl = orderBookAuctionCommentPopover.anchor;
  const open = Boolean(anchorEl && auction?.status !== AuctionStatusEnum.Canceled);

  const onClose = () => {
    setOrderBookAuctionCommentPopover({ anchor: null });
  };

  const onCancel = () => {
    if (selectedAuctionId) void cancelAuction(selectedAuctionId);
  };

  return (
    <Popover
      PaperProps={popoverPaperProps}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <Stack direction="column" spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Confirm Cancelation of Auction</Typography>
          <IconButton sx={iconButtonSx} onClick={onClose}>
            <Iconography iconName="xmark" color="text.secondary" />
          </IconButton>
        </Stack>
        <Typography variant="body2">
          Once canceled, the auction process will stop. If you change your mind, a new auction will need to be run from
          the beginning.
        </Typography>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <ActionButton variant="outlined" onClick={onClose}>
            Don&apos;t Cancel
          </ActionButton>
          <ActionButton variant="contained" color="error" onClick={onCancel} loading={loadingCancel}>
            <Iconography iconName="check" color="common.white" />
            Confirm Cancel Auction
          </ActionButton>
        </Stack>
      </Stack>
    </Popover>
  );
};
