import { ReactElement } from 'react';

import { AnnuityOrderModel } from '@halo-common/models';
import { Skeleton, Typography } from '@mui/material';

export type PostTradeAnnuityDetailModalHeaderIdProps = {
  product?: AnnuityOrderModel | null;
  loading: boolean;
};

export const PostTradeAnnuityDetailModalHeaderId = ({
  product,
  loading,
}: PostTradeAnnuityDetailModalHeaderIdProps): ReactElement | null => {
  if (loading) return <Skeleton variant="rounded" width={50} height={24} />;

  const contractNumber = product?.contractValues?.contractNumber;
  const idText = `Contract ID# ${contractNumber}`;

  return contractNumber ? (
    <Typography variant="overline" color="textSecondary">
      {idText}
    </Typography>
  ) : null;
};
