import { HistoricalPerformanceModel } from '@halo-common/models';
import { submitHistoricalPerformanceSearch } from '@halo-data-sources/clients';
import { apiHistoricalPerformanceMapper } from '@halo-data-sources/mappers';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import { HistoricalPerformanceQueryKeyFactory } from '../keys';

export type HistoricalPerformanceQueryPayload = {
  underlyings: Array<string>;
  date: DateRange<DateTime> | null;
  protectionLevel: number | null;
};

export type HistoricalPerformanceQueryOptions = UseQueryOptions<HistoricalPerformanceModel | null, Error>;
export type HistoricalPerformanceQueryResult = UseQueryResult<HistoricalPerformanceModel | null, Error>;

const historicalPerformanceQueryFn = async (params: HistoricalPerformanceQueryPayload | null) => {
  if (!params) return null;

  const { underlyings, date, protectionLevel } = params;

  const searchParams = new URLSearchParams();

  const underlyingsString = underlyings.toString();
  const startDate = date?.[0];
  const endDate = date?.[1];
  const startDateString = startDate ? startDate.toLocaleString(DateTime.DATE_SHORT).split(',')[0] : '';
  const endDateString = endDate ? endDate.toLocaleString(DateTime.DATE_SHORT).split(',')[0] : '';
  const protectionLevelString = protectionLevel ? protectionLevel.toString() : '0';

  searchParams.append('tradables', underlyingsString);
  searchParams.append('start_date', startDateString);
  searchParams.append('end_date', endDateString);
  searchParams.append('protection', protectionLevelString);

  const dataString = searchParams.toString();
  const response = await submitHistoricalPerformanceSearch(dataString);

  return apiHistoricalPerformanceMapper.toHistoricalPerformanceResponseModel(response);
};

export const useHistoricalPerformanceQuery = (
  searchParams: HistoricalPerformanceQueryPayload | null,
  options?: HistoricalPerformanceQueryOptions,
): HistoricalPerformanceQueryResult =>
  useQuery({
    queryKey: HistoricalPerformanceQueryKeyFactory.query(searchParams),
    queryFn: () => historicalPerformanceQueryFn(searchParams),
    ...options,
  });
