import {
  DynamicFiltersHeaderFiltersModel,
  DynamicFiltersMinMaxModel,
  DynamicFiltersSideBarFiltersModel,
  DynamicFiltersToggleModel,
  dynamicFiltersClearAtom,
  dynamicFiltersHeaderManagerAtom,
  dynamicFiltersInitialLoadAtom,
  dynamicSideBarManagerAtom,
  initialHeaderFilters,
} from '@halo-atoms/common';
import { CALENDAR_PREFERENCE_TAG } from '@halo-common/constants';
import { SortModelDirectionEnum } from '@halo-common/enums';
import { CalendarModel, SortModel } from '@halo-common/models';
import { GetCalendarTermsheetDownloadPayloadModel } from '@halo-data-sources/queries';
import { atom } from 'jotai';

export type CalendarsCheckboxFilterModel = {
  starred: boolean;
  submittedIndication: boolean;
  advisory: boolean;
  highlighted: boolean;
  brokerage: boolean;
  softProtection: boolean;
  hardProtection: boolean;
  dailyProtection: boolean;
  continuousProtection: boolean;
  autocallable: boolean;
  issuerCallable: boolean;
  notCallable: boolean;
  fixed: boolean;
  contingent: boolean;
  memory: boolean;
  capped: boolean;
  uncapped: boolean;
};

export type CalendarQueryModel = Omit<DynamicFiltersHeaderFiltersModel, 'sortBy'> &
  DynamicFiltersSideBarFiltersModel & {
    sort: Array<SortModel>;
    calendarNoteId: number | null;
    approved?: boolean | null;
    isVisible?: boolean;
    showAsNME?: boolean;
  };

export type CalendarManagerModel = {
  query: CalendarQueryModel;
  isFiltered: boolean;
  isInitialLoad: boolean;
  sideBarFilters: DynamicFiltersSideBarFiltersModel;
};

export type CalendarQueryPayload = {
  sortBy?: string;
  filters?: Partial<DynamicFiltersHeaderFiltersModel>;
  toggles?: Partial<DynamicFiltersToggleModel>;
  checkboxes?: Partial<CalendarsCheckboxFilterModel>;
  checkbox?: string;
  toggle?: string;
  protectionAmount?: DynamicFiltersMinMaxModel | null;
  term?: DynamicFiltersMinMaxModel | null;
  currencies?: Array<string>;
  issuers?: Array<string>;
  productTypes?: Array<string>;
  initialPageLoad?: boolean;
};

export type CalendarPdfViewerDispositionTypeState = GetCalendarTermsheetDownloadPayloadModel['contentDisposition'];

export type CalendarPDFViewerPayload = {
  calendar: CalendarModel | null;
  disposition: CalendarPdfViewerDispositionTypeState;
};

export const calendarSelectedAtom = atom<CalendarModel | null>(null);
export const calendarPDFSelectedAtom = atom<CalendarModel | null>(null);

const _calendarDispositionTypeAtom = atom<CalendarPdfViewerDispositionTypeState>('');
export const calendarPDFViewerAtom = atom(
  (get): CalendarPDFViewerPayload => ({
    calendar: get(calendarPDFSelectedAtom),
    disposition: get(_calendarDispositionTypeAtom),
  }),
  (_, set, { calendar, disposition }: Partial<CalendarPDFViewerPayload>) => {
    if (typeof disposition === 'string') set(_calendarDispositionTypeAtom, disposition);
    if (calendar || calendar === null) set(calendarPDFSelectedAtom, calendar);
  },
);

export const calendarHeaderFiltersAtom = atom(
  (get) => get(dynamicFiltersHeaderManagerAtom),
  (_, set, payload: CalendarQueryPayload['filters']) => {
    if (payload) set(dynamicFiltersHeaderManagerAtom, { tag: CALENDAR_PREFERENCE_TAG, ...initialHeaderFilters });
  },
);

const DEFAULT_CALENDAR_SORT: Array<SortModel> = [
  { field: 'is_active', direction: SortModelDirectionEnum.desc },
  { field: 'expiration_date', direction: SortModelDirectionEnum.asc },
  { field: 'term', direction: SortModelDirectionEnum.asc },
];

export const calendarManagerAtom = atom((get): CalendarManagerModel => {
  const getCalendarSideBarFilters = get(dynamicSideBarManagerAtom);
  const getCalendarHeaderFilters = get(calendarHeaderFiltersAtom);

  const calendarSideBarFilters = getCalendarSideBarFilters(CALENDAR_PREFERENCE_TAG);
  const calendarHeaderFilters = getCalendarHeaderFilters(CALENDAR_PREFERENCE_TAG);
  const calendarInitialPageLoad = get(dynamicFiltersInitialLoadAtom);

  const sortByParameters = calendarHeaderFilters?.sortByParameters;
  const calendarSortBy = sortByParameters?.length ? sortByParameters : DEFAULT_CALENDAR_SORT;

  const query = {
    ...calendarHeaderFilters,
    ...calendarSideBarFilters,
    sort: calendarSortBy,
  };

  const isFiltered = calendarSideBarFilters.isFiltered || calendarHeaderFilters.isFiltered;

  return { query, isFiltered, isInitialLoad: calendarInitialPageLoad, sideBarFilters: calendarSideBarFilters };
});

export const clearCalendarFiltersAtom = atom(null, (_, set) => {
  set(dynamicFiltersClearAtom, { tag: CALENDAR_PREFERENCE_TAG, action: 'all' });
});
