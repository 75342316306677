import type { TermsheetRepoDocModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { updateTermsheetRepoDoc } from '@halo-data-sources/clients';
import { ApiTermsheetRepoMapper } from '@halo-data-sources/mappers';
import type { ApiUpdateTermsheetRepoDocPayloadModel } from '@halo-data-sources/models';
import { TermsheetRepoQueryKeyFactory } from '@halo-data-sources/queries';
import { TermsheetFormSchema } from '@halo-modules/app/documents/repository';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationOptions, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

export type useUpdateTermsheetRepoDocMutationVariables = {
  id: string | number;
} & TermsheetFormSchema;

export type useUpdateTermsheetRepoDocMutationOptions = UseMutationOptions<
  TermsheetRepoDocModel,
  Error,
  useUpdateTermsheetRepoDocMutationVariables
>;

export type useUpdateTermsheetRepoDocMutationResult = UseMutationResult<
  TermsheetRepoDocModel,
  Error,
  useUpdateTermsheetRepoDocMutationVariables
>;

const errorMsg = translations.messages.error;
const successMsg = translations.documents.termsheets.updateTermsheetSuccessMessage;

const updateTermsheetRepoDocMutationFn = async ({ id, ...fields }: useUpdateTermsheetRepoDocMutationVariables) => {
  const payload = ApiTermsheetRepoMapper.toTermsheetPayload(fields) as ApiUpdateTermsheetRepoDocPayloadModel;
  const isFile = fields.document instanceof File;
  if (!isFile) delete payload.document;
  const data = await updateTermsheetRepoDoc(id, payload);
  return ApiTermsheetRepoMapper.toTermsheetRepoDocModel(data);
};

export const useUpdateTermsheetRepoDocMutation = (
  options?: useUpdateTermsheetRepoDocMutationOptions,
): useUpdateTermsheetRepoDocMutationResult => {
  const queryClient = useQueryClient();
  const { enqueueSuccessEvent, enqueueErrorEvent, closeSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: updateTermsheetRepoDocMutationFn,
    ...options,
    onError: (...props) => {
      closeSnackbar();
      enqueueErrorEvent({ message: errorMsg });
      options?.onError?.(...props);
    },
    onSuccess: (...props) => {
      closeSnackbar();
      enqueueSuccessEvent({ message: successMsg });
      const key = TermsheetRepoQueryKeyFactory.all();
      void queryClient.invalidateQueries({ queryKey: key });
      options?.onSuccess?.(...props);
    },
  });
};
