import { ReactElement, useState } from 'react';

import { accountModalAtom } from '@halo-atoms/portfolio';
import { SuccessAccountModalLayout } from '@halo-common/layouts';
import { AddAccountModalForm, AddAccountModalFormFields, AddAccountWizardFooter } from '@halo-common/modals';
import { AccountModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import {
  useAddAccountToExistingHouseholdMutation,
  useAddAccountToNewHouseholdMutation,
  useUpdateAccountToExistingHouseholdMutation,
  useUpdateAccountToNewHouseholdMutation,
} from '@halo-data-sources/mutations';
import { useHouseholdQuery } from '@halo-data-sources/queries';
import { ElementSelector, Modal, ModalBaseProps } from '@halodomination/halo-fe-common';
import { useAtomValue, useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';

export type AddAccountWizardVariantProps = {
  onBack?: () => void;
  onClose: () => void;
  onReset?: () => void;
  onSuccess?: () => void;
  open?: boolean;
  selected?: AccountModel | null;
  size?: ModalBaseProps['size'];
  step?: number;
};

export const AddAccountWizardVariant = ({
  onBack,
  onClose,
  onReset,
  onSuccess,
  open = false,
  selected,
  size = 'large',
  step = 0,
}: AddAccountWizardVariantProps): ReactElement => {
  const [showNewHousehold, setShowNewHousehold] = useState(false);

  const { handleSubmit, reset } = useFormContext<AddAccountModalFormFields>();

  const { data: households = [] } = useHouseholdQuery();

  const { modalStepMap } = useAtomValue(accountModalAtom);
  const { handleNextAtom } = modalStepMap;

  const handleNext = useSetAtom(handleNextAtom);

  const {
    mutate: addAccountToExistingHousehold,
    isPending: isAddAccountToExistingHouseholdFetching,
    error: addAccountToExistingHouseholdError,
    reset: resetAddAccountToExistingHousehold,
  } = useAddAccountToExistingHouseholdMutation(handleNext);

  const {
    mutate: addAccountToNewHousehold,
    isPending: isAddAccountToNewHouseholdFetching,
    error: addAccountToNewHouseholdError,
    reset: resetAddAccountToNewHousehold,
  } = useAddAccountToNewHouseholdMutation(handleNext);

  const {
    mutate: updateAccountToExistingHousehold,
    isPending: isUseUpdateAccountToExistingHouseholdFetching,
    error: updateAccountToExistingHouseholdError,
    reset: resetUpdateAccountToExistingHousehold,
  } = useUpdateAccountToExistingHouseholdMutation();

  const {
    mutate: updateAccountToNewHouseholdMutation,
    isPending: isUpdateAccountToNewHouseholdFetching,
    error: updateAccountToNewHouseholdError,
    reset: resetUpdateAccountToNewHousehold,
  } = useUpdateAccountToNewHouseholdMutation();

  const isAccountCreateOrUpdateRequestingStatus =
    isAddAccountToExistingHouseholdFetching ||
    isAddAccountToNewHouseholdFetching ||
    isUseUpdateAccountToExistingHouseholdFetching ||
    isUpdateAccountToNewHouseholdFetching;

  const errorList = [
    addAccountToExistingHouseholdError,
    addAccountToNewHouseholdError,
    updateAccountToExistingHouseholdError,
    updateAccountToNewHouseholdError,
  ];

  const filterErrorList = errorList.filter((error) => (error !== null ? error : false));

  const stepOneTitle = selected
    ? translations.portfolio.addAccountModal.wizardFormUpdatedAccountTitle
    : translations.portfolio.addAccountModal.wizardFormCreateAccountTitle;

  const stepTwoTitle = selected
    ? translations.portfolio.addAccountModal.wizardFormUpdateSuccessTitle
    : translations.portfolio.addAccountModal.wizardFormCreateSuccessTitle;

  const modalPropList = [
    {
      icon: selected ? 'pencil' : 'user-plus',
      overline: translations.portfolio.common.yourPortfolioTitle,
      title: stepOneTitle,
    },
    {
      icon: 'user-check',
      overline: translations.portfolio.common.yourPortfolioTitle,
      title: stepTwoTitle,
    },
  ];

  const handleResetError = () => {
    if (addAccountToExistingHouseholdError) resetAddAccountToExistingHousehold();
    if (addAccountToNewHouseholdError) resetAddAccountToNewHousehold();
    if (updateAccountToExistingHouseholdError) resetUpdateAccountToExistingHousehold();
    if (updateAccountToNewHouseholdError) resetUpdateAccountToNewHousehold();
  };

  const handleBack = () => {
    reset();
    onBack?.();
    handleResetError();
  };

  const handleReset = () => {
    reset();
    onReset?.();
    handleResetError();
  };

  const handleClose = () => {
    handleResetError();
    reset();
    onClose();
  };

  const handleUpdate = handleSubmit((data: AddAccountModalFormFields) => {
    const { accountHousehold, accountName } = data;
    const { id: accountId } = selected as AccountModel;

    const isExistingHousehold = !accountHousehold || households.some(({ name }) => accountHousehold === name);
    setShowNewHousehold(!isExistingHousehold);

    if (isExistingHousehold) {
      const household = households.find((household) => data.accountHousehold === household.name);
      const adviseeId = data.accountHousehold && household ? household.id : null;
      const action = { accountId, adviseeId, accountName };
      updateAccountToExistingHousehold({ ...action });
    } else {
      const action = { accountId, accountName, adviseeId: null, householdName: accountHousehold };
      updateAccountToNewHouseholdMutation({ ...action });
    }
  });

  const handleCreate = handleSubmit((data: AddAccountModalFormFields) => {
    const { accountHousehold, accountNumber, accountName, custodian } = data;
    const { id: custodianId } = custodian;

    const isExistingHousehold = !accountHousehold || households.some(({ name }) => accountHousehold === name);
    setShowNewHousehold(!isExistingHousehold);

    if (isExistingHousehold) {
      const household = households.find((household) => data.accountHousehold === household.name);
      const adviseeId = data.accountHousehold && household ? household.id : undefined;
      const action = { ownerId: adviseeId, accountName, accountNumber, custodianId };
      addAccountToExistingHousehold(action);
    } else {
      const action = { accountName, accountNumber, custodianId, householdName: accountHousehold };
      addAccountToNewHousehold(action);
    }
  });

  return (
    <Modal
      {...modalPropList[step]}
      footer={
        <AddAccountWizardFooter
          selected={selected}
          step={step}
          onBack={handleBack}
          onClose={handleClose}
          onCreate={handleCreate}
          onReset={handleReset}
          onUpdate={handleUpdate}
          onSuccess={onSuccess}
          isLoading={isAccountCreateOrUpdateRequestingStatus}
          error={filterErrorList}
        />
      }
      open={open}
      keepMounted
      onClose={handleClose}
      size={size}
    >
      <ElementSelector selected={step}>
        <AddAccountModalForm selected={selected} error={filterErrorList} />
        <SuccessAccountModalLayout showNewHouseholdMessage={showNewHousehold} />
      </ElementSelector>
    </Modal>
  );
};
