import { ReactElement } from 'react';

import { LexicalTypography } from '@halo-common/components';
import { Chip, ChipProps } from '@mui/material';

const chipStyling = {
  borderRadius: 1,
  backgroundColor: 'common.charts.accent11.main',
  color: 'primary.contrastText',
};

const nameSx = {
  fontWeight: 600,
  fontSize: 12,
};

export type ProductAnnuityTypeChipProps = {
  variant: ChipProps['variant'];
};

export const ProductAnnuityTypeChip = ({ variant }: ProductAnnuityTypeChipProps): ReactElement => {
  const productTypeText = 'Annuity';

  const labelComponent = (
    <LexicalTypography sx={nameSx} variant="body2" translationKey={productTypeText}>
      {productTypeText}
    </LexicalTypography>
  );

  return <Chip size="small" sx={chipStyling} label={labelComponent} variant={variant} />;
};
