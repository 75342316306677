import { ReactElement } from 'react';

import { calendarPDFViewerAtom } from '@halo-atoms/calendars';
import { modalAtom } from '@halo-atoms/common';
import { usePrimaryAssetIdentifier } from '@halo-common/hooks';
import { PdfViewerModal } from '@halo-common/modals';
import { translations } from '@halo-common/translations';
import { useCalendarTermsheetDownloadQuery } from '@halo-data-sources/queries';
import { useAtom } from 'jotai';

export const CALENDAR_PDF_VIEWER_MODAL_NAME = 'pdf';

export const CalendarPdfViewerModal = (): ReactElement => {
  const [modalMap, setModelMap] = useAtom(modalAtom);
  const [{ calendar, disposition }, setPdfViewerPayload] = useAtom(calendarPDFViewerAtom);

  const { identifierText } = usePrimaryAssetIdentifier(calendar);

  const { isLoading, data } = useCalendarTermsheetDownloadQuery({
    calendarId: calendar?.id,
    contentDisposition: disposition,
    fileName: calendar?.preliminaryTerms?.filename ?? 'termsheet',
  });

  const path = data ?? '';

  const handleDownloadClick = () => {
    setPdfViewerPayload({ disposition: 'attachment' });
    setModelMap({ [CALENDAR_PDF_VIEWER_MODAL_NAME]: false });
  };

  const handlePdfViewerClose = () => {
    setModelMap({ [CALENDAR_PDF_VIEWER_MODAL_NAME]: false });
  };

  return (
    <PdfViewerModal
      isLoading={isLoading}
      path={path}
      open={modalMap[CALENDAR_PDF_VIEWER_MODAL_NAME]}
      onClose={handlePdfViewerClose}
      onDownload={handleDownloadClick}
      overline={identifierText}
      title={translations.calendars.common.pdfViewerModalTitle}
      enableDownload={false}
    />
  );
};
