import { ReactElement } from 'react';

import { ProductDetailModalLayoutHeader } from '@halo-common/layouts';
import {
  PostTradeAnnuityDetailModalHeaderFeatures,
  PostTradeAnnuityDetailModalHeaderId,
  PostTradeAnnuityDetailModalHeaderName,
} from '@halo-common/modals';
import { AnnuityOrderModel } from '@halo-common/models';

export type PostTradeAnnuityDetailModalHeaderProps = {
  product?: AnnuityOrderModel | null;
  loading?: boolean;
  onClose?: () => void;
};

export const PostTradeAnnuityDetailModalHeader = ({
  product,
  loading = true,
  onClose,
}: PostTradeAnnuityDetailModalHeaderProps): ReactElement => {
  const slots = {
    features: <PostTradeAnnuityDetailModalHeaderFeatures loading={loading} product={product} />,
    productId: <PostTradeAnnuityDetailModalHeaderId loading={loading} product={product} />,
    parameter: <PostTradeAnnuityDetailModalHeaderName loading={loading} product={product} />,
  };

  return <ProductDetailModalLayoutHeader onClose={onClose} slots={slots} />;
};
