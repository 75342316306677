import { ReactElement } from 'react';

import { dynamicFiltersHeaderManagerAtom, dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { CollapsibleFilters, GroupedCheckbox } from '@halo-common/components';
import { DynamicTypeFiltersSideBarTitlesDict } from '@halo-common/layouts';
import { useCalendarProductQuery } from '@halo-data-sources/queries';
import { useAtom, useAtomValue } from 'jotai';

export type CalendarProductTypeFiltersProps = {
  approved?: boolean | null;
  tag: string;
};

export const CalendarProductTypeFilters = ({
  approved = true,
  tag,
}: CalendarProductTypeFiltersProps): ReactElement | null => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);
  const getHeaderFilters = useAtomValue(dynamicFiltersHeaderManagerAtom);

  const headerFilters = getHeaderFilters(tag);
  const { endDate, startDate } = headerFilters;

  const sideBarFilters = getSideBarFilters(tag);
  const selectedProductTypes = sideBarFilters.productTypes;

  const { data: productTypes } = useCalendarProductQuery({ approved, endDate, startDate, showAsNME: false });

  const handleCheckboxChange = (value: string) => {
    const isInFilter = selectedProductTypes.includes(value);

    const updatedProductTypes = isInFilter
      ? selectedProductTypes.filter((productType) => productType !== value)
      : [...selectedProductTypes, value];

    setSideBarFilters({ tag, productTypes: updatedProductTypes });
  };

  const productTypeCheckboxes = productTypes?.map((productType) => ({
    isChecked: selectedProductTypes.includes(productType.productType),
    name: productType.noteType,
    value: productType.productType,
  }));

  return productTypeCheckboxes?.length ? (
    <CollapsibleFilters title={DynamicTypeFiltersSideBarTitlesDict.calendarProductType}>
      <GroupedCheckbox onChange={handleCheckboxChange} values={productTypeCheckboxes} groupedCheckboxType="product" />
    </CollapsibleFilters>
  ) : null;
};
