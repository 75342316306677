import { ReactElement, SyntheticEvent } from 'react';

import { UnderlyingModel } from '@halo-common/models';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Stack } from '@halodomination/halo-fe-common';
import { Box, Typography } from '@mui/material';

const imageSx = {
  backgroundColor: 'transparent',
  width: 32,
  height: 32,
  borderRadius: 0.5,
};

export type UnderlyingAutocompleteOptionProps = {
  underlying: UnderlyingModel;
};

export const UnderlyingAutocompleteOption = ({ underlying }: UnderlyingAutocompleteOptionProps): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();

  const staticAssetUrl = userInfo?.configuration.staticAssetUrl;
  const src = `${staticAssetUrl}${underlying.svg}`;

  const handleError = (event: SyntheticEvent<HTMLImageElement>) => {
    (event.target as HTMLImageElement).style.opacity = '0';
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box component="img" alt="-" sx={imageSx} src={src} onError={handleError} />
      <Stack direction="column">
        <Typography variant="overline" color="text.secondary">
          {underlying.name}
        </Typography>
        <Typography variant="subtitle2" color="text.primary">
          {underlying.description}
        </Typography>
      </Stack>
    </Stack>
  );
};
