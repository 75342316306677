import { EducationQuizAttemptMap } from '@halo-common/models';
import { getEducationQuizAttempts } from '@halo-data-sources/clients';
import { ApiEducationMapper } from '@halo-data-sources/mappers';
import { EducationQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getEducationQuizAttemptsFn = async (id?: number) => {
  if (!id) return null;
  const quizAttemptsResponse = await getEducationQuizAttempts(id);
  return ApiEducationMapper.toQuizAttemptMap(quizAttemptsResponse);
};

export const useEducationQuizAttemptsQuery = (id?: number): UseQueryResult<EducationQuizAttemptMap | null, Error> =>
  useQuery<EducationQuizAttemptMap | null, Error>({
    queryKey: EducationQueryKeyFactory.quizAttempts(id),
    queryFn: () => getEducationQuizAttemptsFn(id),
  });
