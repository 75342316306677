import { translations } from '@halo-common/translations';
import { DisclaimerText, DocumentLinks, LinkExpiredForm, OnboardingLayout } from '@halo-modules/app/v2/onboarding';
import { LocalizedTypography, PageComponent, PageMeta } from '@halodomination/halo-fe-common';

const LinkExpiredPage: PageComponent = () => (
  <OnboardingLayout variant="small">
    <LocalizedTypography textAlign="center" mb={1} variant="h4">
      {translations.onboarding.linkExpiredForm.linkExpiredTitle}
    </LocalizedTypography>
    <LocalizedTypography textAlign="center" mb={6} variant="subtitle2" color="text.secondary">
      {translations.onboarding.linkExpiredForm.secure}
    </LocalizedTypography>
    <LinkExpiredForm />
    <DisclaimerText />
    <DocumentLinks />
  </OnboardingLayout>
);

export const LinkExpiredPageMeta: PageMeta = {
  pageName: 'LinkExpired',
  route: '/app/v2/onboarding/link-expired',
  iconName: 'question-circle',
};

LinkExpiredPage.pageName = LinkExpiredPageMeta.pageName;
LinkExpiredPage.route = LinkExpiredPageMeta.route;
LinkExpiredPage.iconName = LinkExpiredPageMeta.iconName;

export default LinkExpiredPage;
