import { SubNavLayout } from '@halo-common/layouts';
import {
  ComplianceApprovalCalendarList,
  ComplianceApprovalModal,
  ComplianceApprovalPageHeader,
} from '@halo-modules/admin';
import { PageComponent, PageMeta, Stack } from '@halodomination/halo-fe-common';
import { Container } from '@mui/material';

const containerSx = { padding: 3 };

const ComplianceApprovalPage: PageComponent = () => (
  <>
    <SubNavLayout title="Compliance Approval" hideTabs>
      <Container sx={containerSx} maxWidth="xl">
        <Stack direction="column" spacing={2}>
          <ComplianceApprovalPageHeader />
          <ComplianceApprovalCalendarList />
        </Stack>
      </Container>
    </SubNavLayout>
    <ComplianceApprovalModal />
  </>
);

export const ComplianceApprovalPageMeta: PageMeta = {
  pageName: 'CalendarOrderReview',
  route: '/admin/calendar/orders/review',
  routeExpression: '/admin/calendar/orders/review',
};

ComplianceApprovalPage.pageName = ComplianceApprovalPageMeta.pageName;
ComplianceApprovalPage.route = ComplianceApprovalPageMeta.route;

export default ComplianceApprovalPage;
