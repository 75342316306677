import { ReactElement, useEffect, useState } from 'react';

import { HOUR_MINUTE_SECONDS_TIME_FORMAT, YEAR_MONTH_DAY_DATE_FORMAT } from '@halo-common/constants';
import { TackOnFormSchema, tackOnFormSchema } from '@halo-common/schemas';
import { ConfirmCreateTackOn, CreateTackOn, TackOnContext } from '@halo-modules/admin';
import { CalendarActions } from '@halo-stores/Calendar';
import { OrdersActions, OrdersSelectors } from '@halo-stores/Orders';
import { useSteps } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

export type CreateTackOnModalProps = {
  open: boolean;
  onClose: () => void;
};

export const CreateTackOnModal = ({ open, onClose }: CreateTackOnModalProps): ReactElement => {
  const dispatch = useDispatch();
  const { step, handleNext, handleBack, handleReset } = useSteps({ max: 1 });

  const auction = useSelector(OrdersSelectors.selectSelectedAuction);

  const formMethods = useForm<TackOnFormSchema>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    resolver: yupResolver<TackOnFormSchema>(tackOnFormSchema),
    defaultValues: {
      category: '',
      issuer: undefined,
    },
  });

  const { handleSubmit, reset } = formMethods;

  const now = DateTime.now();
  const later = DateTime.now().plus({ days: 1 });
  const [startShowingDate, setStartShowingDate] = useState<DateTime>(now);
  const [startShowingTime, setStartShowingTime] = useState<DateTime>(now);
  const [expirationDate, setExpirationDate] = useState<DateTime>(later);
  const [expirationTime, setExpirationTime] = useState<DateTime>(later);

  const submitForm = handleSubmit((data: TackOnFormSchema) => {
    if (auction) {
      const parsedStartShowingDate = startShowingDate ?? DateTime.now();
      const parsedStartShowingTime = startShowingTime ?? DateTime.now();
      const parsedExpirationDate = expirationDate ?? DateTime.now().plus({ days: 1 });
      const parsedExpirationTime = expirationTime ?? DateTime.now().plus({ days: 1 });

      const formatStartShowingDate = parsedStartShowingDate.toFormat(YEAR_MONTH_DAY_DATE_FORMAT);
      const formatStartShowingTime = parsedStartShowingTime.toFormat(HOUR_MINUTE_SECONDS_TIME_FORMAT);
      const formatExpirationDate = parsedExpirationDate.toFormat(YEAR_MONTH_DAY_DATE_FORMAT);
      const formatExpirationTime = parsedExpirationTime.toFormat(HOUR_MINUTE_SECONDS_TIME_FORMAT);

      const submitData = {
        id: auction.id,
        internalName: data.internalName,
        externalName: data.externalName,
        reoffer: data.reoffer,
        pages: data.calendarPages as Array<number>,
        startShowing: `${formatStartShowingDate}T${formatStartShowingTime}.00Z`,
        expiration: `${formatExpirationDate}T${formatExpirationTime}.00Z`,
        issuer: data.issuer ?? null,
        category: data.category,
      };

      dispatch(OrdersActions.createAuctionTackOn(submitData));
    }

    handleClose();
  });

  const handleClose = () => {
    reset();
    handleReset();
    onClose();
  };

  useEffect(() => {
    dispatch(CalendarActions.fetchAdminCalendarPages());
  }, []);

  return (
    <TackOnContext.Provider
      value={{
        startShowingDate,
        setStartShowingDate,
        startShowingTime,
        setStartShowingTime,
        expirationDate,
        setExpirationDate,
        expirationTime,
        setExpirationTime,
      }}
    >
      <FormProvider {...formMethods}>
        {
          [
            <CreateTackOn
              open={open}
              key="create-tack-on-step"
              auction={auction}
              onClose={handleClose}
              onNext={handleNext}
            />,
            <ConfirmCreateTackOn
              open={open}
              key="confirm-tack-on-step"
              onClose={handleClose}
              onBack={handleBack}
              onSubmit={submitForm}
            />,
          ][step]
        }
      </FormProvider>
    </TackOnContext.Provider>
  );
};
