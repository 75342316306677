import { forwardRef, ReactElement, Ref, SyntheticEvent } from 'react';

import { selectedNotificationTypesAtom } from '@halo-atoms/profile';
import { translations } from '@halo-common/translations';
import { NotificationTypeModel } from '@halo-data-sources/models';
import { useNotificationTypesQuery } from '@halo-data-sources/queries/profile';
import { Iconography, LocalizedTextField, useCombinedStyling } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import {
  Autocomplete,
  AutocompleteChangeReason,
  autocompleteClasses,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  Box,
  Chip,
  CircularProgress,
  createFilterOptions,
  SxProps,
  TextFieldProps,
} from '@mui/material';
import { useT } from '@transifex/react';
import { useAtom } from 'jotai';

import { NotificationTypeAutocompleteOption } from './NotificationTypeAutocompleteOption';

const autoCompleteSx = {
  [`.${autocompleteClasses.input}`]: {
    padding: `0 0 0 4px !important`,
  },
  [`.${autocompleteClasses.inputRoot}`]: {
    gap: 0.5,
    display: 'flex',
    flexWrap: 'wrap',
  },
  [`.${autocompleteClasses.tag}`]: {
    m: 0,
  },
};

const optionSx = {
  justifyContent: 'space-between !important',
  '&:hover': {
    backgroundColor: 'grey.300',
  },
  '&.MuiAutocomplete-option[aria-selected="true"].Mui-focused:hover': {
    backgroundColor: 'grey.200',
  },
  '&.MuiAutocomplete-option[aria-selected="true"]': { backgroundColor: 'transparent' },
  '&.MuiAutocomplete-option[aria-selected="true"].Mui-focused': { backgroundColor: 'transparent' },
  '&.MuiAutocomplete-option[aria-selected="true"].Mui-selected': { backgroundColor: 'transparent' },
};

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: NotificationTypeModel) => option.name,
});

export type NotificationTypeAutocompleteProps<T> = {
  id?: string;
  label: string;
  name?: string;
  value?: Array<NotificationTypeModel>;
  onChange: (selection: Array<T>, reason?: AutocompleteChangeReason) => void;
  error?: TextFieldProps['error'];
  helperText?: TextFieldProps['helperText'];
  variant?: TextFieldProps['variant'];
  tag?: string;
  sx?: SxProps<HaloTheme>;
  size?: 'small' | 'medium' | 'large';
  ChipProps?: {
    size?: 'small' | 'medium';
    color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
    sx?: SxProps<HaloTheme>;
  };
};

const WrappedNotificationTypeAutocomplete = (
  props: NotificationTypeAutocompleteProps<NotificationTypeModel>,
  ref: Ref<unknown>,
): ReactElement => {
  const translator = useT();

  const { value, label, onChange, size = 'large', ChipProps, sx, ...inputProps } = props;

  const chipColor = ChipProps?.color ?? 'secondary';
  const chipSize = ChipProps?.size ?? 'medium';
  const chipSx = ChipProps?.sx;

  const combinedStyling = useCombinedStyling(autoCompleteSx, sx);
  const { data, isLoading } = useNotificationTypesQuery();

  const [selectedTypes, setNotificationTypes] = useAtom(selectedNotificationTypesAtom);

  const notificationTypes = value ?? selectedTypes;
  const options = data?.notificationTypes ?? [];

  const handleSelection = (
    _: SyntheticEvent<Element, Event>,
    selection: Array<NotificationTypeModel>,
    reason: AutocompleteChangeReason,
  ) => {
    if (!value) setNotificationTypes(selection);
    onChange?.(selection, reason);
  };

  const handleTagRender = (value: Array<NotificationTypeModel>, getTagProps: AutocompleteRenderGetTagProps) => {
    const sortedValues = value.sort((typeA, typeB) => typeA.name.localeCompare(typeB.name));

    return sortedValues.map((tag, index) => (
      <Chip
        {...getTagProps({ index })}
        key={tag.id}
        sx={chipSx}
        color={chipColor}
        size={chipSize}
        label={translator(tag.name)}
      />
    ));
  };

  const handleRenderInput = (params: AutocompleteRenderInputParams) => (
    <LocalizedTextField
      {...params}
      {...inputProps}
      inputRef={ref}
      label={label}
      fullWidth
      size={size}
      multiline
      InputProps={{
        ...params.InputProps,
        endAdornment: isLoading ? <CircularProgress color="inherit" size={20} /> : null,
      }}
    />
  );

  const handleRenderOptionLabel = (option: NotificationTypeModel) => translator(option.name);

  const translatedNoOptionsText = translator(translations.profile.notifications.noOptions);

  return (
    <Autocomplete
      disableCloseOnSelect
      disableClearable
      disablePortal
      multiple
      openOnFocus
      filterOptions={filterOptions}
      noOptionsText={translatedNoOptionsText}
      sx={combinedStyling}
      options={options}
      renderTags={handleTagRender}
      renderInput={handleRenderInput}
      onChange={handleSelection}
      value={notificationTypes}
      getOptionLabel={handleRenderOptionLabel}
      renderOption={(props, option, { selected }) => (
        <Box sx={optionSx} component="li" {...props}>
          <NotificationTypeAutocompleteOption type={option} />
          {selected && <Iconography iconName="check-circle" color="info.light" />}
        </Box>
      )}
    />
  );
};

export const NotificationTypeAutocomplete = forwardRef<
  Ref<unknown>,
  NotificationTypeAutocompleteProps<NotificationTypeModel>
>(WrappedNotificationTypeAutocomplete);
