import { ReactElement } from 'react';

import { ConnectedContactButton } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { HaloLogo, Iconography, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Button, Typography, Paper } from '@mui/material';

const logoSx = {
  maxWidth: 72,
  maxHeight: 95,
};

const containerSx = {
  padding: 3,
  height: '100%',
};

const headingSx = {
  color: 'grey.600',
};

const buttonSx = {
  fontSize: 21,
  minWidth: 'unset',
  justifyContent: 'flex-start',
  py: 1,
  pl: 1,
};

export const UserProfileRelationshipSection = (): ReactElement => {
  const { data: user } = useUserInfoQuery();

  const relationshipManager = user?.details.relationshipManager;
  const phone = relationshipManager?.phone;
  const email = relationshipManager?.email;
  const name = relationshipManager?.name;

  const phoneContent = phone ? (
    <Button
      href={`tel:${phone}`}
      type="text"
      size="small"
      sx={buttonSx}
      startIcon={<Iconography color="primary.main" iconName="phone-alt" />}
      color="primary"
    >
      {phone}
    </Button>
  ) : null;

  const content = relationshipManager ? (
    <Stack direction="column" spacing={2} sx={containerSx}>
      <Stack direction="column">
        <LocalizedTypography color="text.secondary" variant="body2">
          {translations.profile.details.relationshipManager}
        </LocalizedTypography>
        <LocalizedTypography variant="h6">{name}</LocalizedTypography>
      </Stack>
      <Stack direction="row" spacing={2} wrap="wrap">
        {phoneContent}
        <Button
          href={`mailto:${email}`}
          type="text"
          size="small"
          sx={buttonSx}
          startIcon={<Iconography color="primary.main" iconName="envelope" />}
          color="primary"
        >
          {email}
        </Button>
      </Stack>
    </Stack>
  ) : (
    <Stack direction="column" alignItems="center" justify="center" spacing={3} sx={containerSx}>
      <Stack direction="column" spacing={1} alignItems="center" justify="center">
        <HaloLogo variant="iconGrey" slotProps={{ root: { sx: { logoSx } } }} />
        <Typography variant="h1" sx={headingSx}>
          Halo Support
        </Typography>
      </Stack>
      <ConnectedContactButton type="button" color="primary" variant="contained" size="large" />
    </Stack>
  );

  return <Paper elevation={2}>{content}</Paper>;
};
