import { LifecycleFilterModel } from '@halo-atoms/portfolio';
import { useHaloInfiniteQuery } from '@halo-common/hooks';
import { InfinitePaginationModel, InfiniteQueryOptions, PortfolioLifecycleModel } from '@halo-common/models';
import { getPortfolioLifecycleEvents } from '@halo-data-sources/clients';
import { ApiPortfolioLifecycleMapper, PaginationMapper, PortfolioLifecycleMapper } from '@halo-data-sources/mappers';
import { PortfolioLifecycleQueryKeyFactory } from '@halo-data-sources/queries';

export const LIFECYCLE_EVENTS_QUERY_PAGE_LENGTH = 15;

export type PortfolioFiltersModel = {
  householdId?: number;
  accountId?: number;
};

export type GetPortfolioLifecycleEventsQueryFilterModel = Omit<LifecycleFilterModel, 'endDate' | 'startDate'> &
  PortfolioFiltersModel & {
    synced: boolean | null;
    search?: string;
    startDate?: string;
    endDate?: string;
  };

export type GetPortfolioLifecycleInfiniteResult = {
  events: Array<PortfolioLifecycleModel>;
  pagination: InfinitePaginationModel;
};

const getLifecycleEventsQueryFn = async (
  filters: GetPortfolioLifecycleEventsQueryFilterModel,
  pageParam: InfinitePaginationModel,
) => {
  const mappedRequest = PortfolioLifecycleMapper.toApiLifecycleModel({ ...filters, page: pageParam.next });

  const data = await getPortfolioLifecycleEvents(mappedRequest);

  const mappedResponse = data.data.map(ApiPortfolioLifecycleMapper.toLifecycleModel);
  const mappedPagination = PaginationMapper.toInfinitePaginationModel(data.pagination);

  return { events: mappedResponse, pagination: mappedPagination };
};

export const useLifecycleEventsQuery = (
  filters: GetPortfolioLifecycleEventsQueryFilterModel,
  options?: Partial<InfiniteQueryOptions<GetPortfolioLifecycleInfiniteResult>>,
) =>
  useHaloInfiniteQuery<GetPortfolioLifecycleInfiniteResult>({
    queryKey: PortfolioLifecycleQueryKeyFactory.filter(filters),
    queryFn: ({ pageParam }) => getLifecycleEventsQueryFn(filters, pageParam),
    ...options,
  });
