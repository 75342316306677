import { ReactElement, useEffect, useState } from 'react';

import { AuctionTableModal } from '@halo-modules/admin';
import { OrderStatusEnum, OrdersActions, OrdersSelectors } from '@halo-stores/Orders';
import { ChipInput, ChipModel, Iconography, IconographyProps, Stack } from '@halodomination/halo-fe-common';
import { Button, Checkbox, Chip, FormControlLabel, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { OpsApprovalModalTable } from './OpsApprovalModalTable';
import type { OpsApprovalFormTableRow } from './OpsApprovalModalTable/types';

export type OpsApprovalFormFieldSchema = {
  subject: string;
  autoReply: boolean;
};

export type OpsApprovalAdditionalFormFieldSchema = {
  toAddresses: Array<ChipModel>;
  ccAddresses: Array<ChipModel>;
  tableRows: Array<OpsApprovalFormTableRow>;
};

export type OpsApprovalModalProps = {
  icon?: IconographyProps['iconName'];
};

export const OpsApprovalModal = ({ icon = 'info-circle' }: OpsApprovalModalProps): ReactElement => {
  const dispatch = useDispatch();

  const [additionalFormState, setAdditionalFormState] = useState<OpsApprovalAdditionalFormFieldSchema>({
    toAddresses: [],
    ccAddresses: [],
    tableRows: [],
  });

  const { toAddresses, ccAddresses, tableRows } = additionalFormState;

  const orderStatus = useSelector(OrdersSelectors.selectStatus);
  const selectedAuction = useSelector(OrdersSelectors.selectSelectedAuction);
  const modalOpen = orderStatus === OrderStatusEnum.showOpsApprovalModal;

  const { reset, register, handleSubmit, control, formState, setValue, getValues } =
    useForm<OpsApprovalFormFieldSchema>({
      mode: 'onChange',
      defaultValues: {
        autoReply: false,
        subject: '',
      },
    });

  const disableAction = !formState.isValid || !toAddresses.length;

  const { ref: subjectRef, ...subjectFieldProps } = register('subject', { required: true });

  const handleModalClose = () => {
    reset();
    dispatch(OrdersActions.selectAuction({ auction: null }));
  };

  const handleModalAction = handleSubmit((data: OpsApprovalFormFieldSchema) => {
    dispatch(
      OrdersActions.opsApproveOrderAuction({
        to: toAddresses.map((toAddress) => toAddress.id.toString()),
        cc: ccAddresses.map((ccAddress) => ccAddress.label),
        autoReply: data.autoReply,
        subject: data.subject,
        tableData: tableRows,
        id: selectedAuction?.id,
      }),
    );
  });

  const handleModalSubAction = () => {
    const data = getValues();

    dispatch(
      OrdersActions.sendOpsApprovalTestEmail({
        autoReply: data.autoReply,
        subject: data.subject,
        tableData: tableRows,
        id: selectedAuction?.id,
      }),
    );
  };

  const handleUpdateTableRows = (rows: Array<OpsApprovalFormTableRow>) => {
    setAdditionalFormState({ ...additionalFormState, tableRows: rows });
  };

  const handleAddCCAddress = (chips: Array<ChipModel>) => {
    setAdditionalFormState({ ...additionalFormState, ccAddresses: chips });
  };

  const handleRemoveFromToAddress = (_: Array<ChipModel>, chip?: ChipModel) => {
    const updateToAddresses = toAddresses.filter((nextChip) => nextChip.id !== chip?.id);

    setAdditionalFormState({ ...additionalFormState, toAddresses: updateToAddresses });
  };

  const handleRemoveFromCCAddress = (_: Array<ChipModel>, chip?: ChipModel) => {
    const updatedCCAddresses = !chip ? [] : ccAddresses.filter((nextChip) => nextChip.id !== chip.id);

    setAdditionalFormState({ ...additionalFormState, ccAddresses: updatedCCAddresses });
  };

  const renderChip = (chips: Array<ChipModel>) => {
    return chips.map(({ id, label, issuer }) => {
      const deleteHandler = issuer ? handleRemoveFromToAddress : handleRemoveFromCCAddress;

      return <Chip key={id} label={label} onDelete={deleteHandler} />;
    });
  };

  useEffect(() => {
    if (selectedAuction) {
      const { id, issuers } = selectedAuction;

      const updatedIssuers = issuers ? [...issuers] : [];

      setValue('subject', `New Auction ${id} Available`);

      setAdditionalFormState({
        ...additionalFormState,
        toAddresses: updatedIssuers.map((issuer) => ({ id: issuer.id, label: issuer.email, issuer: true })),
      });
    }
  }, [selectedAuction?.id]);

  return (
    <AuctionTableModal
      onClose={handleModalClose}
      open={modalOpen}
      title="Send Email"
      auctionId={selectedAuction?.id}
      buyer={selectedAuction?.buyer}
      organizationName={selectedAuction?.organizationName}
      footer={
        <Stack direction="row" justify="flex-end" spacing={2}>
          <Button type="button" variant="outlined" color="primary" onClick={handleModalSubAction}>
            Send Test
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleModalAction}
            startIcon={<Iconography iconName={icon} color="primary.main" />}
            disabled={disableAction}
          >
            Approve &amp; Send
          </Button>
        </Stack>
      }
    >
      <form>
        <Stack direction="column" spacing={2}>
          <ChipInput
            fullWidth
            value={toAddresses}
            disabled
            onRemove={handleRemoveFromToAddress}
            chipRenderer={renderChip}
          />
          <ChipInput fullWidth value={ccAddresses} onAdd={handleAddCCAddress} onRemove={handleRemoveFromCCAddress} />
          <Stack direction="row" xs={6} alignItems="center" justify="flex-end" spacing={2}>
            <TextField inputRef={subjectRef} {...subjectFieldProps} fullWidth label="Subject Line" />
            <Controller
              control={control}
              name="autoReply"
              render={({ field: { value, ...controlProps } }) => (
                <FormControlLabel
                  control={<Checkbox {...controlProps} checked={Boolean(value)} />}
                  label="Allow Auto Reply"
                />
              )}
            />
          </Stack>
          <OpsApprovalModalTable onUpdate={handleUpdateTableRows} />
        </Stack>
      </form>
    </AuctionTableModal>
  );
};
