import { ChangeEventHandler, ReactElement, ReactNode } from 'react';

import { DateRangePicker, DebouncedTextField } from '@halo-common/components';
import { useWhiteLabelDateFormat } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { Iconography } from '@halodomination/halo-fe-common';
import { InputAdornment, Stack } from '@mui/material';
import type { DateRange } from '@mui/x-date-pickers-pro';
import type { DateTime } from 'luxon';

export type SearchFiltersState = {
  search: string;
  maturesFrom: DateTime | null;
  maturesTo: DateTime | null;
  issuedFrom: DateTime | null;
  issuedTo: DateTime | null;
};

export type SearchFiltersProps = {
  state: SearchFiltersState;
  onChange: (newFilters: Partial<SearchFiltersState>) => void;
  showSearch?: boolean;
  showMatures?: boolean;
  showIssued?: boolean;
  actions?: ReactNode;
};

export const SearchFilters = ({
  state,
  onChange,
  showSearch = true,
  showMatures = false,
  showIssued = false,
  actions,
}: SearchFiltersProps): ReactElement => {
  const whiteLabelDateFormat = useWhiteLabelDateFormat();

  const updateSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange({ search: e.target.value });
  };

  const updateMatures = ([maturesFrom, maturesTo]: DateRange<DateTime>) => {
    onChange({ maturesFrom, maturesTo });
  };

  const updateIssued = ([issuedFrom, issuedTo]: DateRange<DateTime>) => {
    onChange({ issuedFrom, issuedTo });
  };

  const InputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <Iconography iconName="magnifying-glass" />
      </InputAdornment>
    ),
  };

  const searchInput = showSearch ? (
    <DebouncedTextField
      label={translations.common.search}
      variant="outlined"
      size="large"
      slotProps={{ input: InputProps }}
      value={state.search}
      onChange={updateSearch}
      sx={{ flexBasis: !actions ? '50%' : '25%' }}
    />
  ) : null;

  const maturesFilter = showMatures ? (
    <DateRangePicker
      format={whiteLabelDateFormat}
      label={translations.documents.termsheets.maturityDate}
      startDate={state.maturesFrom}
      endDate={state.maturesTo}
      onChange={updateMatures}
      size="large"
      sx={{ flexBasis: '25%' }}
    />
  ) : null;

  const issuedFilter = showIssued ? (
    <DateRangePicker
      format={whiteLabelDateFormat}
      label={translations.documents.termsheets.issueDate}
      startDate={state.issuedFrom}
      endDate={state.issuedTo}
      onChange={updateIssued}
      size="large"
      sx={{ flexBasis: '25%' }}
    />
  ) : null;

  const actionsContainer = actions ? (
    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2} sx={{ flexBasis: '25%' }}>
      {actions}
    </Stack>
  ) : null;

  return (
    <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 2 }}>
      {searchInput}
      {maturesFilter}
      {issuedFilter}
      {actionsContainer}
    </Stack>
  );
};
