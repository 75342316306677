import { ReactElement } from 'react';

import { CalendarProductCardFavoriteBtn } from '@halo-common/components';
import {
  useCalendarIndicationButtonText,
  useModalState,
  usePrimaryAssetIdentifier,
  useProductProgress,
} from '@halo-common/hooks';
import { MUST_COMPLETE_ONBOARDING_MODAL } from '@halo-common/modals';
import { CalendarModel } from '@halo-common/models';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedButton, ProgressBar, Stack } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';
import { DateTime } from 'luxon';

const containerSx = {
  minWidth: 425,
};

const progressBarSx = {
  width: 250,
};

export type ExectutionCalendarDetailModalHeaderActionsProps = {
  product?: CalendarModel | null;
  onSubmitIndication?: (product: CalendarModel) => void;
  loading?: boolean;
};

export const ExecutionCalendarDetailModalHeaderActions = ({
  product,
  onSubmitIndication,
  loading = true,
}: ExectutionCalendarDetailModalHeaderActionsProps): ReactElement | null => {
  const { data: userInfo } = useUserInfoQuery();
  const { setOpen } = useModalState(MUST_COMPLETE_ONBOARDING_MODAL);

  const openOnboardingModal = () => setOpen(true);

  const indicationButtonText = useCalendarIndicationButtonText(product?.activeAllocationCount, product?.expirationDate);

  const { percentFilled, variant, label, dynamicContent } = useProductProgress({ calendar: product });
  const { identifier, identifierType, fallback } = usePrimaryAssetIdentifier(product);

  if (loading) {
    return (
      <Stack direction="row" spacing={1}>
        <Skeleton width={100} height={42} />
      </Stack>
    );
  }

  if (!product || !onSubmitIndication) return null;

  const buttonSx = {
    pt: { md: 0, xs: 2 },
    pb: { md: 2, xs: 0 },
    pr: { md: 0, xs: 2 },
    pl: { md: 2, xs: 0 },
  };

  const parsedIdentifierText = identifier ? identifier : fallback.identifier;
  const parsedIdentifierType = identifier ? identifierType : fallback.identifierType;

  const allocationsEnabled = userInfo?.settings.enableCalendarAllocations;
  const hasAllocations = product.activeAllocationCount > 0;
  const isExpired = product?.expirationDate < DateTime.utc().toISO();
  const isClosed = !hasAllocations && isExpired;
  const allocationsButtonDisabled = isClosed || !allocationsEnabled;

  const isOnboardingCompleted = userInfo?.organization?.isOnboardingCompleted;

  const handleSubmitIndication = () => {
    if (!isOnboardingCompleted) openOnboardingModal();
    else onSubmitIndication(product);
  };

  return (
    <Stack
      direction="row"
      sx={containerSx}
      elementStyling={buttonSx}
      alignItems="center"
      justifyElements="flex-end"
      wrap="wrap"
    >
      <ProgressBar
        variant={variant}
        label={label}
        percentFilled={percentFilled}
        sx={progressBarSx}
        dynamicContent={dynamicContent}
      />
      <LocalizedButton
        type="button"
        variant="contained"
        color="primary"
        onClick={handleSubmitIndication}
        disabled={allocationsButtonDisabled}
      >
        {indicationButtonText}
      </LocalizedButton>
      <CalendarProductCardFavoriteBtn
        calendarId={product?.id}
        isFavorite={product?.isFavorite}
        assetIdentifier={parsedIdentifierText}
        assetIdentifierType={parsedIdentifierType}
      />
    </Stack>
  );
};
