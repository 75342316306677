import { ReactElement } from 'react';

import {
  CalendarNoteTypeField,
  CalendarStatusField,
  CusipField,
  TimeFrameField,
  UnderlyingField,
} from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';

export const OrderBookCalendarsFilters = (): ReactElement => (
  <Stack direction="column" spacing={2}>
    <Stack direction="row" spacing={1} xs={3} wrap="wrap">
      <UnderlyingField />
      <CalendarNoteTypeField />
      <CusipField />
      <CalendarStatusField />
      <TimeFrameField />
    </Stack>
  </Stack>
);
