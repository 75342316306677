import { ForwardedRef, forwardRef, ReactElement, SyntheticEvent, useState } from 'react';

import { useDebounced } from '@halo-common/hooks';
import { AccountModel, HouseholdBasicModel } from '@halo-common/models';
import {
  AccountTypeAheadGroupedList,
  AccountTypeAheadInput,
  AccountTypeAheadListItem,
} from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import {
  GetAccountInfiniteQueryDataSwitchRequest,
  useAccountInfiniteQueryDataSwitch,
} from '@halo-data-sources/switches';
import { IconographyProps } from '@halodomination/halo-fe-common';
import { Autocomplete, AutocompleteInputChangeReason, TextFieldProps } from '@mui/material';

export const ACCOUNT_NO_HOUSEHOLD_OPTION = translations.components.accountTypeAheadNoHouseholdsOption;

export type AccountTypeAheadSelectionReasons = 'account' | 'household' | 'action' | 'clear';

export type AccountTypeAheadState = {
  search: string;
  open: boolean;
};

export type AccountTypeAheadAction = {
  icon?: IconographyProps['iconName'];
  label: string;
  clearedOnSelection: boolean;
  disabled?: boolean;
};

export type AccountTypeAheadOption = {
  account?: AccountModel;
  household?: HouseholdBasicModel;
  action?: string;
  partial?: string;
  icon?: IconographyProps['iconName'];
  clearedOnSelection?: boolean;
  disabled?: boolean;
};

export type AccountTypeAheadRules = {
  disableHouseholds?: boolean;
  disabledOptions?: Array<string>;
  enabledPositionTypeahead?: boolean;
  hasPositions?: boolean;
  custodianIds?: Array<number>;
};

export type AccountTypeAheadProps = {
  actions?: Array<AccountTypeAheadAction>;
  defaultValue?: AccountTypeAheadOption | null;
  disableClearable?: boolean;
  error?: TextFieldProps['error'];
  helperText?: TextFieldProps['helperText'];
  label?: TextFieldProps['label'];
  onSelect: (value: AccountTypeAheadOption | null, reason?: AccountTypeAheadSelectionReasons) => void;
  rules?: AccountTypeAheadRules;
  sx?: TextFieldProps['sx'];
  size?: TextFieldProps['size'];
  value: AccountTypeAheadOption | null;
  disabled?: boolean;
  accountFormatter?: (option: AccountTypeAheadOption) => string;
};

const defaultAccountFormatter = (option: AccountTypeAheadOption) => {
  const { account, household, action, clearedOnSelection } = option;

  if (action) return !clearedOnSelection ? action : '';
  else if (household) return household.name;
  else {
    const name = account?.name ?? '--';
    const accountId = account?.accountId ?? '--';
    const custodian = account?.custodian?.name ?? '--';
    const source = account?.source ? ` - ${account?.source}` : '';
    return `${name} - ${custodian} #${accountId}${source}`;
  }
};

export const AccountTypeAheadComponent = (
  props: AccountTypeAheadProps,
  ref: ForwardedRef<HTMLDivElement>,
): ReactElement => {
  const {
    actions = [],
    disableClearable,
    label,
    error,
    helperText,
    onSelect,
    rules,
    size,
    sx,
    value = null,
    disabled = false,
    accountFormatter: handleAccountFormat = defaultAccountFormatter,
  } = props;

  const [{ search, open }, setState] = useState<AccountTypeAheadState>({ search: '', open: false });

  const disabledOptions = rules?.disabledOptions ?? [];
  const disableHouseholds = rules?.disableHouseholds ?? false;
  const enabledPositionAccountTypeahead = rules?.enabledPositionTypeahead ?? undefined;
  const enabledHasPositions = rules?.hasPositions;
  const custodianIds = rules?.custodianIds;

  const query: GetAccountInfiniteQueryDataSwitchRequest = {
    page: 1,
    pageLength: 15,
    search,
    isPosition: enabledPositionAccountTypeahead,
    hasPositions: enabledHasPositions,
    custodianIds,
  };

  const { data: user } = useUserInfoQuery();
  const isQueryEnabled = Boolean(user?.details.id);

  const { isPending, data, hasNextPage, fetchNextPage, isFetchingNextPage } = useAccountInfiniteQueryDataSwitch(query, {
    enabled: isQueryEnabled,
  });

  const pages = data?.pages ?? [];
  const loading = isPending || isFetchingNextPage;
  const showLoadingIndicator = open && loading;
  const noOptionsText = search.length
    ? translations.components.accountTypeAheadNoMatchesMessage
    : translations.components.accountTypeAheadPlaceholder;

  const households: Array<HouseholdBasicModel> = pages.flatMap((page) => page.households);

  const accounts: Array<AccountTypeAheadOption> = pages.flatMap((page) =>
    page.accounts.map((account) => ({ account })),
  );

  const typeAheadActions: Array<AccountTypeAheadOption> = actions.map((action) => ({
    action: action.label,
    disabled: action.disabled,
    clearedOnSelection: action.clearedOnSelection,
    icon: action.icon,
  }));

  const clientOptions: Array<AccountTypeAheadOption> = [...typeAheadActions, ...accounts];

  const searchHandler = (value: string) => setState({ open: true, search: value });

  const debouncedSearchHandler = useDebounced(searchHandler, 500);

  const handleSearch = (_: unknown, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason !== 'input') return undefined;
    debouncedSearchHandler(value);
  };

  const handleSelect = (_: unknown, option: AccountTypeAheadOption | null, reason: string) => {
    if (reason === 'clear') onSelect(null, 'clear');
    else onSelect(option, 'account');
    setState({ open: false, search: '' });
  };

  const handleGroupSelect = (group: string, clearedOnSelection: boolean, reason: AccountTypeAheadSelectionReasons) => {
    if (reason === 'action') onSelect({ action: group, clearedOnSelection }, reason);
    else onSelect({ household: households.find((household) => group === household.name) }, reason);
    setState({ open: false, search: '' });
  };

  const handleFilterOverride = (list: Array<AccountTypeAheadOption>) => list;

  const handleGrouping = (option: AccountTypeAheadOption) => {
    return option?.account?.household ?? option?.action ?? ACCOUNT_NO_HOUSEHOLD_OPTION;
  };

  const handleEquality = (option: AccountTypeAheadOption, value: AccountTypeAheadOption) => {
    if (!option || !value) return false;

    const hasHousehold = option.household && value.household;
    const hasAccount = option.account && value.account;
    const hasAction = option.action && value.action;

    const isHouseholdSelected = hasHousehold && option.household === value.household;
    const isAccountSelected = hasAccount && option.account?.id === value.account?.id;
    const isActionSelected = hasAction && !option.clearedOnSelection && option.action === value.action;

    return Boolean(isHouseholdSelected || isAccountSelected || isActionSelected);
  };

  const handleOpen = () => void setState({ open: true, search: '' });
  const handleClose = () => void setState({ open: false, search: '' });

  return (
    <Autocomplete<AccountTypeAheadOption, undefined, boolean>
      clearOnBlur
      selectOnFocus
      className="wm-account-typeahead"
      disableClearable={disableClearable}
      slotProps={{ popper: { keepMounted: true } }}
      filterOptions={handleFilterOverride}
      getOptionLabel={handleAccountFormat}
      groupBy={handleGrouping}
      isOptionEqualToValue={handleEquality}
      noOptionsText={noOptionsText}
      onChange={handleSelect}
      onClose={handleClose}
      onInputChange={handleSearch}
      onOpen={handleOpen}
      open={open}
      options={clientOptions}
      ref={ref}
      sx={sx}
      value={value}
      disabled={disabled}
      ListboxProps={{
        role: 'list-box',
        onScroll: (event: SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          const isBottom = listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight * 0.5;
          const loadMoreContent = open && !isPending && !isFetchingNextPage && hasNextPage && isBottom;
          if (loadMoreContent) void fetchNextPage();
        },
      }}
      renderGroup={({ group, key, ...props }) => {
        const action = typeAheadActions.find((option) => option.action === group);
        const clearActionOnSelection = Boolean(action?.clearedOnSelection);

        let icon: IconographyProps['iconName'] | null = null;
        if (group === ACCOUNT_NO_HOUSEHOLD_OPTION) icon = 'address-book';
        else if (!action) icon = 'house';
        else if (action.icon) icon = action.icon;

        const isAction = Boolean(action);
        const isDisabledAction = action && action.disabled;
        const isBlacklistedOption = !isAction && disabledOptions?.includes(group);
        const isDisabledHousehold = !isAction && (disableHouseholds || isBlacklistedOption);
        const isDisabled = Boolean(isDisabledAction || isDisabledHousehold);
        const isSelected = value?.household?.name === group || value?.action === group;

        const onGroupSelect = (value: string, reason: AccountTypeAheadSelectionReasons) => {
          handleGroupSelect(value, clearActionOnSelection, reason);
        };

        return (
          <AccountTypeAheadGroupedList
            {...props}
            key={key}
            icon={icon}
            group={group}
            disabled={isDisabled}
            selected={isSelected}
            onGroupSelect={onGroupSelect}
            hideChildren={isAction}
          />
        );
      }}
      renderInput={(props) => (
        <AccountTypeAheadInput
          {...props}
          label={label}
          error={error}
          helperText={helperText}
          loading={showLoadingIndicator}
          size={size}
        />
      )}
      renderOption={(props, option, { selected }) => {
        const accountKey = option.account ? `${option.account.household}-${option.account.id}` : undefined;
        const actionKey = option.action;
        const householdKey = option.household?.name;
        const key = accountKey || actionKey || householdKey;

        const label = handleAccountFormat(option);

        return <AccountTypeAheadListItem {...props} key={key} selected={selected} label={label} />;
      }}
    />
  );
};

export const AccountTypeAhead = forwardRef<HTMLDivElement, AccountTypeAheadProps>(AccountTypeAheadComponent);
