import { ReactElement, ReactNode } from 'react';

import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Divider, Paper, Skeleton } from '@mui/material';

const dividerSx = { marginY: 2, marginX: 0 };
const paperSx = { padding: 2 };

export type OrderReviewCardProps = {
  title: string;
  subtitle?: string;
  loading?: boolean;
  children: ReactNode;
};

export const OrderReviewCard = ({ loading = false, title, subtitle, children }: OrderReviewCardProps): ReactElement => {
  const titleContent = loading ? (
    <Skeleton width={250} height={32} />
  ) : (
    <LocalizedTypography variant="h5" color="primary">
      {title}
    </LocalizedTypography>
  );

  const subtitleText = subtitle ? <LocalizedTypography variant="caption">{subtitle}</LocalizedTypography> : null;
  const subtitleContent = loading ? <Skeleton width={340} height={16.5} /> : subtitleText;

  return (
    <Paper sx={paperSx}>
      {titleContent}
      {subtitleContent}
      <Divider sx={dividerSx} />
      <Stack direction="column" spacing={2}>
        {children}
      </Stack>
    </Paper>
  );
};
