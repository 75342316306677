import { Input } from '@halo-common/components/FormBuilder';
import { translations } from '@halo-common/translations';
import { OnboardingSuitabilityForm, SuitabilityStepModel } from '@halo-modules/app/v2/onboarding';
import { Stack } from '@mui/material';

const suitability = translations.onboarding.suitability;

const DeliveryProcessForm = () => (
  <OnboardingSuitabilityForm>
    <Stack spacing={1}>
      <Input type="checkbox" label={suitability.dvdRvp} name="trading_details.delivery_process_checkbox_dvp_rvp" />
      <Input
        type="checkbox"
        label={suitability.primeBrokerage}
        name="trading_details.delivery_process_checkbox_prime_brokerage"
      />
    </Stack>
    <Input
      label={suitability.pleaseIncludeCorrespondingClearingInstructions}
      name="trading_details.delivery_process_text"
      multiline
      rows={4}
    />
  </OnboardingSuitabilityForm>
);

export const deliveryProcessStep: SuitabilityStepModel = {
  id: '3-3',
  title: suitability.deliveryProcess,
  component: <DeliveryProcessForm />,
  isCompleted: (formData) => {
    const tradingDetails = formData?.trading_details || {};
    return Boolean(
      (tradingDetails.delivery_process_checkbox_dvp_rvp || tradingDetails.delivery_process_checkbox_prime_brokerage) &&
        tradingDetails.delivery_process_text,
    );
  },
};
