import { ReactElement } from 'react';

import { LexicalTypography } from '@halo-common/components';
import { NoteProductTypeEnum, NoteTypeEnum } from '@halo-common/enums';
import { useCombinedStyling } from '@halodomination/halo-fe-common';
import { Chip, ChipProps } from '@mui/material';

const DEFAULT_DISPLAY_TEXT = 'Note';

const defaultSx = {
  borderRadius: 1,
  backgroundColor: 'common.charts.accent1.main',
  color: 'primary.contrastText',
};

const nameSx = {
  fontWeight: 600,
  fontSize: 12,
};

const defaultChipColorSx = { backgroundColor: 'common.charts.accent1.main' };
const growthChipColorSx = { backgroundColor: 'common.charts.accent2.main' };
const snowballChipColorSx = { backgroundColor: 'common.charts.accent3.main' };
const digitalChipColorSx = { backgroundColor: 'common.charts.accent4.main' };
const digitalPlusChipColorSx = { backgroundColor: 'common.charts.accent5.main' };
const absoluteChipColorSx = { backgroundColor: 'common.charts.accent6.main', color: 'text.primary' };
const ppnChipColorSx = { backgroundColor: 'common.charts.accent7.main', color: 'text.primary' };
const generalChipColorSx = { backgroundColor: 'common.charts.accent13.main' };
const catapultChipColorSx = { backgroundColor: 'common.charts.accent10.main' };

export type ProductNoteTypeChipProps = {
  type?: string;
  subtype?: string;
  variant?: ChipProps['variant'];
};

export const ProductNoteTypeChip = ({ type = '', subtype = '', variant }: ProductNoteTypeChipProps): ReactElement => {
  const productTypeText = subtype || type || DEFAULT_DISPLAY_TEXT;

  const typeColor = {
    [NoteTypeEnum.Income]: defaultChipColorSx,
    [NoteTypeEnum.Growth]: growthChipColorSx,
    [NoteTypeEnum.Digital]: digitalChipColorSx,
    [NoteTypeEnum.Absolute]: absoluteChipColorSx,
    [NoteTypeEnum.Catapult]: catapultChipColorSx,
    [NoteTypeEnum.General]: generalChipColorSx,
  }[type.toLowerCase()];

  const subtypeColor = {
    [NoteProductTypeEnum.Snowball]: snowballChipColorSx,
    [NoteProductTypeEnum.DigitalPlus]: digitalPlusChipColorSx,
    [NoteProductTypeEnum.PrincipalProtected]: ppnChipColorSx,
  }[subtype.toLowerCase()];

  const productColor = subtypeColor ?? typeColor ?? defaultChipColorSx;
  const chipSx = useCombinedStyling(defaultSx, productColor);

  const labelComponent = (
    <LexicalTypography sx={nameSx} variant="body2">
      {productTypeText}
    </LexicalTypography>
  );

  return <Chip size="small" sx={chipSx} label={labelComponent} variant={variant} />;
};
