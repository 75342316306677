import { ReactElement, ReactNode } from 'react';

import { DocumentDownloadTableButtonPopover } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { CircularProgress, PopoverProps } from '@mui/material';

const loadingIndicatorSx = {
  color: 'primary.main',
};

const buttonSx = {
  py: 0,
};

export type DocumentDownloadTableButtonProps = {
  handleDownload?: () => void;
  loading?: boolean;
  popover?: {
    props?: Partial<PopoverProps>;
    content: ReactNode;
  };
};

export const DocumentDownloadTableButton = ({
  handleDownload,
  loading = false,
  popover,
}: DocumentDownloadTableButtonProps): ReactElement => {
  const downloadButtonLoading = !popover && loading;

  const buttonText = translations.common.download;
  const downloadIcon = <Iconography iconName="circle-down" color="primary.main" />;
  const buttonIcon = loading ? <CircularProgress size={20} sx={loadingIndicatorSx} /> : downloadIcon;

  return popover ? (
    <DocumentDownloadTableButtonPopover
      buttonText={buttonText}
      endIcon={downloadIcon}
      type="button"
      variant="text"
      color="primary"
      size="extraSmall"
      PopoverProps={popover.props}
    >
      {popover.content}
    </DocumentDownloadTableButtonPopover>
  ) : (
    <LocalizedButton
      sx={buttonSx}
      onClick={handleDownload}
      endIcon={buttonIcon}
      type="button"
      variant="text"
      color="primary"
      size="extraSmall"
      disabled={downloadButtonLoading}
    >
      {buttonText}
    </LocalizedButton>
  );
};
