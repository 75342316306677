import { UnderlyingAssetTypeEnum, UnderlyingFormatTypeEnum } from '@halo-common/enums';
import { UnderlyingExchangeModel, UnderlyingModel } from '@halo-common/models';
import { ApiUnderlyingExchangeModel, ApiUnderlyingModel } from '@halo-data-sources/models';
import { createEnumValueMapper } from '@halodomination/halo-fe-common';

export const ApiUnderlyingMapper = {
  toTradableAssetTypeEnum: createEnumValueMapper<
    string | null,
    typeof UnderlyingAssetTypeEnum,
    UnderlyingAssetTypeEnum | null
  >(UnderlyingAssetTypeEnum, null),
  toExchangeModel: (exchange: ApiUnderlyingExchangeModel): UnderlyingExchangeModel => ({
    id: exchange.id,
    description: exchange.description,
    isoCountry: exchange.isocountry,
    code: exchange.code,
  }),
  toUnderlyingModel: (tradable: ApiUnderlyingModel): UnderlyingModel => ({
    assetType: tradable.asset_type
      ? ApiUnderlyingMapper.toTradableAssetTypeEnum(tradable.asset_type.toLowerCase())
      : null,
    cusip: tradable.cusip ? tradable.cusip : null,
    description: tradable.description,
    id: tradable.id.toString(),
    isin: tradable.isin ? tradable.isin : null,
    name: tradable.name,
    ticker: tradable.bloomberg,
    exchange: tradable.exchange ? ApiUnderlyingMapper.toExchangeModel(tradable.exchange) : null,
    format: UnderlyingFormatTypeEnum.note,
    svg: tradable.svg ?? `/assets/images/svg/tiles/${tradable.name}.svg`,
  }),
};
