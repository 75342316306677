import { forwardRef, ReactElement, Ref, SyntheticEvent, useMemo, useState } from 'react';

import { underlyingInputSelectionAtom } from '@halo-atoms/common';
import { UnderlyingAutocompleteOption } from '@halo-common/components';
import { DEFAULT_PREFERENCE_TAG } from '@halo-common/constants';
import { UnderlyingModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useUnderlyingInfiniteQueryDataSwitch } from '@halo-data-sources/queries';
import { LocalizedTextField, useCombinedStyling } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import {
  Autocomplete,
  AutocompleteChangeReason,
  autocompleteClasses,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  Box,
  Chip,
  CircularProgress,
  SxProps,
  TextFieldProps,
} from '@mui/material';
import { useT } from '@transifex/react';
import { useAtom } from 'jotai';

const autoCompleteSx = {
  [`.${autocompleteClasses.input}`]: {
    padding: `0 0 0 4px !important`,
    height: 32,
  },
  [`.${autocompleteClasses.inputRoot}`]: {
    gap: 0.5,
    display: 'flex',
    flexWrap: 'wrap',
  },
  [`.${autocompleteClasses.tag}`]: {
    m: 0,
  },
};

const optionSx = {
  '&:hover': {
    backgroundColor: 'grey.300',
  },
};

export type UnderlyingAutocompleteProps<T> = {
  id?: string;
  label: string;
  name?: string;
  value?: Array<UnderlyingModel>;
  onChange: (selection: Array<T>, reason?: AutocompleteChangeReason) => void;
  error?: TextFieldProps['error'];
  helperText?: TextFieldProps['helperText'];
  variant?: TextFieldProps['variant'];
  tag?: string;
  sx?: SxProps<HaloTheme>;
  size?: 'small' | 'medium' | 'large';
  ChipProps?: {
    size?: 'small' | 'medium';
    color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
    sx?: SxProps<HaloTheme>;
  };
};

const WrappedUnderlyingAutocomplete = (
  props: UnderlyingAutocompleteProps<UnderlyingModel>,
  ref: Ref<unknown>,
): ReactElement => {
  const { value, label, onChange, size = 'large', ChipProps, sx, tag, ...inputProps } = props;

  const translator = useT();

  const [selectedUnderlyings, setSelectedUnderlyings] = useAtom(underlyingInputSelectionAtom);
  const [ticker, setTicker] = useState<string>('');

  const combinedStyling = useCombinedStyling(autoCompleteSx, sx);

  const underlyings = value ?? selectedUnderlyings;
  const queryData = ticker ?? undefined;
  const queryTag = tag ?? DEFAULT_PREFERENCE_TAG;

  const { data, isPending, isFetchingNextPage, hasNextPage, fetchNextPage } = useUnderlyingInfiniteQueryDataSwitch(
    queryData,
    queryTag,
  );

  const pages = data?.pages ?? [];
  const options = pages.flatMap((page) => page.underlyings.map((underlying) => underlying));
  const loading = isPending || isFetchingNextPage;

  const handleInputChange = (_: unknown, newValue: string) => setTicker(newValue);

  const handleRenderOptionLabel = (option: UnderlyingModel) => option.name;

  const handleSelection = (
    _: SyntheticEvent<Element, Event>,
    selection: Array<UnderlyingModel>,
    reason: AutocompleteChangeReason,
  ) => {
    if (!value) setSelectedUnderlyings(selection);
    onChange?.(selection, reason);
  };

  const noOptionsText = useMemo(() => {
    if (!ticker) return translations.components.underlyingAutocompleteBegin;
    return translations.components.underlyingAutocompleteNoResults;
  }, [ticker]);

  const translatedNoOptionsText = translator(noOptionsText, { ticker });

  const chipColor = ChipProps?.color ?? 'secondary';
  const chipSize = ChipProps?.size ?? 'medium';
  const chipSx = ChipProps?.sx;

  const handleTagRender = (value: Array<UnderlyingModel>, getTagProps: AutocompleteRenderGetTagProps) => {
    const sortedValues = value.sort((underlyingA, underlyingB) => underlyingA.name.localeCompare(underlyingB.name));

    return sortedValues.map((tag, index) => (
      <Chip {...getTagProps({ index })} key={tag.id} sx={chipSx} color={chipColor} size={chipSize} label={tag.name} />
    ));
  };

  const handleRenderInput = (params: AutocompleteRenderInputParams) => (
    <LocalizedTextField
      {...params}
      {...inputProps}
      inputRef={ref}
      label={label}
      fullWidth
      size={size}
      multiline
      slotProps={{
        input: {
          ...params.InputProps,
          endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null,
        },
      }}
    />
  );

  return (
    <Autocomplete
      autoComplete
      autoHighlight
      disableClearable
      disablePortal
      multiple
      openOnFocus
      filterOptions={(options) => options}
      noOptionsText={translatedNoOptionsText}
      sx={combinedStyling}
      options={options}
      getOptionLabel={handleRenderOptionLabel}
      renderTags={handleTagRender}
      renderInput={handleRenderInput}
      onInputChange={handleInputChange}
      onChange={handleSelection}
      value={underlyings}
      ListboxProps={{
        role: 'list-box',
        onScroll: (event: SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          const isBottom = listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight * 0.5;
          const loadMoreContent = !isPending && !isFetchingNextPage && hasNextPage && isBottom;
          if (loadMoreContent) void fetchNextPage();
        },
      }}
      renderOption={({ key, ...props }, option) => (
        <Box key={key} sx={optionSx} component="li" {...props}>
          <UnderlyingAutocompleteOption underlying={option} />
        </Box>
      )}
    />
  );
};

export const UnderlyingAutocomplete = forwardRef<Ref<unknown>, UnderlyingAutocompleteProps<UnderlyingModel>>(
  WrappedUnderlyingAutocomplete,
);
