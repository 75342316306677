import { ReactElement } from 'react';

import { EmptyResultsCard, EmptyResultsCardProps } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { AddAccountOrPositionButton } from '@halo-modules/app';
import { BrandIcon } from '@halodomination/halo-fe-common';

export type PortfolioPositionsEmptyMessageProps = Partial<EmptyResultsCardProps>;

export const PortfolioPositionsEmptyMessage = (props: PortfolioPositionsEmptyMessageProps): ReactElement => (
  <EmptyResultsCard
    title={translations.dashboard.portfolioPositions.overseeYourFullBookOfBusiness}
    description={translations.dashboard.portfolioPositions.addYourClientsAndPositionsToBeginTracking}
    action={<AddAccountOrPositionButton color="primary" />}
    brandIcon={<BrandIcon variant="risk" color="primary" />}
    {...props}
  />
);
