import { LifecycleEventTypeEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';

type TitleNameModel = {
  columnOne: (x?: number) => string;
  columnTwo: string;
  columnThree: (x?: boolean) => string;
};

const EMPTY_DEFAULT = {
  columnOne: () => '',
  columnTwo: 'Empty',
  columnThree: () => '',
};

const titleNamesByTypeUnexpired = {
  AUTOCALL_OBS: {
    columnOne: () => translations.common.callObservation,
    columnTwo: translations.portfolio.lifecycle.totalNotional,
    columnThree: (isNegativeValue?: boolean) =>
      isNegativeValue
        ? translations.portfolio.lifecycle.belowCallLevel
        : translations.portfolio.lifecycle.aboveCallLevel,
  },
  ISSUER_CALL_OBS: {
    columnOne: () => translations.common.callObservation,
    columnTwo: translations.portfolio.lifecycle.totalNotional,
    columnThree: () => '',
  },
  EXPIRATION: {
    columnOne: () => translations.common.maturity,
    columnTwo: translations.portfolio.lifecycle.totalNotional,
    columnThree: () => translations.portfolio.lifecycle.protectionRemaining,
  },
  FIXED_COUPON: {
    columnOne: () => translations.common.coupon,
    columnTwo: translations.portfolio.lifecycle.totalNotional,
    columnThree: () => '',
  },
  CONDITIONAL_COUPON: {
    columnOne: () => translations.common.coupon,
    columnTwo: translations.portfolio.lifecycle.totalNotional,
    columnThree: () => translations.portfolio.lifecycle.couponProtectionRemaining,
  },
  MEMORY_COUPON: {
    columnOne: (num?: number) => `${num} Coupon${num && num > 1 ? 's' : ''}`,
    columnTwo: translations.portfolio.lifecycle.totalNotional,
    columnThree: () => translations.portfolio.lifecycle.couponProtectionRemaining,
  },
  EMPTY: EMPTY_DEFAULT,
};

const titleNamesByTypeExpired = {
  AUTOCALL_OBS: {
    columnOne: () => translations.portfolio.lifecycle.maturityValue,
    columnTwo: translations.portfolio.lifecycle.totalNotional,
    columnThree: () => '',
  },
  ISSUER_CALL_OBS: {
    columnOne: () => translations.portfolio.lifecycle.maturityValue,
    columnTwo: translations.portfolio.lifecycle.totalNotional,
    columnThree: () => '',
  },
  EXPIRATION: {
    columnOne: () => translations.portfolio.lifecycle.maturityValue,
    columnTwo: translations.portfolio.lifecycle.totalNotional,
    columnThree: () => '',
  },
  FIXED_COUPON: {
    columnOne: () => translations.common.coupon,
    columnTwo: translations.portfolio.lifecycle.totalNotional,
    columnThree: () => '',
  },
  CONDITIONAL_COUPON: {
    columnOne: () => translations.common.coupon,
    columnTwo: translations.portfolio.lifecycle.totalNotional,
    columnThree: () => '',
  },
  MEMORY_COUPON: {
    columnOne: (num?: number) => `${num} Coupon${num && num > 1 ? 's' : ''}`,
    columnTwo: translations.portfolio.lifecycle.totalNotional,
    columnThree: () => '',
  },
  EMPTY: EMPTY_DEFAULT,
};

export const usePortfolioLifecycleTitleNamesByType = (
  isExpired: boolean,
  eventType: LifecycleEventTypeEnum,
): TitleNameModel => {
  return isExpired ? titleNamesByTypeExpired[eventType] : titleNamesByTypeUnexpired[eventType];
};
