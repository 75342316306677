import { ReactElement, ReactNode } from 'react';

import {
  accountModalAtom,
  addPositionsToAccountModalAtom,
  portfolioAccountManagerAtom,
  portfolioFiltersAtom,
} from '@halo-atoms/portfolio';
import { SortModelDirectionEnum, SortModelNullSortEnum, UserSaveAccessEnum } from '@halo-common/enums';
import { AccountModel, GridPaginationModel } from '@halo-common/models';
import { useLegacyRouter } from '@halo-common/providers';
import { AccountTypeAheadSelectionReasons } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { useAccountPagedQuery, useHouseholdQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import {
  GridSortModel,
  HaloDataGrid,
  HaloDataGridProps,
  mapNumberToLocalCurrency,
} from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { useAtom, useSetAtom } from 'jotai';

const commonColumnProps = {
  flex: 1,
  minWidth: 150,
  disableColumnMenu: true,
};

const interactiveButtonSx = {
  height: 'unset',
  minWidth: 'unset',
  p: 0,
  '&:hover': { backgroundColor: 'transparent' },
};

const sortFieldDictionary: Record<string, string> = {
  accountId: 'account_identifier',
  name: 'name',
  source: 'source',
  household: 'advisee_name',
  custodianName: 'custodian_name',
  marketValue: 'market_value',
  numOfProducts: 'num_products',
  designation: 'designation',
};

export const AccountsTable = (): ReactElement => {
  const { setQueryParams } = useLegacyRouter();

  const setPortfolioFilters = useSetAtom(portfolioFiltersAtom);
  const [accountsData, setAccountsData] = useAtom(portfolioAccountManagerAtom);
  const setAccountModal = useSetAtom(accountModalAtom);
  const setAddPositionsToAccount = useSetAtom(addPositionsToAccountModalAtom);

  const { filters, query, pagination: paginationAtom } = accountsData;
  const { currency } = filters;

  const { data: user } = useUserInfoQuery();
  const { data: householdMeta } = useHouseholdQuery();

  const isQueryEnabled = Boolean(user?.details.id);
  const { data: accountMeta, isPending } = useAccountPagedQuery(query, { enabled: isQueryEnabled });

  const accounts = accountMeta?.accounts ?? [];
  const households = householdMeta ?? [];

  const pagination = accountMeta?.pagination;
  const hasPages = paginationAtom.resultsPerPage < (pagination?.totalResults ?? 0);
  const page = accountMeta?.pagination.page ?? paginationAtom.page;
  const pageSize = accountMeta?.pagination.resultsPerPage ?? paginationAtom.resultsPerPage;
  const paginationModel = { page: page - 1, pageSize };
  const rowCount = pagination?.totalResults ?? paginationAtom.totalResults ?? 0;

  const accountSaveAccess = user?.whiteLabel.accountSaveAccess;
  const positionSaveAccess = user?.whiteLabel.positionSaveAccess;

  const isAccountUpdateEnabled = accountSaveAccess === UserSaveAccessEnum.ENABLED;
  const isPositionAddEnabled = positionSaveAccess === UserSaveAccessEnum.ENABLED;

  const isAccountUpdateDisabled = accountSaveAccess === UserSaveAccessEnum.DISABLED;
  const isPositionAddDisabled = positionSaveAccess === UserSaveAccessEnum.DISABLED;

  const isAccountManual = accountSaveAccess === UserSaveAccessEnum.MANUAL_ONLY;
  const isPositionManual = positionSaveAccess === UserSaveAccessEnum.MANUAL_ONLY;

  const handlePageChange = (model: GridPaginationModel) => {
    const page = model.page;
    setAccountsData({ pagination: { ...pagination, page: page + 1 } });
  };

  const handleSort = (model: GridSortModel) => {
    if (!model.length) return;
    const newSortModel = model.map((option) => {
      const newSortField = sortFieldDictionary[option.field] ?? 'advisee_name';
      const newSortDirection = (option.sort as SortModelDirectionEnum) ?? SortModelDirectionEnum.desc;
      const newSortAscending = newSortDirection === SortModelDirectionEnum.asc;
      const newSortNulls = newSortAscending ? SortModelNullSortEnum.first : SortModelNullSortEnum.last;
      return { field: newSortField, direction: newSortDirection, nulls: newSortNulls };
    });

    setAccountsData({ sort: newSortModel });
  };

  const renderCurrencyCell = (value = 0): ReactNode => {
    if (value === undefined) return '--';

    const roundedValue = Math.round(value);
    const parsedValue = roundedValue === 0 ? 0 : roundedValue;
    const currencyValue = mapNumberToLocalCurrency(
      parsedValue,
      { currency: currency.code, maximumFractionDigits: 0 },
      currency.symbol,
    );

    return currencyValue;
  };

  const handleInteractiveCell = (label?: string, row?: AccountModel, reason?: AccountTypeAheadSelectionReasons) => {
    const isAccount = reason === 'account';

    const handleClick = () => {
      const data = isAccount
        ? { account: row }
        : { household: households.find((nextHousehold) => nextHousehold.name === row?.household) };

      setPortfolioFilters({ accountOption: data });
      setAccountsData({ pagination: { page: 1 } });
      setQueryParams(new URLSearchParams({ tab: 'positions' }));
    };

    return (
      <Button sx={interactiveButtonSx} variant="text" color="primary" disableRipple onClick={handleClick}>
        {label}
      </Button>
    );
  };

  const updateAccountAction = {
    onClick: (row: GridRowParams['row']) => setAccountModal({ account: row, open: true }),
    label: translations.common.edit,
    icon: 'pencil',
    disabled: (row: GridRowParams['row']) => {
      if (isAccountUpdateEnabled) return false;
      const editable = !row.source || (row.source === 'halo' && isAccountManual);
      return isPending || !editable;
    },
  };

  const addPositionAction = {
    onClick: (row: GridRowParams['row']) => setAddPositionsToAccount({ account: row, open: true }),
    label: translations.portfolio.accounts.addNewPositionTableAction,
    icon: 'plus',
    disabled: (row: GridRowParams['row']) => {
      if (isPositionAddEnabled) return false;
      const editable = !row.source || (row.source === 'halo' && isPositionManual);
      return isPending || !editable;
    },
  };

  const items = [];
  if (!isAccountUpdateDisabled) items.unshift(updateAccountAction);
  if (!isPositionAddDisabled) items.unshift(addPositionAction);

  const menuItems = items.length > 0 ? items : undefined;

  const tableColumns: HaloDataGridProps['columns'] = [
    {
      ...commonColumnProps,
      field: 'household',
      headerName: translations.common.accountHousehold,
      renderCell: ({ value, row }) => handleInteractiveCell(value, row, 'household'),
    },
    {
      ...commonColumnProps,
      field: 'name',
      headerName: translations.common.accountName,
      renderCell: ({ value, row }) => handleInteractiveCell(value, row, 'account'),
    },
    {
      ...commonColumnProps,
      field: 'accountId',
      headerName: translations.common.accountNumber,
      valueFormatter: (value) => value ?? '-',
    },
    {
      ...commonColumnProps,
      field: 'custodianName',
      headerName: translations.common.custodian,
      valueGetter: (_, row: AccountModel) => row?.custodian?.name,
      valueFormatter: (value) => value ?? '-',
    },
    {
      ...commonColumnProps,
      field: 'designation',
      translateCell: true,
      headerName: translations.portfolio.accounts.accountTypeTableHeader,
      valueFormatter: (value) => value ?? '-',
    },
    {
      ...commonColumnProps,
      field: 'marketValue',
      headerName: translations.portfolio.accounts.marketValueTableHeader,
      align: 'right',
      valueFormatter: (value) => renderCurrencyCell(value),
    },
    {
      ...commonColumnProps,
      field: 'numOfProducts',
      headerName: translations.portfolio.accounts.activeProductCountTableHeader,
      align: 'right',
      valueFormatter: (value) => value ?? '-',
    },
    {
      ...commonColumnProps,
      field: 'source',
      headerName: translations.portfolio.accounts.syncingFromTableHeader,
      valueFormatter: (value) => value ?? '-',
    },
  ];

  return (
    <HaloDataGrid
      columns={tableColumns}
      noResultsMessage={translations.portfolio.accounts.accountsTableEmptyMessage}
      rowCount={rowCount}
      density="compact"
      paginationMode="server"
      sortingMode="server"
      pageSizeOptions={[paginationAtom.resultsPerPage]}
      pagination={hasPages}
      paginationModel={paginationModel}
      onPaginationModelChange={handlePageChange}
      onSortModelChange={handleSort}
      rows={accounts}
      menuItems={menuItems}
      loading={isPending}
    />
  );
};
