import { MouseEvent, ReactElement, useState } from 'react';

import { adminAuctionUpdateFormDatesAtom } from '@halo-atoms/auctions';
import { ActionButton } from '@halo-common/components';
import {
  ExecutionAuctionReviewDetailModalFooterPopover,
  ExecutionAuctionReviewUpdateFields,
} from '@halo-common/modals';
import { AuctionAdminModel } from '@halo-common/models';
import { useAdminUpdateAuctionMutation, useCancelAuctionMutation } from '@halo-data-sources/mutations';
import { Stack, mapLocalCurrencyToNumber } from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useFormContext } from 'react-hook-form';

export type ExecutionAuctionReviewDetailModalFooterProps = {
  product: AuctionAdminModel;
  loading: boolean;
};

export const ExecutionAuctionReviewDetailModalFooter = ({
  product,
  loading,
}: ExecutionAuctionReviewDetailModalFooterProps): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const adminAuctionUpdateFormData = useAtomValue(adminAuctionUpdateFormDatesAtom);

  const { mutate: onUpdate, isPending: loadingUpdate } = useAdminUpdateAuctionMutation();
  const { mutate: onCancel, isPending: loadingCancel } = useCancelAuctionMutation();

  const {
    handleSubmit: submitHandler,
    reset,
    formState: { isDirty, isValid },
  } = useFormContext();

  const isPopoverOpen = Boolean(anchorEl);
  const isLoading = loading || loadingUpdate || loadingCancel;
  const isActionDisabled = isLoading || !isDirty || !isValid || !product.endDateTime;

  const footerJustification = isDirty ? 'space-between' : 'flex-end';

  const handleRevert = () => reset();

  const handleCancel = () => void onCancel(product.id);

  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleSubmit = submitHandler((data: ExecutionAuctionReviewUpdateFields) => {
    const currencyCode = 'USD';
    const isTopOfBook = data.isTopOfBook === 'yes';
    const targetSolvableParameter = data.targetSolvableParameter ? parseFloat(data.targetSolvableParameter) : undefined;
    const noteId = data.noteId ? parseInt(data.noteId) : undefined;
    const notional = mapLocalCurrencyToNumber(data.notional, { currency: currencyCode }) ?? undefined;

    onUpdate({
      id: product.id,
      details: {
        ...data,
        currencyCode,
        isTopOfBook,
        targetSolvableParameter,
        endDate: adminAuctionUpdateFormData.endDate?.toISO() ?? undefined,
        strikeDate: adminAuctionUpdateFormData.strikeDate?.toISO() ?? undefined,
        settleDate: adminAuctionUpdateFormData.settleDate?.toISO() ?? undefined,
        tradeDate: adminAuctionUpdateFormData.tradeDate?.toISO() ?? undefined,
        noteId,
        notional,
      },
    });
  });

  const RevertChangesButton = isDirty ? (
    <Button type="button" variant="text" color="primary" disabled={isActionDisabled} onClick={handleRevert}>
      Revert Changes
    </Button>
  ) : null;

  return (
    <Stack direction="row" justify={footerJustification}>
      {RevertChangesButton}
      <Stack direction="row" spacing={2}>
        <ActionButton
          type="button"
          variant="outlined"
          color="error"
          loading={loadingCancel}
          disabled={isActionDisabled}
          onClick={handleOpenPopover}
        >
          Cancel this Auction
        </ActionButton>
        <ActionButton
          type="button"
          variant="contained"
          color="primary"
          loading={loadingUpdate}
          disabled={isActionDisabled}
          onClick={handleSubmit}
        >
          Save Changes
        </ActionButton>
        <ExecutionAuctionReviewDetailModalFooterPopover
          open={isPopoverOpen}
          onBack={handleClosePopover}
          onCancel={handleCancel}
          anchorEl={anchorEl}
        />
      </Stack>
    </Stack>
  );
};
