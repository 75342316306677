export enum ApiComparisonOptionEnum {
  GT = 'GT',
  GTE = 'GTE',
  LT = 'LT',
  LTE = 'LTE',
  EQ = 'EQ',
  NEQ = 'NEQ',
  ILIKE = 'ILIKE',
  IN = 'IN',
  NIN = 'NIN',
  CONTS = 'CONTS',
  NCONTS = 'NCONTS',
  CONTBY = 'CONTBY',
  AEQ = 'AEQ',
}

export enum ApiComparisonTypeEnum {
  AND = 'AND',
  OR = 'OR',
}
