import { OrderBookCalendarsQueryFields } from '@halo-common/models';
import { getOrderBookCalendarsCSVDownload } from '@halo-data-sources/clients';
import { OrderBookMapper } from '@halo-data-sources/mappers';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

export type UseOrderBookDownloadCalendarsCSVMutationResult = UseMutationResult<
  void,
  Error,
  OrderBookCalendarsQueryFields
>;

const orderBookDownloadCalendarsCSVMutationFn = async (searchParams: OrderBookCalendarsQueryFields) => {
  const data = await getOrderBookCalendarsCSVDownload(
    OrderBookMapper.toApiCalendarOrderBookCSVRequestModel(searchParams),
  );

  if (data.tmplink) window.open(data.tmplink, '_blank')?.focus();
};

export const useOrderBookDownloadCalendarsCSVMutation = (): UseOrderBookDownloadCalendarsCSVMutationResult =>
  useMutation<void, Error, OrderBookCalendarsQueryFields>({
    mutationFn: orderBookDownloadCalendarsCSVMutationFn,
  });
