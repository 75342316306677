import { useHaloInfiniteQuery } from '@halo-common/hooks';
import { InfinitePaginationModel, WatchlistDetailsProductModel } from '@halo-common/models';
import { getWatchlistNotes } from '@halo-data-sources/clients';
import { HttpError } from '@halo-data-sources/errors';
import { ApiWatchlistMapper, PaginationMapper } from '@halo-data-sources/mappers';
import { WatchlistQueryKeyFactory } from '@halo-data-sources/queries';

export type WatchlistNotesQueryResult = {
  products: Array<WatchlistDetailsProductModel>;
  pagination: InfinitePaginationModel;
};

export interface WatchlistNotesQueryOptions {
  resultsPerPage?: number;
}

const fetchWatchlistNotesQueryFn = async (id: number, params?: InfinitePaginationModel) => {
  if (!id) throw new HttpError(400, 'id is required');

  const page = params?.next ?? 1;
  const resultsPerPage = params?.resultsPerPage ?? 20;

  const response = await getWatchlistNotes(id, page, resultsPerPage);

  const products = response.products.map((product) => ApiWatchlistMapper.toWatchlistDetailsProductModel(product));
  const pagination = PaginationMapper.toInfinitePaginationModel(response.pagination);

  return { products, pagination };
};

export const useWatchlistNotesQuery = (id: number, options?: WatchlistNotesQueryOptions) => {
  const { resultsPerPage = 20 } = options || {};

  const DEFAULT_PAGINATION: InfinitePaginationModel = {
    totalPages: 0,
    resultsPerPage,
    totalResults: 0,
    page: 0,
    next: 1,
  };

  return useHaloInfiniteQuery<WatchlistNotesQueryResult>({
    initialPageParam: DEFAULT_PAGINATION,
    queryKey: WatchlistQueryKeyFactory.products(id, { resultsPerPage }),
    queryFn: ({ pageParam = DEFAULT_PAGINATION }) => fetchWatchlistNotesQueryFn(id, pageParam),
    ...options,
  });
};
