import { ReactElement } from 'react';

import { Iconography } from '@halodomination/halo-fe-common';
import { Box, Button } from '@mui/material';

const arrowSx = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '48px',
  height: '100%',
  backgroundColor: 'grey.50',
  border: 'none',
  zIndex: 2,
  transition: 'opacity 0.3s',
  opacity: 1,
  pointerEvents: 'auto',
  '& > button': {
    position: 'relative',
    zIndex: 3,
  },
  '&:has(button:disabled)': {
    opacity: 0,
    pointerEvents: 'none',
  },
};

const blindSx = {
  backgroundColor: 'inherit',
  position: 'absolute',
  top: '-16px',
  bottom: '-48px',
  pointerEvents: 'inherit',
  zIndex: 1,
};

const buttonSx = {
  minHeight: 40,
  minWidth: 40,
  borderRadius: '50%',
};

export interface NotesCarouselPrevArrowProps {
  currentSlide?: number;
  onClick?: () => void;
}

export const NotesCarouselPrevArrow = ({ currentSlide = 0, onClick }: NotesCarouselPrevArrowProps): ReactElement => {
  const canScrollLeft = currentSlide > 0;

  const containerSx = {
    ...arrowSx,
    borderRight: '1px solid',
    borderColor: 'grey.300',
    left: 0,
    justifyContent: 'flex-start',
  };

  const offsetSx = {
    ...blindSx,
    right: '0',
    left: '-100vw',
  };

  return (
    <Box sx={containerSx}>
      <Box sx={offsetSx} />
      <Button onClick={onClick} disabled={!canScrollLeft} sx={buttonSx} className="wm-carousel-left">
        <Iconography iconName="chevron-left" />
      </Button>
    </Box>
  );
};
