import { bulkOrderTicketManagerAtom, setFixTimeoutAtom } from '@halo-atoms/orderTicket';
import { FixWebsocketEvent } from '@halo-common/hooks';
import { OrderTicketReceiptModel } from '@halo-common/models';
import { useWebSocketContext } from '@halo-common/providers';
import { ApiFixEventMessageEnum, ApiFixEventTypeEnum } from '@halo-data-sources/enums';
import { BulkAllocationError } from '@halo-data-sources/errors';
import { ApiAllocationMapper, BulkAllocationMapper } from '@halo-data-sources/mappers';
import {
  FIVE_MINUTE_TIMEOUT,
  OrderTicketSubmissionSwitchHandlerResult,
  PERSHING_ALLOCATIONS_FORM_KEY,
  PERSHING_DEFAULT_TIMEOUT_MESSAGE,
} from '@halo-data-sources/switches';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { useSetAtom } from 'jotai';

export const usePershingSubmissionHandlers = (): OrderTicketSubmissionSwitchHandlerResult => {
  const { enqueueErrorEvent } = useSnackbar();
  const { events } = useWebSocketContext();

  const setOrderTicketState = useSetAtom(bulkOrderTicketManagerAtom);
  const setFixTimeout = useSetAtom(setFixTimeoutAtom);

  return {
    onMutate: (data) => {
      const allocations = data.bulkData?.allocations;

      if (!allocations?.length) return undefined;

      allocations.forEach((allocation) => {
        const account = allocation.accountOption?.account;
        const accountId = account?.id;
        const accountIdentifier = account?.accountId;

        if (!accountId) return;

        const websocketCallback = (event: FixWebsocketEvent) => {
          const { account: fixAccount, value, event: eventType } = event;
          const isMatchingAccount = accountId === fixAccount.id || accountIdentifier === fixAccount.account_identifier;
          const isNewFixEvent = eventType === ApiFixEventTypeEnum.NEW || eventType === ApiFixEventTypeEnum.PENDING_NEW;
          const isRejectedFixEvent = eventType === ApiFixEventTypeEnum.REJECTED;
          const handleAccepted = isMatchingAccount && isNewFixEvent;
          const handleRejected = isMatchingAccount && isRejectedFixEvent;

          const message = value.message as ApiFixEventMessageEnum;
          const isSubmitted =
            message === ApiFixEventMessageEnum.ORDER_SUBMITTED_SUCCESSFULLY ||
            message === ApiFixEventMessageEnum.ORDER_SENT_FOR_APPROVAL;

          if (isMatchingAccount) events.fix.remove(accountId);

          const bulkOrder = BulkAllocationMapper.toBulkOrderTicketReceipt(event);

          if (handleAccepted && isSubmitted) {
            setOrderTicketState({ submittedAllocationIds: accountId, lastSubmittedOrder: bulkOrder });
          } else if (handleRejected) {
            const error = { [accountId.toString()]: value.message };

            setOrderTicketState({ failedAllocationIds: accountId, fixSubmissionErrors: error });
          }
        };

        events.fix.add(accountId, websocketCallback);
      });
    },
    onError: (error: BulkAllocationError) => {
      const allocations = error.allocations ?? [];
      const hasAllocations = Boolean(allocations?.length);

      if (hasAllocations) {
        const errors = ApiAllocationMapper.toOrderTicketReceiptAllocationErrors(allocations);
        allocations.forEach((allocation) => void events.fix.remove(allocation.accountId));
        setOrderTicketState({ fixSubmissionErrors: errors });
      }

      setOrderTicketState();
      setFixTimeout();
      enqueueErrorEvent({ message: error.message });
    },
    onSuccess: (response: OrderTicketReceiptModel | null) => {
      const allocations = response?.allocations;

      if (!allocations?.length) return undefined;

      const allocationIds = allocations.map((allocation) => allocation.accountId);
      setOrderTicketState({ setPendingSubmittedAllocationIds: allocationIds });

      const timeout = global.setTimeout(() => {
        allocations.forEach((allocation) => void events.fix.remove(allocation.accountId));
        setOrderTicketState();
        const error = { [PERSHING_ALLOCATIONS_FORM_KEY]: PERSHING_DEFAULT_TIMEOUT_MESSAGE };
        setOrderTicketState({ fixSubmissionErrors: error });
        enqueueErrorEvent({ message: PERSHING_DEFAULT_TIMEOUT_MESSAGE });
      }, FIVE_MINUTE_TIMEOUT);

      setFixTimeout(timeout);
    },
  };
};
