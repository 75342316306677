import { MutableRefObject, ReactElement } from 'react';

import { EmptyResultsCard } from '@halo-common/components';
import { useDataGridSort } from '@halo-common/hooks';
import { AllocationModel } from '@halo-common/models';
import { BrandIcon, Stack, useCombinedStyling } from '@halodomination/halo-fe-common';
import { Box, SxProps } from '@mui/material';
import { DataGridPro, DataGridProProps, GridColDef } from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

const noRowsSx = {
  width: '100%',
  height: '100%',
  minHeight: 250,
};

const containerSx = {
  height: 400,
  overflowY: 'auto',
  marginTop: 2,
  borderRadius: 1,
};

const tableSx = {
  backgroundColor: 'common.white',
};

export type AdminAllocationTableProps = {
  allocations?: Array<AllocationModel>;
  columns: Array<GridColDef>;
  slots?: DataGridProProps['slots'];
  gridRef?: MutableRefObject<GridApiPro>;
  hideFooter?: DataGridProProps['hideFooter'];
  loading: boolean;
  sx?: SxProps;
};

export const AdminAllocationTable = ({
  allocations = [],
  columns,
  slots,
  gridRef,
  hideFooter,
  loading,
  sx,
}: AdminAllocationTableProps): ReactElement => {
  const rows = loading ? [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }] : allocations;

  const combinedTableStyling = useCombinedStyling(tableSx, sx);

  const sortingProps = useDataGridSort({
    initialSortColumn: 'accountName',
    sortingOrder: ['desc', 'asc'],
  });

  const gridComponents = {
    ...slots,
    NoRowsOverlay: () => (
      <Stack direction="row" alignItems="center" justify="center" sx={noRowsSx} xs={12}>
        <EmptyResultsCard
          brandIcon={<BrandIcon color="primary" variant="search" />}
          description="No allocations to show at this time."
          title="No Results"
          showBorder={false}
        />
      </Stack>
    ),
  };

  return (
    <Box sx={containerSx}>
      <DataGridPro
        {...sortingProps}
        hideFooterPagination
        hideFooterRowCount
        hideFooterSelectedRowCount
        apiRef={gridRef}
        slots={gridComponents}
        hideFooter={hideFooter}
        sx={combinedTableStyling}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        disableColumnMenu
        columnHeaderHeight={56}
      />
    </Box>
  );
};
