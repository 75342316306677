import { ReactElement } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { CollapsibleFilters, GroupedCheckbox } from '@halo-common/components';
import { NoteTypeEnum } from '@halo-common/enums';
import { DynamicTypeFiltersSideBarTitlesDict } from '@halo-common/layouts';
import { useAtom } from 'jotai';

export type NoteTypeFiltersProps = {
  tag: string;
  filterValues?: (string | number)[];
};

export const NoteTypeFilters = ({ tag, filterValues }: NoteTypeFiltersProps): ReactElement => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);
  const selectedNoteTypes = sideBarFilters.noteTypes;

  const handleCheckboxChange = (value: string) => {
    const isInFilter = selectedNoteTypes.includes(value);

    const updatedNoteTypes = isInFilter
      ? selectedNoteTypes.filter((noteType) => noteType !== value)
      : [...selectedNoteTypes, value];

    setSideBarFilters({ tag, noteTypes: updatedNoteTypes });
  };

  const noteTypeCheckboxes = Object.keys(NoteTypeEnum)
    .filter((noteType) => filterValues?.includes(noteType.toLowerCase()) ?? true)
    .map((noteType) => ({
      isChecked: selectedNoteTypes.includes(noteType),
      name: noteType,
      value: noteType,
    }));

  return (
    <CollapsibleFilters title={DynamicTypeFiltersSideBarTitlesDict.noteType}>
      <GroupedCheckbox onChange={handleCheckboxChange} values={noteTypeCheckboxes} groupedCheckboxType="product" />
    </CollapsibleFilters>
  );
};
