import { ReactElement, useEffect } from 'react';

import {
  DEFAULT_PRODUCT_DETAIL_MODAL_ANALYTICS_TAB_STATE,
  productDetailModalAnalyticsTabStateAtom,
} from '@halo-atoms/pdm';
import { DateRangePicker } from '@halo-common/components';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { NoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useRollingReturnsChartQuery } from '@halo-data-sources/queries';
import { LocalizedTypography, Stack, Tabs } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useAtom } from 'jotai';
import { DateTime } from 'luxon';

const titleSx = { color: 'text.secondary' };

export type ProductDetailModalAnalyticsRollingReturnsFiltersProps = {
  product?: NoteModel | null;
  loading?: boolean;
};

export const ProductDetailModalAnalyticsRollingReturnsFilters = ({
  product,
  loading = false,
}: ProductDetailModalAnalyticsRollingReturnsFiltersProps): ReactElement => {
  const [state, setState] = useAtom(productDetailModalAnalyticsTabStateAtom);

  const { selectedTab, openDateRange, startDate, endDate, dateRangeError } = state;

  const { data: rollingReturnsMeta, isPending, isFetched } = useRollingReturnsChartQuery(product);

  const data = rollingReturnsMeta?.chart?.data ?? [];
  const tabs = rollingReturnsMeta?.filters?.names ?? [];
  const tabValues = rollingReturnsMeta?.filters?.values ?? [];
  const minDate = rollingReturnsMeta?.filters?.minDate ?? DateTime.local();
  const maxDate = rollingReturnsMeta?.filters?.maxDate ?? DateTime.local();

  const isLoading = loading || isPending || !isFetched;
  const hasDateRangeError = Boolean(dateRangeError);

  const handleDateRangeOpen = () => void setState((prev) => ({ ...prev, openDateRange: true }));
  const handleDateRangeClose = () => void setState((prev) => ({ ...prev, openDateRange: false }));

  const handleDatePickerChange = (value: DateRange<DateTime>) => {
    const selectedStartDate = value[0];
    const selectedEndDate = value[1];

    const isInvalid = !selectedStartDate?.isValid || !selectedEndDate?.isValid;

    const isSelectedStartBeyondMinDate = !isInvalid && selectedStartDate < minDate;
    const isSelectedStartBeyondMaxDate = !isInvalid && selectedStartDate > maxDate;
    const isSelectedEndBeyondMinDate = !isInvalid && selectedEndDate < minDate;
    const isSelectedEndBeyondMaxDate = !isInvalid && selectedEndDate > maxDate;

    const isBeyondMinDate = isSelectedStartBeyondMinDate || isSelectedEndBeyondMinDate;
    const isBeyondMaxDate = isSelectedStartBeyondMaxDate || isSelectedEndBeyondMaxDate;

    const noDataForDateRange = isInvalid || isBeyondMinDate || isBeyondMaxDate;

    if (noDataForDateRange) {
      return setState((prev) => ({
        ...prev,
        selectedTab: tabs.length - 1,
        dateRangeError: `No data for selected date(s).`,
      }));
    }

    const isStartAfterEnd = selectedStartDate > selectedEndDate;

    if (isStartAfterEnd) {
      return setState((prev) => ({
        ...prev,
        selectedTab: tabs.length - 1,
        dateRangeError: `Set an end date that is later than the start.`,
      }));
    }

    const duration = selectedEndDate && selectedStartDate ? selectedEndDate.diff(selectedStartDate, 'days') : undefined;
    const updatedDaysBetween = duration?.days ? Math.round(duration.days) : undefined;

    const updatedBrushStartIndex = data.findIndex(({ x }) => selectedStartDate <= DateTime.fromSeconds(x));
    const updatedBrushEndIndex = data.findIndex(({ x }) => selectedEndDate <= DateTime.fromSeconds(x));

    const updatedStartDate = DateTime.fromSeconds(data[updatedBrushStartIndex].x);
    const updatedEndDate = DateTime.fromSeconds(data[updatedBrushEndIndex].x);

    const updatedStarDateText = updatedStartDate.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
    const updatedEndDateText = updatedEndDate.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
    const updatedDateRangeText = `${updatedStarDateText} - ${updatedEndDateText}`;

    setState((prev) => ({
      ...prev,
      dateRangeError: undefined,
      openDateRange: false,
      brushStart: updatedBrushStartIndex,
      brushEnd: updatedBrushEndIndex,
      selectedTab: tabs.length - 1,
      daysBetween: updatedDaysBetween ?? prev.daysBetween,
      startDate: updatedStartDate ?? prev.startDate,
      endDate: updatedEndDate ?? prev.endDate,
      dateRangeText: updatedDateRangeText,
    }));
  };

  const handleTabChange = (index: number, suppressDateRangeOpen = false) => {
    const isCustomTab = index === tabs.length - 1;
    const showCalculatedDate = !isCustomTab || suppressDateRangeOpen;
    const isInitialLoad = isCustomTab && suppressDateRangeOpen;

    const numberOfYearsSelected = tabValues[index];
    const lastDateIndex = data.length - 1;

    const endDateReference = data[lastDateIndex]?.x ?? endDate.toSeconds();
    const lastDate = DateTime.fromSeconds(endDateReference);

    const startDateReference = data[0]?.x ?? startDate.toSeconds();
    const firstDate = DateTime.fromSeconds(startDateReference);

    const desiredEndDate = !isCustomTab ? lastDate : endDate;
    const selectedEndDate = isInitialLoad ? maxDate : desiredEndDate;
    const brushEndIndex = !isCustomTab
      ? lastDateIndex
      : data.findIndex(({ x }) => selectedEndDate <= DateTime.fromSeconds(x));

    const desiredStartDate = !isCustomTab ? selectedEndDate.minus({ years: numberOfYearsSelected }) : startDate;
    const correctedStartDate = desiredStartDate < firstDate ? firstDate : desiredStartDate;
    const selectedStartDate = isInitialLoad ? minDate : correctedStartDate;
    const brushStartIndex = !isCustomTab
      ? data.findIndex(({ x }) => selectedStartDate <= DateTime.fromSeconds(x))
      : data.findIndex(({ x }) => startDate <= DateTime.fromSeconds(x));

    const daysBetween = Math.round(selectedEndDate.diff(selectedStartDate, 'days').days);

    const starDateText = selectedStartDate.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
    const endDateText = selectedEndDate.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
    const dateRangeText = `${starDateText} - ${endDateText}`;

    setState((prev) => ({
      ...prev,
      daysBetween,
      dateRangeError: undefined,
      selectedTab: index,
      brushStart: brushStartIndex,
      brushEnd: brushEndIndex,
      openDateRange: !suppressDateRangeOpen && isCustomTab,
      endDate: showCalculatedDate ? selectedEndDate : prev.endDate,
      startDate: showCalculatedDate ? selectedStartDate : prev.startDate,
      dateRangeText,
    }));
  };

  const title = isLoading ? (
    <Skeleton variant="rounded" height={17} width="100%" />
  ) : (
    <LocalizedTypography sx={titleSx} variant="caption">
      {translations.pdm.preTrade.zoom}
    </LocalizedTypography>
  );

  useEffect(() => {
    const updatedSelectedTab = tabValues.includes(10)
      ? DEFAULT_PRODUCT_DETAIL_MODAL_ANALYTICS_TAB_STATE.selectedTab
      : tabValues.length
        ? tabValues.length - 1
        : tabs.length - 1;

    if (!isPending) handleTabChange(updatedSelectedTab, true);
  }, [isPending]);

  return (
    <Stack direction="row" alignItems="center" xs={[8, 4]}>
      <Stack direction="row" alignItems="center" spacing={2}>
        {title}
        <Tabs
          variant="pills"
          tabs={tabs}
          loading={isLoading}
          value={selectedTab}
          onChange={handleTabChange}
          slotProps={{ tab: { walkMeNamespace: 'wm-rr' } }}
        />
      </Stack>
      <DateRangePicker
        closeOnSelect
        open={openDateRange}
        onOpen={handleDateRangeOpen}
        onClose={handleDateRangeClose}
        loading={isLoading}
        onChange={handleDatePickerChange}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        label="Issued Date"
        helperText={dateRangeError}
        error={hasDateRangeError}
        walkMeNamespace="wm-rr"
      />
    </Stack>
  );
};
