import { OrderBookQuery } from '@halo-atoms/orderbook';
import { API_ARBITRARY_LENGTH_TO_GET_ALL_DATA, API_ARBITRARY_PAGE_TO_GET_ALL_DATA } from '@halo-common/constants';
import { OrderBookCalendarDataModel, OrderBookCalendarDetails } from '@halo-common/models';
import { getCalendars, postCalendarOrderQuery } from '@halo-data-sources/clients';
import { ApiComparisonOptionEnum } from '@halo-data-sources/enums';
import {
  ApiAllocationMapper,
  ApiCalendarsMapper,
  JanneyOrderBookMapper,
  OrderBookMapper,
  PaginationMapper,
} from '@halo-data-sources/mappers';
import { OrderBookClientQueryKeyFactory } from '@halo-data-sources/queries';
import { keepPreviousData, useQuery, UseQueryResult } from '@tanstack/react-query';

const getOrderBookJanneyQueryFn = async (searchParams: OrderBookQuery) => {
  const sort = OrderBookMapper.toApiOrderBookSortList(searchParams);
  const comparisons = JanneyOrderBookMapper.toApiFiltersComparisonList(searchParams);

  const orderResponse = await postCalendarOrderQuery({
    sort,
    comparisons,
    page: searchParams.page,
    page_length: searchParams.pageSize,
  });

  const calendarIds = orderResponse.results.map(({ allocation }) => allocation.calendar_id);

  const calendarResponse = await getCalendars({
    comparisons: [
      {
        field: 'calendar_notes.id',
        value: calendarIds,
        op: ApiComparisonOptionEnum.IN,
      },
    ],
    page: API_ARBITRARY_PAGE_TO_GET_ALL_DATA,
    page_length: API_ARBITRARY_LENGTH_TO_GET_ALL_DATA,
  });

  const calendarDetails: Array<OrderBookCalendarDetails> = orderResponse.results.map(({ allocation, extra }) => {
    const calendar = calendarResponse.calendars.find((calendar) => calendar.id === allocation.calendar_id);

    return {
      id: allocation.id,
      calendar: calendar ? ApiCalendarsMapper.toCalendarModel(calendar) : undefined,
      allocation: ApiAllocationMapper.toJanneyOrderbookAllocationModel(allocation),
      transactionSummary: ApiAllocationMapper.toOrderTicketReceiptSummary(extra),
    };
  });

  return {
    details: calendarDetails,
    pagination: PaginationMapper.toPaginationModel(orderResponse.pagination),
  };
};

export const useOrderBookJanneyQuery = (
  searchParams: OrderBookQuery,
): UseQueryResult<OrderBookCalendarDataModel, Error> =>
  useQuery<OrderBookCalendarDataModel, Error>({
    queryKey: OrderBookClientQueryKeyFactory.janney(searchParams),
    queryFn: () => getOrderBookJanneyQueryFn(searchParams),
    placeholderData: keepPreviousData,
  });
