import { ReactElement } from 'react';

import { Iconography, Tooltip, useCombinedStyling } from '@halodomination/halo-fe-common';
import { useHaloTheme } from '@halodomination/halo-fe-theme';
import { Box, Chip, SxProps, Typography } from '@mui/material';

const chipContainerSx = {
  display: 'flex',
  height: '100%',
  width: '100%',
  alignItems: 'center',
};

export type StatusChipProps = {
  status: 'completed' | 'pending' | 'action-required' | 'closed' | 'error';
  label: string;
  message?: string;
  sx?: SxProps;
  outerSx?: SxProps;
};

export const StatusChip = ({ label, message, status, sx, outerSx }: StatusChipProps): ReactElement => {
  const { theme } = useHaloTheme();

  const icon = {
    completed: <Iconography color="success.main" iconName="circle-check" />,
    closed: <Iconography color="text.secondary" iconName="circle-xmark" />,
    'action-required': <Iconography color="warning.main" prefix="fal" iconName="circle-exclamation" />,
    pending: <Iconography color="success.main" prefix="fal" iconName="clock" />,
    error: <Iconography color="error.main" prefix="fal" iconName="circle-xmark" />,
  }[status];

  const color = {
    completed: theme.palette.text?.secondary,
    closed: theme.palette.text?.secondary,
    'action-required': theme.palette.warning?.dark,
    pending: theme.palette.success?.main,
    error: theme.palette.error?.main,
  }[status];

  const backgroundColor = {
    completed: theme.palette.grey?.[200],
    closed: theme.palette.grey?.[200],
    'action-required': theme.palette.warning?.background,
    pending: theme.palette.success?.background,
    error: theme.palette.error?.background,
  }[status];

  const chipSx = { backgroundColor };
  const contentSx = useCombinedStyling({ color, px: 1 }, sx);
  const containerSx = useCombinedStyling(chipContainerSx, outerSx);

  const tooltip = message ? (
    <Tooltip title={message}>
      <Iconography color="grey.700" prefix="fal" iconName="circle-info" />
    </Tooltip>
  ) : null;

  const content = (
    <Box sx={chipContainerSx}>
      {icon}
      <Typography sx={contentSx} fontWeight="bold" variant="caption">
        {label}
      </Typography>
      {tooltip}
    </Box>
  );

  return (
    <Box sx={containerSx}>
      <Chip label={content} sx={chipSx} size="small" />
    </Box>
  );
};
