export enum DocusignBooleanEnum {
  YES = 'YES',
  NO = 'NO',
}

export enum DocusignRegulatorEnum {
  SEC = 'SEC',
  FINRA = 'FINRA',
  OTHER = 'OTHER',
}

export enum DocusignExperienceEnum {
  NONE = 'NONE',
  ONE_TO_TWO = 'ONE_TO_TWO',
  THREE_TO_FIVE = 'THREE_TO_FIVE',
  SIX_TO_NINE = 'SIX_TO_NINE',
  TEN_PLUS = 'TEN_PLUS',
}

export enum DocusignComplianceEnum {
  IN_HOUSE = 'IN_HOUSE',
  OUTSOURCED = 'OUTSOURCED',
}

export enum DocusignBankTypeEnum {
  FEDERAL_BANK = 'FEDERAL',
  STATE_BANK = 'STATE',
}
