import { selectedWatchlistAtom } from '@halo-atoms/watchlists';
import { WatchlistModel } from '@halo-common/models';
import { useLegacyRouter } from '@halo-common/providers';
import { deleteWatchlist } from '@halo-data-sources/clients';
import { WatchlistQueryKeyFactory } from '@halo-data-sources/queries';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';

export type DeleteWatchlistMutationOptions = {
  navigate: boolean;
};

export type DeleteWatchlistMutationResult = {
  id: number;
  message: string;
};

const deleteWatchlistMutationFn = async (id?: number | null) => {
  if (!id) return null;

  await deleteWatchlist(id);

  return {
    id,
    message: 'Successfully deleted watchlist',
  };
};

export const useDeleteWatchlistMutation = (
  options?: DeleteWatchlistMutationOptions,
): UseMutationResult<DeleteWatchlistMutationResult | null, Error, number | null | undefined> => {
  const navigate = options?.navigate;

  const legacyRouter = useLegacyRouter();
  const queryClient = useQueryClient();

  const setSelectedWatchlist = useSetAtom(selectedWatchlistAtom);

  return useMutation({
    mutationFn: deleteWatchlistMutationFn,
    onSettled: (_, error) => {
      if (error) {
        return undefined;
      } else if (navigate) {
        setSelectedWatchlist(null);
        legacyRouter.removeQueryParam('watchlist');
      }
    },
    onSuccess: (data) => {
      const key = WatchlistQueryKeyFactory.all();
      queryClient.setQueryData<Array<WatchlistModel>>(key, (prev) => {
        if (!prev || !data) return undefined;
        return prev.filter((watchlist) => watchlist.id !== data.id);
      });
    },
  });
};
