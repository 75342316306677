import { useEffect, useState } from 'react';

export const useRemainingHeight = () => {
  const [remainingHeight, setRemainingHeight] = useState(0);

  useEffect(() => {
    const calculateRemainingHeight = () => {
      const headerHeight = document.getElementById('haloPageHeader')?.offsetHeight ?? 0;
      const footerHeight = document.getElementById('haloPageFooter')?.offsetHeight ?? 0;

      const viewHeight = window.innerHeight - headerHeight - footerHeight;

      setRemainingHeight(viewHeight);
    };

    calculateRemainingHeight();

    window.addEventListener('resize', calculateRemainingHeight);

    return () => window.removeEventListener('resize', calculateRemainingHeight);
  }, []);

  return remainingHeight;
};
