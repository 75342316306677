import { downloadFile } from '@halo-common/utils';
import { getDisclaimerRaw } from '@halo-data-sources/clients';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

export type DisclaimerDownloadUrlQueryPayload = {
  filename?: string;
  disclaimerId?: string;
};

const userDisclaimerDownloadMutationFn = async (payload: DisclaimerDownloadUrlQueryPayload) => {
  if (!payload.filename || !payload.disclaimerId) return;

  const { filename, disclaimerId } = payload;

  const response = await getDisclaimerRaw(disclaimerId);

  downloadFile({ fileName: filename, downloadFilePath: response });
};

export const useUserDisclaimerDownloadMutation = (): UseMutationResult<
  void,
  Error,
  DisclaimerDownloadUrlQueryPayload
> =>
  useMutation<void, Error, DisclaimerDownloadUrlQueryPayload>({
    mutationFn: userDisclaimerDownloadMutationFn,
  });
