import { ReactElement } from 'react';

import { ProductFeatureList, ProductTypeChip } from '@halo-common/components';
import { ProductTypeEnum } from '@halo-common/enums';
import { AnnuityOrderModel } from '@halo-common/models';
import { Stack } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';

const featureListContainerSx = { marginBottom: 2 };

export type PostTradeAnnuityDetailModalHeaderFeaturesProps = {
  product?: AnnuityOrderModel | null;
  loading?: boolean;
};

export const PostTradeAnnuityDetailModalHeaderFeatures = ({
  loading = true,
}: PostTradeAnnuityDetailModalHeaderFeaturesProps): ReactElement => {
  if (loading) {
    return (
      <Stack direction="row" spacing={1}>
        <Skeleton width={58} height={32} />
        <Skeleton width={58} height={32} />
        <Skeleton width={58} height={32} />
        <Skeleton width={58} height={32} />
      </Stack>
    );
  }

  return (
    <ProductFeatureList sx={featureListContainerSx} type={ProductTypeEnum.annuity} features={[]}>
      <ProductTypeChip type={ProductTypeEnum.annuity} />
    </ProductFeatureList>
  );
};
