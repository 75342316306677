import { ReactElement } from 'react';

import { FixedIncomeProductCardFavoriteBtn, V2ProductSolvableParameter } from '@halo-common/components';
import {
  useCalendarIndicationButtonText,
  useDesktopView,
  useModalState,
  usePrimaryAssetIdentifier,
} from '@halo-common/hooks';
import { MUST_COMPLETE_ONBOARDING_MODAL } from '@halo-common/modals';
import { CalendarModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';

const allocationsButtonSx = {
  width: 175,
};

const containerSx = {
  width: 'fit-content',
};

const actionHeadingSx = {
  minWidth: 250,
};

export type FixedIncomeProductCardAction = (calendar: CalendarModel) => void;

export type FixedIncomeProductCardActionsProps = {
  calendar: CalendarModel;
  actions?: {
    onDetails?: FixedIncomeProductCardAction;
    onSubmitIndication?: FixedIncomeProductCardAction;
    onOfferingDoc?: FixedIncomeProductCardAction;
  };
};

export const FixedIncomeProductCardActions = ({
  calendar,
  actions,
}: FixedIncomeProductCardActionsProps): ReactElement => {
  const {
    activeAllocationCount,
    noteV2,
    expirationDate,
    id,
    isFavorite,
    finalTerms,
    preliminaryTerms,
    inventoryRemaining,
    paused,
  } = calendar;

  const isDesktop = useDesktopView();

  const { data: userInfo } = useUserInfoQuery();
  const { setOpen } = useModalState(MUST_COMPLETE_ONBOARDING_MODAL);

  const openOnboardingModal = () => setOpen(true);

  const { identifier, identifierType, fallback } = usePrimaryAssetIdentifier(calendar);

  const parsedIdentifierText = identifier ? identifier : fallback.identifier;
  const parsedIdentifierType = identifier ? identifierType : fallback.identifierType;

  const parameter = noteV2?.payoffDetails?.solvableParameter;

  const allocationsEnabled = userInfo?.settings.enableCalendarAllocations;
  const hasAllocations = activeAllocationCount > 0;
  const hasInventory = !inventoryRemaining || inventoryRemaining > 0;
  const hasDocuments = finalTerms || preliminaryTerms;
  const isExpired = expirationDate < DateTime.utc().toISO();
  const isClosed = isExpired && !hasAllocations;
  const isPaused = paused && !hasAllocations;
  const showOfferingDocButton = Boolean(hasDocuments && actions?.onOfferingDoc);
  const allocationButtonDisabled = isPaused || isClosed || !hasInventory || !allocationsEnabled;

  const desktopLayout = isDesktop ? 'row' : 'column';
  const centerOfferingBtnIdentifier = isDesktop ? 'center' : undefined;
  const identifierSx = { paddingLeft: showOfferingDocButton ? 2 : 0 };

  const allocationModalButtonText = useCalendarIndicationButtonText(activeAllocationCount, expirationDate);

  const isOnboardingCompleted = userInfo?.organization?.isOnboardingCompleted;

  const handleOfferingDocClick = () => {
    actions?.onOfferingDoc?.(calendar);
  };

  const handleSubmitAllocationsToggle = () => {
    if (!isOnboardingCompleted) openOnboardingModal();
    else actions?.onSubmitIndication?.(calendar);
  };

  const handleDetailsModalToggle = () => {
    actions?.onDetails?.(calendar);
  };

  const offeringDocButton = showOfferingDocButton ? (
    <LocalizedButton
      onClick={handleOfferingDocClick}
      size="small"
      color="primary"
      startIcon={<Iconography color="primary.main" iconName="file-magnifying-glass" />}
    >
      {translations.calendars.common.offeringDoc}
    </LocalizedButton>
  ) : null;

  const submitIndicationsButton = actions?.onSubmitIndication ? (
    <LocalizedButton
      sx={allocationsButtonSx}
      size="medium"
      variant="contained"
      onClick={handleSubmitAllocationsToggle}
      disabled={allocationButtonDisabled}
    >
      {allocationModalButtonText}
    </LocalizedButton>
  ) : null;

  const detailButton = actions?.onDetails ? (
    <LocalizedButton size="medium" variant="text" onClick={handleDetailsModalToggle}>
      {translations.common.details}
    </LocalizedButton>
  ) : null;

  return (
    <Stack sx={containerSx} spacing={2} direction="column" justifyContent="space-between">
      <Stack sx={actionHeadingSx} direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction={desktopLayout} justifyContent="space-between" alignItems={centerOfferingBtnIdentifier}>
          {offeringDocButton}
          <Typography sx={identifierSx} variant="body2" color="text.secondary">
            {parsedIdentifierText}
          </Typography>
        </Stack>
        <FixedIncomeProductCardFavoriteBtn
          isFavorite={isFavorite}
          calendarId={id}
          assetIdentifier={parsedIdentifierText}
          assetIdentifierType={parsedIdentifierType}
        />
      </Stack>
      <Stack direction="column" alignItems="center">
        <V2ProductSolvableParameter parameter={parameter} loading={!calendar} />
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        {submitIndicationsButton}
        {detailButton}
      </Stack>
    </Stack>
  );
};
