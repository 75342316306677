import { UserRoleEnum } from '@halo-common/enums';
import { useUserInfoQuery } from '@halo-data-sources/queries';

export const useOrderHubEditMode = (): boolean => {
  const { data: userInfo } = useUserInfoQuery();

  const roles = userInfo?.details.roles ?? [];
  const orderHubEditRoles = [UserRoleEnum.HaloAdmin, UserRoleEnum.AuctionAdmin];

  return roles.some((role) => orderHubEditRoles.includes(role));
};
