import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import {
  WatchlistContentFormRadioGroupButton,
  WatchlistContentFormValues,
  watchlistIconNames,
} from '@halo-modules/app';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { RadioGroup } from '@mui/material';
import { ControllerRenderProps, useFormContext } from 'react-hook-form';

const titleSx = { pb: 1 };
const errorSx = { ml: 0, fontSize: '12px' };

export type WatchlistContentFormRadioGroupProps = {
  field: ControllerRenderProps<WatchlistContentFormValues, 'watchlistIcon'>;
  loading?: boolean;
};

export const WatchlistContentFormRadioGroup = ({
  field,
  loading = false,
}: WatchlistContentFormRadioGroupProps): ReactElement => {
  const {
    formState: { errors },
  } = useFormContext<WatchlistContentFormValues>();

  const error = errors['watchlistIcon']?.message;

  const iconErrorIndication = error ? (
    <LocalizedTypography color="error.main" sx={errorSx}>
      {error}
    </LocalizedTypography>
  ) : null;

  return (
    <RadioGroup {...field}>
      <Stack direction="column">
        <LocalizedTypography variant="h6" sx={titleSx}>
          {translations.watchlist.common.chooseIcon}
        </LocalizedTypography>
        <Stack direction="row" wrap="wrap" spacing={2}>
          {watchlistIconNames.map((name) => (
            <WatchlistContentFormRadioGroupButton key={name} icon={name} value={name} loading={loading} />
          ))}
        </Stack>
        {iconErrorIndication}
      </Stack>
    </RadioGroup>
  );
};
