import { OrderBookCalendarModel, OrderBookCalendarsQueryFields, PaginationModel } from '@halo-common/models';
import { getOrderBookCalendars } from '@halo-data-sources/clients';
import { ApiOrderBookMapper, OrderBookMapper } from '@halo-data-sources/mappers';
import { OrderBookCalendarQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryOptions, UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

export type OrderBookCalendarsQueryResult = {
  calendars?: Array<OrderBookCalendarModel>;
  pagination?: PaginationModel;
};

export type UseOrderBoolCalendarsQueryOptions = UseQueryOptions<OrderBookCalendarsQueryResult, Error>;
export type UseOrderBookCalendarsQueryResult = UseQueryResult<OrderBookCalendarsQueryResult, Error>;

const getOrderBookCalendarsFn = async (searchParams: OrderBookCalendarsQueryFields) => {
  const mappedParams = OrderBookMapper.toApiCalendarSearchParams(searchParams);

  const response = await getOrderBookCalendars(mappedParams);

  return ApiOrderBookMapper.toOrderBookCalendarsQueryResult(response);
};

export const useOrderBookCalendarsQuery = (
  searchParams: OrderBookCalendarsQueryFields,
  options?: UseOrderBoolCalendarsQueryOptions,
): UseOrderBookCalendarsQueryResult =>
  useQuery<OrderBookCalendarsQueryResult, Error>({
    queryKey: OrderBookCalendarQueryKeyFactory.calendars(searchParams),
    queryFn: () => getOrderBookCalendarsFn(searchParams),
    placeholderData: keepPreviousData,
    ...options,
  });
