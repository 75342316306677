import { ReactElement } from 'react';

import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, useTheme } from '@mui/material';
import { SxProps } from '@mui/material/styles';

export type DoughnutProps = {
  sx?: SxProps<HaloTheme>;
};

export const Doughnut = (props: DoughnutProps): ReactElement => {
  const theme = useTheme<HaloTheme>();

  return (
    <Box {...props}>
      <svg width="347" height="347" viewBox="0 0 347 347" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill={theme.palette.common.black}
          fillOpacity="0.2"
          d="M173 125.37C166.716 125.388 160.496 126.645 154.697 129.067C148.898 131.489 143.633 135.03 139.203 139.487C134.772 143.945 131.263 149.231 128.876 155.045C126.489 
        160.858 125.27 167.085 125.29 173.37C125.29 199.63 146.71 221.37 173 221.37C185.456 220.968 197.267 215.737 205.936 206.784C214.604 197.831 219.451 185.857 219.451 
        173.395C219.451 160.933 214.604 148.959 205.936 140.006C197.267 131.053 185.456 125.822 173 125.42V125.37ZM172.86 0C268.58 0 346.24 78.12 346.24 173.79C346.24 269.46 
        268.58 346.7 172.86 346.7C150.148 346.729 127.652 342.276 106.664 333.596C85.6751 324.916 66.6054 312.18 50.5474 296.117C34.4895 280.055 21.7589 260.981 13.085 
        239.99C4.41121 218.999 -0.0353285 196.503 0.000211372 173.79C0.000211372 78.12 77.2002 0 172.86 0Z"
        />
      </svg>
    </Box>
  );
};
