import { ReactElement } from 'react';

import { ProductFeatureList, ProductNoteTypeChip } from '@halo-common/components';
import { PortfolioOrderModel } from '@halo-common/models';
import { Stack } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';

const containerSx = { marginBottom: 2 };

export type ExecutionAuctionReviewDetailModalHeaderFeaturesProps = {
  type?: string;
  subtype?: string;
  features?: PortfolioOrderModel['note']['features'];
  loading: boolean;
};

export const ExecutionAuctionReviewDetailModalHeaderFeatures = ({
  features = [],
  type,
  subtype,
  loading,
}: ExecutionAuctionReviewDetailModalHeaderFeaturesProps): ReactElement => {
  if (loading) {
    return (
      <Stack direction="row" spacing={1}>
        <Skeleton width={58} height={32} />
        <Skeleton width={58} height={32} />
        <Skeleton width={58} height={32} />
        <Skeleton width={58} height={32} />
      </Stack>
    );
  }

  const productFeatureListItems = features.map((feature) => ({
    name: feature.name,
    description: feature.description ?? '',
    icon: feature.icon,
  }));

  return (
    <ProductFeatureList sx={containerSx} type={type} subtype={subtype} features={productFeatureListItems}>
      <ProductNoteTypeChip type={type} subtype={subtype} />
    </ProductFeatureList>
  );
};
