import { HTMLAttributes, ReactElement, useState } from 'react';

import { useDebounced } from '@halo-common/hooks';
import { UserRelationshipManagerModel } from '@halo-common/models';
import { useRelationshipManagerSearchQuery } from '@halo-data-sources/queries';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  Box,
  CircularProgress,
  TextField,
  TextFieldProps,
  Typography,
  inputBaseClasses,
} from '@mui/material';

const endAdornmentSx = {
  display: 'flex',
};

const loadingIndicatorSx = {
  marginRight: 1,
};

const clientInputSx = {
  [`.${inputBaseClasses.root}`]: {
    paddingLeft: 2,
  },
};

export type RelationshipManagerTypeAheadProps = Omit<
  AutocompleteProps<UserRelationshipManagerModel, boolean, boolean, undefined>,
  'options' | 'renderInput' | 'onChange'
> & {
  onChange?: (option: UserRelationshipManagerModel | Array<UserRelationshipManagerModel> | null) => void;
  TextFieldProps?: TextFieldProps;
};

export const RelationshipManagerTypeAhead = ({
  TextFieldProps,
  onChange,
  ...props
}: RelationshipManagerTypeAheadProps): ReactElement => {
  const [query, setQuery] = useState('');

  const { data: managers = [], isPending } = useRelationshipManagerSearchQuery(query);

  const debouncedSearchHandler = useDebounced(setQuery, 500);

  const handleSearch = (_: unknown, value: string) => {
    debouncedSearchHandler(value);
  };

  const getOptionLabel = (option: UserRelationshipManagerModel) => option.name;

  const handleChange = (
    _: unknown,
    option: UserRelationshipManagerModel | Array<UserRelationshipManagerModel> | null,
  ) => {
    onChange?.(option);
  };

  const handleRenderOption = (props: HTMLAttributes<HTMLLIElement>, option: UserRelationshipManagerModel) => (
    <Box {...props} component="li" key={option.id}>
      <Typography>{option.name}</Typography>
    </Box>
  );

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const textFieldInputLoadingIndicator = isPending ? <CircularProgress sx={loadingIndicatorSx} size={20} /> : null;

    const textFieldInputProps = {
      ...params.InputProps,
      endAdornment: (
        <Box sx={endAdornmentSx}>
          {textFieldInputLoadingIndicator}
          {params.InputProps.endAdornment}
        </Box>
      ),
    };

    return (
      <TextField
        {...params}
        label="Search Relationship Managers"
        multiline={props.multiple}
        size="large"
        sx={clientInputSx}
        slotProps={{ input: textFieldInputProps }}
        {...TextFieldProps}
      />
    );
  };

  return (
    <Autocomplete<UserRelationshipManagerModel, boolean, boolean, undefined>
      {...props}
      options={managers}
      onChange={handleChange}
      onInputChange={handleSearch}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel}
      renderOption={handleRenderOption}
    />
  );
};
