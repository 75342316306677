import { PortfolioPositionQueryModel } from '@halo-atoms/portfolio';
import { useHaloInfiniteQuery } from '@halo-common/hooks';
import { InfinitePaginationModel, InfiniteQueryOptions, PortfolioPositionModel, SortModel } from '@halo-common/models';
import { getPortfolioPositionSummary } from '@halo-data-sources/clients';
import { ApiPositionsMapper, PaginationMapper, PositionsMapper } from '@halo-data-sources/mappers';
import { PortfolioQueryKeyFactory } from '@halo-data-sources/queries';

export type PortfolioPositionSummaryQueryModel = PortfolioPositionQueryModel & {
  sort: Array<SortModel>;
};

export type GetPortfolioPositionSummaryInfiniteResult = {
  pagination: InfinitePaginationModel;
  positionSummaries: Array<PortfolioPositionModel>;
};

const getPortfolioPositionSummaryFn = async (
  payload: PortfolioPositionSummaryQueryModel,
  pageParam: InfinitePaginationModel,
) => {
  const updatedPageParam = { ...pageParam, page: pageParam.next };
  const request = PositionsMapper.toFetchPositionSummaryRequest(payload, updatedPageParam);

  const response = await getPortfolioPositionSummary(request);

  const positionSummaries = response.position_summaries.map(ApiPositionsMapper.toPosition);
  const pagination = PaginationMapper.toInfinitePaginationModel(response.pagination);

  return { positionSummaries, pagination };
};

export const usePortfolioPositionSummaryQuery = (
  payload: PortfolioPositionSummaryQueryModel,
  options?: InfiniteQueryOptions<GetPortfolioPositionSummaryInfiniteResult>,
) =>
  useHaloInfiniteQuery<GetPortfolioPositionSummaryInfiniteResult>({
    queryKey: PortfolioQueryKeyFactory.summaries(payload),
    queryFn: ({ pageParam }) => getPortfolioPositionSummaryFn(payload, pageParam),

    ...options,
  });
