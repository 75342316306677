import { SMAAllocationModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { postSMAAllocationRequest } from '@halo-data-sources/clients';
import { ApiSMAMapper } from '@halo-data-sources/mappers';
import { ApiCreateSMAAllocationModel } from '@halo-data-sources/models';
import { SMAQueryKeyFactory } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

const useSMAAllocationsSubmitMutationFn = async (
  options: Array<ApiCreateSMAAllocationModel>,
): Promise<Array<SMAAllocationModel>> => {
  const response = await postSMAAllocationRequest(options);
  return response.requests.map((model) => ApiSMAMapper.toSMAAllocationModel(model));
};

export const useSMAAllocationsSubmitMutation = (): UseMutationResult<
  Array<SMAAllocationModel>,
  Error,
  Array<ApiCreateSMAAllocationModel>
> => {
  const queryClient = useQueryClient();
  const { enqueueErrorEvent, enqueueSuccessEvent } = useSnackbar();

  return useMutation<Array<SMAAllocationModel>, Error, Array<ApiCreateSMAAllocationModel>>({
    mutationFn: useSMAAllocationsSubmitMutationFn,
    onSuccess: (data) => {
      const queryKey = SMAQueryKeyFactory.allocations();
      queryClient.setQueryData<Array<SMAAllocationModel>>(queryKey, (prev) => {
        if (!prev) return undefined;
        const updatedAllocations = [...prev, ...data];
        return updatedAllocations;
      });
      enqueueSuccessEvent({ message: 'SMA Request Submitted! ' });
    },
    onError: (e) => {
      enqueueErrorEvent({ message: e.message || translations.common.error });
    },
  });
};
