import { fixedIncomeManagerAtom, fixedIncomeSelectedAtom } from '@halo-atoms/fixedIncome';
import { CalendarFavoriteModel, CalendarModel } from '@halo-common/models';
import { deleteCalendarFavorite, postCalendarFavorite } from '@halo-data-sources/clients';
import { ApiCalendarsMapper } from '@halo-data-sources/mappers';
import { FixedIncomeQueryKeyFactory, GetFixedIncomeInfiniteResult } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { InfiniteData, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';

export type UseFixedIncomeFavoriteMutationParams = {
  id: number;
  favorite: boolean;
  assetIdentifier: string;
  assetIdentifierType: string;
};

const updateFixedIncomeFavoriteFn = async (payload: UseFixedIncomeFavoriteMutationParams) => {
  const { favorite, id } = payload;

  const response = !favorite ? await deleteCalendarFavorite(id) : await postCalendarFavorite(id);

  return ApiCalendarsMapper.toCalendarFavoriteModel(response);
};

export const useFixedIncomeFavoriteMutation = (): UseMutationResult<
  CalendarFavoriteModel,
  Error,
  UseFixedIncomeFavoriteMutationParams
> => {
  const queryClient = useQueryClient();

  const { query } = useAtomValue(fixedIncomeManagerAtom);

  const [selectedFixedIncome, setSelectedFixedIncome] = useAtom(fixedIncomeSelectedAtom);

  const fixedIncomeListQueryKey = FixedIncomeQueryKeyFactory.fixedIncomeList(query);
  const fixedIncomeQueryKey = FixedIncomeQueryKeyFactory.fixedIncome(selectedFixedIncome?.id);

  const { enqueueErrorEvent } = useSnackbar();

  const updateCalendarFavoriteCache = (id: number, isFavorite: boolean) => {
    setSelectedFixedIncome((prev) => {
      if (!prev) return prev;
      return { ...prev, isFavorite };
    });

    queryClient.setQueryData<CalendarModel>(fixedIncomeQueryKey, (prev) => {
      if (!prev) return prev;
      return { ...prev, isFavorite };
    });

    queryClient.setQueryData<InfiniteData<GetFixedIncomeInfiniteResult>>(fixedIncomeListQueryKey, (prev) => {
      if (!prev) return prev;

      const updatedPages = prev.pages.map((page) => {
        const updatedCalendars = page.calendars.map((calendar) => {
          if (calendar.id === id) return { ...calendar, isFavorite };
          return calendar;
        });

        return { ...page, calendars: updatedCalendars };
      });

      return { ...prev, pages: updatedPages };
    });
  };

  return useMutation<CalendarFavoriteModel, Error, UseFixedIncomeFavoriteMutationParams>({
    mutationFn: updateFixedIncomeFavoriteFn,
    onMutate: (payload) => {
      if (payload?.id) updateCalendarFavoriteCache(payload.id, payload.favorite);
    },
    onError: (_, payload) => {
      const message = `There was an error when trying to favorite ${payload.assetIdentifierType}: ${payload.assetIdentifier}, please refresh the page and try again.`;
      enqueueErrorEvent({ message });

      if (payload?.id) updateCalendarFavoriteCache(payload.id, !payload.favorite);
    },
  });
};
