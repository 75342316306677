import { IconName } from '@fortawesome/fontawesome-svg-core';
import { IconographyColors } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { SxProps } from '@mui/material';

export const getBackgroundColors = (color: string): SxProps<HaloTheme> => {
  if (color?.includes('#')) return { backgroundColor: (theme: HaloTheme) => theme.palette.info.background };
  if (color?.includes('grey')) return { backgroundColor: 'grey.200' };
  return { backgroundColor: (theme: HaloTheme) => theme.palette[color].background as IconographyColors };
};

export const getIconColor = (color: string): IconographyColors => {
  if (color?.includes('#')) return 'info.light';
  if (color?.includes('grey')) return 'grey.600';
  return `${color}.light` as IconographyColors;
};

export const getIconName = (icon: string): IconName => {
  switch (icon) {
    case 'money':
      return 'money-bill';
    case 'window-close-o':
      return 'window-close';
    default:
      return icon as IconName;
  }
};
