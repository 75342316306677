import { toStringOrBlob } from '@halo-common/utils';
import { getClientConfiguration, request } from '@halo-data-sources/clients';
import type {
  ApiComparisonModel,
  ApiCreateTermsheetRepoDocPayloadModel,
  ApiFiltersResponseModel,
  ApiTermsheetRepoConfigResponseModel,
  ApiTermsheetRepoDocModel,
  ApiTermsheetRepoDocsOptionsModel,
  ApiTermsheetRepoDocsResponseModel,
  ApiUpdateTermsheetRepoDocPayloadModel,
} from '@halo-data-sources/models';

const TERMSHEET_REPO_PATH = '/pretrade/termsheet-repo';

export const getTermsheetRepoConfig = async (): Promise<ApiTermsheetRepoConfigResponseModel> => {
  const clientConfiguration = await getClientConfiguration(TERMSHEET_REPO_PATH);

  const path = clientConfiguration.basePath;

  const response = await request<ApiTermsheetRepoConfigResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const getTermsheetRepoDocs = async (
  options: ApiTermsheetRepoDocsOptionsModel,
): Promise<ApiTermsheetRepoDocsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(TERMSHEET_REPO_PATH);

  const path = `${clientConfiguration.basePath}/query`;

  const response = await request<ApiTermsheetRepoDocsResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...options,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const getTermsheetRepoFilters = async (
  comparisons?: Array<ApiComparisonModel>,
): Promise<ApiFiltersResponseModel> => {
  const clientConfiguration = await getClientConfiguration(TERMSHEET_REPO_PATH);

  const path = `${clientConfiguration.basePath}/query/filters`;

  const response = await request<ApiFiltersResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      filters: [
        {
          field: 'termsheet_repo_docs.currency_id',
          type: 'OBJECTS',
          object_fields: ['currencies.name', 'currencies.iso_code', 'currencies.symbol'],
        },
        {
          field: 'termsheet_repo_docs.issuer_id',
          type: 'OBJECTS',
          object_fields: ['issuers.name'],
        },
        {
          field: 'termsheet_repo_docs.note_type',
          type: 'VALUES',
        },
        {
          field: 'termsheet_repo_docs.product_type',
          type: 'VALUES',
        },
      ],
      comparisons,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const createTermsheetRepoDoc = async (
  payload: ApiCreateTermsheetRepoDocPayloadModel,
): Promise<ApiTermsheetRepoDocModel> => {
  const clientConfiguration = await getClientConfiguration(TERMSHEET_REPO_PATH);

  const path = `${clientConfiguration.basePath}/doc`;

  const data = new FormData();

  Object.entries(payload).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      data.append(key, toStringOrBlob(value));
    }
  });

  data.append('_csrf_token', clientConfiguration.csrfToken as string);

  const response = await request<ApiTermsheetRepoDocModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    body: data,
  });

  return response;
};

export const updateTermsheetRepoDoc = async (
  id: string | number,
  payload: ApiUpdateTermsheetRepoDocPayloadModel,
): Promise<ApiTermsheetRepoDocModel> => {
  const clientConfiguration = await getClientConfiguration(TERMSHEET_REPO_PATH);

  const path = `${clientConfiguration.basePath}/doc/${encodeURIComponent(id)}`;

  const data = new FormData();

  Object.entries(payload).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      data.append(key, toStringOrBlob(value));
    }
  });

  data.append('_csrf_token', clientConfiguration.csrfToken as string);

  const response = await request<ApiTermsheetRepoDocModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    headers: { 'Content-Type': 'multipart/form-data' },
    body: data,
  });

  return response;
};

export const deleteTermsheetRepoDocs = async (ids: Array<number>): Promise<void> => {
  const clientConfiguration = await getClientConfiguration(TERMSHEET_REPO_PATH);

  const path = `${clientConfiguration.basePath}/delete`;

  const response = await request<void>(path, {
    ...clientConfiguration.requestInit,
    method: 'DELETE',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ids,
    }),
  });

  return response;
};
