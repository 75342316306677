import type { DynamicFiltersSideBarFiltersModel } from '@halo-atoms/common';
import type { TableStateModel } from '@halo-atoms/documentsRepository';
import { YEAR_MONTH_DAY_DATE_FORMAT } from '@halo-common/constants';
import { AssetIdentifierEnum } from '@halo-common/enums';
import type { DynamicTypeFiltersSideBarFilterValues } from '@halo-common/layouts';
import type { SortModel, TermsheetRepoConfigModel, TermsheetRepoDocModel } from '@halo-common/models';
import { ApiAssetIdentifierEnum, ApiComparisonOptionEnum, ApiComparisonTypeEnum } from '@halo-data-sources/enums';
import { mapApiDocumentModelToS3DocumentModel, PaginationMapper } from '@halo-data-sources/mappers';
import type {
  ApiComparisonModel,
  ApiCreateTermsheetRepoDocPayloadModel,
  ApiFiltersResponseModel,
  ApiFilterValueObjectsModel,
  ApiFilterValueValuesModel,
  ApiTermsheetRepoConfigResponseModel,
  ApiTermsheetRepoDocModel,
  ApiTermsheetRepoDocsOptionsModel,
  ApiTermsheetRepoDocsResponseModel,
  TermsheetRepoDocsResponseModel,
} from '@halo-data-sources/models';
import type { SearchFiltersState, TermsheetFormSchema } from '@halo-modules/app/documents/repository';
import { columns } from '@halo-modules/app/documents/repository/hooks/useTermsheetsDataGridColumns/columns';
import { DateTime } from 'luxon';

const isValidDate = (date: unknown): date is DateTime => date instanceof DateTime && date.isValid;

const sanitizeArray = (array: string[] | null | undefined): string[] => array?.filter(Boolean) || [];

const formatDate = (date: DateTime): string => date.toFormat(YEAR_MONTH_DAY_DATE_FORMAT);

export interface ApiTermsheetRepoMapperModel {
  toTermsheetRepoConfigModel(model: ApiTermsheetRepoConfigResponseModel): TermsheetRepoConfigModel;
  toTermsheetRepoDocModel(model: ApiTermsheetRepoDocModel): TermsheetRepoDocModel;
  toTermsheetRepoDocsModel(model: ApiTermsheetRepoDocsResponseModel): TermsheetRepoDocsResponseModel;
  toTermsheetRepoDocsFiltersModel(model: ApiFiltersResponseModel): DynamicTypeFiltersSideBarFilterValues;
  toTermsheetPayload(model: TermsheetFormSchema): ApiCreateTermsheetRepoDocPayloadModel;
  toTermsheetRepoDocsComparisons(pageState: {
    searchFilters: SearchFiltersState;
    sidebarFilters: DynamicFiltersSideBarFiltersModel;
  }): Array<ApiComparisonModel>;
  toTermsheetRepoDocsPayload(pageState: {
    tableState: TableStateModel;
    searchFilters: SearchFiltersState;
    sidebarFilters: DynamicFiltersSideBarFiltersModel;
  }): ApiTermsheetRepoDocsOptionsModel;
}

export const ApiTermsheetRepoMapper: ApiTermsheetRepoMapperModel = {
  toTermsheetRepoConfigModel(model) {
    const columns = [...model.columns].sort((a, b) => a.order - b.order);
    return {
      id: model.id,
      internalName: model.internal_name,
      isPublic: !!model.public,
      columns,
    };
  },
  toTermsheetRepoDocModel(model) {
    return {
      assetIdentifiers: model.asset_identifiers.map((assetIdentifier) => ({
        type: AssetIdentifierEnum[assetIdentifier.type],
        value: assetIdentifier.value,
      })),
      bondCode: model.bond_code,
      currencyId: model.currency_id,
      description: model.description,
      document: mapApiDocumentModelToS3DocumentModel(model.document),
      id: model.id,
      issueDate: model.issue_date,
      issuerId: model.issuer_id,
      maturityDate: model.maturity_date,
      noteType: model.note_type,
      notional: model.notional,
      productType: model.product_type,
    };
  },
  toTermsheetRepoDocsModel(model) {
    return {
      results: model.results.map((doc) => ApiTermsheetRepoMapper.toTermsheetRepoDocModel(doc)),
      pagination: PaginationMapper.toPaginationModel(model.pagination),
    };
  },
  toTermsheetRepoDocsFiltersModel(model) {
    const filterValues: DynamicTypeFiltersSideBarFilterValues = {};

    const currencies = (
      model?.filter_values?.find(
        (filter) => filter.field === 'termsheet_repo_docs.currency_id',
      ) as ApiFilterValueObjectsModel
    )?.objects
      ?.map((currency) => currency.object['termsheet_repo_docs.currency_id'])
      .filter(Boolean) as number[];
    filterValues.currency = currencies;

    const issuers = (
      model?.filter_values?.find(
        (filter) => filter.field === 'termsheet_repo_docs.issuer_id',
      ) as ApiFilterValueObjectsModel
    )?.objects
      ?.map((issuer) => issuer.object['termsheet_repo_docs.issuer_id'])
      .filter(Boolean) as number[];
    filterValues.issuer = issuers;

    const noteTypes = (
      model?.filter_values?.find(
        (filter) => filter.field === 'termsheet_repo_docs.note_type',
      ) as ApiFilterValueValuesModel
    )?.values
      ?.map((noteType) => noteType.value?.toLowerCase())
      .filter(Boolean);
    filterValues.noteType = noteTypes;

    const productTypes = (
      model?.filter_values?.find(
        (filter) => filter.field === 'termsheet_repo_docs.product_type',
      ) as ApiFilterValueValuesModel
    )?.values
      ?.map((productType) => productType.value)
      .filter(Boolean);
    filterValues.termsheetsProductType = productTypes;

    return filterValues;
  },
  toTermsheetPayload(fields) {
    const format = (value: DateTime | string | number | null | undefined): string | number | null => {
      if (!value) return null;
      if (value instanceof DateTime) return value.toFormat(YEAR_MONTH_DAY_DATE_FORMAT);
      if (typeof value === 'string') return value.trim() || null;
      return value;
    };

    const payload = {
      asset_identifiers: [],
      bond_code: format(fields.bondCode),
      currency_id: format(fields.currencyId),
      description: format(fields.description),
      issue_date: format(fields.issueDate),
      issuer_id: format(fields.issuerId),
      maturity_date: format(fields.maturityDate),
      note_type: format(fields.noteType?.toUpperCase()),
      notional: format(fields.notional),
      product_type: format(fields.productType),
      public: true,
    } as unknown as ApiCreateTermsheetRepoDocPayloadModel;

    if (fields.document instanceof File) {
      payload.document = fields.document;
    }

    Object.entries(fields['asset_identifiers'] || {}).forEach((identifier) => {
      const type = ApiAssetIdentifierEnum[identifier[0] as AssetIdentifierEnum];
      const value = format(identifier[1]) as string | null;

      if (!type || !value) return;

      payload.asset_identifiers.push({ type, value });
    });

    return payload;
  },
  toTermsheetRepoDocsComparisons({ searchFilters, sidebarFilters }) {
    const comparisons: Array<ApiComparisonModel> = [];

    const search = searchFilters.search?.trim();

    if (search) {
      comparisons.push({
        comparison_type: ApiComparisonTypeEnum.OR,
        comparisons: [
          'asset_identifiers.cusip',
          'asset_identifiers.isin',
          'asset_identifiers.fundservcode',
          'asset_identifiers.alphacode',
          'termsheet_repo_docs.bond_code',
          'termsheet_repo_docs.description',
          'termsheet_repo_docs.product_type',
        ].map((field) => ({
          field,
          op: ApiComparisonOptionEnum.ILIKE,
          value: search,
        })),
      });
    }

    if (isValidDate(searchFilters.issuedFrom)) {
      comparisons.push({
        field: 'termsheet_repo_docs.issue_date',
        op: ApiComparisonOptionEnum.GTE,
        value: formatDate(searchFilters.issuedFrom),
      });
    }

    if (isValidDate(searchFilters.issuedTo)) {
      comparisons.push({
        field: 'termsheet_repo_docs.issue_date',
        op: ApiComparisonOptionEnum.LTE,
        value: formatDate(searchFilters.issuedTo),
      });
    }

    if (isValidDate(searchFilters.maturesFrom)) {
      comparisons.push({
        field: 'termsheet_repo_docs.maturity_date',
        op: ApiComparisonOptionEnum.GTE,
        value: formatDate(searchFilters.maturesFrom),
      });
    }

    if (isValidDate(searchFilters.maturesTo)) {
      comparisons.push({
        field: 'termsheet_repo_docs.maturity_date',
        op: ApiComparisonOptionEnum.LTE,
        value: formatDate(searchFilters.maturesTo),
      });
    }

    const issuers = sanitizeArray(sidebarFilters.issuers?.map((issuer) => issuer.id));
    if (issuers.length > 0) {
      comparisons.push({
        comparison_type: ApiComparisonTypeEnum.OR,
        comparisons: issuers.map((issuer) => ({
          field: 'termsheet_repo_docs.issuer_id',
          op: ApiComparisonOptionEnum.EQ,
          value: issuer,
        })),
      });
    }

    const currencies = sanitizeArray(sidebarFilters.currencies);
    if (currencies.length > 0) {
      comparisons.push({
        comparison_type: ApiComparisonTypeEnum.OR,
        comparisons: currencies.map((currency) => ({
          field: 'termsheet_repo_docs.currency_id',
          op: ApiComparisonOptionEnum.EQ,
          value: currency,
        })),
      });
    }

    const productTypes = sanitizeArray(sidebarFilters.productTypes);
    if (productTypes.length > 0) {
      comparisons.push({
        comparison_type: ApiComparisonTypeEnum.OR,
        comparisons: productTypes.map((productType) => ({
          field: 'termsheet_repo_docs.product_type',
          op: ApiComparisonOptionEnum.EQ,
          value: productType,
        })),
      });
    }

    const noteTypes = sanitizeArray(sidebarFilters.noteTypes);
    if (noteTypes.length > 0) {
      comparisons.push({
        comparison_type: ApiComparisonTypeEnum.OR,
        comparisons: noteTypes.map((noteType) => ({
          field: 'termsheet_repo_docs.note_type',
          op: ApiComparisonOptionEnum.EQ,
          value: noteType.toUpperCase(),
        })),
      });
    }

    return comparisons;
  },
  toTermsheetRepoDocsPayload({ tableState, searchFilters, sidebarFilters }) {
    const sort = (tableState.sortBy
      ?.map(({ field, sort }) => ({
        field: columns.find((column) => column.field === field)?.id || field,
        direction: sort,
      }))
      .filter(({ field, direction }) => field && direction) || []) as Array<SortModel>;

    const comparisons = ApiTermsheetRepoMapper.toTermsheetRepoDocsComparisons({ searchFilters, sidebarFilters });

    const payload = {
      page: tableState.page,
      page_length: tableState.pageSize,
      sort,
      comparisons,
      comparison_type: ApiComparisonTypeEnum.AND,
    };

    return payload;
  },
};
