import { ReactElement } from 'react';

import { useMobileView } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';

export type PortfolioGenerateReportButtonProps = {
  handleClick: () => void;
  loading?: boolean;
};

export const PortfolioGenerateReportButton = ({
  handleClick,
  loading = false,
}: PortfolioGenerateReportButtonProps): ReactElement => {
  const isMobile = useMobileView();

  const icon = <Iconography iconName="file-chart-column" color="primary.main" />;

  return loading ? (
    <Skeleton width="100%" height={48} variant="rounded" />
  ) : (
    <LocalizedButton variant="outlined" size="large" onClick={handleClick} startIcon={icon} fullWidth={isMobile}>
      {translations.common.generateReport}
    </LocalizedButton>
  );
};
