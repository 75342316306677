export enum UserRoleEnum {
  AccountManager = 'AccountManager',
  AllocationEditor = 'AllocationEditor',
  Admin = 'Admin',
  AuctionAdmin = 'AuctionAdmin',
  BetaTester = 'BetaTester',
  Broker = 'Broker',
  BrokerAdmin = 'BrokerAdmin',
  Buyer = 'Buyer',
  CalendarAdmin = 'CalendarAdmin',
  CalendarManager = 'CalendarManager',
  CalendarNoteViewer = 'CalendarNoteViewer',
  CalendarOrderSender = 'CalendarOrderSender',
  DailyAdmin = 'DailyAdmin',
  DealableQuoteViewer = 'DealableQuoteViewer',
  DevPershingTester = 'DevPershingTester',
  DocumentUploader = 'DocumentUploader',
  DocumentRepositoryManager = 'DocumentRepositoryManager',
  EducationLock = 'EducationLock',
  ExecutingBroker = 'ExecutingBroker',
  GhostUser = 'GhostUser',
  HybridBuyer = 'HybridBuyer',
  HaloAdmin = 'HaloAdmin',
  IndicativeTermsheetViewer = 'IndicativeTermsheetViewer',
  Issuer = 'Issuer',
  IssuerOrderReceiver = 'IssuerOrderReceiver',
  LambdaAuthor = 'LambdaAuthor',
  JanneyOrderSender = 'JanneyOrderSender',
  PershingOrderSender = 'PershingOrderSender',
  PortfolioAdmin = 'PortfolioAdmin',
  PortfolioAnalysisAdmin = 'PortfolioAnalysisAdmin',
  PrimaryOrderSender = 'PrimaryOrderSender',
  Public = 'Public',
  QuoteViewer = 'QuoteViewer',
  ReadEducationHub = 'ReadEducationHub',
  RFQDashboardViewer = 'RFQDashboardViewer',
  RIAAdmin = 'RIAAdmin',
  SecondaryOrderSender = 'SecondaryOrderSender',
  SuperGhostUser = 'SuperGhostUser',
  TemplateDebugger = 'TemplateDebugger',
  TermsheetParser = 'TermsheetParser',
  TermsheetRequester = 'TermsheetRequester',
  TermSheetReviewer = 'TermSheetReviewer',
  UserManager = 'UserManager',
  WhiteLabelAdmin = 'WhiteLabelAdmin',
  WhiteLabelTFAUserOps = 'WhiteLabel2FAUserOps',
  WhiteLabelUserOps = 'WhiteLabelUserOps',
  WriteEducationHub = 'WriteEducationHub',
}

export enum UserPortfolioAccessEnum {
  NEW = 'NEW',
  BOTH = 'BOTH',
  LEGACY = 'LEGACY',
}

export enum UserAuctionAccessEnum {
  LEGACY = 'LEGACY',
  REACT = 'REACT',
}

export enum UserSaveAccessEnum {
  MANUAL_ONLY = 'MANUAL_ONLY',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum DynamicTypeFiltersSideBarFiltersEnum {
  calendarAssetType = 'calendarAssetType',
  calendarIssuer = 'calendarIssuer',
  calendarProductType = 'calendarProductType',
  calendarType = 'calendarType',
  currency = 'currency',
  issuer = 'issuer',
  myOffering = 'myOffering',
  nonCallPeriod = 'nonCallPeriod',
  noteType = 'noteType',
  principalProtection = 'principalProtection',
  productFeatures = 'productFeatures',
  settlementType = 'settlementType',
  termsheetsProductType = 'termsheetsProductType',
  termSlider = 'termSlider',
  underlyingCount = 'underlyingCount',
}

export enum CalendarFilterTypeEnum {
  MY_OFFERINGS = 'myOffering',
  SETTLEMENT = 'settlementType',
  CALENDAR_TYPE = 'calendarType',
  PRODUCT_TYPE = 'calendarProductType',
  NON_CALL_PERIOD = 'nonCallPeriod',
  PRODUCT_FEATURES = 'productFeatures',
  PRINCIPAL_PROTECTION = 'principalProtection',
  BASKETS = 'underlyingCount',
  TERM = 'termSlider',
  ISSUER = 'calendarIssuer',
  TOGGLE_BASKETS = 'calendarAssetType',
  CURRENCY = 'currency',
}
