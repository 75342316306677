import { ReactElement } from 'react';

import { anchorElAtom } from '@halo-atoms/common';
import { portfolioPositionPopoverAtom } from '@halo-atoms/portfolio';
import { translations } from '@halo-common/translations';
import { useDeletePositionMutation } from '@halo-data-sources/mutations';
import {
  HaloDataGrid,
  HaloDataGridProps,
  LocalizedButton,
  LocalizedTypography,
  mapNumberToLocalCurrency,
  Stack,
} from '@halodomination/halo-fe-common';
import { Popover } from '@mui/material';
import { useAtom } from 'jotai';

const slotProps = {
  paper: {
    sx: {
      p: 2,
      width: 548,
    },
  },
};

export const DeletePositionPopoverAnchor = 'DeletePositionPopoverAnchor';

export type DeletePositionPopoverProps = {
  currencyCode?: string;
  currencySymbol?: string;
};

export const DeletePositionPopover = ({ currencyCode, currencySymbol }: DeletePositionPopoverProps): ReactElement => {
  const { mutate: submitDelete } = useDeletePositionMutation();
  const [anchorElMap, setAnchorElMap] = useAtom(anchorElAtom);
  const [position, setPosition] = useAtom(portfolioPositionPopoverAtom);

  const accountOption = {
    id: position.notePositionId,
    notional: position.notional,
    ...position.accountOption,
  };
  const loading = !position.accountOption;
  const rows = !loading ? [accountOption] : undefined;

  const anchorEl = anchorElMap[DeletePositionPopoverAnchor];
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorElMap({ ...anchorElMap, [DeletePositionPopoverAnchor]: null });
    setPosition();
  };

  const handleDelete = () => {
    const positionId = position.notePositionId;
    if (positionId) submitDelete(positionId);
  };

  const commonCellProps = {
    editable: false,
    disableColumnMenu: true,
    sortable: true,
    flex: 1,
    resizable: true,
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      headerName: translations.common.household,
      field: 'advisee_name',
      valueGetter: (_, row) => row.account?.household,
    },
    {
      ...commonCellProps,
      headerName: translations.common.name,
      field: 'composite_name',
      flex: 3,
      valueGetter: (_, row) => {
        const name = row.account?.name ?? '--';
        const accountId = row.account?.accountId ?? '--';
        const custodian = row.account?.custodian?.name ?? '--';
        const source = row.account?.source ? ` - ${row.account?.source}` : '';
        return `${name} - ${custodian} #${accountId}${source}`;
      },
    },
    {
      ...commonCellProps,
      headerName: translations.common.notional,
      field: 'notional',
      align: 'right',
      valueGetter: (value: number) => {
        const maximumFractionDigits = value < 1 ? 2 : 0;
        return value && value !== 0
          ? mapNumberToLocalCurrency(value, { currency: currencyCode, maximumFractionDigits }, currencySymbol)
          : '-';
      },
    },
  ];

  return (
    <Popover
      slotProps={slotProps}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'right' }}
    >
      <Stack direction="column" spacing={2}>
        <LocalizedTypography variant="h6">Delete Allocation</LocalizedTypography>
        <HaloDataGrid density="compact" columns={columns} rows={rows} loading={loading} />
        <Stack direction="row" spacing={1} justify="flex-end">
          <LocalizedButton variant="outlined" onClick={handleClose}>
            {translations.common.cancel}
          </LocalizedButton>
          <LocalizedButton variant="contained" onClick={handleDelete}>
            {translations.common.confirmDelete}
          </LocalizedButton>
        </Stack>
      </Stack>
    </Popover>
  );
};
