import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiCreateSMAAllocationModel,
  ApiSMAAllocationResponseModel,
  ApiSMAAllocationsRequestModel,
  ApiSMAStrategyModel,
} from '@halo-data-sources/models';

const BASE_PATH = '/pretrade';
const SMA_PATH = `${BASE_PATH}/sma`;
const STRATEGIES_PATH = `${SMA_PATH}/strategies`;

export const fetchSMAStrategies = async (): Promise<Array<ApiSMAStrategyModel>> => {
  const clientConfiguration = await getClientConfiguration(STRATEGIES_PATH);

  const response = await request<Array<ApiSMAStrategyModel>>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const postSMAAllocationRequest = async (
  requests: Array<ApiCreateSMAAllocationModel>,
): Promise<ApiSMAAllocationResponseModel> => {
  const clientConfiguration = await getClientConfiguration(SMA_PATH);

  const url = `${clientConfiguration.basePath}/requests`;

  const response = await request<ApiSMAAllocationResponseModel>(url, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      sma_requests: requests,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const getSMAAllocationRequest = async (): Promise<ApiSMAAllocationsRequestModel> => {
  const clientConfiguration = await getClientConfiguration(SMA_PATH);

  const url = `${clientConfiguration.basePath}/requests`;

  const response = await request<ApiSMAAllocationsRequestModel>(url, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};
