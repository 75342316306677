import { ReactElement } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { useLiveTime } from '@halo-common/hooks';
import { ModalLayout } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';

import { OrderTicketReviewFooter } from './OrderTicketReviewFooter';
import { OrderTicketBulkOrderReceipt } from '../OrderTicketBulkOrderReceipt';
import { OrderTicketSingleOrderReceipt } from '../OrderTicketSingleOrderReceipt';

const modalContentProps = { sx: { paddingTop: 3 } };

export type OrderTicketReviewProps = {
  bulk?: boolean;
  onClose: () => void;
  onUpdateOrder?: () => void;
};

export const OrderTicketReview = ({ bulk = false, onClose, onUpdateOrder }: OrderTicketReviewProps): ReactElement => {
  const orderDate = useLiveTime()?.toISO();

  const selectedCalendar = useAtomValue(calendarSelectedAtom);

  const cusip = selectedCalendar?.cusip ?? '';
  const title = `Submit Indication ${cusip}`;
  const subtitle = `To submit the order, please click "Acknowledge & Submit Order"`;

  const receipt = bulk ? (
    <OrderTicketBulkOrderReceipt orderReceiptTime={orderDate} />
  ) : (
    <OrderTicketSingleOrderReceipt />
  );

  return (
    <ModalLayout
      title={title}
      subtitle={subtitle}
      ContentProps={modalContentProps}
      onClose={onClose}
      footer={<OrderTicketReviewFooter bulk={bulk} onUpdateOrder={onUpdateOrder} />}
    >
      {receipt}
    </ModalLayout>
  );
};
