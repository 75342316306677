import { OrdersDuck } from './OrdersDuck';
import * as selectors from './OrdersSelectors';
import * as thunks from './OrdersThunks';

export const OrdersSelectors = selectors;

export const OrdersActions = {
  ...OrdersDuck.actions,
  ...thunks,
};

export const OrdersDuckName = OrdersDuck.name;

export const OrdersReducer = OrdersDuck.reducer;

export * from './OrdersModel';
