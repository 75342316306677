import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiCreatePositionsResponseModel,
  ApiDocumentModel,
  ApiEditPositionPayload,
  ApiEditPositionResponse,
  ApiFetchPortfolioAssetsResponseModel,
  ApiFetchPortfolioOrderAutocallSummaryResponseModel,
  ApiFetchPortfolioOrderCouponSummaryResponseModel,
  ApiFetchPortfolioOrderEvaluationResponseModel,
  ApiFetchPortfolioOrderResponseModel,
  ApiFetchPortfolioOrderTermsheetPriceHistoryResponseModel,
  ApiFetchPortfolioOrderUnderlyingsResponseModel,
  ApiFetchPortfolioProvidersResponseModel,
  ApiFetchPostTradeNoteDetailsResponseModel,
  ApiGetPortfolioPositionsAggregationPayload,
  ApiGetPortfolioPositionsAggregationResponse,
  ApiGetPortfolioPositionsAggregationTemplatesResponseModel,
  ApiGetPositionsDetailResponse,
  ApiGetPositionsDetailsPayloadV2,
  ApiGetPositionsNoteFeatureResponse,
  ApiPortfolioAccountPerformanceReportPayload,
  ApiPortfolioAccountsBreakdownPayload,
  ApiPortfolioAccountsBreakdownResponseModel,
  ApiPortfolioCashflowEventsRequestModel,
  ApiPortfolioCashflowEventsResponseModel,
  ApiPortfolioPerformanceReportResponse,
  ApiPortfolioPositionDownloadPayload,
  ApiPortfolioPositionPerformanceReportPayload,
  ApiPortfolioPositionSummaryPayload,
  ApiPortfolioPositionSummaryResponse,
  ApiPortfolioPositionsEventsPayload,
  ApiPositionAllocationModel,
  ApiPostPortfolioEventsExcelPayload,
  ApiPostPortfolioExcelPayload,
  ApiPostPortfolioExcelResponseModel,
  ApiPostPositionsBySearchPayload,
  ApiPostPositionsBySearchResponseModel,
  ApiPostTradeEventsResponse,
  ApiPutPortfolioAssetsRequestModel,
  ApiQueuedPositionAllocationModel,
  ApiQueuedPositionNoteModel,
  ApiQueuedPositionNoteResponseModel,
  PutPortfolioAssetsResponseModel,
} from '@halo-data-sources/models';

const PORTFOLIO_PATH = '/issuer/v1/portfolio';
const POST_TRADE_PATH = '/posttrade/portfolio';
const PORTFOLIO_TERMSHEET_PATH = `${PORTFOLIO_PATH}/termsheet`;
const PORTFOLIO_EVENTS_PATH = `${POST_TRADE_PATH}/events`;
const POST_TRADE_ACCOUNTS = '/posttrade/accounts';
const POSTTRADE_V2_PATH = `/posttrade/nme`;
const POST_TRADE_POSITION = `${POST_TRADE_PATH}/position`;
const POST_TRADE_TERMSHEET = `${POST_TRADE_PATH}/termsheet`;

export const fetchPortfolioOrderTermsheetPriceHistory = async (
  id: number,
): Promise<ApiFetchPortfolioOrderTermsheetPriceHistoryResponseModel> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_TERMSHEET);

  const path = `${clientConfiguration.basePath}/price-history/${id}`;

  const response = await request<ApiFetchPortfolioOrderTermsheetPriceHistoryResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const fetchPortfolioOrderEvaluation = async (
  id: string,
): Promise<ApiFetchPortfolioOrderEvaluationResponseModel> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_TERMSHEET);

  const path = `${clientConfiguration.basePath}/valuations/${encodeURIComponent(id)}`;

  const response = await request<ApiFetchPortfolioOrderEvaluationResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const fetchPortfolioOrderUnderlyingPerformance = async (
  id: string,
): Promise<ApiFetchPortfolioOrderUnderlyingsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(PORTFOLIO_TERMSHEET_PATH);

  const path = `${clientConfiguration.basePath}/underlyings/${encodeURIComponent(id)}`;

  const response = await request<ApiFetchPortfolioOrderUnderlyingsResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const fetchPortfolioOrderUnderlyingPrices = async (
  id: string,
): Promise<ApiFetchPortfolioOrderUnderlyingsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(PORTFOLIO_TERMSHEET_PATH);

  const path = `${clientConfiguration.basePath}/underlyings/prices/${encodeURIComponent(id)}`;

  const response = await request<ApiFetchPortfolioOrderUnderlyingsResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const fetchPortfolioOrderCouponSummary = async (
  id: string,
  params?: string,
): Promise<ApiFetchPortfolioOrderCouponSummaryResponseModel> => {
  const clientConfiguration = await getClientConfiguration(PORTFOLIO_TERMSHEET_PATH);

  const path = `${clientConfiguration.basePath}/${encodeURIComponent(id)}/coupons${params}`;

  const response = await request<ApiFetchPortfolioOrderCouponSummaryResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const fetchPortfolioOrderAutocallSummary = async (
  id: string,
): Promise<ApiFetchPortfolioOrderAutocallSummaryResponseModel> => {
  const clientConfiguration = await getClientConfiguration(PORTFOLIO_TERMSHEET_PATH);

  const path = `${clientConfiguration.basePath}/${encodeURIComponent(id)}/autocalls`;

  const response = await request<ApiFetchPortfolioOrderAutocallSummaryResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const fetchPortfolioOrder = async (id: string): Promise<ApiFetchPortfolioOrderResponseModel> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_TERMSHEET);

  const path = `${clientConfiguration.basePath}/${encodeURIComponent(id)}`;

  const response = await request<ApiFetchPortfolioOrderResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const fetchPortfolioProviders = async (): Promise<ApiFetchPortfolioProvidersResponseModel> => {
  const clientConfiguration = await getClientConfiguration(PORTFOLIO_PATH);

  const response = await request<ApiFetchPortfolioProvidersResponseModel>(
    `${clientConfiguration.basePath}/assets/providers`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};

export const fetchPortfolioAssets = async (): Promise<ApiFetchPortfolioAssetsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(PORTFOLIO_PATH);

  const response = await request<ApiFetchPortfolioAssetsResponseModel>(`${clientConfiguration.basePath}/assets`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const fetchPortfolioProviderRedirect = async (name: string, type: string): Promise<string> => {
  const clientConfiguration = await getClientConfiguration(PORTFOLIO_PATH);

  const response = await request<string>(
    `${clientConfiguration.basePath}/assets/providers/redirect/${name.toLowerCase()}/${type.toLowerCase()}`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};

export const putPortfolioAssets = async (
  assets: ApiPutPortfolioAssetsRequestModel,
): Promise<PutPortfolioAssetsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(PORTFOLIO_PATH);
  const payload = {
    assets,
    _csrf_token: clientConfiguration.csrfToken,
  };

  const response = await request<PutPortfolioAssetsResponseModel>(`${clientConfiguration.basePath}/assets`, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  return response;
};

export const resyncPortfolioPositions = async (): Promise<void> => {
  const clientConfiguration = await getClientConfiguration(PORTFOLIO_PATH);

  await request<void>(`${clientConfiguration.basePath}/assets/positions`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });
};

export const resyncPortfolioAssets = async (): Promise<void> => {
  const clientConfiguration = await getClientConfiguration(PORTFOLIO_PATH);

  const payload = {
    _csrf_token: clientConfiguration.csrfToken,
  };

  await request<void>(`${clientConfiguration.basePath}/assets/update`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const postDisconnectPortfolioAssets = async (name: string, type: string): Promise<void> => {
  const clientConfiguration = await getClientConfiguration(PORTFOLIO_PATH);

  const payload = {
    _csrf_token: clientConfiguration.csrfToken,
  };

  await request(`${clientConfiguration.basePath}/assets/providers/unsubscribe/${name}/${type}`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const getPortfolioPositionsAggregation = async (
  data: ApiGetPortfolioPositionsAggregationPayload,
): Promise<ApiGetPortfolioPositionsAggregationResponse> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_PATH);

  const payload = {
    ...data,
    _csrf_token: clientConfiguration.csrfToken,
  };

  const response = await request<ApiGetPortfolioPositionsAggregationResponse>(
    `${clientConfiguration.basePath}/positions/aggregation`,
    {
      ...clientConfiguration.requestInit,
      method: 'POST',
      body: JSON.stringify(payload),
    },
  );

  return response;
};

export const getPortfolioPositionsAggregationTemplates =
  async (): Promise<ApiGetPortfolioPositionsAggregationTemplatesResponseModel> => {
    const clientConfiguration = await getClientConfiguration(POST_TRADE_PATH);

    const response = await request<ApiGetPortfolioPositionsAggregationTemplatesResponseModel>(
      `${clientConfiguration.basePath}/positions/aggregation`,
      {
        ...clientConfiguration.requestInit,
        method: 'GET',
      },
    );

    return response;
  };

export const getPortfolioPositions = async (
  detailsData: ApiGetPositionsDetailsPayloadV2,
): Promise<ApiGetPositionsDetailResponse> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_PATH);
  const detailsPayload = {
    ...detailsData,
    _csrf_token: clientConfiguration.csrfToken,
  };

  return await request<ApiGetPositionsDetailResponse>(`${clientConfiguration.basePath}/positions/details`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify(detailsPayload),
    failOnError: false,
  });
};

export const getPortfolioNoteFeatures = async (
  detailsData: ApiGetPositionsDetailsPayloadV2,
): Promise<ApiGetPositionsNoteFeatureResponse> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_PATH);
  const detailsPayload = {
    ...detailsData,
    _csrf_token: clientConfiguration.csrfToken,
  };

  return await request<ApiGetPositionsNoteFeatureResponse>(
    `${clientConfiguration.basePath}/positions/notes/aggregates`,
    {
      ...clientConfiguration.requestInit,
      method: 'POST',
      body: JSON.stringify(detailsPayload),
      failOnError: false,
    },
  );
};

export const createPositions = async (
  positions: Array<ApiPositionAllocationModel>,
): Promise<ApiCreatePositionsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_PATH);

  const response = await request<ApiCreatePositionsResponseModel>(`${clientConfiguration.basePath}/position`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      positions,
    }),
  });

  return response;
};

export const createQueuedPositions = async (
  positions: Array<ApiQueuedPositionAllocationModel>,
): Promise<Array<ApiQueuedPositionNoteModel>> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_PATH);

  const response = await request<ApiQueuedPositionNoteResponseModel>(`${clientConfiguration.basePath}/position/queue`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      positions,
    }),
  });

  return response.queued_note_positions;
};

export const getPositionTermsheet = async (id: number): Promise<string> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_TERMSHEET);
  const path = `${clientConfiguration.basePath}/download/${encodeURIComponent(id)}`;

  const response = await request<string>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const fetchPostTradeNoteDetails = async (id: string): Promise<ApiFetchPostTradeNoteDetailsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(PORTFOLIO_TERMSHEET_PATH);
  const clientTermsheetConfiguration = await getClientConfiguration(POST_TRADE_TERMSHEET);

  const options = { ...clientConfiguration.requestInit, method: 'GET', failOnError: false };

  const requests = [
    request<ApiFetchPortfolioOrderEvaluationResponseModel>(
      `${clientTermsheetConfiguration.basePath}/valuations/${encodeURIComponent(id)}`,
      options,
    ),
    request<ApiFetchPortfolioOrderResponseModel>(
      `${clientTermsheetConfiguration.basePath}/${encodeURIComponent(id)}`,
      options,
    ),
    request<ApiFetchPortfolioOrderUnderlyingsResponseModel>(
      `${clientConfiguration.basePath}/underlyings/${encodeURIComponent(id)}`,
      options,
    ),
    request<ApiFetchPortfolioOrderCouponSummaryResponseModel>(
      `${clientConfiguration.basePath}/${encodeURIComponent(id)}/coupons`,
      options,
    ),
    request<ApiFetchPortfolioOrderAutocallSummaryResponseModel>(
      `${clientConfiguration.basePath}/${encodeURIComponent(id)}/autocalls`,
      options,
    ),
    request<ApiFetchPortfolioOrderUnderlyingsResponseModel>(
      `${clientConfiguration.basePath}/underlyings/prices/${encodeURIComponent(id)}`,
      options,
    ),
  ];

  return await Promise.all(requests);
};

export const downloadPortfolioExcel = async (
  filters: ApiPostPortfolioExcelPayload,
): Promise<ApiPostPortfolioExcelResponseModel> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_PATH);

  const url = `${clientConfiguration.basePath}/positions/details/export`;

  const response = await request<ApiPostPortfolioExcelResponseModel>(url, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...filters,
    }),
  });

  return response;
};

export const downloadPortfolioEventsExcel = async (
  filters: ApiPostPortfolioEventsExcelPayload,
): Promise<ApiPostPortfolioExcelResponseModel> => {
  const clientConfiguration = await getClientConfiguration(PORTFOLIO_EVENTS_PATH);

  const url = `${clientConfiguration.basePath}/details/download/excel`;

  const response = await request<ApiPostPortfolioExcelResponseModel>(url, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...filters,
    }),
  });

  return response;
};

export const getPortfolioCashflowEvents = async (
  requestModel: ApiPortfolioCashflowEventsRequestModel,
): Promise<ApiPortfolioCashflowEventsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(PORTFOLIO_EVENTS_PATH);

  const url = `${clientConfiguration.basePath}/aggregation`;

  const response = await request<ApiPortfolioCashflowEventsResponseModel>(url, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...requestModel,
    }),
  });

  return response;
};

export const postPositionsBySearch = async (
  payload: ApiPostPositionsBySearchPayload,
): Promise<ApiPostPositionsBySearchResponseModel> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_PATH);

  const url = `${clientConfiguration.basePath}/positions/summarize/query`;

  const response = await request<ApiPostPositionsBySearchResponseModel>(url, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });

  return response;
};

export const getPortfolioPositionSummary = async (
  payload: ApiPortfolioPositionSummaryPayload,
): Promise<ApiPortfolioPositionSummaryResponse> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_PATH);

  const url = `${clientConfiguration.basePath}/positions/summarize`;

  const response = await request<ApiPortfolioPositionSummaryResponse>(url, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });

  return response;
};

export const getPostTradeV2Events = async (
  payload: ApiPortfolioPositionsEventsPayload,
): Promise<ApiPostTradeEventsResponse> => {
  const clientConfiguration = await getClientConfiguration(POSTTRADE_V2_PATH);
  const url = `${clientConfiguration.basePath}/events`;
  const response = await request<ApiPostTradeEventsResponse>(url, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });

  return response;
};

export const getPortfolioAccountPerformanceReport = async (
  payload: ApiPortfolioAccountPerformanceReportPayload,
): Promise<ApiPortfolioPerformanceReportResponse> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_PATH);

  const url = `${clientConfiguration.basePath}/portfolio-performance-report/download`;

  const response = await request<ApiPortfolioPerformanceReportResponse>(url, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({ ...payload, _csrf_token: clientConfiguration.csrfToken }),
  });

  return response;
};

export const getPortfolioPositionPerformanceReport = async (
  payload: ApiPortfolioPositionPerformanceReportPayload,
): Promise<ApiPortfolioPerformanceReportResponse> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_PATH);

  const url = `${clientConfiguration.basePath}/performance-report/download`;

  const response = await request<ApiPortfolioPerformanceReportResponse>(url, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({ ...payload, _csrf_token: clientConfiguration.csrfToken }),
  });

  return response;
};

export const getPortfolioAccountsBreakdown = async (
  payload: ApiPortfolioAccountsBreakdownPayload,
): Promise<ApiPortfolioAccountsBreakdownResponseModel> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_ACCOUNTS);

  const url = `${clientConfiguration.basePath}/breakdown`;

  const response = await request<ApiPortfolioAccountsBreakdownResponseModel>(url, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({ ...payload, _csrf_token: clientConfiguration.csrfToken }),
  });

  return response;
};

export const putPortfolioPosition = async ({
  position_id,
  ...payload
}: ApiEditPositionPayload): Promise<ApiEditPositionResponse> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_PATH);

  const url = `${clientConfiguration.basePath}/position/${encodeURIComponent(position_id)}`;

  const response = await request<ApiEditPositionResponse>(url, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({ ...payload, _csrf_token: clientConfiguration.csrfToken }),
  });

  return response;
};

export const deletePortfolioPosition = async (position_id: number): Promise<void> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_PATH);

  const url = `${clientConfiguration.basePath}/position/${encodeURIComponent(position_id)}`;

  const response = await request<void>(url, {
    ...clientConfiguration.requestInit,
    method: 'DELETE',
    body: JSON.stringify({ _csrf_token: clientConfiguration.csrfToken }),
  });

  return response;
};

export const fetchPortfolioPositionsDownloadDocument = async (
  payload: ApiPortfolioPositionDownloadPayload,
): Promise<ApiDocumentModel> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_POSITION);

  return await request<ApiDocumentModel>(`${clientConfiguration.basePath}/export`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });
};
