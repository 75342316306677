import { ReactElement, ReactNode } from 'react';

import { translations } from '@halo-common/translations';
import { EducationProgressBar } from '@halo-modules/app';
import { LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';

const flex0 = {
  flex: '0 0 auto',
};

const textWrapperSx = {
  flex: '1 1 auto',
  overflow: 'hidden',
};

const textSx = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textAlign: 'start',
  maxWidth: '100%',
};

const subTitleSx = {
  ...textSx,
  maxWidth: { lg: 1000, md: 650, sm: 450, xs: 150 },
};

const moduleHeaderSx = { p: 2 };

export type EducationModuleGroupHeaderProps = {
  children?: ReactNode;
  title: string | null;
  subtitle?: string | null;
  progress: number;
  onStart: (reason: 'start' | 'continue' | 'review') => void;
  variant?: 'default' | 'compact';
  actionButtonClassName?: string;
};

export const EducationModuleGroupHeader = ({
  children,
  title,
  subtitle,
  progress,
  onStart,
  variant = 'default',
  actionButtonClassName,
}: EducationModuleGroupHeaderProps): ReactElement => {
  const finished = progress >= 100;
  const started = progress > 0 && progress < 100;

  let actionText = translations.dashboard.educationCourses.startCourse;
  if (finished) actionText = translations.dashboard.educationCourses.reviewCourse;
  else if (started) actionText = translations.dashboard.educationCourses.continueCourse;

  const progresssSize = variant === 'compact' ? 'medium' : 'large';
  const actionVariant = finished ? 'outlined' : 'contained';
  const actionSize = variant === 'compact' ? 'small' : 'medium';

  const titleVariant = variant === 'compact' ? 'subtitle1' : 'h5';
  const subtitleVariant = variant === 'compact' ? 'body2' : 'body1';

  const handleStart = () => {
    if (finished) onStart('review');
    else if (started) onStart('continue');
    else onStart('start');
  };

  const progressBarLabelSx = {
    fontSize: variant === 'compact' ? 10 : undefined,
  };

  return (
    <Stack direction="row" sx={moduleHeaderSx} alignItems="center" spacing={2}>
      <EducationProgressBar progress={progress} size={progresssSize} sx={flex0} labelSx={progressBarLabelSx} />
      <Stack direction="column" sx={textWrapperSx} justifyContent="center">
        <LocalizedTypography sx={textSx} color="textPrimary" variant={titleVariant}>
          {title}
        </LocalizedTypography>
        {!!subtitle && (
          <LocalizedTypography sx={subTitleSx} color="textSecondary" variant={subtitleVariant}>
            {subtitle}
          </LocalizedTypography>
        )}
      </Stack>

      <Stack direction="row" sx={flex0} alignItems="center" spacing={3}>
        <LocalizedButton
          color="primary"
          variant={actionVariant}
          size={actionSize}
          onClick={handleStart}
          className={actionButtonClassName}
        >
          {actionText}
        </LocalizedButton>
        {children}
      </Stack>
    </Stack>
  );
};
