import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { NotificationModel } from '@halo-data-sources/models';
import { useMarkNotificationAsRead } from '@halo-data-sources/mutations';
import { getBackgroundColors, getIconColor, getIconName } from '@halo-modules/app/account';
import {
  Iconography,
  IconographyColors,
  LocalizedButton,
  LocalizedTypography,
  useCombinedStyling,
} from '@halodomination/halo-fe-common';
import { Paper, Stack, Typography } from '@mui/material';
import { useT } from '@transifex/react';

const sx = {
  padding: '8px 16px',
  marginBottom: 2,
  border: '1px solid',
  borderColor: 'grey.300',
};

const stackSx = {
  minHeight: 64,
};

const iconBoxSx = {
  width: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
};

const inactiveSx = {
  backgroundColor: 'grey.50',
  border: 'none',
  borderBottom: '1px solid',
  borderBottomColor: 'grey.300',
  borderRadius: 0,
};

export type UserProfileNotificationsListItemProps = {
  data: NotificationModel;
};

export const UserProfileNotificationsListItem = ({ data }: UserProfileNotificationsListItemProps): ReactElement => {
  const translator = useT();

  const { mutate, isPending } = useMarkNotificationAsRead();

  const { body, notificationType, redirectPath, id, isActive } = data;

  const translatedBody = translator(body);

  const handleMarkAsRead = () => mutate([id]);

  const iconName = getIconName(notificationType.icon);
  const iconColor = getIconColor(notificationType.color as IconographyColors);
  const backgroundSx = getBackgroundColors(notificationType.color as IconographyColors);
  const combinedDetailItemSx = useCombinedStyling(iconBoxSx, backgroundSx);

  const buttonSx = {
    alignSelf: 'center',
    m: 2,
    backgroundColor: !isActive ? 'white' : 'default',
  };

  const containerSx = {
    ...sx,
    ...(!isActive ? inactiveSx : undefined),
  };

  return (
    <Paper elevation={0} sx={containerSx}>
      <Stack direction="row" alignItems="center" sx={stackSx}>
        <Iconography sx={combinedDetailItemSx} iconName={iconName} color={iconColor} />
        <Stack sx={{ flex: 1, px: 2 }}>
          <LocalizedTypography
            variant="h6"
            component="div"
            color={isActive ? 'text.primary' : 'text.secondary'}
            sx={{ fontWeight: 'bold', mb: 0.5 }}
          >
            {notificationType.title}
          </LocalizedTypography>
          <Typography variant="body2" color="text.secondary" dangerouslySetInnerHTML={{ __html: translatedBody }} />
        </Stack>
        {isActive && (
          <LocalizedButton onClick={handleMarkAsRead} disabled={isPending}>
            {translations.profile.notifications.markAsReadButtonTitle}
          </LocalizedButton>
        )}
        <LocalizedButton
          href={redirectPath}
          target="_blank"
          variant="outlined"
          sx={buttonSx}
          endIcon={<Iconography iconName="arrow-right" />}
        >
          {translations.profile.notifications.detailsButtonTitle}
        </LocalizedButton>
      </Stack>
    </Paper>
  );
};
