import { ReactElement } from 'react';

import { ProductDetailPanelSection, ProductDetailPanelSectionField } from '@halo-common/layouts';
import type { V2NotePayoffDetailModel, V2NotePayoffModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { roundValue } from '@halo-common/utils';
import { v4 as uuid } from 'uuid';

const LOADING_FIELDS: Array<V2NotePayoffModel> = Array.from(Array(4), () => ({
  name: uuid(),
  description: '',
  value: '',
  when: '',
}));

export type V2PostTradeNoteDetailPayoffSectionProps = {
  solvableParameterPayoffs?: V2NotePayoffDetailModel['payoffs'];
  highlightedPayoffs?: V2NotePayoffDetailModel['payoffs'];
  loading: boolean;
};

export const V2PostTradeNoteDetailPayoffSection = ({
  solvableParameterPayoffs = [],
  highlightedPayoffs = [],
  loading,
}: V2PostTradeNoteDetailPayoffSectionProps): ReactElement | null => {
  const payoffs = loading ? LOADING_FIELDS : [...solvableParameterPayoffs, ...highlightedPayoffs];

  const sectionFields = payoffs.map(({ name, value, description, dynamicContent, when }) => {
    const roundedValue = roundValue(value);
    const composedDescription = `${roundedValue}${when ? ` ${when}` : ''}`;
    const descriptionText = description ? description : composedDescription;

    return (
      <ProductDetailPanelSectionField
        key={name}
        label={name}
        description={descriptionText}
        dynamicContent={dynamicContent}
        loading={loading}
      />
    );
  });

  return sectionFields.length ? (
    <ProductDetailPanelSection title={translations.pdm.common.payoffSection} loading={loading}>
      {sectionFields}
    </ProductDetailPanelSection>
  ) : null;
};
