import { AllocationModel, ExecutionOrderModel } from '@halo-common/models';
import { postExecCalendarAdminOrderCancellation } from '@halo-data-sources/clients';
import { ApiAllocationMapper, ApiExecOrderMapper } from '@halo-data-sources/mappers';
import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';

export type CancelExecCalendarAdminOrderMutationPayload = {
  allocationId: number;
  reason?: string;
};

export type CancelExecCalendarAdminOrderMutationResult = {
  allocation: AllocationModel;
  execOrder: ExecutionOrderModel;
  message: string;
};

const cancelExecCalendarAdminOrderMutationFn = async (payload?: CancelExecCalendarAdminOrderMutationPayload) => {
  if (!payload) return null;

  const response = await postExecCalendarAdminOrderCancellation(payload.allocationId, payload.reason);

  return {
    execOrder: ApiExecOrderMapper.toExecutionOrder(response.exec_order),
    allocation: ApiAllocationMapper.toAllocationModel(response.allocation),
    message: 'Successfully submitted cancellation request',
  };
};

export const useCancelExecCalendarAdminOrderMutation = (
  options?: UseMutationOptions<
    CancelExecCalendarAdminOrderMutationResult | null,
    Error,
    CancelExecCalendarAdminOrderMutationPayload | undefined
  >,
): UseMutationResult<
  CancelExecCalendarAdminOrderMutationResult | null,
  Error,
  CancelExecCalendarAdminOrderMutationPayload | undefined
> =>
  useMutation<
    CancelExecCalendarAdminOrderMutationResult | null,
    Error,
    CancelExecCalendarAdminOrderMutationPayload | undefined
  >({
    mutationFn: cancelExecCalendarAdminOrderMutationFn,
    ...options,
  });
