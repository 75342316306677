import { ReactElement, useEffect } from 'react';

import {
  bulkOrderTicketManagerAtom,
  bulkOrderTicketReceiptAtom,
  fixTimeoutErrorAtom,
  fixValidationErrorsAtom,
  orderTicketStepAtom,
  setFixTimeoutAtom,
} from '@halo-atoms/orderTicket';
import { ActionButton } from '@halo-common/components';
import { BulkOrderTicketFormFields } from '@halo-common/schemas';
import { PERSHING_DEFAULT_TIMEOUT_MESSAGE } from '@halo-data-sources/switches';
import { Stack } from '@halodomination/halo-fe-common';
import { Alert, Divider, Typography } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { AccountNotionalInput } from './AccountNotionalInput';
import { BulkAllocationSectionFooter } from './BulkAllocationSectionFooter';

const containerSx = {
  px: 3,
};

export const BULK_ALLOCATION_COLUMNS = ['Account Number', 'Quantity in Thousands'];

export const BulkAllocationSection = (): ReactElement => {
  const { control, trigger } = useFormContext<BulkOrderTicketFormFields>();

  const [orderTicketState, setOrderTicketState] = useAtom(bulkOrderTicketManagerAtom);
  const fixErrors = useAtomValue(fixValidationErrorsAtom);
  const setBulkOrderTicketReceipt = useSetAtom(bulkOrderTicketReceiptAtom);
  const handleNextStep = useSetAtom(orderTicketStepAtom.handleNextAtom);
  const setFixTimeout = useSetAtom(setFixTimeoutAtom);
  const fixTimeoutError = useAtomValue(fixTimeoutErrorAtom);

  const { fields, remove, append } = useFieldArray<BulkOrderTicketFormFields>({ name: 'allocations', control });

  const allocationError = fixTimeoutError ? PERSHING_DEFAULT_TIMEOUT_MESSAGE : undefined;
  const hasAllocationError = Boolean(allocationError);
  const hasFixErrors = Boolean(Object.keys(fixErrors).length);

  const handleDownloadTemplate = () => {
    const fileUrl = 'https://notes.haloinvesting.com/assets/public/bulk_allocation_upload_template.xls';
    window.open(fileUrl, '_blank');
  };

  const handleAddAllocation = () => {
    append({ accountOption: null });
  };

  const accountAllocationList = fields.map((field, index) => {
    const handleDelete = (): void => remove(index);
    return <AccountNotionalInput key={field.id} index={index} onDelete={handleDelete} />;
  });

  const allocationErrorContent = hasAllocationError ? (
    <Alert variant="outlined" severity="error">
      {allocationError}
    </Alert>
  ) : null;

  const validAllocations = orderTicketState.validatedAllocations;
  const invalidAllocations = orderTicketState.invalidAllocations;
  const pendingAllocations = orderTicketState.pendingValidateAllocations;
  const pendingAllocationLength = pendingAllocations.length || null;
  const validationFinished =
    fixTimeoutError || validAllocations.length + invalidAllocations.length === pendingAllocationLength;
  const validationSuccess = validAllocations.length === pendingAllocationLength;

  const validate = async () => await trigger();

  useEffect(() => {
    if (hasFixErrors) {
      void validate();
    }
  }, [fixErrors]);

  useEffect(() => {
    if (validationFinished) {
      setFixTimeout();
      setOrderTicketState();
      if (!validationSuccess) setBulkOrderTicketReceipt();
    }
  }, [validationFinished]);

  useEffect(() => {
    if (validationSuccess) handleNextStep();
  }, [validationSuccess]);

  return (
    <Stack direction="column" spacing={2} sx={containerSx}>
      <Stack direction="row" spacing={1} xs={12} sm={[2, 8, 2]} alignItems="center" justify="space-between">
        <Typography variant="h6" color="primary">
          Allocations
        </Typography>
        <Divider />
        <ActionButton variant="text" color="primary" onClick={handleDownloadTemplate}>
          Download Template
        </ActionButton>
      </Stack>
      {allocationErrorContent}
      <Stack direction="row" spacing={2} xs={12} sm={6} wrap="wrap">
        {accountAllocationList}
      </Stack>
      <BulkAllocationSectionFooter fields={fields} addAllocation={handleAddAllocation} />
    </Stack>
  );
};
