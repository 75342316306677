import { ReactElement } from 'react';

import { productDetailModalAnalyticsTabStateAtom } from '@halo-atoms/pdm';
import {
  ProductDetailModalAnalyticsRollingReturnsNoteSummaryLine,
  ProductDetailModalAnalyticsRollingReturnsSummary,
} from '@halo-common/layouts';
import { NoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useRollingReturnsSummaryQuery } from '@halo-data-sources/queries';
import { Collapsible, LoadingIndicator, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Box, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';

const titleSx = { pb: 2 };
const overlineSx = { color: 'text.secondary' };
const summaryContainerSx = { width: '100%' };
const descriptionBoxSx = {
  color: 'info.main',
  backgroundColor: 'info.background',
  py: 1,
  px: 4,
  m: 0,
  borderRadius: 1,
};

export type ProductDetailModalAnalyticsRollingReturnsNoteSummaryProps = {
  overline?: string;
  product?: NoteModel | null;
};

export const ProductDetailModalAnalyticsRollingReturnsNoteSummary = ({
  overline,
  product,
}: ProductDetailModalAnalyticsRollingReturnsNoteSummaryProps): ReactElement | null => {
  const state = useAtomValue(productDetailModalAnalyticsTabStateAtom);

  const productId = product?.id;

  const { underlyingModalStartDate } = state;

  const summaryQuery = useRollingReturnsSummaryQuery(productId, underlyingModalStartDate);
  const { data: summaryData, isPending, isError } = summaryQuery;

  if (isError) {
    return null;
  }

  if (isPending) {
    return (
      <Stack direction="column" spacing={1} alignItems="center" justify="center">
        <LoadingIndicator show />
        <LocalizedTypography variant="caption">{translations.pdm.preTrade.generatingNoteSummary}</LocalizedTypography>
      </Stack>
    );
  }

  const noteSummaryDescriptions = summaryData?.descriptions?.length ? (
    <Box component="ul" sx={descriptionBoxSx}>
      {summaryData?.descriptions.map((description) => (
        <Box key={description} component="li">
          <Typography variant="body2">{description}</Typography>
        </Box>
      ))}
    </Box>
  ) : null;

  const noteSummaryFeatures = summaryData?.features?.length
    ? summaryData.features.map((feature) => (
        <ProductDetailModalAnalyticsRollingReturnsNoteSummaryLine
          key={feature.label}
          label={feature.label}
          value={feature.value}
          loading={false}
        />
      ))
    : null;

  return (
    <Stack direction="row" spacing={2} xs={6}>
      <Stack direction="column">
        <Stack direction="column" sx={titleSx}>
          <Typography sx={overlineSx} variant="caption">
            {overline}
          </Typography>
          <LocalizedTypography variant="h6">{translations.pdm.preTrade.noteReturnSummary}</LocalizedTypography>
        </Stack>
        {noteSummaryDescriptions}
        <Stack direction="row" justify="space-evenly" sx={summaryContainerSx} wrap="wrap">
          <ProductDetailModalAnalyticsRollingReturnsSummary
            label={summaryData?.underlierReturn.label}
            value={summaryData?.underlierReturn.value}
            loading={isPending}
          />
          <ProductDetailModalAnalyticsRollingReturnsSummary
            label={summaryData?.noteReturn.label}
            value={summaryData?.noteReturn.value}
            loading={isPending}
          />
        </Stack>
      </Stack>
      <Collapsible title={translations.pdm.preTrade.seeTheMath} walkMeNamespace="wm-rr">
        <Stack direction="column">
          <ProductDetailModalAnalyticsRollingReturnsNoteSummaryLine
            label={summaryData?.underlierReturn.label}
            value={summaryData?.underlierReturn.value}
            loading={false}
            colorBasedOnValue
          />
          {noteSummaryFeatures}
        </Stack>
        <ProductDetailModalAnalyticsRollingReturnsNoteSummaryLine
          label={translations.pdm.preTrade.noteReturnAtMaturity}
          value={summaryData?.noteReturn.value}
          loading={false}
          ContainerProps={{ sx: { backgroundColor: 'grey.300' } }}
          LabelProps={{ fontWeight: 'bold' }}
          ValueProps={{ variant: 'h5' }}
          colorBasedOnValue
        />
      </Collapsible>
    </Stack>
  );
};
