import { DocumentModel, DocumentBucketModel } from '@halo-common/models';
import { ApiDocumentModel, ApiOtherDocumentModel } from '@halo-data-sources/models';

export const mapApiDocumentModelToS3DocumentModel = (model: ApiDocumentModel): DocumentBucketModel => ({
  filename: model?.filename,
  extension: model?.extension,
  id: model?.id,
  bucket: model?.bucket,
  path: model?.path,
  tmplink: model?.tmplink,
  token: model?.token,
});

export const mapApiOtherDocumentModelToOtherDocumentModel = (model: ApiOtherDocumentModel): DocumentModel => ({
  document: mapApiDocumentModelToS3DocumentModel(model.document),
  id: model?.id,
  name: model?.name,
});
