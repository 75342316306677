import { UserRelationshipManagerModel } from '@halo-common/models';
import { ApiRelationshipManagerModel } from '@halo-data-sources/models';
import { isInDevBuildEnv } from '@halodomination/halo-fe-common';

export const ApiRelationshipManagerMapper = {
  toRelationshipManager: (
    manager: ApiRelationshipManagerModel | string | null,
  ): UserRelationshipManagerModel | null => {
    try {
      if (!manager) return null;

      const parsedRelationshipManager = typeof manager === 'string' ? JSON.parse(manager) : manager;

      return {
        id: parsedRelationshipManager?.id,
        name: parsedRelationshipManager?.name?.toString(),
        phone: parsedRelationshipManager?.phone?.toString(),
        email: parsedRelationshipManager?.email?.toString(),
      };
    } catch (e) {
      if (isInDevBuildEnv()) console.log(e);
      return null;
    }
  },
};
