import { ComplianceApprovalStatusEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { ApiComparisonOptionEnum, ApiComparisonTypeEnum } from '@halo-data-sources/enums';
import { ApiComparisonModel } from '@halo-data-sources/models';
import { AdminCalendarAllocationsQueryFilters } from '@halo-data-sources/queries';
import { DateTime } from 'luxon';

export const ComplianceApprovalMapper = {
  toStatusQueryComparisons: (status: ComplianceApprovalStatusEnum) => {
    const now = DateTime.local().toISODate();

    const comparisons: Array<ApiComparisonModel> = [];

    if (status === ComplianceApprovalStatusEnum.pending) {
      comparisons.push({
        field: 'calendar_allocations.approved',
        op: ApiComparisonOptionEnum.EQ,
        value: null,
      });
      comparisons.push({
        field: 'calendar_allocations.status',
        op: ApiComparisonOptionEnum.IN,
        value: ['pending', 'accepted'],
      });
      comparisons.push({
        field: 'calendar_notes.settle_date::date',
        op: ApiComparisonOptionEnum.GTE,
        value: now,
      });
    } else if (status === ComplianceApprovalStatusEnum.confirmed) {
      comparisons.push({
        field: 'calendar_allocations.approved',
        op: ApiComparisonOptionEnum.EQ,
        value: true,
      });
      comparisons.push({
        field: 'calendar_allocations.status',
        op: ApiComparisonOptionEnum.IN,
        value: ['pending', 'accepted', 'canceled', 'filled'],
      });
      comparisons.push({
        field: 'calendar_notes.settle_date::date',
        op: ApiComparisonOptionEnum.GTE,
        value: now,
      });
    } else if (status === ComplianceApprovalStatusEnum.denied) {
      comparisons.push({
        field: 'calendar_allocations.approved',
        op: ApiComparisonOptionEnum.EQ,
        value: false,
      });
      comparisons.push({
        field: 'calendar_allocations.status',
        op: ApiComparisonOptionEnum.IN,
        value: ['pending', 'accepted', 'canceled', 'filled'],
      });
      comparisons.push({
        field: 'calendar_notes.settle_date::date',
        op: ApiComparisonOptionEnum.GTE,
        value: now,
      });
    } else if (status === ComplianceApprovalStatusEnum.available) {
      comparisons.push({
        field: 'calendar_allocations.status',
        op: ApiComparisonOptionEnum.IN,
        value: ['pending', 'accepted', 'canceled', 'filled'],
      });
      comparisons.push({
        field: 'calendar_notes.settle_date::date',
        op: ApiComparisonOptionEnum.GTE,
        value: now,
      });
    } else {
      comparisons.push({
        field: 'calendar_allocations.status',
        op: ApiComparisonOptionEnum.IN,
        value: ['pending', 'accepted', 'canceled', 'filled'],
      });
      comparisons.push({
        field: 'calendar_notes.settle_date::date',
        op: ApiComparisonOptionEnum.LTE,
        value: now,
      });
    }

    return comparisons;
  },
  toCalendarFiltersQueryComparisons: (filters: AdminCalendarAllocationsQueryFilters) => {
    const hasExpirationDates = filters.expirationStartDate && filters.expirationEndDate;

    const comparisons: Array<ApiComparisonModel> = [
      {
        field: 'calendar_allocations.passed_rules_engine',
        op: ApiComparisonOptionEnum.EQ,
        value: true,
      },
      {
        field: 'calendar_allocations.fix_id',
        op: ApiComparisonOptionEnum.NEQ,
        value: null,
      },
      {
        field: 'calendar_page.id',
        op: ApiComparisonOptionEnum.EQ,
        value: filters.calendarPageId,
      },
    ];

    if (filters.ip) {
      comparisons.push({
        field: 'computed.full_name',
        op: ApiComparisonOptionEnum.ILIKE,
        value: filters.ip,
      });
    }

    if (filters.cusip) {
      comparisons.push({
        field: 'calendar_notes.cusip',
        op: ApiComparisonOptionEnum.ILIKE,
        value: filters.cusip,
      });
    }

    if (filters.account) {
      comparisons.push({
        comparison_type: ApiComparisonTypeEnum.OR,
        comparisons: [
          {
            field: 'accounts.name',
            op: ApiComparisonOptionEnum.ILIKE,
            value: filters.account,
          },
          {
            field: 'accounts.account_identifier',
            op: ApiComparisonOptionEnum.ILIKE,
            value: filters.account,
          },
        ],
      });
    }

    if (filters.issuer && filters.issuer !== translations.common.all) {
      comparisons.push({
        field: 'issuers.id',
        op: ApiComparisonOptionEnum.EQ,
        value: filters.issuer,
      });
    }

    if (hasExpirationDates) {
      comparisons.push({
        field: 'calendar_notes.expiration_date::date',
        op: ApiComparisonOptionEnum.GTE,
        value: filters.expirationStartDate?.toISODate(),
      });
      comparisons.push({
        field: 'calendar_notes.expiration_date::date',
        op: ApiComparisonOptionEnum.LTE,
        value: filters.expirationEndDate?.toISODate(),
      });
    }

    return comparisons;
  },
  toCalendarTotalsQueryComparisons: (filters: AdminCalendarAllocationsQueryFilters) => {
    const pendingList = ComplianceApprovalMapper.toStatusQueryComparisons(ComplianceApprovalStatusEnum.pending);
    const confirmedList = ComplianceApprovalMapper.toStatusQueryComparisons(ComplianceApprovalStatusEnum.confirmed);
    const deniedList = ComplianceApprovalMapper.toStatusQueryComparisons(ComplianceApprovalStatusEnum.denied);
    const activeList = ComplianceApprovalMapper.toStatusQueryComparisons(ComplianceApprovalStatusEnum.available);
    const archivedList = ComplianceApprovalMapper.toStatusQueryComparisons(ComplianceApprovalStatusEnum.archived);

    const comparisons = ComplianceApprovalMapper.toCalendarFiltersQueryComparisons(filters);

    return [
      { comparisons: [...comparisons, ...pendingList] },
      { comparisons: [...comparisons, ...confirmedList] },
      { comparisons: [...comparisons, ...deniedList] },
      { comparisons: [...comparisons, ...activeList] },
      { comparisons: [...comparisons, ...archivedList] },
    ];
  },
  toCalendarListQueryComparisons: (filters: AdminCalendarAllocationsQueryFilters) => {
    const statusComparisons = ComplianceApprovalMapper.toStatusQueryComparisons(filters.status);
    const filterComparisons = ComplianceApprovalMapper.toCalendarFiltersQueryComparisons(filters);

    const comparisons: Array<ApiComparisonModel> = [...statusComparisons, ...filterComparisons];

    return comparisons;
  },
  toIssuersQueryComparisons: (filters: AdminCalendarAllocationsQueryFilters) => {
    const parsedFilters = { ...filters, issuer: undefined };

    const statusComparisons = ComplianceApprovalMapper.toStatusQueryComparisons(filters.status);
    const filterComparisons = ComplianceApprovalMapper.toCalendarFiltersQueryComparisons(parsedFilters);

    const comparisons: Array<ApiComparisonModel> = [...statusComparisons, ...filterComparisons];

    return comparisons;
  },
};
