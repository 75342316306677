export enum AccountTypeEnum {
  Cash = '1',
  DVP = '0',
  Margin = '2',
}

export enum AccountDesignationEnum {
  SMA = 'SMA',
  Brokerage = 'Brokerage',
  Advisory = 'Advisory',
}
