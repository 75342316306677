import { UserAlertModel } from '@halo-common/models';
import { getUserAlerts } from '@halo-data-sources/clients';
import { ApiUserAlertsMapper } from '@halo-data-sources/mappers';
import { UserQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const fetchUserAlertsQueryFn = async () => {
  const response = await getUserAlerts();

  return response.map(ApiUserAlertsMapper.toUserAlert);
};

export const useUserAlertsQuery = (): UseQueryResult<Array<UserAlertModel>, Error> =>
  useQuery<Array<UserAlertModel>, Error>({
    queryKey: UserQueryKeyFactory.alerts(),
    queryFn: () => fetchUserAlertsQueryFn(),
  });
