import { ReactElement, ReactNode } from 'react';

import { productDetailModalAnalyticsTabStateAtom } from '@halo-atoms/pdm';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Skeleton, Typography } from '@mui/material';
import { ITranslateParams } from '@transifex/native';
import { useAtomValue } from 'jotai';

const titleSx = { pb: 2 };
const overlineSx = { color: 'text.secondary' };

export type ProductDetailModalAnalyticsRollingReturnsStatisticsTableLayoutProps = {
  children?: ReactNode;
  title?: string;
  loading?: boolean;
  dynamicContent?: ITranslateParams;
};

export const ProductDetailModalAnalyticsRollingReturnsStatisticsTableLayout = ({
  children,
  title,
  loading = false,
  dynamicContent,
}: ProductDetailModalAnalyticsRollingReturnsStatisticsTableLayoutProps): ReactElement => {
  const state = useAtomValue(productDetailModalAnalyticsTabStateAtom);

  if (loading) {
    return (
      <Stack direction="column">
        <Stack direction="column" spacing={1} sx={titleSx}>
          <Skeleton variant="rounded" width={160} height={14} />
          <Skeleton variant="rounded" width={575} height={22} />
        </Stack>
        {children}
      </Stack>
    );
  }

  const { dateRangeText } = state;

  const overline = dateRangeText ? (
    <Typography sx={overlineSx} variant="overline">
      {dateRangeText}
    </Typography>
  ) : null;

  return (
    <Stack direction="column">
      <Stack direction="column" sx={titleSx}>
        {overline}
        <LocalizedTypography variant="h6" dynamicContent={dynamicContent}>
          {title}
        </LocalizedTypography>
      </Stack>
      {children}
    </Stack>
  );
};
