import { ConditionalField, Input, Radio, RadioGroup } from '@halo-common/components/FormBuilder';
import { DocusignBankTypeEnum } from '@halo-common/enums/docusign';
import { translations } from '@halo-common/translations';
import { OnboardingSuitabilityForm, SuitabilityStepModel } from '@halo-modules/app/v2/onboarding';
import { Stack } from '@mui/material';

const suitability = translations.onboarding.suitability;

const radioGroupSx = { pl: 3 };

const FirmEntityForm = () => (
  <OnboardingSuitabilityForm>
    <Stack spacing={1}>
      <Input
        type="checkbox"
        label={suitability.registeredInvestmentAdvisor}
        name="firm_details.entity_type_checkbox_ria"
      />
      <Input type="checkbox" label={suitability.familyOffice} name="firm_details.entity_type_checkbox_family_office" />
      <Input type="checkbox" label={suitability.brokerDealer} name="firm_details.entity_type_checkbox_broker" />
      <Input type="checkbox" label={suitability.bank} name="firm_details.entity_type_checkbox_bank" />
      <ConditionalField field="firm_details.entity_type_checkbox_bank">
        <RadioGroup name="firm_details.entity_type_bank_type_radio" sx={radioGroupSx}>
          <Radio label={suitability.federalRegulatedBank} value={DocusignBankTypeEnum.FEDERAL_BANK} />
          <Radio label={suitability.stateRegulatedBank} value={DocusignBankTypeEnum.STATE_BANK} />
        </RadioGroup>
      </ConditionalField>
    </Stack>
  </OnboardingSuitabilityForm>
);

export const entityTypeStep: SuitabilityStepModel = {
  id: '1-2',
  title: suitability.whatTypeOfEntityIsThisFirm,
  subtitle: suitability.selectAllThatApply,
  component: <FirmEntityForm />,
  isCompleted: (formData) => {
    const firmDetails = formData?.firm_details || {};
    return Boolean(
      firmDetails.entity_type_checkbox_ria ||
        firmDetails.entity_type_checkbox_family_office ||
        firmDetails.entity_type_checkbox_broker ||
        (firmDetails.entity_type_checkbox_bank && firmDetails.entity_type_bank_type_radio),
    );
  },
};
