import { ReactElement } from 'react';

import { AnnuityOrderModel } from '@halo-common/models';
import { Skeleton, Typography } from '@mui/material';

export type PostTradeAnnuityDetailModalHeaderNameProps = {
  product?: AnnuityOrderModel | null;
  loading?: boolean;
};

export const PostTradeAnnuityDetailModalHeaderName = ({
  product,
  loading = true,
}: PostTradeAnnuityDetailModalHeaderNameProps): ReactElement => {
  if (loading) return <Skeleton width={200} height={80} />;

  const name = product?.contractValues?.productName;

  return (
    <Typography variant="h2" color="textPrimary">
      {name}
    </Typography>
  );
};
