import { ReactElement } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { LexicalTypography } from '@halo-common/components';
import { Chip, SxProps } from '@mui/material';
import { useAtom } from 'jotai';
import { startCase } from 'lodash';

export type CalendarProductTypeChipsProps = {
  tag: string;
  sx: SxProps;
};

export const CalendarProductTypeChips = ({ tag, sx }: CalendarProductTypeChipsProps): ReactElement | null => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);

  const handleDynamicProductTypeChipDelete = (value: string) => {
    const updatedProductTypes = sideBarFilters.productTypes.filter((type) => type !== value);
    setSideBarFilters({ tag, productTypes: updatedProductTypes });
  };

  if (!sideBarFilters.productTypes.length) return null;

  const chips = sideBarFilters.productTypes.map((productType) => {
    const formattedLabel = startCase(productType);
    const formattedLabelNode = <LexicalTypography variant="caption">{formattedLabel}</LexicalTypography>;
    return (
      <Chip
        sx={sx}
        size="small"
        variant="outlined"
        key={productType}
        label={formattedLabelNode}
        onDelete={() => handleDynamicProductTypeChipDelete(productType)}
      />
    );
  });

  return <>{chips}</>;
};
