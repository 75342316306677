import { ReactElement } from 'react';

import { Skeleton, Stack } from '@mui/material';

export interface NotesCarouselSkeletonProps {
  ribbon?: boolean;
  width?: number;
}

export const NotesCarouselSkeleton = ({ ribbon = false, width = 360 }: NotesCarouselSkeletonProps): ReactElement => (
  <Stack width="100%" px={1} direction="column" spacing={2}>
    <Skeleton variant="rounded" width={width} height={300} />
    {ribbon ? <Skeleton variant="rounded" width={width} height={48} /> : null}
  </Stack>
);

export const renderNoteCardSkeletons = ({ items = 4, width = 360, ribbon = false } = {}): JSX.Element[] =>
  [...Array(items)].map((_, index) => <NotesCarouselSkeleton width={width} ribbon={ribbon} key={index} />);
