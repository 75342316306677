import {
  AccountModel,
  AllocationModel,
  CalendarModel,
  CalendarPendingModel,
  FixOrderModel,
  OrganizationModel,
  PaginationModel,
} from '@halo-common/models';
import { ApiPutAddRemoveQueryParams } from '@halo-data-sources/models';
import { AdminCalendarStatusFilter, PershingAdminQueryParams } from '@halo-modules/admin';
import { CalendarPageQueryParams, CalendarQueryParams, CalendarSortingOptions } from '@halo-stores/Calendar';
import { QueuedFixOrder } from '@halo-stores/Fix';
import { HaloSliceState } from '@halo-stores/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

export enum PershingStatusEnum {
  failureDownloadPershingCalendarCsv = 'failureDownloadPershingCalendarCsv',
  failureUpdatePershingCalendar = 'failureUpdatePershingCalendar',
  failureFetchCalendar = 'failureFetchCalendar',
  failureUploadCalendar = 'failureUploadCalendar',
  failureAcceptAllocationsFixOrderApproval = 'failureAcceptAllocationsFixOrderApproval',
  failureRejectAllocationsFixOrderApproval = 'failureRejectAllocationsFixOrderApproval',
  failureFetchExecutionHubCalendars = 'failureFetchExecutionHubCalendars',
  failureFetchComplianceApprovalCalendars = 'failureFetchComplianceApprovalCalendars',
  failureFetchLoadingDockCalendars = 'failureFetchLoadingDockCalendars',
  failureFetchCalendarPage = 'failureFetchCalendarPage',
  failureSubmitAddRemoveCalendars = 'failureSubmitAddRemoveCalendars',
  failureSubmitFixOrder = 'failureSubmitFixOrder',
  failureFillFixOrder = 'failureFillFixOrder',
  failureBulkFillFixOrder = 'failureBulkFillFixOrder',
  failureFetchFixOrderAndAllocation = 'failureFetchFixOrderAndAllocation',
  failureFillOrderWebsocketEvent = 'failureFillOrderWebsocketEvent',
  idle = 'idle',
  requestBulkFillFixOrder = 'requestBulkFillFixOrder',
  requestDownloadPershingCalendarCsv = 'requestDownloadPershingCalendarCsv',
  requestUpdatePershingCalendar = 'requestUpdatePershingCalendar',
  requestFetchCalendar = 'requestFetchCalendar',
  requestUploadCalendar = 'requestUploadCalendar',
  requestAcceptAllocationsFixOrderApproval = 'requestAcceptAllocationsFixOrderApproval',
  requestRejectAllocationsFixOrderApproval = 'requestRejectAllocationsFixOrderApproval',
  requestFetchExecutionHubCalendars = 'requestFetchExecutionHubCalendars',
  requestFetchComplianceApprovalCalendars = 'requestFetchComplianceApprovalCalendars',
  requestFetchLoadingDockCalendars = 'requestFetchLoadingDockCalendars',
  requestFetchCalendarPage = 'requestFetchCalendarPage',
  requestSubmitAddRemoveCalendars = 'requestSubmitAddRemoveCalendars',
  requestSubmitFixOrder = 'requestSubmitFixOrder',
  requestFetchFixOrderAndAllocation = 'requestFetchFixOrderAndAllocation',
  successDownloadPershingCalendarCsv = 'successDownloadPershingCalendarCsv',
  successUpdatePershingCalendar = 'successUpdatePershingCalendar',
  successFetchCalendar = 'successFetchCalendar',
  successUploadCalendar = 'successUploadCalendar',
  successAcceptAllocationsFixOrderApproval = 'successAcceptAllocationsFixOrderApproval',
  successRejectAllocationsFixOrderApproval = 'successRejectAllocationsFixOrderApproval',
  successFetchExecutionHubCalendars = 'successFetchExecutionHubCalendars',
  successFetchComplianceApprovalCalendars = 'successFetchComplianceApprovalCalendars',
  successFetchLoadingDockCalendars = 'successFetchLoadingDockCalendars',
  successFetchCalendarPage = 'successFetchCalendarPage',
  successSubmitAddRemoveCalendars = 'successSubmitAddRemoveCalendars',
  successSubmitFixOrder = 'successSubmitFixOrder',
  successInitiateFixOrderValidation = 'successInitiateFixOrderValidation',
  successFetchFixOrder = 'successFetchFixOrder',
  successFillFixOrder = 'successFillFixOrder',
  successBulkFillFixOrder = 'successBulkFillFixOrder',
  successSubmitOrderRejection = 'successSubmitOrderRejection',
  successFetchFixOrderAndAllocation = 'successFetchFixOrderAndAllocation',
  successSubmitOrderWebsocketEvent = 'successSubmitOrderWebsocketEvent',
  successFillOrderWebsocketEvent = 'successFillOrderWebsocketEvent',
  successCancelOrderWebsocketEvent = 'successCancelOrderWebsocketEvent',
  websocketTimeout = 'websocketTimeout',
}

export type PershingStatus = keyof typeof PershingStatusEnum;

export type PershingCalendarStatusMap = {
  available: Array<CalendarModel>;
  confirmed: Array<CalendarModel>;
  denied: Array<CalendarModel>;
  filled: Array<CalendarModel>;
  archived: Array<CalendarModel>;
  pending: Array<CalendarModel>;
};

export type PershingCalendarStatusMapKey = keyof PershingCalendarStatusMap;

export type PershingCalendarMap = PershingCalendarStatusMap & {
  [key: string]: Array<CalendarModel>;
};

export type PershingAllocationReference = {
  id: number;
  message?: string | null;
};

export type PershingTotalsMap = {
  [key: string]: number;
  available: number;
  confirmed: number;
  denied: number;
  filled: number;
  archived: number;
  pending: number;
};

export type PershingPendingCalendarPairing = {
  calendars: Array<CalendarPendingModel>;
  filteredTotal?: number | null;
  total: number;
};

export type PershingCalendarPairing = {
  calendars: Array<CalendarModel>;
  filteredTotal?: number | null;
  total?: number;
};

export type PershingCalendarBatchResultMap = {
  calendarMap: PershingCalendarStatusMap;
  calendarTotals: PershingTotalsMap;
};

export type PershingCalendarPageBatchResultMap = {
  calendarMap: PershingCalendarStatusMap;
  calendarTotals: PershingTotalsMap;
  allocationTotals: PershingTotalsMap;
};

export type SelectCalendarActionPayload = PayloadAction<{ calendar: CalendarModel } | undefined>;

export type DownloadPershingCalendarCsvThunkAction = {
  organization?: OrganizationModel | null;
  route: 'products/pending' | 'products' | 'orders' | 'compliance';
  params?: PershingAdminQueryParams;
};

export type FetchAdminCalendarsThunkAction = CalendarQueryParams | undefined;

export type FetchLoadingDockCalendarsPayload = {
  query: CalendarQueryParams;
  sorting?: CalendarSortingOptions;
};

export type FetchLoadingDockCalendarsThunkAction = {
  available?: FetchLoadingDockCalendarsPayload;
  pending?: FetchLoadingDockCalendarsPayload;
  archived?: FetchLoadingDockCalendarsPayload;
  updateFilteredTotals?: boolean;
};

export type FetchLoadingDockCalendarsThunkResult = {
  calendars: {
    available?: Array<CalendarModel>;
    archived?: Array<CalendarModel>;
    pending?: Array<CalendarPendingModel>;
  };
  totals?: Partial<PershingTotalsMap>;
  filteredTotals?: Partial<PershingTotalsMap>;
};

export type FetchCalendarPagesForComplianceApprovalInfiniteScrollThunkAction = {
  calendarPageId?: number | null;
  status: AdminCalendarStatusFilter;
  params?: Array<CalendarPageQueryParams>;
};

export type FetchCalendarPagesForComplianceApprovalInfiniteScrollThunkResult = {
  calendars: PershingCalendarMap;
};

export type FetchComplianceApprovalCalendarsThunkAction = {
  calendarPageId?: number | null;
  updateAllTotals?: boolean;
  updateFilteredTotals?: boolean;
  updateTotalsFor?: AdminCalendarStatusFilter;
  params?: Array<CalendarPageQueryParams>;
};

export type FetchComplianceApprovalCalendarsThunkResult = {
  calendars?: PershingCalendarMap;
  filteredTotals?: PershingTotalsMap;
  allocationTotals?: PershingTotalsMap;
  totals?: PershingTotalsMap;
};

export type FetchCalendarsForExecutionHubInfiniteScrollThunkAction = {
  status: AdminCalendarStatusFilter;
  params: Array<CalendarQueryParams>;
};

export type FetchCalendarsForExecutionHubInfiniteScrollThunkResult = {
  calendars: PershingCalendarMap;
};

export type FetchExecutionHubCalendarsThunkAction = {
  params: Array<CalendarQueryParams>;
  updateTotalsFor?: AdminCalendarStatusFilter;
  updateAllTotals?: boolean;
  updateFilteredTotals?: boolean;
};

export type FetchExecutionHubCalendarsThunkResult = {
  calendars?: PershingCalendarMap;
  filteredTotals?: PershingTotalsMap;
  totals?: PershingTotalsMap;
};

export type FetchProductApprovalCalendarPageThunkAction = {
  calendarPageId?: number | null;
  updateAllTotals?: boolean;
  updateFilteredTotals?: boolean;
  params?: CalendarPageQueryParams;
  sorting?: CalendarSortingOptions;
};

export type FetchProductApprovalCalendarPageThunkResult = {
  childPageCalendarMap: PershingCalendarPairing;
  parentPageCalendarMap: PershingCalendarPairing;
  visibleParentCalendarIds: PershingSliceState['visibleParentCalendarIds'];
};

export type AcceptComplianceApprovalCalendarThunkAction = {
  reason?: string;
};

export type RejectComplianceApprovalCalendarThunkAction = {
  allocation: AllocationModel;
  order: FixOrderModel;
  event: string;
  type: string;
};

export type UpdatePershingCalendarStatusThunkResult = {
  allocation?: AllocationModel | null;
  calendars?: PershingCalendarMap;
};

export type UpdatePershingCalendarThunkAction = {
  id: number;
  date: DateTime;
};

export type UpdatePershingCalendarThunkResult = {
  calendars: PershingCalendarMap;
};

export type UpdateProductApprovalCalendarStatusThunkAction = {
  calendarPageId?: number | null;
  addRemove: ApiPutAddRemoveQueryParams;
};

export type UpdateProductApprovalCalendarStatusThunkResult = {
  visibleParentCalendarIds: PershingSliceState['visibleParentCalendarIds'];
};

export type PershingSliceState = {
  calendars: PershingCalendarMap;
  childPageCalendarMap: PershingCalendarPairing;
  fixOrderQueue: Array<QueuedFixOrder>;
  filteredTotals: PershingTotalsMap | null;
  parentPageCalendarMap: PershingCalendarPairing;
  pendingCalendars: PershingPendingCalendarPairing;
  selectedAllocation?: AllocationModel | null;
  selectedAccount?: AccountModel | null;
  selectedCalendar?: CalendarModel | null;
  status: PershingStatus;
  allocationTotals: PershingTotalsMap;
  totals: PershingTotalsMap;
  visibleParentCalendarIds: Array<number>;
  orderBook: {
    status: PershingStatus;
    fixOrderQueue: Array<QueuedFixOrder>;
    allocations: {
      calendarAllocations: Array<AllocationModel>;
      pagination: PaginationModel | null;
      canceledOrderId: number | null;
    };
  };
};

export type PershingState = PershingSliceState & Omit<HaloSliceState, 'status'>;
