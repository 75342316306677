import { ChangeEvent, ReactElement } from 'react';

import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { useDebounced } from '@halo-common/hooks';
import { TextField } from '@mui/material';
import { useAtom } from 'jotai';

const inputLabelProps = {
  shrink: true,
};

export const IbdField = (): ReactElement => {
  const [searchFields, setSearchFields] = useAtom(orderBookQueryAtom);

  const handleFieldChange = (key: string, value: string) => {
    setSearchFields({
      filters: {
        [key]: value,
      },
    });
  };

  const debouncedSearchHandler = useDebounced(handleFieldChange, 500);

  const debouncedIBDChange = (ev: ChangeEvent<HTMLInputElement>) => debouncedSearchHandler('ibd', ev.target.value);

  return (
    <TextField
      name="ibd"
      label="IBD"
      size="large"
      placeholder="All"
      defaultValue={searchFields.filters.ibd}
      onChange={debouncedIBDChange}
      slotProps={{ inputLabel: inputLabelProps }}
      fullWidth
    />
  );
};
