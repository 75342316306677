import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiGetEducationModuleGroupResponseModel,
  ApiGetEducationModuleGroupsResponseModel,
  ApiGetEducationEducationGlossaryTermsResponseModel,
} from '@halo-data-sources/models';

const EDUCATION_ROUTE = '/issuer/v1/education';

export const getEducationGlossary = async (): Promise<ApiGetEducationEducationGlossaryTermsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EDUCATION_ROUTE);

  const response = await request<ApiGetEducationEducationGlossaryTermsResponseModel>(
    `${clientConfiguration.basePath}/glossary`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};
export const getEducationModuleGroups = async (): Promise<ApiGetEducationModuleGroupsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EDUCATION_ROUTE);

  const response = await request<ApiGetEducationModuleGroupsResponseModel>(
    `${clientConfiguration.basePath}/module-groups`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};

export const getEducationModuleGroup = async (groupId: number): Promise<ApiGetEducationModuleGroupResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EDUCATION_ROUTE);

  const response = await request<ApiGetEducationModuleGroupResponseModel>(
    `${clientConfiguration.basePath}/module-group/${groupId}`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};
