import { ReactElement } from 'react';

import { LONG_MONTH_DAY_YEAR_FORMAT } from '@halo-common/constants';
import { useTranslatePdmDates } from '@halo-common/hooks';
import { ProductDetailPanelSectionField } from '@halo-common/layouts';

export type PostTradeNoteDetailLifecycleSectionFieldProps = {
  title: string;
  value?: string;
  loading: boolean;
};

export const PostTradeNoteDetailLifecycleSectionField = ({
  title,
  value,
  loading,
}: PostTradeNoteDetailLifecycleSectionFieldProps): ReactElement => {
  const translateDate = useTranslatePdmDates();

  const date = value ? translateDate(value, LONG_MONTH_DAY_YEAR_FORMAT) : '';

  return <ProductDetailPanelSectionField label={title} description={date} loading={loading} />;
};
