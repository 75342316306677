import { ReactElement, ReactNode } from 'react';

import { Stack } from '@halodomination/halo-fe-common';
import { Paper, Typography } from '@mui/material';

const captionStyling = {
  display: 'block',
  mt: 1,
  mx: 0,
  mb: 3,
  maxWidth: 600,
};
const containerStyling = {
  margint: 0,
  marginx: 0,
  marginb: 5,
  padding: 2,
};

export type TackOnTableLayoutProps = {
  children: ReactNode;
  description: string;
  title: string;
};

export const TackOnTableLayout = ({ children, description, title }: TackOnTableLayoutProps): ReactElement => {
  return (
    <Paper sx={containerStyling}>
      <Stack direction="row" justify="space-between">
        <Typography variant="h3">{title}</Typography>
      </Stack>
      <Typography sx={captionStyling} variant="body2">
        {description}
      </Typography>
      {children}
    </Paper>
  );
};
