import { MouseEvent, ReactElement } from 'react';

import { ProductTypeEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { Tabs } from '@halodomination/halo-fe-common';
import { Box, Popover, PopoverProps } from '@mui/material';

import { WatchlistTargetedQuoteField } from './WatchlistTargetedQuoteField';
import { WatchlistTypeahead } from './WatchlistTypeahead';

const popoverSx = {
  p: 2,
  width: 600,
};

const WATCHLIST_POPOVER_TABS = [
  translations.components.watchlistPopoverTab,
  translations.components.watchlistPopoverTargetTab,
];

export type WatchlistPopoverProps = {
  anchorEl: PopoverProps['anchorEl'];
  open: PopoverProps['open'];
  onClose: (ev: MouseEvent<HTMLButtonElement>) => void;
  onTabChange: (tab: number) => void;
  productId: number;
  productType: ProductTypeEnum;
  selectedTab: number;
};

export const WatchlistPopover = ({
  productId,
  productType,
  onClose,
  onTabChange,
  selectedTab,
  ...props
}: WatchlistPopoverProps): ReactElement => (
  <Popover
    {...props}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    disableAutoFocus
    disableEnforceFocus
  >
    <Box sx={popoverSx}>
      <Tabs
        disableGutters
        tabs={WATCHLIST_POPOVER_TABS}
        variant="scrollablePills"
        value={selectedTab}
        onChange={onTabChange}
        slotProps={{ tabs: { allowScrollButtonsMobile: true, scrollButtons: 'auto' } }}
      >
        <WatchlistTypeahead productId={productId} productType={productType} onClose={onClose} />
        <WatchlistTargetedQuoteField productId={productId} onClose={onClose} />
      </Tabs>
    </Box>
  </Popover>
);
