export const DASHBOARD_PORTFOLIO_LIFECYCLE_TRANSLATIONS = {
  addYourPortfolioPositions:
    'Add your portfolio positions to track observation dates, performance, and client allocations.',
  allAccounts: 'All Accounts',
  allCoupons: 'All Coupons',
  allEvents: 'All Events',
  autocallAboveCallLevel: 'Autocall (Above Call Level)',
  chartAndListOfUpcomingEvents: 'A chart and list of the upcoming 4 potential cashflow events.',
  lifecycle: 'Lifecycle',
  maturity: 'Maturity',
  noRecentEvents: 'No Recent Events',
  noUpcomingEvents: 'No Upcoming Events - {type}',
  portfolioLifecycle: 'Portfolio Lifecycle',
  recentEvents: 'Recent Events',
};
