import { EventModel, EventQueueModel, EventQueueResult, useEventQueue } from '@halo-common/hooks';
import { ApiFixEventType } from '@halo-data-sources/enums';
import { ApiAccountModel, ApiAllocationModel, ApiFixOrderModel } from '@halo-data-sources/models';
import { atom } from 'jotai';

const SOCKET_NAMESPACE = 'fix-message';
const ACCOUNT_COMPARISON_KEY = 'account.id';

type FixComparisonType = FixWebsocketEvent['account']['id'];

export type FixWebsocketEvent = {
  allocation: ApiAllocationModel;
  account: ApiAccountModel;
  order: ApiFixOrderModel;
  value: { message: string };
  event: ApiFixEventType;
};

export type FixWebsocketEventQueue = EventQueueModel<FixWebsocketEvent, FixComparisonType>;
export type FixWebsocketEventModel = EventModel<FixWebsocketEvent, FixComparisonType>;

const fixQueueAtom = atom<Array<FixWebsocketEventModel>>([]);

export const useFixEventQueue = (): EventQueueResult<FixWebsocketEvent, FixComparisonType> => {
  return useEventQueue<FixWebsocketEvent, FixComparisonType>(SOCKET_NAMESPACE, ACCOUNT_COMPARISON_KEY, fixQueueAtom);
};

const ALLOCATION_COMPARISON_KEY = 'allocation.id';

type FixAllocationComparisonType = FixWebsocketEvent['allocation']['id'];

export type FixAllocationWebsocketEventQueue = EventQueueModel<FixWebsocketEvent, FixAllocationComparisonType>;
export type FixAllocationWebsocketEventModel = EventModel<FixWebsocketEvent, FixAllocationComparisonType>;

const fixAllocationQueueAtom = atom<Array<FixAllocationWebsocketEventModel>>([]);

export const useFixAllocationEventQueue = (): EventQueueResult<FixWebsocketEvent, FixAllocationComparisonType> => {
  return useEventQueue<FixWebsocketEvent, FixAllocationComparisonType>(
    SOCKET_NAMESPACE,
    ALLOCATION_COMPARISON_KEY,
    fixAllocationQueueAtom,
  );
};
