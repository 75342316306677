import { ReactElement, useState } from 'react';

import { FormControlLabel, Switch } from '@mui/material';

export type EducationHubTableSwitchProps = {
  defaultChecked?: boolean;
  onUpdate: (checked: boolean) => void;
};

export const EducationHubTableSwitch = ({
  defaultChecked = false,
  onUpdate,
}: EducationHubTableSwitchProps): ReactElement => {
  const defaultLabel = defaultChecked ? 'On' : 'Off';
  const [state, setState] = useState({ label: defaultLabel, checked: defaultChecked });

  const handleUpdate = (_: unknown, checked: boolean) => {
    onUpdate(checked);
    setState({ checked, label: checked ? 'On' : 'Off' });
  };

  const control = <Switch defaultChecked={defaultChecked} onChange={handleUpdate} />;

  return <FormControlLabel control={control} label={state.label} />;
};
