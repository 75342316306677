import { ReactElement } from 'react';

import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { OrderBookCalendarDisplayStatusEnum } from '@halo-common/enums';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useAtom } from 'jotai';

export const CalendarStatusField = (): ReactElement => {
  const [searchFields, setSearchFields] = useAtom(orderBookQueryAtom);

  const labelString = 'Status';

  const handleStatusChange = (ev: SelectChangeEvent<string>) => {
    setSearchFields({
      filters: {
        status: ev.target.value,
      },
    });
  };

  return (
    <FormControl fullWidth>
      <InputLabel shrink>{labelString}</InputLabel>
      <Select
        label={labelString}
        size="large"
        value={searchFields.filters.status}
        onChange={handleStatusChange}
        displayEmpty
        notched
      >
        <MenuItem value="">All</MenuItem>
        {Object.entries(OrderBookCalendarDisplayStatusEnum).map((entry) => (
          <MenuItem key={entry[0]} value={entry[0]}>
            {entry[1]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
