import { ReactElement, useEffect } from 'react';

import { dynamicSideBarManagerAtom, sideFiltersLoadedAtom } from '@halo-atoms/common';
import { ActionButton } from '@halo-common/components';
import { CALENDAR_PREFERENCE_TAG } from '@halo-common/constants';
import { WidgetEnginesEnum } from '@halo-common/enums';
import { DynamicSearchFiltersSortBy, SideBarFilterChips, useSaveSideBarFilters } from '@halo-common/layouts';
import type { DashboardWidgetModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Widget } from '@halo-modules/app';
import { Chip } from '@mui/material';
import { useT } from '@transifex/react';
import { useAtomValue, useSetAtom } from 'jotai';

import { CalendarExportButton } from './CalendarExportButton';
import { CalendarsList } from './CalendarsList';

const engine = WidgetEnginesEnum.PRODUCT_SHELF;

const title = translations.dashboard.productShelf.title;
const description = translations.dashboard.productShelf.description;
const tag = CALENDAR_PREFERENCE_TAG;

const appliedChipsSx = {
  borderRadius: 1,
  fontWeight: 600,
};

const chipsSx = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 1,
  ['& > *']: {
    margin: 0,
  },
};

export const ProductShelf = (): ReactElement => {
  const translator = useT();
  const { data: user } = useUserInfoQuery();

  const getSidePanelState = useAtomValue(dynamicSideBarManagerAtom);
  const onLoaded = useSetAtom(sideFiltersLoadedAtom);

  const { isFiltered, filtersChanged } = getSidePanelState(tag);
  const preferences = user?.preferences?.[tag];

  useEffect(() => {
    if (preferences) onLoaded({ tag, ...preferences });
  }, [preferences]);

  const showFilterChips = isFiltered || filtersChanged;

  const { isPending: loading, saveSidebarFilters } = useSaveSideBarFilters(tag, {
    onSuccess: (newPreferences) => {
      const updatedPreferences = newPreferences?.[tag];
      if (updatedPreferences) onLoaded({ tag, update: true, ...updatedPreferences });
    },
  });

  const handleSaveFilters = () => saveSidebarFilters();

  const actionButton = filtersChanged ? (
    <ActionButton size="small" variant="outlined" color="info" onClick={handleSaveFilters} loading={loading}>
      {translations.calendars.common.saveFilters}
    </ActionButton>
  ) : (
    <Chip
      label={translator(translations.calendars.common.appliedSavedFilters)}
      color="success"
      variant="outlined"
      sx={appliedChipsSx}
    />
  );

  const filterChips = showFilterChips ? (
    <SideBarFilterChips tag={tag} sx={chipsSx}>
      {actionButton}
    </SideBarFilterChips>
  ) : null;

  return (
    <Widget
      id={engine}
      title={title}
      filters={<DynamicSearchFiltersSortBy tag={CALENDAR_PREFERENCE_TAG} label={null} />}
      className="wm-dashboard-product-shelf"
      navigateButtonHref="/calendar"
      navigateButtonLabel={translations.dashboard.productShelf.calendars}
      navigateButtonClassName="wm-dashboard-product-shelf-calendars"
    >
      {filterChips}
      <CalendarExportButton />
      <CalendarsList />
    </Widget>
  );
};

const widget: DashboardWidgetModel = {
  engine,
  title,
  description,
  component: ProductShelf,
};

export default widget;
