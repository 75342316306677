import { ReactElement } from 'react';

import { UnderlyingGlyph } from '@halo-common/components';
import { UnderlyingModel } from '@halo-common/models';
import { Stack, StackProps, useCombinedStyling } from '@halodomination/halo-fe-common';
import { Skeleton, SxProps } from '@mui/material';

export type UnderlyingGlyphWeights = {
  [underlying: string]: number;
};

export type UnderlyingGlyphsProps = {
  size?: 'small' | 'large';
  scrollable?: boolean;
  sx?: SxProps;
  tooltip?: boolean;
  underlyings?: Array<UnderlyingModel>;
  weights?: UnderlyingGlyphWeights | null;
  StackProps?: Partial<StackProps>;
  loading?: boolean;
  centered?: boolean;
};

export const UnderlyingGlyphs = ({
  loading = false,
  scrollable = false,
  size = 'small',
  StackProps,
  sx,
  tooltip = false,
  underlyings = [],
  weights,
  centered = false,
}: UnderlyingGlyphsProps): ReactElement => {
  const spacing = scrollable ? 0.5 : 1;

  const underlyingRowSx = {
    alignItems: 'center',
    flexWrap: !scrollable ? 'wrap' : 'unset',
    justifyContent: centered ? 'center' : 'flex-start',
    overflowX: scrollable ? 'scroll' : 'unset',
    py: 1,
  };

  const glyphSx = !scrollable ? { my: 1 } : undefined;

  const underlyingStackSx = useCombinedStyling(underlyingRowSx, sx);

  if (loading) {
    const loadingGlyphs = Array.from(Array(3), (_, id) => ({ ticker: id.toString() })).map(({ ticker }) => (
      <Skeleton key={ticker} width={50} height={50} variant="rounded" />
    ));

    return (
      <Stack direction="row" sx={underlyingStackSx} spacing={0.5} {...StackProps}>
        {loadingGlyphs}
      </Stack>
    );
  }

  const glyphs = [...underlyings]
    .sort((a: UnderlyingModel, b: UnderlyingModel) => {
      const isWorstOf = !weights;
      const alphaSort = a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      if (isWorstOf) return alphaSort;
      else return weights[a.name] < weights[b.name] ? 1 : weights[a.name] > weights[b.name] ? -1 : alphaSort;
    })
    .map(({ name, ...underlying }: UnderlyingModel) => {
      const weight = weights?.[name];
      return (
        <UnderlyingGlyph
          {...underlying}
          key={name}
          ticker={name}
          tooltip={tooltip}
          weight={weight}
          size={size}
          sx={glyphSx}
        />
      );
    });

  return (
    <Stack direction="row" sx={underlyingStackSx} spacing={spacing} {...StackProps}>
      {glyphs}
    </Stack>
  );
};
