import { LifecycleFilterModel } from '@halo-atoms/portfolio';
import { PortfolioCashflowEventsPayload } from '@halo-common/models';
import { hash } from '@halo-common/utils';
import { GetLifecycleEventDetailPayload, QueryKeyFactoryFn } from '@halo-data-sources/queries';

type PortfolioLifecycleQueryKeys = {
  all: QueryKeyFactoryFn;
  paginate: QueryKeyFactoryFn;
  aggregate: QueryKeyFactoryFn;
  filter: QueryKeyFactoryFn;
  recommendations: QueryKeyFactoryFn;
  calendar: QueryKeyFactoryFn;
};

export const PortfolioLifecycleQueryKeyFactory: PortfolioLifecycleQueryKeys = {
  all: () => ['portfolio', 'lifecycle'],
  paginate: (payload: GetLifecycleEventDetailPayload) => [...PortfolioLifecycleQueryKeyFactory.all(), hash(payload)],
  aggregate: (payload: PortfolioCashflowEventsPayload) => [
    ...PortfolioLifecycleQueryKeyFactory.all(),
    'aggregate',
    hash(payload),
  ],
  filter: (payload: LifecycleFilterModel) => [...PortfolioLifecycleQueryKeyFactory.all(), hash(payload)],
  recommendations: (payload: Array<number>) => [
    ...PortfolioLifecycleQueryKeyFactory.all(),
    'recommendations',
    ...payload,
  ],
  calendar: (termsheetId: number) => [...PortfolioLifecycleQueryKeyFactory.all(), termsheetId],
};
