import { COMBINED_DATE_TIME_ZONE_FORMAT } from '@halo-common/constants';
import { useDateFormatter } from '@halo-common/hooks';
import { CalendarModel, CalendarPendingModel, CalendarUploadModel } from '@halo-common/models';
import { navigateParentTo } from '@halo-common/utils';
import { useWholesalersQuery } from '@halo-data-sources/queries';
import { PershingSelectors } from '@halo-stores/Pershing';
import { Link, Skeleton } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';

const linkSx = {
  fontWeight: 'fontWeightBold',
  textDecoration: 'underline',
  '&:hover': {
    cursor: 'pointer',
  },
};

export const buildLoadingDockColumns = (
  status: 'pending' | 'available' | 'archived',
  loading: boolean,
  canNavigate: boolean,
  onOpenEditCalendarModal?: (calendar: CalendarModel) => void,
): Array<GridColDef> => {
  const formatDate = useDateFormatter();

  const { data: fixEnabledWholesalers } = useWholesalersQuery();

  const loadingDockWholesalers = useSelector(PershingSelectors.selectLoadingDockWholesalerMaps);

  const wholesalerMap = loadingDockWholesalers[status];
  const showTableMenu = Boolean(onOpenEditCalendarModal);

  const handleResize = () => {
    // NO_OP
  };

  const commonCellProps: Partial<GridColDef> = {
    align: 'right',
    editable: false,
    sortable: false,
    width: 200,
  };

  const buildWholesalerColumns = (code: string): Array<GridColDef> => {
    const parsedCode = code.toLowerCase();
    const labelCode = code.toUpperCase();

    const buyPriceHeaderText = `${labelCode} BUY PRICE`;
    const reofferPriceHeaderText = `${labelCode} REOFFER`;

    return [
      {
        ...commonCellProps,
        field: `${parsedCode}buyPrice`,
        headerName: buyPriceHeaderText,
        valueGetter: (_, row: CalendarModel | CalendarPendingModel) => {
          const { id, wholesalers } = row;
          const wholesaler = wholesalers?.find((wholesaler) => wholesaler.code?.toLowerCase() === parsedCode);
          const wholesalerCode = wholesaler?.code ?? '';
          const incorrectWholesaler = Boolean(!wholesaler || !wholesalerMap[wholesalerCode]?.[id]);

          if (loading || incorrectWholesaler) return null;
          return wholesalerMap[wholesalerCode][id].price.toFixed(3);
        },
        renderCell: ({ value }: GridRenderCellParams) => {
          const formattedValue = value ? `${value}%` : null;
          return loading ? <Skeleton width="25%" height={24} /> : formattedValue;
        },
      },
      {
        ...commonCellProps,
        field: `${parsedCode}Reoffer`,
        headerName: reofferPriceHeaderText,
        valueGetter: (_, row: CalendarModel | CalendarPendingModel) => {
          const { id, wholesalers } = row;
          const wholesaler = wholesalers?.find((wholesaler) => wholesaler.code?.toLowerCase() === parsedCode);
          const wholesalerCode = wholesaler?.code ?? '';
          const incorrectWholesaler = Boolean(!wholesaler || !wholesalerMap[wholesalerCode]?.[id]);

          if (loading || incorrectWholesaler) return null;
          return wholesalerMap[wholesalerCode][id].salePrice.toFixed(3);
        },
        renderCell: ({ value }: GridRenderCellParams) => {
          const formattedValue = value ? `${value}%` : null;
          return loading ? <Skeleton width="25%" height={24} /> : formattedValue;
        },
      },
    ];
  };

  const wholesalerColumns = fixEnabledWholesalers?.flatMap(({ code }) => buildWholesalerColumns(code)) ?? [];

  const columns: Array<GridColDef> = [
    {
      field: 'cusip',
      headerName: 'CUSIP',
      editable: false,
      minWidth: 125,
      renderCell: ({ row, value }: GridRenderCellParams<CalendarUploadModel>) => {
        if (loading) return <Skeleton width="50%" height={24} />;

        const handleNavigation = () => void navigateParentTo(`/calendar/preview/${row.id}`);

        const link = (
          <Link sx={linkSx} onClick={handleNavigation}>
            {value}
          </Link>
        );

        return canNavigate ? link : value;
      },
    },
    {
      field: 'expirationDate',
      headerName: 'CLOSES',
      editable: false,
      minWidth: 240,
      renderCell: ({ row }: GridRenderCellParams<CalendarUploadModel>) => {
        if (loading) return <Skeleton width="100%" height={24} />;

        const { expirationDate } = row;

        return expirationDate ? formatDate(expirationDate, COMBINED_DATE_TIME_ZONE_FORMAT) : undefined;
      },
    },
    ...wholesalerColumns,
  ];

  if (showTableMenu) {
    columns.unshift({
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: ({ row }: GridRowParams<CalendarModel>) => [
        <GridActionsCellItem
          onResize={handleResize}
          onResizeCapture={handleResize}
          key="menu-toggle"
          label="Modify"
          onClick={() => onOpenEditCalendarModal?.(row)}
          showInMenu
        />,
      ],
    });
  }

  return columns;
};
