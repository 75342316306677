import { ReactElement } from 'react';

import { linkExpiredSchema } from '@halo-common/schemas';
import { ConnectedContactButton } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { useSendResetPasswordEmailMutation } from '@halo-data-sources/mutations/onboarding';
import {
  LocalizedAlert,
  LocalizedButton,
  LocalizedTextField,
  LocalizedTypography,
  Stack,
} from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Paper } from '@mui/material';
import { useForm } from 'react-hook-form';

const formContainer = {
  padding: 3,
  overflowY: 'auto',
  maxWidth: 450,
  minWidth: { xs: 343, sm: 'default' },
  width: '100%',
};

const contactButtonSx = {
  color: 'info.dark',
  pl: 0,
  textDecoration: 'underline',
};

const alertSx = {
  alignItems: 'flex-start',
};

const linkExpiredResolver = yupResolver<LinkExpiredSchema>(linkExpiredSchema);

export type LinkExpiredSchema = {
  email: string;
};

export const LinkExpiredForm = (): ReactElement => {
  const {
    mutate: sendResetPassword,
    isPending,
    error: resetPasswordError,
    isError,
  } = useSendResetPasswordEmailMutation(true);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LinkExpiredSchema>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    resolver: linkExpiredResolver,
    defaultValues: {
      email: '',
    },
  });

  const { ref: emailRef, ...emailProps } = register('email');

  const onSubmit = (data: LinkExpiredSchema) => {
    sendResetPassword(data);
  };

  const alertMessage = isError ? (
    <LocalizedAlert sx={alertSx} severity="info" variant="filled">
      <LocalizedTypography variant="body2" component="span">
        {resetPasswordError?.message}
      </LocalizedTypography>
      <ConnectedContactButton size="small" sx={contactButtonSx} />
    </LocalizedAlert>
  ) : (
    <LocalizedAlert sx={alertSx} severity="info" variant="filled">
      <LocalizedTypography variant="body2" component="span">
        {translations.onboarding.linkExpiredForm.assistance}
      </LocalizedTypography>
      <ConnectedContactButton size="small" sx={contactButtonSx} />
    </LocalizedAlert>
  );

  const startIcon = isPending ? <CircularProgress size="1rem" /> : undefined;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper variant="outlined" sx={formContainer}>
        <Stack direction="column" spacing={3}>
          {alertMessage}
          <LocalizedTextField
            {...emailProps}
            fullWidth
            size="large"
            label={translations.common.emailAddress}
            inputRef={emailRef}
            error={Boolean(errors?.email)}
            helperText={errors?.email?.message}
          />
          <LocalizedButton
            startIcon={startIcon}
            disabled={isPending}
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
          >
            {translations.common.sendLink}
          </LocalizedButton>
        </Stack>
      </Paper>
    </form>
  );
};
