import { ReactElement, useEffect } from 'react';

import {
  POSITIONS_DETAILS_MODAL,
  portfolioPositionDetailsModalAtom,
  portfolioPositionsManagerAtom,
} from '@halo-atoms/portfolio';
import { PortfolioTabEnum } from '@halo-common/enums';
import { PostTradeNoteDetailModalLayout, V2PostTradeNoteDetailModalLayout } from '@halo-common/modals';
import { useLegacyRouter } from '@halo-common/providers';
import { useRecordActivityMutation } from '@halo-data-sources/mutations';
import { useIsNoteMigratedQuery } from '@halo-data-sources/queries';
import { ModalBase } from '@halodomination/halo-fe-common';
import { useAtom, useAtomValue } from 'jotai';

export const PortfolioProductDetailsNoteModal = (): ReactElement => {
  const router = useLegacyRouter();

  const { mutate: recordActivity } = useRecordActivityMutation();

  const positionsData = useAtomValue(portfolioPositionsManagerAtom);
  const [positionModal, setPositionModal] = useAtom(portfolioPositionDetailsModalAtom);

  const { selectedPositionAtom, modalMap } = positionModal;

  const open = modalMap[POSITIONS_DETAILS_MODAL];

  const noteId = selectedPositionAtom?.id;

  const { data: isMigrated, isPending: checkingMigration } = useIsNoteMigratedQuery(noteId);

  const { filters: positionFilters } = positionsData;
  const { accountOption, currency } = positionFilters;

  const filters = { accountOption, currencyId: currency?.id };

  const handleCloseModal = () => void setPositionModal();

  const props = { onClose: handleCloseModal, referenceId: noteId, filters: filters, loading: checkingMigration };

  const content = isMigrated ? (
    <V2PostTradeNoteDetailModalLayout {...props} />
  ) : (
    <PostTradeNoteDetailModalLayout {...props} />
  );

  useEffect(() => {
    if (open && !checkingMigration) {
      const selectedTabQuery = router.query.tab;
      const hasTabQueryParam = typeof selectedTabQuery === 'string';
      const selectedTab = hasTabQueryParam ? selectedTabQuery : PortfolioTabEnum.POSITION_TAB;

      const verb = isMigrated
        ? `opened product details modal with NME for position ${noteId} from ${selectedTab}`
        : `opened product details modal for position ${noteId} from ${selectedTab}`;

      recordActivity({
        activity: [{ verb, model_name: 'Portfolio' }],
        fromIFrame: false,
      });
    }
  }, [open, checkingMigration]);

  return (
    <ModalBase size="large" open={open} onClose={props.onClose} className="wm-pdm-posttrade">
      {content}
    </ModalBase>
  );
};
