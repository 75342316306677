import { ReactElement } from 'react';

import { HaloDataGrid, HaloDataGridProps } from '@halodomination/halo-fe-common';
import { Typography } from '@mui/material';
import { gridClasses } from '@mui/x-data-grid-pro';

const RR_ASSET_HEADER_NAME = 'rr-nonAssetHeaderName';
const RR_ASSET_CELL_NAME = 'rr-nonAssetCellName';

const tableSx = {
  '& .name-cell': {
    fontWeight: 'fontWeightBold',
  },
  [`& .${RR_ASSET_HEADER_NAME}`]: {
    [`& .${gridClasses.columnHeaderTitleContainer}`]: {
      justifyContent: 'center',
    },
  },
  [`& .${RR_ASSET_CELL_NAME}`]: {
    backgroundColor: 'grey.100',
  },
  [`& .${gridClasses.treeDataGroupingCell}`]: {
    fontWeight: 'fontWeightBold',
    marginLeft: 0,
  },
};

export type ProductDetailModalAnalyticsRollingReturnsTableProps = Pick<
  HaloDataGridProps,
  'columns' | 'rows' | 'loading' | 'treeData'
>;

export const ProductDetailModalAnalyticsRollingReturnsTable = ({
  columns = [],
  rows = [],
  loading = false,
  treeData,
}: ProductDetailModalAnalyticsRollingReturnsTableProps): ReactElement => {
  const updatedColumns: HaloDataGridProps['columns'] = !treeData
    ? [{ flex: 0.4, field: 'name', headerName: 'Asset', cellClassName: 'name-cell' }, ...columns]
    : columns;

  const mappedColumns: HaloDataGridProps['columns'] = updatedColumns.map((column) => ({
    flex: 1,
    align: column.headerName === 'Asset' ? 'left' : 'right',
    sortable: false,
    headerClassName: RR_ASSET_HEADER_NAME,
    valueFormatter: (value: number) => {
      const showPercentage = typeof value === 'number';
      return showPercentage ? `${value.toFixed(2)}%` : value;
    },
    ...column,
  }));

  return (
    <HaloDataGrid
      sx={tableSx}
      disableColumnPinning
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
      disableColumnReorder
      disableRowSelectionOnClick
      treeData={treeData}
      columns={mappedColumns}
      rows={rows}
      loading={loading}
      getRowClassName={({ row }) => {
        const isRoot = row.path?.length === 1;
        return !isRoot ? RR_ASSET_CELL_NAME : '';
      }}
      density="compact"
      getTreeDataPath={(row) => row?.path ?? []}
      groupingColDef={{
        align: 'left',
        flex: 1,
        cellClassName: 'name-cell',
        renderHeader: () => (
          <Typography variant="caption" fontWeight="bold" paddingLeft="44px">
            Asset
          </Typography>
        ),
      }}
    />
  );
};
