import { ReactElement } from 'react';

import { orderBookCancelModalAtom, orderBookQueryAtom } from '@halo-atoms/orderbook';
import { ActionButton } from '@halo-common/components';
import { useCancelExecCalendarOrderMutation } from '@halo-data-sources/mutations';
import {
  IssuerQueryKeyFactory,
  OrderBookClientQueryKeyFactory,
  OrdersClientQueryKeyFactory,
} from '@halo-data-sources/queries';
import { Iconography, Modal, Stack, useSnackbar } from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';

export const OrderBookJanneyCancelOrderModal = (): ReactElement => {
  const queryClient = useQueryClient();

  const { enqueueSuccessEvent, enqueueErrorEvent } = useSnackbar();

  const searchFields = useAtomValue(orderBookQueryAtom);
  const [orderBookCancelModal, setOrderBookCancelModal] = useAtom(orderBookCancelModalAtom);

  const { mutate, isPending } = useCancelExecCalendarOrderMutation({
    onError: (error) => {
      enqueueErrorEvent({ message: error.message });
    },
    onSuccess: (data, allocationId) => {
      if (!data) return undefined;

      void queryClient.invalidateQueries({ queryKey: IssuerQueryKeyFactory.byExecutionOrder(searchFields) });
      void queryClient.invalidateQueries({ queryKey: OrderBookClientQueryKeyFactory.janney(searchFields) });
      void queryClient.invalidateQueries({ queryKey: OrdersClientQueryKeyFactory.calendarOrder(allocationId) });

      setOrderBookCancelModal({ open: false });

      enqueueSuccessEvent({ message: `Order: ${data.execOrderId}`, subMessage: data.message });
    },
  });

  const { open, allocation: selectedAllocation } = orderBookCancelModal;

  const selectedAccountId = selectedAllocation?.account?.accountId;
  const title = `Are you sure you would like to cancel the indication of interest for account ${selectedAccountId}?`;

  const handleClose = () => {
    if (!isPending) setOrderBookCancelModal({ open: false });
  };

  const handleCancel = () => {
    if (selectedAllocation) mutate(selectedAllocation.id);
  };

  const footer = (
    <Stack direction="row" justify="space-between">
      <Button
        type="button"
        variant="text"
        color="primary"
        onClick={handleClose}
        startIcon={<Iconography iconName="times" />}
        disabled={isPending}
      >
        Don&apos;t Cancel
      </Button>
      <ActionButton
        type="button"
        variant="contained"
        color="primary"
        onClick={handleCancel}
        loadingIndicator="Canceling..."
        loading={isPending}
      >
        Cancel Indication
      </ActionButton>
    </Stack>
  );

  return <Modal size="small" open={open} title={title} icon="question" footer={footer} onClose={handleClose} />;
};
