import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

export type NotificationsQueryKeys = {
  all: QueryKeyFactoryFn;
  allTypes: QueryKeyFactoryFn;
  infinite: QueryKeyFactoryFn;
};

export const NotificationsQueryKeyFactory: NotificationsQueryKeys = {
  all: () => ['notifications'],
  allTypes: () => ['notificationTypes'],
  infinite: (payload) => [...NotificationsQueryKeyFactory.all(), 'infinite', hash(payload)],
};
