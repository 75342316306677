import { ReactElement } from 'react';

import { selectedWatchlistAtom } from '@halo-atoms/watchlists';
import { useLegacyRouter } from '@halo-common/providers';
import { translations } from '@halo-common/translations';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { useSetAtom } from 'jotai';

export const WatchlistHomeButton = (): ReactElement => {
  const legacyRouter = useLegacyRouter();

  const setSelectedWatchlist = useSetAtom(selectedWatchlistAtom);

  const handleReturnClick = () => {
    legacyRouter.removeQueryParam('watchlist');
    setSelectedWatchlist(null);
  };

  return (
    <LocalizedButton
      variant="text"
      size="small"
      startIcon={<Iconography color="primary.main" iconName="arrow-left" />}
      onClick={handleReturnClick}
    >
      {translations.watchlist.common.returnToAll}
    </LocalizedButton>
  );
};
