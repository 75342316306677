export const EDUCATION_QUIZ_MANAGER_TRANSLATIONS = {
  submitQuiz: 'Submit Quiz',
  quiz: 'Quiz',
  stepperSubtitle: 'Quiz • {stepTitle}',
  errorMessage: 'To pass this quiz, you must correctly answer: {threshold} of {questionCount} questions.',
  successMessage:
    'Great job, you passed! You correctly answered {correctAnswers} out of {questionCount} questions. Please continue to the next section.',
  answerCorrectly: 'To pass this quiz, you must correctly answer:',
  requirement: '{threshold} of {questionCount} questions',
  allCourses: 'All Courses',
  seeAll: 'See All',
  quizFailed: 'Quiz Failed',
  quizPassed: 'Quiz Passed',
};
