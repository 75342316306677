import { ReactElement, useEffect, useState } from 'react';

import { WhiteLabelLogo } from '@halo-common/components';
import { PageMessageBackground } from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import {
  Alert,
  Box,
  Button,
  Container,
  Paper,
  Skeleton,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const skeletonSx = {
  transform: 'none',
};

const logoSx = {
  mt: 6,
};

const containerSx = {
  height: '100%',
  maxWidth: { xs: '100%' },
  overflow: 'hidden',
  position: { md: 'relative', xs: 'scroll' },
};

const contentSx = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: 384,
  left: 'calc(50% - (461px / 2))',
  padding: 4,
  position: 'absolute',
  top: 350,
  width: 464,
  zIndex: 'modal',
};

const snackbarSx = {
  minWidth: 'fit-content',
};

export type PageMessageProps = {
  buttonText?: string;
  icon: ReactElement;
  showSnackbar?: boolean;
  snackbarContent?: ReactElement;
  subText?: string;
  titleText?: string;
  loading?: boolean;
  onClick?: () => void;
  onClose?: () => void;
};

export const PageMessageLayout = ({
  buttonText,
  icon,
  showSnackbar,
  snackbarContent,
  subText,
  titleText,
  loading = false,
  onClick,
  onClose,
}: PageMessageProps): ReactElement => {
  const theme = useTheme<HaloTheme>();

  const [height, setHeight] = useState(0);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const textContainerSx = {
    height: '100%',
    justifyContent: isSmallScreen ? 'flex-start' : 'center',
    mt: isSmallScreen ? 18 : 'unset',
    zIndex: 'modal',
  };

  const colorSx = { color: isSmallScreen ? 'primary.contrastText' : 'primary.main' };
  const subColorSx = { color: isSmallScreen ? 'primary.contrastText' : 'text.secondary' };

  const openSnackbar = showSnackbar && !loading;

  const logoContent = !loading ? <WhiteLabelLogo /> : <Skeleton sx={logoSx} width={190} height={53} />;

  const message = (
    <Stack sx={textContainerSx} direction="column" alignItems="center" spacing={2}>
      {icon}
      <Typography sx={colorSx} align="center" variant="h2">
        {titleText}
      </Typography>
      <Typography sx={subColorSx} align="center" variant="body1">
        {subText}
      </Typography>
      {buttonText ? (
        <Button sx={colorSx} type="button" variant="text" onClick={onClick}>
          {buttonText}
        </Button>
      ) : null}
    </Stack>
  );

  const mainContent = isSmallScreen ? (
    message
  ) : (
    <Paper variant="outlined" sx={contentSx}>
      {message}
    </Paper>
  );

  const content = !loading ? (
    mainContent
  ) : (
    <Stack sx={textContainerSx} direction="column" justify="center" alignItems="center" spacing={2}>
      <Box width={76} height={96} />
      <Skeleton sx={skeletonSx} width={460} height={38} />
      <Stack direction="column" spacing={1}>
        <Skeleton sx={skeletonSx} width={400} height={26} />
        <Skeleton sx={skeletonSx} width={400} height={26} />
        <Skeleton sx={skeletonSx} width={400} height={26} />
      </Stack>
      <Skeleton sx={skeletonSx} width={120} height={42} />
    </Stack>
  );

  useEffect(() => {
    setHeight(window.screen.availHeight > 600 ? window.screen.availHeight : 600);
  }, []);

  return (
    <Container sx={containerSx}>
      <PageMessageBackground contentPositionY={height} loading={loading} fullPage />
      {logoContent}
      {content}
      <Snackbar sx={snackbarSx} open={openSnackbar} autoHideDuration={3000} onClose={onClose}>
        <Alert onClose={onClose} severity="success" variant="filled">
          <Typography component="span" align="center" color="textSecondary" variant="subtitle1">
            {snackbarContent}
          </Typography>
        </Alert>
      </Snackbar>
    </Container>
  );
};
