import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiCalendarOrderCancellationResponseModel,
  ApiCalendarOrderFillResponseModel,
  ApiCalendarOrderPreviewQueryResponseModel,
  ApiCalendarOrderQueryResponseModel,
  ApiExecOrderPreviewRequestModel,
  ApiFillExecOrdersRequestModel,
  ApiQueryFiltersResponseModel,
  ApiQueryRequestModel,
  ApiSubmissionOrderRequestModel,
  ApiSubmissionOrderResponseModel,
  ApiValidateOrderRequestModel,
  ApiValidateOrderResponseModel,
} from '@halo-data-sources/models';

const EXEC_ORDER_PATH = '/execution/order';

export const postCalendarOrderQuery = async (
  payload?: ApiQueryRequestModel,
): Promise<ApiCalendarOrderQueryResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_ORDER_PATH);

  const path = `${clientConfiguration.basePath}/calendar/query`;

  return await request<ApiCalendarOrderQueryResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });
};

export const postCalendarOrderCSVDownload = async (payload?: ApiQueryRequestModel): Promise<string> => {
  const clientConfiguration = await getClientConfiguration(EXEC_ORDER_PATH);

  const path = `${clientConfiguration.basePath}/calendar/query/csv`;

  return await request<string>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });
};

export const postCalendarOrderFiltersQuery = async (
  payload?: ApiQueryRequestModel,
): Promise<ApiQueryFiltersResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_ORDER_PATH);

  const path = `${clientConfiguration.basePath}/calendar/query/filters`;

  return await request<ApiQueryFiltersResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });
};

export const getCalendarOrderPreview = async (
  payload?: ApiExecOrderPreviewRequestModel,
): Promise<ApiCalendarOrderPreviewQueryResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_ORDER_PATH);

  const path = `${clientConfiguration.basePath}/calendar/admin/fill/preview`;

  return await request<ApiCalendarOrderPreviewQueryResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });
};

export const postCalendarAdminOrderFiltersQuery = async (
  payload?: ApiQueryRequestModel,
): Promise<ApiQueryFiltersResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_ORDER_PATH);

  const path = `${clientConfiguration.basePath}/calendar/admin/query/filters`;

  return await request<ApiQueryFiltersResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });
};

export const postCalendarAdminOrdersQuery = async (
  payload: ApiQueryRequestModel,
): Promise<ApiCalendarOrderQueryResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_ORDER_PATH);

  const path = `${clientConfiguration.basePath}/calendar/admin/query`;

  return await request<ApiCalendarOrderQueryResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });
};

export const postCalendarAdminOrderCSVDownload = async (payload: ApiQueryRequestModel): Promise<string> => {
  const clientConfiguration = await getClientConfiguration(EXEC_ORDER_PATH);

  const path = `${clientConfiguration.basePath}/calendar/admin/query/csv`;

  return await request<string>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });
};

export const postFillAllExecCalendarOrder = async (
  payload: ApiFillExecOrdersRequestModel,
): Promise<ApiCalendarOrderFillResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_ORDER_PATH);

  const path = `${clientConfiguration.basePath}/calendar/admin/fill`;

  return await request<ApiCalendarOrderFillResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });
};

export const postExecCalendarAdminOrderCancellation = async (
  allocationId: number,
  reason?: string,
): Promise<ApiCalendarOrderCancellationResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_ORDER_PATH);

  const path = `${clientConfiguration.basePath}/calendar/${allocationId}/admin/cancel`;

  return await request<ApiCalendarOrderCancellationResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      status: 'CANCELED',
      reason,
    }),
  });
};

export const postExecCalendarOrderCancellation = async (
  allocationId: number,
): Promise<ApiCalendarOrderCancellationResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_ORDER_PATH);

  const path = `${clientConfiguration.basePath}/calendar/${allocationId}/cancel`;

  return await request<ApiCalendarOrderCancellationResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};

export const submitCalendarOrder = async (
  payload: ApiSubmissionOrderRequestModel,
): Promise<ApiSubmissionOrderResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_ORDER_PATH);

  const path = `${clientConfiguration.basePath}/calendar/place`;

  const response = await request<ApiSubmissionOrderResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...payload,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const validateCalendarOrder = async (
  payload: ApiValidateOrderRequestModel,
): Promise<ApiValidateOrderResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_ORDER_PATH);

  const path = `${clientConfiguration.basePath}/calendar/validate`;

  const response = await request<ApiValidateOrderResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...payload,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};
