import { ReactElement } from 'react';

import { AuctionAdminModel } from '@halo-common/models';
import { IconographyProps } from '@halodomination/halo-fe-common';
import { Skeleton, Stack, Typography } from '@mui/material';

import { TackOnTableAction, TackOnTableActionProps } from '../TackOnTableAction';

const whoContainerSx = {
  px: 2,
  height: '100%',
  width: '100%',
};

const organizationSx = {
  minWidth: 150,
  width: '100%',
  fontWeight: 'fontWeightBold',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const whoTitleSx = {
  fontWeight: 'fontWeightBold',
};

const whoValueSx = {
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap',
};

export type TackOnTableWhoCellProps = {
  inProgress?: boolean;
  row: AuctionAdminModel;
  buyerName: string;
  salesRepName?: string;
  underlyers?: string;
  orgName: string;
  isLoading?: boolean;
  cta: {
    icon?: IconographyProps['iconName'];
    label: string;
    disabled?: boolean;
    color?: TackOnTableActionProps['color'];
  };
};

export const TackOnTableWhoCell = ({
  inProgress = false,
  row,
  buyerName,
  salesRepName,
  underlyers,
  orgName,
  isLoading = false,
  cta,
}: TackOnTableWhoCellProps): ReactElement => {
  if (isLoading) {
    return (
      <Stack sx={whoContainerSx} direction="column" justifyContent="center">
        <Skeleton width="100%" height={20} />
        <Skeleton width="100%" height={20} />
        <Skeleton width="100%" height={20} />
        <Skeleton width="100%" height={32} />
      </Stack>
    );
  }

  const salesRep = salesRepName ? (
    <Stack direction="row">
      <Typography sx={whoTitleSx} variant="body2">
        Sales:&nbsp;
      </Typography>
      <Typography sx={whoValueSx} variant="body2">
        {salesRepName}
      </Typography>
    </Stack>
  ) : null;

  const underliers = underlyers ? (
    <Stack direction="row">
      <Typography sx={whoTitleSx} variant="body2">
        Underlyers:&nbsp;
      </Typography>
      <Typography sx={whoValueSx} variant="body2">
        {underlyers}
      </Typography>
    </Stack>
  ) : null;

  return (
    <Stack sx={whoContainerSx} direction="column" justifyContent="center">
      <Typography sx={organizationSx} variant="body2">
        {orgName}
      </Typography>
      <Stack direction="row">
        <Typography sx={whoTitleSx} variant="body2">
          Buyer:&nbsp;
        </Typography>
        <Typography sx={whoValueSx} variant="body2">
          {buyerName}
        </Typography>
      </Stack>
      {salesRep}
      {underliers}
      <TackOnTableAction {...cta} data={row} inProgress={inProgress} />
    </Stack>
  );
};
