import { ReactElement } from 'react';

import { SMAStrategyModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { StrategyClickableDocument } from '@halo-modules/app';
import { StrategyCards } from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';
import { Container, Paper, SxProps, Typography } from '@mui/material';

export type SMAOfferingsProps = {
  onSubmitAllocation: (strategy: SMAStrategyModel) => void;
};

const secLink = 'https://adviserinfo.sec.gov/firm/summary/325613';

export const SMAOfferings = ({ onSubmitAllocation }: SMAOfferingsProps): ReactElement => {
  const containerSx: SxProps = { mt: 4, ml: 4 };
  const fixedButtonsSx = {
    borderRadius: '4px',
    cursor: 'pointer',
    width: 694,
    height: 80,
    display: 'flex',
    alignItems: 'center',
    padding: 1,
  };

  return (
    <Container maxWidth={false} sx={containerSx}>
      <Stack direction="column" spacing={3}>
        <StrategyCards onSubmitAllocation={onSubmitAllocation} />
        <Paper variant="outlined" sx={fixedButtonsSx}>
          <Stack direction="row" justify="space-between" alignItems="center">
            <Typography fontWeight={600} fontSize={18} color="primary.main">
              {translations.sma.haloInvestmentServices}
            </Typography>
            <Stack direction="row" justify="space-between" alignItems="center">
              <StrategyClickableDocument text="Form ADV & CRS" hyperlink={secLink} />
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
};
