import { fetchUserCommonSettings } from '@halo-data-sources/clients';
import { ApiUserMapper } from '@halo-data-sources/mappers';
import { CommonQueryKeyFactory } from '@halo-data-sources/queries';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export type CommonUserSettingGroupSetting = {
  field: string;
  value?: string | number | null | boolean;
};

export type CommonUserSettingGroup = {
  name: string;
  settings: Array<CommonUserSettingGroupSetting>;
};

export type CommonUserSettings = {
  settingGroups: Array<CommonUserSettingGroup>;
};

const fetchCommonUserSettingsQueryFn = async () => {
  const response = await fetchUserCommonSettings();
  return ApiUserMapper.toCommonUserSettings(response);
};

export type useCommonUserSettingsQueryResult = UseQueryResult<CommonUserSettings, Error>;

export const useCommonUserSettingsQuery = (): useCommonUserSettingsQueryResult => {
  return useQuery<CommonUserSettings, Error>({
    queryKey: CommonQueryKeyFactory.userSettings(),
    queryFn: () => fetchCommonUserSettingsQueryFn(),
  });
};
