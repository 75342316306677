import { removeTargetQuote } from '@halo-data-sources/clients';
import { WatchlistQueryKeyFactory } from '@halo-data-sources/queries';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';

export type RemoveTargetedNoteFromWatchlistMutationResult = {
  id: number;
  message: string;
};

const removeTargetedNoteFromWatchlistMutationFn = async (id?: number | null) => {
  if (!id) return null;

  await removeTargetQuote(id);

  return {
    id,
    message: 'Successfully deleted watchlist',
  };
};

export const useRemoveTargetedNoteFromWatchlistMutation = (): UseMutationResult<
  RemoveTargetedNoteFromWatchlistMutationResult | null,
  Error,
  number | null | undefined
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: removeTargetedNoteFromWatchlistMutationFn,
    onSuccess: async (data) => {
      if (!data?.id) return;
      const queryKey = WatchlistQueryKeyFactory.all();
      await queryClient.refetchQueries({ queryKey });
    },
  });
};
