import { ReactElement, ReactNode } from 'react';

import { portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import { translations } from '@halo-common/translations';
import { PortfolioPositionAggregationModel } from '@halo-data-sources/queries';
import {
  getPieChartPercentageFormatter,
  LocalizedTypography,
  mapNumberToLocalCurrency,
  PieChartTooltipProps,
  PieChartTooltipRow,
} from '@halodomination/halo-fe-common';
import { Paper, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';

const paperSx = {
  width: 240,
  paddingX: 2,
  paddingY: 1,
  zIndex: 'modal',
  translate: '-56px 164px',
};

const textSx = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const nameSx = {
  ...textSx,
  flex: '1 1 auto',
};

const valueSx = {
  ...textSx,
  flex: '0 0 auto',
};

type StatProps = { title: ReactNode; value: ReactNode };

const Stat = ({ title, value }: StatProps): ReactElement => (
  <Stack alignItems="center" direction="row" spacing={1} pl={2}>
    <LocalizedTypography variant="body2" color="text.secondary" sx={nameSx}>
      {title}
    </LocalizedTypography>
    <Typography variant="body2" sx={valueSx}>
      {value}
    </Typography>
  </Stack>
);

export type PortfolioPositionsTooltipProps = PieChartTooltipProps;

export const PortfolioPositionsTooltip = ({
  active,
  payload,
  formatter,
  valueFormatter = getPieChartPercentageFormatter,
}: PortfolioPositionsTooltipProps): ReactElement | null => {
  const { filters } = useAtomValue(portfolioPositionsManagerAtom);
  const { currency } = filters;

  const currencyOptions = { currency: currency?.code, maximumFractionDigits: 0 };

  if (!active || !Array.isArray(payload) || !payload.length) return null;

  const payloadRow = payload[0];
  const aggregation = payloadRow.payload as PortfolioPositionAggregationModel;

  const fill = payloadRow.payload.fill;
  const value = payloadRow.value as number;
  const name = payloadRow.name as string;
  const formattedValue = valueFormatter
    ? valueFormatter(value, name, payloadRow, 0, payload)
    : formatter
      ? formatter(value, name, payloadRow, 0, payload)
      : payloadRow.value;

  const formatCurrency = (value: number) =>
    mapNumberToLocalCurrency(Math.round(value), currencyOptions, currency?.symbol);

  const notional = aggregation.totalNotional ? (
    <Stat
      title={translations.dashboard.portfolioPositions.notional}
      value={formatCurrency(aggregation.totalNotional)}
    />
  ) : null;

  const numberOfProducts = aggregation.positionCount ? (
    <Stat title={translations.dashboard.portfolioPositions.numberOfProducts} value={aggregation.positionCount} />
  ) : null;

  const marketValue = aggregation.marketValue ? (
    <Stat
      title={translations.dashboard.portfolioPositions.marketValue}
      value={formatCurrency(aggregation.marketValue)}
    />
  ) : null;

  const marketChange = aggregation.marketChange ? (
    <Stat
      title={translations.dashboard.portfolioPositions.marketChange}
      value={formatCurrency(aggregation.marketChange)}
    />
  ) : null;

  return (
    <Paper sx={paperSx} elevation={4}>
      <Stack direction="column" spacing={1}>
        <PieChartTooltipRow fill={fill} name={name} value={formattedValue} />
        {notional}
        {numberOfProducts}
        {marketValue}
        {marketChange}
      </Stack>
    </Paper>
  );
};
