import { ReactElement } from 'react';

import { useNoteSpecificDetails } from '@halo-common/hooks';
import { ProductDetailModalLayoutHeader } from '@halo-common/layouts';
import {
  ExecutionAuctionReviewDetailModalHeaderActions,
  ExecutionAuctionReviewDetailModalHeaderFeatures,
  ExecutionAuctionReviewDetailModalHeaderSolvableParameter,
} from '@halo-common/modals';
import { AuctionAdminModel } from '@halo-common/models';

export type ExecutionAuctionReviewDetailModalHeaderProps = {
  product?: AuctionAdminModel | null;
  loading?: boolean;
  onClose?: () => void;
};

export const ExecutionAuctionReviewDetailModalHeader = ({
  product,
  loading = true,
  onClose,
}: ExecutionAuctionReviewDetailModalHeaderProps): ReactElement => {
  const note = product?.note;
  const noteId = note?.id;
  const noteType = note?.type;
  const productType = note?.productType;
  const features = note?.features;

  const { solvableParameter } = useNoteSpecificDetails(note);

  const slots = {
    features: (
      <ExecutionAuctionReviewDetailModalHeaderFeatures
        loading={loading}
        features={features}
        type={noteType}
        subtype={productType}
      />
    ),
    parameter: (
      <ExecutionAuctionReviewDetailModalHeaderSolvableParameter loading={loading} parameter={solvableParameter} />
    ),
    actions: <ExecutionAuctionReviewDetailModalHeaderActions loading={loading} noteId={noteId} />,
  };

  return <ProductDetailModalLayoutHeader onClose={onClose} slots={slots} />;
};
