import { useEffect, useRef } from 'react';

import { quoteValueMapAtom } from '@halo-atoms/quotes';
import { NoteModel } from '@halo-common/models';
import { roundFloatToSpecificDecimal } from '@halo-common/utils';
import { getRollingReturnsStatistics } from '@halo-data-sources/clients/rollingReturns';
import { RollingReturnsQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';

export type RollingReturnsStatisticsQueryPayload = {
  endDate: DateTime | null;
  startDate: DateTime | null;
  basket?: Record<string, number> | null;
  showAgainstNote?: boolean;
};

export type RollingReturnsStatisticsModel = {
  id?: string;
  path: Array<string>;
  name: string;
};

export type RollingReturnsStatisticsCouponModel = RollingReturnsStatisticsModel & {
  lifeSpan?: number;
  numberCoupons?: string;
  percentCoupons?: number;
  annualizedYield?: number;
};

export type RollingReturnsStatisticsFrequencyModel = RollingReturnsStatisticsModel & {
  lifeSpan?: number;
  negative?: number;
  positive?: number;
  zero?: number;
};

export type RollingReturnsStatisticsPerformanceModel = RollingReturnsStatisticsModel & {
  lifeSpan?: number;
  average?: number;
  best?: number;
  worst?: number;
};

export type RollingReturnsStatisticsPrincipalReturnsModel = RollingReturnsStatisticsModel & {
  lifeSpan?: number;
  average?: number;
  worst?: number;
  full?: number;
};

export type RollingReturnsStatisticsQueryResult = {
  coupon: Array<RollingReturnsStatisticsCouponModel>;
  frequency: Array<RollingReturnsStatisticsFrequencyModel>;
  performance: Array<RollingReturnsStatisticsPerformanceModel>;
  principalReturns: Array<RollingReturnsStatisticsPrincipalReturnsModel>;
};

const NOTE_PATH_NAME = 'Note';

const getRollingReturnsStatisticsQueryFn = async (
  productId?: number,
  quoteId?: number,
  payload?: RollingReturnsStatisticsQueryPayload,
) => {
  if (!productId) return null;

  const startDate = payload?.startDate?.toISODate() ?? DateTime.now().minus({ years: 1 }).toISODate();
  const endDate = payload?.endDate?.toISODate() ?? DateTime.now().toISODate();

  if (startDate === endDate) return null;

  const response = await getRollingReturnsStatistics({
    quote_id: quoteId,
    date_range: { start_date: startDate, end_date: endDate },
    note_id: productId,
  });

  const isSingle = Boolean(response.underliers_statistics.length && response.underliers_statistics.length === 1);
  const isWeighted = Boolean(payload?.basket);
  const isWorstOf = !isSingle && !isWeighted;

  const noteStats = response.note_statistics;
  const showAgainstNote = Boolean(payload?.showAgainstNote && noteStats);

  let basketPathName = response.underliers_statistics?.[0]?.tradable_name;
  if (isWorstOf) basketPathName = 'Worst-of Basket';
  else if (isWeighted) basketPathName = 'Weighted Basket';

  const formatUnderlyingName = (name: string) => {
    return isWeighted ? `${payload?.basket?.[name]?.toFixed(2)}% ${name}` : name;
  };

  const sortUnderliers = (a: { tradable_name: string }, b: { tradable_name: string }) => {
    if (!isWeighted || !payload?.basket) return 0;
    const aWeight = payload.basket[a.tradable_name];
    const bWeight = payload.basket[b.tradable_name];
    return aWeight < bWeight ? 1 : aWeight > bWeight ? -1 : 0;
  };

  const convertToPercentage = (value?: number) => {
    return typeof value === 'number' ? roundFloatToSpecificDecimal(value * 100) : undefined;
  };

  const couponRows: Array<RollingReturnsStatisticsCouponModel> = [];
  if (showAgainstNote) {
    const couponsPaid = noteStats?.income?.average_number_coupons_paid;
    const couponsAvailable = noteStats?.income?.average_number_coupons_available;
    const showNumberCouponsPaid = couponsPaid && couponsAvailable;
    const numberOfCoupons = showNumberCouponsPaid
      ? `${roundFloatToSpecificDecimal(couponsPaid)} / ${roundFloatToSpecificDecimal(couponsAvailable)}`
      : undefined;

    couponRows.push({
      id: NOTE_PATH_NAME,
      path: [NOTE_PATH_NAME],
      name: NOTE_PATH_NAME,
      lifeSpan: roundFloatToSpecificDecimal(noteStats?.income?.average_lifespan),
      numberCoupons: numberOfCoupons,
      percentCoupons: convertToPercentage(noteStats?.income?.coupon_percent),
      annualizedYield: convertToPercentage(noteStats?.income?.realized_annual_yield),
    });
  }

  const underlyingFrequencyData = response.effective_underlier_statistics.returns_performance;
  const noteFrequencyData = noteStats?.returns_performance;
  const frequencyUnderlyingRows = !isSingle
    ? response.underliers_statistics.sort(sortUnderliers).map((stats) => {
        const name = formatUnderlyingName(stats.tradable_name);

        return {
          id: `${stats.tradable_name}-frequency-stats`,
          name,
          path: [basketPathName, name],
          negative: convertToPercentage(stats.returns_performance.negative),
          positive: convertToPercentage(stats.returns_performance.positive),
          zero: convertToPercentage(stats.returns_performance.zero),
        };
      })
    : [];

  const frequencyRows: Array<RollingReturnsStatisticsFrequencyModel> = [
    {
      id: basketPathName,
      path: [basketPathName],
      name: basketPathName,
      negative: convertToPercentage(underlyingFrequencyData.negative),
      positive: convertToPercentage(underlyingFrequencyData.positive),
      zero: convertToPercentage(underlyingFrequencyData.zero),
    },
  ];

  if (showAgainstNote) {
    frequencyRows.push({
      id: NOTE_PATH_NAME,
      path: [NOTE_PATH_NAME],
      name: NOTE_PATH_NAME,
      lifeSpan: roundFloatToSpecificDecimal(noteStats?.income?.average_lifespan),
      negative: convertToPercentage(noteFrequencyData?.negative),
      positive: convertToPercentage(noteFrequencyData?.positive),
      zero: convertToPercentage(noteFrequencyData?.zero),
    });
  }

  const underlyingPerformanceData = response.effective_underlier_statistics.basic;
  const notePerformanceData = noteStats?.basic;
  const performanceUnderlyingRows = !isSingle
    ? response.underliers_statistics.sort(sortUnderliers).map((stats) => {
        const name = formatUnderlyingName(stats.tradable_name);

        return {
          id: `${stats.tradable_name}-performance-stats`,
          name,
          path: [basketPathName, name],
          average: convertToPercentage(stats.basic.average),
          best: convertToPercentage(stats.basic.best),
          worst: convertToPercentage(stats.basic.worst),
        };
      })
    : [];

  const performanceRows: Array<RollingReturnsStatisticsPerformanceModel> = [
    {
      id: basketPathName,
      path: [basketPathName],
      name: basketPathName,
      average: convertToPercentage(underlyingPerformanceData.average),
      best: convertToPercentage(underlyingPerformanceData.best),
      worst: convertToPercentage(underlyingPerformanceData.worst),
    },
  ];

  if (showAgainstNote) {
    performanceRows.push({
      id: NOTE_PATH_NAME,
      path: [NOTE_PATH_NAME],
      name: NOTE_PATH_NAME,
      lifeSpan: roundFloatToSpecificDecimal(noteStats?.income?.average_lifespan),
      average: convertToPercentage(notePerformanceData?.average),
      best: convertToPercentage(notePerformanceData?.best),
      worst: convertToPercentage(notePerformanceData?.worst),
    });
  }

  const underlyingPrincipalReturnsData = response.effective_underlier_statistics.principal_returns;
  const notePrincipalReturnsData = noteStats?.principal_returns;
  const principalReturnsUnderlyingRows = !isSingle
    ? response.underliers_statistics.sort(sortUnderliers).map((stats) => {
        const name = formatUnderlyingName(stats.tradable_name);

        return {
          id: `${stats.tradable_name}-principal-stats`,
          name,
          path: [basketPathName, name],
          average: convertToPercentage(stats.principal_returns.average),
          worst: convertToPercentage(stats.principal_returns.worst),
          full: convertToPercentage(stats.principal_returns.full_or_more),
        };
      })
    : [];

  const principalReturnRows: Array<RollingReturnsStatisticsPrincipalReturnsModel> = [
    {
      id: basketPathName,
      path: [basketPathName],
      name: basketPathName,
      average: convertToPercentage(underlyingPrincipalReturnsData.average),
      worst: convertToPercentage(underlyingPrincipalReturnsData.worst),
      full: convertToPercentage(underlyingPrincipalReturnsData.full_or_more),
    },
  ];

  if (showAgainstNote) {
    principalReturnRows.push({
      id: NOTE_PATH_NAME,
      path: [NOTE_PATH_NAME],
      name: NOTE_PATH_NAME,
      lifeSpan: roundFloatToSpecificDecimal(noteStats?.income?.average_lifespan),
      average: convertToPercentage(notePrincipalReturnsData?.average),
      worst: convertToPercentage(notePrincipalReturnsData?.worst),
      full: convertToPercentage(notePrincipalReturnsData?.full_or_more),
    });
  }

  return {
    coupon: couponRows,
    frequency: [...frequencyRows, ...frequencyUnderlyingRows],
    performance: [...performanceRows, ...performanceUnderlyingRows],
    principalReturns: [...principalReturnRows, ...principalReturnsUnderlyingRows],
  };
};

export const useRollingReturnsStatisticsQuery = (
  product?: NoteModel | null,
  filters?: RollingReturnsStatisticsQueryPayload,
  options?: Partial<UseQueryOptions<RollingReturnsStatisticsQueryResult | null, Error>>,
): UseQueryResult<RollingReturnsStatisticsQueryResult | null, Error> => {
  const quoteIdRef = useRef<number | undefined>();

  const productId = product?.id;

  const quoteValueMap = useAtomValue(quoteValueMapAtom);
  const quoteDetails = productId ? quoteValueMap[productId] : undefined;

  const isInitialQuote = quoteDetails && !quoteDetails.updated;
  const quote = isInitialQuote ? quoteDetails.recent : undefined;
  const quoteId = isInitialQuote ? quote?.id : quoteIdRef.current;

  const startDate = filters?.startDate?.toISODate() ?? DateTime.now().minus({ years: 1 }).toISODate();
  const endDate = filters?.endDate?.toISODate() ?? DateTime.now().toISODate();
  const keyRange = `${startDate}-${endDate}`;

  useEffect(() => {
    if (typeof quoteId === 'number') quoteIdRef.current = quoteId;
  }, [quoteId]);

  return useQuery<RollingReturnsStatisticsQueryResult | null, Error>({
    queryKey: RollingReturnsQueryKeyFactory.stats(productId, quoteId, keyRange),
    queryFn: () => getRollingReturnsStatisticsQueryFn(productId, quoteId, filters),
    retry: false,
    ...options,
  });
};
