import { MouseEvent, ReactElement } from 'react';

import { anchorElAtom } from '@halo-atoms/common';
import { orderBookAuctionAllocationEditDeleteAtom, orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { useBuyerAuctionQuery } from '@halo-data-sources/queries';
import {
  GridColDef,
  HaloDataGrid,
  HaloDataGridProps,
  Iconography,
  LocalizedButton,
  LocalizedTypography,
  mapNumberToLocalCurrency,
} from '@halodomination/halo-fe-common';
import { Divider, Stack } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { DeleteAllocationPopover, DeleteAllocationPopoverAnchor } from '../DeleteAllocationPopover';
import { EditAllocationPopover, EditAllocationPopoverAnchor } from '../EditAllocationPopover';

const buttonSx = {
  px: 1,
  minWidth: 'auto',
};

const dividerSx = {
  transform: 'rotate(15deg)',
  height: '42px',
};

export const SubmittedAllocationsTable = (): ReactElement => {
  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);
  const setAllocation = useSetAtom(orderBookAuctionAllocationEditDeleteAtom);
  const [anchorElMap, setAnchorElMap] = useAtom(anchorElAtom);

  const auctionId = selectedAuctionId?.toString();

  const { data: auction } = useBuyerAuctionQuery(auctionId);

  const rows = auction?.allocations || [];

  const total = rows.reduce((prev, row) => prev + row.amount, 0);
  const totalColor = auction?.notional && total > auction.notional ? 'error' : 'text.primary';

  const currencyCode = auction?.note.currencyCode;
  const currencySymbol = auction?.note.currencySymbol;

  const mapCurrency = (value?: number) => {
    if (value === undefined) return value;
    const maximumFractionDigits = value < 1 ? 2 : 0;
    return value && value !== 0
      ? mapNumberToLocalCurrency(value, { currency: currencyCode, maximumFractionDigits }, currencySymbol)
      : '-';
  };

  const commonCellProps: Partial<GridColDef> = {
    flex: 1,
    editable: false,
    resizable: true,
    disableColumnMenu: true,
    sortable: true,
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      field: 'account.household',
      headerName: translations.common.household,
      valueGetter: (_, row) => row.account?.household ?? '--',
    },
    {
      ...commonCellProps,
      field: 'account.name',
      headerName: translations.common.name,
      valueGetter: (_, row) => row.account?.name,
    },
    {
      ...commonCellProps,
      field: 'account.number',
      headerName: translations.common.number,
      valueGetter: (_, row) => row.account?.accountId,
    },
    {
      ...commonCellProps,
      field: 'account.custodian',
      headerName: translations.common.custodian,
      valueGetter: (_, row) => row.account?.custodian?.name,
    },
    {
      ...commonCellProps,
      field: 'notional',
      headerName: translations.common.notional,
      valueGetter: (_, row) => row.amount,
      valueFormatter: mapCurrency,
    },
    {
      ...commonCellProps,
      field: 'actions',
      headerName: translations.common.actions,
      align: 'center',
      flex: 0.6,
      renderCell: ({ row }) => {
        const handleEdit = (event: MouseEvent<HTMLButtonElement>) => {
          const { id: allocationId, amount: notional } = row;
          const account = { account: row.account } as AccountTypeAheadOption;

          setAllocation({ allocationId, accountOption: account, notional });
          setAnchorElMap({ ...anchorElMap, [EditAllocationPopoverAnchor]: event.currentTarget });
        };

        const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
          const { id: allocationId, amount: notional } = row;
          const account = { account: row.account } as AccountTypeAheadOption;

          setAllocation({ allocationId, accountOption: account, notional });
          setAnchorElMap({ ...anchorElMap, [DeleteAllocationPopoverAnchor]: event.currentTarget });
        };

        return (
          <Stack direction="row" justifyItems="space-evenly" spacing={1}>
            <LocalizedButton sx={buttonSx} variant="outlined" size="extraSmall" onClick={handleEdit}>
              <Iconography prefix="fal" iconName="pencil" />
            </LocalizedButton>
            <LocalizedButton sx={buttonSx} variant="outlined" size="extraSmall" onClick={handleDelete}>
              <Iconography prefix="fal" iconName="trash" />
            </LocalizedButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
        <LocalizedTypography variant="h6" color="primary">
          {translations.orderBook.auctions.submittedAllocations}
        </LocalizedTypography>
        <Divider sx={{ flex: 1 }} />
      </Stack>
      <HaloDataGrid
        rows={rows}
        columns={columns}
        density="compact"
        pageSizeOptions={[10, 25, 50]}
        pagination
        disableRowSelectionOnClick
      />
      <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3}>
        <LocalizedTypography variant="subtitle1" color="text.secondary">
          {translations.orderBook.auctions.totalNotional}
        </LocalizedTypography>
        <Stack direction="column" alignItems="flex-end" spacing={1}>
          <LocalizedTypography variant="h6" color={totalColor}>
            {mapCurrency(total)}
          </LocalizedTypography>
          <LocalizedTypography variant="overline" color="text.secondary">
            {translations.common.allocated}
          </LocalizedTypography>
        </Stack>
        <Divider orientation="vertical" sx={dividerSx} />
        <Stack direction="column" alignItems="flex-end" spacing={1}>
          <LocalizedTypography variant="h6" color="text.primary">
            {mapCurrency(auction?.notional)}
          </LocalizedTypography>
          <LocalizedTypography variant="overline" color="text.secondary">
            {translations.common.committed}
          </LocalizedTypography>
        </Stack>
      </Stack>
      <EditAllocationPopover currencyCode={currencyCode} currencySymbol={currencySymbol} />
      <DeleteAllocationPopover currencyCode={currencyCode} currencySymbol={currencySymbol} />
    </Stack>
  );
};
