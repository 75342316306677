import { ReactElement } from 'react';

import { ActionButton } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import {
  useDeleteTermsheetRepoDocMutation,
  useDeleteTermsheetRepoDocMutationOptions,
} from '@halo-data-sources/mutations';
import { Iconography, LocalizedButton, LocalizedTypography, Modal } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';

export interface DeleteTermsheetModalProps extends useDeleteTermsheetRepoDocMutationOptions {
  ids: Array<number>;
  open: boolean;
  onClose: () => void;
}

export const DeleteTermsheetModal = ({
  ids,
  open,
  onClose,
  ...props
}: DeleteTermsheetModalProps): ReactElement | null => {
  const deleteMutation = useDeleteTermsheetRepoDocMutation({
    ...props,
    onSuccess: (...data) => {
      onClose();
      props.onSuccess?.(...data);
    },
  });

  const deleteTermsheet = () => deleteMutation.mutate(ids);

  if (!Array.isArray(ids) || ids.length === 0) return null;

  const footer = (
    <Stack direction="row" justifyContent="space-between" spacing={2}>
      <LocalizedButton onClick={onClose} color="primary" startIcon={<Iconography iconName="times" />}>
        {translations.common.cancel}
      </LocalizedButton>
      <ActionButton onClick={deleteTermsheet} variant="contained" color="error" loading={deleteMutation.isPending}>
        {translations.common.confirmDelete}
      </ActionButton>
    </Stack>
  );

  const overline =
    ids.length > 1 ? (
      <LocalizedTypography dynamicContent={{ count: ids.length }} color="error.main" variant="overline">
        {translations.documents.termsheets.termsheetsSelected}
      </LocalizedTypography>
    ) : null;

  return (
    <Modal
      open={open}
      size="small"
      onClose={onClose}
      overline={overline}
      title={translations.documents.termsheets.confirmDeleteTitle}
      subtitle={translations.documents.termsheets.confirmDeleteDescription}
      footer={footer}
      dismissible={false}
    />
  );
};
