import { ReactElement, ReactNode } from 'react';

import {
  DEFAULT_DYNAMIC_FILTER_SIDE_BAR_WIDTH,
  DynamicSearchFilters,
  DynamicTypeFiltersSideBar,
  DynamicTypeFiltersSideBarProps,
} from '@halo-common/layouts';
import { Stack, Theme } from '@mui/material';

const containerSx = {
  flex: 1,
  maxWidth: (theme: Theme) => `calc(100% - ${DEFAULT_DYNAMIC_FILTER_SIDE_BAR_WIDTH}px - ${theme.spacing(3)})`,
};

export type DynamicFiltersLayoutProps = DynamicTypeFiltersSideBarProps & {
  children: ReactNode;
  slots?: {
    searchFilters?: ReactNode;
    ExportButton?: ReactNode;
  };
};

export const DynamicFiltersLayout = ({
  children,
  tag,
  slots,
  filters,
  filterValues,
  rules,
}: DynamicFiltersLayoutProps): ReactElement => {
  const hasFilters = Array.isArray(filters) && filters.length > 0;

  const searchFilters = slots?.searchFilters ?? <DynamicSearchFilters tag={tag} slots={slots} rules={rules} />;

  const sidebar = hasFilters ? (
    <DynamicTypeFiltersSideBar tag={tag} filters={filters} filterValues={filterValues} rules={rules} />
  ) : null;

  return (
    <Stack direction="row" spacing={3}>
      {sidebar}
      <Stack sx={containerSx} direction="column">
        {searchFilters}
        {children}
      </Stack>
    </Stack>
  );
};
