import { ChangeEvent, DragEvent, ReactElement, useRef, useState } from 'react';

import { translations } from '@halo-common/translations';
import {
  Iconography,
  LocalizedButton,
  LocalizedTypography,
  Stack,
  useCombinedStyling,
} from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, Typography } from '@mui/material';

const btnStyling = {
  height: 'unset',
  padding: 0,
  textDecoration: 'underline',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

const contentStyling = (theme: HaloTheme) => ({
  padding: 3,
  border: `2px dashed ${theme.palette.common.black}00026`,
  color: `${theme.palette.common.black}00054`,
  maxWidth: 257,
  transition: 'background-color 0.15s ease-in-out',
});

const fileIconStyling = { display: 'flex' };

const fileNameStyling = (theme: HaloTheme) => ({
  color: `${theme.palette.common.black}00054`,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '800px',
});

const hoveredStyling = {
  backgroundColor: 'background.mask',
};

export type UploadFileModalSelectionStepProps = {
  onFileSelection: (file?: File | null) => void;
  file?: File | null;
  errorMessage?: string | ((file: File) => string);
  showError: boolean;
  types: string;
};

export const UploadFileModalSelectionStep = ({
  onFileSelection,
  file,
  errorMessage,
  showError,
  types,
}: UploadFileModalSelectionStepProps): ReactElement => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [hovered, setHovered] = useState(false);

  const fileSelected = Boolean(file);

  const errorMessageText = typeof errorMessage === 'string' ? errorMessage : errorMessage?.(file as File);

  const handleFileSelection = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const { files } = ev.target;
    onFileSelection(files?.[0]);
  };

  const handleDropEvent = (ev: DragEvent) => {
    ev.preventDefault();

    const { dataTransfer } = ev;
    const { files } = dataTransfer;

    onFileSelection(files.item(0));

    setHovered(false);
  };

  const handleDragOverEvent = (ev: DragEvent) => {
    ev.preventDefault();
    if (!hovered) setHovered(true);
  };

  const handleDragLeaveEvent = (ev: DragEvent) => {
    ev.preventDefault();
    if (hovered) setHovered(false);
  };

  const fileNameSection = !showError ? (
    <Stack direction="row" spacing={1} alignItems="center">
      <Iconography sx={fileIconStyling} iconName="file-upload" color="text.faded" size="1.25x" />
      <Typography sx={fileNameStyling}>{file?.name}</Typography>
    </Stack>
  ) : (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Iconography iconName="exclamation-triangle" color="error.main" size="1.25x" />
        <Typography color="error">{file?.name}</Typography>
      </Stack>
      {errorMessageText ? <LocalizedTypography color="error">{errorMessageText}</LocalizedTypography> : null}
    </Stack>
  );

  const fileNameContent = fileSelected ? fileNameSection : null;

  const combinedStyling = useCombinedStyling(contentStyling, hovered ? hoveredStyling : null);

  return (
    <form>
      <Stack direction="column" justify="center" alignItems="center" spacing={3}>
        <Box
          sx={combinedStyling}
          id="drop_zone"
          onDrop={handleDropEvent}
          onDragOver={handleDragOverEvent}
          onDragLeave={handleDragLeaveEvent}
        >
          <Stack direction="column" spacing={1} alignItems="center">
            <Iconography iconName="file-upload" color="text.faded" size="2x" />
            <LocalizedTypography variant="body1">{translations.profile.documents.dragAndDrop}</LocalizedTypography>
            <LocalizedTypography variant="body1">{translations.common.or}</LocalizedTypography>
            <LocalizedButton sx={btnStyling} color="primary" onClick={handleFileSelection} disableRipple>
              {translations.profile.documents.chooseFromComputer}
            </LocalizedButton>
          </Stack>
        </Box>
        {fileNameContent}
      </Stack>
      <input ref={fileInputRef} type="file" hidden onChange={handleFileChange} accept={types} />
    </form>
  );
};
