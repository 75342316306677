import { MouseEvent, ReactElement } from 'react';

import { anchorElAtom } from '@halo-atoms/common';
import { orderBookAuctionCommentPopoverAtom } from '@halo-atoms/orderbook';
import { ActionButton } from '@halo-common/components';
import { QuoteAuctionModel } from '@halo-common/models';
import { AuctionDetailsReviewCommentPopover } from '@halo-modules/app';
import { Iconography, Stack } from '@halodomination/halo-fe-common';
import { Alert } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';

const alertSx = {
  px: 2,
  py: 1,
};

export type ReviewCommentFooterProps = {
  quote?: QuoteAuctionModel;
  children: ReactElement;
};

export const ReviewCommentFooter = ({ quote, children }: ReviewCommentFooterProps): ReactElement | null => {
  const updateAnchorElMap = useSetAtom(anchorElAtom);
  const [popoverData, setPopoverData] = useAtom(orderBookAuctionCommentPopoverAtom);

  const declined = quote?.comment?.buyerApproved === false;
  const accepted = quote?.comment?.buyerApproved === true;
  const commentMap = popoverData.commentMap;
  const popoverName = AuctionDetailsReviewCommentPopover;
  const comment = quote?.comment;
  const commentText = comment?.comment || 'Placeholder comment content';
  const isLoading = comment && commentMap ? commentMap[comment.id] : false;
  const iconName = declined ? 'comment-xmark' : accepted ? 'comment-check' : 'comment-exclamation';

  const reviewCommentIcon = <Iconography iconName={iconName} prefix="far" color="primary.main" />;
  const commentIcon = <Iconography iconName="comment" />;

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    updateAnchorElMap({ [popoverName]: event.currentTarget });
    setPopoverData({ quote });
  };

  return (
    <Stack direction="column" spacing={1}>
      {children}
      <Stack direction="row" xs={12} sm={[8, 4]} spacing={2}>
        <Alert severity="info" variant="filled" icon={commentIcon} sx={alertSx}>
          {commentText}
        </Alert>
        <ActionButton
          variant="outlined"
          size="medium"
          startIcon={reviewCommentIcon}
          fullWidth
          onClick={handlePopoverOpen}
          loading={isLoading}
        >
          Review Comment
        </ActionButton>
      </Stack>
    </Stack>
  );
};
