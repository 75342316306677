export const EXECUTION_HUB_EDIT_REOFFER_MODAL = {
  exceeds50BpsMessage: `The updated Reoffer is more than the typical range of 50bps. Please review and confirm this is correct.`,
  exceedsMaxCommissionMessage: `The updated Reoffer is more than the maximum commission for the Brokerage Account Type. The Reoffer will be {maxCommissionValue}.`,
  maxCommissionColumn: `{maxCommission}% Max`,
  maxCommissionMessage: `The maximum commission for this account type is {maxCommission}%.`,
  reofferTextfieldLabel: `Update Reoffer`,
  originalReofferTextfieldLabel: `Original Reoffer`,
  accountTypeLabel: 'Select Account Types',
  confirmAndSaveButton: 'Confirm & Save',
  editReofferModalTitle: 'Edit Reoffer',
  confirmReofferModalTitle: 'Confirm Reoffer Change',
};

export const EXECUTION_HUB_FILL_MODAL = {
  errorMessage: 'An error occurred and no orders were filled. Please try again.',
};
