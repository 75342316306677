import { ReactElement } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { OrderTicketFormModelFields } from '@halo-common/modals';
import { useCalendarAllocationsQuery } from '@halo-data-sources/queries';
import { Iconography, Modal, Stack, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Alert, Button } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useFormContext } from 'react-hook-form';

export type OrderTicketConfirmationModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
};

export const OrderTicketConfirmationModal = ({
  open,
  onClose,
  onSubmit,
}: OrderTicketConfirmationModalProps): ReactElement => {
  const { watch } = useFormContext<OrderTicketFormModelFields>();

  const account = watch('account');
  const selectedCalendar = useAtomValue(calendarSelectedAtom);

  const { data: allocations = [] } = useCalendarAllocationsQuery(selectedCalendar?.id);

  const filteredAllocations = allocations.filter((allocation) => {
    return (
      allocation.account.id === account?.account?.id &&
      allocation.status !== 'canceled' &&
      allocation.status !== 'rejected'
    );
  });

  const accountText = account?.account?.name ?? account?.account?.accountId;
  const allocationCount = filteredAllocations.length;
  const allocationTotal = filteredAllocations.reduce((total, { amount }) => total + amount, 0);

  const [allocationTotalText] = useCurrencyConverter(allocationTotal);

  const alertText = `${accountText} currently has ${allocationCount} existing allocations totaling ${allocationTotalText} notional on this offering. Continuing with this order will submit an additional order for this account.`;

  const handleSubmit = () => void onSubmit();

  return (
    <Modal
      open={open}
      onClose={onClose}
      ContentProps={{ sx: { py: 1, px: 2 } }}
      FooterProps={{ sx: { p: 2 } }}
      size="small"
      title="Warning: Existing Allocation for this account"
      footer={
        <Stack direction="row" justify="space-between" alignItems="center">
          <Button variant="text" onClick={onClose} startIcon={<Iconography iconName="arrow-left" />}>
            Go Back
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            Acknowledge & Continue To Review
          </Button>
        </Stack>
      }
    >
      <Alert variant="filled" severity="warning">
        {alertText}
      </Alert>
    </Modal>
  );
};
