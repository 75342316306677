import { useMemo } from 'react';

import { UserRoleEnum } from '@halo-common/enums';
import { useUserInfoQuery } from '@halo-data-sources/queries';

export const useIsDocumentRepositoryManager = (): boolean => {
  const { data: user } = useUserInfoQuery();

  const isAdmin = useMemo(
    () => user?.details?.roles?.includes(UserRoleEnum.DocumentRepositoryManager) ?? false,
    [user],
  );

  return isAdmin;
};
