import { TradingDaysModel } from '@halo-common/models';
import { getTradingDays } from '@halo-data-sources/clients';
import { ApiTradingDaysMapper, TradingDaysMapper } from '@halo-data-sources/mappers';
import { TradingDaysQueryKeyFactory } from '@halo-data-sources/queries';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { DateTime } from 'luxon';

export type TradingDaysQueryPayload = {
  startDate: DateTime;
  endDate: DateTime;
};

const getTradingDaysQueryFn = async (payload: TradingDaysQueryPayload) => {
  const request = TradingDaysMapper.toApiTradingDaysRequestModel(payload);

  const response = await getTradingDays(request);

  return ApiTradingDaysMapper.toTradingDaysModel(response);
};

export const useTradingDaysQuery = (payload: TradingDaysQueryPayload): UseQueryResult<TradingDaysModel, Error> =>
  useQuery<TradingDaysModel, Error>({
    queryKey: TradingDaysQueryKeyFactory.range(payload),
    queryFn: () => getTradingDaysQueryFn(payload),
  });
