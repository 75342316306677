import { FixDuck } from './FixDuck';
import * as selectors from './FixSelectors';
import * as thunks from './FixThunks';

export const FixSelectors = selectors;

export const FixActions = {
  ...FixDuck.actions,
  ...thunks,
};

export const FixDuckName = FixDuck.name;

export const FixReducer = FixDuck.reducer;

export * from './FixModel';
