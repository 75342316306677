import { ReactElement } from 'react';

import { useNoteSpecificDetails } from '@halo-common/hooks';
import { AuctionTableModal, AuctionNoteDetailsHeader } from '@halo-modules/admin';
import { OrdersActions, OrdersSelectors, OrderStatusEnum } from '@halo-stores/Orders';
import { Iconography, LocalizedTypography, Stack, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Button, Container, Divider, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

const dataRowStyling = { backgroundColor: 'common.white', padding: 2 };

export const TermsReviewModal = (): ReactElement => {
  const dispatch = useDispatch();

  const orderStatus = useSelector(OrdersSelectors.selectStatus);
  const selectedAuction = useSelector(OrdersSelectors.selectSelectedAuction);

  const modalOpen = orderStatus === OrderStatusEnum.showTermsReviewedModal;

  const {
    id: auctionId,
    buyer,
    organizationName,
    note,
    winningQuote,
    style,
    targetStrikeDate,
    notional,
    targetTradeDate,
    targetSettleDate: settlementDate,
    createdAt: startDate,
    endDateTime: endDate,
  } = selectedAuction ?? {};

  const { highlights: noteSpecificFields } = useNoteSpecificDetails(note);
  const [priceText, notionalText] = useCurrencyConverter(
    { price: note?.price, notional },
    { currencyCode: note?.currencyCode },
  );

  const winningIssuer = winningQuote?.issuer;

  const handleModalClose = () => void dispatch(OrdersActions.selectAuction({ auction: null }));
  const handleModalSecondaryAction = () => void dispatch(OrdersActions.rejectPrelimTerms(auctionId));
  const handleModalPrimaryAction = () => void dispatch(OrdersActions.acceptPrelimTerms(auctionId));
  const handleDownloadAction = () => void dispatch(OrdersActions.getPrelimTerms(auctionId));

  return (
    <AuctionTableModal
      onClose={handleModalClose}
      open={modalOpen}
      title="Review Terms"
      auctionId={auctionId}
      buyer={buyer}
      organizationName={organizationName}
      HeaderProps={{ AdditionalContent: <AuctionNoteDetailsHeader note={note} /> }}
      footer={
        <Stack direction="row" justify="space-between">
          <Button
            type="button"
            color="primary"
            variant="text"
            onClick={handleDownloadAction}
            startIcon={<Iconography iconName="file-download" color="primary.main" />}
          >
            Download Terms
          </Button>
          <Stack direction="row" spacing={2}>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={handleModalSecondaryAction}
              endIcon={<Iconography iconName="times" color="primary.main" />}
            >
              Reject Terms
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleModalPrimaryAction}
              endIcon={<Iconography iconName="check" color="primary.main" />}
            >
              Accept Terms
            </Button>
          </Stack>
        </Stack>
      }
    >
      <Container maxWidth="md" disableGutters>
        <Stack sx={dataRowStyling} direction="row" xs={6}>
          <Typography variant="body2">Issuer:</Typography>
          <Typography variant="body2">{winningIssuer}</Typography>
        </Stack>
        <Divider />
        <Stack sx={dataRowStyling} direction="row" xs={6}>
          <Typography variant="body2">Notional:</Typography>
          <Typography variant="body2">{notionalText}</Typography>
        </Stack>
        <Divider />
        <Stack sx={dataRowStyling} direction="row" xs={6}>
          <Typography variant="body2">Price:</Typography>
          <Typography variant="body2">{priceText}</Typography>
        </Stack>
        <Divider />
        <Stack sx={dataRowStyling} direction="row" xs={6}>
          <Typography variant="body2">Auction Style:</Typography>
          <Typography variant="body2">{style}</Typography>
        </Stack>
        <Divider />
        {noteSpecificFields.map(({ label, text, dynamicContent }) => (
          <div key={label}>
            <Stack sx={dataRowStyling} direction="row" xs={6}>
              <Typography variant="body2">{label}</Typography>
              <LocalizedTypography variant="body2" dynamicContent={dynamicContent}>
                {text}
              </LocalizedTypography>
            </Stack>
            <Divider />
          </div>
        ))}
        <Stack sx={dataRowStyling} direction="row" xs={6}>
          <Typography variant="body2">Strike Date:</Typography>
          <Typography variant="body2">{targetStrikeDate}</Typography>
        </Stack>
        <Divider />
        <Stack sx={dataRowStyling} direction="row" xs={6}>
          <Typography variant="body2">Trade Date:</Typography>
          <Typography variant="body2">{targetTradeDate}</Typography>
        </Stack>
        <Divider />
        <Stack sx={dataRowStyling} direction="row" xs={6}>
          <Typography variant="body2">Settlement Date:</Typography>
          <Typography variant="body2">{settlementDate}</Typography>
        </Stack>
        <Divider />
        <Stack sx={dataRowStyling} direction="row" xs={6}>
          <Typography variant="body2">Auction Start Time:</Typography>
          <Typography variant="body2">{startDate}</Typography>
        </Stack>
        <Divider />
        <Stack sx={dataRowStyling} direction="row" xs={6}>
          <Typography variant="body2">Auction End Time:</Typography>
          <Typography variant="body2">{endDate}</Typography>
        </Stack>
      </Container>
    </AuctionTableModal>
  );
};
