import { WatchlistModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { updateWatchlist } from '@halo-data-sources/clients';
import { HttpError } from '@halo-data-sources/errors';
import { ApiWatchlistMapper, WatchlistMapper } from '@halo-data-sources/mappers';
import { WatchlistQueryKeyFactory } from '@halo-data-sources/queries';
import { IconographyProps, useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

export type UpdateWatchlistMutationPayload = {
  id?: number | null;
  name?: string;
  icon?: IconographyProps['iconName'];
};

export type UpdateWatchlistMutationResult = {
  watchlist: WatchlistModel;
  message: string;
};

const updateWatchlistMutationFn = async (payload?: UpdateWatchlistMutationPayload) => {
  if (!payload || !payload?.id) return null;

  try {
    const icon = payload.icon ? WatchlistMapper.toApiWatchlistIconName(payload.icon) : undefined;
    const updatedPayload = { ...payload, icon };
    const response = await updateWatchlist(payload.id, updatedPayload);

    return {
      watchlist: ApiWatchlistMapper.toWatchlistModel(response),
      message: 'Successfully updated watchlist',
    };
  } catch (e) {
    const parsedError = e as HttpError;
    // NOTE: BE sends the wrong code for duplicates.
    const isDuplicateError = parsedError.status === 400;
    const msg = isDuplicateError
      ? 'A Watchlist with this name already exists, please choose a different name.'
      : parsedError.message;

    throw new Error(msg);
  }
};

export const useUpdateWatchlistMutation = (): UseMutationResult<
  UpdateWatchlistMutationResult | null,
  Error,
  UpdateWatchlistMutationPayload | undefined
> => {
  const queryClient = useQueryClient();

  const { enqueueSuccessEvent, enqueueErrorEvent } = useSnackbar();

  return useMutation({
    mutationFn: updateWatchlistMutationFn,
    onError: () => {
      enqueueErrorEvent({ message: translations.watchlist.common.updateWatchlistError });
    },
    onSuccess: (data) => {
      if (!data) return undefined;

      enqueueSuccessEvent({ message: translations.watchlist.common.updateWatchlistSuccess });

      const key = WatchlistQueryKeyFactory.all();
      queryClient.setQueryData<Array<WatchlistModel>>(key, (prev) => {
        if (!prev) return undefined;

        const watchlistIndex = prev.findIndex((watchlist) => watchlist.id === data.watchlist.id);
        const updatedWatchlists = [...prev];
        updatedWatchlists.splice(watchlistIndex, 1, data.watchlist);

        return updatedWatchlists;
      });
    },
  });
};
