import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type OrdersClientQueryKeys = {
  all: QueryKeyFactoryFn;
  calendarOrder: QueryKeyFactoryFn;
  calendarOrderPreview: QueryKeyFactoryFn;
  calendarOrders: QueryKeyFactoryFn;
  calendarOrderByStatus: QueryKeyFactoryFn;
};

export const OrdersClientQueryKeyFactory: OrdersClientQueryKeys = {
  all: () => ['orders'],
  calendarOrder: (allocationId) => [...OrdersClientQueryKeyFactory.all(), allocationId],
  calendarOrderPreview: (query) => [...OrdersClientQueryKeyFactory.all(), 'calendar', hash(query), 'preview'],
  calendarOrders: (query) => [...OrdersClientQueryKeyFactory.all(), 'by', 'calendarId', hash(query)],
  calendarOrderByStatus: (query) => [...OrdersClientQueryKeyFactory.all(), 'by', 'status', hash(query)],
};
