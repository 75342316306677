import { CalendarModel } from '@halo-common/models';
import { getCalendar, getCalendarOfferings } from '@halo-data-sources/clients';
import { ApiCalendarsMapper } from '@halo-data-sources/mappers';
import { FixedIncomeQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getFixedIncomeCalendarFn = async (product: CalendarModel | null) => {
  if (!product?.id) return null;

  const id = product.id;

  const [details, calendar] = await Promise.all([
    getCalendarOfferings({ calendar_note_id: id }),
    getCalendar(id.toString()),
  ]);

  const hydratedCalendar = { ...calendar.calendar, is_favorite: details.calendars[0].is_favorite };

  const mappedCalendar = ApiCalendarsMapper.toCalendarModel(hydratedCalendar);

  return { ...mappedCalendar, noteV2: product.noteV2 };
};

export const useFixedIncomeCalendarQuery = (
  product: CalendarModel | null,
): UseQueryResult<CalendarModel | null, Error> =>
  useQuery<CalendarModel | null, Error>({
    queryKey: FixedIncomeQueryKeyFactory.fixedIncome(product?.id),
    queryFn: () => getFixedIncomeCalendarFn(product),
  });
