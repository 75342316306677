import { ChangeEvent, ReactElement, useEffect } from 'react';

import { targetAtom } from '@halo-atoms/auctions';
import { LexicalTypography } from '@halo-common/components';
import { NoteSpecificDetailsField, useNoteSpecificDetails } from '@halo-common/hooks';
import { ProductDetailPanelSection, ProductDetailPanelSectionField } from '@halo-common/layouts';
import { NoteModel } from '@halo-common/models';
import { useRecentQuotesQuery, useWatchlistTargetedNoteQuery } from '@halo-data-sources/queries';
import { Stack } from '@halodomination/halo-fe-common';
import { InputAdornment, TextField, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { v4 as uuid } from 'uuid';

const LOADING_FIELDS: Array<NoteSpecificDetailsField> = Array.from(Array(4), () => ({
  field: uuid(),
  text: '',
  label: '',
}));

export type AuctionNotePayoffDetailsProps = {
  product?: NoteModel | null;
  loading: boolean;
};

export const AuctionNotePayoffDetails = ({ product, loading }: AuctionNotePayoffDetailsProps): ReactElement | null => {
  const [target, setTarget] = useAtom(targetAtom);

  const { data: targetedNote } = useWatchlistTargetedNoteQuery(product?.id);

  const productIds = product ? [product.id] : [];
  const { data: quoteMap } = useRecentQuotesQuery(productIds);

  const { fields, solvableParameter } = useNoteSpecificDetails(product);

  const targetError = !target || Number(target) < 0.01;
  const payoffs = loading ? LOADING_FIELDS : fields;
  const targetLabel = solvableParameter?.label;

  const onTargetChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const newTarget = ev.target.value ? ev.target.value : '';
    setTarget(newTarget);
  };

  const sectionFields = payoffs.map(({ text, label, dynamicContent }) => (
    <ProductDetailPanelSectionField
      key={label}
      label={label}
      description={text}
      loading={loading}
      dynamicContent={dynamicContent}
    />
  ));

  useEffect(() => {
    const targetLevel = targetedNote?.target?.targetLevel;
    const quote = product ? quoteMap?.[product.id]?.recent?.value : undefined;
    if (targetLevel) setTarget(targetLevel.toString());
    else if (quote) setTarget(quote.toString());
  }, [targetedNote, product, quoteMap]);

  return !loading ? (
    <ProductDetailPanelSection title="Payoff Details" loading={loading}>
      <Stack direction="row" xs={6} alignItems="center">
        <Typography variant="body2" color="textSecondary">
          Target&nbsp;
          <LexicalTypography component="span" variant="body2" color="textSecondary" translationKey={targetLabel}>
            {targetLabel}
          </LexicalTypography>
        </Typography>
        <TextField
          name="target"
          size="small"
          type="number"
          slotProps={{ input: { endAdornment: <InputAdornment position="end">%</InputAdornment> } }}
          value={target}
          onChange={onTargetChange}
          error={targetError}
          fullWidth
        />
      </Stack>
      {sectionFields}
    </ProductDetailPanelSection>
  ) : null;
};
