import { ReactElement, useEffect } from 'react';

import { POSITIONS_DETAILS_MODAL, portfolioPositionDetailsModalAtom } from '@halo-atoms/portfolio';
import { PortfolioTabEnum, ProductTypeEnum } from '@halo-common/enums';
import { PostTradeAnnuityDetailModal } from '@halo-common/modals';
import { useLegacyRouter } from '@halo-common/providers';
import { useRecordActivityMutation } from '@halo-data-sources/mutations';
import { PortfolioProductDetailsNoteModal } from '@halo-modules/app';
import { useAtom } from 'jotai';

export const PortfolioProductDetailsModal = (): ReactElement => {
  const router = useLegacyRouter();

  const { mutate: recordActivity } = useRecordActivityMutation();

  const [positionModal, setPositionModal] = useAtom(portfolioPositionDetailsModalAtom);

  const { selectedPositionAtom, modalMap } = positionModal;

  const open = modalMap[POSITIONS_DETAILS_MODAL];

  const noteId = selectedPositionAtom?.id;
  const contractId = selectedPositionAtom?.contractId;
  const noteType = selectedPositionAtom?.type;
  const isAnnuity = noteType === ProductTypeEnum.annuity;

  const handleCloseModal = () => void setPositionModal();

  useEffect(() => {
    if (open && isAnnuity) {
      const selectedTabQuery = router.query.tab;
      const hasTabQueryParam = typeof selectedTabQuery === 'string';
      const selectedTab = hasTabQueryParam ? selectedTabQuery : PortfolioTabEnum.POSITION_TAB;

      const verb = `opened product details modal for position ${noteId} from ${selectedTab}`;

      recordActivity({ activity: [{ verb, model_name: 'Portfolio' }], fromIFrame: false });
    }
  }, [open, isAnnuity]);

  if (isAnnuity) return <PostTradeAnnuityDetailModal onClose={handleCloseModal} open={open} contractId={contractId} />;
  else return <PortfolioProductDetailsNoteModal />;
};
