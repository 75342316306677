import { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';

import { usePageLoaded, UsePageLoadedOptions } from '@halo-common/hooks';
import { AllocationModel, CalendarModel } from '@halo-common/models';
import { AdminCalendarStatusFilter } from '@halo-modules/admin';
import { CalendarPageQueryParams, CalendarQueryParams, CalendarSortingOptions } from '@halo-stores/Calendar';
import { PershingSelectors, PershingStatus } from '@halo-stores/Pershing';
import { useSelector } from 'react-redux';

const DEFAULT_STATUS_FILTER: AdminCalendarStatusFilter = 'available';

const NO_OP = () => {
  // NO-OP
};

const INITIAL_CONTENT_LOADING_MAP = {
  active: false,
  archived: false,
  available: false,
  confirmed: false,
  denied: false,
  filled: false,
  pending: false,
};

const INITIAL_TABLE_PAGE_MAP = {
  available: 1,
  archived: 1,
  confirmed: 1,
  denied: 1,
  filled: 1,
  pending: 1,
};

const INITIAL_CONTENT_SORTING_MAP = {
  available: [],
  archived: [],
  confirmed: [],
  denied: [],
  filled: [],
  pending: [],
};

const INITIAL_CONTEXT = {
  disableFilters: false,
  filtered: false,
  onStatusFilterChange: NO_OP,
  onQueryChange: NO_OP,
  pageLoaded: false,
  pageLoading: true,
  queryParams: null,
  statusFilter: DEFAULT_STATUS_FILTER,
  tableConfiguration: {
    contentLoading: false,
    onInfiniteScroll: NO_OP,
    onPageChange: NO_OP,
    onSortingChange: NO_OP,
    pageMap: INITIAL_TABLE_PAGE_MAP,
    paginatingMap: INITIAL_CONTENT_LOADING_MAP,
    sortMap: INITIAL_CONTENT_SORTING_MAP,
    sortingMap: INITIAL_CONTENT_LOADING_MAP,
  },
};

export type PershingAdminQueryParams = CalendarPageQueryParams | CalendarQueryParams | null | undefined;

export type PershingAdminSorting = CalendarSortingOptions | undefined;

export type PershingAdminSortingMap = {
  [key: string]: PershingAdminSorting;
  available: PershingAdminSorting;
  archived: PershingAdminSorting;
  confirmed: PershingAdminSorting;
  denied: PershingAdminSorting;
  filled: PershingAdminSorting;
  pending: PershingAdminSorting;
};

export type PershingAdminTablePageMap = {
  [key: string]: number;
  archived: number;
  available: number;
  confirmed: number;
  denied: number;
  filled: number;
  pending: number;
};

export type PershingAdminFiltersMap = {
  [key: string]: boolean;
  active: boolean;
  archived: boolean;
  available: boolean;
  confirmed: boolean;
  denied: boolean;
  filled: boolean;
  pending: boolean;
};

export type PershingAdminActions = {
  onOpenApprovalModal?: (calendar: CalendarModel, allocation: AllocationModel) => void;
  onOpenEditCalendarModal?: (calendar: CalendarModel) => void;
  onOpenFillOrderModal?: (calendar: CalendarModel) => void;
  onToggleUploadModal?: () => void;
};

export type PershingAdminTableConfiguration = {
  contentLoading: boolean;
  onInfiniteScroll: (filter: AdminCalendarStatusFilter, pageNumber: number) => void;
  onPageChange: (filter: AdminCalendarStatusFilter, pageNumber: number) => void;
  onSortingChange: (filter: AdminCalendarStatusFilter, sort?: PershingAdminSorting) => void;
  pageMap: PershingAdminTablePageMap;
  paginatingMap: PershingAdminFiltersMap;
  sortMap: PershingAdminSortingMap;
  sortingMap: PershingAdminFiltersMap;
};

export type PershingAdminContextModel = {
  actions?: PershingAdminActions;
  disableFilters: boolean;
  filtered: boolean;
  onQueryChange: (params?: PershingAdminQueryParams) => void;
  onStatusFilterChange: (filter: AdminCalendarStatusFilter) => void;
  pageLoaded: boolean;
  pageLoading: boolean;
  queryParams: PershingAdminQueryParams;
  statusFilter: AdminCalendarStatusFilter;
  tableConfiguration: PershingAdminTableConfiguration;
};

export type PershingAdminFiltersOptions = {
  successStatus: PershingStatus;
  loadingStatus: PershingStatus;
  failureStatus: PershingStatus;
};

export type PershingAdminProviderProps = {
  actions?: PershingAdminContextModel['actions'];
  children: ReactNode;
  contentLoadedOptions: PershingAdminFiltersOptions;
  defaultStatus: AdminCalendarStatusFilter;
  pageLoadedOptions: UsePageLoadedOptions<PershingStatus>;
  onInfiniteScroll?: (context: PershingAdminContextModel) => void;
  onPagination?: (
    filter: AdminCalendarStatusFilter,
    sorting: PershingAdminSorting,
    context: PershingAdminContextModel,
  ) => void;
  onSorting?: (
    filter: AdminCalendarStatusFilter,
    sorting: PershingAdminSorting,
    context: PershingAdminContextModel,
  ) => void;
};

export const PershingAdminContext = createContext<PershingAdminContextModel>(INITIAL_CONTEXT);

export const usePershingAdminContext = (): PershingAdminContextModel =>
  useContext<PershingAdminContextModel>(PershingAdminContext);

export const PershingAdminProvider = ({
  actions,
  children,
  contentLoadedOptions,
  defaultStatus,
  pageLoadedOptions,
  onInfiniteScroll,
  onPagination,
  onSorting,
}: PershingAdminProviderProps): ReactElement => {
  const pershingStatus = useSelector(PershingSelectors.selectStatus);

  const pageLoaded = usePageLoaded(PershingSelectors.selectStatus, pageLoadedOptions);

  const [providerState, setProviderState] = useState<PershingAdminContextModel>({
    ...INITIAL_CONTEXT,
    actions,
    pageLoaded,
    pageLoading: !pageLoaded,
    statusFilter: defaultStatus,
    disableFilters: pershingStatus === contentLoadedOptions.loadingStatus,
  });

  const handleStatusFilterChange = (filter: AdminCalendarStatusFilter) => {
    setProviderState({
      ...providerState,
      queryParams: null,
      statusFilter: filter,
      tableConfiguration: {
        ...providerState.tableConfiguration,
        contentLoading: true,
        sortMap: INITIAL_CONTEXT.tableConfiguration.sortMap,
        sortingMap: INITIAL_CONTEXT.tableConfiguration.sortingMap,
        pageMap: INITIAL_CONTEXT.tableConfiguration.pageMap,
        paginatingMap: INITIAL_CONTEXT.tableConfiguration.paginatingMap,
      },
    });
  };

  const handleQueryChange = (params?: PershingAdminQueryParams) => {
    const queryParams = params ?? null;

    setProviderState({
      ...providerState,
      filtered: Boolean(queryParams),
      queryParams: queryParams,
      tableConfiguration: {
        ...providerState.tableConfiguration,
        contentLoading: true,
        pageMap: INITIAL_TABLE_PAGE_MAP,
      },
    });
  };

  const handlePageChange = (filter: AdminCalendarStatusFilter, pageNumber: number) => {
    const sorting = providerState.tableConfiguration.sortMap[filter];

    const { paginatingMap, pageMap } = providerState.tableConfiguration;

    const updatedPaginationMap = { ...paginatingMap, [filter]: true };
    const updatedPageMap = { ...pageMap, [filter]: pageNumber };
    const updatedState = {
      ...providerState,
      tableConfiguration: {
        ...providerState.tableConfiguration,
        pageMap: updatedPageMap,
        paginatingMap: updatedPaginationMap,
      },
    };

    setProviderState(updatedState);
    onPagination?.(filter, sorting, updatedState);
  };

  const handleSortingChange = (filter: AdminCalendarStatusFilter, sort: PershingAdminSorting) => {
    const { sortingMap, sortMap } = providerState.tableConfiguration;

    const updatedSortingMap = { ...sortingMap, [filter]: true };
    const updatedSortMap = { ...sortMap, [filter]: sort };
    const updatedState = {
      ...providerState,
      tableConfiguration: {
        ...providerState.tableConfiguration,
        sortingMap: updatedSortingMap,
        sortMap: updatedSortMap,
      },
    };

    setProviderState(updatedState);
    onSorting?.(filter, sort, updatedState);
  };

  const handleInfiniteScroll = (filter: AdminCalendarStatusFilter, pageNumber: number) => {
    const { pageMap } = providerState.tableConfiguration;

    const updatedPageMap = { ...pageMap, [filter]: pageNumber };
    const updatedState = {
      ...providerState,
      tableConfiguration: {
        ...providerState.tableConfiguration,
        pageMap: updatedPageMap,
      },
    };

    setProviderState(updatedState);
    onInfiniteScroll?.(updatedState);
  };

  const context = {
    ...providerState,
    onQueryChange: handleQueryChange,
    onStatusFilterChange: handleStatusFilterChange,
    tableConfiguration: {
      ...providerState.tableConfiguration,
      onPageChange: handlePageChange,
      onSortingChange: handleSortingChange,
      onInfiniteScroll: handleInfiniteScroll,
    },
  };

  useEffect(() => {
    const failedRequest = pershingStatus === contentLoadedOptions.failureStatus;
    const successfulRequest = pershingStatus === contentLoadedOptions.successStatus;
    const requestFinished = failedRequest || successfulRequest;

    const disableFilters = pershingStatus === contentLoadedOptions.loadingStatus;

    const updatedTableConfiguration = requestFinished
      ? {
          ...providerState.tableConfiguration,
          contentLoading: false,
          paginatingMap: INITIAL_CONTENT_LOADING_MAP,
          sortingMap: INITIAL_CONTENT_LOADING_MAP,
        }
      : providerState.tableConfiguration;

    setProviderState({
      ...providerState,
      disableFilters,
      pageLoaded,
      pageLoading: !pageLoaded,
      tableConfiguration: updatedTableConfiguration,
    });
  }, [pershingStatus, pageLoaded]);

  return <PershingAdminContext.Provider value={context}>{children}</PershingAdminContext.Provider>;
};
