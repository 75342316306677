export enum WidgetEnginesEnum {
  NOTEFLIX = 'noteflix',
  WATCHLIST = 'watchlist',
  PORTFOLIO_LIFECYCLE = 'portfolio_lifecycle',
  PORTFOLIO_POSITIONS = 'portfolio_positions',
  PRODUCT_SHELF = 'calendar',
  EDUCATION = 'education',
  ORDER_BOOK = 'order_book',
  FEATURED_NOTES = 'featured_notes',
}
