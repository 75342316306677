export const DASHBOARD_NOTEFLIX_TRANSLATIONS = {
  listOfNotesForSelectedCategory: 'List of Notes for a selected Noteflix category.',
  pleaseSelectAnotherCategoryFromTheDropdown: 'Please select another category from the dropdown.',
  thereAreNotAnyNotesInThisCategory: 'There aren’t any notes in this category',
  visitNoteflix: 'Visit Noteflix',
  createNote: 'Create A Note',
  hereAre2WaysToGetStarted: 'Here are 2 ways to get started:',
  trackNoteValueOverTime: 'Track Note Value Over Time',
  addNotesToSetATargetAndObserveTrendsInValue: 'Add notes to set a target and observe trends in value',
  addProductsToAWatchlistToTrackIdeas:
    'Add products to a {eyeIcon} watchlist to track ideas, observe trends in yield value, or save quick templates for custom notes.',
};
