import { translations } from '@halo-common/translations';
import { downloadFile } from '@halo-common/utils';
import { getClientConfiguration, request } from '@halo-data-sources/clients';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { DateTime } from 'luxon';

export type GeneratePreTradeDocumentPayload = {
  additionalContent?: {
    cardFront?: string;
  };
  endpoint?: string;
  productId?: number;
  whiteLabelId?: number;
  publicNoteId?: string;
  observationType?: string;
  userId?: number;
  notional?: string;
  graphStartDate?: string;
};

export type GeneratePreTradeDocumentResponse = {
  download_url: string;
  template_erg_id: string;
  status: number;
  message: string;
};

const generatePreTradeDocumentFn = async (payload: GeneratePreTradeDocumentPayload) => {
  const { endpoint, publicNoteId, notional, graphStartDate, additionalContent } = payload;

  if (!endpoint || !publicNoteId) return undefined;

  const settlement = `T+${graphStartDate?.split(' ')[0]}`;

  const clientConfiguration = await getClientConfiguration();
  const url = clientConfiguration.basePath + endpoint;

  const isIndicative = Boolean(notional);
  const indicativeTermsheetPayload = isIndicative ? { notional, settlement } : undefined;

  const response: GeneratePreTradeDocumentResponse = await request(url, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      public_note_id: publicNoteId,
      note_card_front_html: additionalContent?.cardFront,
      ...indicativeTermsheetPayload,
    }),
  });

  const fileName = isIndicative ? `${DateTime.now()}_indicative_termsheet.pdf` : `${DateTime.now()}_note_proposal.pdf`;
  downloadFile({ fileName, downloadFilePath: response.download_url });
};

export const useGeneratePreTradeDocumentMutation = (): UseMutationResult<
  void,
  Error,
  GeneratePreTradeDocumentPayload
> => {
  const { enqueueErrorEvent } = useSnackbar();

  return useMutation<void, Error, GeneratePreTradeDocumentPayload>({
    mutationFn: generatePreTradeDocumentFn,
    onError: (error) => {
      const message = error.message || translations.common.error;
      enqueueErrorEvent({ message });
      return error;
    },
  });
};
