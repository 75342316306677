import { ReactElement } from 'react';

import { orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { AuctionStatusEnum } from '@halo-common/enums';
import { useCommonUserSetting } from '@halo-common/hooks';
import { useBuyerAuctionQuery } from '@halo-data-sources/queries';
import { Stack } from '@halodomination/halo-fe-common';
import { Skeleton, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';

const skeletonSx = {
  margin: 'auto',
};

export const AuctionDetailsStatusTitle = (): ReactElement => {
  const showAllocationsTab = useCommonUserSetting('Show_Auction_Allocations_Tab', 'Auction');
  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);
  const auctionId = selectedAuctionId?.toString();

  const { data: auction } = useBuyerAuctionQuery(auctionId);

  const pendingApprovalAllocationsText = showAllocationsTab
    ? 'You may begin to assign your client allocations to this auction at any time.'
    : '';

  const winAllocationsText = showAllocationsTab
    ? 'In the meantime, now is a good time to enter your Client Allocations or Prime account if you haven’t already.'
    : '';

  const termsheetAcceptedAllocationsText = showAllocationsTab
    ? 'Please make sure your Client Allocations are finalized and submitted in the Allocations tab.'
    : '';

  const statusBasedContents = {
    pendingApproval: [
      'Pending Approval',
      `Once this auction has been approved, Issuers will be notified and the live bidding process will begin. ${pendingApprovalAllocationsText}`,
    ],
    inAuction: [
      'Waiting For Bids',
      `Issuers have received your request for prices, and are running the numbers to get you the best price.`,
    ],
    chooseWinner: [
      'Choose Winning Issuer',
      `All banks have responded to your auction. If a winner is chosen, you are expected to move forward and execute the note with the terms of the auction.`,
    ],
    win: [
      `${auction?.winningQuote?.issuer} has been alerted that they’ve won, please hang tight as they prepare a Preliminary Termsheet.`,
      `We will let you know when it’s time to come back and review the Preliminary Termsheet. ${winAllocationsText}`,
    ],
    hasPrelim: [
      'Please carefully review this Preliminary Termsheet.',
      `Once you download the document, you may approve or reject. Rejecting will terminate the auction immediately, and cannot be undone.`,
    ],
    termSheetAccepted: ['Halo is now booking your purchase with the Issuer.', termsheetAcceptedAllocationsText],
    purchased: [
      'Purchase Complete!',
      `It usually takes a couple of days for your order to be processed and uploaded to the platform. You will be sent an email once the process is complete.`,
    ],
    canceled: ['Auction Canceled', ''],
    noWinner: ['No Winner Chosen', `A winner was not chosen in time, so this auction has been marked inactive.`],
  };

  const statusSkeleton = <Skeleton width="40%" sx={skeletonSx} />;
  const blurbSkeleton = (
    <Stack direction="column">
      <Skeleton sx={skeletonSx} />
      <Skeleton width="70%" sx={skeletonSx} />
    </Stack>
  );

  const statusContent = auction?.status
    ? {
        [AuctionStatusEnum.Canceled]: statusBasedContents.canceled,
        [AuctionStatusEnum.ChooseWinner]: statusBasedContents.chooseWinner,
        [AuctionStatusEnum.InAuction]: statusBasedContents.inAuction,
        [AuctionStatusEnum.NotWin]: statusBasedContents.noWinner,
        [AuctionStatusEnum.OpsApproval]: statusBasedContents.pendingApproval,
        [AuctionStatusEnum.PreApproval]: statusBasedContents.pendingApproval,
        [AuctionStatusEnum.Purchased]: statusBasedContents.purchased,
        [AuctionStatusEnum.TermsAccepted]: statusBasedContents.termSheetAccepted,
        [AuctionStatusEnum.TermsPending]: auction?.prelim ? statusBasedContents.hasPrelim : statusBasedContents.win,
        [AuctionStatusEnum.TermsReviewed]: statusBasedContents.hasPrelim,
      }[auction.status]
    : [statusSkeleton, blurbSkeleton];

  const responseCount = auction?.numberIssuersResponded ?? 0;
  const issuerCount = auction?.issuers?.length ?? 0;
  const bidCounts =
    auction?.status === AuctionStatusEnum.InAuction ? (
      <Typography variant="h3" fontWeight={300} component="span">
        ({responseCount}/{issuerCount})
      </Typography>
    ) : null;

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h3" align="center">
        {statusContent?.[0]} {bidCounts}
      </Typography>
      <Typography variant="body2" align="center">
        {statusContent?.[1]}
      </Typography>
    </Stack>
  );
};
