import { ReactElement } from 'react';

import { InfiniteScroller, InfiniteScrollerProps } from '@halo-common/components';
import { WatchlistDetailsProductModel } from '@halo-common/models';
import { WatchlistProductCard } from '@halo-modules/app';

export type WatchlistCardStackProps = {
  products: Array<WatchlistDetailsProductModel>;
  ScrollerProps: Omit<InfiniteScrollerProps, 'children'>;
};

export const WatchlistCardStack = ({ products = [], ScrollerProps }: WatchlistCardStackProps): ReactElement => (
  <InfiniteScroller {...ScrollerProps} grid>
    {products.map(({ product, type }) => (
      <WatchlistProductCard key={product.id} product={product} type={type} removable />
    ))}
  </InfiniteScroller>
);
