import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type RollingReturnsQueryKeys = {
  all: QueryKeyFactoryFn;
  note: QueryKeyFactoryFn;
  quote: QueryKeyFactoryFn;
  underlying: QueryKeyFactoryFn;
  summary: QueryKeyFactoryFn;
  chart: QueryKeyFactoryFn;
  stats: QueryKeyFactoryFn;
};

export const RollingReturnsQueryKeyFactory: RollingReturnsQueryKeys = {
  all: () => ['rolling', 'returns'],
  note: (id) => [...RollingReturnsQueryKeyFactory.all(), 'note', id],
  quote: (id, quoteId) => [...RollingReturnsQueryKeyFactory.note(id), 'quote', quoteId],
  underlying: (id, filters) => [...RollingReturnsQueryKeyFactory.note(id), 'underlying', hash(filters)],
  stats: (id, quoteId, range) => [...RollingReturnsQueryKeyFactory.quote(id, quoteId), 'stats', range],
  summary: (id, quoteId, date) => [...RollingReturnsQueryKeyFactory.quote(id, quoteId), 'summary', date],
  chart: (id, quoteId) => [...RollingReturnsQueryKeyFactory.quote(id, quoteId), 'filters'],
};
