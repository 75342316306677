import { ReactElement } from 'react';

import { NoteTypeEnum } from '@halo-common/enums';
import { useNoteSpecificDetails } from '@halo-common/hooks';
import { ProductDetailModalLayoutHeader } from '@halo-common/layouts';
import {
  PostTradeNoteDetailModalHeaderActions,
  PostTradeNoteDetailModalHeaderFeatures,
  PostTradeNoteDetailModalHeaderId,
  PostTradeNoteDetailModalHeaderSolvableParameter,
} from '@halo-common/modals';
import { PortfolioOrderModel } from '@halo-common/models';

export type PostTradeNoteDetailModalHeaderProps = {
  product?: PortfolioOrderModel | null;
  loading?: boolean;
  onClose?: () => void;
};

export const PostTradeNoteDetailModalHeader = ({
  product,
  loading = true,
  onClose,
}: PostTradeNoteDetailModalHeaderProps): ReactElement => {
  const noteType = product?.note.type;
  const productType = product?.note.productType;
  const features = product?.note.features;

  const { solvableParameter } = useNoteSpecificDetails(product?.note, { ignoreQuote: true });

  const hideCreateSimilar = noteType === NoteTypeEnum.General;

  const slots = {
    features: (
      <PostTradeNoteDetailModalHeaderFeatures
        loading={loading}
        features={features}
        type={noteType}
        subtype={productType}
      />
    ),
    productId: <PostTradeNoteDetailModalHeaderId loading={loading} product={product} />,
    parameter: (
      <PostTradeNoteDetailModalHeaderSolvableParameter
        loading={loading}
        parameter={solvableParameter}
        type={noteType}
      />
    ),
    actions: (
      <PostTradeNoteDetailModalHeaderActions
        loading={loading}
        noteId={product?.note?.id}
        termsheetId={product?.termsheet?.id}
        hideCreateSimilar={hideCreateSimilar}
      />
    ),
  };

  return <ProductDetailModalLayoutHeader onClose={onClose} slots={slots} />;
};
