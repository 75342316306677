import { DocumentModel } from '@halo-common/models';
import { downloadFile } from '@halo-common/utils';
import { generateDocumentUrl } from '@halo-data-sources/clients';
import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';

const downloadS3DocumentMutationFn = async (document: DocumentModel) => {
  const { download_url } = await generateDocumentUrl(document.document);

  downloadFile({ fileName: document.name || document.document.filename, downloadFilePath: download_url });
};

export type UseDownloadS3DocumentMutationOptions = UseMutationOptions<void, Error, DocumentModel>;

export const useDownloadS3DocumentMutation = (
  options?: Partial<UseDownloadS3DocumentMutationOptions>,
): UseMutationResult<void, Error, DocumentModel> =>
  useMutation({
    mutationFn: downloadS3DocumentMutationFn,
    ...options,
  });
