import { SortModelDirectionEnum } from '@halo-common/enums';
import { ApiComparisonOptionEnum, ApiComparisonTypeEnum } from '@halo-data-sources/enums';
import {
  ApiNotificationModel,
  ApiNotificationTypeModel,
  NotificationFilters,
  NotificationModel,
  NotificationsListQueryPayload,
  NotificationTypeModel,
  NotificationTypesQueryPayload,
} from '@halo-data-sources/models';

export const NotificationMapper = {
  toApiNotificationsPayload: (filters: NotificationFilters): NotificationsListQueryPayload => {
    const payload: NotificationsListQueryPayload = {
      sort: [
        {
          direction: SortModelDirectionEnum.desc,
          field: 'notifications.created_at',
        },
      ],
      comparisons: [],
      comparison_type: ApiComparisonTypeEnum.AND,
      page: 1,
      page_length: filters.pageLength,
    };

    if (filters.onlyUnread) {
      payload.comparisons.push({
        field: 'notifications.is_active',
        op: ApiComparisonOptionEnum.EQ,
        value: 'true',
      });
    }

    if (filters.startDate) {
      payload.comparisons.push({
        field: 'notifications.created_at::date',
        op: ApiComparisonOptionEnum.GTE,
        value: filters.startDate,
      });
    }

    if (filters.endDate) {
      payload.comparisons.push({
        field: 'notifications.created_at::date',
        op: ApiComparisonOptionEnum.LTE,
        value: filters.endDate,
      });
    }

    if (filters.notificationTypes.length > 0) {
      payload.comparisons.push({
        field: 'notifications.notification_type_id',
        op: ApiComparisonOptionEnum.IN,
        value: filters.notificationTypes.map(({ id }: { id: number }) => id),
      });
    }

    return payload;
  },
  toApiNotificationTypesPayload: (filters: NotificationFilters): NotificationTypesQueryPayload => {
    const payload: NotificationTypesQueryPayload = {
      filters: [
        {
          field: 'notification_type.id',
          type: 'OBJECTS',
          object_fields: ['notification_type.title', 'notification_type.icon', 'notification_type.color'],
        },
      ],
      comparisons: [],
      comparison_type: ApiComparisonTypeEnum.AND,
      page: 1,
      page_length: filters.pageLength,
    };

    if (filters.onlyUnread) {
      payload.comparisons.push({
        field: 'notifications.is_active',
        op: ApiComparisonOptionEnum.EQ,
        value: 'true',
      });
    }

    if (filters.startDate) {
      payload.comparisons.push({
        field: 'notifications.created_at::date',
        op: ApiComparisonOptionEnum.GTE,
        value: filters.startDate,
      });
    }

    if (filters.endDate) {
      payload.comparisons.push({
        field: 'notifications.created_at::date',
        op: ApiComparisonOptionEnum.LTE,
        value: filters.endDate,
      });
    }

    return payload;
  },
};

export const ApiNotificationMapper = {
  toNotificationTypeModel: ({ object }: { object: ApiNotificationTypeModel }): NotificationTypeModel => ({
    id: object['notification_type.id'],
    name: object['notification_type.title'],
    icon: object['notification_type.icon'],
    color: object['notification_type.color'],
  }),
  toNotificationModel: (notification: ApiNotificationModel): NotificationModel => {
    return {
      body: notification.body,
      createdAt: notification.created_at,
      id: notification.id,
      isActive: notification.is_active,
      notificationType: {
        color: notification.notification_type.color,
        ergName: notification.notification_type.erg_name,
        icon: notification.notification_type.icon,
        id: notification.notification_type.id,
        title: notification.notification_type.title,
        tooltip: notification.notification_type.tooltip,
      },
      redirectPath: notification.redirect_path,
    };
  },
};
