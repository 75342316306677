import { ReactElement } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { CollapsibleFilters, GroupedCheckbox } from '@halo-common/components';
import { DynamicTypeFiltersSideBarTitlesDict } from '@halo-common/layouts';
import { useTermsheetRepoFiltersQuery } from '@halo-data-sources/queries/termsheetsRepo';
import { useAtom } from 'jotai';

export type TermsheetsProductTypeFiltersProps = {
  tag: string;
  filterValues?: (string | number)[];
};

export const TermsheetsProductTypeFilters = ({
  tag,
  filterValues,
}: TermsheetsProductTypeFiltersProps): ReactElement => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const { data } = useTermsheetRepoFiltersQuery();

  const sideBarFilters = getSideBarFilters(tag);
  const selectedProductTypes = sideBarFilters.productTypes;

  const handleCheckboxChange = (value: string) => {
    const isInFilter = selectedProductTypes.includes(value);

    const updatedProductTypes = isInFilter
      ? selectedProductTypes.filter((productType) => productType !== value)
      : [...selectedProductTypes, value];

    setSideBarFilters({ tag, productTypes: updatedProductTypes });
  };

  const productTypes = (data?.termsheetsProductType as string[]) ?? [];

  const productTypeCheckboxes = productTypes
    .filter((productType) => filterValues?.includes(productType) ?? true)
    .map((productType) => ({
      isChecked: selectedProductTypes.includes(productType),
      name: productType,
      value: productType,
    }));

  return (
    <CollapsibleFilters title={DynamicTypeFiltersSideBarTitlesDict.termsheetsProductType}>
      <GroupedCheckbox onChange={handleCheckboxChange} values={productTypeCheckboxes} groupedCheckboxType="product" />
    </CollapsibleFilters>
  );
};
