import { OrderTicketReceiptModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';

export class BulkAllocationError extends Error {
  allocations: OrderTicketReceiptModel['allocations'];
  constructor(message?: string, allocations?: OrderTicketReceiptModel['allocations']) {
    super(message ?? translations.common.error);
    this.allocations = allocations ?? [];
  }
}
