import { useT } from '@transifex/react';
import { DateTime } from 'luxon';

export type UseTranslatePdmDatesReturnType = (value: string, format: string) => string;

export const useTranslatePdmDates = (): UseTranslatePdmDatesReturnType => {
  const translator = useT();

  const translateDates = (value: string, format: string) => {
    const date = value ? DateTime.fromISO(value).toFormat(format) : '';
    const splitDate = date.split(' ');
    const month = splitDate[0];
    splitDate.shift();
    const dayAndYear = splitDate.join(' ');
    const translatedMonth = translator(month);
    return `${translatedMonth} ${dayAndYear}`;
  };

  return translateDates;
};
