import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type AccountDataSwitchKeys = {
  all: QueryKeyFactoryFn;
  infinite: QueryKeyFactoryFn;
};

export const AccountDataSwitchKeyFactory: AccountDataSwitchKeys = {
  all: () => ['accounts'],
  infinite: (payload) => [...AccountDataSwitchKeyFactory.all(), 'infinite', hash(payload)],
};
