import { ReactElement } from 'react';

import { useRemainingHeight } from '@halo-common/hooks';
import { CalendarAllocationsModal } from '@halo-common/modals';
import {
  FixedIncomeAllocationsModal,
  OrderBookCalendarDetailModal,
  OrderBookCalendarsDownloadCSVButton,
  OrderBookCalendarsFilters,
  OrderBookCalendarsTable,
} from '@halo-modules/app';
import { Stack } from '@mui/material';

export const OrderBookCalendarsPage = (): ReactElement => {
  const remainingHeight = useRemainingHeight();

  return (
    <>
      <Stack direction="column" spacing={2} sx={{ height: remainingHeight }}>
        <OrderBookCalendarsFilters />
        <Stack direction="row" justifyContent="flex-end">
          <OrderBookCalendarsDownloadCSVButton />
        </Stack>
        <OrderBookCalendarsTable />
      </Stack>
      <CalendarAllocationsModal />
      <OrderBookCalendarDetailModal />
      <FixedIncomeAllocationsModal />
    </>
  );
};
