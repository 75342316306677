import { ReactElement } from 'react';

import { AuctionStatusEnum, AuctionStatusKeyEnum } from '@halo-common/enums';
import { OrderHubOrderedColumnsModel } from '@halo-common/models';
import { useUserSettingMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import {
  AuctionTable,
  AuctionTableLayout,
  OH_ORDERED_COLUMNS_KEY,
  OH_PINNED_COLUMNS_KEY,
  useAuctionTableData,
} from '@halo-modules/admin';
import { GridPinnedColumnFields } from '@mui/x-data-grid-pro';

import { TermsAcceptedModal } from './TermsAcceptedModal';

export const TermsAcceptedTable = (): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();
  const { mutate: updateUserSetting } = useUserSettingMutation();
  const { data, gridRef, issuersVisible, handleDataSwitch } = useAuctionTableData(AuctionStatusEnum.TermsAccepted);

  const orderHubPinnedColumns = userInfo?.settings?.orderHubPinnedColumns;
  const orderHubOrderedColumns = userInfo?.settings?.orderHubOrderedColumns;

  const selectOrderHubPinnedColumns = orderHubPinnedColumns?.[AuctionStatusKeyEnum.TermsAccepted];
  const selectOrderHubOrderedColumns = orderHubOrderedColumns?.[AuctionStatusKeyEnum.TermsAccepted];

  const handleColumnPin = (updatedGridPinnedColumns: GridPinnedColumnFields) => {
    updateUserSetting({
      key: OH_PINNED_COLUMNS_KEY,
      value: { ...orderHubPinnedColumns, inAuction: updatedGridPinnedColumns },
    });
  };

  const handleColumnReorder = (updatedColumnOrders: OrderHubOrderedColumnsModel) => {
    updateUserSetting({
      key: OH_ORDERED_COLUMNS_KEY,
      value: { ...orderHubOrderedColumns, inAuction: updatedColumnOrders },
    });
  };

  const description = `The Preliminary Termsheet has been approved and the winning issuer alerted. The Final Termsheet is needed on settlement date to complete the purchase.`;

  return (
    <AuctionTableLayout
      description={description}
      title="Terms Accepted"
      onIssuersToggle={handleDataSwitch}
      onExport={gridRef.current.exportDataAsCsv}
    >
      <AuctionTable
        onColumnPin={handleColumnPin}
        onColumnReorder={handleColumnReorder}
        defaultPinnedColumns={selectOrderHubPinnedColumns}
        defaultOrderedColumns={selectOrderHubOrderedColumns}
        issuersVisible={issuersVisible}
        DataGridProps={{
          data,
          cta: {
            icon: 'file-upload',
            label: 'Upload Final Terms',
            color: 'purple',
          },
        }}
        gridRef={gridRef}
      />
      <TermsAcceptedModal icon="file-upload" />
    </AuctionTableLayout>
  );
};
