import { ReactElement } from 'react';

import { useMobileView } from '@halo-common/hooks';
import { ConnectedContactButton } from '@halo-common/smartComponents';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Reminder } from '@halo-modules/app';

export const ForgotCredentialsReminder = (): ReactElement => {
  const isMobile = useMobileView();

  const { data: userInfo } = useUserInfoQuery();

  const userFacingName = userInfo?.whiteLabel.userFacingName;

  const reminderMessageText = `If you can't remember your email, or are having problems logging in, please contact ${userFacingName}.`;

  return (
    <Reminder message={reminderMessageText} icon="user-unlock">
      <ConnectedContactButton type="button" variant="outlined" fullWidth={isMobile} />
    </Reminder>
  );
};
