import { NoteCouponPeriodEnum, NoteParameterEnum } from '@halo-common/enums';
import { QuoteAuctionModel, QuoteCalendarModel, QuoteModel } from '@halo-common/models';
import { ApiIssuerMapper, mapApiDocumentModelToS3DocumentModel, mapEnumValue } from '@halo-data-sources/mappers';
import {
  ApiAuctionQuoteModel,
  ApiCalendarQuoteModel,
  ApiNoteFlixQuoteModel,
  ApiQuoteModel,
} from '@halo-data-sources/models';
import { round } from 'lodash';
import { DateTime } from 'luxon';

const CUSTOM_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss.u';

interface ApiQuoteMapper {
  toQuoteModel: (model: ApiQuoteModel) => QuoteModel;
  toNoteflixQuoteModel: (model?: ApiNoteFlixQuoteModel) => QuoteModel | null;
  toCalendarQuoteModel: (model: ApiCalendarQuoteModel) => QuoteCalendarModel;
  toAuctionQuoteModel: (model: ApiAuctionQuoteModel) => QuoteAuctionModel;
}

export const ApiQuoteMapper: ApiQuoteMapper = {
  toQuoteModel: (model) => {
    const apiDocument = model?.s3_document;
    const document = apiDocument ? mapApiDocumentModelToS3DocumentModel(apiDocument) : null;
    const parameter = mapEnumValue<NoteParameterEnum>(model.parameter, NoteCouponPeriodEnum);

    return {
      document,
      downloadable: model.downloadable,
      id: model.note_quote_id,
      issuerId: model.issuer_id,
      issuerName: model.issuer_name,
      noteId: model.note_id,
      noteRfqId: model.note_rfq_id,
      parameter,
      receivedAt: model.received_at,
      value: model.value,
    };
  },
  toNoteflixQuoteModel: (model) => {
    if (!model) return null;

    const apiDocument = model?.document;
    const document = apiDocument ? mapApiDocumentModelToS3DocumentModel(apiDocument) : null;
    const parameter = mapEnumValue<NoteParameterEnum>(model.parameter, NoteCouponPeriodEnum);

    return {
      document,
      downloadable: model.downloadable,
      id: model.id,
      issuerId: model.issuer.id,
      issuerName: model.issuer.name,
      noteId: model.note_id,
      noteRfqId: model.note_rfq_id,
      parameter,
      receivedAt: model.received_at,
      value: model.value,
    };
  },
  toCalendarQuoteModel: (model) => {
    const issuer = ApiIssuerMapper.toIssuerModel(model.issuer);
    const parameter = mapEnumValue<NoteParameterEnum>(model.parameter, NoteCouponPeriodEnum);

    const comment = model.comment
      ? {
          adminApproved: model.comment.adminapproved,
          adminRead: model.comment.adminread,
          auctionId: model.comment.auction_id,
          buyerApproved: model.comment.buyerapproved,
          buyerRead: model.comment.buyerread,
          comment: model.comment.comment,
          id: model.comment.id,
          issuer: ApiIssuerMapper.toIssuerModel(model.comment.issuer),
        }
      : undefined;

    const document = model.document
      ? {
          id: model.document.id,
          path: model.document.path,
          fileName: model.document.filename,
          name: model.document.name,
        }
      : undefined;

    return {
      approved: Boolean(model.comment?.adminapproved),
      comment,
      document,
      downloadable: model.downloadable,
      fees: model.fees,
      id: model.id,
      isManualUpdate: model.is_manual_update,
      isWinningQuote: model.is_winning_quote,
      issuer,
      noteId: model.note_id,
      noteRfqId: model.note_rfq_id?.toString() ?? null,
      parameter,
      pricerId: model.pricer_id,
      receivedAt: DateTime.fromFormat(model.received_at, CUSTOM_DATE_TIME_FORMAT, { zone: 'UTC' }).toISO() as string,
      sysid: model.sysid,
      value: round(model.value, 2),
      expirationDate: DateTime.fromSQL(model.expires_at).toISO() as string,
    };
  },
  toAuctionQuoteModel: (model) => {
    const issuer = ApiIssuerMapper.toIssuerModel(model.issuer);
    const parameter = mapEnumValue<NoteParameterEnum>(model.parameter, NoteCouponPeriodEnum);

    const comment = model.comment
      ? {
          adminApproved: model.comment.adminapproved,
          adminRead: model.comment.adminread,
          auctionId: model.comment.auction_id,
          buyerApproved: model.comment.buyerapproved,
          buyerRead: model.comment.buyerread,
          comment: model.comment.comment,
          id: model.comment.id,
          issuer: ApiIssuerMapper.toIssuerModel(model.comment.issuer),
        }
      : undefined;

    const document = model.document
      ? {
          id: model.document.id,
          path: model.document.path,
          fileName: model.document.filename,
          name: model.document.name,
        }
      : undefined;

    return {
      approved: Boolean(model.comment?.adminapproved),
      comment,
      document,
      downloadable: model.downloadable,
      fees: model.fees,
      id: model.id,
      isManualUpdate: model.is_manual_update,
      isWinningQuote: model.is_winning_quote,
      issuer,
      noteId: model.note_id,
      noteRfqId: model.note_rfq_id?.toString() ?? null,
      parameter,
      pricerId: model.pricer_id,
      receivedAt: DateTime.fromFormat(model.received_at, CUSTOM_DATE_TIME_FORMAT, { zone: 'UTC' }).toISO() as string,
      sysid: model.sysid,
      value: round(model.value, 2),
      expirationDate: DateTime.fromSQL(model.expires_at).toISO() as string,
    };
  },
};
