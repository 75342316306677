import { debouncedAtom } from '@halo-atoms/debounce';
import { ExecutionHubJanneyStatusEnum } from '@halo-common/enums';
import { ProductByAssetModel } from '@halo-data-sources/queries';
import { atom } from 'jotai';
import { DateTime } from 'luxon';

export type ExecutionHubFilters = {
  status: ExecutionHubJanneyStatusEnum;
  issuer: string;
  accountType: string;
  calendar: ProductByAssetModel | null;
  expirationStartDate: DateTime;
  expirationEndDate: DateTime;
};

export type ExecutionHubQuery = ExecutionHubFilters & {
  account: string;
  repCode: string;
  orderNumber: string;
};

const DEFAULT_FILTERS: ExecutionHubFilters = {
  status: ExecutionHubJanneyStatusEnum.active,
  issuer: '',
  accountType: '',
  calendar: null,
  expirationStartDate: DateTime.local(),
  expirationEndDate: DateTime.local().plus({ months: 1 }),
};

export const executionHubFillingMapAtom = atom<Record<number, boolean>>({});
export const executionHubFiltersAtom = atom<ExecutionHubFilters>(DEFAULT_FILTERS);

const { currentValueAtom: orderNumSearchCurrent, debouncedValueAtom: orderNumSearchDebounced } = debouncedAtom('');
const _currentOrderNumberSearchAtom = orderNumSearchCurrent;
const _debouncedOrderNumberSearchAtom = orderNumSearchDebounced;
export const orderNumberSearchAtom = atom(
  (get) => get(_currentOrderNumberSearchAtom),
  (_, set, payload: string) => set(_debouncedOrderNumberSearchAtom, payload),
);

const { currentValueAtom: accountSearchCurrent, debouncedValueAtom: accountSearchDebounced } = debouncedAtom('');
const _currentAccountSearchAtom = accountSearchCurrent;
const _debouncedAccountSearchAtom = accountSearchDebounced;
export const accountSearchAtom = atom(
  (get) => get(_currentAccountSearchAtom),
  (_, set, payload: string) => set(_debouncedAccountSearchAtom, payload),
);

const { currentValueAtom: repCodeSearchCurrent, debouncedValueAtom: repCodeSearchDebounced } = debouncedAtom('');
const _currentRepCodeSearchAtom = repCodeSearchCurrent;
const _debouncedRepCodeSearchAtom = repCodeSearchDebounced;
export const repCodeSearchAtom = atom(
  (get) => get(_currentRepCodeSearchAtom),
  (_, set, payload: string) => set(_debouncedRepCodeSearchAtom, payload),
);

export const executionHubQueryAtom = atom((get) => {
  const account = get(accountSearchDebounced);
  const repCode = get(repCodeSearchDebounced);
  const orderNumber = get(orderNumSearchDebounced);
  const filters = get(executionHubFiltersAtom);

  return { ...filters, account, repCode, orderNumber };
});
