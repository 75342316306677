import { ReactElement, ReactNode } from 'react';

import { BackgroundEllipse, ForegroundEllipse, MainEllipse } from '@halo-modules/app';
import { BrandIconColorOptions, useCombinedStyling } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, Stack, useTheme } from '@mui/material';
import { keyframes } from '@mui/system';

const backgroundRotation = keyframes`
0% {
  transform: rotate(30deg);
}
50% {
  transform: rotate(-45deg);
}
100% {
  transform: rotate(30deg);
}
`;

const foregroundRotation = keyframes`
0% {
  transform: rotate(-30deg);
}
50% {
  transform: rotate(90deg);
}
100% {
  transform: rotate(-30deg);
}
`;

const graphicContainerSx = { position: 'relative', top: 0, left: -200, mt: 5 };
const graphicElementContainerSx = { position: 'absolute', pt: 3 };
const graphicElementSx = { position: 'absolute', transform: 'translateX(-50%)', animationDelay: '1ms' };

const foregroundEllipseContainerSx = {
  transform: 'translateX(0)',
};

const mainEllipseContainerSx = {
  transform: 'translate(calc(-50% + 10px), 11px)',
  animation: `${foregroundRotation} 10000ms infinite ease-in-out`,
};

const backgroundEllipseContainerSx = {
  transform: 'translate(calc(-50% + 10px), 11px)',
  animation: `${backgroundRotation} 10000ms infinite ease-in-out`,
};

export type ModuleFinishedGraphicProps = {
  children?: ReactNode;
  color?: BrandIconColorOptions;
};

export const ModuleFinishedGraphic = ({ children, color = 'primary' }: ModuleFinishedGraphicProps): ReactElement => {
  const theme = useTheme<HaloTheme>();

  const { foregroundEllipseColor, mainEllipseColor, backgroundEllipseColor } = {
    dark: {
      foregroundEllipseColor: theme.palette.primary.main,
      mainEllipseColor: theme.palette.secondary.main,
      backgroundEllipseColor: theme.palette.secondary.background,
    },
    light: {
      foregroundEllipseColor: theme.palette.primary.main,
      mainEllipseColor: theme.palette.secondary.main,
      backgroundEllipseColor: theme.palette.secondary.background,
    },
    primary: {
      foregroundEllipseColor: theme.palette.primary.main,
      mainEllipseColor: theme.palette.secondary.main,
      backgroundEllipseColor: theme.palette.secondary.background,
    },
    secondary: {
      foregroundEllipseColor: theme.palette.primary.main,
      mainEllipseColor: theme.palette.secondary.main,
      backgroundEllipseColor: theme.palette.secondary.background,
    },
  }[color];

  return (
    <Box sx={graphicContainerSx}>
      <Box sx={useCombinedStyling(graphicElementSx, mainEllipseContainerSx)}>
        <MainEllipse color={mainEllipseColor} />
      </Box>
      <Box sx={useCombinedStyling(graphicElementSx, backgroundEllipseContainerSx)}>
        <BackgroundEllipse color={backgroundEllipseColor} />
      </Box>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={useCombinedStyling(graphicElementSx, foregroundEllipseContainerSx)}
      >
        <ForegroundEllipse color={foregroundEllipseColor} />
        <Stack sx={graphicElementContainerSx} direction="column" alignItems="center" justifyContent="space-around">
          {children}
        </Stack>
      </Stack>
    </Box>
  );
};
