export const RR_COMMON_TRANSLATIONS = {
  filterByNoteTypeSearchLabel: `Filter by Note Type`,
  findByIDSearchLabel: `Note ID`,
  findByIDSubTitle: `Depending on which version of Note Details you are looking at, the ID will be at the top right or bottom left.`,
  findByIDTitle: 'Find Note By ID',
  lastCreatedNotesSubTitle: `Click on a card to open the Rolling Returns tab.`,
  lastCreatedNotesTitle: 'Last Created Notes',
  noResultsTitle: 'No Created Note History',
  noResultsMessage: 'Once you create a Note, it will show up here.',
  searchByIdInvalidMessage: 'Note ID not found. See instructions above on how to find this.',
};
