import { UserEducationProgressModel, UserModel } from '@halo-common/models';
import {
  ApiOrganizationMapper,
  ApiRelationshipManagerMapper,
  ApiUserRoleMapper,
  DEFAULT_USER_LANGUAGE,
} from '@halo-data-sources/mappers';
import {
  ApiUserManagementActivityModel,
  ApiUserManagementEducationModuleProgressModel,
  ApiUserManagementEducationProgressModel,
  ApiUserManagementUserModel,
} from '@halo-data-sources/models';
import { DateTime } from 'luxon';

export const ApiUserManagementMapper = {
  toUser: (
    user: ApiUserManagementUserModel,
    activity?: ApiUserManagementActivityModel,
    educationProgress?: ApiUserManagementEducationProgressModel,
  ): UserModel => {
    const lastLoginDate = activity?.[user.id]
      ? (DateTime.fromFormat(activity[user.id], 'yyyy-MM-dd').toISO() ?? undefined)
      : undefined;

    const userEducationProgress = educationProgress?.[user.id] ?? [];
    const userPhoneNumber = user.phone?.replace(/\D/g, '') ?? null;
    const userFullName = user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : undefined;

    const auctionPermissions = {
      absolute: user.is_auction_permitted_absolute,
      digital: user.is_auction_permitted_digital,
      growth: user.is_auction_permitted_growth,
      income: user.is_auction_permitted_income,
    };

    const relationshipManager = ApiRelationshipManagerMapper.toRelationshipManager(user?.relationship_manager);

    return {
      isActive: user.active,
      lastLogin: lastLoginDate,
      createdAt: user.created_on,
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      name: userFullName,
      id: user.id,
      auctionPermissions,
      relationshipManager,
      preferredLanguage: DEFAULT_USER_LANGUAGE,
      phone: userPhoneNumber,
      organization: ApiOrganizationMapper.toOrganization(user.organization),
      educationProgress: userEducationProgress.map(ApiUserManagementMapper.toUserEducationProgress),
      roles: user?.roles.map(ApiUserRoleMapper.toUserRole),
    };
  },
  toUserEducationProgress: (model: ApiUserManagementEducationModuleProgressModel): UserEducationProgressModel => ({
    title: model.title,
    percentComplete: model.pct_complete,
  }),
};
