import { ExecutionHubQuery } from '@halo-atoms/executionHub';
import { downloadFile } from '@halo-common/utils';
import { postCalendarAdminOrderCSVDownload } from '@halo-data-sources/clients';
import { ExecutionHubMapper } from '@halo-data-sources/mappers';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { DateTime } from 'luxon';

const executionHubDownloadJanneyCSVMutationFn = async (query: ExecutionHubQuery) => {
  const orderComparisons = ExecutionHubMapper.toApiCalendarOrderComparisonList(query);

  const response = await postCalendarAdminOrderCSVDownload({
    comparisons: orderComparisons,
    page: null,
    page_length: null,
  });

  const csvDownload = new Blob([response], { type: 'text/csv' });
  const url = URL.createObjectURL(csvDownload);

  const date = DateTime.local().toFormat('MMddyyyy_hhmmss');
  const fileName = `orders.${date}.csv`;

  downloadFile({ fileName, downloadFilePath: url });
};

export const useExecutionHubDownloadJanneyCSVMutation = (): UseMutationResult<void, Error, ExecutionHubQuery> =>
  useMutation<void, Error, ExecutionHubQuery>({
    mutationFn: executionHubDownloadJanneyCSVMutationFn,
  });
