import { ReactElement, ReactNode } from 'react';

import { LocalizedTypography, LocalizedTypographyProps, Stack } from '@halodomination/halo-fe-common';
import { Box, Paper } from '@mui/material';

const rootSx = { pt: 3, height: '100%' };
const headerSx = { px: 3 };

export type AccountCardLocalizationProps = {
  title?: LocalizedTypographyProps;
  subtitle?: LocalizedTypographyProps;
};

export type AccountCardProps = {
  actions?: ReactNode;
  children: ReactNode;
  title: string;
  subtitle?: string;
  Localization?: AccountCardLocalizationProps;
};

export const AccountCard = ({
  actions,
  children,
  title,
  subtitle,
  Localization = {},
}: AccountCardProps): ReactElement => {
  const subtitleComponent = subtitle ? (
    <LocalizedTypography variant="body1" {...Localization.subtitle}>
      {subtitle}
    </LocalizedTypography>
  ) : null;

  return (
    <Paper variant="outlined" sx={rootSx}>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" justify="space-between">
          <Stack direction="column" spacing={1} sx={headerSx}>
            <LocalizedTypography variant="h4" {...Localization.title}>
              {title}
            </LocalizedTypography>
            {subtitleComponent}
          </Stack>
          {actions ? <Box sx={headerSx}>{actions}</Box> : null}
        </Stack>
        {children}
      </Stack>
    </Paper>
  );
};
