import { ReactElement } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { useSliderText } from '@halo-common/hooks';
import { TERM_SLIDER_MAX_VALUE, TERM_SLIDER_MIN_VALUE } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { Chip, SxProps } from '@mui/material';
import { useAtom } from 'jotai';

export type TermChipProps = {
  tag: string;
  sx: SxProps;
};

export const TermChip = ({ tag, sx }: TermChipProps): ReactElement | null => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);

  const handleTermDelete = () => {
    setSideBarFilters({ tag, term: null });
  };

  const label = useSliderText({
    prefix: translations.common.term,
    minValue: TERM_SLIDER_MIN_VALUE,
    maxValue: TERM_SLIDER_MAX_VALUE,
    suffix: translations.dynamicFilters.common.dateSliderSuffix,
    leftSliderValue: sideBarFilters.term?.min ?? 0,
    rightSliderValue: sideBarFilters.term?.max ?? 60,
  });

  if (!sideBarFilters.term) return null;

  return <Chip sx={sx} size="small" variant="outlined" onDelete={handleTermDelete} label={label} />;
};
