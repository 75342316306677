import { translations } from '@halo-common/translations';
import { OnboardingLayout } from '@halo-modules/app/v2/onboarding';
import { Iconography, LocalizedTypography, PageComponent, PageMeta } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';
import { useRouter } from 'next/router';

const AwaitRegistrationPage: PageComponent = () => {
  const router = useRouter();

  const email = (router.query.email ?? '') as string;
  const name = (router.query.name ?? '') as string;

  const emailDynamicContent = { email };
  const nameDynamicContent = { name };

  const fallback = [
    <Skeleton key="awaitRegistrationContent1" width={40} height={40} />,
    <Skeleton key="awaitRegistrationContent2" width="100%" height={36} />,
    <Skeleton key="awaitRegistrationContent3" width="100%" height={36} />,
  ];

  return (
    <OnboardingLayout variant="small" fallback={fallback}>
      <Iconography color="common.black" iconName="envelope-open-text" prefix="fal" size="3x" />
      <LocalizedTypography mt={2} dynamicContent={nameDynamicContent} variant="h4">
        {translations.onboarding.recoverPassword.checkEmailName}
      </LocalizedTypography>
      <LocalizedTypography
        textAlign="center"
        mt={1}
        variant="subtitle2"
        color="text.secondary"
        dynamicContent={emailDynamicContent}
      >
        {translations.onboarding.recoverPassword.awaitRegistration}
      </LocalizedTypography>
    </OnboardingLayout>
  );
};

export const AwaitRegistrationMeta: PageMeta = {
  pageName: 'AwaitRegistration',
  route: '/app/v2/onboarding/await-registration',
  iconName: 'mailbox',
};

AwaitRegistrationPage.pageName = AwaitRegistrationMeta.pageName;
AwaitRegistrationPage.route = AwaitRegistrationMeta.route;
AwaitRegistrationPage.iconName = AwaitRegistrationMeta.iconName;

export default AwaitRegistrationPage;
