import { ReactElement } from 'react';

import { orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { AuctionIssuerBidStatusEnum, AuctionStatusEnum } from '@halo-common/enums';
import { useAuctionIssuerBidStatus } from '@halo-common/hooks';
import { IssuerModel, QuoteAuctionModel } from '@halo-common/models';
import { useBuyerAuctionQuery } from '@halo-data-sources/queries';
import { ChooseWinnerFooter, MiniCommentButton, PassedReasonFooter, ReviewCommentFooter } from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';
import { Typography } from '@mui/material';
import { useAtomValue } from 'jotai';

export type IssuerBidFooterProps = {
  issuer: IssuerModel;
  quote?: QuoteAuctionModel;
};

export const IssuerBidFooter = ({ issuer, quote }: IssuerBidFooterProps): ReactElement | null => {
  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);
  const auctionId = selectedAuctionId?.toString();

  const { data: auction } = useBuyerAuctionQuery(auctionId);

  const issuerBidStatus = useAuctionIssuerBidStatus({ issuer, quote });

  const issuerPassedReason = issuer.reason;
  const providedComment = quote?.comment?.comment;
  const cds5Yr = issuer.historicCds?.find((cds) => cds.termInYears === 5);

  const ratingContent = (
    <Stack direction="row" xs={3}>
      <Stack direction="column">
        <Typography variant="overline" color="text.secondary">
          Fitch
        </Typography>
        <Typography variant="subtitle1">{issuer.fitch}</Typography>
      </Stack>
      <Stack direction="column">
        <Typography variant="overline" color="text.secondary">
          Moody
        </Typography>
        <Typography variant="subtitle1">{issuer.moody}</Typography>
      </Stack>
      <Stack direction="column">
        <Typography variant="overline" color="text.secondary">
          S&amp;P
        </Typography>
        <Typography variant="subtitle1">{issuer.sp}</Typography>
      </Stack>
      <Stack direction="column">
        <Typography variant="overline" color="text.secondary">
          5Y CDS
        </Typography>
        <Typography variant="subtitle1">{cds5Yr?.spread.toFixed(2)}</Typography>
      </Stack>
    </Stack>
  );

  const winningWithComment = (
    <Stack direction="row" xs={12} sm={[11, 1]} spacing={2} justify="space-between">
      {ratingContent}
      <MiniCommentButton quote={quote} />
    </Stack>
  );

  const isChooseWinner = auction?.status === AuctionStatusEnum.ChooseWinner;
  const chooseWinnerContent = <ChooseWinnerFooter quote={quote}>{ratingContent}</ChooseWinnerFooter>;
  const receivedWithCommentContent = <ReviewCommentFooter quote={quote}>{ratingContent}</ReviewCommentFooter>;
  const passedWithCommentContent = <PassedReasonFooter reason={issuerPassedReason} />;

  return {
    [AuctionIssuerBidStatusEnum.waitingForBid]: null,
    [AuctionIssuerBidStatusEnum.received]: isChooseWinner ? chooseWinnerContent : ratingContent,
    [AuctionIssuerBidStatusEnum.receivedWithComment]: isChooseWinner ? chooseWinnerContent : receivedWithCommentContent,
    [AuctionIssuerBidStatusEnum.declinedToBid]: issuerPassedReason ? passedWithCommentContent : null,
    [AuctionIssuerBidStatusEnum.declinedByYou]: isChooseWinner ? chooseWinnerContent : receivedWithCommentContent,
    [AuctionIssuerBidStatusEnum.acceptedByYou]: isChooseWinner ? chooseWinnerContent : receivedWithCommentContent,
    [AuctionIssuerBidStatusEnum.winningQuote]: providedComment ? winningWithComment : ratingContent,
  }[issuerBidStatus];
};
