import { ReactElement } from 'react';

import { CustodianFormField, HouseholdFormField } from '@halo-common/formComponents';
import { useMobileView, useTabletView } from '@halo-common/hooks';
import { AccountModel, CustodianModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { HttpError } from '@halo-data-sources/errors';
import { LocalizedTextField, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Alert } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export type AddAccountModalFormFields = {
  accountHousehold?: string | null;
  accountName: string;
  custodian: CustodianModel;
  accountNumber: string;
};

export type AddAccountModalFormProps = {
  hidePadding?: boolean;
  selected?: AccountModel | null;
  error?: Array<HttpError | null>;
};

export const AddAccountModalForm = ({
  hidePadding = false,
  selected,
  error,
}: AddAccountModalFormProps): ReactElement => {
  const isMobile = useMobileView();
  const isTablet = useTabletView();

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<AddAccountModalFormFields>();

  const { ...accountNameProps } = register('accountName', {
    required: translations.portfolio.addAccountModal.formNameRequiredError,
  });

  const { ...accountNumberProps } = register('accountNumber', {
    required: translations.portfolio.addAccountModal.formNumberRequiredError,
  });

  const { accountName: accountNameError, accountNumber: accountNumberError } = errors ?? {};

  const isSelected = Boolean(selected);
  const isTabletOrMobile = isMobile || isTablet;
  const requestErrorMessage = error?.[0]?.message;

  const formContainerSx = { display: 'flex', gap: 5, px: !isTabletOrMobile ? 15 : null, py: 2 };
  const containerSx = !hidePadding ? formContainerSx : null;

  const accountNameHelperMessage = accountNameError?.message;
  const custodianHelperMessage = isSelected ? translations.portfolio.addAccountModal.custodianHelperMessage : '';
  const accountNumberHelperMessage = isSelected
    ? translations.portfolio.addAccountModal.accountHelperMessage
    : accountNumberError?.message;

  const accountNumberFieldError = !isSelected ? Boolean(accountNumberError) : undefined;

  const custodianRules = {
    required: translations.portfolio.addAccountModal.formCustodianRequiredError,
    validate: !isSelected
      ? {
          required: (v: string | number | CustodianModel | null | undefined) => {
            if (typeof v === 'string' || typeof v === 'number') return false;
            else return Boolean(v && v.id > -1);
          },
        }
      : undefined,
  };

  const alertMessage = requestErrorMessage ? (
    <Alert severity="error" variant="filled">
      <LocalizedTypography>{requestErrorMessage}</LocalizedTypography>
    </Alert>
  ) : null;

  return (
    <Stack direction="column" spacing={2} sx={containerSx}>
      {alertMessage}
      <HouseholdFormField control={control} label={translations.common.accountHousehold} name="accountHousehold" />
      <LocalizedTextField
        {...accountNameProps}
        name="accountName"
        required
        error={Boolean(accountNameError)}
        label={translations.common.accountName}
        helperText={accountNameHelperMessage}
        fullWidth
        size="large"
      />
      <CustodianFormField<AddAccountModalFormFields>
        helperText={custodianHelperMessage}
        disabled={isSelected}
        label={translations.common.custodian}
        control={control}
        name="custodian"
        rules={custodianRules}
      />
      <LocalizedTextField
        {...accountNumberProps}
        name="accountNumber"
        error={accountNumberFieldError}
        required
        disabled={isSelected}
        helperText={accountNumberHelperMessage}
        label={translations.common.accountNumber}
        fullWidth
        size="large"
      />
    </Stack>
  );
};
