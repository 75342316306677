import { ReactElement } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { UserSaveAccessEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedButton, LocalizedButtonProps } from '@halodomination/halo-fe-common';
import { useSetAtom } from 'jotai';

export const AddAccountOrPositionButton = ({
  children,
  variant = 'contained',
  color = 'secondary',
  ...props
}: LocalizedButtonProps): ReactElement | null => {
  const onModalToggle = useSetAtom(modalAtom);

  const { data: user } = useUserInfoQuery();
  const accountAddAccess = user?.whiteLabel.accountSaveAccess;
  const positionAddAccess = user?.whiteLabel.positionSaveAccess;

  const canCreateAccount =
    accountAddAccess === UserSaveAccessEnum.ENABLED || accountAddAccess === UserSaveAccessEnum.MANUAL_ONLY;
  const canCreatePosition = positionAddAccess === UserSaveAccessEnum.ENABLED;

  if (!canCreateAccount && !canCreatePosition) return null;

  const buttonText =
    canCreateAccount && canCreatePosition
      ? translations.portfolio.common.addNewPositionOrAccount
      : canCreateAccount
        ? translations.portfolio.common.addNewAccount
        : translations.portfolio.common.addNewPosition;

  const handleModalToggle = () => void onModalToggle({ addAccountOrPositionsModal: true });

  return (
    <LocalizedButton onClick={handleModalToggle} variant={variant} color={color} {...props}>
      {children || buttonText}
    </LocalizedButton>
  );
};
