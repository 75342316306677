import { ReactElement } from 'react';

import { executionHubOrderTicketModalAtom } from '@halo-atoms/executionHub';
import { ExecutionOrderReceipt } from '@halo-common/components';
import { Modal } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';

const modalContentStyling = { sx: { paddingTop: 3, borderBottomLeftRadius: 20, borderBottomRightRadius: 20 } };

export type ExecutionOrderTicketReviewModalProps = {
  open: boolean;
  bulk?: boolean;
  onClose: () => void;
  onUpdateOrder?: () => void;
};

export const ExecutionOrderTicketReviewModal = ({
  open,
  onClose,
}: ExecutionOrderTicketReviewModalProps): ReactElement => {
  const { calendar, order } = useAtomValue(executionHubOrderTicketModalAtom);

  const cusip = calendar?.cusip ?? '';
  const account = order?.allocation?.account;

  return (
    <Modal open={open} onClose={onClose} title={cusip} ContentProps={modalContentStyling}>
      <ExecutionOrderReceipt
        calendarId={calendar?.id}
        order={order?.executionOrder}
        account={account}
        summary={order?.transactionSummary}
      />
    </Modal>
  );
};
