import { V2NoteModel } from '@halo-common/models';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { capitalize } from '@mui/material';

export type V2ProtectionDetails = {
  text?: string;
  label?: string;
  value?: string;
};

export const useV2ProtectionDetails = (product?: V2NoteModel | null): V2ProtectionDetails | undefined => {
  const { data: user } = useUserInfoQuery();

  if (!product) return undefined;

  const moneynessEnabled = Boolean(user?.settings?.moneynessEnabled);

  const protection = product.protection;
  const percentage = protection?.value ? parseFloat(protection.value) : '';
  const type = protection?.label ? capitalize(protection.label) : '';

  const hasProtection = typeof percentage === 'number';
  const isFullProtection = hasProtection && percentage === 100;
  const isNoProtection = hasProtection && percentage === 0;
  const isMoneynessFullProtection = moneynessEnabled && isNoProtection;
  const isMoneynessNoProtection = moneynessEnabled && isFullProtection;
  const showFullProtectionText = isMoneynessFullProtection || isFullProtection;
  const showNoProtectionText = isMoneynessNoProtection || isNoProtection;

  if (showFullProtectionText) return { label: 'Full Protection', value: 'Full Protection', text: 'Full Protection' };
  else if (showNoProtectionText) return { label: 'No Protection', value: 'No Protection', text: 'No Protection' };
  else if (hasProtection) return { text: `${percentage}% ${type}`, value: `${percentage}%`, label: type };
  else return undefined;
};
