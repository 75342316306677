import { useHaloInfiniteQuery } from '@halo-common/hooks';
import {
  InfinitePaginationModel,
  InfiniteQueryOptions,
  UserModel,
  UserRelationshipManagerModel,
} from '@halo-common/models';
import { getAdminUsers } from '@halo-data-sources/clients';
import { ApiUserManagementMapper } from '@halo-data-sources/mappers';
import { AdminUserQueryKeyFactory } from '@halo-data-sources/queries';

export type AdminUserManagementInfiniteQueryPayload = {
  relationshipManagers?: Array<UserRelationshipManagerModel>;
  users?: Array<UserModel>;
  orderBy?: Array<string>;
};

export type AdminUserManagementInfiniteQueryResult = {
  users: Array<UserModel>;
  pagination: InfinitePaginationModel;
};

export type AdminUserManagementInfiniteQueryParams = {
  page: string;
  per_page: string;
  relationship_managers?: string;
  users?: string;
  order_by?: string;
};

const getAdminUserManagementFn = async (
  payload: AdminUserManagementInfiniteQueryPayload | undefined,
  pageParams: InfinitePaginationModel,
) => {
  const page = pageParams.next;
  const resultsPerPage = pageParams.resultsPerPage;

  const params: AdminUserManagementInfiniteQueryParams = {
    page: page.toString(),
    per_page: resultsPerPage.toString(),
  };

  if (payload?.orderBy?.length) {
    const sorting = payload.orderBy.map(
      (field) =>
        ({
          name: 'name',
          'organization.name': 'organization',
          createdAt: 'created_on',
          lastLogin: 'last_login',
          relationshipManager: 'relationship_manager',
        })[field],
    );

    params.order_by = sorting.join(',');
  }

  if (payload?.users?.length) {
    params.users = payload.users.map(({ id }) => id).join(',');
  }

  if (payload?.relationshipManagers?.length) {
    params.relationship_managers = payload.relationshipManagers.map(({ id }) => id).join(',');
  }

  const searchParams = new URLSearchParams(params);

  const response = await getAdminUsers(searchParams);

  const users = response.org_members.map((member) =>
    ApiUserManagementMapper.toUser(member, response.activity, response.education_progress),
  );

  const pagination: InfinitePaginationModel = {
    ...pageParams,
    next: pageParams.page + 1,
    totalResults: 25,
    totalPages: response.meta.total_pages,
  };

  return { users, pagination };
};

export const useAdminUserManagementInfiniteQuery = (
  payload?: AdminUserManagementInfiniteQueryPayload,
  options?: InfiniteQueryOptions<AdminUserManagementInfiniteQueryResult>,
) =>
  useHaloInfiniteQuery<AdminUserManagementInfiniteQueryResult>({
    queryKey: AdminUserQueryKeyFactory.manage(payload),
    queryFn: ({ pageParam }) => getAdminUserManagementFn(payload, pageParam),

    ...options,
  });
