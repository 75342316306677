import { ReactElement } from 'react';

import { modalAtom } from '@halo-atoms/common';
import {
  addPositionsAssetSubmittedAtom,
  addPositionToMultipleAccountModalAtom,
  assetIdSearchValueAtom,
} from '@halo-atoms/portfolio';
import { AddPositionToMultipleAccountsFormFields } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';

export const AddPositionToMultipleAccountsFooterBackButton = (): ReactElement | null => {
  const { reset } = useFormContext<AddPositionToMultipleAccountsFormFields>();

  const setAssetIdSubmitted = useSetAtom(addPositionsAssetSubmittedAtom);
  const setAssetIdSearchValue = useSetAtom(assetIdSearchValueAtom);
  const [addPositionToMultipleAccount, setAddPositionToMultipleAccount] = useAtom(
    addPositionToMultipleAccountModalAtom,
  );

  const { modalStepMap, hideBackButtonOnStep } = addPositionToMultipleAccount;
  const { handlePrevAtom, handleResetAtom, currentPageAtom } = modalStepMap;

  const setModalMap = useSetAtom(modalAtom);
  const step = useAtomValue(currentPageAtom);
  const handleBack = useSetAtom(handlePrevAtom);
  const handleReset = useSetAtom(handleResetAtom);

  if (hideBackButtonOnStep === step) return null;

  const returnToAccountsOrPositionModal = () => {
    reset();
    handleReset();
    setAssetIdSubmitted(false);
    setAssetIdSearchValue(null);
    setAddPositionToMultipleAccount();
    setModalMap({ addAccountOrPositionsModal: true });
  };

  const addMoreHoldings = () => {
    reset();
    setAssetIdSubmitted(false);
    setAssetIdSearchValue(null);
    handleReset();
    setAddPositionToMultipleAccount({ asset: null, open: true });
  };

  const backFromChooseAssetButton = (
    <LocalizedButton
      onClick={returnToAccountsOrPositionModal}
      variant="text"
      color="primary"
      startIcon={<Iconography iconName="arrow-left" />}
    >
      {translations.common.back}
    </LocalizedButton>
  );

  const backFromChooseAccountButton = (
    <LocalizedButton
      onClick={handleBack}
      variant="text"
      color="primary"
      startIcon={<Iconography iconName="arrow-left" />}
    >
      {translations.common.back}
    </LocalizedButton>
  );

  const addMoreHoldingsButton = (
    <LocalizedButton
      onClick={addMoreHoldings}
      variant="text"
      color="primary"
      startIcon={<Iconography iconName="arrow-rotate-right" />}
    >
      {translations.portfolio.common.addMoreHoldingsButton}
    </LocalizedButton>
  );

  const backButtonList = [backFromChooseAssetButton, backFromChooseAccountButton, addMoreHoldingsButton];

  return backButtonList[step];
};
