import { Radio, RadioGroup } from '@halo-common/components/FormBuilder';
import { DocusignExperienceEnum } from '@halo-common/enums/docusign';
import { translations } from '@halo-common/translations';
import { OnboardingSuitabilityForm, SuitabilityStepModel } from '@halo-modules/app/v2/onboarding';

const suitability = translations.onboarding.suitability;

const experienceOptions = [
  { label: suitability.none, value: DocusignExperienceEnum.NONE },
  { label: suitability.oneTwoYears, value: DocusignExperienceEnum.ONE_TO_TWO },
  { label: suitability.threeFiveYears, value: DocusignExperienceEnum.THREE_TO_FIVE },
  { label: suitability.sixNineYears, value: DocusignExperienceEnum.SIX_TO_NINE },
  { label: suitability.tenPlusYears, value: DocusignExperienceEnum.TEN_PLUS },
];

const FirmExperienceForm = () => (
  <OnboardingSuitabilityForm>
    <RadioGroup name="firm_details.experience_years_radio">
      {experienceOptions.map((option) => (
        <Radio key={option.value} value={option.value} label={option.label} />
      ))}
    </RadioGroup>
  </OnboardingSuitabilityForm>
);

export const firmExperienceInTradingStep: SuitabilityStepModel = {
  id: '1-7',
  title: suitability.firmExperienceInTrading,
  component: <FirmExperienceForm />,
  isCompleted: (formData) => {
    const firmDetails = formData?.firm_details || {};
    return Boolean(firmDetails.experience_years_radio);
  },
};
