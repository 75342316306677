import { ReactElement, useEffect } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { selectedWatchlistAtom } from '@halo-atoms/watchlists';
import { useDeleteWatchlistMutation } from '@halo-data-sources/mutations';
import { DeleteWatchlistModalFooter } from '@halo-modules/app';
import { Modal } from '@halodomination/halo-fe-common';
import { Alert } from '@mui/material';
import { useAtomValue } from 'jotai';

export type DeleteWatchlistModalProps = {
  onClose: (showEdit?: boolean) => void;
};

export const DeleteWatchlistModal = ({ onClose }: DeleteWatchlistModalProps): ReactElement => {
  const modalMap = useAtomValue(modalAtom);
  const selectedWatchlist = useAtomValue(selectedWatchlistAtom);

  const { mutate, isError, isSuccess, isPending } = useDeleteWatchlistMutation({ navigate: true });

  const open = Boolean(modalMap?.deleteWatchlist);

  const handleDelete = () => void mutate(selectedWatchlist?.id);
  const handleClose = () => void onClose();
  const handleBack = () => void onClose(true);

  const errorAlert = isError ? (
    <Alert severity="error" variant="filled">
      Something went wrong. Please try submitting again shortly.
    </Alert>
  ) : null;

  useEffect(() => {
    if (isSuccess) handleClose();
  }, [isSuccess]);

  return (
    <Modal
      title="Deleting a Watchlist will permanently remove it from your account."
      icon="trash"
      open={open}
      size="small"
      onClose={handleClose}
      ContentProps={{ sx: { py: 3 } }}
      footer={<DeleteWatchlistModalFooter loading={isPending} onDelete={handleDelete} onBack={handleBack} />}
    >
      {errorAlert}
    </Modal>
  );
};
