import { AllocationWholesalerModel } from '@halo-common/models';
import { fetchWholesalers } from '@halo-data-sources/clients';
import { ApiAllocationMapper } from '@halo-data-sources/mappers';
import { WholesalersQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getWholesalersFn = async () => {
  const response = await fetchWholesalers();

  return response.wholesalers.map((model) => ApiAllocationMapper.toAllocationWholesalerModel(model));
};

export const useWholesalersQuery = (): UseQueryResult<Array<AllocationWholesalerModel>, Error> =>
  useQuery<Array<AllocationWholesalerModel>, Error>({
    queryKey: WholesalersQueryKeyFactory.wholesalers(),
    queryFn: () => getWholesalersFn(),
  });
