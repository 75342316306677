import { createContext, ReactElement, ReactNode, useContext, useEffect, useRef, useState } from 'react';

import { ThunkConfig } from '@halo-stores/createThunk';
import { WebsocketActions } from '@halo-stores/Websocket';
import { isInDevBuildEnv } from '@halodomination/halo-fe-common';
import { ActionCreatorWithPayload, AsyncThunk } from '@reduxjs/toolkit';
import { batch, useDispatch } from 'react-redux';

enum LegacyWebSocketErrorTypes {
  ERROR = 'ERROR',
  CONNECTION = 'CONNECTION',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

const INITIAL_STATE: LegacyWebSocketState = {
  connected: null,
  disconnectedProperly: false,
  error: null,
  connectionOptions: null,
};

let reconnectInterval: ReturnType<typeof setInterval>;

export type LegacyWebSocketState = {
  connected: boolean | null;
  disconnectedProperly: boolean;
  error: keyof typeof LegacyWebSocketErrorTypes | null;
  connectionOptions: LegacyWebSocketConnectOptions | null;
};

export type LegacyWebSocketMessage = {
  type: string;
  halo_user: number;
  status: string;
  status_code: number;
  data: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
    type: string;
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LegacyWebSocketHandler = AsyncThunk<any, any, ThunkConfig<any>> | ActionCreatorWithPayload<any, any>;

export type LegacyWebSocketHandlerMap = {
  [event: string]: LegacyWebSocketHandler | Array<LegacyWebSocketHandler>;
};

export type LegacyWebSocketConnectOptions = {
  redux?: { handlers: LegacyWebSocketHandlerMap };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  keys?: { [event: string]: (data: any) => void };
  delay?: number;
  additionalPayload?: { [key: string]: unknown };
  resolver?: () => void;
};

export type LegacyWebSocketConnectHook = (options: LegacyWebSocketConnectOptions) => void;

export type LegacyWebSocketProviderContext = {
  connected: LegacyWebSocketState['connected'];
  error: LegacyWebSocketState['error'];
  connect: LegacyWebSocketConnectHook;
  disconnect: () => void;
};

export type LegacyWebSocketProviderOptions = {
  children: ReactNode;
};

const LegacyWebSocketContext = createContext<LegacyWebSocketProviderContext>({
  connected: INITIAL_STATE.connected,
  error: INITIAL_STATE.error,
  connect: () => {
    // NO-OP
  },
  disconnect: () => {
    // NO-OP
  },
});

export const useLegacyWebSocket = (): LegacyWebSocketProviderContext => {
  return useContext<LegacyWebSocketProviderContext>(LegacyWebSocketContext);
};

export const LegacyWebSocketProvider = ({ children }: LegacyWebSocketProviderOptions): ReactElement => {
  const dispatch = useDispatch();

  const protocol = location.protocol !== 'https:' ? 'ws' : 'wss';
  const domain = process.env.NEXT_PUBLIC_INSTITUTIONAL_WS_URL ?? `${protocol}://${location.host}`;

  const socketRef = useRef<WebSocket | null>(null);

  const [connected, setConnected] = useState(INITIAL_STATE.connected);
  const [disconnectedProperly, setDisconnectedProperly] = useState(INITIAL_STATE.disconnectedProperly);
  const [error, setError] = useState(INITIAL_STATE.error);
  const [connectionOptions, setConnectionOptions] = useState(INITIAL_STATE.connectionOptions);

  const connect = (options: LegacyWebSocketConnectOptions) => {
    const { delay, resolver, keys, redux } = options;

    if (connected || socketRef.current) return;
    if (delay) dispatch(WebsocketActions.setTimeoutDelay({ delay }));

    const websocketUrl = `${domain}/ws`;
    socketRef.current = new WebSocket(websocketUrl);

    setConnectionOptions(options);

    const openReduxBasedConnection = function (this: WebSocket, ev: MessageEvent<string>) {
      const { data: eventData } = ev;
      const parsedEventData = JSON.parse(eventData) as LegacyWebSocketMessage;
      const { data, type, status, status_code } = parsedEventData;

      if (status === 'error' && status_code === 403) {
        setError(LegacyWebSocketErrorTypes.UNAUTHORIZED);
      } else {
        const subType = data?.type;
        const handler = redux?.handlers[subType] ?? redux?.handlers[type];

        if (handler) {
          const actionPayload = { ...(data ?? parsedEventData), ...options?.additionalPayload };

          batch(() => {
            if (typeof handler === 'function') dispatch(handler(actionPayload));
            else handler.forEach((action) => dispatch(action(actionPayload)));

            if (resolver) resolver();
            else dispatch(WebsocketActions.handleResolved());
          });
        }
      }
    };

    const openConnection = function (this: WebSocket, ev: MessageEvent<string>) {
      const { data: eventData } = ev;
      const parsedEventData = JSON.parse(eventData) as LegacyWebSocketMessage;
      const { data, type, status, status_code } = parsedEventData;

      if (status === 'error' && status_code === 403) {
        setError(LegacyWebSocketErrorTypes.UNAUTHORIZED);
      } else {
        const subType = data?.type;
        const eventHandler = keys?.[subType] ?? keys?.[type];
        const actionPayload = { ...(data ?? parsedEventData), ...options?.additionalPayload };
        eventHandler?.(actionPayload);
      }
    };

    socketRef.current.onopen = function (this: WebSocket) {
      if (isInDevBuildEnv()) console.info(`Websocket connection established to ${websocketUrl}.`);
      setConnected(true);
      this.onmessage = redux ? openReduxBasedConnection : openConnection;
    };

    socketRef.current.onclose = function (this: WebSocket) {
      setConnected(false);
    };

    socketRef.current.onerror = function (this: WebSocket, ev: Event) {
      if (isInDevBuildEnv()) console.error(`Websocket error occurred.`, ev.type);
    };
  };

  const reconnect = () => {
    if (isInDevBuildEnv()) console.warn('Websocket connection lost. Reconnecting...');

    if (!reconnectInterval) reconnectInterval = setInterval(reconnect, 5000);

    connect(connectionOptions as LegacyWebSocketConnectOptions);
  };

  const disconnect = () => {
    if (isInDevBuildEnv()) console.error(`Websocket connection disconnected.`);

    setDisconnectedProperly(true);
    clearInterval(reconnectInterval);

    socketRef?.current?.close();
  };

  useEffect(() => {
    if (connected) {
      setError(INITIAL_STATE.error);
    } else if (connected !== null && !disconnectedProperly) {
      setError(LegacyWebSocketErrorTypes.CONNECTION);
    }
  }, [connected]);

  useEffect(() => {
    if (error === LegacyWebSocketErrorTypes.CONNECTION) {
      reconnect();
    } else if (error === LegacyWebSocketErrorTypes.UNAUTHORIZED) {
      disconnect();
    } else {
      clearInterval(reconnectInterval);
    }
  }, [error]);

  const context = { connected, error, connect, disconnect };

  return <LegacyWebSocketContext.Provider value={context}>{children}</LegacyWebSocketContext.Provider>;
};
