import { navigateParentTo } from '@halo-common/utils';
import { logout } from '@halo-data-sources/clients';
import { HttpError, HttpValidationError } from '@halo-data-sources/errors';
import { isInDevBuildEnv } from '@halodomination/halo-fe-common';

type RequestHeaders = HeadersInit & {
  'white-label-id'?: string;
  'Content-Type'?: string;
};

export type RequestOptions = RequestInit & { failOnError?: boolean };

const DEFAULT_ERROR_MESSAGE = 'An error occurred, while making a request. Please try again later.';

const handleError = async (response: Response): Promise<void> => {
  const { status } = response;

  if (status === 401) {
    const response = await logout();

    const url = response?.destination ?? '/';

    navigateParentTo(url);

    throw new HttpError(status, 'Unauthorized Request');
  } else if (status === 422) {
    const invalidResponse = await response.text();
    const parsedInvalidResponse = JSON.parse(invalidResponse);

    const errorMessage = parsedInvalidResponse.error?.status ?? parsedInvalidResponse.message;

    throw new HttpValidationError(status, errorMessage, parsedInvalidResponse.detail);
  } else if (status !== 500) {
    const invalidResponse = await response.text();
    const parsedInvalidResponse = JSON.parse(invalidResponse);

    const errorMessage = parsedInvalidResponse.error?.status ?? parsedInvalidResponse.message;

    throw new HttpError(status, errorMessage);
  } else {
    throw new HttpError(status, DEFAULT_ERROR_MESSAGE);
  }
};

const buildRequestOptions = (options: RequestOptions): RequestInit => {
  const headers: RequestHeaders = {
    'Content-Type': 'application/json',
    ...options.headers,
  };

  const removeContentType = options.method === 'GET' || headers['Content-Type']?.startsWith('multipart/');

  if (removeContentType) delete headers['Content-Type'];
  if (process.env.NEXT_PUBLIC_WHITE_LABEL_ID) headers['white-label-id'] = process.env.NEXT_PUBLIC_WHITE_LABEL_ID;

  return { ...options, headers };
};

export const request = async <T extends unknown>(path: string, options: RequestOptions): Promise<T> => {
  try {
    const { failOnError = true, ...additionalOptions } = options;

    const requestOptions = buildRequestOptions(additionalOptions);

    const response = await fetch(path, requestOptions);

    const throwError = failOnError && !response.ok;
    if (throwError) await handleError(response);

    if (response.status === 204) return null as T;
    if (response.headers.get('Content-Type')?.includes('text')) return (await response.text()) as T;

    return (await response.json()) as T;
  } catch (e) {
    if (isInDevBuildEnv()) console.error(e);

    if (typeof e === 'string') throw new Error(e);
    else throw e;
  }
};
