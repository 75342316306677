import { ReactElement } from 'react';

import { V2NoteModel } from '@halo-common/models';
import { Stack } from '@halodomination/halo-fe-common';
import { Skeleton, Typography } from '@mui/material';

export type V2PostTradeNoteDetailModalHeaderSolvableParameterProps = {
  parameter?: V2NoteModel['payoffDetails']['solvableParameter'] | null;
  loading?: boolean;
};

export const V2PostTradeNoteDetailModalHeaderSolvableParameter = ({
  parameter,
  loading = true,
}: V2PostTradeNoteDetailModalHeaderSolvableParameterProps): ReactElement => {
  if (loading) return <Skeleton width={200} height={80} />;

  const payoffs = parameter?.payoffs ?? [];
  const conjunction = parameter?.conjunction;

  const columns = payoffs?.map(({ name, value, when }, index) => (
    <Stack key={name} direction="row" alignItems="center" spacing={2}>
      <Stack direction="column">
        <Typography variant="h2" color="textPrimary">
          {value}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {`${name}${when ? ` ${when}` : ''}`}
        </Typography>
      </Stack>
      {conjunction && index !== payoffs.length - 1 ? (
        <Typography variant="body2" color="textSecondary">
          {conjunction}
        </Typography>
      ) : null}
    </Stack>
  ));

  return (
    <Stack direction="row" spacing={2}>
      {columns}
    </Stack>
  );
};
