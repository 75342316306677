import { Children, ReactElement, ReactNode } from 'react';

import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, Stack } from '@mui/material';

const borderSx = {
  borderLeft: (theme: HaloTheme) => `solid 1px ${theme.palette.grey[300]}`,
  paddingLeft: 1.5,
  width: 'fit-content',
};

const payoffContainerSx = {
  paddingRight: 1,
  width: '40%',
};

export type ProductCardDetailsProps = {
  payoff: ReactNode;
  children?: ReactNode;
};

export const ProductCardDetails = ({ children, payoff }: ProductCardDetailsProps): ReactElement => {
  const fields = Children.toArray(children);

  return (
    <Stack direction="row" alignItems="center">
      <Box sx={payoffContainerSx}>{payoff}</Box>
      <Stack direction="column" sx={borderSx} spacing={1}>
        {fields}
      </Stack>
    </Stack>
  );
};
