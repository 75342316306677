import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiRollingReturnsRequestModel,
  ApiRollingReturnsResponseModel,
  ApiRollingReturnsStatisticsRequestModel,
  ApiRollingReturnsStatisticsResponseModel,
  ApiRollingReturnsSummaryRequestModel,
  ApiRollingReturnsSummaryResponseModel,
  ApiRollingReturnsUnderlyingDataRequestModel,
  ApiRollingReturnsUnderlyingDataResponseModel,
  ApiRollingReturnsUnderlyingStatisticsRequestModel,
  ApiRollingReturnsUnderlyingStatisticsResponseModel,
} from '@halo-data-sources/models';

const ROLLING_RETURNS_PATH = '/pretrade/rolling/returns';

export const getRollingReturnsData = async (
  body: ApiRollingReturnsRequestModel,
): Promise<ApiRollingReturnsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ROLLING_RETURNS_PATH);

  const response = await request<ApiRollingReturnsResponseModel>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    failOnError: false,
    method: 'POST',
    body: JSON.stringify({
      ...body,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const getRollingReturnsSummaryData = async (
  body: ApiRollingReturnsSummaryRequestModel,
): Promise<ApiRollingReturnsSummaryResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ROLLING_RETURNS_PATH);

  const url = `${clientConfiguration.basePath}/summary`;

  const response = await request<ApiRollingReturnsSummaryResponseModel>(url, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...body,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const getRollingReturnsStatistics = async (
  body: ApiRollingReturnsStatisticsRequestModel,
): Promise<ApiRollingReturnsStatisticsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ROLLING_RETURNS_PATH);

  const url = `${clientConfiguration.basePath}/statistics`;

  const response = await request<ApiRollingReturnsStatisticsResponseModel>(url, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...body,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const getRollingReturnsUnderlyingData = async (
  body: ApiRollingReturnsUnderlyingDataRequestModel,
): Promise<ApiRollingReturnsUnderlyingDataResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ROLLING_RETURNS_PATH);

  const url = `${clientConfiguration.basePath}/underliers`;

  const response = await request<ApiRollingReturnsUnderlyingDataResponseModel>(url, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...body,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const getRollingReturnsUnderlyingStatisticsData = async (
  body: ApiRollingReturnsUnderlyingStatisticsRequestModel,
): Promise<ApiRollingReturnsUnderlyingStatisticsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ROLLING_RETURNS_PATH);

  const url = `${clientConfiguration.basePath}/underliers/statistics`;

  const response = await request<ApiRollingReturnsUnderlyingStatisticsResponseModel>(url, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...body,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};
