import { SMAAllocationModel } from '@halo-common/models';
import { getSMAAllocationRequest } from '@halo-data-sources/clients';
import { ApiSMAMapper } from '@halo-data-sources/mappers';
import { SMAQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getSMAAllocationsFn = async () => {
  const response = await getSMAAllocationRequest();
  return response.map((model) => ApiSMAMapper.toSMAAllocationModel(model));
};

export const useSMAAllocationsQuery = (): UseQueryResult<Array<SMAAllocationModel>, Error> =>
  useQuery<Array<SMAAllocationModel>, Error>({
    queryKey: SMAQueryKeyFactory.allocations(),
    queryFn: () => getSMAAllocationsFn(),
  });
