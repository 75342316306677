import { DateTime } from 'luxon';
import hashObject from 'object-hash';

// TODO: Remove when we are fully migrated over to React
export const navigateParentTo = (uri = '/', inNewTab = false): void => {
  if (inNewTab) {
    const isAbsoluteUrl = uri.startsWith('http://') || uri.startsWith('https://');
    const url = isAbsoluteUrl ? uri : `${window.parent.origin}${uri}`;
    void window.open(url, '_blank');
  } else {
    void window.parent.location.assign(uri);
  }
};

export const hasTimeComponent = (date: string): boolean => {
  const dt = DateTime.fromISO(date);
  return dt.hour !== 0 || dt.minute !== 0 || dt.second !== 0 || dt.millisecond !== 0;
};

export const roundFloatToSpecificDecimal = (value?: number | null, decimalPlace = 2): number => {
  if (typeof value !== 'number') return Number.NaN;
  const multiplier = Math.pow(10, decimalPlace);
  return Math.round((value + Number.EPSILON) * multiplier) / multiplier;
};

export const getCountryNameFormat = (countryCode: string, locale = 'en'): string => {
  const formattedLocale = locale.substring(0, 2);
  const regionObj = new Intl.DisplayNames([formattedLocale], { type: 'region' });

  return regionObj.of(countryCode) ?? 'Unknown Country Name';
};

export const toStringOrBlob = (value: unknown): string | Blob => {
  if (typeof value === 'string') return value;
  if (value instanceof Blob) return value;
  if (value instanceof File) return value;
  return JSON.stringify(value);
};

export const toKebabCase = (...classNames: (string | null | undefined)[]): string =>
  classNames
    .map((className) => className?.trim().toLowerCase())
    .filter(Boolean)
    .map((className) => className?.replace(/\s+/g, '-'))
    .join('-');

export const isFalsy = (value: unknown): boolean =>
  value === undefined ||
  value === null ||
  value === false ||
  value === '' ||
  (Array.isArray(value) && value.length === 0) ||
  (typeof value === 'object' && Object.keys(value).length === 0);

export const cleanObject = (object: unknown): unknown => {
  if (typeof object !== 'object' || object === null) {
    return object;
  }

  if (Array.isArray(object)) {
    return object.map(cleanObject).filter((item) => !isFalsy(item));
  }

  const result: Record<string, unknown> = {};
  Object.entries(object).forEach(([key, value]) => {
    const skipKey = ['isFiltered', 'filtersChanged'].includes(key);
    if (skipKey) return;
    const cleanedValue = cleanObject(value);
    if (!isFalsy(cleanedValue)) {
      result[key] = cleanedValue;
    }
  });

  return result;
};

// eslint-disable-next-line @typescript-eslint/no-wrapper-object-types
export const hash = (value?: Object | null): string => {
  if (!value) return '';
  else return hashObject(value);
};
