import { ReactElement } from 'react';

import { ProductDetailPanelSection, ProductDetailPanelSectionField } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { ITranslateParams } from '@transifex/native';

export type ProductDetailPanelPrincipalSectionLocalization = {
  protection?: ITranslateParams;
  term?: ITranslateParams;
  gearing?: ITranslateParams;
};

export type ProductDetailPanelPrincipalSectionProps = {
  term?: string;
  gearing?: string;
  protection?: string;
  protectionType?: string;
  currency?: string;
  loading: boolean;
  Localization?: ProductDetailPanelPrincipalSectionLocalization;
};

export const ProductDetailPanelPrincipalSection = ({
  term,
  gearing,
  protection,
  protectionType,
  currency,
  loading,
  Localization = {},
}: ProductDetailPanelPrincipalSectionProps): ReactElement | null => {
  const showTermLine = loading || typeof term === 'string';
  const showCurrencyLine = loading || typeof currency === 'string';
  const showPrincipalLine = loading || typeof protection === 'string';
  const showGearingLine = loading || typeof gearing === 'string';

  const hideSection = !showTermLine && !showPrincipalLine && !showCurrencyLine;

  if (hideSection) return null;

  const termLine = showTermLine ? (
    <ProductDetailPanelSectionField
      label={translations.common.term}
      description={term}
      loading={loading}
      dynamicContent={Localization.term}
    />
  ) : null;

  const principalProtectionLine = showPrincipalLine ? (
    <ProductDetailPanelSectionField
      label={translations.pdm.common.principalProtectionSection}
      description={protection}
      translations={{ description: protectionType }}
      loading={loading}
      dynamicContent={Localization.protection}
    />
  ) : null;

  const gearingLine = showGearingLine ? (
    <ProductDetailPanelSectionField
      label={translations.common.gearing}
      description={gearing}
      loading={loading}
      dynamicContent={Localization.gearing}
    />
  ) : null;

  const currencyLine = showCurrencyLine ? (
    <ProductDetailPanelSectionField label={translations.common.currency} description={currency} loading={loading} />
  ) : null;

  return (
    <ProductDetailPanelSection title={translations.pdm.common.principalSection} loading={loading}>
      {termLine}
      {principalProtectionLine}
      {gearingLine}
      {currencyLine}
    </ProductDetailPanelSection>
  );
};
