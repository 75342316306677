export const STATE_LIST = [
  {
    label: '',
    value: '',
  },
  {
    label: 'Outside of the US',
    value: 'non-us',
  },
  {
    label: 'AL - Alabama',
    value: 'al',
  },
  {
    label: 'AK - Alaska',
    value: 'ak',
  },
  {
    label: 'AZ - Arizona',
    value: 'az',
  },
  {
    label: 'AR - Arkansas',
    value: 'ar',
  },
  {
    label: 'CA - California',
    value: 'ca',
  },
  {
    label: 'CO - Colorado',
    value: 'co',
  },
  {
    label: 'CT - Connecticut',
    value: 'ct',
  },
  {
    label: 'DE - Delaware',
    value: 'de',
  },
  {
    label: 'FL - Florida',
    value: 'fl',
  },
  {
    label: 'GA - Georgia',
    value: 'ga',
  },
  {
    label: 'HI - Hawaii',
    value: 'hi',
  },
  {
    label: 'ID - Idaho',
    value: 'id',
  },
  {
    label: 'IL - Illinois',
    value: 'il',
  },
  {
    label: 'IN - Indiana',
    value: 'in',
  },
  {
    label: 'IA - Iowa',
    value: 'ia',
  },
  {
    label: 'KA - Kansas',
    value: 'ka',
  },
  {
    label: 'KY - Kentucky',
    value: 'ky',
  },
  {
    label: 'LA - Louisiana',
    value: 'la',
  },
  {
    label: 'ME - Maine',
    value: 'me',
  },
  {
    label: 'MD - Maryland',
    value: 'md',
  },
  {
    label: 'MA - Massachusetts',
    value: 'ma',
  },
  {
    label: 'MI - Michigan',
    value: 'mi',
  },
  {
    label: 'MN - Minnesota',
    value: 'mn',
  },
  {
    label: 'MS - Mississippi',
    value: 'ms',
  },
  {
    label: 'MO - Missouri',
    value: 'mo',
  },
  {
    label: 'MT - Montana',
    value: 'mt',
  },
  {
    label: 'NE - Nebraska',
    value: 'ne',
  },
  {
    label: 'NV - Nevada',
    value: 'nv',
  },
  {
    label: 'NH - New Hampshire',
    value: 'nh',
  },
  {
    label: 'NJ - New Jersey',
    value: 'nj',
  },
  {
    label: 'NM - New Mexico',
    value: 'nm',
  },
  {
    label: 'NY - New York',
    value: 'ny',
  },
  {
    label: 'NC - North Carolina',
    value: 'nc',
  },
  {
    label: 'ND - North Dakota',
    value: 'nd',
  },
  {
    label: 'OH - Ohio',
    value: 'oh',
  },
  {
    label: 'OK - Oklahoma',
    value: 'ok',
  },
  {
    label: 'OR - Oregon',
    value: 'or',
  },
  {
    label: 'PA - Pennsylvania',
    value: 'pa',
  },
  {
    label: 'RI - Rhode Island',
    value: 'ri',
  },
  {
    label: 'SC - South Carolina',
    value: 'sc',
  },
  {
    label: 'SD - South Dakota',
    value: 'sd',
  },
  {
    label: 'TN - Tennessee',
    value: 'tn',
  },
  {
    label: 'TX - Texas',
    value: 'tx',
  },
  {
    label: 'UT - Utah',
    value: 'ut',
  },
  {
    label: 'VT - Vermont',
    value: 'vt',
  },
  {
    label: 'VA - Virginia',
    value: 'va',
  },
  {
    label: 'WA - Washington',
    value: 'wa',
  },
  {
    label: 'WV - West Virginia',
    value: 'wv',
  },
  {
    label: 'WI - Wisconsin',
    value: 'wi',
  },
  {
    label: 'WY - Wyoming',
    value: 'wy',
  },
];
