import { ReactElement } from 'react';

import {
  EASTERN_DAYLIGHT_TIME_ZONE,
  HOUR_MINUTE_SECONDS_TIME_ZONE_FORMAT,
  MONTH_DAY_YEAR_DATE_FORMAT,
} from '@halo-common/constants';
import { PershingSelectors } from '@halo-stores/Pershing';
import { Alert, alertClasses } from '@mui/material';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';

const thinStyling = {
  [`& ${alertClasses.root}`]: {
    paddingTop: 1,
    paddingBottom: 1,
  },
};

export type FillOrderModalReviewAlertProps = {
  status?: 'success' | 'error';
};

export const FillOrderModalReviewAlert = ({ status }: FillOrderModalReviewAlertProps): ReactElement => {
  const selectedAllocation = useSelector(PershingSelectors.selectAllocation);

  const date = selectedAllocation?.updatedOn as string;
  const updatedOn = DateTime.fromISO(date);
  const dateToday = updatedOn.setZone(EASTERN_DAYLIGHT_TIME_ZONE).toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
  const timeToday = updatedOn.setZone(EASTERN_DAYLIGHT_TIME_ZONE).toFormat(HOUR_MINUTE_SECONDS_TIME_ZONE_FORMAT);

  const message =
    status !== 'error'
      ? `Filled on ${dateToday} at ${timeToday}`
      : `There was an issue filling this order. Please try again.`;

  return (
    <Alert severity={status} sx={thinStyling} variant="filled">
      {message}
    </Alert>
  );
};
