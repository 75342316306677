import { AllocationModel, ExecutionOrderModel } from '@halo-common/models';
import { postCalendarOrderQuery } from '@halo-data-sources/clients';
import { ApiComparisonOptionEnum } from '@halo-data-sources/enums';
import { ApiAllocationMapper, ApiExecOrderMapper } from '@halo-data-sources/mappers';
import { OrdersClientQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export type CalendarOrderQueryResult = {
  allocation: AllocationModel;
  executionOrder: ExecutionOrderModel;
  transactionSummary: Record<string, number>;
};

const getCalendarOrderQueryFn = async (id?: number) => {
  if (!id) return null;

  const response = await postCalendarOrderQuery({
    comparisons: [
      {
        field: 'calendar_allocations.id',
        op: ApiComparisonOptionEnum.EQ,
        value: id,
      },
    ],
  });

  const calendarOrder = response.results[0];

  const principal = parseFloat(calendarOrder.extra.principal);
  const commission = parseFloat(calendarOrder.extra.commission);
  const otherMiscFees = parseFloat(calendarOrder.extra.other_misc_fees);
  const salesCredit = parseFloat(calendarOrder.extra.sales_credit);
  const total = parseFloat(calendarOrder.extra.total);

  return {
    allocation: ApiAllocationMapper.toAllocationModel(calendarOrder.allocation),
    executionOrder: ApiExecOrderMapper.toExecutionOrder(calendarOrder.exec_order),
    transactionSummary: {
      principal: !Number.isNaN(principal) ? principal : 0,
      commission: !Number.isNaN(commission) ? commission : 0,
      otherMiscFees: !Number.isNaN(otherMiscFees) ? otherMiscFees : 0,
      salesCredit: !Number.isNaN(salesCredit) ? salesCredit : 0,
      total: !Number.isNaN(total) ? total : 0,
    },
  };
};

export const useCalendarOrderQuery = (id?: number): UseQueryResult<CalendarOrderQueryResult | null, Error> =>
  useQuery<CalendarOrderQueryResult | null, Error>({
    queryKey: OrdersClientQueryKeyFactory.calendarOrder(id),
    queryFn: () => getCalendarOrderQueryFn(id),
    retry: false,
  });
