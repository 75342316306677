import { HTMLAttributes, ReactElement, useState } from 'react';

import { useDebounced } from '@halo-common/hooks';
import { UserActivityAction } from '@halo-common/models';
import { UserActivityActionsQueryPayload, useUserActivityActionsQuery } from '@halo-data-sources/queries';
import { Stack } from '@halodomination/halo-fe-common';
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  Box,
  Chip,
  CircularProgress,
  TextField,
  TextFieldProps,
  Typography,
  inputBaseClasses,
} from '@mui/material';

const endAdornmentSx = {
  display: 'flex',
};

const loadingIndicatorSx = {
  marginRight: 1,
};

const clientInputSx = {
  [`.${inputBaseClasses.root}`]: {
    paddingLeft: 2,
  },
};

export type UserActivityActionTypeAheadProps = Omit<
  AutocompleteProps<UserActivityAction, boolean, boolean, undefined>,
  'options' | 'renderInput' | 'onChange'
> & {
  filters?: UserActivityActionsQueryPayload;
  onChange?: (option: UserActivityAction | Array<UserActivityAction> | null) => void;
  TextFieldProps?: TextFieldProps;
};

export const UserActivityActionTypeAhead = ({
  filters,
  TextFieldProps,
  onChange,
  ...props
}: UserActivityActionTypeAheadProps): ReactElement => {
  const [query, setQuery] = useState('');

  const { data: actions = [], isPending } = useUserActivityActionsQuery(filters);

  const options = !query ? actions : actions.filter((action) => action.name.includes(query));

  const debouncedSearchHandler = useDebounced(setQuery, 500);

  const handleSearch = (_: unknown, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason !== 'input') return undefined;
    debouncedSearchHandler(value);
  };

  const getOptionLabel = (option: UserActivityAction) => option.name;

  const handleChange = (_: unknown, option: UserActivityAction | Array<UserActivityAction> | null) => {
    onChange?.(option);
  };

  const handleRenderOption = (props: HTMLAttributes<HTMLLIElement>, option: UserActivityAction) => (
    <Box {...props} component="li" key={option.name}>
      <Stack direction="row" justify="space-between" alignItems="center" sx={{ width: '100%' }}>
        <Typography>{option.name}</Typography>
        <Chip label={option.count} size="small" color="primary" />
      </Stack>
    </Box>
  );

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const textFieldInputLoadingIndicator = isPending ? <CircularProgress sx={loadingIndicatorSx} size={20} /> : null;

    const textFieldInputProps = {
      ...params.InputProps,
      endAdornment: (
        <Box sx={endAdornmentSx}>
          {textFieldInputLoadingIndicator}
          {params.InputProps.endAdornment}
        </Box>
      ),
    };

    return (
      <TextField
        {...params}
        label="Search Activity Actions"
        multiline={props.multiple}
        size="large"
        sx={clientInputSx}
        slotProps={{ input: textFieldInputProps }}
        {...TextFieldProps}
      />
    );
  };

  return (
    <Autocomplete<UserActivityAction, boolean, boolean, undefined>
      {...props}
      options={options}
      onChange={handleChange}
      onInputChange={handleSearch}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel}
      renderOption={handleRenderOption}
    />
  );
};
