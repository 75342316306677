import { ReactElement, useMemo } from 'react';

import { translations } from '@halo-common/translations';
import { Iconography, LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack } from '@halodomination/halo-fe-common';
import { ButtonGroup, useMediaQuery, useTheme } from '@mui/material';

const downloadStyling = { backgroundColor: 'common.white' };
const cellStyling = { pr: 2 };
const nameCellStyling = { pr: 2, wordWrap: 'break-word' };
const rowStyling = {
  borderTop: '1px solid',
  borderTopColor: 'grey.300',
  padding: 3,
  '&:hover': {
    backgroundColor: 'grey.100',
    cursor: 'default',
  },
};
export type DocumentsTableRowValueType = {
  id: string;
  name: string;
  description?: string;
  extension: string;
};

export type DocumentsTableInputModel = Record<string, unknown>;

export type DocumentsTableRowMapFunction<T> = (model: T) => DocumentsTableRowValueType;

export type DocumentsTableRowProps<T> = {
  model: T;
  mapValues: DocumentsTableRowMapFunction<T>;
  onDownload?: (model: T) => void;
  onUpload?: (model: T) => void;
  onRemove?: (model: T) => void;
};

export const DocumentsTableRow = <T extends DocumentsTableInputModel>({
  model,
  mapValues,
  onDownload,
  onUpload,
  onRemove,
}: DocumentsTableRowProps<T>): ReactElement => {
  const theme = useTheme();

  const mapped = useMemo(() => mapValues(model), [model]);

  const handleDownload = () => onDownload?.(model);
  const handleUpload = () => onUpload?.(model);
  const handleRemove = () => onRemove?.(model);

  const isMedium = useMediaQuery(theme.breakpoints.up('sm'));
  const buttonOrientation = isMedium ? 'horizontal' : 'vertical';

  return (
    <Stack
      direction="row"
      alignItems="center"
      justify="space-between"
      xs={[3, 4, 2, 3]}
      md={[4, 4, 2, 2]}
      sx={rowStyling}
      justifyElements={[undefined, undefined, undefined, 'flex-end']}
    >
      <LocalizedTypography sx={nameCellStyling}>{mapped.name}</LocalizedTypography>
      <LocalizedTypography sx={cellStyling}>{mapped.description}</LocalizedTypography>
      <LocalizedTypography sx={cellStyling}>{mapped.extension.toUpperCase()}</LocalizedTypography>
      <ButtonGroup variant="contained" aria-label="document actions" orientation={buttonOrientation}>
        {onDownload ? (
          <LocalizedButton
            sx={downloadStyling}
            color="primary"
            variant="outlined"
            size="small"
            onClick={handleDownload}
            endIcon={<Iconography iconName="arrow-alt-circle-down" color="primary.main" />}
          >
            {translations.common.download}
          </LocalizedButton>
        ) : null}
        {onUpload ? (
          <LocalizedButton
            color="primary"
            variant="contained"
            size="small"
            onClick={handleUpload}
            endIcon={<Iconography iconName="arrow-alt-circle-up" color="common.white" />}
          >
            {translations.common.upload}
          </LocalizedButton>
        ) : null}
        {onRemove ? (
          <LocalizedButton
            color="primary"
            variant="contained"
            size="small"
            onClick={handleRemove}
            endIcon={<Iconography iconName="trash-alt" color="common.white" />}
          >
            {translations.common.remove}
          </LocalizedButton>
        ) : null}
      </ButtonGroup>
    </Stack>
  );
};
