import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { OrderBookTabKeyEnum, OrderBookTabNameEnum } from '@halo-common/enums';
import { PageFooterLayout, SubNav, SubNavLayout } from '@halo-common/layouts';
import { MustCompleteOnboardingModal } from '@halo-common/modals';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import {
  OrderBookAuctionsPage,
  OrderBookCalendarsPage,
  OrderBookJanneyPage,
  OrderBookPershingPage,
} from '@halo-modules/app';
import { PageComponent, PageMeta } from '@halodomination/halo-fe-common';
import { useSetAtom } from 'jotai';

const OrderBookPage: PageComponent = () => {
  const setSearchFields = useSetAtom(orderBookQueryAtom);

  const { data: userInfo } = useUserInfoQuery();

  const orderBookTabs = userInfo?.settings.orderBookTabs ?? [];
  const hideTabs = orderBookTabs.length <= 1;
  const noPadding = !hideTabs;

  const handleTabChange = () => void setSearchFields();

  const tabs = orderBookTabs.map((tab) => {
    const tabKey = `${tab.name}-tab`;
    const tabName = OrderBookTabNameEnum[tab.name];
    const tabContent = {
      [OrderBookTabKeyEnum.annuity]: null,
      [OrderBookTabKeyEnum.auction]: <OrderBookAuctionsPage />,
      [OrderBookTabKeyEnum.calendar]: <OrderBookCalendarsPage />,
      [OrderBookTabKeyEnum.pershing]: <OrderBookPershingPage />,
      [OrderBookTabKeyEnum.janney]: <OrderBookJanneyPage />,
      [OrderBookTabKeyEnum.sma]: null,
    }[tab.name];

    return (
      <SubNav key={tabKey} name={tabName} noPadding={noPadding}>
        {tabContent}
      </SubNav>
    );
  });

  return (
    <PageFooterLayout>
      <SubNavLayout title="Order Book" hideTabs={hideTabs} TabsProps={{ onChange: handleTabChange }}>
        {tabs}
      </SubNavLayout>
      <MustCompleteOnboardingModal />
    </PageFooterLayout>
  );
};

export const OrderBookPageMeta: PageMeta = {
  pageName: 'Order Book',
  route: 'app/order-book',
  routeExpression: '/user/orderbook',
};

OrderBookPage.pageName = OrderBookPageMeta.pageName;
OrderBookPage.route = OrderBookPageMeta.route;

export default OrderBookPage;
