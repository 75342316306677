import { ReactElement } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { bulkOrderTicketManagerAtom, orderTicketReceiptAtom } from '@halo-atoms/orderTicket';
import { ModalLayout } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';

import { OrderTicketStatusAlert } from './OrderTicketStatusAlert';
import { OrderTicketSuccessFooter } from './OrderTicketSuccessFooter';
import { OrderTicketBulkOrderReceipt } from '../OrderTicketBulkOrderReceipt';
import { OrderTicketSingleOrderReceipt } from '../OrderTicketSingleOrderReceipt';

const modalContentProps = { sx: { paddingTop: 3 } };

export type OrderTicketSuccessProps = {
  bulk?: boolean;
  onClose: () => void;
};

export const OrderTicketSuccess = ({ bulk = false, onClose }: OrderTicketSuccessProps): ReactElement => {
  const selectedCalendar = useAtomValue(calendarSelectedAtom);
  const bulkOrderTicketReceipt = useAtomValue(bulkOrderTicketManagerAtom);
  const orderTicketReceipt = useAtomValue(orderTicketReceiptAtom);

  const cusip = selectedCalendar?.cusip ?? '';
  const title = `Submit Indication ${cusip}`;

  const orderDate = bulk
    ? bulkOrderTicketReceipt.lastSubmittedOrder?.order.orderReceiptTime
    : orderTicketReceipt?.order?.orderReceiptTime;

  const orderDateText = orderDate ?? null;

  const receipt = bulk ? (
    <OrderTicketBulkOrderReceipt orderReceiptTime={orderDateText} />
  ) : (
    <OrderTicketSingleOrderReceipt />
  );

  return (
    <ModalLayout
      title={title}
      ContentProps={modalContentProps}
      onClose={onClose}
      footer={<OrderTicketSuccessFooter onClose={onClose} />}
    >
      <OrderTicketStatusAlert />
      {receipt}
    </ModalLayout>
  );
};
