import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { useUserWebHooksQuery } from '@halo-data-sources/queries';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';

const disclaimerSx = { color: 'text.secondary' };

export const ProductDetailModalAnalyticsRollingReturnsDisclaimer = (): ReactElement | null => {
  const { data: webhooks } = useUserWebHooksQuery();

  const webHook = webhooks?.['react:rr:disclaimer'];
  const disclaimer = webHook?.html ?? null;

  return disclaimer ? (
    <Stack direction="column" sx={disclaimerSx} spacing={1}>
      <LocalizedTypography variant="caption">{translations.pdm.preTrade.disclaimer}</LocalizedTypography>
      <LocalizedTypography variant="disclaimer" component="div">
        {disclaimer}
      </LocalizedTypography>
    </Stack>
  ) : null;
};
