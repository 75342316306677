import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type CommonQueryKeys = {
  all: QueryKeyFactoryFn;
  countries: QueryKeyFactoryFn;
  userSettings: QueryKeyFactoryFn;
};

export const CommonQueryKeyFactory: CommonQueryKeys = {
  all: () => ['common'],
  countries: () => [...CommonQueryKeyFactory.all(), 'countries'],
  userSettings: () => [...CommonQueryKeyFactory.all(), 'user', 'settings'],
};
