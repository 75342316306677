import { ReactElement } from 'react';

import { ProductCardRemoveButton } from '@halo-common/components';
import { ProductActionTypeEnum, ProductTypeEnum } from '@halo-common/enums';
import { WatchlistButton } from '@halo-common/smartComponents';

export type ProductCardActionProps = {
  productId?: number;
  productType: ProductTypeEnum;
  action: ProductActionTypeEnum;
};

export const ProductCardAction = ({ productId, productType, action }: ProductCardActionProps): ReactElement | null => {
  const hideAction = action === ProductActionTypeEnum.none;
  const showDelete = action === ProductActionTypeEnum.delete;

  if (!productId || hideAction) return null;
  else if (showDelete) return <ProductCardRemoveButton productId={productId} productType={productType} />;
  else return <WatchlistButton productId={productId} productType={productType} />;
};
