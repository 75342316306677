import { SMACheckBoxEnum } from '@halo-common/enums';
import { SMAStrategyModel } from '@halo-common/models';
import { atom } from 'jotai';

export const selectedStrategyAtom = atom<SMAStrategyModel | null>(null);

export type AllocationOrderCheckboxes = { investmentObjChecked: boolean; advsChecked: boolean };
export type AllocationOrderActions = {
  type: SMACheckBoxEnum.CheckADV | SMACheckBoxEnum.CheckInvestmentObj;
  value: boolean;
};

export const allocationOrderCheckboxesReducer = (
  prev: AllocationOrderCheckboxes,
  action: AllocationOrderActions,
): AllocationOrderCheckboxes => {
  if (action?.type === SMACheckBoxEnum.CheckADV) return { ...prev, advsChecked: action.value };
  if (action?.type === SMACheckBoxEnum.CheckInvestmentObj) return { ...prev, investmentObjChecked: action.value };
  return prev;
};

export const allocationOrderCheckboxesAtom = atom<AllocationOrderCheckboxes>({
  investmentObjChecked: false,
  advsChecked: false,
});
