import { CountryModel } from '@halo-common/models';
import { ApiCountryModel } from '@halo-data-sources/models/General/Country';

interface IApiCountryMapper {
  toCountry: (country: ApiCountryModel) => CountryModel;
}

export const ApiCountryMapper: IApiCountryMapper = {
  toCountry: (country) => ({
    id: country.id,
    isoCode: country.isocode,
    name: country.name,
    label: `${country.isocode} - ${country.name}`,
  }),
};
