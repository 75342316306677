import { ReactElement } from 'react';

import { useUserInfoQuery } from '@halo-data-sources/queries';
import { HaloLogo, publicPath, Stack } from '@halodomination/halo-fe-common';
import { Box } from '@mui/material';

const logoSx = {
  height: 58,
  mb: { md: 18, xs: 3 },
  width: 195,
  ['@media (max-height:1000px)']: {
    mb: { md: 9, xs: 3 },
  },
};

const poweredByHaloStyling = { width: 104 };

export const LogoRow = (): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();

  const brand = userInfo?.whiteLabel?.brand.name;
  const logo = userInfo?.settings?.onboarding?.logoLight?.path;
  const showPoweredByHalo = userInfo?.settings.showPoweredByHalo;

  const poweredByHaloPath = publicPath('/assets/logos/PoweredByHaloLight.svg');

  const isHalo = brand === 'halo';
  const brandLogo = isHalo ? (
    <HaloLogo slotProps={{ root: { sx: { logoSx } } }} />
  ) : (
    <Box component="img" src={logo} sx={logoSx} />
  );

  const poweredByHalo = showPoweredByHalo ? (
    <Box component="img" src={poweredByHaloPath} sx={poweredByHaloStyling} />
  ) : null;

  return (
    <Stack direction="row" justify="space-between">
      {brandLogo}
      {poweredByHalo}
    </Stack>
  );
};
