import { translations } from '@halo-common/translations';

export const useErrorMessageTranslation = (error: Error | null): string | undefined => {
  const message = error?.message;
  const messageKey = message || '';

  const translatedMessage = {
    old_password_incorrect: translations.profile.password.oldPasswordError,
  }[messageKey];

  return translatedMessage ?? message;
};
