import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiCreateNoteWatchlistResponseModel,
  ApiDeleteWatchlistByIdResponseModel,
  ApiGetProductsWatchlistResponseModel,
  ApiGetProductWatchlistResponseModel,
  ApiGetTargetedNotesResponseModel,
  ApiRemoveTargetQuoteResponseModel,
  ApiTargetQuoteSet,
  ApiUpdateWatchlistProductsRequestModel,
  ApiUpdateWatchlistProductsResponseModel,
  ApiUpdateWatchlistRequestModel,
  ApiUpdateWatchlistResponseModel,
  ApiWatchlistProductsResponseModel,
} from '@halo-data-sources/models';

const WATCH_LIST_PRE_TRADE_PATH = '/pretrade/watchlist';

export const getUserWatchlists = async (): Promise<ApiGetProductWatchlistResponseModel> => {
  const clientConfiguration = await getClientConfiguration(WATCH_LIST_PRE_TRADE_PATH);

  const url = `${clientConfiguration.basePath}/list`;

  const response = await request<ApiGetProductWatchlistResponseModel>(url, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const getWatchlistNotes = async (
  id: number,
  page = 1,
  size = 20,
): Promise<ApiWatchlistProductsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(WATCH_LIST_PRE_TRADE_PATH);

  const response = await request<ApiWatchlistProductsResponseModel>(`${clientConfiguration.basePath}/${id}/products`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      page: page,
      page_length: size,
    }),
  });

  return response;
};

export const createWatchlist = async (name: string, icon: string): Promise<ApiCreateNoteWatchlistResponseModel> => {
  const clientConfiguration = await getClientConfiguration(WATCH_LIST_PRE_TRADE_PATH);

  const response = await request<ApiCreateNoteWatchlistResponseModel>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      name,
      icon,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const updateWatchlist = async (
  id: number,
  { name, icon }: ApiUpdateWatchlistRequestModel,
): Promise<ApiUpdateWatchlistResponseModel> => {
  const clientConfiguration = await getClientConfiguration(WATCH_LIST_PRE_TRADE_PATH);

  const response = await request<ApiUpdateWatchlistResponseModel>(`${clientConfiguration.basePath}/${id}/edit`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({ name, icon, _csrf_token: clientConfiguration.csrfToken }),
  });

  return response;
};

export const deleteWatchlist = async (id: number): Promise<ApiDeleteWatchlistByIdResponseModel> => {
  const clientConfiguration = await getClientConfiguration(WATCH_LIST_PRE_TRADE_PATH);

  const response = await request<ApiDeleteWatchlistByIdResponseModel>(`${clientConfiguration.basePath}/${id}/delete`, {
    ...clientConfiguration.requestInit,
    method: 'DELETE',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const getWatchlistTargetedNotes = async (): Promise<ApiGetTargetedNotesResponseModel> => {
  const clientConfiguration = await getClientConfiguration(WATCH_LIST_PRE_TRADE_PATH);

  const response = await request<ApiGetTargetedNotesResponseModel>(`${clientConfiguration.basePath}/targeted`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
    failOnError: false,
  });

  return response;
};

export const setTargetQuote = async (noteId: number, level: number): Promise<ApiTargetQuoteSet> => {
  const clientConfiguration = await getClientConfiguration(WATCH_LIST_PRE_TRADE_PATH);

  const response = await request<ApiTargetQuoteSet>(`${clientConfiguration.basePath}/targeted/set`, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      noteid: noteId.toString(),
      level: level.toString(),
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const removeTargetQuote = async (targetId: number): Promise<ApiRemoveTargetQuoteResponseModel> => {
  const clientConfiguration = await getClientConfiguration(WATCH_LIST_PRE_TRADE_PATH);

  const response = await request<ApiRemoveTargetQuoteResponseModel>(`${clientConfiguration.basePath}/targeted/remove`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      targetid: targetId.toString(),
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const getProductWatchlistDetails = async (
  id: number,
  type: string,
): Promise<ApiGetProductsWatchlistResponseModel> => {
  const clientConfiguration = await getClientConfiguration(WATCH_LIST_PRE_TRADE_PATH);

  return await request<ApiGetProductsWatchlistResponseModel>(`${clientConfiguration.basePath}/products`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      products: [{ id, type }],
    }),
  });
};

export const updateWatchlistProducts = async (
  body: ApiUpdateWatchlistProductsRequestModel,
): Promise<ApiUpdateWatchlistProductsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(WATCH_LIST_PRE_TRADE_PATH);

  return await request<ApiUpdateWatchlistProductsResponseModel>(`${clientConfiguration.basePath}/bulk-product-edit`, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      ...body,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};
