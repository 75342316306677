import { ReactElement } from 'react';

import { useNoteSpecificDetails } from '@halo-common/hooks';
import { NoteModel } from '@halo-common/models';
import { LocalizedTypography } from '@halodomination/halo-fe-common';

export const ProductApprovalTableProtectionCell = ({ note }: { note: NoteModel }): ReactElement => {
  const { protectionDetails } = useNoteSpecificDetails(note);

  const text = protectionDetails?.[0]?.text ? protectionDetails[0].text : '-';
  const dynamicContent = protectionDetails?.[0]?.dynamicContent;

  return (
    <LocalizedTypography
      display="flex"
      alignItems="center"
      height="100%"
      variant="body2"
      dynamicContent={dynamicContent}
    >
      {text}
    </LocalizedTypography>
  );
};
