import { SetPasswordFormData } from '@halo-common/forms';
import { getQueryParams } from '@halo-common/utils';
import { setPasswordV2 } from '@halo-data-sources/clients';
import { LinkExpiredPageMeta } from '@halo-pages/app/v2/onboarding/link-expired';
import { LoginPageMeta } from '@halo-pages/app/v2/onboarding/login';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

const useSetNewPasswordMutationFn = async (payload: SetPasswordFormData) => {
  await setPasswordV2(payload);
};

export const useSetNewPasswordMutation = (): UseMutationResult<void, Error, SetPasswordFormData> =>
  useMutation<void, Error, SetPasswordFormData>({
    mutationFn: useSetNewPasswordMutationFn,
    onSuccess: () => {
      const queryParams = getQueryParams();
      const params = new URLSearchParams({ ...queryParams, passwordSuccess: 'true' });
      window.location.assign(`/react${LoginPageMeta.route}?${params.toString()}`);
    },
    onError: () => {
      const queryParams = getQueryParams();
      const params = new URLSearchParams({ ...queryParams });
      window.location.assign(`/react${LinkExpiredPageMeta.route}?${params.toString()}`);
    },
  });
