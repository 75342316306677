import { getDeviceType, getEngine, getOSName } from '@halo-common/utils';
import { request, getClientConfiguration } from '@halo-data-sources/clients';
import { ActivityTrackingRequestModel } from '@halo-data-sources/models/Tracking/TrackingModels';

const COMMON_ACTIVITY_TRACKING_PATH = '/common/activity';

export const recordActivity = async (activity: ActivityTrackingRequestModel[], fromIFrame: boolean): Promise<void> => {
  const clientConfiguration = await getClientConfiguration(COMMON_ACTIVITY_TRACKING_PATH);

  const uaInfo = {
    client: {
      screen_width: fromIFrame ? window.parent.screen.width : screen.width,
      screen_height: fromIFrame ? window.parent.screen.height : screen.height,
      viewport_width: fromIFrame ? window.parent.innerWidth : window.innerWidth,
      viewport_height: fromIFrame ? window.parent.innerHeight : window.innerHeight,
      lang: navigator.language,
      tz_offset: new Date().getTimezoneOffset(),
    },
    browser: {
      name: navigator.userAgent,
    },
    cpu: {
      cores: navigator.hardwareConcurrency || 'Unknown',
    },
    device: {
      type: getDeviceType(),
    },
    engine: {
      name: getEngine(),
    },
    os: {
      name: getOSName(),
    },
    ua: navigator.userAgent,
  };

  const payload = {
    _csrf_token: clientConfiguration.csrfToken,
    method: 'AJAX',
    endpoint: 'api_do',
    full_path: fromIFrame ? window.parent.location.pathname : window.location.pathname,
    activity: activity,
    ua_info: uaInfo,
  };

  await request(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify(payload),
  });
};
