import { ReactElement } from 'react';

import { NotesCarousel, renderNoteCardSkeletons } from '@halo-modules/app';

export interface NoteflixSkeletonProps {
  items?: number;
  ribbon?: boolean;
  width?: number;
}

export const NoteflixSkeleton = ({ ribbon = true, ...props }: NoteflixSkeletonProps): ReactElement => (
  <NotesCarousel>{renderNoteCardSkeletons({ ribbon, ...props })}</NotesCarousel>
);
