import { ReactElement } from 'react';

import { navigateParentTo } from '@halo-common/utils';
import { OnboardingActions, OnboardingSelectors, OnboardingStatusEnum } from '@halo-stores/Onboarding';
import { Iconography, Stack } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { object as yupObject, string as yupString } from 'yup';

const linkSx = {
  alignItems: 'center',
  display: 'flex',
  marginTop: 5,
  width: 'fit-content',
};

export type LinkExpiredSchema = {
  email: string;
};

const schema = yupObject().shape({
  email: yupString().email('Please enter a valid email address.').required('Please enter a valid email address.'),
});

const linkExpiredResolver = yupResolver(schema);

export const LinkExpiredForm = (): ReactElement => {
  const dispatch = useDispatch();

  const status = useSelector(OnboardingSelectors.selectStatus);

  const navigationHandler = () => navigateParentTo('/logon');

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<LinkExpiredSchema>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    resolver: linkExpiredResolver,
    defaultValues: {
      email: '',
    },
  });

  const { ref: emailRef, ...emailProps } = register('email');

  const onSubmit = (data: LinkExpiredSchema) => {
    dispatch(OnboardingActions.resendSetPassword(data));
  };

  const successMessage =
    status === OnboardingStatusEnum.successResendSetPassword ? (
      <Alert severity="success" variant="filled">
        <Typography variant="body2" component="span">
          If you have an account, we&apos;ll email you a reset link.
        </Typography>
      </Alert>
    ) : null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={4}>
        <Stack direction="column" spacing={3}>
          <Typography align="center" component="h4" variant="h4">
            Enter email for a new activation link
          </Typography>
          {successMessage}
          <TextField
            {...emailProps}
            fullWidth
            size="large"
            label="Email Address"
            inputRef={emailRef}
            error={Boolean(errors?.email)}
            helperText={errors?.email?.message}
          />
        </Stack>
        <Stack direction="column" spacing={3}>
          <Button
            startIcon={isSubmitting && <CircularProgress size="1rem" />}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
          >
            Resend Link
          </Button>
          <Button
            color="primary"
            sx={linkSx}
            onClick={navigationHandler}
            startIcon={<Iconography iconName="arrow-left" size="1.25x" color="primary.main" />}
          >
            Back To Login
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
