import { ReactElement } from 'react';

import { V2UnderlyingGlyph } from '@halo-common/components';
import { UnderlyingBasketEnum } from '@halo-common/enums';
import { V2NoteModel } from '@halo-common/models';
import { Stack, StackProps, useCombinedStyling } from '@halodomination/halo-fe-common';
import { Skeleton, SxProps } from '@mui/material';

export type V2UnderlyingGlyphsProps = {
  size?: 'small' | 'large';
  scrollable?: boolean;
  sx?: SxProps;
  underlyings?: V2NoteModel['underlyings'];
  basketType?: V2NoteModel['underlyingBasketType'];
  StackProps?: Partial<StackProps>;
  loading?: boolean;
};

export const V2UnderlyingGlyphs = ({
  basketType,
  loading = false,
  scrollable = false,
  size = 'small',
  StackProps,
  sx,
  underlyings = [],
}: V2UnderlyingGlyphsProps): ReactElement => {
  const spacing = scrollable ? 0.5 : 1;

  const underlyingRowSx = {
    alignItems: 'center',
    flexWrap: !scrollable ? 'wrap' : 'unset',
    justifyContent: scrollable ? 'flex-start' : 'center',
    overflowX: scrollable ? 'scroll' : 'unset',
    py: 1,
  };

  const glyphSx = !scrollable ? { my: 1 } : undefined;

  const underlyingStackSx = useCombinedStyling(underlyingRowSx, sx);

  if (loading) {
    const loadingGlyphs = Array.from(Array(3), (_, id) => ({ ticker: id.toString() })).map(({ ticker }) => (
      <Skeleton key={ticker} width={50} height={50} variant="rounded" />
    ));

    return (
      <Stack direction="row" sx={underlyingStackSx} spacing={0.5} {...StackProps}>
        {loadingGlyphs}
      </Stack>
    );
  }

  const glyphs = [...underlyings]
    .sort((a, b) => {
      const { weight: weightA = 0, ticker: tickerA } = a;
      const { weight: weightB = 0, ticker: tickerB } = b;
      if (basketType === UnderlyingBasketEnum.WORST_OF) return tickerA < tickerB ? -1 : tickerA > tickerB ? 1 : 0;
      else return weightA < weightB ? 1 : weightA > weightB ? -1 : 0;
    })
    .map(({ weight, ticker }) => (
      <V2UnderlyingGlyph key={ticker} weight={weight} ticker={ticker} size={size} sx={glyphSx} />
    ));

  return (
    <Stack direction="row" sx={underlyingStackSx} spacing={spacing} {...StackProps}>
      {glyphs}
    </Stack>
  );
};
