import { useState } from 'react';

import { PageFooterLayout } from '@halo-common/layouts';
import { PreTradeNoteDetailModal } from '@halo-common/modals';
import { NoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { useNoteQuery, useRecentQuotesQuery, useRecentlyCreatedNoteQuery } from '@halo-data-sources/queries';
import { RollingReturnsProductList, RollingReturnsSearchField } from '@halo-modules/app';
import { Iconography, LocalizedButton, PageComponent, PageMeta } from '@halodomination/halo-fe-common';
import { Container, Stack } from '@mui/material';

const backButtonSx = { mt: 2 };

const RollingReturnsPage: PageComponent = () => {
  const queryProductId = new URL(parent.location.href)?.searchParams?.get('productId') ?? null;

  const [selectedProduct, setSelectedProduct] = useState<NoteModel | null>(null);
  const [selectedProductId, setSelectedProductId] = useState<string | null>(queryProductId);

  const { data: recentlyCreatedQuery } = useRecentlyCreatedNoteQuery();
  const { data: note, isPending: isLoadingNote, isError } = useNoteQuery(selectedProductId, { retry: false });

  const products = recentlyCreatedQuery?.products ?? [];
  const productIds = products.map(({ id }) => id);
  const product = selectedProduct ?? note;

  const open = Boolean(product);

  const { isPending: isLoadingQuotes } = useRecentQuotesQuery(productIds);

  const isLoading = isLoadingQuotes || isLoadingNote;

  const handleBack = () => {
    navigateParentTo('/tiles/notes/analytics');
  };

  const handleClose = () => {
    setSelectedProduct(null);
    setSelectedProductId(null);
  };

  const handleProductIdUpdate = (id: string) => {
    setSelectedProductId(id);
  };

  const handleProductUpdate = (product: NoteModel) => {
    setSelectedProduct(product);
  };

  return (
    <PageFooterLayout>
      <Container maxWidth="xl">
        <Stack direction="column" spacing={3}>
          <LocalizedButton
            sx={backButtonSx}
            color="primary"
            size="large"
            startIcon={<Iconography iconName="arrow-left" color="primary.main" />}
            variant="text"
            onClick={handleBack}
          >
            {translations.analytics.common.backButtonText}
          </LocalizedButton>
          <Stack direction="column" spacing={5}>
            <RollingReturnsSearchField
              onSelect={handleProductIdUpdate}
              productId={selectedProduct?.id}
              loading={isLoading}
              error={isError}
            />
            <RollingReturnsProductList onSelect={handleProductUpdate} />
          </Stack>
        </Stack>
      </Container>
      <PreTradeNoteDetailModal open={open} product={product} loading={isLoading} onClose={handleClose} />
    </PageFooterLayout>
  );
};

export const RollingReturnsPageMeta: PageMeta = {
  pageName: 'Rolling Returns',
  route: '/app/rolling-returns',
  routeExpression: '/analytics/rolling-returns',
};

RollingReturnsPage.pageName = RollingReturnsPageMeta.pageName;
RollingReturnsPage.route = RollingReturnsPageMeta.route;

export default RollingReturnsPage;
