import { OrderBookAuctionModel, OrderBookAuctionQueryFields, PaginationModel } from '@halo-common/models';
import { getOrderBookAuctions } from '@halo-data-sources/clients';
import { ApiOrderBookMapper, OrderBookMapper } from '@halo-data-sources/mappers';
import { OrderBookAuctionQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryOptions, UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

export type OrderBookAuctionsQueryResult = {
  auctions?: Array<OrderBookAuctionModel>;
  pagination?: PaginationModel;
};

export type UseOrderBookAuctionsQueryResult = UseQueryResult<OrderBookAuctionsQueryResult, Error>;
export type UseOrderBookAuctionsQueryOptions = UseQueryOptions<OrderBookAuctionsQueryResult, Error>;

const getOrderBookAuctionsFn = async (searchParams: OrderBookAuctionQueryFields) => {
  const mappedParams = OrderBookMapper.toApiAuctionSearchParams(searchParams);
  const response = await getOrderBookAuctions(mappedParams);

  return ApiOrderBookMapper.toOrderBookAuctionsQueryResult(response);
};

export const useOrderBookAuctionsQuery = (
  searchParams: OrderBookAuctionQueryFields,
  interval?: number,
  options?: UseOrderBookAuctionsQueryOptions,
): UseOrderBookAuctionsQueryResult =>
  useQuery<OrderBookAuctionsQueryResult, Error>({
    queryKey: OrderBookAuctionQueryKeyFactory.auctions(searchParams),
    queryFn: () => getOrderBookAuctionsFn(searchParams),
    refetchInterval: interval,

    placeholderData: keepPreviousData,
    ...options,
  });
