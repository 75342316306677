import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiDocumentModel,
  ApiGetOrderBookAuctionsResponseModel,
  ApiGetOrderBookCSVRequestModel,
  ApiGetOrderBookCalendarsResponseModel,
  ApiGetOrderBookClientAllocationsResponseModel,
  ApiGetOrderBookIssuersResponseModel,
  ApiGetOrderBookNoteTypeResponseModel,
  ApiGetOrderBookOptionsModel,
  ApiGetOrderBookPershingOrdersCSVDownloadOptionsModel,
} from '@halo-data-sources/models';

const EXECUTION_ORDERBOOK_PATH = `/execution/orderbook`;
const EXECUTION_CALENDAR_ORDERBOOK_PATH = `${EXECUTION_ORDERBOOK_PATH}/calendar`;
const EXECUTION_CALENDAR_ALLOCATIONS_ORDERBOOK_PATH = `${EXECUTION_ORDERBOOK_PATH}/calendar_allocations`;
const EXECUTION_AUCTION_ORDERBOOK_PATH = `${EXECUTION_ORDERBOOK_PATH}/auction`;

export const getOrderBookAuctions = async (
  options: ApiGetOrderBookOptionsModel,
): Promise<ApiGetOrderBookAuctionsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_ORDERBOOK_PATH);

  const path = clientConfiguration.basePath;

  return await request<ApiGetOrderBookAuctionsResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...options,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};

export const getOrderBookAuctionNoteTypes = async (): Promise<ApiGetOrderBookNoteTypeResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_ORDERBOOK_PATH);

  const path = `${clientConfiguration.basePath}/user_note_product`;

  return await request<ApiGetOrderBookNoteTypeResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'Get',
  });
};

export const getOrderBookAuctionIssuers = async (): Promise<ApiGetOrderBookIssuersResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_ORDERBOOK_PATH);

  const path = `${clientConfiguration.basePath}/user_auction_issuers`;

  return await request<ApiGetOrderBookIssuersResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'Get',
  });
};

export const getOrderBookAuctionCSVDownload = async (
  options: ApiGetOrderBookCSVRequestModel,
): Promise<ApiDocumentModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_ORDERBOOK_PATH);

  const path = `${clientConfiguration.basePath}/download/csv`;

  return await request<ApiDocumentModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...options,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};

export const getOrderBookCalendarsNoteTypes = async (): Promise<ApiGetOrderBookNoteTypeResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_CALENDAR_ORDERBOOK_PATH);

  const path = `${clientConfiguration.basePath}/user_note_product`;

  return await request<ApiGetOrderBookNoteTypeResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'Get',
  });
};

export const getOrderBookCalendars = async (
  options: ApiGetOrderBookOptionsModel,
): Promise<ApiGetOrderBookCalendarsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_CALENDAR_ORDERBOOK_PATH);

  const path = clientConfiguration.basePath;

  return await request<ApiGetOrderBookCalendarsResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...options,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};

export const getOrderBookCalendarsCSVDownload = async (
  options: ApiGetOrderBookCSVRequestModel,
): Promise<ApiDocumentModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_CALENDAR_ORDERBOOK_PATH);

  const path = `${clientConfiguration.basePath}/download/csv`;

  return await request<ApiDocumentModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...options,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};

export const getOrderBookClientOrders = async (
  options: ApiGetOrderBookOptionsModel,
): Promise<ApiGetOrderBookClientAllocationsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_CALENDAR_ALLOCATIONS_ORDERBOOK_PATH);

  const path = clientConfiguration.basePath;

  return await request<ApiGetOrderBookClientAllocationsResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...options,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};

export const getOrderBookPershingOrdersCSVDownload = async (
  options: ApiGetOrderBookPershingOrdersCSVDownloadOptionsModel,
): Promise<ApiDocumentModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_CALENDAR_ALLOCATIONS_ORDERBOOK_PATH);

  const path = `${clientConfiguration.basePath}/download/csv`;

  return await request<ApiDocumentModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...options,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};
