import {
  AssetIdentifierEnum,
  LifecycleProductTypeEnum,
  PortfolioPositionsTableSearchNameEnum,
} from '@halo-common/enums';
import {
  AssetPositionModel,
  FetchPortfolioExcelSheetModel,
  FetchPositionsAggregationResultModel,
  InfinitePaginationModel,
  PortfolioPositionModel,
  PortfolioPositionReferenceModel,
  PortfolioPositionsNoteFeaturesModel,
  PortfolioPositionsTemplate,
  PositionAggregationModel,
  PositionTermsheetV2ExistenceModel,
  PositionsBySearchProductIdentifierModel,
  PositionsDetailsColumnsModel,
  PositionsDetailsPositionModel,
  PositionsDetailsSectionModel,
} from '@halo-common/models';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { ApiAssetIdentifierEnum, PortfolioColumnTypeEnum } from '@halo-data-sources/enums';
import {
  ApiAssetPositionModel,
  ApiGetPortfolioPositionsAggregationResponse,
  ApiGetPortfolioPositionsAggregationTemplatesModel,
  ApiGetPositionsDetailResponse,
  ApiGetPositionsDetailsColumnsModel,
  ApiGetPositionsDetailsNoteFeaturesModel,
  ApiGetPositionsDetailsPositionModel,
  ApiGetPositionsDetailsSectionModel,
  ApiPortfolioPositionModel,
  ApiPortfolioPositionSummaryPayload,
  ApiPortfolioPositionsAggregation,
  ApiPortfolioPositionsEventsPayload,
  ApiPositionAllocationModel,
  ApiPostPortfolioExcelPayload,
  ApiPostPositionsBySearchProductIdentifierModel,
  ApiPostTradeEvent,
  ApiPostTradeEventsResponse,
  ApiQueuedPositionAllocationModel,
  ApiTermsheetV2ExistenceModel,
} from '@halo-data-sources/models';
import {
  PortfolioPositionSummaryQueryModel,
  PostTradeV2Event,
  PostTradeV2EventsQueryResult,
} from '@halo-data-sources/queries';
import { CallEventCalledStatus, CompositionEnum, CouponEventPaidStatus } from '@halo-modules/app';
import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';

export const PositionsMapper = {
  toFetchPortfolioExcelRequest: (model: FetchPortfolioExcelSheetModel): ApiPostPortfolioExcelPayload => {
    return {
      account_selector: {
        account_ids: model.accountId ? [model.accountId] : undefined,
        advisee_ids: model.householdId ? [model.householdId] : undefined,
        synced: model.synced ?? null,
      },
      filename: model.fileName,
      include_inactive: model.includeInactive,
    };
  },
  toFetchPositionSummaryRequest: (
    model: PortfolioPositionSummaryQueryModel,
    pagination: InfinitePaginationModel,
  ): ApiPortfolioPositionSummaryPayload => {
    return {
      account_selector: {
        account_ids: model.accountIds,
        advisee_ids: model.householdIds,
        synced: model.synced ?? null,
      },
      // filters: model.filters,
      include_inactive: model.includeInactive ?? false,
      page: pagination.page,
      page_length: pagination.resultsPerPage,
      sort: model.sort,
    };
  },
  toFetchPositionEventsRequest: (
    id: number,
    accountDetails?: AccountTypeAheadOption | null,
  ): ApiPortfolioPositionsEventsPayload => {
    return {
      account_selector: {
        account_ids: accountDetails?.account ? [accountDetails?.account.id] : undefined,
        advisee_ids: accountDetails?.household ? [accountDetails?.household.id] : undefined,
        synced: false,
      },
      termsheet_id: id,
    };
  },
};

export const ApiPositionsMapper = {
  toPosition: (position: ApiPortfolioPositionModel): PortfolioPositionModel => {
    return {
      accountId: position.account_id,
      costBasis: position.cost_basis,
      currencyId: position.currency_id,
      cusip: position.cusip,
      fundserv: position.fundservcode,
      isActive: position.is_active,
      isin: position.isin,
      issuerCreditRating: position.issuer_credit_rating,
      issuerName: position.issuer_name,
      marketChange: position.market_change,
      marketValue: position.market_value,
      maturityDate: DateTime.fromISO(position.maturity_date),
      noteId: position.note_id,
      noteReturn: position.note_return,
      notional: position.notional,
      ownerId: position.owner_id,
      pctBreach: position.percent_to_breach,
      shortName: position.short_name,
      templateDisplayName: position.template_display_name,
      templateFullname: position.template_fullname,
      termsheetId: position.term_sheet_id,
      uid: uuid(),
      underlyingReturn: position.underlying_return,
      valuation: position.valuation,
    };
  },
  toPositionTermsheetV2Existence: (position: ApiTermsheetV2ExistenceModel): PositionTermsheetV2ExistenceModel => {
    return {
      definitiveProductId: position.definitive_product_id,
      status: position.status,
      termsheetId: position.termsheet_id,
    };
  },
  toPositionAggregation: (model: ApiPortfolioPositionsAggregation): PositionAggregationModel => {
    return {
      name: model.name,
      positionCount: model.num_products,
      totalNotional: model.notional,
      percentage: model.notional_percent,
      filterKey: model.filter_value,
      uid: uuid(),
      marketValue: model.market_value,
      marketChange: model.market_change,
      notionalPercent: model.notional_percent,
      nextEventDate: model.next_event_date,
    };
  },
  toFilterTemplates: (templates: ApiGetPortfolioPositionsAggregationTemplatesModel): PortfolioPositionsTemplate => {
    return {
      name: templates.internal_name as CompositionEnum,
      displayName: templates.display_name,
      filters: templates.traversals.map((traversal) => ({
        fieldNames: traversal.filters,
        grouping: traversal.group_by,
      })),
    };
  },
  toPositionsColumnDictionaryMapper: (
    columns: Array<ApiGetPositionsDetailsColumnsModel>,
    primaryAssetIdentifier: AssetIdentifierEnum,
  ): Array<PositionsDetailsColumnsModel> => {
    const mappedColumns = columns.map((column) => ({
      displayName: column.display_name,
      ergName: column.erg_name,
      type: column.type,
    }));

    const shortNameColumn = {
      displayName: PortfolioPositionsTableSearchNameEnum.shortName,
      ergName: 'product_name',
      type: PortfolioColumnTypeEnum.RAW,
    };

    const primaryAssetIdentifierColumn = {
      displayName: primaryAssetIdentifier,
      ergName: primaryAssetIdentifier,
      type: PortfolioColumnTypeEnum.RAW,
    };

    return [...mappedColumns, shortNameColumn, primaryAssetIdentifierColumn];
  },
  toPositionsMapperV2: (
    position: ApiGetPositionsDetailsPositionModel,
    positionIndex: number,
    primaryAssetIdentifier: AssetIdentifierEnum,
    uuidPositions: Array<string>,
    section: ApiGetPositionsDetailsSectionModel,
  ): PositionsDetailsPositionModel => {
    const primaryAssetIdentifierObject = {
      [primaryAssetIdentifier]: {
        value:
          position.asset_identifiers.find((asset) => asset.type === ApiAssetIdentifierEnum[primaryAssetIdentifier])
            ?.value ?? 'N/A',
        type: PortfolioColumnTypeEnum.RAW,
        ergName: ApiAssetIdentifierEnum[primaryAssetIdentifier],
      },
    };

    const initialColumnValue = {
      ...primaryAssetIdentifierObject,
      [PortfolioPositionsTableSearchNameEnum.shortName]: {
        value: position.product_name.replace(/\s+/g, ' ').trim(),
        type: PortfolioColumnTypeEnum.RAW,
        ergName: 'product_name',
      },
    };

    return {
      id: position.product_id,
      uid: uuidPositions[positionIndex],
      productType: position.product_type,
      isActive: position.is_active,
      columnDetails: section.columns.reduce(
        (map, details, columnIndex) => {
          const isCurrencyValue = details.type === PortfolioColumnTypeEnum.CURRENCY;
          const value =
            isCurrencyValue && section.cells[positionIndex][columnIndex]
              ? section.cells[positionIndex][columnIndex].amount
              : section.cells[positionIndex][columnIndex];

          const currencyId =
            isCurrencyValue && section.cells[positionIndex][columnIndex]
              ? section.cells[positionIndex][columnIndex].currency_id
              : undefined;

          return {
            ...map,
            [details.display_name]: {
              value,
              currencyId,
              type: details.type,
              ergName: details.erg_name,
            },
          };
        },
        { ...initialColumnValue },
      ),
    };
  },
  toPositionsSections: ({
    positions,
    sections,
    primaryAssetIdentifier = AssetIdentifierEnum.CUSIP,
  }: Omit<ApiGetPositionsDetailResponse, 'pagination'>): Array<PositionsDetailsSectionModel> => {
    const uuidPositions = positions.map(() => uuid());
    const mappedSections = sections.map((section) => ({
      name: section.name,
      columns: [
        PortfolioPositionsTableSearchNameEnum.shortName,
        primaryAssetIdentifier,
        ...new Set(section.columns.map((column) => column.display_name)),
      ],
      columnDictionary: ApiPositionsMapper.toPositionsColumnDictionaryMapper(section.columns, primaryAssetIdentifier),
      positions: positions.map((position, positionIndex) => {
        return ApiPositionsMapper.toPositionsMapperV2(
          position,
          positionIndex,
          primaryAssetIdentifier,
          uuidPositions,
          section,
        );
      }),
    }));
    const allColumns = [...new Set(mappedSections.flatMap((section) => section.columns))];
    const allPositions = mappedSections.flatMap((section) => section.positions);
    const allColumnsDictionary = [...new Set(mappedSections.flatMap((section) => section.columnDictionary))];

    const combinedAllPositions = allPositions.reduce(
      (columnMap: { [uid: string]: PositionsDetailsPositionModel }, position) => {
        if (!columnMap[position.uid]) {
          columnMap[position.uid] = position;
        } else {
          columnMap[position.uid].columnDetails = {
            ...columnMap[position.uid].columnDetails,
            ...position.columnDetails,
          };
        }

        return columnMap;
      },
      {},
    );

    const allInfoSection = {
      name: translations.portfolio.positions.allInfo,
      columns: allColumns,
      positions: Object.values(combinedAllPositions),
      columnDictionary: allColumnsDictionary,
    };

    return [allInfoSection, ...mappedSections];
  },
  toAggregation: (aggregate: ApiGetPortfolioPositionsAggregationResponse): FetchPositionsAggregationResultModel => {
    return {
      tableDetails: {
        totalPositions: aggregate.num_products,
        totalNotional: aggregate.notional,
        nextEventDate: aggregate.next_event_date,
        marketValue: aggregate.market_value,
        marketChange: aggregate.market_change,
      },
      aggregations: aggregate.children
        .map(ApiPositionsMapper.toPositionAggregation)
        .sort((a, b) => (a.percentage < b.percentage ? 1 : a.percentage > b.percentage ? -1 : 0)),
    };
  },
  toPositionsNoteFeature: (
    note_features: ApiGetPositionsDetailsNoteFeaturesModel,
  ): PortfolioPositionsNoteFeaturesModel => ({
    count: note_features.count,
    name: note_features.display_name,
    value: note_features.internal_name,
    notionalPercent: note_features.notional_percent,
    notional: note_features.notional,
  }),
  toPositionsIdentifier: (
    identifier: ApiPostPositionsBySearchProductIdentifierModel,
  ): PositionsBySearchProductIdentifierModel => ({
    type: AssetIdentifierEnum[identifier.type],
    value: identifier.value,
  }),
  toAssetPosition: (product: ApiAssetPositionModel): AssetPositionModel => ({
    assetIds: product.asset_identifiers.map(ApiPositionsMapper.toPositionsIdentifier),
    templateName: product.template_fullname,
    id: product.term_sheet_id,
    productType: LifecycleProductTypeEnum.NOTE,
    isActive: product.is_active,
    displayName: product.template_display_name,
    shortName: product.short_name,
  }),
  toPositionsCouponEventPaidStatus: (paid: boolean): CouponEventPaidStatus => {
    if (paid === null) return CouponEventPaidStatus.PENDING;
    if (paid === true) return CouponEventPaidStatus.PAID;
    return CouponEventPaidStatus.MISSED;
  },
  toPositionsCallEventCallStatus: (called: boolean): CallEventCalledStatus => {
    if (called === null) return CallEventCalledStatus.PENDING;
    if (called === true) return CallEventCalledStatus.CALLED;
    return CallEventCalledStatus.NOT_CALLED;
  },
  toPositionCouponEvents: (event: ApiPostTradeEvent): PostTradeV2Event => ({
    barrierPct: event.barrier_pct,
    cashFlowAmt: event.cash_flow_amount,
    eventDate: event.event_date,
    paid: ApiPositionsMapper.toPositionsCouponEventPaidStatus(event.paid),
    id: uuid(),
  }),
  toPositionCallEvents: (event: ApiPostTradeEvent): PostTradeV2Event => ({
    barrierPct: event.barrier_pct,
    cashFlowAmt: event.cash_flow_amount,
    eventDate: event.event_date,
    called: ApiPositionsMapper.toPositionsCallEventCallStatus(event.paid),
    id: uuid(),
  }),
  toPositionCouponAndRedemeptionEvents: (event: ApiPostTradeEventsResponse): PostTradeV2EventsQueryResult => ({
    callEvents: event.callability_calendar.map(ApiPositionsMapper.toPositionCallEvents),
    coupon: event.coupon_calendar.map(ApiPositionsMapper.toPositionCouponEvents),
  }),
};

export const PositionAllocationMapper = {
  toApiModel: (allocation: PortfolioPositionReferenceModel): ApiPositionAllocationModel | null => {
    if (typeof allocation.accountId !== 'string' || typeof allocation.notional !== 'number') {
      return null;
    }

    return {
      account_id: parseInt(allocation.accountId),
      termsheet_id: allocation.termsheetId,
      notional: allocation.notional,
    };
  },
  toQueuedApiModel: (allocation: PortfolioPositionReferenceModel): ApiQueuedPositionAllocationModel | null => {
    if (
      typeof allocation.accountId !== 'string' ||
      typeof allocation.assetId !== 'string' ||
      typeof allocation.notional !== 'number'
    ) {
      return null;
    }

    return {
      account_id: parseInt(allocation.accountId),
      cusip: allocation.assetId,
      notional: allocation.notional,
    };
  },
};
