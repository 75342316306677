import { QuoteModel } from '@halo-common/models';
import { atom } from 'jotai';

export type QuoteValueMap = {
  [noteId: number]: {
    updated: boolean;
    recent: QuoteModel | null;
    past: Array<QuoteModel>;
  };
};

export const quoteValueMapAtom = atom<QuoteValueMap>({});
