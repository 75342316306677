import { translations } from '@halo-common/translations';
import { array as yupArray, number as yupNumber, object as yupObject } from 'yup';

const auctionDetailsAccountOptionSchema = yupObject()
  .shape({ account: yupObject().shape({ id: yupNumber() }) })
  .required(translations.orderBook.auctions.accountRequiredError)
  .nullable();

const auctionDetailsQuantitySchema = yupNumber()
  .transform((value: number) => (!isNaN(value) ? value : undefined))
  .required(translations.orderBook.auctions.quantityRequiredError)
  .min(1000, translations.orderBook.auctions.quantityRequiredError);

export const auctionDetailsAllocationSchema = yupObject({
  allocations: yupArray()
    .of(
      yupObject()
        .shape({
          accountOption: auctionDetailsAccountOptionSchema,
          quantity: auctionDetailsQuantitySchema,
        })
        .test({
          name: 'are-allocation-accounts-unique',
          test: (currentAllocation, ctx) => {
            const { parent, createError, path } = ctx;
            const currentAllocationId = currentAllocation.accountOption?.account?.id;
            const allocations = parent as AuctionDetailsAllocationFields['allocations'];

            const foundDup = allocations?.findIndex((allocation, index) => {
              const ignoreIndex = path === `allocations[${index}]`;
              return !ignoreIndex && allocation.accountOption?.account?.id === currentAllocationId;
            });

            if (foundDup >= 0) {
              return createError({
                message: translations.orderBook.auctions.duplicateSelectedError,
                path: `${path}.accountOption`,
              });
            }

            return true;
          },
        }),
    )
    .min(1, translations.orderBook.auctions.allocationRequiredError)
    .required(translations.orderBook.auctions.allocationRequiredError),
});

export type AuctionDetailsAllocationFields = {
  allocations: Array<{
    accountOption: { account: { id?: number } } | null;
    quantity: number;
  }>;
};
