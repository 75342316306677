import { downloadFile } from '@halo-common/utils';
import { getPositionTermsheet } from '@halo-data-sources/clients';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

const TERMSHEET_PENDING_MSG = 'Downloading termsheet...';
const TERMSHEET_FAILURE_MSG = 'There was an error downloading the termsheet.';
const TERMSHEET_SUCCESS_MSG = 'Successfully downloaded the termsheet.';

export type PositionTermsheetMutationRequest = {
  termsheetId: number;
  productDescriptor: string;
};

const positionTermsheetMutationFn = async ({ termsheetId, productDescriptor }: PositionTermsheetMutationRequest) => {
  if (!termsheetId) return null;
  const result = await getPositionTermsheet(termsheetId);
  const urlSplit = result.split('_sep_');
  const secondUrlSplit = urlSplit[1].split('?');
  const fileName = secondUrlSplit[0];
  downloadFile({ fileName, downloadFilePath: result });
  return productDescriptor;
};

export const usePositionTermsheetMutation = (): UseMutationResult<
  null | string,
  Error,
  PositionTermsheetMutationRequest
> => {
  const { enqueuePendingEvent, enqueueWarningEvent, enqueueSuccessEvent, closeSnackbar } = useSnackbar();

  return useMutation<null | string, Error, PositionTermsheetMutationRequest>({
    mutationFn: positionTermsheetMutationFn,
    onMutate: (request) => {
      enqueuePendingEvent({ message: TERMSHEET_PENDING_MSG, subMessage: request.productDescriptor });
    },
    onSuccess: (productDescriptor) => {
      closeSnackbar();
      enqueueSuccessEvent({ message: TERMSHEET_SUCCESS_MSG, subMessage: productDescriptor ?? undefined });
    },
    onError: (_, request) => {
      closeSnackbar();
      enqueueWarningEvent({ message: TERMSHEET_FAILURE_MSG, subMessage: request.productDescriptor });
    },
  });
};
