import { ProductTypeEnum } from '@halo-common/enums';
import { WatchlistModel } from '@halo-common/models';
import { getProductWatchlistDetails } from '@halo-data-sources/clients';
import { ApiWatchlistMapper } from '@halo-data-sources/mappers';
import { WatchlistQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getWatchlistNoteQueryFn = async (id?: number, type?: ProductTypeEnum) => {
  if (!id || !type) return null;

  const response = await getProductWatchlistDetails(id, type.toString());
  const watchlists = response.products[0].watchlists;

  return watchlists.map((watchlist) => ApiWatchlistMapper.toWatchlistModel(watchlist));
};

export const useWatchlistNoteQuery = (
  id?: number,
  type?: ProductTypeEnum,
): UseQueryResult<Array<WatchlistModel> | null, Error> =>
  useQuery<Array<WatchlistModel> | null, Error>({
    queryKey: WatchlistQueryKeyFactory.product(id, type),
    queryFn: () => getWatchlistNoteQueryFn(id, type),
  });
