import { translations } from '@halo-common/translations';

import { authorizedPersonsStep } from './authorizedPersonsStep';
import { entityTypeStep } from './entityTypeStep';
import { firmDetailsStep } from './firmDetailsStep';
import { firmExperienceInTradingStep } from './firmExperienceInTradingStep';
import { isFirmRegulatedStep } from './isFirmRegulatedStep';
import { principalContactsStep } from './principalContactsStep';
import { structureTypeStep } from './structureTypeStep';

export const firmDetailsSection = {
  id: '1',
  label: translations.onboarding.suitability.firmDetails,
  steps: [
    firmDetailsStep,
    entityTypeStep,
    structureTypeStep,
    principalContactsStep,
    authorizedPersonsStep,
    isFirmRegulatedStep,
    firmExperienceInTradingStep,
  ],
};
