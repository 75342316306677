import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type OnboardingQueryKeys = {
  all: QueryKeyFactoryFn;
  disclaimers: QueryKeyFactoryFn;
};

export const OnboardingQueryKeyFactory: OnboardingQueryKeys = {
  all: () => ['onboarding'],
  disclaimers: () => [...OnboardingQueryKeyFactory.all(), 'disclaimers'],
};
