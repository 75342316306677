import { useCallback, useMemo } from 'react';

import { useQueryParamState } from '@halo-common/hooks';

import { WizardFormSectionModel, WizardFormStepModel } from '../types';

export type UseStepWizardManagerOptions = {
  sections: WizardFormSectionModel[];
  defaultStep?: WizardFormStepModel | null;
  fallbackStep?: WizardFormStepModel | null;
  queryKey?: string;
};

export type StepWizardManager = {
  step: string;
  setStep: (stepId: string | null) => void;
  activeSection: WizardFormSectionModel | null;
  activeSectionIndex: number;
  activeStep: WizardFormStepModel | null;
  activeStepIndex: number;
  isFirstStep: boolean;
  isLastStep: boolean;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
};

export const useStepWizardManager = ({
  sections,
  defaultStep = null,
  fallbackStep = null,
  queryKey = 'step',
}: UseStepWizardManagerOptions): StepWizardManager => {
  const [step, setStep] = useQueryParamState(queryKey);

  const { activeSection, activeSectionIndex, activeStep, activeStepIndex, flatSteps } = useMemo(() => {
    const flatSteps = sections.flatMap((section) => section.steps);

    const fallback = step ? fallbackStep : defaultStep;

    const activeStep = flatSteps.find((s) => s.id === step) || fallback;
    const activeStepIndex = flatSteps.findIndex((s) => s.id === step);

    const activeSection = sections.find((section) => section.steps.includes(activeStep as WizardFormStepModel)) || null;
    const activeSectionIndex = sections.findIndex((section) => section === activeSection);

    return {
      activeSection,
      activeSectionIndex,
      activeStep,
      activeStepIndex,
      flatSteps,
    };
  }, [sections, step, defaultStep, fallbackStep]);

  const isFirstStep = activeStepIndex <= 0;

  const isLastStep = activeStepIndex === flatSteps.length - 1;

  const goToNextStep = useCallback(() => {
    const nextStep = flatSteps[activeStepIndex + 1];
    if (!nextStep) return null;
    return setStep(nextStep.id);
  }, [activeStepIndex, flatSteps, setStep]);

  const goToPreviousStep = useCallback(() => {
    const previousStep = flatSteps[activeStepIndex - 1];
    if (!previousStep) return null;
    return setStep(previousStep.id);
  }, [activeStepIndex, flatSteps, setStep]);

  const stepManager: StepWizardManager = useMemo(
    () => ({
      activeSection,
      activeSectionIndex,
      activeStep,
      activeStepIndex,
      isFirstStep,
      isLastStep,
      step,
      setStep,
      goToNextStep,
      goToPreviousStep,
    }),
    [
      activeSection,
      activeSectionIndex,
      activeStep,
      activeStepIndex,
      isFirstStep,
      isLastStep,
      step,
      setStep,
      goToNextStep,
      goToPreviousStep,
    ],
  );

  return stepManager;
};
