import { ReactElement } from 'react';

import { ProductDetailPanelSection } from '@halo-common/layouts';
import { PostTradeNoteDetailLifecycleSectionField } from '@halo-common/modals';
import { PortfolioOrderModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';

export type PostTradeNoteDetailLifecycleSectionProps = {
  product?: PortfolioOrderModel | null;
  loading?: boolean;
};

export const PostTradeNoteDetailLifecycleSection = ({
  product,
  loading = true,
}: PostTradeNoteDetailLifecycleSectionProps): ReactElement | null => {
  const termsheet = product?.termsheet;
  const initialFixing = termsheet?.initialFixing;
  const issueDate = termsheet?.issueDate;
  const finalValuation = termsheet?.finalValuationDate;
  const maturityDate = termsheet?.maturityDate;

  const hideSection = !loading && !initialFixing && !issueDate && !maturityDate && !finalValuation;

  if (hideSection) return null;

  const showInitialFixingField = loading || initialFixing;
  const initialFixingField = showInitialFixingField ? (
    <PostTradeNoteDetailLifecycleSectionField
      title={translations.pdm.postTrade.lifecycleFieldInitialDate}
      value={initialFixing}
      loading={loading}
    />
  ) : null;

  const showIssueDateField = loading || issueDate;
  const issueDateField = showIssueDateField ? (
    <PostTradeNoteDetailLifecycleSectionField
      title={translations.pdm.postTrade.lifecycleFieldIssueDate}
      value={issueDate}
      loading={loading}
    />
  ) : null;

  const showMaturityDateField = loading || maturityDate;
  const maturityDateField = showMaturityDateField ? (
    <PostTradeNoteDetailLifecycleSectionField
      title={translations.common.maturity}
      value={maturityDate}
      loading={loading}
    />
  ) : null;

  const showFinalValuationField = loading || finalValuation;
  const finalValuationField = showFinalValuationField ? (
    <PostTradeNoteDetailLifecycleSectionField
      title={translations.pdm.postTrade.lifecycleFieldFinalValuation}
      value={finalValuation}
      loading={loading}
    />
  ) : null;

  return (
    <ProductDetailPanelSection title={translations.common.lifecycle} loading={loading}>
      {initialFixingField}
      {issueDateField}
      {maturityDateField}
      {finalValuationField}
    </ProductDetailPanelSection>
  );
};
