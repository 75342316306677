import { useMemo } from 'react';

import { ACCOUNT_TYPE_AHEAD_SYNCED_OPTION, ACCOUNT_TYPE_AHEAD_UNSYNCED_OPTION } from '../../components';

/** Common util for getting `synced` payload value based on the selected action */
export const getIsAccountTypeAheadSyncedOption = (action: string | null | undefined): boolean | undefined => {
  if (action === ACCOUNT_TYPE_AHEAD_SYNCED_OPTION) return true;
  if (action === ACCOUNT_TYPE_AHEAD_UNSYNCED_OPTION) return false;
  return undefined;
};

export const useIsAccountTypeAheadSyncedOption = (action: string | null | undefined): boolean | undefined =>
  useMemo(() => getIsAccountTypeAheadSyncedOption(action), [action]);
