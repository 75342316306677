import { ReactElement } from 'react';

import type { CalendarModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { LifecycleRecommendationFilterTypesEnum } from '@halo-modules/app/portfolio/subPages/lifecycle/enums';
import { Iconography, Tooltip, Stack, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Box, capitalize } from '@mui/material';
import { ITranslateParams } from '@transifex/native';

export type PortfolioEventsReinvestmentPopoverProps = {
  filterTypes?: Array<string>;
  calendar?: CalendarModel | null;
};

type PopoverContent = {
  text: string;
  dynamicContent: ITranslateParams;
};

export const PortfolioEventsCalendarReinvestmentPopover = ({
  filterTypes,
  calendar,
}: PortfolioEventsReinvestmentPopoverProps): ReactElement => {
  const buildTranslations = (
    type: LifecycleRecommendationFilterTypesEnum,
    text: string,
    dynamicContent: ITranslateParams,
  ) => {
    return filterTypes?.includes(type) ? { text, dynamicContent } : undefined;
  };

  const popoverBulletText: { [key: string]: PopoverContent | undefined } = {
    ISSUER: buildTranslations(
      LifecycleRecommendationFilterTypesEnum.ISSUER,
      translations.portfolio.lifecycle.reinvestPopoverIssuer,
      { issuer: calendar?.issuer?.name ?? 'N/A' },
    ),
    CURRENCY: buildTranslations(
      LifecycleRecommendationFilterTypesEnum.CURRENCY,
      translations.portfolio.lifecycle.reinvestPopoverCurrency,
      { currency: calendar?.note?.currencySymbol ?? 'N/A' },
    ),
    TERM: buildTranslations(
      LifecycleRecommendationFilterTypesEnum.TERM,
      translations.portfolio.lifecycle.reinvestPopoverTerm,
      { term: calendar?.note?.termInMonths?.toString() ?? 'N/A' },
    ),
    UNDERLYINGS: buildTranslations(
      LifecycleRecommendationFilterTypesEnum.UNDERLYINGS,
      translations.portfolio.lifecycle.reinvestPopoverUnderlyings,
      { underlyings: calendar?.note?.tradables.map((tradeable) => capitalize(tradeable.name)).join(' ') ?? 'N/A' },
    ),
    NOTE_TYPE: buildTranslations(
      LifecycleRecommendationFilterTypesEnum.NOTE_TYPE,
      translations.portfolio.lifecycle.reinvestPopoverNoteType,
      { noteType: calendar?.note?.type ?? 'N/A' },
    ),
    PROTECTION_LEVEL: buildTranslations(
      LifecycleRecommendationFilterTypesEnum.NOTE_TYPE,
      translations.portfolio.lifecycle.reinvestPopoverProtectionLevel,
      { protectionLevel: calendar?.note?.protectionPercent?.toString() ?? 'N/A' },
    ),
    PROTECTION_TYPE: buildTranslations(
      LifecycleRecommendationFilterTypesEnum.NOTE_TYPE,
      translations.portfolio.lifecycle.reinvestPopoverProtectionType,
      { protectionType: calendar?.note?.protectionType ?? 'N/A' },
    ),
  };

  const textBody = filterTypes?.map((filterType) => {
    const textValues = popoverBulletText[filterType];
    const text = textValues?.text;
    const dynamicContent = textValues?.dynamicContent;
    return text ? (
      <li key={filterType}>
        <LocalizedTypography color="primary.black" variant="body1" dynamicContent={dynamicContent}>
          {text}
        </LocalizedTypography>
      </li>
    ) : null;
  });

  const titleOverride = (
    <Stack direction="column">
      <LocalizedTypography color="primary.black" variant="body1">
        {translations.portfolio.lifecycle.thisMatchesYourCalendar}
      </LocalizedTypography>
      <ul>{textBody}</ul>
    </Stack>
  );

  return (
    <Box component="span">
      <Tooltip TitleOverride={titleOverride} arrow variant="white">
        <Iconography iconName="circle-info" color="text.secondary" />
      </Tooltip>
    </Box>
  );
};
