import { ReactElement, useMemo, useCallback } from 'react';

import { auctionStepAtoms } from '@halo-atoms/auctions';
import { useRecordActivityMutation } from '@halo-data-sources/mutations';
import { Stack } from '@halodomination/halo-fe-common';
import { Box, Step, StepLabel, Typography } from '@mui/material';
import { useSetAtom } from 'jotai';

const stepLabelStyling = {
  cursor: 'pointer',
};

export type AuctionStepperStepProps = {
  step: number;
  label: string;
  icon: ReactElement;
  displayValue: string;
  disabled?: boolean;
  trackingVerb?: string;
};

export const AuctionStepperStep = ({
  step,
  label,
  icon,
  displayValue,
  disabled = false,
  trackingVerb,
}: AuctionStepperStepProps): ReactElement => {
  const { mutate: recordActivity } = useRecordActivityMutation();

  const setStep = useSetAtom(auctionStepAtoms.setCurrentPageAtom);
  const hasVisitedStep = useSetAtom(auctionStepAtoms.hasVisitedPageAtom);

  const visited = hasVisitedStep(step);
  const currentStepIconBackgroundOpacity = visited ? '50%' : '100%';

  const iconContainerSx = {
    alignItems: 'center',
    backgroundColor: 'common.white',
    opacity: currentStepIconBackgroundOpacity,
    border: '1px solid',
    borderColor: 'common.white',
    borderRadius: '50%',
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    width: 40,
    zIndex: 1,
  };

  const stepIcon = useCallback(() => <Box sx={iconContainerSx}>{icon}</Box>, [icon, iconContainerSx]);

  const stepLabel = useMemo(() => {
    const showLabel = visited;
    return showLabel ? (
      <Stack direction="column" spacing={1} sx={stepLabelStyling}>
        <Typography variant="overline" color="primary.background">
          {label}
        </Typography>
        <Typography variant="h3" color="primary.contrastText">
          {displayValue}
        </Typography>
      </Stack>
    ) : null;
  }, [visited, label, displayValue]);

  const onStepClicked = () => {
    if (!disabled && visited) {
      setStep(step);
      if (trackingVerb) {
        recordActivity({
          activity: [{ verb: trackingVerb }],
          fromIFrame: true,
        });
      }
    }
  };

  return (
    <Step index={step}>
      <StepLabel data-testid={`auctionStepperStep-${step}`} onClick={onStepClicked} StepIconComponent={stepIcon}>
        {stepLabel}
      </StepLabel>
    </Step>
  );
};
