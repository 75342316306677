import { orderTicketReceiptAtom, orderTicketStepAtom } from '@halo-atoms/orderTicket';
import { OrderTicketReceiptModel } from '@halo-common/models';
import { OrderTicketValidationSwitchHandlerResult } from '@halo-data-sources/switches';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { useSetAtom } from 'jotai';

export const useJanneyValidationHandlers = (): OrderTicketValidationSwitchHandlerResult => {
  const { enqueueErrorEvent } = useSnackbar();

  const moveToReviewStep = useSetAtom(orderTicketStepAtom.handleNextAtom);
  const setOrderTicketReceipt = useSetAtom(orderTicketReceiptAtom);

  return {
    onSuccess: (data: OrderTicketReceiptModel | null) => {
      setOrderTicketReceipt(data);
      moveToReviewStep();
    },
    onError: (error: Error) => {
      setOrderTicketReceipt((prev) => ({ ...prev, status: 'error' }));
      enqueueErrorEvent({ subMessage: error.message, message: 'Validation Error' });
    },
  };
};
