import { FIX_DATE_TIME_FORMAT, YEAR_MONTH_DAY_DATE_FORMAT } from '@halo-common/constants';
import {
  AllocationModel,
  CalendarFomsCheckModel,
  CalendarModel,
  FixOrderChildrenAuxiliaryModel,
  FixOrderChildrenModel,
  FixOrderFeeModel,
  FixOrderModel,
  FixOrderStipulationModel,
} from '@halo-common/models';
import { postMessageService } from '@halo-common/utils';
import { ApiAllocationMapper, CalendarsMapper } from '@halo-data-sources/mappers';
import {
  ApiFixFeeModel,
  ApiFixOrderChildrenAuxiliaryModel,
  ApiFixOrderChildrenModel,
  ApiFixOrderModel,
  ApiFixOrderStipulationModel,
  ApiFixOrderWebsocketResult,
} from '@halo-data-sources/models';
import { AdminCalendarAllocationFilledStatusEnum } from '@halo-modules/admin';
import { FixState, FixStatusEnum } from '@halo-stores/Fix';
import {
  PershingAllocationReference,
  PershingCalendarMap,
  PershingState,
  PershingStatusEnum,
} from '@halo-stores/Pershing';
import { DateTime } from 'luxon';

export const mapApiFixOrderWebsocketResultToCalendarFomsCheckModel = (
  model: ApiFixOrderWebsocketResult,
): CalendarFomsCheckModel => {
  const { event, order, value } = model;
  const { id, shares, misc_fees } = order;
  const { message } = value;

  const otherFees = misc_fees ? misc_fees.filter((fee) => fee.type === 'OTHER') : [];
  const miscFeesAmount = otherFees.length ? parseFloat(otherFees[0].amount) : 0;

  const calendarFomsCheckModel = {
    amount: parseFloat(shares),
    status: 'warning',
    message,
    allocationId: id,
    miscFeesAmount,
  };

  switch (event) {
    case 'PENDING_NEW':
    case 'NEW':
      calendarFomsCheckModel.status = 'success';
      break;
    case 'REJECTED':
      calendarFomsCheckModel.status = 'error';
      break;
  }

  return calendarFomsCheckModel as CalendarFomsCheckModel;
};

export const mapApiFixOrderStipulationModelToFixOrderStipulationModel = (
  model: ApiFixOrderStipulationModel,
): FixOrderStipulationModel => ({
  type: model.type,
  value: model.value,
});

export const mapApiFixFeeModelToFixFeeModel = (model: ApiFixFeeModel): FixOrderFeeModel => ({
  basis: model.basis,
  type: model.type,
  amount: model.amount,
});

export const mapApiFixOrderChildrenAuxiliaryModelToFixOrderChildrenAuxiliaryModel = (
  model: ApiFixOrderChildrenAuxiliaryModel,
): FixOrderChildrenAuxiliaryModel => ({
  dtc: model.dtc ? parseFloat(model.dtc) : null,
  mpid: model.mpid ? model.mpid : null,
  price: model.price ? parseFloat(model.price) : null,
});

export const mapApiFixOrderChildrenModelToFixOrderChildrenModel = (
  model: ApiFixOrderChildrenModel,
): FixOrderChildrenModel => ({
  auxiliary: model.auxiliary
    ? mapApiFixOrderChildrenAuxiliaryModelToFixOrderChildrenAuxiliaryModel(model.auxiliary)
    : null,
  commission: model.commission,
  id: model.id,
  miscFees: model.misc_fees.map(mapApiFixFeeModelToFixFeeModel),
  price: model.price,
  secondaryOrderId: model.secondary_order_id,
  shares: model.shares,
  side: model.side,
  status: model.status,
  stipulations: model.stipulations.map(mapApiFixOrderStipulationModelToFixOrderStipulationModel),
});

export const mapApiFixOrderModelToFixOrderModel = (model: ApiFixOrderModel): FixOrderModel => ({
  accountType: model.auxiliary.AccountType,
  discretionUsed: model.auxiliary.DiscretionUsed,
  orderReceiptTime: DateTime.fromFormat(
    model.auxiliary.PershingOrderReceiptTime,
    FIX_DATE_TIME_FORMAT,
  ).toISO() as string,
  orderReceiveFrom: model.auxiliary.PershingOrderReceiveFrom,
  calendarStatus: model.calendarstatus,
  cusip: model.cusip,
  commission: model.commission ? parseFloat(model.commission) : 0,
  capacity: model.capacity,
  id: model.id,
  miscFees: model.misc_fees?.map(mapApiFixFeeModelToFixFeeModel) ?? [],
  parPrice: model.par_price,
  price: model.price,
  settlementDate: DateTime.fromFormat(model.settlement_date, YEAR_MONTH_DAY_DATE_FORMAT).toISO() as string,
  settlementType: model.settlement_type,
  shares: model.shares,
  side: model.side,
  solicited: model.solicited,
  status: model.status,
  stipulations: model.stipulations.map(mapApiFixOrderStipulationModelToFixOrderStipulationModel),
  children: model.children.map(mapApiFixOrderChildrenModelToFixOrderChildrenModel),
});

export const mapApiFixOrderWebsocketSubmissionResultToPershingState = (
  result: ApiFixOrderWebsocketResult,
  calendars: PershingState['calendars'],
  selectedCalendar?: PershingState['selectedCalendar'],
): Partial<PershingState> => {
  const { allocation } = result;

  const mappedAllocation = ApiAllocationMapper.toAllocationModel(allocation);

  const updatedCalendar = { ...selectedCalendar } as CalendarModel;
  const updatedAllocations = updatedCalendar?.adminAllocations
    ? [...updatedCalendar.adminAllocations, mappedAllocation]
    : [mappedAllocation];

  updatedCalendar.adminAllocations = updatedAllocations;

  const updatedCalendars = CalendarsMapper.toUpdatedCalendarMap(calendars, updatedCalendar);

  if (allocation) {
    postMessageService(window.parent).publish({
      type: 'CALENDAR_ORDER_SUBMISSION_SUCCESS',
      payload: { calendarId: selectedCalendar?.id },
    });
  }

  return {
    status: PershingStatusEnum.successSubmitOrderWebsocketEvent,
    calendars: updatedCalendars,
    selectedCalendar: updatedCalendar,
    selectedAllocation: mappedAllocation,
  };
};

export const mapApiFixOrderWebsocketCancellationResultToPershingState = (
  result: ApiFixOrderWebsocketResult,
  currentCalendar?: CalendarModel | null,
): { updatedCalendar: CalendarModel; updatedAllocation: AllocationModel } | null => {
  if (!currentCalendar) return null;

  const updatedAllocation = ApiAllocationMapper.toAllocationModel(result.allocation);

  const updatedAllocations = currentCalendar.allocations ? [...currentCalendar.allocations] : [];
  const allocationIndex = updatedAllocations.findIndex((next) => next?.id === updatedAllocation?.id);

  if (allocationIndex !== -1) updatedAllocations.splice(allocationIndex, 1, updatedAllocation);

  const updatedCalendar = { ...currentCalendar, allocations: updatedAllocations };

  return { updatedCalendar, updatedAllocation };
};

export const updateCalendarAllocations = (
  calendars: PershingCalendarMap,
  status: AdminCalendarAllocationFilledStatusEnum,
  calendarId?: number,
  allocationRefs?: Array<PershingAllocationReference>,
): PershingCalendarMap => {
  const mapAllocationsToStatus = (allocation: AllocationModel) => {
    const allocationStatus = allocation.status as AdminCalendarAllocationFilledStatusEnum;
    const isFilled = allocationStatus === AdminCalendarAllocationFilledStatusEnum.filled;
    const allocationSpecified = allocationRefs ? allocationRefs.find(({ id }) => id === allocation.id) : undefined;

    const shouldUpdateAllocation = allocationRefs === undefined || Boolean(allocationSpecified);

    return !isFilled && shouldUpdateAllocation
      ? { ...allocation, status, error: allocationSpecified?.message }
      : allocation;
  };

  const updatedCalendars = Object.entries(calendars).reduce(
    (map: PershingCalendarMap, [key, calendarList]) => {
      const updatedCalendarList = [...calendarList];

      const calendarIndex = updatedCalendarList.findIndex((calendar) => calendar.id === calendarId);

      if (calendarIndex !== -1) {
        const calendarByStatus = { ...updatedCalendarList[calendarIndex] };
        const updatedAllocations = calendarByStatus.adminAllocations?.map(mapAllocationsToStatus) ?? null;

        calendarByStatus.adminAllocations = updatedAllocations;
        updatedCalendarList[calendarIndex] = calendarByStatus;
      }

      return { ...map, [key]: updatedCalendarList };
    },
    { ...calendars },
  );

  return updatedCalendars;
};

export const mapApiFixOrderWebsocketValidationResultToFixState = (
  payload: ApiFixOrderWebsocketResult,
): Partial<FixState> => ({
  status: FixStatusEnum.successFixOrderValidationEvent,
  validationResult: mapApiFixOrderWebsocketResultToCalendarFomsCheckModel(payload),
  selectedOrder: mapApiFixOrderModelToFixOrderModel(payload.order),
});

export const mapApiFixOrderWebsocketSubmissionResultToFixState = (
  payload: ApiFixOrderWebsocketResult,
): Partial<FixState> => ({
  status: FixStatusEnum.successFixOrderSubmissionEvent,
  selectedOrder: mapApiFixOrderModelToFixOrderModel(payload.order),
});

export const mapApiFixOrderWebsocketRejectedResultToFixState = (
  payload: ApiFixOrderWebsocketResult,
): Partial<FixState> => ({
  status: FixStatusEnum.failureFixOrderEvent,
  error: payload.value.message,
});

export const mapApiFixOrderWebsocketCanceledResultToFixState = (
  payload: ApiFixOrderWebsocketResult,
): Partial<FixState> => ({
  status: FixStatusEnum.successFixOrderCanceled,
  selectedOrder: mapApiFixOrderModelToFixOrderModel(payload.order),
});
