import { ReactElement } from 'react';

import { LexicalTypography, V2UnderlyingGlyphs } from '@halo-common/components';
import { UnderlyingBasketEnum } from '@halo-common/enums';
import { ProductDetailPanelSection } from '@halo-common/layouts';
import { V2NoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { Skeleton } from '@mui/material';

export type V2ProductDetailPanelUnderlyingSectionProps = {
  underlyings?: V2NoteModel['underlyings'];
  basketType?: V2NoteModel['underlyingBasketType'];
  loading: boolean;
};

export const V2ProductDetailPanelUnderlyingSection = ({
  underlyings = [],
  basketType,
  loading,
}: V2ProductDetailPanelUnderlyingSectionProps): ReactElement | null => {
  const hideSection = !loading && !underlyings.length;

  if (hideSection) return null;

  const basketTextMap = {
    [UnderlyingBasketEnum.SINGLE]: translations.underlying.basketTypeSingle,
    [UnderlyingBasketEnum.WEIGHTED]: translations.underlying.basketTypeWeighted,
    [UnderlyingBasketEnum.WORST_OF]: translations.underlying.basketTypeWorst,
  };

  const basketTypeLine = basketType ? (
    <LexicalTypography variant="body2" color="textSecondary" translationKey={basketType}>
      {basketTextMap[basketType]}
    </LexicalTypography>
  ) : null;

  const basketTypeContent = loading ? <Skeleton width={50} height={20} /> : basketTypeLine;

  return (
    <ProductDetailPanelSection title={translations.pdm.common.underlyingSection} loading={loading}>
      {basketTypeContent}
      <V2UnderlyingGlyphs
        underlyings={underlyings}
        basketType={basketType}
        scrollable={false}
        StackProps={{ justify: 'flex-start' }}
        loading={loading}
      />
    </ProductDetailPanelSection>
  );
};
