import { ReactElement } from 'react';

import { loadingDockUploadAtom } from '@halo-atoms/loadingDock';
import { translations } from '@halo-common/translations';
import { HaloDataGrid, HaloDataGridProps } from '@halodomination/halo-fe-common';
import { useT } from '@transifex/react';
import { useAtomValue } from 'jotai';

const tableSx = {
  '& .key-cell': {
    textTransform: 'capitalize',
  },
};

export const UploadFileSuccessContent = (): ReactElement => {
  const { rows: atomRows } = useAtomValue(loadingDockUploadAtom);
  const translator = useT();

  const rows = Object.entries(atomRows).map(([key, { products, pendingProducts }], index) => ({
    id: index,
    key,
    products,
    pendingProducts,
  }));

  const commonCellProps: Partial<HaloDataGridProps['columns'][0]> = {
    align: 'left',
    editable: false,
    sortable: false,
    resizable: false,
    disableColumnMenu: true,
    disableReorder: true,
    display: 'flex',
    flex: 1,
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      field: 'key',
      headerName: '',
      display: 'text',
      cellClassName: 'key-cell',
      valueFormatter: (value: string) => translator(value),
    },
    {
      ...commonCellProps,
      field: 'products',
      headerName: translations.common.products,
      cellClassName: 'products-cell',
    },
    {
      ...commonCellProps,
      field: 'pendingProducts',
      headerName: translations.common.pendingProducts,
      cellClassName: 'pending-products-cell',
    },
  ];

  return <HaloDataGrid sx={tableSx} columns={columns} rows={rows} density="compact" rowSelection={false} />;
};
