import { useEffect, useState } from 'react';

import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { DateTime } from 'luxon';

export const useLiveTime = (): DateTime => {
  const configureDateTime = useWhiteLabelDateConfiguration();

  const date = configureDateTime(DateTime.local({ zone: 'utc' }).toISO()) as DateTime;
  const [currentTime, setCurrentTime] = useState(date);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const date = configureDateTime(DateTime.local({ zone: 'utc' }).toISO()) as DateTime;
      setCurrentTime(date);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return currentTime;
};
