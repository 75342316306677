export const API_ARBITRARY_PAGE_TO_GET_ALL_DATA = 1;

export const API_ARBITRARY_LENGTH_TO_GET_ALL_DATA = Number.MAX_VALUE;

export const DEFAULT_INFINITE_QUERY_NEXT_PAGINATION = {
  totalResults: 0,
  resultsPerPage: 25,
  page: 1,
  totalPages: 0,
  next: 1,
};
