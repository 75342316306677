import { DynamicTypeFiltersSideBarFilterValues } from '@halo-common/layouts';
import { getTermsheetRepoFilters } from '@halo-data-sources/clients';
import { ApiTermsheetRepoMapper } from '@halo-data-sources/mappers';
import type { ApiComparisonModel } from '@halo-data-sources/models';
import { TermsheetRepoQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

export type useTermsheetRepoFiltersQueryOptions = UseQueryOptions<DynamicTypeFiltersSideBarFilterValues, Error>;

export type useTermsheetRepoFiltersQueryResult = UseQueryResult<DynamicTypeFiltersSideBarFilterValues, Error>;

export const getTermsheetRepoFiltersQueryFn = async (
  comparisons?: Array<ApiComparisonModel>,
): Promise<DynamicTypeFiltersSideBarFilterValues> => {
  const data = await getTermsheetRepoFilters(comparisons);
  return ApiTermsheetRepoMapper.toTermsheetRepoDocsFiltersModel(data);
};

export const useTermsheetRepoFiltersQuery = (
  comparisons?: Array<ApiComparisonModel>,
  options?: useTermsheetRepoFiltersQueryOptions,
): useTermsheetRepoFiltersQueryResult =>
  useQuery({
    queryKey: TermsheetRepoQueryKeyFactory.docsFilters(comparisons),
    queryFn: () => getTermsheetRepoFiltersQueryFn(comparisons),

    ...options,
  });
