import { ReactElement } from 'react';

import { NoteTypeEnum, ProductTypeEnum } from '@halo-common/enums';
import { useCommonUserSetting } from '@halo-common/hooks';
import { ProductDetailModalCreateSimilarButton } from '@halo-common/layouts';
import { PreTradeNoteDetailModalHeaderSharePopoverButton } from '@halo-common/modals';
import { WatchlistButton } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Iconography, LocalizedButton, Stack } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';

export type PreTradeNoteDetailModalHeaderActionsProps = {
  productId?: number | null;
  productType?: NoteTypeEnum | null;
  loading?: boolean;
};

export const PreTradeNoteDetailModalHeaderActions = ({
  productId,
  productType,
  loading = true,
}: PreTradeNoteDetailModalHeaderActionsProps): ReactElement | null => {
  const { data: user } = useUserInfoQuery();

  const isEnabledToAuction = useCommonUserSetting('enabled_to_start_auction', 'Auction');

  // TODO: Remove this and simply set auctionDisabled to !isEnabledToAuction once the common user settings are fully implemented
  const auctionDisabled = isEnabledToAuction !== undefined ? !isEnabledToAuction : !user?.settings.isEnabledToAuction;

  if (loading) {
    return (
      <Stack direction="row" spacing={1}>
        <Skeleton width={100} height={42} />
        <Skeleton width={100} height={42} />
      </Stack>
    );
  }

  if (!productId) return null;

  const shareUrl = `${window.location.origin}/note/${productId}`;

  const disableStartAuction =
    auctionDisabled || !productType || !user?.settings?.startAuctionTypeWhitelist?.includes(productType);

  const handleStartAuction = () => navigateParentTo(`/auction/${productId}`);

  const buttonSx = {
    pt: { md: 0, xs: 2 },
    pb: { md: 2, xs: 0 },
    pr: { md: 0, xs: 2 },
    pl: { md: 2, xs: 0 },
  };

  return (
    <Stack direction="row" elementStyling={buttonSx} justifyElements="flex-end" wrap="wrap">
      <ProductDetailModalCreateSimilarButton noteId={productId} />
      <PreTradeNoteDetailModalHeaderSharePopoverButton url={shareUrl} />
      <WatchlistButton productId={productId} productType={ProductTypeEnum.note} />
      <LocalizedButton
        className="wm-pre-pdm-note-start-auction-button"
        startIcon={<Iconography iconName="gavel" color="common.white" />}
        component="a"
        onClick={handleStartAuction}
        type="button"
        variant="contained"
        color="primary"
        disabled={disableStartAuction}
      >
        {translations.pdm.preTrade.buttonStartAuction}
      </LocalizedButton>
    </Stack>
  );
};
