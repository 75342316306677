import {
  bulkOrderTicketManagerAtom,
  bulkOrderTicketReceiptAtom,
  fixTimeoutErrorAtom,
  fixValidationErrorsAtom,
  setFixTimeoutAtom,
} from '@halo-atoms/orderTicket';
import { FixWebsocketEvent } from '@halo-common/hooks';
import { OrderTicketReceiptModel } from '@halo-common/models';
import { useWebSocketContext } from '@halo-common/providers';
import { ApiFixEventMessageEnum, ApiFixEventTypeEnum } from '@halo-data-sources/enums';
import { BulkAllocationError } from '@halo-data-sources/errors';
import { ApiAllocationMapper, BulkAllocationMapper } from '@halo-data-sources/mappers';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { useSetAtom } from 'jotai';

import { OrderTicketValidationSwitchHandlerResult } from '../useOrderTicketValidationSwitchHandlers';

export const FIVE_MINUTE_TIMEOUT = 300000;

export const PERSHING_ALLOCATIONS_FORM_KEY = 'allocations';
export const PERSHING_DEFAULT_VALIDATION_MESSAGE = 'An allocation failed to be validated.';
export const PERSHING_DEFAULT_TIMEOUT_MESSAGE =
  'There was an error processing your allocations, please try again later.';

export const usePershingValidationHandlers = (): OrderTicketValidationSwitchHandlerResult => {
  const { enqueueErrorEvent } = useSnackbar();
  const { events } = useWebSocketContext();

  const setOrderTicketState = useSetAtom(bulkOrderTicketManagerAtom);
  const setFixValidationErrors = useSetAtom(fixValidationErrorsAtom);
  const setBulkOrderTicketReceipt = useSetAtom(bulkOrderTicketReceiptAtom);
  const setFixTimeout = useSetAtom(setFixTimeoutAtom);
  const setFixErrorTimeout = useSetAtom(fixTimeoutErrorAtom);

  return {
    onMutate: (data) => {
      const allocations = data.bulkData?.allocations;

      if (!allocations?.length) return undefined;

      const allocationIds = allocations.map((allocation) => allocation.accountOption?.account?.id) as Array<number>;
      setOrderTicketState({ setPendingValidateAllocationIds: allocationIds });
      setFixValidationErrors();
      setFixTimeout();
      setFixErrorTimeout(false);

      allocations.forEach((allocation) => {
        const account = allocation.accountOption?.account;
        const accountId = account?.id;
        const accountIdentifier = account?.accountId;

        if (!accountId) return;

        const websocketCallback = (event: FixWebsocketEvent) => {
          const { account: fixAccount, value, event: eventType } = event;
          const isMatchingAccount = accountId === fixAccount.id || accountIdentifier === fixAccount.account_identifier;
          const isNewFixEvent = eventType === ApiFixEventTypeEnum.NEW;
          const isRejectedFixEvent = eventType === ApiFixEventTypeEnum.REJECTED;
          const handleAccepted = isMatchingAccount && isNewFixEvent;
          const handleRejected = isMatchingAccount && isRejectedFixEvent;

          const message = value.message as ApiFixEventMessageEnum;
          const isValidated = message === ApiFixEventMessageEnum.ORDER_VALIDATED;

          if (isMatchingAccount) events.fix.remove(accountId);

          if (handleAccepted && isValidated) {
            const bulkOrder = BulkAllocationMapper.toBulkOrderTicketReceipt(event);

            setBulkOrderTicketReceipt(bulkOrder);
            setOrderTicketState({ validatedAllocationIds: accountId });
          } else if (handleRejected) {
            const error = { [accountId.toString()]: value.message };

            setOrderTicketState({ invalidAllocationIds: accountId });
            setFixValidationErrors(error);
          }
        };

        events.fix.add(accountId, websocketCallback);
      });
    },
    onError: (error: BulkAllocationError) => {
      const allocations = error.allocations ?? [];
      const hasAllocations = Boolean(allocations?.length);

      if (hasAllocations) {
        const errors = ApiAllocationMapper.toOrderTicketReceiptAllocationErrors(allocations);
        allocations.forEach((allocation) => void events.fix.remove(allocation.accountId));
        setFixValidationErrors(errors);
      }

      setOrderTicketState();
      setFixTimeout();
      enqueueErrorEvent({ message: error.message });
    },
    onSuccess: (response: OrderTicketReceiptModel | null) => {
      const allocations = response?.allocations;

      if (!allocations?.length) return undefined;

      const timeout = global.setTimeout(() => {
        setFixErrorTimeout(true);
        allocations.forEach((allocation) => void events.fix.remove(allocation.accountId));
        setOrderTicketState();
        enqueueErrorEvent({ message: PERSHING_DEFAULT_TIMEOUT_MESSAGE });
      }, FIVE_MINUTE_TIMEOUT);

      setFixTimeout(timeout);
    },
  };
};
