import { useHaloInfiniteQuery } from '@halo-common/hooks';
import type { GetNoteflixNotesResult, InfinitePaginationModel, InfiniteQueryOptions } from '@halo-common/models';
import { getNoteflixNotes } from '@halo-data-sources/clients';
import { HttpError } from '@halo-data-sources/errors';
import { ApiNoteflixMapper } from '@halo-data-sources/mappers';
import { NoteflixQueryKeyFactory } from '@halo-data-sources/queries';
import type { UseInfiniteQueryResult } from '@tanstack/react-query';

export type UseNoteflixNotesQueryOptions = InfiniteQueryOptions<GetNoteflixNotesResult> & { from?: number };
export type UseNoteflixNotesQueryResult = UseInfiniteQueryResult<GetNoteflixNotesResult, Error>;

const fetchNoteflixNotesQueryFn = async (id: number, pageParam: InfinitePaginationModel) => {
  if (!id) throw new HttpError(400, 'Noteflix ID is required.');
  const response = await getNoteflixNotes(id, pageParam?.next);
  return ApiNoteflixMapper.toNoteflixNotesModel(response);
};

export const useNoteflixNotesQuery = (id: number, options?: Partial<UseNoteflixNotesQueryOptions>) => {
  const { from = 0, ...rest } = options || {};

  // TODO: Update this when we have a better BE endpoint for Noteflix
  const DEFAULT_PAGINATION: InfinitePaginationModel = {
    next: from,
    page: from,
    totalResults: from,
    resultsPerPage: from,
    totalPages: from,
  };

  return useHaloInfiniteQuery<GetNoteflixNotesResult>({
    initialPageParam: DEFAULT_PAGINATION,
    queryKey: NoteflixQueryKeyFactory.category_notes(id),
    queryFn: ({ pageParam }) => fetchNoteflixNotesQueryFn(id, pageParam),
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage) return DEFAULT_PAGINATION;

      const previousPage = pages[pages.length - 2];
      const nextIndex = lastPage.pagination.next;
      const previousIndex = previousPage?.pagination.next ?? from;
      const pageSize = nextIndex - previousIndex;
      const hasNextPage = lastPage.items?.length >= pageSize;

      if (hasNextPage) return lastPage.pagination;
      else return undefined;
    },
    ...rest,
  });
};
