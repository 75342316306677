import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { RepSettingInput } from './RepSettingInput';

export type RepSettingInputFieldValue = {
  repSettingId: number;
  value: string;
};

export type RepSettingInputFieldSchema = {
  repSettingArrayField: Array<Array<RepSettingInputFieldValue>>;
};

export type RepSettingArrayFieldProps = {
  id: number;
  inputLabel: string;
  allowMultiple: boolean;
  required?: boolean;
  fieldIndex: number;
  maxLength?: number;
  minLength?: number;
  disabled?: boolean;
};

export const RepSettingArrayField = ({
  id,
  inputLabel,
  fieldIndex,
  allowMultiple,
  required = true,
  maxLength,
  minLength,
  disabled = false,
}: RepSettingArrayFieldProps): ReactElement => {
  const { control } = useFormContext<RepSettingInputFieldSchema>();

  const { fields, append, remove } = useFieldArray<RepSettingInputFieldSchema>({
    control,
    name: `repSettingArrayField.${fieldIndex}`,
    rules: { minLength, maxLength, required },
  });

  const dynamicContent = {
    helperText: {
      maxCharacters: maxLength?.toString() ?? '',
      minCharacters: minLength?.toString() ?? '',
    },
  };

  const handleAddAnother = () => {
    append({
      repSettingId: id,
      value: '',
    });
  };

  const showAddAnotherButton = !disabled && allowMultiple;

  const addAnotherIcon = <Iconography iconName="plus" />;
  const addAnotherBtn = showAddAnotherButton ? (
    <LocalizedButton onClick={handleAddAnother} variant="text" startIcon={addAnotherIcon}>
      {translations.common.addAnother}
    </LocalizedButton>
  ) : null;

  return (
    <>
      {fields.map((field, index) => (
        <RepSettingInput
          key={field.id}
          positionIndex={index}
          fieldIndex={fieldIndex}
          dynamicContent={dynamicContent}
          label={inputLabel}
          onRemove={remove}
        />
      ))}
      {addAnotherBtn}
    </>
  );
};
