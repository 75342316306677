import { ReactElement } from 'react';

import { ModuleQuizAnswerModel } from '@halo-common/models';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { FormControlLabel, Radio, radioClasses } from '@mui/material';

export type QuizManagerAnswerProps = {
  answer: ModuleQuizAnswerModel;
  attempted: boolean;
  selected: boolean;
  disabled: boolean;
};

export const QuizManagerAnswer = ({ answer, attempted, selected, disabled }: QuizManagerAnswerProps): ReactElement => {
  const { id, content, explanation, isRight } = answer;

  const valid = attempted && isRight;
  const invalid = attempted && selected && !isRight;

  let informativeBackgroundColor = 'default';
  if (valid) informativeBackgroundColor = 'success.background';
  else if (invalid) informativeBackgroundColor = 'warning.background';

  let informativeTextColor = 'default';
  if (valid) informativeTextColor = 'success.dark';
  else if (invalid) informativeTextColor = 'warning.dark';

  let informativeRadioColor = 'primary.main';
  if (valid) informativeRadioColor = 'success.main';
  else if (invalid) informativeRadioColor = 'warning.main';

  const labelStyling = { backgroundColor: informativeBackgroundColor, width: '100%' };
  const labelTextStyling = { color: informativeTextColor };
  const errorStyling = { ...labelStyling, ...labelTextStyling, p: 2, ml: -1.375 };
  const radioStyling = {
    py: 2,
    color: informativeRadioColor,
    [radioClasses.checked]: {
      color: informativeRadioColor,
    },
  };

  const control = <Radio sx={radioStyling} disabled={disabled} />;
  const label = <LocalizedTypography sx={labelTextStyling}>{content}</LocalizedTypography>;

  const showExplanation = invalid && selected && explanation;
  const wrongAnswerExplanation = showExplanation ? (
    <LocalizedTypography sx={errorStyling}>{explanation}</LocalizedTypography>
  ) : null;

  return (
    <Stack direction="column" xs={12}>
      <FormControlLabel sx={labelStyling} value={id} control={control} label={label} />
      {wrongAnswerExplanation}
    </Stack>
  );
};
