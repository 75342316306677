import { ReactElement } from 'react';

import { NoteSpecificDetailsField } from '@halo-common/hooks';
import { ProductDetailPanelSection, ProductDetailPanelSectionField } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { v4 as uuid } from 'uuid';

const LOADING_FIELDS: Array<NoteSpecificDetailsField> = Array.from(Array(4), () => ({
  field: uuid(),
  text: '',
  label: '',
}));

export type ProductDetailPanelEarlyRedemptionSectionProps = {
  fields?: Array<NoteSpecificDetailsField>;
  loading: boolean;
};

export const ProductDetailPanelEarlyRedemptionSection = ({
  fields = [],
  loading,
}: ProductDetailPanelEarlyRedemptionSectionProps): ReactElement | null => {
  const payoffs = loading ? LOADING_FIELDS : fields;

  const sectionFields = payoffs.map(({ text, label, dynamicContent }) => (
    <ProductDetailPanelSectionField
      key={label}
      label={label}
      description={text}
      loading={loading}
      dynamicContent={dynamicContent}
    />
  ));

  return sectionFields.length ? (
    <ProductDetailPanelSection title={translations.pdm.common.principalProtectionSection} loading={loading}>
      {sectionFields}
    </ProductDetailPanelSection>
  ) : null;
};
