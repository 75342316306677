import { ReactElement } from 'react';

import { useUserInfoQuery } from '@halo-data-sources/queries';
import { CustodyFormsSection, MyFilesSection, SignedDocumentsSection } from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';

export const CUSTODY_FORM_NAME = 'Custody Forms';

export const UserProfileDocumentsTab = (): ReactElement => {
  const { data: user } = useUserInfoQuery();

  const showCustodyForm = Boolean(user?.settings.showCustodyForm);

  const custodyFormSection = showCustodyForm ? <CustodyFormsSection /> : null;

  return (
    <Stack direction="column" spacing={3} xs={12}>
      <MyFilesSection />
      <SignedDocumentsSection />
      {custodyFormSection}
    </Stack>
  );
};
