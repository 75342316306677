import { quoteValueMapAtom } from '@halo-atoms/quotes';
import { QuoteModel } from '@halo-common/models';
import { useAtomValue } from 'jotai';

export const useQuoteSelector = (productId?: number): QuoteModel | undefined => {
  const quoteValueMap = useAtomValue(quoteValueMapAtom);

  if (!productId) return undefined;

  return quoteValueMap?.[productId]?.recent ?? undefined;
};
