import { EventModel, EventQueueModel, EventQueueResult, useEventQueue } from '@halo-common/hooks';
import { ApiExecOrder } from '@halo-data-sources/models';
import { atom } from 'jotai';

const SOCKET_NAMESPACE = 'exec-order';
const COMPARISON_KEY = 'order.id';

type ExecOrderComparisonType = ExecOrderWebsocketEvent['order']['id'];

export type ExecOrderWebsocketEvent = {
  order: ApiExecOrder;
  fills: Array<{
    id: number;
    buy_exec_order: Record<string, number>;
    sell_exec_order: Record<string, number>;
    quantity: number;
    price: number;
  }>;
  comment: string | null;
  reason: string;
  event_type: 'FILLACK';
};

export type ExecOrderWebsocketEventModel = EventModel<ExecOrderWebsocketEvent, ExecOrderComparisonType>;
export type WebsocketExecOrderEventQueue = EventQueueModel<ExecOrderWebsocketEvent, ExecOrderComparisonType>;

const execOrderQueueAtom = atom<Array<ExecOrderWebsocketEventModel>>([]);

export const useExecOrderEventQueue = (): EventQueueResult<ExecOrderWebsocketEvent, ExecOrderComparisonType> => {
  return useEventQueue<ExecOrderWebsocketEvent, ExecOrderComparisonType>(
    SOCKET_NAMESPACE,
    COMPARISON_KEY,
    execOrderQueueAtom,
  );
};
