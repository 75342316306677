import { useCallback } from 'react';

import { useRouter } from 'next/router';

const getString = (value: string | string[] | undefined): string => {
  if (Array.isArray(value)) return value[0];
  return value ?? '';
};

type SetQueryParamFn = (stepId: string | null) => void;

export type useQueryParamStateReturn = [string, SetQueryParamFn];

export type useQueryParamStateOptions = {
  shallow?: boolean;
  scroll?: boolean;
};

export const useQueryParamState = (queryKey: string, options?: useQueryParamStateOptions): useQueryParamStateReturn => {
  const router = useRouter();

  const param = getString(router.query[queryKey]);

  const { shallow = true, scroll = true } = options || {};

  const setParam = useCallback(
    (stepId: string | null) => {
      const newQuery = { ...router.query };

      if (stepId) newQuery[queryKey] = stepId;
      else delete newQuery[queryKey];

      return router.push({ query: newQuery }, undefined, { shallow, scroll });
    },
    [router, queryKey, shallow, scroll],
  );

  return [param, setParam];
};
