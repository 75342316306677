import { ReactElement, useState } from 'react';

import { calendarManagerAtom, calendarSelectedAtom } from '@halo-atoms/calendars';
import { modalAtom } from '@halo-atoms/common';
import { resetBulkOrderTicketAtom } from '@halo-atoms/orderTicket';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { CalendarOrderStatusEnum } from '@halo-common/enums';
import {
  useWhiteLabelRole,
  usePrimaryAssetIdentifier,
  useProductProgress,
  useWhiteLabelDateConfiguration,
} from '@halo-common/hooks';
import { AllocationModal, AllocationModalFormModel, AllocationModalProps, OrderTicketModal } from '@halo-common/modals';
import { calendarAllocationSchema, calendarAttestationSchema } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import {
  CalendarAllocationModel,
  useCalendarAllocationMutation,
  useEditCalendarAllocationMutation,
  useRecordActivityMutation,
} from '@halo-data-sources/mutations';
import { CalendarsQueryKeyFactory, useCalendarAllocationsQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { CALENDAR_DETAILS_MODAL_NAME } from '@halo-modules/app';
import { HaloDataGridProps } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';

const EDITABLE_STATES = ['NEW', 'PENDING'];

export const CALENDAR_ALLOCATION_MODAL_NAME = 'calendarAllocation';

export const CalendarAllocationsModal = (): ReactElement => {
  const { asPath: url } = useRouter();
  const queryClient = useQueryClient();

  const configureDateTime = useWhiteLabelDateConfiguration();
  const whiteLabelRole = useWhiteLabelRole();

  const [tab, setTab] = useState(0);

  const [modalMap, setModalMap] = useAtom(modalAtom);
  const { query } = useAtomValue(calendarManagerAtom);
  const [selectedCalendar, setSelectedCalendar] = useAtom(calendarSelectedAtom);
  const resetBulkOrderTicket = useSetAtom(resetBulkOrderTicketAtom);

  const { data: user } = useUserInfoQuery();
  const { data: allocations, isPending: allocationsLoading } = useCalendarAllocationsQuery(selectedCalendar?.id);

  const { mutate: recordActivity } = useRecordActivityMutation();
  const { mutate: editAllocation, isPending: editLoading } = useEditCalendarAllocationMutation();
  const {
    mutate: submitAllocation,
    isPending: submitLoading,
    isSuccess,
  } = useCalendarAllocationMutation({
    onSuccess: () => setTab(1),
  });

  const organization = user?.organization;

  const pageId = Number(organization?.calendarPageId);
  const isPershing = whiteLabelRole === 'pershing';
  const isPershingDev = whiteLabelRole === 'pershingDev';
  const isJanney = whiteLabelRole === 'janney';

  const requiresAttestation = organization?.requiresAttestation;
  const schema = requiresAttestation ? calendarAttestationSchema : calendarAllocationSchema;
  const validation = yupResolver<AllocationModalFormModel>(schema);

  const { identifier } = usePrimaryAssetIdentifier(selectedCalendar);

  const title = translations.allocationsModal.calendars.title.replace('{identifier}', identifier);

  let allocationModalStatus: AllocationModalProps['status'] = 'idle';
  if (allocationsLoading) allocationModalStatus = 'loading';
  else if (isSuccess) allocationModalStatus = 'success';

  const id = selectedCalendar?.id;
  const subtitle = selectedCalendar?.displayName;
  const expirationDate = selectedCalendar?.expirationDate;

  const { status } = useProductProgress({ calendar: selectedCalendar });

  const isClosed = status === CalendarOrderStatusEnum.Closed;
  const formDisabled = isClosed || selectedCalendar?.paused;
  const hidePrimaryAction = formDisabled || tab === 1;

  const checkboxLabel = requiresAttestation
    ? translations.allocationsModal.calendars.attestationCheckboxLabel
    : undefined;

  const formattedDateVerb = isClosed
    ? translations.allocationsModal.calendars.statusClosed
    : translations.allocationsModal.calendars.statusCloses;

  const closeDateText = expirationDate ? configureDateTime(expirationDate)?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT) : '';
  const closingDate = closeDateText ? formattedDateVerb.replace('{date}', closeDateText) : '';

  const open = modalMap[CALENDAR_ALLOCATION_MODAL_NAME];
  const hasAllocations = allocations?.length ? allocations.length > 0 : false;
  const hasInventoryRemaining = selectedCalendar?.inventoryRemaining ? selectedCalendar.inventoryRemaining > 0 : false;
  const useMaxNotional = hasAllocations && hasInventoryRemaining;
  const maxNotional = useMaxNotional ? selectedCalendar?.inventoryRemaining : undefined;
  const rules = { maxNotional };

  const handleCancelAllocation = (id: number) => {
    editAllocation({ id, status: 'canceled' });
  };

  const handleEditAllocation: HaloDataGridProps['onCellEditStop'] = (params) => {
    const canEditCell = EDITABLE_STATES.includes(params.row.status.toUpperCase());

    if (canEditCell) {
      const cellValue = Number(params.row[params.field].split(',').join(''));
      editAllocation({ id: Number(params.id), status: 'pending', amount: cellValue });
    }
  };

  const handleSubmit = (data: AllocationModalFormModel) => {
    if (url.includes('portfolio')) {
      recordActivity({
        activity: [{ verb: 'submitted allocation via calendar reinvestment', model_name: 'Lifecycle' }],
        fromIFrame: false,
      });
    }

    const formattedAllocations: Array<CalendarAllocationModel> = data.allocations.map((allocation) => ({
      account: allocation.accountOption?.account?.id,
      quantity: Number(allocation.amount),
      attestation: Boolean(allocation.checkbox),
    }));

    submitAllocation({ calendarId: id, calendarPageId: pageId, allocations: formattedAllocations });
  };

  const handleClose = () => {
    setModalMap({ [CALENDAR_ALLOCATION_MODAL_NAME]: false });
    resetBulkOrderTicket();
    setTab(0);

    if (!modalMap[CALENDAR_DETAILS_MODAL_NAME]) setSelectedCalendar(null);
  };

  const handleUpdateOrder = () => {
    const queryKey = CalendarsQueryKeyFactory.infinite(query);
    void queryClient.refetchQueries({ queryKey });
  };

  if (isJanney) {
    return <OrderTicketModal onClose={handleClose} open={open} onUpdateOrder={handleUpdateOrder} />;
  }

  if (isPershing || isPershingDev) {
    return <OrderTicketModal bulk onClose={handleClose} open={open} onUpdateOrder={handleUpdateOrder} />;
  }

  return (
    <AllocationModal
      disableEdits
      title={title}
      subtitle={subtitle}
      message={translations.allocationsModal.calendars.allocationInstructionsMessage}
      open={open}
      allocations={allocations}
      status={allocationModalStatus}
      hideForm={formDisabled}
      hidePrimaryAction={hidePrimaryAction}
      validation={validation}
      onClose={handleClose}
      onSubmit={handleSubmit}
      onCancelAllocation={handleCancelAllocation}
      onEditAllocation={handleEditAllocation}
      TableProps={{
        cancelling: editLoading,
        message: translations.allocationsModal.calendars.allocationCancellationMessage,
      }}
      TabsProps={{ value: tab, onChange: setTab }}
      FormProps={{ checkboxLabel, rules }}
      FooterProps={{ children: closingDate, loading: submitLoading }}
    />
  );
};
