import type { TermsheetRepoConfigModel } from '@halo-common/models';
import { getTermsheetRepoConfig } from '@halo-data-sources/clients';
import type { HttpError } from '@halo-data-sources/errors';
import { ApiTermsheetRepoMapper } from '@halo-data-sources/mappers';
import { TermsheetRepoQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

export type useTermsheetRepoConfigQueryOptions = UseQueryOptions<TermsheetRepoConfigModel, HttpError>;

export type useTermsheetRepoConfigQueryResult = UseQueryResult<TermsheetRepoConfigModel, HttpError>;

const getTermsheetRepoConfigQueryFn = async () => {
  const data = await getTermsheetRepoConfig();
  return ApiTermsheetRepoMapper.toTermsheetRepoConfigModel(data);
};

export const useTermsheetRepoConfigQuery = (
  options?: useTermsheetRepoConfigQueryOptions,
): useTermsheetRepoConfigQueryResult =>
  useQuery({
    queryKey: TermsheetRepoQueryKeyFactory.config(),
    queryFn: () => getTermsheetRepoConfigQueryFn(),

    ...options,
  });
