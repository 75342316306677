import { useHaloInfiniteQuery } from '@halo-common/hooks';
import {
  AccountModel,
  HouseholdBasicModel,
  InfinitePaginationModel,
  InfiniteQueryOptions,
  SortModel,
} from '@halo-common/models';
import {
  AccountDataSwitchKeyFactory,
  useAccountInfiniteCommonQueryFn,
  useAccountPositionsInfiniteQueryDataSwitchFn,
} from '@halo-data-sources/switches';

export type GetAccountInfiniteQueryDataSwitchRequest = {
  search?: string;
  hasPositions?: boolean;
  accountIds?: Array<number>;
  householdIds?: Array<number>;
  synced?: boolean | null;
  page: number;
  pageLength: number;
  sort?: Array<SortModel>;
  currencyId?: number;
  isPosition?: boolean;
  custodianIds?: Array<number>;
};

export type GetAccountInfiniteQueryDataSwitchResult = {
  accounts: Array<AccountModel>;
  households: Array<HouseholdBasicModel>;
  pagination: InfinitePaginationModel;
};

const getAccountInfiniteQueryDataSwitchFn = (
  request: GetAccountInfiniteQueryDataSwitchRequest,
  pageParam: InfinitePaginationModel,
) => {
  if (request.isPosition) return useAccountPositionsInfiniteQueryDataSwitchFn(request, pageParam);
  return useAccountInfiniteCommonQueryFn(request, pageParam);
};

export const useAccountInfiniteQueryDataSwitch = (
  payload: GetAccountInfiniteQueryDataSwitchRequest,
  options?: Partial<InfiniteQueryOptions<GetAccountInfiniteQueryDataSwitchResult>>,
) =>
  useHaloInfiniteQuery<GetAccountInfiniteQueryDataSwitchResult>({
    queryKey: AccountDataSwitchKeyFactory.infinite(payload),
    queryFn: ({ pageParam }) => getAccountInfiniteQueryDataSwitchFn(payload, pageParam),
    ...options,
  });
