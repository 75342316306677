import { ReactElement } from 'react';

import { Iconography } from '@halodomination/halo-fe-common';

export type AgreedIconProps = {
  agreed?: boolean;
};

export const AgreedIcon = ({ agreed }: AgreedIconProps): ReactElement => {
  const iconStyling = { color: agreed ? 'success.main' : 'grey.200', margin: 0.5 };
  const iconName = agreed ? 'check-circle' : 'circle';
  const prefix = agreed ? 'far' : 'fas';

  return <Iconography iconName={iconName} sx={iconStyling} prefix={prefix} />;
};
