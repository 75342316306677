import { translations } from '@halo-common/translations';
import { LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Alert, AlertTitle, SxProps } from '@mui/material';

export type AssetSearchAlertProps = {
  onClear: () => void;
  sx: SxProps;
};

export const AssetSearchAlert = ({ onClear, sx }: AssetSearchAlertProps) => (
  <Alert severity="warning" variant="filled" sx={sx}>
    <AlertTitle>
      <LocalizedTypography fontWeight={0}>{translations.dynamicFilters.common.assetSearchAlert}</LocalizedTypography>
    </AlertTitle>
    <LocalizedButton onClick={onClear} variant="text">
      {translations.dynamicFilters.common.clearSearch}
    </LocalizedButton>
  </Alert>
);
