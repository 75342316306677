import { ReactElement } from 'react';

import { DocumentsTableSkeletonRow } from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';

const rowStyling = { pt: 2, pb: 0, px: 3 };

export const DocumentsTableSkeleton = (): ReactElement => (
  <Stack direction="column" spacing={2}>
    <Stack
      direction="row"
      alignItems="center"
      justify="space-between"
      xs={[3, 4, 2, 3]}
      md={[2, 6, 2, 2]}
      sx={rowStyling}
      justifyElements={[undefined, undefined, undefined, 'flex-end']}
    >
      <Skeleton width={70} height={22} variant="rounded" />
      <Skeleton width={70} height={22} variant="rounded" />
      <Skeleton width={70} height={22} variant="rounded" />
      <Skeleton width={70} height={22} variant="rounded" />
    </Stack>
    <Stack direction="column">
      <DocumentsTableSkeletonRow />
      <DocumentsTableSkeletonRow />
      <DocumentsTableSkeletonRow />
      <DocumentsTableSkeletonRow />
    </Stack>
  </Stack>
);
