import { ReactElement } from 'react';

import { EmptyResultsCard, EmptyResultsCardProps } from '@halo-common/components/EmptyResultsCard';
import { translations } from '@halo-common/translations';
import { BrandIcon } from '@halodomination/halo-fe-common';

export type NoteflixEmptyMessageProps = Partial<EmptyResultsCardProps>;

export const NoteflixEmptyMessage = (props: NoteflixEmptyMessageProps): ReactElement => (
  <EmptyResultsCard
    title={translations.dashboard.noteflix.thereAreNotAnyNotesInThisCategory}
    description={translations.dashboard.noteflix.pleaseSelectAnotherCategoryFromTheDropdown}
    brandIcon={<BrandIcon variant="trackEvents" color="primary" />}
    {...props}
  />
);
