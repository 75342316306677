import { ReactElement } from 'react';

import { addPositionsToAccountModalAtom } from '@halo-atoms/portfolio';
import { AddPositionsToAccountFormFields } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';

export const AddPositionsToAccountFooterBackButton = (): ReactElement => {
  const { reset } = useFormContext<AddPositionsToAccountFormFields>();

  const [addPositionsToAccountModal, setAddPositionsToAccountModal] = useAtom(addPositionsToAccountModalAtom);
  const { modalStepMap } = addPositionsToAccountModal;
  const { handleResetAtom, currentPageAtom, handlePrevAtom } = modalStepMap;

  const handleReset = useSetAtom(handleResetAtom);
  const handleBack = useSetAtom(handlePrevAtom);
  const step = useAtomValue(currentPageAtom);

  const addMoreHoldings = () => {
    setAddPositionsToAccountModal({ removedAssets: [], open: true });
    reset();
    handleReset();
  };

  const handleClose = () => {
    setAddPositionsToAccountModal();
    reset();
    handleReset();
  };

  const backFromChooseAssetsButton = (
    <LocalizedButton
      onClick={handleClose}
      variant="text"
      color="primary"
      startIcon={<Iconography iconName="arrow-left" />}
    >
      {translations.common.back}
    </LocalizedButton>
  );

  const backFromAllocateNotionalButton = (
    <LocalizedButton
      onClick={handleBack}
      variant="text"
      color="primary"
      startIcon={<Iconography iconName="arrow-left" />}
    >
      {translations.common.back}
    </LocalizedButton>
  );

  const addMoreHoldingsButton = (
    <LocalizedButton
      onClick={addMoreHoldings}
      variant="text"
      color="primary"
      startIcon={<Iconography iconName="arrow-rotate-right" />}
    >
      {translations.portfolio.common.addMoreHoldingsButton}
    </LocalizedButton>
  );

  const backButtonList = [backFromChooseAssetsButton, backFromAllocateNotionalButton, addMoreHoldingsButton];

  return backButtonList[step];
};
