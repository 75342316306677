import { useOrderTicketContentPicker } from '@halo-common/hooks';
import { OrderTicketFormModelFields } from '@halo-common/modals';
import { CalendarModel } from '@halo-common/models';
import { useFormContext } from 'react-hook-form';

export const useOrderTicketSalesPrice = (calendar?: CalendarModel | null): number | null => {
  const { watch } = useFormContext<OrderTicketFormModelFields>();

  const { calculateSalesPrice, isAdvisoryAccountSelected, isBrokerageAccountSelected } = useOrderTicketContentPicker();

  const hasAccountSelected = Boolean(watch('account.account'));

  if (isAdvisoryAccountSelected) return 0;
  else if (!calendar || !calculateSalesPrice || !hasAccountSelected || !isBrokerageAccountSelected) return null;

  const quantity = parseInt(watch('quantity'));
  const parsedQuantity = !Number.isNaN(quantity) ? quantity * 1000 : 0;
  const salesCreditPercentage = 100 - (calendar.wholesaler?.salePrice ?? 100);

  return (salesCreditPercentage / 100) * parsedQuantity;
};
