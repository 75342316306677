import { useState } from 'react';

export type UseElementHoverHook = {
  hovered: boolean;
  onEnter: () => void;
  onLeave: () => void;
};

export const useElementHover = (): UseElementHoverHook => {
  const [isHovered, setIsHovered] = useState(false);

  const handleOnEntered = () => setIsHovered(true);
  const handleOnLeave = () => setIsHovered(false);

  return { hovered: isHovered, onEnter: handleOnEntered, onLeave: handleOnLeave };
};
