import { getPortfolioTermSheetDownloadLink } from '@halo-data-sources/clients';
import { TermsheetQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getTermsheetDownloadLinkQueryFn = async (id?: number) => {
  if (!id) return null;
  return await getPortfolioTermSheetDownloadLink(id);
};

export const useTermsheetDownloadLinkQuery = (id?: number): UseQueryResult<string | null, Error> =>
  useQuery<string | null, Error>({
    queryKey: TermsheetQueryKeyFactory.downloadLink(id),
    queryFn: () => getTermsheetDownloadLinkQueryFn(id),
  });
