import { FormEventHandler, ReactElement, UIEvent, useCallback, useState } from 'react';

import { DisclaimerModalButton } from '@halo-common/modals';
import { DisclaimerOnboardingModel } from '@halo-common/models';
import { Modal } from '@halodomination/halo-fe-common';
import { DialogContent, DialogContentText } from '@mui/material';
import { sanitize } from 'dompurify';

const contentStyling = { px: 5, py: 3 };
const modalContentStyling = { sx: { padding: 0 } };

export type DisclaimerModalProps = {
  disclaimer: DisclaimerOnboardingModel | null;
  disclaimerFormId?: string;
  open: boolean;
  disclaimerIsAccepted: boolean;
  handleClose: () => void;
  handleDisclaimerAcceptance: (id: number) => void | FormEventHandler;
  handleSubmit?: FormEventHandler;
};

export const DisclaimerModal = ({
  disclaimer,
  open,
  disclaimerIsAccepted,
  handleClose,
  handleDisclaimerAcceptance,
  disclaimerFormId,
  handleSubmit,
}: DisclaimerModalProps): ReactElement => {
  const [isDisabled, setIsDisabled] = useState(false);

  const disclaimerTitle = disclaimer?.displayName ? disclaimer.displayName : '';
  const disclaimerId = disclaimer?.id ? disclaimer.id : -1;
  const disclaimerContent = disclaimer?.content ? disclaimer.content : '';
  const sanitizedContent = sanitize(disclaimerContent);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    e.preventDefault();

    const closeToBottom = !(
      e.currentTarget.scrollHeight - Math.ceil(e.currentTarget.scrollTop + window.devicePixelRatio) <=
      e.currentTarget.clientHeight
    );

    setIsDisabled(isDisabled && closeToBottom);
  };

  const contentRef = useCallback((node: Element) => {
    if (node) setIsDisabled(node.scrollHeight > node.clientHeight);
  }, []);

  const dangerousContent = <DialogContentText dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;

  const insecureDialogContent =
    disclaimerFormId && handleSubmit ? (
      <form id={disclaimerFormId} onSubmit={handleSubmit}>
        {dangerousContent}
      </form>
    ) : (
      dangerousContent
    );

  return (
    <Modal
      title={disclaimerTitle}
      footer={
        <DisclaimerModalButton
          disclaimerId={disclaimerId}
          disclaimerIsAccepted={disclaimerIsAccepted}
          isDisabled={isDisabled}
          handleClose={handleClose}
          handleDisclaimerAcceptance={handleDisclaimerAcceptance}
          disclaimerFormId={disclaimerFormId}
        />
      }
      open={open}
      onClose={handleClose}
      ContentProps={modalContentStyling}
    >
      <DialogContent ref={contentRef} sx={contentStyling} onScroll={handleScroll}>
        {insecureDialogContent}
      </DialogContent>
    </Modal>
  );
};
