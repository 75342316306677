import { MouseEvent, ReactElement, useEffect, useRef } from 'react';

import { LoadingDockStatusEnum, LoadingDockTable, usePershingAdminContext } from '@halo-modules/admin';
import { PershingSelectors } from '@halo-stores/Pershing';
import { ElementSelector } from '@halodomination/halo-fe-common';
import { Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';

export const LoadingDockTableList = (): ReactElement => {
  const { actions, pageLoaded, statusFilter, tableConfiguration } = usePershingAdminContext();
  const { paginatingMap } = tableConfiguration;

  const activeRef = useRef<HTMLDivElement | null>(null);

  const calendarMap = useSelector(PershingSelectors.selectCalendarsForLoadingDock);
  const totals = useSelector(PershingSelectors.selectTotals);
  const filteredTotals = useSelector(PershingSelectors.selectFilteredTotals);

  const pendingCalendars = useSelector(PershingSelectors.selectPendingCalendars);
  const pendingFilterTotal = useSelector(PershingSelectors.selectPendingFilteredTotal);
  const pendingTotalCount = useSelector(PershingSelectors.selectPendingTotal);

  const selectedIndex = [
    LoadingDockStatusEnum.active,
    LoadingDockStatusEnum.available,
    LoadingDockStatusEnum.pending,
    LoadingDockStatusEnum.archived,
  ].findIndex((status) => status === statusFilter);

  const isFilteredTotalsDefined = filteredTotals !== null && filteredTotals !== undefined;
  const isPendingFilterTotalDefined = pendingFilterTotal !== null && pendingFilterTotal !== undefined;

  const availableTotal = isFilteredTotalsDefined ? filteredTotals.available : totals.available;
  const archivedTotal = isFilteredTotalsDefined ? filteredTotals.archived : totals.archived;
  const pendingTotal = isPendingFilterTotalDefined ? pendingFilterTotal : pendingTotalCount;
  const hasContent = availableTotal || archivedTotal || pendingTotal;

  const addNewButtonStyling = !hasContent ? { marginBottom: 2 } : undefined;

  const handleToggleUploadModal = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    actions?.onToggleUploadModal?.();
  };

  const addNewButton = (
    <Button
      sx={addNewButtonStyling}
      type="button"
      variant="contained"
      color="primary"
      onClick={handleToggleUploadModal}
    >
      + Add New
    </Button>
  );

  useEffect(() => {
    const shouldScrollToTop = pageLoaded && !paginatingMap.available && statusFilter === LoadingDockStatusEnum.active;
    if (shouldScrollToTop) activeRef.current?.scrollIntoView();
  }, [paginatingMap.available]);

  return (
    <Box ref={activeRef}>
      <ElementSelector selected={selectedIndex}>
        <>
          <LoadingDockTable
            calendars={calendarMap.available}
            label="Available Offerings"
            status={LoadingDockStatusEnum.available}
            tableAction={addNewButton}
            total={availableTotal}
          />
          <LoadingDockTable
            calendars={pendingCalendars}
            label="Pending Offerings"
            navigateOnCusipClick={false}
            status={LoadingDockStatusEnum.pending}
            total={pendingTotal}
          />
        </>
        <LoadingDockTable
          calendars={calendarMap.available}
          label="Available Offerings"
          status={LoadingDockStatusEnum.available}
          tableAction={addNewButton}
          total={availableTotal}
        />
        <LoadingDockTable
          calendars={pendingCalendars}
          label="Pending Offerings"
          navigateOnCusipClick={false}
          status={LoadingDockStatusEnum.pending}
          total={pendingTotal}
        />
        <LoadingDockTable
          calendars={calendarMap.archived}
          label="Archived Offerings"
          status={LoadingDockStatusEnum.archived}
          total={archivedTotal}
        />
      </ElementSelector>
    </Box>
  );
};
