import { getClientConfiguration, request } from '@halo-data-sources/clients';
import { ApiGetRFQResponse } from '@halo-data-sources/models';

const REQUEST_FOR_QUOTES_PATH = '/issuer/v1/rfq';

export const getRfq = async (id: number): Promise<ApiGetRFQResponse> => {
  const clientConfiguration = await getClientConfiguration(REQUEST_FOR_QUOTES_PATH);

  const response = await request<ApiGetRFQResponse>(`${clientConfiguration.basePath}/${id}`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};
