export enum CallEventCalledStatus {
  CALLED = 'Called',
  NOT_CALLED = 'Not Called',
  PENDING = 'Pending',
}

export enum CouponEventPaidStatus {
  PAID = 'Paid',
  PENDING = 'Pending',
  MISSED = 'Missed',
}
