import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiHistoricRollingReturnsModel,
  ApiHistoricalQuotesResponseModel,
  ApiMostRecentQuotesResponseModel,
  ApiNoteQuotePricersModel,
  ApiQuoteModel,
} from '@halo-data-sources/models';

const QUOTES_PATH = '/issuer/v1/quotes';
const PRE_TRADE_PATH = '/pretrade/quotes';
const PRE_TRADE_NOTE_PATH = '/pretrade/notes';

export const postPricerSetForUser = async (noteId?: number, live?: boolean): Promise<ApiNoteQuotePricersModel> => {
  const clientConfiguration = await getClientConfiguration(QUOTES_PATH);

  return await request<ApiNoteQuotePricersModel>(`${clientConfiguration.basePath}/pricers`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      note: noteId,
      live: live,
    }),
  });
};

export const getMostRecentQuotes = async (noteIds: Array<number>): Promise<ApiMostRecentQuotesResponseModel> => {
  const clientConfiguration = await getClientConfiguration(PRE_TRADE_NOTE_PATH);

  return await request<ApiMostRecentQuotesResponseModel>(`${clientConfiguration.basePath}/recent-quotes`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      note_ids: noteIds,
    }),
  });
};

export const updateQuote = async (id: number, value: number): Promise<ApiMostRecentQuotesResponseModel> => {
  const clientConfiguration = await getClientConfiguration(PRE_TRADE_PATH);

  return await request<ApiMostRecentQuotesResponseModel>(`${clientConfiguration.basePath}/${id}`, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      value,
    }),
  });
};

export const getHistoricalQuotes = async (noteId: number): Promise<ApiHistoricalQuotesResponseModel> => {
  const clientConfiguration = await getClientConfiguration(PRE_TRADE_NOTE_PATH);

  return await request<ApiHistoricalQuotesResponseModel>(
    `${clientConfiguration.basePath}/${noteId}/historical-quotes`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );
};

export const getAllQuotesByNoteId = async (noteId: number): Promise<Array<ApiQuoteModel>> => {
  const clientConfiguration = await getClientConfiguration(QUOTES_PATH);

  const queryStringParameters = new URLSearchParams({ note_id: noteId.toString() });

  return await request<Array<ApiQuoteModel>>(
    `${clientConfiguration.basePath}/viewable?${queryStringParameters.toString()}`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );
};

export const getHistoricRollingReturns = async (params: string): Promise<ApiHistoricRollingReturnsModel> => {
  const clientConfiguration = await getClientConfiguration(QUOTES_PATH);

  return await request<ApiHistoricRollingReturnsModel>(`${clientConfiguration.basePath}/rollingret?${params}`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });
};
