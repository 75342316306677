import { ReactNode } from 'react';

import type { FormBuilderField } from '@halo-common/components';
import { getRenderFunction } from '@halo-common/components';
import omit from 'lodash/omit';
import { Controller } from 'react-hook-form';

export const renderField = (field: FormBuilderField): ReactNode => {
  if ('content' in field) return field.content;

  const render = field.render || getRenderFunction(field.type);

  if (!render) return null;

  const props = omit(field, ['render', 'validation', 'options']);

  return (
    <Controller
      defaultValue=""
      key={field.name}
      name={field.name}
      render={(controller) => render({ ...field, ...controller, props })}
    />
  );
};
