import { SortModelDirectionEnum } from '@halo-common/enums';
import { NotificationFilters } from '@halo-data-sources/models';
import { atom } from 'jotai';
import { DateTime } from 'luxon';

const DEFAULT_PAGE_LENGTH = 10;

export const DEFAULT_FILTERS: NotificationFilters = {
  startDate: DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd'),
  endDate: DateTime.now().toFormat('yyyy-LL-dd'),
  pageLength: DEFAULT_PAGE_LENGTH,
  sortDirection: SortModelDirectionEnum.desc,
  notificationTypes: [],
  onlyUnread: false,
};

const _notificationFiltersAtom = atom(DEFAULT_FILTERS);

export const notificationFiltersAtom = atom(
  (get) => {
    const filters = get(_notificationFiltersAtom);

    return filters;
  },
  (_, set, payload) => {
    if (!payload) {
      set(_notificationFiltersAtom, DEFAULT_FILTERS);
    } else if (typeof payload === 'object') {
      set(_notificationFiltersAtom, (prev) => ({ ...prev, ...payload }));
    }
  },
);
