import { CalendarModel } from '@halo-common/models';
import { getPortfolioLifecycleCalendar } from '@halo-data-sources/clients';
import { ApiCalendarsMapper } from '@halo-data-sources/mappers';
import { PortfolioLifecycleQueryKeyFactory } from '@halo-data-sources/queries';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

const getLifecycleEventCalendarQueryFn = async (termsheetId?: number) => {
  if (!termsheetId) return null;

  const result = await getPortfolioLifecycleCalendar(termsheetId);

  const calendar = ApiCalendarsMapper.toCalendarModel(result.calendar);

  return calendar;
};

export const useLifecycleEventCalendarQuery = (termsheetId?: number): UseQueryResult<CalendarModel | null, Error> =>
  useQuery<CalendarModel | null, Error>({
    queryKey: PortfolioLifecycleQueryKeyFactory.calendar(termsheetId),
    queryFn: () => getLifecycleEventCalendarQueryFn(termsheetId),
  });
