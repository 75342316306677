import { OnboardingDuck } from './OnboardingDuck';
import * as selectors from './OnboardingSelectors';
import * as thunks from './OnboardingThunks';

export const OnboardingSelectors = selectors;

export const OnboardingActions = {
  ...OnboardingDuck.actions,
  ...thunks,
};

export const OnboardingDuckName = OnboardingDuck.name;

export const OnboardingReducer = OnboardingDuck.reducer;

export {
  OnboardingStatusEnum,
  OnboardingFamiliarityEnum,
  OnboardingGoalEnum,
  OnboardingCustodyEnum,
} from './OnboardingModel';
export type {
  OnboardingSliceState,
  OnboardingState,
  OnboardingFamiliarity,
  OnboardingCustody,
} from './OnboardingModel';
