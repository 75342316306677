import { AuctionBuyerModel } from '@halo-common/models';
import { fetchBuyerAuction } from '@halo-data-sources/clients';
import { ApiAuctionMapper } from '@halo-data-sources/mappers';
import { AuctionQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getBuyerAuctionFn = async (id?: string) => {
  if (!id) return null;
  const response = await fetchBuyerAuction(id);
  return ApiAuctionMapper.toBuyerAuctionModel(response.auction);
};

export const useBuyerAuctionQuery = (id?: string, interval?: number): UseQueryResult<AuctionBuyerModel | null, Error> =>
  useQuery<AuctionBuyerModel | null, Error>({
    queryKey: AuctionQueryKeyFactory.buyerAuction(id),
    queryFn: () => getBuyerAuctionFn(id),
    refetchInterval: interval,
  });
