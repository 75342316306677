import { ReactElement } from 'react';

import {
  addPositionsAssetSubmittedAtom,
  addPositionSelectedAccountsAtom,
  addPositionToMultipleAccountModalAtom,
  assetIdSearchValueAtom,
} from '@halo-atoms/portfolio';
import { AssetIdLocationEnum } from '@halo-common/enums';
import { AddPositionToMultipleAccountsFormFields, addPositionToMultipleAccountsFormSchema } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { CreatePositionsRequest, useCreatePositionsMutation } from '@halo-data-sources/mutations';
import { usePortfolioAssetExistenceQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import {
  AddPositionToMultipleAccountsFooterBackButton,
  AddPositionToMultipleAccountsFooterContinueButton,
  AssetSearchField,
  ChooseAccountsModalLayout,
  ChooseAssetAlerts,
  SuccessAddPositionsToAccountsModalLayout,
  useAssetIdListText,
} from '@halo-modules/app';
import { ElementSelector, Modal, Stack } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';

const footerSx = {
  width: '100%',
};

export const AddPositionToMultipleAccountsModal = (): ReactElement => {
  const methods = useForm<AddPositionToMultipleAccountsFormFields>({
    mode: 'onSubmit',
    resolver: yupResolver<AddPositionToMultipleAccountsFormFields>(addPositionToMultipleAccountsFormSchema),
  });

  const [assetIdValue, setAssetIdValue] = useAtom(assetIdSearchValueAtom);
  const assetIds = assetIdValue ? [assetIdValue] : undefined;

  const setAddPositionAssetSubmitted = useSetAtom(addPositionsAssetSubmittedAtom);
  const addPositionSelectedAccounts = useAtomValue(addPositionSelectedAccountsAtom);
  const [addPositionToMultipleAccount, setPositionToMultipleAccount] = useAtom(addPositionToMultipleAccountModalAtom);
  const { selectedAssetAtom, modalStepMap, open } = addPositionToMultipleAccount;
  const { handleResetAtom, currentPageAtom, handleNextAtom } = modalStepMap;

  const { data: userInfo } = useUserInfoQuery();
  const { data: assets } = usePortfolioAssetExistenceQuery(assetIds);

  const assetList = assets ?? [];

  const handleReset = useSetAtom(handleResetAtom);
  const handleNext = useSetAtom(handleNextAtom);
  const step = useAtomValue(currentPageAtom);

  const {
    data: transactions,
    mutate: createPositions,
    isPending: isCreatePositionLoading,
    isSuccess: isCreatePositionSuccess,
  } = useCreatePositionsMutation(handleNext);

  const enhancedTransactions = transactions?.map((transaction) => ({
    ...transaction,
    assetId: assetList[0]?.assetId,
    assetIdType: assetList[0]?.assetIdType,
  }));

  const whiteLabel = userInfo?.whiteLabel;
  const assetIdentifiers = whiteLabel?.assetIdentifiers;
  const assetIdTypes = useAssetIdListText(assetIdentifiers);
  const assetId = selectedAssetAtom?.id?.toString();
  const assetIdText = assetId ?? '';
  const isEdgarAsset = assetList[0]?.location === AssetIdLocationEnum.edgar;

  const stepOneTitle = translations.portfolio.addPositionToAccountsModal.stepOneTitle;
  const stepTwoTitle = translations.portfolio.addPositionToAccountsModal.stepTwoTitle;

  const stepOneLocalization = { title: { dynamicContent: { assetIds: assetIdTypes } } };
  const stepTwoLocalization = { title: { dynamicContent: { assetId: assetIdText } } };

  const stepThreeSubtitle = isEdgarAsset
    ? translations.portfolio.addPositionToAccountsModal.stepThreeSubtitle
    : undefined;

  const modalPropList = [
    {
      icon: 'search',
      title: stepOneTitle,
      subtitle: translations.portfolio.addPositionToAccountsModal.stepOneSubtitle,
      Localization: stepOneLocalization,
    },
    {
      icon: 'exchange',
      title: stepTwoTitle,
      Localization: stepTwoLocalization,
    },
    {
      icon: 'check',
      title: translations.portfolio.addPositionToAccountsModal.stepThreeTitle,
      subtitle: stepThreeSubtitle,
    },
  ];

  const handleSubmit = (createPositionsRequest: CreatePositionsRequest) => {
    createPositions({ ...createPositionsRequest });
  };

  const { reset } = methods;

  const handleModalClose = () => {
    reset();
    setAssetIdValue(null);
    handleReset();
    setAddPositionAssetSubmitted(false);
    setPositionToMultipleAccount();
  };

  const modalFooter = (
    <Stack direction="row" justify="space-between" alignItems="center" sx={footerSx}>
      <AddPositionToMultipleAccountsFooterBackButton />
      <AddPositionToMultipleAccountsFooterContinueButton
        onSubmit={handleSubmit}
        isLoading={isCreatePositionLoading}
        isSuccess={isCreatePositionSuccess}
      />
    </Stack>
  );

  return (
    <FormProvider {...methods}>
      <form>
        <Modal
          {...modalPropList[step]}
          overline={translations.portfolio.addPositionToAccountsModal.overline}
          open={open}
          onClose={handleModalClose}
          footer={modalFooter}
        >
          <ElementSelector selected={step}>
            <Stack direction="column" spacing={3}>
              <ChooseAssetAlerts />
              <AssetSearchField />
            </Stack>
            <ChooseAccountsModalLayout />
            <SuccessAddPositionsToAccountsModalLayout
              transactions={enhancedTransactions}
              accounts={addPositionSelectedAccounts}
            />
          </ElementSelector>
        </Modal>
      </form>
    </FormProvider>
  );
};
