import { portfolioAccountManagerAtom } from '@halo-atoms/portfolio';
import { AccountModel, HouseholdModel } from '@halo-common/models';
import { createHousehold, postAccount } from '@halo-data-sources/clients';
import { HttpError } from '@halo-data-sources/errors';
import { ApiAccountMapper } from '@halo-data-sources/mappers';
import { AccountQueryKeyFactory } from '@halo-data-sources/queries';
import { AccountDataSwitchKeyFactory } from '@halo-data-sources/switches';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

export type useAddAccountToNewHouseholdPayload = {
  ownerId?: number;
  accountName: string;
  accountNumber: string;
  custodianId: number;
  householdName?: string;
};

export type useAddAccountToNewHouseholdResult = {
  account: AccountModel;
  household: HouseholdModel;
};

export type useAddAccountToNewHouseholdMutationPayload = () => void;

const addAccountToNewHouseholdFn = async (payload?: useAddAccountToNewHouseholdPayload) => {
  if (!payload) return null;

  const householdName = payload.householdName ?? '';
  const householdResult = await createHousehold(householdName);

  const newHousehold = ApiAccountMapper.toHouseholdModel(householdResult.advisee);

  const updatedPayload = { ...payload, ownerId: newHousehold.id };
  const accountResult = await postAccount(updatedPayload);

  const newAccount = accountResult?.accounts?.[0];

  return { account: ApiAccountMapper.toAccountModel(newAccount), household: newHousehold };
};

export const useAddAccountToNewHouseholdMutation = (
  onSuccess?: useAddAccountToNewHouseholdMutationPayload,
): UseMutationResult<
  useAddAccountToNewHouseholdResult | null,
  HttpError,
  useAddAccountToNewHouseholdPayload | undefined
> => {
  const queryClient = useQueryClient();

  const { query } = useAtomValue(portfolioAccountManagerAtom);

  return useMutation<
    useAddAccountToNewHouseholdResult | null,
    HttpError,
    useAddAccountToNewHouseholdPayload | undefined
  >({
    mutationFn: addAccountToNewHouseholdFn,
    onSuccess: async (data) => {
      if (!data) return undefined;

      const accountKey = AccountDataSwitchKeyFactory.infinite();
      await queryClient.refetchQueries({ queryKey: accountKey });

      const pageAccountKey = AccountQueryKeyFactory.paged(query);
      await queryClient.refetchQueries({ queryKey: pageAccountKey });

      const householdKey = AccountQueryKeyFactory.households();
      queryClient.setQueryData<Array<HouseholdModel>>(householdKey, (prev = []) => [...prev, data.household]);

      onSuccess?.();
    },
  });
};
