import { ReactElement, ReactNode } from 'react';

import { useBreakpointView } from '@halo-common/hooks';
import { ProductDetailModalLayoutContent } from '@halo-common/layouts';
import { Stack, TabsProps } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, Paper } from '@mui/material';

const borderTopSx = { borderTop: (theme: HaloTheme) => `1px solid ${theme.palette.grey['300']}` };

export type ProductDetailModalLayoutProps = {
  children: ReactNode;
  slots?: { header: ReactNode; footer?: ReactNode; panel: ReactNode };
  tabs: TabsProps['tabs'];
  TabProps?: Partial<TabsProps>;
};

export const ProductDetailModalLayout = ({
  children,
  slots,
  tabs,
  TabProps,
}: ProductDetailModalLayoutProps): ReactElement => {
  const isTabletView = useBreakpointView('md', 'up');

  const mobileWrapperSx = !isTabletView ? borderTopSx : null;

  const productDetailPanelSx = {
    backgroundColor: 'background.default',
    borderRadius: '0 0 0 8px',
    borderRight: (theme: HaloTheme) => `1px solid ${theme.palette.grey['300']}`,
    borderTop: (theme: HaloTheme) => `1px solid ${theme.palette.grey['300']}`,
    height: '100%',
    width: '100%',
    pb: 2,
    pt: 0,
    px: 2,
  };

  const columnOneBasis = 320;
  const columnTwoBasis = 1024;
  const totalWidth = columnOneBasis + columnTwoBasis;
  const columnOneMaxWidth = isTabletView ? (columnOneBasis / totalWidth) * 100 : 100;
  const columnTwoMaxWidth = isTabletView ? (columnTwoBasis / totalWidth) * 100 : 100;

  const sectionSizing = [
    { pt: 4.75, flex: `1 1 ${columnOneBasis}px`, maxWidth: `${columnOneMaxWidth}%` },
    { flex: `1 1 ${columnTwoBasis}px`, maxWidth: `${columnTwoMaxWidth}%` },
  ];

  const header = slots?.header ?? null;
  const footer = slots?.footer ?? null;
  const panel = slots?.panel ?? null;

  const sidePanelContent = isTabletView ? <Box sx={productDetailPanelSx}>{panel}</Box> : null;

  return (
    <Paper>
      <Stack direction="column">
        {header}
        <Stack direction="row" sx={mobileWrapperSx} elementStyling={sectionSizing}>
          {sidePanelContent}
          <ProductDetailModalLayoutContent tabs={tabs} footer={footer} TabProps={TabProps}>
            {children}
          </ProductDetailModalLayoutContent>
        </Stack>
      </Stack>
    </Paper>
  );
};
