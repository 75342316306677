import { HaloAppState } from '@halo-stores/types';
import { createSelector } from '@reduxjs/toolkit';

export const selectStatus = createSelector(
  ({ Calendar }: HaloAppState) => Calendar.status,
  (status) => status,
);

export const selectError = createSelector(
  ({ Calendar }: HaloAppState) => Calendar.error,
  (error) => error,
);

export const selectCalendarPageIssuers = createSelector(
  ({ Calendar }: HaloAppState) => Calendar.issuers,
  (issuers) => issuers,
);

export const selectAdminCalendarPages = createSelector(
  ({ Calendar }: HaloAppState) => Calendar.adminCalendarPages,
  (adminCalendarPages) => adminCalendarPages,
);

export const selectParentChildPages = createSelector(
  ({ Calendar }: HaloAppState) => Calendar.parentChildPages,
  (parentChildPages) => parentChildPages,
);

export const selectCalendar = createSelector(
  ({ Calendar }: HaloAppState) => Calendar.selectedCalendar,
  (calendar) => calendar,
);

export const selectAllocation = createSelector(
  ({ Calendar }: HaloAppState) => Calendar.selectedAllocation,
  (selectedAllocation) => selectedAllocation,
);

export const selectAllocations = createSelector(
  ({ Calendar }: HaloAppState) => Calendar.allocations,
  (allocations) => allocations,
);
