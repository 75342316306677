import { ReactElement } from 'react';

import type { CurrencyModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { PortfolioPositionsCard } from '@halo-modules/app';
import { BrandIcon, Iconography, mapNumberToLocalCurrency } from '@halodomination/halo-fe-common';
import { BoxProps, Typography } from '@mui/material';

const ContainerProps = { component: 'span' } as Partial<BoxProps>;

export interface PortfolioPositionsValueCardProps {
  marketValue: number;
  marketChange: number;
  currency: CurrencyModel;
}

export const PortfolioPositionsValueCard = ({
  marketValue,
  marketChange,
  currency,
}: PortfolioPositionsValueCardProps): ReactElement | null => {
  const title = translations.dashboard.portfolioPositions.fullPortfolioValue;

  const roundedMarketValue = Math.round(marketValue);
  const roundedMarketChange = Math.round(marketChange);

  const currencyOptions = { currency: currency?.code, maximumFractionDigits: 0 };

  const marketValueText = mapNumberToLocalCurrency(roundedMarketValue, currencyOptions, currency?.symbol);
  const marketChangeCurrencyText = mapNumberToLocalCurrency(roundedMarketChange, currencyOptions, currency?.symbol);

  const isMarketChangePositive = marketChange >= 0;

  const marketChangeSx = {
    color: isMarketChangePositive ? 'common.charts.positive.main' : 'common.charts.negative.main',
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
  };

  const marketChangeTag =
    marketChange !== 0 ? (
      <Typography variant="body2" fontWeight={600} sx={marketChangeSx}>
        <Iconography
          ContainerProps={ContainerProps}
          iconName={isMarketChangePositive ? 'caret-up' : 'caret-down'}
          color="inherit"
        />
        {isMarketChangePositive ? '+' : null}
        {marketChangeCurrencyText}
      </Typography>
    ) : null;

  const leftContent = (
    <>
      <Typography variant="h3" mb={2} fontWeight={600}>
        {marketValueText}
      </Typography>
      {marketChangeTag}
    </>
  );

  const rightContent = <BrandIcon variant="trackEvents" color="primary" height={100} width={124} />;

  return <PortfolioPositionsCard title={title} leftContent={leftContent} rightContent={rightContent} />;
};
