import { ReactElement, useMemo } from 'react';

import { dynamicFiltersClearAtom, dynamicSideBarManagerAtom, sideFiltersLoadedAtom } from '@halo-atoms/common';
import { ActionButton } from '@halo-common/components';
import { DynamicTypeFiltersSideBarFiltersEnum } from '@halo-common/enums';
import {
  CalendarAssetTypeFilters,
  CalendarIssuerFilters,
  CalendarProductTypeFilters,
  CalendarTypeFilters,
  CurrencyFilters,
  IssuerFilters,
  MyOfferingFilters,
  NonCallPeriodFilters,
  NoteTypeFilters,
  PrincipalProtectionFilters,
  ProductFeaturesFilters,
  SettlementTypeFilters,
  SideBarFilterChips,
  TermsheetsProductTypeFilters,
  TermSliderFilters,
  UnderlyingCountFilters,
} from '@halo-common/layouts';
import type { UserSideBarPreferenceKey } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';

import { useLoadInitialSidebarPanelState } from './useLoadInitialSidebarPanelState';
import { useSaveSideBarFilters } from './useSaveSideBarFilters';

export const DEFAULT_DYNAMIC_FILTER_SIDE_BAR_WIDTH = 248;

const calendarsTypeFilterSx = {
  minWidth: DEFAULT_DYNAMIC_FILTER_SIDE_BAR_WIDTH,
  minHeight: 'fit-content',
};

export const DynamicTypeFiltersSideBarTitlesDict = {
  calendarAssetType: translations.dynamicFilters.common.toggleBaskets,
  calendarIssuer: translations.common.issuer,
  calendarProductType: translations.common.productType,
  calendarType: translations.common.calendarType,
  currency: translations.common.currency,
  issuer: translations.common.issuer,
  myOffering: translations.dynamicFilters.common.myOfferings,
  nonCallPeriod: translations.common.nonCallPeriod,
  noteType: translations.common.noteType,
  principalProtection: translations.common.principalProtection,
  productFeatures: translations.dynamicFilters.common.productFeaturesFilter,
  settlementType: translations.dynamicFilters.common.settlement,
  termsheetsProductType: translations.common.productType,
  termSlider: translations.common.term,
  underlyingCount: translations.dynamicFilters.common.basketsFilter,
};

export type DynamicTypeFiltersSideBarFilterValues = Partial<
  Record<DynamicTypeFiltersSideBarFiltersEnum, (string | number)[]>
>;

export type DynamicTypeFiltersSideBarRules = {
  nmeEnabled?: boolean | null;
  approvedAssetsOnly?: boolean | null;
};

export type DynamicTypeFiltersSideBarProps = {
  tag: UserSideBarPreferenceKey;
  filters: Array<DynamicTypeFiltersSideBarFiltersEnum>;
  filterValues?: DynamicTypeFiltersSideBarFilterValues | null;
  rules?: DynamicTypeFiltersSideBarRules;
};

export const DynamicTypeFiltersSideBar = ({
  tag,
  filters = [],
  filterValues,
  rules,
}: DynamicTypeFiltersSideBarProps): ReactElement => {
  const { data: user } = useUserInfoQuery();

  const getSidePanelState = useAtomValue(dynamicSideBarManagerAtom);
  const onLoaded = useSetAtom(sideFiltersLoadedAtom);
  const onClear = useSetAtom(dynamicFiltersClearAtom);

  useLoadInitialSidebarPanelState(tag);

  const { isPending: loading, saveSidebarFilters } = useSaveSideBarFilters(tag, {
    onSuccess: (newPreferences) => {
      const updatedPreferences = newPreferences?.[tag];
      if (updatedPreferences) onLoaded({ tag, update: true, ...updatedPreferences });
    },
  });

  const sidePanelState = getSidePanelState(tag);
  const saveDisabled = !sidePanelState.filtersChanged;
  const isFiltered = sidePanelState.isFiltered;

  const handleClearFiltersClick = () => onClear({ tag, action: 'sidebar' });

  const handleSaveFilters = () => saveSidebarFilters();

  const sections = useMemo(
    () =>
      filters.map((filter) => {
        const SideBarFilterComponent = {
          calendarAssetType: CalendarAssetTypeFilters,
          calendarIssuer: CalendarIssuerFilters,
          calendarProductType: CalendarProductTypeFilters,
          calendarType: CalendarTypeFilters,
          currency: CurrencyFilters,
          issuer: IssuerFilters,
          myOffering: MyOfferingFilters,
          nonCallPeriod: NonCallPeriodFilters,
          noteType: NoteTypeFilters,
          principalProtection: PrincipalProtectionFilters,
          productFeatures: ProductFeaturesFilters,
          settlementType: SettlementTypeFilters,
          termsheetsProductType: TermsheetsProductTypeFilters,
          termSlider: TermSliderFilters,
          underlyingCount: UnderlyingCountFilters,
        }[filter];

        if (!SideBarFilterComponent) return null;

        const usesApprovalProp =
          filter === DynamicTypeFiltersSideBarFiltersEnum.calendarIssuer ||
          filter === DynamicTypeFiltersSideBarFiltersEnum.calendarProductType;

        const approved = usesApprovalProp ? rules?.approvedAssetsOnly : undefined;

        return (
          <SideBarFilterComponent key={filter} tag={tag} filterValues={filterValues?.[filter]} approved={approved} />
        );
      }),
    [filters, tag, rules],
  );

  const saveFilters = user?.authenticated ? (
    <ActionButton loading={loading} onClick={handleSaveFilters} disabled={saveDisabled} variant="outlined" size="small">
      {translations.dynamicFilters.common.saveFilters}
    </ActionButton>
  ) : null;

  return (
    <Stack direction="column" spacing={1} sx={calendarsTypeFilterSx}>
      <LocalizedTypography variant="overline" color="text.secondary">
        {translations.dynamicFilters.common.filterOfferings}
      </LocalizedTypography>
      <Stack direction="row" spacing={1}>
        {saveFilters}
        <LocalizedButton onClick={handleClearFiltersClick} disabled={!isFiltered} variant="outlined" size="small">
          {translations.common.clearAll}
        </LocalizedButton>
      </Stack>
      <SideBarFilterChips tag={tag} sx={{ maxWidth: DEFAULT_DYNAMIC_FILTER_SIDE_BAR_WIDTH }} />
      {sections}
    </Stack>
  );
};
