import { ReactElement } from 'react';

import { CalendarPointList } from '@halo-common/components';
import { useBreakpointView } from '@halo-common/hooks';
import {
  ExecutionCalendarDetailModalDocumentTable,
  ExecutionFixedIncomeDetailModalCallTable,
  ExecutionFixedIncomeDetailModalCouponTable,
  ExecutionFixedIncomeDetailModalHeader,
  ProductDetailModal,
  V2PostTradeNoteDetailModalPanel,
} from '@halo-common/modals';
import { CalendarModel } from '@halo-common/models';
import { useUserInfoQuery } from '@halo-data-sources/queries';

const FROM_THE_ISSUER_TAB = 'From the Issuer';
const DETAILS_TAB = 'Details';
const COUPON_SUMMARY_TAB = 'Coupon Schedule';
const AUTOCALL_SUMMARY_TAB = 'Early Redemption';
const DOCUMENTS_TAB = 'Documents';

export type FixedIncomeDetailModalProps = {
  open: boolean;
  onClose: () => void;
  product?: CalendarModel | null;
  onSubmitIndication: () => void;
};

export const ExecutionFixedIncomeDetailModal = ({
  onClose,
  open,
  product,
  onSubmitIndication,
}: FixedIncomeDetailModalProps): ReactElement => {
  const { data } = useUserInfoQuery();

  const isTabletView = useBreakpointView('md', 'up');

  const showCalendarPoints = data?.calendarPage?.showCalendarPoints;
  const calendarPoints = product?.points ?? [];
  const showIssuerTab = Boolean(showCalendarPoints && calendarPoints.length);

  const isLoading = !product;

  const note = product?.noteV2;

  const couponEvents = note?.lifecycle.calendarCoupons;
  const callEvents = note?.lifecycle.calendarCalls;

  const showCouponTab = Boolean(couponEvents?.length);
  const showAutocallTab = Boolean(callEvents?.length);

  const tabs = [];
  if (showIssuerTab) tabs.push(FROM_THE_ISSUER_TAB);
  if (!isTabletView) tabs.push(DETAILS_TAB);
  if (showCouponTab) tabs.push(COUPON_SUMMARY_TAB);
  if (showAutocallTab) tabs.push(AUTOCALL_SUMMARY_TAB);
  tabs.push(DOCUMENTS_TAB);

  const panel = <V2PostTradeNoteDetailModalPanel product={product?.noteV2} loading={isLoading} />;
  const header = (
    <ExecutionFixedIncomeDetailModalHeader
      product={product}
      loading={isLoading}
      onClose={onClose}
      onSubmitIndication={onSubmitIndication}
    />
  );
  const slots = { panel, header };

  const issuerTab = showIssuerTab ? <CalendarPointList points={calendarPoints} loading={isLoading} /> : null;

  const detailsTabContents = !isTabletView ? panel : null;

  const couponSummaryTableContent = showCouponTab ? (
    <ExecutionFixedIncomeDetailModalCouponTable loading={isLoading} events={couponEvents} />
  ) : null;

  const callSummaryTableContent = showAutocallTab ? (
    <ExecutionFixedIncomeDetailModalCallTable loading={isLoading} events={callEvents} />
  ) : null;

  return (
    <ProductDetailModal open={open} onClose={onClose} tabs={tabs} slots={slots} className="wm-pdm-execution">
      {issuerTab}
      {detailsTabContents}
      {couponSummaryTableContent}
      {callSummaryTableContent}
      <ExecutionCalendarDetailModalDocumentTable product={product} loading={isLoading} />
    </ProductDetailModal>
  );
};
