export const SUITABILITY_TRANSLATIONS = {
  oneTwoYears: '1-2 years',
  threeFiveYears: '3-5 years',
  sixNineYears: '6-9 years',
  tenPlusYears: '10+ years',
  cCorp: 'C-Corporation (C-Corp)',
  sCorp: 'S-Corporation (S-Corp)',
  addAnotherTrader: 'Add Another Trader',
  addSecondaryContact: 'Add Secondary Contact',
  amlPolicies: 'Does the Firm have AML Policies & Procedures?',
  aptSuiteNo: 'Apt/Suite No.',
  authorizedSigner: 'Authorized Signer',
  authorizedPerson: 'Authorized Person {number}{subtitle}',
  bank: 'Bank',
  brokerDealer: 'Broker / Dealer',
  city: 'City',
  complaintsSanctionsWarnings:
    'Have there been any complaints, sanctions, warnings, fines against the Firm, or any of its related entities, in relation to the distribution or trading of any Structured Product, or any other Investment?',
  compliance: 'Compliance',
  complianceFunctions: 'Where are the Firm’s compliance functions?',
  completed: {
    title: 'Thank you for completing the Onboarding Form!',
    description:
      'The final documents have been sent to {authorizedSignerName} for review and signature. Once approved by Halo’s compliance, the Firm will gain full access to trading on Halo. In the meantime, you may explore the platform without trading capability.',
  },
  continueToHalo: 'Continue To Halo',
  country: 'Country',
  crd: 'CRD#',
  dateOfBirth: 'Date of Birth',
  defaultStepTitle: 'Get your Firm trade-ready by answering some questions.',
  delegateKycAmlChecks: 'Does the Firm delegate any KYC/AML checks on end investors to any affiliate or third party?',
  delegateSuitabilityAssessment:
    'Does the Firm delegate any suitability assessment and responsibilities to any affiliate or third party?',
  deliveryProcess: 'What type of Delivery process does the Firm use?',
  disablePaperConfirmations: 'Disable Paper Confirmations',
  disablePaperStatements: 'Disable Paper Statements',
  dvdRvp: 'DVP / RVP (Tradeaway)',
  emailAddress: 'Email Address',
  familyOffice: 'Family Office',
  federalRegulatedBank: 'Federal Regulated Bank',
  final: {
    authorizedSigner: 'Authorized Signer',
    hereIsWhereWeWillSendTheFinalDocuments: 'Here is where we will send the PDFs for signature via DocuSign:',
    noAuthorizedSignerFound: 'No Authorized Signer was found. Please return to the Authorized Persons step to add one.',
    principalContact: 'Principal Contact',
    reviewUnfinishedQuestions:
      'Review {count, plural, one {1 Unfinished Question} other {{count} Unfinished Questions}}',
    sendForSignatures: 'Send For Signatures',
    someQuestionsWereLeftUnanswered:
      'Some questions were left unanswered. The Authorized Signer will need to complete blank fields for approval.',
  },
  finalize: 'Finalize',
  finra: 'FINRA',
  firmDetails: 'Firm Details',
  firmExperienceInTrading:
    'How many years of experience in distributing or trading Structured Products does the Firm have?',
  formCompletedWithRvp: 'Was this form completed in conjunction with your Halo RVP?',
  fullLegalName: 'Full legal name of the Firm',
  fullName: 'Full Name',
  haloCanConfigureYourPlatform:
    'Halo can configure your Firm’s platform according to your particular processes and needs.',
  haloOnboardingSuitability: 'Firm Onboarding',
  hasFirmGoneByAnotherName: 'Has the Firm gone by another name?',
  howLong: 'How Long?',
  inhouseCompliance: 'In-house Compliance',
  intro: {
    disclaimer:
      'IMPORTANT: To help the government fight financial crime including the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify and record information that identifies each person who opens an account and record information about the beneficial owners of legal entity customers. When you open an account, you will be asked for your name, your address / principal place of business as well as your Taxpayer Identification Number. Additional information required for corporations, partnerships and other entities includes evidence of beneficial owners. Applications without such information will not be considered in good order. Halo reserves the right to deny an application if it is not in good order.',
    forwardRemainingQuestionsToAuthorizedPerson:
      'If you can’t answer everything, forward remaining questions to an authorized person in your Firm.',
    haloCanConfigureYourPlatform:
      'Halo can configure your Firm’s platform according to your particular processes and needs.',
    requiredToCompleteBeforeAllowedToTrade: 'Required to complete before your Firm is allowed to trade.',
    someQuestionsMayBePreFilled: 'Some questions may be pre-filled based on information already gathered.',
    title: 'While completion is not required immediately, it is highly recommended.',
  },
  isThisFirmRegulated: 'Is this Firm Regulated?',
  mailingAddress: 'Mailing Address',
  mailingAddressDifferent: 'Mailing address different than permanent?',
  none: 'None',
  optOutOfPaperStatements: 'Would you like to opt out of paper statements?',
  other: 'Other',
  otherImNotSure: ' Other/I’m Not Sure',
  outsourcedCompliance: 'Outsourced Compliance',
  partnership: 'Partnership',
  permanentAddress: 'Permanent Address',
  phoneNumber: 'Phone Number',
  phoneNumberMustBeExactly7Digits: 'Phone number must be at least 7 digits',
  pleaseElaborate: 'Please elaborate',
  pleaseElaborateOnYourOutsorcedCompliance: 'Please elaborate on your Outsourced Compliance',
  pleaseEnterAtLeastOneAuthorizedSigner:
    'Please enter at least one Authorized Signer who can sign legal documents on behalf of the firm.',
  pleaseExplain: 'Please explain',
  pleaseIncludeCorrespondingClearingInstructions: 'Please include corresponding clearing instructions',
  pleaseSpecify: 'Please specify',
  position: 'Position',
  previousLegalNames: 'Previous legal name(s) of the Firm',
  primaryContact: 'Primary Contact',
  primeBrokerage: 'Prime Brokerage',
  productApprovalProcess: 'Has the Firm adopted a product approval process with respect to Structured Products?',
  registeredInvestmentAdvisor: 'Registered Investment Advisor (RIA)',
  removeAuthorizedPerson: 'Remove Authorized Person',
  removeSecondaryContact: 'Remove Secondary Contact',
  reviewAndMakeChanges: 'Let’s look over the info provided one more time.',
  reviewProductPerformance:
    'Does the Firm review the performance of the product after sale, in terms of its ability to meet the needs of the target market?',
  reviewSubtitle: 'Review and make any changes before sending for a signature.',
  saveProgressAndGetRightToThePlatform: '(Save progress and get right to the platform)',
  secondaryContact: 'Secondary Contact',
  sec: 'SEC',
  selectAllThatApply: 'Select all that apply',
  sendForSignatures: 'Send For Signatures',
  skipForNow: 'Skip For Now',
  skipQuestionnaireForNow: 'Skip Questionnaire For Now',
  someQuestionsMayBePreFilled: 'Some questions may be pre-filled based on information already gathered.',
  state: 'State',
  stateRegulatedBank: 'State Regulated Bank',
  streetAddress: 'Street Address',
  taxIdOrSsn: 'Tax ID or SSN',
  tellUsMoreAboutYourFirm: 'Tell us more about your Firm',
  includeAtleastOneAuthorizedSigner:
    'The below shall serve as evidence of authority of the individual signing this Form on behalf of the Firm, and any other officers authorized to transact in the Account(s) established. Choose up to 4 here. If there are more than 4, reach out to your Sales Rep.',
  theseAreThePeopleWeWillContactWithAnyQuestionsOrIssues:
    'These are the people we will contact with any questions or issues.',
  thisPersonIsAuthorizedToSignLegalDocuments:
    'This person is authorized to sign legal documents on behalf of the Firm.',
  tradingDetails: 'Trading Details',
  trainingOfFinancialAdvisors:
    'Does the Firm ensure appropriate training of its financial advisors or affiliates to distribute Structured Products to end clients?',
  trust: 'Trust',
  unfinishedQuestions: '{count, plural, one {1 Unfinished Question} other {{count} Unfinished Questions}}',
  viaPhoneZoomEtc: 'Via Phone, Zoom, etc.',
  whatIsRegistrationNumber: 'What is the Registration Number? (CRD/SEC)',
  whatTypeOfEntityIsThisFirm: 'What type of Entity is this Firm?',
  whatTypeOfStructureIsThisFirm: 'What type of Structure is this Firm?',
  whoAreThePrincipalContactsAtTheFirm: 'Who are the Principal Contacts at the Firm?',
  whoIsTheRegulator: 'Who is the Regulator?',
  whoWillBeTheAuthorizedTraders: 'Who will be the Authorized Persons?',
  zipCode: 'Zip Code',
  zipCodeMustBeExactly5Digits: 'ZIP code must be exactly 5 digits',
  LLC: 'Limited Liability Company (LLC)',
  skipSuitabilityWarning: {
    title: 'Your firm will not be authorized to trade until the Onboarding Suitability form is complete.',
    allInformationWillNeedToBeSignedByAuthorizedSigner:
      'All information will need to be signed by an Authorized Signer at your Firm, and then reviewed by Halo for compliance. It is recommended to provide information as soon as possible to ensure no disruptions in your ability to trade.',
    areYouSureYouWishToSkipTheOnboardingFormForNow:
      'Are you sure you wish to skip the onboarding form for now? If you have unsaved information on this question it will be lost. You may return to unanswered questions at any point.',
  },
  concurrentEditWarning: {
    title: 'This form may be being edited by someone else in your Firm.',
    subtitle:
      'Halo has detected a recent edit from someone else. To ensure no data loss, take caution before proceeding by making sure no one else is editing the same questions as you.',
  },
  everythingYouNeedToStartTradingNotesAndMore: 'Everything you need to start trading Notes and more.',
  completeFirmSuitability: 'Complete Firm Onboarding',
  authorizedSignerReviewsAndSignsPaperwork: 'Authorized Signer at {firm} reviews & signs paperwork',
  haloReviewsPaperwork: 'Halo reviews paperwork',
  scheduleTimeIfNeedAssistance:
    'If you need assistance, please use the link below to schedule time with your Relationship Manager:',
  scheduleTime: 'Schedule Time',
  pleaseCompleteOnboardingSuitability: 'Please complete Firm Onboarding in order to submit a trade.',
  youMustCompleteOnboardingSuitabilityBeforeTrading:
    'You must complete Firm Onboarding before {firm} may begin trading.',
  authorizedSignerMustReviewAndSignPaperwork:
    'The Authorized Signer must review and sign paperwork before the Firm may begin trading.',
  complianceTeamIsReviewingFirmsPaper: 'Halo’s compliance team is currently reviewing your Firm’s paperwork.',
  useIsForDemoPurposeOnly: 'In the meantime, use of {feature} is for demo purposes only.',
};
