export const PROFILE_DOCUMENTS_TRANSLATIONS = {
  myFiles: 'My Files',
  myFilesSubtitle: 'Upload any other files, such as identity documents.',
  signedDocs: 'Signed Documents',
  signedDocsSubtitle: 'These are the documents you have signed',
  modalTitle: 'Upload new file',
  modalSubtitle: 'Add a description for the selected file',
  fileDescription: 'File Description',
  addNewFile: 'Add New File',
  noFiles: 'No files uploaded yet',
  noFilesSubtitle: 'Your uploaded files appear here.',
  fileType: 'File Type',
  custodyTitle: 'Custody Forms',
  custodySubtitle: 'Download, complete, and upload required custodial agreement forms',
  noCustodyForms: 'No documents to complete',
  noCustodyFormsSubtitle: 'Your custody forms will appear here when assigned by your relationship manager.',
  uploadFile: 'Select a file from your device to upload to {orgName}',
  uploadSuccess: 'New file successfully added',
  uploadError: 'File failed to upload',
  removeSuccess: 'Document deleted successfully',
  removeError: 'Document failed to delete',
  backToUpload: 'Back to Uploader',
  dragAndDrop: 'Drag and drop a file here',
  dragAndDropFileOr: 'Drag and drop a file or',
  chooseFromComputer: 'Choose from computer',
  replaceFromComputer: 'Replace from computer',
};
