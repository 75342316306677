/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReactElement, ReactNode, useEffect } from 'react';

import { REACT_ENV_TEST } from '@halo-common/constants';
import { useUserWebHooksQuery } from '@halo-data-sources/queries';

const REACT_ENV = process.env.NEXT_PUBLIC_REACT_ENV;
const WALK_ME_SCRIPT_ID = 'walk-me-main-script';

export type WalkMeProviderProps = {
  children: ReactNode;
};

export const WalkMeProvider = ({ children }: WalkMeProviderProps): ReactElement => {
  const { data: webhooks } = useUserWebHooksQuery();

  const hasHook = webhooks?.['react:walkme']?.reactFeatureEnabled;

  useEffect(() => {
    const injected = document.getElementById(WALK_ME_SCRIPT_ID);
    const injectWalkMeScript = hasHook && !injected;

    if (injectWalkMeScript) {
      const walkMeSource =
        REACT_ENV !== REACT_ENV_TEST
          ? 'https://cdn.walkme.com/users/8b337369838d4c659b74bc467c3e198b/walkme_8b337369838d4c659b74bc467c3e198b_https.js'
          : 'https://cdn.walkme.com/users/8b337369838d4c659b74bc467c3e198b/test/walkme_8b337369838d4c659b74bc467c3e198b_https.js';

      const walkMeScript = document.createElement('script');
      walkMeScript.id = WALK_ME_SCRIPT_ID;
      walkMeScript.type = 'text/javascript';
      walkMeScript.async = true;
      walkMeScript.src = walkMeSource;

      const firstScript = document.getElementsByTagName('script')[0];
      firstScript?.parentNode?.insertBefore(walkMeScript, firstScript);

      (window as any)._walkmeConfig = (window as any).parent._walkmeConfig;
      (window as any).jinja_env = (window as any).parent.jinja_env;
    }
  }, [hasHook]);

  return <>{children}</>;
};
