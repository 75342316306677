import { ReactElement } from 'react';

import { useModalState } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { Iconography, LocalizedButton, Modal } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useT } from '@transifex/react';

export const SKIP_SUITABILITY_FORM_WARNING_MODAL_NAME = 'skipSuitabilityFormWarningModal';

const skipButtonSx = { ml: 'auto' };

const suitability = translations.onboarding.suitability;

export const SkipSuitabilityFormWarningModal = (): ReactElement => {
  const translator = useT();
  const { open, setOpen } = useModalState(SKIP_SUITABILITY_FORM_WARNING_MODAL_NAME);

  const handleClose = () => setOpen(false);

  const returnToDashboard = () => navigateParentTo('/user/dashboard');

  const footer = (
    <Stack direction="row">
      <LocalizedButton
        onClick={handleClose}
        variant="text"
        size="large"
        startIcon={<Iconography iconName="arrow-left" />}
      >
        {translations.common.goBack}
      </LocalizedButton>
      <LocalizedButton variant="contained" size="large" color="primary" onClick={returnToDashboard} sx={skipButtonSx}>
        {suitability.skipForNow}
      </LocalizedButton>
    </Stack>
  );

  const subtitle = (
    <>
      {translator(suitability.skipSuitabilityWarning.allInformationWillNeedToBeSignedByAuthorizedSigner)}
      <br />
      <b>{translator(suitability.skipSuitabilityWarning.areYouSureYouWishToSkipTheOnboardingFormForNow)}</b>
    </>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={suitability.skipSuitabilityWarning.title}
      subtitle={subtitle}
      dismissible={false}
      footer={footer}
      size="small"
    />
  );
};
