import { ReactElement } from 'react';

import { CustodianModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { Iconography, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Box } from '@mui/material';
import { useT } from '@transifex/react';

type SMAAllocationCustodianWarningProps = {
  custodians: Array<CustodianModel>;
};

export const SMAAllocationCustodianWarning = ({ custodians }: SMAAllocationCustodianWarningProps): ReactElement => {
  const custodianNames = custodians.map((custodian) => custodian.name);

  const boxSx = {
    color: '#081A66',
    fontWeight: 'bold',
    backgroundColor: '#F5F5FA',
    p: 1,
    display: 'flex',
    alignItems: 'center',
  };

  const t = useT();

  const custodiansList = () => {
    const and = t(translations.common.and);
    switch (custodianNames.length) {
      case 1:
        return custodianNames[0];
      case 2:
        return `${custodianNames[0]} ${and} ${custodianNames[1]}`;
      default:
        return `${custodianNames.slice(0, -1).join(', ')}, ${and} ${custodianNames.at(-1)}`;
    }
  };

  return (
    <Box sx={boxSx}>
      <Iconography iconName="circle-info" sx={{ mr: 1 }} />
      <LocalizedTypography dynamicContent={{ custodians: custodiansList() }}>
        {translations.sma.custodianWarning}
      </LocalizedTypography>
    </Box>
  );
};
