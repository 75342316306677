import { AllocationModel, FixOrderModel } from '@halo-common/models';
import { getFixOrderAndAllocation } from '@halo-data-sources/clients';
import { ApiAllocationMapper, ApiFixMapper } from '@halo-data-sources/mappers';
import { FixQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export type FixOrderQueryResult = {
  fixOrder: FixOrderModel;
  allocation: AllocationModel;
};

const getFixOrderQueryFn = async (allocationId?: number) => {
  if (allocationId === undefined) return null;

  const result = await getFixOrderAndAllocation(allocationId);

  return {
    fixOrder: ApiFixMapper.toFixOrder(result.order),
    allocation: ApiAllocationMapper.toAllocationModel(result.allocation),
  };
};

export const useFixOrderQuery = (allocationId?: number): UseQueryResult<FixOrderQueryResult | null, Error> =>
  useQuery<FixOrderQueryResult | null, Error>({
    queryKey: FixQueryKeyFactory.order(allocationId),
    queryFn: () => getFixOrderQueryFn(allocationId),
    retry: false,
  });
