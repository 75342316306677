import { ReactElement } from 'react';

import { Stack } from '@halodomination/halo-fe-common';
import { Button, Typography } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid-pro';

import type { OpsApprovalFormTableRow } from '../types';

const tableFooterStyling = { backgroundColor: 'background.action', py: 1, px: 2 };
const deleteRowBtnStyling = { color: 'error.main' };

export type OpsApprovalModalTableFooterProps = {
  rows: Array<OpsApprovalFormTableRow>;
  rowsToDelete: Array<GridRowId>;
  onAdd: (rows: Array<OpsApprovalFormTableRow>) => void;
  onDelete: (rows: Array<OpsApprovalFormTableRow>) => void;
};

export const OpsApprovalModalTableFooter = ({
  rows,
  rowsToDelete,
  onDelete,
  onAdd,
}: OpsApprovalModalTableFooterProps): ReactElement => {
  const handleAddRow = () => {
    const updatedRows = [{ id: Math.random().toString(), name: '', value: '' }, ...rows];
    onAdd(updatedRows);
  };

  const handleDeleteRow = () => {
    const updatedRows = rows.filter((row) => !rowsToDelete.includes(row.id));
    onDelete(updatedRows);
  };

  return (
    <Stack sx={tableFooterStyling} direction="row" alignItems="center" justify="space-between">
      <Typography variant="caption">Double click on a field to edit.</Typography>
      <Stack direction="row" spacing={2}>
        {rowsToDelete.length ? (
          <Button sx={deleteRowBtnStyling} variant="text" type="button" onClick={handleDeleteRow}>
            Remove Selected
          </Button>
        ) : null}
        <Button color="primary" variant="outlined" type="button" onClick={handleAddRow}>
          Add Row
        </Button>
      </Stack>
    </Stack>
  );
};
