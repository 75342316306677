import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiAddRemoveCalendarsResponseModel,
  ApiAllocationModel,
  ApiCalendarExpirationOverrideRequestModel,
  ApiCalendarExpirationOverrideResponseModel,
  ApiCalendarResponseModel,
  ApiCalendarSortingOptionsModel,
  ApiCreateCalendarAllocationRequestModel,
  ApiCreateCalendarAllocationResponseModel,
  ApiFixResponseModel,
  ApiGetBatchCalendarPageResponseModel,
  ApiGetCalendarAllocationResponseModel,
  ApiGetCalendarDetailsRequestModel,
  ApiGetCalendarIssuerQueryResponseModel,
  ApiGetCalendarPageResponseModel,
  ApiGetCalendarQueryProductsResponseModel,
  ApiGetCalendarResponse,
  ApiGetCalendarsCsvResponse,
  ApiGetCalendarsNameResponse,
  ApiGetCalendarsRequestModel,
  ApiGetCalendarsResponse,
  ApiGetCalendarsSearchAssetRequestModel,
  ApiGetCalendarsSearchAssetResponseModel,
  ApiGetCalendarsV2Response,
  ApiGetChildCalendarPageIdsResponse,
  ApiPostCalendarEmailSubscribeResponseModel,
  ApiPostCalendarFavoriteResponseModel,
  ApiPostCalendarPageQueryParams,
  ApiPutAddRemoveQueryParams,
  ApiQueryRequestModel,
  ApiSubmitCalendarAllocationResponseModel,
  ApiUpdateAdminCalendarPriceRequestModel,
  ApiUpdateAdminCalendarPriceResponseModel,
} from '@halo-data-sources/models';
import { GetCalendarTermsheetDownloadPayloadModel } from '@halo-data-sources/queries';
import { ApiUpdateCalendarAllocationThunkResult } from '@halo-stores/Calendar';

const EXEC_CALENDARS_PATH = '/execution/calendar';
const EXEC_CALENDAR_ORDERS_PATH = '/execution/order/calendar';
const CALENDARS_PATH = '/issuer/v1/calendar';
const BUYER_CALENDARS_PATH = '/issuer/v1/buyer/calendar';

export const putCalendarOfferingDoc = async (file: File): Promise<void> => {
  const clientConfiguration = await getClientConfiguration(EXEC_CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/offering/upload/loading_dock`;

  const data = new FormData();
  data.append('file', file);
  data.append('_csrf_token', clientConfiguration.csrfToken as string);

  return await request<void>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    body: data,
  });
};

export const putAdminCalendarPrice = async (
  payload: ApiUpdateAdminCalendarPriceRequestModel,
): Promise<ApiUpdateAdminCalendarPriceResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_CALENDAR_ORDERS_PATH);

  const path = `${clientConfiguration.basePath}/admin/price`;

  const response = await request<ApiUpdateAdminCalendarPriceResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });

  return response;
};

export const getCalendars = async (payload: ApiQueryRequestModel): Promise<ApiCalendarResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/query`;

  const response = await request<ApiCalendarResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });

  return response;
};

export const getCalendarName = async (ids: Array<number>): Promise<ApiGetCalendarsNameResponse> => {
  const clientConfiguration = await getClientConfiguration(EXEC_CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/name`;

  const response = await request<ApiGetCalendarsNameResponse>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      calendar_ids: ids,
    }),
  });

  return response;
};

export const getCalendarOfferings = async (payload: ApiGetCalendarsRequestModel): Promise<ApiGetCalendarsResponse> => {
  const clientConfiguration = await getClientConfiguration(EXEC_CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/offering/query`;

  const response = await request<ApiGetCalendarsResponse>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });

  return response;
};

export const getCalendarOfferingsV2 = async (payload: ApiQueryRequestModel): Promise<ApiGetCalendarsV2Response> => {
  const clientConfiguration = await getClientConfiguration(EXEC_CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/offering/query-v2`;

  const response = await request<ApiGetCalendarsV2Response>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });

  return response;
};

export const updateCalendarExpirationDate = async (
  payload: ApiCalendarExpirationOverrideRequestModel,
): Promise<ApiCalendarExpirationOverrideResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/expiration`;

  const response = await request<ApiCalendarExpirationOverrideResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });

  return response;
};

export const getCalendar = async (id: string): Promise<ApiGetCalendarResponse> => {
  const clientConfiguration = await getClientConfiguration(EXEC_CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/${id}`;

  const response = await request<ApiGetCalendarResponse>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const postCalendarOrder = async (id: number): Promise<void> => {
  const clientConfiguration = await getClientConfiguration(CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/allocation/${id}/place`;

  const response = await request<void>(path, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({ _csrf_token: clientConfiguration.csrfToken }),
  });

  return response;
};

export const getChildCalendarPageIds = async (): Promise<ApiGetChildCalendarPageIdsResponse> => {
  const clientConfiguration = await getClientConfiguration(CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/page/note-ids`;

  const response = await request<ApiGetChildCalendarPageIdsResponse>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const putCalendarOrderApproval = async (
  id: number,
  approved: boolean,
  reason = '',
): Promise<ApiFixResponseModel> => {
  const clientConfiguration = await getClientConfiguration(CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/manager/allocation/${id}`;

  const response = await request<ApiFixResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      approved,
      reason,
    }),
  });

  return response;
};

export const getCalendarPageCSV = async (
  id?: number | null,
  route?: string,
  query?: string,
): Promise<ApiGetCalendarsCsvResponse> => {
  const clientConfiguration = await getClientConfiguration(CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/manager/${id}/csv/${route}${query}`;

  const response = await request<ApiGetCalendarsCsvResponse>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const getCalendarPage = async (id?: string | null, query?: string): Promise<ApiGetCalendarPageResponseModel> => {
  const clientConfiguration = await getClientConfiguration(CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/manager/${id}${query}`;

  const response = await request<ApiGetCalendarPageResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const postCalendarPage = async (
  id?: string | null,
  query?: ApiPostCalendarPageQueryParams | null,
  sorting?: ApiCalendarSortingOptionsModel,
): Promise<ApiGetCalendarPageResponseModel> => {
  const clientConfiguration = await getClientConfiguration(CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/manager/${id}`;

  const queryObject = query ?? {};

  const response = await request<ApiGetCalendarPageResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...queryObject,
      sort: sorting,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const getCalendarQueryProducts = async (
  payload: ApiGetCalendarDetailsRequestModel,
): Promise<ApiGetCalendarQueryProductsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/query/product`;

  const response = await request<ApiGetCalendarQueryProductsResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });

  return response;
};

export const getCalendarIssuer = async (
  payload: ApiGetCalendarDetailsRequestModel,
): Promise<ApiGetCalendarIssuerQueryResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/query/issuer`;

  const response = await request<ApiGetCalendarIssuerQueryResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });

  return response;
};

export const postBatchCalendarPage = async (
  id?: string | null,
  queries?: Array<ApiPostCalendarPageQueryParams | null>,
  sorting?: ApiCalendarSortingOptionsModel,
): Promise<ApiGetBatchCalendarPageResponseModel> => {
  const clientConfiguration = await getClientConfiguration(CALENDARS_PATH);

  const options = {
    ...clientConfiguration.requestInit,
    method: 'POST',
  };

  if (queries?.length) {
    const requests = queries?.map((query = {}) =>
      request<ApiGetCalendarPageResponseModel>(`${clientConfiguration.basePath}/manager/${id}`, {
        ...options,
        body: JSON.stringify({
          ...query,
          sort: sorting,
          _csrf_token: clientConfiguration.csrfToken,
        }),
      }),
    );

    return await Promise.all(requests);
  }

  const response = await request<ApiGetCalendarPageResponseModel>(`${clientConfiguration.basePath}/manager/${id}`, {
    ...options,
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      sort: sorting,
    }),
  });

  return [response];
};

export const putAddRemoveCalendars = async (
  id: number,
  addRemove: ApiPutAddRemoveQueryParams,
): Promise<ApiAddRemoveCalendarsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(CALENDARS_PATH);
  const path = `${clientConfiguration.basePath}/manager/${id}`;

  const response = await request<ApiAddRemoveCalendarsResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      add: addRemove.add ?? [],
      remove: addRemove.remove ?? [],
    }),
  });

  return response;
};

export const getCalendarAllocations = async (calendarId: string): Promise<ApiGetCalendarAllocationResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/${calendarId}/allocations`;

  const response = await request<ApiGetCalendarAllocationResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const postCalendarAllocationRequest = async (
  payload: ApiCreateCalendarAllocationRequestModel,
): Promise<ApiCreateCalendarAllocationResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/${payload.calendar_id}/allocations`;

  const response = await request<ApiCreateCalendarAllocationResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      page: payload.calendar_page_id,
      allocations: payload.allocations,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const submitCalendarAllocationRequest = async (
  calendarId: number,
  allocations: Array<ApiAllocationModel>,
): Promise<ApiSubmitCalendarAllocationResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/${calendarId}/allocations`;

  const response = await request<ApiSubmitCalendarAllocationResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      allocations: allocations,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const putCalendarAllocationRequest = async (
  id: string,
  status: string,
  amount?: number,
): Promise<ApiUpdateCalendarAllocationThunkResult> => {
  const clientConfiguration = await getClientConfiguration(EXEC_CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/allocation/${id}`;

  const response = await request<ApiUpdateCalendarAllocationThunkResult>(path, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      allocation: { status, amount },
    }),
  });

  return response;
};

export const getCalendarsTermsheetDownload = async (
  payload: GetCalendarTermsheetDownloadPayloadModel,
): Promise<string> => {
  const clientConfiguration = await getClientConfiguration(BUYER_CALENDARS_PATH);

  const query = new URLSearchParams({ 'content-disposition': payload.contentDisposition });

  const path = `${clientConfiguration.basePath}/download/${payload.calendarId}?${query}`;

  const response = await request<string>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const postCalendarFavorite = async (calendarId: number): Promise<ApiPostCalendarFavoriteResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/${calendarId}/favorite`;

  const response = await request<ApiPostCalendarFavoriteResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const deleteCalendarFavorite = async (calendarId: number): Promise<ApiPostCalendarFavoriteResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/${calendarId}/favorite`;

  const response = await request<ApiPostCalendarFavoriteResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'DELETE',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const getCalendarsSearchAsset = async (
  payload: ApiGetCalendarsSearchAssetRequestModel,
): Promise<ApiGetCalendarsSearchAssetResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/search/asset`;

  const response = await request<ApiGetCalendarsSearchAssetResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...payload,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const postCalendarEmailSubscribe = async (
  email: string,
): Promise<ApiPostCalendarEmailSubscribeResponseModel> => {
  const clientConfiguration = await getClientConfiguration(CALENDARS_PATH);

  const path = `${clientConfiguration.basePath}/subscribe`;

  const response = await request<ApiPostCalendarEmailSubscribeResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      email,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};
