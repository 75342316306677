export const RECOVER_PASSWORD_TRANSLATIONS = {
  recoverPassword: "Let's recover your password.",
  enterEmailAddress: 'Enter the Email Address you registered with to receive a reset link.',
  ssoError: 'Your account is only enabled through single sign on. Please visit your single sign on portal.',
  recoverPasswordError: 'Something went wrong. Please try again later.',
  contactUsMessage:
    "If you can't remember your Email, or are having problems and need assistance, we are here to help.",
  accountAssociate:
    "If there's an account associated with {email}, you should receive an email to reset your password momentarily.",
  awaitRegistration: 'You should receive an email to {email} with a link to set your account password momentarily.',
  checkEmail: 'Check your email',
  checkEmailName: 'Check your email, {name}',
  resendEmail: 'Resend Email',
  resentEmail: 'Email Re-Sent',
  notReceivedEmail: 'If you have still not received an email after a few minutes, we are here to help.',
  registrationApproval: 'Your registration requires approval.',
  registrationApprovalText: 'If approved, an email will be sent to {email} with a link to set your account password.',
};
