import { initialSideBarFilters } from '@halo-atoms/common';
import {
  CALENDAR_PREFERENCE_TAG,
  DASHBOARD_PREFERENCE_TAG,
  FIXED_INCOME_PREFERENCE_TAG,
  PRODUCT_APPROVAL_PREFERENCE_TAG,
  TERMSHEET_PREFERENCE_TAG,
} from '@halo-common/constants';
import {
  PRINCIPAL_PROTECTION_SLIDER_MAX_VALUE,
  PRINCIPAL_PROTECTION_SLIDER_MIN_VALUE,
  TERM_SLIDER_MAX_VALUE,
  TERM_SLIDER_MIN_VALUE,
  UNDERLYING_SLIDER_MAX_VALUE,
  UNDERLYING_SLIDER_MIN_VALUE,
} from '@halo-common/layouts';
import type {
  DashboardPreferencesModel,
  UserDynamicFiltersSideBarPreferences,
  UserPreferencesModel,
} from '@halo-common/models';
import type { ApiUserInfoResponseModel, ApiUserUiPreferencesModel } from '@halo-data-sources/models';

interface ApiUserPreferencesMapperModel {
  toUserDynamicFiltersSideBarPreferences: (
    model?: ApiUserUiPreferencesModel['content'],
  ) => UserDynamicFiltersSideBarPreferences | undefined;
  toUserDashboardPreferences: (model?: ApiUserUiPreferencesModel['content']) => DashboardPreferencesModel | undefined;
  toUserPreferences: (uiPreference?: ApiUserInfoResponseModel['user_ui_preferences']) => UserPreferencesModel;
}

export const ApiUserPreferencesMapper: ApiUserPreferencesMapperModel = {
  toUserDynamicFiltersSideBarPreferences: (model) => {
    if (!model) return undefined;

    let protectionAmount: UserDynamicFiltersSideBarPreferences['protectionAmount'] = {
      min: PRINCIPAL_PROTECTION_SLIDER_MIN_VALUE,
      max: PRINCIPAL_PROTECTION_SLIDER_MAX_VALUE,
    };

    if (model.protectionAmountMin) protectionAmount.min = model.protectionAmountMin as number;
    if (model.protectionAmountMax) protectionAmount.max = model.protectionAmountMax as number;
    if (!model.protectionAmountMin && !model.protectionAmountMax) protectionAmount = null;

    let term: UserDynamicFiltersSideBarPreferences['term'] = {
      min: TERM_SLIDER_MIN_VALUE,
      max: TERM_SLIDER_MAX_VALUE,
    };

    if (model.termMin) term.min = model.termMin as number;
    if (model.termMax) term.max = model.termMax as number;
    if (!model.termMin && !model.termMax) term = null;

    let nonCallPeriod: UserDynamicFiltersSideBarPreferences['nonCallPeriod'] = {
      min: UNDERLYING_SLIDER_MIN_VALUE,
      max: UNDERLYING_SLIDER_MAX_VALUE,
    };

    if (model.nonCallPeriodMin) nonCallPeriod.min = model.nonCallPeriodMin as number;
    if (model.nonCallPeriodMax) nonCallPeriod.max = model.nonCallPeriodMax as number;
    if (!model.nonCallPeriodMin && !model.nonCallPeriodMax) nonCallPeriod = null;

    let underlyingCount: UserDynamicFiltersSideBarPreferences['underlyingCount'] = {
      min: UNDERLYING_SLIDER_MIN_VALUE,
      max: UNDERLYING_SLIDER_MAX_VALUE,
    };

    if (model.underlyingCountMin) underlyingCount.min = model.underlyingCountMin as number;
    if (model.underlyingCountMax) underlyingCount.max = model.underlyingCountMax as number;
    if (!model.underlyingCountMin && !model.underlyingCountMax) underlyingCount = null;

    const checkboxes = Object.keys(initialSideBarFilters.checkboxes).reduce(
      (map, key) => ({ ...map, [key]: Boolean(model[key]) }),
      initialSideBarFilters.checkboxes,
    );

    const toggles = Object.keys(initialSideBarFilters.toggles).reduce(
      (map, key) => ({ ...map, [key]: Boolean(model[key]) }),
      initialSideBarFilters.toggles,
    );

    return {
      checkboxes,
      toggles,
      currencies: (model.currencies ?? []) as UserDynamicFiltersSideBarPreferences['currencies'],
      issuers: (model.issuers ?? []) as UserDynamicFiltersSideBarPreferences['issuers'],
      noteTypes: (model.noteTypes ?? []) as UserDynamicFiltersSideBarPreferences['noteTypes'],
      productTypes: (model.productTypes ?? []) as UserDynamicFiltersSideBarPreferences['productTypes'],
      nonCallPeriod,
      underlyingCount,
      term,
      protectionAmount,
    };
  },
  toUserDashboardPreferences: (model) => {
    if (!model) return undefined;

    const preferences: DashboardPreferencesModel = {};

    const watchlistId = model.watchlistId;
    const noteflixId = model.noteflixId;
    const widgetsVisibility = model.widgetsVisibility;
    const widgetsPosition = model.widgetsPosition;

    const hasWatchlistId = typeof watchlistId === 'string' || typeof watchlistId === 'number';
    if (hasWatchlistId) preferences.watchlistId = Number(watchlistId);

    const hasNoteflixId = typeof noteflixId === 'string' || typeof noteflixId === 'number';
    if (hasNoteflixId) preferences.noteflixId = Number(noteflixId);

    const updateWidgetVisibility = typeof widgetsVisibility === 'object' && !Array.isArray(widgetsVisibility);
    if (updateWidgetVisibility) {
      const visibility = Object.entries(widgetsVisibility).reduce<Record<string, boolean>>((map, [key, value]) => {
        const isValid = typeof value === 'boolean' || value === 'false' || value === 'true';
        if (isValid) map[key] = value === true || value === 'true';
        return map;
      }, {});

      preferences.widgetsVisibility = visibility;
    }

    const updateWidgetPosition = typeof widgetsPosition === 'object' && !Array.isArray(widgetsPosition);
    if (updateWidgetPosition) {
      const position = Object.entries(widgetsPosition).reduce<Record<string, number>>((map, [key, value]) => {
        const isValid = typeof value === 'string' || typeof value === 'number';
        if (isValid) map[key] = Number(value);
        return map;
      }, {});

      preferences.widgetsPosition = position;
    }

    return preferences;
  },
  toUserPreferences: (model) => {
    const preferences: UserPreferencesModel = {};

    if (!model) return preferences;

    const calendarPreferences = model.find((preference) => preference.tag === CALENDAR_PREFERENCE_TAG);
    const fixedIncomePreferences = model.find((preference) => preference.tag === FIXED_INCOME_PREFERENCE_TAG);
    const productApprovalPreferences = model.find((preference) => preference.tag === PRODUCT_APPROVAL_PREFERENCE_TAG);
    const termsheetPreferences = model.find((preference) => preference.tag === TERMSHEET_PREFERENCE_TAG);
    const dashboardPreferences = model.find((preference) => preference.tag === DASHBOARD_PREFERENCE_TAG);

    if (calendarPreferences) {
      preferences[CALENDAR_PREFERENCE_TAG] = ApiUserPreferencesMapper.toUserDynamicFiltersSideBarPreferences(
        calendarPreferences.content,
      );
    }

    if (fixedIncomePreferences) {
      preferences[FIXED_INCOME_PREFERENCE_TAG] = ApiUserPreferencesMapper.toUserDynamicFiltersSideBarPreferences(
        fixedIncomePreferences.content,
      );
    }

    if (productApprovalPreferences) {
      preferences[PRODUCT_APPROVAL_PREFERENCE_TAG] = ApiUserPreferencesMapper.toUserDynamicFiltersSideBarPreferences(
        productApprovalPreferences.content,
      );
    }

    if (termsheetPreferences) {
      preferences[TERMSHEET_PREFERENCE_TAG] = ApiUserPreferencesMapper.toUserDynamicFiltersSideBarPreferences(
        termsheetPreferences.content,
      );
    }

    if (dashboardPreferences?.content) {
      preferences[DASHBOARD_PREFERENCE_TAG] = ApiUserPreferencesMapper.toUserDashboardPreferences(
        dashboardPreferences.content,
      );
    }

    return preferences;
  },
};
