import { UserActivityModel } from '@halo-common/models';
import { ApiOrganizationMapper, ApiUserMapper } from '@halo-data-sources/mappers';
import { ApiUserActivityModel } from '@halo-data-sources/models';

export const ApiUserActivityMapper = {
  toUserActivity: (model: ApiUserActivityModel): UserActivityModel => {
    const receivedAtIso = model.received_at.split(' ').join('T');

    return {
      arguments: model.arguments,
      endpoint: model.endpoint,
      fullPath: model.full_path,
      ghostUser: model.ghost_user ? ApiUserMapper.toUser(model.ghost_user) : null,
      haloUser: ApiUserMapper.toUser(model.halo_user),
      id: model.id,
      method: model.method,
      model: model.model,
      modelId: model.model_id,
      organization: ApiOrganizationMapper.toOrganization(model.organization),
      receivedAt: receivedAtIso,
      verb: model.verb,
    };
  },
};
