import { ReactElement } from 'react';

import { useV2ProtectionDetails } from '@halo-common/hooks';
import {
  ProductDetailPanelLayout,
  ProductDetailPanelPrincipalSection,
  V2ProductDetailPanelUnderlyingSection,
} from '@halo-common/layouts';
import {
  V2PostTradeNoteDetailIssuerSection,
  V2PostTradeNoteDetailLifecycleSection,
  V2PostTradeNoteDetailPayoffSection,
} from '@halo-common/modals';
import { V2NoteModel } from '@halo-common/models';

export type V2PostTradeNoteDetailModalPanelProps = {
  product?: V2NoteModel | null;
  loading: boolean;
};

export const V2PostTradeNoteDetailModalPanel = ({
  product,
  loading,
}: V2PostTradeNoteDetailModalPanelProps): ReactElement => {
  const term = product?.term?.description;
  const currency = product?.currency;

  const protection = useV2ProtectionDetails(product);

  const showIssuerSection = loading || product?.issuer;
  const issuerSection = showIssuerSection ? (
    <V2PostTradeNoteDetailIssuerSection issuer={product?.issuer} loading={loading} />
  ) : null;

  const showPayoffSection = loading || product?.payoffDetails;
  const solvableParameter = product?.payoffDetails?.solvableParameter;
  const alternateSolvableParameterFormat = product?.payoffDetails?.alternateSolvableParameterFormat;
  const payoffs = alternateSolvableParameterFormat?.payoffs.length
    ? alternateSolvableParameterFormat?.payoffs
    : solvableParameter?.payoffs;
  const payoffSection = showPayoffSection ? (
    <V2PostTradeNoteDetailPayoffSection
      solvableParameterPayoffs={payoffs}
      highlightedPayoffs={product?.payoffDetails?.highlight.payoffs}
      loading={loading}
    />
  ) : null;

  const showLifecycleSection = loading || product?.lifecycle;
  const lifecycleSection = showLifecycleSection ? (
    <V2PostTradeNoteDetailLifecycleSection product={product} loading={loading} />
  ) : null;

  const slots = {
    highlight: issuerSection,
    underlyings: (
      <V2ProductDetailPanelUnderlyingSection
        underlyings={product?.underlyings}
        basketType={product?.underlyingBasketType}
        loading={loading}
      />
    ),
  };

  const principalSectionLocalization = { term: product?.term?.dynamicContent };

  return (
    <ProductDetailPanelLayout slots={slots} loading={loading}>
      <ProductDetailPanelPrincipalSection
        term={term}
        protection={protection?.text}
        protectionType={protection?.label}
        currency={currency}
        loading={loading}
        Localization={principalSectionLocalization}
      />
      {payoffSection}
      {lifecycleSection}
    </ProductDetailPanelLayout>
  );
};
