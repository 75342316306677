import { ReactElement, ReactNode, useState } from 'react';

import { Iconography, Stack, useCombinedStyling } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Collapse, Paper, SxProps, Typography } from '@mui/material';

const captionStyling = { display: 'block', mx: 0, mt: 1, mb: 3, maxWidth: 600 };
const containerStyling = { padding: 2 };

export type TablePresentationLayoutProps = {
  actions?: ReactNode;
  collapsible?: boolean;
  children: ReactNode;
  sx?: SxProps<HaloTheme>;
  subtitle?: string;
  title?: string;
};

export const TablePresentationLayout = ({
  actions,
  children,
  collapsible = false,
  sx,
  subtitle,
  title,
}: TablePresentationLayoutProps): ReactElement => {
  const [open, setOpen] = useState(true);

  const handleCollapse = () => {
    if (collapsible) setOpen(!open);
  };

  const showContent = !collapsible || open;

  const chevron = open ? 'chevron-up' : 'chevron-down';
  const chevronIcon = collapsible ? <Iconography iconName={chevron} color="primary.dark" size="1.25x" /> : null;

  const combinedStyling = useCombinedStyling(containerStyling, sx);

  return (
    <Paper sx={combinedStyling}>
      <span aria-label="table presentation header collapse button" onClick={handleCollapse}>
        <Stack direction="row" justify="space-between">
          <Stack direction="row" spacing={1}>
            {chevronIcon}
            <Stack direction="column">
              <Typography variant="h6" color="primaryDark">
                {title}
              </Typography>
              <Typography sx={captionStyling} variant="caption">
                {subtitle}
              </Typography>
            </Stack>
          </Stack>
          {actions}
        </Stack>
      </span>
      <Collapse in={showContent}>{children}</Collapse>
    </Paper>
  );
};
