import { AuctionStatusEnum, AuctionStrikeStyleEnum, AuctionStyleEnum } from '@halo-common/enums';
import {
  AccountModel,
  AuctionAdminModel,
  AuctionUpdateAdminModel,
  AuctionsByStatusModel,
  IssuerModel,
  NoteModel,
  QuoteModel,
} from '@halo-common/models';
import { StrikeStyleEnum } from '@halo-data-sources/enums';
import { HaloSliceState } from '@halo-stores/types';

export enum OrderStatusEnum {
  idle = 'idle',
  requesting = 'requesting',
  tableRequest = 'tableRequest',
  modalRequest = 'modalRequest',
  success = 'success',
  failure = 'failure',
  showDetails = 'showDetails',
  showIssuerComment = 'showIssuerComment',
  showChooseWinnerModal = 'showChooseWinnerModal',
  showCreateTackOnModal = 'showCreateTackOnModal',
  showEditTackOnModal = 'showEditTackOnModal',
  showOpsApprovalModal = 'showOpsApprovalModal',
  showPreApprovalModal = 'showPreApprovalModal',
  showTermsAcceptedModal = 'showTermsAcceptedModal',
  showTermsPendingModal = 'showTermsPendingModal',
  showTermsReviewedModal = 'showTermsReviewedModal',
  successEditOrderAuction = 'successEditOrderAuction',
  requestingFetchAuction = 'requestingFetchAuction',
  requestingCreateOrUpdateAuctionAllocation = 'requestingCreateOrUpdateAuctionAllocation',
  failureFetchAuction = 'failureFetchAuction',
  failureCreateOrUpdateAuctionAllocation = 'failureCreateOrUpdateAuctionAllocation',
  successFetchAuction = 'successFetchAuction',
  successCreateOrUpdateAuctionAllocation = 'successCreateOrUpdateAuctionAllocation',
}

export type OrderStatus = keyof typeof OrderStatusEnum;

export type UploadPrelimTermsThunkAction = {
  auction: AuctionAdminModel;
  termSheet: File;
  isin?: string;
  cusip?: string;
};

export type UploadFinalPrelimTermsThunkAction = {
  auction: AuctionAdminModel;
  termSheet: File;
  isin?: string;
  cusip?: string;
};

export type OpsApproveOrderAuctionThunkAction = {
  id: number;
  to: Array<string>;
  cc: Array<string>;
  subject: string;
  autoReply: boolean;
  tableData: Array<{ id: string; name: string; value: string | number }>;
};

export type OpsApproveTestEmailOrderAuctionThunkAction = {
  id: number;
  subject: string;
  autoReply: boolean;
  tableData: Array<{ id: string; name: string; value: string | number }>;
};

export type ChooseWinnerOrderAuctionThunkAction = {
  id: number;
  quote: number;
};

export type ChooseInstantWinnerOrderAuctionThunkAction = {
  id: number;
  quoteId: number;
  status: AuctionStatusEnum;
};

export type CancelOrderAuctionThunkAction = {
  id: number;
  status: AuctionStatusEnum;
};

export type DeclineAuctionCommentThunkAction = {
  id: number;
  issuerId: number;
  comment?: string;
};

export type IssuerAuctionCommentThunkAction = {
  quoteId: string;
  comment: string;
};

export type IssuerAuctionQuoteThunkAction = {
  auction: AuctionAdminModel;
  issuerId: number;
  quote: string;
  comment?: string;
};

export type UpdateOrderAuctionThunkAction = AuctionUpdateAdminModel & {
  [key: string]:
    | number
    | string
    | boolean
    | AuctionStyleEnum
    | AuctionStrikeStyleEnum
    | Array<IssuerModel>
    | null
    | undefined;
  id: number;
  status: AuctionStatusEnum;
};

export type UpdatedOrderAuction = Omit<Partial<AuctionAdminModel>, 'note'> & {
  note: Partial<NoteModel>;
};

export type AuctionThunkResult = {
  auction: AuctionAdminModel;
};

export type AuctionsThunkResult = {
  auctions: Array<AuctionAdminModel>;
};

export type UpdateAuctionThunkResult = {
  id: number;
  status: AuctionStatusEnum;
  data: Partial<AuctionAdminModel>;
};

export type UpdateAuctionStatusThunkResult = {
  auction: AuctionAdminModel;
  oldStatus: AuctionStatusEnum;
};

export type CreateTackOnThunkAction = {
  id: number;
  reoffer: number;
  pages: Array<number>;
  externalName: string | null;
  internalName: string | null;
  issuer: number | null;
  category: string;
  startShowing: string;
  expiration: string;
};

export type EditTackOnThunkAction = {
  id: number;
  reoffer: number;
  pages: Array<number>;
  externalName: string | null;
  internalName: string | null;
  issuer: number | null;
  category: string;
  startShowing: string;
  expiration: string;
};

export type DeleteTackOnThunkAction = {
  id: number;
};

export type AuctionAllocationModel = {
  quantity: number;
  account: AccountModel;
  index: number;
};

export type GetAuctionThunkAction = {
  auctionId: string;
};

export type CreateOrUpdateAuctionAllocationThunkAction = {
  id: string;
  allocations: Array<AuctionAllocationModel>;
};

export type OrdersSliceState = {
  auctions: AuctionsByStatusModel;
  totalAuctions: number;
  selectedAuction?: AuctionAdminModel | null;
  selectedQuote?: QuoteModel | null;
  selectedIssuer?: IssuerModel | null;
  status: OrderStatus;
};

export type OrdersState = OrdersSliceState & Omit<HaloSliceState, 'status'>;

export type StartAuctionModel = {
  issuers: Array<number>;
  notional: number;
  strikeDate: string;
  tradeDate: string;
  settlementDate: string;
  noteId: number;
  auctionStyle: AuctionStyleEnum;
  strikeMode: StrikeStyleEnum;
  number: number;
  allocations: Array<number>;
  details?: string;
};

export type StartAuctionResponseModel = {
  auctionId: number;
};
