import { DisclaimerOnboardingModel } from '@halo-common/models';
import { HaloSliceState } from '@halo-stores/types';

export enum OnboardingStatusEnum {
  idle = 'idle',
  requesting = 'requesting',
  failurePasswordReset = 'failurePasswordReset',
  failureResendSetPassword = 'failureResendSetPassword',
  failureRegisterUser = 'failureRegisterUser',
  failureSetPassword = 'failureSetPassword',
  failureGetDisclaimers = 'failureGetDisclaimers',
  failureAcceptDisclaimer = 'failureAcceptDisclaimer',
  failureContactForm = 'failureContactForm',
  successPasswordReset = 'successPasswordReset',
  successRegisterUser = 'successRegisterUser',
  successResendSetPassword = 'successResendSetPassword',
  successGetDisclaimers = 'successGetDisclaimers',
  successAcceptDisclaimer = 'successAcceptDisclaimer',
  successContactForm = 'successContactForm',
  successSetPassword = 'successSetPassword',
}

export type OnboardingStatus = keyof typeof OnboardingStatusEnum;

export enum OnboardingFamiliarityEnum {
  new = 'new',
  experienced = 'experienced',
  intermediate = 'intermediate',
}

export type OnboardingFamiliarity = keyof typeof OnboardingFamiliarityEnum;

export enum OnboardingGoalEnum {
  newInvestments = 'newInvestments',
  manageNotes = 'manageNotes',
  investmentChoices = 'investmentChoices',
  analyzeNotePerformance = 'analyzeNotePerformance',
}

export type OnboardingGoal = keyof typeof OnboardingGoalEnum;

export enum OnboardingCustodyEnum {
  fidelity = 'fidelity',
  pershing = 'pershing',
  schwab = 'schwab',
  td = 'td',
  other = 'other',
}

export type OnboardingCustody = keyof typeof OnboardingCustodyEnum;

export type NewRegisteredUserThunkResult = {
  whiteListed: boolean;
  name: string;
  email: string;
};

export type PasswordResetThunkResult = {
  emailSent: boolean;
  email: string;
};

export type AcceptDisclaimerThunkResult = {
  disclaimerId: number;
};

export type GetDisclaimersThunkResult = {
  disclaimers: Array<DisclaimerOnboardingModel>;
};

export type UserPasswordUpdate = {
  oldPassword: string;
  newPassword: string;
};

export type OnboardingSliceState = {
  emailSent: boolean;
  passwordSet: boolean;
  userWhiteListed: boolean;
  userEmail: string;
  userName: string;
  disclaimers: Array<DisclaimerOnboardingModel>;
  acceptedDisclaimers: { [key: string]: boolean };
  status: OnboardingStatus;
};

export type OnboardingState = OnboardingSliceState & Omit<HaloSliceState, 'status'>;
