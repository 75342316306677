import { OrderBookQuery } from '@halo-atoms/orderbook';
import { getCalendarAllocationFiltersQuery } from '@halo-data-sources/clients';
import { PershingOrderBookMapper } from '@halo-data-sources/mappers';
import { IssuerQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export type PershingOrderBookIssuersQueryResult = Array<{
  id: number;
  name: string;
}>;

const pershingOrderBookIssuersQueryFn = async (query: OrderBookQuery) => {
  const parsedQuery = { ...query, filters: { ...query.filters, issuer: undefined } };

  const comparisons = PershingOrderBookMapper.toApiFiltersComparisonList(parsedQuery);

  const response = await getCalendarAllocationFiltersQuery({
    filters: [
      {
        field: 'issuers.id',
        type: 'OBJECTS',
        object_fields: ['issuers.name'],
      },
    ],
    comparisons,
  });

  return (
    response.filter_values[0]?.objects?.map(({ object }) => ({
      id: object['issuers.id'] as number,
      name: object['issuers.name'] as string,
    })) ?? []
  );
};

export const usePershingOrderBookIssuersQuery = (
  query: OrderBookQuery,
): UseQueryResult<PershingOrderBookIssuersQueryResult, Error> =>
  useQuery<PershingOrderBookIssuersQueryResult, Error>({
    queryKey: IssuerQueryKeyFactory.byAllocation(query),
    queryFn: () => pershingOrderBookIssuersQueryFn(query),
    retry: false,
  });
