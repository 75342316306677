import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiEducationSubmitQuizResponseModel,
  ApiGetEducationQuizAttemptsResponseModel,
} from '@halo-data-sources/models';
import { QuizSchema } from '@halo-modules/app';

const EDUCATION_ROUTE = '/issuer/v1/quiz';

export const updateEducationQuizProgress = async (
  quizId: number,
  answers: QuizSchema,
): Promise<ApiEducationSubmitQuizResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EDUCATION_ROUTE);

  return await request<ApiEducationSubmitQuizResponseModel>(`${clientConfiguration.basePath}/${quizId}`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...answers,
    }),
  });
};

export const getEducationQuizAttempts = async (quizId: number): Promise<ApiGetEducationQuizAttemptsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EDUCATION_ROUTE);

  const batchResponse = await request<ApiGetEducationQuizAttemptsResponseModel>(
    `${clientConfiguration.basePath}/${quizId}`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
      failOnError: false,
    },
  );

  return batchResponse;
};
