import { ReactElement } from 'react';

import { HOUR_MINUTE_TIME_FORMAT } from '@halo-common/constants';
import { translations } from '@halo-common/translations';
import { LocalizedTextField, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { TimePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { useController, useFormContext } from 'react-hook-form';

import { ProductApprovalEditCloseDateFormFields } from '../ProductApprovalEditCloseDateModal';
import { DaysPriorDropdown } from './DaysPriorDropdown';

export const ProductApprovalEditCloseDateMultiForm = (): ReactElement => {
  const formMethods = useFormContext<ProductApprovalEditCloseDateFormFields>();

  const timePickerMethods = useController({
    control: formMethods.control,
    name: 'time',
  });

  const { onChange: onTimeChange, value } = timePickerMethods.field;

  const selectedTime = value ? DateTime.fromISO(value) : null;

  const handleTimeChange = (value: DateTime | null) => {
    const updatedDateISO = value?.toISO() ?? '';
    onTimeChange(updatedDateISO);
  };

  return (
    <Stack direction="column" spacing={4}>
      <LocalizedTypography variant="h6">
        {translations.productApproval.editCloseDateModal.closeTimeTitle}
      </LocalizedTypography>
      <Stack direction="row" alignItems="flex-start" spacing={2} xs={[7, 5]}>
        <DaysPriorDropdown />
        <TimePicker
          value={selectedTime}
          onChange={handleTimeChange}
          label={translations.productApproval.editCloseDateModal.modifyCloseTimeLabel}
          format={HOUR_MINUTE_TIME_FORMAT}
          closeOnSelect
          slots={{ textField: (props) => <LocalizedTextField {...props} fullWidth size="large" /> }}
        />
      </Stack>
    </Stack>
  );
};
