import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { PopoverRangeFilter } from '@halo-common/components';
import { useSliderText } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { useAtom } from 'jotai';

export const UNDERLYING_SLIDER_MIN_VALUE = 0;
export const UNDERLYING_SLIDER_MAX_VALUE = 10;

const DEFAULT_SLIDER_DATA = {
  min: UNDERLYING_SLIDER_MIN_VALUE,
  max: UNDERLYING_SLIDER_MAX_VALUE,
};

export type UnderlyingCountSliderFiltersProps = {
  tag: string;
};

export const UnderlyingCountSliderFilters = ({ tag }: UnderlyingCountSliderFiltersProps): ReactElement => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);
  const { underlyingCount } = sideBarFilters;

  const [sliderData, setSliderData] = useState(DEFAULT_SLIDER_DATA);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const sliderMin = sliderData?.min ?? UNDERLYING_SLIDER_MIN_VALUE;
  const sliderMax = sliderData?.max ?? UNDERLYING_SLIDER_MAX_VALUE;
  const sliderValue = [sliderMin, sliderMax];

  const isSliderMinValue = sliderMin === UNDERLYING_SLIDER_MIN_VALUE;
  const isSliderMaxValue = sliderMax === UNDERLYING_SLIDER_MAX_VALUE;

  const open = Boolean(anchorEl);
  const id = open ? 'underlying-count-popover' : undefined;

  const minInputValue = !isSliderMinValue ? sliderMin : '';
  const maxInputValue = !isSliderMaxValue ? sliderMax : '';

  const formattedButtonText = useSliderText({
    minValue: UNDERLYING_SLIDER_MIN_VALUE,
    maxValue: UNDERLYING_SLIDER_MAX_VALUE,
    leftSliderValue: sliderMin,
    rightSliderValue: sliderMax,
  });

  const handlePopoverOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleDoneClick = () => {
    if (isSliderMinValue && isSliderMaxValue) setSideBarFilters({ tag, underlyingCount: null });
    else setSideBarFilters({ tag, underlyingCount: { min: sliderMin, max: sliderMax } });
    handlePopoverClose();
  };

  const handleSliderChange = (_: unknown, value: number | Array<number>) => {
    const updatedSliderMin = typeof value === 'number' ? value : value[0];
    const updatedSliderMax = typeof value === 'number' ? value : value[1];
    setSliderData({ min: updatedSliderMin, max: updatedSliderMax });
  };

  const handleMinInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const inputValue = value ? parseInt(event.target.value) : UNDERLYING_SLIDER_MIN_VALUE;

    const filteredValue = inputValue <= UNDERLYING_SLIDER_MIN_VALUE ? UNDERLYING_SLIDER_MIN_VALUE : inputValue;

    setSliderData({ min: filteredValue, max: sliderMax });
  };

  const handleMaxInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const inputValue = value ? parseInt(event.target.value) : UNDERLYING_SLIDER_MAX_VALUE;

    const filteredValue = inputValue >= UNDERLYING_SLIDER_MAX_VALUE ? UNDERLYING_SLIDER_MAX_VALUE : inputValue;

    setSliderData({ min: sliderMin, max: filteredValue });
  };

  useEffect(() => {
    const resetValue = underlyingCount === null;
    const hasValueChanged = underlyingCount?.max !== sliderData.max || underlyingCount?.min !== sliderData.min;
    if (resetValue) setSliderData(DEFAULT_SLIDER_DATA);
    else if (hasValueChanged) setSliderData(underlyingCount);
  }, [underlyingCount]);

  return (
    <PopoverRangeFilter
      title={translations.dynamicFilters.common.assetCountFilter}
      maxInputValue={maxInputValue}
      minInputValue={minInputValue}
      PopoverProps={{
        id,
        open,
        anchorEl,
        onClose: handlePopoverClose,
      }}
      SliderProps={{
        onChange: handleSliderChange,
        max: UNDERLYING_SLIDER_MAX_VALUE,
        min: UNDERLYING_SLIDER_MIN_VALUE,
        step: 1,
        value: sliderValue,
      }}
      buttonText={formattedButtonText}
      handleMinInputChange={handleMinInputChange}
      handleMaxInputChange={handleMaxInputChange}
      handlePopoverOpen={handlePopoverOpen}
      handleDoneClick={handleDoneClick}
    />
  );
};
