import { ReactElement, useEffect } from 'react';

import { debouncedLifecycleIdSearchAtom, lifecyclePretradeModalAtom } from '@halo-atoms/portfolio';
import { usePrimaryAssetIdentifier } from '@halo-common/hooks';
import { CalendarAllocationsModal, PreTradeNoteDetailModal } from '@halo-common/modals';
import { useLegacyRouter } from '@halo-common/providers';
import { useRecordActivityMutation } from '@halo-data-sources/mutations';
import { usePortfolioOrderQuery } from '@halo-data-sources/queries';
import {
  CalendarDetailsModal,
  CalendarPdfViewerModal,
  PortfolioCashflowChart,
  PortfolioEventsExcelDownloadModal,
  PortfolioEventsList,
  PortfolioEventsListFilters,
} from '@halo-modules/app';
import { Divider, Stack } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';

export const PortfolioLifecycleTab = (): ReactElement => {
  const router = useLegacyRouter();

  const [lifecyclePretradeState, setLifecyclePretradeState] = useAtom(lifecyclePretradeModalAtom);
  const setDebouncedSearch = useSetAtom(debouncedLifecycleIdSearchAtom);

  const { event, open } = lifecyclePretradeState;

  const termsheetIdQuery = router.query['termsheet_id'];
  const hasTermsheetIdQuery = typeof termsheetIdQuery === 'string';
  const termsheetId = hasTermsheetIdQuery ? parseInt(termsheetIdQuery) : undefined;

  const { mutate: recordActivity } = useRecordActivityMutation();
  const { data: order, isPending } = usePortfolioOrderQuery(termsheetId);
  const { identifier: assetId, fallback } = usePrimaryAssetIdentifier(order?.termsheet);

  const handleModalClose = () => {
    setLifecyclePretradeState();
  };

  useEffect(() => {
    const modelName = 'Lifecycle';
    const verb = `navigated to react lifecycle page`;
    recordActivity({ activity: [{ verb, model_name: modelName }], fromIFrame: false });
  }, []);

  useEffect(() => {
    if (assetId) setDebouncedSearch(assetId);
    else if (fallback.identifier) setDebouncedSearch(fallback.identifier);
  }, [assetId, fallback.identifier]);

  return (
    <Stack direction="column" spacing={3}>
      <Divider />
      <Stack direction="column" spacing={4}>
        <PortfolioCashflowChart loading={isPending} />
        <Stack direction="column" spacing={4}>
          <PortfolioEventsListFilters />
          <PortfolioEventsList loading={isPending} />
        </Stack>
      </Stack>
      <CalendarDetailsModal />
      <CalendarPdfViewerModal />
      <CalendarAllocationsModal />
      <PortfolioEventsExcelDownloadModal />
      <PreTradeNoteDetailModal product={event.note} open={open} onClose={handleModalClose} />
    </Stack>
  );
};
