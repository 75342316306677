import { OrderBookQuery } from '@halo-atoms/orderbook';
import {
  OrderBookAuctionQueryFields,
  OrderBookCalendarsQueryFields,
  OrderBookPershingQueryFields,
} from '@halo-common/models';
import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type OrderBookAuctionQueryKeys = {
  all: QueryKeyFactoryFn;
  auctions: QueryKeyFactoryFn;
  noteTypes: QueryKeyFactoryFn;
  issuers: QueryKeyFactoryFn;
};

export const OrderBookAuctionQueryKeyFactory: OrderBookAuctionQueryKeys = {
  all: () => ['orderBook', 'auctions'],
  auctions: (searchParams?: OrderBookAuctionQueryFields) => [
    ...OrderBookAuctionQueryKeyFactory.all(),
    hash(searchParams),
  ],
  noteTypes: () => [...OrderBookAuctionQueryKeyFactory.all(), 'note', 'types'],
  issuers: () => [...OrderBookAuctionQueryKeyFactory.all(), 'issuers'],
};

type OrderBookCalendarQueryKeys = {
  all: QueryKeyFactoryFn;
  calendar: QueryKeyFactoryFn;
  calendars: QueryKeyFactoryFn;
  noteTypes: QueryKeyFactoryFn;
};

export const OrderBookCalendarQueryKeyFactory: OrderBookCalendarQueryKeys = {
  all: () => ['orderBook', 'calendars'],
  calendar: (calendarId?: string) => [...OrderBookCalendarQueryKeyFactory.all(), calendarId],
  calendars: (searchParams?: OrderBookCalendarsQueryFields) => [
    ...OrderBookCalendarQueryKeyFactory.all(),
    hash(searchParams),
  ],
  noteTypes: () => [...OrderBookCalendarQueryKeyFactory.all(), 'note', 'types'],
};

type OrderBookClientQueryKeys = {
  all: QueryKeyFactoryFn;
  janney: QueryKeyFactoryFn;
  pershing: QueryKeyFactoryFn;
};

export const OrderBookClientQueryKeyFactory: OrderBookClientQueryKeys = {
  all: () => ['orderBook', 'client'],
  pershing: (searchParams?: OrderBookPershingQueryFields) => [
    ...OrderBookClientQueryKeyFactory.all(),
    'pershing',
    hash(searchParams),
  ],
  janney: (searchParams: OrderBookQuery) => [...OrderBookClientQueryKeyFactory.all(), 'janney', hash(searchParams)],
};
