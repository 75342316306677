import { DisclaimerUserModel } from '@halo-common/models';
import { putDisclaimerAcceptance } from '@halo-data-sources/clients';
import { ApiDisclaimerMapper } from '@halo-data-sources/mappers';
import { UserDisclaimerSection, UserQueryKeyFactory } from '@halo-data-sources/queries';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

export type UserDisclaimerAcceptanceUploadMutationPayload = {
  file: File;
  id: string;
};

const userDisclaimerAcceptanceUploadMutationFn = async (payload: UserDisclaimerAcceptanceUploadMutationPayload) => {
  if (!payload.id) return null;

  const model = await putDisclaimerAcceptance({ id: payload.id, document: payload.file });

  return ApiDisclaimerMapper.toUserDisclaimer(model);
};

export const useUserDisclaimerAcceptanceUploadMutation = (): UseMutationResult<
  DisclaimerUserModel | null,
  Error,
  UserDisclaimerAcceptanceUploadMutationPayload
> => {
  const queryClient = useQueryClient();

  return useMutation<DisclaimerUserModel | null, Error, UserDisclaimerAcceptanceUploadMutationPayload>({
    mutationFn: userDisclaimerAcceptanceUploadMutationFn,
    onSuccess: (data) => {
      if (!data) return;

      const key = UserQueryKeyFactory.disclaimers();
      queryClient.setQueryData<Array<UserDisclaimerSection>>(key, (prev) => {
        if (!prev) return undefined;

        const disclaimerSections = [...prev];
        const sectionIndex = disclaimerSections.findIndex(({ files }) => files.some((file) => file.id === data.id));
        const fileIndex = disclaimerSections[sectionIndex]?.files.findIndex((file) => file.id === data.id);

        if (fileIndex >= 0) disclaimerSections[sectionIndex].files.splice(fileIndex, 1, data);

        return disclaimerSections;
      });
    },
  });
};
