import { selectedWatchlistAtom } from '@halo-atoms/watchlists';
import { WatchlistModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { alphabeticallySortWatchlists } from '@halo-common/utils';
import { updateWatchlistProducts } from '@halo-data-sources/clients';
import { ApiWatchlistMapper } from '@halo-data-sources/mappers';
import { WatchlistQueryKeyFactory } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

export type UpdateNoteWatchlistMutationPayload = {
  productId?: number;
  productType?: string;
  addIds?: Array<number>;
  removeIds?: Array<number>;
};

export type UpdateNoteWatchlistMutationResult = {
  added: Array<WatchlistModel>;
  removed: Array<WatchlistModel>;
  productId: number;
  productType: string;
  message: string;
};

const updateNoteWatchlistMutationFn = async (payload: UpdateNoteWatchlistMutationPayload) => {
  if (!payload.productId || !payload.productType) return null;

  const { productId, productType, addIds = [], removeIds = [] } = payload;

  const request = {
    add_to: addIds,
    products: [{ product_id: productId, product_type: productType }],
    remove_from: removeIds,
  };

  const response = await updateWatchlistProducts(request);

  const watchlists = response.watchlists.map((watchlist) => ApiWatchlistMapper.toWatchlistModel(watchlist));

  return {
    productId,
    productType,
    added: watchlists.filter((watchlist) => addIds.includes(watchlist.id)),
    removed: watchlists.filter((watchlist) => removeIds.includes(watchlist.id)),
    message: 'Successfully updated watchlists',
  };
};

export const useUpdateNoteWatchlistMutation = (): UseMutationResult<
  UpdateNoteWatchlistMutationResult | null,
  Error,
  UpdateNoteWatchlistMutationPayload
> => {
  const queryClient = useQueryClient();

  const { enqueueSuccessEvent, enqueueErrorEvent } = useSnackbar();

  const [selectedWatchlist, setSelectedWatchlist] = useAtom(selectedWatchlistAtom);

  return useMutation({
    mutationFn: updateNoteWatchlistMutationFn,
    onError: () => {
      enqueueErrorEvent({ message: translations.watchlist.common.updateNoteWatchlistError });
    },
    onSuccess: (data) => {
      if (!data) return undefined;

      enqueueSuccessEvent({ message: translations.watchlist.common.updateNoteWatchlistSuccess });

      const { productId, productType, removed, added } = data;

      const updateCount = (watchlistId: number, prev?: Array<WatchlistModel>, remove = false) => {
        if (!prev) return undefined;
        else if (!remove && selectedWatchlist?.id === watchlistId) return undefined;

        const watchlistIndex = prev.findIndex((watchlist) => watchlist.id === watchlistId);
        const watchlist = prev[watchlistIndex];

        const updatedCount = remove ? watchlist.count - 1 : watchlist.count + 1;
        const updatedWatchlist = { ...watchlist, count: Math.max(updatedCount, 0) };
        const updatedWatchlists = [...prev];

        updatedWatchlists.splice(watchlistIndex, 1, updatedWatchlist);

        const isSelectedWatchlist = selectedWatchlist?.id === watchlistId;
        if (isSelectedWatchlist) setSelectedWatchlist(updatedWatchlist);

        return updatedWatchlists;
      };

      removed.forEach((watchlist) => {
        const watchlistId = watchlist.id;

        const productsKey = WatchlistQueryKeyFactory.products(watchlistId);
        void queryClient.refetchQueries({ queryKey: productsKey });

        const watchlistsKey = WatchlistQueryKeyFactory.all();
        queryClient.setQueryData<Array<WatchlistModel>>(watchlistsKey, (prev) => updateCount(watchlistId, prev, true));
      });

      added.forEach((watchlist) => {
        const watchlistId = watchlist.id;

        const productsKey = WatchlistQueryKeyFactory.products(watchlistId);
        void queryClient.refetchQueries({ queryKey: productsKey });

        const watchlistsKey = WatchlistQueryKeyFactory.all();
        queryClient.setQueryData<Array<WatchlistModel>>(watchlistsKey, (prev) => updateCount(watchlistId, prev, false));
      });

      const noteWatchlistsKey = WatchlistQueryKeyFactory.product(productId, productType);
      queryClient.setQueryData<Array<WatchlistModel>>(noteWatchlistsKey, (prev) => {
        if (!prev) return undefined;

        let updatedRelationship = [...prev];

        if (added?.length) {
          updatedRelationship = alphabeticallySortWatchlists([...updatedRelationship, ...added]);
        }

        if (removed?.length) {
          updatedRelationship = updatedRelationship.filter(
            (watchlist) => !removed.some(({ id }) => id === watchlist.id),
          );
        }

        return updatedRelationship;
      });
    },
  });
};
