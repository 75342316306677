import { ReactElement } from 'react';

import { AuctionHistoryModel } from '@halo-common/models';
import { GridColDef, HaloDataGrid, HaloDataGridProps } from '@halodomination/halo-fe-common';

const commonColumnProps: Partial<GridColDef> = {
  align: 'left',
  headerAlign: 'left',
  flex: 1,
};

const columns: HaloDataGridProps['columns'] = [
  { ...commonColumnProps, field: 'date', headerName: 'Date' },
  { ...commonColumnProps, field: 'time', headerName: 'Time' },
  { ...commonColumnProps, field: 'participant', headerName: 'Participant', flex: 1.25 },
  { ...commonColumnProps, field: 'action', headerName: 'Action' },
  { ...commonColumnProps, field: 'value', headerName: 'Value' },
  { ...commonColumnProps, field: 'email', headerName: 'Email' },
];

export type ExecutionAuctionReviewHistoryTabProps = {
  history?: Array<AuctionHistoryModel>;
};

export const ExecutionAuctionReviewHistoryTab = ({
  history = [],
}: ExecutionAuctionReviewHistoryTabProps): ReactElement => <HaloDataGrid columns={columns} hideFooter rows={history} />;
