import { getPortfolioPositionsAggregation } from '@halo-data-sources/clients';
import { ApiPositionsMapper } from '@halo-data-sources/mappers';
import { ApiGetPortfolioPositionsAggregationPayload } from '@halo-data-sources/models';
import { PortfolioQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export type PortfolioPositionAggregationQueryModel = {
  accountIds?: Array<number>;
  householdIds?: Array<number>;
  reportingCurrencyId?: number;
  includeInactive?: boolean;
  features?: Array<string>;
  composition: string;
  synced: boolean | null;
  filters?: { [filter: string]: string | number };
};

export type PortfolioPositionAggregationModel = {
  name: string;
  uid: string;
  positionCount: number;
  totalNotional: number;
  percentage: number;
  filterKey: number | string;
  marketValue: number;
  marketChange: number;
  notionalPercent: number;
  nextEventDate: string;
};

export type PortfolioPositionAggregationQueryResult = {
  tableDetails: {
    totalPositions: number;
    totalNotional: number;
    nextEventDate: string;
    marketValue: number;
    marketChange: number;
  };
  aggregations: Array<PortfolioPositionAggregationModel>;
};

const getPortfolioPositionAggregationQueryFn = async (options: PortfolioPositionAggregationQueryModel) => {
  const { accountIds, filters, householdIds, composition, reportingCurrencyId, features, synced } = options;

  const payload: ApiGetPortfolioPositionsAggregationPayload = {
    account_selector: {
      account_ids: accountIds,
      advisee_ids: householdIds,
      synced,
    },
    reporting_currency_id: reportingCurrencyId,
    filters,
    group_by: composition,
  };

  const hasNoteFeatures = features && features.length;
  if (hasNoteFeatures) payload.note_features = features;

  const result = await getPortfolioPositionsAggregation(payload);

  return ApiPositionsMapper.toAggregation(result);
};

export const usePortfolioPositionAggregationQuery = (
  payload: PortfolioPositionAggregationQueryModel,
): UseQueryResult<PortfolioPositionAggregationQueryResult, Error> =>
  useQuery<PortfolioPositionAggregationQueryResult, Error>({
    queryKey: PortfolioQueryKeyFactory.aggregationSummary(payload),
    queryFn: () => getPortfolioPositionAggregationQueryFn(payload),
  });
