import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type TermsheetQueryKeys = {
  all: QueryKeyFactoryFn;
  notes: QueryKeyFactoryFn;
  downloadLink: QueryKeyFactoryFn;
};

export const TermsheetQueryKeyFactory: TermsheetQueryKeys = {
  all: () => ['termsheet'],
  notes: (id: number) => [...TermsheetQueryKeyFactory.all(), 'notes', id],
  downloadLink: (id: number) => [...TermsheetQueryKeyFactory.all(), 'downloadLink', id],
};
