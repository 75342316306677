import { ReactElement } from 'react';

import { LexicalTypography, ProductDetailTable } from '@halo-common/components';
import { SHORT_MONTH_SHORT_DAY_YEAR_FORMAT } from '@halo-common/constants';
import { useTranslatePdmDates } from '@halo-common/hooks';
import { CurrencyModel } from '@halo-common/models';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { PostTradeV2Event, usePostTradeV2EventsQuery } from '@halo-data-sources/queries';
import { CouponEventPaidStatus } from '@halo-modules/app';
import {
  GridColDef,
  GridRenderCellParams,
  HaloDataGridProps,
  Iconography,
  mapNumberToLocalCurrency,
} from '@halodomination/halo-fe-common';
import { Stack, Typography } from '@mui/material';

const emphasisSx = {
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  width: '100%',
};

const iconSx = {
  marginRight: 1.625,
};

const paidSx = {
  color: 'success.dark',
};

const missedSx = {
  color: 'error.main',
};

const pendingSx = {
  color: 'text.secondary',
};

const commonColumnProps: Partial<GridColDef> = {
  align: 'left',
  flex: 1,
};

const statusContainerSx = {
  height: '100%',
};

export type V2PostTradeNoteDetailModalCouponTableProps = {
  showDate?: boolean;
  showAmount?: boolean;
  showStatus?: boolean;
  loading?: boolean;
  account?: AccountTypeAheadOption | null;
  id?: number;
  currency?: CurrencyModel;
};

export const V2PostTradeNoteDetailModalCouponTable = ({
  showDate = true,
  showAmount = true,
  showStatus = true,
  loading = true,
  id,
  account,
  currency,
}: V2PostTradeNoteDetailModalCouponTableProps): ReactElement => {
  const translateDate = useTranslatePdmDates();

  const { data: events, isPending: isEventsLoading } = usePostTradeV2EventsQuery(id, account);

  const rows = events?.coupon ?? [];
  const height = rows.length >= 16 ? 600 : 'auto';

  const isLoading = loading || isEventsLoading;

  const columns: HaloDataGridProps['columns'] = [];

  if (showDate) {
    columns.push({
      ...commonColumnProps,
      field: 'eventDate',
      headerName: translations.common.date,
      valueFormatter: (value: string) => translateDate(value, SHORT_MONTH_SHORT_DAY_YEAR_FORMAT),
    });
  }

  if (showAmount) {
    columns.push({
      ...commonColumnProps,
      field: 'cashFlowAmt',
      headerName: translations.common.amount,
      renderCell: ({ value }: GridRenderCellParams<PostTradeV2Event, number>) => {
        const valueAsCurrency = mapNumberToLocalCurrency(
          value,
          { currency: currency?.code ?? 'USD' },
          currency?.symbol,
        );

        return (
          <Typography sx={emphasisSx} variant="body2">
            {valueAsCurrency}
          </Typography>
        );
      },
    });
  }

  if (showStatus) {
    columns.push({
      ...commonColumnProps,
      field: 'paid',
      headerName: translations.common.paid,
      renderCell: ({ value }) => {
        const isPaid = value === CouponEventPaidStatus.PAID;
        const isMissed = value === CouponEventPaidStatus.MISSED;
        const isPending = value === CouponEventPaidStatus.PENDING;

        const statusStyling = [];
        if (isPaid) statusStyling.push(paidSx);
        if (isMissed) statusStyling.push(missedSx);
        if (isPending) statusStyling.push(pendingSx);

        let icon = <Iconography sx={iconSx} color="text.secondary" iconName="calendar" />;
        if (isPaid) icon = <Iconography sx={iconSx} color="success.dark" iconName="check" />;
        if (isMissed) icon = <Iconography sx={iconSx} color="error.main" iconName="times" />;

        return (
          <Stack sx={statusContainerSx} direction="row" alignItems="center">
            {icon}
            <LexicalTypography variant="body2" sx={statusStyling} translationKey={value}>
              {value}
            </LexicalTypography>
          </Stack>
        );
      },
    });
  }

  return <ProductDetailTable<PostTradeV2Event> height={height} columns={columns} rows={rows} loading={isLoading} />;
};
