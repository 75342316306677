import { ReactElement } from 'react';
import { FormEventHandler } from 'react';

import { Stack } from '@halodomination/halo-fe-common';
import { Button, Typography } from '@mui/material';

const mainButtonStyling = { color: 'common.white', backgroundColor: 'primary.main' };
const captionStyling = { color: 'text.secondary', fontStyle: 'italic' };

export type DisclaimerModalButtonProps = {
  disclaimerIsAccepted: boolean;
  isDisabled: boolean;
  disclaimerId: number;
  handleDisclaimerAcceptance: (id: number) => void;
  handleClose: () => void;
  disclaimerFormId?: string;
  handleSubmit?: FormEventHandler;
};

export const DisclaimerModalButton = ({
  disclaimerIsAccepted,
  isDisabled,
  disclaimerId,
  handleDisclaimerAcceptance,
  handleClose,
  disclaimerFormId,
}: DisclaimerModalButtonProps): ReactElement => {
  const agreeHandler = disclaimerFormId ? undefined : () => void handleDisclaimerAcceptance(disclaimerId);

  return !disclaimerIsAccepted ? (
    <Stack direction="row" justify="flex-end" spacing={2} alignItems="center">
      <Typography variant="caption" sx={captionStyling}>
        You must scroll to the bottom to agree.
      </Typography>

      <Button
        variant="contained"
        color="primary"
        type="submit"
        form={disclaimerFormId}
        sx={mainButtonStyling}
        onClick={agreeHandler}
        disabled={isDisabled}
      >
        Agree
      </Button>
    </Stack>
  ) : (
    <Stack direction="row" justify="flex-end">
      <Button variant="contained" color="primary" sx={mainButtonStyling} onClick={handleClose}>
        Close
      </Button>
    </Stack>
  );
};
