import { ReactElement } from 'react';

import { LexicalTypography, ProductNoteTypeChip } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { capitalize, Checkbox, Chip, FormControlLabel } from '@mui/material';

const checkboxSx = {
  padding: 0.5,
};

const checkboxLabelSx = {
  ml: 0,
};

const chipSx = {
  borderRadius: 1,
  fontSize: 12,
};

const nameSx = {
  fontSize: 12,
  lineHeight: 1,
  fontWeight: 600,
  letterSpacing: 0.16,
};

export type CheckboxTypeModel = {
  isChecked?: boolean;
  value: string;
  name: string;
};

export type GroupedCheckboxProps = {
  disableLocalization?: boolean;
  values?: Array<CheckboxTypeModel>;
  onChange: (value: string) => void;
  groupedCheckboxType?: 'default' | 'product' | 'productFeature';
  title?: string;
};

export const GroupedCheckbox = ({
  values = [],
  onChange,
  groupedCheckboxType = 'default',
  title,
  disableLocalization = false,
}: GroupedCheckboxProps): ReactElement => {
  const handleOnChange = (value: string) => {
    onChange(value);
  };

  const groupedCheckboxTitle = title ? (
    <LexicalTypography variant="overline" color="text.secondary">
      {title}
    </LexicalTypography>
  ) : null;

  const mappedCheckbox =
    values.length > 0 ? (
      values.map((value) => {
        const isChecked = value.isChecked;
        const fontWeight = isChecked ? 'fontWeightMedium' : undefined;
        const name = capitalize(value.name);

        const chipLabelComponent = (
          <Stack direction="row" alignItems="center" spacing={1}>
            <LexicalTypography
              disableLocalization={disableLocalization}
              sx={nameSx}
              variant="body2"
              color="textSecondary"
              fontWeight={fontWeight}
            >
              {name}
            </LexicalTypography>
          </Stack>
        );

        const checkboxLabel = {
          default: (
            <LexicalTypography disableLocalization={disableLocalization} fontWeight={fontWeight} variant="body2">
              {name}
            </LexicalTypography>
          ),
          product: <ProductNoteTypeChip type={value.name} subtype={value.value} />,
          productFeature: <Chip size="small" sx={chipSx} label={chipLabelComponent} />,
        }[groupedCheckboxType];

        return (
          <FormControlLabel
            key={value.value}
            sx={checkboxLabelSx}
            control={<Checkbox sx={checkboxSx} onChange={() => handleOnChange(value.value)} checked={isChecked} />}
            label={checkboxLabel}
          />
        );
      })
    ) : (
      <LocalizedTypography variant="body2" color="textSecondary" ml={1} mt={1}>
        {translations.messages.noAvailableFilters}
      </LocalizedTypography>
    );

  return (
    <Stack direction="column">
      {groupedCheckboxTitle}
      {mappedCheckbox}
    </Stack>
  );
};
