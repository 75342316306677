import { UserAlertModel } from '@halo-common/models';
import { putUserAlerts } from '@halo-data-sources/clients';
import { ApiUserAlertsMapper } from '@halo-data-sources/mappers';
import { ApiUserAlertPutRequestModel } from '@halo-data-sources/models';
import { UserQueryKeyFactory } from '@halo-data-sources/queries';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

export type UpdateUserAlertMutationPayload = {
  id?: string;
  emailEnabled?: boolean;
  notificationEnabled?: boolean;
  disableProfileEmail?: boolean;
};

const updateUserAlertMutationFn = async (payload: UpdateUserAlertMutationPayload) => {
  if (!payload.id) return null;

  const options: Partial<ApiUserAlertPutRequestModel> = {
    notification: payload?.notificationEnabled,
  };

  if (!payload?.disableProfileEmail) options.email = payload?.emailEnabled;

  const response = await putUserAlerts(payload.id, options);

  return ApiUserAlertsMapper.toUserAlert(response);
};

export const useUpdateUserAlertMutation = (): UseMutationResult<
  UserAlertModel | null,
  Error,
  UpdateUserAlertMutationPayload
> => {
  const queryClient = useQueryClient();

  return useMutation<UserAlertModel | null, Error, UpdateUserAlertMutationPayload>({
    mutationFn: updateUserAlertMutationFn,
    onMutate: (payload) => {
      if (!payload.id) return;

      const key = UserQueryKeyFactory.alerts();

      queryClient.setQueryData<Array<UserAlertModel>>(key, (prev) => {
        if (!prev) return undefined;

        const { id, emailEnabled, notificationEnabled } = payload;

        const alerts = [...prev];
        const index = alerts.findIndex((alert) => alert.id === id);

        const oldEmailEnabled = alerts[index].emailEnabled;
        const updatedEmailEnabled = emailEnabled !== undefined ? emailEnabled : oldEmailEnabled;

        const oldNotificationEnabled = alerts[index].notificationEnabled;
        const updatedNotificationEnabled =
          notificationEnabled !== undefined ? notificationEnabled : oldNotificationEnabled;

        alerts.splice(index, 1, {
          ...alerts[index],
          emailEnabled: updatedEmailEnabled,
          notificationEnabled: updatedNotificationEnabled,
        });

        return alerts;
      });
    },
    onError: (_, payload) => {
      if (!payload.id) return;

      const key = UserQueryKeyFactory.alerts();

      queryClient.setQueryData<Array<UserAlertModel>>(key, (prev) => {
        if (!prev) return undefined;

        const { id, emailEnabled, notificationEnabled } = payload;

        const alerts = [...prev];
        const index = alerts.findIndex((alert) => alert.id === id);

        const oldEmailEnabled = alerts[index].emailEnabled;
        const revertedEmailEnabled = emailEnabled !== undefined ? !emailEnabled : oldEmailEnabled;

        const oldNotificationEnabled = alerts[index].notificationEnabled;
        const revertedNotificationEnabled =
          notificationEnabled !== undefined ? !notificationEnabled : oldNotificationEnabled;

        alerts.splice(index, 1, {
          ...alerts[index],
          emailEnabled: revertedEmailEnabled,
          notificationEnabled: revertedNotificationEnabled,
        });

        return alerts;
      });
    },
    onSuccess: (data) => {
      if (!data) return;

      const key = UserQueryKeyFactory.alerts();

      queryClient.setQueryData<Array<UserAlertModel>>(key, (prev) => {
        if (!prev) return undefined;

        const alerts = [...prev];
        const index = alerts.findIndex((alert) => alert.id === data.id);
        const updatedAlert = { ...alerts[index], ...data };

        alerts.splice(index, 1, updatedAlert);

        return alerts;
      });
    },
  });
};
