import { AssetIdentifierEnum, SortModelDirectionEnum, SortModelNullSortEnum } from '@halo-common/enums';
import { useHaloInfiniteQuery } from '@halo-common/hooks';
import { AssetPositionModel, InfinitePaginationModel, InfiniteQueryOptions } from '@halo-common/models';
import { postPositionsBySearch } from '@halo-data-sources/clients';
import { ApiAssetIdentifierEnum, ApiComparisonOptionEnum, ApiComparisonTypeEnum } from '@halo-data-sources/enums';
import { ApiPositionsMapper, PaginationMapper } from '@halo-data-sources/mappers';
import { PortfolioQueryKeyFactory } from '@halo-data-sources/queries';

export type PortfolioPositionAssetQueryModel = {
  assetSearch: string;
  accountIds?: Array<number>;
  householdIds?: Array<number>;
  assetIdentifiers: Array<string>;
  synced: boolean | null;
};

export type GetPortfolioPositionAssetSearchInfiniteResult = {
  pagination: InfinitePaginationModel;
  positions: Array<AssetPositionModel>;
};

const DEFAULT_PAGINATION = {
  totalResults: 0,
  page: 1,
  totalPages: 0,
  next: 1,
  resultsPerPage: 15,
};

const getPortfolioPositionAssetSearchQueryFn = async (
  payload: PortfolioPositionAssetQueryModel,
  pageParam: InfinitePaginationModel,
) => {
  if (!payload.assetSearch) return { positions: [], pagination: DEFAULT_PAGINATION };

  const assetIdentifiers = payload.assetIdentifiers.map(
    (assetId) => ApiAssetIdentifierEnum[assetId as keyof typeof ApiAssetIdentifierEnum],
  );

  const shortNameField = 'position_summary.short_name';
  const ExtendedAssetIdentifierEnum = {
    ...AssetIdentifierEnum,
    FUNDSERVCODE: 'fundservcode',
  } as const;

  const accountSelector = {
    account_ids: payload.accountIds,
    advisee_ids: payload.householdIds,
    synced: payload.synced,
  };

  const comparisons = [
    {
      field: shortNameField,
      value: payload.assetSearch,
      op: ApiComparisonOptionEnum.ILIKE,
    },
    ...assetIdentifiers.map((identifyer) => ({
      field: `asset_identifiers.${ExtendedAssetIdentifierEnum[identifyer]}`,
      value: payload.assetSearch,
      op: ApiComparisonOptionEnum.ILIKE,
    })),
  ];

  const sort = [
    {
      direction: SortModelDirectionEnum.asc,
      nulls: SortModelNullSortEnum.first,
      field: shortNameField,
    },
  ];

  const searchPayload = {
    account_selector: accountSelector,
    page_length: DEFAULT_PAGINATION.resultsPerPage,
    page: pageParam.next,
    comparison_type: ApiComparisonTypeEnum.OR,
    comparisons: comparisons,
    sort: sort,
  };

  const response = await postPositionsBySearch(searchPayload);
  const pagination = PaginationMapper.toInfinitePaginationModel(response.pagination);
  const positions = response.results
    .map(ApiPositionsMapper.toAssetPosition)
    .filter((assets) => assets.assetIds.length !== 0);

  return {
    pagination,
    positions,
  };
};

export const usePortfolioPositionAssetSearchQuery = (
  payload: PortfolioPositionAssetQueryModel,
  options?: InfiniteQueryOptions<GetPortfolioPositionAssetSearchInfiniteResult>,
) =>
  useHaloInfiniteQuery<GetPortfolioPositionAssetSearchInfiniteResult>({
    initialPageParam: DEFAULT_PAGINATION,
    queryKey: PortfolioQueryKeyFactory.asset(payload),
    queryFn: ({ pageParam }) => getPortfolioPositionAssetSearchQueryFn(payload, pageParam),
    ...options,
  });
