import { ReactElement } from 'react';

import { ActionButton } from '@halo-common/components';
import { usePershingAdminContext } from '@halo-modules/admin';
import { PershingSelectors, PershingStatusEnum } from '@halo-stores/Pershing';
import { Iconography } from '@halodomination/halo-fe-common';
import { Skeleton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

export type AdminCalendarCsvButtonProps = {
  onDownload: () => void;
};

export const AdminCalendarCsvButton = ({ onDownload }: AdminCalendarCsvButtonProps): ReactElement => {
  const { pageLoading } = usePershingAdminContext();

  const pershingStatus = useSelector(PershingSelectors.selectStatus);

  const isRequestingDownload = pershingStatus === PershingStatusEnum.requestDownloadPershingCalendarCsv;

  return pageLoading ? (
    <Skeleton width={133} height={40} />
  ) : (
    <ActionButton
      type="button"
      variant="text"
      color="primary"
      onClick={onDownload}
      loading={isRequestingDownload}
      startIcon={<Iconography prefix="fas" iconName="download" color="primary.main" size="1.25x" />}
    >
      <Typography variant="button" color="primary">
        Download CSV
      </Typography>
    </ActionButton>
  );
};
