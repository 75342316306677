import { ReactElement } from 'react';

import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { AccountTypeAhead, AccountTypeAheadOption } from '@halo-common/smartComponents';
import { ACCOUNT_TYPE_AHEAD_ALL_OPTION } from '@halo-modules/app';
import { IconographyProps } from '@halodomination/halo-fe-common';
import { useAtom } from 'jotai';

export type OrderAccountFieldProps = {
  disableHouseholds?: boolean;
};

export const OrderAccountField = ({ disableHouseholds = true }: OrderAccountFieldProps): ReactElement => {
  const [searchFields, setSearchFields] = useAtom(orderBookQueryAtom);

  const actions = [
    {
      label: ACCOUNT_TYPE_AHEAD_ALL_OPTION,
      icon: 'universal-access' as IconographyProps['iconName'],
      clearedOnSelection: false,
    },
  ];

  const selectAccountHandler = (option?: AccountTypeAheadOption | null) => {
    const parsedOption = option ?? null;

    setSearchFields({
      filters: {
        account: parsedOption,
      },
    });
  };

  return (
    <AccountTypeAhead
      value={searchFields.filters.account || null}
      onSelect={selectAccountHandler}
      label="Account"
      actions={actions}
      rules={{ disableHouseholds }}
    />
  );
};
