import { ReactElement } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { ACCOUNT_MODAL, accountModalAtom } from '@halo-atoms/portfolio';
import { AddAccountModal } from '@halo-common/modals';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

export const PortfolioAddAccountModal = (): ReactElement => {
  const [accountModal, setAccountModal] = useAtom(accountModalAtom);
  const onModalToggle = useSetAtom(modalAtom);

  const { modalStepMap, selectedAccountAtom, open } = accountModal;
  const { currentPageAtom, handleResetAtom } = modalStepMap;

  const handleReset = useSetAtom(handleResetAtom);
  const step = useAtomValue(currentPageAtom);

  const handleAccountModalReset = () => {
    handleReset();
  };

  const handleAccountModalClose = () => {
    handleReset();
    setAccountModal();
  };

  const handleAccountModalBack = () => {
    onModalToggle({ addAccountOrPositionsModal: true, [ACCOUNT_MODAL]: false });
  };

  const handleAccountSuccess = () => {
    onModalToggle({ addAccountOrPositionsModal: false });
    setAccountModal();
    handleReset();
  };

  return (
    <AddAccountModal
      onBack={handleAccountModalBack}
      onClose={handleAccountModalClose}
      onReset={handleAccountModalReset}
      onSuccess={handleAccountSuccess}
      open={open}
      selected={selectedAccountAtom}
      size="medium"
      step={step}
    />
  );
};
