import { OrderBookNoteTypesModel } from '@halo-common/models';
import { getOrderBookCalendarsNoteTypes } from '@halo-data-sources/clients';
import { ApiOrderBookMapper } from '@halo-data-sources/mappers';
import { OrderBookCalendarQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getOrderBookCalendarsNoteTypesFn = async () => {
  const response = await getOrderBookCalendarsNoteTypes();

  return ApiOrderBookMapper.toOrderBookNoteTypesModel(response);
};

export const useOrderBookCalendarsNoteTypeQuery = (): UseQueryResult<OrderBookNoteTypesModel, Error> =>
  useQuery<OrderBookNoteTypesModel, Error>({
    queryKey: OrderBookCalendarQueryKeyFactory.noteTypes(),
    queryFn: () => getOrderBookCalendarsNoteTypesFn(),
  });
