import { ReactElement, useState } from 'react';

import { ContactModal } from '@halo-common/modals';
import { ContactHaloSchema } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { useHaloContactUsMutation } from '@halo-data-sources/mutations/onboarding';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedButton, useCombinedStyling } from '@halodomination/halo-fe-common';
import { ButtonProps } from '@mui/material';

const buttonSx = { color: 'primary.contrastText', borderColor: 'common.white' };

export type ConnectedContactButtonProps = Omit<ButtonProps, 'onSubmit'>;

export const ConnectedContactButton = ({ sx, ...props }: ConnectedContactButtonProps): ReactElement => {
  const [open, setOpen] = useState(false);

  const { data: userMeta } = useUserInfoQuery();
  const { mutate, isPending, isSuccess, isError, reset } = useHaloContactUsMutation();

  const combinedSx = useCombinedStyling(buttonSx, sx);

  const user = userMeta?.details ?? null;

  const onSubmit = (data: ContactHaloSchema) => {
    mutate(data);
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    reset();
    setOpen(false);
  };

  return (
    <>
      <LocalizedButton {...props} sx={combinedSx} onClick={onOpen}>
        {translations.common.contactUs}
      </LocalizedButton>
      <ContactModal
        isError={isError}
        isLoading={isPending}
        isSuccess={isSuccess}
        user={user}
        open={open}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </>
  );
};
