import { ReactElement } from 'react';

import { useCalendarSpecificDetails, useLexiconTranslator } from '@halo-common/hooks';
import {
  ProductDetailPanelCommissionSection,
  ProductDetailPanelLayout,
  ProductDetailPanelPayoffSection,
  ProductDetailPanelPrincipalSection,
  ProductDetailPanelUnderlyingSection,
} from '@halo-common/layouts';
import { ExecutionCalendarDetailLifecycleSection } from '@halo-common/modals';
import { CalendarModel } from '@halo-common/models';

export type ExecutionCalendarDetailModalPanelProps = {
  product?: CalendarModel | null;
  loading?: boolean;
};

export const ExecutionCalendarDetailModalPanel = ({
  product,
  loading = true,
}: ExecutionCalendarDetailModalPanelProps): ReactElement => {
  const note = product?.note;
  const underlyings = note?.tradables;
  const weights = note?.tradableWeights;
  const protectionType = note?.protectionType;
  const currency = note?.currencyCode;

  const { fieldMap, term, protectionDetails, payoffDetails } = useCalendarSpecificDetails(product);

  const localization = {
    protection: protectionDetails?.[0]?.dynamicContent,
    term: term.dynamicContent,
    gearing: protectionDetails?.[0]?.dynamicContent,
  };

  const principalProtection = protectionDetails?.[0];
  const translatedProtectionType = useLexiconTranslator(protectionType);

  const hasPayoffDetails = payoffDetails.length !== 0;
  const showPayoffSection = loading || hasPayoffDetails;
  const payoffSection = showPayoffSection ? (
    <ProductDetailPanelPayoffSection fields={payoffDetails} loading={loading} />
  ) : null;

  const hasCommissionDetails = Boolean(fieldMap.commission.text);
  const showCommissionSection = loading || hasCommissionDetails;
  const commissionSection = showCommissionSection ? (
    <ProductDetailPanelCommissionSection field={fieldMap.commission} loading={loading} />
  ) : null;

  const showLifecycleSection = loading || product;
  const lifecycleSection = showLifecycleSection ? (
    <ExecutionCalendarDetailLifecycleSection product={product} loading={loading} />
  ) : null;

  const slots = {
    underlyings: <ProductDetailPanelUnderlyingSection underlyings={underlyings} weights={weights} loading={loading} />,
  };

  return (
    <ProductDetailPanelLayout slots={slots} loading={loading}>
      <ProductDetailPanelPrincipalSection
        term={term?.text}
        protection={principalProtection?.text}
        protectionType={translatedProtectionType[0]}
        currency={currency}
        loading={loading}
        Localization={localization}
      />
      {commissionSection}
      {payoffSection}
      {lifecycleSection}
    </ProductDetailPanelLayout>
  );
};
