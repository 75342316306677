import { ReactElement } from 'react';

import { portfolioLifecycleManagerAtom, portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import { PortfolioCashflowBarChart, PortfolioCashflowChartPeriodDropdownFilter } from '@halo-common/components';
import { PortfolioPeriodEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { useLifecycleEventChartQuery } from '@halo-data-sources/queries';
import { ACCOUNT_TYPE_AHEAD_SYNCED_OPTION, ACCOUNT_TYPE_AHEAD_UNSYNCED_OPTION } from '@halo-modules/app';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { useAtom, useAtomValue } from 'jotai';
import { DateTime } from 'luxon';

/** Returns the start and end date based on the selected `period` */
const getStartEndDate = (period: PortfolioPeriodEnum) => {
  const startDate = DateTime.now();

  // Next 4 years
  let endDate = startDate.plus({ years: 4 }).endOf('year');
  // Next 2 years
  if (period === PortfolioPeriodEnum.Quarterly) endDate = startDate.plus({ months: 21 }).endOf('month');
  // Next 1 year
  else if (period === PortfolioPeriodEnum.Monthly) endDate = startDate.plus({ months: 11 }).endOf('month');

  return { startDate, endDate };
};

/** Returns the `synced` based on the action filter */
const getIsSyncedOption = (action: string | null | undefined) => {
  if (action === ACCOUNT_TYPE_AHEAD_SYNCED_OPTION) return true;
  if (action === ACCOUNT_TYPE_AHEAD_UNSYNCED_OPTION) return false;
  return undefined;
};

export type PortfolioCashflowChartProps = {
  loading?: boolean;
};

export const PortfolioCashflowChart = ({ loading }: PortfolioCashflowChartProps): ReactElement => {
  const { filters } = useAtomValue(portfolioPositionsManagerAtom);
  const [{ period }, setPeriod] = useAtom(portfolioLifecycleManagerAtom);

  const { accountOption, currency } = filters;
  const accountId = accountOption?.account?.id;
  const householdId = accountOption?.household?.id;

  const isSyncedOption = getIsSyncedOption(accountOption?.action);

  const accountIds = accountId ? [accountId] : [];
  const currencyId = currency?.id ?? 0;

  const { startDate, endDate } = getStartEndDate(period);

  const { data: chartData, isFetching } = useLifecycleEventChartQuery({
    currencyId,
    householdId,
    accountIds,
    synced: isSyncedOption,
    frequency: period,
    startDate: startDate.toFormat('yyyy-LL-dd'),
    endDate: endDate.toFormat('yyyy-LL-dd'),
  });

  const isLoading = loading || isFetching;

  return (
    <Stack direction="column" spacing={2} justify="center">
      <Stack direction="row" xs={[2, 2]} justify="space-between" alignItems="flex-end">
        <LocalizedTypography variant="h6" color="primary.black">
          {translations.portfolio.lifecycle.chartHeading}
        </LocalizedTypography>
        <PortfolioCashflowChartPeriodDropdownFilter period={period} setPeriod={(period) => setPeriod({ period })} />
      </Stack>
      <PortfolioCashflowBarChart chartData={chartData} loading={isLoading} currency={currency} period={period} />
    </Stack>
  );
};
