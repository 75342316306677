import { ReactElement } from 'react';

import { ProductDetailModalCreateSimilarButton } from '@halo-common/layouts';
import { Stack } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';

export type ExecutionAuctionReviewDetailModalHeaderActionsProps = {
  noteId?: number;
  loading?: boolean;
};

export const ExecutionAuctionReviewDetailModalHeaderActions = ({
  noteId,
  loading = true,
}: ExecutionAuctionReviewDetailModalHeaderActionsProps): ReactElement | null => {
  const buttonSx = {
    pt: { md: 0, xs: 2 },
    pb: { md: 2, xs: 0 },
    pr: { md: 0, xs: 2 },
    pl: { md: 2, xs: 0 },
  };

  if (loading) {
    return (
      <Stack direction="row" elementStyling={buttonSx} justifyElements="flex-end">
        <Skeleton width={100} height={42} />
      </Stack>
    );
  }

  if (!noteId) return null;

  return (
    <Stack direction="row" elementStyling={buttonSx} justifyElements="flex-end">
      <ProductDetailModalCreateSimilarButton noteId={noteId} />
    </Stack>
  );
};
