export const AUCTIONS = {
  addAccount: 'Add Account',
  uploadSpreadsheet: 'Upload Spreadsheet',
  accountsCount: 'Accounts: {count}',
  total: 'Total: {total}',
  submittedAllocationsSuccessfully: 'Allocations submitted successfully',
  deletedAllocationsSuccessfully: 'Allocation deleted successfully',
  downloadTemplate: 'Download Template',
  allocationRequiredError: 'Add an allocation',
  accountRequiredError: 'Select an account',
  quantityRequiredError: 'Notional must at least be 1,000',
  duplicateSelectedError: 'You have already selected this account',
  uploadAllocationsTitle: 'Upload file',
  uploadAllocationsSubtitle: 'Uploading a file here will replace all previously entered spreads.',
  submittedAllocations: 'Submitted Allocations',
  totalNotional: 'Total Notional:',
};
