import { ReactElement } from 'react';

import { Iconography, Stack, Tooltip } from '@halodomination/halo-fe-common';
import { Box, Typography } from '@mui/material';

export type EducationHubTableInfoHeaderProps = {
  name?: string;
  info: string;
};

export const EducationHubTableInfoHeader = ({ name = '', info }: EducationHubTableInfoHeaderProps): ReactElement => (
  <Stack direction="row" spacing={1} alignItems="center">
    <Typography variant="caption" fontWeight="bold">
      {name}
    </Typography>
    <Tooltip title={info} placement="top">
      <Box component="span">
        <Iconography iconName="circle-info" prefix="fal" color="text.secondary" />
      </Box>
    </Tooltip>
  </Stack>
);
