import { ReactElement } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { CollapsibleFilters, GroupedCheckbox } from '@halo-common/components';
import { DynamicTypeFiltersSideBarTitlesDict, UnderlyingCountSliderFilters } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { Stack } from '@mui/material';
import { useAtom } from 'jotai';

export type UnderlyingCountFiltersProps = {
  tag: string;
};

export const UnderlyingCountFilters = ({ tag }: UnderlyingCountFiltersProps): ReactElement => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);

  const handleCheckboxChange = (value: string) => {
    setSideBarFilters({ tag, checkbox: value });
  };

  const principalProtectionTypeCheckboxes = [
    {
      isChecked: sideBarFilters.checkboxes.averageBasket,
      value: 'averageBasket',
      name: translations.dynamicFilters.common.average,
    },
    {
      isChecked: sideBarFilters.checkboxes.singleBasket,
      value: 'singleBasket',
      name: translations.dynamicFilters.common.single,
    },
    {
      isChecked: sideBarFilters.checkboxes.weightedBasket,
      value: 'weightedBasket',
      name: translations.dynamicFilters.common.weighted,
    },
    {
      isChecked: sideBarFilters.checkboxes.worstOfBasket,
      value: 'worstOfBasket',
      name: translations.dynamicFilters.common.worstOf,
    },
  ];

  return (
    <CollapsibleFilters title={DynamicTypeFiltersSideBarTitlesDict.underlyingCount}>
      <Stack direction="column" spacing={2}>
        <UnderlyingCountSliderFilters tag={tag} />
        <GroupedCheckbox onChange={handleCheckboxChange} values={principalProtectionTypeCheckboxes} />
      </Stack>
    </CollapsibleFilters>
  );
};
