import { ReactElement } from 'react';

import { StatusChip, StatusChipProps } from '@halo-common/components';
import { OrderBookAuctionStatusDisplayEnum, OrderBookAuctionStatusEnum } from '@halo-common/enums';

const getStatus = (status: OrderBookAuctionStatusEnum): StatusChipProps['status'] => {
  switch (status) {
    case OrderBookAuctionStatusEnum['purchased']:
      return 'completed';

    case OrderBookAuctionStatusEnum['not-win']:
    case OrderBookAuctionStatusEnum['expired']:
    case OrderBookAuctionStatusEnum['canceled']:
      return 'closed';

    case OrderBookAuctionStatusEnum['review_terms']:
    case OrderBookAuctionStatusEnum['choose-winner']:
      return 'action-required';

    default:
      return 'pending';
  }
};

export type OrderBookAuctionStatusProps = {
  value: string;
  message?: string;
};

export const OrderBookAuctionStatus = ({ value, message }: OrderBookAuctionStatusProps): ReactElement => {
  const auctionLabel = OrderBookAuctionStatusDisplayEnum[value as keyof typeof OrderBookAuctionStatusDisplayEnum];
  const auctionStatus = OrderBookAuctionStatusEnum[value as keyof typeof OrderBookAuctionStatusEnum];

  const status = getStatus(auctionStatus);

  return <StatusChip label={auctionLabel} status={status} message={message} />;
};
