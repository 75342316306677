import { ReactElement } from 'react';

import { EstimatedTransactionSection, OrderReviewCard, OrderReviewSection } from '@halo-common/components';
import { HOUR_MINUTE_SECONDS_TIME_ZONE_FORMAT, MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { AccountDesignationEnum } from '@halo-common/enums';
import { useNoteDetailCaption, useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { AccountModel, ExecutionOrderModel, OrderTicketReceiptSummaryModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useCalendarQuery } from '@halo-data-sources/queries';
import { useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Box, Stack } from '@mui/material';

export type ExecutionOrderReceiptProps = {
  calendarId?: number | null;
  order?: ExecutionOrderModel;
  account?: AccountModel;
  summary?: OrderTicketReceiptSummaryModel;
  wholesalerPrice?: number;
};

export const ExecutionOrderReceipt = ({
  calendarId,
  order,
  account,
  summary,
}: ExecutionOrderReceiptProps): ReactElement => {
  const { data: calendar } = useCalendarQuery(calendarId);

  const note = calendar?.note;
  const quantity = order?.quantity;
  const originalPrice = order?.originalPrice ?? calendar?.wholesaler?.salePrice;
  const isAdvisoryAccountSelected = account?.designation === AccountDesignationEnum.Advisory;
  const isBrokerageAccountSelected = account?.designation === AccountDesignationEnum.Brokerage;

  const configureDateTime = useWhiteLabelDateConfiguration();

  const reviewCardSubtitle = useNoteDetailCaption({ note, issuer: calendar?.issuer, category: calendar?.category });
  const [wholesalerOfferingPrice] = useCurrencyConverter(originalPrice);

  const accountTypeText = isAdvisoryAccountSelected
    ? translations.common.advisory
    : isBrokerageAccountSelected
      ? translations.common.brokerage
      : '-';

  const offeringPrice = wholesalerOfferingPrice || '-';

  const formattedSettlementDate = configureDateTime(calendar?.settleDate)?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
  const orderDateTime = configureDateTime(order?.orderReceiptTime);
  const formattedOrderDate = orderDateTime?.toFormat(
    `${MONTH_DAY_YEAR_DATE_FORMAT} ${HOUR_MINUTE_SECONDS_TIME_ZONE_FORMAT}`,
  );

  const underlyings = note?.tradables?.map((tradable) => tradable.name).join(', ') ?? '';
  const reviewCardTitle = `Buy ${quantity} Bond(s) of ${underlyings}`;

  return (
    <Stack direction="row" spacing={4}>
      <Box sx={{ flex: 2 }}>
        <OrderReviewCard title={reviewCardTitle} subtitle={reviewCardSubtitle}>
          <OrderReviewSection header="Account">{order?.accountId}</OrderReviewSection>
          <OrderReviewSection header="Account Type">{accountTypeText}</OrderReviewSection>
          <OrderReviewSection header="CUSIP">{calendar?.cusip ?? ''}</OrderReviewSection>
          <OrderReviewSection header="Transaction">{order?.transactionType}</OrderReviewSection>
          <OrderReviewSection header="Offering Price">{offeringPrice}</OrderReviewSection>
          <OrderReviewSection header="Solicited">{order?.solicited}</OrderReviewSection>
          <OrderReviewSection header="Quantity (in Thousands)">{quantity}</OrderReviewSection>
          <OrderReviewSection header="Time Received">{formattedOrderDate}</OrderReviewSection>
          <OrderReviewSection header="Settlement Date">{formattedSettlementDate}</OrderReviewSection>
          <OrderReviewSection header="Rep Code">{order?.ttoRepCode}</OrderReviewSection>
        </OrderReviewCard>
      </Box>
      <Box sx={{ flex: 1 }}>
        <EstimatedTransactionSection
          loading={!summary}
          principal={summary?.principal}
          compensation={summary?.salesCredit}
          miscFees={summary?.otherMiscFees}
          total={summary?.total}
        />
      </Box>
    </Stack>
  );
};
