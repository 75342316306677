export enum OrderBookCalendarDisplayStatusEnum {
  pending = 'Pending',
  canceled = 'Canceled',
  closed = 'Completed',
}

export enum OrderBookCalendarStatusEnum {
  pending = 'pending',
  canceled = 'canceled',
  closed = 'closed',
}

export enum OrderBookCalendarDisplayEnum {
  pending = 'Pending',
  canceled = 'Canceled',
  closed = 'Complete',
}

export enum OrderBookCalendarSortColumnsEnum {
  noteType = 'note_type',
  cusip = 'cusip',
  notional = 'notional',
  closes = 'expiration_date',
  expiration_date = 'expiration_date',
}

export enum OrderBookJanneyStatusFilterEnum {
  accepted = 'Approved',
  canceled = 'Canceled',
  filled = 'Filled',
  rejected = 'Rejected',
}

export enum OrderBookJanneyStatusEnum {
  accepted = 'accepted',
  canceled = 'canceled',
  filled = 'filled',
  rejected = 'rejected',
}

export enum OrderBookJanneyStatusDisplayEnum {
  accepted = 'Approved',
  canceled = 'Canceled',
  filled = 'Filled',
  rejected = 'Rejected',
}

export enum OrderBookJanneySortColumnsEnum {
  'executionOrderId' = 'exec_order_id',
  'account' = 'account_id',
  'designation' = 'account_designation',
  'user' = 'user_name',
  'calendar' = 'cusip',
  'issuer' = 'issuer_id',
  'updatedOn' = 'updated_since',
  'status' = 'status',
}

export enum OrderBookPershingSortColumnsEnum {
  account = 'account_id',
  calendar = 'cusip',
  category = 'category',
  ibd = 'ibd',
  issuer = 'issuer_id',
  closes = 'expiration_date',
  expiration_date = 'expiration_date',
  pershingOrderBookStatus = 'pershing_order_book_status',
  updatedOn = 'updated_since',
  user = 'user_name',
}

export enum OrderBookAuctionStatusEnum {
  'new' = 'new',
  'auction' = 'auction',
  'not-win' = 'not-win',
  'waiting_for_terms' = 'waiting_for_terms',
  'review_terms' = 'review_terms',
  'expired' = 'expired',
  'term_sheet_accepted' = 'term_sheet_accepted',
  'purchased' = 'purchased',
  'canceled' = 'canceled',
  'choose-winner' = 'choose-winner',
}

export enum OrderBookAuctionStatusDisplayEnum {
  'new' = 'Pending Approval',
  'pre-approval-required' = 'Pending Approval',
  'ops-approval-required' = 'Pending Approval',
  'auction' = 'Waiting for Bids',
  'not-win' = 'No Winner',
  'waiting_for_terms' = 'Waiting For Termsheet',
  'review_terms' = 'Review Termsheet',
  'expired' = 'Terms Expired',
  'term_sheet_accepted' = 'Booking',
  'purchased' = 'Complete',
  'canceled' = 'Canceled',
  'choose-winner' = 'Choose Winner',
}

export enum OrderBookAuctionStatusDropdownEnum {
  'Pending Approval' = 'new',
  'Waiting for Bids' = 'auction',
  'No Winner' = 'not-win',
  'Waiting For Termsheet' = 'waiting_for_terms',
  'Review Termsheet' = 'review_terms',
  'Terms Expired' = 'expired',
  'Booking' = 'term_sheet_accepted',
  'Complete' = 'purchased',
  'Canceled' = 'canceled',
  'Choose Winner' = 'choose-winner',
}

export enum OrderBookAuctionSortColumnsEnum {
  noteType = 'note_type',
  issuer = 'issuer',
  notional = 'notional',
  endDate = 'end_date',
  status = 'status',
}

export enum OrderBookAccountCapacityEnum {
  all = 'All',
  advisory = 'Advisory',
  brokerage = 'Brokerage',
}

export enum OrderBookTimeFrameEnum {
  oneDay = '1 Day',
  sevenDays = '7 Days',
  thirtyDays = '30 Days',
  ninetyDays = '90 Days',
  oneYear = '1 Year',
}

export enum OrderBookTabNameEnum {
  annuity = 'Annuity',
  auction = 'Auctions',
  calendar = 'Calendars',
  pershing = 'Pershing',
  janney = 'Calendars',
  sma = 'SMA',
}

export enum OrderBookTabKeyEnum {
  annuity = 'annuity',
  auction = 'auction',
  calendar = 'calendar',
  pershing = 'pershing',
  janney = 'janney',
  sma = 'sma',
}

export type OrderBookAuctionSortColumns = keyof typeof OrderBookAuctionSortColumnsEnum;
export type OrderBookJanneySortColumns = keyof typeof OrderBookJanneySortColumnsEnum;
export type OrderBookPershingSortColumns = keyof typeof OrderBookPershingSortColumnsEnum;
export type OrderBookCalendarSortColumns = keyof typeof OrderBookCalendarSortColumnsEnum;
export type OrderBookTabNames = keyof typeof OrderBookTabNameEnum;
export type OrderBookTimeFrame = keyof typeof OrderBookTimeFrameEnum;
export type OrderBookCalendarStatuses = keyof typeof OrderBookCalendarStatusEnum;
