import { UnderlyingBasketEnum } from '@halo-common/enums';
import {
  DocumentModel,
  TermsheetModel,
  V2CalendarCallEvent,
  V2CalendarCouponEvent,
  V2NoteCallEvent,
  V2NoteCouponEvent,
  V2NoteFeatureModel,
  V2NoteModel,
  V2NotePrincipalCalendarEntryModel,
  V2NoteUnderlyingModel,
} from '@halo-common/models';
import { ApiIssuerMapper } from '@halo-data-sources/mappers';
import {
  ApiTermsheetDocumentModel,
  ApiTermsheetModel,
  ApiTermsheetV2CalendarCallEventModel,
  ApiTermsheetV2CalendarCouponEventModel,
  ApiTermsheetV2FeatureDetailModel,
  ApiTermsheetV2NoteCallEventModel,
  ApiTermsheetV2NoteCouponEventModel,
  ApiTermsheetV2PrincipalCalendarEntryModel,
  ApiTermsheetV2ProductResponse,
  ApiTermsheetV2UnderlyingInstrumentModel,
} from '@halo-data-sources/models';
import { v4 as uuid } from 'uuid';

export interface ApiTermsheetMapperModel {
  toTermsheetModel(model: ApiTermsheetModel | null): TermsheetModel | null;
}

export const ApiTermsheetMapper: ApiTermsheetMapperModel = {
  toTermsheetModel(model) {
    if (!model) return null;

    const initialStrikePrices =
      model.initial_strike_prices?.reduce((obj, item) => ({ ...obj, [item.tradable]: item.price }), {}) ?? {};

    return {
      id: model.id,
      noteId: model.note_id,
      issuer: ApiIssuerMapper.toIssuerModel(model.issuer),
      documents: TermsheetDocumentsMapper.toTermsheetDocumentsModel(model.documents),
      path: model.path,
      maturityDate: model.maturity_date,
      finalValuationDate: model.final_valuation_date,
      issueDate: model.issue_date,
      initialFixing: model.pricing_date,
      initialStrikePrices,
      annualizedYield: model.annualized_yield,
      cusip: model.cusip,
      isin: model.isin,
      fundserv: model.fundservcode,
    };
  },
};

export interface ApiTermsheetV2MapperModel {
  toV2NoteModel(model: ApiTermsheetV2ProductResponse['product']): V2NoteModel;
  toTermDetails(model: ApiTermsheetV2ProductResponse['product']): V2NoteModel['term'];
  toPayoffDetails(model: ApiTermsheetV2ProductResponse['product']): V2NoteModel['payoffDetails'];
  toProtectionDetails(model: ApiTermsheetV2ProductResponse['product']): V2NoteModel['protection'];
  toLifecycleDetails(model: ApiTermsheetV2ProductResponse['product']): V2NoteModel['lifecycle'];
  toUnderlying(model: ApiTermsheetV2UnderlyingInstrumentModel): V2NoteUnderlyingModel;
  toCallEvent(model: ApiTermsheetV2NoteCallEventModel): V2NoteCallEvent;
  toCouponEvent(model: ApiTermsheetV2NoteCouponEventModel): V2NoteCouponEvent;
  toCalendarCouponEvent(model: ApiTermsheetV2CalendarCouponEventModel): V2CalendarCouponEvent;
  toCalendarCallEvent(model: ApiTermsheetV2CalendarCallEventModel): V2CalendarCallEvent;
  toV2FeatureModel(model: ApiTermsheetV2FeatureDetailModel): V2NoteFeatureModel;
  toPrincipalCalendarEntry(
    model: Array<ApiTermsheetV2PrincipalCalendarEntryModel>,
  ): Array<V2NotePrincipalCalendarEntryModel>;
}

export const ApiTermsheetV2Mapper: ApiTermsheetV2MapperModel = {
  toPayoffDetails(model) {
    const principalProtection = model.payoff_description.principal_protection?.[0];

    const solvableParameterPayoffs =
      model.payoff_description?.primary?.map((payoff) => ({
        name: payoff.name,
        value: payoff.value,
        when: payoff.when,
        description: payoff.description,
        label: payoff.label ?? '',
      })) ?? [];

    const solvableParameterPayoffConjunction = model.payoff_description?.primary?.find(
      (payoff) => payoff.conjunction,
    )?.conjunction;

    const solvableParameterPayoffs2 =
      model.payoff_description?.primary_2?.map((payoff) => ({
        name: payoff.name,
        value: payoff.value,
        when: payoff.when,
        description: payoff.description,
        label: payoff.label ?? '',
      })) ?? [];

    const solvableParameterPayoffConjunction2 = model.payoff_description?.primary_2?.find(
      (payoff) => payoff.conjunction,
    )?.conjunction;

    const highlightPayoffs =
      model.payoff_description?.secondary?.map((payoff) => ({
        name: payoff.name,
        value: payoff.value,
        when: payoff.when,
        description: payoff.description,
        label: principalProtection?.label ?? '',
      })) ?? [];

    const highlightPayoffConjunction = model.payoff_description?.secondary?.find(
      (payoff) => payoff.conjunction,
    )?.conjunction;

    return {
      solvableParameter: {
        payoffs: solvableParameterPayoffs,
        conjunction: solvableParameterPayoffConjunction,
      },
      alternateSolvableParameterFormat: {
        payoffs: solvableParameterPayoffs2,
        conjunction: solvableParameterPayoffConjunction2,
      },
      highlight: {
        payoffs: highlightPayoffs,
        conjunction: highlightPayoffConjunction,
      },
    };
  },
  toProtectionDetails(model) {
    const principalProtection = model.payoff_description.principal_protection?.[0];

    return principalProtection
      ? {
          name: principalProtection.name,
          value: principalProtection.value,
          description: principalProtection.unformatted.description,
          label: principalProtection.label,
        }
      : undefined;
  },
  toTermDetails(model) {
    const termDetails = model.payoff_description.universal?.find((payoff) => payoff.name.toLowerCase() === 'term');
    const description = termDetails ? `{term} ${termDetails.label}` : '{term} Months';
    const dynamicContent = { term: termDetails?.value ?? model.term };

    return {
      value: model.term,
      description,
      dynamicContent,
    };
  },
  toCallEvent(event) {
    const calledStatus = event.called?.toLowerCase();

    let status: V2NoteCallEvent['status'] = 'pending';
    if (calledStatus === 'called') status = 'called';
    else if (calledStatus === 'not called') status = 'not called';

    return {
      id: uuid(),
      status,
      settlementDate: event.recall_date,
      observationDate: event.review_date,
      return: event.performance,
      level: event.level,
    };
  },
  toCalendarCallEvent(event) {
    return {
      id: uuid(),
      level: event.level,
      month: event.month,
    };
  },
  toCouponEvent(event) {
    const calledStatus = event.status.toLowerCase();

    let status: V2NoteCouponEvent['status'] = 'pending';
    if (calledStatus === 'missed') status = 'missed';
    else if (calledStatus === 'paid') status = 'paid';

    return {
      id: uuid(),
      observationDate: event.observation_date,
      settlementDate: event.settlement_date,
      amount: event.amount,
      status,
    };
  },
  toCalendarCouponEvent(event) {
    return {
      id: uuid(),
      couponPct: event.coupon_pct,
      couponCap: event.coupon_cap,
      couponFloor: event.coupon_floor,
      month: event.month,
    };
  },
  toPrincipalCalendarEntry(lifecycleDates) {
    const reducedDates = lifecycleDates.reduce((prev, current) => {
      const { name, settlement_date, observation_date } = current;
      let dateEntries = [] as Array<V2NotePrincipalCalendarEntryModel>;

      if (name === 'issue') {
        dateEntries = [
          { name: 'Pricing Date', date: observation_date },
          { name: 'Issue Date', date: settlement_date },
        ];
      } else dateEntries = [{ name: `${name} Date`, date: settlement_date }];

      return [...prev, ...dateEntries];
    }, [] as Array<V2NotePrincipalCalendarEntryModel>);

    return reducedDates;
  },
  toLifecycleDetails(model) {
    const couponEvents = model.lifecycle.coupon_calendar?.map((event) => ApiTermsheetV2Mapper.toCouponEvent(event));
    const callEvents = model.lifecycle.callability_calendar?.map((event) => ApiTermsheetV2Mapper.toCallEvent(event));
    const calendarCoupons = model.lifecycle.coupon?.map((event) => ApiTermsheetV2Mapper.toCalendarCouponEvent(event));
    const calendarCalls = model.lifecycle.callability?.map((event) => ApiTermsheetV2Mapper.toCalendarCallEvent(event));
    const lifecycleDates = model.lifecycle.principal_calendar
      ? ApiTermsheetV2Mapper.toPrincipalCalendarEntry(model.lifecycle.principal_calendar)
      : [];

    return {
      active: model.lifecycle.is_active,
      pricingDate: model.lifecycle.pricing_date,
      issueDate: model.lifecycle.issue_date,
      finalValuationDate: model.lifecycle.final_valuation_date,
      maturityDate: model.lifecycle.maturity_date,
      timeRemaining: model.lifecycle.time_remaining,
      couponEvents,
      callEvents,
      calendarCoupons,
      calendarCalls,
      lifecycleDates,
    };
  },
  toUnderlying(model) {
    return {
      ticker: model.symbol,
      value: model.initial_strike,
      weight: model.weight ? parseFloat(model.weight) * 100 : undefined,
    };
  },
  toV2FeatureModel(model) {
    const description = model.unformatted.description;
    const regexPattern = /{([A-Za-z0-9_^]+)}/g;
    const sanitized = description.replace(regexPattern, (match) => {
      return match.replace(/[_^]/g, '');
    });

    const keyArray = Object.keys(model.unformatted.format_dictionary);
    const formattedKeys = keyArray.map((key) => key.split('^').join('').split('_').join(''));
    const dynamicContent = formattedKeys.reduce((map, key, index) => {
      const originalKey = keyArray[index];
      return { ...map, [key]: model.unformatted.format_dictionary[originalKey] };
    }, {});

    return {
      name: model.unformatted.name,
      description: sanitized,
      dynamicContent,
    };
  },
  toV2NoteModel(model) {
    const features = model.payoff_description?.feature_details
      ? model.payoff_description.feature_details.map((feature) => ApiTermsheetV2Mapper.toV2FeatureModel(feature))
      : [];

    const identifiers = {
      cusip: model.identifiers.cusip,
      isin: model.identifiers.isin,
    };

    // TODO: Add more information here when available
    const issuer = { ergonomicId: model.issuer.ergonomic_id };
    const basketType = model.underlier.type as UnderlyingBasketEnum;
    const underlyings = model.underlier.instruments.map((instrument) => ApiTermsheetV2Mapper.toUnderlying(instrument));
    const triggerLevels = model.key_parameters?.trigger_levels ?? [];

    return {
      id: model.identifiers.legacy_termsheet_id[0],
      noteId: model.identifiers.legacy_note_id[0],
      currency: model.currency,
      features,
      identifiers,
      issueDate: model.issue_date,
      issuer,
      lifecycle: ApiTermsheetV2Mapper.toLifecycleDetails(model),
      maturityDate: model.maturity_date,
      name: model.short_name,
      notional: model.notional,
      payoffDetails: ApiTermsheetV2Mapper.toPayoffDetails(model),
      protection: ApiTermsheetV2Mapper.toProtectionDetails(model),
      price: model.price,
      term: ApiTermsheetV2Mapper.toTermDetails(model),
      type: model.notetype,
      underlyingBasketType: UnderlyingBasketEnum[basketType],
      underlyings,
      documents: TermsheetDocumentsMapper.toTermsheetDocumentsModel(model.documents),
      triggerLevels,
    };
  },
};

export interface TermsheetDocumentsMapperModel {
  toTermsheetDocumentsModel(documents: Array<ApiTermsheetDocumentModel>): Array<DocumentModel>;
}

export const TermsheetDocumentsMapper: TermsheetDocumentsMapperModel = {
  toTermsheetDocumentsModel(documents) {
    return (
      documents?.map(({ name, document }) => {
        const { id } = document;
        return { id, name, document };
      }) ?? []
    );
  },
};
