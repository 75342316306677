import { ReactElement } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { CollapsibleFilters, GroupedCheckbox } from '@halo-common/components';
import { DynamicTypeFiltersSideBarTitlesDict } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { useAtom } from 'jotai';

export type CalendarTypeFiltersProps = {
  tag: string;
};

export const CalendarTypeFilters = ({ tag }: CalendarTypeFiltersProps): ReactElement => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);

  const handleCheckboxChange = (value: string) => {
    setSideBarFilters({ tag, checkbox: value });
  };

  const calendarTypeCheckboxes = [
    {
      isChecked: sideBarFilters.checkboxes.advisory,
      value: 'advisory',
      name: translations.common.advisory,
    },
    {
      isChecked: sideBarFilters.checkboxes.highlighted,
      value: 'highlighted',
      name: translations.common.highlighted,
    },
    {
      isChecked: sideBarFilters.checkboxes.brokerage,
      value: 'brokerage',
      name: translations.common.brokerage,
    },
  ];

  return (
    <CollapsibleFilters title={DynamicTypeFiltersSideBarTitlesDict.calendarType}>
      <GroupedCheckbox onChange={handleCheckboxChange} values={calendarTypeCheckboxes} />
    </CollapsibleFilters>
  );
};
