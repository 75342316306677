import { ConditionalField, Input, Select } from '@halo-common/components/FormBuilder';
import { DocusignBooleanEnum, DocusignRegulatorEnum } from '@halo-common/enums/docusign';
import { translations } from '@halo-common/translations';
import { yesNoStep } from '@halo-modules/app/v2/onboarding/subPages/suitability/utils/yesNoStep';

const suitability = translations.onboarding.suitability;

const regulatorOptions = [
  { label: suitability.sec, value: DocusignRegulatorEnum.SEC },
  { label: suitability.finra, value: DocusignRegulatorEnum.FINRA },
  { label: suitability.other, value: DocusignRegulatorEnum.OTHER },
];

const conditionalYesContent = (
  <>
    <Select label={suitability.whoIsTheRegulator} name="firm_details.regulator_radio" options={regulatorOptions} />
    <ConditionalField field="firm_details.regulator_radio" value={DocusignRegulatorEnum.OTHER}>
      <Input label={suitability.pleaseSpecify} name="firm_details.regulator_yes_other_text" />
    </ConditionalField>
    <Input label={suitability.whatIsRegistrationNumber} name="firm_details.registration_number" />
  </>
);

export const isFirmRegulatedStep = yesNoStep({
  id: '1-6',
  title: suitability.isThisFirmRegulated,
  field: 'firm_details.is_firm_regulated_radio',
  conditionalYesContent,
  isCompleted: (formData) => {
    const firmDetails = formData?.firm_details || {};
    const isRegulated = firmDetails.is_firm_regulated_radio;
    return Boolean(
      isRegulated === DocusignBooleanEnum.NO ||
        (isRegulated === DocusignBooleanEnum.YES &&
          firmDetails.regulator_radio &&
          (firmDetails.regulator_radio !== DocusignRegulatorEnum.OTHER || firmDetails.regulator_yes_other_text) &&
          firmDetails.registration_number),
    );
  },
});
