import {
  fixedIncomeAllocationsModalTabAtom,
  fixedIncomeInventoryTotalAtom,
  fixedIncomeManagerAtom,
  fixedIncomeSelectedAtom,
} from '@halo-atoms/fixedIncome';
import { AllocationModel, CalendarModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { postCalendarAllocationRequest, submitCalendarAllocationRequest } from '@halo-data-sources/clients';
import { ApiCalendarsMapper } from '@halo-data-sources/mappers';
import { ApiCalendarAllocationModel } from '@halo-data-sources/models';
import {
  CalendarsQueryKeyFactory,
  FixedIncomeQueryKeyFactory,
  GetFixedIncomeInfiniteResult,
  OrderBookCalendarQueryKeyFactory,
} from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import {
  InfiniteData,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

export type FixedIncomeAllocationModel = {
  account?: number;
  quantity?: string | number;
  attestation?: boolean;
};

export type FixedIncomeAllocationMutationOptions = {
  calendarId?: number | null;
  calendarPageId?: number | null;
  allocations: Array<FixedIncomeAllocationModel>;
};

export type UseFixedIncomeAllocationMutationOptions = UseMutationOptions<
  CalendarModel | null,
  Error,
  FixedIncomeAllocationMutationOptions
>;

export type UseFixedIncomeAllocationMutationResult = UseMutationResult<
  CalendarModel | null,
  Error,
  FixedIncomeAllocationMutationOptions
>;

const updateInventory = (
  total: number,
  inventoryConsumed: number | null | undefined,
  inventoryRemaining: number | null | undefined,
) => {
  const hasConsumedInventory = typeof inventoryConsumed === 'number';
  const hasRemainingInventory = typeof inventoryRemaining === 'number';
  const updatedInventoryConsumed = hasConsumedInventory ? inventoryConsumed + total : undefined;
  const updatedInventoryRemaining = hasRemainingInventory ? inventoryRemaining - total : undefined;

  return { updatedInventoryConsumed, updatedInventoryRemaining };
};

const submitFixedIncomeAllocationMutation = async (options: FixedIncomeAllocationMutationOptions) => {
  if (typeof options.calendarId !== 'number' || typeof options.calendarPageId !== 'number') return null;

  const formattedAllocations = options.allocations.filter(
    (allocation) => typeof allocation.account === 'number' && typeof allocation.quantity === 'number',
  );

  const request = {
    calendar_id: options.calendarId.toString(),
    calendar_page_id: options.calendarPageId?.toString(),
    allocations: formattedAllocations as Array<ApiCalendarAllocationModel>,
  };

  const createResponse = await postCalendarAllocationRequest(request);
  const pendingAllocations = createResponse.allocations.map((allocation) => ({
    ...allocation,
    status: 'pending',
  }));

  const pendingAllocationsResponse = await submitCalendarAllocationRequest(
    createResponse.calendar.id,
    pendingAllocations,
  );

  return ApiCalendarsMapper.toCalendarModel(pendingAllocationsResponse.calendar);
};

export const useFixedIncomeAllocationMutation = (
  options?: UseFixedIncomeAllocationMutationOptions,
): UseFixedIncomeAllocationMutationResult => {
  const queryClient = useQueryClient();

  const { query } = useAtomValue(fixedIncomeManagerAtom);

  const [selectedFixedIncome, setSelectedFixedIncome] = useAtom(fixedIncomeSelectedAtom);
  const [fixedIncomeInventoryTotal, setFixedIncomeInventoryTotal] = useAtom(fixedIncomeInventoryTotalAtom);
  const setAllocationsModalTab = useSetAtom(fixedIncomeAllocationsModalTabAtom);

  const { enqueueSuccessEvent, enqueueErrorEvent } = useSnackbar();

  return useMutation({
    mutationFn: submitFixedIncomeAllocationMutation,
    ...options,
    onSettled: (...props) => {
      const [data, error] = props;
      if (error) enqueueErrorEvent({ message: translations.common.error });
      else if (data) enqueueSuccessEvent({ message: 'Allocations successfully allocated to calendar.' });
      void queryClient.invalidateQueries({ queryKey: OrderBookCalendarQueryKeyFactory.all() });
      options?.onSettled?.(...props);
    },
    onSuccess: (...props) => {
      const [data] = props;

      options?.onSuccess?.(...props);

      if (!data) return undefined;

      const allocationsKey = CalendarsQueryKeyFactory.allocations(selectedFixedIncome?.id);
      queryClient.setQueryData<Array<AllocationModel>>(allocationsKey, (prev) => {
        if (!prev) return undefined;
        return [...data.allocations];
      });

      const fixedIncomeKey = FixedIncomeQueryKeyFactory.fixedIncome(selectedFixedIncome?.id);
      queryClient.setQueryData<CalendarModel>(fixedIncomeKey, (prev) => {
        if (!prev) return undefined;

        const { activeAllocationCount, allocations } = data;

        const { inventoryConsumed, inventoryRemaining } = prev;

        const { updatedInventoryConsumed, updatedInventoryRemaining } = updateInventory(
          fixedIncomeInventoryTotal,
          inventoryConsumed,
          inventoryRemaining,
        );

        const updatedCalendar = {
          ...prev,
          allocations,
          activeAllocationCount,
          inventoryConsumed: updatedInventoryConsumed,
          inventoryRemaining: updatedInventoryRemaining,
        };

        return updatedCalendar;
      });

      const payload = { ...query };
      const fixedIncomeListKey = FixedIncomeQueryKeyFactory.fixedIncomeList(payload);
      queryClient.setQueryData<InfiniteData<GetFixedIncomeInfiniteResult>>(fixedIncomeListKey, (prev) => {
        if (!prev) return prev;

        const { id, activeAllocationCount, allocations } = data;

        const updatedPages = [...prev.pages];
        const pageIndex = updatedPages.findIndex(({ calendars }) => calendars.some((calendar) => calendar.id === id));

        if (pageIndex < 0) return prev;

        const updatedPage = { ...updatedPages[pageIndex] };

        const updatedPageCalendars = [...updatedPage.calendars];

        const calendarIndex = updatedPageCalendars.findIndex((calendar) => calendar.id === id);
        const prevCalendar = updatedPageCalendars[calendarIndex];

        const { inventoryConsumed, inventoryRemaining } = prevCalendar;
        const { updatedInventoryConsumed, updatedInventoryRemaining } = updateInventory(
          fixedIncomeInventoryTotal,
          inventoryConsumed,
          inventoryRemaining,
        );

        const updatedCalendar = {
          ...prevCalendar,
          allocations,
          activeAllocationCount,
          inventoryConsumed: updatedInventoryConsumed,
          inventoryRemaining: updatedInventoryRemaining,
        };

        updatedPageCalendars.splice(calendarIndex, 1, updatedCalendar);

        updatedPage.calendars = updatedPageCalendars;
        updatedPages.splice(pageIndex, 1, updatedPage);

        setAllocationsModalTab(1);
        setSelectedFixedIncome(updatedCalendar);
        setFixedIncomeInventoryTotal(0);

        return { ...prev, pages: updatedPages };
      });
    },
  });
};
