export const LOGIN_TRANSLATIONS = {
  email: 'Email Address',
  forgotPassword: 'Forgot Password?',
  getStarted: 'Get Started',
  incorrectCreds:
    'You entered an incorrect email, password, or both. If you are having trouble logging in we are here to help.',
  infoEmailReset: "If you have an account, we'll email you a reset link.",
  login: 'Log In',
  noAccount: "Don't have an account?",
  password: 'Password',
  passwordSetSuccessfully: 'Your password was successfully set. You may now login.',
  registationUnsuccessful: 'User registration unsuccessful. Please contact us if you need any help.',
  haloRegistrationUnsuccessful:
    'User registration unsuccessful. If you are having trouble signing up we are here to help.',
  registrationPendingApproval: 'Your registration has not been approved yet.',
  inactiveUser: 'This account has been deactivated. Please contact our support to get it unlocked.',
  multipleAttemptsLockedAccount:
    'You have entered an incorrect password too many times and your account is temporarily locked. Please contact our support to get it unlocked.',
};
