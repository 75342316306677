export enum UserManagementModelOrderByEnum {
  Name = 'name',
  Organization = 'organization',
  LastLogin = 'last_login',
  CreatedAt = 'created_on',
  RelationshipManager = 'relationship_manager',
}

export type UserManagementModelOrderByField = keyof typeof UserManagementModelOrderByEnum;

export enum UserManagementModelFilterByEnum {
  RelationshipManager = 'relationship_manager',
}

export type UserManagementModelFilterByField = keyof typeof UserManagementModelFilterByEnum;

export enum AdminCalendarStatusEnum {
  filled = 'filled',
  closed = 'closed',
  active = 'active',
  pending = 'pending',
  rejected = 'rejected',
  accepted = 'accepted',
  canceled = 'canceled',
  'active+pending' = 'active+pending',
}

export type ApiAdminCalendarStatus = keyof typeof AdminCalendarStatusEnum;
