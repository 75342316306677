import { ReactElement } from 'react';

import { anchorElAtom } from '@halo-atoms/common';
import { orderBookAuctionAllocationEditDeleteAtom, orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { translations } from '@halo-common/translations';
import { useAuctionDeleteAllocationMutation } from '@halo-data-sources/mutations';
import {
  HaloDataGrid,
  HaloDataGridProps,
  LocalizedButton,
  LocalizedTypography,
  mapNumberToLocalCurrency,
} from '@halodomination/halo-fe-common';
import { Popover, Stack } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';

const slotProps = {
  paper: {
    sx: {
      p: 2,
      width: 800,
    },
  },
};

export const DeleteAllocationPopoverAnchor = 'DeleteAllocationPopoverAnchor';

export type DeleteAllocationPopoverProps = {
  currencyCode?: string;
  currencySymbol?: string;
};

export const DeleteAllocationPopover = ({
  currencyCode,
  currencySymbol,
}: DeleteAllocationPopoverProps): ReactElement => {
  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);
  const [anchorElMap, setAnchorElMap] = useAtom(anchorElAtom);
  const [allocation, setAllocation] = useAtom(orderBookAuctionAllocationEditDeleteAtom);
  const { mutate: submitDelete } = useAuctionDeleteAllocationMutation({
    onSuccess: () => {
      setAnchorElMap({ ...anchorElMap, [DeleteAllocationPopoverAnchor]: null });
      setAllocation();
    },
  });

  const accountOption = {
    id: allocation.allocationId,
    notional: allocation.notional,
    ...allocation.accountOption,
  };
  const loading = !allocation.accountOption;
  const rows = !loading ? [accountOption] : undefined;

  const anchorEl = anchorElMap[DeleteAllocationPopoverAnchor];
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorElMap({ ...anchorElMap, [DeleteAllocationPopoverAnchor]: null });
    setAllocation();
  };

  const handleDelete = () => {
    if (selectedAuctionId && allocation.allocationId) {
      submitDelete({ auctionId: selectedAuctionId, allocationId: allocation.allocationId });
    }
  };

  const mapCurrency = (value?: number) => {
    if (value === undefined) return value;
    const maximumFractionDigits = value < 1 ? 2 : 0;
    return value && value !== 0
      ? mapNumberToLocalCurrency(value, { currency: currencyCode, maximumFractionDigits }, currencySymbol)
      : '-';
  };

  const commonCellProps = {
    editable: false,
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    resizable: true,
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      field: 'account.household',
      headerName: translations.common.household,
      valueGetter: (_, row) => row.account?.household?.name,
    },
    {
      ...commonCellProps,
      field: 'account.name',
      headerName: translations.common.name,
      valueGetter: (_, row) => row.account?.name,
    },
    {
      ...commonCellProps,
      field: 'account.number',
      headerName: translations.common.number,
      valueGetter: (_, row) => row.account?.accountId,
    },
    {
      ...commonCellProps,
      field: 'account.custodian',
      headerName: translations.common.custodian,
      valueGetter: (_, row) => row.account?.custodian?.name,
    },
    {
      ...commonCellProps,
      field: 'notional',
      headerName: translations.common.notional,
      valueGetter: (_, row) => {
        return row.notional;
      },
      valueFormatter: mapCurrency,
    },
  ];

  return (
    <Popover
      slotProps={slotProps}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'right' }}
    >
      <Stack direction="column" spacing={2}>
        <LocalizedTypography variant="h6">Delete Allocation</LocalizedTypography>
        <HaloDataGrid density="compact" columns={columns} rows={rows} loading={loading} />
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <LocalizedButton variant="outlined" onClick={handleClose}>
            {translations.common.cancel}
          </LocalizedButton>
          <LocalizedButton variant="contained" onClick={handleDelete}>
            {translations.common.confirmDelete}
          </LocalizedButton>
        </Stack>
      </Stack>
    </Popover>
  );
};
