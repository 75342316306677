import { getClientConfiguration, request } from '@halo-data-sources/clients';
import { ApiTradingDaysRequestModel, ApiTradingDaysResponseModel } from '@halo-data-sources/models';

const TRADING_DAYS_PATH = '/execution/date/trading';

export const getTradingDays = async (
  requestModel: ApiTradingDaysRequestModel,
): Promise<ApiTradingDaysResponseModel> => {
  const clientConfiguration = await getClientConfiguration(TRADING_DAYS_PATH);

  const response = await request<ApiTradingDaysResponseModel>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...requestModel,
    }),
  });

  return response;
};
