import { ReactElement } from 'react';

import { Doughnut, LinedCircle, YellowCircleOfCircles } from '@halo-common/components';
import { Box, useTheme } from '@mui/material';

export type AnimationLayerProps = {
  contentPositionY: number;
};

export const AnimationLayer = ({ contentPositionY }: AnimationLayerProps): ReactElement => {
  const theme = useTheme();

  const layerStyling = {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: theme.zIndex.drawer + 1,
  };

  const haloLogoLetterOPosition = 161 / 2;
  const linedCircleRadius = 573 / 2;
  const linedCircleOffset = parseInt(theme.spacing(11));
  const linedCircleMobileOffset = parseInt(theme.spacing(7.5));
  const linedCircleStyles = {
    position: 'absolute',
    top: {
      md: `calc(-1 * (${linedCircleRadius}px - ${linedCircleOffset}px))`,
      xs: `calc(-1 * (${linedCircleRadius}px - ${linedCircleMobileOffset}px))`,
    },
    left: { md: '-14%', xs: 'unset' },
    right: { md: 'unset', xs: `calc(50% - ${linedCircleRadius}px - ${haloLogoLetterOPosition}px)` },
  };

  const doughnutOffset = parseInt(theme.spacing(58));
  const doughnutStyles = {
    left: '-11%',
    position: 'absolute',
    top: contentPositionY - doughnutOffset,
  };

  const yellowCircleOfCirclesOffset = parseInt(theme.spacing(38));
  const yellowCircleOfCirclesStyles = {
    position: 'absolute',
    right: { xs: 'unset', md: '-40%' },
    left: { xs: 8, md: 'unset' },
    top: contentPositionY - yellowCircleOfCirclesOffset,
  };

  return (
    <Box sx={layerStyling}>
      <LinedCircle sx={linedCircleStyles} />
      <Doughnut sx={doughnutStyles} />
      <YellowCircleOfCircles sx={yellowCircleOfCirclesStyles} />
    </Box>
  );
};
