export class HttpError extends Error {
  constructor(status: number, message: string, response?: Response) {
    super(message);
    this.status = status;
    this.name = `HttpError (${status})`;
    this.response = response;
  }

  status: number;
  response?: Response;
}
