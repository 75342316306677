import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

export type UseIdFromQueryParamsResult = {
  id?: number;
  error?: Error;
};

export type IdFromQueryParamsOptions = {
  useErrorBoundary?: boolean;
};

export const useIdFromQueryParams = (key: string, options?: IdFromQueryParamsOptions): UseIdFromQueryParamsResult => {
  const useErrorBoundary = options?.useErrorBoundary ?? false;

  const router = useRouter();

  const [id, setId] = useState<number | undefined>();
  const [error, setError] = useState<Error | undefined>();

  const queryParameter = router?.query[key];

  useEffect(() => {
    if (router.isReady) {
      const idString = router.query[key]?.toString() ?? '';
      const idNumber = parseInt(idString);

      if (Number.isNaN(idNumber)) {
        const error = new Error('ID in query string is not a number');
        setError(error);
        if (useErrorBoundary) throw error;
      } else {
        setId(idNumber);
      }
    }
  }, [router.isReady, queryParameter]);

  return { id, error };
};
