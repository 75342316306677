import { NoteIncomeOutcomesModel } from '@halo-common/models';
import { getNoteIncomeOutcomes } from '@halo-data-sources/clients';
import { mapApiNoteIncomeOutcomesModelToNoteIncomeOutcomesModel } from '@halo-data-sources/mappers';
import { NoteQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getNoteIncomeOutcomesQueryFn = async (id?: number) => {
  if (id === undefined) return null;

  const response = await getNoteIncomeOutcomes(id);

  return mapApiNoteIncomeOutcomesModelToNoteIncomeOutcomesModel(response);
};

export const useNoteIncomeOutcomesQuery = (id?: number): UseQueryResult<NoteIncomeOutcomesModel | null, Error> =>
  useQuery<NoteIncomeOutcomesModel | null, Error>({
    queryKey: NoteQueryKeyFactory.incomeOutcomes(id),
    queryFn: () => getNoteIncomeOutcomesQueryFn(id),
  });
