import { useState } from 'react';

export type EventListenerSocketResult<EventType> = {
  name: string;
  handler: (event: EventType) => void;
};

export type EventListenerModel<EventType> = {
  name: string;
  current: EventType | null;
};

export type EventListenerResult<EventType> = [EventListenerSocketResult<EventType>, EventListenerModel<EventType>];

export const useEventListener = <EventType extends Record<string, unknown>>(
  eventTypeName: string,
): EventListenerResult<EventType> => {
  const [currentEvent, setCurrentEvent] = useState<EventType | null>(null);

  const handleEvent = (event: EventType) => {
    setCurrentEvent(event);
  };

  return [
    {
      name: eventTypeName,
      handler: handleEvent,
    },
    {
      name: eventTypeName,
      current: currentEvent,
    },
  ];
};
