import { ReactElement } from 'react';

import { HOUR_MINUTE_SECONDS_TIME_ZONE_FORMAT, MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { useCalendarApprovalStatuses } from '@halo-common/hooks';
import { AllocationModel } from '@halo-common/models';
import { Alert, AlertColor } from '@mui/material';
import { DateTime } from 'luxon';

export type ApproveCalendarOrderAlertProps = {
  allocation?: AllocationModel | null;
  date?: DateTime | null;
  loading?: boolean;
};

export const ApproveCalendarOrderAlert = ({
  allocation,
  date,
  loading = false,
}: ApproveCalendarOrderAlertProps): ReactElement | null => {
  const {
    isAccepted,
    isPershingPending,
    isRejected,
    isDenied,
    isCanceled,
    isFilled,
    isApproved,
    isExpired,
    isPending,
  } = useCalendarApprovalStatuses(allocation?.approved, allocation?.status, allocation?.expirationDate);

  let alertStatus = '';
  if (isRejected) alertStatus = 'rejected';
  else if (isPending || isPershingPending) alertStatus = '';
  else if (isDenied) alertStatus = 'denied';
  else if (isCanceled) alertStatus = 'canceled';
  else if (isFilled) alertStatus = 'filled';
  else if (isApproved) alertStatus = 'confirmed';
  else if (isAccepted) alertStatus = 'accepted';
  else if (isExpired) alertStatus = 'canceled';

  const dateText = date?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
  const timeText = date?.toFormat(HOUR_MINUTE_SECONDS_TIME_ZONE_FORMAT);

  const message = {
    accepted: `This interest was approved on ${dateText} at ${timeText}.`,
    canceled: `This interest was canceled on ${dateText} at ${timeText}.`,
    confirmed: `This interest has been successfully confirmed on ${dateText} at ${timeText}.`,
    denied: `This interest was denied on ${dateText} at ${timeText}.`,
    filled: `This interest has been successfully filled on ${dateText} at ${timeText}.`,
    rejected: `This interest was rejected on ${dateText} at ${timeText}.`,
  }[alertStatus];

  const severity = {
    canceled: 'error',
    accepted: 'success',
    confirmed: 'success',
    denied: 'error',
    filled: 'success',
    rejected: 'error',
  }[alertStatus] as AlertColor;

  const showAlert = !loading && alertStatus;

  return showAlert ? (
    <Alert variant="filled" severity={severity}>
      {message}
    </Alert>
  ) : null;
};
