import { ChangeEvent, ReactElement } from 'react';

import {
  ExecutionHubFilters,
  accountSearchAtom,
  executionHubFiltersAtom,
  executionHubQueryAtom,
  orderNumberSearchAtom,
  repCodeSearchAtom,
} from '@halo-atoms/executionHub';
import { DateRangePicker } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { ProductByAssetModel, useExecutionHubIssuersQuery } from '@halo-data-sources/queries';
import { LocalizedMenuItem, LocalizedTextField } from '@halodomination/halo-fe-common';
import { Box, MenuItem, Stack, StackProps, TextField } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useAtom, useAtomValue } from 'jotai';
import { DateTime } from 'luxon';

import { V2ExecutionHubAssetIdFilter } from './V2ExecutionHubAssetIdFilter';

const itemSx = { flexBasis: { md: '20%', xs: '100%' }, width: '100%' };
const calendarSx = { flexBasis: { md: '20%', xs: '100%' }, width: '100%' };
const fillerSx = { flexBasis: { md: '20%' }, visibility: 'hidden', display: { md: 'inherit', xs: 'none' } };
const containerDirection: StackProps['direction'] = { md: 'row', xs: 'column' };
const containerAlignment = { md: 'center', xs: 'flex-start' };

// TODO: Use a hook to determine which client is accessing the page so we can
//       show custom filters
export const V2ExecutionHubFiltersBar = (): ReactElement => {
  const [orderNumber, setOrderNumber] = useAtom(orderNumberSearchAtom);
  const [account, setAccount] = useAtom(accountSearchAtom);
  const [repCode, setRepCode] = useAtom(repCodeSearchAtom);
  const [filters, setFilters] = useAtom(executionHubFiltersAtom);
  const executionHubQuery = useAtomValue(executionHubQueryAtom);

  const { data: issuers } = useExecutionHubIssuersQuery(executionHubQuery);

  const startDate = filters.expirationStartDate.toISO();
  const endDate = filters.expirationEndDate.toISO();

  const handleOrderNumberChange = (ev: ChangeEvent<HTMLInputElement>) => setOrderNumber(ev.target.value);
  const handleAccountChange = (ev: ChangeEvent<HTMLInputElement>) => setAccount(ev.target.value);
  const handleRepCodeChange = (ev: ChangeEvent<HTMLInputElement>) => setRepCode(ev.target.value);

  const handleIssuerChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    setFilters((prev) => ({ ...prev, issuer: value }));
  };

  const handleProductSelect = (product: ProductByAssetModel | Array<ProductByAssetModel> | null) => {
    const calendar = Array.isArray(product) ? product[0] : product;
    setFilters((prev) => ({ ...prev, calendar: calendar ?? null }));
  };

  const handleAccountTypeChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    setFilters((prev) => ({ ...prev, accountType: value }));
  };

  const handleDateChange = (value: DateRange<DateTime>) => {
    const startDate = value[0];
    const endDate = value[1];

    const filters: Partial<ExecutionHubFilters> = {};

    if (startDate) filters.expirationStartDate = startDate;
    if (endDate) filters.expirationEndDate = endDate;

    setFilters((prev) => ({ ...prev, ...filters }));
  };

  const FillerBox = <Box sx={fillerSx} />;

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction={containerDirection} alignItems={containerAlignment} spacing={2}>
        <TextField sx={itemSx} label="Order Number" fullWidth value={orderNumber} onChange={handleOrderNumberChange} />
        <TextField sx={itemSx} label="Issuer" fullWidth select value={filters.issuer} onChange={handleIssuerChange}>
          <LocalizedMenuItem value="">{translations.common.all}</LocalizedMenuItem>
          {issuers?.map((issuer) => (
            <MenuItem key={issuer.id} value={issuer.id}>
              {issuer.name}
            </MenuItem>
          ))}
        </TextField>
        <LocalizedTextField
          sx={itemSx}
          label={translations.common.account}
          fullWidth
          value={account}
          onChange={handleAccountChange}
        />
        <LocalizedTextField
          sx={itemSx}
          label={translations.common.repCode}
          fullWidth
          value={repCode}
          onChange={handleRepCodeChange}
        />
        <LocalizedTextField
          sx={itemSx}
          label={translations.common.accountType}
          fullWidth
          select
          value={filters.accountType}
          onChange={handleAccountTypeChange}
        >
          <LocalizedMenuItem value="">{translations.common.all}</LocalizedMenuItem>
          <LocalizedMenuItem value="Brokerage">{translations.common.brokerage}</LocalizedMenuItem>
          <LocalizedMenuItem value="Advisory">{translations.common.advisory}</LocalizedMenuItem>
        </LocalizedTextField>
      </Stack>
      <Stack direction={containerDirection} alignItems={containerAlignment} spacing={2}>
        <V2ExecutionHubAssetIdFilter sx={itemSx} fullWidth onChange={handleProductSelect} value={filters.calendar} />
        <DateRangePicker
          sx={calendarSx}
          label="Expiration Date"
          onChange={handleDateChange}
          startDate={startDate}
          endDate={endDate}
        />
        {FillerBox}
        {FillerBox}
        {FillerBox}
      </Stack>
    </Stack>
  );
};
