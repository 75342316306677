import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';

const buttonIconSx = {
  marginRight: 1,
};

export type ProductDetailModalCreateSimilarButtonProps = {
  noteId?: number;
};

export const ProductDetailModalCreateSimilarButton = ({
  noteId,
}: ProductDetailModalCreateSimilarButtonProps): ReactElement | null => {
  const handleCreateSimilar = () => navigateParentTo(`/note_creator?note=${noteId}`, true);

  return (
    <LocalizedButton
      className="wm-post-pdm-note-create-similar-button"
      startIcon={<Iconography sx={buttonIconSx} iconName="redo" color="primary.main" />}
      onClick={handleCreateSimilar}
      type="button"
      variant="text"
      color="primary"
      fullWidth
    >
      {translations.pdm.common.createSimilarButton}
    </LocalizedButton>
  );
};
