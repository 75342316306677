import { ReactElement } from 'react';

import { executionHubFiltersAtom, executionHubQueryAtom } from '@halo-atoms/executionHub';
import { ExecutionHubJanneyStatusEnum } from '@halo-common/enums';
import { useCalendarOrderByStatusQuery } from '@halo-data-sources/queries';
import { Tabs } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';

import { V2ExecutionHubDownloadCSVButton } from './V2ExecutionHubDownloadCSVButton';
import { V2ExecutionHubFiltersBar } from './V2ExecutionHubFiltersBar';

const headerSx = { mb: { md: 2, xs: 0 } };

export const V2ExecutionHubPageHeader = (): ReactElement => {
  const [executionHubFilters, setExecutionHubFilters] = useAtom(executionHubFiltersAtom);
  const executionHubQuery = useAtomValue(executionHubQueryAtom);

  const { data } = useCalendarOrderByStatusQuery(executionHubQuery);

  const pendingCount = data?.active ?? 0;
  const filledCount = data?.filled ?? 0;
  const canceledCount = data?.canceled ?? 0;

  const tabs = [
    {
      label: 'Active',
      value: ExecutionHubJanneyStatusEnum.active,
      count: pendingCount,
    },
    {
      label: 'Filled',
      value: ExecutionHubJanneyStatusEnum.filled,
      count: filledCount,
    },
    {
      label: 'Canceled',
      value: ExecutionHubJanneyStatusEnum.canceled,
      count: canceledCount,
    },
  ];

  const activeTab = tabs.findIndex(({ value }) => executionHubFilters.status === value);

  const handleStatusFilterChange = (index: number) => {
    setExecutionHubFilters((prev) => ({ ...prev, status: tabs[index].value }));
  };

  return (
    <Stack direction="column" spacing={2} sx={headerSx}>
      <Stack
        direction={{ sm: 'row', xs: 'column' }}
        spacing={2}
        justifyContent={{ sm: 'space-between', xs: 'flex-start' }}
        alignItems={{ sm: 'center', xs: 'flex-start' }}
      >
        <Tabs
          variant="scrollablePills"
          tabs={tabs}
          onChange={handleStatusFilterChange}
          defaultTab={activeTab}
          value={activeTab}
        />
        <V2ExecutionHubDownloadCSVButton />
      </Stack>
      <V2ExecutionHubFiltersBar />
    </Stack>
  );
};
