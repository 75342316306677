import { ReactElement } from 'react';

import { ModuleQuizModel, QuizAttemptAnswerModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import {
  QuizManagerError,
  QuizManagerQuestion,
  QuizManagerRequirement,
  QuizManagerSuccess,
  useCourseManager,
} from '@halo-modules/app';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';
import { useT } from '@transifex/react';

export type QuizManagerFormProps = {
  attempts?: QuizAttemptAnswerModel;
  passed?: boolean;
  failed?: boolean;
  attempted?: boolean;
  loading?: boolean;
};

export const QuizManagerForm = ({
  attempts = {},
  passed = false,
  failed = false,
  attempted = false,
  loading = false,
}: QuizManagerFormProps): ReactElement => {
  const translator = useT();

  const { progress } = useCourseManager();
  const currentStep = progress?.step;

  const quiz = currentStep as ModuleQuizModel;
  const quizTitle = translator(quiz?.title);

  const dynamicContent = {
    stepTitle: quizTitle,
  };

  const quizThreshold = quiz?.passThreshold;
  const quizQuestions = quiz?.questions ?? [];
  const questionCount = quizQuestions?.length;

  const submittedAnswers = attempts ? Object.values(attempts) : [];
  const correctAnswers = submittedAnswers
    .filter((attempt) => attempt.isCorrect)
    .map((attempt) => attempt.submittedAnswer);

  const showPassingAlert = !loading && passed;
  const showFailureAlert = !loading && failed;

  const title = loading ? (
    <Skeleton variant="rounded" height={38} width="100%" />
  ) : (
    <LocalizedTypography variant="h2" color="primary" dynamicContent={dynamicContent}>
      {translations.education.educationQuizManager.stepperSubtitle}
    </LocalizedTypography>
  );

  const failureAlert = showFailureAlert ? (
    <QuizManagerError threshold={quizThreshold} questionCount={questionCount} />
  ) : null;

  const passAlert = showPassingAlert ? (
    <QuizManagerSuccess correctAnswers={correctAnswers.length} questionCount={questionCount} />
  ) : null;

  const questions = quizQuestions.map((question) => (
    <QuizManagerQuestion
      key={question.id}
      question={question}
      answers={attempts}
      attempted={attempted}
      disabled={passed}
      loading={loading}
    />
  ));

  return (
    <Stack direction="column" spacing={3} xs={12}>
      {title}
      <QuizManagerRequirement threshold={quizThreshold} questionCount={questionCount} loading={loading} />
      {failureAlert}
      {passAlert}
      {questions}
    </Stack>
  );
};
