import { useRef } from 'react';

import { executionHubQueryAtom } from '@halo-atoms/executionHub';
import { useWebSocketContext } from '@halo-common/providers';
import {
  CalendarOrderInfiniteQueryResult,
  ExecutionHubOrder,
  OrdersClientQueryKeyFactory,
} from '@halo-data-sources/queries';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

const ONE_MINUTE_TIMEOUT = 60000;
const TIMEOUT_STATUS = 'TIME_OUT';
const FILLED_STATUS = 'FILLED';

export type EHOrderManagerHandler = (orders: Array<ExecutionHubOrder>) => void;

export const useEHOrderManager = (): EHOrderManagerHandler => {
  const queryClient = useQueryClient();
  const { events } = useWebSocketContext();

  const timeouts = useRef<Array<{ id: number; ref: NodeJS.Timeout }>>([]);

  const executionHubQuery = useAtomValue(executionHubQueryAtom);

  return (orders) => {
    const handleUpdate = (execOrderId: number, status: string, reason?: string) => {
      const key = OrdersClientQueryKeyFactory.calendarOrders(executionHubQuery);

      const completedEventIndex = timeouts.current.findIndex(({ id }) => id === execOrderId);

      if (completedEventIndex !== -1) {
        clearTimeout(timeouts.current[completedEventIndex].ref);
        timeouts.current.splice(completedEventIndex, 1);
      }

      queryClient.setQueryData<InfiniteData<CalendarOrderInfiniteQueryResult>>(key, (prev) => {
        if (!prev?.pages) return prev;

        const isFilled = status === FILLED_STATUS;

        const updatedPages = [...prev.pages];
        const pageIndex = updatedPages.findIndex(({ orders }) =>
          orders.some(({ orders }) => orders.some((order) => order.executionOrder.id === execOrderId)),
        );

        if (pageIndex < 0) return prev;

        const updatedPage = { ...updatedPages[pageIndex] };

        const updatedOrderPairings = [...updatedPage.orders];
        const orderPairingIndex = updatedOrderPairings.findIndex(({ orders }) =>
          orders.some((order) => order.executionOrder.id === execOrderId),
        );

        const updatedOrders = [...updatedOrderPairings[orderPairingIndex].orders];
        const orderIndex = updatedOrders.findIndex((order) => order.executionOrder.id === execOrderId);

        const updatedOrder = { ...updatedOrders[orderIndex] };
        const updatedExecOrder = { ...updatedOrder.executionOrder };
        const updatedExecOrderStatus = isFilled ? 'filled' : 'warning';
        updatedExecOrder.status = updatedExecOrderStatus;
        updatedExecOrder.comment = reason;

        updatedOrders.splice(orderIndex, 1, {
          ...updatedOrder,
          executionOrder: updatedExecOrder,
        });

        updatedOrderPairings.splice(orderPairingIndex, 1, {
          ...updatedOrderPairings[orderPairingIndex],
          orders: updatedOrders,
        });

        updatedPages.splice(pageIndex, 1, {
          ...updatedPages[pageIndex],
          orders: updatedOrderPairings,
        });

        return { ...prev, pages: updatedPages };
      });

      if (status !== TIMEOUT_STATUS) events.execOrder.remove(execOrderId);
      else events.execOrder.replace(execOrderId, { pending: false });
    };

    orders.forEach(({ executionOrder }) => {
      const execOrderId = executionOrder.id;

      events.execOrder.add(execOrderId, (event) => handleUpdate(execOrderId, event.order.status, event.reason));

      timeouts.current.push({
        id: execOrderId,
        ref: global.setTimeout(() => {
          handleUpdate(execOrderId, TIMEOUT_STATUS);
        }, ONE_MINUTE_TIMEOUT),
      });
    });
  };
};
