import { createContext, useContext, ReactNode, useMemo, ReactElement } from 'react';

import type { StepWizardManager } from './useStepWizardManager';

const defaultStepWizardManager: StepWizardManager = {
  step: '',
  setStep: () => null,
  activeSection: null,
  activeSectionIndex: 0,
  activeStep: null,
  activeStepIndex: 0,
  isFirstStep: true,
  isLastStep: false,
  goToNextStep: () => null,
  goToPreviousStep: () => null,
};

const stepWizardContext = createContext<StepWizardManager>(defaultStepWizardManager);

export interface StepWizardProviderProps {
  children: ReactNode;
  stepWizardManager: StepWizardManager;
}

export const StepWizardContextProvider = ({ children, stepWizardManager }: StepWizardProviderProps): ReactElement => {
  const value = useMemo(() => ({ ...stepWizardManager }), [stepWizardManager]);
  return <stepWizardContext.Provider value={value}>{children}</stepWizardContext.Provider>;
};

export const useStepWizardContext = (): StepWizardManager => useContext(stepWizardContext);
