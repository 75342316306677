import { SetPasswordFormData } from '@halo-common/forms';
import { ContactHaloSchema } from '@halo-common/schemas';
import {
  getOnboardingDisclaimers,
  postContactHalo,
  postPasswordChange,
  registerUser,
  resendPasswordLink,
  sendDisclaimerAcceptance,
  sendResetPasswordEmail,
  setPassword,
} from '@halo-data-sources/clients';
import { ApiDisclaimerMapper } from '@halo-data-sources/mappers';
import { ApiUserPasswordUpdateResponse } from '@halo-data-sources/models';
import { DisclaimerAcceptanceSchema, LinkExpiredSchema, RegisterFormSchema } from '@halo-modules/app';
import { createThunk } from '@halo-stores/createThunk';

import {
  AcceptDisclaimerThunkResult,
  GetDisclaimersThunkResult,
  NewRegisteredUserThunkResult,
  PasswordResetThunkResult,
  UserPasswordUpdate,
} from './OnboardingModel';

export const initiatePasswordReset = createThunk<PasswordResetThunkResult, string>(
  'onboarding/password/reset',
  async (email) => {
    await sendResetPasswordEmail({ email });

    return { emailSent: true, email };
  },
);

export const resendSetPassword = createThunk<void, LinkExpiredSchema>(
  'onboarding/password/resend',
  async (requestModel) => await resendPasswordLink(requestModel),
);

export const setNewUserPassword = createThunk<void, SetPasswordFormData>(
  'onboarding/password/create',
  async (requestModel) => await setPassword(requestModel),
);

export const registerNewUser = createThunk<NewRegisteredUserThunkResult, RegisterFormSchema>(
  'onboarding/user/create',
  async (requestModel) => {
    const result = await registerUser(requestModel);

    return {
      whiteListed: result.whitelisted,
      name: requestModel.firstName,
      email: requestModel.emailAddress,
    };
  },
);

export const changeUserPassword = createThunk<ApiUserPasswordUpdateResponse, UserPasswordUpdate>(
  'user/password/update',
  async (data) => {
    return await postPasswordChange({
      old_password: data.oldPassword,
      new_password: data.newPassword,
    });
  },
);

export const getDisclaimers = createThunk<GetDisclaimersThunkResult, void>('onboarding/disclaimers/get', async () => {
  const response = await getOnboardingDisclaimers();

  const disclaimers = response.map(ApiDisclaimerMapper.toOnboardingDisclaimer);

  return { disclaimers };
});

export const acceptDisclaimer = createThunk<AcceptDisclaimerThunkResult, DisclaimerAcceptanceSchema>(
  'onboarding/disclaimer/accept',
  async (requestModel) => {
    await sendDisclaimerAcceptance(requestModel);

    return { disclaimerId: requestModel.disclaimerId };
  },
);

export const contactHaloMessage = createThunk<void, ContactHaloSchema>(
  'onboarding/contactForm/send',
  async (requestModel) => {
    await postContactHalo(requestModel);
  },
);
