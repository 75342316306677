import { ReactElement } from 'react';

import { useStepWizardContext } from '@halo-common/components/StepWizard';
import { translations } from '@halo-common/translations';
import type { SuitabilityStepModel } from '@halo-modules/app/v2/onboarding';
import { Iconography, LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import { List, ListItem, ListItemText, ListItemIcon, listItemClasses, listItemIconClasses } from '@mui/material';

import { firmDetailsStep } from '../firmDetailsSection';

const intro = translations.onboarding.suitability.intro;

const points = [
  intro.requiredToCompleteBeforeAllowedToTrade,
  intro.forwardRemainingQuestionsToAuthorizedPerson,
  intro.someQuestionsMayBePreFilled,
  intro.haloCanConfigureYourPlatform,
];

const listSx = {
  mb: 2,
  p: 0,
  [`& .${listItemClasses.root}`]: {
    paddingInline: 0,
    [`& .${listItemIconClasses.root}`]: {
      minWidth: 32,
    },
  },
};

const Intro = (): ReactElement => {
  const { setStep } = useStepWizardContext();

  const handleBegin = () => setStep(firmDetailsStep.id);

  return (
    <>
      <LocalizedTypography variant="h6" align="center" mb={2}>
        {intro.title}
      </LocalizedTypography>

      <List sx={listSx}>
        {points.map((point, i) => (
          <ListItem key={i}>
            <ListItemIcon>
              <Iconography iconName="check" color="success.main" />
            </ListItemIcon>
            <ListItemText>
              <LocalizedTypography variant="body2">{point}</LocalizedTypography>
            </ListItemText>
          </ListItem>
        ))}
      </List>

      <LocalizedButton
        variant="contained"
        color="primary"
        endIcon={<Iconography iconName="arrow-right" color="inherit" />}
        fullWidth
        onClick={handleBegin}
      >
        {translations.common.begin}
      </LocalizedButton>
    </>
  );
};

const Disclaimer = (): ReactElement => (
  <LocalizedTypography color="text.secondary" variant="caption">
    {intro.disclaimer}
  </LocalizedTypography>
);

export const introStep: SuitabilityStepModel = {
  id: 'default',
  title: translations.onboarding.suitability.defaultStepTitle,
  component: <Intro />,
  footer: <Disclaimer />,
};
