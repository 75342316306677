import { V2NoteModel } from '@halo-common/models';
import { getTermsheet } from '@halo-data-sources/clients';
import { ApiTermsheetV2Mapper } from '@halo-data-sources/mappers';
import { TermsheetQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getTermsheetNotesQueryFn = async (id?: number) => {
  if (!id) return [];
  const data = await getTermsheet(id);
  return data.map((product) => ApiTermsheetV2Mapper.toV2NoteModel(product.product));
};

export const useTermsheetNotesQuery = (id?: number): UseQueryResult<Array<V2NoteModel>, Error> =>
  useQuery<Array<V2NoteModel>, Error>({
    queryKey: TermsheetQueryKeyFactory.notes(id),
    queryFn: () => getTermsheetNotesQueryFn(id),
  });
