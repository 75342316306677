import { ReactElement, useState } from 'react';

import { SetPasswordFormFields, setPasswordFormSchema } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import {
  LocalizedAlert,
  LocalizedButton,
  PasswordInput,
  Stack,
  LocalizedTypography,
} from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, List, Paper } from '@mui/material';
import { Result } from 'check-password-strength';
import { useForm } from 'react-hook-form';

import { PasswordStrengthBar } from './PasswordStrengthBar';
import { PasswordValidateItem } from './PasswordValidateItem';

const alertSx = {
  backgroundColor: 'error.background',
};

const formContainerSx = {
  padding: 3,
  overflowY: 'auto',
  minWidth: { xs: 343, sm: 'default' },
  width: { xs: '100%', sm: 450 },
};

export type SetPasswordFormDataV2 = {
  oldPassword?: string;
  password: string;
  confirmPassword: string;
};

export type SetPasswordSchemaV2 = SetPasswordFormFields;

export type SetPasswordFormPropsV2 = {
  onSubmit: (data: SetPasswordFormDataV2) => void;
  error?: boolean;
  errorMessage?: string;
  confirmCurrentPassword?: boolean;
  hasTitle?: boolean;
};

export const SetPasswordFormV2 = ({
  onSubmit,
  error = false,
  hasTitle = false,
  errorMessage = '',
  confirmCurrentPassword = false,
}: SetPasswordFormPropsV2): ReactElement => {
  const [validationResult, setValidationResult] = useState<Result<string> | undefined>();

  const {
    handleSubmit,
    register,
    watch,
    formState: { isSubmitting, errors, isValid },
  } = useForm<SetPasswordSchemaV2>({
    mode: 'onSubmit',
    resolver: yupResolver<SetPasswordSchemaV2>(setPasswordFormSchema),
    defaultValues: {
      confirmCurrentPassword,
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
  });

  const { ref: oldPasswordRef, ...oldPasswordProps } = register('oldPassword');
  const { ref: passwordRef, ...passwordProps } = register('password');
  const { ref: confirmPasswordRef, ...confirmPasswordProps } = register('confirmPassword');

  const userPassword = watch('password', '');
  const confirmUserPassword = watch('confirmPassword', '');

  const handleValidation = (value: Result<string>) => void setValidationResult(value);
  const onInnerSubmit = ({ oldPassword, password, confirmPassword }: SetPasswordSchemaV2) => {
    onSubmit({ oldPassword, password, confirmPassword });
  };

  const hasMinLength = validationResult && validationResult.length >= 8;
  const hasUppercase = validationResult?.contains.includes('uppercase');
  const hasLowercase = validationResult?.contains.includes('lowercase');
  const hasNumber = validationResult?.contains.includes('number');
  const matchingPasswords = userPassword === confirmUserPassword && userPassword !== '';
  const meetsPasswordCriteria = hasMinLength && hasUppercase && hasLowercase && hasNumber && matchingPasswords;

  const showHasMinLengthCheck = hasMinLength ? 'check' : undefined;
  const showHasUppercaseCheck = hasUppercase ? 'check' : undefined;
  const showHasLowercaseCheck = hasLowercase ? 'check' : undefined;
  const showHasNumberCheck = hasNumber ? 'check' : undefined;
  const showMatchingPasswordsCheck = matchingPasswords ? 'check' : undefined;

  const disabled = isSubmitting || !isValid || !meetsPasswordCriteria;
  const buttonStartIcon = isSubmitting && <CircularProgress size="1rem" />;

  const errorMessageText = errorMessage || translations.profile.password.error;

  const errorAlert = error ? (
    <LocalizedAlert sx={alertSx} severity="error" variant="outlined">
      {errorMessageText}
    </LocalizedAlert>
  ) : null;

  const currentPasswordConfirmation = confirmCurrentPassword ? (
    <PasswordInput
      size="large"
      fullWidth
      label={translations.profile.password.oldPassword}
      inputRef={oldPasswordRef}
      {...oldPasswordProps}
      error={Boolean(errors?.oldPassword)}
      helperText={errors?.oldPassword?.message}
    />
  ) : null;

  const title = hasTitle ? (
    <LocalizedTypography align="center" component="h4" variant="h4">
      {translations.profile.password.setPassword}
    </LocalizedTypography>
  ) : null;

  return (
    <form onSubmit={handleSubmit(onInnerSubmit)}>
      <Paper variant="outlined" sx={formContainerSx}>
        <Stack direction="column" spacing={3}>
          {errorAlert}
          <Stack direction="column" spacing={3}>
            {title}
            {currentPasswordConfirmation}
            <PasswordInput
              id="main"
              size="large"
              fullWidth
              label={translations.profile.password.password}
              inputRef={passwordRef}
              {...passwordProps}
              error={Boolean(errors?.password)}
              helperText={errors?.password?.message}
              autoComplete="new-password"
            />
            <PasswordInput
              size="large"
              fullWidth
              label={translations.profile.password.confirmPassword}
              inputRef={confirmPasswordRef}
              {...confirmPasswordProps}
              error={Boolean(errors?.confirmPassword)}
              helperText={errors?.confirmPassword?.message}
              autoComplete="new-password"
            />
          </Stack>
          <Stack direction="row" xs={[8, 4]}>
            <List component="nav" disablePadding>
              <PasswordValidateItem
                icon={showHasMinLengthCheck}
                description={translations.profile.password.passwordLength}
              />
              <PasswordValidateItem
                icon={showHasUppercaseCheck}
                description={translations.profile.password.uppercase}
              />
              <PasswordValidateItem
                icon={showHasLowercaseCheck}
                description={translations.profile.password.lowercase}
              />
              <PasswordValidateItem icon={showHasNumberCheck} description={translations.profile.password.number} />
              <PasswordValidateItem
                icon={showMatchingPasswordsCheck}
                description={translations.profile.password.matching}
              />
            </List>
            <PasswordStrengthBar password={userPassword} onValidation={handleValidation} />
          </Stack>
          <Stack direction="column" spacing={3}>
            <LocalizedButton
              startIcon={buttonStartIcon}
              disabled={disabled}
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
            >
              {translations.common.finish}
            </LocalizedButton>
          </Stack>
        </Stack>
      </Paper>
    </form>
  );
};
