import { ApiFixEventTypeEnum } from '@halo-data-sources/enums/fix';
import {
  mapApiFixOrderModelToFixOrderModel,
  mapApiFixOrderWebsocketCanceledResultToFixState,
  mapApiFixOrderWebsocketRejectedResultToFixState,
  mapApiFixOrderWebsocketSubmissionResultToFixState,
  mapApiFixOrderWebsocketValidationResultToFixState,
} from '@halo-data-sources/mappers';
import { ApiFixOrderWebsocketResult } from '@halo-data-sources/models';
import { WebsocketActions } from '@halo-stores/Websocket';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { FixState, FixStatusEnum } from './FixModel';
import {
  bulkFillFixOrder,
  cancelFixOrder,
  fetchFixOrder,
  fetchFixOrderAndAllocation,
  fetchFixOrders,
  fillFixOrder,
} from './FixThunks';

const INITIAL_STATE: FixState = {
  orders: [],
  orderQueue: [],
  rejectionReason: '',
  selectedOrder: null,
  status: FixStatusEnum.idle,
  validationResult: null,
};

export const FixDuck = createSlice({
  name: 'Fix',
  initialState: INITIAL_STATE,
  reducers: {
    handleWebsocketEvent(state, action: PayloadAction<ApiFixOrderWebsocketResult>) {
      const payload = action?.payload;
      const { allocation, event, order } = payload ?? {};
      const { orderQueue, status } = state;

      const matchesQueuedFixOrder = orderQueue.some(
        ({ orderId }) => orderId === order?.id || orderId === allocation?.id,
      );

      if (!matchesQueuedFixOrder) return state;

      const isFillEventType = event === ApiFixEventTypeEnum.TRADE && status !== FixStatusEnum.failureFillFixOrder;
      const isCancelEventType = event === ApiFixEventTypeEnum.CANCELED;
      const isErrorEventType = event === ApiFixEventTypeEnum.REJECTED || event === ApiFixEventTypeEnum.DONTKNOWTRADEDK;
      const isValidationEventType = allocation === null;
      const isSubmissionEventType = allocation !== null;

      const updatedOrderIdQueue = orderQueue.filter(
        ({ orderId }) => orderId !== order?.id && orderId !== allocation?.id,
      );

      if (isFillEventType) {
        const updatedStatus = updatedOrderIdQueue.length
          ? state.status
          : FixStatusEnum.successBulkFillOrderWebsocketEvent;

        return {
          ...state,
          selectedOrder: mapApiFixOrderModelToFixOrderModel(payload.order),
          status: updatedStatus,
          orderQueue: updatedOrderIdQueue,
        };
      } else if (isCancelEventType) {
        return {
          ...state,
          ...mapApiFixOrderWebsocketCanceledResultToFixState(payload),
          orderQueue: updatedOrderIdQueue,
        };
      } else if (isErrorEventType) {
        return {
          ...state,
          ...mapApiFixOrderWebsocketRejectedResultToFixState(payload),
          orderQueue: updatedOrderIdQueue,
        };
      } else if (isValidationEventType) {
        return {
          ...state,
          ...mapApiFixOrderWebsocketValidationResultToFixState(payload),
          orderQueue: updatedOrderIdQueue,
        };
      } else if (isSubmissionEventType) {
        return {
          ...state,
          ...mapApiFixOrderWebsocketSubmissionResultToFixState(payload),
          orderQueue: updatedOrderIdQueue,
        };
      }

      return state;
    },
    resetStatus(state) {
      return {
        ...state,
        status: INITIAL_STATE.status,
      };
    },
    resetValidationResult(state) {
      return {
        ...state,
        status: INITIAL_STATE.status,
        validationResult: INITIAL_STATE.validationResult,
      };
    },
    selectOrder(state, action) {
      return {
        ...state,
        error: INITIAL_STATE.error,
        selectedOrder: action?.payload?.fixOrder,
      };
    },
    removeFromOrderQueue(state, action) {
      return {
        ...state,
        orderQueue: state.orderQueue.filter((order) => order.calendarId !== action.payload.calendarId),
      };
    },
    updateOrderQueue(state, action) {
      const orders = action?.payload.orders ?? [];

      return {
        ...state,
        orderQueue: [...state.orderQueue, ...orders],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFixOrders.pending, (state) => {
        state.status = FixStatusEnum.requestFetchFixOrders;
      })
      .addCase(fetchFixOrders.rejected, (state, action) => {
        state.status = FixStatusEnum.failureFetchFixOrders;
        state.error = action?.payload?.message;
      })
      .addCase(fetchFixOrders.fulfilled, (state, action) => {
        state.status = FixStatusEnum.successFetchFixOrders;
        state.error = INITIAL_STATE.error;

        state.orders = action.payload.orders;
      })
      .addCase(cancelFixOrder.pending, (state) => {
        state.status = FixStatusEnum.requestCancelFixOrder;
      })
      .addCase(cancelFixOrder.rejected, (state, action) => {
        state.status = FixStatusEnum.failureCancelFixOrder;
        state.error = action?.payload?.message;
      })
      .addCase(cancelFixOrder.fulfilled, (state, action) => {
        state.status = FixStatusEnum.successCancelFixOrder;
        state.error = INITIAL_STATE.error;

        state.selectedOrder = action?.payload.fixOrder;
        state.orderQueue = [...state.orderQueue, { orderId: action?.payload.fixOrder.id }];
      })
      .addCase(fillFixOrder.pending, (state) => {
        state.status = FixStatusEnum.requestFillFixOrder;
      })
      .addCase(fillFixOrder.rejected, (state, action) => {
        state.status = FixStatusEnum.failureFillFixOrder;
        state.error = action?.payload?.message;
      })
      .addCase(fillFixOrder.fulfilled, (state, action) => {
        state.status = FixStatusEnum.successFillFixOrder;
        state.error = INITIAL_STATE.error;

        state.selectedOrder = action?.payload.fixOrder;
        state.orderQueue = [...state.orderQueue, { orderId: action?.payload.fixOrder.id }];
      })
      .addCase(fetchFixOrderAndAllocation.pending, (state) => {
        state.status = FixStatusEnum.requestFetchFixOrderAndAllocation;
      })
      .addCase(fetchFixOrderAndAllocation.rejected, (state, action) => {
        state.status = FixStatusEnum.failureFetchFixOrderAndAllocation;
        state.error = action?.payload?.message;

        state.selectedOrder = INITIAL_STATE.selectedOrder;
      })
      .addCase(fetchFixOrderAndAllocation.fulfilled, (state, action) => {
        state.status = FixStatusEnum.successFetchFixOrderAndAllocation;
        state.error = INITIAL_STATE.error;

        state.selectedOrder = action?.payload.fixOrder;
      })
      .addCase(fetchFixOrder.pending, (state) => {
        state.status = FixStatusEnum.requestFetchFixOrder;
      })
      .addCase(fetchFixOrder.rejected, (state, action) => {
        state.status = FixStatusEnum.failureFetchFixOrder;
        state.error = action?.payload?.message;

        state.selectedOrder = INITIAL_STATE.selectedOrder;
      })
      .addCase(fetchFixOrder.fulfilled, (state, action) => {
        state.status = FixStatusEnum.successFetchFixOrder;
        state.error = INITIAL_STATE.error;

        state.selectedOrder = action?.payload.fixOrder;
      })
      .addCase(bulkFillFixOrder.pending, (state) => {
        state.status = FixStatusEnum.requestBulkFillFixOrder;
      })
      .addCase(bulkFillFixOrder.rejected, (state) => {
        state.status = FixStatusEnum.failureBulkFillFixOrder;
        state.orderQueue = INITIAL_STATE.orderQueue;
      })
      .addCase(bulkFillFixOrder.fulfilled, (state) => {
        state.status = FixStatusEnum.successBulkFillFixOrder;
        state.error = INITIAL_STATE.error;
      })
      .addCase(WebsocketActions.handleTimeout, (state) => {
        state.status = FixStatusEnum.websocketTimeout;
        state.orderQueue = INITIAL_STATE.orderQueue;
      });
  },
});
