import { ReactElement } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { modalAtom } from '@halo-atoms/common';
import { fixedIncomeSelectedAtom } from '@halo-atoms/fixedIncome';
import { CalendarOrderStatusEnum } from '@halo-common/enums';
import { CALENDAR_ALLOCATION_MODAL_NAME, MUST_COMPLETE_ONBOARDING_MODAL } from '@halo-common/modals';
import { CalendarModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { FIXED_INCOME_ALLOCATION_MODAL_NAME } from '@halo-modules/app';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { Button, ButtonProps } from '@mui/material';
import { useSetAtom } from 'jotai';

export type OrderBookCalendarsAllocationButtonProps = Partial<ButtonProps> & {
  calendar: CalendarModel;
};

const buttonSx = {
  minWidth: 'unset',
};

export const OrderBookCalendarsAllocationButton = ({
  calendar,
  size = 'medium',
  ...props
}: OrderBookCalendarsAllocationButtonProps): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();

  const updateModalMap = useSetAtom(modalAtom);
  const setSelectedCalendar = useSetAtom(calendarSelectedAtom);
  const setSelectedFixedIncome = useSetAtom(fixedIncomeSelectedAtom);

  const status = calendar?.status as CalendarOrderStatusEnum;
  const isClosed = status === CalendarOrderStatusEnum.Closed || status === CalendarOrderStatusEnum.Closing;
  const isOnboardingCompleted = userInfo?.organization?.isOnboardingCompleted;

  const showAsNme = calendar.showAsNme;
  const allocationModalName = showAsNme ? FIXED_INCOME_ALLOCATION_MODAL_NAME : CALENDAR_ALLOCATION_MODAL_NAME;

  const openOnboardingModal = () => updateModalMap({ [MUST_COMPLETE_ONBOARDING_MODAL]: true });

  const openAllocationModal = () => {
    if (showAsNme) setSelectedFixedIncome(calendar);
    else setSelectedCalendar(calendar);

    updateModalMap({ [allocationModalName]: true });
  };

  const onClick = () => {
    if (!isOnboardingCompleted) openOnboardingModal();
    else openAllocationModal();
  };

  if (size === 'small') {
    return (
      <Button
        color="primary"
        type="button"
        variant="outlined"
        onClick={onClick}
        size="small"
        aria-label={translations.common.viewCalendar}
        sx={buttonSx}
        {...props}
      >
        <Iconography iconName="magnifying-glass" />
      </Button>
    );
  }

  const buttonText = isClosed ? translations.common.view : translations.common.edit;

  return (
    <LocalizedButton color="primary" type="button" variant="outlined" onClick={onClick} size="small" {...props}>
      {buttonText}
    </LocalizedButton>
  );
};
