import { ReactElement } from 'react';

import { V2AdminCalendarCard } from '@halo-common/components';
import { HaloDataGrid, HaloDataGridProps } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';
import { v4 as uuid } from 'uuid';

const SKELETON_DATA = [{ id: uuid() }, { id: uuid() }, { id: uuid() }, { id: uuid() }];

const commonColumnProps = {
  editable: false,
  resizable: false,
  disableReorder: true,
  flex: 1,
};

const tableSx = {
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  [`& .executionHubDividerHeader`]: {
    borderRightWidth: 2,
  },
  [`& .executionHubDividerCell`]: {
    borderRightWidth: 2,
  },
};

export const V2ExecutionHubLoadingList = (): ReactElement => {
  const loadingCardStyling = { m: 2 };

  const columns: HaloDataGridProps['columns'] = [
    {
      editable: false,
      resizable: false,
      disableReorder: true,
      field: 'status',
      headerName: '',
      width: 86,
    },
    {
      ...commonColumnProps,
      field: 'orderId',
      headerName: 'order #',
    },
    {
      ...commonColumnProps,
      field: 'repCode',
      headerName: 'rep code',
    },
    {
      ...commonColumnProps,
      field: 'accountId',
      headerName: 'account #',
    },
    {
      ...commonColumnProps,
      field: 'submitted',
      headerName: 'submitted by',
    },
    {
      ...commonColumnProps,
      field: 'category',
      headerName: 'account type',
      headerClassName: 'executionHubDividerHeader',
      cellClassName: 'executionHubDividerCell',
      translateCell: true,
    },
    {
      ...commonColumnProps,
      field: 'soldPrice',
      align: 'right',
      headerName: 'issuer reoffer',
    },
    {
      ...commonColumnProps,
      align: 'right',
      field: 'quantity',
      headerName: 'quantity',
    },
  ];

  return (
    <>
      {SKELETON_DATA.map(({ id }) => (
        <V2AdminCalendarCard key={id} sx={loadingCardStyling} loading actions={<Skeleton width={64} height={20} />}>
          <HaloDataGrid sx={tableSx} columns={columns} rows={SKELETON_DATA} density="compact" />
        </V2AdminCalendarCard>
      ))}
    </>
  );
};
