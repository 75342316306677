import { UserRoleEnum } from '@halo-common/enums';
import { useUserInfoQuery } from '@halo-data-sources/queries';

export type WhiteLabelRole = 'pershingDev' | 'janney' | 'pershing' | 'marketPlace';

export const useWhiteLabelRole = (): WhiteLabelRole => {
  const { data: user } = useUserInfoQuery();

  const roles = user?.details.roles ?? [];

  if (roles.includes(UserRoleEnum.JanneyOrderSender)) return 'janney';
  if (roles.includes(UserRoleEnum.PershingOrderSender)) return 'pershing';
  if (roles.includes(UserRoleEnum.DevPershingTester)) return 'pershingDev';
  return 'marketPlace';
};
