import { ReactElement } from 'react';

import { FindPriceButton } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Typography } from '@mui/material';

export type ProductCardSolvableParameterProps = {
  value: number | null;
  label: string;
  productId?: number;
  showRefresh?: boolean;
};

export const ProductCardSolvableParameter = ({
  value,
  label,
  productId,
  showRefresh = false,
}: ProductCardSolvableParameterProps): ReactElement => {
  const valueText = value?.toFixed(2);

  const findPriceButton = showRefresh ? (
    <FindPriceButton productId={productId} size="small" variant="icon">
      {translations.components.findPriceButtonRefreshText}
    </FindPriceButton>
  ) : null;

  return (
    <Stack direction="column" alignItems="center">
      <Stack direction="row" alignItems="center">
        <Stack direction="row" alignItems="flex-start">
          <Typography variant="h4" color="textPrimary">
            {valueText}
          </Typography>
          <Typography variant="subtitle2" color="textPrimary">
            %
          </Typography>
        </Stack>
        {findPriceButton}
      </Stack>
      <LocalizedTypography variant="subtitle2" color="textSecondary">
        {label}
      </LocalizedTypography>
    </Stack>
  );
};
