import { ReactElement, useEffect } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import { ActionButton } from '@halo-common/components';
import { GenerateReportFormFields, generateReportFormSchema } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { useGeneratePortfolioPerformanceReportMutation, useRecordActivityMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Iconography, Modal, Stack } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtom, useAtomValue } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';

import { GenerateReportForm } from './GenerateReportForm';

const buttonSx = {
  minWidth: 140,
};

const generateReportModalName = 'generateReportModal';

export const GenerateReportModal = (): ReactElement => {
  const { data: user } = useUserInfoQuery();

  const { mutate, reset, isPending, isSuccess, isError, error } = useGeneratePortfolioPerformanceReportMutation({
    pending: translations.portfolio.generateReportModal.downloadPendingMessage,
    success: translations.portfolio.generateReportModal.downloadSuccessMessage,
    error: translations.portfolio.generateReportModal.downloadErrorMessage,
  });

  const { mutate: recordActivity } = useRecordActivityMutation();

  const [modalMap, onModalToggle] = useAtom(modalAtom);

  const positionData = useAtomValue(portfolioPositionsManagerAtom);

  const { filters } = positionData;
  const { accountOption, currency } = filters;

  const currencies = user?.whiteLabel.currencies ?? [];
  const disableCurrencyDropdown = !user?.settings.hasMultiCurrencyRole;
  const currencyId = !disableCurrencyDropdown ? currency?.id : undefined;
  const selectedCurrency = currencyId ?? currencies[0]?.id;

  const formMethods = useForm<GenerateReportFormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver<GenerateReportFormFields>(generateReportFormSchema),
    defaultValues: { accountOption: null },
  });

  const { setValue, handleSubmit, clearErrors, resetField } = formMethods;

  const handleGenerateReportModalClose = () => {
    reset();
    resetField('accountOption');
    clearErrors('accountOption');
    void onModalToggle({ [generateReportModalName]: false });
  };

  const handleGenerateReport = handleSubmit((data: GenerateReportFormFields) => {
    const activityId = data.accountOption?.household?.id ?? data?.accountOption?.account?.accountId;
    const activityType = data.accountOption?.household ? 'household' : 'account';
    const activityVerb = `downloaded portfolio report for ${activityType} ${activityId}`;

    recordActivity({ activity: [{ verb: activityVerb, model_name: 'Portfolio' }], fromIFrame: false });

    mutate({
      accountId: data.accountOption?.account?.id,
      adviseeId: data.accountOption?.household?.id,
      reportingCurrencyId: data.currencyId,
      userId: user?.details.id,
    });
  });

  const footer = (
    <Stack direction="row" justify="flex-end">
      <ActionButton
        sx={buttonSx}
        variant="contained"
        size="large"
        loading={isPending}
        onClick={handleGenerateReport}
        endIcon={<Iconography iconName="download" color="common.white" />}
      >
        {translations.common.download}
      </ActionButton>
    </Stack>
  );

  useEffect(() => {
    if (isSuccess) handleGenerateReportModalClose();
  }, [isSuccess]);

  useEffect(() => {
    if (typeof selectedCurrency === 'number') setValue('currencyId', selectedCurrency);
  }, [selectedCurrency]);

  useEffect(() => {
    const hasOptionSelected = accountOption?.account || accountOption?.household;
    if (hasOptionSelected) setValue('accountOption', accountOption);
  }, [accountOption]);

  return (
    <Modal
      overline={translations.portfolio.common.portfolioDocumentOverline}
      title={translations.common.generateReport}
      subtitle={translations.portfolio.generateReportModal.subtitle}
      icon="file-chart-line"
      open={modalMap[generateReportModalName]}
      onClose={handleGenerateReportModalClose}
      footer={footer}
    >
      <FormProvider {...formMethods}>
        <GenerateReportForm invalid={isError} error={error} />
      </FormProvider>
    </Modal>
  );
};
