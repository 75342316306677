import { ReactElement } from 'react';

import { FindPriceButton } from '@halo-common/components';
import { NoteTypeEnum } from '@halo-common/enums';
import { useNoteSpecificDetails } from '@halo-common/hooks';
import { NoteModel, NoteflixNoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Skeleton, Typography } from '@mui/material';

export type PreTradeNoteDetailModalHeaderSolvableParameterProps = {
  product?: NoteModel | null;
  loading?: boolean;
  disableQuoteRefresh?: boolean;
};

export const PreTradeNoteDetailModalHeaderSolvableParameter = ({
  product,
  loading = true,
  disableQuoteRefresh = false,
}: PreTradeNoteDetailModalHeaderSolvableParameterProps): ReactElement | null => {
  const noteId = product?.id;
  const overriddenQuote = (product as NoteflixNoteModel)?.quote;
  const isGeneralNote = product?.type === NoteTypeEnum.General;

  const { solvableParameter, quote } = useNoteSpecificDetails(product, {
    overriddenQuote,
  });

  if (loading) return <Skeleton width={200} height={80} />;

  if (!noteId) return null;

  if (isGeneralNote)
    return (
      <LocalizedTypography variant="h6" color="textPrimary">
        {solvableParameter?.text}
      </LocalizedTypography>
    );

  if (!quote) return <FindPriceButton productId={noteId} label={solvableParameter?.label} />;

  const findPriceButton =
    !disableQuoteRefresh && !overriddenQuote ? (
      <FindPriceButton productId={noteId} variant="icon">
        {translations.components.findPriceButtonRefreshText}
      </FindPriceButton>
    ) : null;

  return (
    <Stack direction="column" xs={12}>
      <Stack direction="row" alignItems="center">
        <Stack direction="row" alignItems="flex-start">
          <Typography variant="h4" color="textPrimary">
            {solvableParameter?.original?.toFixed(2)}
          </Typography>
          <Typography variant="subtitle2" color="textPrimary">
            %
          </Typography>
        </Stack>
        {findPriceButton}
      </Stack>
      <LocalizedTypography variant="subtitle1" color="textSecondary">
        {solvableParameter?.label}
      </LocalizedTypography>
    </Stack>
  );
};
