import { ReactElement } from 'react';

import { myOpenAuctionsSearchFieldsAtom } from '@halo-atoms/dashboard';
import { ProductTypeChip } from '@halo-common/components';
import { COMBINED_DATE_TIME_ZONE_FORMAT } from '@halo-common/constants';
import { ProductTypeEnum } from '@halo-common/enums';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { GridPaginationModel, NoteModel, OrderBookAuctionModel } from '@halo-common/models';
import { useOrderBookAuctionsQuery } from '@halo-data-sources/queries';
import {
  AuctionDetailsModal,
  OrderBookAuctionCreateSimilarButton,
  OrderBookAuctionStatus,
  OrderBookAuctionViewButton,
  orderBookWidgetDataGridProps,
} from '@halo-modules/app';
import { HaloDataGrid, HaloDataGridProps, Stack, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { useAtom } from 'jotai';

export type DashboardOrderBookAuctionsTableProps = {
  loading?: boolean;
};

export const DashboardOrderBookAuctionsTable = ({
  loading = false,
}: DashboardOrderBookAuctionsTableProps): ReactElement => {
  const configureDateTime = useWhiteLabelDateConfiguration();

  const [searchFields, setSearchFields] = useAtom(myOpenAuctionsSearchFieldsAtom);

  const { data, ...query } = useOrderBookAuctionsQuery(searchFields, 10000);

  const isLoading = loading || query.isPending || query.isPlaceholderData;

  const rows = data?.auctions ?? [];
  const page = data?.pagination?.page ?? searchFields.page;
  const pageSize = data?.pagination?.resultsPerPage ?? searchFields.pageSize;
  const totalResults = data?.pagination?.totalResults ?? 0;
  const paginationModel = { page: page - 1, pageSize };

  const handlePaginationChange = (model: GridPaginationModel) => {
    setSearchFields((previous) => ({
      ...previous,
      page: model.page + 1,
      pageSize: model.pageSize,
    }));
  };

  const handleSortChange = (model: GridSortModel) => {
    setSearchFields((previous) => ({
      ...previous,
      sortBy: model,
    }));
  };

  const formatCurrency = (value: number) => useCurrencyConverter(value);

  const commonCellProps = {
    editable: false,
    disableColumnMenu: true,
    disableReorder: true,
    flex: 1,
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      field: 'id',
      headerName: 'Auction ID',
      flex: 0.8,
      sortable: false,
    },
    {
      ...commonCellProps,
      field: 'noteType',
      headerName: 'Note Type',
      renderCell: ({ value }) => {
        const note = { type: value, productType: value } as NoteModel;
        return <ProductTypeChip product={note} type={ProductTypeEnum.note} />;
      },
    },
    {
      ...commonCellProps,
      field: 'underlying',
      headerName: 'Underlying',
      sortable: false,
      valueGetter: (value: Array<string>) => value?.join(', '),
    },
    {
      ...commonCellProps,
      field: 'payoff',
      headerName: 'Target',
      flex: 1.8,
      sortable: false,
      valueGetter: (value: OrderBookAuctionModel['payoff']) => value?.payoffString,
    },
    {
      ...commonCellProps,
      field: 'notional',
      headerName: 'Notional',
      valueFormatter: (value) => formatCurrency(value),
    },
    {
      ...commonCellProps,
      field: 'endDate',
      headerName: 'End Date',
      valueFormatter: (value) => {
        const endDateTime = configureDateTime(value);
        return endDateTime?.toFormat(COMBINED_DATE_TIME_ZONE_FORMAT);
      },
      flex: 1.8,
    },
    {
      ...commonCellProps,
      field: 'status',
      headerName: 'Status',
      flex: 1.6,
      renderCell: ({ value }) => <OrderBookAuctionStatus value={value} />,
    },
    {
      ...commonCellProps,
      field: 'action',
      headerName: '',
      sortable: false,
      resizable: false,
      width: 104,
      minWidth: 104,
      maxWidth: 104,
      renderCell: ({ row }) => (
        <Stack direction="row" spacing={1}>
          <OrderBookAuctionCreateSimilarButton noteId={row.noteId} className="wm-dashboard-openorders-createsimilar" />
          <OrderBookAuctionViewButton auctionId={row.id} size="small" className="wm-dashboard-openorders-viewdetails" />
        </Stack>
      ),
    },
  ];

  return (
    <>
      <HaloDataGrid
        {...orderBookWidgetDataGridProps}
        columns={columns}
        rows={rows}
        loading={isLoading}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationChange}
        onSortModelChange={handleSortChange}
        rowCount={totalResults}
      />
      <AuctionDetailsModal />
    </>
  );
};
