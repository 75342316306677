import { ReactElement } from 'react';

import { EmptyResultsCard, InfiniteScroller } from '@halo-common/components';
import {
  AdminLoadingCardList,
  ExecutionHubCalendarCard,
  ExecutionHubStatusEnum,
  usePershingAdminContext,
} from '@halo-modules/admin';
import { PershingSelectors, PershingStatusEnum } from '@halo-stores/Pershing';
import { BrandIcon } from '@halodomination/halo-fe-common';
import { useSelector } from 'react-redux';

export const ExecutionHubCalendarList = (): ReactElement => {
  const calendarMap = useSelector(PershingSelectors.selectCalendarsForExecutionHub);
  const filteredTotals = useSelector(PershingSelectors.selectFilteredTotals);
  const totals = useSelector(PershingSelectors.selectTotals);
  const pershingStatus = useSelector(PershingSelectors.selectStatus);

  const { statusFilter, pageLoaded, pageLoading, tableConfiguration } = usePershingAdminContext();

  const { onInfiniteScroll, pageMap } = tableConfiguration;

  const isActiveStatus = statusFilter === ExecutionHubStatusEnum.available;

  const isFilteredTotalsDefined = filteredTotals !== null && filteredTotals !== undefined;
  const totalsMap = isFilteredTotalsDefined ? filteredTotals : totals;
  const totalCalendars = isActiveStatus ? totalsMap.available : totalsMap[statusFilter];
  const calendarsViewed = isActiveStatus ? pageMap.available * 25 : pageMap[statusFilter] * 25;

  const hasMore = totalCalendars > 25 && calendarsViewed < totalCalendars;

  const calendars = calendarMap[statusFilter as ExecutionHubStatusEnum];
  const noContent = pageLoaded && !calendars?.length;

  const handleInfiniteScroll = () => {
    onInfiniteScroll(statusFilter, pageMap[statusFilter] + 1);
  };

  if (noContent) {
    const brandIcon = <BrandIcon color="primary" variant="search" />;

    return (
      <EmptyResultsCard
        title="No Results"
        description="Filling Indications of Interest are not available at this time"
        brandIcon={brandIcon}
      />
    );
  }

  if (pageLoading) {
    return <AdminLoadingCardList />;
  }

  const infiniteScrolling = pershingStatus === PershingStatusEnum.requestFetchExecutionHubCalendars;

  return (
    <InfiniteScroller loading={infiniteScrolling} next={handleInfiniteScroll} hasMore={hasMore}>
      {calendars.map((calendar) => (
        <ExecutionHubCalendarCard key={calendar.id} calendar={calendar} />
      ))}
    </InfiniteScroller>
  );
};
