import { ReactElement } from 'react';

import { Skeleton, Typography } from '@mui/material';

export type PreTradeNoteDetailModalHeaderIdProps = {
  productId?: number;
  loading: boolean;
};

export const PreTradeNoteDetailModalHeaderId = ({
  productId,
  loading,
}: PreTradeNoteDetailModalHeaderIdProps): ReactElement | null => {
  if (loading) return <Skeleton variant="rounded" width={75} height={24} />;
  else if (!productId) return null;

  const idText = `ID# ${productId}`;

  return (
    <Typography variant="overline" color="textSecondary">
      {idText}
    </Typography>
  );
};
