import { modalAtom } from '@halo-atoms/common';
import { CalendarModel } from '@halo-common/models';
import { ExecutionHubOrder } from '@halo-data-sources/queries';
import { createStepAtoms } from '@halodomination/halo-fe-common';
import { atom } from 'jotai';

const _selectedCalendarAtom = atom<CalendarModel | null>(null);
const _selectedOrdersAtom = atom<Array<ExecutionHubOrder>>([]);

const EXECUTION_HUB_FILL_ALL_MODAL = 'executionHubFillAllModal';
export const executionHubFillModalAtom = atom(
  (get) => {
    const modalMap = get(modalAtom);
    const orders = get(_selectedOrdersAtom);
    const calendar = get(_selectedCalendarAtom);

    return { open: modalMap[EXECUTION_HUB_FILL_ALL_MODAL], orders, calendar };
  },
  (_, set, payload: { open: boolean; orders?: Array<ExecutionHubOrder>; calendar?: CalendarModel }) => {
    set(modalAtom, { [EXECUTION_HUB_FILL_ALL_MODAL]: payload.open });
    set(_selectedCalendarAtom, payload?.calendar ?? null);
    set(_selectedOrdersAtom, payload?.orders ?? []);
  },
);

const EXECUTION_HUB_CANCEL_MODAL = 'executionHubCancelModal';
export const executionHubCancelModalAtom = atom(
  (get) => {
    const modalMap = get(modalAtom);
    const orders = get(_selectedOrdersAtom);
    const calendar = get(_selectedCalendarAtom);

    return { open: modalMap[EXECUTION_HUB_CANCEL_MODAL], order: orders[0], calendar };
  },
  (_, set, payload: { open: boolean; order?: ExecutionHubOrder; calendar?: CalendarModel }) => {
    set(modalAtom, { [EXECUTION_HUB_CANCEL_MODAL]: payload.open });
    set(_selectedCalendarAtom, payload?.calendar ?? null);
    set(_selectedOrdersAtom, payload?.order ? [payload.order] : []);
  },
);

const EXECUTION_HUB_EDIT_REOFFER_MODAL = 'executionHubEditReofferModal';
export const executionHubEditReofferModalAtom = atom(
  (get) => {
    const modalMap = get(modalAtom);
    const orders = get(_selectedOrdersAtom);
    const calendar = get(_selectedCalendarAtom);

    return { open: modalMap[EXECUTION_HUB_EDIT_REOFFER_MODAL], reoffer: orders[0]?.allocation?.boughtPrice, calendar };
  },
  (_, set, payload: { open: boolean; order?: ExecutionHubOrder; calendar?: CalendarModel }) => {
    set(modalAtom, { [EXECUTION_HUB_EDIT_REOFFER_MODAL]: payload.open });
    set(_selectedCalendarAtom, payload?.calendar ?? null);
    set(_selectedOrdersAtom, payload?.order ? [payload.order] : []);
  },
);

const EXECUTION_HUB_PDM_MODAL = 'executionHubPDMModal';
export const executionHubPDMModalAtom = atom(
  (get) => {
    const modalMap = get(modalAtom);
    const calendar = get(_selectedCalendarAtom);

    return { open: modalMap[EXECUTION_HUB_PDM_MODAL], calendar };
  },
  (_, set, payload: { open: boolean; calendar?: CalendarModel }) => {
    set(modalAtom, { [EXECUTION_HUB_PDM_MODAL]: payload.open });
    set(_selectedCalendarAtom, payload?.calendar ?? null);
  },
);

export const executionHubEditReofferModalStepAtom = createStepAtoms({ max: 1 });

export const EXECUTION_HUB_ORDER_TICKET_MODAL_NAME = 'executionOrderTicket';
export const executionHubOrderTicketModalAtom = atom(
  (get) => {
    const modalMap = get(modalAtom);
    const calendar = get(_selectedCalendarAtom);
    const orders = get(_selectedOrdersAtom);

    return { open: modalMap[EXECUTION_HUB_ORDER_TICKET_MODAL_NAME], calendar, order: orders[0] };
  },
  (_, set, payload: { open?: boolean; calendar?: CalendarModel; order?: ExecutionHubOrder; reset?: boolean }) => {
    if (payload.open !== undefined) set(modalAtom, { [EXECUTION_HUB_ORDER_TICKET_MODAL_NAME]: payload.open });
    if (payload.calendar) set(_selectedCalendarAtom, payload?.calendar ?? null);
    if (payload.order) set(_selectedOrdersAtom, payload?.order ? [payload.order] : []);
    if (payload.reset) {
      set(modalAtom, { [EXECUTION_HUB_ORDER_TICKET_MODAL_NAME]: false });
      set(_selectedCalendarAtom, null);
      set(_selectedOrdersAtom, []);
    }
  },
);
