import { translations } from '@halo-common/translations';
import { ProgressBarEnum, mapNumberToLocalCurrency } from '@halodomination/halo-fe-common';
import { ITranslateParams } from '@transifex/native';

export type UseProductInventoryTrackingProps = {
  inventoryConsumed?: number | null;
  inventoryRemaining?: number | null;
  inventoryTotal?: number | null;
  isCalendarClosed?: boolean;
  isPaused?: boolean;
};

export type UseProductInventoryTrackingReturn = {
  percentFilled: number;
  variant: ProgressBarEnum;
  label: string;
  dynamicContent: ITranslateParams;
};

export const useProductInventoryTracking = ({
  inventoryConsumed = 0,
  inventoryRemaining,
  inventoryTotal,
  isCalendarClosed,
  isPaused,
}: UseProductInventoryTrackingProps): UseProductInventoryTrackingReturn | undefined => {
  const noInventoryConsumed = typeof inventoryConsumed !== 'number';
  const noInventoryRemaining = typeof inventoryRemaining !== 'number';
  const noInventoryTotal = typeof inventoryTotal !== 'number';

  const noReturn = noInventoryConsumed || noInventoryRemaining || noInventoryTotal;

  if (noReturn) return undefined;

  const percentFilled = Math.abs(inventoryConsumed / inventoryTotal) * 100;

  const isDisabled = inventoryRemaining === 0 || isCalendarClosed || isPaused;

  const variant = isDisabled ? ProgressBarEnum.DISABLED : ProgressBarEnum.PRIMARY;

  const remainingValue = mapNumberToLocalCurrency(inventoryRemaining, { maximumFractionDigits: 0 });
  const label = isCalendarClosed
    ? translations.calendars.common.offeringClosed
    : isPaused
      ? translations.calendars.common.offeringPaused
      : translations.calendars.common.inventoryRemaining;

  const dynamicContent = { inventory: remainingValue };

  return { percentFilled, variant, label, dynamicContent };
};
