import { ReactElement, useState } from 'react';

import { IconName } from '@fortawesome/fontawesome-svg-core';
import { EducationVideoPlayer } from '@halo-modules/app';
import { Iconography, IconographyProps, LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Collapse, Paper, Stack } from '@mui/material';

const definitionBodySx = {
  padding: 2,
  borderRadius: 2,
  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3',
};

const containerSx = {
  width: '100%',
};

const iconSx = {
  flex: 0.5,
};

const descriptionSx = {
  flex: 1,
};

const subContainerSx = {
  maxWidth: '100%',
};

export type EducationDefinitionProps = {
  iconName?: IconographyProps['iconName'];
  title?: string;
  children: string;
  videoUrl?: string | null;
  bold?: boolean;
  italics?: boolean;
};

export const EducationDefinition = ({
  iconName,
  title,
  children,
  videoUrl,
  bold = false,
  italics = false,
}: EducationDefinitionProps): ReactElement => {
  const [showVideo, setShowVideo] = useState(false);

  const contentSx = {
    fontStyle: italics ? 'italic' : 'normal',
    fontWeight: bold ? 'fontWeightBold' : 'fontWeightRegular',
    wordBreak: 'break-word',
  };

  const videoButtonIconName: IconName = showVideo ? 'eye-slash' : 'play-circle';
  const videoButtonText = showVideo ? 'Hide Video' : 'Show Video';

  const toggleVideo = () => void setShowVideo(!showVideo);

  const iconContent = iconName ? (
    <Iconography sx={iconSx} color="primary.main" prefix="fas" iconName={iconName} flair="shadowless" size="1.25x" />
  ) : null;

  const titleContent = title ? (
    <LocalizedTypography color="primary" variant="h6">
      {title}
    </LocalizedTypography>
  ) : null;

  const videoContent = videoUrl ? (
    <Collapse sx={subContainerSx} in={showVideo} data-testid="video-element">
      <EducationVideoPlayer path={videoUrl} />
    </Collapse>
  ) : null;

  const videoButton = videoUrl ? (
    <LocalizedButton
      color="primary"
      size="large"
      variant="outlined"
      startIcon={<Iconography color="primary.main" prefix="fas" iconName={videoButtonIconName} />}
      onClick={toggleVideo}
    >
      {videoButtonText}
    </LocalizedButton>
  ) : null;

  return (
    <Paper sx={definitionBodySx}>
      <Stack sx={containerSx} direction="row" spacing={2}>
        {iconContent}
        <Stack sx={descriptionSx} direction="column" justifyContent="space-between" spacing={1}>
          {titleContent}
          <LocalizedTypography sx={contentSx} color="textPrimary" variant="body1">
            {children}
          </LocalizedTypography>
          {videoContent}
          {videoButton}
        </Stack>
      </Stack>
    </Paper>
  );
};
