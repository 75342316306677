import { DocumentUserFile } from '@halo-common/models';
import { getUserFileList } from '@halo-data-sources/clients';
import { UserQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getUserFilesQueryFn = async () => {
  const response = await getUserFileList();

  return response.map(
    (model): DocumentUserFile => ({
      id: model.id.toString(),
      document: { filename: model.document.filename, extension: model.document.extension },
      notes: model.notes,
    }),
  );
};

export const useUserFilesQuery = (): UseQueryResult<Array<DocumentUserFile>, Error> =>
  useQuery<Array<DocumentUserFile>, Error>({
    queryKey: UserQueryKeyFactory.files(),
    queryFn: () => getUserFilesQueryFn(),
  });
