import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { EducationProgressBar, ModuleFinishedGraphic, useCourseManager } from '@halo-modules/app';
import {
  Confetti,
  Iconography,
  LocalizedButton,
  LocalizedTypography,
  useCombinedStyling,
} from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';

const pageContainerSx = { minHeight: 450 };
const headlineSx = { pb: 1 };
const titleSx = { pb: 3 };
const nextButtonSx = { mb: 2 };
const buttonSx = { color: 'primary.contrastText' };

export const ModuleFinishedPage = (): ReactElement => {
  const { progress, courseGroups, onReset, onNextGroup } = useCourseManager();

  const currentGroup = progress.group;
  const nextGroup = courseGroups.find((next) => next.id !== currentGroup?.id && next.percentageComplete !== 100);

  const groupTitle = currentGroup?.title;
  const percentageComplete = currentGroup?.percentageComplete ?? 0;

  const completedRequiredEducation = courseGroups.every((courseGroup) => courseGroup.percentageComplete >= 100);
  const nextCourseButtonText = completedRequiredEducation
    ? translations.education.educationFinishedPage.finishEducation
    : translations.education.educationFinishedPage.startNextUnfinished;

  const handleCourseReset = () => onReset();
  const handleNextCourse = () => {
    if (completedRequiredEducation) onReset();
    else if (nextGroup) onNextGroup(nextGroup);
  };

  return (
    <Stack direction="column" alignItems="center" sx={pageContainerSx}>
      <Confetti width={window.innerWidth} numberOfPieces={115} initialVelocityY={4} />
      <EducationProgressBar progress={percentageComplete} size="large" />
      <ModuleFinishedGraphic>
        <Stack direction="column" alignItems="center" justifyContent="center">
          <LocalizedTypography sx={headlineSx} color="primary.contrastText" variant="overline">
            {translations.education.educationFinishedPage.congrats}
          </LocalizedTypography>
          <LocalizedTypography sx={titleSx} color="primary.contrastText" variant="h2" align="center">
            {groupTitle}
          </LocalizedTypography>
        </Stack>
        <Stack direction="column" alignItems="center" justifyContent="center">
          <LocalizedButton
            sx={useCombinedStyling(nextButtonSx, buttonSx)}
            color="inherit"
            variant="outlined"
            size="large"
            onClick={handleNextCourse}
          >
            {nextCourseButtonText}
          </LocalizedButton>
          <LocalizedButton
            sx={buttonSx}
            type="button"
            color="inherit"
            startIcon={<Iconography color="primary.contrastText" prefix="fas" iconName="arrow-left" />}
            variant="text"
            onClick={handleCourseReset}
          >
            {translations.education.educationFinishedPage.allCourses}
          </LocalizedButton>
        </Stack>
      </ModuleFinishedGraphic>
    </Stack>
  );
};
