import { useHaloInfiniteQuery } from '@halo-common/hooks';
import type { GetNoteflixCategoriesResult } from '@halo-common/models';
import { InfinitePaginationModel, InfiniteQueryOptions } from '@halo-common/models';
import { getNoteflixCategories } from '@halo-data-sources/clients';
import { ApiNoteflixMapper } from '@halo-data-sources/mappers';
import { NoteflixQueryKeyFactory } from '@halo-data-sources/queries';
import type { UseInfiniteQueryResult } from '@tanstack/react-query';

export type UseNoteflixCategoriesQueryOptions = InfiniteQueryOptions<GetNoteflixCategoriesResult> & { from?: number };
export type UseNoteflixCategoriesQueryResult = UseInfiniteQueryResult<GetNoteflixCategoriesResult, Error>;

const fetchNoteflixCategoriesQueryFn = async (pageParam: InfinitePaginationModel) => {
  const response = await getNoteflixCategories(pageParam.next);
  return ApiNoteflixMapper.toNoteflixCategories(response, pageParam.next);
};

export const useNoteflixCategoriesQuery = (options?: Partial<UseNoteflixCategoriesQueryOptions>) => {
  const { from = 0, ...rest } = options || {};

  // TODO: Update this when we have a better BE endpoint for Noteflix
  const DEFAULT_PAGINATION: InfinitePaginationModel = {
    next: from,
    page: from,
    totalResults: from,
    resultsPerPage: from,
    totalPages: from,
  };

  return useHaloInfiniteQuery<GetNoteflixCategoriesResult>({
    initialPageParam: DEFAULT_PAGINATION,
    queryKey: NoteflixQueryKeyFactory.categories(),
    queryFn: ({ pageParam }) => fetchNoteflixCategoriesQueryFn(pageParam),
    getNextPageParam: (lastPage) => lastPage?.pagination ?? DEFAULT_PAGINATION,
    ...rest,
  });
};
