import { ReactElement } from 'react';

import { SMAStrategyModel } from '@halo-common/models';
import { useSMAStrategiesQuery } from '@halo-data-sources/queries/sma/useSMAStrateiesQuery';
import { StrategyCard } from '@halo-modules/app';
import { Skeleton, Stack } from '@mui/material';

type StrategyContentProps = {
  onSubmitAllocation: (strategy: SMAStrategyModel) => void;
};

export const StrategyCards = ({ onSubmitAllocation }: StrategyContentProps): ReactElement => {
  const { data: strategies, isLoading } = useSMAStrategiesQuery();

  if (isLoading || !strategies) {
    return <Skeleton height={80} />;
  }

  const sortedStrategies = strategies.sort((a, b) => a.position - b.position);

  return (
    <Stack direction="column" spacing={3}>
      {sortedStrategies?.map((strategy) => (
        <StrategyCard key={strategy.id} onSubmitAllocation={onSubmitAllocation} strategy={strategy} />
      ))}
    </Stack>
  );
};
