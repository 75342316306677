import { getCalendarName, updateQuote } from '@halo-data-sources/clients';
import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';

export type CalendarNameMutationPayload = {
  calendarId: number;
  quoteId: number;
  value: number;
};

const calendarNameMutationFn = async (payload: CalendarNameMutationPayload) => {
  await updateQuote(payload.quoteId, payload.value);

  const response = await getCalendarName([payload.calendarId]);

  return response.calendar_names[0]?.name ?? null;
};

export const useCalendarNameMutation = (
  options?: UseMutationOptions<string | null, Error, CalendarNameMutationPayload>,
): UseMutationResult<string | null, Error, CalendarNameMutationPayload> =>
  useMutation<string | null, Error, CalendarNameMutationPayload>({
    mutationFn: calendarNameMutationFn,
    ...options,
  });
