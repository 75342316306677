import { ReactElement } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { orderTicketReceiptAtom } from '@halo-atoms/orderTicket';
import { ExecutionOrderReceipt } from '@halo-common/components';
import { OrderTicketFormModelFields } from '@halo-common/modals';
import { useAtomValue } from 'jotai';
import { useFormContext } from 'react-hook-form';

export const OrderTicketSingleOrderReceipt = (): ReactElement => {
  const selectedCalendar = useAtomValue(calendarSelectedAtom);
  const orderTicketReceipt = useAtomValue(orderTicketReceiptAtom);

  const fieldMethods = useFormContext<OrderTicketFormModelFields>();
  const account = fieldMethods?.watch?.('account.account');

  const order = orderTicketReceipt?.order;
  const summary = orderTicketReceipt?.summary;

  return <ExecutionOrderReceipt calendarId={selectedCalendar?.id} order={order} summary={summary} account={account} />;
};
