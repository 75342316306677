export const HALO_ORGANIZATION_DETAILS = {
  name: 'Halo',
  fullName: 'Halo Investing, Inc.',
  images: {
    logo: '/static/img/powered_by_halo.svg',
    background: null,
  },
  documents: {
    privacyPolicy: '/static/assets/legal/privacy-policy.txt',
    termsOfUse: '/static/assets/legal/Halo%20Investing%20Terms%20of%20Use.pdf',
  },
  operations: {
    name: 'Halo Operations',
    phone: '312-586-2790',
    email: 'ops@haloinvesting.com',
  },
};
