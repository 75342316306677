import { PrimitiveAtom, useAtom } from 'jotai';

type EventCallback<EventType> = (event: EventType) => void;

export type EventModel<EventType, ComparisonType> = {
  [key: string]: ComparisonType | EventCallback<EventType> | boolean | undefined;
  callback?: EventCallback<EventType>;
  pending: boolean;
};

export type EventQueueModel<EventType, ComparisonType> = {
  name: string;
  queue: Array<EventModel<EventType, ComparisonType>>;
  clear: () => void;
  add: (id: ComparisonType, eventCallback?: EventCallback<EventType>) => void;
  remove: (id: ComparisonType) => void;
  contains: (id?: ComparisonType) => boolean;
  pending: (id?: ComparisonType) => boolean;
  replace: (id: ComparisonType, event: Partial<EventModel<EventType, ComparisonType>>) => void;
};

export type EventQueueSocketResult<EventType> = {
  name: string;
  handler: (event: EventType) => void;
};

export type EventQueueResult<EventType, ComparisonType> = [
  EventQueueSocketResult<EventType>,
  EventQueueModel<EventType, ComparisonType>,
];

export const useEventQueue = <EventType extends Record<string, unknown>, ComparisonType extends unknown>(
  eventTypeName: string,
  comparisonKey: string,
  queueState: PrimitiveAtom<Array<EventModel<EventType, ComparisonType>>>,
): EventQueueResult<EventType, ComparisonType> => {
  const [queue, setQueue] = useAtom(queueState);

  const parseKeyValueFromEvent = (key: string, event: EventType) => {
    const keys = key.split('.');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return keys.reduce((value: any, key: string) => value[key] ?? value, event);
  };

  const handleEvent = (event: EventType) => {
    const incomingEventValue = parseKeyValueFromEvent(comparisonKey, event);
    const queuedEvent = queue.find((next) => next?.[comparisonKey] === incomingEventValue);

    queuedEvent?.callback?.(event);
  };

  const handleDequeue = (id: ComparisonType) => {
    setQueue((prev) => prev.filter((next) => next?.[comparisonKey] !== id));
  };

  const handleEnqueue = (id: ComparisonType, callback?: EventCallback<EventType>) => {
    setQueue((prev) => [...prev, { [comparisonKey]: id, callback, pending: true }]);
  };

  const handleClear = () => {
    setQueue([]);
  };

  const handleContains = (id?: ComparisonType) => {
    if (id === null || id === undefined) return false;
    return queue.some((next) => next?.[comparisonKey] === id);
  };

  const handlePending = (id?: ComparisonType) => {
    if (id === null || id === undefined) return false;
    return queue.some((next) => next?.[comparisonKey] === id && next.pending);
  };

  const handleReplace = (id: ComparisonType, event: Partial<EventModel<EventType, ComparisonType>>) => {
    setQueue((prev) => {
      const updatedQueue = [...prev];
      const index = updatedQueue.findIndex((next) => next?.[comparisonKey] === id);

      if (index !== -1) updatedQueue[index] = { ...updatedQueue[index], ...event };

      return updatedQueue;
    });
  };

  return [
    {
      name: eventTypeName,
      handler: handleEvent,
    },
    {
      queue,
      name: eventTypeName,
      remove: handleDequeue,
      clear: handleClear,
      add: handleEnqueue,
      replace: handleReplace,
      pending: handlePending,
      contains: handleContains,
    },
  ];
};
