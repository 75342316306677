import { AuctionStyleEnum } from '@halo-common/enums';
import { StrikeStyleEnum } from '@halo-data-sources/enums';
import {
  InferType,
  array as yupArray,
  mixed as yupMixed,
  number as yupNumber,
  object as yupObject,
  string as yupString,
} from 'yup';

const numberErrorMsg = 'Please enter a Target of at least 0.01';

export const startAuctionSchema = yupObject().shape({
  issuers: yupArray().of(yupNumber().required()).required(),
  notional: yupNumber().required(),
  strikeDate: yupString().required(),
  tradeDate: yupString().required(),
  settlementDate: yupString().required(),
  noteId: yupNumber().required(),
  auctionStyle: yupMixed<AuctionStyleEnum>().required().oneOf(Object.values(AuctionStyleEnum)),
  strikeMode: yupMixed<StrikeStyleEnum>().required().oneOf(Object.values(StrikeStyleEnum)),
  number: yupNumber().required(numberErrorMsg).min(0.01, numberErrorMsg),
  allocations: yupArray().of(yupNumber().required()).required(),
  details: yupString().optional(),
});

export interface StartAuctionFormFields extends InferType<typeof startAuctionSchema> {}
