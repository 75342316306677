import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { startAuction } from '@halo-data-sources/clients';
import { ApiAuctionMapper, AuctionMapper } from '@halo-data-sources/mappers';
import { StartAuctionModel, StartAuctionResponseModel } from '@halo-stores/Orders/OrdersModel';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

const startAuctionMutationFn = async (startAuctionItem: StartAuctionModel): Promise<StartAuctionResponseModel> => {
  const mappedItem = AuctionMapper.toApiStartAuctionRequest(startAuctionItem);
  const response = await startAuction(mappedItem);
  return ApiAuctionMapper.toStartAuctionResponseModel(response);
};

export const useStartAuctionMutation = (): UseMutationResult<StartAuctionResponseModel, Error, StartAuctionModel> => {
  const { enqueueErrorEvent } = useSnackbar();

  return useMutation<StartAuctionResponseModel, Error, StartAuctionModel>({
    mutationFn: startAuctionMutationFn,
    onSettled: (data, error) => {
      if (error) enqueueErrorEvent({ message: translations.common.error });
      else if (data) navigateParentTo(`/buyer/auctions/${data.auctionId}`);
    },
  });
};
