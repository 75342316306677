import { ReactElement, ReactNode } from 'react';

import { Tabs, Iconography, Stack } from '@halodomination/halo-fe-common';
import { Button, Paper, Typography } from '@mui/material';
import { GridCsvExportOptions } from '@mui/x-data-grid-pro';
import { DateTime } from 'luxon';

const captionStyling = {
  display: 'block',
  mt: 1,
  mx: 0,
  mb: 3,
  maxWidth: 600,
};
const containerStyling = {
  margint: 0,
  marginx: 0,
  marginb: 5,
  padding: 2,
};

export type AuctionTableLayoutProps = {
  children: ReactNode;
  description: string;
  title: string;
  onIssuersToggle?: (value: boolean) => void;
  onExport: (options?: GridCsvExportOptions) => void;
};

export const AuctionTableLayout = ({
  children,
  description,
  title,
  onIssuersToggle,
  onExport,
}: AuctionTableLayoutProps): ReactElement => {
  const tabs = [
    { label: 'Details View', value: 'false' },
    { label: 'Quotes View', value: 'true' },
  ];

  const handleChange = (index: number) => {
    onIssuersToggle?.(tabs[index].value === 'true');
  };

  const handleExport = () =>
    void onExport({
      fileName: `${title.toLowerCase().replace(' ', '-')}_${DateTime.now().toFormat('dd-mm-yyyy_hh-mm-ss')}`,
      fields: [
        'id',
        'noteId',
        'cusip',
        'isin',
        'notional',
        'endDateTime',
        'targetTradeDate',
        'targetStrikeDate',
        'targetSettleDate',
        'submittedDate',
      ],
    });

  return (
    <Paper sx={containerStyling}>
      <Stack direction="row" justify="space-between">
        <Typography variant="h3">{title}</Typography>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Tabs variant="scrollablePills" onChange={handleChange} tabs={tabs} />
          <Button size="small" color="primary" variant="outlined" onClick={handleExport}>
            Export&nbsp;&nbsp;
            <Iconography iconName="file-export" color="primary.main" />
          </Button>
        </Stack>
      </Stack>
      <Typography sx={captionStyling} variant="caption">
        {description}
      </Typography>
      {children}
    </Paper>
  );
};
