import { ReactElement } from 'react';

import { DocumentBucketModel, SMAStrategyModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useRecordActivityMutation } from '@halo-data-sources/mutations';
import { StrategyClickableDocument } from '@halo-modules/app';
import { Iconography, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Box, Button, Paper, useMediaQuery, useTheme } from '@mui/material';

const containerSx = { p: 4 };

export type StrategyCardProps = {
  strategy: SMAStrategyModel;
  onSubmitAllocation: (strategy: SMAStrategyModel) => void;
};

export const StrategyCard = ({ onSubmitAllocation, strategy }: StrategyCardProps): ReactElement => {
  // TODO: Update the breakpoint hooks and use them here.
  const theme = useTheme();
  const isLargeDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { mutate: recordActivity } = useRecordActivityMutation();

  const {
    description,
    name: strategyTitle,
    firm: { logoImage },
    contentBlocks,
    minimumInvestmentAmount,
    insideTheStrategy,
    factSheet,
    pitchBook,
    suitability,
  } = strategy;

  const submitAllocationSectionSx = isLargeDesktop ? { width: '100%' } : undefined;
  const submitAllocationSectionJustify = !isLargeDesktop ? 'flex-end' : undefined;
  const actionSectionSpacing = isLargeDesktop ? 5 : 2.5;

  const handleSubmitAllocation = () => {
    onSubmitAllocation(strategy);
    recordActivity({
      activity: [{ verb: `clicked submit sma allocation`, model_name: 'SMAStrategyModel' }],
      fromIFrame: false,
    });
  };

  const clickableDocumentList = [insideTheStrategy, factSheet, pitchBook, suitability];
  const clickableDocuments = clickableDocumentList.filter(Boolean) as Array<DocumentBucketModel>;

  const hardCodedSMAButtons = clickableDocuments.map((document) => {
    return <StrategyClickableDocument key={document?.id} text={document.caption} document={document} />;
  });

  const dynamicSMAButtons = contentBlocks?.map((contentBlock) => {
    if (contentBlock.link) {
      return (
        <StrategyClickableDocument
          key={contentBlock.text}
          text={contentBlock.text}
          hyperlink={contentBlock.link}
          document={contentBlock?.document}
        />
      );
    } else if (contentBlock?.document) {
      return (
        <StrategyClickableDocument key={contentBlock.text} text={contentBlock.text} document={contentBlock?.document} />
      );
    }

    return null;
  });

  return (
    <Paper variant="outlined" sx={containerSx} elevation={0}>
      <Stack direction="row" justify="space-between" xs={6}>
        <Stack direction="column" spacing={1}>
          <Box component="img" src={logoImage} alt="strategy logo" sx={{ maxWidth: '360px', maxHeight: '64px' }} />
          <LocalizedTypography variant="h5" color="text.primary">
            {strategyTitle}
          </LocalizedTypography>
          <LocalizedTypography variant="body2" color="text.primary">
            {description}
          </LocalizedTypography>
        </Stack>
        <Stack direction="row" spacing={actionSectionSpacing} justify="flex-end" xs={12} lg={[8, 4]} wrap="wrap">
          <Stack direction="row" justify="flex-end" wrap="wrap" spacing={2}>
            {hardCodedSMAButtons}
            {dynamicSMAButtons}
          </Stack>
          <Stack
            direction="column"
            spacing={2.5}
            lg={12}
            elementStyling={submitAllocationSectionSx}
            alignItems={submitAllocationSectionJustify}
          >
            <Stack direction="column">
              <LocalizedTypography fontWeight={400} fontSize={12} color="text.secondary">
                {translations.sma.minimumInvestment}
              </LocalizedTypography>
              <LocalizedTypography fontWeight={600} fontSize={18} color="text.primary">
                ${minimumInvestmentAmount?.toLocaleString()}
              </LocalizedTypography>
            </Stack>
            <Button
              onClick={() => handleSubmitAllocation()}
              size="large"
              variant="contained"
              fullWidth={isLargeDesktop}
              startIcon={<Iconography color="primary.contrastText" iconName="plus" />}
            >
              {translations.sma.submitAllocation}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};
