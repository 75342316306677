import { ReactElement } from 'react';

import { CircularProgress } from '@mui/material';

export type EducationProgressBarBackgroundProps = {
  size?: number;
  backgroundThickness?: number;
  color?: 'primary' | 'secondary';
  offsetBackground?: number;
};

export const EducationProgressBarBackground = ({
  size = 68,
  backgroundThickness = 4,
  color = 'primary',
  offsetBackground = 0,
}: EducationProgressBarBackgroundProps): ReactElement => {
  const backgroundStyling = {
    color: color === 'primary' ? 'primary.background' : 'secondary.background',
  };

  return (
    <CircularProgress
      size={size - offsetBackground}
      thickness={backgroundThickness}
      variant="determinate"
      value={100}
      sx={backgroundStyling}
    />
  );
};
