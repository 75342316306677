import { ReactElement } from 'react';

import { OrganizationOnboardingStatusEnum } from '@halo-common/enums';
import { useModalState } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { OnboardingSteps } from '@halo-modules/app/dashboard/components';
import { Iconography, LocalizedButton, LocalizedTypography, Modal } from '@halodomination/halo-fe-common';
import { Paper, Stack } from '@mui/material';

export const MUST_COMPLETE_ONBOARDING_MODAL = 'MUST_COMPLETE_ONBOARDING_MODAL';

const SCHEDULE_TIME_URL =
  'https://meetings.hubspot.com/taylor-jenkins/meet-with-halo-investing-regional-vice-president?utm_campaign=Email+-+Platform+Re-engagement&utm_source=platform-meeting-request-click&uuid=6bf4bfcb-6afc-4bb3-9867-9f288722a6f8';

const paperSx = {
  py: 2,
  px: 6,
  borderRadius: 3,
};

const suitability = translations.onboarding.suitability;

const getTitle = (onboardingStatus: OrganizationOnboardingStatusEnum) => {
  switch (onboardingStatus) {
    case OrganizationOnboardingStatusEnum.DOCS_NOT_SIGNED:
      return suitability.authorizedSignerMustReviewAndSignPaperwork;
    case OrganizationOnboardingStatusEnum.UNDER_HALO_REVIEW:
      return suitability.complianceTeamIsReviewingFirmsPaper;
    default:
      return suitability.pleaseCompleteOnboardingSuitability;
  }
};

export const MustCompleteOnboardingModal = (): ReactElement | null => {
  const { open, setOpen } = useModalState(MUST_COMPLETE_ONBOARDING_MODAL);

  const handleClose = () => setOpen(false);

  const { data: userInfo } = useUserInfoQuery();

  const organization = userInfo?.organization;

  if (!organization) return null;

  const { onboardingStatus, isOnboardingCompleted } = organization;

  if (isOnboardingCompleted) return null;

  const title = getTitle(onboardingStatus);

  const scheduleTime = () => navigateParentTo(SCHEDULE_TIME_URL, true);

  return (
    <Modal open={open} title={title} onClose={handleClose}>
      <Paper sx={paperSx} elevation={6}>
        <OnboardingSteps />
      </Paper>
      <Stack direction="column" gap={2} alignItems="center" justifyContent="center" mt={3}>
        <LocalizedTypography variant="subtitle2" color="text.secondary" textAlign="center">
          {suitability.scheduleTimeIfNeedAssistance}
        </LocalizedTypography>
        <LocalizedButton onClick={scheduleTime} startIcon={<Iconography iconName="calendar" color="inherit" />}>
          {suitability.scheduleTime}
        </LocalizedButton>
      </Stack>
    </Modal>
  );
};
