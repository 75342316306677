import { PortfolioPositionsTemplate } from '@halo-common/models';
import { getPortfolioPositionsAggregationTemplates } from '@halo-data-sources/clients';
import { ApiPositionsMapper } from '@halo-data-sources/mappers';
import { PortfolioQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getPortfolioPositionAggregationTemplatesQueryFn = async () => {
  const results = await getPortfolioPositionsAggregationTemplates();

  return results.paths.map(ApiPositionsMapper.toFilterTemplates);
};

export const usePortfolioPositionAggregationTemplatesQuery = (): UseQueryResult<
  Array<PortfolioPositionsTemplate>,
  Error
> =>
  useQuery<Array<PortfolioPositionsTemplate>, Error>({
    queryKey: PortfolioQueryKeyFactory.aggregateTemplates(),
    queryFn: () => getPortfolioPositionAggregationTemplatesQueryFn(),
    staleTime: Infinity,
  });
