import { ReactElement } from 'react';

import { Iconography, IconographyProps } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, Fade } from '@mui/material';

const iconContainerStyling = { position: 'absolute', left: (theme: HaloTheme) => theme.spacing(-2) };

const ICON_TIMEOUT = 300;

export type TileCardIconProps = {
  iconName: IconographyProps['iconName'];
  show: boolean;
};

export const TileCardIcon = ({ show = false, iconName = 'arrow-right' }: TileCardIconProps): ReactElement => (
  <Box sx={iconContainerStyling}>
    <Fade in={show} timeout={ICON_TIMEOUT}>
      <Box>
        <Iconography color="primary.main" prefix="fal" iconName={iconName} flair />
      </Box>
    </Fade>
  </Box>
);
