export const ADD_POSITIONS_TO_ACCOUNT_TRANSLATIONS = {
  multipleAssetAssetIdError: `The following IDs were not found in our database, please contact your relationship manager to have them added:`,
  multipleAssetHelperText: 'Enter {assetIds} separated by a space, line break, or comma.',
  multipleAssetJhnAlert: `You cannot add positions with these IDs:`,
  multipleAssetNoAssetIdError: `Please enter one or more valid IDs separated by a space, line break, or comma`,
  multipleAssetQueueAlert: `The following IDs will be added to our database in the next 48 hours:`,
  overline: 'Link your portfolio holdings',
  stepOneSubtitle: 'You will specify the notional amount one asset at a time in the next step.',
  stepOneTitle: `Enter the {assetIds} you'd like to add to {account}`,
  stepThreeSubtitle: `It may take up to 48 hours for some assets to appear as they get added to Halo's database.`,
  stepThreeTitle: 'These assets will appear in your portfolio shortly!',
  stepTwoTitle: `Assign a notional amount to each ID.`,
  enterAccountError: 'Please enter an account.',
  enterNotionalError: 'Please enter a notional amount greater or equal to 1.',
};
