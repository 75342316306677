export * from './calendars';
export * from './execution';
export * from './postTrade';
export * from './preTrade';
export * from './rollingReturns';

export const PRODUCT_DETAILS_MODAL_COMMON = {
  commissionSection: 'Commission Details',
  earlyRedemptionSection: 'Early Redemption Details',
  callLevel: 'Call Level',
  couponProtection: 'Coupon Protection',
  createSimilarButton: 'Create Similar',
  fullProtection: 'Full Protection',
  issuerSection: 'Issued By',
  noProtection: 'No Protection',
  payoffSection: 'Payoff Details',
  principalProtectionSection: 'Principal Protection',
  principalSection: 'Principal Details',
  protectionBarrier: '{protection}% Barrier',
  protectionBuffer: '{protection}% Buffer',
  protectionContinuous: '{protection}% Continuous',
  protectionDaily: '{protection}% Daily',
  protectionHard: '{protection}% Hard',
  protectionSoft: '{protection}% Soft',
  settlementDate: 'Settlement Date',
  targetedWatchlistPopoverTitle: 'View My Targeted Products',
  targetedWatchlistTargetsUsedMessage: `{count} Note Targets Used`,
  tradeDate: 'Trade Date',
  underlyingSection: 'Underlying Details',
  setQuoteTarget: 'Set Quote Target',
};
