import { ReactElement } from 'react';

import { useRecentQuotesQuery, useWatchlistNotesQuery } from '@halo-data-sources/queries';
import {
  WatchlistCardStack,
  WatchlistDetailsEmptyMessage,
  WatchlistDetailsHeader,
  WatchlistDetailsSkeleton,
} from '@halo-modules/app';
import { Stack } from '@mui/material';

export type WatchlistDetailsProps = {
  id: number;
  loading: boolean;
};

export const WatchlistDetails = ({ id, loading }: WatchlistDetailsProps): ReactElement => {
  const {
    data,
    isPending: noteQueryLoading,
    fetchNextPage,
    hasNextPage,
    isSuccess,
    isError,
  } = useWatchlistNotesQuery(id);

  const products = data?.pages.flatMap((page) => page?.products ?? []) ?? [];
  const productIds = data?.pages[data?.pages?.length - 1]?.products.map(({ product }) => product.id) ?? [];

  useRecentQuotesQuery(productIds);

  const isLoading = loading || noteQueryLoading;

  if (isLoading) return <WatchlistDetailsSkeleton />;

  const hasMore = Boolean(hasNextPage);
  const scrollerProps = { next: fetchNextPage, hasMore, loading: isLoading };

  const loaded = isError || isSuccess;
  const isEmptyWatchlist = loaded && products.length === 0;

  const content = isEmptyWatchlist ? (
    <WatchlistDetailsEmptyMessage />
  ) : (
    <WatchlistCardStack products={products} ScrollerProps={scrollerProps} />
  );

  return (
    <Stack direction="column" spacing={4}>
      <WatchlistDetailsHeader />
      {content}
    </Stack>
  );
};
