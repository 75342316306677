import { NoteModel } from '@halo-common/models';
import { getRecentlyCreateNotes } from '@halo-data-sources/clients';
import { ApiNoteMapper } from '@halo-data-sources/mappers';
import { NoteQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export type RecentlyCreatedNotesQueryResult = {
  products: Array<NoteModel>;
};

const recentlyCreatedNoteQueryFn = async () => {
  const response = await getRecentlyCreateNotes();

  const products = response.notes.map((note) => ApiNoteMapper.toNote(note));

  return { products };
};

export const useRecentlyCreatedNoteQuery = (): UseQueryResult<RecentlyCreatedNotesQueryResult, Error> =>
  useQuery<RecentlyCreatedNotesQueryResult, Error>({
    queryKey: NoteQueryKeyFactory.recentlyCreated(),
    queryFn: () => recentlyCreatedNoteQueryFn(),
  });
