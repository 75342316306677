import { HTMLAttributes, ReactElement, useState } from 'react';

import { useDebounced } from '@halo-common/hooks';
import { UserModel } from '@halo-common/models';
import { useUserSearchQuery } from '@halo-data-sources/queries';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  Box,
  CircularProgress,
  TextField,
  TextFieldProps,
  Typography,
  inputBaseClasses,
} from '@mui/material';

const endAdornmentSx = {
  display: 'flex',
};

const loadingIndicatorSx = {
  marginRight: 1,
};

const clientInputSx = {
  [`.${inputBaseClasses.root}`]: {
    paddingLeft: 2,
  },
};

export type UserTypeAheadProps = Omit<
  AutocompleteProps<UserModel, boolean, boolean, undefined>,
  'options' | 'renderInput' | 'onChange'
> & {
  onChange?: (users: UserModel | Array<UserModel> | null) => void;
  TextFieldProps?: TextFieldProps;
};

export const UserTypeAhead = ({ TextFieldProps, onChange, ...props }: UserTypeAheadProps): ReactElement => {
  const [query, setQuery] = useState('');

  const { data: users = [], isFetching } = useUserSearchQuery(query);

  const debouncedSearchHandler = useDebounced(setQuery, 500);

  const handleSearch = (_: unknown, value: string) => {
    debouncedSearchHandler(value);
  };

  const handleChange = (_: unknown, option: UserModel | Array<UserModel> | null) => {
    onChange?.(option);
  };

  const getOptionLabel = (option: UserModel) => option.name ?? `${option.firstName} ${option.lastName}`;

  const handleRenderOption = (props: HTMLAttributes<HTMLLIElement>, option: UserModel) => (
    <Box {...props} component="li" key={option.id}>
      <Typography>{getOptionLabel(option)}</Typography>
    </Box>
  );

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const textFieldInputLoadingIndicator = isFetching ? <CircularProgress sx={loadingIndicatorSx} size={20} /> : null;

    const textFieldInputProps = {
      ...params.InputProps,
      endAdornment: (
        <Box sx={endAdornmentSx}>
          {textFieldInputLoadingIndicator}
          {params.InputProps.endAdornment}
        </Box>
      ),
    };

    return (
      <TextField
        {...params}
        label="Search Users"
        multiline={props.multiple}
        size="large"
        sx={clientInputSx}
        slotProps={{ input: textFieldInputProps }}
        {...TextFieldProps}
      />
    );
  };

  return (
    <Autocomplete<UserModel, boolean, boolean, undefined>
      {...props}
      options={users}
      onChange={handleChange}
      onInputChange={handleSearch}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel}
      renderOption={handleRenderOption}
    />
  );
};
