import { ReactElement } from 'react';

import { useOrderTicketContentPicker } from '@halo-common/hooks';
import { SingleOrderTicketFormFields } from '@halo-common/schemas';
import { AccountTypeAhead, AccountTypeAheadOption } from '@halo-common/smartComponents';
import { Collapsible, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Controller, useFormContext } from 'react-hook-form';

export const AccountSection = (): ReactElement => {
  const { showDesignation, isAdvisoryAccountSelected, isBrokerageAccountSelected } = useOrderTicketContentPicker();

  const { control, formState, watch, setValue } = useFormContext<SingleOrderTicketFormFields>();

  const { errors } = formState;
  const account = watch('account');

  const accountError = errors?.account?.message;
  const hasAccountError = Boolean(accountError);

  const accountTypeText = isAdvisoryAccountSelected ? 'Advisory' : isBrokerageAccountSelected ? 'Brokerage' : '-';

  const handleSelect = (value: AccountTypeAheadOption | null) => {
    setValue('account', value, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
  };

  const accountDesignation = showDesignation ? (
    <Stack direction="column">
      <LocalizedTypography variant="caption">Account Type</LocalizedTypography>
      <LocalizedTypography fontWeight="bold">{accountTypeText}</LocalizedTypography>
    </Stack>
  ) : null;

  return (
    <Collapsible title="Account Section" defaultExpanded>
      <Stack direction="row" alignItems="center" spacing={2} xs={12} sm={[8, 4]} wrap="wrap">
        <Controller
          render={({ field: { ref, ...options } }) => (
            <AccountTypeAhead
              {...options}
              ref={ref}
              value={account}
              onSelect={handleSelect}
              label="Account"
              rules={{ disableHouseholds: true }}
              helperText={accountError}
              error={hasAccountError}
              disableClearable
            />
          )}
          control={control}
          name="account"
        />
        {accountDesignation}
      </Stack>
    </Collapsible>
  );
};
