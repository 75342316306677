export type SlideState = 'inactive' | 'previous-1' | 'previous' | 'current' | 'next' | 'next+1';

export const getSlideState = (index: number, current: number, total: number): SlideState => {
  if (index === current) return 'current';
  const relativeIndex = (index - current + total) % total;
  if (relativeIndex === 1) return 'next';
  if (relativeIndex === 2) return 'next+1';
  if (relativeIndex === total - 1) return 'previous';
  if (relativeIndex === total - 2) return 'previous-1';
  return 'inactive';
};
