import { ReactElement } from 'react';

import { Paper, Skeleton, Stack } from '@mui/material';

const loadingContainerSx = {
  maxWidth: 450,
  width: '100%',
  padding: 3,
};

export const RegisterFormSkeleton = (): ReactElement => (
  <Paper variant="outlined" sx={loadingContainerSx}>
    <Stack direction="column" spacing={3}>
      <Stack direction="row" spacing={1}>
        <Skeleton width="100%" height={72} />
        <Skeleton width="100%" height={72} />
      </Stack>
      <Skeleton height={72} />
      <Skeleton height={72} />
      <Skeleton height={72} />
      <Skeleton height={72} />
      <Skeleton width={120} height={72} />
      <Skeleton height={72} />
      <Skeleton height={60} />
      <Stack direction="row" spacing={2} alignItems="center">
        <Skeleton width={170} height={36} />
        <Skeleton width={68} height={60} />
      </Stack>
    </Stack>
  </Paper>
);
