import { ReactElement } from 'react';

import { orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { HOUR_MINUTE_TIME_ZONE_FORMAT } from '@halo-common/constants';
import { UserRoleEnum } from '@halo-common/enums';
import { useDateFormatter } from '@halo-common/hooks';
import { AuctionHistoryModel } from '@halo-common/models';
import { useAdminAuctionQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { HaloDataGrid, HaloDataGridProps } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';
import { capitalize } from 'lodash';

const tableSx = {};

export const AuctionDetailsAdminLogs = (): ReactElement | null => {
  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);
  const formatDate = useDateFormatter({ ignoreWhiteLabel: true });
  const auctionId = selectedAuctionId?.toString();

  const { data: userInfo } = useUserInfoQuery();
  const { data: auction, isPending } = useAdminAuctionQuery(auctionId, 10000);

  if (!userInfo?.details?.roles?.includes(UserRoleEnum.Admin)) return null;

  const history = auction?.history;

  const commonCellProps = {
    editable: false,
    resizable: false,
    disableColumnMenu: true,
    disableReorder: true,
    flex: 1,
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      field: 'date',
      headerName: 'Date',
      valueGetter: (_, row: AuctionHistoryModel) => row.createdAt,
      valueFormatter: (value) => formatDate(value),
      flex: 0.5,
    },
    {
      ...commonCellProps,
      field: 'time',
      headerName: 'Time',
      valueGetter: (_, row: AuctionHistoryModel) => row.createdAt,
      valueFormatter: (value) => formatDate(value, HOUR_MINUTE_TIME_ZONE_FORMAT),
      flex: 0.5,
    },
    {
      ...commonCellProps,
      field: 'email',
      headerName: 'User',
    },
    {
      ...commonCellProps,
      field: 'adminTriggeredEvent',
      headerName: 'Role',
      valueFormatter: (value: string) => (value ? 'Admin' : 'User'),
      flex: 0.5,
    },
    {
      ...commonCellProps,
      field: 'action',
      headerName: 'Event',
      valueFormatter: (value: string) => capitalize(value),
    },
    {
      ...commonCellProps,
      field: 'value',
      headerName: 'Value',
      valueFormatter: (value: string) => value ?? '-',
    },
  ];

  return (
    <HaloDataGrid rows={history} columns={columns} loading={isPending} sx={tableSx} hideFooter density="compact" />
  );
};
