import { ReactElement } from 'react';

import { Stack } from '@halodomination/halo-fe-common';
import { CircularProgress, CircularProgressProps, SxProps, Theme, Typography } from '@mui/material';

import { EducationProgressBarBackground } from './EducationProgressBarBackground';

export type EducationProgressBarProps = Omit<CircularProgressProps, 'value' | 'size' | 'color'> & {
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary';
  backgroundThickness?: number;
  offsetBackground?: number;
  progress: number;
  hideLabel?: boolean;
  labelSx?: SxProps<Theme>;
};

export const EducationProgressBar = ({
  size = 'medium',
  color = 'primary',
  progress,
  backgroundThickness = 4,
  offsetBackground = 0,
  hideLabel = false,
  sx,
  labelSx,
  ...props
}: EducationProgressBarProps): ReactElement => {
  let circumference = 42;
  let fontSize = 10;

  if (size === 'large') {
    circumference = 68;
    fontSize = 12;
  } else if (size === 'small') {
    circumference = 16;
    fontSize = 8;
  }

  const containerStyling: SxProps<Theme> = {
    position: 'relative',
    width: circumference,
    height: circumference,
    ...(sx || {}),
  };
  const progressContainerStyling = {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-48%)',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize,
  };

  const progressBarStyling = { strokeLinecap: 'round' };

  const progressLabelStyling = {
    color: color === 'primary' ? 'primary.main' : 'common.white',
    ...labelSx,
  };

  const progressLabel = !hideLabel ? (
    <Typography variant="button" sx={progressLabelStyling}>
      {`${Math.round(progress)}%`}
    </Typography>
  ) : null;

  return (
    <Stack
      direction="column"
      alignContent="center"
      justify="center"
      sx={containerStyling}
      elementStyling={progressContainerStyling}
    >
      <EducationProgressBarBackground
        offsetBackground={offsetBackground}
        color={color}
        backgroundThickness={backgroundThickness}
        size={circumference}
      />
      <CircularProgress
        sx={progressBarStyling}
        {...props}
        color={color}
        size={circumference}
        variant="determinate"
        value={progress}
      />
      {progressLabel}
    </Stack>
  );
};
