export enum ApiOrderAuctionStatusEnum {
  Canceled = 'canceled',
  ChooseWinner = 'choose-winner',
  InAuction = 'auction',
  NotWin = 'not-win',
  OpsApproval = 'ops-approval-required',
  PreApproval = 'pre-approval-required',
  Purchased = 'purchased',
  TermsAccepted = 'term_sheet_accepted',
  TermsPending = 'waiting_for_terms',
  TermsReviewed = 'review_terms',
}

export enum ClientAuctionDateDefaults {
  StrikeDate = 1,
  TradeDate = 2,
  SettlementDate = 2,
}
