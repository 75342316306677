import { portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import { CurrencyModel } from '@halo-common/models';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { ACCOUNT_TYPE_AHEAD_SYNCED_OPTION, ACCOUNT_TYPE_AHEAD_UNSYNCED_OPTION } from '@halo-modules/app';
import { atom } from 'jotai';

export type PortfolioFiltersModel = {
  accountOption: AccountTypeAheadOption | null;
  currency: CurrencyModel;
  synced: boolean | null;
};

export type PortfolioFiltersPayload = {
  accountOption?: PortfolioFiltersModel['accountOption'];
  currency?: PortfolioFiltersModel['currency'];
};

const DEFAULT_ACCOUNT_OPTION = {
  action: translations.portfolio.common.accountTypeAheadAllOption,
  clearedOnSelection: false,
};

const DEFAULT_PORTFOLIO_FILTERS: PortfolioFiltersModel = {
  accountOption: DEFAULT_ACCOUNT_OPTION,
  currency: { id: 1, code: 'USD', symbol: '$', name: 'United States Dollar' },
  synced: null,
};

const _portfolioFiltersAtom = atom<PortfolioFiltersModel>(DEFAULT_PORTFOLIO_FILTERS);
export const portfolioFiltersAtom = atom(
  (get) => get(_portfolioFiltersAtom),
  (get, set, payload: PortfolioFiltersPayload) => {
    const { accountOption, currency } = payload;

    const filters = get(_portfolioFiltersAtom);

    if (accountOption) {
      const action = accountOption?.action;
      const synced = action === ACCOUNT_TYPE_AHEAD_SYNCED_OPTION;
      const unsynced = action === ACCOUNT_TYPE_AHEAD_UNSYNCED_OPTION;
      const syncOptionSelected = synced ? true : unsynced ? false : null;

      const combinedFilters = { ...filters, accountOption, synced: syncOptionSelected };
      set(_portfolioFiltersAtom, combinedFilters);
      set(portfolioPositionsManagerAtom);
    } else if (currency) {
      const updatedFilters = { ...filters, currency: currency ?? DEFAULT_PORTFOLIO_FILTERS.currency };
      set(_portfolioFiltersAtom, updatedFilters);
    }
  },
);
