export const MONTH_DAY_YEAR_DATE_FORMAT = 'MM/dd/yyyy';
export const YEAR_MONTH_DAY_DATE_FORMAT = 'yyyy-MM-dd';
export const YEAR_MONTH_DAY_DATE_PICKER_FORMAT = 'yyyy/MM/dd';
export const DAY_MONTH_YEAR_DATE_FORMAT = 'dd/MM/yyyy';
export const LONG_MONTH_DAY_YEAR_FORMAT = 'LLL dd, yyyy';
export const SHORT_MONTH_SHORT_DAY_YEAR_FORMAT = 'MMM d, yyyy';

export const HOUR_MINUTE_TIME_FORMAT = 'hh:mm a';
export const HOUR_MINUTE_SECONDS_TIME_FORMAT = 'HH:mm:ss';
export const HOUR_MINUTE_SECONDS_TIME_ZONE_FORMAT = 'hh:mm:ss ZZZZ a';
export const HOUR_MINUTE_SECONDS_APM_TIME_ZONE_FORMAT = 'hh:mm:ss a ZZZZ';
export const HOUR_MINUTE_TIME_ZONE_FORMAT = 'hh:mm a ZZZZ';

export const COMBINED_SIMPLE_DATE_TIME_FORMAT = `${MONTH_DAY_YEAR_DATE_FORMAT} ${HOUR_MINUTE_TIME_FORMAT}`;
export const COMBINED_DATE_TIME_FORMAT = `${YEAR_MONTH_DAY_DATE_FORMAT} ${HOUR_MINUTE_SECONDS_TIME_FORMAT}`;
export const COMBINED_DATE_TIME_ZONE_FORMAT = `${MONTH_DAY_YEAR_DATE_FORMAT} ttt`;

export const CALENDAR_DATE_TIME_EDIT_FORMAT = `${MONTH_DAY_YEAR_DATE_FORMAT} hh:mm:ss z a`;

export const FIX_DATE_TIME_FORMAT = 'yyyyMMdd-HH:mm:ss';

export const EASTERN_DAYLIGHT_TIME_ZONE = 'America/New_York';
export const EASTERN_STANDARD_TIME_ZONE = 'America/Panama';
