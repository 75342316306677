import { AllocationModel } from '@halo-common/models';
import { putCalendarOrderApproval } from '@halo-data-sources/clients';
import { ApiAllocationMapper } from '@halo-data-sources/mappers';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

export type CalendarRejectionMutationPayload = { id: number; reason: string };

const calendarRejectionMutationFn = async (payload: CalendarRejectionMutationPayload) => {
  const { id, reason } = payload;
  const result = await putCalendarOrderApproval(id, false, reason);
  return ApiAllocationMapper.toAllocationModel(result.allocation);
};

export const useCalendarRejectionMutation = (
  options?: Partial<UseMutationOptions<AllocationModel, Error, CalendarRejectionMutationPayload>>,
): UseMutationResult<AllocationModel, Error, CalendarRejectionMutationPayload> => {
  const { enqueueErrorEvent, enqueueSuccessEvent } = useSnackbar();

  return useMutation({
    mutationFn: calendarRejectionMutationFn,
    ...options,
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
      enqueueSuccessEvent({ message: 'Rejected!' });
    },
    onError: (...args) => {
      options?.onError?.(...args);
      enqueueErrorEvent({ message: "Couldn't Reject!" });
    },
  });
};
