import { translations } from '@halo-common/translations';
import { yesNoStep } from '@halo-modules/app/v2/onboarding/subPages/suitability/utils/yesNoStep';

const suitability = translations.onboarding.suitability;

export const formCompletedWithRvpStep = yesNoStep({
  id: '3-5',
  title: suitability.formCompletedWithRvp,
  subtitle: suitability.viaPhoneZoomEtc,
  field: 'trading_details.completion_with_rvp_radio',
});
