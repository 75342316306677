import { UserActivityAction, UserModel, UserRelationshipManagerModel } from '@halo-common/models';
import { atom } from 'jotai';

export type AdminUserActivityFilterModel = {
  relationshipManagers?: Array<UserRelationshipManagerModel>;
  actions?: Array<UserActivityAction>;
  users?: Array<UserModel>;
};

export const initialAdminUserActivityFilterAtom = {
  relationshipManagers: [],
  actions: [],
  users: [],
};

const _filters = atom<AdminUserActivityFilterModel>(initialAdminUserActivityFilterAtom);
export const adminUserActivityFilterAtom = atom(
  (get): AdminUserActivityFilterModel => get(_filters),
  (get, set, filters?: Partial<AdminUserActivityFilterModel>) => {
    const prevFilters = get(_filters);
    if (!filters) set(_filters, initialAdminUserActivityFilterAtom);
    else set(_filters, { ...prevFilters, ...filters });
  },
);

export type AdminUserFilterModel = {
  relationshipManagers?: Array<UserRelationshipManagerModel>;
  users?: Array<UserModel>;
  orderBy?: Array<string>;
};

export const initialAdminUserFilterAtom = {
  relationshipManagers: [],
  users: [],
  pageLength: 25,
};

const _adminUserFilters = atom<AdminUserFilterModel>(initialAdminUserFilterAtom);
export const adminUserFilterAtom = atom(
  (get): AdminUserFilterModel => get(_adminUserFilters),
  (get, set, filters?: Partial<AdminUserFilterModel>) => {
    const previousFilters = get(_adminUserFilters);
    if (!filters) set(_adminUserFilters, initialAdminUserFilterAtom);
    else set(_adminUserFilters, { ...previousFilters, ...filters });
  },
);
