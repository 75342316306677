import { ReactElement } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { modalAtom } from '@halo-atoms/common';
import { CALENDAR_ALLOCATION_MODAL_NAME, ExecutionCalendarDetailModal } from '@halo-common/modals';
import { CalendarModel } from '@halo-common/models';
import { useCalendarQuery } from '@halo-data-sources/queries';
import { useAtom } from 'jotai';

export const CALENDAR_DETAILS_MODAL_NAME = 'calendarDetailsModal';

export const CalendarDetailsModal = (): ReactElement => {
  const [modalMap, setModalMap] = useAtom(modalAtom);
  const [selectedCalendar, setSelectedCalendar] = useAtom(calendarSelectedAtom);

  const { data } = useCalendarQuery(selectedCalendar?.id);

  const handleClose = () => {
    setModalMap({ [CALENDAR_DETAILS_MODAL_NAME]: false });
    setSelectedCalendar(null);
  };

  const handleSubmitIndication = (product: CalendarModel) => {
    setModalMap({ [CALENDAR_ALLOCATION_MODAL_NAME]: true });
    setSelectedCalendar(product);
  };

  return (
    <ExecutionCalendarDetailModal
      open={modalMap[CALENDAR_DETAILS_MODAL_NAME]}
      onClose={handleClose}
      product={data}
      onSubmitIndication={handleSubmitIndication}
    />
  );
};
