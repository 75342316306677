import { ReactElement, ReactNode } from 'react';

import { ProductTypeChip } from '@halo-common/components';
import { ProductTypeEnum } from '@halo-common/enums';
import { NoteModel } from '@halo-common/models';
import { Stack } from '@halodomination/halo-fe-common';
import { Typography } from '@mui/material';

const currencySx = { textTransform: 'uppercase' };

export type ProductCardHeaderProps = {
  action: ReactNode;
  note?: NoteModel | null;
  type: ProductTypeEnum;
};

export const ProductCardHeader = ({ action, note, type }: ProductCardHeaderProps): ReactElement => {
  const headerJustification = action ? 'space-between' : 'flex-end';

  const isAnnuity = type === ProductTypeEnum.annuity;

  if (isAnnuity) {
    return (
      <Stack direction="row" justify={headerJustification} alignItems="center">
        {action}
        <Stack direction="row" spacing={1} alignItems="center">
          <ProductTypeChip type={type} />;
        </Stack>
      </Stack>
    );
  }

  const currencyCode = note?.currencyCode;

  return (
    <Stack direction="row" justify={headerJustification} alignItems="center">
      {action}
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography sx={currencySx} color="textSecondary" variant="caption">
          {currencyCode}
        </Typography>
        <ProductTypeChip product={note} type={ProductTypeEnum.note} />
      </Stack>
    </Stack>
  );
};
