import { CalendarQueryModel } from '@halo-atoms/calendars';
import { DEFAULT_INFINITE_QUERY_NEXT_PAGINATION } from '@halo-common/constants';
import { getCalendarUnderlyingSearch } from '@halo-data-sources/clients';
import { ApiUnderlyingMapper, CalendarsMapper, PaginationMapper } from '@halo-data-sources/mappers';
import { GetUnderlyingInfiniteQueryDataSwitchResult } from '@halo-data-sources/queries';

export const useCalendarUnderlyingInfiniteQueryFn = async (
  search: string,
  calendarQuery: CalendarQueryModel,
  pageParam = DEFAULT_INFINITE_QUERY_NEXT_PAGINATION,
  approved?: boolean,
): Promise<GetUnderlyingInfiniteQueryDataSwitchResult> => {
  const mappedQuery = { ...calendarQuery, approved };
  const mappedFilters = CalendarsMapper.toCalendarUnderlyingQueryRequest(search, mappedQuery, pageParam);

  const response = await getCalendarUnderlyingSearch(mappedFilters);

  const mappedUnderlyings = response.tradables.map(ApiUnderlyingMapper.toUnderlyingModel);
  const mappedPagination = PaginationMapper.toInfinitePaginationModel(response.pagination);

  return { underlyings: mappedUnderlyings, pagination: mappedPagination };
};
