import { ReactElement } from 'react';

import { portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import {
  usePortfolioPositionAggregationQuery,
  usePortfolioPositionAggregationTemplatesQuery,
  usePortfolioPositionsQuery,
  useUserInfoQuery,
} from '@halo-data-sources/queries';
import { CompositionEnum, usePortfolioPositionsDetailData, usePositionsAggregateChartData } from '@halo-modules/app';
import { HeroBarChart, HeroBarModel } from '@halodomination/halo-fe-common';
import { useAtom } from 'jotai';

export const PortfolioPositionsAggregateChart = (): ReactElement => {
  const [positionsData, setPositionsData] = useAtom(portfolioPositionsManagerAtom);

  const { detailsQuery, query, filters, showAggregate } = positionsData;
  const { selectedTabs, composition } = filters;

  const { data: userInfo } = useUserInfoQuery();

  const positionsQueryEnabled = Boolean(CompositionEnum.Product in detailsQuery.filters && userInfo);
  const positionsQueryOptions = { enabled: positionsQueryEnabled };

  const { data: templateMeta, isPending: templateLoading } = usePortfolioPositionAggregationTemplatesQuery();
  const { data: aggregationMeta, isPending: aggregationLoading } = usePortfolioPositionAggregationQuery(query);
  const { isPending: positionLoading } = usePortfolioPositionsQuery(detailsQuery, positionsQueryOptions);
  const { features: featuresMeta } = usePortfolioPositionsDetailData();

  const templates = templateMeta ?? [];
  const aggregations = aggregationMeta?.aggregations ?? [];
  const features = featuresMeta ?? [];

  const selectedTabName = !showAggregate ? selectedTabs[selectedTabs.length - 1].name : undefined;
  const selectedFeature = features.find((feature) => feature.name === selectedTabName);
  const selectedBarIndex = !showAggregate ? 0 : undefined;

  const isFilteredByTemplateFullName = CompositionEnum.Product in filters.templateFilters;
  const isLoadingPositions = positionsQueryEnabled && positionLoading;
  const isLoading = templateLoading || aggregationLoading || isLoadingPositions;

  const chartData = usePositionsAggregateChartData(aggregations, selectedFeature);

  const handleSelectionChange = (bar: HeroBarModel) => {
    const deselect = selectedTabs.some((tab) => tab.name === bar.label);

    if (selectedFeature && isFilteredByTemplateFullName) {
      const tab = { count: selectedFeature.count, name: selectedFeature.name, value: selectedFeature.value };
      setPositionsData({ selectedFeatureTabs: { tab, deselect } });
    } else {
      const aggregation = aggregations.find((aggregation) => aggregation.name === bar.label);

      if (aggregation) {
        const tab = { count: aggregation.positionCount, name: aggregation.name, value: aggregation.filterKey };
        const selectedTemplate = templates.find((template) => template.name === composition);
        setPositionsData({ selectedNoteTab: { tab, template: selectedTemplate, deselect } });
      }
    }
  };

  return (
    <HeroBarChart
      hideEmptyMessage
      onClick={handleSelectionChange}
      data={chartData}
      loading={isLoading}
      selected={selectedBarIndex}
    />
  );
};
