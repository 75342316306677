import { UserWebHooksModel } from '@halo-common/models';
import { fetchUserWebHooks } from '@halo-data-sources/clients';
import { ApiUserWebHooksMapper } from '@halo-data-sources/mappers';
import { UserQueryKeyFactory, useUserInfoQuery } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const fetchUserWebHooksQueryFn = async () => {
  const result = await fetchUserWebHooks();

  return ApiUserWebHooksMapper.toUserWebHooks(result.webhooks);
};

export const useUserWebHooksQuery = (): UseQueryResult<UserWebHooksModel, Error> => {
  const { data: user } = useUserInfoQuery();

  const userId = user?.details?.id;

  return useQuery<UserWebHooksModel, Error>({
    queryKey: UserQueryKeyFactory.webHooks(userId),
    queryFn: () => fetchUserWebHooksQueryFn(),
    enabled: typeof userId === 'number',
  });
};
