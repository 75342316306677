import { complianceSection } from './complianceSection';
import { finalizeSection } from './finalizeSection';
import { firmDetailsSection } from './firmDetailsSection';
import { tradingDetailsSection } from './tradingDetailsSection';
import type { SuitabilitySectionModel } from '../types';

export * from './complianceSection';
export * from './fallbackStep';
export * from './finalizeSection';
export * from './firmDetailsSection';
export * from './intro';
export * from './tradingDetailsSection';

export const suitabilitySections: SuitabilitySectionModel[] = [
  firmDetailsSection,
  complianceSection,
  tradingDetailsSection,
  finalizeSection,
];
