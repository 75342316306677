import { QueryKeyFactoryFn } from '@halo-data-sources/queries';
import { QueryKey } from '@tanstack/react-query';

type NoteflixQueryKeys = {
  all: QueryKeyFactoryFn;
  categories: QueryKeyFactoryFn;
  category_notes: QueryKeyFactoryFn;
};

export const NoteflixQueryKeyFactory: NoteflixQueryKeys = {
  all: (): QueryKey => ['noteflix'],
  categories: (): QueryKey => [...NoteflixQueryKeyFactory.all(), 'categories'],
  category_notes: (id?: number): QueryKey => [...NoteflixQueryKeyFactory.all(), id, 'notes'],
};
