import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';

import { translations } from '@halo-common/translations';
import { LocalizedButton, LocalizedTextField, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import {
  Box,
  InputAdornment,
  PopoverProps as MuiPopoverProps,
  SliderProps as MuiSliderProps,
  Popover,
  Slider,
} from '@mui/material';

const containerSx = {
  width: '100%',
};

const popoverContentSx = {
  p: 2,
  pb: 1,
};

const buttonSx = {
  textTransform: 'uppercase',
};

export type PopoverRangeFilterPropModel = {
  title?: string;
  suffix?: string;
  maxInputValue: number | '';
  minInputValue: number | '';
  PopoverProps: MuiPopoverProps;
  SliderProps: MuiSliderProps;
  buttonText: string;
  handleDoneClick: () => void;
  handleMaxInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleMinInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handlePopoverOpen: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const PopoverRangeFilter = ({
  title,
  suffix = '',
  maxInputValue,
  minInputValue,
  PopoverProps,
  SliderProps,
  buttonText,
  handleMinInputChange,
  handleMaxInputChange,
  handlePopoverOpen,
  handleDoneClick,
}: PopoverRangeFilterPropModel): ReactElement => {
  const [error, setError] = useState<string>('');

  const hasError = Boolean(error);

  const htmlInputProps = { min: SliderProps?.min, max: SliderProps?.max };
  const muiInputLabelProps = { shrink: true };
  const muiInputProps = { endAdornment: <InputAdornment position="end">{suffix}</InputAdornment> };
  const titleText = title ? <LocalizedTypography variant="overline">{title}</LocalizedTypography> : null;

  const handlePopoverClick = (event: MouseEvent<HTMLButtonElement>) => {
    handlePopoverOpen(event);
  };

  const errorMessage = hasError ? (
    <LocalizedTypography variant="caption" color="error">
      {error}
    </LocalizedTypography>
  ) : null;

  useEffect(() => {
    const validMin = typeof minInputValue === 'number';
    const unsetMin = minInputValue === SliderProps.min;
    const validMax = typeof maxInputValue === 'number';
    const unsetMax = maxInputValue === SliderProps.max;
    const validBounds = !unsetMin && !unsetMax && validMin && validMax;

    const minGreaterThanMax = validBounds && minInputValue > maxInputValue;
    if (minGreaterThanMax) setError('Min value must be less than or equal to max.');

    const minGreaterThanUpperBound = !unsetMin && validMin && SliderProps?.max && minInputValue > SliderProps.max;
    const maxGreaterThanUpperBound = !unsetMax && validMax && SliderProps?.max && maxInputValue > SliderProps.max;
    const upperBoundViolation = minGreaterThanUpperBound || maxGreaterThanUpperBound;

    const minLessThanLowerBound = !unsetMin && validMin && SliderProps?.min && minInputValue < SliderProps.min;
    const maxLessThanLowerBound = !unsetMax && validMax && SliderProps?.min && maxInputValue < SliderProps.min;
    const lowerBoundViolation = minLessThanLowerBound || maxLessThanLowerBound;

    const boundaryViolation = lowerBoundViolation || upperBoundViolation;
    if (boundaryViolation) setError('Min/Max must be within the bounds of the slider.');

    const formValid = !minGreaterThanMax && !boundaryViolation;
    if (formValid) setError('');
  }, [minInputValue, maxInputValue, SliderProps.max]);

  return (
    <Box sx={containerSx}>
      {titleText}
      <LocalizedButton fullWidth aria-describedby={PopoverProps.id} variant="outlined" onClick={handlePopoverClick}>
        {buttonText}
      </LocalizedButton>
      <Popover
        {...PopoverProps}
        hideBackdrop
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            style: { width: 400, height: hasError ? 232 : 200 },
          },
        }}
      >
        <Stack sx={popoverContentSx} direction="column" spacing={1}>
          <LocalizedTypography variant="overline">{translations.components.setRange}</LocalizedTypography>
          <Slider {...SliderProps} disableSwap valueLabelDisplay="off" />
          <Stack direction="row" spacing={0.5} xs={6}>
            <LocalizedTextField
              fullWidth
              onChange={handleMinInputChange}
              id="min-input"
              type="number"
              placeholder={translations.common.any}
              value={minInputValue}
              slotProps={{ input: muiInputProps, inputLabel: muiInputLabelProps, htmlInput: htmlInputProps }}
              label={translations.common.min}
            />
            <LocalizedTextField
              fullWidth
              onChange={handleMaxInputChange}
              id="max-input"
              type="number"
              placeholder={translations.common.any}
              value={maxInputValue}
              slotProps={{ input: muiInputProps, inputLabel: muiInputLabelProps, htmlInput: htmlInputProps }}
              label={translations.common.max}
            />
          </Stack>
          {errorMessage}
          <LocalizedButton sx={buttonSx} fullWidth onClick={handleDoneClick} variant="text" disabled={hasError}>
            {translations.common.done}
          </LocalizedButton>
        </Stack>
      </Popover>
    </Box>
  );
};
