export enum AnnuityProductTypeEnum {
  Annuity = 'annuity',
  FIA = 'FIA',
  RILA = 'RILA',
  VA = 'VA',
}

export enum AnnuityProtectionTypeEnum {
  Floor = 'floor',
  Buffer = 'buffer',
  FullPrincipalProtection = 'full principal protection',
}
