import { useEffect, useState } from 'react';

import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LoginForm, OnboardingLayout } from '@halo-modules/app';
import { OnboardingActions, OnboardingSelectors } from '@halo-stores/Onboarding';
import { PageComponent, PageMeta } from '@halodomination/halo-fe-common';
import { useDispatch, useSelector } from 'react-redux';

const LoginPage: PageComponent = () => {
  const dispatch = useDispatch();

  const [hasError, setHasError] = useState(false);

  const passwordSet = useSelector(OnboardingSelectors.selectPasswordSet);

  const { data: userInfo } = useUserInfoQuery();

  const userFacingName = userInfo?.whiteLabel?.userFacingName;
  const listVariant = hasError ? 'forgot' : 'default';
  const welcomeMessage = passwordSet
    ? `You're all set! Log into your new ${userFacingName} account to get started.`
    : `Welcome back to ${userFacingName}!`;

  const handleError = (val: boolean) => void setHasError(val);

  useEffect(() => {
    dispatch(OnboardingActions.resetStatus());
  }, []);

  return (
    <OnboardingLayout pageName={LoginPageMeta.pageName} welcomeMessage={welcomeMessage} listVariant={listVariant}>
      <LoginForm onError={handleError} />
    </OnboardingLayout>
  );
};

export const LoginPageMeta: PageMeta = {
  pageName: 'Login',
  route: '/app/onboarding/login',
  iconName: 'sign-in',
};

LoginPage.pageName = LoginPageMeta.pageName;
LoginPage.route = LoginPageMeta.route;
LoginPage.iconName = LoginPageMeta.iconName;

export default LoginPage;
