import { UnderlyingBasketEnum } from '@halo-common/enums';
import { UnderlyingPerformanceTableDataModel } from '@halo-common/models';
import { getRollingReturnsUnderlyingData, getRollingReturnsUnderlyingStatisticsData } from '@halo-data-sources/clients';
import { RollingReturnsQueryKeyFactory } from '@halo-data-sources/queries';
import { LineChartProps, LineChartReferenceLine } from '@halodomination/halo-fe-common';
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

const EFFECTIVE_UNDERLIER_API_NAME = 'effective-underlier';
const BREACH_LEVEL_REF_NAME = 'Breach Level';
const COUPON_PROTECTION_REF_NAME = 'Coupon Protection';

export type RollingReturnsUnderlyingQueryPayload = {
  endDate: DateTime | null;
  startDate: DateTime | null;
  protection?: number | null;
  couponProtection?: number | null;
  basket?: Record<string, number> | null;
};

export type RollingReturnsUnderlyingQueryChartPoint = {
  x: number;
  [key: string]: number | null;
};

export type RollingReturnsUnderlyingQueryChartResult = {
  data: Array<RollingReturnsUnderlyingQueryChartPoint>;
  lines: LineChartProps['lines'];
  references?: LineChartProps['referenceLines'];
};

export type RollingReturnsUnderlyingStatisticsQueryTableResult = {
  statistics: Array<UnderlyingPerformanceTableDataModel>;
};

export type RollingReturnsUnderlyingQueryResult = {
  chart: RollingReturnsUnderlyingQueryChartResult;
  table: RollingReturnsUnderlyingStatisticsQueryTableResult;
};

const getRollingReturnsUnderlyingQueryFn = async (id?: number, filters?: RollingReturnsUnderlyingQueryPayload) => {
  if (!id || !filters?.startDate || !filters?.endDate) return null;

  const { endDate, startDate, basket, protection, couponProtection } = filters;

  const chartResponse = await getRollingReturnsUnderlyingData({
    date_range: {
      start_date: filters.startDate.toISODate() as string,
      end_date: filters.endDate.toISODate() as string,
    },
    note_id: id,
  });

  const { effective_underlier, underliers = [] } = chartResponse;

  const showBreachLevel = typeof protection === 'number' && protection < 100;
  const showCouponProtection = typeof couponProtection === 'number';
  const isSingleBasket = Boolean(underliers.length && underliers.length === 1);
  const isWeightedBasket = Boolean(basket);

  const basketType = isSingleBasket
    ? UnderlyingBasketEnum.SINGLE
    : isWeightedBasket
      ? UnderlyingBasketEnum.WEIGHTED
      : UnderlyingBasketEnum.WORST_OF;

  const isWorstOf = basketType === UnderlyingBasketEnum.WORST_OF;
  const isWeighted = basketType === UnderlyingBasketEnum.WEIGHTED;

  let basketName = 'Single Underlying Basket';
  if (isWorstOf) basketName = 'Worst-of Basket';
  else if (isWeighted) basketName = 'Weighted Basket';

  const sortUnderliers = (a: { tradable_name: string }, b: { tradable_name: string }) => {
    if (!isWeighted || !filters?.basket) return 0;
    const aWeight = filters.basket[a.tradable_name];
    const bWeight = filters.basket[b.tradable_name];
    return aWeight < bWeight ? 1 : aWeight > bWeight ? -1 : 0;
  };

  const reversedUnderliers = underliers.reverse();
  const lineDefinitions: LineChartProps['lines'] = reversedUnderliers
    .sort(sortUnderliers)
    .map(({ tradable_name: label }) => ({ label }));
  const lines: LineChartProps['lines'] = isWeighted ? [{ label: basketName }, ...lineDefinitions] : lineDefinitions;

  const data = effective_underlier.dates.map((date, index) => {
    const dateInSeconds = DateTime.fromISO(date).toSeconds();

    const underlyingMap = reversedUnderliers.reduce(
      (map, underlying) => ({ ...map, [underlying.tradable_name]: underlying.values[index] * 100 }),
      {},
    );

    const point: RollingReturnsUnderlyingQueryChartPoint = { x: dateInSeconds, ...underlyingMap };

    if (isWeighted) point[basketName] = effective_underlier.values[index] * 100;

    return point;
  });

  const tableResponse = await getRollingReturnsUnderlyingStatisticsData({
    date_range: {
      end_date: endDate?.toISODate() ?? DateTime.now().toISODate(),
      start_date: startDate?.toISODate() ?? DateTime.now().minus({ years: 1 }).toISODate(),
    },
    note_id: id,
  });

  const reversedStatistics = tableResponse.statistics.reverse();
  const statistics = reversedStatistics
    .sort(sortUnderliers)
    .reduce((stats: Array<UnderlyingPerformanceTableDataModel>, details, index) => {
      const isEffectiveUnderlier = details.tradable_name === EFFECTIVE_UNDERLIER_API_NAME;
      const hideEffectiveUnderlierRow = !isWeighted && isEffectiveUnderlier;

      if (hideEffectiveUnderlierRow) return stats;

      const isWorst = isWorstOf && reversedStatistics[0].pct_change_at_maturity === details.pct_change_at_maturity;
      const hasBasketId = isWorst || isEffectiveUnderlier;

      const underlyingName = isEffectiveUnderlier ? basketName : details.tradable_name;
      const showWeightsInName = !isEffectiveUnderlier && basket;
      const formattedName = showWeightsInName
        ? `${basket[underlyingName]?.toFixed(2)}% ${underlyingName}`
        : underlyingName;

      const mappedDetails = {
        colorId: !isWeighted ? index - 1 : index,
        id: hasBasketId ? basketType : details.tradable_name,
        name: formattedName,
        initialLevel: details.initial_level,
        level: details.level_at_maturity,
        percentageChange: details.pct_change_at_maturity * 100,
        breachLevel: details.breach_level,
        distanceToBarrier: details.dst_barrier_at_maturity * 100,
      };

      return [...stats, mappedDetails];
    }, []);

  const references: Array<LineChartReferenceLine> | undefined = [];
  if (showBreachLevel) references.push({ point: -protection, label: BREACH_LEVEL_REF_NAME, orientation: 'y' });
  if (showCouponProtection) {
    references.push({ point: -couponProtection, label: COUPON_PROTECTION_REF_NAME, type: 'dot', orientation: 'y' });
  }

  return { chart: { data, lines, references }, table: { statistics } };
};

export const useRollingReturnsUnderlyingQuery = (
  id?: number,
  filters?: RollingReturnsUnderlyingQueryPayload,
  options?: Partial<UseQueryOptions<RollingReturnsUnderlyingQueryResult | null, Error>>,
): UseQueryResult<RollingReturnsUnderlyingQueryResult | null, Error> => {
  const startDate = filters?.startDate?.toISODate() ?? null;
  const endDate = filters?.endDate?.toISODate() ?? null;

  const keyFilters = { ...filters, startDate, endDate };

  return useQuery<RollingReturnsUnderlyingQueryResult | null, Error>({
    queryKey: RollingReturnsQueryKeyFactory.underlying(id, keyFilters),
    queryFn: () => getRollingReturnsUnderlyingQueryFn(id, filters),
    retry: false,
    ...options,
  });
};
