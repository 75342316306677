import { WatchlistTargetQuoteDataModel } from '@halo-common/models';
import { getWatchlistTargetedNotes } from '@halo-data-sources/clients';
import { ApiWatchlistMapper } from '@halo-data-sources/mappers';
import { WatchlistQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getWatchlistTargetedNoteQueryFn = async (id?: number) => {
  if (!id) return null;

  const response = await getWatchlistTargetedNotes();

  const targets = response.targets.map((target) => ApiWatchlistMapper.toTargetedQuoteModel(target));
  const target = targets.find((target) => target?.note?.id === id) ?? null;

  return { target, targets };
};

export const useWatchlistTargetedNoteQuery = (
  id?: number,
): UseQueryResult<WatchlistTargetQuoteDataModel | null, Error> =>
  useQuery<WatchlistTargetQuoteDataModel | null, Error>({
    queryKey: WatchlistQueryKeyFactory.target(id),
    queryFn: () => getWatchlistTargetedNoteQueryFn(id),
  });
