import { ReactElement } from 'react';

import { myOpenCalendarsSearchFieldsAtom } from '@halo-atoms/dashboard';
import { ProductTypeChip } from '@halo-common/components';
import { COMBINED_DATE_TIME_ZONE_FORMAT } from '@halo-common/constants';
import { ProductTypeEnum } from '@halo-common/enums';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { GridPaginationModel, NoteModel, OrderBookCalendarModel } from '@halo-common/models';
import { useOrderBookCalendarsQuery } from '@halo-data-sources/queries';
import {
  OrderBookCalendarsAllocationButton,
  OrderBookCalendarsStatus,
  orderBookWidgetDataGridProps,
} from '@halo-modules/app';
import { HaloDataGrid, HaloDataGridProps, Stack, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { useAtom } from 'jotai';

export type DashboardOrderBookCalendarsTableProps = {
  loading?: boolean;
};

export const DashboardOrderBookCalendarsTable = ({
  loading = false,
}: DashboardOrderBookCalendarsTableProps): ReactElement => {
  const configureDateTime = useWhiteLabelDateConfiguration();

  const [searchFields, setSearchFields] = useAtom(myOpenCalendarsSearchFieldsAtom);

  const { data, ...query } = useOrderBookCalendarsQuery(searchFields);

  const isLoading = loading || query.isPending || query.isPlaceholderData;

  const rows = data?.calendars ?? [];
  const page = data?.pagination?.page ?? searchFields.page;
  const pageSize = data?.pagination?.resultsPerPage ?? searchFields.pageSize;
  const totalResults = data?.pagination?.totalResults ?? 0;
  const paginationModel = { page: page - 1, pageSize };

  const handlePaginationChange = (model: GridPaginationModel) => {
    setSearchFields((previous) => ({
      ...previous,
      page: model.page + 1,
      pageSize: model.pageSize,
    }));
  };

  const handleSortChange = (model: GridSortModel) => {
    setSearchFields((previous) => ({
      ...previous,
      sortBy: model,
    }));
  };

  const formatCurrency = (value: number) => useCurrencyConverter(value);

  const commonCellProps = {
    editable: false,
    disableColumnMenu: true,
    disableReorder: true,
    flex: 1,
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      field: 'noteType',
      headerName: 'Note Type',
      renderCell: ({ value }) => {
        const note = { type: value, productType: value } as NoteModel;
        return <ProductTypeChip product={note} type={ProductTypeEnum.note} />;
      },
    },
    {
      ...commonCellProps,
      field: 'underlying',
      headerName: 'Underlying',
      sortable: false,
      valueFormatter: (value: Array<string>) => value?.join(', '),
    },
    {
      ...commonCellProps,
      field: 'payoff',
      headerName: 'Payoff Value',
      flex: 1.8,
      sortable: false,
      valueGetter: (value: OrderBookCalendarModel['payoff']) => value?.payoffString,
    },
    {
      ...commonCellProps,
      field: 'notional',
      headerName: 'Notional',
      valueFormatter: (value) => formatCurrency(value),
    },
    {
      ...commonCellProps,
      field: 'closes',
      headerName: 'Closes',
      valueFormatter: (value: OrderBookCalendarModel['closes']) => {
        const closeDateTime = configureDateTime(value);
        return closeDateTime?.toFormat(COMBINED_DATE_TIME_ZONE_FORMAT);
      },
      flex: 1.8,
    },
    {
      ...commonCellProps,
      field: 'status',
      headerName: 'Status',
      flex: 1.6,
      sortable: false,
      renderCell: ({ value }) => <OrderBookCalendarsStatus value={value} />,
    },
    {
      ...commonCellProps,
      field: 'action',
      headerName: '',
      resizable: false,
      sortable: false,
      width: 104,
      minWidth: 104,
      maxWidth: 104,
      renderCell: ({ row }) => (
        <Stack direction="row" spacing={1}>
          <OrderBookCalendarsAllocationButton
            calendar={row}
            size="small"
            className="wm-dashboard-openorders-viewdetails"
          />
        </Stack>
      ),
    },
  ];

  return (
    <HaloDataGrid
      {...orderBookWidgetDataGridProps}
      columns={columns}
      rows={rows}
      loading={isLoading}
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationChange}
      onSortModelChange={handleSortChange}
      rowCount={totalResults}
    />
  );
};
