import { UserAlertModel } from '@halo-common/models';
import { postUserAlerts } from '@halo-data-sources/clients';
import { ApiUserAlertsMapper } from '@halo-data-sources/mappers';
import { ApiUserAlertPostRequestModel } from '@halo-data-sources/models';
import { UserQueryKeyFactory } from '@halo-data-sources/queries';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';

export type CreateUserAlertMutationPayload = {
  alertTypeId: string;
  emailEnabled?: boolean;
  notificationEnabled?: boolean;
};

const createUserAlertMutationFn = async (payload: CreateUserAlertMutationPayload) => {
  if (!payload.alertTypeId) return null;

  const options: ApiUserAlertPostRequestModel = {
    alert_type_id: payload.alertTypeId,
    email: payload?.emailEnabled,
    notification: payload?.notificationEnabled,
  };

  const response = await postUserAlerts(options);

  return ApiUserAlertsMapper.toUserAlert(response);
};

export const useCreateUserAlertMutation = (): UseMutationResult<
  UserAlertModel | null,
  Error,
  CreateUserAlertMutationPayload
> => {
  const queryClient = useQueryClient();

  const temporaryAlertId = uuid();

  return useMutation<UserAlertModel | null, Error, CreateUserAlertMutationPayload>({
    mutationFn: createUserAlertMutationFn,
    onMutate: (payload) => {
      if (!payload.alertTypeId) return;

      const key = UserQueryKeyFactory.alerts();

      queryClient.setQueryData<Array<UserAlertModel>>(key, (prev) => {
        if (!prev) return undefined;

        const { emailEnabled, notificationEnabled } = payload;

        const alerts = [...prev];
        const index = alerts.findIndex((alert) => alert.alertType.id === payload.alertTypeId);

        const oldEmailEnabled = alerts[index].emailEnabled;
        const updatedEmailEnabled = emailEnabled !== undefined ? emailEnabled : oldEmailEnabled;

        const oldNotificationEnabled = alerts[index].notificationEnabled;
        const updatedNotificationEnabled =
          notificationEnabled !== undefined ? notificationEnabled : oldNotificationEnabled;

        const temporaryAlert = {
          ...alerts[index],
          id: temporaryAlertId,
          emailEnabled: updatedEmailEnabled,
          notificationEnabled: updatedNotificationEnabled,
        };

        alerts.splice(index, 1, temporaryAlert);

        return alerts;
      });
    },
    onError: (_, payload) => {
      const key = UserQueryKeyFactory.alerts();

      queryClient.setQueryData<Array<UserAlertModel>>(key, (prev) => {
        if (!prev) return undefined;

        const alerts = [...prev];
        const index = alerts.findIndex((alert) => alert.alertType.id === payload.alertTypeId);

        alerts.splice(index, 1);

        return alerts;
      });
    },
    onSuccess: (data, payload) => {
      if (!data) return;

      const key = UserQueryKeyFactory.alerts();

      queryClient.setQueryData<Array<UserAlertModel>>(key, (prev) => {
        if (!prev) return undefined;

        const alerts = [...prev];
        const index = alerts.findIndex((alert) => alert.alertType.id === payload.alertTypeId);

        alerts.splice(index, 1, data);

        return alerts;
      });
    },
  });
};
