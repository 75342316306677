import { ReactElement } from 'react';

import { UserProfileNotificationsFilters, UserProfileNotificationsList } from '@halo-modules/app';
import { Stack } from '@mui/material';

export const UserProfileNotificationsTab = (): ReactElement => (
  <Stack sx={{ width: '100%' }}>
    <UserProfileNotificationsFilters />
    <UserProfileNotificationsList />
  </Stack>
);
