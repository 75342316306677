import { ReactElement, ReactNode } from 'react';

import { Alert } from '@mui/material';

const statusStyling = {
  backgroundColor: 'error.background',
};

export type TackOnAlertProps = {
  children: ReactNode;
};

export const TackOnAlert = ({ children }: TackOnAlertProps): ReactElement => {
  return (
    <Alert severity="error" variant="outlined" sx={statusStyling}>
      {children}
    </Alert>
  );
};
