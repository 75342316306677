import { ReactElement } from 'react';

import { InstitutionalLineChart } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { PostTradeProductDetailsDataSwitchResult } from '@halo-data-sources/switches';

export type V2PostTradeNoteDetailModalNoteValueChartProps = {
  noteAnalytics?: PostTradeProductDetailsDataSwitchResult['noteAnalytics'];
  loading: boolean;
};

export const V2PostTradeNoteDetailModalNoteValueChart = ({
  noteAnalytics,
  loading,
}: V2PostTradeNoteDetailModalNoteValueChartProps): ReactElement => {
  const data = noteAnalytics?.chart.data ?? [];
  const lines = noteAnalytics?.chart.lines ?? [];

  const handleTooltipValueFormat = (value: number) => {
    return `${value.toFixed(2)}%`;
  };

  return (
    <InstitutionalLineChart
      lines={lines}
      data={data}
      dataType="date"
      yLabel={translations.pdm.postTrade.noteValueChartYAxis}
      xLabel={translations.common.date}
      loading={loading}
      TooltipProps={{ formatter: handleTooltipValueFormat }}
    />
  );
};
