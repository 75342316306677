import { translations } from '@halo-common/translations';
import { sendForSignatures } from '@halo-data-sources/clients';
import type { DocusignSendForSignaturesPayloadModel } from '@halo-data-sources/models';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

export type useSendForSignaturesMutationOptions = UseMutationOptions<
  DocusignSendForSignaturesPayloadModel,
  Error,
  DocusignSendForSignaturesPayloadModel
>;

export type useSendForSignaturesMutationResult = UseMutationResult<
  DocusignSendForSignaturesPayloadModel,
  Error,
  DocusignSendForSignaturesPayloadModel
>;

export const useSendForSignaturesMutation = (
  options?: useSendForSignaturesMutationOptions,
): useSendForSignaturesMutationResult => {
  const { enqueueErrorEvent } = useSnackbar();
  return useMutation({
    mutationFn: sendForSignatures,
    ...options,
    onError: (...props) => {
      const error = props[0];
      options?.onError?.(...props);
      const message = error?.message || translations.common.error;
      enqueueErrorEvent({ message });
    },
  });
};
