import { ReactElement } from 'react';

import { Stack, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Typography } from '@mui/material';

const headerSx = { color: 'text.secondary' };
const contentSx = { color: 'common.black' };

export type UnderlyingGlyphAnnuityTooltipProps = {
  ticker?: string | null;
  description?: string | null;
  weight?: number | null;
  units?: number | null;
  value?: number | null;
};

export const UnderlyingGlyphAnnuityTooltip = ({
  ticker,
  description,
  weight,
  units,
  value,
}: UnderlyingGlyphAnnuityTooltipProps): ReactElement => {
  const formattedTicker = ticker ?? '-';
  const formattedDescription = description ?? '-';
  const formattedWeight = weight ? `${weight.toFixed(2)}%` : '-';
  const formattedUnits = units?.toLocaleString() ?? '-';
  const formattedValue = useCurrencyConverter(value) ?? '-';

  return (
    <Stack direction="column" spacing={2} sx={contentSx}>
      <Stack direction="column">
        <Typography sx={headerSx} variant="overline">
          {formattedTicker}
        </Typography>
        <Typography variant="h5">{formattedDescription}</Typography>
      </Stack>
      <Stack direction="row" justify="space-between">
        <Stack direction="column">
          <Typography sx={headerSx} variant="overline">
            FUND PERCENTAGE
          </Typography>
          <Typography variant="subtitle1">{formattedWeight}</Typography>
        </Stack>
        <Stack direction="column">
          <Typography sx={headerSx} variant="overline">
            FUND UNITS
          </Typography>
          <Typography variant="subtitle1">{formattedUnits}</Typography>
        </Stack>
        <Stack direction="column">
          <Typography sx={headerSx} variant="overline">
            FUND VALUE
          </Typography>
          <Typography variant="subtitle1">{formattedValue}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
