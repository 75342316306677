import { ReactElement } from 'react';

import { auctionStepAtoms } from '@halo-atoms/auctions';
import { Stepper, StepConnector, stepConnectorClasses } from '@mui/material';
import { useAtomValue } from 'jotai';

import { AuctionCreatedAtStep } from './AuctionCreatedAtStep';
import { AuctionIssuersStep } from './AuctionIssuersStep';
import { AuctionNotionalStep } from './AuctionNotionalStep';

const stepperSx = {
  width: '50%',
};

const connectorSx = {
  pt: 1,
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      border: '1px solid',
      borderColor: 'common.white',
      opacity: '50%',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      border: '1px solid',
      borderColor: 'common.white',
      opacity: '50%',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 2,
    borderRadius: 1,
  },
};

export const AuctionStepper = (): ReactElement => {
  const step = useAtomValue(auctionStepAtoms.currentPageAtom);

  const steps = [
    {
      id: 'notional',
      Component: AuctionNotionalStep,
    },
    {
      id: 'tradeDate',
      Component: AuctionCreatedAtStep,
    },
    {
      id: 'issuers',
      Component: AuctionIssuersStep,
    },
  ];

  return (
    <Stepper activeStep={step} alternativeLabel sx={stepperSx} connector={<StepConnector sx={connectorSx} />}>
      {steps.map(({ id, Component }) => (
        <Component key={id} />
      ))}
    </Stepper>
  );
};
