import { OrderBookNoteTypesModel } from '@halo-common/models';
import { getOrderBookAuctionNoteTypes } from '@halo-data-sources/clients';
import { ApiOrderBookMapper } from '@halo-data-sources/mappers';
import { OrderBookAuctionQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getOrderBookAuctionNoteTypesFn = async () => {
  const response = await getOrderBookAuctionNoteTypes();

  return ApiOrderBookMapper.toOrderBookNoteTypesModel(response);
};

export const useOrderBookAuctionNoteTypeQuery = (): UseQueryResult<OrderBookNoteTypesModel, Error> =>
  useQuery<OrderBookNoteTypesModel, Error>({
    queryKey: OrderBookAuctionQueryKeyFactory.noteTypes(),
    queryFn: () => getOrderBookAuctionNoteTypesFn(),
  });
