import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type QuoteQueryKeys = {
  all: QueryKeyFactoryFn;
  byNoteId: QueryKeyFactoryFn;
  byNoteIds: QueryKeyFactoryFn;
};

export const QuoteQueryKeyFactory: QuoteQueryKeys = {
  all: () => ['quotes'],
  byNoteId: (id?: string) => [...QuoteQueryKeyFactory.all(), 'note', id],
  byNoteIds: (ids: Array<number> = []) => [...QuoteQueryKeyFactory.all(), 'notes', ...ids],
};
