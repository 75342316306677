import { ReactElement } from 'react';

import { orderBookCancelModalAtom } from '@halo-atoms/orderbook';
import { ActionButton } from '@halo-common/components';
import { PershingStatusEnum } from '@halo-common/enums';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { AllocationModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useSetAtom } from 'jotai';
import { DateTime } from 'luxon';

export type CancelPershingOrderButtonProps = {
  allocation: AllocationModel;
};

export const CancelPershingOrderButton = ({ allocation }: CancelPershingOrderButtonProps): ReactElement | null => {
  const configureDateTime = useWhiteLabelDateConfiguration();

  const setOrderBookCancelModal = useSetAtom(orderBookCancelModalAtom);

  const status = allocation.pershingOrderBookStatus;
  const expirationDate = configureDateTime(allocation.expirationDate);
  const isExpired = expirationDate && expirationDate < DateTime.now();
  const isNeedsReview = status === PershingStatusEnum['Needs Review'];
  const isConfirmed = status === PershingStatusEnum['Confirmed'];
  const isCancellable = !isExpired && (isNeedsReview || isConfirmed);

  const openCancelModal = () => void setOrderBookCancelModal({ open: true, allocation });

  if (!isCancellable) {
    return null;
  }

  return (
    <ActionButton
      color="primary"
      type="button"
      variant="outlined"
      onClick={openCancelModal}
      fullWidth
      size="extraSmall"
    >
      {translations.common.cancel}
    </ActionButton>
  );
};
