import { ReactElement } from 'react';

import { CalendarProductCardFavoriteBtn } from '@halo-common/components';
import { NoteTypeEnum } from '@halo-common/enums';
import {
  useCalendarIndicationButtonText,
  useCalendarSpecificDetails,
  useModalState,
  usePrimaryAssetIdentifier,
  useWhiteLabelDateConfiguration,
} from '@halo-common/hooks';
import { MUST_COMPLETE_ONBOARDING_MODAL } from '@halo-common/modals';
import { CalendarModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Iconography, LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';

const calendarCardActionsSx = {
  pl: 2,
  height: '100%',
};

export type CalendarProductCardAction = (calendar: CalendarModel) => void;

export type CalendarProductCardActionsProps = {
  calendar: CalendarModel;
  termsheetId?: number;
  actions?: {
    onDetails?: CalendarProductCardAction;
    onSubmitIndication?: CalendarProductCardAction;
    onOfferingDoc?: CalendarProductCardAction;
  };
};

export const CalendarProductCardActions = ({
  calendar,
  termsheetId,
  actions,
}: CalendarProductCardActionsProps): ReactElement => {
  const { activeAllocationCount, note, expirationDate, id, isFavorite, finalTerms, preliminaryTerms } = calendar;
  const { type } = note;

  const { data: userInfo } = useUserInfoQuery();
  const { setOpen } = useModalState(MUST_COMPLETE_ONBOARDING_MODAL);

  const openOnboardingModal = () => setOpen(true);

  const { solvableParameter } = useCalendarSpecificDetails(calendar);
  const { identifier, identifierType, fallback } = usePrimaryAssetIdentifier(calendar);

  const configureDateTime = useWhiteLabelDateConfiguration();

  const parsedIdentifierText = identifier ? identifier : fallback.identifier;
  const parsedIdentifierType = identifier ? identifierType : fallback.identifierType;

  const todaysDateTime = configureDateTime(DateTime.utc().toISO()) as DateTime;
  const expirationDateTime = configureDateTime(expirationDate) ?? todaysDateTime;

  const hasQuoteLabel = Boolean(solvableParameter?.text);
  const hasAllocations = activeAllocationCount > 0;
  const hasDocuments = finalTerms || preliminaryTerms;
  const isExpired = expirationDateTime < todaysDateTime;
  const isClosed = isExpired && !hasAllocations;
  const isCalendarGeneral = type === NoteTypeEnum.General;
  const isCaptionLabel = isCalendarGeneral && hasQuoteLabel;
  const isAllocationsButtonDisabled = !userInfo?.settings?.enableCalendarAllocations || isClosed;
  const showOfferingDocButton = Boolean(hasDocuments && actions?.onOfferingDoc);

  const label = hasQuoteLabel ? `${solvableParameter?.text}` : '-';
  const labelVariant = isCaptionLabel ? 'caption' : 'h2';
  const labelFontWeight = isCaptionLabel ? 'bold' : undefined;
  const topRightJustification = showOfferingDocButton ? 'space-between' : 'flex-end';

  const indicationButtonText = useCalendarIndicationButtonText(activeAllocationCount, expirationDate);

  const isOnboardingCompleted = userInfo?.organization?.isOnboardingCompleted;

  const handleOfferingDocClick = () => {
    actions?.onOfferingDoc?.(calendar);
  };

  const handleSubmitAllocationsToggle = () => {
    if (!isOnboardingCompleted) openOnboardingModal();
    else actions?.onSubmitIndication?.(calendar);
  };

  const handleDetailsModalToggle = () => {
    actions?.onDetails?.(calendar);
  };

  const offeringDocButton = showOfferingDocButton ? (
    <LocalizedButton
      onClick={handleOfferingDocClick}
      size="small"
      color="primary"
      startIcon={<Iconography color="primary.main" iconName="file-magnifying-glass" />}
    >
      {translations.calendars.common.offeringDoc}
    </LocalizedButton>
  ) : null;

  const submitIndicationsButton = actions?.onSubmitIndication ? (
    <LocalizedButton
      size="medium"
      fullWidth
      variant="contained"
      onClick={handleSubmitAllocationsToggle}
      disabled={isAllocationsButtonDisabled}
    >
      {indicationButtonText}
    </LocalizedButton>
  ) : null;

  const detailButton = actions?.onDetails ? (
    <LocalizedButton size="medium" fullWidth variant="text" onClick={handleDetailsModalToggle}>
      {translations.common.details}
    </LocalizedButton>
  ) : null;

  return (
    <Stack sx={calendarCardActionsSx} direction="column" justifyContent="space-between">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={{ md: topRightJustification, xs: 'flex-end' }}
        flexWrap="wrap-reverse"
        useFlexGap
      >
        {offeringDocButton}
        <Stack
          direction={{ md: 'row', xs: 'column-reverse' }}
          justifyContent="flex-end"
          spacing={2}
          alignItems={{ md: 'center', xs: 'flex-start' }}
          flexWrap="wrap-reverse"
          useFlexGap
        >
          <Typography variant="body2" color="text.secondary">
            {parsedIdentifierText}
          </Typography>
          <CalendarProductCardFavoriteBtn
            isFavorite={isFavorite}
            calendarId={id}
            assetIdentifier={parsedIdentifierText}
            assetIdentifierType={parsedIdentifierType}
            termsheetId={termsheetId}
          />
        </Stack>
      </Stack>
      <Stack direction="column">
        <LocalizedTypography variant={labelVariant} fontWeight={labelFontWeight} color="text.primary">
          {label}
        </LocalizedTypography>
        <LocalizedTypography variant="caption" color="text.secondary">
          {solvableParameter?.label}
        </LocalizedTypography>
      </Stack>
      <Stack direction={{ md: 'row', xs: 'column' }} spacing={2}>
        {submitIndicationsButton}
        {detailButton}
      </Stack>
    </Stack>
  );
};
