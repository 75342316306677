import { ReactElement } from 'react';

import { LexicalTypography } from '@halo-common/components';
import { NoteTypeEnum } from '@halo-common/enums';
import { useMoneyness, useNoteSpecificDetails } from '@halo-common/hooks';
import { NoteModel } from '@halo-common/models';
import { Iconography, LocalizedTypography, Stack, useCombinedStyling } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Chip, Divider, Tooltip, Typography } from '@mui/material';
import { capitalize } from 'lodash';

const featureTitleStyling = { marginLeft: 4 };
const noteTypeRowStyling = { pt: 1, pb: 2, px: 0 };
const noteDataRowStyling = { py: 2, px: 0 };
const incomeTypeStyling = { backgroundColor: 'common.accent3', color: 'primary.dark' };
const growthTypeStyling = { backgroundColor: 'primary.dark', color: 'primary.contrastText' };
const growthFeatureStyling = { backgroundColor: 'primary.background' };
const incomeFeatureStyling = (theme: HaloTheme) => ({
  backgroundColor: `${theme.palette.common.accent3}30`,
});
const fullWidthStyling = (theme: HaloTheme) => ({
  width: `calc(100% - ${theme.spacing(3)}px)`,
});

export type AuctionNoteDetailsHeaderProps = {
  commission?: number;
  note?: NoteModel;
};

export const AuctionNoteDetailsHeader = ({ commission, note }: AuctionNoteDetailsHeaderProps): ReactElement => {
  const { highlights: noteSpecificFields } = useNoteSpecificDetails(note);
  const [principalProtectionPercentage] = useMoneyness(note?.protectionPercent);

  const principalProtectionText = note ? `${principalProtectionPercentage}% ${capitalize(note.protectionType)}` : '';
  const weights = note?.tradableWeights;

  const isIncomeType = note?.type === NoteTypeEnum.Income;
  const isGrowthType = note?.type === NoteTypeEnum.Growth;

  const combinedStyling = useCombinedStyling(noteTypeRowStyling, fullWidthStyling);
  const combinedRowStyling = useCombinedStyling(noteDataRowStyling, fullWidthStyling);
  const combinedTypeStyling = useCombinedStyling(
    isIncomeType ? incomeTypeStyling : null,
    isGrowthType ? growthTypeStyling : null,
  );
  const combinedFeatureStyling = useCombinedStyling(
    isIncomeType ? incomeFeatureStyling : null,
    isGrowthType ? growthFeatureStyling : null,
  );

  return (
    <Stack direction="column">
      <Stack sx={combinedStyling} direction="column">
        <Stack direction="row" alignItems="center" spacing={2}>
          <LexicalTypography translationKey="Type" variant="h6">
            Type:
          </LexicalTypography>
          <Chip
            size="small"
            sx={combinedTypeStyling}
            label={<LexicalTypography variant="body2">{note?.type}</LexicalTypography>}
          />
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <LexicalTypography sx={featureTitleStyling} translationKey="Features" variant="h6">
            Features:
          </LexicalTypography>
          {note?.features?.map(({ name, description }) => {
            const text = description ?? '';

            return (
              <Tooltip key={name} title={text} placement="top" arrow>
                <Chip
                  size="small"
                  icon={text ? <Iconography prefix="fal" iconName="info-circle" /> : undefined}
                  sx={combinedFeatureStyling}
                  label={<LexicalTypography variant="body2">{name}</LexicalTypography>}
                />
              </Tooltip>
            );
          })}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <LexicalTypography sx={featureTitleStyling} translationKey="Underlyings" variant="h6">
            Underlyings:
          </LexicalTypography>
          {note?.tradables.map(({ id, name }) => {
            const text = `${name}${weights ? ` ${weights[name]}%` : ''}`;

            return <Chip key={id} size="small" label={<Typography variant="body2">{text}</Typography>} />;
          })}
        </Stack>
        {commission ? (
          <Stack direction="row" alignItems="center" spacing={2}>
            <LexicalTypography translationKey="Commission" variant="h6">
              Commission:
            </LexicalTypography>
            <Typography variant="body1">{commission}%</Typography>
          </Stack>
        ) : null}
      </Stack>
      <Divider sx={fullWidthStyling} />
      <Stack sx={combinedRowStyling} direction="row" justify="space-between">
        {noteSpecificFields.map(({ label, text, dynamicContent }) => (
          <Stack key={`${label}-header`} direction="column">
            <LexicalTypography variant="body2">{label}</LexicalTypography>
            <LocalizedTypography variant="h6" dynamicContent={dynamicContent}>
              {text}
            </LocalizedTypography>
          </Stack>
        ))}
        <Stack direction="column">
          <LexicalTypography variant="body2">Principal Protection</LexicalTypography>
          <LexicalTypography translationKey={capitalize(note?.protectionType)} variant="h6">
            {principalProtectionText}
          </LexicalTypography>
        </Stack>
        <Stack direction="column">
          <LexicalTypography variant="body2">Term</LexicalTypography>
          <Typography variant="h6">{note?.termInMonths}&nbsp;Months</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
