import { WebsocketDuck } from './WebsocketDuck';
import * as selectors from './WebsocketSelectors';

export const WebsocketSelectors = selectors;

export const WebsocketActions = WebsocketDuck.actions;

export const WebsocketDuckName = WebsocketDuck.name;

export const WebsocketReducer = WebsocketDuck.reducer;

export * from './WebsocketModel';
