import { ReactElement, useEffect } from 'react';

import { ActionButton } from '@halo-common/components';
import { usePrimaryAssetIdentifier, useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { CalendarModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useCalendarExpirationOverrideMutation } from '@halo-data-sources/mutations';
import { Iconography, LocalizedButton, Modal, Stack } from '@halodomination/halo-fe-common';
import { DateTime } from 'luxon';
import { FormProvider, useForm } from 'react-hook-form';

import { ProductApprovalEditCloseDateMultiForm } from './ProductApprovalEditCloseDateMultiForm';
import { ProductApprovalEditCloseDateSingleForm } from './ProductApprovalEditCloseDateSingleForm';

export type ProductApprovalEditCloseDateModalProps = {
  open?: boolean;
  onClose: () => void;
  onSubmit: () => void;
  calendar?: CalendarModel | null;
  calendarIds?: Array<number>;
  multiple?: boolean;
};

export type ProductApprovalEditCloseDateFormFields = {
  date: string | null;
  dayModification: string;
  time: string | null;
};

export const ProductApprovalEditCloseDateModal = ({
  open = false,
  calendar,
  onClose,
  onSubmit,
  calendarIds = [],
  multiple = false,
}: ProductApprovalEditCloseDateModalProps): ReactElement => {
  const configureDateTime = useWhiteLabelDateConfiguration();

  const {
    mutate: updateExpirationDate,
    isPending,
    isSuccess,
    error,
    reset: resetMutation,
  } = useCalendarExpirationOverrideMutation();

  const formMethods = useForm<ProductApprovalEditCloseDateFormFields>({
    mode: 'onSubmit',
    defaultValues: {
      dayModification: '0',
    },
  });

  const { identifier, identifierType } = usePrimaryAssetIdentifier(calendar);
  const assetIdentifierText = `${identifierType} ${identifier}`;

  const title = multiple
    ? translations.productApproval.editCloseDateModal.titleMultiple
    : translations.productApproval.editCloseDateModal.titleSingle;

  const overline = multiple ? translations.productApproval.editCloseDateModal.overlineMultiple : assetIdentifierText;

  const Localization = {
    overline: {
      dynamicContent: {
        count: calendarIds.length,
      },
    },
  };

  const handleSubmit = formMethods.handleSubmit((data) => {
    const timeString = data.date ?? data.time;
    const isoDate = data.date ? (DateTime.fromISO(data.date).toUTC().toISODate() ?? undefined) : undefined;
    const isoTime = timeString ? (DateTime.fromISO(timeString).toUTC().toISOTime() ?? undefined) : undefined;

    const date = multiple ? { relative: parseInt(data.dayModification) } : { absolute: isoDate };

    updateExpirationDate({ calendarIds, assetIdentifier: assetIdentifierText, date, time: isoTime });

    onSubmit();
  });

  const handleClose = () => {
    resetMutation();
    formMethods.reset();
    onClose();
  };

  const footer = (
    <Stack direction="row" alignItems="center" justify="space-between" sx={{ width: '100%' }}>
      <LocalizedButton
        variant="text"
        color="primary"
        startIcon={<Iconography iconName="times" />}
        onClick={handleClose}
        disabled={isPending}
      >
        {translations.common.cancel}
      </LocalizedButton>
      <ActionButton color="primary" onClick={handleSubmit} loading={isPending}>
        {translations.common.save}
      </ActionButton>
    </Stack>
  );

  const content = multiple ? (
    <ProductApprovalEditCloseDateMultiForm />
  ) : (
    <ProductApprovalEditCloseDateSingleForm calendar={calendar} error={error} />
  );

  useEffect(() => {
    if (isSuccess) handleClose();
  }, [isSuccess]);

  useEffect(() => {
    const updatedExpirationDate = configureDateTime(calendar?.expirationDate, { disableUTCConversion: true })?.toISO();
    if (updatedExpirationDate) formMethods.setValue('date', updatedExpirationDate);
  }, [calendar?.expirationDate]);

  return (
    <FormProvider {...formMethods}>
      <Modal
        icon="pencil"
        open={open}
        title={title}
        overline={overline}
        footer={footer}
        onClose={handleClose}
        size="small"
        Localization={Localization}
      >
        {content}
      </Modal>
    </FormProvider>
  );
};
