import { UserModel } from '@halo-common/models';
import { searchAdminUsers } from '@halo-data-sources/clients';
import { ApiUserMapper } from '@halo-data-sources/mappers';
import { UserQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

const searchUsersFn = async (query?: string) => {
  if (!query) return [];
  const params = query ? new URLSearchParams({ query }).toString() : '';
  const result = await searchAdminUsers(params);
  return result.map(ApiUserMapper.toUser);
};

export const useUserSearchQuery = (query?: string): UseQueryResult<Array<UserModel>, Error> =>
  useQuery<Array<UserModel>, Error>({
    queryKey: UserQueryKeyFactory.search(query),
    queryFn: () => searchUsersFn(query),
    placeholderData: keepPreviousData,
  });
