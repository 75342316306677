import { ComplianceApprovalQuery } from '@halo-atoms/complianceApproval';
import { getAdminCalendarAllocationFiltersQuery } from '@halo-data-sources/clients';
import { ApiComparisonTypeEnum } from '@halo-data-sources/enums';
import { ComplianceApprovalMapper } from '@halo-data-sources/mappers';
import { AllocationClientQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

export type AdminCalendarByStatusQueryResult = {
  pending: number;
  confirmed: number;
  denied: number;
  active: number;
  archived: number;
};

export type AdminCalendarByStatusQueryPayload = ComplianceApprovalQuery & {
  calendarPageId?: number | null;
};

const adminCalendarByStatusQueryFn = async (filters: AdminCalendarByStatusQueryPayload) => {
  const comparisons = ComplianceApprovalMapper.toCalendarTotalsQueryComparisons(filters);

  const response = await getAdminCalendarAllocationFiltersQuery({
    filters: [
      {
        field: 'calendar_allocations.status',
        type: 'VALUES',
      },
    ],
    comparisons,
    comparison_type: ApiComparisonTypeEnum.OR,
  });

  // 'pending', 'accepted', 'canceled', 'filled'
  const defaultCountMap = { pending: 0, confirmed: 0, denied: 0, active: 0, archived: 0 };

  return (
    response.filter_values[0]?.values?.reduce((map, { value, count }) => {
      const status = (value as string).toLowerCase();
      return { ...map, [status]: count };
    }, defaultCountMap) ?? defaultCountMap
  );
};

export const useAdminCalendarByStatusQuery = (
  query: AdminCalendarByStatusQueryPayload,
): UseQueryResult<AdminCalendarByStatusQueryResult, Error> =>
  useQuery<AdminCalendarByStatusQueryResult, Error>({
    queryKey: AllocationClientQueryKeyFactory.adminByStatus(query),
    queryFn: () => adminCalendarByStatusQueryFn(query),
    placeholderData: keepPreviousData,
    retry: false,
  });
