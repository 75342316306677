import { MouseEvent, ReactElement, useState } from 'react';

import { orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { ActionButton } from '@halo-common/components';
import {
  useBuyerAuctionAcceptTermsMutation,
  useBuyerAuctionRejectTermsMutation,
  useExecutionTermsheetDownloadMutation,
} from '@halo-data-sources/mutations';
import { Iconography, Stack } from '@halodomination/halo-fe-common';
import { Alert, Paper, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';

import { AuctionDetailsRejectTermsPopover } from './AuctionDetailsRejectTermsPopover';

const paperSx = {
  px: 2,
  py: 1,
};

export const AuctionDetailsReviewTermsSection = (): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);

  const { mutate: acceptTerms, isPending: isAccepting } = useBuyerAuctionAcceptTermsMutation();
  const { mutate: rejectTerms, isPending: isRejecting } = useBuyerAuctionRejectTermsMutation();
  const { mutate: fetchPrelims } = useExecutionTermsheetDownloadMutation();

  const isPopoverOpen = Boolean(anchorEl);

  const disableAcceptReject = isAccepting || isRejecting;

  const handleDownloadTerms = () => {
    void fetchPrelims({
      type: 'prelim',
      auctionId: selectedAuctionId,
    });
  };

  const handleAcceptTerms = () => {
    if (selectedAuctionId) acceptTerms(selectedAuctionId);
  };

  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const handleRejectTerms = () => {
    if (selectedAuctionId) rejectTerms(selectedAuctionId);
    setAnchorEl(null);
  };

  const downloadIcon = <Iconography iconName="download" />;
  const rejectIcon = <Iconography iconName="exclamation-triangle" color="common.white" />;
  const approveIcon = <Iconography iconName="check" color="common.white" />;

  return (
    <Stack direction="column" spacing={2}>
      <Paper variant="outlined" sx={paperSx}>
        <Stack direction="row" justify="space-between" alignItems="center">
          <Typography>Preliminary Termsheet</Typography>
          <Stack direction="row" justify="space-between" spacing={1}>
            <ActionButton size="small" variant="text" startIcon={downloadIcon} onClick={handleDownloadTerms}>
              Download
            </ActionButton>
            <ActionButton
              size="small"
              variant="contained"
              color="error"
              startIcon={rejectIcon}
              onClick={handleOpenPopover}
              disabled={disableAcceptReject}
            >
              Reject
            </ActionButton>
            <ActionButton
              size="small"
              variant="contained"
              startIcon={approveIcon}
              onClick={handleAcceptTerms}
              disabled={disableAcceptReject}
            >
              Approve
            </ActionButton>
          </Stack>
        </Stack>
      </Paper>
      <Alert severity="warning" variant="filled">
        This Preliminary Termsheet will be automatically accepted if no action is taken by the close date.
      </Alert>
      <AuctionDetailsRejectTermsPopover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onReject={handleRejectTerms}
        onClose={closePopover}
        loading={disableAcceptReject}
      />
    </Stack>
  );
};
