import { ReactElement } from 'react';

import { ModuleQuizQuestionModel, QuizAttemptAnswerModel } from '@halo-common/models';
import { QuizManagerAnswer, QuizSchema } from '@halo-modules/app';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Avatar, Paper, RadioGroup, Skeleton, Stack } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const positionIconSx = { backgroundColor: 'primary.background', color: 'text.secondary' };
const questionTextSx = { fontWeight: 'fontWeightBold', color: 'primary.main' };
const radioGroupSx = { pt: 2, pl: 1 };

export type QuizManagerQuestionProps = {
  question: ModuleQuizQuestionModel;
  answers: QuizAttemptAnswerModel;
  attempted: boolean;
  disabled: boolean;
  loading?: boolean;
};

export const QuizManagerQuestion = ({
  question,
  answers,
  attempted,
  disabled,
  loading,
}: QuizManagerQuestionProps): ReactElement => {
  const { control } = useFormContext<QuizSchema>();

  const questionId = question.id;
  const questionAnswers = question?.answers ?? [];
  const questionFieldName = questionId.toString();
  const questionRules = { required: true };
  const answeredIncorrectly = attempted && !answers[questionId]?.isCorrect;

  const containerSx = {
    padding: 3,
    backgroundColor: 'default',
    borderColor: answeredIncorrectly ? 'warning.main' : 'default',
  };

  return loading ? (
    <Paper variant="outlined" sx={containerSx}>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="rounded" width={450} height={24} />
        </Stack>
        <Stack direction="column" spacing={4}>
          {questionAnswers.map((answer) => (
            <Stack key={answer.id} direction="row" spacing={2}>
              <Skeleton variant="circular" width={24} height={24} />
              <Skeleton variant="rounded" width={400} height={24} />
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Paper>
  ) : (
    <Paper variant="outlined" sx={containerSx}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Avatar sx={positionIconSx}>{question.position}</Avatar>
        <LocalizedTypography id="questions-radio-group" sx={questionTextSx}>
          {question.content}
        </LocalizedTypography>
      </Stack>
      <Controller
        name={questionFieldName}
        control={control}
        rules={questionRules}
        defaultValue={null}
        render={({ field }) => (
          <RadioGroup {...field} aria-labelledby="questions-radio-group" sx={radioGroupSx}>
            {questionAnswers.map((answer) => {
              const value = answers[questionId]?.submittedAnswer;
              const isSelected = value === answer.id;
              const questionAttempted = Boolean(attempted || typeof value === 'number');

              return (
                <QuizManagerAnswer
                  key={answer.id}
                  answer={answer}
                  attempted={questionAttempted}
                  selected={isSelected}
                  disabled={disabled}
                />
              );
            })}
          </RadioGroup>
        )}
      />
    </Paper>
  );
};
