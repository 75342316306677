import { ReactElement, useEffect } from 'react';

import { ActionButton } from '@halo-common/components';
import { ModuleQuizModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useEducationQuizSubmissionMutation } from '@halo-data-sources/mutations';
import { useEducationQuizAttemptsQuery } from '@halo-data-sources/queries';
import { EducationStepper, QuizManagerForm, useCourseManager } from '@halo-modules/app';
import { Iconography, Stack } from '@halodomination/halo-fe-common';
import { Container, Paper } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

const containerSx = { pb: 10 };
const quizSx = { px: 12, pt: 3, pb: 12 };

export type QuizSchema = { [id: string]: number | null };

export const QuizManager = (): ReactElement => {
  const { progress } = useCourseManager();
  const currentStep = progress?.step;

  const attemptsQuery = useEducationQuizAttemptsQuery(currentStep?.id);
  const { data: attempts, isFetching, isFetched: attemptsFetched } = attemptsQuery;

  const submissionMutation = useEducationQuizSubmissionMutation();
  const { data: submission, isPending, mutate, reset } = submissionMutation;

  const quiz = currentStep as ModuleQuizModel;
  const quizAttemptMap = submission?.attempt ?? attempts?.[quiz.id] ?? {};
  const quizAttempts = quizAttemptMap ? Object.entries(quizAttemptMap) : [];
  const submittedAnswers = quizAttempts.reduce(
    (map: QuizSchema, [key, value]) => ({ ...map, [key]: value.submittedAnswer }),
    {},
  );

  const initialLoading = !attemptsFetched;
  const contentLoading = isFetching || isPending;
  const hasSubmittedQuiz = Boolean(Object.values(submittedAnswers).length);
  const passedQuiz = hasSubmittedQuiz && quiz.completed;
  const failedQuiz = hasSubmittedQuiz && !quiz.completed;
  const submissionPassed = submission && submission.passed;
  const submissionFailed = submission && !submission.passed;
  const passed = submissionPassed ?? passedQuiz;
  const failed = submissionFailed ?? failedQuiz;
  const attempted = passedQuiz || failedQuiz;

  const formMethods = useForm<QuizSchema>({ mode: 'onChange', defaultValues: submittedAnswers });
  const { handleSubmit: onSubmit, formState, reset: formReset } = formMethods;
  const { isValid } = formState;

  const handleSubmit = onSubmit((data: QuizSchema) => {
    const questionIds = new Set(quiz.questions.map((question) => question.id.toString()));

    const answers: QuizSchema = Object.entries(data).reduce((map: QuizSchema, [key, value]) => {
      if (questionIds.has(key)) map[key] = value;
      return map;
    }, {});

    mutate({ quizId: quiz.id, answers });
  });

  const quizSubmissionButton = !passed ? (
    <ActionButton
      loading={contentLoading}
      disabled={!isValid}
      type="submit"
      endIcon={<Iconography color="common.white" prefix="fas" iconName="pen" />}
      variant="contained"
    >
      {translations.education.educationQuizManager.submitQuiz}
    </ActionButton>
  ) : undefined;

  const onNext = () => {
    reset();
  };
  const onPrev = () => {
    reset();
  };

  useEffect(() => {
    if (attemptsFetched) formReset(submittedAnswers);
    return () => reset();
  }, [attemptsFetched]);

  return (
    <FormProvider {...formMethods}>
      <Container maxWidth="md" sx={containerSx}>
        <form onSubmit={handleSubmit}>
          <Stack direction="column" xs={12}>
            <Paper sx={quizSx}>
              <QuizManagerForm
                attempts={quizAttemptMap}
                passed={passed}
                failed={failed}
                attempted={attempted}
                loading={initialLoading}
              />
            </Paper>
          </Stack>
          <EducationStepper primaryActionOverride={quizSubmissionButton} onNext={onNext} onPrev={onPrev} />
        </form>
      </Container>
    </FormProvider>
  );
};
