import { atom } from 'jotai';
import { DateTime } from 'luxon';

const DEFAULT_END_DATE = DateTime.local();
const DEFAULT_START_DATE = DEFAULT_END_DATE.minus({ years: 10 });

export const DEFAULT_PRODUCT_DETAIL_MODAL_ANALYTICS_TAB_STATE = {
  selectedTab: 3,
  selectedStatsTab: 0,
  underlyingModalOpen: false,
  underlyingModalStartDate: null,
  underlyingModalEndDate: null,
  openDateRange: false,
  daysBetween: DEFAULT_END_DATE.diff(DEFAULT_START_DATE, 'days').days,
  startDate: DEFAULT_START_DATE,
  endDate: DEFAULT_END_DATE,
};

export type ProductDetailModalAnalyticsTabState = {
  selectedTab: number;
  selectedStatsTab: number;
  underlyingModalOpen: boolean;
  underlyingModalStartDate: DateTime | null;
  underlyingModalEndDate: DateTime | null;
  openDateRange: boolean;
  daysBetween: number;
  brushStart?: number;
  brushEnd?: number;
  startDate: DateTime;
  endDate: DateTime;
  dateRangeError?: string;
  dateRangeText?: string;
};

export const productDetailModalAnalyticsTabStateAtom = atom<ProductDetailModalAnalyticsTabState>(
  DEFAULT_PRODUCT_DETAIL_MODAL_ANALYTICS_TAB_STATE,
);
