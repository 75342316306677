import { useEffect, useState } from 'react';

import { useMobileView } from '@halo-common/hooks';
import { ConnectedContactButton } from '@halo-common/smartComponents';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LinkExpiredSchema, PageMessageLayout } from '@halo-modules/app';
import { OnboardingActions, OnboardingSelectors, OnboardingStatusEnum } from '@halo-stores/Onboarding';
import { Iconography, PageComponent, PageMeta } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, capitalize } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

const CheckEmailPage: PageComponent = () => {
  const dispatch = useDispatch();
  const isSmallScreen = useMobileView();

  const { data: userInfo, isPending } = useUserInfoQuery();

  const [showSnackbar, setShowSnackbar] = useState(false);

  const userIdentification = useSelector(OnboardingSelectors.selectUserIdentification);
  const status = useSelector(OnboardingSelectors.selectStatus);

  const whiteLabel = userInfo?.whiteLabel;
  const userFacingName = whiteLabel?.userFacingName;
  const { userName: name, userEmail: email } = userIdentification;

  const iconColor = isSmallScreen ? 'primary.contrastText' : 'primary.main';
  const messageLayoutSubtext = `We're so happy to have you on ${userFacingName}. You should receive an email with a link to set your account password momentarily.`;
  const titleText = name ? `Check your email, ${capitalize(name)}` : `Check your email`;

  const mainSx = (theme: HaloTheme) => ({
    background: !isPending
      ? `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`
      : null,
    height: '100vh',
    width: '100vw',
  });

  const handleResendEmail = () => {
    const payload: LinkExpiredSchema = { email };

    dispatch(OnboardingActions.resendSetPassword(payload));

    setShowSnackbar(status === OnboardingStatusEnum.successResendSetPassword);
  };

  const handleClose = () => {
    setShowSnackbar(false);
  };

  useEffect(() => {
    dispatch(OnboardingActions.resetStatus());
  }, []);

  return (
    <Box sx={mainSx}>
      <PageMessageLayout
        icon={<Iconography iconName="envelope-open-text" color={iconColor} size="5x" />}
        titleText={titleText}
        subText={messageLayoutSubtext}
        buttonText="Resend Email"
        snackbarContent={
          <p>
            We have resent the link to your email. If you still need help: <ConnectedContactButton />
          </p>
        }
        loading={isPending}
        showSnackbar={showSnackbar}
        onClick={handleResendEmail}
        onClose={handleClose}
      />
    </Box>
  );
};

export const CheckEmailPageMeta: PageMeta = {
  pageName: 'CheckEmail',
  route: '/app/onboarding/check-email',
  iconName: 'mailbox',
};

CheckEmailPage.pageName = CheckEmailPageMeta.pageName;
CheckEmailPage.route = CheckEmailPageMeta.route;
CheckEmailPage.iconName = CheckEmailPageMeta.iconName;

export default CheckEmailPage;
