export enum AuctionStatusEnum {
  Canceled = 'canceled',
  ChooseWinner = 'choose-winner',
  InAuction = 'auction',
  NotWin = 'not-win',
  OpsApproval = 'ops-approval-required',
  PreApproval = 'pre-approval-required',
  Purchased = 'purchased',
  TermsAccepted = 'term_sheet_accepted',
  TermsPending = 'waiting_for_terms',
  TermsReviewed = 'review_terms',
}

export enum AuctionStatusKeyEnum {
  Canceled = 'canceled',
  ChooseWinner = 'chooseWinner',
  InAuction = 'inAuction',
  NotWin = 'noWinner',
  OpsApproval = 'opsApproval',
  PreApproval = 'preApproval',
  Purchased = 'purchased',
  TermsAccepted = 'termsAccepted',
  TermsPending = 'termsPending',
  TermsReviewed = 'termsReview',
}

export enum AuctionStrikeStyleEnum {
  Open = 'open',
  Close = 'close',
  Live = 'live',
}

export enum AuctionStyleEnum {
  Choose = 'choose',
  Best = 'best',
  FillOrKill = 'fok',
}

export enum AuctionDetailsIssuerSortOptionsEnum {
  alpha = 'Alphabetically',
  bid = 'Highest Bid',
}

export enum AuctionDateTypeEnum {
  AuctionEnd = 'AUCTION_END',
  Strike = 'STRIKE',
  Trade = 'TRADE',
  Settlement = 'SETTLEMENT',
}

export enum AuctionUserFacingStyleEnum {
  choose = 'Choose Winner',
  best = 'Best Quote',
  fok = 'Fill Or Kill',
}

export enum AuctionIssuerBidStatusEnum {
  waitingForBid = 'waitingForBid',
  received = 'received',
  receivedWithComment = 'receivedWithComment',
  declinedToBid = 'declinedToBid',
  declinedByYou = 'declinedByYou',
  acceptedByYou = 'acceptedByYou',
  winningQuote = 'winningQuote',
}
