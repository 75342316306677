import { MouseEvent, ReactElement, useEffect } from 'react';

import { anchorElAtom } from '@halo-atoms/common';
import { ActionButton } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { useUpdateWatchlistTargetQuoteMutation } from '@halo-data-sources/mutations';
import { useWatchlistTargetedNoteQuery } from '@halo-data-sources/queries';
import { LocalizedButton, LocalizedTextField, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { InputAdornment, Typography } from '@mui/material';
import { useSetAtom } from 'jotai';
import { useForm } from 'react-hook-form';

const numberInputSx = {
  '-moz-appearance': 'textfield !important',
  '-webkit-appearance': 'none',
  margin: 0,
};

const inputContainerSx = {
  'input[type="number"]': numberInputSx,
  'input[type="number"]::-webkit-inner-spin-button': numberInputSx,
  'input[type="number"]::-webkit-outer-spin-button': numberInputSx,
};

const MAX_NUMBER_OF_TARGETS = 4;

type TargetedQuoteFormFields = { target: number };

export type WatchlistTargetedQuoteFieldProps = {
  productId?: number;
  onClose: (ev: MouseEvent<HTMLButtonElement>) => void;
};

export const WatchlistTargetedQuoteField = ({ productId, onClose }: WatchlistTargetedQuoteFieldProps): ReactElement => {
  const { data: watchlistTargetedNotes } = useWatchlistTargetedNoteQuery(productId);
  const { mutate, isPending, isSuccess } = useUpdateWatchlistTargetQuoteMutation();

  const setAnchorElMap = useSetAtom(anchorElAtom);

  const targetLevel = watchlistTargetedNotes?.target?.targetLevel;
  const targetCount = watchlistTargetedNotes?.targets.length ?? 0;
  const targetText = `${targetCount}/${MAX_NUMBER_OF_TARGETS}`;

  const maxTargetsReached = targetCount >= MAX_NUMBER_OF_TARGETS;
  const isTargetedNote = watchlistTargetedNotes?.targets.some(({ note }) => note?.id === productId);
  const exceedsTargetedCount = !isTargetedNote && maxTargetsReached;

  const {
    handleSubmit: onSubmit,
    register,
    formState,
  } = useForm<TargetedQuoteFormFields>({
    reValidateMode: 'onSubmit',
    mode: 'onChange',
    defaultValues: { target: targetLevel },
  });

  const { isValid, isDirty, errors } = formState;
  const disableSave = !isDirty || !isValid || exceedsTargetedCount;

  const hasError = Boolean(errors['target']);

  const errorMessageMap: { [key: string]: string } = {
    min: translations.messages.targetQuoteMinimum,
    max: translations.messages.targetQuoteMaximum,
    required: translations.messages.requiredField,
  };
  const errorMessage = errorMessageMap[errors['target']?.type ?? ''];

  const handleNavigation = () => {
    navigateParentTo('/watchlist?tab=my+targeted+products');
  };

  const handleSubmit = onSubmit((data: TargetedQuoteFormFields) => {
    if (productId) mutate({ productId, target: data.target });
  });

  const { ref: targetRef, ...targetProps } = register('target', { required: true, min: 0.01, max: 99999.99 });

  useEffect(() => {
    if (productId && isSuccess) setAnchorElMap({ [productId]: null });
  }, [productId, isSuccess]);

  return (
    <Stack direction="column" xs={12} spacing={2}>
      <Stack
        sx={inputContainerSx}
        direction="row"
        alignItems="center"
        justify="space-between"
        spacing={2}
        elementStyling={[{ flex: 1 }]}
      >
        <LocalizedTextField
          {...targetProps}
          fullWidth
          ref={targetRef}
          type="number"
          label={translations.pdm.common.setQuoteTarget}
          size="large"
          error={hasError}
          helperText={errorMessage}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <Typography>%</Typography>
                </InputAdornment>
              ),
            },
          }}
        />
        <LocalizedTypography variant="button" color="action.disabled" dynamicContent={{ count: targetText }}>
          {translations.pdm.common.targetedWatchlistTargetsUsedMessage}
        </LocalizedTypography>
      </Stack>
      <Stack sx={inputContainerSx} direction="row" alignItems="center" justify="space-between">
        <LocalizedButton onClick={handleNavigation} component="a" type="button" variant="text" color="primary">
          {translations.pdm.common.targetedWatchlistPopoverTitle}
        </LocalizedButton>
        <Stack direction="row" spacing={2}>
          <LocalizedButton onClick={onClose} type="button" variant="outlined" color="primary">
            {translations.common.cancel}
          </LocalizedButton>
          <ActionButton
            onClick={handleSubmit}
            disabled={disableSave}
            loading={isPending}
            type="button"
            variant="contained"
            color="primary"
          >
            {translations.common.save}
          </ActionButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
