import { translations } from '@halo-common/translations';
import { useT } from '@transifex/react';

export type UseSliderTextPropModel = {
  minValue: number;
  maxValue: number;
  suffix?: string;
  prefix?: string;
  leftSliderValue: string | number;
  rightSliderValue: string | number;
};

export const useSliderText = ({
  minValue,
  maxValue,
  suffix = '',
  leftSliderValue,
  rightSliderValue,
  prefix,
}: UseSliderTextPropModel): string => {
  const translator = useT();
  const isSliderMinValue = leftSliderValue === minValue;
  const isSliderMaxValue = rightSliderValue === maxValue;

  const anyText = translator(translations.common.any);
  const translatedSuffix = translator(suffix);
  const translatedPrefix = translator(prefix);

  const rightButtonText = isSliderMaxValue ? anyText : `${rightSliderValue}${translatedSuffix}`;
  const leftButtonText = isSliderMinValue ? anyText : `${leftSliderValue}${translatedSuffix}`;

  const sliderText = isSliderMaxValue && isSliderMinValue ? anyText : `${leftButtonText} - ${rightButtonText}`;

  const prefixedText = prefix ? `${translatedPrefix}: ${sliderText}` : sliderText;

  return prefixedText;
};
