import type { CalendarModel } from '@halo-common/models';
import { getCalendarOfferingsV2, getFeaturedCalendarQuery } from '@halo-data-sources/clients';
import { ApiComparisonOptionEnum } from '@halo-data-sources/enums';
import { ApiCalendarsMapper } from '@halo-data-sources/mappers';
import type { ApiFeaturedCalendarNoteResponseModel, ApiQueryRequestModel } from '@halo-data-sources/models';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { FeaturedCalendarQueryKeyFactory } from '../keys';

export type FeaturedCalendarNoteResponseModel = ApiFeaturedCalendarNoteResponseModel & {
  calendar: CalendarModel;
};

export type FeaturedCalendarResponseModel = Array<FeaturedCalendarNoteResponseModel>;

export type UseFeaturedCalendarQueryOptions = UseQueryOptions<FeaturedCalendarResponseModel, Error>;

export type UseFeaturedCalendarQueryResult = UseQueryResult<FeaturedCalendarResponseModel, Error>;

const getFeaturedCalendarQueryFn = async (
  searchParams: ApiQueryRequestModel,
): Promise<FeaturedCalendarResponseModel> => {
  const res = await getFeaturedCalendarQuery(searchParams);

  const featuredNotes = res.featured_calendar_notes || [];
  const calendarIds = featuredNotes.map((note) => note.calendar_note_id).filter(Boolean);

  const comparisons = [
    {
      field: 'calendar_notes.id',
      value: calendarIds,
      op: ApiComparisonOptionEnum.IN,
    },
    {
      field: 'calendar_offering.approved',
      value: 'true',
      op: ApiComparisonOptionEnum.EQ,
    },
    {
      field: 'calendar_offering.is_active',
      value: 'true',
      op: ApiComparisonOptionEnum.EQ,
    },
    {
      field: 'calendar_offering.is_visible',
      value: 'true',
      op: ApiComparisonOptionEnum.EQ,
    },
  ];

  const { results } = await getCalendarOfferingsV2({
    comparisons,
    comparison_type: 'AND',
  });

  const mappedFeaturedNotes = featuredNotes.map((note) => {
    const calendar = results?.find((calendar) => calendar.id === note.calendar_note_id);
    return { ...note, calendar: calendar ? ApiCalendarsMapper.toCalendarModel(calendar) : null };
  }) as Array<FeaturedCalendarNoteResponseModel>;

  return mappedFeaturedNotes.filter((note) => note.calendar);
};

export const useFeaturedCalendarQuery = (
  searchParams: ApiQueryRequestModel,
  options?: UseFeaturedCalendarQueryOptions,
): UseFeaturedCalendarQueryResult =>
  useQuery({
    queryKey: FeaturedCalendarQueryKeyFactory.query(),
    queryFn: () => getFeaturedCalendarQueryFn(searchParams),
    ...options,
  });
