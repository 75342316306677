import { ReactElement } from 'react';

import { AuctionAdminModel, NoteModel } from '@halo-common/models';
import { Stack } from '@halodomination/halo-fe-common';
import { Typography } from '@mui/material';

export type TackOnAuctionDetailsProps = {
  auction: AuctionAdminModel | null | undefined;
  note?: NoteModel | null | undefined;
};

export const TackOnAuctionDetails = ({ auction, note }: TackOnAuctionDetailsProps): ReactElement => {
  const useCusipIsinHeader = (cusip: string | null, isin: string | null) => {
    const cusipIsinDetails = { cusipIsinHeader: 'CUSIP/ISIN', cusipIsinCaption: '' };
    if (cusip && isin) {
      cusipIsinDetails.cusipIsinCaption = `${cusip}/${isin}`;
    } else if (cusip && !isin) {
      cusipIsinDetails.cusipIsinHeader = 'CUSIP';
      cusipIsinDetails.cusipIsinCaption = cusip;
    } else if (!cusip && isin) {
      cusipIsinDetails.cusipIsinHeader = 'ISIN';
      cusipIsinDetails.cusipIsinCaption = isin;
    }
    return cusipIsinDetails;
  };

  const selectedNote = note ?? auction?.note;

  const noteId = selectedNote?.id ?? '';

  const cusip = auction?.cusip ? auction.cusip : null;
  const isin = auction?.isin ? auction.isin : null;
  const { cusipIsinHeader, cusipIsinCaption } = useCusipIsinHeader(cusip, isin);

  const settleDate = auction?.targetSettleDate ? auction.targetSettleDate : '';
  const strikeDate = auction?.targetStrikeDate ? auction.targetStrikeDate : '';
  const tradeDate = auction?.targetTradeDate ? auction.targetTradeDate : '';

  return (
    <Stack direction="row" spacing={12} justifyElements="flex-start">
      <Stack direction="column" spacing={1}>
        <Typography variant="body2">Note ID</Typography>
        <Typography variant="h6">{noteId}</Typography>
      </Stack>
      <Stack direction="column" spacing={1}>
        <Typography variant="body2">{cusipIsinHeader}</Typography>
        <Typography variant="h6">{cusipIsinCaption}</Typography>
      </Stack>
      <Stack direction="column" spacing={1}>
        <Typography variant="body2">Trade Date</Typography>
        <Typography variant="h6">{settleDate}</Typography>
      </Stack>
      <Stack direction="column" spacing={1}>
        <Typography variant="body2">Strike Date</Typography>
        <Typography variant="h6">{strikeDate}</Typography>
      </Stack>
      <Stack direction="column" spacing={1}>
        <Typography variant="body2">Settle Date</Typography>
        <Typography variant="h6">{tradeDate}</Typography>
      </Stack>
    </Stack>
  );
};
