import { HouseholdModel } from '@halo-common/models';
import { getHouseholds } from '@halo-data-sources/clients';
import { ApiAccountMapper } from '@halo-data-sources/mappers';
import { AccountQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getHouseholdQueryFn = async () => {
  const result = await getHouseholds();

  const households = result.advisees.map(ApiAccountMapper.toHouseholdModel);

  return households;
};

export const useHouseholdQuery = (): UseQueryResult<Array<HouseholdModel>, Error> =>
  useQuery<Array<HouseholdModel>, Error>({
    queryKey: AccountQueryKeyFactory.households(),
    queryFn: () => getHouseholdQueryFn(),
  });
