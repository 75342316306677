import { ReactElement, ReactNode } from 'react';

import { Iconography } from '@halodomination/halo-fe-common';
import { IconButton, Stack } from '@mui/material';

const closeButtonContainerSx = {
  pl: 2,
  textAlign: 'right',
};

const closeButtonSx = {
  height: 'fit-content',
  padding: 0,
  top: '-1px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

const bottomRowSx = {
  flexBasis: { md: '50%', xs: '100%' },
};

export type ProductDetailModalLayoutHeaderProps = {
  onClose?: () => void;
  slots?: {
    parameter?: ReactNode;
    actions?: ReactNode;
    description?: ReactNode;
    productId?: ReactNode;
    features?: ReactNode;
  };
};

export const ProductDetailModalLayoutHeader = ({
  onClose,
  slots,
}: ProductDetailModalLayoutHeaderProps): ReactElement => {
  const productDetailHeaderSx = { p: { md: 3, xs: 2 }, pb: { md: 0, xs: 2 } };
  const topRowJustify = !slots?.features ? 'flex-end' : 'space-between';
  const bottomRowJustify = !slots?.parameter ? 'flex-end' : 'space-between';

  return (
    <Stack direction="column" sx={productDetailHeaderSx}>
      <Stack direction="row" justifyContent={topRowJustify}>
        {slots?.features}
        <Stack direction="row" sx={closeButtonContainerSx} spacing={2}>
          {slots?.productId}
          <IconButton sx={closeButtonSx} onClick={onClose} disableRipple>
            <Iconography iconName="times" color="text.secondary" />
          </IconButton>
        </Stack>
      </Stack>
      {slots?.description}
      <Stack direction={{ md: 'row', xs: 'column' }} justifyContent={bottomRowJustify} alignItems="flex-start">
        <Stack sx={bottomRowSx} direction="row" justifyContent="flex-start">
          {slots?.parameter}
        </Stack>
        <Stack sx={bottomRowSx} direction="row" justifyContent="flex-end">
          {slots?.actions}
        </Stack>
      </Stack>
    </Stack>
  );
};
