import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type CalendarsQueryKeys = {
  all: QueryKeyFactoryFn;
  allocations: QueryKeyFactoryFn;
  calendar: QueryKeyFactoryFn;
  calendarName: QueryKeyFactoryFn;
  calendars: QueryKeyFactoryFn;
  download: QueryKeyFactoryFn;
  infinite: QueryKeyFactoryFn;
  issuers: QueryKeyFactoryFn;
  product: QueryKeyFactoryFn;
  search: QueryKeyFactoryFn;
  wholesalers: QueryKeyFactoryFn;
};

export const CalendarsQueryKeyFactory: CalendarsQueryKeys = {
  all: () => ['calendars'],
  allocations: (id) => [...CalendarsQueryKeyFactory.calendar(id), 'allocations'],
  calendar: (id) => [...CalendarsQueryKeyFactory.all(), id],
  calendarName: (id) => [...CalendarsQueryKeyFactory.calendar(id), 'name'],
  calendars: (payload) => [...CalendarsQueryKeyFactory.all(), hash(payload)],
  download: (payload) => [...CalendarsQueryKeyFactory.all(), 'download', hash(payload)],
  infinite: (payload) => [...CalendarsQueryKeyFactory.calendars(payload), 'infinite'],
  issuers: (payload) => [...CalendarsQueryKeyFactory.all(), 'issuers', hash(payload)],
  product: (payload) => [...CalendarsQueryKeyFactory.all(), 'product', hash(payload)],
  search: (payload) => [...CalendarsQueryKeyFactory.all(), 'search', hash(payload)],
  wholesalers: (payload) => [...CalendarsQueryKeyFactory.all(), 'wholesalers', hash(payload)],
};
