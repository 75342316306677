import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { getPostTradeV2Events } from '@halo-data-sources/clients';
import { ApiPositionsMapper, PositionsMapper } from '@halo-data-sources/mappers';
import { PDMKeyFactory } from '@halo-data-sources/queries';
import { PDMSwitchKeyFactory, PostTradeProductDetailsDataSwitchResult } from '@halo-data-sources/switches';
import { CallEventCalledStatus, CouponEventPaidStatus } from '@halo-modules/app';
import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { DateTime } from 'luxon';

export type PostTradeV2Event = {
  barrierPct: number;
  cashFlowAmt: number;
  eventDate: string;
  paid?: CouponEventPaidStatus;
  called?: CallEventCalledStatus;
  return?: number;
  id: string;
};

export type PostTradeV2EventsQueryResult = {
  callEvents: Array<PostTradeV2Event>;
  coupon: Array<PostTradeV2Event>;
};

type PostTradeV2AdditionalData = {
  dates?: Array<string>;
  returns?: Array<number>;
};

const getPostTradeV2EventsQueryFn = async (
  id?: number,
  accountDetails?: AccountTypeAheadOption | null,
  additionalData?: PostTradeV2AdditionalData,
): Promise<PostTradeV2EventsQueryResult | null> => {
  if (!id || !additionalData?.dates || !additionalData?.returns) return null;

  const request = PositionsMapper.toFetchPositionEventsRequest(id, accountDetails);

  const response = await getPostTradeV2Events(request);

  const { returns, dates } = additionalData;

  const mappedResponse = ApiPositionsMapper.toPositionCouponAndRedemeptionEvents(response);

  const callEvents = mappedResponse.callEvents.reduce((events: Array<PostTradeV2Event>, event) => {
    const eventDate = DateTime.fromISO(event.eventDate);
    const inPast = eventDate <= DateTime.now();
    const returnIndex = dates.findIndex((date) => DateTime.fromISO(date).equals(eventDate));
    const returnValue = returns[returnIndex];

    if (inPast && !returnValue) return events;

    return [...events, { ...event, return: returnValue }];
  }, []);

  return { coupon: mappedResponse.coupon, callEvents };
};

export const usePostTradeV2EventsQuery = (
  id?: number,
  accountDetails?: AccountTypeAheadOption | null,
): UseQueryResult<PostTradeV2EventsQueryResult | null, Error> => {
  const queryClient = useQueryClient();

  const detailsKey = PDMSwitchKeyFactory.postTrade(id);
  const details = queryClient.getQueryData<PostTradeProductDetailsDataSwitchResult>(detailsKey);

  const dates = details?.history.dates;
  const returns = details?.history.returns;

  const additionalData = { dates, returns };

  return useQuery<PostTradeV2EventsQueryResult | null, Error>({
    queryKey: PDMKeyFactory.events(id, accountDetails),
    queryFn: () => getPostTradeV2EventsQueryFn(id, accountDetails, additionalData),
    enabled: !!id,
  });
};
