import { ReactElement, useCallback, useEffect } from 'react';

import { selectedWatchlistAtom } from '@halo-atoms/watchlists';
import { DASHBOARD_PREFERENCE_TAG } from '@halo-common/constants';
import { WidgetEnginesEnum } from '@halo-common/enums';
import type { DashboardWidgetModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useUpdateUserPreferencesMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery, useWatchlistsQuery } from '@halo-data-sources/queries';
import { DashboardWatchlistDetails, DashboardWatchlistSkeleton, Widget } from '@halo-modules/app';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useAtom } from 'jotai';

const engine = WidgetEnginesEnum.WATCHLIST;

const title = translations.dashboard.watchlist.watchlist;
const description = translations.dashboard.watchlist.listOfNotesForSelectedWatchlist;

const controlSx = {
  minWidth: 180,
  maxWidth: 360,
};

const itemSx = {
  minWidth: 180,
  maxWidth: 360,
};

const labelSx = {
  flex: '1 1 auto',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const Watchlist = (): ReactElement => {
  const [selectedWatchlist, setSelectedWatchlist] = useAtom(selectedWatchlistAtom);

  const { data: watchlists } = useWatchlistsQuery();
  const { isPending: isUserInfoLoading, data: userInfo } = useUserInfoQuery();
  const { mutate: updatePreferences } = useUpdateUserPreferencesMutation();

  const { preferences } = userInfo || {};
  const preferredWatchlistId = preferences?.dashboard_preferences?.watchlistId ?? null;
  const watchlistDropdownValue = selectedWatchlist?.id || null;

  const onWatchlistChange = useCallback(
    (e: SelectChangeEvent<number | null>) => {
      const id = e.target.value as number;
      const watchlist = watchlists?.find((list) => list.id === id);

      if (!watchlist) return;

      const updatedPreferences = {
        tag: DASHBOARD_PREFERENCE_TAG,
        content: {
          ...(preferences?.dashboard_preferences ?? {}),
          watchlistId: id,
        },
      };

      setSelectedWatchlist(watchlist);
      updatePreferences(updatedPreferences);
    },
    [watchlists, preferences, updatePreferences, setSelectedWatchlist],
  );

  const body = !selectedWatchlist ? (
    <DashboardWatchlistSkeleton />
  ) : (
    <DashboardWatchlistDetails id={selectedWatchlist.id} key={selectedWatchlist.id} />
  );

  useEffect(() => {
    const needToUpdate = !isUserInfoLoading && !selectedWatchlist && watchlists && watchlists.length > 0;

    if (!needToUpdate) return;

    const preferredWatchlist = preferredWatchlistId
      ? watchlists.find((list) => list.id === preferredWatchlistId)
      : null;

    const defaultWatchlist = preferredWatchlist ?? watchlists?.[0];

    setSelectedWatchlist(defaultWatchlist);
  }, [isUserInfoLoading, selectedWatchlist, watchlists, preferredWatchlistId, setSelectedWatchlist]);

  return (
    <Widget
      id={engine}
      title={title}
      filters={
        <FormControl variant="standard" sx={controlSx}>
          <Select
            value={watchlistDropdownValue}
            fullWidth
            size="large"
            onChange={onWatchlistChange}
            className="wm-dashboard-watchlist-selectlist"
          >
            {watchlists?.map((watchlist) => (
              <MenuItem value={watchlist.id} sx={itemSx} key={watchlist.id}>
                <Box sx={labelSx}>{watchlist.name}</Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
      className="wm-dashboard-watchlist"
      navigateButtonHref="/watchlist"
      navigateButtonLabel={translations.dashboard.watchlist.watchlist}
      navigateButtonClassName="wm-dashboard-watchlist-viewall"
    >
      {body}
    </Widget>
  );
};

const widget: DashboardWidgetModel = {
  engine,
  title,
  description,
  component: Watchlist,
};

export default widget;
