import { ReactElement } from 'react';

import { useGenerateFilePreviewLink } from '@halo-common/hooks';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LoginPageManagementFormFields } from '@halo-modules/admin/content-management';
import { Iconography, LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Box, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const bodySx = { my: 3, maxWidth: '464px' };
const mediaContentSx = { mb: 3, maxWidth: '400px', maxHeight: '400px' };
const imageSx = { maxHeight: '100%', maxWidth: '100%' };
const videoSx = { border: 'none', borderRadius: '16px' };
const titleSx = { maxWidth: '560px' };

export const OnboardingSidebarPreview = (): ReactElement | null => {
  const { data: user } = useUserInfoQuery();

  const formMethods = useFormContext<LoginPageManagementFormFields>();

  const { watch } = formMethods;

  const backgroundImage = user?.whiteLabel?.brand.onboardingBackground?.path;
  const onboardingSetting = user?.settings?.onboarding;

  const image = watch('image') ?? (onboardingSetting?.image as LoginPageManagementFormFields['image']);
  const video = watch('videoLink') ?? onboardingSetting?.video;
  const overline = watch('overline') ?? onboardingSetting?.overline;
  const title = watch('headline') ?? onboardingSetting?.title;
  const subtitle = watch('subtitle') ?? onboardingSetting?.subtitle;
  const body = watch('description') ?? onboardingSetting?.body;
  const buttonText = watch('ctaText') ?? onboardingSetting?.buttonText;
  const buttonLink = watch('ctaLink') ?? onboardingSetting?.buttonLink;

  const previewLink = useGenerateFilePreviewLink(image);

  const hasCTAButton = buttonText && buttonLink;
  const hasNoContent = !overline && !title && !subtitle && !body && !video && !buttonText && !buttonLink && !image;

  const containerSx = {
    alignItems: 'center',
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: 'common.black',
    backgroundSize: 'cover',
    backgroundPosition: 'top left',
    justifyContent: 'center',
    borderRadius: 1,
    pb: 6,
    pt: 6,
    px: 6,
    width: '100%',
  };

  const previewSx = {
    padding: 3,
    minWidth: '50%',
    background: hasNoContent
      ? 'none'
      : 'radial-gradient(62.37% 71.99% at 0% 50%, #1E3543 0%, rgba(6, 17, 34, 0.055) 94.5%, rgba(5, 15, 32, 0) 100%)',
  };

  const mediaContent = previewLink ? (
    <img style={imageSx} src={previewLink} data-testid="preview-img" />
  ) : video ? (
    <iframe width="350px" height="205px" style={videoSx} src={video} />
  ) : null;

  const buttonIcon = <Iconography color="secondary.main" iconName="arrow-right" />;

  const button = hasCTAButton ? (
    <LocalizedButton
      color="secondary"
      target="_blank"
      size="medium"
      variant="outlined"
      href={buttonLink}
      endIcon={buttonIcon}
    >
      {buttonText}
    </LocalizedButton>
  ) : null;

  return !hasNoContent ? (
    <Stack direction="column" sx={containerSx}>
      <Stack direction="column" sx={previewSx}>
        <LocalizedTypography color="secondary.main" variant="overline">
          {overline}
        </LocalizedTypography>
        <LocalizedTypography sx={titleSx} color="primary.contrastText" variant="h2">
          {title}
        </LocalizedTypography>
        <LocalizedTypography sx={titleSx} color="grey.500" variant="h5">
          {subtitle}
        </LocalizedTypography>
        <LocalizedTypography sx={bodySx} color="grey.500" variant="body1">
          {body}
        </LocalizedTypography>
        <Stack direction="column">
          <Box sx={mediaContentSx}>{mediaContent}</Box>
          {button}
        </Stack>
      </Stack>
    </Stack>
  ) : null;
};
