import { ReactElement } from 'react';

import { Paper, Skeleton, Stack } from '@mui/material';

const LoadingContainerSx = {
  padding: 3,
  overflowY: 'auto',
  maxWidth: 450,
  minWidth: { xs: 343, sm: 'default' },
  width: '100%',
};

export const DisclaimerListSkeleton = (): ReactElement => (
  <Paper variant="outlined" sx={LoadingContainerSx}>
    <Stack direction="column" justifyContent="center">
      <Skeleton height={300} />
      <Skeleton height={60} />
    </Stack>
  </Paper>
);
