import type { DocumentBucketModel } from '@halo-common/models';
import { getClientConfiguration, request } from '@halo-data-sources/clients';
import type { ApiAdviseeSearchResponseModel, ApiGenerateDocumentUrlResponseModel } from '@halo-data-sources/models';

const COMMON_PATH = '/common';

export const generateDocumentUrl = async (
  document: DocumentBucketModel,
): Promise<ApiGenerateDocumentUrlResponseModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_PATH);

  const { id, token } = document;

  const path = `${clientConfiguration.basePath}/document/${encodeURIComponent(id)}/generate-url`;

  const response = await request<ApiGenerateDocumentUrlResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      token,
    }),
  });

  return response;
};

export const getAdviseesForUser = async (): Promise<ApiAdviseeSearchResponseModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_PATH);

  const path = `${clientConfiguration.basePath}/advisees/all_for_user`;

  const response = await request<ApiAdviseeSearchResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};
