import { ReactElement } from 'react';

import { NoteTypeEnum } from '@halo-common/enums';
import { useBreakpointView, usePrimaryAssetIdentifier } from '@halo-common/hooks';
import { ProductDetailModalLayout } from '@halo-common/layouts';
import {
  PostTradeNoteDetailModalAllocationsTab,
  PostTradeNoteDetailModalCouponTable,
  PostTradeNoteDetailModalEarlyRedemptionTable,
  PostTradeNoteDetailModalHeader,
  PostTradeNoteDetailModalPanel,
  V2PostTradeNoteDetailModalAnalyticsTab,
  V2PostTradeNoteDetailModalDocumentTable,
} from '@halo-common/modals';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { useRecordActivityMutation } from '@halo-data-sources/mutations';
import {
  usePortfolioOrderQuery,
  usePostTradeProductDetailsEarlyRedemptionQuery,
  useUserInfoQuery,
} from '@halo-data-sources/queries';
import { usePostTradeProductDetailsDataSwitch } from '@halo-data-sources/switches';

const DETAILS_TAB = translations.common.details;
const DOCUMENTS_TAB = translations.common.documents;
const ANALYTICS_TAB = translations.common.analytics;
const COUPON_SUMMARY_TAB = translations.common.couponSchedule;
const EARLY_REDEMPTION_SUMMARY_TAB = translations.common.earlyRedemption;
const ALLOCATIONS_TAB = translations.common.allocations;

export type PostTradeNoteDetailModalLayoutProps = {
  onClose: () => void;
  referenceId?: number;
  loading?: boolean;
  filters?: {
    currencyId?: number;
    accountOption?: AccountTypeAheadOption | null;
  };
};

export const PostTradeNoteDetailModalLayout = ({
  onClose,
  referenceId,
  loading = false,
  filters,
}: PostTradeNoteDetailModalLayoutProps): ReactElement => {
  const isTabletView = useBreakpointView('md', 'up');

  const { data: userInfo, isPending: userInfoLoading } = useUserInfoQuery();
  const productQuery = usePortfolioOrderQuery(referenceId);

  const product = productQuery?.data ?? null;

  const productDetailsQuery = usePostTradeProductDetailsDataSwitch(product, { nme: false, enabled: !loading });
  const earlyRedemptionQuery = usePostTradeProductDetailsEarlyRedemptionQuery(referenceId, filters?.accountOption);
  const { mutate: recordActivity } = useRecordActivityMutation();

  const isLoading =
    loading ||
    userInfoLoading ||
    productQuery.isPending ||
    productDetailsQuery.isPending ||
    earlyRedemptionQuery.isPending;

  const termsheet = product?.termsheet;
  const documents = termsheet?.documents;
  const termsheetId = termsheet?.id;
  const note = product?.note;
  const noteAnalytics = productDetailsQuery?.data?.noteAnalytics;
  const underlyingAnalytics = productDetailsQuery?.data?.underlyingAnalytics;
  const couponsViewable = userInfo?.settings.couponSummary
    ? Object.values(userInfo.settings.couponSummary).some(Boolean)
    : false;

  const showEarlyRedemptionTab = note?.type === NoteTypeEnum.Income;
  const showCouponTab = showEarlyRedemptionTab && couponsViewable;

  const isFixed = note?.features?.some((feature) => {
    const hasFixedIncomeFeatureName = feature.name.toLowerCase().includes('fixed');
    const hasFixedIncomeFeatureDescription = feature.description?.toLowerCase().includes('fixed');
    return hasFixedIncomeFeatureName || hasFixedIncomeFeatureDescription;
  });

  const { identifierText, identifier } = usePrimaryAssetIdentifier(product?.termsheet);

  const currencyCode = note?.currencyCode;
  const currencySymbol = note?.currencySymbol;

  const tabs = [ANALYTICS_TAB];
  if (!isTabletView) tabs.push(DETAILS_TAB);
  if (showCouponTab) tabs.push(COUPON_SUMMARY_TAB);
  if (showEarlyRedemptionTab) tabs.push(EARLY_REDEMPTION_SUMMARY_TAB);
  tabs.push(DOCUMENTS_TAB);
  tabs.push(ALLOCATIONS_TAB);

  const trackTab = (value: number) => {
    recordActivity({
      activity: [{ verb: `clicked ${tabs[value]} Tab`, model_name: 'NoteOrder' }],
      fromIFrame: false,
    });
  };

  const tabProps = { onChange: trackTab };

  const panel = <PostTradeNoteDetailModalPanel product={product} loading={isLoading} />;
  const header = <PostTradeNoteDetailModalHeader product={product} loading={isLoading} onClose={onClose} />;
  const detailsTabContents = !isTabletView ? panel : null;
  const slots = { header, panel };

  const couponSummaryTableContent = showCouponTab ? (
    <PostTradeNoteDetailModalCouponTable
      termsheetId={referenceId}
      filters={filters}
      currencyCode={currencyCode}
      currencySymbol={currencySymbol}
      isFixed={isFixed}
    />
  ) : null;

  const earlyRedemptionTableContent = showEarlyRedemptionTab ? (
    <PostTradeNoteDetailModalEarlyRedemptionTable termsheetId={referenceId} />
  ) : null;

  return (
    <ProductDetailModalLayout tabs={tabs} slots={slots} TabProps={tabProps}>
      <V2PostTradeNoteDetailModalAnalyticsTab
        termsheetId={referenceId}
        noteAnalytics={noteAnalytics}
        underlyingAnalytics={underlyingAnalytics}
        filters={filters}
        loading={isLoading}
      />
      {detailsTabContents}
      {couponSummaryTableContent}
      {earlyRedemptionTableContent}
      <V2PostTradeNoteDetailModalDocumentTable
        account={filters?.accountOption}
        currency={filters?.currencyId}
        termsheetId={termsheetId}
        identifierText={identifierText}
        identifier={identifier}
        documents={documents}
      />
      <PostTradeNoteDetailModalAllocationsTab
        identifier={identifier}
        termsheetId={referenceId}
        currencyCode={currencyCode}
        currencySymbol={currencySymbol}
      />
    </ProductDetailModalLayout>
  );
};
