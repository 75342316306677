import { ReactElement } from 'react';

import { NoteTypeEnum } from '@halo-common/enums';
import { useCalendarSpecificDetails } from '@halo-common/hooks';
import { ExecutionCalendarDetailModalHeaderSolvableParameterFindPriceButton } from '@halo-common/modals';
import { CalendarModel } from '@halo-common/models';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Skeleton, Typography } from '@mui/material';

export type ExecutionCalendarDetailModalHeaderSolvableParameterProps = {
  product?: CalendarModel | null;
  loading?: boolean;
  disableQuoteRefresh?: boolean;
};

export const ExecutionCalendarDetailModalHeaderSolvableParameter = ({
  product,
  loading = true,
  disableQuoteRefresh = false,
}: ExecutionCalendarDetailModalHeaderSolvableParameterProps): ReactElement | null => {
  const note = product?.note;
  const noteId = note?.id;
  const type = note?.type;

  const { solvableParameter } = useCalendarSpecificDetails(product);

  if (loading) return <Skeleton width={200} height={80} />;

  const hasQuote = Boolean(solvableParameter?.text);
  const isGeneralNote = type === NoteTypeEnum.General;
  const isUnquotedGeneral = isGeneralNote && !hasQuote;
  const hideSection = !noteId || isUnquotedGeneral;

  if (hideSection) return null;

  const generalNoteTextVariant = isGeneralNote ? 'h6' : 'h2';
  const generalNoteElementSx = isGeneralNote ? [{ width: '100%' }] : undefined;

  return (
    <Stack direction="column" xs={12}>
      <Stack direction="row" alignItems="center" spacing={1} elementStyling={generalNoteElementSx}>
        <Typography variant={generalNoteTextVariant} color="textPrimary">
          {solvableParameter?.text}
        </Typography>
        <ExecutionCalendarDetailModalHeaderSolvableParameterFindPriceButton
          product={note}
          label={solvableParameter?.label}
          disableQuoteRefresh={disableQuoteRefresh}
        />
      </Stack>
      <LocalizedTypography variant="subtitle1" color="textSecondary">
        {solvableParameter?.label}
      </LocalizedTypography>
    </Stack>
  );
};
