import { ReactElement } from 'react';

import { OrderTicketFormModelFields } from '@halo-common/modals';
import { Stack } from '@halodomination/halo-fe-common';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export const DisclaimerSection = (): ReactElement => {
  const { control, formState } = useFormContext<OrderTicketFormModelFields>();
  const { errors } = formState;

  const label = `I have reviewed and disclosed the details regarding this bond to my client. This includes information known by or reasonably accessible to the market and available to me through publicly available sources`;
  const error = errors?.disclaimer?.message;

  return (
    <Controller
      control={control}
      name="disclaimer"
      render={({ field: { ref, value, ...field } }) => (
        <Stack direction="column" sx={{ px: 4, py: 2 }} spacing={0.25}>
          <FormControlLabel
            {...field}
            inputRef={ref}
            control={<Checkbox checked={value} />}
            label={<Typography variant="body2">{label}</Typography>}
          />
          <Typography variant="caption" color="error.main">
            {error}
          </Typography>
        </Stack>
      )}
    />
  );
};
