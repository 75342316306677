import { AssetIdentifierEnum } from '@halo-common/enums';
import { AssetIdExistenceModel, AssetIdLocation, AssetIdModel } from '@halo-common/models';
import { validateAssetIdLength, validateFundServCode } from '@halo-common/utils';
import { ApiTermsheetMapper } from '@halo-data-sources/mappers';
import { ApiAssetIdExistenceModel } from '@halo-data-sources/models';
import { v4 as uuid } from 'uuid';

export const ApiAssetIdExistenceMapper = {
  toAssetIdExistenceModel: (assetId: ApiAssetIdExistenceModel): AssetIdExistenceModel => {
    return {
      id: assetId.id,
      exists: assetId.exists as AssetIdLocation,
      termsheet: assetId?.termsheet ? ApiTermsheetMapper.toTermsheetModel(assetId.termsheet) : null,
      assetIdType: assetId.asset_identifier_type,
    };
  },
  toAssetIdModel: (
    assetId: ApiAssetIdExistenceModel,
    assetIdentifiers: Array<AssetIdentifierEnum>,
    primaryAssetIdentifier: string,
  ): AssetIdModel => {
    const assetIdLengthValid = validateAssetIdLength(assetId.id, assetIdentifiers);
    const isAssetJHN = validateFundServCode(assetId.id, assetId.asset_identifier_type);

    const location = (assetId.exists as AssetIdLocation) ?? null;

    const isAssetIdResolved = assetIdLengthValid && !isAssetJHN && location;

    const status = isAssetIdResolved ? 'resolved' : 'unresolved';
    const termsheetId = assetId.termsheet?.id ?? null;
    const assetIdType = assetId.asset_identifier_type ?? primaryAssetIdentifier;

    return { id: uuid(), assetId: assetId.id, location, notionalAmount: 0, status, termsheetId, assetIdType };
  },
};
