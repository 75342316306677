import { ReactElement } from 'react';

import { adminAuctionUpdateFormDatesAtom } from '@halo-atoms/auctions';
import { useLexiconTranslator } from '@halo-common/hooks';
import { AuctionAdminModel } from '@halo-common/models';
import { AUCTION_DATE_FORMAT, AUCTION_EDITABLE_END_DATE_FORMAT } from '@halo-data-sources/mappers';
import { CurrencyInput, Stack } from '@halodomination/halo-fe-common';
import {
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  capitalize,
} from '@mui/material';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers-pro';
import { useAtom } from 'jotai';
import { DateTime } from 'luxon';
import { Controller, useFormContext } from 'react-hook-form';

const formSx = { width: '100%' };
const headerSx = { mt: 3 };
const secondRowSx = { minWidth: 273 };
const toggleButtonSx = { borderColor: 'primary', width: 65 };
const helperTextSx = { fontSize: 12, color: 'text.secondary' };
const toggleStackSx = { mt: -3 };

export type ExecutionAuctionReviewDetailFormFieldsProps = {
  product?: AuctionAdminModel | null;
  loading: boolean;
};

export const ExecutionAuctionReviewDetailFormFields = ({
  product,
  loading,
}: ExecutionAuctionReviewDetailFormFieldsProps): ReactElement => {
  const [adminAuctionUpdateFormData, setAdminAuctionUpdateFormData] = useAtom(adminAuctionUpdateFormDatesAtom);

  const { register, control } = useFormContext();

  const { endDate, strikeDate, tradeDate, settleDate } = adminAuctionUpdateFormData;

  const note = product?.note;
  const currency = note?.currencyCode;
  const parsedParameter = note?.parameter
    ?.split('_')
    .map((word) => capitalize(word))
    .join(' ');

  const solvableParameter = parsedParameter ?? 'Solvable Parameter';
  const targetSolvableParameterLabel = `Target ${solvableParameter}`;

  const [
    priceLabel,
    notionalLabel,
    auctionStyleLabel,
    strikeStyleLabel,
    additionalDetailsLabel,
    auctionEndDateLabel,
    auctionSettleDateLabel,
    auctionStrikeDateLabel,
    auctionTradeDateLabel,
  ] = useLexiconTranslator([
    'Price',
    'Notional',
    'Auction Style',
    'Strike Style',
    'Additional Details',
    'Auction End Date',
    'Settlement Date',
    'Strike Date',
    'Trade Date',
  ]);

  if (loading) {
    return (
      <Stack sx={formSx} direction="column" spacing={2}>
        <Stack sx={headerSx} direction="column" spacing={2}>
          <Skeleton variant="rounded" width={250} height={48} />
          <Skeleton variant="rounded" width="75%" height={40} />
        </Stack>
        <Stack direction="row" spacing={2} justify="space-between">
          <Skeleton variant="rounded" width={250} height={48} />
          <Skeleton variant="rounded" width={190} height={48} />
          <Skeleton variant="rounded" width={250} height={48} />
          <Skeleton variant="rounded" width={140} height={48} />
        </Stack>
        <Stack direction="row" spacing={2} justify="space-between">
          <Skeleton variant="rounded" width={270} height={48} />
          <Skeleton variant="rounded" width={270} height={48} />
          <Skeleton variant="rounded" width={270} height={48} />
        </Stack>
        <Stack direction="row" spacing={2} justify="space-between">
          <Skeleton variant="rounded" width={270} height={48} />
          <Skeleton variant="rounded" width={175} height={48} />
          <Skeleton variant="rounded" width={175} height={48} />
          <Skeleton variant="rounded" width={175} height={48} />
        </Stack>
        <Skeleton variant="rounded" width="100%" height={48} />
      </Stack>
    );
  }

  const { ref: targetSolvableParameterRef, ...targetSolvableParameterProps } = register('targetSolvableParameter');
  const { ref: additionalDetailsRef, ...additionalDetailsProps } = register('additionalDetails');
  const { ref: noteIdRef, ...noteIdProps } = register('noteId');

  const handleDateChange = (name: string, date: DateTime | null) => {
    setAdminAuctionUpdateFormData((prev) => ({ ...prev, [name]: date }));
  };

  return (
    <Stack sx={formSx} direction="column" spacing={2}>
      <Stack sx={headerSx} direction="column" spacing={2}>
        <Typography variant="h3">Edit This Auction</Typography>
        <Typography variant="body1">
          Adjust the details of the structure being sent to auction and the timeline and details.
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} justify="space-between">
        <TextField
          {...noteIdProps}
          size="large"
          inputRef={noteIdRef}
          slotProps={{ htmlInput: { inputMode: 'numeric', pattern: '[0-9]*' } }}
          fullWidth
          label="Note ID"
          defaultValue={note?.id}
        />
        <TextField
          {...targetSolvableParameterProps}
          size="large"
          fullWidth
          label={targetSolvableParameterLabel}
          inputRef={targetSolvableParameterRef}
        />
        <DateTimePicker
          slots={{
            textField: (props) => <TextField id="edit-note-end-date-picker-inline" size="large" fullWidth {...props} />,
          }}
          format={AUCTION_EDITABLE_END_DATE_FORMAT}
          label={auctionEndDateLabel}
          value={endDate}
          onChange={(value: DateTime | null) => {
            handleDateChange('endDate', value);
          }}
        />
        <Stack direction="column" sx={toggleStackSx}>
          <FormLabel sx={helperTextSx}>Show Top of Book:</FormLabel>
          <Controller
            render={({ field: fieldProps }) => (
              <ToggleButtonGroup
                color="primary"
                {...fieldProps}
                exclusive
                aria-label="show top of book toggle button group"
              >
                <ToggleButton sx={toggleButtonSx} value="no">
                  No
                </ToggleButton>
                <ToggleButton sx={toggleButtonSx} value="yes">
                  Yes
                </ToggleButton>
              </ToggleButtonGroup>
            )}
            control={control}
            name="isTopOfBook"
          />
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} justify="space-between">
        <FormControl sx={secondRowSx} variant="outlined" fullWidth>
          <InputLabel id="strike-style-select-label">Strike Style</InputLabel>
          <Controller
            control={control}
            render={({ field: { ref, ...fieldProps } }) => (
              <Select
                {...fieldProps}
                inputRef={ref}
                size="large"
                fullWidth
                labelId="strike-style-select-label"
                id="strike-style-select"
                label={strikeStyleLabel}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="open">Open</MenuItem>
                <MenuItem value="close">Close</MenuItem>
                <MenuItem value="live">Live</MenuItem>
              </Select>
            )}
            name="strikeStyle"
          />
        </FormControl>
        <Controller
          render={({ field: { ref, ...options } }) => (
            <CurrencyInput
              {...options}
              sx={secondRowSx}
              size="large"
              inputRef={ref}
              fullWidth
              label={priceLabel}
              allowNegative={false}
              CurrencyOptions={{ currency }}
            />
          )}
          control={control}
          name="price"
        />
        <FormControl sx={secondRowSx} variant="outlined" fullWidth>
          <InputLabel id="auction-style-select-label">Auction Style</InputLabel>
          <Controller
            control={control}
            render={({ field: { ref, ...fieldProps } }) => (
              <Select
                {...fieldProps}
                inputRef={ref}
                size="large"
                fullWidth
                labelId="auction-style-select-label"
                id="auction-style-select"
                label={auctionStyleLabel}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="choose">Choose Winner</MenuItem>
                <MenuItem value="best">Best Quote Wins</MenuItem>
                <MenuItem value="fok">Fill or Kill</MenuItem>
              </Select>
            )}
            name="style"
          />
        </FormControl>
      </Stack>
      <Stack direction="row" spacing={2} justify="space-between">
        <Controller
          render={({ field: { ref, ...options } }) => (
            <CurrencyInput
              {...options}
              sx={secondRowSx}
              size="large"
              inputRef={ref}
              fullWidth
              label={notionalLabel}
              allowNegative={false}
              CurrencyOptions={{ currency }}
            />
          )}
          control={control}
          name="notional"
        />
        <DatePicker
          slotProps={{
            field: {
              clearable: true,
            },
            textField: {
              id: 'edit-note-strike-date-picker-inline',
              size: 'large',
              fullWidth: true,
            },
          }}
          format={AUCTION_DATE_FORMAT}
          label={auctionStrikeDateLabel}
          value={strikeDate}
          onChange={(value: DateTime | null) => {
            handleDateChange('strikeDate', value);
          }}
        />
        <DatePicker
          slotProps={{
            field: {
              clearable: true,
            },
            textField: {
              id: 'edit-note-trade-date-picker-inline',
              size: 'large',
              fullWidth: true,
            },
          }}
          format={AUCTION_DATE_FORMAT}
          label={auctionTradeDateLabel}
          value={tradeDate}
          onChange={(value: DateTime | null) => {
            handleDateChange('tradeDate', value);
          }}
        />
        <DatePicker
          slotProps={{
            field: {
              clearable: true,
            },
            textField: {
              id: 'edit-note-settle-date-picker-inline',
              size: 'large',
              fullWidth: true,
            },
          }}
          format={AUCTION_DATE_FORMAT}
          label={auctionSettleDateLabel}
          value={settleDate}
          onChange={(value: DateTime | null) => {
            handleDateChange('settleDate', value);
          }}
        />
      </Stack>
      <TextField
        {...additionalDetailsProps}
        size="large"
        fullWidth
        label={additionalDetailsLabel}
        rows={3}
        inputRef={additionalDetailsRef}
        placeholder="Tell us what else we can do for your note auction..."
      />
    </Stack>
  );
};
