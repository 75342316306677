import { ReactElement } from 'react';

import { NoteParameterEnum } from '@halo-common/enums';
import { useNoteSpecificDetails } from '@halo-common/hooks';
import {
  ProductDetailPanelCommissionSection,
  ProductDetailPanelEarlyRedemptionSection,
  ProductDetailPanelLayout,
  ProductDetailPanelPayoffSection,
  ProductDetailPanelPrincipalSection,
  ProductDetailPanelUnderlyingSection,
} from '@halo-common/layouts';
import { NoteModel } from '@halo-common/models';

export type PreTradeNoteDetailModalPanelProps = {
  product?: NoteModel | null;
  loading?: boolean;
};

export const PreTradeNoteDetailModalPanel = ({
  product,
  loading = true,
}: PreTradeNoteDetailModalPanelProps): ReactElement => {
  const { earlyRedemptionDetails, fieldMap, payoffDetails, protectionDetails, term } = useNoteSpecificDetails(product);

  const principalProtection = protectionDetails?.[0];
  const gearingProtection = protectionDetails?.[1];

  const localization = {
    protection: protectionDetails?.[0]?.dynamicContent,
    term: term.dynamicContent,
    gearing: protectionDetails?.[1]?.dynamicContent,
  };

  const underlyings = product?.tradables;
  const weights = product?.tradableWeights;
  const parameter = product?.parameter;

  const hasCommissionDetails = Boolean(parameter !== NoteParameterEnum.Price && fieldMap.commission.text);
  const showCommissionSection = loading || hasCommissionDetails;
  const commissionSection = showCommissionSection ? (
    <ProductDetailPanelCommissionSection field={fieldMap.commission} loading={loading} />
  ) : null;

  const hasPayoffDetails = payoffDetails.length !== 0;
  const showPayoffSection = loading || hasPayoffDetails;
  const payoffSection = showPayoffSection ? (
    <ProductDetailPanelPayoffSection fields={payoffDetails} loading={loading} />
  ) : null;

  const hasEarlyRedemptionDetails = earlyRedemptionDetails.length !== 0;
  const showEarlyRedemptionSection = loading || hasEarlyRedemptionDetails;
  const earlyRedemptionSection = showEarlyRedemptionSection ? (
    <ProductDetailPanelEarlyRedemptionSection fields={earlyRedemptionDetails} loading={loading} />
  ) : null;

  const slots = {
    underlyings: <ProductDetailPanelUnderlyingSection underlyings={underlyings} weights={weights} loading={loading} />,
  };

  return (
    <ProductDetailPanelLayout slots={slots} loading={loading}>
      <ProductDetailPanelPrincipalSection
        term={term?.text}
        gearing={gearingProtection?.text}
        protection={principalProtection?.text}
        protectionType={product?.protectionType}
        currency={product?.currencyCode}
        loading={loading}
        Localization={localization}
      />
      {commissionSection}
      {payoffSection}
      {earlyRedemptionSection}
    </ProductDetailPanelLayout>
  );
};
