export * from './accounts';
export * from './addAccountModal';
export * from './addAccountOrPositionModal';
export * from './addPositionsToAccountModal';
export * from './addPositionToAccountsModal';
export * from './custodianModal';
export * from './deleteAccountModal';
export * from './generateReportModal';
export * from './lifecycle';
export * from './positions';

export const COMMON_TRANSLATIONS = {
  accountTypeAheadAllOption: 'All Accounts',
  accountTypeAheadLabel: 'View Positions Of',
  accountTypeAheadSyncedOption: 'Synced Accounts',
  accountTypeAheadUnallocatedOption: 'All Unallocated Positions',
  accountTypeAheadUnsyncedOption: 'Unsynced Accounts',
  addMoreHoldingsButton: 'Add More Holdings',
  addNewPositionOrAccount: 'Add New Position Or Account',
  addNewAccount: 'Add New Account',
  addNewPosition: 'Add New Position',
  assetSearchEmptyMessage: 'No Matching Assets Found',
  assetSearchLabel: 'Search by Name, {assetId}',
  assetPositionSearchLabel: 'Search by {assetId}',
  assetSearchNoMatchMessage: 'No ID Available',
  assetSearchValidationMessage: 'Please enter a valid {assetIdList}.',
  assetSearchValidationDefaultMessage: 'Please enter a valid assetId.',
  compositionTypeLabel: 'Change Composition View',
  currencyDropdownLabel: 'Chart Currency',
  defaultCurrencyDropdownLabel: 'Reporting Currency',
  notionalAmount: 'Notional Amount',
  portfolioDocumentOverline: 'Portfolio Document',
  positionTab: 'Positions',
  title: 'Portfolio',
  yourAccountTitle: 'Your Account',
  yourPortfolioTitle: 'Your Portfolio',
};
