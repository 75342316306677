import { ReactElement, useEffect } from 'react';

import { AuctionStatusEnum } from '@halo-common/enums';
import {
  AvailableTable,
  CreateTackOnModal,
  EditTackOnModal,
  InProgressTable,
  useOrderHubFilters,
} from '@halo-modules/admin';
import { OrdersActions, OrdersSelectors, OrderStatusEnum } from '@halo-stores/Orders';
import { ElementSelector, Tabs } from '@halodomination/halo-fe-common';
import { useDispatch, useSelector } from 'react-redux';

const tabsStyling = { marginBottom: 5 };

export const OrderHubTackOnsPage = (): ReactElement => {
  const dispatch = useDispatch();

  const auctions = useSelector(OrdersSelectors.selectAuctions);
  const orderStatus = useSelector(OrdersSelectors.selectStatus);

  const termsAccepted = auctions[AuctionStatusEnum.TermsAccepted] ?? [];
  const termsPending = auctions[AuctionStatusEnum.TermsPending] ?? [];
  const termsReviewed = auctions[AuctionStatusEnum.TermsReviewed] ?? [];

  const totalAuctions = [...termsAccepted, ...termsPending, ...termsReviewed];

  const availableAuctions = totalAuctions.length ? totalAuctions.filter((auction) => !auction.tackOn) : [];
  const inProgressAuctions = totalAuctions.length ? totalAuctions.filter((auction) => auction.tackOn) : [];

  const totalTackOnAuctions = availableAuctions.length + inProgressAuctions.length;

  const tabs = [
    {
      label: 'All',
      count: totalTackOnAuctions,
    },
    {
      label: 'Available',
      count: availableAuctions.length,
    },
    {
      label: 'In Progress',
      count: inProgressAuctions.length,
    },
  ];

  const { selectedTab, handleFilterChange, showAll, status } = useOrderHubFilters(tabs);

  const showCreateTackOnModal = orderStatus === OrderStatusEnum.showCreateTackOnModal;
  const showEditTackOnModal = orderStatus === OrderStatusEnum.showEditTackOnModal;

  const handleCloseModal = () => {
    dispatch(OrdersActions.resetSelected());
  };

  useEffect(() => {
    dispatch(OrdersActions.getOrderAuctions());
  }, [status]);

  return (
    <>
      <Tabs
        variant="scrollablePills"
        onChange={handleFilterChange}
        tabs={tabs}
        slotProps={{ tabs: { sx: tabsStyling } }}
      />
      <ElementSelector selected={selectedTab} showAll={showAll}>
        <AvailableTable />
        <InProgressTable />
      </ElementSelector>
      <CreateTackOnModal open={showCreateTackOnModal} onClose={handleCloseModal} />
      <EditTackOnModal open={showEditTackOnModal} onClose={handleCloseModal} />
    </>
  );
};
