import { EducationModuleGroup } from '@halo-common/models';
import { getEducationModuleGroups } from '@halo-data-sources/clients';
import { ApiEducationMapper } from '@halo-data-sources/mappers';
import { EducationQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

export type EducationModuleGroupsQueryResult = {
  groups: Array<EducationModuleGroup>;
  progress: number;
};

export type UseEducationModuleGroupsQueryOptions = UseQueryOptions<EducationModuleGroupsQueryResult, Error>;

export type UseEducationModuleGroupsQueryResult = UseQueryResult<EducationModuleGroupsQueryResult, Error>;

const getEducationModuleGroupsFn = async (): Promise<EducationModuleGroupsQueryResult> => {
  const moduleGroupsResponse = await getEducationModuleGroups();

  const groups = ApiEducationMapper.toModuleGroups(moduleGroupsResponse.groups);
  const educationProgress = groups.reduce((total, group) => total + group.percentageComplete, 0);
  const progress = groups.length ? Math.floor(educationProgress / groups.length) : 0;

  return { groups, progress };
};

export const useEducationModuleGroupsQuery = (
  options?: UseEducationModuleGroupsQueryOptions,
): UseEducationModuleGroupsQueryResult =>
  useQuery({
    queryKey: EducationQueryKeyFactory.moduleGroups(),
    queryFn: () => getEducationModuleGroupsFn(),
    ...(options ?? {}),
  });
