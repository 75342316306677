import { ReactElement, useEffect } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { selectedWatchlistAtom } from '@halo-atoms/watchlists';
import { translations } from '@halo-common/translations';
import { useUpdateWatchlistMutation } from '@halo-data-sources/mutations';
import {
  EditWatchlistFooter,
  WatchlistContentForm,
  WatchlistContentFormValues,
  watchlistValidationSchema,
} from '@halo-modules/app';
import { Modal } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtom, useAtomValue } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';

const DEFAULT_CREATE_WATCHLIST_ERROR_MSG = 'Something went wrong. Please try submitting again in a few minutes.';

export type EditWatchlistModalProps = {
  onDelete: (showEdit?: boolean) => void;
  onClose: () => void;
};

export const EditWatchlistModal = ({ onDelete, onClose }: EditWatchlistModalProps): ReactElement => {
  const modalMap = useAtomValue(modalAtom);
  const [selectedWatchlist, setSelectedWatchlist] = useAtom(selectedWatchlistAtom);

  const { data, mutate, error, isError, isSuccess, isPending, reset } = useUpdateWatchlistMutation();

  const formMethods = useForm<WatchlistContentFormValues>({
    mode: 'onSubmit',
    resolver: yupResolver<WatchlistContentFormValues>(watchlistValidationSchema),
    defaultValues: {
      watchlistName: selectedWatchlist?.name,
      watchlistIcon: selectedWatchlist?.icon,
    },
  });

  const { handleSubmit: submitHandler, reset: handleFormReset, setValue } = formMethods;
  const errorMessage = isError ? (error?.message ?? DEFAULT_CREATE_WATCHLIST_ERROR_MSG) : undefined;
  const open = Boolean(modalMap?.editWatchlist);

  const handleClose = () => {
    onClose();
    handleFormReset();
    reset();
  };

  const handleDeleteWatchlist = () => {
    onDelete();
  };

  const handleSubmit = submitHandler((data: WatchlistContentFormValues) => {
    mutate({ id: selectedWatchlist?.id, name: data.watchlistName.trim(), icon: data.watchlistIcon });
  });

  useEffect(() => {
    if (isSuccess) {
      setSelectedWatchlist(data?.watchlist ?? null);
      onClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (open && selectedWatchlist) {
      setValue('watchlistName', selectedWatchlist.name);
      setValue('watchlistIcon', selectedWatchlist.icon);
    }
  }, [open, selectedWatchlist]);

  return (
    <FormProvider {...formMethods}>
      <Modal
        icon="pencil"
        footer={
          <EditWatchlistFooter
            onSave={handleSubmit}
            onDelete={handleDeleteWatchlist}
            onClose={handleClose}
            loading={isPending}
            isDefault={selectedWatchlist?.default}
          />
        }
        ContentProps={{ sx: { py: 3 } }}
        title={translations.watchlist.common.editWatchlistModalTitle}
        size="small"
        subtitle={translations.watchlist.common.editWatchlistModalSubtitle}
        open={open}
        onClose={handleClose}
      >
        <WatchlistContentForm loading={isPending} error={errorMessage} />
      </Modal>
    </FormProvider>
  );
};
