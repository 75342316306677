import { ReactElement } from 'react';

import { EstimatedTransactionRow } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { LocalizedTypography, Stack, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Divider, Skeleton } from '@mui/material';

const headerSx = {
  paddingTop: 2,
  paddingBottom: 4,
};

export type EstimatedTransactionSectionProps = {
  loading?: boolean;
  commission?: boolean;
  principal?: number;
  compensation?: number;
  miscFees?: number;
  total?: number;
};

export const EstimatedTransactionSection = ({
  loading = false,
  commission = false,
  principal,
  compensation,
  miscFees,
  total,
}: EstimatedTransactionSectionProps): ReactElement => {
  const compensationTitle = commission ? translations.common.commission : translations.common.salesCredit;

  const [principalText, compensationText, miscFeesText, totalText] = useCurrencyConverter(
    { principal, compensation, miscFees, total },
    { currencyCode: 'USD' },
  );

  const titleContent = loading ? (
    <Skeleton width={267} height={70} />
  ) : (
    <LocalizedTypography variant="h6" sx={headerSx}>
      {translations.components.estimatedTransactionSectionTitle}
    </LocalizedTypography>
  );

  const principalRow = principalText ? (
    <EstimatedTransactionRow loading={loading} header="Principal">
      {principalText}
    </EstimatedTransactionRow>
  ) : null;

  const compensationRow = compensationText ? (
    <EstimatedTransactionRow loading={loading} header={compensationTitle}>
      {compensationText}
    </EstimatedTransactionRow>
  ) : null;

  const miscFeesRow = miscFeesText ? (
    <EstimatedTransactionRow loading={loading} header="Fees">
      {miscFeesText}
    </EstimatedTransactionRow>
  ) : null;

  const totalRow = totalText ? (
    <EstimatedTransactionRow loading={loading} header="Total">
      {totalText}
    </EstimatedTransactionRow>
  ) : null;

  return (
    <Stack direction="column" spacing={2}>
      {titleContent}
      {principalRow}
      {compensationRow}
      {miscFeesRow}
      <Divider />
      {totalRow}
    </Stack>
  );
};
