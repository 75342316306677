import { KeyboardEvent, ReactElement, useContext, useEffect } from 'react';

import { AuctionStatusEnum } from '@halo-common/enums';
import { OrderHubAuctionContext } from '@halo-modules/admin';
import { OrdersActions, OrdersSelectors } from '@halo-stores/Orders';
import { Modal, Stack } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { object as yupObject, string as yupString } from 'yup';

const schema = yupObject().shape({
  quote: yupString().required(),
  comment: yupString(),
});

const PASS_BUTTON_WHITE_LIST = [
  AuctionStatusEnum.PreApproval,
  AuctionStatusEnum.OpsApproval,
  AuctionStatusEnum.InAuction,
  AuctionStatusEnum.ChooseWinner,
  AuctionStatusEnum.TermsPending,
  AuctionStatusEnum.TermsReviewed,
  AuctionStatusEnum.TermsAccepted,
  AuctionStatusEnum.Purchased,
  AuctionStatusEnum.NotWin,
  AuctionStatusEnum.Canceled,
];

const INSTANT_WIN_BUTTON_WHITE_LIST = [
  AuctionStatusEnum.OpsApproval,
  AuctionStatusEnum.InAuction,
  AuctionStatusEnum.ChooseWinner,
  AuctionStatusEnum.NotWin,
];

type IssuerCommentFormModel = {
  quote?: string;
  comment?: string | null;
};

export type IssuerCommentModalProps = {
  open: boolean;
};

export const IssuerCommentModal = ({ open }: IssuerCommentModalProps): ReactElement => {
  const dispatch = useDispatch();

  const auction = useSelector(OrdersSelectors.selectSelectedAuction);
  const quote = useSelector(OrdersSelectors.selectSelectedQuote);
  const issuer = useSelector(OrdersSelectors.selectSelectedIssuer);

  const status = auction?.status;

  const { handleSubmit, register, setValue, reset, getValues } = useForm<IssuerCommentFormModel>({
    mode: 'onChange',
    resolver: yupResolver<IssuerCommentFormModel>(schema),
    defaultValues: {
      quote: quote?.value?.toString(),
      comment: quote?.comment?.comment,
    },
  });

  const { ref: quoteRef, ...quoteFieldProps } = register('quote');
  const { ref: commentRef, ...commentFieldProps } = register('comment');

  const { handleCloseModal } = useContext(OrderHubAuctionContext);

  const handleClose = () => {
    reset();
    handleCloseModal?.();
  };
  const handleInstantWinner = () => {
    dispatch(
      OrdersActions.chooseInstantWinnerOrderAuction({
        id: auction?.id,
        quoteId: quote?.id,
        status,
      }),
    );
    handleClose();
  };
  const handleApprove = handleSubmit((data: IssuerCommentFormModel) => {
    dispatch(
      OrdersActions.createIssuerQuoteForOrderAuction({
        auction: auction,
        issuerId: issuer?.id,
        quote: data.quote as string,
        comment: data?.comment as string,
      }),
    );
    handleClose();
  });
  const handlePass = () => {
    dispatch(
      OrdersActions.declineIssuerQuoteCommentForOrderAuction({
        id: auction?.id,
        issuerId: issuer?.id,
        comment: getValues('comment') as string,
      }),
    );
    handleClose();
  };

  const handleKeyDown = (ev: KeyboardEvent<HTMLElement>) => {
    const { key } = ev;

    if (key === 'Enter') {
      void handleApprove();
    }
  };

  const auctionIdString = auction?.id ? `Auction ID ${auction.id}` : '';

  useEffect(() => {
    setValue('quote', quote?.value?.toString());
    setValue('comment', quote?.comment?.comment);
  }, [quote]);

  return (
    <Modal
      title={`Review ${issuer?.name ?? 'Issuer'} ${auctionIdString}`}
      footer={
        <Stack direction="row" justify="space-between">
          <Stack direction="row" spacing={2}>
            {INSTANT_WIN_BUTTON_WHITE_LIST.includes(status) ? (
              <Button type="button" variant="outlined" color="primary" onClick={handleInstantWinner}>
                Instant Win
              </Button>
            ) : null}
            {PASS_BUTTON_WHITE_LIST.includes(status) ? (
              <Button type="button" variant="outlined" color="primary" onClick={handlePass}>
                Pass
              </Button>
            ) : null}
          </Stack>
          <Button type="button" variant="contained" color="primary" onClick={handleApprove}>
            Update and Approve
          </Button>
        </Stack>
      }
      open={open}
      onClose={handleClose}
      ContentProps={{ sx: { pt: 3, px: 5 } }}
    >
      <form>
        <Stack direction="column" spacing={3}>
          <TextField
            inputRef={quoteRef}
            {...quoteFieldProps}
            onKeyDown={handleKeyDown}
            label="Issuer Quote"
            fullWidth
            slotProps={{ input: { endAdornment: '%' } }}
            helperText="Edit or approve this issuer quote."
          />
          <TextField
            inputRef={commentRef}
            {...commentFieldProps}
            label="Issuer Comment"
            multiline
            rows={3}
            fullWidth
            helperText="Edit or approve this issuer comment"
          />
        </Stack>
      </form>
    </Modal>
  );
};
