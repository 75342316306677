import { PortfolioEventSortDropdownValueEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { downloadFile } from '@halo-common/utils';
import { downloadPortfolioEventsExcel } from '@halo-data-sources/clients';
import { PortfolioLifecycleMapper } from '@halo-data-sources/mappers';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

const pendingMsg = translations.portfolio.lifecycle.generatePortfolioEventsReportPendingMessage;
const errorMsg = translations.portfolio.lifecycle.generatePortfolioEventsReportErrorMessage;
const successMsg = translations.portfolio.lifecycle.generatePortfolioEventsReportSuccessMessage;

export type GeneratePortfolioEventsReportOptions = {
  accountId?: number;
  householdId?: number;
  fileName: string;
  synced?: boolean;
  startDate: string;
  endDate: string;
  sortField: PortfolioEventSortDropdownValueEnum;
};

const generatePortfolioEventsReportFn = async (options: GeneratePortfolioEventsReportOptions) => {
  const sort = [PortfolioLifecycleMapper.toApiLifecycleSortModel(options.sortField)];

  const result = await downloadPortfolioEventsExcel({
    account_selector: {
      account_ids: options.accountId ? [options.accountId] : undefined,
      advisee_ids: options.householdId ? [options.householdId] : undefined,
      synced: options.synced ?? null,
    },
    filename: options.fileName,
    start_date: options.startDate,
    end_date: options.endDate,
    sort: sort,
  });

  const fileName = result.filename;
  const downloadFilePath = result.tmplink;

  downloadFile({ fileName, downloadFilePath });
};

export const useGeneratePortfolioEventsReportMutation = (): UseMutationResult<
  void,
  Error,
  GeneratePortfolioEventsReportOptions
> => {
  const { enqueueSuccessEvent, enqueuePendingEvent, enqueueWarningEvent, closeSnackbar } = useSnackbar();

  return useMutation<void, Error, GeneratePortfolioEventsReportOptions>({
    mutationFn: generatePortfolioEventsReportFn,
    onMutate: () => {
      enqueuePendingEvent({ persist: true, message: pendingMsg });
    },
    onError: () => {
      closeSnackbar();
      enqueueWarningEvent({ message: errorMsg });
    },
    onSuccess: () => {
      closeSnackbar();
      enqueueSuccessEvent({ message: successMsg });
    },
  });
};
