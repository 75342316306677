import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type AllocationClientQueryKeys = {
  all: QueryKeyFactoryFn;
  adminInfinite: QueryKeyFactoryFn;
  adminByStatus: QueryKeyFactoryFn;
};

export const AllocationClientQueryKeyFactory: AllocationClientQueryKeys = {
  all: () => ['allocations'],
  adminInfinite: (filters) => [...AllocationClientQueryKeyFactory.all(), 'admin', hash(filters), 'infinite'],
  adminByStatus: (filters) => [...AllocationClientQueryKeyFactory.all(), 'admin', hash(filters), 'status'],
};
