import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';

export const useBreakpointView = (breakpoint: Breakpoint, direction?: 'down' | 'up'): boolean => {
  /* Usage: 
        This hook returns a boolean when the viewport width is beneath the specified breakpoint.
        useBreakpointView("md") returns true when the viewport is xs or sm. 
        i.e., "True if smaller than medium."
        Bang the function's return in order to get "True if at medium or wider."
  */
  const theme = useTheme();
  const breakpointFunction = direction ? theme.breakpoints[direction] : theme.breakpoints.down;
  return useMediaQuery(breakpointFunction(breakpoint));
};
