import { atom } from 'jotai';

export type AnchorElMap = {
  [key: string | number]: HTMLElement | null;
};

const anchorElMapAtom = atom<AnchorElMap>({});

export const anchorElAtom = atom(
  (get) => get(anchorElMapAtom),
  (get, set, newMap: AnchorElMap) => {
    const previous = get(anchorElMapAtom);
    const updatedMap = { ...previous, ...newMap };
    set(anchorElMapAtom, updatedMap);
  },
);
