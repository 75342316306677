import { debouncedAtom } from '@halo-atoms/debounce';
import { EducationModuleGroup, ModuleModel, ModuleStepModel } from '@halo-common/models';
import { CourseModeEnum } from '@halo-modules/app';
import { IconographyProps } from '@halodomination/halo-fe-common';
import { atom } from 'jotai';

export type StepAction = {
  text: string;
  icon: IconographyProps['iconName'];
};

export type CourseStepDetails = {
  primaryAction: StepAction;
  secondaryAction: StepAction;
};

export type CourseProgress = {
  isStart: boolean;
  mode: number;
  module: ModuleModel | null;
  group: EducationModuleGroup | null;
  step: ModuleStepModel | null;
};

export type EducationCompletion = { overallCompletion: number | null };

export type CourseManagerResult = CourseProgress & CourseStepDetails & EducationCompletion;

export type CourseManagerAction = {
  progress?: Partial<CourseProgress> & Partial<EducationCompletion>;
  type: 'next' | 'previous' | 'set' | 'reset';
};

export const selectedGlossaryLetterAtom = atom<number>(0);

const { debouncedValueAtom, currentValueAtom } = debouncedAtom<string>('');
export const currentGlossarySearchAtom = currentValueAtom;
export const debouncedGlossarySearchAtom = debouncedValueAtom;

const _educationCompletion = atom<number | null>(null);

const DEFAULT_COURSE_PROGRESS = {
  mode: 0,
  isStart: true,
  module: null,
  group: null,
  step: null,
};
const _courseProgressAtom = atom<CourseProgress>(DEFAULT_COURSE_PROGRESS);

const DEFAULT_COURSE_STEP_DETAILS: CourseStepDetails = {
  primaryAction: { text: '', icon: 'arrow-right' },
  secondaryAction: { text: '', icon: 'left-right' },
};
const _courseStepDetails = atom<CourseStepDetails>(DEFAULT_COURSE_STEP_DETAILS);

export const courseManagerAtom = atom(
  (get): CourseManagerResult => ({
    ...get(_courseProgressAtom),
    ...get(_courseStepDetails),
    overallCompletion: get(_educationCompletion),
  }),
  (get, set, { progress, type }: CourseManagerAction) => {
    const currentProgress = get(_courseProgressAtom);

    const { step, group, module, isStart } = currentProgress;
    const updatedProgress = { ...currentProgress, finished: false, ...progress };

    if (type === 'next') {
      const currentNextStepPosition = isStart ? 0 : step ? step.position + 1 : -1;
      const currentNextStep = module?.steps?.[currentNextStepPosition];

      const currentNextModulePosition = module ? module.position + 1 : -1;
      const currentNextModule = group?.modules?.[currentNextModulePosition];

      if (currentNextStep) {
        updatedProgress.step = currentNextStep;
      } else if (currentNextModule) {
        const nextModuleSteps = currentNextModule.steps ?? [];
        const updatedCurrentStep = nextModuleSteps[0] ?? null;
        updatedProgress.step = updatedCurrentStep;
        updatedProgress.module = currentNextModule;
      } else {
        updatedProgress.module = DEFAULT_COURSE_PROGRESS.module;
        updatedProgress.step = DEFAULT_COURSE_PROGRESS.step;
      }

      const updatedCurrentStep = updatedProgress.step;
      updatedProgress.mode = !updatedCurrentStep
        ? CourseModeEnum.Finish
        : updatedCurrentStep?.type === 'quiz'
          ? CourseModeEnum.QuizManager
          : CourseModeEnum.Explorer;

      updatedProgress.isStart = false;

      set(_courseProgressAtom, updatedProgress);
    } else if (type === 'previous') {
      const currentPrevStepPosition = step ? step.position - 1 : -1;
      const currentPrevStep = module?.steps?.[currentPrevStepPosition];

      const currentPrevModulePosition = module ? module.position - 1 : -1;
      const currentPrevModule = group?.modules?.[currentPrevModulePosition];

      if (currentPrevStep) {
        updatedProgress.step = currentPrevStep;
      } else if (currentPrevModule) {
        const prevModuleSteps = currentPrevModule.steps ?? [];
        const updatedCurrentStep = prevModuleSteps[prevModuleSteps.length - 1] ?? null;
        updatedProgress.step = updatedCurrentStep;
        updatedProgress.module = currentPrevModule;
      }

      const updatedCurrentStep = updatedProgress.step;
      updatedProgress.mode = !updatedCurrentStep
        ? CourseModeEnum.Finish
        : updatedCurrentStep?.type === 'quiz'
          ? CourseModeEnum.QuizManager
          : CourseModeEnum.Explorer;

      updatedProgress.isStart = false;

      set(_courseProgressAtom, updatedProgress);
    } else if (type === 'set') {
      set(_courseProgressAtom, updatedProgress);
    } else {
      set(_courseProgressAtom, DEFAULT_COURSE_PROGRESS);
      set(_courseStepDetails, DEFAULT_COURSE_STEP_DETAILS);
    }

    if (updatedProgress.overallCompletion) {
      set(_educationCompletion, updatedProgress.overallCompletion);
    }

    if (type !== 'reset') {
      const isStart = updatedProgress.isStart;
      const updatedStep = updatedProgress.step;
      const updatedModule = updatedProgress.module;

      const updatedNextModulePosition = updatedModule ? updatedModule.position + 1 : -1;
      const updatedNextModule = group?.modules?.[updatedNextModulePosition];

      const updatedPrevModulePosition = updatedModule ? updatedModule.position - 1 : -1;
      const updatedPrevModule = group?.modules?.[updatedPrevModulePosition];

      const updatedNextStepPosition = updatedStep ? updatedStep.position + 1 : -1;
      const updatedNextStep = updatedModule?.steps?.[updatedNextStepPosition] ?? updatedNextModule?.steps?.[0];

      const updatedPrevStepPosition = updatedStep ? updatedStep.position - 1 : -1;
      const updatedPrevStep =
        updatedModule?.steps?.[updatedPrevStepPosition] ??
        updatedPrevModule?.steps?.[updatedPrevModule?.steps?.length - 1];

      const isCourseEnd = !isStart && !updatedNextModule && !updatedNextStep;
      const primaryFinishText = isCourseEnd ? 'Finish Course' : undefined;

      const isNextStepQuiz = updatedNextStep?.type === 'quiz';
      const primaryActionText = isNextStepQuiz ? 'Start Quiz' : 'Next Topic';
      const primaryIcon = isNextStepQuiz ? 'pencil' : 'arrow-right';
      const primaryText = primaryFinishText ?? primaryActionText;

      const isPreviousStepQuiz = updatedPrevStep?.type === 'quiz';
      const secondaryText = isPreviousStepQuiz ? 'Previous Quiz' : 'Previous Topic';

      const updatedStepDetails: CourseStepDetails = {
        primaryAction: { text: primaryText, icon: primaryIcon },
        secondaryAction: { text: secondaryText, icon: 'arrow-left' },
      };

      set(_courseStepDetails, updatedStepDetails);
    }
  },
);
