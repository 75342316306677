import { useEffect } from 'react';

import { ForgotPasswordForm, OnboardingLayout } from '@halo-modules/app';
import { OnboardingActions } from '@halo-stores/Onboarding';
import { PageComponent, PageMeta } from '@halodomination/halo-fe-common';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

const ForgotPasswordPage: PageComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(OnboardingActions.resetStatus());
  }, []);

  return (
    <OnboardingLayout
      pageName={ForgotPasswordPageMeta.pageName}
      welcomeMessage="We can help recover your password."
      listVariant="forgot"
    >
      <Typography variant="h1">
        <ForgotPasswordForm />
      </Typography>
    </OnboardingLayout>
  );
};

export const ForgotPasswordPageMeta: PageMeta = {
  pageName: 'ForgotPassword',
  route: '/app/onboarding/forgot-password',
  iconName: 'question-circle',
};

ForgotPasswordPage.pageName = ForgotPasswordPageMeta.pageName;
ForgotPasswordPage.route = ForgotPasswordPageMeta.route;
ForgotPasswordPage.iconName = ForgotPasswordPageMeta.iconName;

export default ForgotPasswordPage;
