import { UserRelationshipManagerModel } from '@halo-common/models';
import { searchRelationshipManagers } from '@halo-data-sources/clients';
import { UserQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

const searchRelationshipManagersFn = async (query?: string) => {
  const params = query ? new URLSearchParams({ query }).toString() : '';

  const response = await searchRelationshipManagers(params);

  return Object.values(response.objects).map(
    (model): UserRelationshipManagerModel => ({
      id: model.id,
      name: model.name,
      email: model.email,
      phone: model.phone,
    }),
  );
};

export const useRelationshipManagerSearchQuery = (
  query?: string,
): UseQueryResult<Array<UserRelationshipManagerModel>, Error> =>
  useQuery<Array<UserRelationshipManagerModel>, Error>({
    queryKey: UserQueryKeyFactory.search(query),
    queryFn: () => searchRelationshipManagersFn(query),
    placeholderData: keepPreviousData,
  });
