import { IssuerModel } from '@halo-common/models';
import { fetchAllowedIssuers } from '@halo-data-sources/clients';
import { ApiIssuerMapper } from '@halo-data-sources/mappers';
import { IssuerQueryKeyFactory } from '@halo-data-sources/queries';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

const getIssuersQueryFn = async (includeCds?: boolean) => {
  const params = { include_cds: includeCds ? 'true' : 'false' };
  const searchParams = new URLSearchParams(params);
  const data = await fetchAllowedIssuers(searchParams.toString());
  return data.issuers.map((issuer) => ApiIssuerMapper.toIssuerModel(issuer));
};

export const useIssuersQuery = (includeCds?: boolean): UseQueryResult<Array<IssuerModel>, Error> =>
  useQuery<Array<IssuerModel>, Error>({
    queryKey: IssuerQueryKeyFactory.list(includeCds),
    queryFn: () => getIssuersQueryFn(includeCds),
  });
