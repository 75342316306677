import { ReactElement } from 'react';

import { LexicalTypography } from '@halo-common/components';
import { Stack } from '@halodomination/halo-fe-common';

export type TicketDetailProps = {
  label: string;
  value?: string;
};

export const TicketDetail = ({ label, value = '-' }: TicketDetailProps): ReactElement => (
  <Stack direction="column">
    <LexicalTypography variant="caption">{label}</LexicalTypography>
    <LexicalTypography fontWeight="bold">{value}</LexicalTypography>
  </Stack>
);
