import { AuctionStatusEnum } from '@halo-common/enums';
import { AuctionAdminModel, AuctionsByStatusModel } from '@halo-common/models';
import {
  acceptPrelimTerms,
  approveIssuerQuoteCommentForOrderAuction,
  approveOrderAuction,
  cancelOrderAuction,
  chooseInstantWinnerOrderAuction,
  chooseWinnerOrderAuction,
  createAuctionTackOn,
  createIssuerQuoteCommentForOrderAuction,
  createIssuerQuoteForOrderAuction,
  declineIssuerQuoteCommentForOrderAuction,
  deleteAuctionTackOn,
  editAuctionTackOn,
  getAuction,
  getOrderAuctions,
  opsApproveOrderAuction,
  rejectPrelimTerms,
  sendOpsApprovalTestEmail,
  updateOrderAuction,
  uploadFinalPrelimTerms,
  uploadPrelimTerms,
} from '@halo-stores/Orders/OrdersThunks';
import { RejectedAction } from '@halo-stores/types';
import { createSlice } from '@reduxjs/toolkit';

import { OrdersState, OrderStatusEnum } from './OrdersModel';

const INITIAL_STATE: OrdersState = {
  auctions: {
    [AuctionStatusEnum.Canceled]: [],
    [AuctionStatusEnum.ChooseWinner]: [],
    [AuctionStatusEnum.InAuction]: [],
    [AuctionStatusEnum.NotWin]: [],
    [AuctionStatusEnum.OpsApproval]: [],
    [AuctionStatusEnum.PreApproval]: [],
    [AuctionStatusEnum.Purchased]: [],
    [AuctionStatusEnum.TermsAccepted]: [],
    [AuctionStatusEnum.TermsPending]: [],
    [AuctionStatusEnum.TermsReviewed]: [],
  },
  selectedQuote: null,
  selectedAuction: null,
  selectedIssuer: null,
  status: 'idle',
  totalAuctions: 0,
};

const STATUS_MAP = {
  [AuctionStatusEnum.ChooseWinner]: OrderStatusEnum.showChooseWinnerModal,
  [AuctionStatusEnum.OpsApproval]: OrderStatusEnum.showOpsApprovalModal,
  [AuctionStatusEnum.PreApproval]: OrderStatusEnum.showPreApprovalModal,
  [AuctionStatusEnum.TermsAccepted]: OrderStatusEnum.showTermsAcceptedModal,
  [AuctionStatusEnum.TermsPending]: OrderStatusEnum.showTermsPendingModal,
  [AuctionStatusEnum.TermsReviewed]: OrderStatusEnum.showTermsReviewedModal,
} as { [key: string]: OrderStatusEnum };

const updateAuctionStatusInMap = (
  auctionsMap: AuctionsByStatusModel,
  fromStatus: AuctionStatusEnum,
  toStatus: AuctionStatusEnum,
  auction?: AuctionAdminModel,
) => {
  const auctionsByStatusCopy = [...(auctionsMap[fromStatus] ?? [])];
  const filteredPreApproveAuctions = auctionsByStatusCopy.filter((nextAuction) => nextAuction.id !== auction?.id);

  const updatedOpsApproveAuctions = [...(auctionsMap[toStatus] ?? [])];

  if (auction) updatedOpsApproveAuctions.unshift(auction);

  return {
    ...auctionsMap,
    [fromStatus]: filteredPreApproveAuctions,
    [toStatus]: updatedOpsApproveAuctions,
  };
};

const updateAuctionInMap = (auctionsMap: AuctionsByStatusModel, auction: AuctionAdminModel) => {
  const { status } = auction;
  const auctionsByStatusCopy = [...auctionsMap[status]];
  const auctionIndex = auctionsByStatusCopy.findIndex((nextAuction) => nextAuction.id === auction?.id);

  auctionsByStatusCopy.splice(auctionIndex, 1, auction);

  return {
    ...auctionsMap,
    [status]: auctionsByStatusCopy,
  };
};

export const OrdersDuck = createSlice({
  name: 'Orders',
  initialState: INITIAL_STATE,
  reducers: {
    resetSelected(state) {
      return {
        ...state,
        selectedAuction: null,
        selectedQuote: null,
        selectedIssuer: null,
        status: OrderStatusEnum.idle,
      };
    },
    showAuctionDetails(state, action?) {
      return {
        ...state,
        selectedAuction: action?.payload.auction,
        status: OrderStatusEnum.showDetails,
      };
    },
    showCreateTackOn(state, action?) {
      return {
        ...state,
        selectedAuction: action?.payload.auction,
        status: OrderStatusEnum.showCreateTackOnModal,
      };
    },
    showEditTackOn(state, action?) {
      return {
        ...state,
        selectedAuction: action?.payload.auction,
        status: OrderStatusEnum.showEditTackOnModal,
      };
    },
    selectAuction(state, action?) {
      const auction = action?.payload?.auction;
      const status = STATUS_MAP[auction?.status];

      return {
        ...state,
        selectedAuction: auction,
        status,
      };
    },
    selectQuote(state, action?) {
      const auction = action?.payload?.auction;
      const quote = action?.payload?.quote ?? null;
      const issuer = action?.payload?.issuer ?? null;

      return {
        ...state,
        selectedAuction: auction,
        selectedQuote: quote,
        selectedIssuer: issuer,
        status: OrderStatusEnum.showIssuerComment,
      };
    },
    update(state, action) {
      return {
        ...state,
        ...action?.payload,
      };
    },
    resetError(state) {
      return {
        ...state,
        error: INITIAL_STATE.error,
      };
    },
    resetStatus(state) {
      return {
        ...state,
        status: INITIAL_STATE.status,
      };
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- loading States --- */
      .addCase(getOrderAuctions.pending, (state) => {
        state.status = 'requesting';
      })
      .addCase(updateOrderAuction.pending, (state) => {
        state.status = 'tableRequest';
      })
      .addCase(sendOpsApprovalTestEmail.pending, (state) => {
        state.status = 'modalRequest';
      })
      .addCase(approveOrderAuction.pending, (state) => {
        state.status = 'modalRequest';
      })
      .addCase(opsApproveOrderAuction.pending, (state) => {
        state.status = 'modalRequest';
      })
      .addCase(chooseWinnerOrderAuction.pending, (state) => {
        state.status = 'modalRequest';
      })
      .addCase(cancelOrderAuction.pending, (state) => {
        state.status = 'modalRequest';
      })
      .addCase(rejectPrelimTerms.pending, (state) => {
        state.status = 'modalRequest';
      })
      .addCase(acceptPrelimTerms.pending, (state) => {
        state.status = 'modalRequest';
      })
      .addCase(uploadPrelimTerms.pending, (state) => {
        state.status = 'modalRequest';
      })
      .addCase(uploadFinalPrelimTerms.pending, (state) => {
        state.status = 'modalRequest';
      })
      .addCase(chooseInstantWinnerOrderAuction.pending, (state) => {
        state.status = 'modalRequest';
      })
      .addCase(approveIssuerQuoteCommentForOrderAuction.pending, (state) => {
        state.status = 'modalRequest';
      })
      .addCase(createIssuerQuoteCommentForOrderAuction.pending, (state) => {
        state.status = 'modalRequest';
      })
      .addCase(declineIssuerQuoteCommentForOrderAuction.pending, (state) => {
        state.status = 'modalRequest';
      })
      .addCase(createIssuerQuoteForOrderAuction.pending, (state) => {
        state.status = 'modalRequest';
      })
      .addCase(getAuction.pending, (state) => {
        state.status = OrderStatusEnum.requestingFetchAuction;
      })
      /* --- Success States --- */
      .addCase(updateOrderAuction.fulfilled, (state, action) => {
        state.status = OrderStatusEnum.successEditOrderAuction;
        state.error = INITIAL_STATE.error;

        const data = action?.payload?.data;
        const status = action?.payload?.status;
        const id = action?.payload?.id;

        const auctions = [...state.auctions[status]];
        const index = auctions.findIndex((nextAuction) => nextAuction.id === id);

        auctions.splice(index, 1, { ...auctions[index], ...data });

        state.auctions = {
          ...state.auctions,
          [status]: auctions,
        };
      })
      .addCase(getOrderAuctions.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = INITIAL_STATE.error;

        state.totalAuctions = action?.payload.auctions.length;
        state.auctions = action?.payload.auctions.reduce((map, auction) => {
          if (map[auction.status]) map[auction.status].push(auction);
          else map[auction.status] = [auction];

          return map;
        }, {} as AuctionsByStatusModel);
      })
      .addCase(approveIssuerQuoteCommentForOrderAuction.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = INITIAL_STATE.error;

        state.auctions = updateAuctionInMap(state.auctions, action?.payload.auction);
      })
      .addCase(createIssuerQuoteForOrderAuction.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = INITIAL_STATE.error;

        state.auctions = updateAuctionInMap(state.auctions, action?.payload.auction);
      })
      .addCase(createIssuerQuoteCommentForOrderAuction.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = INITIAL_STATE.error;

        state.auctions = updateAuctionInMap(state.auctions, action?.payload.auction);
      })
      .addCase(declineIssuerQuoteCommentForOrderAuction.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = INITIAL_STATE.error;

        state.auctions = updateAuctionInMap(state.auctions, action?.payload.auction);
      })
      .addCase(sendOpsApprovalTestEmail.fulfilled, (state) => {
        state.status = 'success';
        state.error = INITIAL_STATE.error;
      })
      .addCase(approveOrderAuction.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = INITIAL_STATE.error;

        state.auctions = updateAuctionStatusInMap(
          state.auctions,
          AuctionStatusEnum.PreApproval,
          AuctionStatusEnum.OpsApproval,
          action?.payload.auction,
        );
      })
      .addCase(opsApproveOrderAuction.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = INITIAL_STATE.error;

        state.auctions = updateAuctionStatusInMap(
          state.auctions,
          AuctionStatusEnum.OpsApproval,
          AuctionStatusEnum.InAuction,
          action?.payload.auction,
        );
      })
      .addCase(chooseWinnerOrderAuction.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = INITIAL_STATE.error;

        state.auctions = updateAuctionStatusInMap(
          state.auctions,
          AuctionStatusEnum.ChooseWinner,
          AuctionStatusEnum.TermsPending,
          action?.payload.auction,
        );
      })
      .addCase(chooseInstantWinnerOrderAuction.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = INITIAL_STATE.error;

        state.auctions = updateAuctionStatusInMap(
          state.auctions,
          action?.payload.oldStatus,
          AuctionStatusEnum.TermsPending,
          action?.payload.auction,
        );
      })
      .addCase(uploadPrelimTerms.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = INITIAL_STATE.error;

        state.auctions = updateAuctionStatusInMap(
          state.auctions,
          AuctionStatusEnum.TermsPending,
          AuctionStatusEnum.TermsReviewed,
          action?.payload.auction,
        );
      })
      .addCase(acceptPrelimTerms.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = INITIAL_STATE.error;

        state.auctions = updateAuctionStatusInMap(
          state.auctions,
          AuctionStatusEnum.TermsReviewed,
          AuctionStatusEnum.TermsAccepted,
          action?.payload.auction,
        );
      })
      .addCase(uploadFinalPrelimTerms.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = INITIAL_STATE.error;

        state.auctions = updateAuctionStatusInMap(
          state.auctions,
          AuctionStatusEnum.TermsAccepted,
          AuctionStatusEnum.Purchased,
          action?.payload.auction,
        );
      })
      .addCase(cancelOrderAuction.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = INITIAL_STATE.error;

        state.auctions = updateAuctionStatusInMap(
          state.auctions,
          action?.payload.oldStatus,
          AuctionStatusEnum.Canceled,
          action?.payload.auction,
        );
      })
      .addCase(rejectPrelimTerms.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = INITIAL_STATE.error;

        state.auctions = updateAuctionStatusInMap(
          state.auctions,
          AuctionStatusEnum.TermsReviewed,
          AuctionStatusEnum.Canceled,
          action?.payload.auction,
        );
      })
      .addCase(createAuctionTackOn.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = INITIAL_STATE.error;

        state.auctions = updateAuctionInMap(state.auctions, action?.payload.auction);
      })
      .addCase(editAuctionTackOn.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = INITIAL_STATE.error;

        state.auctions = updateAuctionInMap(state.auctions, action?.payload.auction);
      })
      .addCase(deleteAuctionTackOn.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = INITIAL_STATE.error;

        state.auctions = updateAuctionInMap(state.auctions, action?.payload.auction);
      })
      .addCase(getAuction.fulfilled, (state, action) => {
        state.status = OrderStatusEnum.successFetchAuction;
        state.error = INITIAL_STATE.error;

        state.selectedAuction = action?.payload?.auction;
      })
      /* --- Failure States --- */
      .addCase(getAuction.rejected, (state, action) => {
        state.status = OrderStatusEnum.failureFetchAuction;

        state.error = action?.payload?.message;
      })
      .addMatcher(
        (action): action is RejectedAction => action.type.endsWith('/rejected'),
        (state) => {
          state.status = 'failure';
        },
      );
  },
});
