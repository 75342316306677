import { HttpError } from '@halo-data-sources/errors';
import { ApiErrorResponseModel } from '@halo-data-sources/models';
import { WebsocketActions } from '@halo-stores/Websocket';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { HaloAppState } from './types';

export type ThunkError = {
  status?: HttpError['status'];
  message: Error['message'];
  stacktrace?: Error['stack'];
};

export type ThunkConfig<J> = {
  state: HaloAppState;
  rejectValue: J;
};

export type ThunkApi = {
  dispatch: ThunkDispatch<HaloAppState, unknown, AnyAction>;
  getState: () => HaloAppState;
};

export function createThunk<R, P, J = ApiErrorResponseModel>(
  actionId: string,
  actionHandler: (payload: P, actionArgs: ThunkApi) => Promise<R> | R,
  rejectValueGetter?: (payload: P, actionArgs: ThunkApi, error: ThunkError) => ApiErrorResponseModel | J,
): AsyncThunk<R, P, ThunkConfig<ApiErrorResponseModel | J>> {
  return createAsyncThunk<R, P, ThunkConfig<ApiErrorResponseModel | J>>(actionId, async (payload, thunkApi) => {
    const actionArgs = { dispatch: thunkApi.dispatch, getState: thunkApi.getState };

    const isWebsocketBasedAction = actionId.includes('/ws/');

    try {
      const response = await actionHandler(payload, actionArgs);

      if (isWebsocketBasedAction) {
        const timeout = setTimeout(() => {
          thunkApi.dispatch(WebsocketActions.handleTimeout());
          clearTimeout(timeout);
        }, thunkApi.getState().Websocket.timeout.delay);

        thunkApi.dispatch(WebsocketActions.setTimeoutReference({ reference: timeout }));
      }

      return response;
    } catch (e) {
      if (isWebsocketBasedAction) void thunkApi.dispatch(WebsocketActions.handleResolved());

      const error: ThunkError = {
        status: e instanceof HttpError ? e.status : undefined,
        message: e instanceof Error ? e.message : 'Something went wrong.',
        stacktrace: e instanceof Error ? e.stack : undefined,
      };

      const rejectionValue = rejectValueGetter?.(payload, actionArgs, error) ?? error;

      return thunkApi.rejectWithValue(rejectionValue);
    }
  });
}
