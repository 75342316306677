import { ReactElement, useMemo } from 'react';

import { EmptyResultsCard } from '@halo-common/components';
import type { DashboardSetWidgetModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { OpenCustomizeWidgetsModalButton, useDashboardConfigQuery, widgetsMap } from '@halo-modules/app';
import { BrandIcon } from '@halodomination/halo-fe-common';
import { Stack, StackProps } from '@mui/material';

export type WidgetsDashboardProps = Partial<StackProps>;

export const WidgetsDashboard = ({ sx, ...props }: WidgetsDashboardProps): ReactElement => {
  const { widgets, widgetsAreHidable, widgetsAreReorderable, userDashboardPreferences } = useDashboardConfigQuery();

  const filteredWidgets: DashboardSetWidgetModel[] = useMemo(() => {
    let dashboardWidgets = [...widgets];

    const { widgetsVisibility, widgetsPosition } = userDashboardPreferences || {};

    dashboardWidgets = dashboardWidgets.filter(
      (widget) =>
        (widgetsAreHidable ? widgetsVisibility?.[widget.engine] : undefined) ?? widget.visibleByDefault ?? true,
    );

    if (widgetsAreReorderable && widgetsPosition) {
      dashboardWidgets = dashboardWidgets.sort(
        (a, b) => (widgetsPosition[a.engine] ?? a.position ?? 0) - (widgetsPosition[b.engine] ?? b.position ?? 0),
      );
    }

    return dashboardWidgets;
  }, [widgets, userDashboardPreferences, widgetsAreHidable, widgetsAreReorderable]);

  let emptyDescription = translations.dashboard.common.thereAreNoWwidgets;
  if (widgetsAreHidable) emptyDescription += ' ' + translations.dashboard.common.weRecommendTurningOnMoreWidgets;

  const emptyCard = (
    <EmptyResultsCard
      brandIcon={<BrandIcon color="primary" variant="noResults" />}
      title={translations.dashboard.common.itsEmptyInHere}
      description={emptyDescription}
      action={<OpenCustomizeWidgetsModalButton size="medium" color="primary" />}
    />
  );

  const wrapperSx = {
    '&:not(:empty) + div': {
      display: 'none',
    },
    '& .empty-results-card': {
      minHeight: 240,
      '& h2': {
        fontSize: '1.125rem',
      },
    },
    ...sx,
  };

  return (
    <>
      <Stack direction="column" alignItems="stretch" spacing={8} sx={wrapperSx} {...props}>
        {filteredWidgets.map(({ engine, widget }) => {
          const Widget = widgetsMap?.[engine]?.component;
          if (!Widget) return null;
          return <Widget {...(widget || {})} key={engine} />;
        })}
      </Stack>
      {emptyCard}
    </>
  );
};
