import { ReactElement } from 'react';

import { orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { orderTicketCSVUploadModalAtom } from '@halo-atoms/orderTicket';
import { ActionButton } from '@halo-common/components';
import { AuctionDetailsAllocationFields } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { useSubmitAuctionAllocationsMutation } from '@halo-data-sources/mutations';
import {
  Iconography,
  LocalizedButton,
  LocalizedTypography,
  useCurrencyConverter,
} from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';

export type AuctionDetailsAllocationsFooterProps = {
  fields: Array<FieldArrayWithId>;
  addAllocation: () => void;
};

export const AuctionDetailsAllocationsFooter = ({
  fields,
  addAllocation,
}: AuctionDetailsAllocationsFooterProps): ReactElement => {
  const { watch, getValues, handleSubmit, reset } = useFormContext<AuctionDetailsAllocationFields>();

  const { mutate: submitAllocations } = useSubmitAuctionAllocationsMutation({
    onSuccess: () => reset(),
  });

  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);
  const setCSVUploadModal = useSetAtom(orderTicketCSVUploadModalAtom);

  const count = fields.length;
  const allocations = watch('allocations');
  const sum = allocations.reduce((prev: number, field) => {
    const qty = Number(field.quantity);
    if (!isNaN(qty)) return prev + qty;
    return prev;
  }, 0);

  const [totalQuantity] = useCurrencyConverter(sum);

  const plusIcon = <Iconography iconName="plus" color="primary.main" />;

  const importIcon = <Iconography iconName="file-import" color="primary.main" />;

  const handleAddAccount = () => {
    addAllocation();
  };

  const handleCSVUpload = () => {
    setCSVUploadModal(true);
  };

  const handleSubmitAllocations = handleSubmit(() => {
    const allocations = getValues('allocations').map((allocation) => ({
      account: allocation.accountOption?.account?.id,
      quantity: allocation.quantity,
    }));
    submitAllocations({ auctionId: selectedAuctionId, allocations });
  });

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          <LocalizedButton variant="text" color="primary" startIcon={plusIcon} onClick={handleAddAccount}>
            {translations.orderBook.auctions.addAccount}
          </LocalizedButton>
          <LocalizedButton variant="text" color="primary" startIcon={importIcon} onClick={handleCSVUpload}>
            {translations.orderBook.auctions.uploadSpreadsheet}
          </LocalizedButton>
        </Stack>
        <Stack direction="row" spacing={4} justifyContent="end">
          <LocalizedTypography dynamicContent={{ count }}>
            {translations.orderBook.auctions.accountsCount}
          </LocalizedTypography>
          <LocalizedTypography dynamicContent={{ total: totalQuantity }}>
            {translations.orderBook.auctions.total}
          </LocalizedTypography>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <ActionButton variant="contained" color="primary" onClick={handleSubmitAllocations}>
          {translations.common.submit}
        </ActionButton>
      </Stack>
    </Stack>
  );
};
