import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { WatchlistContentFormRadioGroup } from '@halo-modules/app';
import { IconographyProps, LocalizedAlert, LocalizedTextField, Stack } from '@halodomination/halo-fe-common';
import { Controller, useFormContext } from 'react-hook-form';

const errorTextSx = {
  color: 'error.main',
  fontSize: '12px',
  ml: 0,
};

export type WatchlistContentFormValues = {
  watchlistName: string;
  watchlistIcon: IconographyProps['iconName'];
};

export type WatchlistContentFormProps = {
  error?: string;
  loading?: boolean;
};

export const WatchlistContentForm = ({ error, loading = false }: WatchlistContentFormProps): ReactElement => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<WatchlistContentFormValues>();

  const nameError = errors['watchlistName'];
  const hasNameError = Boolean(nameError);
  const nameErrorMessage = nameError?.message;

  const { ref: watchlistNameRef, ...watchlistNameProps } = register('watchlistName');

  const errorAlert = error ? (
    <LocalizedAlert severity="error" variant="filled">
      {error}
    </LocalizedAlert>
  ) : null;

  return (
    <Stack direction="column" spacing={3}>
      {errorAlert}
      <LocalizedTextField
        label={translations.watchlist.common.watchlistName}
        fullWidth
        name="watchlistName"
        onChange={watchlistNameProps.onChange}
        slotProps={{ formHelperText: { sx: errorTextSx } }}
        error={hasNameError}
        size="large"
        inputRef={watchlistNameRef}
        helperText={nameErrorMessage}
      />
      <Controller
        control={control}
        name="watchlistIcon"
        render={({ field }) => <WatchlistContentFormRadioGroup field={field} loading={loading} />}
      />
    </Stack>
  );
};
