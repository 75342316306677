import { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';

import { postMessageService } from '@halo-common/utils';
import { NextRouter, useRouter } from 'next/router';

export type LegacyRouterProviderContext = {
  isReady: boolean;
  path: string;
  query: NextRouter['query'];
  resetQueryParams: () => void;
  removeQueryParam: (queryParam: string) => void;
  addQueryParam: (queryParam: string, value: string) => void;
  combinedQueryParams: (queryParams: URLSearchParams) => void;
  setQueryParams: (queryParams: URLSearchParams) => void;
  getQueryParams: (paramName: string) => string;
  pushRouteQueryParams: (pathname: string, queryParams?: URLSearchParams) => void;
};

export type LegacyRouterProviderOptions = {
  children: ReactNode;
};

export const LegacyRouterContext = createContext<LegacyRouterProviderContext>({
  isReady: false,
  path: '/',
  query: {},
  resetQueryParams: () => {
    // NOOP
  },
  addQueryParam: () => {
    // NOOP
  },
  combinedQueryParams: () => {
    // NOOP
  },
  removeQueryParam: () => {
    // NOOP
  },
  setQueryParams: () => {
    // NOOP
  },
  pushRouteQueryParams: () => {
    // NOOP
  },
  getQueryParams: (paramName) => {
    return paramName;
  },
});

export const useLegacyRouter = (): LegacyRouterProviderContext => {
  return useContext<LegacyRouterProviderContext>(LegacyRouterContext);
};

export const LegacyRouterProvider = ({ children }: LegacyRouterProviderOptions): ReactElement => {
  const router = useRouter();

  const [query, setQuery] = useState<URLSearchParams>(new URLSearchParams(window.parent.location.search));

  const queryString = query.toString();

  const buildSearchMap = (searchParams: URLSearchParams) => {
    let search = {};
    for (const searchParam of searchParams) search = { ...search, [searchParam[0]]: searchParam[1] };
    return search;
  };

  const combinedQueryParams = (queryParams: URLSearchParams) => {
    const currentParams = buildSearchMap(query);
    const newParams = buildSearchMap(queryParams);
    const combinedParams = { ...currentParams, ...newParams };
    setQuery(new URLSearchParams(combinedParams));
  };

  const addQueryParam = (queryParam: string, value: string) => {
    const updatedQueryParams = new URLSearchParams(query);
    updatedQueryParams.append(queryParam, value);
    setQuery(updatedQueryParams);
  };

  const removeQueryParam = (queryParam: string) => {
    const updatedQueryParams = new URLSearchParams(query);
    updatedQueryParams.delete(queryParam);
    setQuery(updatedQueryParams);
  };

  const resetQueryParams = () => {
    void router.push(router.pathname, undefined, { shallow: true });

    postMessageService(window.parent).publish({
      type: 'REPLACE_HISTORY_STATE',
      payload: { path: window.parent.location.pathname, title: '' },
    });

    setQuery(new URLSearchParams());
  };

  const pushRouteQueryParams = (pathname: string, queryParams?: URLSearchParams) => {
    const currentParams = buildSearchMap(query);
    const newParams = queryParams ? buildSearchMap(queryParams) : {};
    const combinedParams = { ...currentParams, ...newParams };
    void router.replace({ pathname, query: combinedParams }, undefined, { shallow: true });
  };

  const setQueryParams = (queryParams: URLSearchParams) => setQuery(queryParams);

  const getQueryParams = (paramName: string): string => {
    const queryStringKeyValue = window.parent.location.search.replace('?', '').split('&');
    const queryObject = queryStringKeyValue.reduce((acc, curr) => {
      const [key, value] = curr.split('=');
      return {
        ...acc,
        [key]: decodeURIComponent(value),
      };
    }, {});
    return queryObject[paramName as keyof typeof queryObject] ?? '';
  };

  useEffect(() => {
    if (router.isReady) {
      const queryParameters = queryString ? `?${queryString}` : '';

      const basePath = router.asPath.split('?')[0];
      const path = `${basePath}${queryParameters}`;
      void router.push(path, undefined, { shallow: true });

      const legacyPath = `${window.parent.location.pathname}${queryParameters}`;
      postMessageService(window.parent).publish({
        type: 'REPLACE_HISTORY_STATE',
        payload: { path: legacyPath, title: '' },
      });
    }
  }, [queryString, router.isReady]);

  useEffect(() => {
    const search = location?.search;
    const parentSearch = window?.parent?.location?.search;
    const shouldUpdate = !search?.includes(parentSearch) && router.isReady;

    if (shouldUpdate) {
      const basePath = router.asPath.split('?')[0];
      const path = `${basePath}${search}`;
      void router.push(path, undefined, { shallow: true });
    }
  }, [router.isReady]);

  const context = {
    isReady: router.isReady,
    path: window.parent.location.pathname,
    query: buildSearchMap(query),
    combinedQueryParams,
    addQueryParam,
    resetQueryParams,
    removeQueryParam,
    setQueryParams,
    getQueryParams,
    pushRouteQueryParams,
  };

  return <LegacyRouterContext.Provider value={context}>{children}</LegacyRouterContext.Provider>;
};
