import { ReactElement } from 'react';

import { executionHubQueryAtom } from '@halo-atoms/executionHub';
import { EmptyResultsCard, InfiniteScroller } from '@halo-common/components';
import { useCalendarOrderInfiniteQuery } from '@halo-data-sources/queries';
import { BrandIcon } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';

import { V2ExecutionHubCalendarCard } from './V2ExecutionHubCalendarCard';
import { V2ExecutionHubLoadingList } from './V2ExecutionHubLoadingList';

export const V2ExecutionHubCalendarList = (): ReactElement => {
  const executionHubQuery = useAtomValue(executionHubQueryAtom);

  const {
    data: calendarOrders,
    hasNextPage,
    fetchNextPage,
    isPending,
    isFetchingNextPage,
    isFetched,
  } = useCalendarOrderInfiniteQuery(executionHubQuery);

  const orders = calendarOrders?.pages.flatMap((page) => page.orders) ?? [];

  const hasMore = Boolean(hasNextPage);
  const noContent = Boolean(!isPending && isFetched && !orders?.length);

  if (isPending) {
    return <V2ExecutionHubLoadingList />;
  }

  if (noContent) {
    const brandIcon = <BrandIcon color="primary" variant="search" />;

    return (
      <EmptyResultsCard
        title="No Results"
        description="Filling Indications of Interest are not available at this time"
        brandIcon={brandIcon}
      />
    );
  }

  return (
    <InfiniteScroller loading={isFetchingNextPage} next={fetchNextPage} hasMore={hasMore}>
      {orders.map(({ calendar, orders, setOrderPrice, totalQuantity }) => (
        <V2ExecutionHubCalendarCard
          key={calendar.id}
          calendar={calendar}
          orders={orders}
          setOrderPrice={setOrderPrice}
          totalQuantity={totalQuantity}
        />
      ))}
    </InfiniteScroller>
  );
};
