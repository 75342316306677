import { ExecutionHubQuery } from '@halo-atoms/executionHub';
import { postCalendarAdminOrderFiltersQuery } from '@halo-data-sources/clients';
import { ExecutionHubMapper } from '@halo-data-sources/mappers';
import { IssuerQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

export type ExecutionHubIssuersQueryResult = Array<{
  id: number;
  name: string;
}>;

const executionHubIssuersQueryFn = async (query: ExecutionHubQuery) => {
  const comparisons = ExecutionHubMapper.toApiStatusComparisonList(query.status);

  const response = await postCalendarAdminOrderFiltersQuery({
    filters: [{ field: 'issuers.id', type: 'OBJECTS', object_fields: ['issuers.name'] }],
    comparisons,
  });

  return (
    response.filter_values[0]?.objects?.map(({ object }) => ({
      name: object['issuers.name'] as string,
      id: object['issuers.id'] as number,
    })) ?? []
  );
};

export const useExecutionHubIssuersQuery = (
  query: ExecutionHubQuery,
): UseQueryResult<ExecutionHubIssuersQueryResult, Error> =>
  useQuery<ExecutionHubIssuersQueryResult, Error>({
    queryKey: IssuerQueryKeyFactory.byExecutionOrder(query),
    queryFn: () => executionHubIssuersQueryFn(query),
    placeholderData: keepPreviousData,
    retry: false,
  });
