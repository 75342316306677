import { ReactElement } from 'react';

import { ModalFormBuilder, ModalFormBuilderProps } from '@halo-common/components';
import { AssetIdentifierEnum, NoteTypeEnum } from '@halo-common/enums';
import type { DocumentBucketModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import type { DateTime } from 'luxon';

import { useTermsheetFormFields } from './hooks';

export type TermsheetFormSchema = { document: File | DocumentBucketModel } & Partial<{
  noteType: NoteTypeEnum;
  productType: string;
  bondCode: string;
  notional: number;
  currencyId: number;
  issueDate: DateTime;
  maturityDate: DateTime;
  description: string | null;
  issuerId: number;
  asset_identifiers: Partial<Record<AssetIdentifierEnum, string>>;
}>;

type OptionalExcept<T, K extends keyof T> = {
  [P in keyof T as P extends K ? P : never]-?: T[P];
} & {
  [P in keyof T as P extends K ? never : P]?: T[P];
};

export type TermsheetModalFormProps = OptionalExcept<
  ModalFormBuilderProps<TermsheetFormSchema>,
  'open' | 'onClose' | 'onSubmit'
>;

export const TermsheetModalForm = ({
  title = translations.documents.termsheets.uploadNewTermsheet,
  ...props
}: TermsheetModalFormProps): ReactElement | null => {
  const { fields } = useTermsheetFormFields();

  return <ModalFormBuilder title={title} fields={fields} {...props} />;
};
