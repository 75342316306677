import { ReactElement } from 'react';

import { auctionStepAtoms } from '@halo-atoms/auctions';
import { useRecordActivityMutation } from '@halo-data-sources/mutations';
import { Iconography, Stack } from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';
import { useSetAtom } from 'jotai';

import { AuctionDateControls } from './AuctionDateControls';

const previousIcon = <Iconography iconName="arrow-left" />;
const nextIcon = <Iconography iconName="arrow-right" />;

export const AuctionDatePage = (): ReactElement => {
  const { mutate: recordActivity } = useRecordActivityMutation();

  const handleNext = useSetAtom(auctionStepAtoms.handleNextAtom);
  const handlePrevious = useSetAtom(auctionStepAtoms.handlePrevAtom);

  const handleActivity = (verb: string) => {
    recordActivity({
      activity: [{ verb }],
      fromIFrame: true,
    });
  };

  const onPrevious = () => {
    handleActivity('set auction notional');
    handlePrevious();
  };

  const onNext = () => {
    handleActivity('select auction issuers');
    handleNext();
  };

  return (
    <Stack direction="column" spacing={15}>
      <AuctionDateControls />
      <Stack direction="row" justify="space-between">
        <Button onClick={onPrevious} startIcon={previousIcon}>
          Change notional
        </Button>
        <Button onClick={onNext} variant="outlined" endIcon={nextIcon}>
          Next: Select Issuers
        </Button>
      </Stack>
    </Stack>
  );
};
