import { DateRange } from '@mui/x-date-pickers-pro';
import { atom } from 'jotai';
import { DateTime } from 'luxon';

export type HistoricalPerformanceFormFields = {
  underlyings: Array<string>;
  date: DateRange<DateTime> | null;
  protectionLevel: number | null;
};

export const historicalPerformanceFormFieldsAtom = atom<HistoricalPerformanceFormFields | null>(null);
