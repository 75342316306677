import { ReactElement, useState } from 'react';

import { executionHubFillModalAtom } from '@halo-atoms/executionHub';
import { ActionButton } from '@halo-common/components';
import { useCalendarNameQuery } from '@halo-data-sources/queries';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useFormContext } from 'react-hook-form';

import { V2ExecutionHubNoteTermModal } from '../V2ExecutionHubNoteTermModal';

const containerSx = {
  pb: 1,
};

const editTermsSx = {
  minWidth: 100,
};

export const V2ExecutionHubFillModalConfirmNote = (): ReactElement => {
  const formMethods = useFormContext();

  const [editTermsModalOpen, setEditTermsModalOpen] = useState(false);

  const modalState = useAtomValue(executionHubFillModalAtom);

  const calendar = modalState.calendar;
  const calendarId = calendar?.id;

  const { data: confirmNote, isPending } = useCalendarNameQuery(calendarId);

  if (isPending) {
    return (
      <Stack sx={containerSx} direction="row" spacing={2} justify="space-between">
        <Stack direction="column" spacing={1}>
          <Skeleton variant="rounded" height={14} width={66} />
          <Skeleton variant="rounded" height={22} width="90%" />
        </Stack>
        <Skeleton variant="rounded" height={32} width={87} />
      </Stack>
    );
  }

  const noteTerms = confirmNote ?? formMethods.watch('note');

  const handleOpenEditTermsModal = () => {
    setEditTermsModalOpen(true);
  };

  const handleCloseEditTermsModal = () => {
    setEditTermsModalOpen(false);
  };

  return (
    <>
      <Stack sx={containerSx} direction="row" spacing={2} justify="space-between">
        <Stack direction="column">
          <LocalizedTypography variant="caption">Note Terms</LocalizedTypography>
          <LocalizedTypography variant="subtitle1">{noteTerms}</LocalizedTypography>
        </Stack>
        <ActionButton sx={editTermsSx} variant="outlined" size="small" onClick={handleOpenEditTermsModal}>
          Edit Terms
        </ActionButton>
      </Stack>
      <V2ExecutionHubNoteTermModal
        open={editTermsModalOpen}
        onClose={handleCloseEditTermsModal}
        fullEdit={!confirmNote}
      />
    </>
  );
};
