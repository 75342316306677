import { ReactElement } from 'react';

import { portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import { PortfolioCashflowBarChart } from '@halo-common/components';
import { PortfolioPeriodEnum } from '@halo-common/enums';
import { useLifecycleEventChartQuery } from '@halo-data-sources/queries';
import { useIsAccountTypeAheadSyncedOption } from '@halo-modules/app';
import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';

const period = PortfolioPeriodEnum.Monthly;

export type PortfolioLifecycleCashflowChartProps = {
  loading?: boolean;
  activeCategory?: string | string[];
};

export const PortfolioLifecycleCashflowChart = ({
  loading,
  activeCategory,
}: PortfolioLifecycleCashflowChartProps): ReactElement => {
  const { filters } = useAtomValue(portfolioPositionsManagerAtom);

  const { accountOption, currency } = filters;
  const accountId = accountOption?.account?.id;
  const householdId = accountOption?.household?.id;

  const isSyncedOption = useIsAccountTypeAheadSyncedOption(accountOption?.action);

  const accountIds = accountId ? [accountId] : [];
  const currencyId = currency?.id ?? 0;

  const startDate = DateTime.now();
  const endDate = startDate.plus({ months: 4 }).endOf('month');

  const { data: chartData, isFetching } = useLifecycleEventChartQuery({
    currencyId,
    householdId,
    accountIds,
    synced: isSyncedOption,
    frequency: period,
    startDate: startDate.toFormat('yyyy-LL-dd'),
    endDate: endDate.toFormat('yyyy-LL-dd'),
  });

  const isLoading = loading || isFetching;

  return (
    <PortfolioCashflowBarChart
      activeCategory={activeCategory}
      chartData={chartData}
      loading={isLoading}
      currency={currency}
      period={period}
      height="max(100%, 500px)"
    />
  );
};
