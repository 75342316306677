import { ContactHaloSchema } from '@halo-common/schemas';
import { toStringOrBlob } from '@halo-common/utils';
import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiRegisterResponseModel,
  ApiDisclaimerResponseModel,
  ApiRegisterRequestModel,
  ApiUserOnboardingSettingModel,
  ApiOnboardingConfigurationPutModel,
} from '@halo-data-sources/models';
import { DisclaimerAcceptanceSchema, LinkExpiredSchema, RegisterFormSchema } from '@halo-modules/app';

const ONBOARD_ROUTE = '/onboard';
const PRETRADE_ONBOARDING_ROUTE = '/pretrade/onboarding';
const DISCLAIMERS_ROUTE = '/common/user/disclaimers';
const DISCLAIMER_ROUTE = '/common/user/disclaimer';
const PRE_TRADE_ROUTE = '/pretrade';

export const registerUserV2 = async (registerRequest: ApiRegisterRequestModel): Promise<ApiRegisterResponseModel> => {
  const clientConfiguration = await getClientConfiguration(PRETRADE_ONBOARDING_ROUTE);

  const response = request<ApiRegisterResponseModel>(`${clientConfiguration.basePath}/user`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...registerRequest,
    }),
  });

  return response;
};

export const registerUser = async (user: RegisterFormSchema): Promise<ApiRegisterResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ONBOARD_ROUTE);

  const bodyDict = {
    _csrf_token: clientConfiguration.csrfToken,
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.emailAddress,
    phone: user.phoneNumber,
    crd_number: user.crdNumber,
    company_name: user.companyName,
    company_role: user.role,
    state: user.state,
    country: user.country,
  };

  const body = Object.entries(bodyDict).reduce((formData, [k, v]) => {
    formData.append(k, String(v));

    return formData;
  }, new FormData());

  const response = request<ApiRegisterResponseModel>(`${clientConfiguration.basePath}/user`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    body,
  });

  return response;
};

export const resendPasswordLink = async (data: LinkExpiredSchema): Promise<void> => {
  const clientConfiguration = await getClientConfiguration(ONBOARD_ROUTE);

  await request(`${clientConfiguration.basePath}/resend-set-password`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      email: data.email,
    }),
  });
};

export const postContactHalo = async (data: ContactHaloSchema): Promise<void> => {
  const clientConfiguration = await getClientConfiguration(PRE_TRADE_ROUTE);

  await request(`${clientConfiguration.basePath}/onboarding/contact`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.emailAddress,
      company_name: data.companyName,
      message: data.message,
    }),
  });
};

export const getOnboardingDisclaimers = async (): Promise<ApiDisclaimerResponseModel> => {
  const clientConfiguration = await getClientConfiguration(DISCLAIMERS_ROUTE);

  const response = await request<ApiDisclaimerResponseModel>(`${clientConfiguration.basePath}`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const sendDisclaimerAcceptance = async (data: DisclaimerAcceptanceSchema): Promise<void> => {
  const clientConfiguration = await getClientConfiguration(DISCLAIMER_ROUTE);

  const formData = new FormData();
  formData.append('_csrf_token', clientConfiguration.csrfToken as string);

  await request(`${clientConfiguration.basePath}/${data.disclaimerId}/acceptance`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    body: formData,
  });
};

export const updateOnboardingSettingSet = async (
  payload: ApiOnboardingConfigurationPutModel,
): Promise<ApiUserOnboardingSettingModel> => {
  const clientConfiguration = await getClientConfiguration(PRETRADE_ONBOARDING_ROUTE);

  return await request<ApiUserOnboardingSettingModel>(`${clientConfiguration.basePath}/configuration`, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });
};

export const updateOnboardingImageSettingSet = async (payload: File): Promise<ApiUserOnboardingSettingModel> => {
  const clientConfiguration = await getClientConfiguration(PRETRADE_ONBOARDING_ROUTE);

  const formData = new FormData();
  formData.append('image', toStringOrBlob(payload));
  formData.append('_csrf_token', clientConfiguration.csrfToken as string);

  return await request<ApiUserOnboardingSettingModel>(`${clientConfiguration.basePath}/configuration/image`, {
    ...clientConfiguration.requestInit,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    body: formData,
  });
};
