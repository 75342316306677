import { useMemo } from 'react';

import { NoteTypeEnum } from '@halo-common/enums';
import type { TermsheetRepoDocModel } from '@halo-common/models';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { DateTime } from 'luxon';

import type { TermsheetFormSchema } from '../../TermsheetModalForm';

export const useTermsheetToDefaultValues = (
  termsheet: TermsheetRepoDocModel | null | undefined,
): Partial<TermsheetFormSchema> | undefined =>
  useMemo(() => {
    if (!termsheet) return undefined;

    const { issueDate, maturityDate, ...rest } = termsheet;

    const defaultValues = cloneDeep(rest) as Partial<TermsheetFormSchema>;

    defaultValues.issueDate = issueDate ? DateTime.fromISO(issueDate) : undefined;
    defaultValues.maturityDate = maturityDate ? DateTime.fromISO(maturityDate) : undefined;

    defaultValues.noteType = termsheet.noteType?.toLowerCase() as NoteTypeEnum;

    termsheet.assetIdentifiers?.forEach((identifier) => {
      if (!identifier.value) return;
      defaultValues.asset_identifiers ??= {};
      defaultValues.asset_identifiers[identifier.type] = identifier.value;
    });

    return omitBy(defaultValues, isNil);
  }, [termsheet]);
