import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type EducationQueryKeys = {
  all: QueryKeyFactoryFn;
  moduleGroups: QueryKeyFactoryFn;
  glossary: QueryKeyFactoryFn;
  quizAttempts: QueryKeyFactoryFn;
};

export const EducationQueryKeyFactory: EducationQueryKeys = {
  all: () => ['education'],
  moduleGroups: () => [...EducationQueryKeyFactory.all(), 'module', 'groups'],
  glossary: () => [...EducationQueryKeyFactory.all(), 'glossary'],
  quizAttempts: (id?: number) => [...EducationQueryKeyFactory.all(), 'quiz', 'attempts', id],
};
