import { ReactElement, ReactNode, useMemo } from 'react';

import { useWatch, FieldValues, FieldPath } from 'react-hook-form';

export type ConditionalFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  field: TFieldName;
  value?: string | number | ((fieldValue: unknown) => boolean) | null | undefined;
  children: ReactNode;
  fallback?: ReactNode;
};

export const ConditionalField = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  field,
  value: targetValue,
  children,
  fallback = null,
}: ConditionalFieldProps<TFieldValues, TFieldName>): ReactElement | null => {
  const fieldValue = useWatch<TFieldValues>({ name: field });

  const hasMatchingValue = useMemo(() => {
    if (targetValue === undefined) return !!fieldValue;
    if (typeof targetValue === 'function') return targetValue(fieldValue);
    return fieldValue === targetValue;
  }, [fieldValue, targetValue]);

  if (!hasMatchingValue) return fallback as ReactElement;

  return children as ReactElement;
};
