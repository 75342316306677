import { ReactElement } from 'react';

import {
  CapacityField,
  CusipField,
  JanneyIssuerField,
  JanneyStatusField,
  OrderAccountField,
  OrderNumberField,
  TimeFrameField,
  UserNameField,
} from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';

export const OrderBookJanneyFilters = (): ReactElement => (
  <Stack direction="column" spacing={2}>
    <Stack direction="row" spacing={1} xs={3} wrap="wrap">
      <OrderNumberField />
      <OrderAccountField disableHouseholds={false} />
      <CapacityField />
      <UserNameField />
      <CusipField />
      <JanneyIssuerField />
      <JanneyStatusField />
      <TimeFrameField />
    </Stack>
  </Stack>
);
