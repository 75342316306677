import { ReactElement, ReactNode } from 'react';

import { useUserInfoQuery, useUserWebHooksQuery } from '@halo-data-sources/queries';
import { publicPath } from '@halodomination/halo-fe-common';
import Head from 'next/head';

export type UserProviderProps = {
  children: ReactNode;
};

// TODO: Add context here for authentication when we go full react, and make this the auth provider.
export const UserProvider = ({ children }: UserProviderProps): ReactElement => {
  const { data: user } = useUserInfoQuery();

  useUserWebHooksQuery();

  const whiteLabel = user?.whiteLabel;
  const appDescription = user?.configuration.appDescription;

  const titleTag = whiteLabel?.titleTag ?? 'Halo Investing';
  const faviconLink = whiteLabel?.favicon ?? '/static/img/favicon.ico';

  return (
    <>
      <Head>
        <link href={publicPath('/site.webmanifest')} rel="manifest" />
        <meta content={appDescription} name="description" />
        <link href={faviconLink} rel="icon" sizes="32x32" type="image/png" />
        <title>{titleTag}</title>
      </Head>
      {children}
    </>
  );
};
