import { useMemo } from 'react';

import { useUserInfoQuery } from '@halo-data-sources/queries';
import type { PortfolioPositionAggregationQueryResult } from '@halo-data-sources/queries';
import { useT } from '@transifex/react';

export type PieChardData = {
  name: string;
  value: number;
};

export const useAggregationToPieChartData = (
  aggregations: PortfolioPositionAggregationQueryResult['aggregations'] | null | undefined,
  translateLabels = false,
): PieChardData[] => {
  const { data: user } = useUserInfoQuery();
  const translator = useT();

  const lexicon = user?.lexicon ?? {};

  const pieChartData: PieChardData[] = useMemo(
    () =>
      aggregations?.map((aggregation) => {
        const name = translateLabels
          ? translator(aggregation.name)
          : lexicon[aggregation.name] || aggregation.name || 'N/A';

        const value = aggregation.totalNotional || 0;

        return {
          ...aggregation,
          name,
          value,
        };
      }) || [],
    [aggregations, lexicon],
  );

  return pieChartData;
};
