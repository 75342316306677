import { DocusignBooleanEnum, DocusignRegulatorEnum, DocusignComplianceEnum } from '@halo-common/enums/docusign';
import { PartialDocusignFormDataModel } from '@halo-data-sources/models';
import { mergeWith } from 'lodash';

import { fallbackValues } from './fallbackValues';
import type { Schema } from '../types';

const getNumberOfAuthorizedPersons = (data: PartialDocusignFormDataModel | null): number => {
  const authorizedPersons = data?.firm_details?.authorized_persons;
  if (authorizedPersons?.authorized_person_4?.name) return 4;
  if (authorizedPersons?.authorized_person_3?.name) return 3;
  if (authorizedPersons?.authorized_person_2?.name) return 2;
  return 1;
};

export const toDefaultValues = (data: PartialDocusignFormDataModel | null): Schema => {
  const values = mergeWith({}, fallbackValues, data, (a, b) => (b === null ? a : undefined));
  values.hasGoneByAnotherName = !!values.firm_details?.previous_name;
  values.mailingAddressDifferentThanPermanent = !!values.firm_details?.mailing_address?.street;
  values.hasSecondaryContact = !!values.firm_details?.principal_contacts?.contact_2?.name;
  values.numberOfAuthorizedPersons = getNumberOfAuthorizedPersons(values);
  return values;
};

export const deeplyReplaceEmptyStringsWithNull = <T = unknown>(value: T): T => {
  if (typeof value === 'string' && !value.trim()) return null as unknown as T;
  if (Array.isArray(value)) return value.map(deeplyReplaceEmptyStringsWithNull) as unknown as T;
  if (value && typeof value === 'object') {
    return Object.fromEntries(
      Object.entries(value).map(([key, v]) => [key, deeplyReplaceEmptyStringsWithNull(v)]),
    ) as T;
  }
  return value;
};

export const formatData = (fields: Schema): PartialDocusignFormDataModel => {
  if (!fields.hasGoneByAnotherName) {
    delete fields.firm_details?.previous_name;
  }
  delete fields.hasGoneByAnotherName;
  if (!fields.mailingAddressDifferentThanPermanent) {
    delete fields.firm_details?.mailing_address;
  }
  delete fields.mailingAddressDifferentThanPermanent;
  if (!fields.firm_details?.entity_type_checkbox_bank) {
    delete fields.firm_details?.entity_type_bank_type_radio;
  }
  if (!fields.hasSecondaryContact) {
    delete fields.firm_details?.principal_contacts?.contact_2;
  }
  delete fields.hasSecondaryContact;
  if (!fields.firm_details?.structure_checkbox_other) {
    delete fields.firm_details?.structure_other_text;
  }
  if (fields?.firm_details?.is_firm_regulated_radio !== DocusignBooleanEnum.YES) {
    delete fields.firm_details?.regulator_radio;
    delete fields.firm_details?.registration_number;
  }
  if (fields?.firm_details?.regulator_radio !== DocusignRegulatorEnum.OTHER) {
    delete fields.firm_details?.regulator_yes_other_text;
  }
  if (fields?.compliance?.compliance_type_radio !== DocusignComplianceEnum.OUTSOURCED) {
    delete fields.compliance?.compliance_type_outsourced_text;
  }
  if (fields?.compliance?.sanctions_radio !== DocusignBooleanEnum.YES) {
    delete fields.compliance?.sanctions_yes_text;
  }
  delete fields.numberOfAuthorizedPersons;

  const values = mergeWith({}, fallbackValues, fields, (a, b) => (b === null ? a : undefined));

  const formattedValues = deeplyReplaceEmptyStringsWithNull(values);

  return formattedValues;
};
