import { translations } from '@halo-common/translations';

import { deliveryProcessStep } from './deliveryProcessStep';
import { formCompletedWithRvpStep } from './formCompletedWithRvpStep';
import { optOutOfPaperStatementsStep } from './optOutOfPaperStatementsStep';
import { productApprovalProcessStep } from './productApprovalProcessStep';
import { reviewProductPerformanceStep } from './reviewProductPerformanceStep';

export const tradingDetailsSection = {
  id: '3',
  label: translations.onboarding.suitability.tradingDetails,
  steps: [
    productApprovalProcessStep,
    reviewProductPerformanceStep,
    deliveryProcessStep,
    optOutOfPaperStatementsStep,
    formCompletedWithRvpStep,
  ],
};
