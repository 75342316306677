import { ReactElement } from 'react';

import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { useOrderBookAuctionIssuersQuery } from '@halo-data-sources/queries';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useAtom } from 'jotai';

export const AuctionIssuerField = (): ReactElement => {
  const { data: issuers } = useOrderBookAuctionIssuersQuery();

  const [searchFields, setSearchFields] = useAtom(orderBookQueryAtom);

  const labelString = 'Issuer';

  const handleIssuerChange = (ev: SelectChangeEvent<string>) => {
    setSearchFields({
      filters: {
        issuer: ev.target.value,
      },
    });
  };

  return (
    <FormControl fullWidth>
      <InputLabel shrink>{labelString}</InputLabel>
      <Select
        label={labelString}
        size="large"
        value={searchFields.filters.issuer}
        onChange={handleIssuerChange}
        displayEmpty
        notched
      >
        <MenuItem value="">All</MenuItem>
        {issuers?.map((issuer) => (
          <MenuItem key={issuer.id} value={issuer.id}>
            {issuer.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
