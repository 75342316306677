import { CalendarProductCard, EmptyResultsCard } from '@halo-common/components';
import { PageFooterLayout } from '@halo-common/layouts';
import { CalendarAllocationsModal } from '@halo-common/modals';
import { translations } from '@halo-common/translations';
import { useCalendarQuery } from '@halo-data-sources/queries';
import { CalendarDetailsModal, CalendarPdfViewerModal, useCalendarProductCardActions } from '@halo-modules/app';
import { BrandIcon, LocalizedButton, PageComponent, PageMeta } from '@halodomination/halo-fe-common';
import { Skeleton, Stack } from '@mui/material';
import { useRouter } from 'next/router';

const containerSx = {
  minHeight: '100vh',
  width: '100%',
};

const CalendarPage: PageComponent = () => {
  const router = useRouter();

  const calendarId = Number(router.query?.calendarId);

  const { data: calendar, isPending, isError } = useCalendarQuery(calendarId);

  const actions = useCalendarProductCardActions();

  if (isPending) {
    return (
      <Stack sx={containerSx} direction="row" justifyContent="center" alignItems="center">
        <Skeleton variant="rounded" width={1250} height={200} sx={{ borderRadius: 3 }} />
      </Stack>
    );
  }

  const isCalendarFound = !isError && calendar;

  const content = isCalendarFound ? (
    <CalendarProductCard sx={{ maxWidth: 1250, width: '100%' }} calendar={calendar} actions={actions} />
  ) : (
    <EmptyResultsCard
      sx={{ width: '700px' }}
      title={translations.messages.calendarNotFound}
      description={translations.messages.calendarMissingAccess}
      action={
        <LocalizedButton type="button" variant="contained" color="primary" onClick={() => router.push(router.basePath)}>
          {translations.messages.returnHome}
        </LocalizedButton>
      }
      brandIcon={<BrandIcon variant="trackEvents" color="primary" />}
    />
  );

  return (
    <>
      <PageFooterLayout>
        <Stack sx={containerSx} direction="row" justifyContent="center" alignItems="center">
          {content}
        </Stack>
      </PageFooterLayout>
      <CalendarAllocationsModal />
      <CalendarPdfViewerModal />
      <CalendarDetailsModal />
    </>
  );
};

export const CalendarPageMeta: PageMeta = {
  pageName: 'Calendar',
  route: '/app/calendars/[calendarId]',
  routeExpression: '/calendars/\\d+',
};

CalendarPage.pageName = CalendarPageMeta.pageName;
CalendarPage.route = CalendarPageMeta.route;

export default CalendarPage;
