import { selectedWatchlistAtom } from '@halo-atoms/watchlists';
import { ProductTypeEnum } from '@halo-common/enums';
import { updateWatchlistProducts } from '@halo-data-sources/clients';
import { HttpError } from '@halo-data-sources/errors';
import { WatchlistNotesQueryResult, WatchlistQueryKeyFactory } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import {
  InfiniteData,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useAtom } from 'jotai';

export type RemoveProductFromWatchlistMutationPayload = {
  watchlistId: number;
  productId: number;
  productType: ProductTypeEnum;
};

export type RemoveProductFromWatchlistMutationResult = RemoveProductFromWatchlistMutationPayload & {
  message: string;
};

const removeProductFromWatchlistMutationFn = async (payload: RemoveProductFromWatchlistMutationPayload) => {
  if (!payload) throw new HttpError(400, 'Invalid Payload');

  const request = {
    add_to: [],
    products: [{ product_id: payload.productId, product_type: payload.productType }],
    remove_from: [payload.watchlistId],
  };

  await updateWatchlistProducts(request);

  return {
    ...payload,
    message: 'Successfully removed product from watchlist',
  };
};

export type useRemoveProductFromWatchlistMutationOptions = UseMutationOptions<
  RemoveProductFromWatchlistMutationResult | null,
  Error,
  RemoveProductFromWatchlistMutationPayload
>;

export type useRemoveProductFromWatchlistMutationResult = UseMutationResult<
  RemoveProductFromWatchlistMutationResult | null,
  Error,
  RemoveProductFromWatchlistMutationPayload
>;

export const useRemoveProductFromWatchlistMutation = (
  options?: useRemoveProductFromWatchlistMutationOptions,
): useRemoveProductFromWatchlistMutationResult => {
  const queryClient = useQueryClient();
  const { enqueueSuccessEvent, enqueueErrorEvent } = useSnackbar();

  const [selectedWatchlist, setSelectedWatchlist] = useAtom(selectedWatchlistAtom);

  return useMutation({
    mutationFn: removeProductFromWatchlistMutationFn,
    ...options,
    onSettled: (_, error) => {
      if (!error) enqueueSuccessEvent({ message: 'Successfully removed product from list.' });
      else if (error) enqueueErrorEvent({ message: 'Failed to remove product from list.' });
    },
    onSuccess: async (...props) => {
      const [data] = props;
      const watchlistProductsKey = WatchlistQueryKeyFactory.products(data?.watchlistId);

      queryClient.setQueryData<InfiniteData<WatchlistNotesQueryResult> | null>(watchlistProductsKey, (prev) => {
        if (!prev || !data) return undefined;

        const { productId } = data;

        const pageIndex = prev.pages.findIndex(({ products }) =>
          products.find(({ product }) => product.id === productId),
        );

        const page = prev.pages?.[pageIndex];

        if (!page) return undefined;

        const updatedPageProducts = [...(page?.products ?? [])];
        const updatedProducts = updatedPageProducts.filter(({ product }) => product.id !== productId);
        const updatedPage = { ...page, products: updatedProducts };
        if (selectedWatchlist) setSelectedWatchlist({ ...selectedWatchlist, count: selectedWatchlist.count - 1 });

        const updatedPages = [...prev.pages];
        updatedPages.splice(pageIndex, 1, updatedPage);

        return { ...prev, pages: updatedPages };
      });

      const queryKey = WatchlistQueryKeyFactory.all();
      await queryClient.refetchQueries({ queryKey });

      options?.onSuccess?.(...props);
    },
  });
};
