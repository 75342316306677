import { ReactElement } from 'react';

import { LocalizedTextField } from '@halodomination/halo-fe-common';
import { AutocompleteRenderInputParams, Box, CircularProgress, TextFieldProps, inputBaseClasses } from '@mui/material';
import { useT } from '@transifex/react';

const endAdornmentSx = {
  display: 'flex',
};

const loadingIndicatorSx = {
  marginRight: 1,
};

const clientInputSx = {
  [`.${inputBaseClasses.root}`]: {
    paddingLeft: 2,
  },
};

export type AccountTypeAheadInputProps = Omit<AutocompleteRenderInputParams, 'size'> & {
  label?: TextFieldProps['label'];
  error?: TextFieldProps['error'];
  helperText?: TextFieldProps['helperText'];
  size?: TextFieldProps['size'];
  loading?: boolean;
};

export const AccountTypeAheadInput = ({
  label,
  InputProps,
  loading,
  size = 'large',
  ...props
}: AccountTypeAheadInputProps): ReactElement => {
  const translator = useT();

  const translatedValue = translator(props.inputProps.value);
  const updatedInputProps = { ...props.inputProps, value: translatedValue };

  const textFieldInputLoadingIndicator = loading ? <CircularProgress sx={loadingIndicatorSx} size={20} /> : null;

  const textFieldInputProps = {
    ...InputProps,
    endAdornment: (
      <Box sx={endAdornmentSx}>
        {textFieldInputLoadingIndicator}
        {InputProps.endAdornment}
      </Box>
    ),
  };

  return (
    <LocalizedTextField
      {...props}
      size={size}
      label={label}
      sx={clientInputSx}
      slotProps={{ input: textFieldInputProps, htmlInput: updatedInputProps }}
    />
  );
};
