import { AccountTypeEnum, ConfirmationEnum, OrganizationCapacityEnum } from '@halo-common/enums';
import { OrderTicketFormModelFields } from '@halo-common/modals';
// TODO: Uncomment when rep code data is complete on BE
// import { AdviseeSearchModel } from '@halo-common/models';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { ApiOrganizationCapacityTypes } from '@halo-data-sources/models';
import { OrderTicketValidationSwitchErrors } from '@halo-data-sources/switches';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldErrors, Resolver } from 'react-hook-form';
import {
  AnyObjectSchema,
  array as yupArray,
  boolean as yupBoolean,
  number as yupNumber,
  object as yupObject,
  string as yupString,
} from 'yup';

export type BulkOrderTicketAllocationFields = {
  accountOption: AccountTypeAheadOption | null;
  quantity?: number;
};

export type OrderTicketCommonFields = {
  solicited: ConfirmationEnum | null;
  discretion: ConfirmationEnum | null;
  accountType: AccountTypeEnum;
  compensation?: string;
  ip1?: string;
  ip1Percent?: string;
  ip2?: string;
  ip2Percent?: string;
  legend1?: string;
  legend2?: string;
  trailer1?: string;
  trailer2?: string;
  trailer3?: string;
  orderDate: string;
  orderFrom: string;
  disclaimer?: boolean;
};

export type BulkOrderTicketFormFields = OrderTicketCommonFields & {
  allocations: Array<BulkOrderTicketAllocationFields>;
  capacityType: ApiOrganizationCapacityTypes | null;
};

export type SingleOrderTicketFormFields = OrderTicketCommonFields & {
  account: AccountTypeAheadOption | null;
  quantity: string;
  ttoRepCode: string;
  // TODO: Uncomment when rep code data is complete on BE
  // ttoRepCode: AdviseeSearchModel | null;
};

const orderTicketCompensationSchema = yupString()
  .when('capacityType', {
    is: (capacityType: string) => (capacityType as OrganizationCapacityEnum) === OrganizationCapacityEnum.PRINCIPAL,
    then: (schema) => schema.required(translations.common.required),
  })
  .when('ip1', {
    is: (ip1: string) => Boolean(ip1),
    then: (schema) => schema.required(translations.common.required),
  })
  .when('ip2', {
    is: (ip2: string) => Boolean(ip2),
    then: (schema) => schema.required(translations.common.required),
  })
  .when('ip1Percent', {
    is: (ip1Percent: string) => parseInt(ip1Percent) > 0,
    then: (schema) => schema.required(translations.common.required),
  })
  .when('ip2Percent', {
    is: (ip2Percent: string) => parseInt(ip2Percent) > 0,
    then: (schema) => schema.required(translations.common.required),
  });

const orderTicketIP1Schema = yupString()
  .when('ip1Percent', {
    is: (ip1Percent: string) => parseInt(ip1Percent) > 0,
    then: (schema) => schema.required(translations.common.required),
  })
  .when('ip2', {
    is: (ip2: string) => Boolean(ip2),
    then: (schema) => schema.required(translations.common.required),
  });

const orderTicketIP1PercentSchema = yupString().when('ip1', {
  is: (ip1: string) => Boolean(ip1),
  then: (schema) => schema.required(translations.common.required),
});

const orderTicketIP2Schema = yupString()
  .when('ip2Percent', {
    is: (ip2Percent: string) => parseInt(ip2Percent) > 0,
    then: (schema) => schema.required(translations.common.required),
  })
  .when('ip1Percent', {
    is: (ip1Percent: string) => parseInt(ip1Percent) < 100,
    then: (schema) => schema.required(translations.common.required),
  });

const orderTicketIP2PercentSchema = yupString()
  .test('is-ip-2-percent-above-0', 'IP2 must be greater than 0.', (ip2Percent?: string) => {
    return !ip2Percent || parseInt(ip2Percent) > 0;
  })
  .test('is-ip-2-percent-below-100', 'IP2 must be less than 100.', (ip2Percent?: string) => {
    return !ip2Percent || parseInt(ip2Percent) < 100;
  })
  .when('ip1Percent', {
    is: (ip1Percent: string) => parseInt(ip1Percent) < 100,
    then: (schema) => schema.required(translations.common.required),
  })
  .when('ip2', {
    is: (ip2: string) => Boolean(ip2),
    then: (schema) => schema.required(translations.common.required),
  });

const orderTicketAccountOptionSchema = yupObject()
  .shape({ account: yupObject().shape({ id: yupNumber() }) })
  .nullable()
  .required(translations.allocationsModal.common.accountRequiredError);

const orderTicketQuantitySchema = yupNumber()
  .typeError(translations.allocationsModal.common.qtyTypeError)
  .required(translations.allocationsModal.common.qtyRequiredError)
  .positive(translations.allocationsModal.common.qtyRequiredError)
  .integer(translations.allocationsModal.common.qtyIntegerError);

const bulkOrderTicketAllocationSchema = yupArray()
  .of(
    yupObject()
      .shape({
        accountOption: orderTicketAccountOptionSchema,
        quantity: orderTicketQuantitySchema,
      })
      .test({
        name: 'are-allocation-accounts-unique',
        test: (currentAllocation, ctx) => {
          const { parent, createError, path } = ctx;
          const currentAllocationId = currentAllocation.accountOption?.account?.id;
          const allocations = parent as Array<BulkOrderTicketAllocationFields>;

          const foundDup = allocations?.findIndex((allocation, index) => {
            const ignoreIndex = path === `allocations[${index}]`;
            return !ignoreIndex && allocation.accountOption?.account?.id === currentAllocationId;
          });
          if (foundDup >= 0) {
            return createError({
              message: `Account Duplicated`,
              path: `${path}.accountOption`,
            });
          }
          return true;
        },
      }),
  )
  .min(1, translations.allocationsModal.common.allocationRequiredError)
  .required(translations.allocationsModal.common.allocationRequiredError);

const orderTicketCommonSchemaShape = {
  orderFrom: yupString().required(translations.common.required),
  solicited: yupString().required(translations.common.required),
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const buildOrderTicketFormSchema = (bulk = false, disclaimer = false): AnyObjectSchema => {
  const excludes: Array<[string, string]> = [
    ['ip1', 'ip2'],
    ['ip1', 'ip1Percent'],
    ['ip2', 'ip2Percent'],
  ];

  const orderTicketDisclaimerSchema = yupBoolean().when([], {
    is: () => disclaimer,
    then: (schema) =>
      schema
        .isTrue(translations.allocationsModal.common.disclaimerRequiredError)
        .required(translations.allocationsModal.common.disclaimerRequiredError),
  });

  const bulkOrderTicketSchemaShape = {
    accountType: yupString().required(translations.common.required),
    allocations: bulkOrderTicketAllocationSchema,
    capacityType: yupString().required(translations.common.required),
    compensation: orderTicketCompensationSchema,
    disclaimer: orderTicketDisclaimerSchema,
    discretion: yupString().required(translations.common.required),
    ip1: orderTicketIP1Schema,
    ip1Percent: orderTicketIP1PercentSchema,
    ip2: orderTicketIP2Schema,
    ip2Percent: orderTicketIP2PercentSchema,
    legend1: yupString(),
    legend2: yupString(),
    trailer1: yupString(),
    trailer2: yupString(),
    trailer3: yupString(),
    ...orderTicketCommonSchemaShape,
  };

  const singleOrderTicketSchemaShape = {
    account: orderTicketAccountOptionSchema,
    disclaimer: orderTicketDisclaimerSchema,
    quantity: orderTicketQuantitySchema,
    ...orderTicketCommonSchemaShape,
  };

  const shape = bulk ? bulkOrderTicketSchemaShape : singleOrderTicketSchemaShape;

  return yupObject().shape(shape, excludes);
};

export const buildOrderTicketFormResolver = (
  schema: AnyObjectSchema,
  fixErrors: OrderTicketValidationSwitchErrors,
): Resolver<OrderTicketFormModelFields> => {
  return async (data, context, options) => {
    const schemaErrors = await yupResolver(schema)(data, context, options);

    const { allocations } = data as BulkOrderTicketFormFields;
    const errors = schemaErrors.errors as FieldErrors<BulkOrderTicketFormFields>;
    const schemaAllocationErrors = (errors.allocations ?? []) as FieldErrors<BulkOrderTicketFormFields['allocations']>;

    const mergedErrors = allocations.map((allocation, index) => {
      const accountId = allocation.accountOption?.account?.id;
      const fixError = accountId ? fixErrors[accountId] : undefined;
      const validationError = schemaAllocationErrors[index]?.accountOption?.message;
      if (fixError) {
        const errorMessages = [validationError, fixError].filter(Boolean).join(' - ');
        return { accountOption: { message: errorMessages } };
      }
      return schemaAllocationErrors[index];
    });

    const hasAllocationErrors = mergedErrors.filter(Boolean).length > 0;

    let updatedErrors = {
      ...errors,
      allocations: mergedErrors,
    } as FieldErrors<OrderTicketFormModelFields>;

    if (!hasAllocationErrors) updatedErrors = { ...errors };

    return {
      values: schemaErrors.values,
      errors: updatedErrors,
    };
  };
};
