import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { BrandIcon, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Paper } from '@mui/material';

const containerSx = {
  px: 4,
  py: 3,
  mb: 2,
  outlineWidth: '1px',
  outlineColor: 'background.action',
  boxShadow: 0,
  width: '100%',
};

export type EducationBannerProps = {
  progress: number;
};

export const EducationBanner = ({ progress }: EducationBannerProps): ReactElement => {
  const hasUnfinishedCourses = progress < 100;

  const iconName = hasUnfinishedCourses ? 'education' : 'confirm';

  const title = hasUnfinishedCourses
    ? translations.education.educationLanding.unfinishedTitle
    : translations.education.educationLanding.finishedTitle;

  const subtitle = hasUnfinishedCourses
    ? translations.education.educationLanding.unfinishedSubtitle
    : translations.education.educationLanding.finishedSubtitle;

  const iconContent = iconName ? (
    <Stack direction="row" alignItems="center" justify="center">
      <BrandIcon variant={iconName} color="primary" width={72} height={72} />
    </Stack>
  ) : null;

  const titleContent = title ? (
    <LocalizedTypography color="textPrimary" variant="h5">
      {title}
    </LocalizedTypography>
  ) : null;

  return (
    <Paper sx={containerSx}>
      <Stack direction="row" alignItems="center" spacing={2}>
        {iconContent}
        <Stack direction="column" justify="space-between" spacing={1}>
          {titleContent}
          <LocalizedTypography color="textPrimary" variant="body1">
            {subtitle}
          </LocalizedTypography>
        </Stack>
      </Stack>
    </Paper>
  );
};
