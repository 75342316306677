import { useMemo } from 'react';

import { translations } from '@halo-common/translations';
import type { PartialDocusignFormDataModel } from '@halo-data-sources/models';
import { useOnboardingDocusignFormDataQuery } from '@halo-data-sources/queries';
import {
  suitabilitySections,
  SuitabilitySectionModel,
  SuitabilityStepModel,
} from '@halo-modules/app/v2/onboarding/subPages/suitability';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { DateTime } from 'luxon';

export type useSuitabilityFormManagerReturn = {
  sections: Array<SuitabilitySectionModel>;
  formData: PartialDocusignFormDataModel | null;
  updated_at: string | null;
  created_at: string | null;
  isLoading: boolean;
  isFullyFilled: boolean;
  unansweredSteps: Array<SuitabilityStepModel>;
  firstUnansweredStep: SuitabilityStepModel | null;
  isFormRecentlyEditedBySomeoneElse: boolean;
};

export const isNotOlderThanTenMinutes = (isoString: string): boolean => {
  const formattedISO = isoString.replace(' ', 'T').split('.')[0] + 'Z';

  const inputTime = DateTime.fromISO(formattedISO, { zone: 'utc' }).toLocal();

  if (!inputTime.isValid) return false;

  const now = DateTime.local();

  const diffInMinutes = now.diff(inputTime, 'minutes').minutes;

  return diffInMinutes <= 10;
};

export const useSuitabilityFormManager = (): useSuitabilityFormManagerReturn => {
  const { data, isLoading } = useOnboardingDocusignFormDataQuery();

  const { json_data: formData = null, updated_at = null, created_at = null } = data || {};

  const sectionsWithState = useMemo(
    () =>
      suitabilitySections.map((section) => {
        const steps = section.steps.map((step) => ({
          isStepCompleted: step.isCompleted?.(formData),
          ...step,
        }));
        const unansweredSteps = steps.filter((step) => step.isStepCompleted === false);
        const isSectionCompleted = steps.some((step) => step.isCompleted) ? unansweredSteps.length === 0 : undefined;
        const unansweredQuestions = (
          <LocalizedTypography
            variant="caption"
            color="text.secondary"
            dynamicContent={{
              count: unansweredSteps.length,
            }}
          >
            {translations.onboarding.suitability.unfinishedQuestions}
          </LocalizedTypography>
        );
        const optional = unansweredSteps.length > 0 ? unansweredQuestions : null;
        return {
          isSectionCompleted,
          optional,
          ...section,
          steps,
        };
      }),
    [formData],
  );

  const unansweredSteps = useMemo(
    () => sectionsWithState.flatMap((section) => section.steps.filter((step) => step.isStepCompleted === false)),
    [sectionsWithState],
  );

  const isFullyFilled = unansweredSteps.length === 0;

  const firstUnansweredStep = unansweredSteps[0] || null;

  const isFormRecentlyEditedBySomeoneElse = useMemo(
    () =>
      !!updated_at &&
      isNotOlderThanTenMinutes(updated_at) &&
      localStorage.getItem('suitability_form_updated_at') !== updated_at,
    [updated_at],
  );

  return {
    sections: sectionsWithState,
    formData,
    updated_at,
    created_at,
    isLoading,
    isFullyFilled,
    unansweredSteps,
    firstUnansweredStep,
    isFormRecentlyEditedBySomeoneElse,
  };
};
