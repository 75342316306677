import { UserActivityAction } from '@halo-common/models';
import { getUserActivityActions } from '@halo-data-sources/clients';
import { UserQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export type UserActivityActionsQueryURLParams = { users?: string; relationship_managers?: string };
export type UserActivityActionsQueryPayload = { userIds?: Array<number>; managerIds: Array<number> };
export type UserActivityActionsQueryResult = Array<UserActivityAction>;

const getUserActivityActionsFn = async (payload?: UserActivityActionsQueryPayload) => {
  const params: UserActivityActionsQueryURLParams = {};
  if (payload?.userIds) params.users = payload.userIds.join(',');
  if (payload?.managerIds) params.relationship_managers = payload.managerIds.join(',');

  const searchParams = new URLSearchParams(params);

  const response = await getUserActivityActions(searchParams);

  return response.objects;
};

export const useUserActivityActionsQuery = (
  payload?: UserActivityActionsQueryPayload,
): UseQueryResult<UserActivityActionsQueryResult, Error> =>
  useQuery<UserActivityActionsQueryResult, Error>({
    queryKey: UserQueryKeyFactory.activity(payload),
    queryFn: () => getUserActivityActionsFn(payload),
  });
