import { useMemo } from 'react';

import {
  DEFAULT_CALENDARS_SEARCH_FIELDS,
  DEFAULT_JANNEY_SEARCH_FIELDS,
  DEFAULT_PERSHING_SEARCH_FIELDS,
} from '@halo-atoms/dashboard';
import { useWhiteLabelRole } from '@halo-common/hooks';
import {
  useOrderBookCalendarsQuery,
  useOrderBookJanneyQuery,
  useOrderBookPershingQuery,
} from '@halo-data-sources/queries';
import {
  DashboardOrderBookCalendarsTable,
  DashboardOrderBookJanneyTable,
  DashboardOrderBookPershingTable,
} from '@halo-modules/app';

export type useWhiteLabelCalendarsComponentsReturn =
  | {
      useCalendarsQuery: typeof useOrderBookCalendarsQuery;
      CalendarsTable: typeof DashboardOrderBookCalendarsTable;
      defaultSearchFields: typeof DEFAULT_CALENDARS_SEARCH_FIELDS;
    }
  | {
      useCalendarsQuery: typeof useOrderBookJanneyQuery;
      CalendarsTable: typeof DashboardOrderBookJanneyTable;
      defaultSearchFields: typeof DEFAULT_JANNEY_SEARCH_FIELDS;
    }
  | {
      useCalendarsQuery: typeof useOrderBookPershingQuery;
      CalendarsTable: typeof DashboardOrderBookPershingTable;
      defaultSearchFields: typeof DEFAULT_PERSHING_SEARCH_FIELDS;
    };

export const useWhiteLabelCalendarsComponents = (): useWhiteLabelCalendarsComponentsReturn => {
  const whiteLabelRole = useWhiteLabelRole();

  return useMemo(() => {
    if (whiteLabelRole === 'janney') {
      return {
        useCalendarsQuery: useOrderBookJanneyQuery,
        CalendarsTable: DashboardOrderBookJanneyTable,
        defaultSearchFields: DEFAULT_JANNEY_SEARCH_FIELDS,
      };
    }

    if (whiteLabelRole === 'pershing' || whiteLabelRole === 'pershingDev') {
      return {
        useCalendarsQuery: useOrderBookPershingQuery,
        CalendarsTable: DashboardOrderBookPershingTable,
        defaultSearchFields: DEFAULT_PERSHING_SEARCH_FIELDS,
      };
    }

    return {
      useCalendarsQuery: useOrderBookCalendarsQuery,
      CalendarsTable: DashboardOrderBookCalendarsTable,
      defaultSearchFields: DEFAULT_CALENDARS_SEARCH_FIELDS,
    };
  }, [whiteLabelRole]);
};
