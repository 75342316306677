import { ChangeEvent, ReactElement } from 'react';

import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { useDebounced } from '@halo-common/hooks';
import { TextField } from '@mui/material';
import { useAtom } from 'jotai';

const inputLabelProps = { shrink: true };

export const OrderNumberField = (): ReactElement => {
  const [searchFields, setSearchFields] = useAtom(orderBookQueryAtom);

  const handleFieldChange = (key: string, value: string) => {
    setSearchFields({
      filters: {
        [key]: value,
      },
    });
  };

  const debouncedSearchHandler = useDebounced(handleFieldChange, 500);

  const debouncedOrderNumberChange = (ev: ChangeEvent<HTMLInputElement>) =>
    debouncedSearchHandler(ev.target.name, ev.target.value);

  return (
    <TextField
      name="orderNumber"
      label="Order Number"
      size="large"
      placeholder="All"
      defaultValue={searchFields.filters.orderNumber}
      onChange={debouncedOrderNumberChange}
      slotProps={{ inputLabel: inputLabelProps }}
      fullWidth
    />
  );
};
