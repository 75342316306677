import { useMemo } from 'react';

import { WidgetEnginesEnum } from '@halo-common/enums';
import type { DashboardPreferencesModel, DashboardSetWidgetModel, DashboardWidgetSetModel } from '@halo-common/models';
import { UserInfoQueryResult, useUserInfoQuery } from '@halo-data-sources/queries';
import { widgetsMap } from '@halo-modules/app';

export type useDashboardConfigQueryResult = UserInfoQueryResult & {
  dashboardWidgetSet: DashboardWidgetSetModel | null;
  featuredNotesWidget: DashboardSetWidgetModel | null;
  userDashboardPreferences?: DashboardPreferencesModel | null;
  widgetsAreHidable: boolean;
  widgetsAreReorderable: boolean;
  widgets: DashboardSetWidgetModel[];
};

export const useDashboardConfigQuery = (): useDashboardConfigQueryResult => {
  const userInfoQuery = useUserInfoQuery();

  const dashboardWidgetSet = userInfoQuery.data?.dashboardWidgetSet || null;
  const userDashboardPreferences = userInfoQuery?.data?.preferences?.dashboard_preferences || null;

  const widgetsAreHidable = dashboardWidgetSet?.widgetsAreHidable ?? false;
  const widgetsAreReorderable = dashboardWidgetSet?.widgetsAreReorderable ?? false;

  const widgets = useMemo(
    () =>
      dashboardWidgetSet?.widgets
        ?.filter((widget) => !!widgetsMap[widget.engine])
        .sort((a, b) => (a.position || 0) - (b.position || 0)) || [],
    [dashboardWidgetSet],
  );

  const featuredNotesWidget =
    dashboardWidgetSet?.widgets?.find(
      (widget) => (widget.engine as WidgetEnginesEnum) === WidgetEnginesEnum.FEATURED_NOTES,
    ) ?? null;

  return {
    ...userInfoQuery,
    dashboardWidgetSet,
    featuredNotesWidget,
    userDashboardPreferences,
    widgetsAreHidable,
    widgetsAreReorderable,
    widgets,
  };
};
