import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { Iconography, IconographySizes, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { useMediaQuery, useTheme } from '@mui/material';

export type TargetedWatchlistHeaderProps = {
  count: number;
};

export const TargetedWatchlistHeader = ({ count }: TargetedWatchlistHeaderProps): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const iconSize: IconographySizes = isMobile ? '1x' : '1.75x';
  const titleSpacing = isMobile ? 0 : 1;

  const dynamicContent = { count };

  const flairProps = {
    sx: { borderWidth: '2px' },
  };

  return (
    <Stack direction="row" spacing={1}>
      <Iconography
        color="primary.dark"
        prefix="fal"
        size={iconSize}
        iconName="bullseye-arrow"
        flair
        FlairProps={flairProps}
      />
      <Stack direction="column" spacing={titleSpacing}>
        <LocalizedTypography variant="h2">{translations.watchlist.common.myTargetedProducts}</LocalizedTypography>
        <LocalizedTypography variant="subtitle1" color="text.secondary" dynamicContent={dynamicContent}>
          {translations.watchlist.common.targetedProductCount}
        </LocalizedTypography>
      </Stack>
    </Stack>
  );
};
