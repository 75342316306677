import { ReactElement } from 'react';

import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Box } from '@mui/material';

const documentGridSx = {
  '& a': {
    textDecoration: 'none',
    color: 'white',
  },
};

export const DocumentLinks = (): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();
  const documentLinks = userInfo?.settings?.onboarding?.documentLinks;

  return (
    <Box
      sx={documentGridSx}
      display="flex"
      flexDirection="column"
      dangerouslySetInnerHTML={{ __html: documentLinks ?? '' }}
    />
  );
};
