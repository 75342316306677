import { AssetIdentifierEnum, AssetIdLocationEnum } from '@halo-common/enums';
import { PortfolioPositionReferenceModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { array as yupArray, number as yupNumber, object as yupObject, string as yupString } from 'yup';

const accountIdRequiredText = translations.portfolio.addPositionsToAccountModal.enterAccountError;
const notionalRequiredText = translations.portfolio.addPositionsToAccountModal.enterNotionalError;

export const addPositionsToAccountFormSchema = yupObject().shape({
  positions: yupArray()
    .defined(translations.messages.requiredField)
    .of(
      yupObject().shape({
        termsheetId: yupNumber().defined(translations.messages.requiredField),
        accountId: yupString().required(accountIdRequiredText),
        notional: yupNumber().required(notionalRequiredText).min(1, notionalRequiredText),
        account: yupObject(),
        location: yupString().oneOf(Object.values(AssetIdLocationEnum)),
        assetId: yupString(),
        assetIdType: yupString().oneOf(Object.values(AssetIdentifierEnum)),
      }),
    ),
});

export type AddPositionsToAccountFormFields = {
  positions: Array<PortfolioPositionReferenceModel>;
};
