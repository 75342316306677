import { YEAR_MONTH_DAY_DATE_FORMAT } from '@halo-common/constants';
import { AuctionDefaultDatesModel } from '@halo-common/models';
import { ApiUserAuctionDefaults, ApiUserAuctionDefaultsRequest } from '@halo-data-sources/models';
import { GetAuctionDefaultDatesRequestModel } from '@halo-data-sources/queries';

interface ApiAuctionDefaultsMapperModel {
  toAuctionDateDefaults: (model: ApiUserAuctionDefaults) => AuctionDefaultDatesModel;
}

export const ApiAuctionDefaultsMapper: ApiAuctionDefaultsMapperModel = {
  toAuctionDateDefaults: (model) => ({
    auctionEndDate: model.auction_end_date,
    strikeDate: model.strike_date,
    tradeDate: model.trade_date,
    settlementDate: model.settlement_date,
  }),
};

interface AuctionDefaultsMapperModel {
  toApiUserAuctionDefaultsRequest: (model: GetAuctionDefaultDatesRequestModel) => ApiUserAuctionDefaultsRequest;
}

export const AuctionDefaultsMapper: AuctionDefaultsMapperModel = {
  toApiUserAuctionDefaultsRequest(model) {
    return {
      date: model.date.toFormat(YEAR_MONTH_DAY_DATE_FORMAT),
      date_type: model.dateType,
    };
  },
};
