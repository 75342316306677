import { ReactElement } from 'react';

import { orderBookCancelModalAtom } from '@halo-atoms/orderbook';
import { ActionButton } from '@halo-common/components';
import { AllocationModel } from '@halo-common/models';
import { useSetAtom } from 'jotai';

export type CancelJanneyOrderButtonProps = {
  allocation: AllocationModel;
};

export const CancelJanneyOrderButton = ({ allocation }: CancelJanneyOrderButtonProps): ReactElement | null => {
  const setOrderBookCancelModal = useSetAtom(orderBookCancelModalAtom);

  const onClick = () => void setOrderBookCancelModal({ open: true, allocation });

  return (
    <ActionButton
      sx={{ maxWidth: '50%' }}
      color="primary"
      type="button"
      variant="outlined"
      onClick={onClick}
      fullWidth
      size="extraSmall"
    >
      Cancel
    </ActionButton>
  );
};
