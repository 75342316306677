import { ReactElement } from 'react';

import { orderBookApprovalModalAtom } from '@halo-atoms/orderbook';
import { ExecutionOrderReceipt } from '@halo-common/components';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { ApproveCalendarOrderAlert } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { useCalendarOrderQuery, useCalendarQuery } from '@halo-data-sources/queries';
import { LocalizedButton, Modal, Stack } from '@halodomination/halo-fe-common';
import { useAtom } from 'jotai';

export const OrderBookJanneyApproveOrderModal = (): ReactElement => {
  const configureDateTime = useWhiteLabelDateConfiguration();

  const [orderApprovalModal, setOrderBookApprovalModal] = useAtom(orderBookApprovalModalAtom);

  const { open, allocation: selectedAllocation } = orderApprovalModal;

  const { data: calendarOrderQuery, isPending: orderLoading } = useCalendarOrderQuery(selectedAllocation?.id);
  const { data: calendar, isPending: calendarLoading } = useCalendarQuery(selectedAllocation?.calendarId);

  const loading = orderLoading || calendarLoading;

  const allocation = calendarOrderQuery?.allocation;
  const order = calendarOrderQuery?.executionOrder;
  const summary = calendarOrderQuery?.transactionSummary;

  const cusip = calendar?.cusip ?? '';
  const updatedOnDate = allocation?.updatedOn;

  const lastUpdatedDateTime = configureDateTime(updatedOnDate);

  const handleCloseApproveOrderModal = () => {
    setOrderBookApprovalModal({ open: false });
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseApproveOrderModal}
      size="medium"
      title={cusip}
      loading={loading}
      footer={
        <Stack direction="row" justify="flex-end">
          <LocalizedButton
            type="button"
            variant="contained"
            color="primary"
            onClick={handleCloseApproveOrderModal}
            disabled={loading}
          >
            {translations.common.close}
          </LocalizedButton>
        </Stack>
      }
    >
      <Stack direction="column" spacing={2}>
        <ApproveCalendarOrderAlert loading={loading} allocation={selectedAllocation} date={lastUpdatedDateTime} />
        <ExecutionOrderReceipt
          calendarId={selectedAllocation?.calendarId}
          order={order}
          summary={summary}
          account={allocation?.account}
        />
      </Stack>
    </Modal>
  );
};
