import { AuctionCommentReviewModel } from '@halo-common/models';
import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiAdminAuctionModel,
  ApiAdminAuctionResponseModel,
  ApiAllowedIssuersResponseModel,
  ApiBuyerAuctionChooseWinnerResponseModel,
  ApiBuyerAuctionCommentReviewResponseModel,
  ApiBuyerAuctionResponseModel,
  ApiBuyerAuctionStartModel,
  ApiBuyerAuctionStartResponseModel,
  ApiExecutionTermsheetDownloadRequestModel,
} from '@halo-data-sources/models';
import {
  ApiPutBuyerAuctionAllocationsResponseModel,
  DeleteBuyerAuctionAllocationModel,
  PutBuyerAuctionAllocationsModel,
} from '@halo-data-sources/mutations';

const EXECUTION_AUCTION_BUYER = '/execution/auction/buyer';

const BUYER_PATH = '/issuer/v1/buyer';
const BUYER_PORTFOLIO_PATH = `${BUYER_PATH}/portfolio`;
const BUYER_AUCTION_PATH = `${BUYER_PATH}/auction`;

export const getPortfolioTermSheetDownloadLink = async (ts_id: number): Promise<string> => {
  const clientConfiguration = await getClientConfiguration(BUYER_PORTFOLIO_PATH);
  return await request<string>(`${clientConfiguration.basePath}/download/${ts_id}`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });
};

export const fetchAllowedIssuers = async (query?: string): Promise<ApiAllowedIssuersResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_BUYER);

  const params = query ? `?${query}` : '';

  const response = await request<ApiAllowedIssuersResponseModel>(`${clientConfiguration.basePath}/issuers${params}`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const fetchBuyerPrelimDocument = async (auctionId: number): Promise<string> => {
  const clientConfiguration = await getClientConfiguration(BUYER_AUCTION_PATH);

  const response = await request<string>(`${clientConfiguration.basePath}/download/${auctionId}`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const fetchExecutionTermsheetDocument = async ({
  type,
  auctionId,
}: ApiExecutionTermsheetDownloadRequestModel): Promise<string> => {
  const clientConfiguration = await getClientConfiguration(BUYER_AUCTION_PATH);

  const response = await request<string>(`${clientConfiguration.basePath}/download/${auctionId}?type=${type}`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const cancelBuyerAuction = async (auctionId: number): Promise<ApiAdminAuctionModel> => {
  const clientConfiguration = await getClientConfiguration(BUYER_AUCTION_PATH);

  const response = await request<ApiAdminAuctionModel>(`${clientConfiguration.basePath}/cancel/${auctionId}`, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const rejectBuyerAuctionTerms = async (auctionId: number): Promise<ApiAdminAuctionModel> => {
  const clientConfiguration = await getClientConfiguration(BUYER_AUCTION_PATH);

  const response = await request<ApiAdminAuctionModel>(`${clientConfiguration.basePath}/reject/${auctionId}`, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const acceptBuyerAuctionTerms = async (auctionId: number): Promise<ApiAdminAuctionModel> => {
  const clientConfiguration = await getClientConfiguration(BUYER_AUCTION_PATH);

  const response = await request<ApiAdminAuctionModel>(`${clientConfiguration.basePath}/accept/${auctionId}`, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

// TODO: deprecate fetchAuction in favor of fetchBuyerAuction
export const fetchAuction = async (auctionId: string): Promise<ApiAdminAuctionResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_BUYER);

  const response = await request<ApiAdminAuctionResponseModel>(`${clientConfiguration.basePath}/${auctionId}`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const fetchBuyerAuction = async (auctionId: string): Promise<ApiBuyerAuctionResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_BUYER);

  const response = await request<ApiBuyerAuctionResponseModel>(`${clientConfiguration.basePath}/${auctionId}`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const startAuction = async (
  startAuctionItem: ApiBuyerAuctionStartModel,
): Promise<ApiBuyerAuctionStartResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_BUYER);

  const response = await request<ApiBuyerAuctionStartResponseModel>(`${clientConfiguration.basePath}`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...startAuctionItem,
    }),
  });

  return response;
};

export const putAuctionReviewComment = async ({
  commentId,
  approved,
}: AuctionCommentReviewModel): Promise<ApiBuyerAuctionCommentReviewResponseModel> => {
  const clientConfiguration = await getClientConfiguration(BUYER_AUCTION_PATH);

  const path = `${clientConfiguration.basePath}/comment/${commentId}/approve`;

  return await request<ApiBuyerAuctionCommentReviewResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      approved,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};

export const putAuctionChooseWinner = async (
  auctionId: number,
  issuerId: number,
): Promise<ApiBuyerAuctionChooseWinnerResponseModel> => {
  const clientConfiguration = await getClientConfiguration(BUYER_AUCTION_PATH);

  const path = `${clientConfiguration.basePath}/choose-winner`;

  return await request<ApiBuyerAuctionChooseWinnerResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      auction_id: auctionId,
      issuer: issuerId,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};

export const putAuctionSubmitAuctionAllocations = async ({
  auctionId,
  allocations,
}: PutBuyerAuctionAllocationsModel): Promise<ApiPutBuyerAuctionAllocationsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_BUYER);

  return await request<ApiPutBuyerAuctionAllocationsResponseModel>(`${clientConfiguration.basePath}/${auctionId}`, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      allocations,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};

export const deleteAuctionAllocation = async ({
  auctionId,
  allocationId,
}: DeleteBuyerAuctionAllocationModel): Promise<void> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_BUYER);
  const path = `${clientConfiguration.basePath}/${auctionId}/allocation/${allocationId}`;

  await request<void>(path, {
    ...clientConfiguration.requestInit,
    method: 'DELETE',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};
