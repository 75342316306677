export enum ApiWatchlistIconNamesEnum {
  'ACORN' = 'acorn',
  'CAR' = 'car',
  'HOUSE' = 'house',
  'PLANE' = 'plane',
  'CHARGINGSTATION' = 'charging-station',
  'APARTMENT' = 'apartment',
  'TROPHYSTAR' = 'trophy-star',
  'GLOBE' = 'globe',
  'GAMEPADMODERN' = 'gamepad-modern',
  'CHARTLINEUP' = 'chart-line-up',
  'CHARTLINEDOWN' = 'chart-line-down',
  'HOSPITAL' = 'hospital',
  'SACKDOLLAR' = 'sack-dollar',
  'MONEYBILL' = 'money-bill',
  'SHIELD' = 'shield',
  'CALENDAR' = 'calendar',
}

export type ApiWatchlistIconNames =
  | 'ACORN'
  | 'CAR'
  | 'HOUSE'
  | 'PLANE'
  | 'CHARGINGSTATION'
  | 'APARTMENT'
  | 'TROPHYSTAR'
  | 'GLOBE'
  | 'GAMEPADMODERN'
  | 'CHARTLINEUP'
  | 'CHARTLINEDOWN'
  | 'HOSPITAL'
  | 'SACKDOLLAR'
  | 'MONEYBILL'
  | 'SHIELD'
  | 'CALENDAR';
