import { ReactElement } from 'react';

import { useNoteSpecificDetails } from '@halo-common/hooks';
import {
  ProductDetailPanelLayout,
  ProductDetailPanelPayoffSection,
  ProductDetailPanelPrincipalSection,
  ProductDetailPanelUnderlyingSection,
} from '@halo-common/layouts';
import { NoteModel } from '@halo-common/models';

export type ExecutionAuctionReviewDetailModalPanelProps = {
  product?: NoteModel | null;
  loading?: boolean;
};

export const ExecutionAuctionReviewDetailModalPanel = ({
  product,
  loading = true,
}: ExecutionAuctionReviewDetailModalPanelProps): ReactElement => {
  const { payoffDetails, term, protectionDetails } = useNoteSpecificDetails(product);

  const localization = {
    protection: protectionDetails?.[0]?.dynamicContent,
    term: term?.dynamicContent,
    gearing: protectionDetails?.[1]?.dynamicContent,
  };

  const underlyings = product?.tradables;
  const principalProtection = protectionDetails?.[0];
  const weights = product?.tradableWeights;

  const hasPayoffDetails = payoffDetails.length !== 0;
  const showPayoffSection = loading || hasPayoffDetails;
  const payoffSection = showPayoffSection ? (
    <ProductDetailPanelPayoffSection fields={payoffDetails} loading={loading} />
  ) : null;

  const slots = {
    underlyings: <ProductDetailPanelUnderlyingSection underlyings={underlyings} weights={weights} loading={loading} />,
  };

  return (
    <ProductDetailPanelLayout slots={slots} loading={loading}>
      <ProductDetailPanelPrincipalSection
        term={term?.text}
        protection={principalProtection?.text}
        protectionType={product?.protectionType}
        currency={product?.currencyCode}
        loading={loading}
        Localization={localization}
      />
      {payoffSection}
    </ProductDetailPanelLayout>
  );
};
