import { ReactElement } from 'react';

import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box } from '@mui/material';

const textSx = {
  maxWidth: 500,
  width: 'fit-content',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

const containerSx = {
  '& .highlighted-text': {
    color: (theme: HaloTheme) => `${theme.palette.info.main} !important`,
    backgroundColor: (theme: HaloTheme) => `${theme.palette.info.background} !important`,
    fontWeight: 'bold',
  },
};

export type WatchlistTypeaheadOptionHighlightProps = {
  name: string;
  highlight: string;
};

export const WatchlistTypeaheadOptionHighlight = ({
  name,
  highlight,
}: WatchlistTypeaheadOptionHighlightProps): ReactElement => {
  const isHighlight = Boolean(highlight.trim());

  if (!isHighlight) return <span style={textSx}>{name}</span>;

  const highlightedTextRegex = new RegExp(`(${highlight})`, 'gi');
  const nameParts = name.split(highlightedTextRegex);

  const parts = nameParts.map((part, i) => {
    const isPartHighlighted = highlightedTextRegex.test(part);
    const highlightedClassName = isPartHighlighted ? 'highlighted-text' : undefined;

    return (
      <span key={i} className={highlightedClassName}>
        {part}
      </span>
    );
  });

  return (
    <Box component="span" sx={containerSx}>
      {parts}
    </Box>
  );
};
