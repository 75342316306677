import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { CollapsibleFilters, PopoverRangeFilter } from '@halo-common/components';
import { useSliderText } from '@halo-common/hooks';
import { DynamicTypeFiltersSideBarTitlesDict } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { useAtom } from 'jotai';

export const NON_CALL_PERIOD_SLIDER_MIN_VALUE = 0;
export const NON_CALL_PERIOD_SLIDER_MAX_VALUE = 60;

const DEFAULT_SLIDER_DATA = {
  min: NON_CALL_PERIOD_SLIDER_MIN_VALUE,
  max: NON_CALL_PERIOD_SLIDER_MAX_VALUE,
};

export type NonCallPeriodFiltersProps = {
  tag: string;
};

export const NonCallPeriodFilters = ({ tag }: NonCallPeriodFiltersProps): ReactElement => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);
  const { nonCallPeriod } = sideBarFilters;

  const [sliderData, setSliderData] = useState(DEFAULT_SLIDER_DATA);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const sliderMin = sliderData?.min ?? NON_CALL_PERIOD_SLIDER_MIN_VALUE;
  const sliderMax = sliderData?.max ?? NON_CALL_PERIOD_SLIDER_MAX_VALUE;
  const sliderValue = [sliderMin, sliderMax];

  const isSliderMinValue = sliderMin === NON_CALL_PERIOD_SLIDER_MIN_VALUE;
  const isSliderMaxValue = sliderMax === NON_CALL_PERIOD_SLIDER_MAX_VALUE;

  const open = Boolean(anchorEl);
  const id = open ? 'non-call-period-popover' : undefined;

  const minInputValue = !isSliderMinValue ? sliderMin : '';
  const maxInputValue = !isSliderMaxValue ? sliderMax : '';

  const formattedButtonText = useSliderText({
    minValue: NON_CALL_PERIOD_SLIDER_MIN_VALUE,
    maxValue: NON_CALL_PERIOD_SLIDER_MAX_VALUE,
    suffix: translations.dynamicFilters.common.dateSliderSuffix,
    leftSliderValue: sliderMin,
    rightSliderValue: sliderMax,
  });

  const handlePopoverOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleDoneClick = () => {
    if (isSliderMinValue && isSliderMaxValue) setSideBarFilters({ tag, nonCallPeriod: null });
    else setSideBarFilters({ tag, nonCallPeriod: { min: sliderMin, max: sliderMax } });
    handlePopoverClose();
  };

  const handleSliderChange = (_: unknown, value: number | Array<number>) => {
    const updatedSliderMin = typeof value === 'number' ? value : value[0];
    const updatedSliderMax = typeof value === 'number' ? value : value[1];
    setSliderData({ min: updatedSliderMin, max: updatedSliderMax });
  };

  const handleMinInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const inputValue = value ? parseInt(event.target.value) : NON_CALL_PERIOD_SLIDER_MIN_VALUE;

    const filteredValue =
      inputValue <= NON_CALL_PERIOD_SLIDER_MIN_VALUE ? NON_CALL_PERIOD_SLIDER_MIN_VALUE : inputValue;

    setSliderData({ min: filteredValue, max: sliderMax });
  };

  const handleMaxInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const inputValue = value ? parseInt(event.target.value) : NON_CALL_PERIOD_SLIDER_MAX_VALUE;

    const filteredValue =
      inputValue >= NON_CALL_PERIOD_SLIDER_MAX_VALUE ? NON_CALL_PERIOD_SLIDER_MAX_VALUE : inputValue;

    setSliderData({ min: sliderMin, max: filteredValue });
  };

  useEffect(() => {
    const resetValue = nonCallPeriod === null;
    const hasValueChanged = nonCallPeriod?.max !== sliderData.max || nonCallPeriod?.min !== sliderData.min;
    if (resetValue) setSliderData(DEFAULT_SLIDER_DATA);
    else if (hasValueChanged) setSliderData(nonCallPeriod);
  }, [nonCallPeriod]);

  return (
    <CollapsibleFilters title={DynamicTypeFiltersSideBarTitlesDict.nonCallPeriod}>
      <PopoverRangeFilter
        suffix={translations.dynamicFilters.common.dateSliderSuffix}
        maxInputValue={maxInputValue}
        minInputValue={minInputValue}
        PopoverProps={{
          id,
          open,
          anchorEl,
          onClose: handlePopoverClose,
        }}
        SliderProps={{
          onChange: handleSliderChange,
          max: NON_CALL_PERIOD_SLIDER_MAX_VALUE,
          min: NON_CALL_PERIOD_SLIDER_MIN_VALUE,
          step: 1,
          value: sliderValue,
        }}
        buttonText={formattedButtonText}
        handleMinInputChange={handleMinInputChange}
        handleMaxInputChange={handleMaxInputChange}
        handlePopoverOpen={handlePopoverOpen}
        handleDoneClick={handleDoneClick}
      />
    </CollapsibleFilters>
  );
};
