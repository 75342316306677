import { ReactElement } from 'react';

import { orderTicketReceiptAtom } from '@halo-atoms/orderTicket';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { useWhiteLabelDateConfiguration, useWhiteLabelRole } from '@halo-common/hooks';
import { OrderTicketAlert } from '@halo-common/modals';
import { useAtomValue } from 'jotai';

export const OrderTicketStatusAlert = (): ReactElement | null => {
  const whiteLabelRole = useWhiteLabelRole();
  const configureDateTime = useWhiteLabelDateConfiguration();

  const orderTicketReceipt = useAtomValue(orderTicketReceiptAtom);

  const status = orderTicketReceipt?.status;
  const orderReceiptTime = orderTicketReceipt?.order?.orderReceiptTime;
  const execOrderNumber = orderTicketReceipt?.order?.id;

  if (!status || !orderReceiptTime) return null;

  const isJanney = whiteLabelRole === 'janney';

  const orderInfo = isJanney ? `Your Order Number is ${execOrderNumber}. ` : '';
  const orderDate = configureDateTime(orderReceiptTime);
  const date = orderDate?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
  const time = orderDate?.toFormat('hh:mm a ZZZZ');

  let alertText = `Submitted on ${date} at ${time}. ${orderInfo}Please monitor your order book for execution.`;
  if (status === 'warning') alertText = `Violation(s) has/have occurred. Please monitor your order book for execution.`;
  else if (status === 'error') alertText = 'Interest was not completed due to an error.';

  return <OrderTicketAlert status={status}>{alertText}</OrderTicketAlert>;
};
