import { ReactElement } from 'react';

import { Stack, StackProps, Skeleton } from '@mui/material';

export interface PortfolioEventsLoadingSkeletonProps extends Partial<StackProps> {
  items?: number;
}

export const PortfolioEventsLoadingSkeleton = ({
  items = 7,
  ...props
}: PortfolioEventsLoadingSkeletonProps): ReactElement => (
  <Stack direction="column" spacing={2} sx={{ width: '100%' }} {...props}>
    {[...Array(items)].map((_, index) => (
      <Skeleton key={index} width="100%" height={72} variant="rounded" />
    ))}
  </Stack>
);
