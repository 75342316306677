export const PROFILE_DETAILS_TRANSLATIONS = {
  alphaNumericOnly: 'This must only contain numbers and letters',
  changePassword: 'Change Password',
  contact: 'Contact',
  detailsSubtitle: `Edit your account or change your account password.\nTo update your email, contact us.`,
  exactCharacters: 'This must be {maxCharacters} characters.',
  firstName: 'First Name',
  firstNameError: 'Please enter a first name.',
  langPrefMessage: `The language set here will apply across the whole platform. Choosing a new language will reload the whole page.`,
  languagePreference: 'Language Preference',
  lastName: 'Last Name',
  lastNameError: 'Please enter a last name.',
  maxCharacters: 'This must not be more than {maxCharacters} characters.',
  minCharacters: 'This must be more than {minCharacters} characters.',
  phoneNumber: 'Phone Number',
  phoneNumberError: 'Phone number is not valid.',
  relationshipManager: 'Your Relationship Manager',
  relationshipSubtitle: "If you're having trouble with your account, please contact us.",
  updateFailure: 'We were unable to update your account information.',
  updateSuccess: 'You have successfully updated your account information.',
};
