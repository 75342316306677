import { ReactElement } from 'react';

import { ProductDetailTable } from '@halo-common/components';
import { AnnuityOrderModel } from '@halo-common/models';
import { HaloDataGridProps, useCurrencyConverter } from '@halodomination/halo-fe-common';

export type PostTradeAnnuityDetailFeaturesTableProps = {
  product?: AnnuityOrderModel | null;
  loading: boolean;
};

export const PostTradeAnnuityDetailFeaturesTable = ({
  product,
  loading,
}: PostTradeAnnuityDetailFeaturesTableProps): ReactElement => {
  const features = product?.features?.map((feature, index) => ({ ...feature, id: index })) ?? [];

  const commonCellProps = {
    editable: false,
    resizable: false,
    disableReorder: true,
    disableColumnMenu: true,
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      field: 'type',
      headerName: 'type',
      flex: 3,
    },
    {
      ...commonCellProps,
      field: 'subtype',
      headerName: 'sub-type',
      flex: 3,
    },
    {
      ...commonCellProps,
      field: 'value',
      headerName: 'value',
      flex: 2,
      valueFormatter: (value: string) => {
        const numVal = Number(value);
        if (!numVal) return 'None';
        return useCurrencyConverter(numVal);
      },
    },
    {
      ...commonCellProps,
      field: 'expenseType',
      headerName: 'expense type',
      flex: 2,
      valueFormatter: (value: string) => {
        if (!value) return 'None';
        return value.replace('_', ' ');
      },
    },
    {
      ...commonCellProps,
      field: 'expenseValue',
      headerName: 'expense value',
      flex: 2,
      valueFormatter: (value: string) => {
        const numVal = Number(value);
        if (!numVal) return 'None';
        return useCurrencyConverter(numVal);
      },
    },
  ];

  return <ProductDetailTable hideFooter columns={columns} rows={features} loading={loading} />;
};
