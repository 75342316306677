import { ReactElement } from 'react';

import { COMBINED_SIMPLE_DATE_TIME_FORMAT } from '@halo-common/constants';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { CalendarModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { LocalizedTextField, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { useController, useFormContext } from 'react-hook-form';

import { ProductApprovalEditCloseDateFormFields } from '../ProductApprovalEditCloseDateModal';

export type ProductApprovalEditCloseDateSingleFormProps = {
  calendar?: CalendarModel | null;
  error?: Error | null;
};

export const ProductApprovalEditCloseDateSingleForm = ({
  calendar,
  error,
}: ProductApprovalEditCloseDateSingleFormProps): ReactElement => {
  const configureDateTime = useWhiteLabelDateConfiguration();

  const formMethods = useFormContext<ProductApprovalEditCloseDateFormFields>();

  const datePickerMethods = useController({
    control: formMethods.control,
    name: 'date',
  });

  const { onChange: onDateChange, value } = datePickerMethods.field;

  const selectedDate = value ? DateTime.fromISO(value) : null;

  const dateError = datePickerMethods.fieldState.error ?? error ?? undefined;

  const minDateTime = configureDateTime(calendar?.startShowingDate) ?? undefined;
  const maxDateTime = configureDateTime(calendar?.originalExpirationDate) ?? undefined;
  const originalCloseDateText = maxDateTime?.toFormat(COMBINED_SIMPLE_DATE_TIME_FORMAT) ?? '-';

  const handleDateChange = (value: DateTime | null) => {
    const updatedDateISO = value?.toISO() ?? '';
    onDateChange(updatedDateISO);
  };

  return (
    <Stack direction="row" alignItems="center" spacing={2} xs={6}>
      <Stack direction="column" spacing={1}>
        <LocalizedTypography variant="h6">
          {translations.productApproval.editCloseDateModal.issuerCloseTimeTitle}
        </LocalizedTypography>
        <Typography>{originalCloseDateText}</Typography>
      </Stack>
      <DateTimePicker
        value={selectedDate}
        onChange={handleDateChange}
        label={translations.productApproval.editCloseDateModal.modifyCloseDateLabel}
        disableIgnoringDatePartForTimeValidation
        closeOnSelect
        minDateTime={minDateTime}
        maxDateTime={maxDateTime}
        slots={{
          textField: (props) => (
            <LocalizedTextField
              {...props}
              fullWidth
              size="large"
              error={Boolean(dateError)}
              helperText={dateError?.message}
            />
          ),
        }}
      />
    </Stack>
  );
};
