import { ReactElement } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { useSliderText } from '@halo-common/hooks';
import { UNDERLYING_SLIDER_MAX_VALUE, UNDERLYING_SLIDER_MIN_VALUE } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { Chip, SxProps } from '@mui/material';
import { useAtom } from 'jotai';

export type UnderlyingCountChipProps = {
  tag: string;
  sx: SxProps;
};

export const UnderlyingCountChip = ({ tag, sx }: UnderlyingCountChipProps): ReactElement | null => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);

  const label = useSliderText({
    prefix: translations.dynamicFilters.common.assetCountFilter,
    minValue: UNDERLYING_SLIDER_MIN_VALUE,
    maxValue: UNDERLYING_SLIDER_MAX_VALUE,
    leftSliderValue: sideBarFilters.underlyingCount?.min ?? 0,
    rightSliderValue: sideBarFilters.underlyingCount?.max ?? 10,
  });

  const handleUnderlyingCountDelete = () => {
    setSideBarFilters({ tag, underlyingCount: null });
  };

  if (!sideBarFilters.underlyingCount) return null;

  return <Chip sx={sx} size="small" variant="outlined" onDelete={handleUnderlyingCountDelete} label={label} />;
};
