import { ReactElement } from 'react';

import { portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import { UserPortfolioAccessEnum } from '@halo-common/enums';
import { useDesktopView } from '@halo-common/hooks';
import { usePortfolioPositionAggregationTemplatesQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import {
  PortfolioAllPositionsTable,
  PortfolioPositionsAggregateChart,
  PortfolioPositionsAggregateTable,
  PortfolioPositionsBetaAlert,
  PortfolioPositionsDetailsTable,
  PortfolioPositionsTypeFilterBar,
} from '@halo-modules/app';
import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';

export const PortfolioPositionsPage = (): ReactElement => {
  const isDesktop = useDesktopView();

  const { showAggregate } = useAtomValue(portfolioPositionsManagerAtom);

  const { data: user } = useUserInfoQuery();
  const { isPending: templatesLoading } = usePortfolioPositionAggregationTemplatesQuery();

  const hasPortfolioAccess = user?.details?.portfolioAccess === UserPortfolioAccessEnum.BOTH;

  const portfolioTable = showAggregate ? (
    <PortfolioPositionsAggregateTable loading={templatesLoading} />
  ) : (
    <PortfolioPositionsDetailsTable loading={templatesLoading} />
  );

  const portfolioPositionsBetaAlert = hasPortfolioAccess ? <PortfolioPositionsBetaAlert /> : null;
  const portfolioAllPositionsTable = showAggregate ? <PortfolioAllPositionsTable loading={templatesLoading} /> : null;
  const portfolioPositionsAggregateChart = isDesktop ? <PortfolioPositionsAggregateChart /> : null;

  return (
    <>
      <PortfolioPositionsTypeFilterBar />
      <Stack direction="column" spacing={2}>
        {portfolioPositionsAggregateChart}
        {portfolioTable}
        {portfolioAllPositionsTable}
      </Stack>
      {portfolioPositionsBetaAlert}
    </>
  );
};
