import { ReactElement } from 'react';

import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Chip, Stack, SxProps } from '@mui/material';

import { LocalizedTypographyWithTooltip } from './LocalizedTypographyWithTooltip';

const titleSx = {
  lineHeight: 1,
  pr: 1,
  pt: 0.5,
};

const chipSx = {
  backgroundColor: 'common.charts.accent2.background',
  borderRadius: 1,
  height: 24,
};

const typographySx = {
  fontSize: '10px',
  fontWeight: 600,
};

const containerSx = {
  flex: 0.25,
};

export type PortfolioEventsListItemColumnProps = {
  title: string;
  subtitle: string;
  ellipsis?: boolean;
  previousDate?: boolean;
  hasAlert?: boolean;
  chipLabel?: string;
  sx?: SxProps;
};

export const PortfolioEventsListItemColumn = ({
  title,
  subtitle,
  ellipsis,
  previousDate,
  hasAlert,
  chipLabel,
  sx,
}: PortfolioEventsListItemColumnProps): ReactElement => {
  const containerStyling = sx ?? containerSx;
  const titleTextColor = hasAlert ? 'error.main' : previousDate ? 'text.secondary' : undefined;

  const SubTitle = ellipsis ? LocalizedTypographyWithTooltip : LocalizedTypography;

  const labelComponent = <LocalizedTypography sx={typographySx}>{chipLabel}</LocalizedTypography>;
  const chip = chipLabel ? <Chip sx={chipSx} label={labelComponent} /> : null;

  return (
    <Stack sx={containerStyling} direction="column">
      <Stack direction="row" alignItems="center" flexWrap="wrap-reverse">
        <LocalizedTypography color={titleTextColor} sx={titleSx} variant="h6">
          {title}
        </LocalizedTypography>
        {chip}
      </Stack>
      <SubTitle color="text.secondary" variant="body2" fontWeight={600}>
        {subtitle}
      </SubTitle>
    </Stack>
  );
};
