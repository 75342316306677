import { ReactElement } from 'react';

import { useRecentQuotesQuery, useWatchlistTargetedNotesQuery } from '@halo-data-sources/queries';
import {
  TargetedWatchlistCardStack,
  TargetedWatchlistEmptyMessage,
  TargetedWatchlistHeader,
  TargetedWatchlistSkeleton,
} from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';

export type TargetedWatchlistProps = {
  onRemove: (targetNoteId?: number) => void;
};

export const TargetedWatchlist = ({ onRemove }: TargetedWatchlistProps): ReactElement => {
  const { data, isPending, isSuccess, isError } = useWatchlistTargetedNotesQuery();

  const targets = data ?? [];
  const targetCount = targets.length;
  const productIds = targets.map((target) => target.product?.id);

  const loaded = isSuccess || isError;
  const isEmptyTargetedWatchlist = loaded && targetCount === 0;

  useRecentQuotesQuery(productIds);

  if (isPending) return <TargetedWatchlistSkeleton />;

  const displayTargetedNotes = isEmptyTargetedWatchlist ? (
    <TargetedWatchlistEmptyMessage />
  ) : (
    <TargetedWatchlistCardStack targets={targets} onRemove={onRemove} />
  );

  return (
    <Stack direction="column" spacing={4}>
      <TargetedWatchlistHeader count={targetCount} />
      {displayTargetedNotes}
    </Stack>
  );
};
