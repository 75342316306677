import { ReactElement } from 'react';

import { PortfolioCashflowEventEnum, PortfolioPeriodEnum } from '@halo-common/enums';
import { useTranslateMonthlyLabel } from '@halo-common/hooks';
import type { CurrencyModel, PortfolioCashflowEventChartDataModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { BarChart, BarChartBarDefinition, mapNumberToLocalCurrency } from '@halodomination/halo-fe-common';
import { Paper, PaperProps, Skeleton, capitalize } from '@mui/material';
import { useT } from '@transifex/react';

const bars: Array<BarChartBarDefinition> = Object.values(PortfolioCashflowEventEnum).map((value) => ({
  label: value,
  stackId: 'a',
}));

const yAxisProps = {
  tickCount: 4,
};

export type PortfolioCashflowBarChartProps = PaperProps & {
  chartData?: PortfolioCashflowEventChartDataModel;
  height?: number | string;
  width?: number | string;
  loading?: boolean;
  currency: CurrencyModel | null | undefined;
  period: PortfolioPeriodEnum;
  activeCategory?: string | string[];
};

export const PortfolioCashflowBarChart = ({
  chartData,
  height = 400,
  width = '100%',
  loading = false,
  currency,
  period,
  activeCategory,
  sx,
  ...props
}: PortfolioCashflowBarChartProps): ReactElement => {
  const translator = useT();
  const translateMonthlyLabel = useTranslateMonthlyLabel();

  const paperSx = {
    backgroundColor: 'common.white',
    justifyContent: 'center',
    height,
    width,
    pt: 2,
    pr: 2,
    pb: 0,
    pl: 0,
    ...sx,
  };

  const currencySymbol = currency?.symbol ?? '';
  const currencyCode = currency?.code ?? '';

  const data = chartData?.data ?? [];
  const currentPeriod = chartData?.period ?? period;

  const tooltipValueFormatter = (value: string | number) => {
    const fullValue = typeof value === 'string' ? parseInt(value) : value;
    return mapNumberToLocalCurrency(fullValue, { currency: currencyCode, maximumFractionDigits: 0 }, currencySymbol);
  };

  const eventNameFormatter = (label: string) => {
    const parsedLabel = label as PortfolioCashflowEventEnum;

    if (parsedLabel === PortfolioCashflowEventEnum.Expiration) return translations.common.maturity;
    if (parsedLabel === PortfolioCashflowEventEnum.Autocall) return translations.common.autocall;

    const splitLabel = label.toLowerCase().split('_');
    const capitalizedLabel = splitLabel.map((label) => capitalize(label));

    return capitalizedLabel.join(' ');
  };

  const periodNameFormatter = (label: string | number) => {
    const isLabelString = typeof label === 'string';

    const parsedLabel = isLabelString
      ? capitalize(
          {
            [PortfolioPeriodEnum.Monthly]: translateMonthlyLabel(label),
            [PortfolioPeriodEnum.Quarterly]: label.split('-').reverse().join(' '),
            [PortfolioPeriodEnum.Annually]: label,
          }[currentPeriod],
        )
      : label.toString();

    return parsedLabel;
  };

  const yAxisFormatter = (label: string | number) => {
    const value = typeof label === 'number' ? label / 1000 : parseInt(label) / 1000;
    return value.toLocaleString();
  };

  const xLabel = {
    formatter: periodNameFormatter,
  };

  const yLabel = {
    label: translator(translations.portfolio.lifecycle.chartYLabel, { currencySymbol: currencySymbol }),
    formatter: yAxisFormatter,
  };

  if (loading) return <Skeleton width={width} height={height} variant="rounded" />;

  return (
    <Paper sx={paperSx} variant="outlined" {...props}>
      <BarChart
        xLabel={xLabel}
        yLabel={yLabel}
        height="100%"
        width="100%"
        showLegend
        data={data}
        bars={bars}
        YAxisProps={yAxisProps}
        legendFormatter={eventNameFormatter}
        tooltipLabelFormatter={periodNameFormatter}
        tooltipNameFormatter={eventNameFormatter}
        tooltipValueFormatter={tooltipValueFormatter}
        activeCategory={activeCategory}
      />
    </Paper>
  );
};
