import { getClientConfiguration, request } from '@halo-data-sources/clients';
import { ApiFeaturedCalendarResponseModel, ApiQueryRequestModel } from '@halo-data-sources/models';

const EXEC_FEATURED_CALENDAR_PATH = '/execution/featured-calendar';

export const getFeaturedCalendarQuery = async (
  payload?: ApiQueryRequestModel,
): Promise<ApiFeaturedCalendarResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXEC_FEATURED_CALENDAR_PATH);

  const path = `${clientConfiguration.basePath}/query`;

  return request(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });
};
