import { useCallback, useEffect } from 'react';

import { CourseManagerResult, courseManagerAtom } from '@halo-atoms/education';
import { EducationModuleGroup, ModuleModel, ModuleStepModel } from '@halo-common/models';
import { navigateParentTo } from '@halo-common/utils';
import { useEducationModuleGroupsQuery, UseEducationModuleGroupsQueryResult } from '@halo-data-sources/queries';
import { CourseModeEnum } from '@halo-modules/app';
import { useAtom } from 'jotai';
import { useRouter } from 'next/router';

export type CourseManagerModel = Omit<UseEducationModuleGroupsQueryResult, 'data'> & {
  progress: CourseManagerResult;
  courseGroups: Array<EducationModuleGroup>;
  courseCompletion: number;
  onStart: (selectedGroup: EducationModuleGroup) => void;
  onContinue: (selectedGroup: EducationModuleGroup) => void;
  onNextGroup: (selectedGroup: EducationModuleGroup) => void;
  onNext: () => void;
  onReset: () => void;
  onPrevious: () => void;
  onSelection: (
    selectedGroup: EducationModuleGroup,
    selectedModule: ModuleModel,
    selectedStep: ModuleStepModel,
  ) => void;
};

export const useCourseManager = (): CourseManagerModel => {
  const router = useRouter();
  const { data, ...query } = useEducationModuleGroupsQuery();

  const [courseProgress, setCourseProgress] = useAtom(courseManagerAtom);

  const setCourseProgressAndNavigateToEducationPage: typeof setCourseProgress = useCallback(
    (...props) => {
      setCourseProgress(...props);

      const isEducationPage =
        router.pathname === '/education' ||
        router.pathname === '/app/education' ||
        router.pathname === '/react/app/education';

      if (!isEducationPage) navigateParentTo('/education');
    },
    [router, setCourseProgress],
  );

  const handleStart = (selectedGroup: EducationModuleGroup) => {
    const updatedProgress = {
      isStart: true,
      mode: CourseModeEnum.Explorer,
      group: selectedGroup,
      module: selectedGroup.modules?.[0] ?? null,
    };

    setCourseProgressAndNavigateToEducationPage({ progress: updatedProgress, type: 'set' });
  };

  const handleContinue = (selectedGroup: EducationModuleGroup) => {
    const modules = selectedGroup?.modules ?? [];
    const nextModule = modules.find((module) => module.steps.some(({ completed }) => !completed)) ?? null;

    const steps = nextModule?.steps ?? [];
    const nextStep = steps.find(({ completed }) => !completed) ?? null;

    const updatedMode = nextStep?.type === 'quiz' ? CourseModeEnum.QuizManager : CourseModeEnum.Explorer;

    const updatedProgress = {
      isStart: false,
      mode: updatedMode,
      group: selectedGroup,
      module: nextModule,
      step: nextStep,
    };

    setCourseProgressAndNavigateToEducationPage({ progress: updatedProgress, type: 'set' });
  };

  const handleSelection = (
    selectedGroup: EducationModuleGroup,
    selectedModule: ModuleModel,
    selectedStep: ModuleStepModel,
  ) => {
    const updatedMode = selectedStep.type === 'quiz' ? CourseModeEnum.QuizManager : CourseModeEnum.Explorer;

    const updatedProgress = {
      isStart: false,
      mode: updatedMode,
      group: selectedGroup,
      module: selectedModule,
      step: selectedStep,
    };

    setCourseProgress({ progress: updatedProgress, type: 'set' });
  };

  const handleNextGroup = (selectedGroup: EducationModuleGroup) => {
    if (!selectedGroup) setCourseProgressAndNavigateToEducationPage({ type: 'reset' });
    else handleContinue(selectedGroup);
  };

  const handleNext = () => {
    setCourseProgressAndNavigateToEducationPage({ type: 'next' });
  };

  const handlePrevious = () => {
    setCourseProgressAndNavigateToEducationPage({ type: 'previous' });
  };

  const handleReset = () => {
    setCourseProgressAndNavigateToEducationPage({ type: 'reset' });
  };

  useEffect(() => {
    const shouldUpdateProgress = query.isFetched && courseProgress.overallCompletion === null;
    if (shouldUpdateProgress) setCourseProgress({ progress: { overallCompletion: data?.progress }, type: 'set' });
  }, [query.isFetched, courseProgress.overallCompletion]);

  return {
    ...query,
    progress: courseProgress,
    courseGroups: data?.groups ?? [],
    courseCompletion: data?.progress ?? 0,
    onReset: handleReset,
    onContinue: handleContinue,
    onNextGroup: handleNextGroup,
    onNext: handleNext,
    onPrevious: handlePrevious,
    onStart: handleStart,
    onSelection: handleSelection,
  };
};
