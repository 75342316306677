import { ReactElement, SyntheticEvent } from 'react';

import { CountryModel } from '@halo-common/models';
import { useCountryQuery } from '@halo-data-sources/queries';
import { RegisterFormSchema } from '@halo-modules/app';
import { Autocomplete, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export const CountrySelect = (): ReactElement => {
  const {
    control,
    formState: { errors },
  } = useFormContext<RegisterFormSchema>();

  const { data: countries = [] } = useCountryQuery();

  const stateError = errors?.state?.message;
  const getOptionLabel = (value: CountryModel) => value.label;

  return (
    <Controller
      render={({ field: { onChange, value, ...field } }) => {
        const countryValue = countries.find((country) => country.isoCode === value);

        const selectHandler = (_: SyntheticEvent, data: string | CountryModel | null) => {
          const selectedValue = typeof data === 'string' ? data : data?.isoCode;
          const selectedCountry = countries.find((country) => country.isoCode === selectedValue);
          onChange(selectedCountry?.id.toString());
        };

        return (
          <Autocomplete
            {...field}
            openOnFocus
            value={countryValue}
            options={countries}
            onChange={selectHandler}
            getOptionLabel={getOptionLabel}
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                slotProps={{ htmlInput: inputProps }}
                inputRef={params.InputProps.ref}
                size="large"
                label="Country"
                fullWidth
                helperText={stateError}
                error={Boolean(stateError)}
              />
            )}
          />
        );
      }}
      defaultValue={''}
      control={control}
      name="country"
    />
  );
};
