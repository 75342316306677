import { ProductTypeEnum, WatchlistIconNamesEnum } from '@halo-common/enums';
import {
  WatchlistDetailsProductModel,
  WatchlistModel,
  WatchlistTargetHistoryChartModel,
  WatchlistTargetQuoteDataModel,
  WatchlistTargetQuoteModel,
} from '@halo-common/models';
import { ApiWatchlistIconNames, ApiWatchlistIconNamesEnum } from '@halo-data-sources/enums';
import { ApiAnnuityMapper, ApiQuoteMapper, mapApiNoteModelToNoteModel } from '@halo-data-sources/mappers';
import {
  ApiAnnuityModel,
  ApiCreateNoteWatchlistResponseModel,
  ApiDetailedWatchlistModel,
  ApiNoteModel,
  ApiTargetHistoryResponseModel,
  ApiTargetModel,
  ApiTargetQuoteSet,
  ApiUpdateWatchlistResponseModel,
  ApiWatchlistDetailsProductModel,
  ApiWatchlistModel,
} from '@halo-data-sources/models';
import { IconographyProps } from '@halodomination/halo-fe-common';
import { DateTime } from 'luxon';

export const stripWatchlistPrefix = (name: string | undefined): string | undefined => {
  if (!name) return undefined;
  return name.startsWith('WatchlistIcon.') ? name.slice(14, name.length) : name;
};

export interface ApiWatchlistMapper {
  toTargetedQuoteModel(model: ApiTargetModel): WatchlistTargetQuoteModel;
  toWatchlistModel(model: ApiWatchlistModel): WatchlistModel;
  toWatchlistModel(model: ApiDetailedWatchlistModel): WatchlistModel;
  toWatchlistModel(model: ApiCreateNoteWatchlistResponseModel): WatchlistModel;
  toWatchlistModel(model: ApiUpdateWatchlistResponseModel): WatchlistModel;
  toWatchlistTargetQuoteModel(model: ApiTargetQuoteSet): WatchlistTargetQuoteDataModel;
  toWatchlistDetailsProductModel(model: ApiWatchlistDetailsProductModel): WatchlistDetailsProductModel;
  toWatchlistTargetHistoryChartDataModel(model: ApiTargetHistoryResponseModel): WatchlistTargetHistoryChartModel;
  toWatchlistTargetedNoteModel(model: ApiTargetModel): WatchlistDetailsProductModel;
}

export const ApiWatchlistMapper: ApiWatchlistMapper = {
  toTargetedQuoteModel(model) {
    return {
      best: model.best ? ApiQuoteMapper.toQuoteModel(model.best) : null,
      haloUserId: model.halo_user_id,
      id: model.id,
      name: model.name,
      note: mapApiNoteModelToNoteModel(model.note),
      targetLevel: model.target_level,
    };
  },
  toWatchlistModel(model) {
    const userId = (model as ApiWatchlistModel)?.halo_user?.id;
    const iconName =
      (stripWatchlistPrefix((model as ApiWatchlistModel)?.icon) as ApiWatchlistIconNames) ??
      ('ACORN' as ApiWatchlistIconNames);
    const icon = ApiWatchlistIconNamesEnum[iconName];
    const count = (model as ApiWatchlistModel)?.count ?? 0;
    const isDefault = (model as ApiWatchlistModel)?.is_default ?? false;

    return {
      count,
      userId,
      id: model.id,
      name: model.name,
      default: isDefault,
      icon,
    };
  },
  toWatchlistTargetQuoteModel(model) {
    const status = model?.status ?? null;

    return {
      status,
      target: model.target ? ApiWatchlistMapper.toTargetedQuoteModel(model.target) : null,
      targets: model.targets.map((target) => ApiWatchlistMapper.toTargetedQuoteModel(target)),
    };
  },
  toWatchlistDetailsProductModel(model) {
    const { id, product, type } = model;

    let productDetail;
    if (type === ProductTypeEnum.note) {
      productDetail = mapApiNoteModelToNoteModel(product as ApiNoteModel);
    } else {
      productDetail = ApiAnnuityMapper.toAnnuityModel(product as ApiAnnuityModel);
    }

    return {
      hasQuote: false,
      id,
      product: productDetail,
      rfqId: null,
      rfqMessages: 0,
      type,
    };
  },
  toWatchlistTargetHistoryChartDataModel(model) {
    return {
      x: model.dates.map((date) => DateTime.fromISO(date).toSeconds()),
      quotes: model.quotes,
      reference: model.level,
    };
  },
  toWatchlistTargetedNoteModel(model) {
    return {
      id: model.id,
      product: mapApiNoteModelToNoteModel(model.note),
      targetLevel: model.target_level,
      hasQuote: false,
      rfqMessages: 0,
      rfqId: null,
      type: ProductTypeEnum.note,
    };
  },
};

export interface WatchlistMapper {
  toApiWatchlistIconName(model: IconographyProps['iconName']): WatchlistIconNamesEnum;
}

export const WatchlistMapper: WatchlistMapper = {
  toApiWatchlistIconName(iconName) {
    if (!iconName) return WatchlistIconNamesEnum['acorn'];
    else return WatchlistIconNamesEnum[iconName as keyof typeof WatchlistIconNamesEnum];
  },
};
