import { ReactElement } from 'react';

import { EducationVideoPlayer } from '@halo-modules/app';
import { Stack, Typography } from '@mui/material';

export type EducationVideoProps = {
  videoUrl: string;
  title?: string;
  caption?: string;
};

export const EducationVideo = ({ title, videoUrl, caption }: EducationVideoProps): ReactElement => {
  const titleContent = title ? (
    <Typography color="textPrimary" variant="h5">
      {title}
    </Typography>
  ) : null;

  const captionContent = caption ? (
    <Typography color="textSecondary" variant="caption" data-testid="education-video-caption">
      {caption}
    </Typography>
  ) : null;

  return (
    <Stack direction="column" justifyContent="space-between" spacing={1} sx={{ width: '100%' }}>
      {titleContent}
      <EducationVideoPlayer path={videoUrl} />
      {captionContent}
    </Stack>
  );
};
