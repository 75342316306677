/* eslint-disable @typescript-eslint/no-explicit-any */

import { isInDevBuildEnv } from '@halodomination/halo-fe-common';
import { isObjectLike, isString } from 'lodash';

type InboundEventTypes = 'AUTHENTICATION' | 'SET_PASSWORD_TOKEN';
type OutboundEventTypes =
  | 'LOG_ACTIVITY'
  | 'VIEW_PDF'
  | 'CHAT_WITH_EXPERT'
  | 'REACT_MODAL_CLOSE'
  | 'REPLACE_HISTORY_STATE'
  | 'CALENDAR_ORDER_SUBMISSION_SUCCESS';

export type IMessageEventAction<TData, TType extends string> = {
  type: TType;
  payload?: TData;
};

export type PostMessageService = {
  subscribe: (listeners: Record<InboundEventTypes, (payload?: any) => void>) => void;
  publish: (event: { type: OutboundEventTypes; payload: any }, targetOrigin?: string, callback?: () => void) => void;
};

export const postMessageService = (targetWindow: Window): PostMessageService => ({
  subscribe: (listeners) => {
    targetWindow.addEventListener('message', (event) => {
      if (event.origin !== targetWindow.origin && !isInDevBuildEnv()) return;

      if (!isObjectLike(event.data)) return;

      const action = event.data as Record<string, unknown>;

      if (!isString(action.type)) return;

      const type = action.type as InboundEventTypes;

      listeners[type]?.(action?.payload);
    });
  },
  publish: (event, targetOrigin = '*', callback) => {
    targetWindow.postMessage(event, targetOrigin);

    if (callback) window.setTimeout(() => callback(), 250);
  },
});
