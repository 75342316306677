import { ReactElement, SyntheticEvent } from 'react';

import { UnderlyingGlyphTooltip } from '@halo-common/components';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { ConditionalWrapper, useCombinedStyling } from '@halodomination/halo-fe-common';
import { Box, SxProps, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

const tooltipSx = {
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 500,
    maxWidth: 700,
  },
};

const UNDERLYING_GLYPH_Y_POSITION = -9;

export type UnderlyingGlyphProps = {
  sx?: SxProps;
  ticker?: string | null;
  format?: string | null;
  description?: string | null;
  units?: number | null;
  value?: number | null;
  weight?: number;
  tooltip: boolean;
  size: 'small' | 'large';
};

export const UnderlyingGlyph = ({
  sx,
  ticker,
  weight,
  tooltip,
  size,
  ...props
}: UnderlyingGlyphProps): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();

  const staticAssetUrl = userInfo?.configuration.staticAssetUrl;
  const src = `${staticAssetUrl}/assets/img/symbols/${ticker}.svg`;
  const isSmall = size === 'small';

  const glyphLabelSx: SxProps = {
    backgroundColor: 'common.white',
    color: 'grey.500',
    fontSize: isSmall ? 10 : 12,
    fontWeight: 'fontWeightBold',
    letterSpacing: 0,
    position: 'absolute',
    px: 0.5,
    py: 0,
    borderRadius: 1,
  };

  const glyphImageSx: SxProps = {
    backgroundColor: 'transparent',
    maxWidth: isSmall ? 52 : 64,
    maxHeight: isSmall ? 52 : 64,
    width: 'fit-content',
    borderRadius: 0.5,
  };

  const glyphContainerSx: SxProps = {
    alignItems: 'center',
    display: 'flex',
    border: '1px solid',
    borderColor: 'grey.300',
    borderRadius: 1,
    flexDirection: 'column',
    width: isSmall ? 60 : 72,
    height: isSmall ? 60 : 72,
    justifyContent: 'center',
    position: 'relative',
    '&::before': {
      ...glyphLabelSx,
      top: UNDERLYING_GLYPH_Y_POSITION,
      content: `"${ticker}"`,
    },
  };

  if (weight) {
    glyphContainerSx['&::after'] = {
      ...glyphLabelSx,
      bottom: UNDERLYING_GLYPH_Y_POSITION,
      content: `"${weight.toFixed(2)}%"`,
    };
  }

  const combinedContainerStyling = useCombinedStyling(glyphContainerSx, sx);

  const handleError = (event: SyntheticEvent<HTMLImageElement>) => {
    (event.target as HTMLImageElement).style.opacity = '0';
  };

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(tooltipSx);

  const title = tooltip ? <UnderlyingGlyphTooltip {...props} ticker={ticker} weight={weight} /> : null;

  return (
    <ConditionalWrapper<TooltipProps> condition={tooltip} Wrapper={StyledTooltip} title={title}>
      <Box sx={combinedContainerStyling}>
        <Box component="img" alt="-" sx={glyphImageSx} src={src} onError={handleError} />
      </Box>
    </ConditionalWrapper>
  );
};
