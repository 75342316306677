import { CalendarModel, OrderTicketReceiptModel } from '@halo-common/models';
import { BulkOrderTicketFormFields } from '@halo-common/schemas';
import { postBulkFixAllocationPlace } from '@halo-data-sources/clients';
import { BulkAllocationError } from '@halo-data-sources/errors';
import { ApiAllocationMapper, BulkAllocationMapper } from '@halo-data-sources/mappers';
import { DateTime } from 'luxon';

export const pershingOrderTicketBulkSubmissionSwitchFn = async (
  calendar: CalendarModel,
  data?: BulkOrderTicketFormFields,
): Promise<OrderTicketReceiptModel | null> => {
  if (!data) return null;

  const payload = BulkAllocationMapper.toApiPershingBulkAllocationValidationRequest(calendar, data, DateTime.now());

  const response = await postBulkFixAllocationPlace(payload);

  const result = ApiAllocationMapper.toOrderTicketReceipt(response);

  if (result.message) throw new BulkAllocationError(result.message, result.allocations);
  else return result;
};
