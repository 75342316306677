import { translations } from '@halo-common/translations';
import { DateRange } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import {
  array as yupArray,
  mixed as yupMixed,
  number as yupNumber,
  object as yupObject,
  string as yupString,
} from 'yup';

const underlyingsRequiredText = 'This field must have at least one underlying.';

export const historicalPerformanceSearchSchema = yupObject().shape({
  underlyings: yupArray()
    .of(yupString().defined(translations.messages.requiredField))
    .min(1, underlyingsRequiredText)
    .required(underlyingsRequiredText),
  date: yupMixed<DateRange<DateTime>>()
    .defined(translations.messages.requiredField)
    .nullable()
    .test('start-date-valid', 'Please select a valid start date.', (value) =>
      Boolean(value?.[0] instanceof DateTime && value[0].isValid),
    )
    .test('end-date-valid', 'Please select a valid end date.', (value) =>
      Boolean(value?.[1] instanceof DateTime && value[1].isValid),
    )
    .test('end-date-after-start-date', 'Please input an end date that takes place before the start date', (value) =>
      Boolean(value?.[0] && value?.[1] && value[0] < value[1]),
    ),
  protectionLevel: yupNumber()
    .transform((value: number) => (!isNaN(value) ? value : undefined))
    .min(0)
    .max(100)
    .required('Please enter the protection level'),
});
