import { ReactElement } from 'react';

import { WatchlistHomeButton } from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';
import { Skeleton, useMediaQuery, useTheme } from '@mui/material';

export const WatchlistDetailsSkeleton = (): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dummyArray = [...Array(10).keys()];

  const contentSpacing = isMobile ? 1 : 3;
  const titleSpacing = isMobile ? 0 : 1;

  return (
    <Stack direction="column" spacing={contentSpacing}>
      <WatchlistHomeButton />
      <Stack direction="row" spacing={1}>
        <Skeleton variant="circular" width={80} height={80} />
        <Stack direction="column" spacing={titleSpacing}>
          <Skeleton variant="rounded" width={272} height={38} />
          <Skeleton variant="rounded" width={150} height={22} />
        </Stack>
      </Stack>
      <Stack direction="row" wrap="wrap" spacing={2} justify="center">
        {dummyArray.map((key) => (
          <Skeleton key={key} variant="rounded" width={350} height={300} />
        ))}
      </Stack>
    </Stack>
  );
};
