import { ReactElement, useMemo } from 'react';

import { NoteParameterEnum } from '@halo-common/enums';
import { useNoteSpecificDetails } from '@halo-common/hooks';
import { NoteModel, QuoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { HaloDataGrid, HaloDataGridProps } from '@halodomination/halo-fe-common';
import { DateTime } from 'luxon';

export type PreTradeNoteDetailModalQuotesTableProps = {
  product?: NoteModel | null;
  rows?: Array<QuoteModel>;
  loading?: boolean;
};

export const PreTradeNoteDetailModalQuotesTable = ({
  product,
  rows = [],
  loading,
}: PreTradeNoteDetailModalQuotesTableProps): ReactElement | null => {
  const isCommission = product?.parameter === NoteParameterEnum.Price;

  const { solvableParameter } = useNoteSpecificDetails(product);

  const sortedRows = useMemo(
    () =>
      rows.sort((a, b) => {
        const earlierDate = DateTime.fromISO(a.receivedAt) > DateTime.fromISO(b.receivedAt);
        const laterDate = DateTime.fromISO(a.receivedAt) < DateTime.fromISO(b.receivedAt);
        const sortByDateValue = earlierDate ? 1 : laterDate ? -1 : 0;

        const aValue = isCommission ? 100 - a.value : a.value;
        const bValue = isCommission ? 100 - b.value : b.value;

        return aValue < bValue ? 1 : aValue > bValue ? -1 : sortByDateValue;
      }),
    [rows],
  );

  const commonCellProps = {
    editable: false,
    resizable: false,
    disableReorder: true,
    disableColumnMenu: true,
    flex: 1,
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      field: 'issuerName',
      headerName: translations.common.issuerName,
      valueFormatter: (value: string) => value ?? '--',
    },
    {
      ...commonCellProps,
      field: 'value',
      headerName: solvableParameter?.label,
      valueGetter: (value: number) => {
        return isCommission ? 100 - value : value;
      },
      valueFormatter: (value: number) => (value ? `${value.toFixed(2)}%` : '--'),
    },
    {
      ...commonCellProps,
      field: 'receivedAt',
      headerName: translations.common.received,
      translateCell: true,
      valueFormatter: (value: string) => {
        if (!value) return '--';

        const date = DateTime.fromISO(value, { zone: 'utc' });
        const now = DateTime.local({ zone: 'utc' });

        const minuteDiff = Number(date.diff(now).as('minutes').toFixed(0));
        const hourDiff = Number(date.diff(now).as('hours').toFixed(0));
        const dayDiff = Number(date.diff(now).as('days').toFixed(0));
        const agoOrFromNow = date.diff(now).as('minutes') < 0 ? 'ago' : 'from now';

        if (Math.abs(dayDiff) !== 0) {
          const timeInt = Math.abs(dayDiff);
          const timeUnit = timeInt === 1 ? 'day' : 'days';
          return `${timeInt} ${timeUnit} ${agoOrFromNow}`;
        } else if (Math.abs(hourDiff) !== 0) {
          const timeInt = Math.abs(hourDiff);
          const timeUnit = timeInt === 1 ? 'hour' : 'hours';
          return `${timeInt} ${timeUnit} ${agoOrFromNow}`;
        } else if (Math.abs(minuteDiff) !== 0) {
          const timeInt = Math.abs(minuteDiff);
          const timeUnit = timeInt === 1 ? 'minute' : 'minutes';
          return `${timeInt} ${timeUnit} ${agoOrFromNow}`;
        } else {
          return `Less than 1 minute ${agoOrFromNow}`;
        }
      },
    },
  ];

  return (
    <HaloDataGrid
      columns={columns}
      rows={sortedRows}
      hideFooter
      loading={loading}
      noResultsMessage={translations.pdm.preTrade.quotesTableErrorMessageNoQuotes}
      density="compact"
    />
  );
};
