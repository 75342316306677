import { QuoteValueMap, quoteValueMapAtom } from '@halo-atoms/quotes';
import { getMostRecentQuotes } from '@halo-data-sources/clients';
import { ApiQuoteMapper } from '@halo-data-sources/mappers';
import { QuoteQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';

const getRecentQuotesQueryFn = async (ids?: Array<number>) => {
  if (!ids?.length) return null;

  const response = await getMostRecentQuotes(ids);

  return response?.data.reduce(
    (map: QuoteValueMap, { featured, note_id, quotes }) => ({
      ...map,
      [note_id]: {
        updated: false,
        recent: featured ? ApiQuoteMapper.toQuoteModel(featured) : null,
        past: quotes.map(ApiQuoteMapper.toQuoteModel),
      },
    }),
    {},
  );
};

export const useRecentQuotesQuery = (ids?: Array<number>): UseQueryResult<QuoteValueMap | null, Error> => {
  const setQuoteValueMap = useSetAtom(quoteValueMapAtom);

  return useQuery<QuoteValueMap | null, Error>({
    queryKey: QuoteQueryKeyFactory.byNoteIds(ids),
    queryFn: async () => {
      const response = await getRecentQuotesQueryFn(ids);
      setQuoteValueMap((prev) => ({ ...prev, ...response }));
      return response;
    },
  });
};
