import { ReactElement } from 'react';

import { allocationOrderCheckboxesAtom, allocationOrderCheckboxesReducer } from '@halo-atoms/sma';
import { HOUR_MINUTE_SECONDS_APM_TIME_ZONE_FORMAT, MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { SMACheckBoxEnum } from '@halo-common/enums';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { SMAAllocationModalFormModel } from '@halo-modules/app';
import { Collapsible, LocalizedTextField, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Checkbox, Stack } from '@mui/material';
import { useReducerAtom } from 'jotai/utils';
import { useController, useFormContext } from 'react-hook-form';

export const SMAAllocationOrderDetails = (): ReactElement => {
  const { control } = useFormContext<SMAAllocationModalFormModel>();
  const configureDateTime = useWhiteLabelDateConfiguration();
  const [{ investmentObjChecked, advsChecked }, dispatch] = useReducerAtom(
    allocationOrderCheckboxesAtom,
    allocationOrderCheckboxesReducer,
  );

  const date = new Date(Date.now());

  const currentDate = configureDateTime(date.toISOString())?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
  const currentTime = configureDateTime(date.toISOString())?.toFormat(HOUR_MINUTE_SECONDS_APM_TIME_ZONE_FORMAT);

  const { field: advisorNameField } = useController({ control, name: 'advisorName' });

  return (
    <Collapsible title={translations.sma.orderDetails} sx={{ p: 0 }} HeaderProps={{ sx: { p: 0 } }} defaultExpanded>
      <Stack alignItems="center" direction="row" sx={{ pb: 1 }}>
        <Stack sx={{ pr: 8 }} direction="column">
          <LocalizedTypography variant="caption" color="text.secondary">
            {translations.sma.receiptDateAndTime}
          </LocalizedTypography>
          <Stack direction="row">
            <LocalizedTypography fontWeight="bold" sx={{ pr: 2 }}>
              {currentDate}
            </LocalizedTypography>
            <LocalizedTypography fontWeight="bold">{currentTime}</LocalizedTypography>
          </Stack>
        </Stack>
        <LocalizedTextField {...advisorNameField} label={translations.sma.orderReceivedFrom} sx={{ width: '300px' }} />
      </Stack>
      <Stack direction="column" justifyContent="start">
        <Stack direction="row" alignItems="center">
          <Checkbox
            checked={investmentObjChecked}
            onChange={() =>
              dispatch({
                value: !investmentObjChecked,
                type: SMACheckBoxEnum.CheckInvestmentObj,
              })
            }
          />
          <LocalizedTypography variant="body2">
            {translations.sma.acceptStrategyInvestmentObjectives}
          </LocalizedTypography>
        </Stack>

        <Stack direction="row" alignItems="center">
          <Checkbox
            checked={advsChecked}
            onChange={() => dispatch({ type: SMACheckBoxEnum.CheckADV, value: !advsChecked })}
          />
          <LocalizedTypography variant="body2">{translations.sma.acceptStrategyDeliveredADVs}</LocalizedTypography>
        </Stack>
      </Stack>
    </Collapsible>
  );
};
