import {
  portfolioPositionDetailsModalAtom,
  portfolioPositionsColumnWidthAtom,
  portfolioPositionsManagerAtom,
} from '@halo-atoms/portfolio';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { AssetIdentifierEnum, PortfolioPositionsTableSearchNameEnum, ProductTypeEnum } from '@halo-common/enums';
import { usePrimaryAssetIdentifier, useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { PositionsDetailsPositionModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { PortfolioColumnTypeEnum } from '@halo-data-sources/enums';
import {
  useGenerateHistoricalReportMutation,
  usePositionTermsheetMutation,
  useRecordActivityMutation,
} from '@halo-data-sources/mutations';
import { usePortfolioPositionsQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { CompositionEnum, MUI_COLUMN_HEADER_CLASS_NAME } from '@halo-modules/app';
import {
  GridRenderCellParams,
  HaloDataGridProps,
  LocalizedButton,
  mapNumberToLocalCurrency,
} from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box } from '@mui/material';
import { useT } from '@transifex/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { DateTime } from 'luxon';

const columnsDictionary = [
  'unrealized_glcell',
  'underlyingreturncell',
  'notereturncell',
  'totalnotereturncell',
  'returnatmaturitycell',
];

type TranslatableCellValue = {
  [key: string]: string | number;
};

export type PortfolioDetailsTableInformation = {
  columns: HaloDataGridProps['columns'];
  menuItems: HaloDataGridProps['menuItems'];
};

export const usePortfolioDetailsTableColumns = (tab = 0, loading = false): PortfolioDetailsTableInformation => {
  const translator = useT();
  const configureDateTime = useWhiteLabelDateConfiguration();

  const positionsData = useAtomValue(portfolioPositionsManagerAtom);
  const setPositionModal = useSetAtom(portfolioPositionDetailsModalAtom);
  const columnWidths = useAtomValue(portfolioPositionsColumnWidthAtom);

  const { filters, detailsQuery } = positionsData;

  const { data: userInfo } = useUserInfoQuery();

  const positionsQueryEnabled = Boolean(CompositionEnum.Product in detailsQuery.filters && userInfo);
  const positionsQueryOptions = { enabled: positionsQueryEnabled };

  const { data: positionMeta } = usePortfolioPositionsQuery(detailsQuery, positionsQueryOptions);

  const { mutate: downloadPerformanceReport } = useGenerateHistoricalReportMutation();
  const { mutate: downloadTermsheet } = usePositionTermsheetMutation();
  const { mutate: recordActivity } = useRecordActivityMutation();

  const { identifierType } = usePrimaryAssetIdentifier();

  const sections = positionMeta?.sections ?? [];
  const sectionTabs = positionMeta?.filters ?? [];
  const selectedSection = sections.find((section) => section.name === sectionTabs[tab]);
  const selectedSectionColumns = selectedSection?.columns ?? [];
  const selectedSectionColumnsDictionary = selectedSection?.columnDictionary ?? [];
  const allInfoSection = sections?.[0];
  const sectionColumnWidths = columnWidths[selectedSection?.name ?? 'All Info'] ?? {};

  const translateCouponSummaryCell = (value: TranslatableCellValue) => {
    return { text: translations.portfolio.positions.couponSummaryCell, content: value };
  };

  const translateTermCell = (value: number) => {
    const text = translations.portfolio.positions.monthsCell;

    const content = { months: value };

    return { text, content };
  };

  const translateTermYearsMonthsCell = (value: number) => {
    const years = Math.floor(value / 12);
    const months = years ? value % 12 : value;

    const yearsText = years >= 1 ? translations.portfolio.positions.yearsCell : '';
    const monthsText = months >= 1 ? translations.portfolio.positions.monthsCell : '';
    const text = [yearsText, monthsText].filter(Boolean).join(' ') || translations.portfolio.positions.monthsCell;

    const content = { years, months };

    return { text, content };
  };

  const translateTimeLeftCell = (value: string | null) => {
    if (!value) return { text: '-' };
    const maturityDate = DateTime.fromISO(value);
    const now = DateTime.now();

    const timeRemaining = maturityDate.diff(now, ['months', 'days']);
    const monthsRemaining = timeRemaining.months;
    const daysRemaining = timeRemaining.days;

    const monthsValue = daysRemaining >= 15 ? monthsRemaining + 1 : monthsRemaining;
    const monthsText = translations.portfolio.positions.monthsCell;

    const daysText = translations.portfolio.positions.daysCell;

    const text = monthsValue >= 1 ? monthsText : daysText;
    const content = { months: monthsValue.toFixed(0), days: daysRemaining.toFixed(0) };

    return { text, content };
  };

  const translateCell = (value: TranslatableCellValue | number | string | null, columnName?: string) => {
    const key = columnName ?? '';

    const translatableValues = {
      timeleftcell: translateTimeLeftCell(value as string),
      termcell: translateTermCell(value as number),
      termyearsmonthscell: translateTermYearsMonthsCell(value as number),
      couponsummarycell: translateCouponSummaryCell(value as TranslatableCellValue),
    }[key];

    return translatableValues ? translator(translatableValues.text, translatableValues.content) : value;
  };

  const menuItems: HaloDataGridProps['menuItems'] = [
    {
      disabled: (row) => row?.productType?.toLowerCase() !== 'note',
      icon: 'file-chart-column',
      label: translations.portfolio.positions.positionsTableActionGenerateHistoricalReport,
      onClick: (row) => {
        const accountId = filters.accountOption?.account?.id;
        const householdId = filters.accountOption?.household?.id;
        const currencyId = filters.currency?.id;

        const assetIdentifier = row.columnDetails[identifierType]?.value;
        const identifierText = `${identifierType?.toUpperCase()} #${assetIdentifier}`;

        const activityVerb = `downloaded performance report for termsheet ${row.id}`;

        recordActivity({
          activity: [{ verb: activityVerb, model_name: 'Portfolio' }],
          fromIFrame: false,
        });

        downloadPerformanceReport({
          adviseeId: householdId,
          accountId: accountId,
          termsheetId: row.id,
          productDescriptor: identifierText,
          assetIdentifier,
          reportingCurrencyId: currencyId,
          userId: userInfo?.details.id,
        });
      },
    },
    {
      disabled: (row) => row?.productType?.toLowerCase() !== 'note',
      icon: 'file-contract',
      label: translations.portfolio.positions.positionsTableActionDownloadTermsheet,
      onClick: (row) => {
        const assetIdentifier = row.columnDetails[identifierType]?.value;
        const identifierText = `${identifierType} #${assetIdentifier}`;

        downloadTermsheet({ termsheetId: row.id, productDescriptor: identifierText });
      },
    },
  ];

  const columns = loading
    ? [
        PortfolioPositionsTableSearchNameEnum.shortName,
        PortfolioPositionsTableSearchNameEnum.cusip,
        ...Array.from(Array(35), (_, index) => `${index}`),
      ]
    : selectedSectionColumns;

  const styledColumns: HaloDataGridProps['columns'] = columns.map((column: string) => {
    const columnAssetId = column as AssetIdentifierEnum;
    const columnType = column as PortfolioPositionsTableSearchNameEnum;

    const isShortNameCol = columnType === PortfolioPositionsTableSearchNameEnum.shortName;
    const isBreachedCol = columnType === PortfolioPositionsTableSearchNameEnum.breached;
    const isCouponTypeCol = columnType === PortfolioPositionsTableSearchNameEnum.couponType;
    const isProtectionTypeCol = columnType === PortfolioPositionsTableSearchNameEnum.protectionType;
    const isPayoffCol = columnType === PortfolioPositionsTableSearchNameEnum.payoff;
    const isPrincipalProtectionTypeCol = columnType === PortfolioPositionsTableSearchNameEnum.principalProtectionType;
    const isIssuerCol = columnType === PortfolioPositionsTableSearchNameEnum.issuerName;
    const isAssetIdentifierCol = columnAssetId === identifierType;

    const translateCol =
      isBreachedCol ||
      isCouponTypeCol ||
      isProtectionTypeCol ||
      isPayoffCol ||
      isPrincipalProtectionTypeCol ||
      isIssuerCol;

    const enableFlex = !isShortNameCol && !sectionColumnWidths[column];
    const additionalOptions = enableFlex ? { flex: 1 } : undefined;

    return {
      ...additionalOptions,
      description: column,
      field: column,
      headerName: column,
      minWidth: isShortNameCol ? 250 : 150,
      width: sectionColumnWidths[column],
      translateCell: translateCol,
      headerClassName: MUI_COLUMN_HEADER_CLASS_NAME,
      valueGetter: (_, row: PositionsDetailsPositionModel) => row.columnDetails?.[column]?.value ?? '-',
      renderCell: ({ row }: GridRenderCellParams<PositionsDetailsPositionModel>) => {
        const columnDetails = row.columnDetails?.[column];
        const columnValue = columnDetails?.value;
        const type = columnDetails?.type;

        const selectedColumn = selectedSectionColumnsDictionary.find(
          (dictionaryColumn) => dictionaryColumn.displayName === column,
        )?.ergName;

        const getColumnValue = (value: string | number | TranslatableCellValue): string | number | undefined => {
          if (!value) return '-';

          const currencies = userInfo?.whiteLabel.currencies ?? [];
          // TODO: Uncomment when we have an actual valid date format for the white label
          // const whiteLabelDateFormat = user?.whiteLabel.dateFormat ?? MONTH_DAY_YEAR_DATE_FORMAT;
          const whiteLabelDateFormat = MONTH_DAY_YEAR_DATE_FORMAT;
          const rowCurrency = currencies.find((currency) => currency.id === row.columnDetails?.[column]?.currencyId);
          const currencyCode = rowCurrency?.code ?? 'USD';
          const currencySymbol = rowCurrency?.symbol;

          const currencyOptions = { currency: currencyCode, maximumFractionDigits: 0 };

          if (column === 'Valuation') {
            const parsedValue = typeof value === 'string' ? parseFloat(value) : (value as number);
            return parsedValue.toFixed(2);
          }

          return {
            [PortfolioColumnTypeEnum.RAW]: value,
            [PortfolioColumnTypeEnum.PERCENT]: `${typeof value === 'number' ? value.toFixed(2) : (value as string)}%`,
            [PortfolioColumnTypeEnum.CURRENCY]: mapNumberToLocalCurrency(
              value as number,
              currencyOptions,
              currencySymbol,
            ),
            [PortfolioColumnTypeEnum.DATE]: configureDateTime(value as string)?.toFormat(whiteLabelDateFormat),
            [PortfolioColumnTypeEnum.TRANSLATED]: translateCell(value, selectedColumn),
          }[type];
        };

        const getColumnColor = (value: string | number | TranslatableCellValue, theme: HaloTheme): string => {
          const isNumber = typeof value === 'number';
          const isPositiveNumber = isNumber && value > 0;
          const color = isPositiveNumber ? 'positive' : 'negative';

          const isColored = Boolean(selectedColumn && columnsDictionary.find((column) => column === selectedColumn));

          if (isShortNameCol) return 'primary.main';
          else if (!isColored || tableColValue === '-') return 'text.primary';
          else return theme.palette.common.charts[color]?.main;
        };

        const getColumnAlignment = (value: string | number | TranslatableCellValue): string => {
          const isString = typeof value === 'string';
          const isNumber = typeof value === 'number';
          const isValidNumber = isNumber && !isNaN(value);
          const isColumnLengthGreaterThanFifteen = isString && value.length > 15;

          if (isValidNumber) return 'flex-end';
          if (isAssetIdentifierCol || isColumnLengthGreaterThanFifteen) return 'flex-start';
          return 'center';
        };

        const handleClick = () => {
          if (row.productType.toLowerCase() === 'annuity') {
            const selectedAnnuity = allInfoSection?.positions?.find((position) => position.id === row.id);
            const columnKeys = selectedAnnuity?.columnDetails ? Object.keys(selectedAnnuity.columnDetails) : null;
            const contractIdKey = columnKeys ? columnKeys.find((key) => key.toLowerCase() === 'contract id') : null;
            const contractId = contractIdKey
              ? selectedAnnuity?.columnDetails[contractIdKey]?.value.toString()
              : undefined;

            setPositionModal({ id: row.id, type: ProductTypeEnum.annuity, contractId });
          } else {
            setPositionModal({ id: row.id, type: row.productType.toLowerCase() as ProductTypeEnum });
          }
        };

        const cellAlignment = getColumnAlignment(columnValue);
        const tableColValue = getColumnValue(columnValue);

        const commonColumnSx = {
          alignItems: 'center',
          color: (theme: HaloTheme) => getColumnColor(columnValue, theme),
          display: 'flex',
          justifyContent: cellAlignment,
          width: '100%',
        };

        const shortNameBtnSx = {
          ...commonColumnSx,
          alignItems: 'unset',
          display: 'inline-block',
          height: '100%',
          justifyContent: 'unset',
          padding: 0,
          textAlign: 'left',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        };

        return isShortNameCol ? (
          <LocalizedButton sx={shortNameBtnSx} onClick={handleClick} disableRipple variant="text" color="primary">
            {tableColValue}
          </LocalizedButton>
        ) : (
          <Box sx={commonColumnSx}>{tableColValue}</Box>
        );
      },
    };
  });

  return { columns: styledColumns, menuItems };
};
