import { recordActivity } from '@halo-data-sources/clients';
import { RecordActivityModel } from '@halo-data-sources/models';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

const recordActivityMutationFn = async (data: RecordActivityModel): Promise<void> => {
  return await recordActivity(data.activity, data.fromIFrame);
};

export const useRecordActivityMutation = (): UseMutationResult<void, Error, RecordActivityModel> => {
  return useMutation<void, Error, RecordActivityModel>({
    mutationFn: recordActivityMutationFn,
  });
};
