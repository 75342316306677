import { StepResult, StepsOptions } from '@halodomination/halo-fe-common';
import { atom } from 'jotai';

export type ModalAtomModel = {
  [key: string]: boolean;
};

export type ModalSteppedSetterAtomModel = {
  modalName: string;
  open: boolean;
  options?: StepsOptions;
};

export type ModalStepAtomModel = {
  [key: string]: {
    open: boolean;
    step: StepResult['step'];
    finished: StepResult['finished'];
    handleReset: StepResult['handleReset'];
    handleNext: StepResult['handleNext'];
    handleBack: StepResult['handleBack'];
  };
};

const modalMapAtom = atom<ModalAtomModel>({});

export const modalAtom = atom(
  (get) => get(modalMapAtom),
  (get, set, newMap: ModalAtomModel) => {
    const previous = get(modalMapAtom);
    const updatedMap = { ...previous, ...newMap };
    set(modalMapAtom, updatedMap);
  },
);

export const toggleAtom = atom(undefined, (get, set, key: string) => {
  const previous = get(modalMapAtom);
  const updatedMap = { ...previous, [key]: !previous[key] };
  set(modalMapAtom, updatedMap);
});
