import { useCallback } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { translations } from '@halo-common/translations';
import {
  UpdateUserPreferencesMutationContentType,
  UseUpdateUserPreferencesMutationOptions,
  UseUpdateUserPreferencesMutationResult,
  useUpdateUserPreferencesMutation,
} from '@halo-data-sources/mutations';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';

export type useSaveSideBarFiltersOptions = UseUpdateUserPreferencesMutationOptions & {
  successMessage?: string;
  errorMessage?: string;
};

export type useSaveSideBarFiltersResult = UseUpdateUserPreferencesMutationResult & {
  saveSidebarFilters: () => void;
};

export const useSaveSideBarFilters = (
  tag: string,
  {
    successMessage = translations.calendars.common.successfullySavedFilters,
    errorMessage = translations.calendars.common.thereWasAnErrorSavingYourFiltersPleaseTryAgain,
    ...options
  }: useSaveSideBarFiltersOptions = {},
): useSaveSideBarFiltersResult => {
  const getSidePanelState = useAtomValue(dynamicSideBarManagerAtom);

  const { enqueueSuccessEvent, enqueueErrorEvent, closeSnackbar } = useSnackbar();

  const updateUserPreferencesMutation = useUpdateUserPreferencesMutation({
    ...options,
    onMutate: () => closeSnackbar(),
    onError: (...props) => {
      if (errorMessage) enqueueErrorEvent({ message: errorMessage });
      options.onError?.(...props);
    },
    onSuccess: (...props) => {
      if (successMessage) enqueueSuccessEvent({ message: successMessage });
      options.onSuccess?.(...props);
    },
  });

  const saveSidebarFilters = useCallback(
    (overrideContent?: UpdateUserPreferencesMutationContentType) => {
      const sidePanelState = getSidePanelState(tag);

      const content: UpdateUserPreferencesMutationContentType = {
        ...sidePanelState.checkboxes,
        ...sidePanelState.toggles,
        currencies: sidePanelState.currencies,
        issuers: sidePanelState.issuers,
        productTypes: sidePanelState.productTypes,
        protectionAmountMin: sidePanelState.protectionAmount?.min,
        protectionAmountMax: sidePanelState.protectionAmount?.max,
        nonCallPeriodMin: sidePanelState.nonCallPeriod?.min,
        nonCallPeriodMax: sidePanelState.nonCallPeriod?.max,
        termMin: sidePanelState.term?.min,
        termMax: sidePanelState.term?.max,
        underlyingCountMin: sidePanelState.underlyingCount?.min,
        underlyingCountMax: sidePanelState.underlyingCount?.max,
        ...overrideContent,
      };

      updateUserPreferencesMutation.mutate({ tag, content });
    },
    [tag, getSidePanelState],
  );

  return { ...updateUserPreferencesMutation, saveSidebarFilters };
};
