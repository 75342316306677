import { atomWithListeners } from '@halo-atoms/atomWithListeners';
import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { TERMSHEET_PREFERENCE_TAG } from '@halo-common/constants';
import { ApiTermsheetRepoMapper } from '@halo-data-sources/mappers';
import type { SearchFiltersState } from '@halo-modules/app/documents/repository';
import type { GridSortModel, HaloDataGridProps } from '@halodomination/halo-fe-common';
import { atom } from 'jotai';

export type TableStateModel = {
  page: number;
  pageSize: number;
  sortBy: GridSortModel;
  rowSelectionModel: HaloDataGridProps['rowSelectionModel'];
};

const DEFAULT_TABLE_STATE = {
  page: 1,
  pageSize: 25,
  sortBy: [],
  rowSelectionModel: [],
} as TableStateModel;

export const termsheetsTableStateAtom = atom<TableStateModel>(DEFAULT_TABLE_STATE);

const DEFAULT_SEARCH_FILTERS_STATE = {
  search: '',
  issuedFrom: null,
  issuedTo: null,
  maturesFrom: null,
  maturesTo: null,
} as SearchFiltersState;

const [termsheetsSearchFiltersAtom, useTermsheetsSearchFiltersListener] =
  atomWithListeners<SearchFiltersState>(DEFAULT_SEARCH_FILTERS_STATE);

export { termsheetsSearchFiltersAtom, useTermsheetsSearchFiltersListener };

export type TermsheetsManagerPayload = {
  tableState?: Partial<TableStateModel>;
  searchFilters?: Partial<SearchFiltersState>;
};

export const termsheetsManagerAtom = atom(
  (get) => {
    const tableState = get(termsheetsTableStateAtom);
    const searchFilters = get(termsheetsSearchFiltersAtom);

    const getSidePanelState = get(dynamicSideBarManagerAtom);
    const sidebarFilters = getSidePanelState(TERMSHEET_PREFERENCE_TAG);

    const queryPayload = ApiTermsheetRepoMapper.toTermsheetRepoDocsPayload({
      tableState,
      searchFilters,
      sidebarFilters,
    });

    return {
      tableState,
      searchFilters,
      sidebarFilters,
      queryPayload,
    };
  },
  (_, set, payload?: TermsheetsManagerPayload) => {
    const { tableState, searchFilters } = payload || {};

    if (tableState) set(termsheetsTableStateAtom, (prev) => ({ ...prev, ...tableState }));
    if (searchFilters) set(termsheetsSearchFiltersAtom, (prev) => ({ ...prev, ...searchFilters }));

    if (!payload) {
      set(termsheetsTableStateAtom, DEFAULT_TABLE_STATE);
      set(termsheetsSearchFiltersAtom, DEFAULT_SEARCH_FILTERS_STATE);
    }
  },
);
