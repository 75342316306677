import { ReactElement } from 'react';

import { Doughnut, LinedCircle, YellowCircleOfCircles } from '@halo-common/components';
import { useBreakpointView } from '@halo-common/hooks';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, useTheme } from '@mui/material';

const LINED_CIRCLE_HEIGHT = 573;
const mobileStyling = { display: { xs: 'none', lg: 'none' } };

export type PageMessageAnimationProps = {
  contentPositionY: number;
};

export const PageMessageAnimation = ({ contentPositionY }: PageMessageAnimationProps): ReactElement => {
  const theme = useTheme<HaloTheme>();
  const isMediumScreen = useBreakpointView('md');

  const linedCircleOffset = isMediumScreen ? parseFloat(theme.spacing(2.5)) : parseFloat(theme.spacing(19));

  const doughnutOffset = parseFloat(theme.spacing(57.5));
  const yellowCircleOfCirclesOffset = parseFloat(theme.spacing(37));

  const linedCircleStyling = {
    left: `calc(50% - (${LINED_CIRCLE_HEIGHT}px / 2))`,
    position: 'absolute',
    top: linedCircleOffset,
  };
  const doughnutStyling = {
    left: { xs: -15, md: 1 },
    position: 'absolute',
    top: contentPositionY - doughnutOffset,
  };
  const yellowCircleOfCirclesStyling = {
    position: 'absolute',
    right: { xs: -25, md: 10 },
    top: contentPositionY - yellowCircleOfCirclesOffset,
  };
  return (
    <>
      <LinedCircle disableAnimation sx={linedCircleStyling} />
      <Box sx={mobileStyling}>
        <Doughnut sx={doughnutStyling} />
      </Box>
      <Box sx={mobileStyling}>
        <YellowCircleOfCircles sx={yellowCircleOfCirclesStyling} disableAnimation />
      </Box>
    </>
  );
};
