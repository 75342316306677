import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { formatLocale } from '@halo-common/utils';
import { useUpdateUserInfoMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedMenuItem, LocalizedTypography, useCombinedStyling } from '@halodomination/halo-fe-common';
import { FormControl, InputLabel, Select, SelectChangeEvent, Skeleton, Stack, SxProps } from '@mui/material';
import { useT } from '@transifex/react';

const formControlSx = {
  height: 40,
  width: 220,
};

export type UserLocaleDropdownPropTypes = {
  selectSx?: SxProps;
  containerSx?: SxProps;
};

const menuItems = (availableLocales?: Array<string>, userDefaultLocale?: string) => {
  return availableLocales?.map((locale) => {
    const formattedLocale = formatLocale(locale, userDefaultLocale);
    return (
      <LocalizedMenuItem key={locale} value={locale}>
        {formattedLocale}
      </LocalizedMenuItem>
    );
  });
};

export const UserLocaleDropdown = ({ containerSx, selectSx }: UserLocaleDropdownPropTypes): ReactElement | null => {
  const t = useT();

  const { data: userInfo, isPending } = useUserInfoQuery();

  const { mutate: updateUserInfo } = useUpdateUserInfoMutation({
    onSuccess: () => {
      window.parent.location.reload();
    },
  });

  const availableLocales = userInfo?.whiteLabel.availableLocales ?? [];

  const userDefaultLocale = userInfo?.details?.preferredLanguage ?? 'en';

  const hasLocale = availableLocales.includes(userDefaultLocale);
  const localeValue = hasLocale ? userDefaultLocale : 'en';

  const handleRenderValue = (value: string) => {
    const displayValue = formatLocale(value, userDefaultLocale);
    return t(displayValue);
  };

  const handleLocaleChange = (ev: SelectChangeEvent<string>) => {
    const locale = ev.target.value;
    updateUserInfo({ locale });
  };

  const combinedContainerSx = useCombinedStyling(formControlSx, containerSx);

  const content =
    availableLocales?.length > 1 ? (
      <Stack direction="column" spacing={2}>
        <LocalizedTypography variant="h6">{translations.profile.details.languagePreference}</LocalizedTypography>
        <LocalizedTypography variant="body2" color="text.secondary">
          {translations.profile.details.langPrefMessage}
        </LocalizedTypography>
        <FormControl variant="standard" sx={combinedContainerSx}>
          <InputLabel id="locale-dropdown-label-id">{translations.common.language}</InputLabel>
          <Select
            fullWidth
            labelId="locale-dropdown-label-id"
            id="locale-dropdown"
            value={localeValue}
            onChange={handleLocaleChange}
            label={translations.common.language}
            sx={selectSx}
            renderValue={handleRenderValue}
          >
            {menuItems(availableLocales, userDefaultLocale)}
          </Select>
        </FormControl>
      </Stack>
    ) : null;

  return isPending ? <Skeleton sx={containerSx} height={40} width={220} /> : content;
};
