import { ReactElement } from 'react';

import { UseProductInventoryTrackingReturn } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { Iconography, ProgressBar, Tooltip } from '@halodomination/halo-fe-common';
import { Stack, SxProps } from '@mui/material';

export type ProductInventoryTrackingBarProps = {
  inventory?: UseProductInventoryTrackingReturn;
  tooltipAlignment?: 'right' | 'left';
  sx?: SxProps;
};

export const ProductInventoryTrackingBar = ({
  inventory,
  tooltipAlignment = 'left',
  sx,
}: ProductInventoryTrackingBarProps): ReactElement | null => {
  const tooltip = translations.calendars.common.inventoryTooltip;

  if (!inventory) return null;

  const { variant, percentFilled, label, dynamicContent } = inventory;

  const leftTooltipAlignment = tooltipAlignment === 'left';

  const leftTooltip = leftTooltipAlignment ? (
    <Tooltip title={tooltip}>
      <Iconography iconName="circle-info" color="text.secondary" prefix="far" />
    </Tooltip>
  ) : null;

  const rightTooltip = !leftTooltipAlignment ? (
    <Tooltip title={tooltip}>
      <Iconography iconName="circle-info" color="text.secondary" prefix="far" />
    </Tooltip>
  ) : null;

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {leftTooltip}
      <ProgressBar
        sx={sx}
        variant={variant}
        label={label}
        percentFilled={percentFilled}
        dynamicContent={dynamicContent}
      />
      {rightTooltip}
    </Stack>
  );
};
