import { getCalendarOrderPreview } from '@halo-data-sources/clients';
import { ApiExecOrderPreviewRequestModel } from '@halo-data-sources/models';
import { ExecutionHubOrder, OrdersClientQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export type CalendarOrderPreviewPayload = {
  calendarId?: number;
  trace?: string;
  confirmNote?: string | null;
  orders?: Array<ExecutionHubOrder>;
};

export type CalendarOrderPreviewResult = Array<{
  id: number;
  accountType: string;
  inventoryAccount: string;
  issuerReoffer: number;
  quantity: number;
}>;

const calendarOrderPreviewFn = async (payload?: CalendarOrderPreviewPayload) => {
  if (!payload?.calendarId || !payload?.orders?.length) return null;

  const execOrderIds = payload.orders.map(({ executionOrder }) => executionOrder.id);
  const custom: ApiExecOrderPreviewRequestModel['custom'] = {};

  if (payload.trace) custom.trace = payload.trace;
  if (payload.confirmNote) custom.confirm_note = payload.confirmNote;

  const response = await getCalendarOrderPreview({
    calendar_id: payload.calendarId,
    custom,
    exec_order_ids: execOrderIds,
  });

  return response.offsets.map((offset) => ({
    id: offset.sell_exec_order.id,
    accountType: offset.sell_exec_order.custom.designation,
    inventoryAccount: offset.sell_exec_order.account_identifier,
    issuerReoffer: offset.sell_exec_order.price,
    quantity: offset.quantity,
  }));
};

export const useCalendarOrderPreview = (
  payload?: CalendarOrderPreviewPayload,
): UseQueryResult<CalendarOrderPreviewResult | null, Error> =>
  useQuery<CalendarOrderPreviewResult | null, Error>({
    queryKey: OrdersClientQueryKeyFactory.calendarOrderPreview(payload),
    queryFn: () => calendarOrderPreviewFn(payload),
  });
