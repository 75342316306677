import { useEffect } from 'react';

import { modalAtom } from '@halo-atoms/common';
import {
  selectedTargetedNoteIdAtom,
  selectedWatchlistAtom,
  selectedWatchlistNoteProductAtom,
} from '@halo-atoms/watchlists';
import { PageFooterLayout, SubNav, SubNavLayout } from '@halo-common/layouts';
import { PreTradeNoteDetailModal } from '@halo-common/modals';
import { useLegacyRouter } from '@halo-common/providers';
import { translations } from '@halo-common/translations';
import { useWatchlistsQuery } from '@halo-data-sources/queries';
import {
  CreateWatchlistModal,
  DeleteWatchlistModal,
  EditWatchlistModal,
  RemoveTargetedNoteModal,
  TargetedWatchlist,
  WatchlistDetails,
  WatchlistLanding,
} from '@halo-modules/app';
import { Iconography, LocalizedButton, PageComponent, PageMeta } from '@halodomination/halo-fe-common';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

const WatchlistPage: PageComponent = () => {
  const legacyRouter = useLegacyRouter();

  const { data, isPending, isError } = useWatchlistsQuery();

  const [modalMap, setModalMap] = useAtom(modalAtom);
  const [selectedWatchlist, setSelectedWatchlist] = useAtom(selectedWatchlistAtom);
  const setSelectedTargetedNoteId = useSetAtom(selectedTargetedNoteIdAtom);
  const selectedNoteModel = useAtomValue(selectedWatchlistNoteProductAtom);

  const openPDM = Boolean(modalMap.pdm);
  const watchlistIdQueryParam = legacyRouter.query.watchlist as string | undefined;
  const selectedWatchlistId = watchlistIdQueryParam ? parseInt(watchlistIdQueryParam) : undefined;

  const handlePDMClose = () => {
    setModalMap({ pdm: false });
  };

  const handleCreateToggle = () => {
    setModalMap({ createWatchlist: !modalMap.createWatchlist });
  };

  const handleEditToggle = () => {
    setModalMap({ editWatchlist: !modalMap.editWatchlist });
  };

  const handleRemoveTargetedToggle = (targetNoteId?: number) => {
    setSelectedTargetedNoteId(targetNoteId ?? null);
    setModalMap({ removeTargeted: !modalMap.removeTargeted });
  };

  const handleDeleteToggle = (showEdit = false) => {
    setModalMap({ deleteWatchlist: !modalMap.deleteWatchlist, editWatchlist: showEdit });
  };

  const watchlistContent = selectedWatchlistId ? (
    <WatchlistDetails id={selectedWatchlistId} loading={isPending} />
  ) : (
    <WatchlistLanding watchlists={data} loading={isPending} error={isError} />
  );

  const createWatchlistButton = (
    <LocalizedButton
      className="wm-watchlist-new"
      color="secondary"
      disableRipple
      onClick={handleCreateToggle}
      size="large"
      startIcon={<Iconography color="secondary.contrastText" iconName="plus" />}
      variant="contained"
    >
      {translations.components.watchlistNewWatchlistOption}
    </LocalizedButton>
  );

  useEffect(() => {
    if (!selectedWatchlist?.id && selectedWatchlistId && data?.length) {
      const watchlist = data.find((watchlist) => watchlist.id === selectedWatchlistId) ?? null;
      setSelectedWatchlist(watchlist);
    }
  }, [selectedWatchlistId, selectedWatchlist?.id, data?.length]);

  return (
    <PageFooterLayout>
      <SubNavLayout title={translations.watchlist.common.myLists} right={createWatchlistButton}>
        <SubNav noPadding name={translations.watchlist.common.watchlists}>
          {watchlistContent}
        </SubNav>
        <SubNav noPadding name={translations.watchlist.common.myTargetedProducts}>
          <TargetedWatchlist onRemove={handleRemoveTargetedToggle} />
        </SubNav>
      </SubNavLayout>
      <CreateWatchlistModal onClose={handleCreateToggle} />
      <EditWatchlistModal onClose={handleEditToggle} onDelete={handleDeleteToggle} />
      <RemoveTargetedNoteModal onClose={handleRemoveTargetedToggle} />
      <DeleteWatchlistModal onClose={handleDeleteToggle} />
      <PreTradeNoteDetailModal open={openPDM} onClose={handlePDMClose} product={selectedNoteModel} />
    </PageFooterLayout>
  );
};

export const WatchlistPageMeta: PageMeta = {
  pageName: 'Watchlist',
  route: '/app/watchlist',
  routeExpression: '/watchlist',
};

WatchlistPage.pageName = WatchlistPageMeta.pageName;
WatchlistPage.route = WatchlistPageMeta.route;
WatchlistPage.iconName = WatchlistPageMeta.iconName;

export default WatchlistPage;
