export const DYNAMIC_FILTER_TRANSLATIONS = {
  aClass: 'A-Class',
  assetCountFilter: 'Asset Count',
  assetSearchAlert: `When searching for an individual asset, to receive the most accurate results we do not apply filters.`,
  assetSearchLabel: 'Search by {assetId}',
  average: 'Average',
  averageBasket: 'Average Basket',
  barrierProtection: 'Barrier Protection',
  basketsFilter: 'Baskets',
  bufferProtection: 'Buffer Protection',
  callabilityFilter: 'CALLABILITY',
  cash: 'Cash',
  cashSettlement: 'Cash Settlement',
  class: 'Class',
  clearAllFiltersAndSearches: 'Clear All Filters & Searches',
  clearFiltersDialogNote: `Note: Once cleared, this will not automatically save your filter preferences. To save the cleared filters, click Save Filters in the sidebar.`,
  clearSearch: 'Clear Search',
  containsAll: 'Contains All',
  containsAny: 'Contains Any',
  couponTypeFilter: 'COUPON TYPE',
  fClass: 'F-Class',
  filterOfferings: 'FILTER OFFERINGS',
  finalOfferingsDescription: 'New Offerings are typically added in the first week of the new Month.',
  isExactly: 'Is Exactly',
  myOfferings: 'My Offerings',
  noMatchingAssets: 'No Matching Assets Found',
  dateSliderSuffix: ' Mo',
  noProductResults: 'No Product Results',
  physical: 'Physical',
  physicalSettlement: 'Physical Settlement',
  productFeaturesFilter: 'Product Features',
  propIndices: 'Prop Indices',
  proprietaryIndices: 'Proprietary Indices',
  saveFilters: 'Save Filters',
  settlement: 'Settlement',
  single: 'Single',
  singleBasket: 'Single Basket',
  sortByCloseTimeLatest: 'Close Time (Latest)',
  sortByCloseTimeSoonest: 'Close Time (Soonest)',
  sortByPayoffHighest: 'Payoff (Highest)',
  sortByPayoffLowest: 'Payoff (Lowest)',
  sortByProtectionHighest: 'Protection (Highest)',
  sortByProtectionLowest: 'Protection (Lowest)',
  sortByTermLongest: 'Term (Longest)',
  sortByTermShortest: 'Term (Shortest)',
  starred: 'Starred',
  submittedIndication: 'Submitted Indication',
  thereAreNoResultsForYourCurrentFilters: `There are no results for your current filters. Please try adjusting the selected filters, search settings, and time range to ensure a wider range of results. If you're stuck, click below to reset all filters to default.`,
  toggleChip: 'No {label}',
  toggleBaskets: 'Toggle Baskets',
  underlyingAutocompleteLabel: 'Search by Underlying Asset',
  upsideLeverageFilter: 'UPSIDE FILTER',
  weAreFinalizingThisMonthsOfferings: "We're Finalizing This Month's Offerings.",
  weighted: 'Weighted',
  weightedBasket: 'Weighted Basket',
  worstOf: 'Worst Of',
  wortOfBasket: 'Worst Of Basket',
};
