import { portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import { PortfolioPositionsNoteFeaturesModel } from '@halo-common/models';
import { PortfolioPositionAggregationModel, useUserInfoQuery } from '@halo-data-sources/queries';
import { mapNumberToLocalCurrency } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';

export type PositionsAggregateChartData = {
  label: string;
  percentage: number;
  value?: string;
  disabled: boolean;
};

export const usePositionsAggregateChartData = (
  aggregations: Array<PortfolioPositionAggregationModel> = [],
  selectedFeature?: PortfolioPositionsNoteFeaturesModel,
): Array<PositionsAggregateChartData> => {
  const positionsData = useAtomValue(portfolioPositionsManagerAtom);

  const { data: user } = useUserInfoQuery();

  const currency = positionsData?.filters.currency;
  const showAggregate = positionsData?.showAggregate;
  const lexicon = user?.lexicon;

  if (selectedFeature) {
    const percentageOther = Number((100 - selectedFeature.notionalPercent).toFixed(2));

    const chartData: Array<PositionsAggregateChartData> = [
      {
        label: selectedFeature.name,
        percentage: Number(selectedFeature.notionalPercent.toFixed(2)),
        value: mapNumberToLocalCurrency(
          selectedFeature.notional,
          {
            currency: currency?.code,
            maximumFractionDigits: 0,
          },
          currency?.symbol,
        ),
        disabled: false,
      },
    ];

    if (percentageOther) chartData.push({ label: 'Other', percentage: percentageOther, disabled: true });

    return chartData;
  }

  const lexiconChartData = aggregations.map((aggregation) => ({
    ...aggregation,
    name: aggregation.name ? (lexicon?.[aggregation.name] ?? aggregation.name) : 'N/A',
  }));

  return lexiconChartData.map((data) => {
    const { totalNotional } = data;
    const roundedPercentage = parseFloat(data.notionalPercent.toFixed(2));
    const value = mapNumberToLocalCurrency(
      totalNotional,
      { currency: currency?.code, maximumFractionDigits: 0 },
      currency?.symbol,
    );

    return { label: data.name, value, percentage: roundedPercentage, disabled: !showAggregate };
  });
};
