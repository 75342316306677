import { executionHubQueryAtom } from '@halo-atoms/executionHub';
import { AccountDesignationEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { putAdminCalendarPrice } from '@halo-data-sources/clients';
import { ApiUpdateAdminCalendarPriceRequestModel } from '@halo-data-sources/models';
import { CalendarOrderInfiniteQueryResult, OrdersClientQueryKeyFactory } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { InfiniteData, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

export type EditAdminCalendarReofferMutationPayload = {
  id: number;
  advisoryPrice: number;
  brokeragePrice?: number;
};

export type EditAdminCalendarReofferMutationResult = {
  id: number;
  advisoryPrice: number;
  brokeragePrice?: number;
};

const editAdminCalendarReofferMutationFn = async (payload: EditAdminCalendarReofferMutationPayload) => {
  const { id, advisoryPrice, brokeragePrice } = payload;

  const request: ApiUpdateAdminCalendarPriceRequestModel = {
    calendar_id: id,
    purchase_price: advisoryPrice,
    advisory: {
      sale_price: advisoryPrice,
      landing_price: advisoryPrice,
    },
  };

  if (typeof brokeragePrice === 'number') {
    request.brokerage = {
      sale_price: brokeragePrice,
    };
  }

  await putAdminCalendarPrice(request);

  return {
    id,
    advisoryPrice,
    brokeragePrice,
  };
};

export const useEditAdminCalendarReofferMutation = (
  onClose?: () => void,
): UseMutationResult<EditAdminCalendarReofferMutationResult, Error, EditAdminCalendarReofferMutationPayload> => {
  const queryClient = useQueryClient();

  const query = useAtomValue(executionHubQueryAtom);

  const { enqueueSuccessEvent, enqueueErrorEvent, closeSnackbar } = useSnackbar();

  return useMutation<EditAdminCalendarReofferMutationResult, Error, EditAdminCalendarReofferMutationPayload>({
    mutationFn: editAdminCalendarReofferMutationFn,
    onSettled: (data, error) => {
      closeSnackbar();

      if (error) {
        enqueueErrorEvent({ message: translations.common.error });
      } else if (data) {
        enqueueSuccessEvent({ message: 'Calendar reoffer successfully updated.' });
        onClose?.();
      }
    },
    onSuccess: (data) => {
      if (!data) return undefined;

      const key = OrdersClientQueryKeyFactory.calendarOrders(query);
      queryClient.setQueryData<InfiniteData<CalendarOrderInfiniteQueryResult>>(key, (prev) => {
        if (!prev) return prev;

        const { pages } = prev;

        const updatedPages = [...pages];
        const foundCalendarPageIndex = updatedPages.findIndex((page) => {
          return page.orders.some(({ calendar }) => calendar.id === data.id);
        });

        const updatedPage = { ...updatedPages[foundCalendarPageIndex] };
        const updatedCalendarOrders = [...updatedPage.orders];
        const foundCalendarOrderIndex = updatedCalendarOrders.findIndex(({ calendar }) => {
          return calendar.id === data.id;
        });

        const updatedCalendarOrder = {
          ...updatedCalendarOrders[foundCalendarOrderIndex],
          setOrderPrice: data.advisoryPrice,
        };

        updatedCalendarOrder.orders = updatedCalendarOrder.orders.map((order) => {
          const isBrokerageAccount = order.allocation.account.designation === AccountDesignationEnum.Brokerage;
          const updatedSoldPrice = isBrokerageAccount ? data.brokeragePrice : undefined;

          return {
            ...order,
            allocation: {
              ...order.allocation,
              boughtPrice: data.advisoryPrice,
              soldPrice: updatedSoldPrice ?? order.allocation.soldPrice,
            },
          };
        });

        updatedCalendarOrders.splice(foundCalendarOrderIndex, 1, updatedCalendarOrder);
        updatedPage.orders = updatedCalendarOrders;
        updatedPages.splice(foundCalendarPageIndex, 1, updatedPage);

        return { ...prev, pages: updatedPages };
      });
    },
  });
};
