import { fixedIncomeManagerAtom, fixedIncomeSelectedAtom } from '@halo-atoms/fixedIncome';
import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { AllocationStatusEnum } from '@halo-common/enums';
import { AllocationModel, CalendarModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { putCalendarAllocationRequest } from '@halo-data-sources/clients';
import { ApiAllocationMapper } from '@halo-data-sources/mappers';
import {
  CalendarsQueryKeyFactory,
  FixedIncomeQueryKeyFactory,
  GetFixedIncomeInfiniteResult,
  OrderBookCalendarQueryKeyFactory,
  OrderBookCalendarsQueryResult,
} from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import {
  InfiniteData,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';

export type EditFixedIncomeAllocationMutationOptions = {
  id: number;
  status: string;
  amount?: number;
};

export type UseEditFixedIncomeAllocationMutationOptions = UseMutationOptions<
  AllocationModel | null,
  Error,
  EditFixedIncomeAllocationMutationOptions
>;

export type UseEditFixedIncomeAllocationMutationResult = UseMutationResult<
  AllocationModel | null,
  Error,
  EditFixedIncomeAllocationMutationOptions
>;

const updateInventory = (
  newAmount: number,
  prevAmount: number,
  activeAllocationCount: number,
  inventoryConsumed: number | null | undefined,
  inventoryRemaining: number | null | undefined,
  status: string,
) => {
  const isCanceled = (status as AllocationStatusEnum) === AllocationStatusEnum.canceled;

  const smallerAllocationAmount = newAmount < prevAmount;
  const greaterAllocationAmount = newAmount > prevAmount;
  const sameAllocationAmount = newAmount === prevAmount;

  const allocationDifference = smallerAllocationAmount
    ? prevAmount - newAmount
    : greaterAllocationAmount
      ? newAmount - prevAmount
      : 0;

  const hasConsumedInventory = typeof inventoryConsumed === 'number';
  const hasRemainingInventory = typeof inventoryRemaining === 'number';

  const canceledConsumed = hasConsumedInventory ? inventoryConsumed - newAmount : undefined;
  const smallerConsumed = hasConsumedInventory ? inventoryConsumed - allocationDifference : undefined;
  const greaterConsumed = hasConsumedInventory ? inventoryConsumed + allocationDifference : undefined;
  const sameConsumed = hasConsumedInventory ? inventoryConsumed : undefined;
  const updatedInventoryConsumed = isCanceled
    ? canceledConsumed
    : smallerAllocationAmount
      ? smallerConsumed
      : greaterAllocationAmount
        ? greaterConsumed
        : sameAllocationAmount
          ? sameConsumed
          : undefined;

  const canceledRemaining = hasRemainingInventory ? inventoryRemaining + newAmount : undefined;
  const smallerRemaining = hasRemainingInventory ? inventoryRemaining + allocationDifference : undefined;
  const greaterRemaining = hasRemainingInventory ? inventoryRemaining - allocationDifference : undefined;
  const sameRemaining = hasRemainingInventory ? inventoryRemaining : undefined;

  const updatedInventoryRemaining = isCanceled
    ? canceledRemaining
    : smallerAllocationAmount
      ? smallerRemaining
      : greaterAllocationAmount
        ? greaterRemaining
        : sameAllocationAmount
          ? sameRemaining
          : undefined;

  const updatedActiveAllocationCount = isCanceled ? activeAllocationCount - 1 : activeAllocationCount;

  return { updatedInventoryConsumed, updatedInventoryRemaining, updatedActiveAllocationCount };
};

const editFixedIncomeAllocationMutation = async (options: EditFixedIncomeAllocationMutationOptions) => {
  if (typeof options.id !== 'number' || !options.status) return null;

  const { id, status, amount } = options;

  const response = await putCalendarAllocationRequest(id.toString(), status, amount);

  return ApiAllocationMapper.toAllocationModel(response.allocation);
};

export const useEditFixedIncomeAllocationMutation = (
  options?: UseEditFixedIncomeAllocationMutationOptions,
): UseEditFixedIncomeAllocationMutationResult => {
  const queryClient = useQueryClient();

  const { query } = useAtomValue(fixedIncomeManagerAtom);
  const [selectedFixedIncome, setSelectedFixedIncome] = useAtom(fixedIncomeSelectedAtom);
  const orderbookSearchFields = useAtomValue(orderBookQueryAtom);

  const { enqueueSuccessEvent, enqueueErrorEvent } = useSnackbar();

  return useMutation({
    mutationFn: editFixedIncomeAllocationMutation,
    ...options,
    onSettled: (...props) => {
      const [data, error] = props;
      if (error) enqueueErrorEvent({ message: translations.common.error });
      else if (data) enqueueSuccessEvent({ message: 'Allocation successfully updated.' });
      void queryClient.invalidateQueries({ queryKey: OrderBookCalendarQueryKeyFactory.all() });
      options?.onSettled?.(...props);
    },
    onSuccess: (...props) => {
      const [data] = props;

      options?.onSuccess?.(...props);

      if (!data) return undefined;

      const payload = { ...query };

      const allocationsKey = CalendarsQueryKeyFactory.allocations(selectedFixedIncome?.id);
      const orderbookKey = OrderBookCalendarQueryKeyFactory.calendars(orderbookSearchFields);
      const fixedIncomeKey = FixedIncomeQueryKeyFactory.fixedIncome(selectedFixedIncome?.id);
      const fixedIncomeListKey = FixedIncomeQueryKeyFactory.fixedIncomeList(payload);

      queryClient.setQueryData<Array<AllocationModel>>(allocationsKey, (prev) => {
        if (!prev) return undefined;
        const updatedAllocations = [...prev];
        const allocationIndex = updatedAllocations.findIndex((allocation) => allocation.id === data.id);
        updatedAllocations.splice(allocationIndex, 1, data);
        return updatedAllocations;
      });

      queryClient.setQueryData<CalendarModel>(fixedIncomeKey, (prev) => {
        if (!prev) return undefined;

        const { id, amount, status } = data;

        const updatedAllocations = [...prev.allocations];
        const allocationIndex = updatedAllocations.findIndex((allocation) => allocation.id === id);
        const prevAllocation = updatedAllocations[allocationIndex];
        const updatedAllocation = { ...updatedAllocations[allocationIndex], ...data };
        updatedAllocations.splice(allocationIndex, 1, updatedAllocation);

        const { activeAllocationCount, inventoryConsumed, inventoryRemaining } = prev;

        const { updatedInventoryConsumed, updatedInventoryRemaining, updatedActiveAllocationCount } = updateInventory(
          amount,
          prevAllocation.amount,
          activeAllocationCount,
          inventoryConsumed,
          inventoryRemaining,
          status,
        );

        const updatedCalendar = {
          ...prev,
          allocations: updatedAllocations,
          activeAllocationCount: updatedActiveAllocationCount,
          inventoryConsumed: updatedInventoryConsumed,
          inventoryRemaining: updatedInventoryRemaining,
        };

        return updatedCalendar;
      });

      queryClient.setQueryData<OrderBookCalendarsQueryResult>(orderbookKey, (prev) => {
        if (!prev || !prev?.calendars) return undefined;

        const fixedIncomeCalendar = queryClient.getQueryData<CalendarModel>(fixedIncomeKey);

        if (!fixedIncomeCalendar) return prev;

        const { calendarId } = data;

        const calendarIndex = prev.calendars.findIndex(({ id }) => id === calendarId);
        const prevCalendar = prev.calendars[calendarIndex];

        const allocationsTotal = fixedIncomeCalendar.allocations.reduce((total, prev) => {
          if ((prev.status as AllocationStatusEnum) !== AllocationStatusEnum.canceled) return total + prev.amount;
          else return 0;
        }, 0);

        const updatedCalendar = { ...prevCalendar, notional: allocationsTotal };

        const updatedCalendars = [...prev.calendars];
        updatedCalendars.splice(calendarIndex, 1, updatedCalendar);

        return { ...prev, calendars: updatedCalendars };
      });

      queryClient.setQueryData<InfiniteData<GetFixedIncomeInfiniteResult>>(fixedIncomeListKey, (prev) => {
        if (!prev) return undefined;

        const { amount, id, calendarId, status } = data;

        const pageIndex = prev.pages.findIndex(({ calendars }) =>
          calendars.find((calendar) => calendar.id === calendarId),
        );

        const page = prev.pages?.[pageIndex];

        if (!page) return undefined;

        const updatedPageCalendars = [...(page?.calendars ?? [])];
        const calendarIndex = updatedPageCalendars.findIndex((calendar) => calendar.id === calendarId);
        const prevCalendar = updatedPageCalendars[calendarIndex];

        const updatedAllocations = [...prevCalendar.allocations];
        const allocationIndex = updatedAllocations.findIndex((allocation) => allocation.id === id);
        const prevAllocation = updatedAllocations[allocationIndex];
        const updatedAllocation = { ...updatedAllocations[allocationIndex], ...data };
        updatedAllocations.splice(allocationIndex, 1, updatedAllocation);

        const { activeAllocationCount, inventoryConsumed, inventoryRemaining } = prevCalendar;

        const { updatedInventoryConsumed, updatedInventoryRemaining, updatedActiveAllocationCount } = updateInventory(
          amount,
          prevAllocation.amount,
          activeAllocationCount,
          inventoryConsumed,
          inventoryRemaining,
          status,
        );

        const updatedCalendar = {
          ...prevCalendar,
          inventoryConsumed: updatedInventoryConsumed,
          inventoryRemaining: updatedInventoryRemaining,
          allocations: updatedAllocations,
          activeAllocationCount: updatedActiveAllocationCount,
        };

        updatedPageCalendars.splice(calendarIndex, 1, updatedCalendar);

        const updatedPage = { ...page, calendars: updatedPageCalendars };
        const updatedPages = [...prev.pages];
        updatedPages.splice(pageIndex, 1, updatedPage);

        setSelectedFixedIncome(updatedCalendar);

        return { ...prev, pages: updatedPages };
      });
    },
  });
};
