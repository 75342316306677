/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Atom, atom, PrimitiveAtom, SetStateAction, WritableAtom } from 'jotai';

export type DebouncedAtomModel<T> = {
  currentValueAtom: Atom<T>;
  isDebouncingAtom: PrimitiveAtom<boolean>;
  clearTimeoutAtom: WritableAtom<any, any, any>;
  debouncedValueAtom: WritableAtom<any, any, any>;
};

export function debouncedAtom<T>(
  initialValue: T,
  delayMilliseconds = 500,
  shouldDebounceOnReset = false,
): DebouncedAtomModel<T> {
  const prevTimeoutAtom = atom<ReturnType<typeof setTimeout> | undefined>(undefined);

  // DO NOT EXPORT currentValueAtom as using this atom to set state can cause
  // inconsistent state between currentValueAtom and debouncedValueAtom
  const _currentValueAtom = atom<T>(initialValue);
  const isDebouncingAtom = atom(false);

  const debouncedValueAtom = atom(initialValue, (get, set, update: SetStateAction<T>) => {
    clearTimeout(get(prevTimeoutAtom));

    const prevValue = get<T>(_currentValueAtom);
    const nextValue = typeof update === 'function' ? (update as (prev: T) => T)(prevValue) : update;

    const onDebounceStart = () => {
      set(_currentValueAtom, nextValue);
      set(isDebouncingAtom, true);
    };

    const onDebounceEnd = () => {
      set(debouncedValueAtom, nextValue);
      set(isDebouncingAtom, false);
    };

    onDebounceStart();

    if (!shouldDebounceOnReset && nextValue === initialValue) {
      onDebounceEnd();
      return;
    }

    const nextTimeoutId = setTimeout(() => {
      onDebounceEnd();
    }, delayMilliseconds);

    // set previous timeout atom in case it needs to get cleared
    set(prevTimeoutAtom, nextTimeoutId);
  });

  // exported atom setter to clear timeout if needed
  const clearTimeoutAtom = atom(null, (get, set) => {
    clearTimeout(get(prevTimeoutAtom));
    set(isDebouncingAtom, false);
  });

  return {
    currentValueAtom: atom<T>((get) => get<T>(_currentValueAtom)),
    isDebouncingAtom,
    clearTimeoutAtom,
    debouncedValueAtom,
  };
}
