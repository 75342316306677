import { PershingDuck } from './PershingDuck';
import * as selectors from './PershingSelectors';
import * as thunks from './PershingThunk';

export const PershingSelectors = selectors;

export const PershingActions = {
  ...PershingDuck.actions,
  ...thunks,
};

export const PershingDuckName = PershingDuck.name;

export const PershingReducer = PershingDuck.reducer;

export * from './PershingModel';
