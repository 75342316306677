import { forwardRef, ReactElement, Ref } from 'react';

import { Iconography, LocalizedTypography } from '@halodomination/halo-fe-common';
import { SingleInputDateRangeField, SingleInputDateRangeFieldProps } from '@mui/x-date-pickers-pro';
import { useT } from '@transifex/react';
import { DateTime } from 'luxon';

export type DateRangePickerFieldProps = SingleInputDateRangeFieldProps<DateTime>;

const DateRangePickerFieldComponent = (
  { helperText, label, ...props }: DateRangePickerFieldProps,
  ref?: Ref<HTMLInputElement>,
): ReactElement => {
  const translate = useT();

  const translatedLabel = label ? translate(label) : undefined;
  const icon = <Iconography iconName="calendar-alt" />;

  const helperTextContent = helperText ? (
    <LocalizedTypography sx={{ color: 'error.main', pl: 1 }} variant="caption">
      {helperText}
    </LocalizedTypography>
  ) : null;

  const slotProps: DateRangePickerFieldProps['slotProps'] = {
    textField: {
      helperText: helperTextContent,
      // TODO: For some reason slotProps doesn't work. InputProps will be deprecated.
      InputProps: { startAdornment: icon },
    },
  };

  return <SingleInputDateRangeField clearable {...props} label={translatedLabel} slotProps={slotProps} ref={ref} />;
};

export const DateRangePickerField = forwardRef<HTMLInputElement, DateRangePickerFieldProps>(
  DateRangePickerFieldComponent,
);
