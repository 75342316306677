import { ReactElement } from 'react';

import { V2PostTradeNoteDetailModalLayout } from '@halo-common/modals';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { ModalBase } from '@halodomination/halo-fe-common';

export type V2PostTradeNoteDetailModalProps = {
  open: boolean;
  onClose: () => void;
  referenceId?: number;
  loading?: boolean;
  filters?: {
    currencyId?: number;
    accountOption?: AccountTypeAheadOption | null;
  };
};

export const V2PostTradeNoteDetailModal = ({
  open,
  onClose,
  ...props
}: V2PostTradeNoteDetailModalProps): ReactElement => (
  <ModalBase size="large" open={open} onClose={onClose} className="wm-pdm-posttrade">
    <V2PostTradeNoteDetailModalLayout onClose={onClose} {...props} />
  </ModalBase>
);
