import { ReactElement } from 'react';

import { AuctionAdminModel } from '@halo-common/models';
import { useOrderHubEditMode } from '@halo-modules/admin';
import { OrdersActions } from '@halo-stores/Orders';
import { Iconography, IconographyColors, IconographyProps } from '@halodomination/halo-fe-common';
import { Button, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';

export type AuctionTableActionProps = {
  data: AuctionAdminModel;
  icon?: IconographyProps['iconName'];
  label: string;
  disabled?: boolean;
  color?: 'green' | 'orange' | 'purple' | 'blue' | 'grey';
};

export const AuctionTableAction = ({
  data,
  icon,
  label,
  color = 'grey',
  disabled = false,
}: AuctionTableActionProps): ReactElement => {
  const dispatch = useDispatch();

  const canEdit = useOrderHubEditMode();

  const contentColor = {
    green: 'success.dark',
    orange: 'warning.dark',
    blue: 'info.dark',
    purple: 'primary.dark',
    grey: 'text.faded',
  }[color] as IconographyColors;

  const backgroundColor = {
    green: 'success.background',
    orange: 'warning.background',
    blue: 'info.background',
    purple: 'primary.background',
    grey: 'text.faded',
  }[color];

  const ctaStyling = {
    backgroundColor: 'common.white',
    color: contentColor,
    borderColor: contentColor,
    width: '100%',
    '&:hover': {
      backgroundColor,
      borderColor: contentColor,
    },
  };

  const noteDetailsButtonStyling = {
    backgroundColor: 'transparent',
    color: contentColor,
    width: '100%',
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: 'unset',
    },
  };

  const startIcon = icon ? <Iconography color={contentColor} iconName={icon} /> : null;

  const handleActionClick = () => {
    void dispatch(OrdersActions.selectAuction({ auction: data }));
  };
  const handleDetailsClick = () => {
    void dispatch(OrdersActions.showAuctionDetails({ auction: data }));
  };

  const actionButton = canEdit ? (
    <Button
      sx={ctaStyling}
      fullWidth
      size="extraSmall"
      variant="outlined"
      onClick={handleActionClick}
      startIcon={startIcon}
      disabled={disabled}
    >
      {label}
    </Button>
  ) : null;

  return (
    <Stack sx={{ mt: 1, width: '100%' }} direction="column">
      {actionButton}
      <Button size="extraSmall" fullWidth sx={noteDetailsButtonStyling} onClick={handleDetailsClick}>
        Note Details
      </Button>
    </Stack>
  );
};
