import { ReactElement } from 'react';

import { useRemainingHeight } from '@halo-common/hooks';
import {
  OrderBookCalendarDetailModal,
  OrderBookJanneyApproveOrderModal,
  OrderBookJanneyCancelOrderModal,
  OrderBookJanneyDownloadCSVButton,
  OrderBookJanneyFilters,
  OrderBookJanneyTable,
} from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';

export const OrderBookJanneyPage = (): ReactElement => {
  const remainingHeight = useRemainingHeight();

  return (
    <>
      <Stack direction="column" spacing={2} sx={{ height: remainingHeight }}>
        <OrderBookJanneyFilters />
        <Stack direction="row" justify="flex-end">
          <OrderBookJanneyDownloadCSVButton />
        </Stack>
        <OrderBookJanneyTable />
      </Stack>
      <OrderBookJanneyApproveOrderModal />
      <OrderBookCalendarDetailModal />
      <OrderBookJanneyCancelOrderModal />
    </>
  );
};
