import { ReactElement } from 'react';

import { GridColDef, HaloDataGrid, HaloDataGridProps } from '@halodomination/halo-fe-common';

const commonCellProps: Partial<GridColDef> = {
  editable: false,
  sortable: false,
};

export type ProductDetailTableProps<T> = Omit<HaloDataGridProps, 'rows'> & {
  rows: Array<T>;
};

export const ProductDetailTable = <T extends unknown>({
  columns,
  hideFooter = true,
  ...props
}: ProductDetailTableProps<T>): ReactElement => {
  const updatedColumns: HaloDataGridProps['columns'] = columns.map((column) => ({
    ...column,
    ...commonCellProps,
  }));

  return (
    <HaloDataGrid
      {...props}
      hideFooter={hideFooter}
      columns={updatedColumns}
      density="compact"
      disableRowSelectionOnClick
      disableColumnMenu
      disableColumnResize
      disableColumnReorder
      disableColumnPinning
    />
  );
};
