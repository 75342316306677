import { ReactElement } from 'react';

import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { ActionButton } from '@halo-common/components';
import { useOrderBookDownloadCalendarsCSVMutation } from '@halo-data-sources/mutations';
import { Iconography } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';

export const OrderBookCalendarsDownloadCSVButton = (): ReactElement => {
  const searchFields = useAtomValue(orderBookQueryAtom);

  const { mutate, isPending } = useOrderBookDownloadCalendarsCSVMutation();

  const onClick = () => void mutate(searchFields);

  const icon = <Iconography iconName="download" />;

  return (
    <ActionButton
      color="primary"
      type="button"
      variant="text"
      onClick={onClick}
      startIcon={icon}
      loading={isPending}
      loadingIndicator="Downloading"
    >
      Download CSV
    </ActionButton>
  );
};
