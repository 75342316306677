import { ReactElement, useState } from 'react';

import { anchorElAtom } from '@halo-atoms/common';
import { orderBookAuctionAllocationEditDeleteAtom, orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { AccountTypeAhead } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { useSubmitAuctionAllocationsMutation } from '@halo-data-sources/mutations';
import { CurrencyInput, LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Popover, Stack } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';

const slotProps = {
  paper: {
    sx: {
      p: 2,
      width: 548,
    },
  },
};

export const EditAllocationPopoverAnchor = 'EditAllocationPopoverAnchor';

export type EditAllocationPopoverProps = {
  currencyCode?: string;
  currencySymbol?: string;
};

export const EditAllocationPopover = ({ currencyCode, currencySymbol }: EditAllocationPopoverProps): ReactElement => {
  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);
  const [anchorElMap, setAnchorElMap] = useAtom(anchorElAtom);
  const [allocation, setAllocation] = useAtom(orderBookAuctionAllocationEditDeleteAtom);
  const [error, setError] = useState<string | undefined>();

  const { mutate: submitAllocations } = useSubmitAuctionAllocationsMutation({
    onSuccess: () => {
      setAnchorElMap({ ...anchorElMap, [EditAllocationPopoverAnchor]: null });
      setAllocation();
    },
  });

  const anchorEl = anchorElMap[EditAllocationPopoverAnchor];
  const open = Boolean(anchorEl);

  const onClose = () => {
    setError(undefined);
    setAnchorElMap({ ...anchorElMap, [EditAllocationPopoverAnchor]: null });
    setAllocation();
  };

  const handleSelect = () => {
    // NO_OP
  };

  const handleNotionalChange = (value?: number | string) => {
    setError(undefined);

    let notional = value;

    if (typeof notional === 'string') notional = parseFloat(notional.replace(/[^\d.-]/g, ''));
    if (notional === undefined || Number.isNaN(notional)) notional = 0;
    if (notional < 1000) setError(translations.orderBook.auctions.quantityRequiredError);

    setAllocation({ notional });
  };

  const onSubmit = () => {
    const notional = allocation.notional ?? 0;
    if (notional < 1000) return setError(translations.orderBook.auctions.quantityRequiredError);

    const updatedAllocation = {
      account: allocation.accountOption?.account?.id,
      quantity: notional,
    };

    submitAllocations({ auctionId: selectedAuctionId, allocations: [updatedAllocation] });
    setError(undefined);
  };

  return (
    <Popover
      slotProps={slotProps}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'right' }}
    >
      <Stack direction="column" spacing={2}>
        <LocalizedTypography variant="h6">Change Allocation</LocalizedTypography>
        <Stack direction="row" spacing={1}>
          <AccountTypeAhead
            sx={{ flex: 2 }}
            label="Account"
            value={allocation.accountOption}
            onSelect={handleSelect}
            disabled
          />
          <CurrencyInput
            sx={{ flex: 1 }}
            label={translations.common.notional}
            size="large"
            allowNegative={false}
            value={allocation.notional}
            onChange={handleNotionalChange}
            error={Boolean(error)}
            helperText={error}
            CurrencyOptions={{ currency: currencyCode }}
            symbolOverride={currencySymbol}
          />
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <LocalizedButton variant="outlined" onClick={onClose}>
            {translations.common.cancel}
          </LocalizedButton>
          <LocalizedButton variant="contained" onClick={onSubmit}>
            {translations.common.save}
          </LocalizedButton>
        </Stack>
      </Stack>
    </Popover>
  );
};
