export enum WatchlistIconNamesEnum {
  'acorn' = 'ACORN',
  'car' = 'CAR',
  'house' = 'HOUSE',
  'plane' = 'PLANE',
  'charging-station' = 'CHARGINGSTATION',
  'apartment' = 'APARTMENT',
  'trophy-star' = 'TROPHYSTAR',
  'globe' = 'GLOBE',
  'gamepad-modern' = 'GAMEPADMODERN',
  'chart-line-up' = 'CHARTLINEUP',
  'chart-line-down' = 'CHARTLINEDOWN',
  'hospital' = 'HOSPITAL',
  'sack-dollar' = 'SACKDOLLAR',
  'money-bill' = 'MONEYBILL',
  'shield' = 'SHIELD',
  'calendar' = 'CALENDAR',
}
