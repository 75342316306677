import { ReactElement } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { LexicalTypography } from '@halo-common/components';
import { Chip, SxProps } from '@mui/material';
import { useAtom } from 'jotai';
import { startCase } from 'lodash';

export type NoteTypeChipsProps = {
  tag: string;
  sx: SxProps;
};

export const NoteTypeChips = ({ tag, sx }: NoteTypeChipsProps): ReactElement | null => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);

  const handleDynamicNoteTypeChipDelete = (value: string) => {
    const updatedNoteTypes = sideBarFilters.noteTypes.filter((type) => type !== value);
    setSideBarFilters({ tag, noteTypes: updatedNoteTypes });
  };

  if (!sideBarFilters.noteTypes.length) return null;

  const chips = sideBarFilters.noteTypes.map((noteType) => {
    const formattedLabel = startCase(noteType);
    const formattedLabelNode = <LexicalTypography variant="caption">{formattedLabel}</LexicalTypography>;

    return (
      <Chip
        sx={sx}
        size="small"
        variant="outlined"
        key={noteType}
        label={formattedLabelNode}
        onDelete={() => handleDynamicNoteTypeChipDelete(noteType)}
      />
    );
  });

  return <>{chips}</>;
};
