import { sendDisclaimerAcceptance } from '@halo-data-sources/clients';
import { OnboardingQueryKeyFactory, OnboardingDisclaimerQueryResult } from '@halo-data-sources/queries';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

const disclaimerAcceptanceMutationFn = async (id: number): Promise<number> => {
  await sendDisclaimerAcceptance({ disclaimerId: id });

  return id;
};

export const useDisclaimerAcceptanceMutation = (): UseMutationResult<number, Error, number> => {
  const queryClient = useQueryClient();

  return useMutation<number, Error, number>({
    mutationFn: disclaimerAcceptanceMutationFn,
    onSuccess: (data) => {
      const key = OnboardingQueryKeyFactory.disclaimers();

      queryClient.setQueryData<OnboardingDisclaimerQueryResult>(key, (prev) => {
        if (!prev) return undefined;

        const acceptedDisclaimerIndex = prev.findIndex((disclaimer) => disclaimer.id === data);
        prev[acceptedDisclaimerIndex].isAccepted = true;

        return prev;
      });
    },
  });
};
