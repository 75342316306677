import { ReactElement, useEffect } from 'react';

import { useRemainingHeight } from '@halo-common/hooks';
import { useLegacyWebSocket } from '@halo-common/providers';
import {
  OrderBookCalendarDetailModal,
  OrderBookPershingApproveOrderModal,
  OrderBookPershingCancelOrderModal,
  OrderBookPershingDownloadCSVButton,
  OrderBookPershingFilters,
  OrderBookPershingTable,
} from '@halo-modules/app';
import { PershingActions } from '@halo-stores/Pershing';
import { Stack } from '@halodomination/halo-fe-common';

export const OrderBookPershingPage = (): ReactElement => {
  const { connect } = useLegacyWebSocket();
  const remainingHeight = useRemainingHeight();

  useEffect(() => {
    connect({
      redux: { handlers: { CalendarAllocation: [PershingActions.handleOrderBookWebsocketEvent] } },
      delay: 60000,
    });
  }, []);

  return (
    <>
      <Stack direction="column" spacing={2} sx={{ height: remainingHeight }}>
        <OrderBookPershingFilters />
        <Stack direction="row" justify="flex-end">
          <OrderBookPershingDownloadCSVButton />
        </Stack>
        <OrderBookPershingTable />
      </Stack>
      <OrderBookPershingApproveOrderModal />
      <OrderBookCalendarDetailModal />
      <OrderBookPershingCancelOrderModal />
    </>
  );
};
