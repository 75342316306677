import { AuctionIssuerBidStatusEnum } from '@halo-common/enums';
import { AuctionIssuerBidStatuses, IssuerModel, QuoteAuctionModel } from '@halo-common/models';

export type useAuctionIssuerBidStatusPayload = {
  issuer?: IssuerModel;
  quote?: QuoteAuctionModel;
};

export const useAuctionIssuerBidStatus = ({
  issuer,
  quote,
}: useAuctionIssuerBidStatusPayload): AuctionIssuerBidStatuses => {
  if (quote?.isWinningQuote) return AuctionIssuerBidStatusEnum.winningQuote;
  if (quote?.comment?.buyerApproved === false) return AuctionIssuerBidStatusEnum.declinedByYou;
  if (quote?.comment?.buyerApproved === true) return AuctionIssuerBidStatusEnum.acceptedByYou;
  if (quote?.comment?.comment) return AuctionIssuerBidStatusEnum.receivedWithComment;
  if (quote) return AuctionIssuerBidStatusEnum.received;
  if (issuer?.passed) return AuctionIssuerBidStatusEnum.declinedToBid;
  return AuctionIssuerBidStatusEnum.waitingForBid;
};
