import { HTMLAttributes, ReactElement, useState } from 'react';

import { translations } from '@halo-common/translations';
import { useHouseholdQuery } from '@halo-data-sources/queries';
import { LocalizedTextField } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  formHelperTextClasses,
  SxProps,
  Typography,
} from '@mui/material';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

const HouseholdFormFieldSx = {
  [`& .${formHelperTextClasses.root}`]: {
    color: 'primary.main',
  },
};

export type HouseholdFormFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  id?: string;
  label: string;
  name: TName;
  sx?: SxProps<HaloTheme>;
  disabled?: boolean;
  control: Control<TFieldValues>;
};

export type HouseholdModelTypeAhead = Array<string>;

export const HouseholdFormField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  sx,
  disabled,
  name,
  control,
}: HouseholdFormFieldProps<TFieldValues, TName>): ReactElement => {
  const [isNewHousehold, setIsNewHousehold] = useState(false);

  const { data: households = [] } = useHouseholdQuery();

  const options = households.map((household) => household.name);
  const householdHelperText = isNewHousehold ? translations.components.householdFormFieldHelperText : undefined;

  const handleRenderOption = (props: HTMLAttributes<HTMLLIElement>, option: string) => (
    <Box component="li" {...props}>
      <Typography>{option}</Typography>
    </Box>
  );

  const getOptionLabel = (option: string) => option;

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <LocalizedTextField
      {...params}
      label={label}
      size="large"
      helperText={householdHelperText}
      sx={HouseholdFormFieldSx}
    />
  );

  const handleOnChange = (householdName?: string) => {
    const isOptionNewHousehold = Boolean(householdName && options.every((option) => !option.includes(householdName)));
    setIsNewHousehold(isOptionNewHousehold);
  };

  const handleOnBlur = (householdName?: string) => {
    const isOptionNewHousehold = Boolean(householdName && options.every((option) => option !== householdName));
    setIsNewHousehold(isOptionNewHousehold);
  };

  return (
    <Controller<TFieldValues>
      render={({ field: { onChange, value, ...inputProps } }) => (
        <Autocomplete
          {...inputProps}
          freeSolo
          value={value}
          options={options}
          disabled={disabled}
          renderInput={renderInput}
          sx={sx}
          multiple={false}
          getOptionLabel={getOptionLabel}
          renderOption={handleRenderOption}
          onBlur={() => {
            const trimmedData = (value as string)?.trim();
            handleOnBlur(trimmedData);
          }}
          onInputChange={(_, inputValue) => {
            const trimmedData = inputValue?.trim();
            onChange(trimmedData);
            handleOnChange(trimmedData);
          }}
          onChange={(_, data) => {
            const trimmedData = data?.trim();
            onChange(trimmedData);
          }}
        />
      )}
      name={name}
      control={control}
    />
  );
};
