import { ReactElement, useEffect } from 'react';

import { ConfirmationEnum } from '@halo-common/enums';
import { useOrderTicketContentPicker } from '@halo-common/hooks';
import { OrderTicketFormModelFields } from '@halo-common/modals';
import { MenuItem, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

type DiscretionDropdownProps = {
  bulk?: boolean;
};

export const DiscretionDropdown = ({ bulk }: DiscretionDropdownProps): ReactElement => {
  const { shouldDefaultDiscretion } = useOrderTicketContentPicker();

  const { control, formState, setValue } = useFormContext<OrderTicketFormModelFields>();
  const { errors } = formState;

  const discretionError = errors?.discretion?.message;
  const label = bulk ? 'Discretion?' : 'Discretion';

  useEffect(() => {
    if (shouldDefaultDiscretion) {
      setValue('discretion', ConfirmationEnum.No, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
    }
  }, [shouldDefaultDiscretion]);

  return (
    <Controller
      render={({ field: { ref, ...field } }) => (
        <TextField
          {...field}
          inputRef={ref}
          fullWidth
          size="large"
          select
          label={label}
          helperText={discretionError}
          error={Boolean(discretionError)}
          slotProps={{ inputLabel: { shrink: Boolean(field.value) } }}
        >
          <MenuItem value={ConfirmationEnum.Yes}>Yes</MenuItem>
          <MenuItem value={ConfirmationEnum.No}>No</MenuItem>
        </TextField>
      )}
      control={control}
      name="discretion"
    />
  );
};
