import { NoteTypeModel } from '@halo-common/models';
import { getNoteTypes } from '@halo-data-sources/clients';
import { apiNoteTypeMapper } from '@halo-data-sources/mappers';
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { NoteQueryKeyFactory } from '../keys';

const getNoteTypesQueryFn = async (): Promise<Array<NoteTypeModel>> => {
  const response = await getNoteTypes();
  return response.map((apiNoteType) => apiNoteTypeMapper(apiNoteType));
};

export const useNoteTypeQuery = (
  options?: Partial<UseQueryOptions<Array<NoteTypeModel> | null, Error>>,
): UseQueryResult<Array<NoteTypeModel> | null, Error> =>
  useQuery<Array<NoteTypeModel> | null, Error>({
    ...options,
    queryKey: NoteQueryKeyFactory.all(),
    queryFn: () => getNoteTypesQueryFn(),
    refetchOnMount: false,
  });
