import { accountModalAtom, portfolioAccountManagerAtom } from '@halo-atoms/portfolio';
import { AccountModel } from '@halo-common/models';
import { updateAccount } from '@halo-data-sources/clients';
import { HttpError } from '@halo-data-sources/errors';
import { ApiAccountMapper } from '@halo-data-sources/mappers';
import { AccountQueryKeyFactory } from '@halo-data-sources/queries';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';

export type UpdateAccountToExistingHouseholdPayload = {
  accountId: number;
  adviseeId: number | null;
  accountName: string;
};

const updateAccountToExistingHouseholdMutation = async ({
  accountId,
  ...payload
}: UpdateAccountToExistingHouseholdPayload) => {
  const result = await updateAccount(accountId, payload);

  return ApiAccountMapper.toAccountModel(result.account);
};

export const useUpdateAccountToExistingHouseholdMutation = (): UseMutationResult<
  AccountModel,
  HttpError,
  UpdateAccountToExistingHouseholdPayload
> => {
  const queryClient = useQueryClient();

  const { query } = useAtomValue(portfolioAccountManagerAtom);

  const { modalStepMap } = useAtomValue(accountModalAtom);
  const { handleNextAtom } = modalStepMap;
  const handleNext = useSetAtom(handleNextAtom);

  return useMutation<AccountModel, HttpError, UpdateAccountToExistingHouseholdPayload>({
    mutationFn: updateAccountToExistingHouseholdMutation,
    onSuccess: async (data) => {
      if (!data) return undefined;

      const key = AccountQueryKeyFactory.paged(query);
      await queryClient.refetchQueries({ queryKey: key });

      handleNext();
    },
    onError: (error) => {
      return error;
    },
  });
};
