import { ReactElement, useState } from 'react';

import { portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import { LifecycleRecommendationTypeEnum } from '@halo-common/enums';
import type {
  PortfolioLifecycleEventModel,
  PortfolioLifecycleModel,
  PortfolioLifecycleRecommendationEnhancedModel,
} from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useLifecycleEventBreakdownQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { useIsAccountTypeAheadSyncedOption, usePortfolioLifecycleTitleNamesByType } from '@halo-modules/app';
import {
  GridColDef,
  GridColumnResizeParams,
  GridPaginationModel,
  GridSortModel,
  HaloDataGrid,
  LocalizedTypography,
  mapNumberToLocalCurrency,
} from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';

import { PortfolioEventsAuctionReinvestment } from './PortfolioEventsAuctionReinvestment';
import { PortfolioEventsCalendarReinvestment } from './PortfolioEventsCalendarReinvestment';

const stackSx = {
  mt: 0,
};

const DEFAULT_COLUMN_WIDTH_DICTIONARY = {
  name: undefined,
  cashFlowAmount: undefined,
  totalNotional: undefined,
};

export type PortfolioEventsPaymentBreakdownTableProps = {
  event: PortfolioLifecycleModel;
  reinvestment?: PortfolioLifecycleRecommendationEnhancedModel;
  filterTypes?: Array<string>;
};

export const PortfolioEventsPaymentBreakdownTable = ({
  event,
  reinvestment,
  filterTypes,
}: PortfolioEventsPaymentBreakdownTableProps): ReactElement => {
  const [page, setPage] = useState(1);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'name', sort: 'asc' }]);
  const [columnWidths, setColumnWidths] = useState<Record<string, number | undefined>>(DEFAULT_COLUMN_WIDTH_DICTIONARY);
  const { filters } = useAtomValue(portfolioPositionsManagerAtom);

  const accountId = filters.accountOption?.account?.id;
  const householdId = filters.accountOption?.household?.id;
  const action = filters.accountOption?.action;

  const isSyncedOption = useIsAccountTypeAheadSyncedOption(action);

  const { totalNotional, cashFlowAmount, productId, eventDate, eventType, numberOfCoupons, currencyIsoCode } = event;

  const { data: user } = useUserInfoQuery();
  const { isPending, isFetching, data } = useLifecycleEventBreakdownQuery({
    accountIds: accountId ? [accountId] : undefined,
    householdId,
    synced: isSyncedOption,
    cashFlowAmount,
    productId,
    pageLength: 25,
    totalNotional,
    page,
    sort: sortModel,
  });

  const isExpired = DateTime.now().toFormat('yyyy-MM-dd') > eventDate;

  const { columnOne } = usePortfolioLifecycleTitleNamesByType(isExpired, eventType);

  const rows = data?.data ?? [];
  const pagination = data?.pagination;
  const hasPages = (pagination?.totalPages ?? 0) > 1;
  const totalResults = pagination?.totalResults ?? 0;
  const pageSizeOptions = pagination ? [pagination.resultsPerPage] : undefined;
  const initialPaginationModel = { page: 0, pageSize: 25 };
  const initialState = { pagination: { paginationModel: initialPaginationModel } };
  const paginationModel = { page: page - 1, pageSize: 25 };

  const whiteLabelCurrencies = user?.whiteLabel?.currencies ?? [];
  const eventCurrency = whiteLabelCurrencies.find((currency) => currency.code === currencyIsoCode);
  const currencySymbol = eventCurrency?.symbol;

  const handlePageChange = (model: GridPaginationModel) => {
    if (!isFetching) {
      setPage(model.page + 1);
    }
  };

  const handleSortChange = (sort: GridSortModel) => {
    setSortModel(sort);
  };

  const handleColumnResize = (params: GridColumnResizeParams) => {
    const key = params.colDef.field;
    const width = params.width;
    const newColumnWidthDict = { ...columnWidths, [key]: width };
    setColumnWidths(newColumnWidthDict);
  };

  const renderCashCell = (value: number) => {
    const maximumFractionDigits = value < 1 ? 2 : 0;
    const hasValue = value && value !== 0;

    return hasValue
      ? mapNumberToLocalCurrency(value, { currency: currencyIsoCode, maximumFractionDigits }, currencySymbol)
      : '-';
  };

  const commonColProps: Partial<GridColDef> = {
    minWidth: 150,
    align: 'center',
  };

  const columns: Array<GridColDef> = [
    {
      ...commonColProps,
      field: 'name',
      headerName: translations.common.account,
      width: columnWidths['name'],
      align: 'left',
      ...(!columnWidths['name'] && { flex: 2 }),
      valueGetter: (_, row: PortfolioLifecycleEventModel) => {
        const account = row?.account;

        const custodianName = account?.custodian.name ?? 'N/A';
        const accountNumber = account?.accountId ? `#${account.accountId}` : 'N/A';

        const buttonText = `${account?.name} - ${custodianName} ${accountNumber}`;

        return buttonText;
      },
    },
    {
      ...commonColProps,
      field: 'cashFlowAmount',
      headerName: columnOne(numberOfCoupons),
      width: columnWidths['cashFlowAmount'],
      ...(!columnWidths['cashFlowAmount'] && { flex: 1 }),
      valueGetter: (value: number) => renderCashCell(value),
    },
    {
      ...commonColProps,
      field: 'totalNotional',
      headerName: translations.portfolio.lifecycle.totalNotional,
      width: columnWidths['totalNotional'],
      ...(!columnWidths['totalNotional'] && { flex: 1 }),
      valueGetter: (value: number) => renderCashCell(value),
    },
  ];

  const hasAuctionReinvestment = reinvestment?.recommendationType === LifecycleRecommendationTypeEnum.AUCTION;
  const hasCalendarReinvestment = reinvestment?.recommendationType === LifecycleRecommendationTypeEnum.CALENDAR;

  const breakdownTableContainerSx = {
    flexBasis: hasAuctionReinvestment ? '60%' : '100%',
    mt: 1,
    width: '100%',
  };

  const auctionReinvestment = hasAuctionReinvestment ? (
    <PortfolioEventsAuctionReinvestment reinvestment={reinvestment} />
  ) : null;

  const calendarReinvestment = hasCalendarReinvestment ? (
    <PortfolioEventsCalendarReinvestment reinvestment={reinvestment} filterTypes={filterTypes} />
  ) : null;

  return (
    <Stack sx={stackSx} direction="row" spacing={3} alignItems="top">
      <Stack direction="column" spacing={2} sx={breakdownTableContainerSx}>
        {calendarReinvestment}
        <LocalizedTypography variant="subtitle1">
          {translations.portfolio.lifecycle.paymentBreakdown}:
        </LocalizedTypography>
        <HaloDataGrid
          density="compact"
          columns={columns}
          rows={rows}
          pagination={hasPages}
          paginationMode="server"
          paginationModel={paginationModel}
          sortingMode="server"
          sortModel={sortModel}
          sortingOrder={['desc', 'asc']}
          onSortModelChange={handleSortChange}
          onPaginationModelChange={handlePageChange}
          pageSizeOptions={pageSizeOptions}
          rowCount={totalResults}
          loading={isPending}
          initialState={initialState}
          getRowId={(row) => row.id ?? row.uid}
          noResultsMessage={translations.portfolio.lifecycle.noPaymentBreakdownResult}
          onColumnWidthChange={handleColumnResize}
        />
      </Stack>
      {auctionReinvestment}
    </Stack>
  );
};
