export const UPLOAD_FILE = {
  uploadPrelimTerms: 'Upload Preliminary Terms',
  uploadingWillReplaceSpreads: 'Uploading a file here will replace all previously entered spreads.',
  uploadPendingTitle: 'Upload is being processed',
  uploadPendingMessage: 'You will be notified when each CUSIP is processed and when the upload has completed. ',
  uploadSuccessTitle: 'Upload has been processed',
  uploadErrorTitle: 'Upload Error',
  uploadOverrideVerification: `Are you sure you’d like to upload {fileName}? This will override any CUSIPs that already exist.`,
  uploadWarning: `An issue was detected with the provided data having missing, conflicting, or incorrect values. Please review and correct before proceeding.`,
};
