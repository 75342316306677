import { ReactElement, useState } from 'react';

import { anchorElAtom } from '@halo-atoms/common';
import { portfolioPositionPopoverAtom } from '@halo-atoms/portfolio';
import { AccountTypeAhead } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { useEditPositionMutation } from '@halo-data-sources/mutations';
import { CurrencyInput, LocalizedButton, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Popover } from '@mui/material';
import { useAtom } from 'jotai';

const slotProps = {
  paper: {
    sx: {
      p: 2,
      width: 548,
    },
  },
};

export const EditPositionPopoverAnchor = 'EditPositionPopoverAnchor';

export type EditPositionPopoverProps = {
  termsheetId?: number;
  currencyCode?: string;
  currencySymbol?: string;
};

export const EditPositionPopover = ({
  termsheetId,
  currencyCode,
  currencySymbol,
}: EditPositionPopoverProps): ReactElement => {
  const { mutate: saveEdit } = useEditPositionMutation();
  const [anchorElMap, setAnchorElMap] = useAtom(anchorElAtom);
  const [position, setPosition] = useAtom(portfolioPositionPopoverAtom);
  const [error, setError] = useState<string | undefined>();

  const anchorEl = anchorElMap[EditPositionPopoverAnchor];
  const open = Boolean(anchorEl);

  const onClose = () => {
    setError(undefined);
    setAnchorElMap({ ...anchorElMap, [EditPositionPopoverAnchor]: null });
    setPosition();
  };

  const handleSelect = () => {
    // NO_OP
  };

  const handleNotionalChange = (value?: number | string) => {
    setError(undefined);

    let notional = value;

    if (typeof notional === 'string') notional = parseFloat(notional.replace(/[^\d.-]/g, ''));
    if (notional === undefined || Number.isNaN(notional)) notional = 0;
    if (notional < 1) setError(translations.pdm.postTrade.editPositionAllocationAmountError);

    setPosition({ notional });
  };

  const onSubmit = () => {
    const positionId = position.notePositionId;
    const notional = position.notional;

    if (termsheetId && positionId && notional && !error) {
      saveEdit({ termsheetId, positionId, notional });
      setError(undefined);
    }
  };

  return (
    <Popover
      slotProps={slotProps}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'right' }}
    >
      <Stack direction="column" spacing={2}>
        <LocalizedTypography variant="h6">{translations.pdm.postTrade.changeAllocationTitle}</LocalizedTypography>
        <Stack direction="row" spacing={1} xs={[8, 4]}>
          <AccountTypeAhead label="Account" value={position.accountOption} onSelect={handleSelect} disabled />
          <CurrencyInput
            label={translations.pdm.postTrade.allocationAmount}
            size="large"
            allowNegative={false}
            value={position.notional}
            onChange={handleNotionalChange}
            error={Boolean(error)}
            helperText={error}
            CurrencyOptions={{ currency: currencyCode }}
            symbolOverride={currencySymbol}
          />
        </Stack>
        <Stack direction="row" spacing={1} justify="flex-end">
          <LocalizedButton variant="outlined" onClick={onClose}>
            {translations.common.cancel}
          </LocalizedButton>
          <LocalizedButton variant="contained" onClick={onSubmit}>
            {translations.common.save}
          </LocalizedButton>
        </Stack>
      </Stack>
    </Popover>
  );
};
