import { ReactElement, ReactNode } from 'react';

import { AdminCalendarCardDataColumn } from '@halo-common/components';
import { CalendarOrderCategoryEnum } from '@halo-common/enums';
import { useNoteDetailCaption, usePrimaryAssetIdentifier } from '@halo-common/hooks';
import { CalendarModel, IssuerModel, NoteModel } from '@halo-common/models';
import { Stack as OldStack, useCombinedStyling } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, Paper, Skeleton, Stack, StackProps, SxProps, Typography } from '@mui/material';

const containerSx = { padding: 2, mx: '0 !important', textTransform: 'capitalize' };
const cardSummarySx = { marginTop: 0.75 };
const contentContainerSx = { marginTop: 3 };

const dataColumnDirection: StackProps['direction'] = { md: 'row', xs: 'column' };
const dataColumnSpacing = { md: 17, xs: 1 };
const actionContainerSx = { pb: { l: 0, xs: 2 } };

export type AdminCalendarCardProps = {
  issuer?: IssuerModel;
  cusip?: string;
  note?: NoteModel;
  closeDate?: string;
  settleDate?: string;
  reoffer?: number;
  category?: CalendarOrderCategoryEnum;
  actions?: ReactNode;
  children?: ReactNode;
  sx?: SxProps<HaloTheme>;
  loading?: boolean;
};

/**
 * @deprecated When Pershing is migrated to new data management flow
 * - Use V2AdminCalendarCard instead with any new development
 */
export const AdminCalendarCard = ({
  issuer,
  cusip,
  note,
  closeDate,
  settleDate,
  reoffer,
  category,
  children,
  actions,
  sx,
  loading = false,
}: AdminCalendarCardProps): ReactElement => {
  const combinedContainerSx = useCombinedStyling(containerSx, sx);

  const noteDetailsText = useNoteDetailCaption({ note, issuer, category });

  const closeDateValue = closeDate ?? '';
  const settlementDate = settleDate ?? '';

  const settlementDateColumn = settleDate ? (
    <AdminCalendarCardDataColumn loading={loading} header="Settles" value={settlementDate} />
  ) : null;

  const reofferColumn = reoffer ? (
    <AdminCalendarCardDataColumn loading={loading} header="Reoffer" value={`${reoffer}%`} />
  ) : null;

  const noteDetailsContent = loading ? (
    <Skeleton width={460} height={24} />
  ) : (
    <Typography color="textSecondary" sx={cardSummarySx}>
      {noteDetailsText}
    </Typography>
  );

  const content = children ? <Box sx={contentContainerSx}>{children}</Box> : null;

  return (
    <Paper elevation={6} sx={combinedContainerSx}>
      <OldStack direction="row" justify="space-between">
        <OldStack direction="row" spacing={17}>
          <AdminCalendarCardDataColumn loading={loading} header="Issuer" value={issuer?.name} />
          <AdminCalendarCardDataColumn loading={loading} header="CUSIP" value={cusip} />
          <AdminCalendarCardDataColumn loading={loading} header="Closes" value={closeDateValue} />
          {settlementDateColumn}
          {reofferColumn}
        </OldStack>
        <OldStack direction="row" spacing={2} alignItems="center">
          {actions}
        </OldStack>
      </OldStack>
      {noteDetailsContent}
      {content}
    </Paper>
  );
};

export type V2AdminCalendarCardProps = {
  calendar?: CalendarModel;
  actions?: ReactNode;
  columns?: Array<{ label: string; value: string }>;
  children?: ReactNode;
  sx?: SxProps<HaloTheme>;
  loading?: boolean;
};

/**
 * V2AdminCalendarCard that takes in the entirety of the calendar product and
 * builds out a comprehensive display.
 * @param {V2AdminCalendarCardProps} props - Component Props
 * @param {CalendarModel} props.calendar - Calendar Product Model
 * @param {Array<{label: string; value: string}>} props.columns - Array of additional columns to show in header.
 * @param {ReactNode} [props.children] - Content to show below Calendar Header
 * @param {ReactNode} [props.actions] - Any actionable components to render in top right of card.
 * @param {SxProps} [props.sx] - JSS Mui Styling
 * @param {boolean} [props.loading=false] - Flag to enable loading state
 *
 * @component
 * @example
 * const cardActions = [
 *   <Button type="button" onClick={doSomething}>
 *     Preview
 *   </Button>,
 * ];
 *
 * return (
 *   <V2AdminCalendarCard calendar={calendar} actions={cardActions}>
 *     {table}
 *   </V2AdminCalendarCard>
 * );
 */
export const V2AdminCalendarCard = ({
  calendar,
  columns = [],
  children,
  actions,
  sx,
  loading = false,
}: V2AdminCalendarCardProps): ReactElement => {
  const combinedContainerSx = useCombinedStyling(containerSx, sx);

  const { identifier, identifierType } = usePrimaryAssetIdentifier(calendar);

  const issuer = calendar?.issuer;
  const displayName = calendar?.displayName;

  const dateColumns = columns.map(({ label, value }) => (
    <AdminCalendarCardDataColumn key={label} loading={loading} header={label} value={value} />
  ));

  const noteDetailsContent = loading ? (
    <Skeleton width={460} height={24} />
  ) : (
    <Typography color="textSecondary" sx={cardSummarySx}>
      {displayName}
    </Typography>
  );

  const content = children ? <Box sx={contentContainerSx}>{children}</Box> : null;

  return (
    <Paper elevation={6} sx={combinedContainerSx}>
      <Stack direction="row" justifyContent="space-between" flexWrap="wrap-reverse">
        <Stack direction={dataColumnDirection} spacing={dataColumnSpacing}>
          <AdminCalendarCardDataColumn loading={loading} header="Issuer" value={issuer?.name} />
          <AdminCalendarCardDataColumn loading={loading} header={identifierType} value={identifier} />
          {dateColumns}
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center" sx={actionContainerSx}>
          {actions}
        </Stack>
      </Stack>
      {noteDetailsContent}
      {content}
    </Paper>
  );
};
