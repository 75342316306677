import { ReactElement, useEffect } from 'react';

import { ActionButton } from '@halo-common/components';
import { AddAccountModalFormFields } from '@halo-common/modals';
import { AccountModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { HttpError } from '@halo-data-sources/errors';
import { Iconography, LocalizedButton, Stack } from '@halodomination/halo-fe-common';
import { useFormContext } from 'react-hook-form';

const footerSx = {
  width: '100%',
};

export type AddAccountWizardFooterProps = {
  onBack?: () => void;
  onClose: () => void;
  onCreate: () => Promise<void>;
  onReset?: () => void;
  onUpdate: () => Promise<void>;
  onSuccess?: () => void;
  selected?: AccountModel | null;
  step: number;
  isLoading: boolean;
  error?: Array<HttpError | null>;
};

export const AddAccountWizardFooter = ({
  onBack,
  onClose,
  onCreate,
  onReset,
  onUpdate,
  onSuccess,
  selected,
  step,
  isLoading,
  error,
}: AddAccountWizardFooterProps): ReactElement => {
  const { setError } = useFormContext<AddAccountModalFormFields>();

  const isSelectedAccount = Boolean(selected);

  const stepList = ['modalForm', 'modalSuccess'];
  const currentStep = stepList[step];
  const isFormStep = currentStep === 'modalForm';
  const showUpdateFooter = isSelectedAccount && isFormStep;
  const showCreateAnother = !isSelectedAccount;

  const handleAccountModalBack = () => onBack?.();
  const handleCancel = () => onClose();
  const handleCreateAnotherClick = () => onReset?.();
  const handleDoneClick = () => onSuccess?.();

  const backButtonIcon = <Iconography iconName="arrow-left" />;
  const cancelButtonIcon = <Iconography iconName="x" />;

  const updateAccountFooter = (
    <Stack direction="row" justify="space-between" alignItems="center" sx={footerSx}>
      <LocalizedButton onClick={handleCancel} variant="text" color="primary" startIcon={cancelButtonIcon}>
        {translations.common.cancel}
      </LocalizedButton>
      <ActionButton onClick={onUpdate} type="submit" variant="contained" color="primary" loading={isLoading}>
        {translations.common.save}
      </ActionButton>
    </Stack>
  );

  const createAccountFooter = (
    <Stack direction="row" justify="space-between" alignItems="center" sx={footerSx}>
      <LocalizedButton onClick={handleAccountModalBack} variant="text" color="primary" startIcon={backButtonIcon}>
        {translations.common.back}
      </LocalizedButton>
      <ActionButton onClick={onCreate} type="submit" variant="contained" color="primary" loading={isLoading}>
        {translations.common.create}
      </ActionButton>
    </Stack>
  );

  const createAnotherButton = showCreateAnother ? (
    <LocalizedButton onClick={handleCreateAnotherClick} variant="outlined" color="primary">
      {translations.portfolio.addAccountModal.wizardFormCreateAnotherButton}
    </LocalizedButton>
  ) : null;

  const successFooterButtons = (
    <Stack spacing={2} direction="row" justify="end" alignItems="center" sx={footerSx}>
      {createAnotherButton}
      <LocalizedButton onClick={handleDoneClick} type="button" variant="contained" color="primary">
        {translations.common.done}
      </LocalizedButton>
    </Stack>
  );

  const hasFormDuplicateError = Array.isArray(error) && error.length > 0 && error[0]?.status === 409;

  useEffect(() => {
    if (hasFormDuplicateError) {
      setError('accountNumber', { message: translations.portfolio.addAccountModal.formDuplicateError });
    }
  }, [hasFormDuplicateError]);

  if (showUpdateFooter) return updateAccountFooter;
  else return isFormStep ? createAccountFooter : successFooterButtons;
};
