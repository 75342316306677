import { HTMLAttributes, ReactElement, useState } from 'react';

import { useDebounced, usePrimaryAssetIdentifier } from '@halo-common/hooks';
import { ProductByAssetModel, useCalendarAssetSearchQuery } from '@halo-data-sources/queries';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  Box,
  CircularProgress,
  TextField,
  TextFieldProps,
  Typography,
  inputBaseClasses,
} from '@mui/material';

const endAdornmentSx = {
  display: 'flex',
};

const loadingIndicatorSx = {
  marginRight: 1,
};

const clientInputSx = {
  [`.${inputBaseClasses.root}`]: {
    paddingLeft: 2,
  },
};

export type V2ExecutionHubAssetIdFilterProps = Omit<
  AutocompleteProps<ProductByAssetModel, boolean, boolean, undefined>,
  'options' | 'renderInput' | 'onChange'
> & {
  onChange?: (option: ProductByAssetModel | Array<ProductByAssetModel> | null) => void;
  TextFieldProps?: TextFieldProps;
};

export const V2ExecutionHubAssetIdFilter = ({
  onChange,
  TextFieldProps,
  ...props
}: V2ExecutionHubAssetIdFilterProps): ReactElement => {
  const [query, setQuery] = useState('');

  const { identifierType } = usePrimaryAssetIdentifier();

  const { data: assets = [], isPending } = useCalendarAssetSearchQuery(query, identifierType);

  const debouncedSearchHandler = useDebounced(setQuery, 500);

  const handleSearch = (_: unknown, value: string) => {
    debouncedSearchHandler(value);
  };

  const getOptionLabel = (option: ProductByAssetModel) => option.assetId;

  const handleChange = (_: unknown, option: ProductByAssetModel | Array<ProductByAssetModel> | null) => {
    onChange?.(option);
  };

  const handleRenderOption = (props: HTMLAttributes<HTMLLIElement>, option: ProductByAssetModel) => (
    <Box {...props} component="li" key={option.id}>
      <Typography>{option.assetId}</Typography>
    </Box>
  );

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const textFieldInputLoadingIndicator = isPending ? <CircularProgress sx={loadingIndicatorSx} size={20} /> : null;

    const textFieldInputProps = {
      ...params.InputProps,
      endAdornment: (
        <Box sx={endAdornmentSx}>
          {textFieldInputLoadingIndicator}
          {params.InputProps.endAdornment}
        </Box>
      ),
    };

    return (
      <TextField
        {...params}
        label={`Search by ${identifierType.toUpperCase()}`}
        sx={clientInputSx}
        slotProps={{ input: textFieldInputProps }}
        {...TextFieldProps}
      />
    );
  };

  return (
    <Autocomplete<ProductByAssetModel, boolean, boolean, undefined>
      {...props}
      noOptionsText={`No assets found matching that id.`}
      options={assets}
      onChange={handleChange}
      onInputChange={handleSearch}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel}
      renderOption={handleRenderOption}
    />
  );
};
