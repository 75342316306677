import { DocGenQueryData } from '@halo-common/models';
import { getClientConfiguration, request } from '@halo-data-sources/clients';
import { ApiDocGenPathModel, ApiDocGenTokenModel } from '@halo-data-sources/models';

const COMMON_DOCGEN_TOKEN_PATH = `/common/auth/docgen/token`;
const PERFORMANCE_REPORT_DOCGEN_TOKEN_PATH = `/posttrade/portfolio/performancereport/token`;
const PORTFOLIO_PERFORMANCE_REPORT_DOCGEN_TOKEN_PATH = `/posttrade/portfolio/portfolioperformancereport/token`;

export const getDocgenToken = async (query?: string): Promise<ApiDocGenTokenModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_DOCGEN_TOKEN_PATH);

  const queryString = query ? `&${query}` : '';
  const docgenUrl = `${clientConfiguration.basePath}?_csrf_token=${clientConfiguration.csrfToken}${queryString}`;

  const response = await request<ApiDocGenTokenModel>(docgenUrl, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const getPerformanceReportDocgenToken = async (query: string): Promise<ApiDocGenTokenModel> => {
  const clientConfiguration = await getClientConfiguration(PERFORMANCE_REPORT_DOCGEN_TOKEN_PATH);

  const docgenUrl = `${clientConfiguration.basePath}?${query}`;

  const response = await request<ApiDocGenTokenModel>(docgenUrl, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const postRequestBuildDoc = async (
  url: string,
  queryData?: DocGenQueryData,
  includeCSRF = false,
): Promise<ApiDocGenPathModel> => {
  const clientConfiguration = await getClientConfiguration();

  const requestBody = new URLSearchParams();

  if (includeCSRF) {
    requestBody.append('_csrf_token', String(clientConfiguration.csrfToken));
  }

  if (queryData) {
    Object.entries(queryData)?.forEach(([key, val]) => {
      requestBody.append(key, String(val));
    });
  }

  const bodyString = requestBody.toString();

  const response = await request<ApiDocGenPathModel>(url, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: bodyString,
  });

  return response;
};

export const getPortfolioPerformanceReportDocgenToken = async (query: string): Promise<ApiDocGenTokenModel> => {
  const clientConfiguration = await getClientConfiguration(PORTFOLIO_PERFORMANCE_REPORT_DOCGEN_TOKEN_PATH);

  const docgenUrl = `${clientConfiguration.basePath}?${query}`;

  const response = await request<ApiDocGenTokenModel>(docgenUrl, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};
