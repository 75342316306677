import { useOrderTicketContentPicker } from '@halo-common/hooks';
import { OrderTicketReceiptModel } from '@halo-common/models';
import { BulkAllocationError } from '@halo-data-sources/errors';
import { OrderTicketValidationSwitchPayload } from '@halo-data-sources/switches';
import { UseMutationOptions } from '@tanstack/react-query';

import { useJanneySubmissionHandlers } from './useJanneySubmissionHandlers';
import { usePershingSubmissionHandlers } from './usePershingSubmissionHandlers';

export type OrderTicketSubmissionSwitchHandlerResult = Pick<
  UseMutationOptions<OrderTicketReceiptModel | null, BulkAllocationError, OrderTicketValidationSwitchPayload>,
  'onMutate' | 'onSuccess' | 'onError'
>;

export const useOrderTicketSubmissionSwitchHandlers = (): OrderTicketSubmissionSwitchHandlerResult => {
  const { isPershing } = useOrderTicketContentPicker();

  const janneySubmissionHandlers = useJanneySubmissionHandlers();
  const pershingSubmissionHandlers = usePershingSubmissionHandlers();

  if (isPershing) return pershingSubmissionHandlers;
  else return janneySubmissionHandlers;
};
