import { ReactElement } from 'react';

import { LifecycleEventTypeEnum } from '@halo-common/enums';
import { LocalizedTypography, Stack, useChartColors } from '@halodomination/halo-fe-common';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';

export type DateTilePropModel = {
  eventType: LifecycleEventTypeEnum;
  date: string;
  previousDate?: boolean;
};

export const DateTile = ({ eventType, date, previousDate }: DateTilePropModel): ReactElement => {
  const { palette } = useChartColors('default');

  const computedEventType = previousDate ? 'PAST_EVENT' : eventType;

  const eventColorScheme = {
    EXPIRATION: {
      backgroundColor: palette.accent1.main,
      color: palette.accent1.contrastText,
    },
    FIXED_COUPON: {
      backgroundColor: palette.accent3.main,
      color: palette.accent3.contrastText,
    },
    CONDITIONAL_COUPON: {
      backgroundColor: palette.accent4.main,
      color: palette.accent4.contrastText,
    },
    MEMORY_COUPON: {
      backgroundColor: palette.accent7.main,
      color: palette.accent7.contrastText,
    },
    ISSUER_CALL_OBS: {
      backgroundColor: palette.accent11.main,
      color: palette.accent1.contrastText,
    },
    AUTOCALL_OBS: {
      backgroundColor: palette.accent9.main,
      color: palette.accent1.contrastText,
    },
    PAST_EVENT: {
      backgroundColor: 'grey.100',
      color: 'text.secondary',
    },
    EMPTY: undefined,
  }[computedEventType];

  const boxStyling = {
    display: 'flex',
    borderRadius: 1,
    width: 56,
    height: 56,
    padding: 1,
    lineHeight: 0.9,
    ...eventColorScheme,
  };

  const utcDate = DateTime.fromISO(date);

  const month = utcDate.monthShort;
  const day = utcDate.day;
  const year = utcDate.year;

  return (
    <Box>
      <Stack sx={boxStyling} direction="column" justify="center" alignItems="center">
        <LocalizedTypography variant="caption" fontWeight={500}>
          {month}
        </LocalizedTypography>
        <Typography variant="h6">{day}</Typography>
        <Typography variant="caption" fontWeight={500}>
          {year}
        </Typography>
      </Stack>
    </Box>
  );
};
