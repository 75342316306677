import { FIX_DATE_TIME_FORMAT, YEAR_MONTH_DAY_DATE_FORMAT } from '@halo-common/constants';
import {
  FixOrderChildrenAuxiliaryModel,
  FixOrderChildrenModel,
  FixOrderFeeModel,
  FixOrderModel,
  FixOrderStipulationModel,
} from '@halo-common/models';
import {
  ApiFixFeeModel,
  ApiFixOrderChildrenAuxiliaryModel,
  ApiFixOrderChildrenModel,
  ApiFixOrderModel,
  ApiFixOrderStipulationModel,
} from '@halo-data-sources/models';
import { DateTime } from 'luxon';

export const ApiFixMapper = {
  toFixOrderChildrenAuxiliaryModel: (model: ApiFixOrderChildrenAuxiliaryModel): FixOrderChildrenAuxiliaryModel => ({
    dtc: model.dtc ? parseFloat(model.dtc) : null,
    mpid: model.mpid ? model.mpid : null,
    price: model.price ? parseFloat(model.price) : null,
  }),
  toFixOrderStipulationModel: (model: ApiFixOrderStipulationModel): FixOrderStipulationModel => ({
    type: model.type,
    value: model.value,
  }),
  toFixOrderChildrenModel: (model: ApiFixOrderChildrenModel): FixOrderChildrenModel => ({
    auxiliary: model.auxiliary ? ApiFixMapper.toFixOrderChildrenAuxiliaryModel(model.auxiliary) : null,
    commission: model.commission,
    id: model.id,
    miscFees: model.misc_fees.map(ApiFixMapper.toFixFeeModel),
    price: model.price,
    secondaryOrderId: model.secondary_order_id,
    shares: model.shares,
    side: model.side,
    status: model.status,
    stipulations: model.stipulations.map(ApiFixMapper.toFixOrderStipulationModel),
  }),
  toFixFeeModel: (model: ApiFixFeeModel): FixOrderFeeModel => ({
    basis: model.basis,
    type: model.type,
    amount: model.amount,
  }),
  toFixOrder: (model: ApiFixOrderModel): FixOrderModel => ({
    accountType: model.auxiliary.AccountType,
    discretionUsed: model.auxiliary.DiscretionUsed,
    orderReceiptTime: DateTime.fromFormat(
      model.auxiliary.PershingOrderReceiptTime,
      FIX_DATE_TIME_FORMAT,
    ).toISO() as string,
    orderReceiveFrom: model.auxiliary.PershingOrderReceiveFrom,
    calendarStatus: model.calendarstatus,
    cusip: model.cusip,
    commission: model.commission ? parseFloat(model.commission) : 0,
    capacity: model.capacity,
    id: model.id,
    miscFees: model.misc_fees?.map(ApiFixMapper.toFixFeeModel) ?? [],
    parPrice: model.par_price,
    price: model.price,
    settlementDate: DateTime.fromFormat(model.settlement_date, YEAR_MONTH_DAY_DATE_FORMAT).toISO() as string,
    settlementType: model.settlement_type,
    shares: model.shares,
    side: model.side,
    solicited: model.solicited,
    status: model.status,
    stipulations: model.stipulations.map(ApiFixMapper.toFixOrderStipulationModel),
    children: model.children.map(ApiFixMapper.toFixOrderChildrenModel),
  }),
};
