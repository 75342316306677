import { translations } from '@halo-common/translations';
import { deleteTermsheetRepoDocs } from '@halo-data-sources/clients';
import { TermsheetRepoQueryKeyFactory } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationOptions, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

export type useDeleteTermsheetRepoDocMutationOptions = UseMutationOptions<void, Error, Array<number>> & {
  errorMsg?: string;
  successMsg?: string;
};

export type useDeleteTermsheetRepoDocMutationResult = UseMutationResult<void, Error, Array<number>>;

const deleteTermsheetRepoDocMutationFn = async (ids: Array<number>) => {
  await deleteTermsheetRepoDocs(ids);
};

export const useDeleteTermsheetRepoDocMutation = ({
  errorMsg = translations.messages.error,
  successMsg,
  ...options
}: useDeleteTermsheetRepoDocMutationOptions = {}): useDeleteTermsheetRepoDocMutationResult => {
  const queryClient = useQueryClient();
  const { enqueueSuccessEvent, enqueueErrorEvent, closeSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: deleteTermsheetRepoDocMutationFn,
    ...options,
    onError: (...props) => {
      closeSnackbar();
      enqueueErrorEvent({ message: errorMsg });
      options?.onError?.(...props);
    },
    onSuccess: (...props) => {
      const [, variables] = props;
      closeSnackbar();
      const defaultMessage =
        variables.length > 1
          ? translations.documents.termsheets.deleteTermsheetsSuccessMessage
          : translations.documents.termsheets.deleteTermsheetSuccessMessage;
      const message = successMsg || defaultMessage;
      enqueueSuccessEvent({ message });
      const key = TermsheetRepoQueryKeyFactory.all();
      void queryClient.invalidateQueries({ queryKey: key });
      options?.onSuccess?.(...props);
    },
  });
};
