import { ReactElement } from 'react';

import { useTheme } from '@mui/material';

export type EducationVideoPlayerProps = {
  path?: string;
};

export const EducationVideoPlayer = ({ path }: EducationVideoPlayerProps): ReactElement => {
  const theme = useTheme();

  const videoStyling = {
    maxWidth: 900,
    width: '100%',
    borderRadius: theme.spacing(2),
  };

  return (
    <video controls style={videoStyling}>
      <source src={path} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};
