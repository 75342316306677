import { NoteTypeEnum } from '@halo-common/enums';
import { PortfolioOrderModel, V2NoteModel } from '@halo-common/models';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import {
  PDMKeyFactory,
  PortfolioQueryKeyFactory,
  PostTradeProductDetailsEarlyRedemptionQueryResult,
  TermsheetQueryKeyFactory,
  useIsNoteMigratedQuery,
} from '@halo-data-sources/queries';
import {
  LineChartDataPoint,
  LineChartDot,
  LineChartLineDefinition,
  LineChartReferenceLine,
} from '@halodomination/halo-fe-common';
import { useHaloTheme } from '@halodomination/halo-fe-theme';
import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';

export type AutoCallEventsQueryResult = {
  data: Array<LineChartDataPoint>;
  lines: Array<LineChartLineDefinition>;
  references: Array<LineChartReferenceLine>;
};

export const AUTO_CALL_LEVEL_LINE_NAME = 'Autocall Level';
export const MAX_RETURN_LINE_NAME = 'Max Return';

export const useUnderlyingChartEnhancement = (
  id?: number,
  lines: Array<LineChartLineDefinition> = [],
  data: Array<LineChartDataPoint> = [],
  references: Array<LineChartReferenceLine> = [],
  accountDetails?: AccountTypeAheadOption | null,
): AutoCallEventsQueryResult => {
  const { theme } = useHaloTheme();

  const queryClient = useQueryClient();

  const accountId = accountDetails?.account?.id;
  const householdId = accountDetails?.household?.id;

  const { data: isMigrated } = useIsNoteMigratedQuery(id);

  const productKey = isMigrated ? TermsheetQueryKeyFactory.notes(id) : PortfolioQueryKeyFactory.termsheet(id);
  const product = queryClient.getQueryData<Array<V2NoteModel> | PortfolioOrderModel | null>(productKey);

  const v1Product = product as PortfolioOrderModel;
  const v1ProductType = v1Product?.note?.type;

  const v2Product = (product as Array<V2NoteModel>)?.[0];
  const v2ProductType = v2Product?.type;

  const type = v1ProductType ?? v2ProductType;
  const hasNonCall = Boolean(v1Product?.note?.callInformation?.nonCallPeriod);

  const earlyRedemptionKey = PDMKeyFactory.earlyRedemptionDetails(id, { accountId, householdId, type });
  const eventsData = queryClient.getQueryData<PostTradeProductDetailsEarlyRedemptionQueryResult>(earlyRedemptionKey);

  const events = eventsData?.events ?? [];
  const lineColor = theme.palette.common.charts.negative.main;

  if (!lines.length) return { lines: [], references: [], data: [] };
  else if (!events?.length) return { lines, references, data };

  const nonFutureEvents = events.filter((event) => DateTime.fromISO(event.date) < DateTime.now());

  const allFutureEvents = nonFutureEvents.length === 0;
  const hasOnlyFutureEvents = hasNonCall && allFutureEvents;
  const isIncomeIssuerCall = v1Product?.note?.isIssuerCall && v1Product?.note?.type === NoteTypeEnum.Income;
  const hideAutoCallLine = hasOnlyFutureEvents || isIncomeIssuerCall;

  const showFutureAutoCallIndicatorLine = !hasNonCall && allFutureEvents;

  const enhancedLineDefinitions: Array<LineChartLineDefinition> = [
    ...lines,
    {
      color: lineColor,
      label: AUTO_CALL_LEVEL_LINE_NAME,
      type: 'stepBefore',
      dot: ({ index, ...dotProps }) => {
        const color = index !== 0 ? lineColor : 'transparent';
        return <LineChartDot {...dotProps} index={index} fill={color} stroke={color} />;
      },
    },
  ];

  if (hideAutoCallLine) {
    return { lines, references, data };
  } else if (showFutureAutoCallIndicatorLine) {
    const enhancedReferenceLines: Array<LineChartReferenceLine> = [...references];
    return { lines: enhancedLineDefinitions, references: enhancedReferenceLines, data };
  } else {
    const enhancedPoints = nonFutureEvents.reduce(
      (points: Array<LineChartDataPoint>, event) => {
        const seconds = DateTime.fromISO(event.date).toSeconds();
        const pointIndex = points.findIndex((point) => point.x === seconds);
        const level = event?.level ? event.level - 100 : event.level;

        if (pointIndex < 0) return [...points, { x: seconds, [AUTO_CALL_LEVEL_LINE_NAME]: level }];

        points[pointIndex][AUTO_CALL_LEVEL_LINE_NAME] = level;

        return points;
      },
      [...data],
    );

    if (!hasNonCall) {
      const level = nonFutureEvents[0]?.level ? nonFutureEvents[0].level - 100 : nonFutureEvents[0].level;
      enhancedPoints[0][AUTO_CALL_LEVEL_LINE_NAME] = level ? level - 100 : level;
    }

    const enhancedReferenceLines: Array<LineChartReferenceLine> = [...references];
    return { lines: enhancedLineDefinitions, references: enhancedReferenceLines, data: enhancedPoints };
  }
};
