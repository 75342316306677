import { useEffect } from 'react';

import { DynamicFiltersSideBarPayload, sideFiltersLoadedAtom } from '@halo-atoms/common';
import type { UserSideBarPreferenceKey } from '@halo-common/models';
import { useLegacyRouter } from '@halo-common/providers';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { useSetAtom } from 'jotai';
import { isPlainObject } from 'lodash';

export const useLoadInitialSidebarPanelState = (tag: UserSideBarPreferenceKey): void => {
  const legacyRouter = useLegacyRouter();
  const { data: user, isPending } = useUserInfoQuery();

  const onLoaded = useSetAtom(sideFiltersLoadedAtom);

  const preferences = user?.preferences[tag];

  const isReady = legacyRouter.isReady && !isPending;

  useEffect(() => {
    if (!isReady) return;

    const initialState = legacyRouter.getQueryParams('initialState');

    if (initialState) {
      try {
        const parsedState: Omit<DynamicFiltersSideBarPayload, 'tag'> = JSON.parse(initialState);
        if (isPlainObject(parsedState)) return onLoaded({ tag, ...parsedState });
      } catch (e) {
        console.warn('Failed to parse `initialState` from URL query params', e);
      }
    } else if (preferences) {
      onLoaded({ tag, ...preferences });
    }
  }, [isReady]);
};
