import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type OrdersClientQueryKeys = {
  all: QueryKeyFactoryFn;
  query: QueryKeyFactoryFn;
};

export const HistoricalPerformanceQueryKeyFactory: OrdersClientQueryKeys = {
  all: () => ['historical', 'performance'],
  query: (searchParams) => [...HistoricalPerformanceQueryKeyFactory.all(), 'query', hash(searchParams)],
};
