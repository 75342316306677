export const mapEnumValue = <TEnum = unknown>(
  input: string | undefined | null,
  enumObject: Record<string, string>,
): TEnum => {
  if (!input) return '' as TEnum;

  const entries = Object.entries(enumObject);
  const entry = entries.find(([, value]) => input === value);

  if (!entry) return input as TEnum;

  const [key] = entry;

  return enumObject[key] as TEnum;
};
