import type { TermsheetRepoDocModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { fetchDocumentFromS3 } from '@halo-common/utils';
import { createTermsheetRepoDoc } from '@halo-data-sources/clients';
import { ApiTermsheetRepoMapper } from '@halo-data-sources/mappers';
import { TermsheetRepoQueryKeyFactory } from '@halo-data-sources/queries';
import { TermsheetFormSchema } from '@halo-modules/app/documents/repository';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationOptions, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

export type useCreateTermsheetRepoDocMutationVariables = TermsheetFormSchema;

export type useCreateTermsheetRepoDocMutationOptions = UseMutationOptions<
  TermsheetRepoDocModel,
  Error,
  useCreateTermsheetRepoDocMutationVariables
>;

export type useCreateTermsheetRepoDocMutationResult = UseMutationResult<
  TermsheetRepoDocModel,
  Error,
  useCreateTermsheetRepoDocMutationVariables
>;

const errorMsg = translations.messages.error;
const successMsg = translations.documents.termsheets.createTermsheetSuccessMessage;

const createTermsheetRepoDocMutationFn = async (fields: useCreateTermsheetRepoDocMutationVariables) => {
  const payload = ApiTermsheetRepoMapper.toTermsheetPayload(fields);
  const file = await fetchDocumentFromS3(fields.document);
  payload.document = file;
  const data = await createTermsheetRepoDoc(payload);
  return ApiTermsheetRepoMapper.toTermsheetRepoDocModel(data);
};

export const useCreateTermsheetRepoDocMutation = (
  options?: useCreateTermsheetRepoDocMutationOptions,
): useCreateTermsheetRepoDocMutationResult => {
  const queryClient = useQueryClient();
  const { enqueueSuccessEvent, enqueueErrorEvent, closeSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: createTermsheetRepoDocMutationFn,
    ...options,
    onError: (...props) => {
      closeSnackbar();
      enqueueErrorEvent({ message: errorMsg });
      options?.onError?.(...props);
    },
    onSuccess: (...props) => {
      closeSnackbar();
      enqueueSuccessEvent({ message: successMsg });
      const key = TermsheetRepoQueryKeyFactory.all();
      void queryClient.invalidateQueries({ queryKey: key });
      options?.onSuccess?.(...props);
    },
  });
};
