import { AuctionUpdateAdminModel, AuctionAdminModel } from '@halo-common/models';
import { updateAdminAuction } from '@halo-data-sources/clients';
import { ApiAuctionMapper, AuctionMapper } from '@halo-data-sources/mappers';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

export type UpdateAdminAuctionMutationModel = {
  id: number;
  details: AuctionUpdateAdminModel;
};

const updateAdminAuctionMutationFn = async (data: UpdateAdminAuctionMutationModel) => {
  const requestBody = AuctionMapper.toApiPatchOrderAuctionRequestModel(data.details);

  const response = await updateAdminAuction(data.id, requestBody);

  return ApiAuctionMapper.toAdminAuctionModel(response.result.pending_note_order);
};

// TODO: Update this when order hub is using react query
export const useAdminUpdateAuctionMutation = (): UseMutationResult<
  AuctionAdminModel,
  Error,
  UpdateAdminAuctionMutationModel
> => {
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateAdminAuctionMutationFn,
    // onSuccess: (data) => {
    //   if (data) {
    //     const adminAuctions = queryClient.getQueryData<Array<AuctionModel>>(AuctionQueryKeyFactory.admin()) ?? [];
    //     const auctionIndex = adminAuctions.findIndex((adminAuction) => adminAuction.id === data.id);
    //     const updatedData = [...adminAuctions];

    //     updatedData.splice(auctionIndex, 1, data);

    //     queryClient.setQueryData(AuctionQueryKeyFactory.admin(), updatedData);
    //   }
    // },
  });
};
