import { UserToolModel } from '@halo-common/models';
import { fetchUserTools } from '@halo-data-sources/clients';
import { ApiUserMenuItemMapper } from '@halo-data-sources/mappers';
import { UserQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export type UserToolsQueryResult = UseQueryResult<Array<UserToolModel>, Error>;

const userToolsQueryFn = async (paths: Array<string> = []) => {
  const response = await fetchUserTools();

  let menuItems = response.usermenu.usermenu;

  for (const pathName of paths) {
    const menu = menuItems.find((menuItem) => menuItem.name === pathName);
    if (!menu) return { tools: [] };
    menuItems = menu.submenu;
  }

  return menuItems.map(ApiUserMenuItemMapper.toUserTool);
};

export const useUserToolsQuery = (paths?: Array<string>): UserToolsQueryResult =>
  useQuery<unknown, Error, Array<UserToolModel>>({
    queryKey: UserQueryKeyFactory.tools(),
    queryFn: () => userToolsQueryFn(paths),
  });
