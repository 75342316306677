import { ReactElement } from 'react';

import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Box, Stack } from '@mui/material';

const logoSx = {
  mb: 4,
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: 190,
};

const logoSmallSx = {
  maxWidth: 95,
};

const subtitleSx = {
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  mb: 5,
};

export const OnboardingMiniDrawer = (): ReactElement => {
  const { data: user } = useUserInfoQuery();

  const onboardingSetting = user?.settings?.onboarding;
  const background = onboardingSetting?.drawerBackground;
  const backgroundImage = background?.path;
  const poweredByHalo = onboardingSetting?.showPoweredByHalo;
  const brandSubtitles = onboardingSetting?.subtitles;
  const isLightBackground = onboardingSetting?.isLightBackground;

  const subtitleColor = isLightBackground ? 'common.black' : 'common.white';
  const logoPath = isLightBackground ? onboardingSetting?.logoDark?.path : onboardingSetting?.logoLight?.path;

  const poweredByHaloPath = isLightBackground
    ? '/assets/images/logo_poweredbyhalo.svg'
    : '/assets/images/logo_poweredbyhalo_light.svg';

  const onboardingSx = {
    maxWidth: 400,
    minWidth: 320,
    width: '100%',
    textAlign: 'center',
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: 'common.black',
    backgroundSize: 'cover',
    backgroundPosition: 'top left',
    pb: { md: 12, xs: 0 },
    pt: { md: 8, xs: 5 },
    px: { md: 5, xs: 3 },
  };

  const logo = <Box sx={logoSx} component="img" src={logoPath} alt="Brand Logo" />;

  const poweredByHaloLogo = poweredByHalo ? (
    <Box sx={logoSmallSx} component="img" src={poweredByHaloPath} alt="Powered by Halo Logo" />
  ) : null;

  const subtitleText = brandSubtitles ? (
    <LocalizedTypography sx={subtitleSx} color={subtitleColor} variant="overline">
      {brandSubtitles}
    </LocalizedTypography>
  ) : null;

  return (
    <Stack sx={onboardingSx} direction="column" alignItems="center" justifyContent="flex-start">
      {logo}
      {subtitleText}
      {poweredByHaloLogo}
    </Stack>
  );
};
