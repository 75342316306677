import { ChangeEvent, ReactElement, useEffect } from 'react';

import { modalAtom } from '@halo-atoms/common';
import {
  currentLifecycleIdSearchAtom,
  debouncedLifecycleIdSearchAtom,
  LifecycleFilterModel,
  portfolioLifecycleManagerAtom,
} from '@halo-atoms/portfolio';
import { DateRangePicker } from '@halo-common/components';
import {
  PortfolioEventsListFilterByType,
  PortfolioEventsListSortByFilter,
} from '@halo-common/components/PortfolioEventsList';
import { PortfolioEventSortDropdownValueEnum } from '@halo-common/enums';
import { useLegacyRouter } from '@halo-common/providers';
import { translations } from '@halo-common/translations';
import { useRecordActivityMutation } from '@halo-data-sources/mutations';
import { PORTFOLIO_EVENTS_EXCEL_DL_MODAL_NAME } from '@halo-modules/app';
import { Iconography, LocalizedButton, LocalizedTextField, LocalizedTypography } from '@halodomination/halo-fe-common';
import { InputAdornment, Stack, StackProps } from '@mui/material';
import type { DateRange } from '@mui/x-date-pickers-pro';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { pickBy } from 'lodash';
import { DateTime } from 'luxon';

const clearIconSx = {
  '&:hover': {
    cursor: 'pointer',
  },
};

const searchSx = {
  flexBasis: { md: '20%', xs: 'unset' },
};

const rightFiltersContainerSx = {
  flexBasis: { md: '75%', xs: 'unset' },
};
const dropdownSx = {
  flexBasis: { md: '15%', xs: 'unset' },
  minWidth: { md: 'fit-content', xs: '100%' },
};

const filterDirection: StackProps['direction'] = {
  md: 'row',
  xs: 'column',
};

const dateRangeSx = {
  width: { md: 285, xs: '100%' },
};

export const PortfolioEventsListFilters = (): ReactElement => {
  const legacyRouter = useLegacyRouter();

  const setModalMap = useSetAtom(modalAtom);
  const { mutate: recordActivity } = useRecordActivityMutation();

  const search = useAtomValue(currentLifecycleIdSearchAtom);
  const setDebouncedSearch = useSetAtom(debouncedLifecycleIdSearchAtom);
  const [lifecycleFilters, setLifecycleFilters] = useAtom(portfolioLifecycleManagerAtom);

  const { filters } = lifecycleFilters;
  const { startDate, endDate } = filters;

  const minDate = DateTime.now().minus({ days: 180 });

  const handleSearchInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setDebouncedSearch(ev.target.value);

    const verb = `searched lifecycle events list for ${ev.target.value}`;
    recordActivity({ activity: [{ verb, model_name: 'Portfolio' }], fromIFrame: false });
  };

  const handleClearSearch = () => {
    setDebouncedSearch('');
  };

  const handleDateRangeChange = (value: DateRange<DateTime>) => {
    const newStartDate = value[0];
    const formattedStartDate = newStartDate ? newStartDate.toFormat('yyyy-LL-dd') : startDate;

    const newEndDate = value[1];
    const formattedEndDate = newEndDate ? newEndDate.toFormat('yyyy-LL-dd') : endDate;

    setLifecycleFilters({ filters: { startDate: formattedStartDate, endDate: formattedEndDate } });
  };

  const handleDownload = () => {
    setModalMap({ [PORTFOLIO_EVENTS_EXCEL_DL_MODAL_NAME]: true });
  };

  useEffect(() => {
    if (legacyRouter.isReady) {
      const startDate = legacyRouter.getQueryParams('from_date');
      const endDate = legacyRouter.getQueryParams('to_date');
      const eventTypes = legacyRouter.getQueryParams('event_types');
      const sortField = legacyRouter.getQueryParams('sort_field') as PortfolioEventSortDropdownValueEnum;

      const filters = pickBy<Partial<LifecycleFilterModel>>({ startDate, endDate, eventTypes, sortField }, (v) => !!v);

      if (Object.keys(filters).length > 0) setLifecycleFilters({ filters });
    }
  }, [legacyRouter.isReady]);

  const endAdornment = search ? (
    <InputAdornment position="end" onClick={handleClearSearch}>
      <Iconography sx={clearIconSx} iconName="times" color="text.faded" />
    </InputAdornment>
  ) : (
    <InputAdornment position="end">
      <Iconography iconName="search" color="text.faded" />
    </InputAdornment>
  );

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <LocalizedTypography variant="h6" color="primary.black">
          {translations.portfolio.lifecycle.listHeading}
        </LocalizedTypography>
        <LocalizedButton startIcon={<Iconography iconName="download" />} variant="text" onClick={handleDownload}>
          {translations.common.excelExport}
        </LocalizedButton>
      </Stack>
      <Stack direction={filterDirection} justifyContent="space-between" spacing={2}>
        <LocalizedTextField
          sx={searchSx}
          label={translations.common.search}
          size="large"
          fullWidth
          value={search}
          onChange={handleSearchInputChange}
          slotProps={{ input: { endAdornment } }}
        />
        <Stack direction={filterDirection} spacing={2} justifyContent="flex-end" sx={rightFiltersContainerSx}>
          <DateRangePicker
            sx={dateRangeSx}
            onChange={handleDateRangeChange}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            size="large"
            label={translations.portfolio.lifecycle.eventDatePickerLabel}
          />
          <PortfolioEventsListFilterByType sx={dropdownSx} />
          <PortfolioEventsListSortByFilter sx={dropdownSx} />
        </Stack>
      </Stack>
    </Stack>
  );
};
