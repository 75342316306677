import { ReactElement, ReactNode } from 'react';

import { DEFAULT_AUCTIONS_SEARCH_FIELDS } from '@halo-atoms/dashboard';
import { WidgetEnginesEnum } from '@halo-common/enums';
import type { DashboardWidgetModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useOrderBookAuctionsQuery } from '@halo-data-sources/queries';
import { DashboardOrderBookAuctionsTable, useWhiteLabelCalendarsComponents, Widget } from '@halo-modules/app';
import { Tabs } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';

const engine = WidgetEnginesEnum.ORDER_BOOK;

const title = translations.dashboard.orderBook.myOpenOrders;
const description = translations.dashboard.orderBook.displayIfYouHaveAnyOpenOrders;

export const OrderBook = (): ReactElement | null => {
  const { useCalendarsQuery, defaultSearchFields, CalendarsTable } = useWhiteLabelCalendarsComponents();

  const auctionsQuery = useOrderBookAuctionsQuery(DEFAULT_AUCTIONS_SEARCH_FIELDS, 10000);
  const calendarsQuery = useCalendarsQuery(defaultSearchFields);

  const auctionsCount = auctionsQuery.data?.pagination?.totalResults || 0;
  const calendarsCount = calendarsQuery.data?.pagination?.totalResults || 0;

  const isLoading = auctionsQuery.isPending || calendarsQuery.isPending;
  const showTabs = isLoading || (auctionsCount > 0 && calendarsCount > 0);

  let content: ReactNode = null;

  if (showTabs) {
    const tabs = [
      { id: 'auctions', label: translations.dashboard.orderBook.auctions, count: auctionsCount },
      { id: 'calendars', label: translations.dashboard.orderBook.calendars, count: calendarsCount },
    ].map((t) => ({ ...t, namespace: 'wm-dashboard-openorders' }));

    content = (
      <Tabs tabs={tabs} variant="pills" disableGutters loading={isLoading}>
        <DashboardOrderBookAuctionsTable loading={isLoading} />
        <CalendarsTable loading={isLoading} />
      </Tabs>
    );
  } else if (auctionsCount > 0) {
    content = <DashboardOrderBookAuctionsTable />;
  } else if (calendarsCount > 0) {
    content = <CalendarsTable />;
  } else {
    return null;
  }

  const total = auctionsCount + calendarsCount;

  return (
    <Widget
      id={engine}
      title={title}
      subTitle={isLoading ? <Skeleton width={40} height={20} /> : total}
      className="wm-dashboard-openorders"
      navigateButtonClassName="wm-dashboard-openorders-seeall"
      navigateButtonHref="/user/orderbook"
      navigateButtonLabel={translations.dashboard.orderBook.orderBook}
    >
      {content}
    </Widget>
  );
};

const widget: DashboardWidgetModel = {
  engine,
  title,
  description,
  component: OrderBook,
};

export default widget;
