import { useQuoteSelector } from '@halo-common/hooks';
import { NoteModel } from '@halo-common/models';
import { getNotePayoffDataModel } from '@halo-data-sources/clients';
import { NoteQueryKeyFactory } from '@halo-data-sources/queries';
import { LineChartDataPoint, LineChartLineDefinition, LineChartReferenceLine } from '@halodomination/halo-fe-common';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const NOTE_PAYOFF_LINE = 'Note Payoff';
const UNDERLYING_PERFORMANCE_LINE = 'Underlying Performance';

export type NotePayoffQueryResult = {
  lines: Array<LineChartLineDefinition>;
  data: Array<LineChartDataPoint>;
  references?: Array<LineChartReferenceLine>;
};

const getNotePayoffQueryFn = async (id?: number, protectionPercent?: number) => {
  if (id === undefined) return null;

  const response = await getNotePayoffDataModel(id);

  const data = [];

  for (let i = -100; i <= 100; i++) {
    data.push({
      x: i,
      [NOTE_PAYOFF_LINE]: parseFloat(response[i].toFixed(2)),
      [UNDERLYING_PERFORMANCE_LINE]: i,
    });
  }

  const lines = [{ label: UNDERLYING_PERFORMANCE_LINE }, { label: NOTE_PAYOFF_LINE }];

  const cap = protectionPercent ? -protectionPercent : null;
  const referenceLines: Array<LineChartReferenceLine> | undefined = cap
    ? [{ point: cap, label: 'Protection level', orientation: 'y' }]
    : undefined;

  return { lines, data, references: referenceLines };
};

export const useNotePayoffQuery = (product?: NoteModel | null): UseQueryResult<NotePayoffQueryResult | null, Error> => {
  const quote = useQuoteSelector(product?.id);

  const quoteId = quote?.id;
  const protectionPercent = product?.protectionPercent;

  return useQuery<NotePayoffQueryResult | null, Error>({
    queryKey: NoteQueryKeyFactory.payoff(quoteId),
    queryFn: () => getNotePayoffQueryFn(quoteId, protectionPercent),
  });
};
