import { useQuoteSelector } from '@halo-common/hooks';
import { getRollingReturnsSummaryData } from '@halo-data-sources/clients';
import { RollingReturnsQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

export type RollingReturnSummaryValue = {
  label: string;
  value?: number;
};

export type RollingReturnsSummaryQueryResult = {
  features: Array<RollingReturnSummaryValue>;
  noteReturn: RollingReturnSummaryValue;
  underlierReturn: RollingReturnSummaryValue;
  descriptions: Array<string>;
};

const getRollingReturnsSummaryQueryFn = async (id?: number, quoteId?: number, date?: string | null) => {
  if (!id || !date) return null;

  const response = await getRollingReturnsSummaryData({
    quote_id: quoteId,
    note_id: id,
    date,
  });

  const { feature_entries, note_return_entry, scenario_descriptions, underlier_return_entry } = response;

  return {
    features: feature_entries ?? [],
    noteReturn: note_return_entry,
    underlierReturn: underlier_return_entry,
    descriptions: scenario_descriptions ?? [],
  };
};

export const useRollingReturnsSummaryQuery = (
  id?: number,
  date?: DateTime | null,
): UseQueryResult<RollingReturnsSummaryQueryResult | null, Error> => {
  const quote = useQuoteSelector(id);

  const quoteId = quote?.id;

  const dateString = date?.toISODate();

  return useQuery<RollingReturnsSummaryQueryResult | null, Error>({
    queryKey: RollingReturnsQueryKeyFactory.summary(id, quoteId, dateString),
    queryFn: () => getRollingReturnsSummaryQueryFn(id, quoteId, dateString),

    retry: false,
  });
};
