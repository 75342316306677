import { AuctionBuyerModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { putAuctionSubmitAuctionAllocations } from '@halo-data-sources/clients';
import { ApiAuctionMapper } from '@halo-data-sources/mappers';
import { ApiBuyerAuctionModel } from '@halo-data-sources/models';
import { AuctionQueryKeyFactory } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationOptions, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

export type auctionAllocations = Array<{
  account?: number;
  quantity?: number;
}>;

export type PutBuyerAuctionAllocationsModel = {
  auctionId?: number;
  allocations: auctionAllocations;
};

export type ApiPutBuyerAuctionAllocationsResponseModel = {
  auction: ApiBuyerAuctionModel;
  message?: string;
};

const submitAuctionAllocationsMutationFn = async (
  payload: PutBuyerAuctionAllocationsModel,
): Promise<AuctionBuyerModel> => {
  const response = await putAuctionSubmitAuctionAllocations(payload);

  return ApiAuctionMapper.toBuyerAuctionModel(response.auction);
};

export const useSubmitAuctionAllocationsMutation = (
  options: UseMutationOptions<AuctionBuyerModel, Error, PutBuyerAuctionAllocationsModel>,
): UseMutationResult<AuctionBuyerModel, Error, PutBuyerAuctionAllocationsModel> => {
  const { enqueueErrorEvent, enqueueSuccessEvent } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: submitAuctionAllocationsMutationFn,
    onSuccess: (data, payload, context) => {
      const key = AuctionQueryKeyFactory.buyerAuction(payload.auctionId?.toString());
      void queryClient.invalidateQueries({ queryKey: key });

      enqueueSuccessEvent({ message: translations.orderBook.auctions.submittedAllocationsSuccessfully });
      options?.onSuccess?.(data, payload, context);
    },
    onError: (error, payload, context) => {
      const message = error.message || translations.common.error;
      enqueueErrorEvent({ message });
      options?.onError?.(error, payload, context);
    },
  });
};
