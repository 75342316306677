import { useUserInfoQuery } from '@halo-data-sources/queries';

export const useOnboardingSettingValidation = () => {
  const { data: user } = useUserInfoQuery();

  const onboardingSetting = user?.settings?.onboarding;
  const overline = onboardingSetting?.overline ?? '';
  const title = onboardingSetting?.title ?? '';
  const subtitle = onboardingSetting?.subtitle ?? '';
  const body = onboardingSetting?.body ?? '';
  const video = onboardingSetting?.video;
  const image = onboardingSetting?.image;
  const buttonText = onboardingSetting?.buttonText ?? '';
  const buttonLink = onboardingSetting?.buttonLink ?? '';

  const hasNoContent = !overline && !title && !subtitle && !body && !video && !image && !buttonText && !buttonLink;

  return !hasNoContent;
};
