import { ReactElement } from 'react';

import { useUserInfoQuery } from '@halo-data-sources/queries';
import { PageMessageAnimation } from '@halo-modules/app';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, Skeleton } from '@mui/material';

const skeletonSx = {
  transform: 'none',
};

export type PageMessageBackgroundProps = {
  contentPositionY: number;
  alternateBackground?: ReactElement;
  zIndex?: number;
  loading?: boolean;
  fullPage?: boolean;
};

export const PageMessageBackground = ({
  contentPositionY,
  alternateBackground,
  zIndex,
  loading = false,
  fullPage = false,
}: PageMessageBackgroundProps): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();

  const backgroundImage = userInfo?.whiteLabel.brand?.onboardingBackground?.path;

  const imageSx = {
    height: '100%',
    objectFit: fullPage ? 'cover' : 'unset',
    transform: 'translate(0, 0)',
    width: fullPage ? '100%' : 'unset',
  };

  const styles = (theme: HaloTheme) => ({
    backgroundColor: backgroundImage && !loading ? 'common.black' : null,
    height: !backgroundImage ? contentPositionY : '100%',
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: zIndex ?? theme.zIndex.modal,
  });

  const loadingContent = loading ? <Skeleton sx={skeletonSx} variant="rectangular" width="100%" height="100%" /> : null;
  const imageComponent = <Box sx={imageSx} component="img" src={backgroundImage ?? ''} />;
  const defaultAnimation = alternateBackground ?? <PageMessageAnimation contentPositionY={contentPositionY} />;

  const content = backgroundImage ? imageComponent : defaultAnimation;
  const displayContent = loadingContent ?? content;

  return <Box sx={styles}>{displayContent}</Box>;
};
