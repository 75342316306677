import { ReactElement } from 'react';

import { IssuerModel } from '@halo-common/models';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { HaloDataGrid, HaloDataGridProps, Stack } from '@halodomination/halo-fe-common';
import { Skeleton, Typography } from '@mui/material';
import { GridColDef, GridRowId } from '@mui/x-data-grid-pro';
import { useFormContext } from 'react-hook-form';

const containerSx = {
  backgroundColor: 'background.default',
  minWidth: 350,
  mt: 3,
};

const commonColumnProps: Partial<GridColDef> = {
  align: 'left',
  headerAlign: 'left',
  flex: 1,
};

const columns: HaloDataGridProps['columns'] = [
  { ...commonColumnProps, field: 'name', headerName: 'ISSUER NAME', flex: 1.5 },
  { ...commonColumnProps, field: 'notional', headerName: 'MINIMUM NOTIONAL', flex: 2 },
  { ...commonColumnProps, field: 'fitch', headerName: 'FITCH' },
  { ...commonColumnProps, field: 'moody', headerName: "MOODY'S" },
  { ...commonColumnProps, field: 'sap', headerName: 'S&P' },
  { ...commonColumnProps, field: 'fiveYear', headerName: '5Y CDS' },
  { ...commonColumnProps, field: 'tenYear', headerName: '10Y CDS' },
];

interface UpdateSelectionBase {
  values: () => Iterable<GridRowId>;
}

export type ExecutionAuctionReviewOffersFormFieldsProps = {
  loading: boolean;
};

export const ExecutionAuctionReviewOffersFormFields = ({
  loading,
}: ExecutionAuctionReviewOffersFormFieldsProps): ReactElement => {
  const { setValue, watch } = useFormContext();

  const { data: userInfo } = useUserInfoQuery();

  const mapIssuersToDataGridFormat = (issuer: IssuerModel) => ({
    id: issuer.id,
    name: issuer.name,
    notional: issuer.notional,
    sap: issuer.sp,
    fitch: issuer.fitch,
    moody: issuer.moody,
    fiveYearCds: issuer.historicCds?.find((cds) => cds.termInYears === 5),
    tenYearCds: issuer.historicCds?.find((cds) => cds.termInYears === 10),
  });

  const issuers = userInfo?.whiteLabel.issuers ?? [];
  const mappedIssuers = issuers.map(mapIssuersToDataGridFormat);
  const selectedOffers = watch('offers') as Array<IssuerModel>;
  const selectionModel = selectedOffers.map((issuer) => issuer.id);

  const handleUpdate = <T extends UpdateSelectionBase>(selection: T): void => {
    const values = selection.values();
    const rows = Array.from(values);

    const newOffers = issuers?.reduce((list: Array<IssuerModel>, offer: IssuerModel) => {
      const foundOffer = rows.find((rowId) => rowId === offer.id);
      return foundOffer ? [...list, offer] : list;
    }, []);

    setValue('offers', newOffers, { shouldDirty: true });
  };

  const title = loading ? (
    <Skeleton variant="rounded" width={250} height={48} />
  ) : (
    <Typography variant="h3">Issuer Quotes</Typography>
  );

  const subtitle = loading ? (
    <Skeleton variant="rounded" width="75%" height={48} />
  ) : (
    <Typography variant="body1">
      Adjust the list of issuers being shown the auction along with viewing their credit ratings and CDS spreads.
    </Typography>
  );

  return (
    <Stack sx={containerSx} direction="column" spacing={2}>
      {title}
      {subtitle}
      <HaloDataGrid
        height={450}
        checkboxSelection
        columns={columns}
        hideFooter
        loading={loading}
        onRowSelectionModelChange={handleUpdate}
        rowSelectionModel={selectionModel}
        rows={mappedIssuers}
      />
    </Stack>
  );
};
