import { MouseEvent, ReactElement } from 'react';

import { anchorElAtom } from '@halo-atoms/common';
import { orderBookAuctionCommentPopoverAtom, orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { ActionButton } from '@halo-common/components';
import { QuoteAuctionModel } from '@halo-common/models';
import { useAuctionChooseWinnerMutation } from '@halo-data-sources/mutations';
import { AuctionDetailsReviewCommentPopover, MiniCommentButton } from '@halo-modules/app';
import { Iconography, Stack, useSnackbar } from '@halodomination/halo-fe-common';
import { useAtomValue, useSetAtom } from 'jotai';

export type ChooseWinnerFooterProps = {
  quote?: QuoteAuctionModel;
  children: ReactElement;
};

export const ChooseWinnerFooter = ({ quote, children }: ChooseWinnerFooterProps): ReactElement | null => {
  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);
  const updateAnchorElMap = useSetAtom(anchorElAtom);
  const setPopoverData = useSetAtom(orderBookAuctionCommentPopoverAtom);
  const { enqueueErrorEvent } = useSnackbar();

  const { mutate: putAuctionChooseWinner, isPending } = useAuctionChooseWinnerMutation();

  const issuerId = quote?.issuer.id;
  const issuerName = quote?.issuer.name;

  const winnerIcon = <Iconography iconName="trophy" color="primary.contrastText" />;

  const hasComment = Boolean(quote?.comment?.comment);
  const commentPendingResponse = Boolean(quote?.comment?.buyerApproved === null);

  const handleChooseWinner = (event: MouseEvent<HTMLElement>) => {
    if (hasComment && commentPendingResponse) {
      enqueueErrorEvent({ message: 'Please review the comment before choosing a winner' });
      updateAnchorElMap({ [AuctionDetailsReviewCommentPopover]: event.currentTarget });
      setPopoverData({ quote });
    } else if (selectedAuctionId && issuerId && issuerName) {
      putAuctionChooseWinner({ auctionId: selectedAuctionId, issuerId, issuerName });
    }
  };

  return (
    <Stack direction="row" xs={12} sm={[7, 1, 4]} spacing={2}>
      <Stack direction="column">{children}</Stack>
      <MiniCommentButton quote={quote} />
      <ActionButton
        variant="contained"
        size="medium"
        startIcon={winnerIcon}
        fullWidth
        onClick={handleChooseWinner}
        loading={isPending}
      >
        Choose As Winner
      </ActionButton>
    </Stack>
  );
};
