import { ReactElement } from 'react';

import { AssetIdentifierEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { AllocationDetailsColumn } from '@halo-modules/app';
import { useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Divider, Stack } from '@mui/material';

export type AllocatedAccountListItemProps = {
  name?: string;
  accountId?: string;
  custodian?: string;
  amount?: number;
  isQueued?: boolean;
  assetIdType?: string;
  assetId?: string;
  showDivider?: boolean;
};

export const AllocatedAccountListItem = ({
  name,
  accountId,
  custodian,
  amount,
  assetId,
  assetIdType,
  isQueued = false,
  showDivider = false,
}: AllocatedAccountListItemProps): ReactElement => {
  const [convertedAmount] = useCurrencyConverter(amount);

  const hasAccountTextRequirements = name && accountId && custodian;
  const fullAccountText = hasAccountTextRequirements ? `${name} - ${custodian} #${accountId}` : name;

  const accountText = hasAccountTextRequirements ? fullAccountText : accountId;

  const divider = showDivider ? <Divider /> : null;
  const assetIdTypeText = assetIdType === AssetIdentifierEnum.FUNDSERVCODE ? 'FundServ' : assetIdType;

  return (
    <Stack direction="column" spacing={3}>
      {divider}
      <Stack direction="row" sx={{ width: '100%' }} spacing={5}>
        <AllocationDetailsColumn
          sx={{ flex: 1 }}
          label={assetIdTypeText}
          value={assetId}
          isQueued={isQueued}
          showTooltip={isQueued}
        />
        <AllocationDetailsColumn
          sx={{ flex: 1.5 }}
          label={translations.common.account}
          value={accountText}
          isQueued={isQueued}
        />
        <AllocationDetailsColumn
          sx={{ flex: 1 }}
          label={translations.portfolio.common.notionalAmount}
          value={convertedAmount}
          isQueued={isQueued}
        />
      </Stack>
    </Stack>
  );
};
