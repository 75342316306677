import type {
  GetNoteflixCategoriesResult,
  GetNoteflixNotesResult,
  NoteflixModel,
  NoteflixNoteModel,
} from '@halo-common/models';
import { ApiQuoteMapper, mapApiNoteModelToNoteModel } from '@halo-data-sources/mappers';
import type {
  ApiGetNoteflixCategoriesResult,
  ApiGetNoteflixNotesResult,
  ApiNoteflixModel,
  ApiNoteflixNoteModel,
} from '@halo-data-sources/models';

export interface ApiNoteflixMapper {
  toNoteflixNoteModel(member: [ApiNoteflixNoteModel, string | null]): NoteflixNoteModel;
  toNoteflixModel(noteflix: ApiNoteflixModel): NoteflixModel;
  toNoteflixCategories(categories: ApiGetNoteflixCategoriesResult, from?: number): GetNoteflixCategoriesResult;
  toNoteflixNotesModel(response: ApiGetNoteflixNotesResult): GetNoteflixNotesResult;
}

export const ApiNoteflixMapper: ApiNoteflixMapper = {
  toNoteflixNoteModel([apiNote, ribbon]) {
    return {
      ...(mapApiNoteModelToNoteModel(apiNote) as NoteflixNoteModel),
      quote: ApiQuoteMapper.toNoteflixQuoteModel(apiNote.quote),
      ribbon,
    };
  },
  toNoteflixModel(noteflix) {
    return {
      id: noteflix.id,
      name: noteflix.name,
      description: noteflix.description,
      notesFrom: noteflix.notes_from,
      isProductList: noteflix.is_product_list,
      enableCategoryQuotes: noteflix.enable_category_quotes,
      members: noteflix.members.map((member) => ApiNoteflixMapper.toNoteflixNoteModel(member)),
    };
  },
  toNoteflixCategories(categories, from = 0) {
    const nextRange = from + categories.length;

    // TODO: Update this when we get a better BE for Noteflix
    return {
      categories: categories.map((category) => ApiNoteflixMapper.toNoteflixModel(category)),
      pagination: {
        next: nextRange,
        page: nextRange,
        totalResults: nextRange,
        resultsPerPage: nextRange,
        totalPages: nextRange,
      },
    };
  },
  toNoteflixNotesModel(response) {
    const from = response.items_from;

    // TODO: Update this when we get a better BE for Noteflix
    return {
      items: response.items.map((member) => ApiNoteflixMapper.toNoteflixNoteModel(member)),
      pagination: {
        next: from,
        page: from,
        totalResults: from,
        resultsPerPage: from,
        totalPages: from,
      },
    };
  },
};
