import { OrderBookQuery } from '@halo-atoms/orderbook';
import { postCalendarOrderFiltersQuery } from '@halo-data-sources/clients';
import { JanneyOrderBookMapper } from '@halo-data-sources/mappers';
import { IssuerQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

export type JanneyOrderBookIssuersQueryResult = Array<{
  id: number;
  name: string;
}>;

const janneyOrderBookIssuersQueryFn = async (query: OrderBookQuery) => {
  const comparisons = JanneyOrderBookMapper.toApiFiltersComparisonList(query);

  const response = await postCalendarOrderFiltersQuery({
    filters: [
      {
        field: 'issuers.id',
        type: 'OBJECTS',
        object_fields: ['issuers.name'],
      },
    ],
    comparisons,
  });

  return (
    response.filter_values[0]?.objects?.map(({ object }) => ({
      name: object['issuers.name'] as string,
      id: object['issuers.id'] as number,
    })) ?? []
  );
};

export const useJanneyOrderBookIssuersQuery = (
  query: OrderBookQuery,
): UseQueryResult<JanneyOrderBookIssuersQueryResult, Error> =>
  useQuery<JanneyOrderBookIssuersQueryResult, Error>({
    queryKey: IssuerQueryKeyFactory.byExecutionOrder(query),
    queryFn: () => janneyOrderBookIssuersQueryFn(query),
    placeholderData: keepPreviousData,

    retry: false,
  });
