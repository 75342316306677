export const ALLOCATIONS_MODAL_CALENDAR_TRANSLATIONS = {
  allocationCancellationMessage: 'You can cancel these allocations at any time up until the close date.',
  allocationInstructionsMessage: `Allocations must be submitted before they can be processed. You can change or cancel these allocations at any time up until the close date.`,
  attestationCheckboxLabel: 'I have delivered the prospectus to this client',
  attestationRequiredMessage: 'You must have delivered the prospectus to the client.',
  divisibleByThousandsMessage: 'Notional amount must be in increments of $1,000.',
  notionalRequiredMessage: 'A notional amount is required.',
  statusClosed: 'Closed {date}',
  statusCloses: 'Closes {date}',
  title: `Submit Indication {identifier}`,
};
