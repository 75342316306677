import { ReactElement } from 'react';

import { productApprovalManagerAtom } from '@halo-atoms/productApproval';
import { ActionButton } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { useGenerateCalendarPDFMutation } from '@halo-data-sources/mutations';
import { useProductApprovalCalendarQuery } from '@halo-data-sources/queries';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';

export const ProductApprovalExportButton = (): ReactElement => {
  const { query } = useAtomValue(productApprovalManagerAtom);

  const { data } = useProductApprovalCalendarQuery(query);

  const totalResults = data?.pagination.totalResults ?? 0;

  const { mutate, isPending } = useGenerateCalendarPDFMutation();

  const handlePDFExport = () => {
    mutate({ totalResults, filters: query, approved: null });
  };

  return (
    <Stack direction="row" alignItems="center">
      <LocalizedTypography variant="body2" dynamicContent={{ count: totalResults }}>
        {translations.common.totalResults}
      </LocalizedTypography>
      <ActionButton onClick={handlePDFExport} loading={isPending}>
        {translations.common.exportResults}
      </ActionButton>
    </Stack>
  );
};
