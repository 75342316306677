import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type PDMKeys = {
  all: QueryKeyFactoryFn;
  note: QueryKeyFactoryFn;
  isMigrated: QueryKeyFactoryFn;
  quoteHistory: QueryKeyFactoryFn;
  couponDetails: QueryKeyFactoryFn;
  earlyRedemptionDetails: QueryKeyFactoryFn;
  events: QueryKeyFactoryFn;
  termsheet: QueryKeyFactoryFn;
  allocations: QueryKeyFactoryFn;
  allocationsFiltered: QueryKeyFactoryFn;
};

export const PDMKeyFactory: PDMKeys = {
  all: () => ['pdm'],
  note: (id) => [...PDMKeyFactory.all(), 'note', id],
  isMigrated: (positionId, noteId) => [...PDMKeyFactory.note(noteId), 'is', 'migrated', 'order', positionId],
  quoteHistory: (id) => [...PDMKeyFactory.note(id), 'quote', 'history'],
  couponDetails: (id, filters) => [...PDMKeyFactory.note(id), 'coupon', hash(filters)],
  earlyRedemptionDetails: (id, filters) => [...PDMKeyFactory.note(id), 'early', 'redemption', hash(filters)],
  events: (id, filters) => [...PDMKeyFactory.all(), 'events', id, hash(filters)],
  termsheet: (termsheetId) => [...PDMKeyFactory.all(), 'termsheet', termsheetId],
  allocations: ({ termsheetId }) => [...PDMKeyFactory.termsheet(termsheetId), 'allocations'],
  allocationsFiltered: ({ termsheetId, ...filters }) => [
    ...PDMKeyFactory.termsheet(termsheetId),
    'allocations',
    hash(filters),
  ],
};
