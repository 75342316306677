import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { PortfolioPositionsDownloadPayload, usePortfolioPositionsDownloadMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { DateTime } from 'luxon';

export type DownloadSpreadsheetButtonProps = {
  payload: PortfolioPositionsDownloadPayload;
  disabled?: boolean;
};

export const DownloadSpreadsheetButton = ({
  payload,
  disabled = true,
}: DownloadSpreadsheetButtonProps): ReactElement => {
  const { data: user } = useUserInfoQuery();
  const { mutate: downloadPositions } = usePortfolioPositionsDownloadMutation();

  const handleDownload = () => {
    const account = payload.account?.account;
    const accountId = account?.id;
    const household = payload.account?.household;
    const householdId = household?.id;
    const action = payload.account?.action;

    const isFilteredByHousehold = typeof householdId === 'number';
    const isFilteredByAccount = typeof accountId === 'number';

    let accountOrAdviseeName = action?.replace(' ', '').toUpperCase() ?? 'ALLACCOUNTS';
    if (isFilteredByHousehold) accountOrAdviseeName = household?.name ?? '';
    else if (isFilteredByAccount) accountOrAdviseeName = account?.name ?? '';

    const todaysDate = DateTime.now().toISODate();

    const fileName = `${user?.details.name?.toUpperCase()}_${accountOrAdviseeName}_${todaysDate}.xlsx`;
    const cleanFileName = fileName.replace(/[|&;$%@"<>()+,/:?*]/g, '-');

    downloadPositions({ ...payload, fileName: cleanFileName });
  };

  return (
    <LocalizedButton
      startIcon={<Iconography iconName="download" />}
      variant="text"
      onClick={handleDownload}
      disabled={disabled}
    >
      {translations.common.excelExport}
    </LocalizedButton>
  );
};
