import { ReactElement } from 'react';

import {
  CalendarProductCardActions,
  CalendarProductCardActionsProps,
  CalendarProductCardDetailRow,
  CalendarProductCardIssuer,
  ProductFeatureList,
  ProductNoteTypeChip,
  UnderlyingGlyphs,
} from '@halo-common/components';
import { useDesktopView, useProductProgress } from '@halo-common/hooks';
import { CalendarModel } from '@halo-common/models';
import { ApiCalendarPriceDisplayEnum } from '@halo-data-sources/enums';
import {
  LocalizedTypography,
  ProgressBar,
  ProgressBarEnum,
  Stack,
  useCombinedStyling,
} from '@halodomination/halo-fe-common';
import { Paper, PaperProps, Typography } from '@mui/material';

const calendarCardSx = {
  padding: 2,
  marginBottom: 2,
  border: '1px solid',
  borderColor: 'grey.300',
};

const calendarNameSx = {
  wordWrap: 'break-word',
};

const calendarCardDetailSx = {
  borderRight: '1px solid',
  borderColor: 'grey.300',
  pr: 2,
};

const progressBarSx = {
  width: 250,
};

const underlyingContainerSx = {
  maxWidth: '280px !important',
};

export type CalendarProductCardProps = PaperProps & {
  calendar: CalendarModel;
  actions?: CalendarProductCardActionsProps['actions'];
  loading?: boolean;
  termsheetId?: number;
};

export const CalendarProductCard = ({
  calendar,
  actions,
  loading = false,
  termsheetId,
  sx,
  elevation = 0,
  ...props
}: CalendarProductCardProps): ReactElement => {
  const {
    note: { features, type, tradables, currencyCode, productType, tradableWeights },
    note,
    issuer,
    category,
    displayName,
    price: calendarPrice,
    priceDisplay,
  } = calendar;

  const isDesktop = useDesktopView();

  const { percentFilled, variant, label, dynamicContent } = useProductProgress({ calendar });

  const isCommission = priceDisplay === ApiCalendarPriceDisplayEnum.COMMISSION;
  const notePrice = isCommission && note.price ? 100 - note.price : note.price;
  const price = calendarPrice ?? notePrice;

  const isUnderlyingsScrollable = tradables.length >= 4;
  const isCalendarClosed = variant === ProgressBarEnum.DISABLED;

  const calendarDetailsLayout = isDesktop ? 'row' : 'column';
  const closedCalendarSx = isCalendarClosed ? { backgroundColor: 'grey.100' } : null;
  const calendarItemStyling = useCombinedStyling(calendarCardSx, closedCalendarSx, sx);

  return (
    <Paper sx={calendarItemStyling} elevation={elevation} {...props}>
      <Stack direction="row" sm={[8, 4]} lg={[9, 3]}>
        <Stack sx={calendarCardDetailSx} direction="column" spacing={1} justify="space-between">
          <Stack direction="row" spacing={0.5}>
            <ProductFeatureList features={features ?? []} type={type} subtype={productType}>
              <ProductNoteTypeChip type={type} subtype={productType} />
            </ProductFeatureList>
            <Typography color="textSecondary" variant="caption">
              {currencyCode}
            </Typography>
          </Stack>
          <Stack direction={calendarDetailsLayout} spacing={1} sm={[4, 8]} elementStyling={[underlyingContainerSx]}>
            <UnderlyingGlyphs underlyings={tradables} scrollable={isUnderlyingsScrollable} weights={tradableWeights} />
            <Stack direction="column" justify="center">
              <LocalizedTypography sx={calendarNameSx} marginBottom={2} variant="subtitle1">
                {displayName}
              </LocalizedTypography>
              <CalendarProductCardDetailRow
                calendar={calendar}
                category={category}
                price={price}
                priceDisplay={priceDisplay}
              />
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" justify="space-between" wrap="wrap">
            <CalendarProductCardIssuer category={category} issuer={issuer} loading={loading} />
            <ProgressBar
              variant={variant}
              label={label}
              percentFilled={percentFilled}
              sx={progressBarSx}
              dynamicContent={dynamicContent}
            />
          </Stack>
        </Stack>
        <CalendarProductCardActions calendar={calendar} termsheetId={termsheetId} actions={actions} />
      </Stack>
    </Paper>
  );
};
