import { ReactElement } from 'react';

import { HALO_ORGANIZATION_DETAILS } from '@halo-common/constants';
import { translations } from '@halo-common/translations';
import { useCreateUserAlertMutation, useUpdateUserAlertMutation } from '@halo-data-sources/mutations';
import { useUserAlertsQuery, useUserInfoQuery, useUserWebHooksQuery } from '@halo-data-sources/queries';
import { AccountCard, AlertToggle, UserAlertOnChangeFunction } from '@halo-modules/app';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';

const contentContainerSx = {
  padding: 3,
};

const headerListSx = {
  pl: 1,
};

const NOTE_ORDER_AGGREGATE_ID = 'AggregatedNoteOrderAlerts';

export const UserProfileAlertsTab = (): ReactElement => {
  const { data: user } = useUserInfoQuery();
  const { data: webHooks } = useUserWebHooksQuery();
  const { data: userAlerts } = useUserAlertsQuery();

  const { mutate: createAlert } = useCreateUserAlertMutation();
  const { mutate: updateAlert } = useUpdateUserAlertMutation();

  const alerts = userAlerts ?? [];

  const disableEmailHook = webHooks?.['react:account:disable-profile-email'];
  const disableEmail = Boolean(webHooks && disableEmailHook?.reactFeatureEnabled);

  const userFacingName = user?.whiteLabel?.userFacingName ?? HALO_ORGANIZATION_DETAILS.name;
  const localization = { subtitle: { dynamicContent: { user: userFacingName } } };

  const handleChange: UserAlertOnChangeFunction = (data) => {
    if (data.id) updateAlert({ ...data, disableProfileEmail: disableEmail });
    else createAlert(data);
  };

  const alertList = alerts
    .filter((alert) => alert.alertType.ergonomicId !== NOTE_ORDER_AGGREGATE_ID)
    .map((alert) => (
      <AlertToggle key={alert.alertType.id} alert={alert} onChange={handleChange} disableEmail={disableEmail} />
    ));

  return (
    <AccountCard
      title={translations.profile.alerts.accountCardTitle}
      subtitle={translations.profile.alerts.accountCardUserNameSubtitle}
      Localization={localization}
    >
      <Stack direction="column" spacing={4} sx={contentContainerSx}>
        <Stack direction="row" spacing={9}>
          <LocalizedTypography sx={headerListSx} variant="subtitle1">
            {translations.common.email}
          </LocalizedTypography>
          <LocalizedTypography sx={headerListSx} variant="subtitle1">
            {translations.profile.alerts.notification}
          </LocalizedTypography>
        </Stack>
        <Stack direction="column" spacing={7}>
          {alertList}
        </Stack>
      </Stack>
    </AccountCard>
  );
};
