import { MouseEvent, ReactElement, useRef, useState } from 'react';

import { ModuleModel, ModuleStepModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { EducationModule, EducationProgressBar } from '@halo-modules/app';
import {
  Iconography,
  LocalizedButton,
  LocalizedTypography,
  LocalizedTypographyProps,
  Stack,
} from '@halodomination/halo-fe-common';
import { Menu, menuClasses, Paper, paperClasses, PopoverOrigin } from '@mui/material';
import { useT } from '@transifex/react';

const buttonContainerSx = {
  width: '100%',
};

const buttonSx = {
  color: 'text.secondary',
  py: 1,
};

const menuSx = {
  maxHeight: 'calc(100vh - 96px)',
  [`& .${paperClasses.root}`]: {
    borderRadius: 2,
    transform: 'translateY(-80px) !important',
    width: 726,
  },
  [`& .${menuClasses.list}`]: {
    padding: 1,
  },
};

const moduleGroupContainerSx = {
  padding: 0,
};

const SelectorHolderSx = {
  borderColor: 'primary.background',
  borderRadius: 1,
  borderStyle: 'solid',
  borderWidth: 1,
  padding: 1,
  width: 718,
};

const subtitleSx = {
  mt: -1,
};

export type EducationStepperSelectorLocationType = {
  subtitle?: LocalizedTypographyProps;
  title?: LocalizedTypographyProps;
};

export type EducationStepperSelectorProps = {
  title?: string;
  subtitle?: string;
  progress: number;
  modules: Array<ModuleModel>;
  Localization?: EducationStepperSelectorLocationType;
  onSelection: (module: ModuleModel, step: ModuleStepModel) => void;
};

export const EducationStepperSelector = ({
  title,
  subtitle,
  modules = [],
  progress,
  Localization,
  onSelection,
}: EducationStepperSelectorProps): ReactElement => {
  const menuEl = useRef(null);
  const translate = useT();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const hasSubtitle = Boolean(subtitle && translate(subtitle, Localization?.subtitle?.dynamicContent) !== 'undefined');

  const menuPosition: PopoverOrigin = { vertical: 'top', horizontal: 'center' };

  const open = Boolean(anchorEl);
  const menuControls = open ? 'demo-positioned-menu' : undefined;
  const menuExpanded = open ? 'true' : undefined;

  const handleClick = (event: MouseEvent<HTMLElement>) => void setAnchorEl(event.currentTarget);
  const handleClose = () => void setAnchorEl(null);

  const subtitleText = hasSubtitle ? (
    <LocalizedTypography
      {...Localization?.subtitle}
      sx={subtitleSx}
      color="primary"
      variant="body1"
      fontWeight="bold"
      data-testid="education-caption-element"
    >
      {subtitle}
    </LocalizedTypography>
  ) : null;

  return (
    <Paper sx={SelectorHolderSx} elevation={0} ref={menuEl}>
      <Stack direction="row" alignItems="center" justify="space-between" sx={buttonContainerSx}>
        <Stack direction="row" spacing={2} alignItems="center">
          <EducationProgressBar progress={progress} hideLabel />
          <Stack direction="column" justify="space-between" spacing={1}>
            <LocalizedTypography {...Localization?.title} color="textSecondary" variant="overline">
              {title}
            </LocalizedTypography>
            {subtitleText}
          </Stack>
        </Stack>
        <LocalizedButton
          id="demo-positioned-button"
          aria-controls={menuControls}
          aria-haspopup="true"
          aria-expanded={menuExpanded}
          onClick={handleClick}
          sx={buttonSx}
          endIcon={<Iconography color="text.secondary" prefix="fas" iconName="chevron-down" />}
        >
          {translations.education.educationQuizManager.seeAll}
        </LocalizedButton>
      </Stack>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorOrigin={menuPosition}
        transformOrigin={menuPosition}
        anchorEl={menuEl.current}
        open={open}
        onClose={handleClose}
        sx={menuSx}
      >
        <Stack direction="column" spacing={2} justify="center" sx={moduleGroupContainerSx} xs={12}>
          {modules.map((module) => {
            const { id, title: moduleTitle, steps } = module;

            const handleSelection = (step: ModuleStepModel) => {
              onSelection(module, step);
              handleClose();
            };

            return (
              <EducationModule key={id} title={moduleTitle} steps={steps} onSelection={handleSelection} showCurrent />
            );
          })}
        </Stack>
      </Menu>
    </Paper>
  );
};
