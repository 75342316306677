import { ReactElement } from 'react';

import { AuctionAdminModel } from '@halo-common/models';
import { IconographyProps } from '@halodomination/halo-fe-common';
import { Stack, Skeleton, Typography } from '@mui/material';

import { AuctionTableAction, AuctionTableActionProps } from '../AuctionTableAction';

const whoContainerSx = {
  width: '100%',
};

const whoTitleSx = {
  fontWeight: 'fontWeightBold',
};

const whoValueSx = {
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
};

export type AuctionTableWhoCellProps = {
  row: AuctionAdminModel;
  buyerName: string;
  salesRepName?: string | null;
  underliers?: string;
  orgName: string;
  loading?: boolean;
  cta: {
    icon?: IconographyProps['iconName'];
    label: string;
    disabled?: boolean;
    color?: AuctionTableActionProps['color'];
  };
};

export const AuctionTableWhoCell = ({
  row,
  buyerName,
  salesRepName,
  underliers,
  orgName,
  loading = false,
  cta,
}: AuctionTableWhoCellProps): ReactElement => {
  if (loading) {
    return (
      <Stack sx={whoContainerSx} direction="column">
        <Skeleton width="100%" height={20} />
        <Skeleton width="100%" height={20} />
        <Skeleton width="100%" height={20} />
        <Skeleton width="100%" height={32} />
      </Stack>
    );
  }

  const salesRepNameContent = salesRepName ? (
    <Stack direction="row">
      <Typography sx={whoTitleSx} variant="body2">
        Sales:&nbsp;
      </Typography>
      <Typography sx={whoValueSx} variant="body2">
        {salesRepName}
      </Typography>
    </Stack>
  ) : null;

  const underlyersContent = underliers ? (
    <Stack direction="row">
      <Typography sx={whoTitleSx} variant="body2">
        Underliers:&nbsp;
      </Typography>
      <Typography sx={whoValueSx} variant="body2">
        {underliers}
      </Typography>
    </Stack>
  ) : null;

  return (
    <Stack sx={whoContainerSx} direction="column">
      <Typography sx={whoTitleSx} variant="body2">
        {orgName}
      </Typography>
      <Stack direction="row">
        <Typography sx={whoTitleSx} variant="body2">
          Buyer:&nbsp;
        </Typography>
        <Typography sx={whoValueSx} variant="body2">
          {buyerName}
        </Typography>
      </Stack>
      {salesRepNameContent}
      {underlyersContent}
      <AuctionTableAction {...cta} data={row} />
    </Stack>
  );
};
