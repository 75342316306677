import { fetchExecutionTermsheetDocument } from '@halo-data-sources/clients';
import { ApiExecutionTermsheetDownloadRequestModel } from '@halo-data-sources/models';
import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';

const getExecutionTermsheetDownloadMutationFn = async ({
  type,
  auctionId,
}: ApiExecutionTermsheetDownloadRequestModel) => {
  const termsheetLink = await fetchExecutionTermsheetDocument({ type, auctionId });
  if (termsheetLink) window.open(termsheetLink, '_blank')?.focus();
};

export const useExecutionTermsheetDownloadMutation = (
  options?: Partial<UseMutationOptions<void, Error, ApiExecutionTermsheetDownloadRequestModel>>,
): UseMutationResult<void, Error, ApiExecutionTermsheetDownloadRequestModel> => {
  return useMutation<void, Error, ApiExecutionTermsheetDownloadRequestModel>({
    mutationFn: getExecutionTermsheetDownloadMutationFn,
    ...options,
  });
};
