import { ReactElement } from 'react';

import { complianceApprovalModalAtom } from '@halo-atoms/complianceApproval';
import { AllocationModel, CalendarModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { LocalizedButton } from '@halodomination/halo-fe-common';
import { useSetAtom } from 'jotai';

export type ViewComplianceApprovalModalButtonProps = {
  calendar?: CalendarModel;
  allocation: AllocationModel;
};

export const ViewComplianceApprovalModalButton = ({
  calendar,
  allocation,
}: ViewComplianceApprovalModalButtonProps): ReactElement => {
  const setComplianceApprovalModal = useSetAtom(complianceApprovalModalAtom);

  const onClick = () => {
    setComplianceApprovalModal({ open: true, allocation, calendar });
  };

  return (
    <LocalizedButton color="primary" type="button" variant="outlined" onClick={onClick} fullWidth size="extraSmall">
      {translations.common.view}
    </LocalizedButton>
  );
};
