export const PRODUCT_DETAILS_MODAL_PRE_TRADE = {
  analyticsTabHistory: 'History',
  analyticsTabLookback: 'Lookback',
  analyticsTabPayoffChart: 'Payoff Chart',
  analyticsTabRollingReturns: 'Rolling Returns',
  analyticsTabUnderlyingReturns: 'Underlying Returns',
  buttonStartAuction: 'Start Auction',
  clickChartToSeeMoreReturnDetails: 'Click chart to see more return details.',
  dateLineReturnPeriod: '{dateLine} Return Period',
  disclaimer: 'Disclaimer',
  documentNameIndicativeTermsheet: 'Indicative Termsheet',
  documentNameNoteProposal: 'Note Proposal',
  earlyRedemptionTableLevelFinalObservationDate: 'Final Observation Date',
  earlyRedemptionTableLevelNotCallable: 'Not callable',
  errorMessageNoQuoteHistory: 'No quote history available for this note.',
  generatingNoteSummary: 'Generating Note Summary...',
  getPrice: 'Get Price',
  indicativeTermsheetDayFilter: '{count, plural, one {# Day} other {# Days}}',
  indicativeTermsheetFormNotionalLabel: 'Notional Investment:',
  indicativeTermsheetFormSettlementLabel: 'Settlement Date:',
  indicativeTermsheetFormSubTitle: `Enter your desired settlement days from today, and the estimated total notional you would invest.`,
  indicativeTermsheetFormTitle: 'Download Indicative Termsheet',
  indicativeTermsheetNotionalError: 'Notional must be greater than 0.',
  issuerCallVisibilityMessage: `We are unable to show analytics for Issuer Callable Notes, as callability is to the full discretion of the Issuer.`,
  noteReturnAtMaturity: 'Note Return At Maturity',
  noteReturnSummary: 'Note Return Summary',
  payoffChartX: 'Underlying Return (%)',
  payoffChartY: 'Note Performance (%)',
  popoverShareTitle: 'Share Note URL',
  quoteHistoryChartY: 'Quote Value',
  quotesTableErrorMessageNoQuotes: 'No Quotes Available',
  quotesTableNoRecentQuoteErrorMessage: `There are no recent quotes for this note. You can view all historical quotes on the Quote History chart above.`,
  quotesTableReceivedDayAgo: '{diff} day ago',
  quotesTableReceivedDayFrom: '{diff} day from now',
  quotesTableReceivedDaysAgo: '{diff} days ago',
  quotesTableReceivedDaysFrom: '{diff} days from now',
  quotesTableReceivedHourAgo: '{diff} hour ago',
  quotesTableReceivedHourFrom: '{diff} hour from now',
  quotesTableReceivedHoursAgo: '{diff} hours ago',
  quotesTableReceivedHoursFrom: '{diff} hours from now',
  quotesTableReceivedLessThanMinuteAgo: 'Less than 1 minute ago',
  quotesTableReceivedLessThanMinuteFrom: 'Less than 1 minute from now',
  quotesTableReceivedMinuteAgo: '{diff} minute ago',
  quotesTableReceivedMinuteFrom: '{diff} minute from now',
  quotesTableReceivedMinutesAgo: '{diff} minutes ago',
  quotesTableReceivedMinutesFrom: '{diff} minutes from now',
  quotesTableTitle: 'Last Issuer Quotes',
  quotesTabSubtitle: `See the historical trend of quotes for this Note.`,
  quotesTabTitle: `Quote History`,
  refreshChart: 'Refresh Chart',
  rollingReturnsGeneralSupportMessage: `Support for Rolling Returns on General Notes is not yet available.`,
  rollingReturnsRecallSupportMessage: `Support for Rolling Returns on Notes with Recall coupons is currently still in development and not yet available.`,
  rollingReturnsTableHeaderPercentBreached: 'Percent Breached',
  rollingReturnsTableHeaderProbabilityBreach: 'Probability Of Breach',
  rollingReturnsTableLabel: 'Return:',
  rollingReturnsTableTitle: 'Underlying Stats',
  rollingReturnsTabTitle: 'Rolling Returns',
  scheduleCellValue: '{months, plural, one {# month} other {# months}}',
  seeTheMath: 'See the Math',
  thisNoteHasAnUpdatedPrice: 'This Note has an updated Price, to get the latest chart hit the refresh button below.',
  thisNoteHasNoPrice: 'This Note has no Price. To see enhanced Analytics for this Note, it must have a Price.',
  zoom: 'Zoom:',
};
