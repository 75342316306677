import { ReactElement, useState } from 'react';

import { DocumentDownloadTable } from '@halo-common/components';
import { usePrimaryAssetIdentifier } from '@halo-common/hooks';
import { CalendarModel, DocumentBucketModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { downloadFile } from '@halo-common/utils';
import { ITranslateParams } from '@transifex/native';

type DocumentDownloadMap = {
  [key: string]: boolean;
};

type CalendarDocumentModel = DocumentBucketModel & {
  displayName?: string;
  dynamicContent?: ITranslateParams;
};

export type ExecutionCalendarDetailModalDocumentTableProps = {
  product?: CalendarModel | null;
  loading?: boolean;
};

export const ExecutionCalendarDetailModalDocumentTable = ({
  product,
  loading = true,
}: ExecutionCalendarDetailModalDocumentTableProps): ReactElement => {
  const [documentDownloads, setDocumentDownloads] = useState<DocumentDownloadMap>({});

  const { identifier } = usePrimaryAssetIdentifier(product);

  const productDocuments: Array<CalendarDocumentModel> =
    product?.documents?.map(({ name, document }) => ({
      ...document,
      displayName: name,
    })) ?? [];

  const dynamicContent = { identifier };
  if (product?.preliminaryTerms) {
    productDocuments.unshift({
      displayName: translations.pdm.calendars.prelimTableCell,
      dynamicContent,
      ...product.preliminaryTerms,
    });
  }

  if (product?.finalTerms) {
    productDocuments.push({
      displayName: translations.pdm.calendars.finalTableCell,
      dynamicContent,
      ...product.finalTerms,
    });
  }

  if (product?.additionalInfo) {
    productDocuments.push({
      displayName: translations.pdm.calendars.additionalTableCell,
      dynamicContent,
      ...product.additionalInfo,
    });
  }

  const documents = productDocuments.map((document) => ({
    id: document.id,
    name: document.displayName ?? document.filename,
    downloading: documentDownloads[document.filename],
    dynamicContent: document.dynamicContent,
    onDownload: () => {
      setDocumentDownloads((prev) => ({ ...prev, [document.filename]: true }));
      downloadFile({
        fileName: document.filename,
        downloadFilePath: document.path,
        onFinish: (finished: boolean) => setDocumentDownloads((prev) => ({ ...prev, [document.filename]: !finished })),
      });
    },
  }));

  return <DocumentDownloadTable loading={loading} documents={documents} />;
};
