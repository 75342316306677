import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { Iconography, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack, Typography } from '@mui/material';

import { WatchlistTypeaheadOptionHighlight } from './WatchlistTypeaheadOptionHighlight';

const itemSx = {
  px: 2,
  py: 1,
};

const captionSx = {
  lineHeight: '0.75rem',
};

const highlightSx = {
  color: 'info.dark',
  wordBreak: 'break-all',
  maxWidth: 500,
  width: 'fit-content',
};

export type WatchlistTypeaheadOptionProps = {
  name: string;
  highlight: string;
  pending?: boolean;
  count?: number;
};

export const WatchlistTypeaheadOption = ({
  name,
  highlight = '',
  pending = false,
  count,
}: WatchlistTypeaheadOptionProps): ReactElement => {
  const iconName = pending ? 'plus' : 'eye';

  const headerContent = pending ? (
    <Stack direction="row" alignItems="center" spacing={1}>
      <LocalizedTypography variant="subtitle2">{`${translations.components.watchlistNewWatchlistOption}:`}</LocalizedTypography>
      <Typography sx={highlightSx} variant="subtitle2">
        {highlight}
      </Typography>
    </Stack>
  ) : (
    <WatchlistTypeaheadOptionHighlight name={name} highlight={highlight} />
  );

  const countLabel = count ? (
    <LocalizedTypography sx={captionSx} variant="caption" dynamicContent={{ count }}>
      {translations.watchlist.common.productsCount}
    </LocalizedTypography>
  ) : null;

  return (
    <Stack direction="row" alignItems="center" sx={itemSx} spacing={1}>
      <Iconography iconName={iconName} color="text.secondary" />
      <Stack direction="column">
        {headerContent}
        {countLabel}
      </Stack>
    </Stack>
  );
};
