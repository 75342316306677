export enum UnderlyingAssetTypeEnum {
  Basket = 'basket',
  Commodity = 'commodity',
  Currency = 'currency',
  Debt = 'debt',
  Equity = 'equity',
  Fund = 'fund',
  Future = 'future',
  Index = 'index',
  Other = 'other',
  None = '',
  Stock = 'stock',
  Swap = 'swap',
}

export enum UnderlyingFormatTypeEnum {
  note = 'note',
  annuity = 'annuity',
}

export enum UnderlyingBasketEnum {
  WORST_OF = 'WORST_OF',
  SINGLE = 'SINGLE',
  WEIGHTED = 'WEIGHTED',
}

export enum UnderlyingBasketDisplayEnum {
  WORST_OF = 'Worst of',
  SINGLE = 'Single',
  WEIGHTED = 'Weighted',
}
