import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { LocalizedAlert, LocalizedAlertTitle } from '@halodomination/halo-fe-common';
import { useT } from '@transifex/react';

export type QuizManagerSuccessProps = { correctAnswers?: number; questionCount?: number };

export const QuizManagerSuccess = ({
  correctAnswers = 0,
  questionCount = 0,
}: QuizManagerSuccessProps): ReactElement => {
  const translator = useT();

  const dynamicContent = {
    correctAnswers,
    questionCount,
  };

  const successMessage = translator(translations.education.educationQuizManager.successMessage, dynamicContent);

  return (
    <LocalizedAlert severity="success" variant="filled" dynamicContent={dynamicContent}>
      <LocalizedAlertTitle>{translations.education.educationQuizManager.quizPassed}</LocalizedAlertTitle>
      {successMessage}
    </LocalizedAlert>
  );
};
