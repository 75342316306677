import { PortfolioOrderModel } from '@halo-common/models';
import {
  ApiAccountMapper,
  ApiTermsheetMapper,
  mapApiNoteModelToNoteModel,
  PaginationMapper,
} from '@halo-data-sources/mappers';
import { ApiPortfolioAccountsBreakdownResponseModel, ApiPortfolioOrder } from '@halo-data-sources/models';
import { PortfolioAccountsBreakdownResult } from '@halo-data-sources/queries';

export const mapApiPortfolioOrderModelToPortfolioOrderModel = (
  order: ApiPortfolioOrder | null,
): PortfolioOrderModel | null => {
  return order
    ? {
        note: mapApiNoteModelToNoteModel(order.quotednote),
        termsheet: ApiTermsheetMapper.toTermsheetModel(order.term_sheet),
      }
    : null;
};

export const mapApiPortfolioAccountsBreakdownResponseToPortfolioAccountsBreakdown = (
  accountsBreakdown: ApiPortfolioAccountsBreakdownResponseModel,
): PortfolioAccountsBreakdownResult => {
  const { data } = accountsBreakdown;

  const breakdown = data.map((breakdown) => {
    const account = ApiAccountMapper.toAccountModel(breakdown.account);
    return {
      id: breakdown.note_position_id,
      account,
      notional: breakdown.notional,
    };
  });

  const pagination = PaginationMapper.toInfinitePaginationModel(accountsBreakdown.pagination);

  return { data: breakdown, pagination };
};
