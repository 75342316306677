import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { object as yupObject, number as yupNumber } from 'yup';

export const generateReportFormSchema = yupObject().shape({
  accountOption: yupObject().nullable().required(translations.portfolio.generateReportModal.accountOptionRequired),
  currencyId: yupNumber().required(translations.portfolio.generateReportModal.currencyIdRequired),
});

export type GenerateReportFormFields = {
  accountOption: AccountTypeAheadOption | null;
  currencyId?: number;
};
