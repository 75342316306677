export * from './educationCourses';
export * from './featuredNotes';
export * from './noteflix';
export * from './orderBook';
export * from './portfolioLifcycle';
export * from './portfolioPositions';
export * from './productShelf';
export * from './watchlist';

export const DASHBOARD_COMMON_TRANSLATIONS = {
  customizeHome: 'Customize Home',
  dragAndDropToReoder: 'Drag and drop widgets to re-order, or use the toggles to hide them.',
  thereAreNoWwidgets: 'There are no widgets with any content to show.',
  weRecommendTurningOnMoreWidgets: 'We recommend turning on more widgets using the button below.',
  itsEmptyInHere: 'It’s empty in here!',
  heresTodaysSummary: 'Here’s today’s summary',
  welcome: 'Welcome {firstName},',
};
