import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { DateTime } from 'luxon';

export const useCalendarIndicationButtonText = (allocationCount?: number, expirationDate?: string): string => {
  const configureDateTime = useWhiteLabelDateConfiguration();

  const todaysDateTime = configureDateTime(DateTime.utc().toISO()) as DateTime;
  const expirationDateTime = configureDateTime(expirationDate) ?? todaysDateTime;

  const hasAllocations = allocationCount && allocationCount > 0;
  const isExpired = expirationDateTime < todaysDateTime;
  const isClosed = isExpired && !hasAllocations;

  if (isClosed) return translations.calendars.common.indicationButtonClosedOffer;
  else if (isExpired) return translations.calendars.common.indicationButtonViewOrder;
  else if (hasAllocations) return translations.calendars.common.indicationButtonChangeIndication;
  else return translations.calendars.common.indicationButtonSubmitIndication;
};
