import { ReactElement } from 'react';

import { orderBookExecutionDetailModalAtom } from '@halo-atoms/orderbook';
import { ExecutionCalendarDetailModal } from '@halo-common/modals';
import { useCalendarQuery } from '@halo-data-sources/queries';
import { useAtom } from 'jotai';

export const OrderBookCalendarDetailModal = (): ReactElement => {
  const [modal, setModal] = useAtom(orderBookExecutionDetailModalAtom);

  const { data: calendar } = useCalendarQuery(modal?.calendarId);

  const handleClosePDM = () => {
    setModal({ open: false, calendarId: null });
  };

  return <ExecutionCalendarDetailModal open={modal.open} product={calendar} onClose={handleClosePDM} />;
};
