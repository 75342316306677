export const ADD_POSITION_TO_ACCOUNTS_TRANSLATIONS = {
  addAccountTitle: 'Add Account',
  multiAllocationError: 'Please do not allocate to an account more than once.',
  overline: 'Link your portfolio holdings',
  stepOneSubtitle: 'You will specify the notional amount per account in the next step.',
  stepOneTitle: `Enter one {assetIds} to allocate to multiple accounts.`,
  stepThreeSubtitle: `It may take up to 48 hours for some assets to appear as they get added to Halo's database.`,
  stepThreeTitle: 'These assets will appear in your portfolio shortly!',
  stepTwoTitle: `Allocate {assetId} to multiple accounts.`,
  assetSearchNoAssetError: `Please enter a valid ID separated`,
  assetSearch48HourMessage: `The following ID will be added to our database in the next 48 hours:`,
  assetSearchAssetNotFoundError: `The following ID was not found in our database, please contact your relationship manager to have them added:`,
  assetSearchUnauthorizedError: `You cannot add positions with the following ID:`,
};
