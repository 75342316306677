import { ReactElement, useEffect } from 'react';

import { orderBookCancelModalAtom, orderBookQueryAtom } from '@halo-atoms/orderbook';
import { PershingStatusEnum } from '@halo-common/enums';
import { GridPaginationModel, OrderBookCalendarDataModel } from '@halo-common/models';
import { OrderBookClientQueryKeyFactory, useOrderBookPershingQuery } from '@halo-data-sources/queries';
import { useOrderBookPershingTableColumns } from '@halo-modules/app';
import { PershingSelectors, PershingStatusEnum as ReduxPershingStatusEnum } from '@halo-stores/Pershing';
import { HaloDataGrid } from '@halodomination/halo-fe-common';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';
import { useSelector } from 'react-redux';

export const OrderBookPershingTable = (): ReactElement => {
  const queryClient = useQueryClient();

  const orderbook = useSelector(PershingSelectors.selectOrderBook);

  const [searchFields, setSearchFields] = useAtom(orderBookQueryAtom);
  const orderBookCancelModal = useAtomValue(orderBookCancelModalAtom);

  const { isFetching, data } = useOrderBookPershingQuery(searchFields);

  const calendarAllocations = data?.details || [];
  const page = data?.pagination?.page ?? searchFields.page;
  const pageSize = data?.pagination?.resultsPerPage ?? searchFields.pageSize;
  const totalResults = data?.pagination?.totalResults ?? 0;
  const paginationModel = { page: page - 1, pageSize };

  const fixCancellationSuccess = orderbook.status === ReduxPershingStatusEnum.successCancelOrderWebsocketEvent;

  const handlePaginationChange = (model: GridPaginationModel) => {
    if (!isFetching) {
      setSearchFields({
        page: model.page + 1,
        pageSize: model.pageSize,
      });
    }
  };

  const handleSortChange = (model: GridSortModel) => {
    setSearchFields({
      sortBy: model,
    });
  };

  const columns = useOrderBookPershingTableColumns();

  useEffect(() => {
    if (fixCancellationSuccess) {
      queryClient.setQueryData<OrderBookCalendarDataModel>(
        OrderBookClientQueryKeyFactory.pershing(searchFields),
        (prev) => {
          if (!prev) return prev;

          const details = prev.details ?? [];
          const updatedDetails = details.map((detail) => {
            if (detail.allocation.id === orderBookCancelModal?.allocation?.id) {
              const updatedAllocation = { ...detail.allocation, pershingOrderBookStatus: PershingStatusEnum.Canceled };
              return { ...detail, allocation: updatedAllocation };
            } else {
              return detail;
            }
          });

          return { ...prev, details: updatedDetails };
        },
      );
    }
  }, [fixCancellationSuccess]);

  return (
    <HaloDataGrid
      columns={columns}
      rows={calendarAllocations}
      pagination
      height="fill"
      paginationMode="server"
      sortingMode="server"
      density="compact"
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationChange}
      onSortModelChange={handleSortChange}
      rowCount={totalResults}
      loading={isFetching}
      disableRowSelectionOnClick
    />
  );
};
