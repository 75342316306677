import { ReactElement } from 'react';

import { AllocationModalContainer, AllocationModalContainerProps } from '@halo-common/modals';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { FormProvider, Resolver, useForm } from 'react-hook-form';

export type AllocationModalFormModel = {
  allocations: Array<{
    accountOption: AccountTypeAheadOption | null;
    amount: string;
    checkbox?: boolean;
  }>;
};

export type AllocationModalProps = AllocationModalContainerProps & {
  validation?: Resolver<AllocationModalFormModel>;
};

export const AllocationModal = ({ validation, ...ModalProps }: AllocationModalProps): ReactElement => {
  const formMethods = useForm<AllocationModalFormModel>({
    mode: 'onSubmit',
    resolver: validation,
    defaultValues: {
      allocations: [{ accountOption: null, amount: '', checkbox: false }],
    },
  });

  return (
    <FormProvider {...formMethods}>
      <AllocationModalContainer {...ModalProps} />
    </FormProvider>
  );
};
