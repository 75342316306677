import { FIX_DATE_TIME_FORMAT } from '@halo-common/constants';
import { OrganizationCapacityEnum } from '@halo-common/enums';
import { FixWebsocketEvent } from '@halo-common/hooks';
import {
  AllocationCalendarModel,
  AllocationModel,
  AllocationWholesalerModel,
  BulkOrderTicketReceiptModel,
  CalendarModel,
  OrderTicketReceiptModel,
} from '@halo-common/models';
import { BulkOrderTicketFormFields } from '@halo-common/schemas';
import {
  ApiAccountMapper,
  ApiIssuerMapper,
  ApiUserMapper,
  mapApiFixOrderModelToFixOrderModel,
} from '@halo-data-sources/mappers';
import {
  ApiAllocationModel,
  ApiBulkAllocation,
  ApiCalendarAllocationModel,
  ApiCalendarModel,
  ApiGetBulkAllocationsValidationRequest,
  ApiGetBulkAllocationsValidationResponse,
  ApiWholesalerModel,
} from '@halo-data-sources/models';
import { OrderTicketValidationSwitchErrors } from '@halo-data-sources/switches';
import { DateTime } from 'luxon';

export const ApiAllocationMapper = {
  toOrderTicketReceiptAllocationErrors: (
    allocations: OrderTicketReceiptModel['allocations'],
  ): OrderTicketValidationSwitchErrors => {
    const errors = {} as OrderTicketValidationSwitchErrors;

    return (
      allocations?.reduce((prev, allocation) => {
        return { ...prev, [allocation.accountId.toString()]: allocation.errorMessages?.join('') };
      }, errors) ?? errors
    );
  },
  toOrderTicketReceipt: (model: ApiGetBulkAllocationsValidationResponse): OrderTicketReceiptModel => {
    const allocations = model.allocations?.map((allocation) => ({
      accountId: allocation.account_id,
      accountIdentifier: allocation.account_identifier,
      calendarAllocationId: allocation.calendar_allocation_id,
      commission: allocation.commission,
      fixId: allocation.fix_id,
      otherMiscFees: allocation.other_misc_fees,
      quantity: allocation.quantity,
      salesCredit: allocation.sales_credit,
      errorMessages: allocation.error_messages,
    }));

    const summary = ApiAllocationMapper.toOrderTicketReceiptSummary(model.summary);

    return { allocations, summary, message: model.message, status: 'success' };
  },
  toOrderTicketReceiptSummary: (
    model: ApiGetBulkAllocationsValidationResponse['summary'],
  ): OrderTicketReceiptModel['summary'] => {
    if (!model) return undefined;

    const principal = Number(model.principal);
    const commission = Number(model.commission);
    const otherMiscFees = Number(model.other_misc_fees);
    const salesCredit = Number(model.sales_credit);
    const total = Number(model.total);

    return {
      principal,
      commission: !Number.isNaN(commission) ? commission : undefined,
      otherMiscFees: !Number.isNaN(otherMiscFees) ? otherMiscFees : undefined,
      salesCredit: !Number.isNaN(salesCredit) ? salesCredit : undefined,
      total: !Number.isNaN(total) ? total : undefined,
    };
  },
  toAllocationWholesalerModel(model: ApiWholesalerModel): AllocationWholesalerModel {
    return {
      id: model.id,
      code: model.code.toLowerCase(),
      name: model.name,
      mpid: model.mpid,
      dtc: model.dtc,
    };
  },
  toAllocationCalendarModel(model: ApiCalendarAllocationModel): AllocationCalendarModel {
    return {
      cusip: model.cusip,
      settleDate: model.settle_date,
      expirationDate: model.expiration_date,
    };
  },
  toAllocationModel(model: ApiAllocationModel): AllocationModel {
    return {
      account: ApiAccountMapper.toAccountModel(model.account),
      amount: model.amount,
      approved: model.approved,
      attestation: model.attestation,
      calendarId: model.calendar_id,
      category: model.category,
      createdOn: model.created_at,
      expirationDate: model.expiration_date,
      executionOrderId: model.exec_order_id,
      ergStatus: model.ergstatus,
      ibd: model.calendar_page_erg_id,
      id: model.id,
      boughtPrice: model.price,
      soldPrice: model.saleprice,
      issuer: model.issuer ? ApiIssuerMapper.toIssuerModel(model.issuer) : null,
      reason: model.reason,
      status: model.status,
      updatedOn: model.updated_at,
      user: ApiUserMapper.toUser(model.user),
      wholesaler: model.wholesaler ? ApiAllocationMapper.toAllocationWholesalerModel(model.wholesaler) : null,
      fixId: model.fix_id,
      pershingOrderBookStatus: model.pershing_order_book_status,
      advisorName: model.advisor_name,
    };
  },
  toJanneyOrderbookAllocationCalendarModel: (
    allocationCalendar: ApiCalendarAllocationModel,
    calendar?: ApiCalendarModel,
  ): AllocationCalendarModel => {
    const tradables = calendar?.note.tradables.map((tradable) => ({ name: tradable.name })) ?? [];
    return {
      cusip: allocationCalendar.cusip,
      settleDate: allocationCalendar.settle_date,
      expirationDate: allocationCalendar.expiration_date,
      tradables,
    };
  },
  toJanneyOrderbookAllocationModel: (allocation: ApiAllocationModel): AllocationModel => ({
    account: ApiAccountMapper.toAccountModel(allocation.account),
    amount: allocation.amount,
    approved: allocation.approved,
    attestation: allocation.attestation,
    calendarId: allocation.calendar_id,
    category: allocation.category,
    createdOn: allocation.created_at,
    expirationDate: allocation.expiration_date,
    executionOrderId: allocation.exec_order_id,
    ergStatus: allocation.ergstatus,
    ibd: allocation.calendar_page_erg_id,
    id: allocation.id,
    issuer: allocation.issuer ? ApiIssuerMapper.toIssuerModel(allocation.issuer) : null,
    reason: allocation.reason,
    status: allocation.status,
    boughtPrice: allocation.price,
    soldPrice: allocation.saleprice,
    updatedOn: allocation.updated_at,
    user: ApiUserMapper.toUser(allocation.user),
    wholesaler: allocation.wholesaler ? ApiAllocationMapper.toAllocationWholesalerModel(allocation.wholesaler) : null,
    fixId: allocation.fix_id,
    pershingOrderBookStatus: allocation.pershing_order_book_status,
    advisorName: allocation.advisor_name,
  }),
};

export const BulkAllocationMapper = {
  toApiPershingBulkAllocationValidationRequest: (
    calendarData: CalendarModel,
    bulkAllocationFormData: BulkOrderTicketFormFields,
    receiptTimeStamp: DateTime,
  ): ApiGetBulkAllocationsValidationRequest => {
    const { solicited, discretion, accountType, orderFrom, compensation } = bulkAllocationFormData;
    const { legend1, legend2, trailer1, trailer2, trailer3 } = bulkAllocationFormData;
    const { ip1, ip2, ip1Percent, ip2Percent, capacityType } = bulkAllocationFormData;

    const fixAuxiliaryOptions = {
      DiscretionUsed: discretion?.toUpperCase(),
      AccountType: accountType,
      PershingOrderReceiptTime: receiptTimeStamp.toFormat(FIX_DATE_TIME_FORMAT),
      PershingOrderReceiveFrom: orderFrom,
    };

    const allocations = bulkAllocationFormData.allocations
      .filter((allocation) => allocation.accountOption?.account?.id)
      .map((allocation) => {
        const accountId = allocation.accountOption?.account?.id;
        if (accountId) return { account_id: accountId, quantity: allocation.quantity ?? 0 };
      }) as Array<ApiBulkAllocation>;

    const stipulations = [{ type: 'PRIMARY', value: 'P' }];

    if (legend1) stipulations.push({ type: 'LEGEND1', value: legend1 });
    if (legend2) stipulations.push({ type: 'LEGEND2', value: legend2 });
    if (trailer1) stipulations.push({ type: 'TRAILERCOMMENT1', value: trailer1 });
    if (trailer2) stipulations.push({ type: 'TRAILERCOMMENT2', value: trailer2 });
    if (trailer3) stipulations.push({ type: 'TRAILERCOMMENT3', value: trailer3 });

    if (ip1) stipulations.push({ type: 'SPLITIP1', value: ip1 });
    if (ip1Percent) stipulations.push({ type: 'SPLITIP1AMT', value: ip1Percent });
    if (ip2) stipulations.push({ type: 'SPLITIP2', value: ip2 });
    if (ip2Percent) stipulations.push({ type: 'SPLITIP2AMT', value: ip2Percent });

    const fixRequestBody = {
      calendar_id: calendarData.id,
      allocations,
      fix_tags: {
        auxiliary: fixAuxiliaryOptions,
        capacity: capacityType?.toUpperCase(),
        solicited: solicited?.toUpperCase(),
        stipulations: stipulations ?? undefined,
      },
    } as ApiGetBulkAllocationsValidationRequest;

    const capacity = OrganizationCapacityEnum[capacityType as OrganizationCapacityEnum];
    const capacityIsAgency = capacity === OrganizationCapacityEnum.AGENCY;

    const addCommissionTag = compensation && capacityIsAgency;

    if (addCommissionTag) {
      fixRequestBody.fix_tags.commission = compensation;
      fixRequestBody.fix_tags.commtype = 'ABSOLUTE';
    }

    return fixRequestBody;
  },
  toBulkOrderTicketReceipt: (model: FixWebsocketEvent): BulkOrderTicketReceiptModel => {
    return {
      allocation: model.allocation ? ApiAllocationMapper.toAllocationModel(model.allocation) : null,
      account: ApiAccountMapper.toAccountModel(model.account),
      order: mapApiFixOrderModelToFixOrderModel(model.order),
      value: model.value,
      event: model.event,
    };
  },
};
