import { ReactElement } from 'react';

import { LinearProgress, Skeleton, Stack, SxProps, Typography } from '@mui/material';

const labelSx = {
  width: 42,
  pl: 1,
};

const progressSx = {
  width: '100%',
};

export type HaloProgressBarProps = {
  value?: number;
  showLabel?: boolean;
  loading?: boolean;
  sx?: SxProps;
};

export const HaloProgressBar = ({
  value,
  showLabel = true,
  loading,
  sx,
}: HaloProgressBarProps): ReactElement | null => {
  if (loading) {
    const labelSkeleton = showLabel ? <Skeleton variant="rectangular" sx={labelSx} height={20} /> : null;

    return (
      <Stack direction="row" alignItems="center" sx={sx}>
        <Skeleton variant="rectangular" width="100%" height={4} />
        {labelSkeleton}
      </Stack>
    );
  }

  if (typeof value !== 'number') return null;

  const containerSx = {
    width: '100%',
    height: '100%',
    ...sx,
  };

  const label = showLabel ? (
    <Typography sx={labelSx} variant="body2">
      {value.toFixed(0)}%
    </Typography>
  ) : null;

  return (
    <Stack direction="row" alignItems="center" sx={containerSx}>
      <LinearProgress sx={progressSx} variant="determinate" value={value} />
      {label}
    </Stack>
  );
};
