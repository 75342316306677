import { DebouncedAtomModel, debouncedAtom } from '@halo-atoms/debounce';
import {
  CALENDAR_PREFERENCE_TAG,
  FIXED_INCOME_PREFERENCE_TAG,
  PRODUCT_APPROVAL_PREFERENCE_TAG,
} from '@halo-common/constants';
import {
  DynamicFiltersSortByEnum,
  DynamicFiltersUnderlyingMatchEnum,
  SortModelDirectionEnum,
  SortModelNullSortEnum,
} from '@halo-common/enums';
import { DynamicSearchAssetTypeAheadOption } from '@halo-common/layouts';
import { SortModel, UnderlyingModel } from '@halo-common/models';
import { atom } from 'jotai';
import { DateTime } from 'luxon';

export type DynamicFiltersHeaderFiltersPayload = {
  endDate: string;
  startDate: string;
  underlyings: Array<UnderlyingModel>;
  underlyingMatches?: DynamicFiltersUnderlyingMatchEnum;
  pageLength?: number;
  page?: number;
  sortByType?: DynamicFiltersSortByEnum;
};

export type DynamicFiltersHeaderPayload = { tag: string } & Partial<DynamicFiltersHeaderFiltersPayload>;

export type DynamicFiltersHeaderFiltersModel = {
  endDate: string;
  startDate: string;
  underlyings: Array<UnderlyingModel>;
  underlyingIds: Array<string>;
  underlyingMatches?: DynamicFiltersUnderlyingMatchEnum;
  pageLength?: number;
  page?: number;
  sortByType?: DynamicFiltersSortByEnum;
  sortByParameters?: Array<SortModel>;
};

export type DynamicFiltersHeaderModel = {
  [tag: string]: DynamicFiltersHeaderFiltersModel;
  [CALENDAR_PREFERENCE_TAG]: DynamicFiltersHeaderFiltersModel;
  [FIXED_INCOME_PREFERENCE_TAG]: DynamicFiltersHeaderFiltersModel;
  [PRODUCT_APPROVAL_PREFERENCE_TAG]: DynamicFiltersHeaderFiltersModel;
};

export type DynamicFiltersAssetSearchModel = {
  [tag: string]: DebouncedAtomModel<string>;
  [CALENDAR_PREFERENCE_TAG]: DebouncedAtomModel<string>;
  [FIXED_INCOME_PREFERENCE_TAG]: DebouncedAtomModel<string>;
  [PRODUCT_APPROVAL_PREFERENCE_TAG]: DebouncedAtomModel<string>;
};

export type DynamicFiltersAssetSearchOptionModel = {
  [tag: string]: DynamicSearchAssetTypeAheadOption | null;
  [CALENDAR_PREFERENCE_TAG]: DynamicSearchAssetTypeAheadOption | null;
  [FIXED_INCOME_PREFERENCE_TAG]: DynamicSearchAssetTypeAheadOption | null;
  [PRODUCT_APPROVAL_PREFERENCE_TAG]: DynamicSearchAssetTypeAheadOption | null;
};

export type DynamicFilterAssetSearchOptionPayload = { tag: string; value: DynamicSearchAssetTypeAheadOption | null };

const _dynamicAssetSearchOption = atom<DynamicFiltersAssetSearchOptionModel>({
  [CALENDAR_PREFERENCE_TAG]: null,
  [FIXED_INCOME_PREFERENCE_TAG]: null,
  [PRODUCT_APPROVAL_PREFERENCE_TAG]: null,
});

export const dynamicAssetSearchOptionAtom = atom(
  (get) => {
    const map = get(_dynamicAssetSearchOption);
    return (tag: string) => map[tag] ?? null;
  },
  (_, set, payload: DynamicFilterAssetSearchOptionPayload) => {
    set(_dynamicAssetSearchOption, (prev) => ({ ...prev, [payload.tag]: payload.value }));
  },
);

const _defaultAssetSearch = debouncedAtom('');
const _calendarAssetSearch = debouncedAtom('');
const _fixedIncomeAssetSearch = debouncedAtom('');
const _productApprovalAssetSearch = debouncedAtom('');
const _defaultAssetSearchMap: DynamicFiltersAssetSearchModel = {
  [CALENDAR_PREFERENCE_TAG]: _calendarAssetSearch,
  [FIXED_INCOME_PREFERENCE_TAG]: _fixedIncomeAssetSearch,
  [PRODUCT_APPROVAL_PREFERENCE_TAG]: _productApprovalAssetSearch,
};

export const dynamicFiltersAssetSearchAtom = atom(() => {
  return (tag: string) => _defaultAssetSearchMap[tag] ?? _defaultAssetSearch;
});

export const initialHeaderFilters = {
  endDate: DateTime.now().endOf('month').toISODate(),
  pageLength: 15,
  page: 0,
  sortByType: DynamicFiltersSortByEnum.expirationDateAsc,
  sortByParameters: [],
  startDate: DateTime.now().toISODate(),
  underlyings: [],
  underlyingIds: [],
  underlyingMatches: DynamicFiltersUnderlyingMatchEnum.ANY,
};

export const initialHeaderFiltersMap = {
  [CALENDAR_PREFERENCE_TAG]: initialHeaderFilters,
  [FIXED_INCOME_PREFERENCE_TAG]: initialHeaderFilters,
  [PRODUCT_APPROVAL_PREFERENCE_TAG]: initialHeaderFilters,
};

const _dynamicFiltersHeaderAtom = atom<DynamicFiltersHeaderModel>(initialHeaderFiltersMap);
export const dynamicFiltersHeaderManagerAtom = atom(
  (get) => {
    const filterMap = get(_dynamicFiltersHeaderAtom);
    const getCalendarSearchOption = get(dynamicAssetSearchOptionAtom);

    return (tag: string) => {
      const filters = filterMap[tag];

      const calendarSearchOption = getCalendarSearchOption(tag);
      const calendarNoteId = calendarSearchOption?.noteId ?? null;

      const isFiltered = [
        calendarNoteId,
        filters.endDate !== initialHeaderFilters.endDate,
        filters.startDate !== initialHeaderFilters.startDate,
        filters.underlyingIds.length,
        filters.underlyingMatches !== initialHeaderFilters.underlyingMatches,
      ].some(Boolean);

      return { ...filters, calendarNoteId, isFiltered };
    };
  },
  (_, set, payload: DynamicFiltersHeaderPayload) => {
    const { tag, underlyings, sortByType: sortBy, ...filters } = payload;

    const updateSortBy = (prev: DynamicFiltersHeaderModel): DynamicFiltersHeaderModel => {
      if (!sortBy) return prev;

      const calendarSortBy = sortBy.split(':');
      const sortByValue = calendarSortBy[0];
      const sortByDirection = calendarSortBy[1];

      const isAscending = (sortByDirection as SortModelDirectionEnum) === SortModelDirectionEnum.asc;
      const oppositeSortByDirection = isAscending ? SortModelDirectionEnum.desc : SortModelDirectionEnum.asc;

      const sortByDictionary = {
        expiration_date: [
          { field: 'is_active', direction: SortModelDirectionEnum.desc },
          { field: 'expiration_date', direction: sortByDirection },
          { field: 'term', direction: SortModelDirectionEnum.asc },
        ],
        term: [
          { field: 'term', direction: sortByDirection },
          { field: 'payoff', direction: SortModelDirectionEnum.desc, nulls: SortModelNullSortEnum.last },
        ],
        payoff: [
          { field: 'payoff', direction: sortByDirection, nulls: SortModelNullSortEnum.last },
          { field: 'term', direction: SortModelDirectionEnum.asc },
        ],
        protection: [
          { field: 'protection_pct', direction: sortByDirection },
          { field: 'protection_rank', direction: oppositeSortByDirection },
          { field: 'payoff', direction: SortModelDirectionEnum.desc, nulls: SortModelNullSortEnum.last },
        ],
      };

      const updatedSortBy = !Object.keys(sortByDictionary).includes(sortByValue)
        ? (sortByDictionary['expiration_date'] as Array<SortModel>)
        : (sortByDictionary[sortByValue as keyof typeof sortByDictionary] as Array<SortModel>);

      return { ...prev, [tag]: { ...prev[tag], sortByType: sortBy, sortByParameters: updatedSortBy } };
    };

    const updateUnderlyingIds = (prev: DynamicFiltersHeaderModel) => {
      if (!underlyings) return prev;
      const ids = underlyings.map(({ id }) => id);
      return { ...prev, [tag]: { ...prev[tag], underlyingIds: ids, underlyings } };
    };

    if (sortBy) set(_dynamicFiltersHeaderAtom, updateSortBy);
    else if (underlyings) set(_dynamicFiltersHeaderAtom, updateUnderlyingIds);
    else set(_dynamicFiltersHeaderAtom, (prev) => ({ ...prev, [tag]: { ...prev[tag], ...filters } }));
  },
);
