import { ReactElement, ReactNode } from 'react';

import { Stack } from '@halodomination/halo-fe-common';

export type ProductDetailPanelLayoutProps = {
  children: ReactNode;
  slots?: { highlight?: ReactNode; underlyings?: ReactNode };
  loading?: boolean;
};

export const ProductDetailPanelLayout = ({ children, slots }: ProductDetailPanelLayoutProps): ReactElement => (
  <Stack direction="column">
    {slots?.highlight}
    {slots?.underlyings}
    {children}
  </Stack>
);
