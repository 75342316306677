import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiAdminCalendarAllocationsQueryResponse,
  ApiCalendarAllocationQueryResponseModel,
  ApiGetBulkAllocationsValidationRequest,
  ApiGetBulkAllocationsValidationResponse,
  ApiQueryFiltersResponseModel,
  ApiQueryRequestModel,
} from '@halo-data-sources/models';

const CALENDAR_PATH = '/execution/calendar/allocation';
const FIX_PATH = '/execution/fix/calendar/allocation';

export const getAdminCalendarAllocationsQuery = async (
  payload: ApiQueryRequestModel,
): Promise<ApiAdminCalendarAllocationsQueryResponse> => {
  const clientConfiguration = await getClientConfiguration(CALENDAR_PATH);

  const path = `${clientConfiguration.basePath}/admin/query`;

  const response = await request<ApiAdminCalendarAllocationsQueryResponse>(path, {
    ...clientConfiguration.requestInit,
    failOnError: false,
    method: 'POST',
    body: JSON.stringify({
      ...payload,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const getAdminCalendarAllocationFiltersQuery = async (
  payload: ApiQueryRequestModel,
): Promise<ApiQueryFiltersResponseModel> => {
  const clientConfiguration = await getClientConfiguration(CALENDAR_PATH);

  const path = `${clientConfiguration.basePath}/admin/query/filters`;

  const response = await request<ApiQueryFiltersResponseModel>(path, {
    ...clientConfiguration.requestInit,
    failOnError: false,
    method: 'POST',
    body: JSON.stringify({
      ...payload,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const getAdminCalendarAllocationCSVDownload = async (payload: ApiQueryRequestModel): Promise<string> => {
  const clientConfiguration = await getClientConfiguration(CALENDAR_PATH);

  const path = `${clientConfiguration.basePath}/admin/query/csv`;

  const response = await request<string>(path, {
    ...clientConfiguration.requestInit,
    failOnError: false,
    method: 'POST',
    body: JSON.stringify({
      ...payload,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const getCalendarAllocationsQuery = async (
  payload: ApiQueryRequestModel,
): Promise<ApiCalendarAllocationQueryResponseModel> => {
  const clientConfiguration = await getClientConfiguration(CALENDAR_PATH);

  const path = `${clientConfiguration.basePath}/query`;

  const response = await request<ApiCalendarAllocationQueryResponseModel>(path, {
    ...clientConfiguration.requestInit,
    failOnError: false,
    method: 'POST',
    body: JSON.stringify({
      ...payload,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const getCalendarAllocationFiltersQuery = async (
  payload: ApiQueryRequestModel,
): Promise<ApiQueryFiltersResponseModel> => {
  const clientConfiguration = await getClientConfiguration(CALENDAR_PATH);

  const path = `${clientConfiguration.basePath}/query/filters`;

  const response = await request<ApiQueryFiltersResponseModel>(path, {
    ...clientConfiguration.requestInit,
    failOnError: false,
    method: 'POST',
    body: JSON.stringify({
      ...payload,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const getCalendarAllocationCSVDownload = async (payload: ApiQueryRequestModel): Promise<string> => {
  const clientConfiguration = await getClientConfiguration(CALENDAR_PATH);

  const path = `${clientConfiguration.basePath}/query/csv`;

  const response = await request<string>(path, {
    ...clientConfiguration.requestInit,
    failOnError: false,
    method: 'POST',
    body: JSON.stringify({
      ...payload,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const postBulkFixAllocationValidation = async (
  payload: ApiGetBulkAllocationsValidationRequest,
): Promise<ApiGetBulkAllocationsValidationResponse> => {
  const clientConfiguration = await getClientConfiguration(FIX_PATH);

  const path = `${clientConfiguration.basePath}/validate`;

  const response = await request<ApiGetBulkAllocationsValidationResponse>(path, {
    ...clientConfiguration.requestInit,
    failOnError: false,
    method: 'POST',
    body: JSON.stringify({
      ...payload,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const postBulkFixAllocationPlace = async (
  payload: ApiGetBulkAllocationsValidationRequest,
): Promise<ApiGetBulkAllocationsValidationResponse> => {
  const clientConfiguration = await getClientConfiguration(FIX_PATH);

  const path = `${clientConfiguration.basePath}/place`;

  const response = await request<ApiGetBulkAllocationsValidationResponse>(path, {
    ...clientConfiguration.requestInit,
    failOnError: false,
    method: 'POST',
    body: JSON.stringify({
      ...payload,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};
