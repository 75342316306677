import { ReactElement, useEffect } from 'react';

import { ActionButton } from '@halo-common/components';
import { navigateParentTo } from '@halo-common/utils';
import { CheckEmailPageMeta } from '@halo-pages/app/onboarding/check-email';
import { LoginPageMeta } from '@halo-pages/app/onboarding/login';
import { OnboardingActions, OnboardingSelectors, OnboardingStatusEnum } from '@halo-stores/Onboarding';
import { Iconography, Stack } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { object as yupObject, string as yupString } from 'yup';

const linkSx = {
  alignItems: 'center',
  display: 'flex',
  marginTop: 5,
  width: 'fit-content',
};

const iconSx = {
  alignItems: 'center',
  display: 'flex',
  height: '1.5rem',
  width: '1.5rem',
};

export const schema = yupObject().shape({
  email: yupString().email('Please enter a valid email address.').required('Please enter a valid email address.'),
});

const forgotPasswordResolver = yupResolver(schema);

export type ForgotPasswordFormSchema = {
  email: string;
};

export const ForgotPasswordForm = (): ReactElement => {
  const dispatch = useDispatch();

  const status = useSelector(OnboardingSelectors.selectStatus);
  const emailSent = useSelector(OnboardingSelectors.selectEmailSent);
  const error = useSelector(OnboardingSelectors.selectError);

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<ForgotPasswordFormSchema>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    resolver: forgotPasswordResolver,
    defaultValues: {
      email: '',
    },
  });

  const { ref: emailRef, ...emailProps } = register('email');

  const onSubmit = (data: ForgotPasswordFormSchema) => {
    dispatch(OnboardingActions.initiatePasswordReset(data.email));
  };

  const navigationHandler = () => void navigateParentTo(`/react${LoginPageMeta.route}`);

  useEffect(() => {
    dispatch(OnboardingActions.updateEmailSent({ emailSent: false }));
  }, []);

  useEffect(() => {
    if (emailSent) {
      navigateParentTo(`/react${CheckEmailPageMeta.route}`);
    }
  }, [emailSent]);

  const errorMessageText =
    error === 'SSO ONLY'
      ? 'Your account is only enabled through single sign on. Please visit your single sign on portal.'
      : 'Something went wrong. Please try again later.';

  const errorMessage =
    status === OnboardingStatusEnum.failurePasswordReset ? (
      <Alert severity="error" variant="filled">
        <Typography variant="body2" component="span">
          {errorMessageText}
        </Typography>
      </Alert>
    ) : null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={3}>
        <Typography align="center" component="h4" variant="h4">
          Forgot your password?
        </Typography>
        {errorMessage}
        <TextField
          size="large"
          fullWidth
          label="Email Address"
          inputRef={emailRef}
          {...emailProps}
          error={Boolean(errors?.email)}
          helperText={errors?.email?.message}
        />
        <ActionButton loading={isSubmitting} type="submit" fullWidth color="primary" variant="contained">
          Reset Password
        </ActionButton>
      </Stack>
      <Button
        color="primary"
        sx={linkSx}
        onClick={navigationHandler}
        startIcon={<Iconography iconName="arrow-left" sx={iconSx} color="primary.main" />}
      >
        Back
      </Button>
    </form>
  );
};
