import { ReactElement } from 'react';

import { ConnectedContactButton } from '@halo-common/smartComponents';
import { Reminder } from '@halo-modules/app';
import { useMediaQuery, useTheme } from '@mui/material';

const TIMEOUT = 750;

export const LinkExpiredReminder = (): ReactElement => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Reminder
        timeout={TIMEOUT * 1.15}
        icon="undo"
        message="No problem, we can send out a new activation link to your email. If you still are having problems, please contact us."
      >
        <ConnectedContactButton type="button" variant="outlined" fullWidth={isMobile} />
      </Reminder>
    </>
  );
};
