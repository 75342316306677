import { SortModelDirectionEnum } from '@halo-common/enums';
import { GetEducationHubDetailsRequestModel } from '@halo-common/models';
import { GridRowId } from '@halodomination/halo-fe-common';
import { atom } from 'jotai';

const DEFAULT_FILTERS: GetEducationHubDetailsRequestModel = {
  page: 0,
  resultsPerPage: 25,
  sort: [{ field: 'completionDate', sort: SortModelDirectionEnum.desc }],
};

export const educationHubFiltersAtom = atom<GetEducationHubDetailsRequestModel>(DEFAULT_FILTERS);
export const detailPanelExpandedRowIdListAtom = atom<Array<GridRowId>>([]);
