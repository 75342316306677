import { orderBookAuctionCommentPopoverAtom } from '@halo-atoms/orderbook';
import { AuctionBuyerModel, AuctionCommentReviewModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { putAuctionReviewComment } from '@halo-data-sources/clients';
import { AuctionQueryKeyFactory } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';

const reviewCommentMutationFn = async (options: AuctionCommentReviewModel): Promise<AuctionCommentReviewModel> => {
  await putAuctionReviewComment(options);
  return options;
};

export const useReviewCommentMutation = (
  auctionId?: number,
  commentId?: number,
): UseMutationResult<AuctionCommentReviewModel, Error, AuctionCommentReviewModel> => {
  const queryClient = useQueryClient();
  const { enqueueErrorEvent } = useSnackbar();
  const setCommentPopoverData = useSetAtom(orderBookAuctionCommentPopoverAtom);

  return useMutation<AuctionCommentReviewModel, Error, AuctionCommentReviewModel>({
    mutationFn: reviewCommentMutationFn,
    onSuccess: (data) => {
      const key = AuctionQueryKeyFactory.buyerAuction(auctionId?.toString());
      queryClient.setQueryData<AuctionBuyerModel>(key, (prev) => {
        if (!prev) return undefined;
        const updatedAuction = { ...prev };
        const updatedQuotes = [...updatedAuction.quotes];
        const quoteIndex = updatedQuotes.findIndex((quote) => commentId && quote.comment?.id === commentId);
        const updatedQuote = { ...updatedQuotes[quoteIndex] };
        const updatedComment = updatedQuote.comment ? { ...updatedQuote.comment, buyerApproved: data.approved } : null;
        updatedQuote.comment = updatedComment;
        updatedQuotes.splice(quoteIndex, 1, updatedQuote);
        updatedAuction.quotes = updatedQuotes;
        return updatedAuction;
      });
    },
    onSettled: () => {
      if (commentId) setCommentPopoverData({ commentMap: { [commentId]: false } });
    },
    onError: () => {
      enqueueErrorEvent({ message: translations.common.error });
    },
  });
};
