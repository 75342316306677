import { DisclaimerUserModel } from '@halo-common/models';
import { getUserDisclaimer } from '@halo-data-sources/clients';
import { ApiDisclaimerMapper } from '@halo-data-sources/mappers';
import { UserQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export type UserDisclaimerSection = {
  id: string;
  description: string;
  name: string;
  files: Array<DisclaimerUserModel>;
};

export type UserDisclaimersQueryResult = Array<DisclaimerUserModel>;

const getUserDisclaimersQueryFn = async () => {
  const response = await getUserDisclaimer();

  const disclaimers = response.map(ApiDisclaimerMapper.toUserDisclaimer);

  return Object.values(disclaimers).sort((a, b) => a.name.localeCompare(b.displayName));
};

export const useUserDisclaimersQuery = (): UseQueryResult<Array<DisclaimerUserModel>, Error> =>
  useQuery<Array<DisclaimerUserModel>, Error>({
    queryKey: UserQueryKeyFactory.disclaimers(),
    queryFn: () => getUserDisclaimersQueryFn(),
  });
