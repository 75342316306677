export const DASHBOARD_PORTFOLIO_POSITIONS_TRANSLATIONS = {
  addYourClientsAndPositionsToBeginTracking:
    'Add your clients and positions to begin tracking your portfolio performance, composition, events, and more.',
  subTitle: 'All Accounts, {currency}',
  dataAboutPortfolioComposition: 'Data about portfolio composition.',
  fullPortfolioValue: 'Full Portfolio Value',
  issuerName: 'Issuer Name',
  overseeYourFullBookOfBusiness: 'Oversee Your Full Book of Business',
  portfolioPositions: 'Portfolio Positions',
  positions: 'Positions',
  products: 'Products',
  notional: 'Notional',
  numberOfProducts: '# of Products',
  marketValue: 'Market Value',
  marketChange: 'Market Change',
};
