import { ReactElement } from 'react';

import { EmptyResultsCard, EmptyResultsCardProps } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { AddAccountOrPositionButton, PortfolioLifecycleWidgetTab } from '@halo-modules/app';
import { BrandIcon } from '@halodomination/halo-fe-common';

export type PortfolioLifecycleEmptyMessageProps = Partial<EmptyResultsCardProps> & {
  tab: PortfolioLifecycleWidgetTab;
};

const sx = { minHeight: 400 };

export const PortfolioLifecycleEmptyMessage = ({
  tab,
  ...props
}: PortfolioLifecycleEmptyMessageProps): ReactElement => {
  const recentEventsSelected = tab.id === 'recentEvents';

  const title = recentEventsSelected
    ? translations.dashboard.portfolioLifecycle.noRecentEvents
    : translations.dashboard.portfolioLifecycle.noUpcomingEvents;

  const Localization = !recentEventsSelected ? { title: { dynamicContent: { type: tab.label } } } : undefined;

  return (
    <EmptyResultsCard
      title={title}
      description={translations.dashboard.portfolioLifecycle.addYourPortfolioPositions}
      brandIcon={<BrandIcon variant="couponGuaranteed" color="primary" />}
      action={<AddAccountOrPositionButton color="primary" />}
      Localization={Localization}
      sx={sx}
      {...props}
    />
  );
};
