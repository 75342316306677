import { ReactElement, ReactNode, useEffect } from 'react';

import { USER_LOCAL_KEY, USER_TOKEN_KEY } from '@halo-data-sources/queries';
import { tx } from '@transifex/native';
import { TXProvider } from '@transifex/react';
import { useRouter } from 'next/router';

const tags = [
  'react',
  'react_portfolio',
  'react_user_account',
  'react_calendar_page',
  'react_watchlist',
  'react_note_card',
  'react_note_details',
  'react_legal',
  'react_uncategorized',
];

export const transifexFilterTags = tags.join('|');

export type TransifexProviderProps = {
  children: ReactNode;
};
export const TransifexProvider = ({ children }: TransifexProviderProps): ReactElement => {
  const router = useRouter();

  useEffect(() => {
    if (router.isReady) {
      const urlParams = new URLSearchParams(window.location.search);
      const urlLocale = urlParams.get('locale');

      const localizationPublicKey = window.localStorage.getItem(USER_TOKEN_KEY);
      const preferredLanguage = window.localStorage.getItem(USER_LOCAL_KEY);

      if (localizationPublicKey) {
        tx.init({ token: localizationPublicKey, filterTags: transifexFilterTags });
        if (urlLocale) void tx.setCurrentLocale(urlLocale);
        else if (preferredLanguage) void tx.setCurrentLocale(preferredLanguage);
      }
    }
  }, [router.isReady]);

  return <TXProvider instance={tx}>{children}</TXProvider>;
};
