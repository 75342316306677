import { ReactElement } from 'react';

import { LineChart, LineChartProps } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';

export type InstitutionalLineChartProps = Omit<LineChartProps, 'height'> & {
  height?: number;
  loading?: boolean;
};

export const InstitutionalLineChart = ({
  loading,
  height = 420,
  ...props
}: InstitutionalLineChartProps): ReactElement => {
  if (loading) return <Skeleton variant="rounded" height={height} width="100%" />;

  return <LineChart {...props} height={height} />;
};
