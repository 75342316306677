import { ReactElement } from 'react';

import { orderBookExecutionDetailModalAtom } from '@halo-atoms/orderbook';
import { Link } from '@mui/material';
import { useSetAtom } from 'jotai';

const linkSx = {
  fontWeight: 'fontWeightBold',
  textDecoration: 'none',
  '&:hover': {
    cursor: 'pointer',
  },
};

export type OrderBookCalendarsViewOrderDetailsButtonProps = {
  cusip: string;
  calendarId: number;
};

export const OrderBookCalendarsViewOrderDetailsButton = ({
  cusip,
  calendarId,
}: OrderBookCalendarsViewOrderDetailsButtonProps): ReactElement => {
  const setModal = useSetAtom(orderBookExecutionDetailModalAtom);

  const onClick = () => {
    setModal({ open: true, calendarId });
  };

  return (
    <Link sx={linkSx} onClick={onClick}>
      {cusip}
    </Link>
  );
};
