import { ReactElement } from 'react';

import { complianceApprovalQueryAtom } from '@halo-atoms/complianceApproval';
import { ActionButton } from '@halo-common/components';
import { useComplianceApprovalDownloadCSVMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Iconography } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';

export const ComplianceApprovalDownloadCSVButton = (): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();

  const complianceApprovalQuery = useAtomValue(complianceApprovalQueryAtom);

  const { mutate: downloadCSV, isPending } = useComplianceApprovalDownloadCSVMutation();

  const handleDownload = () => {
    const calendarPageId = userInfo?.organization?.calendarPageId;
    const query = { ...complianceApprovalQuery, calendarPageId };
    downloadCSV(query);
  };

  const icon = <Iconography iconName="download" />;

  return (
    <ActionButton
      color="primary"
      type="button"
      variant="text"
      startIcon={icon}
      loadingIndicator="Downloading"
      loading={isPending}
      onClick={handleDownload}
    >
      Download CSV
    </ActionButton>
  );
};
