import { translations } from '@halo-common/translations';
import { IconographyProps } from '@halodomination/halo-fe-common';
import { object, Schema, string } from 'yup';

export const watchlistValidationSchema = object().shape({
  watchlistName: string()
    .matches(/\S+/, translations.watchlist.common.watchlistNameEmptyError)
    .required(translations.watchlist.common.watchlistNameError),
  watchlistIcon: string().required(translations.watchlist.common.watchlistIconError) as Schema<
    IconographyProps['iconName']
  >,
});
