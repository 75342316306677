import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiGetNotificationsRequestModel,
  ApiGetNotificationsResponseModel,
  ApiNotificationTypesResponseModel,
  ApiNotificationTypesRequestModel,
} from '@halo-data-sources/models';

const path = `/common/user/notifications/query`;

export const getNotifications = async (
  payload: ApiGetNotificationsRequestModel,
): Promise<ApiGetNotificationsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(path);

  const response = await request<ApiGetNotificationsResponseModel>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });

  return response;
};

const typesPath = `/common/user/notifications/query/filters`;

export const getNotificationTypes = async (
  payload: ApiNotificationTypesRequestModel,
): Promise<ApiNotificationTypesResponseModel | null> => {
  const clientConfiguration = await getClientConfiguration(typesPath);

  const response = await request<ApiNotificationTypesResponseModel>(clientConfiguration.basePath, {
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });

  return response;
};

const markAsReadPath = `/common/user/notifications/mark-as-read`;

export const markNotificationsAsRead = async (notificationIds: number[]): Promise<void> => {
  const clientConfiguration = await getClientConfiguration(markAsReadPath);

  await request<void>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      notification_ids: notificationIds,
    }),
  });
};
