import { AuctionDateTypeEnum } from '@halo-common/enums';
import { GetAuctionDefaultDatesRequestModel } from '@halo-data-sources/queries';
import { atom } from 'jotai';
import { DateTime } from 'luxon';

const DEFAULT_NOTIONAL = 500_000;
const DEFAULT_QUERY = {
  date: DateTime.now().startOf('day'),
  dateType: AuctionDateTypeEnum.AuctionEnd,
};

export const auctionDefaultsQueryAtom = atom<GetAuctionDefaultDatesRequestModel>(DEFAULT_QUERY);
export const notionalAtom = atom<number | null>(DEFAULT_NOTIONAL);
export const targetAtom = atom<string>('');
export const tradeDateAtom = atom<DateTime | null>(null);
export const strikeDateAtom = atom<DateTime | null>(null);
export const settlementDateAtom = atom<DateTime | null>(null);
export const startAuctionFromLifecycleAtom = atom<boolean>(false);

const _detailsAtom = atom<string>('');
const _selectedIssuersAtom = atom<Array<number>>([]);

export const selectIssuersAtom = atom(null, (_, set, issuerIds: Array<number>) => {
  set(_selectedIssuersAtom, issuerIds);
});

export const detailsAtom = atom(
  (get) => get(_detailsAtom) || undefined,
  (_, set, newDetails: string) => {
    set(_detailsAtom, newDetails);
  },
);

export const selectedIssuersAtom = atom((get) => get(_selectedIssuersAtom));

export const auctionItemAtom = atom((get) => {
  const targetValue = get(targetAtom);
  const target = targetValue ? Number(targetValue) : 0;
  return {
    issuers: get(_selectedIssuersAtom),
    notional: get(notionalAtom),
    number: target,
    strikeDate: get(strikeDateAtom)?.toISODate(),
    tradeDate: get(tradeDateAtom)?.toISODate(),
    settlementDate: get(settlementDateAtom)?.toISODate(),
    details: get(detailsAtom),
  };
});
