import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { AuctionStatusEnum } from '@halo-common/enums';
import { AuctionBuyerModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { putAuctionChooseWinner } from '@halo-data-sources/clients';
import {
  AuctionQueryKeyFactory,
  OrderBookAuctionQueryKeyFactory,
  OrderBookAuctionsQueryResult,
} from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

export type AuctionChooseWinnerModel = {
  auctionId: number;
  issuerId: number;
  issuerName: string;
};

const chooseAuctionWinnerMutationFn = async (options: AuctionChooseWinnerModel): Promise<AuctionChooseWinnerModel> => {
  await putAuctionChooseWinner(options.auctionId, options.issuerId);
  return options;
};

export const useAuctionChooseWinnerMutation = (): UseMutationResult<
  AuctionChooseWinnerModel,
  Error,
  AuctionChooseWinnerModel
> => {
  const queryClient = useQueryClient();

  const searchFields = useAtomValue(orderBookQueryAtom);

  const { enqueueErrorEvent } = useSnackbar();

  return useMutation<AuctionChooseWinnerModel, Error, AuctionChooseWinnerModel>({
    mutationFn: chooseAuctionWinnerMutationFn,
    onSuccess: (data) => {
      const auctionQueryKey = AuctionQueryKeyFactory.buyerAuction(data.auctionId?.toString());
      queryClient.setQueryData<AuctionBuyerModel>(auctionQueryKey, (prev) => {
        if (!prev) return undefined;
        const updatedAuction = { ...prev };
        const updatedQuotes = [...updatedAuction.quotes];
        const quoteIndex = updatedQuotes.findIndex((quote) => quote.issuer.id === data.issuerId);
        const updatedQuote = { ...updatedQuotes[quoteIndex] };
        updatedQuote.isWinningQuote = true;
        updatedQuotes.splice(quoteIndex, 1, updatedQuote);
        updatedAuction.winningQuote = { id: updatedQuote.id, issuer: data.issuerName };
        updatedAuction.quotes = updatedQuotes;
        updatedAuction.status = AuctionStatusEnum.TermsPending;
        updatedAuction.ergStatus = AuctionStatusEnum.TermsPending;
        return updatedAuction;
      });

      const auctionsQueryKey = OrderBookAuctionQueryKeyFactory.auctions(searchFields);
      queryClient.setQueryData<OrderBookAuctionsQueryResult>(auctionsQueryKey, (prev) => {
        if (!prev) return undefined;
        const prevAuctions = prev.auctions ?? [];
        const updatedOrderbookAuctions = [...prevAuctions];
        const auctionIndex = updatedOrderbookAuctions.findIndex((auction) => auction.id === data.auctionId);
        const updatedAuction = { ...updatedOrderbookAuctions[auctionIndex] };
        updatedAuction.status = AuctionStatusEnum.TermsPending;
        updatedAuction.issuer = data.issuerName;
        updatedOrderbookAuctions.splice(auctionIndex, 1, updatedAuction);
        return { ...prev, auctions: updatedOrderbookAuctions };
      });
    },
    onError: () => {
      enqueueErrorEvent({ message: translations.common.error });
    },
  });
};
