import { ChangeEventHandler, ReactElement } from 'react';

import { portfolioLifecycleManagerAtom } from '@halo-atoms/portfolio';
import {
  LifecycleAllEventTypeEnum,
  LifecycleConditionalEventTypeEnum,
  LifecycleEventTypeEnum,
} from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { useRecordActivityMutation } from '@halo-data-sources/mutations';
import { LocalizedInputLabel, LocalizedMenuItem, LocalizedTextField } from '@halodomination/halo-fe-common';
import { FormControl, FormControlProps } from '@mui/material';
import { useAtom } from 'jotai';

const dividedMenuItemSx = {
  borderTop: '1px solid',
  borderColor: 'grey.300',
};

export type PortfolioEventsListFilterByTypeProps = Partial<FormControlProps>;

export const PortfolioEventsListFilterByType = (props: PortfolioEventsListFilterByTypeProps): ReactElement => {
  const { mutate: recordActivity } = useRecordActivityMutation();

  const [lifecycleFilters, setLifecycleFilters] = useAtom(portfolioLifecycleManagerAtom);
  const { filters } = lifecycleFilters;
  const { eventTypes } = filters;

  const label = translations.portfolio.lifecycle.eventListFilterByLabel;

  const handleEventTypeFilterChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    setLifecycleFilters({ filters: { eventTypes: e.target.value } });
    const verb = `filtered lifecycle events list by ${e.target.value}`;
    const activity = [{ verb, model_name: 'Portfolio' }];
    recordActivity({
      activity,
      fromIFrame: false,
    });
  };

  return (
    <FormControl fullWidth {...props}>
      <LocalizedInputLabel id="eventTypeLabelId" shrink>
        {label}
      </LocalizedInputLabel>
      <LocalizedTextField
        select
        variant="outlined"
        SelectProps={{ labelId: 'eventTypeLabelId', displayEmpty: true }}
        label={label}
        value={eventTypes}
        size="large"
        fullWidth
        onChange={handleEventTypeFilterChange}
      >
        <LocalizedMenuItem value={LifecycleEventTypeEnum.EMPTY}>
          {translations.portfolio.lifecycle.allEventTypeMenuItem}
        </LocalizedMenuItem>
        <LocalizedMenuItem sx={dividedMenuItemSx} value={LifecycleEventTypeEnum.EXPIRATION}>
          {translations.common.maturity}
        </LocalizedMenuItem>
        <LocalizedMenuItem sx={dividedMenuItemSx} value={LifecycleAllEventTypeEnum.ALL_COUPONS}>
          {translations.portfolio.lifecycle.couponsEventTypeMenuItem}
        </LocalizedMenuItem>
        <LocalizedMenuItem value={LifecycleConditionalEventTypeEnum.BELOW_PROTECTION}>
          {translations.portfolio.lifecycle.couponsBelowEventTypeMenuItem}
        </LocalizedMenuItem>
        <LocalizedMenuItem value={LifecycleEventTypeEnum.FIXED_COUPON}>
          {translations.common.fixedCoupon}
        </LocalizedMenuItem>
        <LocalizedMenuItem value={LifecycleEventTypeEnum.CONDITIONAL_COUPON}>
          {translations.common.conditionalCoupon}
        </LocalizedMenuItem>
        <LocalizedMenuItem value={LifecycleEventTypeEnum.MEMORY_COUPON}>
          {translations.common.memoryCoupon}
        </LocalizedMenuItem>
        <LocalizedMenuItem sx={dividedMenuItemSx} value={LifecycleAllEventTypeEnum.ALL_CALL_OBSERVATION}>
          {translations.portfolio.lifecycle.callObservationsEventTypeMenuItem}
        </LocalizedMenuItem>
        <LocalizedMenuItem value={LifecycleEventTypeEnum.AUTOCALL_OBS}>
          {translations.portfolio.lifecycle.autocallObservationEventTypeMenuItem}
        </LocalizedMenuItem>
        <LocalizedMenuItem value={LifecycleConditionalEventTypeEnum.ABOVE_CALL_LEVEL}>
          {translations.portfolio.lifecycle.autocallAboveEventTypeMenuItem}
        </LocalizedMenuItem>
        <LocalizedMenuItem value={LifecycleEventTypeEnum.ISSUER_CALL_OBS}>
          {translations.portfolio.lifecycle.issuerCallObservationEventTypeMenuItem}
        </LocalizedMenuItem>
      </LocalizedTextField>
    </FormControl>
  );
};
