import { ReactElement } from 'react';

import { AuctionStatusEnum } from '@halo-common/enums';
import { useNoteSpecificDetails } from '@halo-common/hooks';
import { ExecutionAuctionReviewDetailRow } from '@halo-common/modals';
import { AuctionAdminModel } from '@halo-common/models';
import { useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Container, Divider } from '@mui/material';

const containerSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minWidth: 350,
};

export type ExecutionAuctionReviewDetailsTabProps = {
  product?: AuctionAdminModel | null;
};

export const ExecutionAuctionReviewDetailsTab = ({ product }: ExecutionAuctionReviewDetailsTabProps): ReactElement => {
  const strikeDate = product?.targetStrikeDate ?? '';
  const tradeDate = product?.targetTradeDate ?? '';
  const settleDate = product?.targetSettleDate ?? '';
  const startDate = product?.createdAt ?? '';
  const endTime = product?.endDateTime ?? '';
  const details = product?.details ?? '';
  const style = product?.style.toString() ?? '';
  const status = product?.status ?? 'DEFAULT';
  const note = product?.note;
  const price = product?.price;
  const notional = product?.notional;

  const { highlights } = useNoteSpecificDetails(note);

  const [priceText, notionalText] = useCurrencyConverter({ price, notional }, { currencyCode: note?.currencyCode });

  const statusText = {
    DEFAULT: '',
    [AuctionStatusEnum.Canceled]: 'Canceled',
    [AuctionStatusEnum.ChooseWinner]: 'Choose Winner',
    [AuctionStatusEnum.InAuction]: 'Auction',
    [AuctionStatusEnum.NotWin]: 'No Winner',
    [AuctionStatusEnum.OpsApproval]: 'Ops-approval Required',
    [AuctionStatusEnum.PreApproval]: 'Pre-approval Required',
    [AuctionStatusEnum.Purchased]: 'Purchased',
    [AuctionStatusEnum.TermsAccepted]: 'Terms Accepted',
    [AuctionStatusEnum.TermsPending]: 'Waiting for Terms',
    [AuctionStatusEnum.TermsReviewed]: 'Review Terms',
  }[status];

  return (
    <Container sx={containerSx} maxWidth="md" disableGutters>
      <ExecutionAuctionReviewDetailRow title="Status">{statusText}</ExecutionAuctionReviewDetailRow>
      <Divider />
      <ExecutionAuctionReviewDetailRow title="Notional">{notionalText}</ExecutionAuctionReviewDetailRow>
      <Divider />
      <ExecutionAuctionReviewDetailRow title="Auction Style">{style}</ExecutionAuctionReviewDetailRow>
      <Divider />
      <ExecutionAuctionReviewDetailRow title="Price">{priceText}</ExecutionAuctionReviewDetailRow>
      <Divider />
      {highlights.map(({ label, text, dynamicContent }) => (
        <div key={label}>
          <ExecutionAuctionReviewDetailRow title={label} dynamicContent={dynamicContent}>
            {text}
          </ExecutionAuctionReviewDetailRow>
          <Divider />
        </div>
      ))}
      <ExecutionAuctionReviewDetailRow title="Strike Date">{strikeDate}</ExecutionAuctionReviewDetailRow>
      <Divider />
      <ExecutionAuctionReviewDetailRow title="Trade Date">{tradeDate}</ExecutionAuctionReviewDetailRow>
      <Divider />
      <ExecutionAuctionReviewDetailRow title="Settlement Date">{settleDate}</ExecutionAuctionReviewDetailRow>
      <Divider />
      <ExecutionAuctionReviewDetailRow title="Auction Start Time">{startDate}</ExecutionAuctionReviewDetailRow>
      <Divider />
      <ExecutionAuctionReviewDetailRow title="Auction End Time">{endTime}</ExecutionAuctionReviewDetailRow>
      <Divider />
      <ExecutionAuctionReviewDetailRow title="Additional Details">{details}</ExecutionAuctionReviewDetailRow>
    </Container>
  );
};
