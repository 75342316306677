import { ReactElement } from 'react';

import { quoteValueMapAtom } from '@halo-atoms/quotes';
import { UserRoleEnum } from '@halo-common/enums';
import { ProductDetailModalAlert } from '@halo-common/layouts';
import { PreTradeNoteDetailModalQuoteHistoryChart, PreTradeNoteDetailModalQuotesTable } from '@halo-common/modals';
import { NoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useQuoteHistoryQuery, useRecentQuotesQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedAlert, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';

const errorAlertSx = { alignItems: 'center', padding: 1 };
const subtitleSx = { color: 'text.secondary' };

export type PreTradeNoteDetailModalQuotesTabProps = {
  product?: NoteModel | null;
  onRefresh?: () => void;
  loading?: boolean;
};

export const PreTradeNoteDetailModalQuotesTab = ({
  product,
  onRefresh,
  loading = true,
}: PreTradeNoteDetailModalQuotesTabProps): ReactElement => {
  const productId = product?.id;

  const recentQuoteQuery = productId ? [productId] : undefined;

  const quoteValueMap = useAtomValue(quoteValueMapAtom);

  const { data: user } = useUserInfoQuery();
  const recentQuotesQuery = useRecentQuotesQuery(recentQuoteQuery);
  const { data: quoteHistory, ...quoteHistoryQuery } = useQuoteHistoryQuery(product);

  const pastQuotes = productId ? quoteValueMap[productId]?.past : [];

  const isLoading = loading || recentQuotesQuery?.isPending || quoteHistoryQuery?.isPending;
  const areQuoteDetailsLoaded = recentQuotesQuery?.isFetched && quoteHistoryQuery?.isFetched;
  const hasQuoteViewerRole = user?.details?.roles?.includes(UserRoleEnum.QuoteViewer);

  const hasPastQuotes = Boolean(productId && pastQuotes?.length);
  const hasQuoteHistory = Boolean(quoteHistory && quoteHistory.chart.data.length);

  const showNoQuoteHistoryMessage = areQuoteDetailsLoaded && !hasQuoteHistory;
  const showNoRecentQuotesMessage = areQuoteDetailsLoaded && !hasPastQuotes;
  const showLastIssuerQuotesTable = hasQuoteViewerRole && !showNoRecentQuotesMessage;

  const quoteHistoryChart = showNoQuoteHistoryMessage ? (
    <LocalizedAlert sx={errorAlertSx} severity="warning" variant="filled">
      {translations.pdm.preTrade.errorMessageNoQuoteHistory}
    </LocalizedAlert>
  ) : (
    <PreTradeNoteDetailModalQuoteHistoryChart product={product} loading={isLoading} />
  );

  const lastIssuerQuotesTable = showLastIssuerQuotesTable ? (
    <PreTradeNoteDetailModalQuotesTable product={product} rows={pastQuotes} loading={isLoading} />
  ) : showNoRecentQuotesMessage ? (
    <LocalizedAlert sx={errorAlertSx} severity="warning" variant="filled">
      {translations.pdm.preTrade.quotesTableNoRecentQuoteErrorMessage}
    </LocalizedAlert>
  ) : null;

  return (
    <Stack direction="column" spacing={2}>
      <ProductDetailModalAlert
        product={product}
        onRefresh={onRefresh}
        hideNoPriceMessage
        hideErrorMessage
        hideIssuerCallMessage
        hideRecallMessage
      />
      <Stack direction="column" spacing={1}>
        <LocalizedTypography variant="h5">{translations.pdm.preTrade.quotesTabTitle}</LocalizedTypography>
        <LocalizedTypography sx={subtitleSx} variant="body2">
          {translations.pdm.preTrade.quotesTabSubtitle}
        </LocalizedTypography>
      </Stack>
      {quoteHistoryChart}
      <Stack direction="column" spacing={1}>
        <LocalizedTypography variant="h6">{translations.pdm.preTrade.quotesTableTitle}</LocalizedTypography>
        {lastIssuerQuotesTable}
      </Stack>
    </Stack>
  );
};
