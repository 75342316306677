import { ReactElement } from 'react';

import { ActionButton } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { CurrencyInput, Iconography, LocalizedTypography, Stack, Tabs } from '@halodomination/halo-fe-common';
import { Box } from '@mui/material';
import { useT } from '@transifex/react';
import { Controller, useForm } from 'react-hook-form';

const containerSx = {
  p: 2,
};

export type PreTradeNoteDetailIndicativeTermsheetFormProps = {
  onDownload: (settlement: string, notional: string) => void;
  loading: boolean;
  currency?: string;
};

export const PreTradeNoteDetailIndicativeTermsheetForm = ({
  onDownload,
  loading,
  currency,
}: PreTradeNoteDetailIndicativeTermsheetFormProps): ReactElement => {
  const translator = useT();

  const formMethods = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {
      settlement: 0,
      notional: 500000,
    },
  });

  const { handleSubmit: onSubmit, control, formState } = formMethods;
  const { errors } = formState;
  const { notional: notionalError } = errors;

  const notionalInvalid = Boolean(notionalError);

  const tabs = [
    translator(translations.pdm.preTrade.indicativeTermsheetDayFilter, { count: 3 }),
    translator(translations.pdm.preTrade.indicativeTermsheetDayFilter, { count: 5 }),
    translator(translations.pdm.preTrade.indicativeTermsheetDayFilter, { count: 7 }),
  ];

  const dynamicContentForTabsTranslation = [{ count: 3 }, { count: 5 }, { count: 7 }];
  const slotProps = { tabs: { dynamicContent: dynamicContentForTabsTranslation } };

  const handleDownload = onSubmit((data) => {
    if (data.notional) onDownload(tabs[data.settlement], data.notional.toString());
  });

  const actionButtonEndIcon = !loading ? (
    <Iconography iconName="circle-arrow-down" color="primary.contrastText" />
  ) : undefined;

  return (
    <Box sx={containerSx}>
      <Stack direction="column" spacing={2}>
        <LocalizedTypography variant="h6">{translations.pdm.preTrade.indicativeTermsheetFormTitle}</LocalizedTypography>
        <LocalizedTypography variant="body2">
          {translations.pdm.preTrade.indicativeTermsheetFormSubTitle}
        </LocalizedTypography>
        <Stack direction="row" alignItems="center" spacing={2}>
          <LocalizedTypography variant="subtitle1">
            {translations.pdm.preTrade.indicativeTermsheetFormSettlementLabel}
          </LocalizedTypography>
          <Controller
            control={control}
            name="settlement"
            render={({ field: { onChange, value } }) => (
              <Tabs variant="pills" tabs={tabs} value={value} onChange={onChange} slotProps={slotProps} />
            )}
          />
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <LocalizedTypography variant="subtitle1">
            {translations.pdm.preTrade.indicativeTermsheetFormNotionalLabel}
          </LocalizedTypography>
          <Controller
            control={control}
            name="notional"
            rules={{
              required: { value: true, message: translations.messages.requiredField },
              min: { value: 1, message: translations.pdm.preTrade.indicativeTermsheetNotionalError },
            }}
            render={({ field: { onChange, value } }) => (
              <CurrencyInput
                hiddenLabel
                error={notionalInvalid}
                helperText={notionalError?.message}
                CurrencyOptions={{ currency }}
                valueType="number"
                value={value}
                onChange={(value) => onChange(value ?? '')}
              />
            )}
          />

          <ActionButton
            onClick={handleDownload}
            variant="contained"
            color="primary"
            loading={loading}
            endIcon={actionButtonEndIcon}
          >
            {translations.common.download}
          </ActionButton>
        </Stack>
      </Stack>
    </Box>
  );
};
