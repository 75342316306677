import { ReactElement } from 'react';

import { useBreakpointView, usePrimaryAssetIdentifier } from '@halo-common/hooks';
import { ProductDetailModalLayout } from '@halo-common/layouts';
import {
  PostTradeNoteDetailModalAllocationsTab,
  V2PostTradeNoteDetailModalAnalyticsTab,
  V2PostTradeNoteDetailModalCouponTable,
  V2PostTradeNoteDetailModalDocumentTable,
  V2PostTradeNoteDetailModalEarlyRedemptionTable,
  V2PostTradeNoteDetailModalHeader,
  V2PostTradeNoteDetailModalPanel,
} from '@halo-common/modals';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { useTermsheetNotesQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { usePostTradeProductDetailsDataSwitch } from '@halo-data-sources/switches';

const DETAILS_TAB = translations.common.details;
const DOCUMENTS_TAB = translations.common.documents;
const ANALYTICS_TAB = translations.common.analytics;
const COUPON_SUMMARY_TAB = translations.common.couponSchedule;
const EARLY_REDEMPTION_SUMMARY_TAB = translations.common.earlyRedemption;
const ALLOCATIONS_TAB = translations.common.allocations;

export type V2PostTradeNoteDetailModalLayoutProps = {
  onClose: () => void;
  referenceId?: number;
  loading?: boolean;
  filters?: {
    currencyId?: number;
    accountOption?: AccountTypeAheadOption | null;
  };
};

export const V2PostTradeNoteDetailModalLayout = ({
  onClose,
  referenceId,
  loading = false,
  filters,
}: V2PostTradeNoteDetailModalLayoutProps): ReactElement => {
  const isTabletView = useBreakpointView('md', 'up');

  const productQuery = useTermsheetNotesQuery(referenceId);

  const product = productQuery?.data?.[0] ?? null;
  const termsheetId = product?.id;
  const documents = product?.documents;

  const productDetailQuery = usePostTradeProductDetailsDataSwitch(product, { nme: true, enabled: !loading });
  const { data: user } = useUserInfoQuery();

  const noteAnalytics = productDetailQuery.data?.noteAnalytics;
  const underlyingAnalytics = productDetailQuery.data?.underlyingAnalytics;

  const { identifierText, identifier } = usePrimaryAssetIdentifier(product?.identifiers);

  const currencyCode = product?.currency;
  const whiteLabelCurrencies = user?.whiteLabel.currencies ?? [];
  const productCurrency = whiteLabelCurrencies.find((currency) => currency.code === currencyCode);

  const isLoading = productQuery.isPending || productDetailQuery.isPending || loading;

  const couponEvents = product?.lifecycle?.couponEvents;
  const showCouponTab = couponEvents?.length;

  const autocallEvents = product?.lifecycle?.callEvents;
  const showEarlyRedemptionTab = autocallEvents?.length;

  const tabs = [ANALYTICS_TAB];
  if (!isTabletView) tabs.push(DETAILS_TAB);
  if (showCouponTab) tabs.push(COUPON_SUMMARY_TAB);
  if (showEarlyRedemptionTab) tabs.push(EARLY_REDEMPTION_SUMMARY_TAB);
  tabs.push(DOCUMENTS_TAB);
  tabs.push(ALLOCATIONS_TAB);

  const panel = <V2PostTradeNoteDetailModalPanel product={product} loading={isLoading} />;
  const header = <V2PostTradeNoteDetailModalHeader product={product} loading={isLoading} onClose={onClose} />;
  const slots = { panel, header };

  const detailsTabContents = !isTabletView ? panel : null;

  const couponSummaryTableContent = showCouponTab ? (
    <V2PostTradeNoteDetailModalCouponTable
      loading={isLoading}
      account={filters?.accountOption}
      id={referenceId}
      currency={productCurrency}
    />
  ) : null;

  const earlyRedemptionSummaryTableContent = showEarlyRedemptionTab ? (
    <V2PostTradeNoteDetailModalEarlyRedemptionTable
      loading={isLoading}
      account={filters?.accountOption}
      id={referenceId}
    />
  ) : null;

  return (
    <ProductDetailModalLayout slots={slots} tabs={tabs}>
      <V2PostTradeNoteDetailModalAnalyticsTab
        termsheetId={termsheetId}
        noteAnalytics={noteAnalytics}
        underlyingAnalytics={underlyingAnalytics}
        filters={filters}
        loading={isLoading}
      />
      {detailsTabContents}
      {couponSummaryTableContent}
      {earlyRedemptionSummaryTableContent}
      <V2PostTradeNoteDetailModalDocumentTable
        termsheetId={termsheetId}
        identifierText={identifierText}
        currency={filters?.currencyId}
        account={filters?.accountOption}
        identifier={identifier}
        documents={documents}
      />
      <PostTradeNoteDetailModalAllocationsTab
        identifier={identifier}
        termsheetId={termsheetId}
        currencyCode={productCurrency?.code}
        currencySymbol={productCurrency?.symbol}
      />
    </ProductDetailModalLayout>
  );
};
