import { ReactElement } from 'react';

import { List, Paper, Skeleton, Stack } from '@mui/material';

const loadingContainerSx = {
  padding: 3,
  overflowY: 'auto',
  minWidth: { xs: 343, sm: 'default' },
  width: { xs: '100%', sm: 450 },
};

export const SetPasswordFormSkeleton = (): ReactElement => (
  <Paper variant="outlined" sx={loadingContainerSx}>
    <Stack direction="column" spacing={3}>
      <Stack direction="column" spacing={3}>
        <Skeleton height={72} />
        <Skeleton height={72} />
      </Stack>
      <Stack direction="row">
        <List sx={{ flexBasis: '60%' }} component="nav" disablePadding>
          <Skeleton component="li" width={170} height={36} />
          <Skeleton component="li" width={170} height={36} />
          <Skeleton component="li" width={170} height={36} />
          <Skeleton component="li" width={170} height={36} />
          <Skeleton component="li" width={170} height={36} />
        </List>
        <Skeleton sx={{ flexBasis: '40%' }} width="100%" height={36} />
      </Stack>
      <Stack direction="column" spacing={3}>
        <Skeleton height={60} />
      </Stack>
    </Stack>
  </Paper>
);
