import { MouseEvent, ReactElement } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { OrderBookModalsEnum, orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { DocumentDownloadTable } from '@halo-common/components';
import { UserRoleEnum } from '@halo-common/enums';
import { useCommonUserSetting } from '@halo-common/hooks';
import { useExecutionTermsheetDownloadMutation } from '@halo-data-sources/mutations';
import { useBuyerAuctionQuery, useRecentQuotesQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { AuctionDetailsModalStepper, AuctionDetailsStatus } from '@halo-modules/app';
import { Stack, TabbedModal } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { useAtom } from 'jotai';

import { AuctionDetailsAdminLogs } from './AuctionDetailsAdminLogs';
import { AuctionDetailsAllocationsTab } from './AuctionDetailsAllocationsTab';

const contentSx = (theme: HaloTheme) => ({
  overflow: 'auto',
  borderRadius: theme.spacing(0, 0, 1, 1),
});

export type AuctionDetailsModalProps = {
  dismissible?: boolean;
};

export const AuctionDetailsModal = ({ dismissible = true }: AuctionDetailsModalProps): ReactElement => {
  const showAllocationsTab = useCommonUserSetting('Show_Auction_Allocations_Tab', 'Auction');

  const [modalMap, updateModalMap] = useAtom(modalAtom);
  const [selectedAuctionId, setSelectedAuctionId] = useAtom(orderBookSelectedAuctionIdAtom);

  const auctionId = selectedAuctionId?.toString();

  const { data: userInfo } = useUserInfoQuery();
  const { data: auction } = useBuyerAuctionQuery(auctionId, 10000);

  const noteIds = auction?.noteId ? [auction.noteId] : undefined;

  useRecentQuotesQuery(noteIds);

  const { mutate: downloadTermsheet } = useExecutionTermsheetDownloadMutation();

  const isAdmin = userInfo?.details?.roles?.includes(UserRoleEnum.Admin);

  const modalName = OrderBookModalsEnum.auctionDetails;
  const modalOpen = Boolean(modalMap[modalName]);
  const adminTab = isAdmin ? ['Admin Logs'] : [];
  const allocationsTab = showAllocationsTab ? ['Allocations'] : [];
  const tabs = ['Status', ...allocationsTab, 'Documents', ...adminTab];
  const overline = `Auction ID ${auctionId}`;

  const documents = [];

  const prelimTerms = auction?.prelim;
  if (prelimTerms) {
    documents.push({
      id: `prelim-${prelimTerms.id}`,
      name: 'Preliminary Termsheet',
      onDownload: () =>
        downloadTermsheet({
          type: 'prelim',
          auctionId: selectedAuctionId,
        }),
    });
  }

  const finalTerms = auction?.finalTermsheet;
  if (finalTerms) {
    documents.push({
      id: `final-${finalTerms.id}`,
      name: 'Final Termsheet',
      onDownload: () =>
        downloadTermsheet({
          type: 'final',
          auctionId: selectedAuctionId,
        }),
    });
  }

  const onClose = (_: MouseEvent<HTMLElement>, reason: string) => {
    if (reason !== 'backdropClick') {
      updateModalMap({ [modalName]: false });
      setSelectedAuctionId(undefined);
    }
  };

  const headerProps = {
    AdditionalContent: <AuctionDetailsModalStepper />,
  };

  const contentProps = {
    sx: contentSx,
  };

  const allocationsTabContent = showAllocationsTab ? <AuctionDetailsAllocationsTab /> : null;

  return (
    <TabbedModal
      size="large"
      open={modalOpen}
      onClose={onClose}
      overline={overline}
      tabs={tabs}
      HeaderProps={headerProps}
      ContentProps={contentProps}
      dismissible={dismissible}
    >
      <AuctionDetailsStatus />
      {allocationsTabContent}
      <Stack direction="row" xs={12} sm={10} md={8} justify="center">
        <DocumentDownloadTable documents={documents} />
      </Stack>
      <AuctionDetailsAdminLogs />
    </TabbedModal>
  );
};
