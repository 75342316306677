import { ReactElement, useState } from 'react';

import { UnderlyingPerformanceTable } from '@halo-common/components';
import {
  V2PostTradeNoteDetailModalProps,
  V2PostTradeNoteDetailModalUnderlyingPerformanceChart,
} from '@halo-common/modals';
import { translations } from '@halo-common/translations';
import { PostTradeProductDetailsDataSwitchResult } from '@halo-data-sources/switches';
import { HaloDataGridProps, Stack } from '@halodomination/halo-fe-common';

export type V2PostTradeNoteDetailModalUnderlyingPerformanceProps = {
  termsheetId?: number;
  underlyingAnalytics?: PostTradeProductDetailsDataSwitchResult['underlyingAnalytics'];
  filters: V2PostTradeNoteDetailModalProps['filters'];
  loading: boolean;
};

export const V2PostTradeNoteDetailModalUnderlyingPerformance = ({
  termsheetId,
  underlyingAnalytics,
  filters,
  loading,
}: V2PostTradeNoteDetailModalUnderlyingPerformanceProps): ReactElement => {
  const chart = underlyingAnalytics?.chart;
  const lines = chart?.lines ?? [];
  const data = chart?.data ?? [];
  const references = chart?.references ?? [];
  const rows = underlyingAnalytics?.table?.rows ?? [];

  const [hiddenLines, setHiddenLines] = useState<Array<string>>([]);

  const handleFixedFormat = (value?: number) => (value ? parseFloat(value?.toFixed(2)).toLocaleString() : '-');

  const handleFixedPercentFormat = (value?: number) => (value ? `${value.toFixed(2)}%` : '-');

  const handleLineToggle = (label: string) => {
    setHiddenLines((prev) => (prev.includes(label) ? prev.filter((line) => line !== label) : [...prev, label]));
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      field: 'marketPrice',
      flex: 1,
      headerName: translations.pdm.postTrade.currentLevel,
      valueFormatter: (value) => handleFixedFormat(value),
    },
    {
      field: 'initialFixingLevel',
      flex: 1,
      headerName: translations.pdm.postTrade.initialLevel,
      valueFormatter: (value) => handleFixedFormat(value),
    },
    {
      field: 'performance',
      flex: 1,
      headerName: translations.pdm.postTrade.change,
      valueFormatter: (value) => handleFixedPercentFormat(value),
    },
    {
      field: 'barrierLevel',
      flex: 1,
      headerName: translations.pdm.postTrade.barrierLevel,
      valueFormatter: (value) => handleFixedFormat(value),
    },
    {
      field: 'distanceToBarrier',
      flex: 1,
      headerName: translations.pdm.postTrade.distToBarrier,
      valueFormatter: (value) => handleFixedPercentFormat(value),
    },
  ];

  return (
    <Stack direction="column" spacing={2} xs={12}>
      <UnderlyingPerformanceTable rows={rows} columns={columns} loading={loading} onToggle={handleLineToggle} />
      <V2PostTradeNoteDetailModalUnderlyingPerformanceChart
        termsheetId={termsheetId}
        lines={lines}
        data={data}
        references={references}
        hiddenLines={hiddenLines}
        filters={filters}
        loading={loading}
      />
    </Stack>
  );
};
