import { ReactElement } from 'react';

import { LexicalTypography } from '@halo-common/components';
import { Stack } from '@halodomination/halo-fe-common';

export type EditCalendarModalWholesalerColumnProps = {
  header: string;
  buy?: string;
  reoffer?: string;
};

export const EditCalendarModalWholesalerColumn = ({
  header,
  buy = '-',
  reoffer = '-',
}: EditCalendarModalWholesalerColumnProps): ReactElement => {
  const buyPriceHeader = `${header} BUY PRICE`;
  const reofferHeader = `${header} REOFFER`;

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="column" spacing={1}>
        <LexicalTypography variant="overline" color="text.secondary">
          {buyPriceHeader}
        </LexicalTypography>
        <LexicalTypography>{buy}</LexicalTypography>
      </Stack>
      <Stack direction="column" spacing={1}>
        <LexicalTypography variant="overline" color="text.secondary">
          {reofferHeader}
        </LexicalTypography>
        <LexicalTypography>{reoffer}</LexicalTypography>
      </Stack>
    </Stack>
  );
};
