import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

export type FixedIncomeQueryKeys = {
  all: QueryKeyFactoryFn;
  fixedIncome: QueryKeyFactoryFn;
  fixedIncomeList: QueryKeyFactoryFn;
};

export const FixedIncomeQueryKeyFactory: FixedIncomeQueryKeys = {
  all: () => ['fixed', 'income'],
  fixedIncome: (id) => [...FixedIncomeQueryKeyFactory.all(), id],
  fixedIncomeList: (filters) => [...FixedIncomeQueryKeyFactory.all(), hash(filters), 'all'],
};
