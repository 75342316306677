import { ReactElement, useEffect } from 'react';

import { useCalendarApprovalStatuses } from '@halo-common/hooks';
import { ApproveCalendarOrderLayout } from '@halo-common/layouts';
import { ApproveOrderModalFooter } from '@halo-common/modals';
import { AllocationModel, CalendarModel, FixOrderModel } from '@halo-common/models';
import { ModalBase } from '@halodomination/halo-fe-common';
import { FormProvider, useForm } from 'react-hook-form';

export type ApproveOrderModalProps = {
  allocation?: AllocationModel | null;
  calendar?: CalendarModel | null;
  fixOrder?: FixOrderModel;
  open: boolean;
  loading?: boolean;
  approving?: boolean;
  rejecting?: boolean;
  error?: string;
  statusModifiable?: boolean;
  onClose: () => void;
  onApprove?: (data: ApproveOrderModalFields) => void;
  onReject?: (data: ApproveOrderModalFields) => void;
};

export type ApproveOrderModalFields = {
  reason?: string;
};

// TODO: When Pershing is migrated to react query we need to rework this entire modal
export const ApproveOrderModal = ({
  allocation,
  calendar,
  fixOrder,
  open,
  loading = false,
  approving = false,
  rejecting = false,
  statusModifiable = true,
  error,
  onClose,
  onApprove,
  onReject,
}: ApproveOrderModalProps): ReactElement => {
  const formMethods = useForm<ApproveOrderModalFields>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    defaultValues: {
      reason: allocation?.reason,
    },
  });

  const { isRejected, isDenied, isCanceled, isFilled, isApproved, isExpired } = useCalendarApprovalStatuses(
    allocation?.approved,
    allocation?.status,
    calendar?.expirationDate,
  );

  const finished = isRejected || isDenied || isFilled || isCanceled || isExpired;
  const canApprove = !finished && !isApproved && statusModifiable;
  const canDeny = !finished && statusModifiable;
  const isStatusModifiable = canApprove || canDeny;

  const handleClose = () => {
    onClose();
    formMethods.reset();
  };

  useEffect(() => {
    const shouldUpdateReasonField = open && allocation;
    if (shouldUpdateReasonField) formMethods.setValue('reason', allocation.reason, { shouldDirty: true });
  }, [open, allocation]);

  return (
    <FormProvider {...formMethods}>
      <ModalBase open={open} onClose={handleClose} size="medium">
        <ApproveCalendarOrderLayout
          allocation={allocation}
          calendar={calendar}
          fixOrder={fixOrder}
          showReason={isStatusModifiable}
          onClose={handleClose}
          loading={loading}
          error={error}
          footer={
            <ApproveOrderModalFooter
              approvable={canApprove}
              deniable={canDeny}
              calendar={calendar}
              loading={loading}
              approving={approving}
              rejecting={rejecting}
              onClose={handleClose}
              onApprove={onApprove}
              onReject={onReject}
            />
          }
        />
      </ModalBase>
    </FormProvider>
  );
};
