import { ReactElement } from 'react';

import { LexicalTypography, ProductDetailTable } from '@halo-common/components';
import { SHORT_MONTH_SHORT_DAY_YEAR_FORMAT } from '@halo-common/constants';
import { useTranslatePdmDates } from '@halo-common/hooks';
import { PostTradeNoteDetailModalProps } from '@halo-common/modals';
import { translations } from '@halo-common/translations';
import {
  PostTradeAutocallLifecycleEvent,
  usePostTradeProductDetailsEarlyRedemptionQuery,
} from '@halo-data-sources/queries';
import { GridColDef, GridRenderCellParams, HaloDataGridProps, Iconography } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Stack, SxProps, Typography } from '@mui/material';

const emphasisSx = {
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  width: '100%',
};

const iconSx = {
  marginRight: 1.625,
};

const calledSx = {
  color: 'success.dark',
};

const emptyDataSx = {
  color: 'text.secondary',
};

const statusContainerSx = {
  height: '100%',
};

const commonColumnProps: Partial<GridColDef> = {
  align: 'left',
  flex: 1,
};

export type PostTradeNoteDetailModalEarlyRedemptionTableProps = {
  termsheetId?: number;
  filters?: PostTradeNoteDetailModalProps['filters'];
};

export const PostTradeNoteDetailModalEarlyRedemptionTable = ({
  termsheetId,
  filters,
}: PostTradeNoteDetailModalEarlyRedemptionTableProps): ReactElement => {
  const translateDate = useTranslatePdmDates();

  const { data, isPending } = usePostTradeProductDetailsEarlyRedemptionQuery(termsheetId, filters?.accountOption);

  const rows = data?.events ?? [];
  const height = rows.length >= 16 ? 600 : 'auto';

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonColumnProps,
      field: 'date',
      headerName: translations.common.date,
      valueFormatter: (value: string) => translateDate(value, SHORT_MONTH_SHORT_DAY_YEAR_FORMAT),
    },
    {
      ...commonColumnProps,
      field: 'level',
      headerName: translations.common.level,
      renderCell: ({ value }: GridRenderCellParams<PostTradeAutocallLifecycleEvent, string>) => {
        const parsedValue = value ? `${value}%` : '';

        return (
          <Typography sx={emphasisSx} variant="body2">
            {parsedValue}
          </Typography>
        );
      },
    },
    {
      ...commonColumnProps,
      field: 'return',
      headerName: translations.common.return,
      renderCell: ({ value }: GridRenderCellParams<PostTradeAutocallLifecycleEvent, string>) => {
        const returnValue = value ? parseFloat(value).toFixed(2) : '';
        const parsedValue = returnValue ? `${returnValue}%` : '-';

        const cellStyling: SxProps = [emphasisSx, !value ? emptyDataSx : null];

        return (
          <Typography sx={cellStyling} variant="body2">
            {parsedValue}
          </Typography>
        );
      },
    },
    {
      ...commonColumnProps,
      field: 'status',
      headerName: translations.common.status,
      renderCell: ({ value }: GridRenderCellParams<PostTradeAutocallLifecycleEvent, string>) => {
        const comparatorValue = value?.toLowerCase();
        const isCalled = comparatorValue === 'called';

        const cellStyling: SxProps<HaloTheme> = [calledSx, iconSx];
        const statusStyling = isCalled ? calledSx : emptyDataSx;

        const icon = isCalled ? <Iconography sx={cellStyling} iconName="check" /> : null;

        return (
          <Stack sx={statusContainerSx} direction="row" alignItems="center">
            {icon}
            <LexicalTypography variant="body2" sx={statusStyling} translationKey={value}>
              {value}
            </LexicalTypography>
          </Stack>
        );
      },
    },
  ];

  return (
    <ProductDetailTable<PostTradeAutocallLifecycleEvent>
      height={height}
      columns={columns}
      rows={rows}
      loading={isPending}
      initialState={{
        sorting: {
          sortModel: [{ field: 'date', sort: 'asc' }],
        },
      }}
    />
  );
};
