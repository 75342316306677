import { ReactElement, useState } from 'react';

import { translations } from '@halo-common/translations';
import {
  LocalizedAlert,
  LocalizedButton,
  LocalizedTypography,
  PasswordInput,
  Stack,
} from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, List } from '@mui/material';
import { Result } from 'check-password-strength';
import { useForm } from 'react-hook-form';
import { InferType, boolean as yupBoolean, object as yupObject, string as yupString } from 'yup';

import { PasswordStrengthBar } from './PasswordStrengthBar';
import { PasswordValidateItem } from './PasswordValidateItem';

const alertSx = {
  backgroundColor: 'error.background',
};

const schema = yupObject().shape({
  confirmCurrentPassword: yupBoolean(),
  oldPassword: yupString().when('confirmCurrentPassword', {
    is: true,
    then: (schema) => schema.required('Please enter your old password.'),
  }),
  password: yupString().required('Please enter a password.'),
  confirmPassword: yupString().required('Please enter the password above again.'),
});

export type SetPasswordFormData = {
  oldPassword?: string;
  password: string;
  confirmPassword: string;
};

export type SetPasswordSchema = InferType<typeof schema>;

export type SetPasswordFormProps = {
  onSubmit: (data: SetPasswordFormData) => void;
  error?: boolean;
  errorMessage?: string;
  confirmCurrentPassword?: boolean;
  hasTitle?: boolean;
};

export const SetPasswordForm = ({
  onSubmit,
  error = false,
  hasTitle = false,
  errorMessage = '',
  confirmCurrentPassword = false,
}: SetPasswordFormProps): ReactElement => {
  const [validationResult, setValidationResult] = useState<Result<string> | undefined>();

  const {
    handleSubmit,
    register,
    watch,
    formState: { isSubmitting, errors, isValid },
  } = useForm<SetPasswordSchema>({
    mode: 'onSubmit',
    resolver: yupResolver<SetPasswordSchema>(schema),
    defaultValues: {
      confirmCurrentPassword,
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
  });

  const { ref: oldPasswordRef, ...oldPasswordProps } = register('oldPassword');
  const { ref: passwordRef, ...passwordProps } = register('password');
  const { ref: confirmPasswordRef, ...confirmPasswordProps } = register('confirmPassword');

  const userPassword = watch('password', '');
  const confirmUserPassword = watch('confirmPassword', '');

  const handleValidation = (value: Result<string>) => void setValidationResult(value);
  const onInnerSubmit = ({ oldPassword, password, confirmPassword }: SetPasswordSchema) => {
    onSubmit({ oldPassword, password, confirmPassword });
  };

  const hasMinLength = validationResult && validationResult.length >= 8;
  const hasUppercase = validationResult?.contains.includes('uppercase');
  const hasLowercase = validationResult?.contains.includes('lowercase');
  const hasNumber = validationResult?.contains.includes('number');
  const matchingPasswords = userPassword === confirmUserPassword && userPassword !== '';
  const meetsPasswordCriteria = hasMinLength && hasUppercase && hasLowercase && hasNumber && matchingPasswords;

  const showHasMinLengthCheck = hasMinLength ? 'check' : undefined;
  const showHasUppercaseCheck = hasUppercase ? 'check' : undefined;
  const showHasLowercaseCheck = hasLowercase ? 'check' : undefined;
  const showHasNumberCheck = hasNumber ? 'check' : undefined;
  const showMatchingPasswordsCheck = matchingPasswords ? 'check' : undefined;

  const disabled = isSubmitting || !isValid || !meetsPasswordCriteria;
  const buttonStartIcon = isSubmitting && <CircularProgress size="1rem" />;

  const errorMessageText = errorMessage || translations.profile.password.error;

  const errorAlert = error ? (
    <LocalizedAlert sx={alertSx} severity="error" variant="outlined">
      {errorMessageText}
    </LocalizedAlert>
  ) : null;

  const currentPasswordConfirmation = confirmCurrentPassword ? (
    <PasswordInput
      size="large"
      fullWidth
      label={translations.profile.password.oldPassword}
      inputRef={oldPasswordRef}
      {...oldPasswordProps}
      error={Boolean(errors?.oldPassword)}
      helperText={errors?.oldPassword?.message}
    />
  ) : null;

  const title = hasTitle ? (
    <LocalizedTypography align="center" component="h4" variant="h4">
      {translations.profile.password.setPassword}
    </LocalizedTypography>
  ) : null;

  return (
    <form onSubmit={handleSubmit(onInnerSubmit)}>
      <Stack direction="column" spacing={3}>
        {errorAlert}
        <Stack direction="column" spacing={3}>
          {title}
          {currentPasswordConfirmation}
          <PasswordInput
            size="large"
            fullWidth
            label={translations.profile.password.password}
            inputRef={passwordRef}
            {...passwordProps}
            error={Boolean(errors?.password)}
            helperText={errors?.password?.message}
          />
          <PasswordInput
            size="large"
            fullWidth
            label={translations.profile.password.confirmPassword}
            inputRef={confirmPasswordRef}
            {...confirmPasswordProps}
            error={Boolean(errors?.confirmPassword)}
            helperText={errors?.confirmPassword?.message}
          />
        </Stack>
        <Stack direction="row" xs={[8, 4]}>
          <List component="nav" disablePadding>
            <PasswordValidateItem
              icon={showHasMinLengthCheck}
              description={translations.profile.password.passwordLength}
            />
            <PasswordValidateItem icon={showHasUppercaseCheck} description={translations.profile.password.uppercase} />
            <PasswordValidateItem icon={showHasLowercaseCheck} description={translations.profile.password.lowercase} />
            <PasswordValidateItem icon={showHasNumberCheck} description={translations.profile.password.number} />
            <PasswordValidateItem
              icon={showMatchingPasswordsCheck}
              description={translations.profile.password.matching}
            />
          </List>
          <PasswordStrengthBar password={userPassword} onValidation={handleValidation} />
        </Stack>
        <Stack direction="column" spacing={3}>
          <LocalizedButton
            startIcon={buttonStartIcon}
            disabled={disabled}
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
          >
            {translations.common.finish}
          </LocalizedButton>
        </Stack>
      </Stack>
    </form>
  );
};
