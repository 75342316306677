import { ReactElement, SyntheticEvent, useEffect, useState } from 'react';

import { OrdersSelectors, OrderStatusEnum } from '@halo-stores/Orders';
import { Alert, Snackbar } from '@mui/material';
import { useSelector } from 'react-redux';

const alertSx = {
  width: '100%',
};

export const AuctionAlertSnackbar = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const orderStatus = useSelector(OrdersSelectors.selectStatus);

  const showSnackbar = orderStatus === OrderStatusEnum.successEditOrderAuction;

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason !== 'clickaway') {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (showSnackbar) {
      setOpen(showSnackbar);
    }
  }, [orderStatus]);

  return (
    <Snackbar open={open} autoHideDuration={15000} onClose={handleClose}>
      <Alert severity="success" sx={alertSx}>
        You have successfully edited an auction.
      </Alert>
    </Snackbar>
  );
};
