import { ReactElement } from 'react';

import { HALO_ORGANIZATION_DETAILS } from '@halo-common/constants';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedLink, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Link, Typography } from '@mui/material';
import { DateTime } from 'luxon';

export type HaloFooterProps = {
  hideRelationshipManager?: boolean;
};

export const HaloFooter = ({ hideRelationshipManager }: HaloFooterProps): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();

  const relationshipManager = userInfo?.details.relationshipManager;

  const currentYear = DateTime.now().year;
  const copyrightDateRange = `2016-${currentYear}`;
  const copyrightTextDynamicContent = { daterange: copyrightDateRange, org: HALO_ORGANIZATION_DETAILS.fullName };

  const contactName = relationshipManager?.name ?? HALO_ORGANIZATION_DETAILS.operations.name;
  const contactPhone = relationshipManager?.phone ?? HALO_ORGANIZATION_DETAILS.operations.phone;
  const contactEmail = relationshipManager?.email ?? HALO_ORGANIZATION_DETAILS.operations.email;

  const phoneLink = `tel:${contactPhone.replaceAll(/[(-)+]/g, '')}`;
  const emailLink = `mailto:${contactEmail}`;

  const relationshipManagerContent = !hideRelationshipManager ? (
    <Stack direction="column" spacing={1}>
      <LocalizedTypography variant="overline">{translations.footer.pageFooter.relationshipManager}</LocalizedTypography>
      <Typography variant="h6">{contactName}</Typography>
      <Link href={phoneLink} underline="hover">
        {contactPhone}
      </Link>
      <Link href={emailLink} underline="hover">
        {contactEmail}
      </Link>
    </Stack>
  ) : null;

  return (
    <Stack direction="row" wrap="wrap" sm={12} md={[5, 3, 2]}>
      <Stack direction="column" spacing={1}>
        <img src={HALO_ORGANIZATION_DETAILS.images.logo} />
        <LocalizedTypography variant="caption" dynamicContent={copyrightTextDynamicContent}>
          {translations.footer.pageFooter.copyRightText}
        </LocalizedTypography>
        <LocalizedTypography variant="subtitle1">{translations.footer.pageFooter.insitutionalUse}</LocalizedTypography>
      </Stack>
      <Stack direction="column" spacing={1}>
        <LocalizedTypography variant="overline">{translations.footer.pageFooter.quickLinks}</LocalizedTypography>
        <LocalizedLink href={HALO_ORGANIZATION_DETAILS.documents.privacyPolicy} underline="hover" download>
          {translations.footer.pageFooter.privacyPolicy}
        </LocalizedLink>
        <LocalizedLink href={HALO_ORGANIZATION_DETAILS.documents.termsOfUse} underline="hover" download>
          {translations.footer.pageFooter.termsOfUse}
        </LocalizedLink>
      </Stack>
      {relationshipManagerContent}
    </Stack>
  );
};
