import { createSlice } from '@reduxjs/toolkit';

import { OnboardingState, OnboardingStatusEnum } from './OnboardingModel';
import {
  initiatePasswordReset,
  registerNewUser,
  resendSetPassword,
  setNewUserPassword,
  changeUserPassword,
  getDisclaimers,
  acceptDisclaimer,
  contactHaloMessage,
} from './OnboardingThunks';

const INITIAL_STATE: OnboardingState = {
  emailSent: false,
  passwordSet: false,
  userWhiteListed: false,
  userEmail: '',
  userName: '',
  disclaimers: [],
  acceptedDisclaimers: {},
  status: OnboardingStatusEnum.idle,
};

export const OnboardingDuck = createSlice({
  name: 'Onboarding',
  initialState: INITIAL_STATE,
  reducers: {
    resetStatus(state) {
      state.status = INITIAL_STATE.status;
    },
    reset(state) {
      state.emailSent = INITIAL_STATE.emailSent;
      state.passwordSet = INITIAL_STATE.passwordSet;
      state.userWhiteListed = INITIAL_STATE.userWhiteListed;
      state.disclaimers = INITIAL_STATE.disclaimers;
      state.acceptedDisclaimers = INITIAL_STATE.acceptedDisclaimers;
      state.error = INITIAL_STATE.error;
      state.status = INITIAL_STATE.status;
    },
    updateEmailSent(state, action) {
      state.emailSent = action?.payload.emailSent;
    },
    resetAcceptedDisclaimer(state, action) {
      state.acceptedDisclaimers[action?.payload.disclaimerId] = false;
    },
  },
  extraReducers: (builder) => {
    builder
      /* --- Loading States --- */
      .addCase(contactHaloMessage.pending, (state) => {
        state.status = OnboardingStatusEnum.requesting;
      })
      .addCase(initiatePasswordReset.pending, (state) => {
        state.status = OnboardingStatusEnum.requesting;
      })
      .addCase(resendSetPassword.pending, (state) => {
        state.status = OnboardingStatusEnum.requesting;
      })
      .addCase(setNewUserPassword.pending, (state) => {
        state.status = OnboardingStatusEnum.requesting;
      })
      .addCase(changeUserPassword.pending, (state) => {
        state.status = OnboardingStatusEnum.requesting;
      })
      .addCase(registerNewUser.pending, (state) => {
        state.status = OnboardingStatusEnum.requesting;
      })
      /* --- Failure States --- */
      .addCase(contactHaloMessage.rejected, (state, action) => {
        state.status = OnboardingStatusEnum.failureContactForm;
        state.error = action?.payload?.message;
      })
      .addCase(initiatePasswordReset.rejected, (state, action) => {
        state.status = OnboardingStatusEnum.failurePasswordReset;

        state.error = action?.payload?.status === 403 ? 'SSO ONLY' : action?.payload?.message;
      })
      .addCase(resendSetPassword.rejected, (state, action) => {
        state.status = OnboardingStatusEnum.failureResendSetPassword;

        state.error = action?.payload?.message;
      })
      .addCase(registerNewUser.rejected, (state, action) => {
        state.status = OnboardingStatusEnum.failureRegisterUser;

        state.error = action?.payload?.message;
      })
      .addCase(setNewUserPassword.rejected, (state, action) => {
        state.status = OnboardingStatusEnum.failureSetPassword;

        state.error = action?.payload?.message;
      })
      .addCase(changeUserPassword.rejected, (state, action) => {
        state.status = OnboardingStatusEnum.failureSetPassword;
        state.error = action?.payload?.message;
      })
      .addCase(getDisclaimers.rejected, (state, action) => {
        state.status = OnboardingStatusEnum.failureGetDisclaimers;
        state.error = action?.payload?.message;
      })
      /* --- Success States --- */
      .addCase(contactHaloMessage.fulfilled, (state) => {
        state.status = OnboardingStatusEnum.successContactForm;
        state.error = INITIAL_STATE.error;
      })
      .addCase(resendSetPassword.fulfilled, (state) => {
        state.status = OnboardingStatusEnum.successResendSetPassword;
        state.error = INITIAL_STATE.error;
      })
      .addCase(initiatePasswordReset.fulfilled, (state, action) => {
        state.status = OnboardingStatusEnum.successPasswordReset;
        state.error = INITIAL_STATE.error;

        state.emailSent = action?.payload.emailSent;
        state.userEmail = action?.payload.email;
      })
      .addCase(registerNewUser.fulfilled, (state, action) => {
        state.status = OnboardingStatusEnum.successRegisterUser;
        state.error = INITIAL_STATE.error;

        state.userWhiteListed = action?.payload.whiteListed;
        state.userEmail = action?.payload.email;
        state.userName = action?.payload.name;
      })
      .addCase(setNewUserPassword.fulfilled, (state) => {
        state.status = OnboardingStatusEnum.successSetPassword;
        state.error = INITIAL_STATE.error;

        state.passwordSet = true;
      })
      .addCase(changeUserPassword.fulfilled, (state) => {
        state.status = OnboardingStatusEnum.successSetPassword;
        state.error = INITIAL_STATE.error;

        state.passwordSet = true;
      })
      .addCase(getDisclaimers.fulfilled, (state, action) => {
        state.status = OnboardingStatusEnum.successGetDisclaimers;
        state.error = INITIAL_STATE.error;

        const disclaimers = action?.payload.disclaimers;

        state.disclaimers = disclaimers;
        state.acceptedDisclaimers = disclaimers.reduce(
          (map, disclaimer) => ({
            ...map,
            [disclaimer.id]: false,
          }),
          {},
        );
      })
      .addCase(acceptDisclaimer.fulfilled, (state, action) => {
        state.status = OnboardingStatusEnum.successAcceptDisclaimer;
        state.error = INITIAL_STATE.error;

        state.acceptedDisclaimers[action?.payload.disclaimerId.toString()] = true;
      });
  },
});
