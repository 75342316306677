import { ReactElement } from 'react';

import { UserProfileDetailsSection, UserProfileRelationshipSection } from '@halo-modules/app';
import { Stack } from '@mui/material';

export const UserProfileTab = (): ReactElement => (
  <Stack direction="column" spacing={6}>
    <UserProfileDetailsSection />
    <UserProfileRelationshipSection />
  </Stack>
);
