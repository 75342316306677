import { useMemo } from 'react';

import type { FormBuilderField } from '@halo-common/components';
import { AssetIdentifierEnum } from '@halo-common/enums';
import { useTermsheetRepoConfigQuery, useUserInfoQuery } from '@halo-data-sources/queries';

import { fieldsMap, getIdentifierColumn } from './fields';

export interface useTermsheetFormFieldsReturn {
  fields: FormBuilderField[];
  assetIdentifiers: AssetIdentifierEnum[] | undefined;
  primaryAssetIdentifier: AssetIdentifierEnum | undefined;
  secondaryIdentifiers: AssetIdentifierEnum[];
}

export const useTermsheetFormFields = (): useTermsheetFormFieldsReturn => {
  const { data: userInfo } = useUserInfoQuery();
  const { assetIdentifiers, primaryAssetIdentifier } = userInfo?.whiteLabel || {};

  const secondaryIdentifiers = useMemo(
    () => assetIdentifiers?.filter((identifier) => identifier !== primaryAssetIdentifier) || [],
    [assetIdentifiers, primaryAssetIdentifier],
  );

  const { data: config } = useTermsheetRepoConfigQuery();

  const fields = useMemo(() => {
    const fields: FormBuilderField[] = [fieldsMap.document];

    const columns = config?.columns || [];

    columns.forEach((column) => {
      switch (column.type) {
        case 'primary_asset_identifier':
          if (primaryAssetIdentifier) {
            fields.push(getIdentifierColumn(primaryAssetIdentifier));
          }
          break;

        case 'secondary_asset_identifiers':
          secondaryIdentifiers.forEach((identifier) => {
            fields.push(getIdentifierColumn(identifier));
          });
          break;

        default:
          if (fieldsMap[column.type]) {
            fields.push(fieldsMap[column.type]);
          }
      }
    });

    return fields;
  }, [primaryAssetIdentifier, secondaryIdentifiers, config]);

  return { fields, assetIdentifiers, primaryAssetIdentifier, secondaryIdentifiers };
};
