import { CalendarModel } from '@halo-common/models';
import { getCalendar, getCalendarOfferings } from '@halo-data-sources/clients';
import { ApiCalendarsMapper } from '@halo-data-sources/mappers';
import { CalendarsQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

export type UseCalendarQueryOptions = UseQueryOptions<CalendarModel | null, Error>;

export type UseCalendarQueryResult = UseQueryResult<CalendarModel | null, Error>;

const getCalendarFn = async (id?: number | null) => {
  if (typeof id !== 'number') return null;

  const [details, calendar] = await Promise.all([
    getCalendarOfferings({ calendar_note_id: id }),
    getCalendar(id.toString()),
  ]);

  const isFavorite = details?.calendars?.[0]?.is_favorite;
  const hydratedCalendar = { ...calendar.calendar, is_favorite: isFavorite };

  return ApiCalendarsMapper.toCalendarModel(hydratedCalendar);
};

export const useCalendarQuery = (id?: number | null, options?: UseCalendarQueryOptions): UseCalendarQueryResult =>
  useQuery({
    queryKey: CalendarsQueryKeyFactory.calendar(id),
    queryFn: () => getCalendarFn(id),
    enabled: typeof id === 'number',
    ...options,
  });
