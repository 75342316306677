import { ReactElement } from 'react';

import { modalAtom } from '@halo-atoms/common';
import {
  fixedIncomeAllocationsModalTabAtom,
  fixedIncomeInventoryTotalAtom,
  fixedIncomeSelectedAtom,
} from '@halo-atoms/fixedIncome';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { CalendarOrderStatusEnum } from '@halo-common/enums';
import { usePrimaryAssetIdentifier, useProductProgress, useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { AllocationModal, AllocationModalFormModel, AllocationModalProps } from '@halo-common/modals';
import { fixedIncomeAllocationSchema, fixedIncomeAttestationSchema } from '@halo-common/schemas';
import {
  FixedIncomeAllocationModel,
  useEditFixedIncomeAllocationMutation,
  useFixedIncomeAllocationMutation,
  useRecordActivityMutation,
} from '@halo-data-sources/mutations';
import { useFixedIncomeCalendarQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { FIXED_INCOME_DETAIL_MODAL_NAME } from '@halo-modules/app';
import { HaloDataGridProps } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtom, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';

const EDITABLE_STATES = ['NEW', 'PENDING'];

export const FIXED_INCOME_ALLOCATION_MODAL_NAME = 'fixedIncomeAllocation';

export const FixedIncomeAllocationsModal = (): ReactElement => {
  const configureDateTime = useWhiteLabelDateConfiguration();

  const [tab, setTab] = useAtom(fixedIncomeAllocationsModalTabAtom);
  const [modalMap, setModalMap] = useAtom(modalAtom);
  const [selectedFixedIncome, setSelectedFixedIncome] = useAtom(fixedIncomeSelectedAtom);
  const setFixedIncomeInventoryTotal = useSetAtom(fixedIncomeInventoryTotalAtom);

  const { data: user } = useUserInfoQuery();
  const { data: fixedIncomeCalendar, isPending: allocationsLoading } = useFixedIncomeCalendarQuery(selectedFixedIncome);

  const { mutate: editAllocation, isPending: editLoading } = useEditFixedIncomeAllocationMutation();
  const { mutate: submitAllocation, isPending: submitLoading, isSuccess } = useFixedIncomeAllocationMutation();
  const { mutate: recordActivity } = useRecordActivityMutation();

  const { asPath: url } = useRouter();

  const organization = user?.organization;

  const pageId = Number(organization?.calendarPageId);

  const requiresAttestation = organization?.requiresAttestation;
  const schema = requiresAttestation ? fixedIncomeAttestationSchema : fixedIncomeAllocationSchema;
  const validation = yupResolver<AllocationModalFormModel>(schema);

  const { identifier } = usePrimaryAssetIdentifier(fixedIncomeCalendar);

  const title = `Submit Indication ${identifier}`;

  let allocationModalStatus: AllocationModalProps['status'] = 'idle';
  if (allocationsLoading) allocationModalStatus = 'loading';
  else if (isSuccess) allocationModalStatus = 'success';

  const id = fixedIncomeCalendar?.id;
  const subtitle = fixedIncomeCalendar?.displayName;
  const expirationDate = fixedIncomeCalendar?.expirationDate;

  const { status } = useProductProgress({ calendar: fixedIncomeCalendar, isFixedIncome: true });

  const isClosed = status === CalendarOrderStatusEnum.Closed;
  const formDisabled = isClosed || fixedIncomeCalendar?.paused;
  const hidePrimaryAction = isClosed || tab === 1;

  const checkboxLabel = requiresAttestation ? 'I have delivered the prospectus to this client' : undefined;

  const formattedDateVerb = isClosed ? 'Closed' : 'Closes';
  const closingDateText = configureDateTime(expirationDate)?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
  const closingDate = closingDateText ? `${formattedDateVerb} ${closingDateText}` : '';

  const hasInventory = Boolean(fixedIncomeCalendar?.inventoryTotal);
  const inventoryRemaining = hasInventory ? fixedIncomeCalendar?.inventoryRemaining : undefined;
  const rules = { inventoryRemaining };

  const noInventoryRemaining =
    typeof fixedIncomeCalendar?.inventoryRemaining === 'number' ? fixedIncomeCalendar.inventoryRemaining <= 0 : false;

  const allocations = fixedIncomeCalendar?.allocations ?? [];

  const mappedAllocations = allocations?.map((allocation) => {
    const disableCancel = allocation.status === 'accepted';
    return { ...allocation, disableCancel };
  });

  const handleCancelAllocation = (id: number) => {
    editAllocation({ id, status: 'canceled' });
  };

  const handleEditAllocation: HaloDataGridProps['onCellEditStop'] = (params) => {
    const canEditCell = EDITABLE_STATES.includes(params.row.status.toUpperCase());

    if (canEditCell) {
      const cellValue = Number(params.row[params.field].split(',').join(''));
      editAllocation({ id: Number(params.id), status: 'pending', amount: cellValue });
    }
  };

  const handleSubmit = (data: AllocationModalFormModel) => {
    if (url.includes('portfolio')) {
      recordActivity({
        activity: [{ verb: 'submitted allocation via calendar reinvestment', model_name: 'Lifecycle' }],
        fromIFrame: false,
      });
    }

    const formattedAllocations: Array<FixedIncomeAllocationModel> = data.allocations.map((allocation) => ({
      account: allocation.accountOption?.account?.id,
      quantity: Number(allocation.amount),
      attestation: Boolean(allocation.checkbox),
    }));

    const totalNotional = formattedAllocations.reduce((total, { quantity }) => total + Number(quantity), 0);

    setFixedIncomeInventoryTotal(totalNotional);
    submitAllocation({ calendarId: id, calendarPageId: pageId, allocations: formattedAllocations });
  };

  const handleClose = () => {
    setModalMap({ [FIXED_INCOME_ALLOCATION_MODAL_NAME]: false });
    setTab(0);

    if (!modalMap[FIXED_INCOME_DETAIL_MODAL_NAME]) setSelectedFixedIncome(null);
  };

  return (
    <AllocationModal
      disableEdits
      disableSubmit={noInventoryRemaining}
      title={title}
      subtitle={subtitle}
      message="Allocations must be submitted before they can be processed."
      open={modalMap[FIXED_INCOME_ALLOCATION_MODAL_NAME]}
      allocations={mappedAllocations}
      status={allocationModalStatus}
      hideForm={formDisabled}
      hidePrimaryAction={hidePrimaryAction}
      validation={validation}
      onClose={handleClose}
      onSubmit={handleSubmit}
      onCancelAllocation={handleCancelAllocation}
      onEditAllocation={handleEditAllocation}
      TableProps={{ cancelling: editLoading }}
      TabsProps={{ value: tab, onChange: setTab }}
      FormProps={{ checkboxLabel, rules }}
      FooterProps={{ children: closingDate, loading: submitLoading }}
    />
  );
};
