import { EventModel, EventQueueModel, EventQueueResult, useEventQueue } from '@halo-common/hooks';
import { atom } from 'jotai';

const SOCKET_NAMESPACE = 'quote';
const COMPARISON_KEY = 'note_id';

type QuoteComparisonType = QuoteWebsocketEvent['note_id'];

// TODO: I believe the event will need to be extended to include any potential errors
export type QuoteWebsocketEvent = { note_id: number };
export type QuoteWebsocketEventModel = EventModel<QuoteWebsocketEvent, QuoteComparisonType>;
export type WebsocketQuoteEventQueue = EventQueueModel<QuoteWebsocketEvent, QuoteComparisonType>;

const quoteQueueAtom = atom<Array<QuoteWebsocketEventModel>>([]);

export const useQuoteEventQueue = (): EventQueueResult<QuoteWebsocketEvent, QuoteComparisonType> => {
  return useEventQueue<QuoteWebsocketEvent, QuoteComparisonType>(SOCKET_NAMESPACE, COMPARISON_KEY, quoteQueueAtom);
};
