import { HTMLAttributes, ReactElement } from 'react';

import { Box } from '@mui/material';

export type AccountTypeAheadListItemProps = HTMLAttributes<HTMLLIElement> & {
  label: string;
  selected: boolean;
};

export const AccountTypeAheadListItem = ({
  label,
  selected,
  ...props
}: AccountTypeAheadListItemProps): ReactElement => {
  const optionContainerSx = { backgroundColor: selected ? 'primary.background' : undefined };
  const optionSx = { flexGrow: 1, color: selected ? 'primary.main' : 'inherit', wordBreak: 'break-word' };

  return (
    <Box component="li" {...props} sx={optionContainerSx}>
      <Box sx={optionSx}>{label}</Box>
    </Box>
  );
};
