import { ReactElement } from 'react';

import { ActionButton } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { LocalizedButton, Stack } from '@halodomination/halo-fe-common';

const footerSx = {
  width: '100%',
};

export type AddAccountSimpleFooterProps = {
  onSubmit: () => void;
  onClose: () => void;
  loading?: boolean;
};

export const AddAccountSimpleFooter = ({ onSubmit, onClose, loading }: AddAccountSimpleFooterProps): ReactElement => (
  <Stack direction="row" spacing={2} justify="right" alignItems="center" sx={footerSx}>
    <LocalizedButton onClick={onClose} variant="outlined">
      {translations.common.close}
    </LocalizedButton>
    <ActionButton onClick={onSubmit} type="submit" variant="contained" color="primary" loading={loading}>
      {translations.common.create}
    </ActionButton>
  </Stack>
);
