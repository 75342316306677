import { ReactElement } from 'react';

import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { OrderBookJanneyStatusFilterEnum } from '@halo-common/enums';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useAtom } from 'jotai';

export const JanneyStatusField = (): ReactElement => {
  const labelString = 'Status';

  const [searchFields, setSearchFields] = useAtom(orderBookQueryAtom);

  const handleStatusChange = (ev: SelectChangeEvent<string>) => {
    const value = ev.target.value;

    setSearchFields({
      filters: {
        status: value,
      },
    });
  };

  return (
    <FormControl fullWidth>
      <InputLabel shrink>{labelString}</InputLabel>
      <Select
        label={labelString}
        size="large"
        value={searchFields.filters.status}
        onChange={handleStatusChange}
        displayEmpty
        notched
      >
        <MenuItem value="">All</MenuItem>
        {Object.entries(OrderBookJanneyStatusFilterEnum).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
