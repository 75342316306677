import { ExecutionHubTraceEnum } from '@halo-common/enums';
import { postFillAllExecCalendarOrder } from '@halo-data-sources/clients';
import { ApiFillExecOrdersRequestModel } from '@halo-data-sources/models';
import { ExecutionHubOrder } from '@halo-data-sources/queries';
import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';

export type FillAllExecCalendarOrdersMutationPayload = {
  calendarId: number;
  trace: string;
  confirmNote: string;
  orders: Array<ExecutionHubOrder>;
};

export type FillAllExecCalendarOrdersMutationResultModel = {
  calendarId: number;
  fillOrderPairings: Array<[number, number]>;
  message: string;
};

export type FillAllExecCalendarOrdersMutationOptions = UseMutationOptions<
  FillAllExecCalendarOrdersMutationResultModel,
  Error,
  FillAllExecCalendarOrdersMutationPayload
>;

export type FillAllExecCalendarOrdersMutationResult = UseMutationResult<
  FillAllExecCalendarOrdersMutationResultModel,
  Error,
  FillAllExecCalendarOrdersMutationPayload
>;

const fillAllExecCalendarOrdersMutationFn = async (payload: FillAllExecCalendarOrdersMutationPayload) => {
  const execOrderIds = payload.orders.map(({ executionOrder }) => executionOrder.id);
  const custom: ApiFillExecOrdersRequestModel['custom'] = { confirm_note: payload.confirmNote };

  if ((payload.trace as ExecutionHubTraceEnum) !== ExecutionHubTraceEnum['No Trace']) custom.trace = payload.trace;

  const response = await postFillAllExecCalendarOrder({
    calendar_id: payload.calendarId,
    custom,
    exec_order_ids: execOrderIds,
  });

  return {
    calendarId: payload.calendarId,
    fillOrderPairings: response.fill_order_tuples,
    message: 'Successfully submitted request to fill all indications',
  };
};

export const useFillAllExecCalendarOrdersMutation = (
  options: FillAllExecCalendarOrdersMutationOptions,
): FillAllExecCalendarOrdersMutationResult =>
  useMutation<FillAllExecCalendarOrdersMutationResultModel, Error, FillAllExecCalendarOrdersMutationPayload>({
    mutationFn: fillAllExecCalendarOrdersMutationFn,
    ...options,
  });
