import { useEffect, useRef } from 'react';

import { quoteValueMapAtom } from '@halo-atoms/quotes';
import { NoteParameterEnum, NoteTypeEnum } from '@halo-common/enums';
import { NoteModel } from '@halo-common/models';
import { roundFloatToSpecificDecimal } from '@halo-common/utils';
import { getRollingReturnsData } from '@halo-data-sources/clients';
import { HttpError } from '@halo-data-sources/errors';
import { RollingReturnsQueryKeyFactory } from '@halo-data-sources/queries';
import { LineChartProps } from '@halodomination/halo-fe-common';
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';

const DEFAULT_FILTERS = [1, 3, 5, 10, 20, 30];

const UNDERLYING_RETURN_LINE_NAME = 'Underlying Basket Return';
const INCOME_NOTE_RETURN_LINE_NAME = 'Note Annualized Yield';
const NOTE_RETURN_LINE_NAME = 'Note Return';
const BREACH_LEVEL_REF_NAME = 'Breach Level';
const MAX_RETURN_REF_NAME = 'Max Return';

type RollingReturnsChartQueryDateMapping = {
  points: Array<{
    x: number;
    [UNDERLYING_RETURN_LINE_NAME]: number | null;
    [INCOME_NOTE_RETURN_LINE_NAME]?: number | null;
    [NOTE_RETURN_LINE_NAME]?: number | null;
  }>;
  maturityDates: Record<number, number | null>;
  lifeTimeMessages: Record<number, string | null>;
  totalNoteReturns: Record<number, number | null>;
};

export type RollingReturnsChartQueryPayload = {
  maxReturn?: number | null;
  protection?: number | null;
  type?: NoteModel['type'];
  isIssuerCall?: boolean;
};

export type RollingReturnsChartQueryResult = {
  chart: {
    data: RollingReturnsChartQueryDateMapping['points'];
    lines: LineChartProps['lines'];
    totalNoteReturns: RollingReturnsChartQueryDateMapping['totalNoteReturns'];
    lifeTimeMessages: RollingReturnsChartQueryDateMapping['lifeTimeMessages'];
    maturityDates: RollingReturnsChartQueryDateMapping['maturityDates'];
    references: LineChartProps['referenceLines'];
  };
  filters: {
    yearsSinceCreated: number;
    values: Array<number>;
    names: Array<string>;
    maxDate: DateTime;
    minDate: DateTime;
  };
};

const getRollingReturnsChartQueryFn = async (
  id?: number,
  quoteId?: number,
  payload?: RollingReturnsChartQueryPayload,
) => {
  if (!id) return null;

  const now = DateTime.local();

  const maxReturn = payload?.maxReturn;
  const protection = payload?.protection;
  const productType = payload?.type;
  const isIssuerCall = payload?.isIssuerCall;

  const dateRange = {
    end_date: now.toISODate(),
    start_date: now.minus({ years: 30 }).toISODate(),
  };

  const response = await getRollingReturnsData({ quote_id: quoteId, date_range: dateRange, note_id: id });

  if (response.issue_dates === undefined) {
    throw new HttpError(400, `There is no historical market data available for this underlying.`);
  } else if (!response.issue_dates.length) {
    const message = `Market data not available for the selected term of this Note. The selected term may be too long to show data.`;
    throw new HttpError(400, message);
  }

  const { issue_dates, notereturns, values, effective_annualized_yield, effective_maturity_dates, maturity_dates } =
    response;

  const isIncome = productType === NoteTypeEnum.Income;
  const hasMaxReturn = typeof maxReturn === 'number';
  const hasProtection = typeof protection === 'number' && protection !== 100;
  const showNoteReturnLine = !isIncome && notereturns?.length;
  const showAnnualizedYieldLine = isIncome && effective_annualized_yield !== null && !isIssuerCall;

  const chartData: RollingReturnsChartQueryDateMapping = {
    points: [],
    maturityDates: {},
    totalNoteReturns: [],
    lifeTimeMessages: {},
  };

  issue_dates.forEach((date, index) => {
    const maturityDateValue = maturity_dates[index];
    const effectiveMaturityDateValue = effective_maturity_dates[index];
    const annualizedYieldValue = effective_annualized_yield?.[index];

    const hasNoteReturnValue = typeof notereturns?.[index] === 'number';
    const hasAnnualizedYieldValue = typeof annualizedYieldValue === 'number';
    const hasMaturityDateTime = typeof maturityDateValue === 'string';
    const hasEffectiveMaturityDateTime = typeof effectiveMaturityDateValue === 'string';

    const issueDateTime = DateTime.fromISO(date);
    const maturityDateTime = hasMaturityDateTime ? DateTime.fromISO(maturityDateValue) : null;
    const effectiveMaturityDateTime = hasEffectiveMaturityDateTime
      ? DateTime.fromISO(effectiveMaturityDateValue)
      : null;

    const xValue = issueDateTime.toSeconds();
    const maturitySeconds = maturityDateTime?.toSeconds() ?? null;
    const effectiveMaturitySeconds = effectiveMaturityDateTime?.toSeconds() ?? null;

    const hasGeneralIncomeContent = isIncome && notereturns?.length;
    const hasAnnualizedYieldContent = showAnnualizedYieldLine && hasAnnualizedYieldValue;

    const underlyingReturn = roundFloatToSpecificDecimal(values[index] * 100);
    const noteReturn = hasNoteReturnValue ? roundFloatToSpecificDecimal(notereturns[index] * 100) : null;
    const incomeNoteReturn = hasAnnualizedYieldContent ? roundFloatToSpecificDecimal(annualizedYieldValue * 100) : null;

    chartData.totalNoteReturns[xValue] = noteReturn;
    if (effectiveMaturitySeconds) chartData.maturityDates[xValue] = effectiveMaturitySeconds;

    if (hasGeneralIncomeContent) {
      const months = effectiveMaturityDateTime?.diff(issueDateTime, 'months').toObject()?.months;
      const isCalled = maturitySeconds !== effectiveMaturitySeconds;
      const monthText = `At ${months} ${months === 1 ? 'Month' : 'Months'}`;
      if (isCalled) chartData.lifeTimeMessages[xValue] = `Note Called ${monthText}`;
      else chartData.lifeTimeMessages[xValue] = `Note Matured ${monthText}`;
    }

    chartData.points.push({
      x: xValue,
      [UNDERLYING_RETURN_LINE_NAME]: underlyingReturn,
      [NOTE_RETURN_LINE_NAME]: noteReturn,
      [INCOME_NOTE_RETURN_LINE_NAME]: incomeNoteReturn,
    });
  });

  const firstDate = issue_dates[0];
  const oldestDate = firstDate ? DateTime.fromISO(firstDate) : now;

  const lastDate = issue_dates[issue_dates.length - 1];
  const newestDate = lastDate ? DateTime.fromISO(lastDate) : now;

  const { years: yearsSinceCreated } = newestDate.diff(oldestDate, 'years');

  const filteredValues = DEFAULT_FILTERS.filter((years) => yearsSinceCreated - years > 0);
  const filtersByName = filteredValues.map((tab) => `${tab}y`);
  const filterNames = [...filtersByName, 'Custom'];

  const lines: LineChartProps['lines'] = [{ label: UNDERLYING_RETURN_LINE_NAME }];
  if (showAnnualizedYieldLine) lines.push({ label: INCOME_NOTE_RETURN_LINE_NAME });
  else if (showNoteReturnLine) lines.push({ label: NOTE_RETURN_LINE_NAME });

  const references: LineChartProps['referenceLines'] = [];
  if (hasProtection) references?.push({ point: -protection, label: BREACH_LEVEL_REF_NAME, orientation: 'y' });
  if (hasMaxReturn) references?.push({ point: maxReturn, label: MAX_RETURN_REF_NAME, type: 'dash', orientation: 'y' });

  return {
    chart: {
      data: chartData.points,
      lifeTimeMessages: chartData.lifeTimeMessages,
      totalNoteReturns: chartData.totalNoteReturns,
      maturityDates: chartData.maturityDates,
      lines,
      references,
    },
    filters: {
      yearsSinceCreated,
      names: filterNames,
      values: filteredValues,
      minDate: oldestDate,
      maxDate: newestDate,
    },
  };
};

export const useRollingReturnsChartQuery = (
  product?: NoteModel | null,
  options?: Partial<UseQueryOptions<RollingReturnsChartQueryResult | null, Error>>,
): UseQueryResult<RollingReturnsChartQueryResult | null, Error> => {
  const quoteIdRef = useRef<number | undefined>();

  const productId = product?.id;
  const productType = product?.type;
  const isIssuerCall = product?.callInformation?.isIssuerCall;
  const protection = product?.protectionPercent;

  const quoteValueMap = useAtomValue(quoteValueMapAtom);
  const quoteDetails = productId ? quoteValueMap[productId] : undefined;

  const isInitialQuote = quoteDetails && !quoteDetails.updated;
  const quote = isInitialQuote ? quoteDetails.recent : undefined;
  const quoteId = isInitialQuote ? quote?.id : quoteIdRef.current;

  const isSolvingForMaxReturn = quote && product?.parameter === NoteParameterEnum.MaxReturn;
  const maxReturn = isSolvingForMaxReturn ? quote.value : product?.maxReturnPercent;

  const payload = { type: productType, maxReturn, protection, isIssuerCall };

  useEffect(() => {
    if (typeof quoteId === 'number') quoteIdRef.current = quoteId;
  }, [quoteId]);

  return useQuery<RollingReturnsChartQueryResult | null, Error>({
    queryKey: RollingReturnsQueryKeyFactory.chart(productId, quoteId),
    queryFn: () => getRollingReturnsChartQueryFn(productId, quoteId, payload),
    retry: false,
    ...options,
  });
};
