import { useEffect, useMemo } from 'react';

import { debounce } from '@mui/material';

import { useCallbackRef } from '../useCallbackRef';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (...args: any[]) => any;

/**
 * Custom hook that returns a debounced version of the provided callback function.
 * The debounced function will delay the execution of the callback until after
 * the specified wait time has elapsed since the last time it was invoked.
 *
 * @param callback - The callback function to debounce.
 * @param wait - The number of milliseconds to delay. Defaults to 500ms.
 * @returns The debounced version of the callback function.
 *
 * @example
 * const debouncedFunction = useDebounced(myFunction, 300);
 * debouncedFunction(); // Will execute myFunction after 300ms if not called again within that time.
 */
export const useDebounced = <T extends AnyFunction>(callback: T | undefined, wait = 500): T => {
  const callbackRef = useCallbackRef(callback);

  const debouncedCallback = useMemo(() => debounce(callbackRef, wait), [callbackRef, wait]);

  useEffect(() => {
    return () => {
      debouncedCallback.clear();
    };
  }, [debouncedCallback]);

  return debouncedCallback;
};
