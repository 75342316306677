import { ReactElement } from 'react';

import { Modal, ModalLayoutProps } from '@halodomination/halo-fe-common';

export type AuctionTableModalProps = ModalLayoutProps & {
  organizationName?: string;
  buyer?: string;
  auctionId?: number;
  open: boolean;
};

export const AuctionTableModal = ({
  title,
  subtitle,
  children,
  open,
  organizationName,
  buyer,
  auctionId,
  onClose,
  footer,
  HeaderProps,
}: AuctionTableModalProps): ReactElement => {
  const overline = `${organizationName ?? ''} • ${buyer ?? ''} • ${auctionId ?? ''}`;

  const footerProps = { sx: { display: 'flex', justifyContent: 'flex-end' } };
  const headerProps = { AdditionalContent: HeaderProps?.AdditionalContent };

  return (
    <Modal
      overline={overline}
      title={title}
      subtitle={subtitle}
      open={open}
      onClose={onClose}
      footer={footer}
      FooterProps={footerProps}
      HeaderProps={headerProps}
    >
      {children}
    </Modal>
  );
};
