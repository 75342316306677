import { ReactElement } from 'react';

import { executionHubFillingMapAtom, executionHubFillModalAtom } from '@halo-atoms/executionHub';
import { ActionButton } from '@halo-common/components';
import { ExecutionHubTraceEnum, NoteProductTypeEnum } from '@halo-common/enums';
import { usePrimaryAssetIdentifier } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { useFillAllExecCalendarOrdersMutation } from '@halo-data-sources/mutations';
import { useCalendarNameQuery, useCalendarOrderPreview } from '@halo-data-sources/queries';
import { useEHOrderManager } from '@halo-modules/admin';
import {
  HaloDataGrid,
  HaloDataGridProps,
  Iconography,
  LocalizedAlert,
  Modal,
  Stack,
} from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';

import { V2ExecutionHubFillModalConfirmNote } from './V2ExecutionHubFillModalConfirmNote';
import { V2ExecutionHubTraceDropdown } from './V2ExecutionHubTraceDropdown';

export const V2ExecutionHubFillModal = (): ReactElement => {
  const setFillingMap = useSetAtom(executionHubFillingMapAtom);
  const [modalState, setModalState] = useAtom(executionHubFillModalAtom);

  const calendar = modalState.calendar;
  const calendarId = calendar?.id;
  const calendarName = calendar?.displayName;
  const calendarQuoteValue = calendar?.quote?.value;
  const isMLCD = calendar?.note?.productType === NoteProductTypeEnum.MLCD;

  const orders = modalState.orders;

  const manageOrders = useEHOrderManager();

  const { identifierText } = usePrimaryAssetIdentifier(calendar);

  const { data: confirmNote } = useCalendarNameQuery(calendarId);
  const { data: orderPreview, isPending: loadingPreview } = useCalendarOrderPreview({ calendarId, orders });

  const rows = orderPreview ?? [];
  const defaultTrace = isMLCD ? ExecutionHubTraceEnum['No Trace'] : ExecutionHubTraceEnum.P1;
  const defaultNote = !confirmNote ? calendarName : calendarQuoteValue?.toString();

  const formMethods = useForm({ defaultValues: { note: defaultNote, trace: defaultTrace } });

  const {
    mutate: onFillAll,
    isPending: fillingOrders,
    isError,
    ...fillMutation
  } = useFillAllExecCalendarOrdersMutation({
    onSuccess: (_, payload) => {
      const ordersToEnqueue =
        modalState?.orders?.filter((order) => {
          const status = order.executionOrder.status;
          return status === 'pending';
        }) ?? [];

      manageOrders(ordersToEnqueue);

      setFillingMap((prev) => ({ ...prev, [payload.calendarId]: true }));

      setModalState({ open: false });
    },
  });

  const columns: HaloDataGridProps['columns'] = [
    {
      flex: 1,
      field: 'accountType',
      headerName: 'account type',
      translateCell: true,
    },
    {
      flex: 1.25,
      field: 'inventoryAccount',
      headerName: 'inventory account',
    },
    {
      flex: 1,
      field: 'issuerReoffer',
      headerName: 'issuer reoffer',
    },
    {
      flex: 0.75,
      field: 'quantity',
      headerName: 'quantity',
    },
  ];

  const handleClose = () => {
    setModalState({ open: false });
    formMethods.reset();
    fillMutation.reset();
  };

  const handleFillAll = formMethods.handleSubmit((data) => {
    const finalNote = confirmNote ?? formMethods.getValues('note');
    const hasData = calendarId && finalNote && orders?.length;

    if (hasData) onFillAll({ calendarId, trace: data.trace, confirmNote: finalNote, orders });
  });

  const errorMessage = isError ? (
    <LocalizedAlert variant="outlined" severity="error">
      {translations.executionHub.fillModal.errorMessage}
    </LocalizedAlert>
  ) : null;

  return (
    <FormProvider {...formMethods}>
      <Modal
        title="Fill All Indications"
        open={modalState.open}
        overline={identifierText}
        size="small"
        onClose={handleClose}
        icon="list-check"
        footer={
          <Stack direction="row" justify="space-between" alignItems="center">
            <Button
              variant="text"
              color="primary"
              startIcon={<Iconography iconName="arrow-left" />}
              onClick={handleClose}
            >
              Go Back
            </Button>
            <ActionButton color="primary" loading={fillingOrders} onClick={handleFillAll}>
              Fill All
            </ActionButton>
          </Stack>
        }
      >
        <Stack direction="column" spacing={2}>
          {errorMessage}
          <V2ExecutionHubFillModalConfirmNote />
          <HaloDataGrid columns={columns} rows={rows} density="compact" loading={loadingPreview} />
          <V2ExecutionHubTraceDropdown />
        </Stack>
      </Modal>
    </FormProvider>
  );
};
