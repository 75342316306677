import { ReactElement } from 'react';

import { ActionButton, ProductNoteTypeChip } from '@halo-common/components';
import { AssetIdentifierEnum, DynamicTypeFiltersSideBarFiltersEnum } from '@halo-common/enums';
import { useCurrencyById, useWhiteLabelDateFormat } from '@halo-common/hooks';
import type { TermsheetRepoDocModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { ApiAssetIdentifierEnum } from '@halo-data-sources/enums';
import { usePreviewS3DocumentMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { EditTermsheetModal, useIsDocumentRepositoryManager } from '@halo-modules/app/documents/repository';
import type { HaloDataGridProps } from '@halodomination/halo-fe-common';
import { Iconography, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';

type Inner<T extends Array<unknown>> = T extends Array<infer U> ? U : never;

type HaloDataGridColDef = Inner<HaloDataGridProps['columns']>;

export type TermsheetRepoColumnModel = HaloDataGridColDef & {
  id: string;
  filter?: DynamicTypeFiltersSideBarFiltersEnum;
};

const GetCurrencyCode = ({ row }: { row: TermsheetRepoDocModel }): ReactElement | null => {
  const { currencyId } = row;
  const currency = useCurrencyById(currencyId);
  const currencyCode = currency?.code;
  return <>{currencyCode ?? currencyId}</>;
};

const GetNotional = ({ row }: { row: TermsheetRepoDocModel }): ReactElement | null => {
  const { notional, currencyId } = row;
  const currency = useCurrencyById(currencyId);
  const currencyCode = currency?.code;
  const formattedNotional = useCurrencyConverter(notional, { currencyCode });
  return <>{formattedNotional}</>;
};

const GetIssuerName = ({ row }: { row: TermsheetRepoDocModel }): ReactElement | null => {
  const { data: userInfo } = useUserInfoQuery();
  const issuerId = row?.issuerId;
  const issuers = userInfo?.whiteLabel?.issuers || [];
  const issuer = issuers?.find((issuer) => issuer.id === issuerId);
  const issuerName = issuer?.name;
  return <>{issuerName ?? issuerId}</>;
};

const FormatDate = ({ date }: { date: string }): ReactElement | null => {
  const whiteLabelDateFormat = useWhiteLabelDateFormat();
  if (!date) return null;
  return <>{DateTime.fromISO(date).toFormat(whiteLabelDateFormat)}</>;
};

const commonCellProps = {
  editable: false,
  disableColumnMenu: true,
  disableReorder: true,
  flex: 1,
  minWidth: 160,
};

export const getIdentifierColumn = (type: AssetIdentifierEnum): TermsheetRepoColumnModel => {
  const id = ApiAssetIdentifierEnum[type].toLowerCase();

  return {
    ...commonCellProps,
    id,
    field: `asset_identifiers.${id}`,
    headerName: type,
    renderCell: ({ row }: { row: TermsheetRepoDocModel }) => {
      const assetIdentifiers = row.assetIdentifiers || [];
      const identifier = assetIdentifiers.find(
        (identifier) => identifier.type.toLowerCase() === type.toLowerCase(),
      )?.value;
      return <>{identifier ?? null}</>;
    },
  };
};

const ProductTypeColumn: TermsheetRepoColumnModel = {
  ...commonCellProps,
  id: 'termsheet_repo_docs.product_type',
  field: 'productType',
  headerName: translations.documents.termsheets.productType,
  renderCell: ({ row }) => <ProductNoteTypeChip type={row.productType} subtype={row.productType} variant="filled" />,
  filter: DynamicTypeFiltersSideBarFiltersEnum.termsheetsProductType,
};

const NotionalColumn: TermsheetRepoColumnModel = {
  ...commonCellProps,
  id: 'termsheet_repo_docs.notional',
  field: 'notional',
  headerName: translations.documents.termsheets.notional,
  renderCell: ({ row }) => <GetNotional row={row} />,
};

const CurrencyIdColumn: TermsheetRepoColumnModel = {
  ...commonCellProps,
  id: 'termsheet_repo_docs.currency_id',
  field: 'currencyId',
  headerName: translations.documents.termsheets.currency,
  renderCell: ({ row }) => <GetCurrencyCode row={row} />,
  filter: DynamicTypeFiltersSideBarFiltersEnum.currency,
};

const IssuerIdColumn: TermsheetRepoColumnModel = {
  ...commonCellProps,
  id: 'termsheet_repo_docs.issuer_id',
  field: 'issuerId',
  headerName: translations.documents.termsheets.issuer,
  renderCell: ({ row }) => <GetIssuerName row={row} />,
  filter: DynamicTypeFiltersSideBarFiltersEnum.issuer,
};

const BondCodeColumn: TermsheetRepoColumnModel = {
  ...commonCellProps,
  id: 'termsheet_repo_docs.bond_code',
  field: 'bondCode',
  headerName: translations.documents.termsheets.bondCode,
};

const DescriptionColumn: TermsheetRepoColumnModel = {
  ...commonCellProps,
  id: 'termsheet_repo_docs.description',
  field: 'description',
  headerName: translations.documents.termsheets.description,
};

const IssueDateColumn: TermsheetRepoColumnModel = {
  ...commonCellProps,
  id: 'termsheet_repo_docs.issue_date',
  field: 'issueDate',
  headerName: translations.documents.termsheets.issueDate,
  renderCell: ({ row }) => <FormatDate date={row.issueDate} />,
};

const MaturityDateColumn: TermsheetRepoColumnModel = {
  ...commonCellProps,
  id: 'termsheet_repo_docs.maturity_date',
  field: 'maturityDate',
  headerName: translations.documents.termsheets.maturityDate,
  renderCell: ({ row }) => <FormatDate date={row.maturityDate} />,
};

const NoteTypeColumn: TermsheetRepoColumnModel = {
  ...commonCellProps,
  id: 'termsheet_repo_docs.note_type',
  field: 'noteType',
  headerName: translations.documents.termsheets.noteType,
  renderCell: ({ row }) => <ProductNoteTypeChip type={capitalize(row.noteType)} variant="filled" />,
  filter: DynamicTypeFiltersSideBarFiltersEnum.noteType,
};

const ActionsCell = ({ termsheet }: { termsheet: TermsheetRepoDocModel }): ReactElement | null => {
  const isAdmin = useIsDocumentRepositoryManager();

  const previewMutation = usePreviewS3DocumentMutation();

  const previewDocument = () => previewMutation.mutate(termsheet.document);

  const view = (
    <ActionButton
      variant="contained"
      color="primary"
      size="small"
      startIcon={<Iconography iconName="arrow-up-right" color="inherit" />}
      onClick={previewDocument}
      loading={previewMutation.isPending}
    >
      {translations.common.view}
    </ActionButton>
  );

  const edit = isAdmin ? <EditTermsheetModal termsheet={termsheet} /> : null;

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      {view}
      {edit}
    </Stack>
  );
};

export const ActionsColumn: TermsheetRepoColumnModel = {
  ...commonCellProps,
  id: 'actions',
  field: 'actions',
  headerName: translations.common.actions,
  renderCell: ({ row }) => <ActionsCell termsheet={row} />,
  sortable: false,
};

export const columns: TermsheetRepoColumnModel[] = [
  ProductTypeColumn,
  NotionalColumn,
  CurrencyIdColumn,
  IssuerIdColumn,
  BondCodeColumn,
  DescriptionColumn,
  IssueDateColumn,
  MaturityDateColumn,
  NoteTypeColumn,
];

export const columnsMap = columns.reduce<Record<string, TermsheetRepoColumnModel>>((acc, column) => {
  const id = column.id.replace('termsheet_repo_docs.', '');
  acc[id] = column;
  return acc;
}, {});
