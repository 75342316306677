import { isValidElement, ReactElement, ReactNode, useEffect, useState } from 'react';

import { useUserInfoQuery } from '@halo-data-sources/queries';
import {
  OnboardingDrawer,
  OnboardingFormLoading,
  OnboardingReminderList,
  OnboardingReminderListVariants,
} from '@halo-modules/app';
import { PageLayout, Stack } from '@halodomination/halo-fe-common';
import { Box, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';

export const ONBOARDING_CONTENT_MIN_HEIGHT = 800;

const pageLayoutContentSx = {
  height: 'fit-content',
  py: 5,
  px: 3,
  overflowY: 'auto',
  mb: { xs: 2, sm: 8 },
  minWidth: { xs: 343, sm: 'default' },
  width: { xs: '100%', sm: 464 },
};

const pageLayoutContainerSx = {
  paddingTop: 5,
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
  minHeight: ONBOARDING_CONTENT_MIN_HEIGHT,
  msOverflowStyle: 'none',
  overflowY: 'auto',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

const desktopSx = { display: { xs: 'none', md: 'block' } };

const mobileSx = { display: { xs: 'block', md: 'none' } };

const contentSx = { width: 'auto', margin: 'auto' };

const childSx = { width: { xs: '100%', sm: 464 } };

export type OnboardingLayoutProps = {
  pageName: string;
  welcomeMessage: string;
  listVariant?: OnboardingReminderListVariants;
  children: NonNullable<ReactNode>;
};

export const OnboardingLayout = ({
  welcomeMessage,
  listVariant = 'default',
  children,
}: OnboardingLayoutProps): ReactElement => {
  const theme = useTheme();

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const { data: user, isPending } = useUserInfoQuery();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const whiteLabel = user?.whiteLabel;
  const onboardingDisclaimerContent = user?.settings.onboardingDisclaimerContent;

  const loading = !whiteLabel || isPending;
  const hasBrand = Boolean(whiteLabel?.brand);
  const showDisclaimer = !loading && onboardingDisclaimerContent;

  const disclaimerContent =
    showDisclaimer && isValidElement(onboardingDisclaimerContent) ? (
      <Typography variant="body2" color="grey.600" fontSize="14px">
        {onboardingDisclaimerContent}
      </Typography>
    ) : null;

  const onboardingReminderList = hasBrand ? <OnboardingReminderList variant={listVariant} /> : null;
  const displayContent = loading ? <OnboardingFormLoading /> : children;

  const mobileContent = (
    <Paper sx={pageLayoutContentSx} variant="outlined">
      {displayContent}
    </Paper>
  );

  useEffect(() => {
    const handleResize = () => {
      if (isMobile) return;
      const isBiggerHeight = window.innerHeight > screenHeight;
      if (isBiggerHeight) setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', () => handleResize());

    return () => void window.removeEventListener('resize', () => handleResize());
  }, []);

  return (
    <>
      <Box sx={desktopSx}>
        <PageLayout
          ContentProps={{ sx: pageLayoutContainerSx }}
          left={
            <OnboardingDrawer
              screenHeight={screenHeight}
              primary={welcomeMessage}
              list={onboardingReminderList}
              loading={loading}
            />
          }
        >
          <Stack direction="column" sx={contentSx} justify="center" elementStyling={childSx}>
            {mobileContent}
            {disclaimerContent}
          </Stack>
        </PageLayout>
      </Box>
      <Box sx={mobileSx}>
        <OnboardingDrawer
          screenHeight={screenHeight}
          primary={welcomeMessage}
          mobileContent={mobileContent}
          list={onboardingReminderList}
          loading={loading}
        />
      </Box>
    </>
  );
};
