import { updateOnboardingImageSettingSet, updateOnboardingSettingSet } from '@halo-data-sources/clients';
import { HttpValidationError } from '@halo-data-sources/errors';
import { OnboardingMapper } from '@halo-data-sources/mappers/onboardingMapper/onboardingMapper';
import { UserQueryKeyFactory } from '@halo-data-sources/queries';
import { LoginPageManagementFormFields } from '@halo-modules/admin';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from '@tanstack/react-query';

const useOnboardingConfigurationMutationFn = async (payload: LoginPageManagementFormFields) => {
  const request = OnboardingMapper.toApiOnboardingConfiguration(payload);

  await updateOnboardingSettingSet(request);

  if (payload?.image instanceof File) await updateOnboardingImageSettingSet(payload.image);
};

export const useOnboardingConfigurationMutation = (
  options?: Partial<UseMutationOptions<void, HttpValidationError, LoginPageManagementFormFields>>,
): UseMutationResult<void, HttpValidationError, LoginPageManagementFormFields> => {
  const queryClient = useQueryClient();

  return useMutation<void, HttpValidationError, LoginPageManagementFormFields>({
    mutationFn: useOnboardingConfigurationMutationFn,
    ...options,
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
      void queryClient.invalidateQueries({ queryKey: UserQueryKeyFactory.info() });
    },
  });
};
