import { ReactElement, useState } from 'react';

import { TileCardIcon } from '@halo-common/components';
import {
  IconographyProps,
  LocalizedTypography,
  LocalizedTypographyProps,
  Stack,
  useCombinedStyling,
} from '@halodomination/halo-fe-common';
import { Box, Skeleton, SxProps } from '@mui/material';

const ICON_WIDTH = 60;
const GAP = 32;

const defaultSx = {
  backgroundColor: 'common.white',
  border: '1px solid',
  borderColor: 'primary.background',
  borderRadius: 1,
  cursor: 'pointer',
  display: 'inline-block',
  height: '100%',
  margin: 0,
  minHeight: 160,
  padding: 3,
  transition: '300ms',
  width: '100%',
  '&:hover': {
    boxShadow: '0px 3px 16px 2px #0000000F',
  },
};

const iconsContainerStyling = {
  minWidth: 48,
  position: 'relative',
};

const containerSx = {
  width: '100%',
};

const contentSx = {
  width: `calc(100% - ${ICON_WIDTH}px - ${GAP}px)`,
};

const fluidContainerSx = {
  flex: '1 1 100%',
  minWidth: 0,
};

const fluidContentSx = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
};

export type TileCardLocalization = {
  title?: LocalizedTypographyProps;
  body?: LocalizedTypographyProps;
};

export type TileCardProps = {
  iconName?: IconographyProps['iconName'];
  loading?: boolean | null;
  title?: string | null;
  description?: string | null;
  onClick?: () => void;
  sx?: SxProps;
  className?: string;
  Localization?: TileCardLocalization;
};

export const TileCard = ({
  iconName = 'arrow-right',
  loading,
  title,
  description,
  onClick,
  sx,
  className,
  Localization = {},
}: TileCardProps): ReactElement => {
  const [hover, setHover] = useState(false);

  const tileCardSx = useCombinedStyling(defaultSx, sx);
  const containerElementSx = [contentSx];

  const setHovered = (hovered: boolean) => {
    if (!loading) setHover(hovered);
  };

  const handleMouseEnter = () => setHovered(true);
  const handleMouseExit = () => setHovered(false);

  const titleContent = loading ? (
    <Skeleton variant="rounded" width={150} height={24} />
  ) : (
    <LocalizedTypography sx={fluidContentSx} variant="h5" {...Localization?.title}>
      {title}
    </LocalizedTypography>
  );

  const bodyContent = loading ? (
    <Skeleton variant="rounded" width={65} height={32} />
  ) : (
    <LocalizedTypography sx={fluidContentSx} color="textSecondary" variant="body1" {...Localization?.body}>
      {description}
    </LocalizedTypography>
  );

  const iconContent = loading ? (
    <Skeleton variant="circular" width={ICON_WIDTH} height={ICON_WIDTH} />
  ) : (
    <Box sx={iconsContainerStyling}>
      <TileCardIcon iconName="arrow-right" show={hover} />
      <TileCardIcon iconName={iconName} show={!hover} />
    </Box>
  );

  return (
    <Box
      component="a"
      className={className}
      sx={tileCardSx}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseExit}
      onClick={onClick}
    >
      <Stack
        sx={containerSx}
        elementStyling={containerElementSx}
        direction="row"
        alignItems="flex-start"
        justify="space-between"
      >
        <Stack
          sx={containerSx}
          elementStyling={fluidContainerSx}
          direction="column"
          justify="space-between"
          spacing={2}
        >
          {titleContent}
          {bodyContent}
        </Stack>
        {iconContent}
      </Stack>
    </Box>
  );
};
