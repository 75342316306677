import { ReactElement } from 'react';

import { PageMeta } from '@halodomination/halo-fe-common';
import { NextComponentType } from 'next';
import { AppProps as NextAppProps } from 'next/app';

export type PageSubLayoutProps = {
  Component: NextComponentType & PageMeta;
  pageProps: NextAppProps['pageProps'];
};

export const PageSubLayout = ({ Component, pageProps }: PageSubLayoutProps): ReactElement => {
  return Component.getSubLayout ? Component.getSubLayout(<Component {...pageProps} />) : <Component {...pageProps} />;
};
