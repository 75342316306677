import { ReactElement } from 'react';

import { ProductDetailTable } from '@halo-common/components';
import { V2CalendarCallEvent } from '@halo-common/models';
import { GridColDef, HaloDataGridProps } from '@halodomination/halo-fe-common';

const commonColumnProps: Partial<GridColDef> = {
  align: 'left',
  maxWidth: 210,
  flex: 1,
};

const tableSx = {
  maxWidth: 420,
};

export type ExecutionFixedIncomeDetailModalCallTableProps = {
  events?: Array<V2CalendarCallEvent>;
  loading?: boolean;
};

export const ExecutionFixedIncomeDetailModalCallTable = ({
  events,
  loading,
}: ExecutionFixedIncomeDetailModalCallTableProps): ReactElement => {
  const rows = events ?? [];

  const height = rows.length >= 16 ? 600 : 'auto';

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonColumnProps,
      field: 'month',
      headerName: 'period',
      valueFormatter: (value: number) => `${value} Months`,
    },
    {
      ...commonColumnProps,
      field: 'level',
      headerName: 'call level',
    },
  ];

  return (
    <ProductDetailTable<V2CalendarCallEvent>
      sx={tableSx}
      height={height}
      columns={columns}
      rows={rows}
      loading={loading}
    />
  );
};
