import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiPostPortfolioLifecycleResponseModel,
  ApiPortfolioLifecycleRequestModel,
  ApiLifecycleRecommendationRequestModel,
  ApiLifecycleRecommendationResponseModel,
  ApiGetLifecycleCalendarResponseModel,
} from '@halo-data-sources/models';

const PORTFOLIO_EVENT_PATH = '/posttrade/portfolio/events/details';
const PORTFOLIO_REINVESTMENT_RECOMMENDATION_PATH = '/posttrade/portfolio/reinvestment/recommendation-type';
const PORTFOLIO_CALENDAR_RECOMMENDATION_PATH = '/posttrade/portfolio/reinvestment/calendar-recommendation';

export const getPortfolioLifecycleEvents = async (
  data: ApiPortfolioLifecycleRequestModel,
): Promise<ApiPostPortfolioLifecycleResponseModel> => {
  const clientConfiguration = await getClientConfiguration(PORTFOLIO_EVENT_PATH);

  const payload = {
    ...data,
    _csrf_token: clientConfiguration.csrfToken,
  };

  return await request<ApiPostPortfolioLifecycleResponseModel>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const getPortfolioLifecycleRecommendations = async (
  requestModel: Array<ApiLifecycleRecommendationRequestModel>,
): Promise<ApiLifecycleRecommendationResponseModel> => {
  const clientConfiguration = await getClientConfiguration(PORTFOLIO_REINVESTMENT_RECOMMENDATION_PATH);

  const response = await request<ApiLifecycleRecommendationResponseModel>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      event_infos: [...requestModel],
    }),
  });

  return response;
};

export const getPortfolioLifecycleCalendar = async (
  termsheetId?: number,
): Promise<ApiGetLifecycleCalendarResponseModel> => {
  const clientConfiguration = await getClientConfiguration(PORTFOLIO_CALENDAR_RECOMMENDATION_PATH);

  const response = await request<ApiGetLifecycleCalendarResponseModel>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      term_sheet_id: termsheetId,
    }),
  });

  return response;
};
