import { ReactElement, useEffect } from 'react';

import { useMobileView } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { CountrySelect, CrdNumberField, StateSelect } from '@halo-modules/app';
import { AwaitRegistrationPageMeta } from '@halo-pages/app/onboarding/await-registration';
import { CheckEmailPageMeta } from '@halo-pages/app/onboarding/check-email';
import { OnboardingActions, OnboardingSelectors, OnboardingStatusEnum } from '@halo-stores/Onboarding';
import { Stack } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { object as yupObject, string as yupString } from 'yup';

const loginButtonSx = { mb: 0.5 };

const schema = (requireCompanyName: boolean, requireCrd: boolean, useInternationalLocations: boolean) => {
  return yupObject().shape({
    emailAddress: yupString()
      .email('Please enter a valid email address.')
      .required('Please enter a valid email address.'),
    firstName: yupString().required('Please enter a first name.'),
    lastName: yupString().required('Please enter a last name.'),
    phoneNumber: yupString()
      .defined(translations.messages.requiredField)
      .matches(
        /(^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$)|^(?![\s\S])/,
        'Phone number is not valid.',
      ),
    companyName: requireCompanyName
      ? yupString().required('Please enter the name of your company.')
      : yupString().defined(translations.messages.requiredField),
    state: !useInternationalLocations ? yupString().required('Please enter your state') : yupString(),
    country: useInternationalLocations ? yupString().required('Please enter your country') : yupString(),
    crdNumber: requireCrd
      ? yupString()
          .required('Please enter a valid CRD#.')
          .matches(/^[0-9]+$/, 'Must be only digits')
          .min(7, 'Must be exactly 7 digits')
          .max(7, 'Must be exactly 7 digits')
      : yupString(),
    role: yupString().default(''),
  });
};

export type RegisterFormSchema = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  companyName: string;
  state?: string;
  country?: string;
  role: string;
  crdNumber?: string | undefined;
};

export const RegisterForm = (): ReactElement => {
  const dispatch = useDispatch();

  const isMobile = useMobileView();

  const { data: userInfo } = useUserInfoQuery();

  const userFacingName = Boolean(userInfo?.whiteLabel.userFacingName);
  const requireCrd = Boolean(userInfo?.settings?.onboarding?.requireCrd);
  const requireCompanyName = Boolean(userInfo?.settings?.onboarding?.requireCompanyName);
  const useInternationalLocations = Boolean(userInfo?.settings?.onboarding?.useInternationalLocations);

  const { error, status, userWhiteListed } = useSelector(OnboardingSelectors.selectState);

  const headerText = `Create your ${userFacingName} Account`;

  const defaultCountry = { country: '' };
  const defaultState = { state: '' };
  const defaultLocation = useInternationalLocations ? defaultCountry : defaultState;

  const formMethods = useForm<RegisterFormSchema>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    resolver: yupResolver<RegisterFormSchema>(schema(requireCompanyName, requireCrd, useInternationalLocations)),
    defaultValues: {
      emailAddress: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      companyName: '',
      role: '',
      crdNumber: undefined,
      ...defaultLocation,
    },
  });

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors, isValid, isDirty },
  } = formMethods;

  const { ref: emailAddressRef, ...emailAddressProps } = register('emailAddress');
  const { ref: firstNameRef, ...firstNameProps } = register('firstName');
  const { ref: lastNameRef, ...lastNameProps } = register('lastName');
  const { ref: phoneNumberRef, ...phoneNumberProps } = register('phoneNumber');
  const { ref: companyNameRef, ...companyNameProps } = register('companyName');
  const { ref: roleRef, ...roleProps } = register('role');

  const disableSubmit = !isDirty || !isValid || isSubmitting;

  const submitHandler = (data: RegisterFormSchema) => {
    dispatch(OnboardingActions.registerNewUser(data));
  };

  const loginHandler = () => void navigateParentTo('/');

  const errorMessage =
    status === OnboardingStatusEnum.failureRegisterUser ? (
      <Alert severity="error" variant="filled">
        <Typography variant="body2" component="span">
          {error}
        </Typography>
      </Alert>
    ) : null;

  const companyNameField = requireCompanyName ? (
    <TextField
      size="large"
      fullWidth
      label="Company Name"
      inputRef={companyNameRef}
      {...companyNameProps}
      error={Boolean(errors?.companyName)}
      helperText={errors?.companyName?.message}
    />
  ) : null;

  const locationSelect = useInternationalLocations ? <CountrySelect /> : <StateSelect />;
  const crdNumberField = requireCrd ? <CrdNumberField /> : null;

  useEffect(() => {
    if (status === OnboardingStatusEnum.successRegisterUser) {
      const route = userWhiteListed ? CheckEmailPageMeta.route : AwaitRegistrationPageMeta.route;
      const url = `/react${route}`;

      navigateParentTo(url);
    }
  }, [status, userWhiteListed]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Stack direction="column" spacing={3}>
          <Stack direction="column" spacing={3}>
            <Typography align="center" component="h4" variant="h4">
              {headerText}
            </Typography>
            {errorMessage}
            <Stack direction="row" xs={6} spacing={1}>
              <TextField
                size="large"
                fullWidth
                label="First Name"
                inputRef={firstNameRef}
                {...firstNameProps}
                error={Boolean(errors?.firstName)}
                helperText={errors?.firstName?.message}
                autoComplete="given-name"
              />
              <TextField
                size="large"
                fullWidth
                label="Last Name"
                inputRef={lastNameRef}
                {...lastNameProps}
                error={Boolean(errors?.lastName)}
                helperText={errors?.lastName?.message}
                autoComplete="family-name"
              />
            </Stack>
            <TextField
              size="large"
              fullWidth
              label="Email Address"
              inputRef={emailAddressRef}
              {...emailAddressProps}
              error={Boolean(errors?.emailAddress)}
              helperText={errors?.emailAddress?.message}
              autoComplete="email"
            />
            <TextField
              size="large"
              fullWidth
              label="Phone Number (Optional)"
              inputRef={phoneNumberRef}
              {...phoneNumberProps}
              error={Boolean(errors?.phoneNumber)}
              helperText={errors?.phoneNumber?.message}
              autoComplete="phone"
            />
            {companyNameField}
            <TextField size="large" fullWidth inputRef={roleRef} {...roleProps} label="Your Role (Optional)" />
            {locationSelect}
            {crdNumberField}
          </Stack>
          <Button
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : undefined}
            disabled={disableSubmit}
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
          >
            Continue
          </Button>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body2">Already have an account?</Typography>
            <Button
              sx={loginButtonSx}
              disabled={isSubmitting}
              type="button"
              color="primary"
              variant="outlined"
              fullWidth={isMobile}
              onClick={loginHandler}
            >
              Log In
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};
