import { ReactElement } from 'react';

import { AllocationModel } from '@halo-common/models';
import { AdminCalendarAllocationFilledStatusEnum, ExecutionHubStatusEnum } from '@halo-modules/admin';
import { Iconography, Tooltip, TooltipProps } from '@halodomination/halo-fe-common';
import { CircularProgress } from '@mui/material';

export type ExecutionHubStatusIndicatorProps = {
  allocation: AllocationModel;
};

export const ExecutionHubStatusIndicator = ({ allocation }: ExecutionHubStatusIndicatorProps): ReactElement => {
  const allocationStatus = allocation.status as ExecutionHubStatusEnum | AdminCalendarAllocationFilledStatusEnum;

  const isFilled = allocationStatus === ExecutionHubStatusEnum.filled;
  const isPending = allocationStatus === ExecutionHubStatusEnum.pending;
  const isWaitingOnWebsocket = allocationStatus === AdminCalendarAllocationFilledStatusEnum.websocket;

  const tooltipProps: Partial<TooltipProps> = { placement: 'right', variant: 'haloBlue' };

  if (isFilled) {
    return (
      <Tooltip {...tooltipProps} title="Filled">
        <Iconography iconName="check-circle" color="success.main" />
      </Tooltip>
    );
  } else if (isPending) {
    return (
      <Tooltip {...tooltipProps} title="Pending">
        <Iconography iconName="clock" color="grey.300" />
      </Tooltip>
    );
  } else if (isWaitingOnWebsocket) {
    return <CircularProgress color="primary" size={16} />;
  }

  const errorMessage = allocation.error ?? 'The request timed out. Please try again later.';

  return (
    <Tooltip {...tooltipProps} title={errorMessage}>
      <Iconography iconName="triangle-exclamation" color="warning.main" />
    </Tooltip>
  );
};
