import { translations } from '@halo-common/translations';
import { postPasswordChange } from '@halo-data-sources/clients';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

export type UpdateUserPasswordMutationPayload = {
  oldPassword?: string;
  newPassword?: string;
};

export type UpdateUserPasswordMutation = () => void;

const updateUserPasswordMutationFn = async (payload: UpdateUserPasswordMutationPayload) => {
  if (!payload.oldPassword || !payload.newPassword) return null;

  const response = await postPasswordChange({ old_password: payload.oldPassword, new_password: payload.newPassword });

  return response.message;
};

export const useUpdateUserPasswordMutation = (
  onSuccessCallback?: UpdateUserPasswordMutation,
): UseMutationResult<string | null, Error, UpdateUserPasswordMutationPayload> => {
  const { enqueueSuccessEvent } = useSnackbar();

  return useMutation<string | null, Error, UpdateUserPasswordMutationPayload>({
    mutationFn: updateUserPasswordMutationFn,
    onSuccess: () => {
      onSuccessCallback?.();
      enqueueSuccessEvent({ message: translations.profile.password.successSetPassword });
    },
    onError: (error) => {
      return error;
    },
  });
};
