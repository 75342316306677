import { SortModelDirectionEnum, SortModelNullSortEnum, UserRoleEnum } from '@halo-common/enums';
import {
  EducationHubUser,
  GetEducationHubDetailsRequestModel,
  GetEducationHubFilters,
  SortModel,
} from '@halo-common/models';
import {
  ApiAdminEducationUserModel,
  ApiGetEducationHubCSVRequestModel,
  ApiGetEducationHubDetailsRequestModel,
  ApiGetEducationHubFiltersModel,
} from '@halo-data-sources/models';
import { GridSortModel } from '@halodomination/halo-fe-common';

export interface ApiEducationHubMapper {
  toEducationHubUser(model: ApiAdminEducationUserModel): EducationHubUser;
}

export const ApiEducationHubMapper: ApiEducationHubMapper = {
  toEducationHubUser: (model) => {
    return {
      id: model.user.id,
      name: model.user.name,
      organization: model.user.organization.name,
      completionDate: model.completion_date,
      completionPercentage: model.completion_percentage,
      canTrade: model.user.roles.includes(UserRoleEnum.Buyer),
      canBuy: !model.user.roles.includes(UserRoleEnum.EducationLock),
      modules: model.modules.map((module) => ({
        name: module.name,
        completionPercentage: module.completion_percentage,
      })),
    };
  },
};

export interface EducationHubMapper {
  toEducationHubFilters(model?: GetEducationHubFilters): ApiGetEducationHubFiltersModel | undefined;
  toEducationHubSortModel(model: GridSortModel[0]): SortModel;
  toEducationHubRequest(model: GetEducationHubDetailsRequestModel): ApiGetEducationHubDetailsRequestModel;
  toEducationHubCSVRequest(model: GetEducationHubDetailsRequestModel): ApiGetEducationHubCSVRequestModel;
}

export const EducationHubMapper: EducationHubMapper = {
  toEducationHubSortModel: (model) => {
    const isAscendingOrder = model.sort === SortModelDirectionEnum.asc;
    const direction = isAscendingOrder ? SortModelDirectionEnum.asc : SortModelDirectionEnum.desc;
    const nulls = isAscendingOrder ? SortModelNullSortEnum.first : SortModelNullSortEnum.last;
    if (model.field === 'completionDate') return { direction, field: 'completion_date', nulls };
    else if (model.field === 'name') return { direction, field: 'user_name' };
    else return { direction, field: 'organization_name' };
  },
  toEducationHubFilters: (model) => {
    const userName = model?.userName ? model.userName : undefined;
    const orgName = model?.organizationName ? model.organizationName : undefined;
    return userName || orgName ? { organization_name: orgName, user_name: userName } : undefined;
  },
  toEducationHubRequest: (model) => {
    const sort = model?.sort?.map((model) => EducationHubMapper.toEducationHubSortModel(model));
    const filters = EducationHubMapper.toEducationHubFilters(model?.filters);

    return { filters, sort, page: model.page, page_length: model.resultsPerPage };
  },
  toEducationHubCSVRequest: (model) => {
    const sort = model?.sort?.map((model) => EducationHubMapper.toEducationHubSortModel(model));
    const filters = EducationHubMapper.toEducationHubFilters(model?.filters);

    return { filters, sort };
  },
};
