import { portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import {
  PortfolioPositionsQueryResult,
  usePortfolioPositionsQuery,
  useUserInfoQuery,
} from '@halo-data-sources/queries';
import { CompositionEnum } from '@halo-modules/app';
import { useAtomValue } from 'jotai';

type PortfolioPositionsDetailsData = Omit<PortfolioPositionsQueryResult, 'sections' | 'pagination'>;

export const usePortfolioPositionsDetailData = (): PortfolioPositionsDetailsData => {
  const positionData = useAtomValue(portfolioPositionsManagerAtom);

  const { detailsQuery } = positionData;

  const { data: userInfo } = useUserInfoQuery();

  const positionsQueryEnabled = Boolean(CompositionEnum.Product in detailsQuery.filters && userInfo);
  const positionsQueryOptions = { enabled: positionsQueryEnabled };

  const { data: positionMeta } = usePortfolioPositionsQuery(detailsQuery, positionsQueryOptions);

  const featuresData = positionMeta?.features ?? [];
  const filtersData = positionMeta?.filters ?? [];

  return { features: featuresData, filters: filtersData };
};
