import { ReactElement } from 'react';

import { portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import { WidgetEnginesEnum } from '@halo-common/enums';
import { useDesktopView } from '@halo-common/hooks';
import type { DashboardWidgetModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { usePortfolioPositionAggregationQuery } from '@halo-data-sources/queries';
import {
  CompositionEnum,
  PortfolioPositionsEmptyMessage,
  PortfolioPositionsPieChartCard,
  PortfolioPositionsValueCard,
  useAggregationToPieChartData,
  Widget,
} from '@halo-modules/app';
import { Box, Skeleton } from '@mui/material';
import { useAtomValue } from 'jotai';

const engine = WidgetEnginesEnum.PORTFOLIO_POSITIONS;

const title = translations.dashboard.portfolioPositions.portfolioPositions;
const description = translations.dashboard.portfolioPositions.dataAboutPortfolioComposition;

export const PortfolioPositions = (): ReactElement => {
  const isDesktop = useDesktopView();
  const { filters, query } = useAtomValue(portfolioPositionsManagerAtom);
  const { currency } = filters;

  const productsQuery = usePortfolioPositionAggregationQuery({
    ...query,
    composition: CompositionEnum.Product,
  });

  const issuersQuery = usePortfolioPositionAggregationQuery({
    ...query,
    composition: CompositionEnum.IssuerName,
  });

  const isLoading = productsQuery.isPending || issuersQuery.isPending;

  const { marketValue = 0, marketChange = 0 } = productsQuery.data?.tableDetails || {};

  const productsPieChartData = useAggregationToPieChartData(productsQuery.data?.aggregations, true);
  const issuersPieChartData = useAggregationToPieChartData(issuersQuery.data?.aggregations, true);

  const wrapperSx = {
    alignItems: 'stretch',
    display: 'grid',
    gap: 2,
    gridTemplateColumns: `repeat(${isDesktop ? 3 : 1}, minmax(0, 1fr))`,
  };

  let content = null;

  if (isLoading) {
    content = (
      <Box sx={wrapperSx}>
        <Skeleton width="100%" height={196} />
        <Skeleton width="100%" height={196} />
        <Skeleton width="100%" height={196} />
      </Box>
    );
  } else if (marketValue > 0) {
    content = (
      <Box sx={wrapperSx}>
        <PortfolioPositionsValueCard marketValue={marketValue} marketChange={marketChange} currency={currency} />
        <PortfolioPositionsPieChartCard
          title={translations.dashboard.portfolioPositions.products}
          data={productsPieChartData}
        />
        <PortfolioPositionsPieChartCard
          title={translations.dashboard.portfolioPositions.issuerName}
          data={issuersPieChartData}
        />
      </Box>
    );
  } else {
    content = <PortfolioPositionsEmptyMessage />;
  }

  const subTitle = translations.dashboard.portfolioPositions.subTitle;

  const Localization = {
    subTitle: {
      dynamicContent: {
        currency: currency?.code || '',
      },
    },
  };

  return (
    <Widget
      id={engine}
      title={title}
      subTitle={subTitle}
      className="wm-dashboard-composition"
      navigateButtonHref="/portfolio?tab=positions"
      navigateButtonLabel={translations.dashboard.portfolioPositions.positions}
      navigateButtonClassName="wm-dashboard-composition-seeall"
      Localization={Localization}
    >
      {content}
    </Widget>
  );
};

const widget: DashboardWidgetModel = {
  engine,
  title,
  description,
  component: PortfolioPositions,
};

export default widget;
