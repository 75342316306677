import { notificationFiltersAtom } from '@halo-atoms/profile';
import { getNotificationTypes } from '@halo-data-sources/clients/profile';
import { ApiNotificationMapper, NotificationMapper } from '@halo-data-sources/mappers';
import { NotificationTypesQueryPayload, NotificationTypesQueryResult } from '@halo-data-sources/models';
import { NotificationsQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

const getNotificationTypesFn = async (
  payload: NotificationTypesQueryPayload,
): Promise<NotificationTypesQueryResult | null> => {
  const response = await getNotificationTypes(payload);

  return response
    ? {
        notificationTypes: (response.filter_values?.[0].objects ?? []).map(
          ApiNotificationMapper.toNotificationTypeModel,
        ),
      }
    : null;
};

export const useNotificationTypesQuery = (): UseQueryResult<NotificationTypesQueryResult | null, Error> => {
  const filters = useAtomValue(notificationFiltersAtom);
  const payload = NotificationMapper.toApiNotificationTypesPayload(filters);

  return useQuery<NotificationTypesQueryResult | null, Error>({
    queryKey: NotificationsQueryKeyFactory.allTypes(),
    queryFn: () => getNotificationTypesFn(payload),
    refetchOnMount: false,
  });
};
