import { ReactElement } from 'react';

import { orderTicketCSVUploadModalAtom } from '@halo-atoms/orderTicket';
import { ActionButton } from '@halo-common/components';
import { BulkOrderTicketFormFields } from '@halo-common/schemas';
import { Iconography, Stack, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Typography } from '@mui/material';
import { useSetAtom } from 'jotai';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';

export type BulkAllocationSectionFooterProps = {
  fields: Array<FieldArrayWithId>;
  addAllocation: () => void;
};

export const BulkAllocationSectionFooter = ({
  fields,
  addAllocation,
}: BulkAllocationSectionFooterProps): ReactElement => {
  const { watch } = useFormContext<BulkOrderTicketFormFields>();

  const setCSVUploadModal = useSetAtom(orderTicketCSVUploadModalAtom);

  const count = fields.length;
  const allocations = watch('allocations');
  const sum = allocations.reduce((prev, field) => {
    const qty = field.quantity && Number(field.quantity);
    if (!qty) return prev;
    return prev + qty;
  }, 0);

  const totalQuantity = useCurrencyConverter(sum * 1000);

  const plusIcon = <Iconography iconName="plus" color="primary.main" />;

  const importIcon = <Iconography iconName="file-import" color="primary.main" />;

  const accountsCount = (
    <Typography component="span" fontWeight="bold">
      {count}
    </Typography>
  );

  const total = (
    <Typography component="span" fontWeight="bold">
      {totalQuantity}
    </Typography>
  );

  const handleAddAccount = () => {
    addAllocation();
  };

  const handleCSVUpload = () => {
    setCSVUploadModal(true);
  };

  return (
    <Stack direction="row" spacing={2} xs={12} sm={6} alignItems="center">
      <Stack direction="row" spacing={2}>
        <ActionButton variant="text" color="primary" startIcon={plusIcon} onClick={handleAddAccount}>
          Add Account
        </ActionButton>
        <ActionButton variant="text" color="primary" startIcon={importIcon} onClick={handleCSVUpload}>
          Upload Spreadsheet
        </ActionButton>
      </Stack>
      <Stack direction="row" spacing={4} justify="end">
        <Typography>Accounts: {accountsCount}</Typography>
        <Typography>Total: {total}</Typography>
      </Stack>
    </Stack>
  );
};
