import { ReactElement, useState } from 'react';

import { Stack } from '@halodomination/halo-fe-common';
import { Button, ButtonGroup, Typography } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';

// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const pdfStyling = { textAlign: 'center', alignSelf: 'center' };
const stepperStyling = { width: 90 };

export type TermsheetViewerProps = {
  path: string;
};

export const TermsheetViewer = ({ path }: TermsheetViewerProps): ReactElement => {
  const [pageNumber, setPageNumber] = useState(1);
  const [maxPages, setMaxPages] = useState(0);

  const pageNumberText = `${pageNumber} of ${maxPages}`;
  const documentPath = `https://${window.location.hostname}${path}`;

  const handleMaxPageSetter = (pdfDocumentProxy: unknown) => {
    const { numPages } = pdfDocumentProxy as { numPages: number };
    setMaxPages(numPages);
  };

  const previousPage = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };

  const nextPage = () => {
    if (pageNumber < maxPages) setPageNumber(pageNumber + 1);
  };

  return (
    <Stack direction="column" elementStyling={pdfStyling} justify="center" spacing={2}>
      <Document file={documentPath} onLoadSuccess={handleMaxPageSetter}>
        <Page pageNumber={pageNumber} />
      </Document>
      <ButtonGroup>
        <Button
          color="primary"
          variant="contained"
          disabled={pageNumber <= 1}
          onClick={previousPage}
          sx={stepperStyling}
        >
          Previous
        </Button>
        <Typography color="primary" variant="button" sx={stepperStyling}>
          {pageNumberText}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          disabled={pageNumber >= maxPages}
          onClick={nextPage}
          sx={stepperStyling}
        >
          Next
        </Button>
      </ButtonGroup>
    </Stack>
  );
};
