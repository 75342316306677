import { MouseEvent, ReactElement } from 'react';

import { orderBookAuctionCommentPopoverAtom, orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { ActionButton } from '@halo-common/components';
import { AuctionStatusEnum } from '@halo-common/enums';
import { useBuyerAuctionQuery } from '@halo-data-sources/queries';
import { Stack } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';

export const AuctionDetailsActionButtons = (): ReactElement | null => {
  const setOrderBookAuctionCommentPopover = useSetAtom(orderBookAuctionCommentPopoverAtom);
  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);

  const auctionId = selectedAuctionId?.toString();
  const { data: auction } = useBuyerAuctionQuery(auctionId);

  if (!auction) return <Skeleton variant="rounded" height={160} />;

  const hideCancelStatuses = [AuctionStatusEnum.Canceled, AuctionStatusEnum.Purchased, AuctionStatusEnum.TermsAccepted];
  const hideCancel = hideCancelStatuses.includes(auction.status);

  if (hideCancel) return null;

  const disableCancel = auction.status === AuctionStatusEnum.NotWin;

  const onClick = (event: MouseEvent<HTMLElement>) => {
    setOrderBookAuctionCommentPopover({ anchor: event.currentTarget });
  };

  return (
    <Stack direction="row" xs={[4, 8]} alignItems="center">
      <ActionButton variant="outlined" color="error" size="small" onClick={onClick} disabled={disableCancel}>
        Cancel Auction
      </ActionButton>
    </Stack>
  );
};
