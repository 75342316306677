import { isDocumentFromS3 } from '@halo-common/utils';
import { ApiOnboardingConfigurationPutModel } from '@halo-data-sources/models';
import { LoginPageManagementFormFields } from '@halo-modules/admin';

interface OnboardingMapperModel {
  toApiOnboardingConfiguration: (model: LoginPageManagementFormFields) => ApiOnboardingConfigurationPutModel;
}

export const OnboardingMapper: OnboardingMapperModel = {
  toApiOnboardingConfiguration: (model) => ({
    body: model.description || null,
    cta_button_link: model.ctaLink || null,
    cta_button_text: model.ctaText || null,
    overline: model.overline || null,
    subtitle: model.subtitle || null,
    title: model.headline || null,
    video_link: model.image && !isDocumentFromS3(model.image) ? null : model.videoLink || null,
  }),
};
