import { MouseEvent, ReactElement } from 'react';

import { anchorElAtom } from '@halo-atoms/common';
import { orderBookAuctionCommentPopoverAtom } from '@halo-atoms/orderbook';
import { ActionButton } from '@halo-common/components';
import { QuoteAuctionModel } from '@halo-common/models';
import { AuctionDetailsReviewCommentPopover } from '@halo-modules/app';
import { Iconography } from '@halodomination/halo-fe-common';
import { useAtom, useSetAtom } from 'jotai';

const commentButtonSx = {
  minWidth: 'unset',
  backgroundColor: 'info.background',
};

export type MiniCommentButtonProps = {
  quote?: QuoteAuctionModel;
};

export const MiniCommentButton = ({ quote }: MiniCommentButtonProps): ReactElement | null => {
  const updateAnchorElMap = useSetAtom(anchorElAtom);
  const [popoverData, setPopoverData] = useAtom(orderBookAuctionCommentPopoverAtom);

  const declined = quote?.comment?.buyerApproved === false;
  const accepted = quote?.comment?.buyerApproved === true;
  const commentMap = popoverData.commentMap;
  const comment = quote?.comment;
  const isLoading = comment && commentMap ? commentMap?.[comment.id] : false;
  const iconName = declined ? 'comment-xmark' : accepted ? 'comment-check' : 'comment-exclamation';

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    updateAnchorElMap({ [AuctionDetailsReviewCommentPopover]: event.currentTarget });
    setPopoverData({ quote });
  };

  const hasComment = Boolean(quote?.comment?.comment);
  const commentIcon = !isLoading ? <Iconography iconName={iconName} /> : null;
  return hasComment ? (
    <ActionButton
      size="medium"
      sx={commentButtonSx}
      color="info"
      fullWidth
      onClick={handlePopoverOpen}
      loading={isLoading}
    >
      {commentIcon}
    </ActionButton>
  ) : null;
};
