import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { useUserInfoQuery } from '@halo-data-sources/queries';

export const fixDateFormat = (dateFormat: string | null | undefined): string | null => {
  if (!dateFormat) return null;
  return dateFormat.replace(/[yY]+/, 'yyyy').replace(/[mM]+/, 'MM').replace(/[dD]+/, 'dd');
};

export const useWhiteLabelDateFormat = (fallback = MONTH_DAY_YEAR_DATE_FORMAT): string => {
  const { data: userInfo } = useUserInfoQuery();
  return fixDateFormat(userInfo?.whiteLabel?.dateFormat) ?? fallback;
};
