import { ReactElement } from 'react';

import { ProductDetailPanelSection, ProductDetailPanelSectionField } from '@halo-common/layouts';
import { AnnuityOrderModel } from '@halo-common/models';
import { useCurrencyConverter } from '@halodomination/halo-fe-common';

export type PostTradeAnnuityDetailModalPanelContractSectionProps = {
  product?: AnnuityOrderModel | null;
  loading: boolean;
};

export const PostTradeAnnuityDetailModalPanelContractSection = ({
  product,
  loading,
}: PostTradeAnnuityDetailModalPanelContractSectionProps): ReactElement => {
  const value = product?.contractValues.contractValue;
  const content = value ? useCurrencyConverter(Number(value))[0] : '';

  return (
    <ProductDetailPanelSection title="Contract Values" loading={loading}>
      <ProductDetailPanelSectionField label="Contract Value" description={content} loading={loading} />
    </ProductDetailPanelSection>
  );
};
