import { ReactElement } from 'react';

import { useBreakpointView } from '@halo-common/hooks';
import {
  PostTradeAnnuityDetailFeaturesTable,
  PostTradeAnnuityDetailModalHeader,
  PostTradeAnnuityDetailModalPanel,
  PostTradeAnnuityDetailTransactionsTable,
  ProductDetailModal,
} from '@halo-common/modals';
import { useAnnuityQuery } from '@halo-data-sources/queries';

const FEATURES_TAB = 'Features';
const TRANSACTIONS_TAB = 'Transactions';
const DETAILS_TAB = 'Details';

export type PostTradeAnnuityDetailModalProps = {
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  contractId?: string;
};

export const PostTradeAnnuityDetailModal = ({
  onClose,
  loading = false,
  open,
  contractId,
}: PostTradeAnnuityDetailModalProps): ReactElement => {
  const isTabletView = useBreakpointView('md', 'up');

  const { data: product, isPending: loadingAnnuity } = useAnnuityQuery(contractId);

  const isLoading = loadingAnnuity || loading;

  const tabs = [FEATURES_TAB];
  if (!isTabletView) tabs.push(DETAILS_TAB);
  tabs.push(TRANSACTIONS_TAB);

  const slots = {
    header: <PostTradeAnnuityDetailModalHeader product={product} loading={isLoading} onClose={onClose} />,
    panel: <PostTradeAnnuityDetailModalPanel product={product} loading={isLoading} />,
  };

  const detailsTabContents = !isTabletView ? (
    <PostTradeAnnuityDetailModalPanel product={product} loading={isLoading} />
  ) : null;

  return (
    <ProductDetailModal open={open} onClose={onClose} tabs={tabs} slots={slots} className="wm-pdm-posttrade">
      <PostTradeAnnuityDetailFeaturesTable product={product} loading={isLoading} />
      {detailsTabContents}
      <PostTradeAnnuityDetailTransactionsTable product={product} loading={isLoading} />
    </ProductDetailModal>
  );
};
