import { Children, ReactElement, ReactNode } from 'react';

import { Stack, Tabs, TabsProps, useCombinedStyling } from '@halodomination/halo-fe-common';
import { Box, Divider } from '@mui/material';

const tabContainerSx = {
  backgroundColor: 'background.default',
  borderRadius: `0 0 8px 0`,
  height: '100%',
};

export type ProductDetailModalLayoutContentProps = {
  children: ReactNode;
  tabs: TabsProps['tabs'];
  footer?: ReactNode;
  TabProps?: Partial<TabsProps>;
};

/**
 * Modal which displays ProductDetails and its nested Tabs/content
 * @param children = react Children to be rendered
 * @param tabs = list of Tabs for component to use
 * @param footer = optional footer content to be rendered
 * @param TabProps = optional additional props to be passed to MuiTabs component
 * @returns ReactElement for the modal
 * The explicit height properties set in the function are read by MuiTabs and explicilty using our small/medium/large
 * settings here breaks the Tab scaling/logic which causes the tab scroll arrows to appear when they shouldn't.
 * This requires the unset heights in the tabsSx variable to fix it.
 */
export const ProductDetailModalLayoutContent = ({
  children,
  footer,
  tabs,
  TabProps,
}: ProductDetailModalLayoutContentProps): ReactElement => {
  const containerSx = { width: '100%' };

  const tabSx = { px: 2, height: 'unset', maxHeight: 'unset', minHeight: 'unset' };
  const tabContentSx = { pt: 1, pb: 3, px: 2 };

  const combinedFooterStyling = useCombinedStyling({ paddingX: 5, paddingY: 2 });
  const combinedTabStyling = useCombinedStyling(containerSx, tabContainerSx);

  const slotProps: TabsProps['slotProps'] = {
    tabs: { allowScrollButtonsMobile: true, scrollButtons: 'auto', sx: tabSx },
    tab: { walkMeNamespace: 'wm-pdm' },
  };

  const parsedChildren = Children.toArray(children).map((child, index) => (
    <Box key={index} sx={tabContentSx}>
      {child}
    </Box>
  ));

  const footerContent = footer ? (
    <>
      <Divider />
      <Box sx={combinedFooterStyling}>{footer}</Box>
    </>
  ) : null;

  return (
    <Stack direction="column" sx={combinedTabStyling}>
      <Tabs tabs={tabs} variant="scrollable" disableGutters slotProps={slotProps} {...TabProps}>
        {parsedChildren}
      </Tabs>
      {footerContent}
    </Stack>
  );
};
