import { ReactElement } from 'react';

import {
  BookmarkReminder,
  ForgotCredentialsReminder,
  InformativeReminder,
  LinkExpiredReminder,
  PasswordReminder,
} from '@halo-modules/app';
import { List } from '@mui/material';

const listStyling = { color: 'common.white' };

export type OnboardingReminderListVariants =
  | 'default'
  | 'forgot'
  | 'inform'
  | 'password'
  | 'linkExpired'
  | 'noListVariant';

export type OnboardingReminderListProps = {
  variant?: OnboardingReminderListVariants;
};

export const OnboardingReminderList = ({ variant = 'default' }: OnboardingReminderListProps): ReactElement => {
  const content = {
    default: <BookmarkReminder />,
    forgot: <ForgotCredentialsReminder />,
    inform: <InformativeReminder />,
    password: <PasswordReminder />,
    linkExpired: <LinkExpiredReminder />,
    noListVariant: null,
  }[variant];

  return <List sx={listStyling}>{content}</List>;
};
