import { ReactElement } from 'react';

import { UserAlertModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { UserAlertPostRequestModel } from '@halo-data-sources/models';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Switch } from '@mui/material';

const toggleTextSx = { width: 25 };
const descriptionContainerSx = { pl: 1 };
const descriptionTitleSx = { fontWeight: 'fontWeightBold' };

export type UserAlertOnChange = UserAlertPostRequestModel & { id: string };

export type UserAlertOnChangeFunction = (data: UserAlertOnChange) => void;

export type AlertToggleProps = {
  alert: UserAlertModel;
  onChange: UserAlertOnChangeFunction;
  disableEmail?: boolean;
  disableNotification?: boolean;
};

export const AlertToggle = ({
  alert,
  onChange,
  disableEmail = false,
  disableNotification = false,
}: AlertToggleProps): ReactElement => {
  const emailValue = !disableEmail ? alert.emailEnabled : undefined;
  const notificationValue = !disableNotification ? alert.notificationEnabled : undefined;

  const emailToggleText = emailValue ? translations.common.on : translations.common.off;
  const notificationToggleText = notificationValue ? translations.common.on : translations.common.off;

  const handleEmailChange = (_: unknown, checked: boolean) => {
    onChange({ emailEnabled: checked, id: alert.id, alertTypeId: alert.alertType.id });
  };

  const handleNotificationChange = (_: unknown, checked: boolean) => {
    onChange({ notificationEnabled: checked, id: alert.id, alertTypeId: alert.alertType.id });
  };

  return (
    <Stack direction="row" spacing={5} alignItems="center">
      <Stack direction="row" alignItems="center">
        <Switch onChange={handleEmailChange} color="primary" checked={emailValue} disabled={disableEmail} />
        <LocalizedTypography sx={toggleTextSx}>{emailToggleText}</LocalizedTypography>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Switch
          onChange={handleNotificationChange}
          color="primary"
          checked={notificationValue}
          disabled={disableNotification}
        />
        <LocalizedTypography sx={toggleTextSx}>{notificationToggleText}</LocalizedTypography>
      </Stack>
      <Stack direction="column" sx={descriptionContainerSx}>
        <LocalizedTypography sx={descriptionTitleSx}>{alert.alertType.name}</LocalizedTypography>
        <LocalizedTypography variant="caption">{alert.alertType.description}</LocalizedTypography>
      </Stack>
    </Stack>
  );
};
