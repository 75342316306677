import { ReactElement } from 'react';

import { ProductDetailTable } from '@halo-common/components';
import { AnnuityOrderModel, AnnuityOrderTransactionModel } from '@halo-common/models';
import { HaloDataGridProps, useCurrencyConverter } from '@halodomination/halo-fe-common';

export type PostTradeAnnuityDetailTransactionsTableProps = {
  product?: AnnuityOrderModel | null;
  loading: boolean;
};

export const PostTradeAnnuityDetailTransactionsTable = ({
  product,
  loading,
}: PostTradeAnnuityDetailTransactionsTableProps): ReactElement => {
  const transactions =
    product?.transactions?.map((transaction, index) => ({
      ...transaction,
      id: index,
    })) ?? [];

  const columns: HaloDataGridProps['columns'] = [
    {
      field: 'identifier',
      headerName: 'identifier',
      flex: 2,
    },
    {
      field: 'amount',
      headerName: 'amount',
      flex: 1,
      valueFormatter: (value: string) => {
        const numVal = Number(value);
        if (!numVal) return 'None';
        return useCurrencyConverter(numVal);
      },
    },
    {
      field: 'effectiveDate',
      headerName: 'effective date',
      flex: 1,
    },
    {
      field: 'processDate',
      headerName: 'process date',
      flex: 1,
    },
  ];

  return <ProductDetailTable<AnnuityOrderTransactionModel> columns={columns} rows={transactions} loading={loading} />;
};
