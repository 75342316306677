import {
  AnnuityOrderContractModel,
  AnnuityOrderFeatureModel,
  AnnuityOrderModel,
  AnnuityOrderPartyModel,
  AnnuityOrderTransactionModel,
  AnnuityOrderUnderlyingModel,
} from '@halo-common/models';
import {
  ApiAnnuityOrderContractValues,
  ApiAnnuityOrderFeature,
  ApiAnnuityOrderParty,
  ApiAnnuityOrderTradable,
  ApiAnnuityOrderTransaction,
  ApiFetchAnnuityContractResponseModel,
} from '@halo-data-sources/models';

export const ApiAnnuityOrderMapper = {
  toAnnuityOrderContractModel: (model: ApiAnnuityOrderContractValues): AnnuityOrderContractModel => ({
    acceleratedBenefitValue: model.accelerated_benefit_value.value,
    administrativeFeePercentage: model.administrative_fee_percentage.value,
    annualWithdrawal: model.annual_withdrawal.value,
    contractNumber: model.contract_number.value,
    contractStatus: model.contract_status.value,
    contractValue: model.contract_value.value,
    cusip: model.cusip.value,
    guaranteedDeathBenefit: model.guaranteed_death_benefit.value,
    guaranteedLivingBenefit: model.guaranteed_living_benefit.value,
    guaranteedLivingBenefitType: model.guaranteed_living_benefit_type.value,
    issueDate: model.issue_date.value,
    issuer: model.issuer.value,
    mortalityExpensePercentage: model.mortality_expense_percentage.value,
    nextAnniversary: model.next_anniversary.value,
    originalInvestmentValue: model.original_investment_value.value,
    productCode: model.product_code.value,
    productName: model.product_name.value,
    productType: model.product_type.value,
    projectedLivingBenefit: model.projected_living_benefit.value,
    requiredMinimumDistribution: model.required_minimum_distribution.value,
    surrenderExpiration: model.surrender_expiration.value,
    taxCode: model.tax_code.value,
    totalBaseFee: model.total_base_fee.value,
    totalPremium: model.total_premium.value,
    totalWithdrawals: model.total_withdrawals.value,
  }),
  toAnnuityOrderFeature: (model: ApiAnnuityOrderFeature): AnnuityOrderFeatureModel => ({
    expenseQualifier: model.expense_qualifier.value,
    expenseType: model.expense_type.value,
    expenseValue: model.expense_value.value,
    frequency: model.frequency.value,
    subtype: model.subtype.value,
    type: model.type.value,
    value: model.value.value,
    valueQualifier: model.value_qualifier.value,
  }),
  toAnnuityOrderParty: (model: ApiAnnuityOrderParty): AnnuityOrderPartyModel => ({
    name: model.name,
    role: model.role,
  }),
  toAnnuityOrderUnderlying: (model: ApiAnnuityOrderTradable): AnnuityOrderUnderlyingModel => ({
    fundPercentage: parseFloat(model.fund_percentage.value),
    fundUnderlyingSecurityName: model.fund_underlying_security_name.value,
    fundUnderlyingSecurityType: model.fund_underlying_security_type.value,
    fundUnits: parseFloat(model.fund_units.value),
    fundValue: parseFloat(model.fund_value.value),
  }),
  toAnnuityOrderTransaction: (model: ApiAnnuityOrderTransaction): AnnuityOrderTransactionModel => ({
    identifier: model.transaction_identifier.value,
    amount: model.transaction_amount.value,
    effectiveDate: model.transaction_effective_date.value,
    processDate: model.transaction_process_date.value,
  }),
  toAnnuityOrderModel: (model: ApiFetchAnnuityContractResponseModel): AnnuityOrderModel => ({
    contractValues: ApiAnnuityOrderMapper.toAnnuityOrderContractModel(model.contract_values),
    features: model.features.map(ApiAnnuityOrderMapper.toAnnuityOrderFeature),
    parties: model.parties.map(ApiAnnuityOrderMapper.toAnnuityOrderParty),
    underlyings: model.tradables.map(ApiAnnuityOrderMapper.toAnnuityOrderUnderlying),
    transactions: model.transactions.map(ApiAnnuityOrderMapper.toAnnuityOrderTransaction),
  }),
};
