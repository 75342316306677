import { PortfolioCashflowEventsPayload, PortfolioCashflowEventChartDataModel } from '@halo-common/models';
import { getPortfolioCashflowEvents } from '@halo-data-sources/clients';
import { PortfolioCashflowEventsMapper } from '@halo-data-sources/mappers';
import { PortfolioLifecycleQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getLifecycleEventChartQueryFn = async (requestModel: PortfolioCashflowEventsPayload) => {
  const request = PortfolioCashflowEventsMapper.toApiPortfolioCashflowEventsRequestModel(requestModel);
  const response = await getPortfolioCashflowEvents(request);
  const data = PortfolioCashflowEventsMapper.toCashflowChartData(response);
  return { data, period: requestModel.frequency };
};

export const useLifecycleEventChartQuery = (
  payload: PortfolioCashflowEventsPayload,
): UseQueryResult<PortfolioCashflowEventChartDataModel, Error> =>
  useQuery<PortfolioCashflowEventChartDataModel, Error>({
    queryKey: PortfolioLifecycleQueryKeyFactory.aggregate(payload),
    queryFn: () => getLifecycleEventChartQueryFn(payload),
  });
