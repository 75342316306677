import { AllocationModel } from '@halo-common/models';
import { postExecCalendarOrderCancellation } from '@halo-data-sources/clients';
import { ApiAllocationMapper } from '@halo-data-sources/mappers';
import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';

export type CancelExecCalendarOrderMutationResult = {
  allocation: AllocationModel;
  execOrderId: number;
  message: string;
};

const cancelExecCalendarOrderMutationFn = async (allocationId?: number) => {
  if (!allocationId) return null;

  const response = await postExecCalendarOrderCancellation(allocationId);

  return {
    execOrderId: response.exec_order.id,
    allocation: ApiAllocationMapper.toAllocationModel(response.allocation),
    message: 'Successfully submitted cancellation request',
  };
};

export const useCancelExecCalendarOrderMutation = (
  options: UseMutationOptions<CancelExecCalendarOrderMutationResult | null, Error, number | undefined>,
): UseMutationResult<CancelExecCalendarOrderMutationResult | null, Error, number | undefined> =>
  useMutation<CancelExecCalendarOrderMutationResult | null, Error, number | undefined>({
    mutationFn: cancelExecCalendarOrderMutationFn,
    ...options,
  });
