import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type SMAQueryKeys = {
  all: QueryKeyFactoryFn;
  allocations: QueryKeyFactoryFn;
  strategies: QueryKeyFactoryFn;
};

export const SMAQueryKeyFactory: SMAQueryKeys = {
  all: () => ['sma'],
  allocations: () => [...SMAQueryKeyFactory.all(), 'allocations'],
  strategies: () => [...SMAQueryKeyFactory.all(), 'strategies'],
};
