import { MouseEvent, ReactElement, useState } from 'react';

import { PreTradeNoteDetailModalHeaderSharePopoverButtonCopyField } from '@halo-common/modals';
import { translations } from '@halo-common/translations';
import { Iconography, LocalizedButton, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { IconButton, Popover } from '@mui/material';

const popoverContentSx = {
  p: 2,
  minWidth: 600,
};

export type PreTradeNoteDetailModalHeaderSharePopoverButtonProps = {
  url: string;
};

export const PreTradeNoteDetailModalHeaderSharePopoverButton = ({
  url,
}: PreTradeNoteDetailModalHeaderSharePopoverButtonProps): ReactElement => {
  const [shareAnchorEl, setShareAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(shareAnchorEl);

  const handleOpenSharePopover = (event: MouseEvent<HTMLButtonElement>) => {
    setShareAnchorEl(event.currentTarget);
  };

  const handleCloseSharePopover = () => {
    setShareAnchorEl(null);
  };

  return (
    <>
      <LocalizedButton
        className="wm-pre-pdm-note-share-popover"
        startIcon={<Iconography iconName="share" color="primary.main" />}
        onClick={handleOpenSharePopover}
        type="button"
        variant="text"
        color="primary"
      >
        {translations.common.share}
      </LocalizedButton>
      <Popover
        open={open}
        anchorEl={shareAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        disableAutoFocus
        disableEnforceFocus
      >
        <Stack direction="column" spacing={1} sx={popoverContentSx}>
          <Stack justify="space-between" direction="row" spacing={2}>
            <LocalizedTypography variant="h6">{translations.pdm.preTrade.popoverShareTitle}</LocalizedTypography>
            <IconButton className="wm-pre-pdm-note-share-button" onClick={handleCloseSharePopover}>
              <Iconography iconName="xmark" color="text.secondary" />
            </IconButton>
          </Stack>
          <PreTradeNoteDetailModalHeaderSharePopoverButtonCopyField url={url} />
        </Stack>
      </Popover>
    </>
  );
};
