import { OrderBookQuery } from '@halo-atoms/orderbook';
import { OrderBookPershingQueryFields } from '@halo-common/models';
import { downloadFile } from '@halo-common/utils';
import { getCalendarAllocationCSVDownload } from '@halo-data-sources/clients';
import { OrderBookMapper, PershingOrderBookMapper } from '@halo-data-sources/mappers';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { DateTime } from 'luxon';

export type UseOrderBookDownloadPershingCSVMutationResult = UseMutationResult<
  void,
  Error,
  OrderBookPershingQueryFields
>;

const orderBookDownloadPershingCSVMutationFn = async (query: OrderBookQuery) => {
  const sort = OrderBookMapper.toApiOrderBookSortList(query);
  const comparisons = PershingOrderBookMapper.toApiFiltersComparisonList(query);

  const response = await getCalendarAllocationCSVDownload({
    comparisons,
    sort,
    page: query.page,
    page_length: query.pageSize,
  });

  const csvDownload = new Blob([response], { type: 'text/csv' });
  const url = URL.createObjectURL(csvDownload);

  const date = DateTime.local().toFormat('MMddyyyy_hhmmss');
  const fileName = `orders.${date}.csv`;

  downloadFile({ fileName, downloadFilePath: url });
};

export const useOrderBookDownloadPershingCSVMutation = (): UseOrderBookDownloadPershingCSVMutationResult =>
  useMutation<void, Error, OrderBookPershingQueryFields>({
    mutationFn: orderBookDownloadPershingCSVMutationFn,
  });
