import { MouseEvent, ReactElement } from 'react';

import { ActionButton } from '@halo-common/components';
import { useWebSocketContext } from '@halo-common/providers';
import { translations } from '@halo-common/translations';
import { useQuoteRefreshMutation } from '@halo-data-sources/mutations';
import { Iconography, LocalizedTypography, Tooltip } from '@halodomination/halo-fe-common';
import { ButtonProps, CircularProgress, IconButton, Stack, StackProps } from '@mui/material';

const buttonSx = {
  minWidth: 100,
};

export type FindPriceButtonProps = Omit<ButtonProps, 'variant'> & {
  children?: string;
  direction?: StackProps['direction'];
  productId?: number;
  label?: string;
  variant?: 'button' | 'icon';
};

export const FindPriceButton = ({
  children = translations.components.findPriceButtonText,
  direction = 'column',
  label,
  productId,
  variant = 'button',
  disabled,
  ...props
}: FindPriceButtonProps): ReactElement => {
  const { mutate, isPending: refreshing } = useQuoteRefreshMutation();

  const { events } = useWebSocketContext();

  const loading = refreshing || events.quote.contains(productId);
  const isDisabled = loading || disabled;
  const isButton = variant === 'button';

  const handleGetPrice = (ev: MouseEvent) => {
    ev?.stopPropagation();
    mutate(productId);
  };

  const tooltipText = !loading ? children : `${translations.components.findPriceMessage}...`;
  const icon = !loading ? <Iconography iconName="repeat" /> : null;
  const iconButtonContent = !loading ? icon : <CircularProgress size={16} />;
  const walkMeClassName = 'wm-find-price-button';

  const labelText = label ? (
    <LocalizedTypography variant="subtitle2" color="textSecondary">
      {label}
    </LocalizedTypography>
  ) : null;

  const action = isButton ? (
    <ActionButton
      size="medium"
      variant="outlined"
      startIcon={icon}
      onClick={handleGetPrice}
      loading={loading}
      sx={buttonSx}
      disabled={isDisabled}
      className={walkMeClassName}
      {...props}
    >
      {children}
    </ActionButton>
  ) : (
    <Tooltip title={tooltipText}>
      <span>
        <IconButton
          className={walkMeClassName}
          size="medium"
          onClick={handleGetPrice}
          color="primary"
          disabled={isDisabled}
          {...props}
        >
          {iconButtonContent}
        </IconButton>
      </span>
    </Tooltip>
  );

  return (
    <Stack direction={direction} alignItems="center" spacing={1}>
      {action}
      {labelText}
    </Stack>
  );
};
