import { ReactElement } from 'react';

import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Box, Stack, Typography } from '@mui/material';

export type EducationImageProps = {
  title?: string;
  imageUrl: string;
  caption?: string;
  maxHeight?: string;
  maxWidth?: string;
};

export const EducationImage = ({
  maxWidth,
  maxHeight,
  title,
  imageUrl,
  caption,
}: EducationImageProps): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();

  const imageContainerSx = {
    borderRadius: 2,
    width: '100%',
    '& img': {
      maxWidth: maxWidth ? parseInt(maxWidth) : '100%',
      maxHeight: maxHeight ? parseInt(maxHeight) : undefined,
    },
  };

  const staticAssetUrl = userInfo?.configuration.staticAssetUrl;
  const altText = `${title} topic image`;
  const url = `${staticAssetUrl}${imageUrl}`;

  const titleContent = title ? (
    <Typography color="textPrimary" variant="h5">
      {title}
    </Typography>
  ) : null;

  const captionContent = caption ? (
    <Typography color="textSecondary" variant="caption" data-testid="education-image-caption">
      {caption}
    </Typography>
  ) : null;

  return (
    <Stack sx={{ width: '100%' }} direction="column" spacing={1}>
      {titleContent}
      <Box sx={imageContainerSx}>
        <img src={url} alt={altText} />
      </Box>
      {captionContent}
    </Stack>
  );
};
