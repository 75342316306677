import { ReactElement } from 'react';

import { selectedWatchlistAtom } from '@halo-atoms/watchlists';
import { DefaultErrorPage, TileGrid, TileGridTile } from '@halo-common/components';
import { WatchlistModel } from '@halo-common/models';
import { useLegacyRouter } from '@halo-common/providers';
import { translations } from '@halo-common/translations';
import { useSetAtom } from 'jotai';

export type WatchlistLandingProps = {
  watchlists?: Array<WatchlistModel>;
  loading: boolean;
  error: boolean;
};

const alphabeticallySortWatchlists = <TProps extends { name: string }>(watchlists: TProps[]): TProps[] => {
  return [...watchlists].sort((a, b) => a.name.localeCompare(b.name));
};

export const WatchlistLanding = ({ watchlists = [], loading, error }: WatchlistLandingProps): ReactElement => {
  const legacyRouter = useLegacyRouter();

  const setSelectedWatchlist = useSetAtom(selectedWatchlistAtom);

  const tiles = alphabeticallySortWatchlists(
    watchlists?.map((watchlist) => {
      const { count, name, icon } = watchlist;

      return {
        description: translations.watchlist.common.productsCount,
        icon,
        name,
        value: watchlist,
        className: 'wm-watchlist-card',
        Localization: { body: { dynamicContent: { count } } },
      };
    }),
  );

  const handleClick = (tile: TileGridTile<WatchlistModel>) => {
    legacyRouter.addQueryParam('watchlist', tile.value.id.toString());
    setSelectedWatchlist(tile.value);
  };

  if (error) return <DefaultErrorPage />;
  else return <TileGrid loading={loading} tiles={tiles} onClick={handleClick} />;
};
