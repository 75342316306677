import { useEffect, useState } from 'react';

const isInIframe = () => {
  if (typeof window === 'undefined') return null;
  return window.self !== window.top;
};

/**
 * Custom hook that determines if the current window is inside an iframe.
 *
 * @returns {boolean | null} `true` if window is inside an iframe, `false` if not. Fallbacks to `null` on the server.
 */
export const useIsInIframe = (): boolean | null => {
  const [isInsideIframe, setIsInsideIframe] = useState<boolean | null>(isInIframe);

  useEffect(() => {
    setIsInsideIframe(isInIframe());
  }, []);

  return isInsideIframe;
};
