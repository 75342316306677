import { ReactElement } from 'react';

import { ProductDetailTable } from '@halo-common/components';
import { UnderlyingBasketEnum } from '@halo-common/enums';
import { UnderlyingPerformanceTableDataModel } from '@halo-common/models';
import { GridColDef, GridRenderCellParams, HaloDataGridProps, useChartColors } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Switch, switchClasses } from '@mui/material';

const commonCellProps: Partial<GridColDef> = {
  align: 'right',
};

export type UnderlyingPerformanceTableProps = {
  rows?: Array<UnderlyingPerformanceTableDataModel>;
  columns?: HaloDataGridProps['columns'];
  loading: boolean;
  onToggle: (label: string) => void;
};

export const UnderlyingPerformanceTable = ({
  rows = [],
  columns = [],
  loading,
  onToggle,
}: UnderlyingPerformanceTableProps): ReactElement => {
  const { getColorAt } = useChartColors('line');

  const tableSx = {
    '& .toggle-cell': {
      padding: 0,
      px: loading ? 1.25 : 'unset',
    },
    '& .name-cell': {
      fontWeight: 'fontWeightBold',
    },
    '& .weighted-basket': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& .worst-basket': {
      backgroundColor: (theme: HaloTheme) => (!loading ? theme.palette.error.background : undefined),
    },
  };

  const mappedColumns: HaloDataGridProps['columns'] = [
    {
      field: 'displayToggle',
      cellClassName: 'toggle-cell',
      headerName: '',
      width: 60,
      renderCell: ({ row }: GridRenderCellParams<UnderlyingPerformanceTableDataModel>) => {
        if (row.hideToggle) return null;

        const color = getColorAt(row.colorId);

        const switchStyle = {
          [`& .${switchClasses.checked}`]: {
            color: color.main,
            [`& .${switchClasses.thumb}`]: {
              color: color.main,
            },
            [`+.${switchClasses.track}`]: {
              backgroundColor: color.background,
            },
          },
        };

        const handleToggle = () => void onToggle(row.name.toLowerCase());

        return <Switch sx={switchStyle} onChange={handleToggle} defaultChecked />;
      },
    },
    {
      width: 150,
      field: 'name',
      headerName: 'name',
      cellClassName: 'name-cell',
    },
    ...columns.map((column) => ({ ...commonCellProps, ...column })),
  ];

  return (
    <ProductDetailTable<UnderlyingPerformanceTableDataModel>
      sx={tableSx}
      rows={rows}
      loading={loading}
      columns={mappedColumns}
      getRowClassName={({ row }) => {
        if (row.id === UnderlyingBasketEnum.WORST_OF) return 'worst-basket';
        else if (row.id === UnderlyingBasketEnum.WEIGHTED) return 'weighted-basket';
        else return '';
      }}
    />
  );
};
