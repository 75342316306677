import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type PDMSwitchKeys = {
  all: QueryKeyFactoryFn;
  postTrade: QueryKeyFactoryFn;
};

export const PDMSwitchKeyFactory: PDMSwitchKeys = {
  all: () => ['pdm'],
  postTrade: (id) => [...PDMSwitchKeyFactory.all(), 'post-trade', 'details', id],
};
