import { ChangeEvent, ReactElement, useContext, useState } from 'react';

import { DAY_MONTH_YEAR_DATE_FORMAT, HOUR_MINUTE_SECONDS_TIME_FORMAT } from '@halo-common/constants';
import { AuctionAdminModel, CalendarPageModel, IssuerModel } from '@halo-common/models';
import { CalendarPageList, TackOnContext } from '@halo-modules/admin';
import { CalendarSelectors } from '@halo-stores/Calendar';
import { Iconography, Stack } from '@halodomination/halo-fe-common';
import {
  Autocomplete,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const containerSx = {
  backgroundColor: 'background.default',
};

const selectCalendarPagesHeading = {
  mt: 2,
  mb: 3,
};

export type TackOnFormProps = {
  auction: AuctionAdminModel | undefined | null;
  issuers: Array<IssuerModel>;
};

export const TackOnForm = ({ auction, issuers }: TackOnFormProps): ReactElement => {
  const {
    startShowingDate,
    startShowingTime,
    expirationDate,
    expirationTime,
    setStartShowingDate,
    setStartShowingTime,
    setExpirationDate,
    setExpirationTime,
  } = useContext(TackOnContext);

  const { register, control, setValue } = useFormContext();

  const parentChildPages = useSelector(CalendarSelectors.selectParentChildPages);
  const [filteredCalendarPages, setFilteredCalendarPages] = useState<Array<CalendarPageModel>>(parentChildPages);

  const doesTitleMatchQuery = (page: CalendarPageModel, query: string) => {
    const pageTitle = page?.title?.toLowerCase();
    const pageOrg = page?.organization?.name.toLowerCase();
    const pageId = page?.id.toString();

    if (!pageTitle && !pageOrg && !pageId && page?.children?.length) return false;
    else if (
      pageTitle.includes(query.toLowerCase()) ||
      (pageOrg && pageOrg.includes(query.toLowerCase())) ||
      pageId.includes(query)
    )
      return true;
    else {
      return page.children.some(
        (child) =>
          child.title.toLowerCase().includes(query.toLowerCase()) ||
          child.organization?.name.toLowerCase().includes(query.toLowerCase()) ||
          child.id.toString().includes(query),
      );
    }
  };

  const filterCalendarPages = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const query = event.target.value;
    const pages = query ? parentChildPages.filter((page) => doesTitleMatchQuery(page, query)) : parentChildPages;

    setFilteredCalendarPages(pages);
  };

  const getIssuerFromAuction = (auction?: AuctionAdminModel | null) => {
    if (auction?.tackOn?.issuer) {
      return auction.tackOn.issuer;
    } else if (auction?.winningQuote?.issuer) {
      return issuers.find((issuer) => issuer.name === auction?.winningQuote?.issuer);
    }

    return;
  };

  const defaultIssuer = getIssuerFromAuction(auction);

  const [issuer, setIssuer] = useState<IssuerModel | undefined>(defaultIssuer);

  const defaultFormValues = auction?.tackOn
    ? {
        externalName: auction.tackOn.displayName,
        internalName: auction.tackOn.internalName,
        reoffer: auction.tackOn.price,
        calendarPages: auction.tackOn.calendarPages,
      }
    : null;

  const defaultCalendarPages = auction?.tackOn?.pages?.map((page) => page.id) ?? [];

  const { ref: externalNameRef, ...externalNameProps } = register('externalName');
  const { ref: internalNameRef, ...internalNameProps } = register('internalName');
  const { ref: reofferRef, ...reofferProps } = register('reoffer');

  const updateIssuer = (id: number | undefined) => {
    const foundIssuer = issuers.find((issuer) => issuer.id === id);
    setIssuer(foundIssuer);
    setValue('issuer', foundIssuer?.id, { shouldDirty: true, shouldValidate: true });
  };

  const updateCalendarPages = (pages: Array<number>) => {
    setValue('calendarPages', pages, { shouldDirty: true, shouldValidate: true });
  };

  const externalNameField = (
    <TextField
      {...externalNameProps}
      fullWidth
      label="External Name"
      inputRef={externalNameRef}
      defaultValue={defaultFormValues ? defaultFormValues.externalName : null}
    />
  );

  const internalNameField = (
    <TextField
      {...internalNameProps}
      fullWidth
      label="Internal Name"
      inputRef={internalNameRef}
      defaultValue={defaultFormValues ? defaultFormValues.internalName : null}
    />
  );

  const startShowingField = (
    <Stack direction="row" spacing={2} justify="space-between">
      <DatePicker
        format={DAY_MONTH_YEAR_DATE_FORMAT}
        onChange={(value: DateTime | null) => {
          setStartShowingDate?.(value as DateTime);
        }}
        slotProps={{
          field: { clearable: true },
          textField: { fullWidth: true },
        }}
        label="Start Showing Date"
        value={startShowingDate}
      />
      <TimePicker
        format={HOUR_MINUTE_SECONDS_TIME_FORMAT}
        onChange={(value: DateTime | null) => {
          setStartShowingTime?.(value as DateTime);
        }}
        slotProps={{
          field: { clearable: true },
          textField: { fullWidth: true },
        }}
        label="Start Showing Time"
        value={startShowingTime}
      />
    </Stack>
  );

  const expirationField = (
    <Stack direction="row" spacing={2}>
      <DatePicker
        format={DAY_MONTH_YEAR_DATE_FORMAT}
        onChange={(value: DateTime | null) => {
          setExpirationDate?.(value as DateTime);
        }}
        slots={{ textField: (props) => <TextField fullWidth type="date" {...props} /> }}
        label="Expiration Date"
        value={expirationDate}
      />
      <TimePicker
        format={HOUR_MINUTE_SECONDS_TIME_FORMAT}
        onChange={(value: DateTime | null) => {
          setExpirationTime?.(value as DateTime);
        }}
        slots={{ textField: (props) => <TextField fullWidth type="time" {...props} /> }}
        label="Start Showing Time"
        value={expirationTime}
      />
    </Stack>
  );

  const issuerField = (
    <FormControl variant="outlined" fullWidth>
      <Autocomplete
        value={issuer}
        options={issuers}
        getOptionLabel={(option) => option.name}
        onChange={(_, data) => updateIssuer(data?.id)}
        renderInput={(params) => (
          <TextField {...params} value={null} inputRef={params.InputProps.ref} label="Issuer" fullWidth />
        )}
      />
    </FormControl>
  );

  const reofferField = (
    <TextField
      {...reofferProps}
      ref={reofferRef}
      label="Reoffer"
      fullWidth
      type="number"
      slotProps={{
        input: {
          startAdornment: <InputAdornment position="start">%</InputAdornment>,
        },
      }}
      defaultValue={defaultFormValues ? defaultFormValues.reoffer : null}
    />
  );

  const categoryField = (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="category-select-label">Category</InputLabel>
      <Controller
        control={control}
        render={({ field: { ref, onChange, ...fieldProps } }) => (
          <Select
            {...fieldProps}
            inputRef={ref}
            fullWidth
            labelId="category-select-label"
            id="category-select"
            label="Category"
            onChange={(event) => {
              onChange(event);
            }}
          >
            <MenuItem value="Advisory">Advisory</MenuItem>
            <MenuItem value="Brokerage">Brokerage</MenuItem>
            <MenuItem value="Exclusive">Exclusive</MenuItem>
          </Select>
        )}
        name="category"
      />
    </FormControl>
  );

  const calendarPagesField = (
    <FormControl variant="outlined" fullWidth>
      <Typography sx={selectCalendarPagesHeading} variant="h6">
        Select Calendar Pages To Display
      </Typography>
      <TextField
        fullWidth
        placeholder="Filter Below Calendar Pages"
        onChange={filterCalendarPages}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end">
                <Iconography iconName="magnifying-glass" prefix="fal" color="text.faded" />
              </InputAdornment>
            ),
          },
        }}
      />
      <CalendarPageList
        calendarPages={filteredCalendarPages}
        onPageCheck={updateCalendarPages}
        defaultValues={defaultCalendarPages}
      />
    </FormControl>
  );

  return (
    <Container maxWidth="xs" disableGutters sx={containerSx}>
      <form>
        <Stack direction="column" spacing={2}>
          {externalNameField}
          {internalNameField}
          {startShowingField}
          {expirationField}
          {issuerField}
          {reofferField}
          {categoryField}
          {calendarPagesField}
        </Stack>
      </form>
    </Container>
  );
};
