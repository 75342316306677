import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { BrandIcon, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';

const emptyMessageSx = { pt: 12, width: '100%' };
const emptyMessageTextSx = { pb: 2 };

export const GlossaryEmptyMessage = (): ReactElement => (
  <Stack direction="row" sx={emptyMessageSx} justify="center" alignItems="center" spacing={3}>
    <BrandIcon variant="search" />
    <LocalizedTypography variant="h3" sx={emptyMessageTextSx}>
      {translations.dashboard.educationCourses.noGlossaryResult}
    </LocalizedTypography>
  </Stack>
);
