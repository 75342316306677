import { ReactElement } from 'react';

import { complianceApprovalQueryAtom } from '@halo-atoms/complianceApproval';
import { EmptyResultsCard, InfiniteScroller } from '@halo-common/components';
import { useAdminCalendarAllocationsQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { AdminLoadingCardList, ComplianceApprovalCalendarCard } from '@halo-modules/admin';
import { BrandIcon } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';

export const ComplianceApprovalCalendarList = (): ReactElement | null => {
  const filters = useAtomValue(complianceApprovalQueryAtom);

  const { data: userInfo } = useUserInfoQuery();

  const calendarPageId = userInfo?.organization?.calendarPageId;
  const queryEnabled = typeof calendarPageId === 'number';
  const query = { ...filters, calendarPageId };

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isFetched, isRefetching, isPending } =
    useAdminCalendarAllocationsQuery(query, { enabled: queryEnabled });

  const results = data?.pages.flatMap((page) => page.details) ?? [];

  const hasMore = Boolean(hasNextPage);
  const noContent = Boolean(!isPending && isFetched && !results?.length);
  const isSearching = isRefetching && !isFetchingNextPage;

  if (isPending) {
    return <AdminLoadingCardList />;
  }

  if (noContent) {
    const brandIcon = <BrandIcon color="primary" variant="search" />;

    return (
      <EmptyResultsCard
        title="No Results"
        description="Approval is not required at this time for Indications of Interest"
        brandIcon={brandIcon}
      />
    );
  }

  return (
    <InfiniteScroller loading={isFetchingNextPage} next={fetchNextPage} hasMore={hasMore}>
      {results.map(({ calendar, allocations }) => (
        <ComplianceApprovalCalendarCard
          key={calendar.id}
          loading={isSearching}
          calendar={calendar}
          allocations={allocations}
        />
      ))}
    </InfiniteScroller>
  );
};
