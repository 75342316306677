export enum ApiFixEventTypeEnum {
  PENDING_NEW = 'PENDING_NEW',
  PENDING_CANCEL = 'PENDING_CANCEL',
  NEW = 'NEW',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  TRADE = 'TRADE',
  DONTKNOWTRADEDK = 'DONTKNOWTRADEDK',
}

export type ApiFixEventType = keyof typeof ApiFixEventTypeEnum;

export enum ApiFixEventMessageEnum {
  ORDER_VALIDATED = 'ORDER VALIDATED',
  ORDER_SUBMITTED_SUCCESSFULLY = 'ORDER SUBMITTED SUCCESSFULLY',
  ORDER_SENT_FOR_APPROVAL = 'YOUR ORDER HAS BEEN SENT FOR APPROVAL.',
}

export enum ApiFixCommissionTypeEnum {
  ABSOLUTE = 'ABSOLUTE',
  PERUNIT = 'PERUNIT',
  PERCENT = 'PERCENT',
}

export type ApiFixCommissionType = keyof typeof ApiFixCommissionTypeEnum;

export enum ApiFixSettlementTypeEnum {
  REGULAR = 'REGULAR',
  CASH = 'CASH',
  T_1 = 'T_1',
  T_2 = 'T_2',
  T_3 = 'T_3',
  T_4 = 'T_4',
  T_5 = 'T_5',
  FUTURE = 'FUTURE',
  IF_ISSUED = 'IF_ISSUED',
  SELLER = 'SELLER',
}

export type ApiFixSettlementType = keyof typeof ApiFixSettlementTypeEnum;

export enum ApiFixStatusEnum {
  NEW = 'NEW',
  PARTIAL = 'PARTIAL',
  FILLED = 'FILLED',
  DONE = 'DONE',
  CANCELED = 'CANCELED',
  PENDING_CANCEL = 'PENDING_CANCEL',
  STOPPED = 'STOPPED',
  REJECTED = 'REJECTED',
  SUSPENDED = 'SUSPENDED',
  PENDING_NEW = 'PENDING_NEW',
  CALCULATED = 'CALCULATED',
  EXPIRED = 'EXPIRED',
  ACCEPTED = 'ACCEPTED',
  PENDING_REPLACE = 'PENDING_REPLACE',
}

export type ApiFixStatus = keyof typeof ApiFixStatusEnum;

export enum ApiFixTypeEnum {
  CalendarAllocation = 'CalendarAllocation',
}

export type ApiFixType = keyof typeof ApiFixTypeEnum;
