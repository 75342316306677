import { SettingSetRepIdModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { AnyObjectSchema, array as yupArray, number as yupNumber, object as yupObject, string as yupString } from 'yup';

export const updateUserInfoSchema = (repIdSettingSet?: Array<SettingSetRepIdModel>): AnyObjectSchema => {
  return yupObject().shape({
    firstName: yupString()
      .required(translations.profile.details.firstNameError)
      .trim(translations.profile.details.firstNameError),
    lastName: yupString()
      .required(translations.profile.details.lastNameError)
      .trim(translations.profile.details.lastNameError),
    phone: yupString()
      .trim(translations.profile.details.phoneNumberError)
      .matches(
        /(^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$)|^(?![\s\S])/,
        translations.profile.details.phoneNumberError,
      ),
    repSettingArrayField: yupArray().of(
      yupArray().of(
        yupObject()
          .shape({
            repSettingId: yupNumber(),
            value: yupString(),
          })
          .test((currentRepId, ctx) => {
            const { createError, path } = ctx;
            const currentRepIdSetting = repIdSettingSet?.find((repId) => repId.id === currentRepId.repSettingId);

            const maxChar = currentRepIdSetting?.maxCharacters;
            const minChar = currentRepIdSetting?.minCharacters;

            if (!currentRepId.value || !maxChar || !minChar) return true;

            const regex = /^[a-zA-Z0-9]+$/g;
            const isAlphaNumeric = regex.test(currentRepId.value);

            if (!isAlphaNumeric) {
              return createError({
                message: translations.profile.details.alphaNumericOnly,
                path: `${path}.value`,
              });
            }

            const currentRepIdLength = currentRepId.value.length;
            const maxAndMinCharEqual = maxChar === minChar;
            const repIdNotEqualToMaxChar = maxAndMinCharEqual && currentRepIdLength !== maxChar;
            const repIdNotEqualToMinChar = maxAndMinCharEqual && currentRepIdLength !== minChar;

            if (repIdNotEqualToMaxChar && repIdNotEqualToMinChar) {
              return createError({
                message: translations.profile.details.exactCharacters,
                path: `${path}.value`,
              });
            } else if (maxAndMinCharEqual) {
              return true;
            } else if (maxChar < currentRepIdLength) {
              return createError({
                message: translations.profile.details.maxCharacters,
                path: `${path}.value`,
              });
            } else if (minChar > currentRepIdLength) {
              return createError({
                message: translations.profile.details.minCharacters,
                path: `${path}.value`,
              });
            }

            return true;
          }),
      ),
    ),
  });
};
