import { ReactElement } from 'react';

import { InstitutionalLineChart } from '@halo-common/components';
import { NoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useQuoteHistoryQuery } from '@halo-data-sources/queries';

export type PreTradeNoteDetailModalQuoteHistoryChartProps = {
  product?: NoteModel | null;
  loading?: boolean;
};

export const PreTradeNoteDetailModalQuoteHistoryChart = ({
  product,
  loading,
}: PreTradeNoteDetailModalQuoteHistoryChartProps): ReactElement => {
  const { data: quoteHistory, isPending: chartLoading } = useQuoteHistoryQuery(product);

  const isLoading = loading || chartLoading;

  const chartData = quoteHistory?.chart?.data ?? [];
  const lines = quoteHistory?.chart?.lines ?? [];
  const references = quoteHistory?.chart?.references ?? [];

  const handleTooltipValueFormat = (value: number) => `${value.toFixed(2)}%`;

  return (
    <InstitutionalLineChart
      data={chartData}
      lines={lines}
      referenceLines={references}
      dataType="date"
      loading={isLoading}
      xLabel={translations.common.time}
      yLabel={translations.pdm.preTrade.quoteHistoryChartY}
      zoomUpdateTrigger="all"
      TooltipProps={{ formatter: handleTooltipValueFormat }}
      XAxisProps={{ tickCount: 4 }}
    />
  );
};
