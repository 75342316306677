import { ReactElement, ReactNode } from 'react';

import { ModuleModel, ModuleStepModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { EducationStepperSelector, useCourseManager } from '@halo-modules/app';
import { Iconography, LocalizedButton, Stack } from '@halodomination/halo-fe-common';
import { Paper } from '@mui/material';

const footerSx = {
  bottom: 0,
  boxShadow: '0 0 0 1px #e0e0e0',
  left: 0,
  position: 'fixed',
  px: 4,
  py: 1,
  right: 0,
};

export type EducationStepperProps = {
  primaryActionOverride?: ReactNode;
  onNext?: () => void;
  onPrev?: () => void;
};

export const EducationStepper = ({ primaryActionOverride, onNext, onPrev }: EducationStepperProps): ReactElement => {
  const { progress, onNext: courseOnNext, onPrevious: courseOnPrev, onReset, onSelection } = useCourseManager();

  const currentGroup = progress.group;
  const currentModule = progress.module;
  const currentStep = progress.step;

  const modules = currentGroup?.modules ?? [];
  const groupTitle = currentGroup?.title;
  const percentageComplete = currentGroup?.percentageComplete ?? 0;

  const currentType = currentStep?.type;
  const isQuiz = currentType === 'quiz';
  const subtitle = isQuiz ? translations.education.educationQuizManager.stepperSubtitle : currentStep?.title;
  const Localization = isQuiz ? { subtitle: { dynamicContent: { stepTitle: currentStep?.title } } } : undefined;

  const previousStep = Boolean(currentStep?.position || currentModule?.position);
  const homeIcon = previousStep ? 'home' : 'arrow-left';

  const primary = progress.primaryAction;
  const secondary = progress.secondaryAction;

  const handleSelection = (selectedModule: ModuleModel, selectedStep: ModuleStepModel) => {
    if (currentGroup) onSelection(currentGroup, selectedModule, selectedStep);
  };

  const handleCourseReset = () => void onReset();
  const handlePrevious = () => {
    onPrev?.();
    void courseOnPrev();
  };
  const handleNext = () => {
    onNext?.();
    void courseOnNext();
  };

  const primaryActionButton = primaryActionOverride ?? (
    <LocalizedButton
      type="button"
      endIcon={<Iconography color="common.white" prefix="fas" iconName={primary.icon} />}
      variant="contained"
      onClick={handleNext}
    >
      {primary.text}
    </LocalizedButton>
  );

  const secondaryActionButton = previousStep ? (
    <LocalizedButton
      type="button"
      startIcon={<Iconography color="primary.main" prefix="fas" iconName={secondary.icon} />}
      variant="outlined"
      onClick={handlePrevious}
    >
      {secondary.text}
    </LocalizedButton>
  ) : null;

  return (
    <Paper sx={footerSx}>
      <Stack
        direction="row"
        justify="space-between"
        alignItems="center"
        xs={[3, 6, 3]}
        justifyElements={['flex-start', 'center', 'flex-end']}
      >
        <Stack direction="row" spacing={2}>
          {secondaryActionButton}
          <LocalizedButton
            type="button"
            startIcon={<Iconography color="primary.main" prefix="fas" iconName={homeIcon} />}
            variant="outlined"
            onClick={handleCourseReset}
          >
            {translations.education.educationQuizManager.allCourses}
          </LocalizedButton>
        </Stack>
        <EducationStepperSelector
          Localization={Localization}
          title={groupTitle}
          subtitle={subtitle}
          progress={percentageComplete}
          modules={modules}
          onSelection={handleSelection}
        />
        {primaryActionButton}
      </Stack>
    </Paper>
  );
};
