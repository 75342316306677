import { ReactElement, ReactNode, useEffect, useState } from 'react';

import { UploadFileModalFooter, UploadFileModalSelectionStep } from '@halo-common/modals';
import { IconographyProps, Modal, ModalHeaderLocalizationProps } from '@halodomination/halo-fe-common';

export enum UploadFileModalStatuses {
  idle = 'idle',
  pending = 'pending',
  success = 'success',
  error = 'error',
  approval = 'approval',
}

export type UploadFileApprovalStep = {
  disabled?: boolean;
  subtitle?: string | ((file: File) => string);
  content?: ReactNode;
  actionText?: string;
};

export type UploadFileResultStep = {
  icon: IconographyProps['iconName'];
  title: string;
  message?: string;
  content?: ReactNode;
};

export type UploadFileModalProps = {
  additionalContent?: ReactNode;
  open: boolean;
  status?: keyof typeof UploadFileModalStatuses;
  title: string;
  subtitle?: string;
  fileTypes?: string;
  loading?: boolean;
  approvalStep?: UploadFileApprovalStep;
  pendingStep?: UploadFileResultStep;
  successStep?: UploadFileResultStep;
  errorStep?: UploadFileResultStep;
  Localization?: ModalHeaderLocalizationProps;
  onSubmit: (file: File) => void;
  onClose: () => void;
  onReset?: () => void;
};

export const UploadFileModal = ({
  onClose,
  onSubmit,
  onReset,
  open = false,
  loading = false,
  status,
  subtitle,
  approvalStep,
  pendingStep,
  successStep,
  errorStep,
  title,
  fileTypes = '*',
  Localization,
}: UploadFileModalProps): ReactElement => {
  const [modalStatus, setModalStatus] = useState<UploadFileModalStatuses>(UploadFileModalStatuses.idle);
  const [selectedFile, setSelectedFile] = useState<File | null | undefined>();

  const isFirstStep = modalStatus === UploadFileModalStatuses.idle;
  const isApproval = modalStatus === UploadFileModalStatuses.approval;
  const isPending = modalStatus === UploadFileModalStatuses.pending;
  const isSuccess = modalStatus === UploadFileModalStatuses.success;
  const isError = modalStatus === UploadFileModalStatuses.error;

  const isSingleStep = !approvalStep;

  const handleSubmit = () => {
    if (selectedFile) onSubmit(selectedFile);
  };

  const handleReset = () => {
    onReset?.();
    setSelectedFile(null);
    setModalStatus(UploadFileModalStatuses.idle);
  };

  const handleConfirmSelection = () => {
    setModalStatus(UploadFileModalStatuses.approval);
  };

  const fileSelected = Boolean(selectedFile);
  const disableAction = !fileSelected;
  const fileName = selectedFile?.name ?? 'this file';

  const modalLocalization = {
    overline: {
      ...Localization?.overline,
      dynamicContent: {
        ...Localization?.overline?.dynamicContent,
        fileName: selectedFile?.name,
      },
    },
    title: {
      ...Localization?.title,
      dynamicContent: {
        ...Localization?.title?.dynamicContent,
        fileName: selectedFile?.name,
      },
    },
    subtitle: {
      ...Localization?.subtitle,
      dynamicContent: {
        ...Localization?.subtitle?.dynamicContent,
        fileName: selectedFile?.name,
      },
    },
  };

  let modalIcon = 'file-upload';
  if (isSuccess && successStep) {
    modalIcon = successStep.icon;
  } else if (isError && errorStep) {
    modalIcon = errorStep.icon;
  }

  let modalTitle = title;
  if (isPending && pendingStep) {
    modalTitle = pendingStep?.title;
  } else if (isSuccess && successStep) {
    modalTitle = successStep?.title;
  } else if (isError && errorStep) {
    modalTitle = errorStep.title;
  }

  let modalSubtitle = subtitle;
  if (isApproval && approvalStep) {
    const parsedSubtitle = approvalStep.subtitle ?? `Are you sure you’d like to upload ${fileName}?`;
    modalSubtitle = typeof parsedSubtitle === 'string' ? parsedSubtitle : parsedSubtitle(selectedFile as File);
  } else if (isPending && pendingStep) {
    modalSubtitle = pendingStep?.message;
  } else if (isSuccess && successStep) {
    modalSubtitle = successStep?.message;
  } else if (isError && errorStep) {
    modalSubtitle = errorStep.message;
  }

  const firstStepFooterProps = {
    action: isSingleStep ? handleSubmit : handleConfirmSelection,
    disabled: disableAction,
    loading: Boolean(isSingleStep && loading),
  };

  const approvalStepFooterProps = {
    action: handleSubmit,
    disabled: loading || approvalStep?.disabled,
    loading,
  };

  const isApprovalStep = isApproval && approvalStep?.content;
  const approvalStepContent = isApprovalStep ? approvalStep?.content : null;
  const pendingStepContent = isPending ? pendingStep?.content : null;
  const successStepContent = isSuccess ? successStep?.content : null;

  const firstStepContent = isFirstStep ? (
    <UploadFileModalSelectionStep
      onFileSelection={setSelectedFile}
      file={selectedFile}
      types={fileTypes}
      showError={isError}
      errorMessage={errorStep?.message}
    />
  ) : null;

  const content = firstStepContent ?? approvalStepContent ?? pendingStepContent ?? successStepContent;

  useEffect(() => {
    if (isSuccess && !successStep) onClose();
  }, [isSuccess, successStep]);

  useEffect(() => {
    if (open) handleReset();
  }, [open]);

  useEffect(() => {
    if (status) setModalStatus(status as UploadFileModalStatuses);
  }, [status]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      icon={modalIcon}
      title={modalTitle}
      subtitle={modalSubtitle}
      Localization={modalLocalization}
      footer={
        <UploadFileModalFooter
          status={modalStatus}
          onBack={handleReset}
          onClose={onClose}
          approvalStep={approvalStepFooterProps}
          firstStep={firstStepFooterProps}
        />
      }
    >
      {content}
    </Modal>
  );
};
