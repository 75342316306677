import { InferType, array as yupArray, number as yupNumber, object as yupObject, string as yupString } from 'yup';

const requiredText = 'required';

export const tackOnFormSchema = yupObject().shape({
  internalName: yupString().required(requiredText),
  externalName: yupString().required(requiredText),
  issuer: yupNumber(),
  reoffer: yupNumber().required(requiredText),
  category: yupString().required(requiredText),
  calendarPages: yupArray().of(yupNumber()).min(1).required(requiredText),
});

export type TackOnFormSchema = InferType<typeof tackOnFormSchema>;
