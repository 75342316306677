import { ReactElement } from 'react';

import { ProductDetailPanelSection } from '@halo-common/layouts';
import { AnnuityOrderModel } from '@halo-common/models';
import { Skeleton, Typography } from '@mui/material';

export type PostTradeAnnuityDetailModalPanelIssuerSectionProps = {
  product?: AnnuityOrderModel | null;
  loading: boolean;
};

export const PostTradeAnnuityDetailModalPanelIssuerSection = ({
  product,
  loading,
}: PostTradeAnnuityDetailModalPanelIssuerSectionProps): ReactElement | null => {
  const issuerName = product?.contractValues.issuer;
  const hideSection = !loading && !issuerName;

  if (hideSection) return null;

  const issuerContent = loading ? (
    <Skeleton width={150} height={40} variant="rounded" />
  ) : (
    <Typography variant="h6">{issuerName}</Typography>
  );

  return (
    <ProductDetailPanelSection title="Issued By" loading={loading}>
      {issuerContent}
    </ProductDetailPanelSection>
  );
};
