import { ReactElement } from 'react';

import { NoteTypeEnum } from '@halo-common/enums';
import { NoteSpecificDetailsField, useBreakpointView } from '@halo-common/hooks';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Skeleton, Typography } from '@mui/material';

const textContainerSx = { wordWrap: 'break-word' };

export type PostTradeNoteDetailModalHeaderSolvableParameterProps = {
  parameter?: NoteSpecificDetailsField | null;
  loading?: boolean;
  type?: string;
};

export const PostTradeNoteDetailModalHeaderSolvableParameter = ({
  parameter,
  loading = true,
  type,
}: PostTradeNoteDetailModalHeaderSolvableParameterProps): ReactElement => {
  const isTabletView = useBreakpointView('md', 'up');

  if (loading) return <Skeleton width={200} height={80} />;

  if (!parameter) {
    return (
      <Stack direction="column">
        <Typography variant="h2" color="textPrimary">
          --
        </Typography>
      </Stack>
    );
  }

  const direction = isTabletView ? 'column' : 'row';
  const mobileSpacing = !isTabletView ? 1 : undefined;
  const containerSx = { width: '100%', alignItems: { xs: isTabletView ? 'flex-start' : 'flex-end' } };

  const isGeneralNote = type === NoteTypeEnum.General;
  const generalNoteTextVariant = isGeneralNote ? 'h6' : 'h2';
  const generalNoteElementSx = isGeneralNote ? [{ width: '100%' }] : undefined;

  return (
    <Stack sx={containerSx} direction={direction} spacing={mobileSpacing} elementStyling={generalNoteElementSx}>
      <Typography sx={textContainerSx} variant={generalNoteTextVariant} color="textPrimary">
        {parameter?.text}
      </Typography>
      <LocalizedTypography variant="subtitle1" color="textSecondary">
        {parameter?.label}
      </LocalizedTypography>
    </Stack>
  );
};
