import { ReactElement } from 'react';

import { WatchlistDetailsProductModel } from '@halo-common/models';
import { TargetedWatchlistCard } from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';
import { Divider } from '@mui/material';

const dividerSx = { mt: 4 };

export type TargetedWatchlistCardStackProps = {
  targets?: Array<WatchlistDetailsProductModel>;
  onRemove: (targetNoteId?: number) => void;
};

export const TargetedWatchlistCardStack = ({
  targets = [],
  onRemove,
}: TargetedWatchlistCardStackProps): ReactElement => (
  <Stack direction="column" spacing={2}>
    {targets.map(({ id, product, type }, index) => (
      <Stack key={id} direction="column">
        <TargetedWatchlistCard id={id} product={product} type={type} onRemove={onRemove} />
        {index !== targets.length - 1 ? <Divider sx={dividerSx} /> : null}
      </Stack>
    ))}
  </Stack>
);
