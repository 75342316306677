import { IssuerHistoricalCdsModel, IssuerModel } from '@halo-common/models';
import { ApiHistoricCdsModel, ApiIssuerModel } from '@halo-data-sources/models';

interface ApiIssuerMapperModel {
  toHistoricCdsModel: (model: ApiHistoricCdsModel) => IssuerHistoricalCdsModel;
  toIssuerModel: (issuer: ApiIssuerModel) => IssuerModel;
}

export const ApiIssuerMapper: ApiIssuerMapperModel = {
  toHistoricCdsModel: (model) => ({
    cfid: model.cfid,
    spotDate: model.spot_date,
    spread: model.spread,
    termInYears: model.data_term_years,
    issuerId: model.issuer_id,
  }),
  toIssuerModel: (issuer) => ({
    id: issuer.id,
    name: issuer.name,
    internalName: issuer.internal_name,
    notional: issuer.min_investment_size,
    ergonomicId: issuer.ergonomic_id,
    email: issuer.desk_email,
    passed: Boolean(issuer.optedout),
    reason: issuer.reason ?? '',
    sp: issuer.sp ?? null,
    fitch: issuer.fitch ?? null,
    moody: issuer.moody ?? null,
    cd5Year: issuer.historic_cds?.find((cd) => cd.data_term_years === 5)?.spread ?? null,
    cd10Year: issuer.historic_cds?.find((cd) => cd.data_term_years === 10)?.spread ?? null,
    minInvestmentSize: issuer.min_investment_size,
    historicCds: issuer?.historic_cds?.map(ApiIssuerMapper.toHistoricCdsModel) ?? [],
    logoUrl: issuer.logo_url ?? '',
  }),
};
