import { orderTicketReceiptAtom, orderTicketStepAtom } from '@halo-atoms/orderTicket';
import { OrderTicketSubmissionSwitchHandlerResult } from '@halo-data-sources/switches';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { useSetAtom } from 'jotai';

export const useJanneySubmissionHandlers = (): OrderTicketSubmissionSwitchHandlerResult => {
  const { enqueueErrorEvent } = useSnackbar();

  const moveToSuccessStep = useSetAtom(orderTicketStepAtom.handleNextAtom);
  const setOrderTicketReceipt = useSetAtom(orderTicketReceiptAtom);

  return {
    onSuccess: () => {
      moveToSuccessStep();
    },
    onError: (error: Error) => {
      setOrderTicketReceipt((prev) => ({ ...prev, status: 'error' }));
      enqueueErrorEvent({ subMessage: error.message, message: 'Submission Error' });
    },
  };
};
