import { ReactElement, ReactNode } from 'react';

import { translations } from '@halo-common/translations';
import { Iconography, LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import {
  Box,
  Paper,
  Stack,
  Stepper,
  StepConnector,
  Step,
  stepLabelClasses,
  stepConnectorClasses,
  StepButton,
} from '@mui/material';

import { StepWizardContextProvider } from './context';
import { useStepWizardManager, UseStepWizardManagerOptions } from './useStepWizardManager';

const stepperSx = {
  mb: 12,
  [`& .${stepLabelClasses.labelContainer}`]: {
    alignItems: 'flex-start',
    gap: '6px',
  },
};

const connectorSx = {
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 4,
    minWidth: 76,
  },
};

const fadeIn = {
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  animation: 'fadeIn 0.6s cubic-bezier(0.16, 1, 0.3, 1)',
  transformOrigin: 'center',
};

const paperSx = {
  ...fadeIn,
  border: '1px solid',
  borderColor: 'primary.background',
  padding: 3,
};

const actionsSx = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: 2,
};

const backButtonSx = {
  '&:disabled': {
    opacity: 0,
    pointerEvents: 'none',
  },
};

export type StepWizardSlotsModel = {
  skipButton?: ReactNode;
};

export type StepWizardProps = UseStepWizardManagerOptions & {
  title: ReactNode;
  slots?: StepWizardSlotsModel;
  nonLinear?: boolean;
};

export const StepWizard = ({
  title,
  sections,
  defaultStep,
  fallbackStep,
  queryKey = 'step',
  nonLinear,
  slots,
}: StepWizardProps): ReactElement => {
  const stepWizardManager = useStepWizardManager({
    sections,
    defaultStep,
    fallbackStep,
    queryKey,
  });

  const { activeSectionIndex, activeStep, step, setStep, isFirstStep, goToPreviousStep } = stepWizardManager;

  const stepTitle = activeStep?.title ? (
    <LocalizedTypography variant="h4" align="center" sx={fadeIn} key={`title-${step}`}>
      {activeStep.title}
    </LocalizedTypography>
  ) : null;

  const stepSubtitle = activeStep?.subtitle ? (
    <LocalizedTypography
      variant="subtitle2"
      align="center"
      color="text.secondary"
      mt={1}
      sx={fadeIn}
      key={`subtitle-${step}`}
    >
      {activeStep.subtitle}
    </LocalizedTypography>
  ) : null;

  const stepContent = activeStep?.component || null;

  const stepFooter = activeStep?.footer || null;

  const skipButton = slots?.skipButton || null;

  const backButton = (
    <LocalizedButton
      variant="outlined"
      color="primary"
      startIcon={<Iconography iconName="arrow-left" />}
      onClick={goToPreviousStep}
      disabled={isFirstStep}
      sx={backButtonSx}
    >
      {translations.common.back}
    </LocalizedButton>
  );

  return (
    <StepWizardContextProvider stepWizardManager={stepWizardManager}>
      <Stepper
        sx={stepperSx}
        activeStep={Math.max(activeSectionIndex, 0)}
        connector={<StepConnector sx={connectorSx} />}
        nonLinear={nonLinear}
      >
        {sections.map(({ id, label, optional, steps, isSectionCompleted }, index) => {
          const openSection = () => {
            setStep(steps.find((step) => step.isStepCompleted === false)?.id ?? steps[0].id);
          };
          return (
            <Step key={id} index={index} completed={isSectionCompleted}>
              <StepButton optional={optional} onClick={openSection}>
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>

      <LocalizedTypography color="text.secondary" variant="overline" align="center" mb={1}>
        {title}
      </LocalizedTypography>

      {stepTitle}
      {stepSubtitle}

      <Stack maxWidth={464} width="100%" mt={6}>
        <Paper sx={paperSx} elevation={0} key={step}>
          {stepContent}
        </Paper>

        <Box pt={3} mb={8} sx={actionsSx}>
          {backButton}
          {skipButton}
        </Box>

        {stepFooter}
      </Stack>
    </StepWizardContextProvider>
  );
};
