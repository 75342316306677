import { productApprovalManagerAtom } from '@halo-atoms/productApproval';
import { updateCalendarExpirationDate } from '@halo-data-sources/clients';
import { ApiCalendarExpirationOverrideRequestModel } from '@halo-data-sources/models';
import { ProductApprovalCalendarResult, ProductApprovalQueryKeyFactory } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

export type CalendarExpirationOverrideMutationPayload = {
  assetIdentifier: string;
  calendarIds: Array<number>;
  date: {
    absolute?: string;
    relative?: number;
  };
  time?: string;
};

export type CalendarExpirationOverrideMutationResult = {
  calendars: Array<{ id: number; expirationDate: string; originalExpirationDate: string }>;
  assetIdentifier: string;
};

const calendarExpirationOverrideMutationFn = async (payload: CalendarExpirationOverrideMutationPayload) => {
  const requestBody: ApiCalendarExpirationOverrideRequestModel = {
    calendar_ids: payload.calendarIds,
    date: payload.date,
  };

  if (payload.time) requestBody.time = payload.time;

  const response = await updateCalendarExpirationDate(requestBody);

  return {
    assetIdentifier: payload.assetIdentifier,
    calendars: response.calendars.map((calendar) => ({
      id: calendar.id,
      expirationDate: calendar.expiration_date,
      originalExpirationDate: calendar.original_expiration_date,
    })),
  };
};

export const useCalendarExpirationOverrideMutation = (): UseMutationResult<
  CalendarExpirationOverrideMutationResult,
  Error,
  CalendarExpirationOverrideMutationPayload
> => {
  const queryClient = useQueryClient();

  const { query } = useAtomValue(productApprovalManagerAtom);

  const { enqueueErrorEvent, enqueueSuccessEvent } = useSnackbar();

  return useMutation<CalendarExpirationOverrideMutationResult, Error, CalendarExpirationOverrideMutationPayload>({
    mutationFn: calendarExpirationOverrideMutationFn,
    onSuccess: (data, payload) => {
      const key = ProductApprovalQueryKeyFactory.products(query);
      queryClient.setQueryData<ProductApprovalCalendarResult>(key, (prev) => {
        if (!prev) return undefined;

        const updatedCalendars = [...prev.calendars];

        const mappedCalendars = updatedCalendars.map((calendar) => {
          const updatedCalendarInfo = data.calendars.find(({ id }) => id === calendar.id);
          if (!updatedCalendarInfo) return calendar;
          else return { ...calendar, ...updatedCalendarInfo };
        });

        return { ...prev, calendars: mappedCalendars };
      });

      const calendarCount = payload.calendarIds.length;
      const multiple = calendarCount > 1;
      const message = multiple ? `Successfully Updated Product Listings` : `Successfully Updated Product Listing`;
      const subMessage = multiple ? `${calendarCount} Products` : payload.assetIdentifier.toUpperCase();
      enqueueSuccessEvent({ message, subMessage });
    },
    onError: (_, payload) => {
      const calendarCount = payload.calendarIds.length;
      const multiple = calendarCount > 1;
      const message = multiple ? `Failed to Update Product Listings` : `Failed to Update Product Listing`;
      const subMessage = multiple ? `${calendarCount} Products` : payload.assetIdentifier.toUpperCase();
      enqueueErrorEvent({ message, subMessage });
    },
  });
};
