import { ReactElement } from 'react';

import { ActionButton } from '@halo-common/components';
import { EASTERN_DAYLIGHT_TIME_ZONE, MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { ApproveOrderModalFields } from '@halo-common/modals';
import { CalendarModel } from '@halo-common/models';
import { Stack } from '@halodomination/halo-fe-common';
import { Button, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';

export type ApproveOrderModalFooterProps = {
  calendar?: CalendarModel | null;
  approvable?: boolean;
  deniable?: boolean;
  loading?: boolean;
  approving?: boolean;
  rejecting?: boolean;
  onClose: () => void;
  onApprove?: (data: ApproveOrderModalFields) => void;
  onReject?: (data: ApproveOrderModalFields) => void;
};

// TODO: When Pershing is migrated to react query we need to rework this entire modal
export const ApproveOrderModalFooter = ({
  calendar,
  approvable = true,
  deniable = true,
  loading = false,
  approving = false,
  rejecting = false,
  onApprove,
  onReject,
  onClose,
}: ApproveOrderModalFooterProps): ReactElement => {
  const { handleSubmit, formState, setError } = useFormContext<ApproveOrderModalFields>();

  const isInvalid = Boolean(formState.errors?.reason?.message);
  const disableRejectButton = isInvalid || loading || approving || rejecting;
  const disableApproveButton = loading || rejecting;

  const closeDate = calendar?.expirationDate;
  const date = closeDate ? DateTime.fromISO(closeDate) : DateTime.now();
  const dateText = date.setZone(EASTERN_DAYLIGHT_TIME_ZONE).toFormat(MONTH_DAY_YEAR_DATE_FORMAT);

  const statusModifiable = approvable || deniable;

  const handleReject = handleSubmit((data) => {
    if (!data.reason) setError('reason', new Error('This field is required to deny.'), { shouldFocus: true });
    else onReject?.(data);
  });
  const handleApprove = handleSubmit((data) => onApprove?.(data));

  const approveButton = approvable ? (
    <ActionButton
      type="button"
      variant="contained"
      color="primary"
      onClick={handleApprove}
      disabled={disableApproveButton}
      loading={approving}
    >
      Confirm
    </ActionButton>
  ) : null;

  const denyButton = deniable ? (
    <ActionButton
      type="button"
      variant="outlined"
      color="primary"
      onClick={handleReject}
      disabled={disableRejectButton}
      loading={rejecting}
    >
      Deny
    </ActionButton>
  ) : null;

  return statusModifiable ? (
    <Stack direction="row" justify="space-between" alignItems="center">
      <Typography variant="body2">Closes {dateText}</Typography>
      <Stack direction="row" spacing={1}>
        {denyButton}
        {approveButton}
      </Stack>
    </Stack>
  ) : (
    <Stack direction="row" justify="flex-end">
      <Button type="button" variant="contained" color="primary" onClick={onClose}>
        Close
      </Button>
    </Stack>
  );
};
