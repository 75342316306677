import { useHaloInfiniteQuery } from '@halo-common/hooks';
import {
  InfinitePaginationModel,
  InfiniteQueryOptions,
  UserActivityAction,
  UserActivityModel,
  UserModel,
  UserRelationshipManagerModel,
} from '@halo-common/models';
import { getUserActivity } from '@halo-data-sources/clients';
import { ApiUserActivityMapper } from '@halo-data-sources/mappers';
import { AdminUserQueryKeyFactory } from '@halo-data-sources/queries';

export type AdminUserActivitiesInfiniteQueryPayload = {
  relationshipManagers?: Array<UserRelationshipManagerModel>;
  actions?: Array<UserActivityAction>;
  users?: Array<UserModel>;
};

export type AdminUserActivitiesInfiniteQueryResult = {
  activities: Array<UserActivityModel>;
  pagination: InfinitePaginationModel;
};

export type AdminUserActivitiesInfiniteQueryParams = {
  page: string;
  per_page: string;
  relationship_managers?: string;
  actions?: string;
  users?: string;
};

const getAdminUserActivitiesFn = async (
  payload: AdminUserActivitiesInfiniteQueryPayload | undefined,
  pageParams: InfinitePaginationModel,
) => {
  const page = pageParams.next;
  const resultsPerPage = pageParams.resultsPerPage;

  const params: AdminUserActivitiesInfiniteQueryParams = {
    page: page.toString(),
    per_page: resultsPerPage.toString(),
  };

  if (payload?.actions?.length) params.actions = payload?.actions?.map(({ name }) => name)?.join(',');
  if (payload?.users?.length) params.users = payload?.users?.map(({ id }) => id)?.join(',');
  if (payload?.relationshipManagers?.length) {
    params.relationship_managers = payload?.relationshipManagers.map(({ id }) => id)?.join(',');
  }

  const searchParams = new URLSearchParams(params);

  const response = await getUserActivity(searchParams);

  const activities = Object.values(response.objects).map(ApiUserActivityMapper.toUserActivity);

  const pagination: InfinitePaginationModel = {
    page: pageParams.next,
    resultsPerPage: pageParams.resultsPerPage,
    next: pageParams.next + 1,
    totalResults: response.meta.total,
    totalPages: response.meta.total_pages,
  };

  return { activities, pagination };
};

export const useAdminUserActivitiesInfiniteQuery = (
  payload?: AdminUserActivitiesInfiniteQueryPayload,
  options?: InfiniteQueryOptions<AdminUserActivitiesInfiniteQueryResult>,
) =>
  useHaloInfiniteQuery<AdminUserActivitiesInfiniteQueryResult>({
    queryKey: AdminUserQueryKeyFactory.activity(payload),
    queryFn: ({ pageParam }) => getAdminUserActivitiesFn(payload, pageParam),
    ...options,
  });
