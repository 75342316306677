import { ReactElement } from 'react';

import { AdminCalendarCard } from '@halo-common/components';
import { AdminAllocationTable } from '@halo-modules/admin';
import { Skeleton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { v4 as uuid } from 'uuid';

const SKELETON_CARDS = [uuid(), uuid(), uuid(), uuid()];
const EMPTY_COLUMNS: Array<GridColDef> = [];

export const AdminLoadingCardList = (): ReactElement => {
  const loadingCardStyling = { m: 2 };

  return (
    <>
      {SKELETON_CARDS.map((id) => (
        <AdminCalendarCard key={id} sx={loadingCardStyling} loading actions={<Skeleton width={64} height={20} />}>
          <AdminAllocationTable columns={EMPTY_COLUMNS} loading />
        </AdminCalendarCard>
      ))}
    </>
  );
};
