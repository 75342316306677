import { ReactElement } from 'react';

import { complianceApprovalModalAtom, complianceApprovalQueryAtom } from '@halo-atoms/complianceApproval';
import { ApproveOrderModal, ApproveOrderModalFields } from '@halo-common/modals';
import { useWebSocketContext } from '@halo-common/providers';
import { useCalendarApprovalMutation, useCalendarFixRejectionMutation } from '@halo-data-sources/mutations';
import { AllocationClientQueryKeyFactory, useFixOrderQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';

export const ComplianceApprovalModal = (): ReactElement => {
  const queryClient = useQueryClient();

  const { events } = useWebSocketContext();

  const [complianceApprovalModal, setComplianceApprovalModal] = useAtom(complianceApprovalModalAtom);
  const filters = useAtomValue(complianceApprovalQueryAtom);

  const { data: userInfo } = useUserInfoQuery();

  const { open, calendar, allocation } = complianceApprovalModal;

  const { data, isPending: fetchingFixOrder } = useFixOrderQuery(allocation?.id);

  const fixOrder = data?.fixOrder;
  const calendarPageId = userInfo?.organization?.calendarPageId;
  const query = { ...filters, calendarPageId };

  const { mutate: reject, isPending: isRejecting } = useCalendarFixRejectionMutation();
  const { mutate: approve, isPending: isApproving } = useCalendarApprovalMutation({
    onSuccess: () => {
      void queryClient.refetchQueries({ queryKey: AllocationClientQueryKeyFactory.adminInfinite(query) });
      setComplianceApprovalModal({ open: false });
    },
  });

  const rejecting = events.fixAllocation.contains(allocation?.id) || isRejecting;

  const handleCloseApproveOrderModal = () => {
    setComplianceApprovalModal({ open: false });
  };

  const handleRejection = (data: ApproveOrderModalFields) => {
    if (data.reason && allocation) {
      reject({ id: allocation.id, reason: data.reason });
    }
  };

  const handleApproval = (data: ApproveOrderModalFields) => {
    if (allocation) {
      approve({ id: allocation.id, reason: data.reason });
    }
  };

  return (
    <ApproveOrderModal
      calendar={calendar}
      allocation={allocation}
      fixOrder={fixOrder}
      open={open}
      onClose={handleCloseApproveOrderModal}
      approving={isApproving}
      rejecting={rejecting}
      loading={fetchingFixOrder}
      onApprove={handleApproval}
      onReject={handleRejection}
    />
  );
};
