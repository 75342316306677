import { ReactElement } from 'react';

import {
  Iconography,
  IconographyProps,
  LocalizedTypography,
  LocalizedTypographyProps,
} from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';

const containerSx = {
  width: '100%',
};

export type ProductCardNoteExpandedDetailsRowProps = {
  icon?: IconographyProps['iconName'];
  text: string;
  label?: string;
  localization?: LocalizedTypographyProps['dynamicContent'];
};

export const ProductCardNoteExpandedDetailsRow = ({
  icon = 'info-circle',
  text,
  label,
  localization,
}: ProductCardNoteExpandedDetailsRowProps): ReactElement => (
  <Stack sx={containerSx} direction="row" alignItems="center" spacing={1}>
    <Iconography iconName={icon} prefix="fal" color="text.secondary" />
    <LocalizedTypography
      display="block"
      variant="caption"
      fontWeight="fontWeightMedium"
      color="primary"
      dynamicContent={localization}
    >
      {text}
    </LocalizedTypography>
    {label ? (
      <LocalizedTypography display="block" variant="caption" color="textSecondary" dynamicContent={localization}>
        {label}
      </LocalizedTypography>
    ) : null}
  </Stack>
);
