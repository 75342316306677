import { AuctionAdminModel, QuoteAuctionModel, AuctionsByStatusModel, IssuerModel } from '@halo-common/models';
import { HaloAppState } from '@halo-stores/types';
import { createSelector } from '@reduxjs/toolkit';

export const selectState = createSelector(
  ({ Orders }: HaloAppState) => Orders,
  (state) => state,
);

export const selectSelectedAuction = createSelector(
  ({ Orders }: HaloAppState) => Orders.selectedAuction,
  (selectedAuction) =>
    (selectedAuction
      ? { ...selectedAuction, note: { ...selectedAuction.note, tradables: [...selectedAuction.note.tradables] } }
      : {}) as AuctionAdminModel,
);

export const selectSelectedQuote = createSelector(
  ({ Orders }: HaloAppState) => Orders.selectedQuote,
  (selectedQuote) => (selectedQuote ? { ...selectedQuote } : {}) as QuoteAuctionModel,
);

export const selectSelectedIssuer = createSelector(
  ({ Orders }: HaloAppState) => Orders.selectedIssuer,
  (selectedIssuer) => (selectedIssuer ? { ...selectedIssuer } : {}) as IssuerModel,
);

export const selectAuctions = createSelector(
  ({ Orders }: HaloAppState) => Orders.auctions,
  (auctions) => (auctions ? { ...auctions } : {}) as AuctionsByStatusModel,
);

export const selectTotalAuctions = createSelector(
  ({ Orders }: HaloAppState) => Orders.totalAuctions,
  (total) => total,
);

// TODO: Update return value type to be an enum
export const selectStatus = createSelector(
  ({ Orders }: HaloAppState) => Orders.status,
  (status) => status,
);

export const selectError = createSelector(
  ({ Orders }: HaloAppState) => Orders.error,
  (error) => error,
);
