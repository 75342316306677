import { ExecutionHubQuery } from '@halo-atoms/executionHub';
import {
  API_ARBITRARY_LENGTH_TO_GET_ALL_DATA,
  API_ARBITRARY_PAGE_TO_GET_ALL_DATA,
} from '@halo-common/constants/api/api';
import { SortModelDirectionEnum } from '@halo-common/enums';
import { useHaloInfiniteQuery } from '@halo-common/hooks';
import {
  AllocationModel,
  CalendarModel,
  ExecutionOrderModel,
  InfinitePaginationModel,
  InfiniteQueryOptions,
  OrderTicketReceiptSummaryModel,
} from '@halo-common/models';
import {
  getCalendars,
  postCalendarAdminOrderFiltersQuery,
  postCalendarAdminOrdersQuery,
} from '@halo-data-sources/clients';
import { ApiComparisonOptionEnum } from '@halo-data-sources/enums';
import {
  ApiAllocationMapper,
  ApiCalendarsMapper,
  ApiExecOrderMapper,
  ExecutionHubMapper,
} from '@halo-data-sources/mappers';
import { OrdersClientQueryKeyFactory } from '@halo-data-sources/queries';
import { EHOrderManagerHandler, useEHOrderManager } from '@halo-modules/admin';

export type ExecutionHubOrder = {
  id: number;
  allocation: AllocationModel;
  executionOrder: ExecutionOrderModel;
  transactionSummary?: OrderTicketReceiptSummaryModel;
};

export type CalendarOrderInfiniteQueryResult = {
  orders: Array<{
    calendar: CalendarModel;
    orders: Array<ExecutionHubOrder>;
    setOrderPrice: number;
    totalQuantity: number;
  }>;
  pagination: InfinitePaginationModel;
};

const DEFAULT_PAGINATION: InfinitePaginationModel = {
  totalResults: 0,
  resultsPerPage: 5,
  page: 1,
  totalPages: 0,
  next: 1,
};

const calendarOrderInfiniteQueryFn = async (
  query: ExecutionHubQuery,
  pageParam: InfinitePaginationModel,
  onEnqueueOrder: EHOrderManagerHandler,
) => {
  const calendarComparisons = ExecutionHubMapper.toApiCalendarComparisonList(query);

  const orderMetaResponse = await postCalendarAdminOrderFiltersQuery({
    filters: [
      {
        field: 'calendar_notes.id',
        type: 'OBJECTS',
        object_fields: ['calendar_notes.expiration_date'],
        sort: [{ direction: SortModelDirectionEnum.asc, field: 'calendar_notes.expiration_date' }],
      },
    ],
    comparisons: calendarComparisons,
    page: pageParam.next,
    page_length: pageParam.resultsPerPage,
  });

  const result = orderMetaResponse?.filter_values?.[0];
  const totalPages = result?.page_count ?? 0;
  const totalResults = result?.items_total_count ?? 0;
  const calendarIds = result?.objects?.map(({ object }) => object['calendar_notes.id'] as number) ?? [];

  const orderComparisons = ExecutionHubMapper.toApiCalendarOrderComparisonList(query);

  const [calendarResponse, orderResponse] = await Promise.all([
    getCalendars({
      comparisons: [
        {
          field: 'calendar_notes.id',
          value: calendarIds,
          op: ApiComparisonOptionEnum.IN,
        },
      ],
      sort: [
        {
          direction: SortModelDirectionEnum.asc,
          field: 'calendar_notes.expiration_date',
        },
      ],
    }),
    postCalendarAdminOrdersQuery({
      comparisons: [
        {
          field: 'calendar_allocations.calendar_id',
          value: calendarIds,
          op: ApiComparisonOptionEnum.IN,
        },
        ...orderComparisons,
      ],
      page: API_ARBITRARY_PAGE_TO_GET_ALL_DATA,
      page_length: API_ARBITRARY_LENGTH_TO_GET_ALL_DATA,
    }),
  ]);

  const calendars = calendarResponse?.calendars ?? [];
  const calendarOrders = orderResponse?.results ?? [];

  const orders = calendars?.map((calendar) => {
    const orders = calendarOrders.filter(({ allocation }) => allocation.calendar_id === calendar.id);

    return {
      // TODO: This will need to be handled differently when we bring on clients that do not
      //       have the same price they buy the allocations at.
      calendar: ApiCalendarsMapper.toCalendarModel(calendar),
      setOrderPrice: orders?.[0]?.allocation.price,
      totalQuantity: orders?.reduce((total, order) => total + order.exec_order.quantity, 0),
      orders: orders?.map(({ allocation, exec_order, extra }) => {
        const mappedAllocation = ApiAllocationMapper.toAllocationModel(allocation);
        const mappedExecutionOrder = ApiExecOrderMapper.toExecutionOrder(exec_order);
        const mappedTransactionSummary = ApiAllocationMapper.toOrderTicketReceiptSummary(extra);

        return {
          id: exec_order.id,
          allocation: mappedAllocation,
          executionOrder: mappedExecutionOrder,
          transactionSummary: mappedTransactionSummary,
        };
      }),
    };
  });

  const fillingOrders = orders.flatMap((calendarOrder) =>
    calendarOrder.orders.filter((order) => order.executionOrder.status === 'filling'),
  );

  if (fillingOrders.length) onEnqueueOrder(fillingOrders);

  return {
    orders,
    pagination: {
      totalResults,
      totalPages,
      page: orderMetaResponse.page,
      resultsPerPage: orderMetaResponse.page_length,
      next: pageParam.next + 1,
    },
  };
};

export const useCalendarOrderInfiniteQuery = (
  query: ExecutionHubQuery,
  options?: InfiniteQueryOptions<CalendarOrderInfiniteQueryResult>,
) => {
  const manager = useEHOrderManager();

  return useHaloInfiniteQuery<CalendarOrderInfiniteQueryResult>({
    initialPageParam: DEFAULT_PAGINATION,
    queryKey: OrdersClientQueryKeyFactory.calendarOrders(query),
    queryFn: ({ pageParam }) => calendarOrderInfiniteQueryFn(query, pageParam, manager),
    retry: false,
    ...options,
  });
};
