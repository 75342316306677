import { ToastWebsocketEvent, ToastWebsocketEventLevelEnum } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { atom } from 'jotai';

type tableRow = {
  products: number;
  pendingProducts: number;
};

export type LoadingDockUploadModel = {
  total: number;
  processed: number;
  errors: Array<string>;
  warnings: Array<string>;
  rows: {
    added: tableRow;
    edited: tableRow;
    errors: tableRow;
    total: tableRow;
  };
};

export const initialLoadingDockUploadAtom = {
  total: 0,
  processed: 0,
  errors: [],
  warnings: [],
  rows: {
    added: { products: 0, pendingProducts: 0 },
    edited: { products: 0, pendingProducts: 0 },
    errors: { products: 0, pendingProducts: 0 },
    total: { products: 0, pendingProducts: 0 },
  },
};

const _loadingDockUploadAtom = atom<LoadingDockUploadModel>(initialLoadingDockUploadAtom);

export const loadingDockUploadAtom = atom(
  (get) => get(_loadingDockUploadAtom),
  (get, set, payload?: ToastWebsocketEvent) => {
    if (!payload) {
      set(_loadingDockUploadAtom, {
        ...initialLoadingDockUploadAtom,
        rows: {
          added: { products: 0, pendingProducts: 0 },
          edited: { products: 0, pendingProducts: 0 },
          errors: { products: 0, pendingProducts: 0 },
          total: { products: 0, pendingProducts: 0 },
        },
      });
      return;
    }

    const prev = get(_loadingDockUploadAtom);

    const updatedErrors = [...prev.errors];
    const updatedWarnings = [...prev.warnings];
    const updatedRows = { ...prev.rows };
    const column = payload.data.is_pending ? 'pendingProducts' : 'products';

    if (payload.data.is_added) updatedRows.added[column] += 1;
    if (payload.data.is_edited) updatedRows.edited[column] += 1;

    if (payload.level === ToastWebsocketEventLevelEnum.ERROR) {
      const defaultMessage = translations.messages.errorContactSupport;
      const message = `${payload.title}: ${payload.message || defaultMessage}`;
      updatedRows.errors[column] += 1;
      updatedErrors.push(message);
    }

    if (payload.level === ToastWebsocketEventLevelEnum.WARNING) {
      const defaultMessage = translations.loadingDock.uploadFile.uploadWarning;
      const message = `${payload.title}: ${payload.message || defaultMessage}`;
      updatedWarnings.push(message);
    }

    updatedRows.total[column] += 1;

    set(_loadingDockUploadAtom, {
      ...prev,
      errors: updatedErrors,
      total: payload.data.total,
      processed: payload.data.processed,
      rows: updatedRows,
    });
  },
);
