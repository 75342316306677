import { ReactElement } from 'react';

import { ProductDetailPanelSection } from '@halo-common/layouts';
import { PortfolioOrderModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Box, Skeleton } from '@mui/material';

const logoImageSx = {
  backgroundColor: 'transparent',
  maxHeight: 40,
  width: 'fit-content',
};

export type PostTradeNoteDetailIssuerSectionProps = {
  product?: PortfolioOrderModel | null;
  loading: boolean;
};

export const PostTradeNoteDetailIssuerSection = ({
  product,
  loading,
}: PostTradeNoteDetailIssuerSectionProps): ReactElement | null => {
  const { data: userInfo } = useUserInfoQuery();

  const termsheet = product?.termsheet;
  const issuer = termsheet?.issuer;
  const hideSection = !loading && !issuer?.ergonomicId;

  if (hideSection) return null;

  const staticAssetUrl = userInfo?.configuration.staticAssetUrl;
  const issuerId = issuer?.ergonomicId as string;
  const issuerLogoUrl = `${staticAssetUrl}/assets/images/svg/other/${issuerId}.svg`;

  const issuerContent = loading ? (
    <Skeleton width={150} height={40} variant="rounded" />
  ) : (
    <Box component="img" sx={logoImageSx} alt={issuerId} src={issuerLogoUrl} />
  );

  return (
    <ProductDetailPanelSection title={translations.pdm.common.issuerSection} loading={loading}>
      {issuerContent}
    </ProductDetailPanelSection>
  );
};
