import { ChangeEvent, ReactElement, useEffect, useState } from 'react';

import { portfolioPositionsColumnWidthAtom, portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import {
  AssetIdentifierEnum,
  PortfolioPositionsTableSearchNameEnum,
  SortModelDirectionEnum,
  SortModelNullSortEnum,
} from '@halo-common/enums';
import { GridPaginationModel, PositionsDetailsSectionModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { hash } from '@halo-common/utils';
import { useRecordActivityMutation } from '@halo-data-sources/mutations';
import { usePortfolioPositionsQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import {
  CompositionEnum,
  PortfolioPositionsExcelExportButton,
  usePortfolioDetailsTableColumns,
} from '@halo-modules/app';
import {
  GridColumnResizeParams,
  GridSortModel,
  HaloDataGrid,
  LocalizedFormControlLabel,
  LocalizedTypography,
  Tabs,
} from '@halodomination/halo-fe-common';
import { Checkbox, Stack } from '@mui/material';
import { GridPinnedColumnFields, gridClasses } from '@mui/x-data-grid-pro';
import { useAtom } from 'jotai';

export const MUI_COLUMN_HEADER_CLASS_NAME = 'MuiDataGrid-columnHeaderTitle';
export const INACTIVE_POSITION_CLASS_NAME = 'portfolio-inactive-position';

const tableSx = {
  mt: 2,
  [`& .${MUI_COLUMN_HEADER_CLASS_NAME}`]: {
    borderRight: '1px solid',
    borderColor: 'grey.300',
    lineHeight: 1,
  },
  [`& .${gridClasses.cell}`]: {
    borderRight: '1px solid',
    borderColor: 'grey.300',
  },
  [`& .${INACTIVE_POSITION_CLASS_NAME}`]: {
    backgroundColor: 'grey.200',
  },
};

export type PortfolioPositionsDetailsTableProps = {
  loading: boolean;
};

export const PortfolioPositionsDetailsTable = ({ loading }: PortfolioPositionsDetailsTableProps): ReactElement => {
  const [selectedTab, setTab] = useState(0);
  const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumnFields | undefined>();

  const [positionData, setPositionData] = useAtom(portfolioPositionsManagerAtom);
  const [columnWidthDict, setColumnWidthDict] = useAtom(portfolioPositionsColumnWidthAtom);

  const { data: userInfo } = useUserInfoQuery();
  const { mutate: recordActivity } = useRecordActivityMutation();

  const { filters, detailsQuery, pagination } = positionData;
  const { includeInactive, noteFeatures } = filters;

  const positionsQueryEnabled = Boolean(CompositionEnum.Product in detailsQuery.filters && userInfo);
  const positionsQueryOptions = { enabled: positionsQueryEnabled };

  const { data: positionMeta, isFetching } = usePortfolioPositionsQuery(detailsQuery, positionsQueryOptions);

  const sections = positionMeta?.sections ?? [];
  const tabs = positionMeta?.filters ?? [];
  const positionMetaPagination = positionMeta?.pagination;

  const selectedSection = sections.find((section: PositionsDetailsSectionModel) => section.name === tabs[selectedTab]);

  const selectedPositions = selectedSection?.positions ?? [];
  const selectedSectionColumns = selectedSection?.columns ?? [];
  const selectedSectionName = selectedSection?.name ?? 'All Info';
  const selectedSectionWidths = columnWidthDict[selectedSectionName] ?? {};
  const primaryAssetIdentifier = userInfo?.whiteLabel.primaryAssetIdentifier;

  const isLoadingPositions = positionsQueryEnabled && isFetching;
  const isTableLoading = loading || isLoadingPositions;

  const currentRowCount = positionMetaPagination?.totalResults ?? 0;
  const currentPage = positionMetaPagination?.page ? positionMetaPagination?.page - 1 : 0;

  const hasPages = pagination.resultsPerPage < currentRowCount;
  const paginationModel = { page: currentPage, pageSize: pagination.resultsPerPage };

  const { columns, menuItems } = usePortfolioDetailsTableColumns(selectedTab, isTableLoading);

  const handleInactiveChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setPositionData({ includeInactive: checked });
  };

  const handlePageChange = (model: GridPaginationModel) => {
    setPositionData({ positionDetailsPagination: { ...pagination, page: model.page + 1 } });
  };

  const handleColumnWidthChange = (params: GridColumnResizeParams) => {
    const key = params.colDef.field;
    const width = params.width;
    const updatedColumnWidths = { ...selectedSectionWidths, [key]: width };
    setColumnWidthDict({ name: selectedSectionName, entry: updatedColumnWidths });
  };

  const handleSort = (model: GridSortModel) => {
    if (!model.length) return;

    const newSortModel = model.map((option) => {
      const foundColumn = selectedSection?.columnDictionary.find((column) => column.displayName === option.field);
      const foundSortField = foundColumn?.ergName ?? 'product_name';

      const newSortField = foundSortField === 'fundserv' ? 'fundservcode' : foundSortField;
      const newSortDirection = (option.sort as SortModelDirectionEnum) ?? SortModelDirectionEnum.desc;
      const newSortAscending = newSortDirection === SortModelDirectionEnum.asc;
      const newSortNulls = newSortAscending ? SortModelNullSortEnum.first : SortModelNullSortEnum.last;

      return { field: newSortField, direction: newSortDirection, nulls: newSortNulls };
    });

    setPositionData({ positionDetailsSort: newSortModel });
  };

  useEffect(() => {
    if (selectedSectionColumns.length) {
      const secondaryColumn = selectedSectionColumns.find((c) => (c as AssetIdentifierEnum) === primaryAssetIdentifier);
      const updatedColumns = ['actions', PortfolioPositionsTableSearchNameEnum.shortName];

      if (secondaryColumn) updatedColumns.push(secondaryColumn);

      setPinnedColumns({ left: updatedColumns });
    }
  }, [selectedSectionColumns]);

  useEffect(() => {
    if (includeInactive) {
      const features = noteFeatures.join(', ');

      recordActivity({
        activity: [{ verb: `show inactive notes for ${features}`, model_name: 'Portfolio' }],
        fromIFrame: false,
      });
    }
  }, [noteFeatures]);

  return (
    <Stack direction="column">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <LocalizedTypography variant="h6">{translations.portfolio.positions.positionsTableTitle}</LocalizedTypography>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <LocalizedFormControlLabel
            control={<Checkbox checked={includeInactive} onChange={handleInactiveChange} />}
            label={translations.portfolio.positions.includeInactiveFilter}
          />
          <PortfolioPositionsExcelExportButton loading={isTableLoading} />
        </Stack>
      </Stack>
      <Tabs variant="pills" onChange={setTab} value={selectedTab} tabs={tabs} loading={isTableLoading} />
      <HaloDataGrid
        height={hasPages ? 600 : 'auto'}
        columns={columns}
        density="compact"
        getRowId={(row) => hash(row)}
        getRowClassName={(data) => {
          const isInactive = !isTableLoading && !data.row.isActive;
          return isInactive ? INACTIVE_POSITION_CLASS_NAME : '';
        }}
        loading={isTableLoading}
        menuItems={menuItems}
        onSortModelChange={handleSort}
        noResultsMessage={translations.messages.noResults}
        onPinnedColumnsChange={setPinnedColumns}
        pagination={hasPages}
        paginationMode="server"
        paginationModel={paginationModel}
        disableColumnMenu
        sortingMode="server"
        onPaginationModelChange={handlePageChange}
        pageSizeOptions={[pagination.resultsPerPage]}
        pinnedColumns={pinnedColumns}
        rowCount={currentRowCount}
        rows={selectedPositions}
        sx={tableSx}
        onColumnWidthChange={handleColumnWidthChange}
      />
    </Stack>
  );
};
