import { useState } from 'react';

import { EmptyResultsCard, ProductCard } from '@halo-common/components';
import { ProductTypeEnum } from '@halo-common/enums';
import { PageFooterLayout } from '@halo-common/layouts';
import { PreTradeNoteDetailModal } from '@halo-common/modals';
import { translations } from '@halo-common/translations';
import { useNoteQuery, useRecentQuotesQuery } from '@halo-data-sources/queries';
import { BrandIcon, LocalizedButton, PageComponent, PageMeta } from '@halodomination/halo-fe-common';
import { Skeleton, Stack } from '@mui/material';
import { useRouter } from 'next/router';

const containerSx = {
  minHeight: '100vh',
  width: '100%',
};

const NotePage: PageComponent = () => {
  const router = useRouter();

  const [openPDM, setOpenPDM] = useState(false);

  const noteId = Number(router.query?.noteId);
  const quotePayload = noteId ? [noteId] : [];

  const { data: note, isPending, isError } = useNoteQuery(noteId, { enabled: !!router.isReady });

  useRecentQuotesQuery(quotePayload);

  if (isPending) {
    return (
      <Stack sx={containerSx} direction="row" justifyContent="center" alignItems="center">
        <Skeleton variant="rounded" width={350} height={300} sx={{ borderRadius: 3 }} />
      </Stack>
    );
  }

  const isNoteFound = !isError && note;

  const content = isNoteFound ? (
    <ProductCard product={note} type={ProductTypeEnum.note} onClick={() => setOpenPDM(true)} />
  ) : (
    <EmptyResultsCard
      sx={{ width: '700px' }}
      title={translations.messages.noteNotFound}
      description={translations.messages.noteMissingAccess}
      action={
        <LocalizedButton type="button" variant="contained" color="primary" onClick={() => router.push(router.basePath)}>
          {translations.messages.returnHome}
        </LocalizedButton>
      }
      brandIcon={<BrandIcon variant="trackEvents" color="primary" />}
    />
  );

  return (
    <>
      <PageFooterLayout>
        <Stack sx={containerSx} direction="row" justifyContent="center" alignItems="center">
          {content}
        </Stack>
      </PageFooterLayout>
      <PreTradeNoteDetailModal open={openPDM} onClose={() => setOpenPDM(false)} product={note} />
    </>
  );
};

export const NotePageMeta: PageMeta = {
  pageName: 'Note',
  route: '/app/note/[noteId]',
  routeExpression: '/note/\\d+',
};

NotePage.pageName = NotePageMeta.pageName;
NotePage.route = NotePageMeta.route;

export default NotePage;
