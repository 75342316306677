import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { HaloLogo, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Paper, Stack } from '@mui/material';

import { OnboardingSteps } from './OnboardingSteps';

const paperSx = {
  borderRadius: 3,
  maxWidth: 740,
  py: 3,
  px: 6,
};

export const OnboardingCard = (): ReactElement | null => {
  const { data: userInfo } = useUserInfoQuery();

  const { isOnboardingCompleted } = userInfo?.details?.organization || {};

  if (isOnboardingCompleted) return null;

  return (
    <Paper sx={paperSx}>
      <Stack direction="row" alignItems="center" spacing={5} mb={2}>
        <HaloLogo variant="getStarted" width="158" />
        <LocalizedTypography variant="subtitle1" color="text.secondary" flex="1 1 auto">
          {translations.onboarding.suitability.everythingYouNeedToStartTradingNotesAndMore}
        </LocalizedTypography>
      </Stack>
      <OnboardingSteps />
    </Paper>
  );
};
