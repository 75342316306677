import { CalendarQueryModel } from '@halo-atoms/calendars';
import { translations } from '@halo-common/translations';
import { downloadFile } from '@halo-common/utils';
import { getCalendarOfferings, getDocgenToken, postRequestBuildDoc } from '@halo-data-sources/clients';
import { CalendarsMapper } from '@halo-data-sources/mappers';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';

export type GenerateCalendarPDFPayload = {
  approved: boolean | null;
  whiteLabelId?: number;
  totalResults: number;
  filters: CalendarQueryModel;
};

const generateCalendarPDFMutationFn = async (payload: GenerateCalendarPDFPayload) => {
  if (!payload.whiteLabelId) return undefined;

  const calendarsRequest = {
    ...payload.filters,
    pageLength: payload.totalResults,
    page: 1,
    showAsNME: false,
    isVisible: true,
    approved: payload.approved,
  };

  const mappedRequest = CalendarsMapper.toCalendarsRequest(calendarsRequest);
  const calendarsResponse = await getCalendarOfferings(mappedRequest);

  const calendarIds = calendarsResponse.calendars.map((calendar) => calendar.id);

  const buildQueryString = 'endpoint=documents/generate/first-successful/calendar_export&ergonomic_id=calendar_export';

  const buildToken = await getDocgenToken(buildQueryString);
  const response = await postRequestBuildDoc(buildToken.url, {
    white_label_id: payload.whiteLabelId,
    calendar_note_ids: [...calendarIds],
  });

  const downloadQueryString = 'endpoint=history/download/&ergonomic_id=calendar_export';
  const downloadToken = await getDocgenToken(downloadQueryString);

  const fileName = response.file_name;
  const filePath = response.file_path;
  const downloadFilePath = `${downloadToken.url}&file_key=${filePath}`;

  downloadFile({ fileName, downloadFilePath });
};

export const useGenerateCalendarPDFMutation = (
  options?: UseMutationOptions<void, Error, GenerateCalendarPDFPayload>,
): UseMutationResult<void, Error, GenerateCalendarPDFPayload> => {
  const { data: userInfo } = useUserInfoQuery();

  const { enqueueSuccessEvent, enqueuePendingEvent, enqueueErrorEvent, closeSnackbar } = useSnackbar();

  const whiteLabelId = userInfo?.whiteLabel?.id;

  return useMutation({
    mutationFn: (variables) => generateCalendarPDFMutationFn({ whiteLabelId, ...variables }),
    onMutate: ({ totalResults }) => {
      enqueuePendingEvent({ message: `Exporting a PDF of ${totalResults} Calendar search results.` });
    },
    onSuccess: () => {
      closeSnackbar();
      enqueueSuccessEvent({
        message: translations.calendars.common.successfullyExportedPDF,
        subMessage: translations.calendars.common.checkYourBrowsersDownloadedFilesMenu,
      });
    },
    onError: () => {
      closeSnackbar();
      enqueueErrorEvent({
        message: translations.calendars.common.somethingWentWrongGeneratingThePDF,
        subMessage: translations.calendars.common.tryAgainInAMomentOrWithDifferentFilters,
      });
    },
    ...options,
  });
};
