import { useEffect, useState } from 'react';

import { DocumentBucketModel } from '@halo-common/models';
import { isDocumentFromS3 } from '@halo-common/utils';

export const useGenerateFilePreviewLink = (file: File | DocumentBucketModel | null | undefined): string | null => {
  const [previewLink, setPreviewLink] = useState<string | null>(null);

  useEffect(() => {
    if (file instanceof File) {
      const reader = new FileReader();

      reader.onload = () => {
        setPreviewLink(reader.result as string);
      };

      reader.readAsDataURL(file);

      return () => {
        if (previewLink) URL.revokeObjectURL(previewLink);
      };
    } else if (isDocumentFromS3(file)) {
      setPreviewLink(file.path ?? null);
    } else {
      setPreviewLink(null);
    }
  }, [file]);

  return previewLink;
};
