import { UserAlertModel } from '@halo-common/models';
import { ApiUserAlertModel } from '@halo-data-sources/models';

export const ApiUserAlertsMapper = {
  toUserAlert: (alert: ApiUserAlertModel): UserAlertModel => ({
    id: alert.id?.toString(),
    alertType: {
      id: alert.alert_type.id.toString(),
      ergonomicId: alert.alert_type.ergonomic_id,
      name: alert.alert_type.name,
      enabled: alert.alert_type.enabled,
      category: alert.alert_type.category,
      icon: alert.alert_type.icon,
      color: alert.alert_type.color,
      description: alert.alert_type.description,
    },
    haloUserId: alert.halo_user_id,
    haloUserName: alert.halo_user_name,
    haloUserEmail: alert.halo_user_email,
    emailEnabled: alert.email_enabled,
    notificationEnabled: alert.notification_enabled,
  }),
};
