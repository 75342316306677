import { ReactElement } from 'react';

import { ProductFeatureList, ProductNoteTypeChip } from '@halo-common/components';
import { V2NoteModel } from '@halo-common/models';
import { Stack } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';

const featureListContainerSx = { marginBottom: 2 };

export type V2PostTradeNoteDetailModalHeaderFeaturesProps = {
  type?: V2NoteModel['type'];
  features?: V2NoteModel['features'];
  descriptionAsLabel?: boolean;
  loading?: boolean;
};

export const V2PostTradeNoteDetailModalHeaderFeatures = ({
  features = [],
  type,
  descriptionAsLabel = false,
  loading = true,
}: V2PostTradeNoteDetailModalHeaderFeaturesProps): ReactElement => {
  if (loading) {
    return (
      <Stack direction="row" spacing={1}>
        <Skeleton width={58} height={32} />
        <Skeleton width={58} height={32} />
        <Skeleton width={58} height={32} />
        <Skeleton width={58} height={32} />
      </Stack>
    );
  }

  return (
    <ProductFeatureList
      sx={featureListContainerSx}
      type={type}
      subtype={type}
      features={features}
      descriptionAsLabel={descriptionAsLabel}
    >
      <ProductNoteTypeChip type={type} subtype={type} />
    </ProductFeatureList>
  );
};
