export const ADD_ACCOUNT_TRANSLATIONS = {
  accountHelperMessage: 'Account number can not be changed.',
  custodianHelperMessage: 'Custodian can not be changed.',
  formCustodianRequiredError: 'Custodian is required.',
  formDuplicateError: 'This account already exists.',
  formNameRequiredError: 'Account Name is required.',
  formNumberRequiredError: 'Account Number is required.',
  simpleFormTitle: 'Create New Client Account',
  wizardFormCreateAccountTitle: 'Create New Client Account',
  wizardFormCreateAnotherButton: 'Create Another',
  wizardFormCreateSuccessTitle: 'Successfully Created Client',
  wizardFormUpdatedAccountTitle: 'Update Client Account',
  wizardFormUpdateSuccessTitle: 'Successfully Updated Client',
};
