import { ReactElement } from 'react';

import { HaloProgressBar } from '@halo-common/components';
import { NoteCouponsPerYearEnum } from '@halo-common/enums';
import { useQuoteSelector } from '@halo-common/hooks';
import { NoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useNoteIncomeOutcomesQuery } from '@halo-data-sources/queries';
import { HaloDataGrid, HaloDataGridProps } from '@halodomination/halo-fe-common';
import { useT } from '@transifex/react';

export type PreTradeNoteDetailModalCouponScheduleTableProps = {
  product?: NoteModel | null;
  loading?: boolean;
};

export const PreTradeNoteDetailModalCouponScheduleTable = ({
  product,
  loading,
}: PreTradeNoteDetailModalCouponScheduleTableProps): ReactElement => {
  const translator = useT();

  const id = product?.id;
  const quote = useQuoteSelector(id);

  const { data, isPending: incomeOutcomesLoading } = useNoteIncomeOutcomesQuery(id);

  const rows = data?.couponSchedule ?? [];
  const isLoading = loading || incomeOutcomesLoading;

  const commonCellProps = {
    editable: false,
    resizable: false,
    disableReorder: true,
    disableColumnMenu: true,
    flex: 1,
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      field: 'months',
      headerName: translations.common.timeElapsed,
      valueFormatter: (value) => {
        const monthsText = translator(translations.pdm.rollingReturns.averageLifetimeMonths, { months: value });
        return value ? monthsText : 'N/A';
      },
    },
    {
      ...commonCellProps,
      field: 'amount',
      headerName: translations.common.amount,
      valueGetter: () => {
        const quoteValue = quote?.value;
        const couponPeriod = product?.coupon?.period;
        const couponsPerYear = couponPeriod ? Number(NoteCouponsPerYearEnum[couponPeriod]) : undefined;
        const value = quoteValue && couponsPerYear ? quoteValue / couponsPerYear : undefined;

        if (value) return `${value.toFixed(2)}%`;

        return '--';
      },
    },
    {
      ...commonCellProps,
      field: 'data',
      headerName: translations.common.probability,
      renderCell: ({ value }) => <HaloProgressBar value={value} />,
    },
  ];

  return <HaloDataGrid columns={columns} rows={rows} hideFooter loading={isLoading} density="compact" />;
};
