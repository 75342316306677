import { getClientConfiguration, request } from '@halo-data-sources/clients';
import { ApiEducationUpdateTopicViewResponseModel } from '@halo-data-sources/models';

const TOPIC_ROUTE = '/issuer/v1/topic';

export const updateTopic = async (topicId: number): Promise<ApiEducationUpdateTopicViewResponseModel> => {
  const clientConfiguration = await getClientConfiguration(TOPIC_ROUTE);

  return await request<ApiEducationUpdateTopicViewResponseModel>(`${clientConfiguration.basePath}/view/${topicId}`, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};
