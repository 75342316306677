import { ReactElement, useEffect, useRef, useState } from 'react';

import { IconBounceRotate } from '@halo-common/components';
import { useElementHover } from '@halo-common/hooks';
import { EducationVideoPlayer } from '@halo-modules/app';
import { Iconography, LocalizedTypography, Stack, useCombinedStyling } from '@halodomination/halo-fe-common';
import { Box, Paper, Skeleton } from '@mui/material';

const iconContainerSx = {
  pr: 4,
  pt: 2,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
};

const definitionSx = {
  margin: 2,
};

const hiddenDefinitionSx = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
};

const titleSx = {
  py: 2,
  pl: 2,
  pr: 8,
  height: '100%',
  background: 'linear-gradient(270deg, rgba(81, 180, 229, 0.064) 0%, rgba(227, 242, 250, 0) 100%)',
};

const collapsibleContainerSx = {
  transition: 'border 0.25s ease-in-out',
  '&:hover': {
    cursor: 'pointer',
    borderColor: 'primary.main',
  },
};

export type GlossaryTermProps = {
  title?: string;
  definition?: string;
  video?: string | null;
  loading?: boolean;
};

export const GlossaryTerm = ({
  title = '',
  definition = '',
  video = null,
  loading = false,
}: GlossaryTermProps): ReactElement => {
  const definitionRef = useRef<HTMLDivElement | null>(null);

  const { hovered, onEnter, onLeave } = useElementHover();

  const [showDefinition, setShowDefinition] = useState(false);
  const [isCollapsible, setIsCollapsible] = useState(true);

  const hideVideo = !showDefinition && isCollapsible;
  const videoContainerSx = hideVideo ? { display: 'none' } : { pt: 1, pb: 5, px: 2 };
  const combinedDefinitionSx = useCombinedStyling(definitionSx, showDefinition ? null : hiddenDefinitionSx);
  const containerSx = isCollapsible ? collapsibleContainerSx : undefined;

  const handleToggle = () => {
    setShowDefinition(!showDefinition);
  };

  useEffect(() => {
    const definitionHeight = definitionRef.current?.clientHeight;
    const smallDefinition = definitionHeight && definitionHeight < 40;
    const shouldHideIcon = !video && isCollapsible && smallDefinition;
    if (shouldHideIcon) setIsCollapsible(false);
  }, [definitionRef?.current]);

  if (loading) return <Skeleton variant="rounded" height={74} />;

  const videoPlayer = video ? (
    <Box sx={videoContainerSx}>
      <EducationVideoPlayer path={video} />
    </Box>
  ) : null;

  const icon = isCollapsible ? (
    <Box sx={iconContainerSx}>
      <IconBounceRotate showIcon={isCollapsible} active={hovered} rotated={showDefinition}>
        <Iconography color="text.secondary" prefix="fas" iconName="chevron-down" />
      </IconBounceRotate>
    </Box>
  ) : null;

  return (
    <Paper sx={containerSx} variant="outlined" onClick={handleToggle} onMouseEnter={onEnter} onMouseLeave={onLeave}>
      <Stack direction="row" alignItems="stretch" justify="space-between" xs={[11, 1]}>
        <Stack direction="row" alignItems="stretch" elementStyling={[{ flex: '1 215px' }, { flex: '1 1' }]}>
          <LocalizedTypography sx={titleSx} variant="h6" color="primary">
            {title}
          </LocalizedTypography>
          <Stack direction="column">
            <LocalizedTypography ref={definitionRef} sx={combinedDefinitionSx} variant="body2" color="textSecondary">
              {definition}
            </LocalizedTypography>
            {videoPlayer}
          </Stack>
        </Stack>
        {icon}
      </Stack>
    </Paper>
  );
};
