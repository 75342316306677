import { ReactElement, useState } from 'react';

import { DisclaimerUserModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useUserDisclaimersQuery } from '@halo-data-sources/queries';
import {
  AccountCard,
  CUSTODY_FORM_NAME,
  CustodyFormDownloadModal,
  CustodyFormUploadModal,
  DocumentsTable,
  DocumentsTableSkeleton,
  EmptyBox,
} from '@halo-modules/app';

export type CustodyFormSectionState = {
  openCustodyUploadModal: boolean;
  openCustodyDownloadModal: boolean;
  currentForm: DisclaimerUserModel | null;
};

export type CustodyFormsSectionProps = {
  custodyForms: Array<DisclaimerUserModel>;
};

export const CustodyFormsSection = (): ReactElement => {
  const { data: disclaimers = [], isPending: loadingFiles } = useUserDisclaimersQuery();

  const [state, setState] = useState<CustodyFormSectionState>({
    openCustodyUploadModal: false,
    openCustodyDownloadModal: false,
    currentForm: null,
  });

  const custodyForms = disclaimers.filter(
    (disclaimer) => disclaimer.name === CUSTODY_FORM_NAME && disclaimer.document.extension,
  );

  const { currentForm, openCustodyDownloadModal, openCustodyUploadModal } = state;

  const getId = (model: DisclaimerUserModel) => model.id;

  const handleDownload = (model: DisclaimerUserModel) => {
    setState((prev) => ({ ...prev, currentForm: model, openCustodyDownloadModal: true }));
  };

  const handleCloseDownloadModal = () => {
    setState((prev) => ({ ...prev, currentForm: null, openCustodyDownloadModal: false }));
  };

  const handleUpload = (model: DisclaimerUserModel) => {
    setState((prev) => ({ ...prev, currentForm: model, openCustodyUploadModal: true }));
  };

  const handleCloseUploadModal = () => {
    setState((prev) => ({ ...prev, currentForm: null, openCustodyUploadModal: true }));
  };

  const handleMapValues = (model: DisclaimerUserModel) => ({
    id: model.id,
    name: model.name,
    description: model.fileUploadDescription,
    extension: model.document?.extension ?? '',
  });

  const custodyFormUploadModal = currentForm ? (
    <CustodyFormUploadModal open={openCustodyUploadModal} custodyForm={currentForm} onClose={handleCloseUploadModal} />
  ) : null;

  const custodyFormDownloadModal = currentForm ? (
    <CustodyFormDownloadModal
      open={openCustodyDownloadModal}
      custodyForm={currentForm}
      onClose={handleCloseDownloadModal}
    />
  ) : null;

  const content =
    !custodyForms.length && !loadingFiles ? (
      <EmptyBox
        title={translations.profile.documents.noCustodyForms}
        subtitle={translations.profile.documents.noCustodyFormsSubtitle}
      />
    ) : loadingFiles ? (
      <DocumentsTableSkeleton />
    ) : (
      <DocumentsTable
        getId={getId}
        mapValues={handleMapValues}
        models={custodyForms}
        onDownload={handleDownload}
        onUpload={handleUpload}
      />
    );

  return (
    <>
      <AccountCard
        title={translations.profile.documents.custodyTitle}
        subtitle={translations.profile.documents.custodySubtitle}
      >
        {content}
      </AccountCard>
      {custodyFormUploadModal}
      {custodyFormDownloadModal}
    </>
  );
};
