import { debouncedAtom } from '@halo-atoms/debounce';
import { ComplianceApprovalStatusEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { atom } from 'jotai';
import { DateTime } from 'luxon';

export type ComplianceApprovalFilters = {
  status: ComplianceApprovalStatusEnum;
  expirationStartDate: DateTime | null;
  expirationEndDate: DateTime | null;
  issuer?: string;
};

export type ComplianceApprovalQuery = ComplianceApprovalFilters & {
  account?: string;
  cusip?: string;
  ip?: string;
};

const DEFAULT_FILTERS: ComplianceApprovalFilters = {
  status: ComplianceApprovalStatusEnum.pending,
  expirationStartDate: null,
  expirationEndDate: null,
  issuer: translations.common.all,
};

export const complianceApprovalStatusChangeReason = atom<{ id: number; reason: string } | null>(null);
export const complianceApprovalFiltersAtom = atom<ComplianceApprovalFilters>(DEFAULT_FILTERS);

const { currentValueAtom: cusipCurrent, debouncedValueAtom: cusipDebounced } = debouncedAtom('');
const _currentCusipSearchAtom = cusipCurrent;
const _debouncedCusipSearchAtom = cusipDebounced;
export const complianceApprovalCusipSearchAtom = atom(
  (get) => get(_currentCusipSearchAtom),
  (_, set, payload: string) => set(_debouncedCusipSearchAtom, payload),
);

const { currentValueAtom: accountSearchCurrent, debouncedValueAtom: accountSearchDebounced } = debouncedAtom('');
const _currentAccountSearchAtom = accountSearchCurrent;
const _debouncedAccountSearchAtom = accountSearchDebounced;
export const complianceApprovalAccountSearchAtom = atom(
  (get) => get(_currentAccountSearchAtom),
  (_, set, payload: string) => set(_debouncedAccountSearchAtom, payload),
);

const { currentValueAtom: ipSearchCurrent, debouncedValueAtom: ipSearchDebounced } = debouncedAtom('');
const _currentIPSearchAtom = ipSearchCurrent;
const _debouncedIPSearchAtom = ipSearchDebounced;
export const complianceApprovalIPSearchAtom = atom(
  (get) => get(_currentIPSearchAtom),
  (_, set, payload: string) => set(_debouncedIPSearchAtom, payload),
);

export const complianceApprovalQueryAtom = atom((get) => {
  const account = get(complianceApprovalAccountSearchAtom);
  const cusip = get(complianceApprovalCusipSearchAtom);
  const ip = get(complianceApprovalIPSearchAtom);
  const filters = get(complianceApprovalFiltersAtom);

  return { ...filters, account, cusip, ip };
});
