import { ReactElement } from 'react';

import { DisclaimerOnboardingModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';

const activeButtonSx = {
  color: 'primary.main',
  borderColor: 'primary.main',
  maxWidth: 70,
  width: 70,
  height: 36,
};

const inactiveButtonSx = {
  color: 'primary.main',
};

export type DisclaimerListButtonProps = {
  disclaimer: DisclaimerOnboardingModel | null;
  disclaimerIsAccepted: boolean;
  handleOpen: (disclaimer: DisclaimerOnboardingModel | null) => void;
};

export const DisclaimerListButton = ({
  disclaimer,
  disclaimerIsAccepted,
  handleOpen,
}: DisclaimerListButtonProps): ReactElement => {
  const openHandler = () => void handleOpen(disclaimer);

  return !disclaimerIsAccepted ? (
    <LocalizedButton variant="outlined" sx={activeButtonSx} onClick={openHandler}>
      {translations.common.open}
    </LocalizedButton>
  ) : (
    <LocalizedButton
      sx={inactiveButtonSx}
      startIcon={<Iconography iconName="check" color="primary.main" />}
      onClick={openHandler}
    >
      {translations.common.open}
    </LocalizedButton>
  );
};
