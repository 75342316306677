import { ReactElement } from 'react';

import { ProductFeatureList, ProductTypeChip } from '@halo-common/components';
import { ProductTypeEnum } from '@halo-common/enums';
import { NoteModel } from '@halo-common/models';
import { Stack } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';

const containerSx = { marginBottom: 2 };

export type PreTradeNoteDetailModalHeaderFeaturesProps = {
  product?: NoteModel | null;
  loading: boolean;
};

export const PreTradeNoteDetailModalHeaderFeatures = ({
  product,
  loading,
}: PreTradeNoteDetailModalHeaderFeaturesProps): ReactElement => {
  if (loading) {
    return (
      <Stack direction="row" spacing={1}>
        <Skeleton width={58} height={32} />
        <Skeleton width={58} height={32} />
        <Skeleton width={58} height={32} />
        <Skeleton width={58} height={32} />
      </Stack>
    );
  }

  const noteType = product?.type;
  const productType = product?.productType;
  const features = product?.features ?? [];

  const productFeatureListItems = features.map((feature) => ({
    name: feature.name,
    description: feature.description ?? '',
    dynamicContent: feature.dynamicContent,
    icon: feature.icon,
  }));

  return (
    <ProductFeatureList sx={containerSx} type={noteType} subtype={productType} features={productFeatureListItems}>
      <ProductTypeChip product={product} type={ProductTypeEnum.note} />
    </ProductFeatureList>
  );
};
