import { ReactElement } from 'react';

import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Stack } from '@mui/material';

const containerSx = {
  width: '100%',
  maxWidth: 450,
  '& a': {
    color: 'text.secondary',
    fontSize: '12px',
    paddingRight: '32px',
    py: 1,
  },
};

export const DocumentLinks = (): ReactElement => {
  const { data } = useUserInfoQuery();

  const documentLinks = data?.settings?.onboarding?.documentLinks;

  return (
    <Stack sx={containerSx} direction="row" flexWrap="wrap" dangerouslySetInnerHTML={{ __html: documentLinks ?? '' }} />
  );
};
