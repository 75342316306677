import { ReactElement, useEffect } from 'react';

import { executionHubFillModalAtom } from '@halo-atoms/executionHub';
import { ActionButton } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { useCalendarNameMutation } from '@halo-data-sources/mutations';
import { CalendarsQueryKeyFactory } from '@halo-data-sources/queries';
import {
  LocalizedAlert,
  LocalizedButton,
  LocalizedTextField,
  LocalizedTypography,
  Modal,
  Stack,
} from '@halodomination/halo-fe-common';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useFormContext } from 'react-hook-form';

const DEFAULT_ERROR_MESSAGE = 'Please enter a valid annual yield.';

export type V2ExecutionHubNoteTermModalProps = {
  open: boolean;
  fullEdit?: boolean;
  onClose: () => void;
};

export const V2ExecutionHubNoteTermModal = ({
  open,
  fullEdit = false,
  onClose,
}: V2ExecutionHubNoteTermModalProps): ReactElement => {
  const formMethods = useFormContext();
  const queryClient = useQueryClient();

  const modalState = useAtomValue(executionHubFillModalAtom);

  const calendar = modalState.calendar;
  const calendarId = calendar?.id;
  const calendarQuoteId = calendar?.quote?.id;
  const calendarQuoteValue = calendar?.quote?.value;
  const calendarName = calendar?.displayName;

  const { ref: confirmNoteValueRef, ...confirmNoteValueProps } = formMethods.register('note', {
    required: DEFAULT_ERROR_MESSAGE,
  });

  const { mutate, isPending, isError } = useCalendarNameMutation({
    onSuccess: (data, payload) => {
      if (!data) return undefined;

      const key = CalendarsQueryKeyFactory.calendarName(payload.calendarId);
      queryClient.setQueryData<string | null>(key, (prev) => {
        if (!prev) return prev;
        return data;
      });

      onClose();
    },
  });

  const hasError = isError || Boolean(formMethods.formState?.errors?.quote?.message);
  const errorMessage = hasError ? DEFAULT_ERROR_MESSAGE : undefined;

  const handleCalendarNameUpdate = formMethods.handleSubmit((data) => {
    if (fullEdit) {
      onClose();
    } else if (calendarId && calendarQuoteId) {
      mutate({ calendarId, quoteId: calendarQuoteId, value: parseFloat(data.note) });
    }
  });

  const handleCloseEditTermsModal = () => {
    onClose();
  };

  useEffect(() => {
    const populateWithQuote = !fullEdit && typeof calendarQuoteValue === 'number';
    if (populateWithQuote) formMethods.setValue('note', calendarQuoteValue);
    const populateWithName = fullEdit && calendarName;
    if (populateWithName) formMethods.setValue('note', calendarName);
  }, [calendarQuoteValue, calendarName, fullEdit]);

  return (
    <Modal
      hideHeader
      open={open}
      size="small"
      onClose={handleCloseEditTermsModal}
      footer={
        <Stack direction="row" justify="flex-end" alignItems="center" spacing={1}>
          <LocalizedButton variant="text" color="primary" onClick={handleCloseEditTermsModal}>
            {translations.common.cancel}
          </LocalizedButton>
          <ActionButton variant="contained" color="primary" loading={isPending} onClick={handleCalendarNameUpdate}>
            {translations.common.save}
          </ActionButton>
        </Stack>
      }
    >
      <Stack direction="column" spacing={2}>
        <LocalizedTypography variant="h6">Edit Note Term</LocalizedTypography>
        <LocalizedAlert severity="warning" variant="filled">
          Use caution and carefully review any changes made to the Note Terms. Anything entered here will be included
          when filling orders.
        </LocalizedAlert>
        <LocalizedTextField
          fullWidth
          label="Note Terms"
          inputRef={confirmNoteValueRef}
          error={hasError}
          helperText={errorMessage}
          {...confirmNoteValueProps}
        />
      </Stack>
    </Modal>
  );
};
