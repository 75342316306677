import { ReactElement } from 'react';

import { Paper, Skeleton, Stack } from '@mui/material';

const loadingContainerSx = {
  px: 3,
  pb: 3,
  pt: 1,
  overflowY: 'auto',
  maxWidth: 450,
  minWidth: { xs: 343, sm: 'default' },
  width: '100%',
};

export const ForgotPasswordFormSkeleton = (): ReactElement => (
  <Stack direction="column" alignItems="flex-start">
    <Paper variant="outlined" sx={loadingContainerSx}>
      <Stack direction="column">
        <Skeleton width={350} height={175} />
        <Skeleton height={72} />
        <Skeleton height={60} />
      </Stack>
    </Paper>
    <Skeleton width={68} height={60} />
  </Stack>
);
