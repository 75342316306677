import { atomWithListeners } from '@halo-atoms/atomWithListeners';
import {
  CALENDAR_PREFERENCE_TAG,
  FIXED_INCOME_PREFERENCE_TAG,
  PRODUCT_APPROVAL_PREFERENCE_TAG,
  TERMSHEET_PREFERENCE_TAG,
} from '@halo-common/constants';
import { atom } from 'jotai';

export type DynamicFiltersMinMaxModel = {
  min: number;
  max: number;
};

export type DynamicFiltersCheckboxModel = {
  starred: boolean;
  submittedIndication: boolean;
  advisory: boolean;
  highlighted: boolean;
  brokerage: boolean;
  softProtection: boolean;
  hardProtection: boolean;
  dailyProtection: boolean;
  continuousProtection: boolean;
  autocallable: boolean;
  issuerCallable: boolean;
  notCallable: boolean;
  fixed: boolean;
  contingent: boolean;
  memory: boolean;
  capped: boolean;
  uncapped: boolean;
  callable: boolean;
  fixedToFloating: boolean;
  floating: boolean;
  fixedStepUp: boolean;
  mlcd: boolean;
  averageBasket: boolean;
  bestOfBasket: boolean;
  weightedBasket: boolean;
  worstOfBasket: boolean;
  singleBasket: boolean;
  cashSettlement: boolean;
  physicalSettlement: boolean;
};

export type DynamicFiltersToggleModel = {
  propIndices: boolean;
};

export type DynamicFiltersSideBarFiltersModel = {
  toggles: DynamicFiltersToggleModel;
  checkboxes: DynamicFiltersCheckboxModel;
  nonCallPeriod: DynamicFiltersMinMaxModel | null;
  protectionAmount: DynamicFiltersMinMaxModel | null;
  underlyingCount: DynamicFiltersMinMaxModel | null;
  term: DynamicFiltersMinMaxModel | null;
  currencies: Array<string>;
  issuers: Array<{ name: string; id: string }>;
  productTypes: Array<string>;
  noteTypes: Array<string>;
  filtersChanged: boolean | null;
};

export type DynamicFiltersSideBarModel = {
  [tag: string]: DynamicFiltersSideBarFiltersModel;
  [CALENDAR_PREFERENCE_TAG]: DynamicFiltersSideBarFiltersModel;
  [FIXED_INCOME_PREFERENCE_TAG]: DynamicFiltersSideBarFiltersModel;
  [PRODUCT_APPROVAL_PREFERENCE_TAG]: DynamicFiltersSideBarFiltersModel;
  [TERMSHEET_PREFERENCE_TAG]: DynamicFiltersSideBarFiltersModel;
};

export type DynamicFiltersPreferencesLoadedModel = {
  [tag: string]: boolean;
  [CALENDAR_PREFERENCE_TAG]: boolean;
  [FIXED_INCOME_PREFERENCE_TAG]: boolean;
  [PRODUCT_APPROVAL_PREFERENCE_TAG]: boolean;
  [TERMSHEET_PREFERENCE_TAG]: boolean;
};

export type DynamicFiltersSideBarPayload = {
  tag: string;
  update?: boolean;
} & Partial<DynamicFiltersSideBarFiltersPayload>;

export type DynamicFiltersSideBarFiltersPayload = {
  toggles?: Partial<DynamicFiltersToggleModel>;
  checkboxes?: Partial<DynamicFiltersCheckboxModel>;
  checkbox?: string;
  toggle?: string;
  nonCallPeriod?: DynamicFiltersMinMaxModel | null;
  protectionAmount?: DynamicFiltersMinMaxModel | null;
  term?: DynamicFiltersMinMaxModel | null;
  underlyingCount?: DynamicFiltersMinMaxModel | null;
  currencies?: Array<string>;
  issuers?: Array<{ name: string; id: string }>;
  productTypes?: Array<string>;
  noteTypes?: Array<string>;
  resetActions?: boolean;
};

export const initialSideBarFilters: DynamicFiltersSideBarFiltersModel = {
  checkboxes: {
    advisory: false,
    autocallable: false,
    averageBasket: false,
    bestOfBasket: false,
    brokerage: false,
    callable: false,
    capped: false,
    cashSettlement: false,
    contingent: false,
    continuousProtection: false,
    dailyProtection: false,
    fixed: false,
    fixedStepUp: false,
    fixedToFloating: false,
    floating: false,
    hardProtection: false,
    highlighted: false,
    issuerCallable: false,
    memory: false,
    mlcd: false,
    notCallable: false,
    physicalSettlement: false,
    singleBasket: false,
    softProtection: false,
    starred: false,
    submittedIndication: false,
    uncapped: false,
    weightedBasket: false,
    worstOfBasket: false,
  },
  currencies: [],
  filtersChanged: null,
  issuers: [],
  nonCallPeriod: null,
  productTypes: [],
  noteTypes: [],
  protectionAmount: null,
  term: null,
  toggles: {
    propIndices: false,
  },
  underlyingCount: null,
};

export const initialSideBarFiltersMap = {
  [CALENDAR_PREFERENCE_TAG]: initialSideBarFilters,
  [FIXED_INCOME_PREFERENCE_TAG]: initialSideBarFilters,
  [PRODUCT_APPROVAL_PREFERENCE_TAG]: initialSideBarFilters,
  [TERMSHEET_PREFERENCE_TAG]: initialSideBarFilters,
};

export const initialPreferencesLoadedMap = {
  [CALENDAR_PREFERENCE_TAG]: false,
  [FIXED_INCOME_PREFERENCE_TAG]: false,
  [PRODUCT_APPROVAL_PREFERENCE_TAG]: false,
  [TERMSHEET_PREFERENCE_TAG]: false,
};

export const dynamicFiltersInitialLoadAtom = atom<boolean>(true);

const _dynamicFiltersPreferencesLoaded = atom<DynamicFiltersPreferencesLoadedModel>(initialPreferencesLoadedMap);
const _dynamicFiltersPreferences = atom<DynamicFiltersSideBarPayload | null>(null);
export const sideFiltersLoadedAtom = atom(null, (get, set, payload: DynamicFiltersSideBarPayload) => {
  const preferencesLoaded = get(_dynamicFiltersPreferencesLoaded);

  const tag = payload.tag;
  const isInitialLoad = !preferencesLoaded[tag];
  const isUpdate = payload.update;

  if (isInitialLoad || isUpdate) {
    set(_dynamicFiltersPreferences, (prev) => ({ ...prev, ...payload }));
    set(_dynamicFiltersPreferencesLoaded, (prev) => ({ ...prev, [tag]: true }));
    set(dynamicSideBarManagerAtom, { ...payload, resetActions: true });
  }
});

const [_dynamicSideBarFiltersAtom, useDynamicSidebarFiltersListener] =
  atomWithListeners<DynamicFiltersSideBarModel>(initialSideBarFiltersMap);
export const dynamicSideBarManagerAtom = atom(
  (get) => {
    const filterMap = get(_dynamicSideBarFiltersAtom);

    return (tag: string) => {
      const filters = filterMap?.[tag];

      if (!filters) return { ...initialSideBarFilters, isFiltered: false };

      const isFiltered = [
        Object.values(filters.toggles).some(Boolean),
        Object.values(filters.checkboxes).some(Boolean),
        Boolean(filters.protectionAmount),
        Boolean(filters.term),
        Boolean(filters.underlyingCount),
        filters.currencies.length,
        filters.issuers.length,
        filters.productTypes.length,
        filters.noteTypes.length,
      ].some(Boolean);

      return { ...filters, isFiltered };
    };
  },
  (get, set, payload: DynamicFiltersSideBarPayload) => {
    const tag = payload.tag;

    const checkbox = payload.checkbox;
    const checkboxes = payload.checkboxes;
    const currencies = payload.currencies;
    const issuers = payload.issuers;
    const toggle = payload.toggle;
    const toggles = payload.toggles;
    const productTypes = payload.productTypes;
    const noteTypes = payload.noteTypes;
    const resetActions = payload.resetActions;

    const nonCallPeriod = payload.nonCallPeriod;
    const protectionAmount = payload.protectionAmount;
    const term = payload.term;
    const underlyingCount = payload.underlyingCount;

    const isInitialPageLoad = get(dynamicFiltersInitialLoadAtom);
    const hasNonCallPeriod = nonCallPeriod || nonCallPeriod === null;
    const hasProtectionAmount = protectionAmount || protectionAmount === null;
    const hasUnderlyingCount = underlyingCount || underlyingCount === null;
    const hasTerm = term || term === null;

    const updateCheckboxFilter = (prev: DynamicFiltersSideBarModel) => {
      if (!prev || !checkbox) return prev;
      const prevCheckboxes = prev[tag].checkboxes;
      const updatedCheckbox = !prevCheckboxes[checkbox as keyof DynamicFiltersSideBarFiltersModel['checkboxes']];
      return { ...prev, [tag]: { ...prev[tag], checkboxes: { ...prevCheckboxes, [checkbox]: updatedCheckbox } } };
    };

    const updateCheckboxFilters = (prev: DynamicFiltersSideBarModel) => {
      if (!prev || !checkboxes) return prev;
      const prevCheckboxes = prev[tag].checkboxes;
      return { ...prev, [tag]: { ...prev[tag], checkboxes: { ...prevCheckboxes, ...checkboxes } } };
    };

    const updateToggleFilter = (prev: DynamicFiltersSideBarModel) => {
      if (!prev || !toggle) return prev;
      const prevToggles = prev[tag].toggles;
      const updatedToggle = !prevToggles[toggle as keyof DynamicFiltersSideBarFiltersModel['toggles']];
      return { ...prev, [tag]: { ...prev[tag], toggles: { ...prevToggles, [toggle]: updatedToggle } } };
    };

    const updateToggleFilters = (prev: DynamicFiltersSideBarModel) => {
      if (!prev || !toggles) return prev;
      const prevToggles = prev[tag].toggles;
      return { ...prev, [tag]: { ...prev[tag], toggles: { ...prevToggles, ...toggles } } };
    };

    const updateFilters = (prev: DynamicFiltersSideBarModel, next: Partial<DynamicFiltersSideBarFiltersModel>) => {
      if (!prev) return prev;
      return { ...prev, [tag]: { ...prev[tag], ...next } };
    };

    if (checkbox) set(_dynamicSideBarFiltersAtom, updateCheckboxFilter);
    if (checkboxes) set(_dynamicSideBarFiltersAtom, updateCheckboxFilters);

    if (toggle) set(_dynamicSideBarFiltersAtom, updateToggleFilter);
    if (toggles) set(_dynamicSideBarFiltersAtom, updateToggleFilters);

    if (currencies) set(_dynamicSideBarFiltersAtom, (prev) => updateFilters(prev, { currencies }));
    if (productTypes) set(_dynamicSideBarFiltersAtom, (prev) => updateFilters(prev, { productTypes }));
    if (noteTypes) set(_dynamicSideBarFiltersAtom, (prev) => updateFilters(prev, { noteTypes }));
    if (issuers) set(_dynamicSideBarFiltersAtom, (prev) => updateFilters(prev, { issuers }));

    if (hasNonCallPeriod) set(_dynamicSideBarFiltersAtom, (prev) => updateFilters(prev, { nonCallPeriod }));
    if (hasProtectionAmount) set(_dynamicSideBarFiltersAtom, (prev) => updateFilters(prev, { protectionAmount }));
    if (hasUnderlyingCount) set(_dynamicSideBarFiltersAtom, (prev) => updateFilters(prev, { underlyingCount }));
    if (hasTerm) set(_dynamicSideBarFiltersAtom, (prev) => updateFilters(prev, { term }));

    if (isInitialPageLoad) set(dynamicFiltersInitialLoadAtom, false);
    else set(_dynamicSideBarFiltersAtom, (prev) => updateFilters(prev, { filtersChanged: resetActions !== true }));
  },
);

export { useDynamicSidebarFiltersListener };
