import { HaloSliceState } from '@halo-stores/types';

export enum WebsocketStatusEnum {
  idle = 'idle',
  listening = 'listening',
  timeout = 'timeout',
}

export type WebsocketStatus = keyof typeof WebsocketStatusEnum;

export type WebsocketTimeout = {
  delay: number;
  reference: number | undefined;
};

export type WebsocketSliceState = {
  status: WebsocketStatus;
  timeout: WebsocketTimeout;
};

export type WebsocketState = WebsocketSliceState & Omit<HaloSliceState, 'status'>;
