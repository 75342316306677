import { ReactElement } from 'react';

import { ContactHaloSchema, contactModalFormSchema } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedButton, Modal, Stack } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { ContactForm, ContactFormProps } from './ContactForm';

const contentSx = {
  backgroundColor: 'background.paper',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export type ContactModalProps = {
  onSubmit: ContactFormProps['onSubmit'];
  user: ContactFormProps['user'];
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
  open: boolean;
  onClose: () => void;
};

export const ContactModal = ({
  open,
  user,
  onSubmit,
  onClose,
  isSuccess,
  isError,
  isLoading,
}: ContactModalProps): ReactElement => {
  const { data } = useUserInfoQuery();

  const requireCompanyName = data?.settings?.onboarding?.requireCompanyName ?? false;
  const resolver = yupResolver(contactModalFormSchema(requireCompanyName));

  const formMethods = useForm<ContactHaloSchema>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    resolver,
    defaultValues: {
      emailAddress: '',
      firstName: '',
      lastName: '',
      companyName: '',
      message: '',
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    reset,
  } = formMethods;

  const handleClose = () => {
    reset();
    onClose();
  };

  const isDisabled = !isValid || isLoading;

  const headerTitle = isSuccess
    ? translations.components.contactFormHeaderSuccess
    : translations.components.contactFormHeaderHelp;
  const headerSubtitle = isSuccess ? '' : translations.components.contactFormIssue;
  const headerIcon = isSuccess ? 'check' : 'user-headset';

  const finishFooter = isSuccess ? (
    <Stack direction="row" justify="flex-end">
      <LocalizedButton color="primary" fullWidth onClick={handleClose} variant="contained">
        {translations.common.finish}
      </LocalizedButton>
    </Stack>
  ) : (
    <Stack direction="row" justify="flex-end">
      <LocalizedButton
        startIcon={isSubmitting && <CircularProgress size="1rem" />}
        disabled={isDisabled}
        color="primary"
        variant="contained"
        onClick={handleSubmit(onSubmit)}
      >
        {translations.common.submit}
      </LocalizedButton>
    </Stack>
  );

  const modalContent = isSuccess ? null : (
    <FormProvider {...formMethods}>
      <form>
        <ContactForm onSubmit={onSubmit} hasError={isError} user={user} />
      </form>
    </FormProvider>
  );

  return (
    <Modal
      onClose={onClose}
      open={open}
      icon={headerIcon}
      footer={finishFooter}
      overline={translations.components.contactFormOverLine}
      title={headerTitle}
      subtitle={headerSubtitle}
      ContentProps={{ sx: contentSx }}
    >
      {modalContent}
    </Modal>
  );
};
