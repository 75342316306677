import { ReactElement } from 'react';

import { ProductDetailPanelSection } from '@halo-common/layouts';
import { V2PostTradeNoteDetailLifecycleSectionField } from '@halo-common/modals';
import { V2NoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { Box } from '@mui/material';

export type V2PostTradeNoteDetailLifecycleSectionProps = {
  product?: V2NoteModel | null;
  loading?: boolean;
};

export const V2PostTradeNoteDetailLifecycleSection = ({
  product,
  loading = true,
}: V2PostTradeNoteDetailLifecycleSectionProps): ReactElement | null => {
  const hideSection = !loading && product?.lifecycle.lifecycleDates?.length === 0;

  if (hideSection) return null;

  const dates = product?.lifecycle.lifecycleDates?.map(({ name, date }, index) => (
    <V2PostTradeNoteDetailLifecycleSectionField
      key={`lifecycle_date_${index}`}
      title={name}
      value={date}
      loading={loading}
    />
  ));

  return (
    <Box sx={{ textTransform: 'capitalize' }}>
      <ProductDetailPanelSection title={translations.common.lifecycle} loading={loading}>
        {dates}
      </ProductDetailPanelSection>
    </Box>
  );
};
