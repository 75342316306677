import { PortfolioTransactionModel } from '@halo-common/models';
import { ApiCreatePositionModel, ApiQueuedPositionNoteModel } from '@halo-data-sources/models';

export const ApiTransactionMapper = {
  toPortfolioTransactionModel: (position: ApiCreatePositionModel): PortfolioTransactionModel => {
    return {
      id: position.id,
      accountName: position.account.name,
      accountId: position.account.id,
      accountDisplayName: position.account.account_identifier ?? undefined,
      amount: position.allocation_amount,
      isQueued: false,
      custodian: position.account.owner?.name,
    };
  },
  toQueuedPortfolioTransactionModel: (queuedPosition: ApiQueuedPositionNoteModel): PortfolioTransactionModel => {
    return {
      id: queuedPosition.id,
      accountName: queuedPosition.account.name,
      accountId: queuedPosition.account.id,
      accountDisplayName: queuedPosition.account.account_identifier,
      amount: queuedPosition.allocation_amount,
      isQueued: true,
      custodian: queuedPosition.account.owner?.name,
    };
  },
};
