import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiLifecycleEventDetailRequestModel,
  ApiPostPortfolioLifecycleEventDetailResponseModel,
} from '@halo-data-sources/models';

const ACCOUNTS_PATH = '/posttrade/accounts';

export const getPostTradeAccountBreakdown = async (
  data: ApiLifecycleEventDetailRequestModel,
): Promise<ApiPostPortfolioLifecycleEventDetailResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ACCOUNTS_PATH);

  const payload = {
    ...data,
    _csrf_token: clientConfiguration.csrfToken,
  };

  return await request<ApiPostPortfolioLifecycleEventDetailResponseModel>(`${clientConfiguration.basePath}/breakdown`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify(payload),
  });
};
