import { ReactElement } from 'react';

import { ActionButton } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { useCalendarEmailSubscribeMutation } from '@halo-data-sources/mutations';
import { LocalizedTextField, LocalizedTypography } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export const requiredText = 'Please enter a valid email address.';

const emailFormSchema = yup.object().shape({
  email: yup.string().email(requiredText).required(requiredText),
});

const emailSubscriptionResolver = yupResolver<EmailFormSchema>(emailFormSchema);

type EmailFormSchema = {
  email: string;
};

export const EmailSubscription = (): ReactElement => {
  const { handleSubmit, formState, register, reset } = useForm<EmailFormSchema>({
    resolver: emailSubscriptionResolver,
    defaultValues: { email: '' },
  });

  const { mutate, isPending } = useCalendarEmailSubscribeMutation({
    onSuccess: () => reset(),
  });

  const { ref: emailRef, ...emailProps } = register('email');

  const emailInput = (
    <LocalizedTextField
      {...emailProps}
      inputRef={emailRef}
      type="email"
      placeholder={translations.calendars.common.enterEmail}
      title={translations.calendars.common.enterEmail}
      helperText={formState.errors.email?.message}
      error={!!formState.errors.email}
      fullWidth
      required
    />
  );

  const onSubmit = handleSubmit(({ email }) => mutate(email));

  return (
    <Stack direction="column" spacing={2}>
      <LocalizedTypography variant="h6" color="text.primary">
        {translations.calendars.common.subscribeToCalendarEmailList}
      </LocalizedTypography>
      <form onSubmit={onSubmit} noValidate>
        <Stack direction="row" spacing={1}>
          {emailInput}
          <ActionButton loading={isPending} type="submit" variant="contained">
            {translations.common.submit}
          </ActionButton>
        </Stack>
      </form>
    </Stack>
  );
};
