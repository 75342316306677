import { ReactElement } from 'react';

import { roundFloatToSpecificDecimal } from '@halo-common/utils';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Skeleton, Typography } from '@mui/material';

const ZERO_COLOR = 'grey.600';

const containerSx = { flex: 1, pt: 2 };
const invalidNumberSx = { color: ZERO_COLOR };
const subtitleColorSx = { color: 'text.secondary' };

export type ProductDetailModalAnalyticsRollingReturnsSummaryProps = {
  label?: string;
  value?: number;
  loading?: boolean;
  reverseColoring?: boolean;
};

export const ProductDetailModalAnalyticsRollingReturnsSummary = ({
  value,
  label,
  loading = false,
  reverseColoring = false,
}: ProductDetailModalAnalyticsRollingReturnsSummaryProps): ReactElement => {
  if (loading) {
    return (
      <Stack sx={containerSx} direction="column" alignItems="flex-end" spacing={1}>
        <Skeleton data-testid="loading-value-text" variant="rounded" width={115} height={38} />
        <Skeleton data-testid="loading-label" variant="rounded" width={58} height={24} />
      </Stack>
    );
  }

  if (typeof value !== 'number') {
    return (
      <Stack sx={containerSx} direction="column" alignItems="flex-end">
        <Typography variant="h2" sx={invalidNumberSx}>
          -
        </Typography>
        <LocalizedTypography variant="caption" sx={subtitleColorSx}>
          {label}
        </LocalizedTypography>
      </Stack>
    );
  }

  const isZero = value === 0;
  const isPositive = value > 0;

  const featureType = label?.toLowerCase();
  const isDigitalCouponFeature = featureType === 'digital coupon';
  const isGearingFeature = featureType === 'gearing';

  const symbol = isDigitalCouponFeature ? '+' : isGearingFeature ? 'x' : '';
  const roundedDifference = roundFloatToSpecificDecimal(value);
  const valueText = `${symbol}${roundedDifference.toFixed(2)}%`;

  const normalColor = isPositive ? 'common.charts.positive.main' : 'common.charts.negative.main';
  const reverseColor = !isPositive ? 'common.charts.positive.main' : 'common.charts.negative.main';
  const color = isZero ? ZERO_COLOR : reverseColoring ? reverseColor : normalColor;

  const textColorSx = { color };

  return (
    <Stack sx={containerSx} direction="column" alignItems="flex-end">
      <Typography variant="h2" sx={textColorSx}>
        {valueText}
      </Typography>
      <LocalizedTypography variant="caption" sx={subtitleColorSx}>
        {label}
      </LocalizedTypography>
    </Stack>
  );
};
