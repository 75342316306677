import { ReactElement, useEffect, useState } from 'react';

import { CalendarPageModel } from '@halo-common/models';
import { Checkbox, FormControlLabel } from '@mui/material';

const childPageStyling = {
  p: 0,
  ml: 2,
};

export type CalendarPageAccordionChildProps = {
  child: CalendarPageModel;
  isChecked: boolean;
  onCheck: (page: number, checked: boolean) => void;
};

export const CalendarPageAccordionChild = ({
  child,
  isChecked,
  onCheck,
}: CalendarPageAccordionChildProps): ReactElement => {
  const [childChecked, setChildChecked] = useState<boolean>(isChecked);

  const childPageName = `${child.id} ${child.organization?.name} - ${child.title}`;
  const childId = child.id;

  const handleCheck = () => {
    const updatedStatus = !childChecked;
    setChildChecked(updatedStatus);
    onCheck(child.id, updatedStatus);
  };

  useEffect(() => {
    setChildChecked(isChecked);
  }, [isChecked]);

  return (
    <FormControlLabel
      sx={childPageStyling}
      control={<Checkbox checked={childChecked} color="primary" onChange={handleCheck} value={childId} />}
      label={childPageName}
    />
  );
};
