import { ReactElement } from 'react';

import { ProductAnnuityTypeChip, ProductNoteTypeChip } from '@halo-common/components';
import { ProductTypeEnum } from '@halo-common/enums';
import { NoteModel } from '@halo-common/models';
import { ChipProps } from '@mui/material';

interface ProductTypeChipDefaultProps {
  variant?: ChipProps['variant'];
}

interface ProductTypeChipNoteProps extends ProductTypeChipDefaultProps {
  product?: NoteModel | null;
  type: ProductTypeEnum.note;
}

interface ProductTypeChipAnnuityProps extends ProductTypeChipDefaultProps {
  product?: never;
  type: ProductTypeEnum.annuity;
}

export type ProductTypeChipProps = ProductTypeChipNoteProps | ProductTypeChipAnnuityProps;

export const ProductTypeChip = ({ type, product, variant = 'filled' }: ProductTypeChipProps): ReactElement | null => {
  const noteType = product?.type;
  const noteProductType = product?.productType;

  return {
    [ProductTypeEnum.note]: <ProductNoteTypeChip type={noteType} subtype={noteProductType} variant={variant} />,
    [ProductTypeEnum.annuity]: <ProductAnnuityTypeChip variant={variant} />,
  }[type];
};
