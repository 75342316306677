import { EducationGlossary } from '@halo-common/models';
import { getEducationGlossary } from '@halo-data-sources/clients';
import { ApiEducationMapper } from '@halo-data-sources/mappers';
import { EducationQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useT } from '@transifex/react';

const getEducationGlossaryFn = async () => {
  const response = await getEducationGlossary();
  return response.terms.map((term) => ApiEducationMapper.toGlossaryTerm(term));
};

export const useEducationGlossaryQuery = (): UseQueryResult<EducationGlossary, Error> => {
  const translator = useT();
  return useQuery<EducationGlossary, Error>({
    queryKey: EducationQueryKeyFactory.glossary(),
    queryFn: async () => {
      const terms = await getEducationGlossaryFn();

      return terms.reduce((glossary: EducationGlossary, term) => {
        const copy = { ...glossary };
        const translatedTitle = translator(term.title);
        const translatedDefinition = translator(term.definition);
        const translatedTerm = { ...term, title: translatedTitle, definition: translatedDefinition };
        const letter = translatedTerm.title.charAt(0).toLowerCase();

        if (copy[letter]) copy[letter].push(translatedTerm);
        else copy[letter] = [translatedTerm];

        return copy;
      }, {});
    },
  });
};
