import { useCallback } from 'react';

import { Dropzone, type FileFieldRender } from '@halo-common/components';

export const renderFileInput: FileFieldRender = ({ field, fieldState, accept, multiple = false }) => {
  const file = field.value as File | null;
  const error = fieldState.error;
  const setFile = field.onChange;

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
        setFile(acceptedFiles[0] || null);
      }
    },
    [setFile],
  );

  return <Dropzone onDrop={onDrop} file={file} error={error?.message} accept={accept} multiple={multiple} />;
};
