import { ReactElement } from 'react';

import { useUserWebHooksQuery } from '@halo-data-sources/queries';
import { Stack } from '@halodomination/halo-fe-common';
import { Box, Typography } from '@mui/material';
import { sanitize } from 'dompurify';

const disclaimerSx = {
  fontWeight: 'fontWeightRegular',
  fontSize: 12,
  lineHeight: '16px',
  letterSpacing: 0,
};

export type PageFooterDisclaimerProps = {
  disclaimerHook?: string;
};

export const PageFooterDisclaimer = ({ disclaimerHook = '' }: PageFooterDisclaimerProps): ReactElement => {
  const { data: webhooks } = useUserWebHooksQuery();

  const disclaimerContent = webhooks?.[disclaimerHook]?.html ?? '';
  const sanitizedContent = sanitize(disclaimerContent);

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="subtitle2">Content Disclaimer</Typography>
      <Box sx={disclaimerSx} dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
    </Stack>
  );
};
