import { ReactElement } from 'react';

import type { AutocompleteFieldProps, FieldType, FileFieldProps, SelectFieldProps } from '@halo-common/components';
import { getRenderFunction } from '@halo-common/components';
import { LocalizedTextFieldProps } from '@halodomination/halo-fe-common';
import { useController, UseControllerProps } from 'react-hook-form';

export type InputProps = UseControllerProps &
  Omit<LocalizedTextFieldProps, 'type' | 'options'> &
  FileFieldProps & {
    type?: FieldType;
    options?: SelectFieldProps['options'] | AutocompleteFieldProps['options'];
  };

export const Input = ({
  name,
  control,
  defaultValue,
  disabled,
  rules,
  shouldUnregister,
  type = 'text',
  size = 'medium',
  options,
  accept,
  multiple,
  ...props
}: InputProps): ReactElement | null => {
  const render = getRenderFunction(type);

  const field = useController({
    name,
    control,
    defaultValue,
    disabled,
    rules,
    shouldUnregister,
  });

  if (!render) return null;

  return render({
    ...field,
    type,
    options,
    accept,
    multiple,
    props: {
      size,
      ...props,
    },
  });
};
