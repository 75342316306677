import { translations } from '@halo-common/translations';
import { putOnboardingDocusignFormData } from '@halo-data-sources/clients';
import type {
  ApiOnboardingDocusignFormDataResponseModel,
  PartialDocusignFormDataModel,
} from '@halo-data-sources/models';
import { DocusignQueryKeyFactory } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from '@tanstack/react-query';

export type useUpdateOnboardingDocusignMutationOptions = UseMutationOptions<
  ApiOnboardingDocusignFormDataResponseModel,
  Error,
  PartialDocusignFormDataModel
>;

export type useUpdateOnboardingDocusignMutationResult = UseMutationResult<
  ApiOnboardingDocusignFormDataResponseModel,
  Error,
  PartialDocusignFormDataModel
>;

export const useUpdateOnboardingDocusignMutation = (
  options?: useUpdateOnboardingDocusignMutationOptions,
): useUpdateOnboardingDocusignMutationResult => {
  const queryClient = useQueryClient();
  const { enqueueErrorEvent } = useSnackbar();

  return useMutation({
    mutationFn: putOnboardingDocusignFormData,
    ...options,
    onSuccess: (...res) => {
      options?.onSuccess?.(...res);
      const newFormData = res[0];
      if (newFormData) {
        queryClient.setQueryData<ApiOnboardingDocusignFormDataResponseModel>(
          DocusignQueryKeyFactory.formData(),
          newFormData,
        );
      }
    },
    onError: (...props) => {
      const error = props[0];
      options?.onError?.(...props);
      const message = error?.message || translations.common.error;
      enqueueErrorEvent({ message });
    },
  });
};
