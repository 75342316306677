import { ReactElement } from 'react';

import { ModuleStepModel } from '@halo-common/models';
import { EducationModuleStep } from '@halo-modules/app';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Box, Stepper, Theme, stepConnectorClasses, stepLabelClasses } from '@mui/material';

const defaultModuleGroupContainerSx = {
  backgroundColor: 'background.default',
  border: '1px solid',
  borderColor: 'primary.background',
  borderRadius: 2,
  width: (theme: Theme) => `calc(100% - ${theme.spacing(1)})`,
};

const compactModuleGroupContainerSx = {
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: 0,
  width: '100%',
};

const moduleStepperSx = {
  margin: 2,
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: 'primary.dark',
    minHeight: 8,
  },
  [`& .${stepConnectorClasses.root}`]: {
    marginLeft: 2.375,
    '&:first-child': {
      display: 'none',
    },
  },
  [`& .${stepLabelClasses.root}`]: {
    alignItems: 'center',
    padding: 0,
  },
  [`& .${stepLabelClasses.labelContainer}`]: {
    alignItems: 'flex-start',
  },
  '&:has([data-highlight])': {
    '& .MuiStep-root': {
      display: 'none',
    },
    '& .MuiStepConnector-root': {
      display: 'none',
    },
    '& .MuiStep-root[data-highlight]': {
      display: 'block',
    },
    '& .MuiStep-root[data-highlight] + .MuiStepConnector-root': {
      display: 'block',
    },
    '& .MuiStepConnector-root:has(+ .MuiStep-root[data-highlight])': {
      display: 'block',
    },
  },
};

export type EducationModuleProps = {
  title?: string | null;
  steps: Array<ModuleStepModel>;
  onSelection: (step: ModuleStepModel) => void;
  showCurrent?: boolean;
  variant?: 'default' | 'compact';
  stepClassName?: string;
};

export const EducationModule = ({
  title,
  steps = [],
  onSelection,
  showCurrent = false,
  variant = 'default',
  stepClassName,
}: EducationModuleProps): ReactElement => {
  const moduleGroupContainerSx = variant === 'compact' ? compactModuleGroupContainerSx : defaultModuleGroupContainerSx;
  const titleBoxSx = {
    backgroundColor: 'primary.background',
    borderTopLeftRadius: variant === 'compact' ? 0 : 16,
    borderTopRightRadius: variant === 'compact' ? 0 : 16,
    px: 2,
    py: 1,
    width: '100%',
  };

  const currentStep = steps.find((step) => !step.completed) || steps.at(-1);

  return (
    <Stack sx={moduleGroupContainerSx} direction="column" justify="space-between">
      <Box sx={titleBoxSx}>
        <LocalizedTypography color="primary" variant="h6">
          {title}
        </LocalizedTypography>
      </Box>
      <Stepper sx={moduleStepperSx} activeStep={0} orientation="vertical">
        {steps.map((step) => {
          const { title, id } = step;
          const key = `${id}-${title}`;
          return (
            <EducationModuleStep
              key={key}
              step={step}
              onSelection={onSelection}
              showCurrent={showCurrent}
              data-highlight={(variant === 'compact' && step === currentStep) || undefined}
              className={stepClassName}
            />
          );
        })}
      </Stepper>
    </Stack>
  );
};
