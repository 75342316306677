import { AllocationModel } from '@halo-common/models';
import { putCalendarOrderApproval } from '@halo-data-sources/clients';
import { ApiAllocationMapper } from '@halo-data-sources/mappers';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

export type CalendarApprovalMutationPayload = { id: number; reason?: string };

const calendarApprovalMutationFn = async (payload: CalendarApprovalMutationPayload) => {
  const { id, reason } = payload;
  const result = await putCalendarOrderApproval(id, true, reason);
  return ApiAllocationMapper.toAllocationModel(result.allocation);
};

export const useCalendarApprovalMutation = (
  options?: Partial<UseMutationOptions<AllocationModel, Error, CalendarApprovalMutationPayload>>,
): UseMutationResult<AllocationModel, Error, CalendarApprovalMutationPayload> => {
  const { enqueueErrorEvent, enqueueSuccessEvent } = useSnackbar();

  return useMutation({
    mutationFn: calendarApprovalMutationFn,
    ...options,
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
      enqueueSuccessEvent({ message: 'Approved!' });
    },
    onError: (...args) => {
      options?.onError?.(...args);
      enqueueErrorEvent({ message: "Couldn't Approve!" });
    },
  });
};
