import { ReactElement, ReactNode } from 'react';

import { Iconography } from '@halodomination/halo-fe-common';
import { Button, SxProps, Theme, Typography } from '@mui/material';

const buttonSx = {
  height: 65,
  maxWidth: 'unset',
  width: '100%',
  backgroundColor: 'common.white',
  border: '1px solid',
  borderColor: 'background.mask',
  padding: 2,
};

const typographySx = {
  width: '100%',
};

export type AddAccountOrPositionModalCustodianButtonProps = {
  name: string;
  icon?: ReactNode;
  sx?: SxProps<Theme>;
  onClick: () => void;
};

export const AddAccountOrPositionModalCustodianButton = ({
  name,
  onClick,
  icon,
  sx,
}: AddAccountOrPositionModalCustodianButtonProps): ReactElement => {
  const styling = { ...buttonSx, ...sx };

  const endIcon = icon ?? <Iconography iconName="arrow-right" color="secondary.contrastText" />;

  return (
    <Button sx={styling} type="button" onClick={onClick} endIcon={endIcon}>
      <Typography sx={typographySx} variant="h6" component="p">
        {name}
      </Typography>
    </Button>
  );
};
