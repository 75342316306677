import { DEFAULT_ASSET_ID } from '@halo-common/constants';
import { AssetIdentifierEnum } from '@halo-common/enums';
import { AssetIdModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { queryAssetExistence } from '@halo-data-sources/clients';
import { HttpError } from '@halo-data-sources/errors';
import { ApiAssetIdExistenceMapper } from '@halo-data-sources/mappers';
import { PortfolioQueryKeyFactory, useUserInfoQuery } from '@halo-data-sources/queries';
import { UseQueryOptions, UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

type AssetIdentityType = {
  assetIdentifiers: Array<AssetIdentifierEnum>;
  primaryAssetIdentifier: string;
};

const getPortfolioAssetExistenceQueryFn = async (assetIds?: Array<string>, assetIdentity?: AssetIdentityType) => {
  if (!assetIds || !assetIdentity) return [];
  if (!assetIds.length) throw new HttpError(400, translations.portfolio.common.assetSearchValidationDefaultMessage);

  const { assetIdentifiers, primaryAssetIdentifier } = assetIdentity;

  const result = await queryAssetExistence(assetIds);

  return result.map((result) =>
    ApiAssetIdExistenceMapper.toAssetIdModel(result, assetIdentifiers, primaryAssetIdentifier),
  );
};

export const usePortfolioAssetExistenceQuery = (
  assetIds?: Array<string>,
  options?: Partial<UseQueryOptions<Array<AssetIdModel>, Error>>,
): UseQueryResult<Array<AssetIdModel>, Error> => {
  const { data: userInfo } = useUserInfoQuery();

  const whiteLabel = userInfo?.whiteLabel;
  const assetIdentifiers = whiteLabel?.assetIdentifiers ?? [];
  const primaryAssetIdentifier = whiteLabel?.primaryAssetIdentifier ?? DEFAULT_ASSET_ID;

  const assetIdentity = {
    assetIdentifiers,
    primaryAssetIdentifier,
  };

  return useQuery<Array<AssetIdModel>, Error>({
    queryKey: PortfolioQueryKeyFactory.assetExistence(assetIds),
    queryFn: () => getPortfolioAssetExistenceQueryFn(assetIds, assetIdentity),
    placeholderData: keepPreviousData,
    ...options,
  });
};
