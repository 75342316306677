import { MutableRefObject } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { CalendarModel, PaginationModel } from '@halo-common/models';
import { GridApiPro } from '@halodomination/halo-fe-common';
import { atom } from 'jotai';

type ProductApprovalEditCloseDateModalData = {
  calendar: CalendarModel | null;
  multiple?: boolean;
  calendarIds?: Array<number>;
  tableRef?: MutableRefObject<GridApiPro>;
};

type ProductApprovalEditCloseDateModalDataPayload = {
  calendar?: CalendarModel;
  open?: boolean;
  multiple?: boolean;
  calendarIds?: Array<number>;
  tableRef?: MutableRefObject<GridApiPro>;
};

export const productApprovalSelectedCalendarIdAtom = atom<number | null>(null);

const PRODUCT_APPROVAL_DETAIL_MODAL_NAME = 'productApprovalDetailModal';
export const productApprovalDetailsModalAtom = atom(
  (get) => {
    const modalMap = get(modalAtom);
    const calendarId = get(productApprovalSelectedCalendarIdAtom);
    return { calendarId, open: modalMap[PRODUCT_APPROVAL_DETAIL_MODAL_NAME] };
  },
  (_, set, payload?: { calendarId?: number; open?: boolean }) => {
    const calendarId = payload?.calendarId;
    const open = payload?.open;

    if (calendarId !== undefined) set(productApprovalSelectedCalendarIdAtom, calendarId);
    if (open !== undefined) set(modalAtom, { [PRODUCT_APPROVAL_DETAIL_MODAL_NAME]: open });

    if (!payload) {
      set(productApprovalSelectedCalendarIdAtom, null);
      set(modalAtom, { [PRODUCT_APPROVAL_DETAIL_MODAL_NAME]: false });
    }
  },
);

const PRODUCT_APPROVAL_EDIT_CLOSE_DATE_MODAL_NAME = 'productApprovalEditCloseDateModal';
const DEFAULT_EDIT_CLOSE_DATE_MODAL_DATA = { calendar: null, multiple: false, calendarIds: [] };
const _productApprovalEditCloseDateModalDatAtom = atom<ProductApprovalEditCloseDateModalData>(
  DEFAULT_EDIT_CLOSE_DATE_MODAL_DATA,
);
export const productApprovalEditCloseDateModalAtom = atom(
  (get) => {
    const modalMap = get(modalAtom);
    const editCloseModalData = get(_productApprovalEditCloseDateModalDatAtom);
    return { ...editCloseModalData, open: modalMap[PRODUCT_APPROVAL_EDIT_CLOSE_DATE_MODAL_NAME] };
  },
  (_, set, payload?: ProductApprovalEditCloseDateModalDataPayload) => {
    const { open, ...rest } = payload ?? {};

    if (open !== undefined) set(modalAtom, { [PRODUCT_APPROVAL_EDIT_CLOSE_DATE_MODAL_NAME]: open });

    if (!payload) {
      set(_productApprovalEditCloseDateModalDatAtom, DEFAULT_EDIT_CLOSE_DATE_MODAL_DATA);
      set(modalAtom, { [PRODUCT_APPROVAL_EDIT_CLOSE_DATE_MODAL_NAME]: false });
    } else {
      set(_productApprovalEditCloseDateModalDatAtom, { ...DEFAULT_EDIT_CLOSE_DATE_MODAL_DATA, ...rest });
    }
  },
);

const PRODUCT_APPROVAL_PDF_MODAL_NAME = 'productApprovalPdfModal';
export const productApprovalPdfViewerModalAtom = atom(
  (get) => {
    const modalMap = get(modalAtom);
    const calendarId = get(productApprovalSelectedCalendarIdAtom);
    return { calendarId, open: modalMap[PRODUCT_APPROVAL_PDF_MODAL_NAME] };
  },
  (_, set, payload?: { calendarId?: number; open?: boolean }) => {
    const calendarId = payload?.calendarId;
    const open = payload?.open;

    if (calendarId !== undefined) set(productApprovalSelectedCalendarIdAtom, calendarId);
    if (open !== undefined) set(modalAtom, { [PRODUCT_APPROVAL_PDF_MODAL_NAME]: open });

    if (!payload) {
      set(productApprovalSelectedCalendarIdAtom, null);
      set(modalAtom, { [PRODUCT_APPROVAL_PDF_MODAL_NAME]: false });
    }
  },
);

type ProductApprovalPaginationModel = Pick<PaginationModel, 'page' | 'resultsPerPage'> & {
  pageSizeOptions: Array<number>;
};

const DEFAULT_PAGINATION = {
  page: 1,
  resultsPerPage: 100,
  pageSizeOptions: [25, 50, 100, 500, 1000],
};

const _productApprovalPaginationAtom = atom<ProductApprovalPaginationModel>(DEFAULT_PAGINATION);
export const productApprovalPaginationAtom = atom(
  (get) => get(_productApprovalPaginationAtom),
  (get, set, payload: Partial<ProductApprovalPaginationModel>) => {
    const pagination = get(_productApprovalPaginationAtom);
    set(_productApprovalPaginationAtom, { ...pagination, ...payload });
  },
);
