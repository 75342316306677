import { AuctionStatusEnum, AuctionStrikeStyleEnum, AuctionStyleEnum } from '@halo-common/enums';
import {
  AuctionAdminModel,
  AuctionBuyerModel,
  AuctionHistoryModel,
  AuctionUpdateAdminModel,
} from '@halo-common/models';
import {
  ApiAllocationMapper,
  ApiCalendarsMapper,
  ApiIssuerMapper,
  ApiQuoteMapper,
  ApiTermsheetMapper,
  ApiUserMapper,
  mapApiDocumentModelToS3DocumentModel,
  mapApiNoteModelToNoteModel,
} from '@halo-data-sources/mappers';
import {
  ApiAdminAuctionHistoryModel,
  ApiAdminAuctionModel,
  ApiBuyerAuctionModel,
  ApiBuyerAuctionStartModel,
  ApiBuyerAuctionStartResponseModel,
  ApiPatchAdminAuctionRequestModel,
} from '@halo-data-sources/models';
import { StartAuctionModel, StartAuctionResponseModel } from '@halo-stores/Orders';
import { mapLocalCurrencyToNumber } from '@halodomination/halo-fe-common';
import { DateTime } from 'luxon';

export const CREATED_DATE_FORMAT = 'ccc LLL dd hh:mm:ss yyyy';
export const AUCTION_DATE_FORMAT = 'LLL dd, yyyy';
export const AUCTION_END_TIME_FORMAT = 'hh:mm:ss a';
export const AUCTION_DATE_TIME_FORMAT = 'LLL dd, yy hh:mm:ss a';
export const AUCTION_HISTORY_DATE_FORMAT = 'MM-dd-yy';
export const AUCTION_HISTORY_TIME_FORMAT = 'hh:mm:ss';
export const AUCTION_HISTORY_DATE_TIME_FORMAT = 'yyyy-MM-dd hh:mm:ss.u';
export const AUCTION_EDITABLE_END_DATE_FORMAT = 'MM/dd/yyyy hh:mm:ss a';
export const AUCTION_API_DATE_TIME_FORMAT = 'yyyy-MM-dd hh:mm:ss';
export const AUCTION_API_DATE_FORMAT = 'yyyy-LL-dd';

interface AuctionMapper {
  toApiPatchOrderAuctionRequestModel: (model: AuctionUpdateAdminModel) => ApiPatchAdminAuctionRequestModel;
  toApiStartAuctionRequest: (model: StartAuctionModel) => ApiBuyerAuctionStartModel;
}

export const AuctionMapper: AuctionMapper = {
  toApiPatchOrderAuctionRequestModel: (model) => {
    const { price, tradeDate, strikeDate, settleDate, endDate, currencyCode, issuers } = model;

    const parsedPrice = price ? mapLocalCurrencyToNumber(price, { currency: currencyCode }) : undefined;
    const parsedTradeDate = tradeDate ? DateTime.fromJSDate(new Date(tradeDate))?.toISO() : undefined;
    const parsedStrikeDate = strikeDate ? DateTime.fromJSDate(new Date(strikeDate))?.toISO() : undefined;
    const parsedSettleDate = settleDate ? DateTime.fromJSDate(new Date(settleDate))?.toISO() : undefined;
    const parsedEndDate = endDate ? DateTime.fromJSDate(new Date(endDate))?.toISO() : undefined;

    const requestModel = {
      cusip: model.cusip,
      isin: model.isin,
      notional: model.notional,
      note_id: model.noteId,
      auction_style: model.style,
      target_value: model.targetSolvableParameter,
      strike_style: model.strikeStyle,
      additional_details: model.additionalDetails,
      show_top_of_book: model.isTopOfBook,
      price: parsedPrice,
      target_trade_date: parsedTradeDate,
      target_strike_date: parsedStrikeDate,
      target_settle_date: parsedSettleDate,
      auction_end: parsedEndDate,
    } as ApiPatchAdminAuctionRequestModel;

    if (issuers) requestModel.issuers = issuers.map((issuer) => issuer.id);

    return requestModel;
  },
  toApiStartAuctionRequest: (model) => ({
    issuers: model.issuers,
    notional: model.notional,
    strike_date: model.strikeDate.toString(),
    trade_date: model.tradeDate.toString(),
    settlement_date: model.settlementDate.toString(),
    note_id: model.noteId,
    auction_style: model.auctionStyle,
    strike_mode: model.strikeMode,
    number: model.number,
    allocations: model.allocations,
    additional_details: model.details || undefined,
  }),
};

interface ApiAuctionMapper {
  toAdminAuctionModel: (responseModel: ApiAdminAuctionModel) => AuctionAdminModel;
  toBuyerAuctionModel: (responseModel: ApiBuyerAuctionModel) => AuctionBuyerModel;
  toAuctionHistory: (history: ApiAdminAuctionHistoryModel) => AuctionHistoryModel;
  toStartAuctionResponseModel: (model: ApiBuyerAuctionStartResponseModel) => StartAuctionResponseModel;
}

export const ApiAuctionMapper: ApiAuctionMapper = {
  toAdminAuctionModel: (responseModel) => {
    const haloUser = ApiUserMapper.toUser(responseModel.halo_user);

    let winningQuote = {};

    if (responseModel.quotes) {
      const fountWinningQuote = responseModel.quotes.find((quote) => quote.is_winning_quote);

      winningQuote = {
        id: fountWinningQuote?.id,
        issuer: fountWinningQuote?.issuer.name,
      };
    }

    return {
      id: responseModel.id,
      isActive: responseModel.is_active,
      organizationName: responseModel.halo_org_name,
      haloUser,
      buyer: `${haloUser.firstName} ${haloUser.lastName}`,
      salesRepresentative: haloUser.relationshipManager?.name,
      note: mapApiNoteModelToNoteModel(responseModel.note),
      noteId: responseModel.note_id,
      ergStatus: responseModel.ergonomic_status as AuctionStatusEnum,
      status: responseModel.status_slug as AuctionStatusEnum,
      style: (responseModel.auction_style as AuctionStyleEnum) ?? '',
      strikeStyle: responseModel.strike_style as AuctionStrikeStyleEnum,
      cusip: responseModel.cusip,
      isin: responseModel.isin,
      price: responseModel.price,
      notional: responseModel.notional,
      targetSolvableParameterValue: responseModel.target_value,
      targetSolvableParameter: responseModel.parameter,
      prelim: responseModel.prelim ? mapApiDocumentModelToS3DocumentModel(responseModel.prelim) : null,
      issuers: responseModel.issuers?.map(ApiIssuerMapper.toIssuerModel),
      quotes: responseModel.quotes?.map((quote) => ApiQuoteMapper.toAuctionQuoteModel(quote)),
      history: responseModel.auction_history?.map((history) => ApiAuctionMapper.toAuctionHistory(history)),
      startDateTime: responseModel.auction_begin,
      startLocalDateTime: responseModel.auction_begin_local,
      endDateTime: responseModel.auction_end,
      endLocalDateTime: responseModel.auction_end_local,
      targetSettleDate: responseModel.target_settle_date,
      targetStrikeDate: responseModel.target_strike_date,
      targetTradeDate: responseModel.target_trade_date,
      submittedDate: responseModel.created_at_iso,
      winningQuote: winningQuote,
      hasSMTP: responseModel.hassmtp,
      details: responseModel.additional_details ?? undefined,
      tackOn: responseModel.tackon ? ApiCalendarsMapper.toCalendarModel(responseModel.tackon) : undefined,
      termsheet: responseModel.termsheet ? ApiTermsheetMapper.toTermsheetModel(responseModel.termsheet) : undefined,
      unread: responseModel.unread,
      isTopOfBook: responseModel.show_top_of_book,
      updatedAt: responseModel.updated_at,
      createdAt: responseModel.created_at,
    };
  },
  toBuyerAuctionModel: (responseModel) => {
    const haloUser = ApiUserMapper.toUser(responseModel.halo_user);
    let winningQuote = {};

    if (responseModel.quotes) {
      const fountWinningQuote = responseModel.quotes.find((quote) => quote.is_winning_quote);

      winningQuote = {
        id: fountWinningQuote?.id,
        issuer: fountWinningQuote?.issuer.name,
        value: fountWinningQuote?.value,
      };
    }

    return {
      id: responseModel.id,
      buyer: `${haloUser.firstName} ${haloUser.lastName}`,
      salesRepresentative: haloUser.relationshipManager?.name,
      note: mapApiNoteModelToNoteModel(responseModel.note),
      noteId: responseModel.note_id,
      status: responseModel.status_slug as AuctionStatusEnum,
      ergStatus: responseModel.ergonomic_status as AuctionStatusEnum,
      style: (responseModel.auction_style as AuctionStyleEnum) ?? '',
      strikeStyle: responseModel.strike_style as AuctionStrikeStyleEnum,
      organizationName: responseModel.halo_org_name,
      notional: responseModel.notional,
      targetSolvableParameterValue: responseModel.target_value,
      targetSolvableParameter: responseModel.parameter,
      startDateTime: responseModel.auction_begin,
      endDateTime: responseModel.auction_end,
      termsheet: responseModel.term_sheet,
      prelim: responseModel.prelim ? mapApiDocumentModelToS3DocumentModel(responseModel.prelim) : null,
      quotes: responseModel.quotes?.map((quote) => ApiQuoteMapper.toAuctionQuoteModel(quote)),
      issuerOptOutCount: responseModel.issuer_opt_out_count,
      numberIssuersResponded: responseModel.number_issuers_responded,
      finalTermsheet: responseModel.final_termsheet_document
        ? mapApiDocumentModelToS3DocumentModel(responseModel.final_termsheet_document)
        : null,
      issuers: responseModel.issuers?.map(ApiIssuerMapper.toIssuerModel),
      winningQuote: winningQuote,
      allocations:
        responseModel.allocations?.map((allocation) => ApiAllocationMapper.toAllocationModel(allocation)) ?? [],
      details: responseModel.additional_details ?? undefined,
      isPendingApproval: responseModel.is_pending_approval,
      tradeDate: responseModel.trade_date,
      targetSettleDate: responseModel.target_settle_date,
      targetStrikeDate: responseModel.target_strike_date,
      targetTradeDate: responseModel.target_trade_date,
    };
  },
  toAuctionHistory: (model) => ({
    action: model.action_type,
    participant: model?.halo_user?.name ?? '',
    email: model?.halo_user?.email ?? '',
    value: model.submitted_value,
    id: model.id,
    adminTriggeredEvent: model.was_admin,
    createdAt: DateTime.fromSQL(model.created_at).toISO() as string,
    date: DateTime.fromFormat(model.created_at, AUCTION_HISTORY_DATE_TIME_FORMAT)?.toFormat(
      AUCTION_HISTORY_DATE_FORMAT,
    ),
    time: DateTime.fromFormat(model.created_at, AUCTION_HISTORY_DATE_TIME_FORMAT)?.toFormat(
      AUCTION_HISTORY_TIME_FORMAT,
    ),
  }),
  toStartAuctionResponseModel: (model) => ({
    auctionId: model.auction_id,
  }),
};
