import { ReactElement } from 'react';

import { navigateParentTo } from '@halo-common/utils';
import { useSuitabilityFormManager } from '@halo-modules/app/v2/onboarding/subPages/suitability';
import { SuitabilityPageMeta } from '@halo-pages/app/onboarding/suitability';
import { LocalizedButton, LocalizedButtonProps } from '@halodomination/halo-fe-common';

export type FinishOnboardingButtonProps = LocalizedButtonProps;

export const FinishOnboardingButton = ({ ...props }: FinishOnboardingButtonProps): ReactElement => {
  const { firstUnansweredStep } = useSuitabilityFormManager();

  const navigateToSuitabilityForm = () => {
    let url = '/react' + SuitabilityPageMeta.route;

    if (firstUnansweredStep) url += `?step=${firstUnansweredStep.id}`;

    return navigateParentTo(url);
  };

  return <LocalizedButton onClick={navigateToSuitabilityForm} {...props} />;
};
