import { translations } from '@halo-common/translations';
import { deleteAuctionAllocation } from '@halo-data-sources/clients';
import { AuctionQueryKeyFactory } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationOptions, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

export type DeleteBuyerAuctionAllocationModel = {
  auctionId: number;
  allocationId: number;
};

const auctionDeleteAllocationMutationFn = async (payload: DeleteBuyerAuctionAllocationModel) => {
  await deleteAuctionAllocation(payload);
};

export const useAuctionDeleteAllocationMutation = (
  options: UseMutationOptions<void, Error, DeleteBuyerAuctionAllocationModel>,
): UseMutationResult<void, Error, DeleteBuyerAuctionAllocationModel> => {
  const { enqueueErrorEvent, enqueueSuccessEvent } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: auctionDeleteAllocationMutationFn,
    onSuccess: (data, payload, context) => {
      const key = AuctionQueryKeyFactory.buyerAuction(payload.auctionId?.toString());
      void queryClient.invalidateQueries({ queryKey: key });

      enqueueSuccessEvent({ message: translations.orderBook.auctions.deletedAllocationsSuccessfully });
      options?.onSuccess?.(data, payload, context);
    },
    onError: (error, payload, context) => {
      const message = error.message || translations.common.error;
      enqueueErrorEvent({ message });
      options?.onError?.(error, payload, context);
    },
  });
};
