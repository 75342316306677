import { ReactElement } from 'react';

import { addPositionsToAccountModalAtom } from '@halo-atoms/portfolio';
import { ActionButton } from '@halo-common/components';
import { AssetIdLocationEnum } from '@halo-common/enums';
import { AddPositionsToAccountFormFields } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { CreatePositionsRequest } from '@halo-data-sources/mutations';
import { usePortfolioAssetExistenceQuery } from '@halo-data-sources/queries';
import { LocalizedButton } from '@halodomination/halo-fe-common';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';

export type AddPositionsToMultipleAccountsFooterContinueBtnProps = {
  handleCreatePositions: (payload: CreatePositionsRequest) => void;
  isLoading: boolean;
};

export const AddPositionsToAccountFooterContinueButton = ({
  handleCreatePositions,
  isLoading,
}: AddPositionsToMultipleAccountsFooterContinueBtnProps): ReactElement => {
  const { handleSubmit, setError, reset } = useFormContext<AddPositionsToAccountFormFields>();

  const [addPositionsToAccountModal, setAddPositionsToAccountModal] = useAtom(addPositionsToAccountModalAtom);
  const { modalStepMap, selectedAssetsAtom } = addPositionsToAccountModal;
  const { handleResetAtom, currentPageAtom, handleNextAtom } = modalStepMap;

  const handleReset = useSetAtom(handleResetAtom);
  const handleNext = useSetAtom(handleNextAtom);
  const step = useAtomValue(currentPageAtom);

  const assetIds = selectedAssetsAtom.map(({ label }) => label);
  const { data: assets } = usePortfolioAssetExistenceQuery(assetIds, { enabled: assetIds.length >= 1 });

  const assetList = assets ?? [];

  const allAssetIdsValid = assetList.every((asset) => asset.status === 'resolved');

  const mapPositionsToRequest = (positions: AddPositionsToAccountFormFields['positions']) => {
    return positions.map((position) => ({
      termsheetId: position.termsheetId,
      accountId: position.accountId ?? '',
      notional: position.notional ?? 0,
      assetId: position.assetId,
    }));
  };

  const handleAddPositionsToAccount = (data: AddPositionsToAccountFormFields) => {
    const queuedPositions = data.positions.filter((position) => position?.location === AssetIdLocationEnum.edgar);
    const positions = data.positions.filter((position) => position?.location !== AssetIdLocationEnum.edgar);

    const positionsLength = positions.length;
    const queuedPositionsLength = queuedPositions.length;

    const mappedPositions = mapPositionsToRequest(positions);
    const mappedQueuePositions = mapPositionsToRequest(queuedPositions);

    const payload = {
      queuedPositions: queuedPositionsLength ? [...mappedQueuePositions] : undefined,
      positions: positionsLength ? mappedPositions : undefined,
    };

    handleCreatePositions(payload);
  };

  const submitCusips = () => {
    const isValid = selectedAssetsAtom.length > 0 && allAssetIdsValid && assetList.length === assetIds.length;

    if (isValid) handleNext();
    else setError('positions', { message: translations.portfolio.addPositionsToAccountModal.multipleAssetHelperText });
  };

  const handleClose = () => {
    reset();
    handleReset();
    setAddPositionsToAccountModal();
  };

  const chooseAssetsButton = (
    <ActionButton loading={isLoading} onClick={submitCusips} variant="contained" type="submit" color="primary">
      {translations.common.continue}
    </ActionButton>
  );

  const allocateNotionalButton = (
    <ActionButton
      loading={isLoading}
      onClick={handleSubmit(handleAddPositionsToAccount)}
      variant="contained"
      type="submit"
      color="primary"
    >
      {translations.common.continue}
    </ActionButton>
  );

  const finishButton = (
    <LocalizedButton variant="contained" color="primary" onClick={handleClose}>
      {translations.common.finish}
    </LocalizedButton>
  );

  const continueButtonList = [chooseAssetsButton, allocateNotionalButton, finishButton];

  return continueButtonList[step];
};
