import { ReactElement, useContext, useEffect, useState } from 'react';

import { AuctionAdminModel } from '@halo-common/models';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { TackOnAlert, TackOnAuctionDetails, TackOnContext, TackOnForm, TackOnHeader } from '@halo-modules/admin';
import { OrdersSelectors } from '@halo-stores/Orders';
import { Iconography, IconographyColors, Modal, Stack } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Button, Divider, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const fullWidthSx = (theme: HaloTheme) => ({
  width: `calc(100% - ${theme.spacing(3)}px)`,
});

export type CreateTackOnProps = {
  open: boolean;
  auction: AuctionAdminModel | null | undefined;
  onNext: () => void;
  onClose: () => void;
};

export const CreateTackOn = ({ open, auction, onNext, onClose }: CreateTackOnProps): ReactElement => {
  const [errorStatus, setErrorStatus] = useState<string | undefined>(undefined);

  const error = useSelector(OrdersSelectors.selectError);

  const { data: userInfo } = useUserInfoQuery();
  const issuers = userInfo?.whiteLabel.issuers ?? [];

  const { startShowingDate, startShowingTime, expirationDate, expirationTime } = useContext(TackOnContext);
  const { formState } = useFormContext();

  const { isValid } = formState;
  const disabled = !isValid || !startShowingDate || !startShowingTime || !expirationDate || !expirationTime;
  const overline = auction?.id ? `Auction ${auction.id}` : '';

  const headerProps = {
    AdditionalContent: <TackOnHeader note={auction?.note} />,
  };

  const iconColor: IconographyColors = disabled ? 'text.disabled' : 'common.white';

  const errorMessage = errorStatus ? (
    <TackOnAlert>
      <Typography>Tack On Creation was not completed due to an error.</Typography>
      <Typography>{errorStatus}</Typography>
    </TackOnAlert>
  ) : null;

  useEffect(() => {
    setErrorStatus(error);
  }, [error]);

  return (
    <Modal
      open={open}
      size="large"
      onClose={onClose}
      overline={overline}
      title="Create Tack On Opportunity"
      HeaderProps={headerProps}
      footer={
        <Stack direction="row" justify="flex-end">
          <Button
            onClick={onNext}
            disabled={disabled}
            variant="contained"
            color="primary"
            startIcon={<Iconography iconName="add" color={iconColor} />}
          >
            Create New Tack On
          </Button>
        </Stack>
      }
    >
      <Stack direction="column" spacing={2}>
        <TackOnAuctionDetails auction={auction} note={auction?.note} />
        <Divider sx={fullWidthSx} />
        {errorMessage}
        <TackOnForm auction={auction} issuers={issuers} />
      </Stack>
    </Modal>
  );
};
