import { ReactElement } from 'react';

import { selectIssuersAtom } from '@halo-atoms/auctions';
import { useIssuersQuery } from '@halo-data-sources/queries';
import {
  GridColDef,
  GridRowId,
  HaloDataGrid,
  HaloDataGridProps,
  useCurrencyConverter,
} from '@halodomination/halo-fe-common';
import { useSetAtom } from 'jotai';

interface UpdateSelectionBase {
  values: () => Iterable<GridRowId>;
}

const DEFAULT_CELL_VALUE = 'N/A';

const tableSx = {
  color: 'error.main',
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderLeft: 'unset',
    borderRight: 'unset',
  },
  '& .MuiDataGrid-cell': {
    color: 'text.secondary',
  },
  '& .name-cell': {
    fontWeight: 'fontWeightBold',
    color: 'text.primary',
  },
  '& .cds-header': {
    textTransform: 'uppercase',
  },
};

export const AuctionIssuersTable = (): ReactElement | null => {
  const { data: issuers, isSuccess } = useIssuersQuery(true);

  const selectIssuers = useSetAtom(selectIssuersAtom);

  if (!isSuccess) {
    return null;
  }

  const formatCurrency = (value: number) => useCurrencyConverter(value);

  const handleSelectChange = <T extends UpdateSelectionBase>(selectedIds: T) => {
    selectIssuers(selectedIds as unknown as Array<number>);
  };

  const commonCellProps: Partial<GridColDef> = {
    align: 'center',
    editable: false,
    resizable: true,
    disableColumnMenu: true,
    sortable: true,
    flex: 1,
    cellClassName: 'cell',
    minWidth: 80,
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      field: 'name',
      headerName: 'Issuer',
      align: 'left',
      flex: 2,
      minWidth: 200,
      cellClassName: 'name-cell',
    },
    {
      ...commonCellProps,
      field: 'minInvestmentSize',
      headerName: 'Minimum',
      align: 'right',
      flex: 1.5,
      minWidth: 150,
      valueGetter: (value: number) => formatCurrency(value)[0],
      renderCell: ({ value }) => value || DEFAULT_CELL_VALUE,
    },
    {
      ...commonCellProps,
      field: 'fitch',
      headerName: 'Fitch',
      valueGetter: (_, row) => row.fitch || DEFAULT_CELL_VALUE,
    },
    {
      ...commonCellProps,
      field: 'moody',
      headerName: 'Moody',
      valueGetter: (_, row) => row.moody || DEFAULT_CELL_VALUE,
    },
    {
      ...commonCellProps,
      field: 'sp',
      headerName: 'S&P',
      valueGetter: (_, row) => row.sp || DEFAULT_CELL_VALUE,
    },
    {
      ...commonCellProps,
      field: 'cd5Year',
      headerName: '5Y CDS',
      headerClassName: 'cds-header',
      align: 'right',
      valueGetter: (_, row) => row.cd5Year?.toFixed(2) || DEFAULT_CELL_VALUE,
    },
    {
      ...commonCellProps,
      field: 'cd10Year',
      headerName: '10Y CDS',
      headerClassName: 'cds-header',
      align: 'right',
      valueGetter: (_, row) => row.cd10Year?.toFixed(2) || DEFAULT_CELL_VALUE,
    },
  ];

  return (
    <HaloDataGrid
      sx={tableSx}
      height="auto"
      rows={issuers}
      columns={columns}
      density="compact"
      checkboxSelection
      onRowSelectionModelChange={handleSelectChange}
    />
  );
};
