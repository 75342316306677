import { postCalendarEmailSubscribe } from '@halo-data-sources/clients';
import { ApiPostCalendarEmailSubscribeResponseModel } from '@halo-data-sources/models';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';

const updateCalendarFavoriteFn = async (payload: string) => {
  const response = await postCalendarEmailSubscribe(payload);
  return response;
};

export type useCalendarEmailSubscribeMutationOptions = UseMutationOptions<
  ApiPostCalendarEmailSubscribeResponseModel,
  Error,
  string
> & {
  successMessage?: string;
  errorMessage?: string;
};

export type useCalendarEmailSubscribeMutationResult = UseMutationResult<
  ApiPostCalendarEmailSubscribeResponseModel,
  Error,
  string
>;

export const useCalendarEmailSubscribeMutation = ({
  successMessage = 'You have successfully subscribed your email.',
  errorMessage = 'There was an error when trying to subscribe your email.',
  ...options
}: useCalendarEmailSubscribeMutationOptions = {}): useCalendarEmailSubscribeMutationResult => {
  const { enqueueErrorEvent, enqueueSuccessEvent } = useSnackbar();

  return useMutation({
    mutationFn: updateCalendarFavoriteFn,
    ...options,
    onSuccess: (...props) => {
      if (successMessage) enqueueSuccessEvent({ message: successMessage });
      options?.onSuccess?.(...props);
    },
    onError: (...props) => {
      if (errorMessage) enqueueErrorEvent({ message: errorMessage });
      options?.onError?.(...props);
    },
  });
};
