export const PROFILE_NOTIFICATIONS_TRANSLATIONS = {
  onlyRead: 'Show Only Unread',
  preferences: 'Notification Preferences',
  emptyListTitle: 'No Notifications To Show',
  emptyListMessage: 'Please adjust your filters.',
  detailsButtonTitle: 'Details',
  noOptions: 'No possible options',
  markAsReadButtonTitle: 'Mark as Read',
  searchByType: 'Search by Type',
  notificationDatePickerLabel: 'Notification Date',
};
