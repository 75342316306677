import { ReactElement, useState } from 'react';

import { DisclaimerModal } from '@halo-common/modals';
import { DisclaimerOnboardingModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { DisclaimerUrgencyEnum } from '@halo-data-sources/enums';
import { useDisclaimerAcceptanceMutation } from '@halo-data-sources/mutations/onboarding';
import { useOnboardingDisclaimers } from '@halo-data-sources/queries';
import { DisclaimerListItem } from '@halo-modules/app/onboarding';
import { LocalizedButton } from '@halodomination/halo-fe-common';
import { List, Paper, Stack } from '@mui/material';

const containerSx = {
  padding: 3,
  overflowY: 'auto',
  maxWidth: 450,
  minWidth: { xs: 343, sm: 'default' },
  width: '100%',
};

const disclaimerListSx = {
  width: '100%',
};

const mainButtonSx = {
  width: '100%',
  height: 42,
  marginTop: 3,
};

export type DisclaimerListProps = {
  disclaimers: Array<DisclaimerOnboardingModel>;
};

export type DisclaimerAcceptanceSchema = {
  disclaimerId: number;
};

export const DisclaimerList = (): ReactElement => {
  const { data: disclaimers } = useOnboardingDisclaimers();
  const { mutate: acceptDisclaimer } = useDisclaimerAcceptanceMutation();

  const [selectedDisclaimer, setSelectedDisclaimer] = useState<DisclaimerOnboardingModel | null>(null);
  const open = Boolean(selectedDisclaimer);

  const filteredDisclaimers = disclaimers?.filter(
    (disclaimer) => disclaimer.urgency === DisclaimerUrgencyEnum.ONBOARDING,
  );

  const allDisclaimersAccepted = filteredDisclaimers?.every((disclaimer) => disclaimer.isAccepted) ?? false;

  const handleOpen = (disclaimer: DisclaimerOnboardingModel | null) => {
    setSelectedDisclaimer(disclaimer);
  };

  const handleClose = () => {
    setSelectedDisclaimer(null);
  };

  const handleDisclaimerAcceptance = (id: number) => {
    acceptDisclaimer(id);
    handleClose();
  };

  const handleFinish = () => navigateParentTo('/');

  const disclaimerList = filteredDisclaimers?.map((disclaimer) => {
    const acceptedDisclaimerKey = disclaimer.id.toString();

    return (
      <DisclaimerListItem
        key={acceptedDisclaimerKey}
        disclaimer={disclaimer}
        disclaimerIsAccepted={disclaimer.isAccepted}
        handleOpen={handleOpen}
      />
    );
  });

  return (
    <Paper variant="outlined" sx={containerSx}>
      <List sx={disclaimerListSx}>
        <Stack direction="column">{disclaimerList}</Stack>
      </List>
      <LocalizedButton
        sx={mainButtonSx}
        variant="contained"
        color="primary"
        disabled={!allDisclaimersAccepted}
        onClick={handleFinish}
      >
        {translations.common.finish}
      </LocalizedButton>
      <DisclaimerModal
        disclaimer={selectedDisclaimer}
        open={open}
        disclaimerIsAccepted={selectedDisclaimer?.isAccepted ?? false}
        handleDisclaimerAcceptance={handleDisclaimerAcceptance}
        handleClose={handleClose}
      />
    </Paper>
  );
};
