// TODO: https://halodomination.atlassian.net/browse/HADT-17799
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */

import { MouseEvent, ReactElement, ReactNode, useEffect, useState } from 'react';

import { LocalizedButton, LocalizedButtonProps } from '@halodomination/halo-fe-common';
import { Popover, PopoverProps as PopoverPropsType } from '@mui/material';

const buttonSx = {
  py: 0,
};

export type DocumentDownloadTableButtonPopoverProps = LocalizedButtonProps & {
  buttonText: string;
  children: ReactNode;
  PopoverProps?: Partial<PopoverPropsType>;
};

export const DocumentDownloadTableButtonPopover = ({
  buttonText,
  children,
  PopoverProps,
  ...props
}: DocumentDownloadTableButtonPopoverProps): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const ariaOwns = open ? 'document-download-button-popover' : undefined;

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const shouldPopoverClose = open && typeof PopoverProps?.open === 'boolean' && !PopoverProps.open;
    if (shouldPopoverClose) setAnchorEl(null);
  }, [PopoverProps?.open]);

  return (
    <>
      <LocalizedButton aria-owns={ariaOwns} aria-haspopup="true" onClick={handlePopoverOpen} sx={buttonSx} {...props}>
        {buttonText}
      </LocalizedButton>
      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        disableRestoreFocus
        {...PopoverProps}
        id="document-download-button-popover"
        open={open}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
      >
        {children}
      </Popover>
    </>
  );
};
