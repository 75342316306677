import { ReactElement, ReactNode } from 'react';

import { LocaleDropdown } from '@halo-common/smartComponents';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import {
  OnBoardingProps,
  OnboardingDrawer,
  OnboardingNavBar,
  useOnboardingSettingValidation,
} from '@halo-modules/app/v2/onboarding';
import { Stack, useMediaQuery, useTheme } from '@mui/material';

export const ONBOARDING_CONTENT_MIN_HEIGHT = 800;

const onboardingSx = {
  minHeight: '100vh',
};

export type OnboardingLayoutProps = {
  children: NonNullable<ReactNode>;
  fallback?: ReactNode;
  isLoading?: boolean;
  variant?: OnBoardingProps['variant'];
  showLocaleDropdown?: boolean;
};

export const OnboardingLayout = ({
  children,
  fallback,
  isLoading = false,
  variant,
  showLocaleDropdown = true,
}: OnboardingLayoutProps): ReactElement => {
  const theme = useTheme();

  const { isPending } = useUserInfoQuery();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = variant === 'small';

  const hasContent = useOnboardingSettingValidation();
  const justify = !isMobile && !hasContent ? 'center' : 'flex-start';

  const direction = !isMobile ? 'row' : 'column';
  const loading = isLoading || isPending;

  const desktopLocaleDropdownSx = {
    position: 'absolute',
    top: isMobile ? 8 : 20,
    width: 170,
    right: isMobile ? 8 : 48,
  };

  const contentSx = {
    flexBasis: isSmall ? '100%' : '50%',
    py: isMobile ? 10 : 8,
    px: 6,
  };

  const displayContent = loading ? fallback : children;

  const leftDrawer = !isMobile ? (
    <OnboardingDrawer isLoading={loading} variant={variant} />
  ) : isSmall ? (
    <OnboardingNavBar />
  ) : null;

  const localeDropdown = showLocaleDropdown ? <LocaleDropdown containerSx={desktopLocaleDropdownSx} /> : null;

  return (
    <>
      {localeDropdown}
      <Stack direction={direction} sx={onboardingSx} justifyContent={justify}>
        {leftDrawer}
        <Stack direction="column" sx={contentSx} alignItems="center" justifyContent="center">
          {displayContent}
        </Stack>
      </Stack>
    </>
  );
};
