import { ReactElement, useState } from 'react';

import type { TermsheetRepoDocModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useUpdateTermsheetRepoDocMutation } from '@halo-data-sources/mutations';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import type { SubmitHandler } from 'react-hook-form';

import { DeleteTermsheetModal } from '../DeleteTermsheetModal';
import { TermsheetFormSchema, TermsheetModalForm, useTermsheetToDefaultValues } from '../TermsheetModalForm';

export interface EditTermsheetModalProps {
  termsheet: TermsheetRepoDocModel;
}

export const EditTermsheetModal = ({ termsheet }: EditTermsheetModalProps): ReactElement | null => {
  const [open, setOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const updateMutation = useUpdateTermsheetRepoDocMutation({
    onSuccess: () => {
      updateMutation.reset();
      setOpen(false);
      setDeleteModalOpen(false);
    },
  });

  const defaultValues = useTermsheetToDefaultValues(termsheet);

  if (!termsheet) return null;

  const onClose = () => {
    updateMutation.reset();
    setOpen(false);
    setDeleteModalOpen(false);
  };

  const openDeleteModal = () => setDeleteModalOpen(true);

  const closeDeleteModal = () => setDeleteModalOpen(false);

  const actions = (
    <>
      <LocalizedButton
        variant="outlined"
        color="error"
        size="large"
        onClick={openDeleteModal}
        data-testid="delete-termsheet-button"
      >
        {translations.common.delete}
      </LocalizedButton>
      <DeleteTermsheetModal
        ids={[termsheet.id]}
        open={deleteModalOpen}
        onClose={closeDeleteModal}
        onSuccess={onClose}
      />
    </>
  );

  const onSubmit: SubmitHandler<TermsheetFormSchema> = (fields) =>
    updateMutation.mutate({
      ...fields,
      id: termsheet.id,
    });

  return (
    <>
      <LocalizedButton
        variant="text"
        color="primary"
        size="small"
        startIcon={<Iconography iconName="edit" color="inherit" />}
        onClick={() => setOpen(true)}
        data-testid="edit-termsheet-button"
      >
        {translations.common.edit}
      </LocalizedButton>
      <TermsheetModalForm
        title={translations.documents.termsheets.editTermsheet}
        open={open}
        onClose={onClose}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        isPending={updateMutation.isPending}
        actions={actions}
      />
    </>
  );
};
