import { PortfolioPositionReferenceModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { array as yupArray, number as yupNumber, object as yupObject, string as yupString } from 'yup';

const accountIdRequiredText = translations.portfolio.addPositionsToAccountModal.enterAccountError;
const notionalRequiredText = translations.portfolio.addPositionsToAccountModal.enterNotionalError;

export const addPositionToMultipleAccountsFormSchema = yupObject().shape({
  positions: yupArray()
    .defined(translations.messages.requiredField)
    .of(
      yupObject().shape({
        termsheetId: yupNumber().defined(translations.messages.requiredField),
        notional: yupNumber().required(notionalRequiredText).min(1, notionalRequiredText),
        account: yupObject().required(accountIdRequiredText),
      }),
    ),
  assetId: yupString(),
});

export type AddPositionToMultipleAccountsFormFields = {
  positions: Array<PortfolioPositionReferenceModel>;
  assetId?: string;
};
