import { OrderBookQuery } from '@halo-atoms/orderbook';
import { OrderBookJanneyStatusEnum, OrderBookTimeFrame } from '@halo-common/enums';
import { ApiComparisonOptionEnum } from '@halo-data-sources/enums';
import { OrderBookMapper } from '@halo-data-sources/mappers';
import { ApiComparisonModel } from '@halo-data-sources/models';

interface JanneyOrderBookMapper {
  toApiFiltersComparisonList: (query: OrderBookQuery) => Array<ApiComparisonModel>;
}

export const JanneyOrderBookMapper: JanneyOrderBookMapper = {
  toApiFiltersComparisonList: (query: OrderBookQuery): Array<ApiComparisonModel> => {
    const comparisons: Array<ApiComparisonModel> = [];

    const filters = query.filters;
    const capacity = filters.capacity;
    const issuer = filters.issuer;
    const status = filters.status;

    const addCapacityFilter = capacity && capacity !== 'All';
    const addIssuerFilter = issuer && issuer !== 'All';
    const addStatusFilter = status && status !== 'All';

    if (addIssuerFilter) {
      comparisons.push({
        field: 'issuers.id',
        op: ApiComparisonOptionEnum.EQ,
        value: parseInt(issuer),
      });
    }

    if (filters.userName) {
      comparisons.push({
        field: 'user_info.full_name',
        op: ApiComparisonOptionEnum.ILIKE,
        value: filters.userName,
      });
    }

    if (filters.account?.household?.id) {
      comparisons.push({
        field: 'advisees.id',
        op: ApiComparisonOptionEnum.EQ,
        value: filters.account?.household?.id,
      });
    }

    if (filters.account?.account?.accountId) {
      comparisons.push({
        field: 'accounts.account_identifier',
        op: ApiComparisonOptionEnum.EQ,
        value: filters.account.account.accountId,
      });
    }

    if (addCapacityFilter) {
      comparisons.push({
        field: 'accounts.designation',
        op: ApiComparisonOptionEnum.EQ,
        value: capacity,
      });
    }

    if (filters.orderNumber) {
      comparisons.push({
        field: 'exec_order.id',
        op: ApiComparisonOptionEnum.ILIKE,
        value: filters.orderNumber,
      });
    }

    if (filters.cusip) {
      comparisons.push({
        field: 'calendar_notes.cusip',
        op: ApiComparisonOptionEnum.ILIKE,
        value: filters.cusip,
      });
    }

    if (addStatusFilter) {
      const janneyStatus = status as OrderBookJanneyStatusEnum;
      const shouldUpdateStatus = janneyStatus === OrderBookJanneyStatusEnum.accepted;

      const execOrderStatus = shouldUpdateStatus ? 'active' : status;

      comparisons.push({
        field: 'exec_order.status',
        op: ApiComparisonOptionEnum.EQ,
        value: execOrderStatus.toUpperCase(),
      });
    }

    if (filters.timeFrame) {
      const timeFrame = OrderBookMapper.toApiOrderBookTimeFrameString(filters.timeFrame as OrderBookTimeFrame);

      comparisons.push({
        field: 'calendar_allocations.updated_at',
        op: ApiComparisonOptionEnum.GTE,
        value: timeFrame,
      });
    }

    return comparisons;
  },
};
