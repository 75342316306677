import { ReactElement } from 'react';

import { LocalizedTextField, LocalizedTextFieldProps } from '@halodomination/halo-fe-common';
import { AutocompleteRenderInputParams } from '@mui/material';
import { FieldError } from 'react-hook-form';

export type CustodianFormTextFieldProps = Omit<AutocompleteRenderInputParams, 'size'> & {
  error?: FieldError;
  label: string;
  helperText: LocalizedTextFieldProps['helperText'];
};

export const CustodianFormTextField = ({
  error,
  helperText,
  label,
  ...props
}: CustodianFormTextFieldProps): ReactElement => (
  <LocalizedTextField
    {...props}
    required
    size="large"
    label={label}
    error={Boolean(error) ?? false}
    helperText={error?.message ?? helperText}
  />
);
