import { ReactElement } from 'react';

import { BrandIcon, Iconography, Modal, Stack } from '@halodomination/halo-fe-common';
import { Button, Typography } from '@mui/material';

export type ConfirmCreateTackOnProps = {
  open: boolean;
  onClose: () => void;
  onBack: () => void;
  onSubmit: () => void;
};

export const ConfirmCreateTackOn = ({ open, onClose, onBack, onSubmit }: ConfirmCreateTackOnProps): ReactElement => {
  return (
    <Modal
      open={open}
      size="small"
      onClose={onClose}
      hideHeader
      footer={
        <Stack direction="row" justify="space-between">
          <Button onClick={onBack} color="primary" startIcon={<Iconography iconName="arrow-left" />}>
            Go Back
          </Button>
          <Button onClick={onSubmit} variant="contained" color="primary">
            Confirm Tack-On
          </Button>
        </Stack>
      }
    >
      <Stack direction="row" spacing={2}>
        <BrandIcon variant="confirm" />
        <Stack direction="column" spacing={2}>
          <Typography variant="h5">Confirm Tack On Creation</Typography>
          <Typography variant="body1">
            This tack on will begin appearing on all selected Calendar pages immediately. It can be removed in the event
            it needs to be undone.
          </Typography>
        </Stack>
      </Stack>
    </Modal>
  );
};
