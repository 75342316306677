import { downloadFile } from '@halo-common/utils';
import { getPortfolioAccountPerformanceReport } from '@halo-data-sources/clients';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';

export type GeneratePortfolioPerformanceReportOptions = {
  success?: string;
  error?: string;
  pending?: string;
  sub?: string;
};

export type GeneratePortfolioPerformanceReportQuery = {
  account_id?: string;
  advisee_id?: string;
};

export type GeneratePortfolioPerformanceReportRequest = {
  accountId?: number;
  adviseeId?: number;
  reportingCurrencyId?: number;
  userId?: number;
};

const generatePortfolioPerformanceReportFn = async (request: GeneratePortfolioPerformanceReportRequest) => {
  const { accountId, adviseeId, reportingCurrencyId, userId } = request;

  if (!accountId && !adviseeId && !userId) return undefined;

  const payload = {
    account_selector: {
      account_ids: accountId ? [accountId] : undefined,
      advisee_ids: adviseeId ? [adviseeId] : undefined,
      synced: null,
    },
    reporting_currency_id: reportingCurrencyId,
  };

  const response = await getPortfolioAccountPerformanceReport(payload);

  const fileName = adviseeId
    ? `Household_Portfolio_Performance_Report_${uuid().substring(0, 7)}`
    : `Portfolio_Performance_Report_${uuid().substring(0, 7)}`;

  downloadFile({ fileName, downloadFilePath: response.download_url });
};

export const useGeneratePortfolioPerformanceReportMutation = (
  messages?: GeneratePortfolioPerformanceReportOptions,
): UseMutationResult<
  GeneratePortfolioPerformanceReportOptions | void,
  Error,
  GeneratePortfolioPerformanceReportRequest
> => {
  const { enqueueSuccessEvent, enqueuePendingEvent, enqueueWarningEvent, closeSnackbar } = useSnackbar();

  return useMutation<void, Error, GeneratePortfolioPerformanceReportRequest>({
    mutationFn: generatePortfolioPerformanceReportFn,
    onMutate: () => {
      if (messages?.pending) enqueuePendingEvent({ message: messages.pending, subMessage: messages.sub });
    },
    onError: () => {
      closeSnackbar();
      if (messages?.error) enqueueWarningEvent({ message: messages.error, subMessage: messages.sub });
    },
    onSuccess: () => {
      closeSnackbar();
      if (messages?.success) enqueueSuccessEvent({ message: messages.success, subMessage: messages.sub });
    },
  });
};
