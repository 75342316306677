import { ReactElement } from 'react';

import { orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { QuoteAuctionModel } from '@halo-common/models';
import { useBuyerAuctionQuery } from '@halo-data-sources/queries';
import { Iconography } from '@halodomination/halo-fe-common';
import { Tooltip, styled, tooltipClasses, TooltipProps } from '@mui/material';
import { useAtomValue } from 'jotai';

export type AuctionDetailsTargetMetProps = {
  variant: 'target' | 'bid';
  quote?: QuoteAuctionModel;
};

export const AuctionDetailsTargetMet = ({ variant, quote }: AuctionDetailsTargetMetProps): ReactElement | null => {
  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);
  const auctionId = selectedAuctionId?.toString();

  const { data: auction } = useBuyerAuctionQuery(auctionId);

  const isTargetVariant = variant === 'target';
  const target = auction?.targetSolvableParameterValue || 0;
  const isTargetMet = quote?.value && quote.value >= target;
  const showTooltip = isTargetVariant || isTargetMet;

  const targetTooltipText = 'Your Target';
  const difference = quote?.value && quote?.value > 0 ? (quote?.value - target).toFixed(2) : null;
  const bidTooltipText = difference ? `+${difference}% above target` : `Target met`;
  const tooltipText = isTargetVariant ? targetTooltipText : bidTooltipText;

  const variantColor = isTargetVariant ? 'primary' : 'success';
  const targetIconProps = {
    sx: {
      display: 'inline-block',
      p: 0.6,
      borderRadius: 2,
      lineHeight: 0,
      backgroundColor: `${variantColor}.background`,
    },
  };
  const tooltipSx = {
    [`& .${tooltipClasses.arrow}`]: {
      color: `${variantColor}.background`,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: `${variantColor}.background`,
      color: 'text.primary',
    },
  };

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement="top" arrow />
  ))(() => tooltipSx);

  return showTooltip ? (
    <StyledTooltip title={tooltipText}>
      <Iconography
        iconName="bullseye-arrow"
        prefix="fal"
        ContainerProps={targetIconProps}
        color={`${variantColor}.main`}
      />
    </StyledTooltip>
  ) : null;
};
