import {
  LifecycleAllEventTypeEnum,
  LifecycleConditionalEventTypeEnum,
  LifecycleEventTypeEnum,
  LifecycleSortEnum,
  PortfolioEventSortDropdownValueEnum,
  SortModelDirectionEnum,
} from '@halo-common/enums';
import {
  PortfolioLifecycleEventModel,
  PortfolioLifecycleModel,
  PortfolioLifecycleRecommendationModel,
  SortModel,
} from '@halo-common/models';
import { ApiComparisonOptionEnum } from '@halo-data-sources/enums';
import { ApiAccountMapper } from '@halo-data-sources/mappers';
import {
  ApiLifecycleComparisonRequestModel,
  ApiLifecycleEventDetailRequestModel,
  ApiLifecycleRecommendationModel,
  ApiLifecycleRecommendationRequestModel,
  ApiPortfolioLifecycleEventDetailModel,
  ApiPortfolioLifecycleFilterRequestModel,
  ApiPortfolioLifecycleModel,
  ApiPortfolioLifecycleRequestModel,
  ApiSortModel,
} from '@halo-data-sources/models';
import {
  GetLifecycleEventDetailPayload,
  GetPortfolioLifecycleEventsQueryFilterModel,
} from '@halo-data-sources/queries';
import { GridSortModel } from '@halodomination/halo-fe-common';
import { v4 as uuid } from 'uuid';

export const ApiPortfolioLifecycleMapper = {
  toLifecycleModel: (event: ApiPortfolioLifecycleModel): PortfolioLifecycleModel => {
    return {
      cashFlowAmount: event.cash_flow_amount,
      cusip: event.cusip,
      distancePercent: event.distance_pct,
      eventDate: event.event_date,
      eventType: event.event_type,
      fundserv: event.fundservcode,
      isin: event.isin,
      paid: event.paid,
      currencyIsoCode: event.currency_iso_code,
      productId: event.product_id,
      totalNotional: event.total_notional,
      name: event.name.replace(/\s+/g, ' ').trim(),
      numberOfCoupons: event.num_coupons,
    };
  },
  toLifecycleEventBreakdownModel: (
    model: ApiPortfolioLifecycleEventDetailModel,
    originalCashFlowAmount: number,
    originalTotalNotional: number,
  ): PortfolioLifecycleEventModel => {
    return {
      totalNotional: (originalTotalNotional * model.notional_pct) / 100,
      cashFlowAmount: (originalCashFlowAmount * model.notional_pct) / 100,
      account: ApiAccountMapper.toAccountModel(model.account),
      uid: uuid(),
    };
  },
  toPortfolioLifecycleRecommendationModel: (
    model: ApiLifecycleRecommendationModel,
  ): PortfolioLifecycleRecommendationModel => {
    return {
      eventDate: model.event_date,
      eventType: model.event_type,
      productId: model.product_id,
      recommendationType: model.recommendation_type,
      totalNotional: model.total_notional,
    };
  },
};

export const PortfolioLifecycleMapper = {
  toApiLifecycleFilterModel: (
    model: GetPortfolioLifecycleEventsQueryFilterModel,
  ): ApiPortfolioLifecycleFilterRequestModel => {
    const { eventTypes, productType, productId, search } = model;

    const parsedEventTypes = eventTypes as
      | LifecycleEventTypeEnum
      | LifecycleAllEventTypeEnum
      | LifecycleConditionalEventTypeEnum;

    const requestModel: Partial<ApiPortfolioLifecycleFilterRequestModel> = {};

    if (search) requestModel.search = search;
    if (productType) requestModel.product_type = productType;
    if (productId) requestModel.product_id = productId;

    if (parsedEventTypes === LifecycleEventTypeEnum.EMPTY) return requestModel;

    const aboveCallObservation = parsedEventTypes === LifecycleConditionalEventTypeEnum.ABOVE_CALL_LEVEL;
    const belowCouponProtection = parsedEventTypes === LifecycleConditionalEventTypeEnum.BELOW_PROTECTION;
    const allCouponAndCallObservation = parsedEventTypes === LifecycleAllEventTypeEnum.ALL_COUPON_AND_CALL_OBSERVATIONS;
    const allCouponEvent = parsedEventTypes === LifecycleAllEventTypeEnum.ALL_COUPONS;
    const allCallObservationEvent = parsedEventTypes === LifecycleAllEventTypeEnum.ALL_CALL_OBSERVATION;

    if (allCouponAndCallObservation) {
      requestModel.event_types = [
        LifecycleEventTypeEnum.FIXED_COUPON,
        LifecycleEventTypeEnum.CONDITIONAL_COUPON,
        LifecycleEventTypeEnum.MEMORY_COUPON,
        LifecycleEventTypeEnum.AUTOCALL_OBS,
        LifecycleEventTypeEnum.ISSUER_CALL_OBS,
      ];
    } else if (allCouponEvent) {
      requestModel.event_types = [
        LifecycleEventTypeEnum.FIXED_COUPON,
        LifecycleEventTypeEnum.CONDITIONAL_COUPON,
        LifecycleEventTypeEnum.MEMORY_COUPON,
      ];
    } else if (allCallObservationEvent) {
      requestModel.event_types = [LifecycleEventTypeEnum.AUTOCALL_OBS, LifecycleEventTypeEnum.ISSUER_CALL_OBS];
    } else if (aboveCallObservation) {
      requestModel.event_types = [LifecycleEventTypeEnum.AUTOCALL_OBS];
    } else if (belowCouponProtection) {
      requestModel.event_types = [LifecycleEventTypeEnum.CONDITIONAL_COUPON, LifecycleEventTypeEnum.MEMORY_COUPON];
    } else if (parsedEventTypes) {
      requestModel.event_types = [parsedEventTypes];
    }

    return requestModel;
  },
  toApiLifecycleComparisonsRequestModel: (
    model: GetPortfolioLifecycleEventsQueryFilterModel,
  ): ApiLifecycleComparisonRequestModel => {
    if (!model.eventTypes) return undefined;

    const parsedEventTypes = model.eventTypes as LifecycleConditionalEventTypeEnum;
    const aboveCallObservation = parsedEventTypes === LifecycleConditionalEventTypeEnum.ABOVE_CALL_LEVEL;
    const belowCouponProtection = parsedEventTypes === LifecycleConditionalEventTypeEnum.BELOW_PROTECTION;

    if (aboveCallObservation) return [{ field: 'distance_pct', value: 0, op: ApiComparisonOptionEnum.GT }];
    else if (belowCouponProtection) return [{ field: 'distance_pct', value: 0, op: ApiComparisonOptionEnum.LT }];

    return undefined;
  },
  toApiLifecycleSortModel: (value: PortfolioEventSortDropdownValueEnum): ApiSortModel => {
    switch (value) {
      case PortfolioEventSortDropdownValueEnum.ASC_EVENT_DATE:
        return { direction: SortModelDirectionEnum.asc, field: 'event_date' };
      case PortfolioEventSortDropdownValueEnum.DESC_EVENT_DATE:
        return { direction: SortModelDirectionEnum.desc, field: 'event_date' };
      case PortfolioEventSortDropdownValueEnum.DESC_TOTAL_NOTIONAL:
        return { direction: SortModelDirectionEnum.desc, field: 'total_notional' };
      case PortfolioEventSortDropdownValueEnum.ASC_TOTAL_NOTIONAL:
        return { direction: SortModelDirectionEnum.asc, field: 'total_notional' };
      default:
        return { direction: SortModelDirectionEnum.asc, field: 'event_date' };
    }
  },
  toApiLifecycleModel: (model: GetPortfolioLifecycleEventsQueryFilterModel): ApiPortfolioLifecycleRequestModel => {
    const sortModel = PortfolioLifecycleMapper.toApiLifecycleSortModel(model.sortField);

    return {
      account_selector: {
        account_ids: model.accountId ? [model.accountId] : undefined,
        advisee_ids: model.householdId ? [model.householdId] : undefined,
        synced: model.synced ?? null,
      },
      comparisons: PortfolioLifecycleMapper.toApiLifecycleComparisonsRequestModel(model),
      end_date: model.endDate,
      filters: PortfolioLifecycleMapper.toApiLifecycleFilterModel(model),
      page: model.page ?? 0,
      page_length: model.pageLength ?? 15,
      sort: [{ direction: sortModel?.direction ?? 'asc', field: sortModel?.field ?? 'event_date' }],
      start_date: model.startDate,
    };
  },
  toApiLifecycleEventsSortModel: (model: GridSortModel): Array<SortModel> => {
    const fieldName = model[0].field;
    const direction = model[0].sort as SortModelDirectionEnum;
    const mappedName = LifecycleSortEnum[fieldName as keyof typeof LifecycleSortEnum];

    return [{ field: mappedName, direction }];
  },
  toApiLifecycleEventBreakdownModel: (model: GetLifecycleEventDetailPayload): ApiLifecycleEventDetailRequestModel => {
    return {
      account_selector: {
        account_ids: model.accountIds,
        advisee_ids: model.householdId ? [model.householdId] : undefined,
        synced: model.synced ?? null,
      },
      page: model.page,
      page_length: model.pageLength,
      term_sheet_id: model.productId,
      sort: PortfolioLifecycleMapper.toApiLifecycleEventsSortModel(model.sort),
    };
  },
  toApiLifecycleRecommendationRequestModel: (
    model: PortfolioLifecycleModel,
  ): ApiLifecycleRecommendationRequestModel => {
    return {
      event_date: model.eventDate,
      event_type: model.eventType,
      product_id: model.productId,
      distance_pct: model.distancePercent,
      total_notional: model.cashFlowAmount,
      paid: model.paid,
    };
  },
};
