import { ChangeEvent, ReactElement } from 'react';

import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { useDebounced } from '@halo-common/hooks';
import { TextField } from '@mui/material';
import { useAtom } from 'jotai';

const inputLabelProps = {
  shrink: true,
};

export const UserNameField = (): ReactElement => {
  const [searchFields, setSearchFields] = useAtom(orderBookQueryAtom);

  const handleFieldChange = (key: string, value: string) => {
    setSearchFields({
      filters: {
        [key]: value,
      },
    });
  };

  const debouncedSearchHandler = useDebounced(handleFieldChange, 500);

  const debouncedUserNameChange = (ev: ChangeEvent<HTMLInputElement>) =>
    debouncedSearchHandler('userName', ev.target.value);

  return (
    <TextField
      name="userName"
      label="User Name"
      size="large"
      placeholder="All"
      defaultValue={searchFields.filters.userName}
      onChange={debouncedUserNameChange}
      slotProps={{ inputLabel: inputLabelProps }}
      fullWidth
    />
  );
};
