import { ReactElement } from 'react';

import { DisclaimerOnboardingModel } from '@halo-common/models';
import { DisclaimerListButton } from '@halo-modules/app';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { ListItem, Stack } from '@mui/material';

const listItemSx = {
  width: '100%',
  px: 0,
  py: 3,
  borderBottom: '1px solid',
  borderColor: 'grey.100',
};

const disclaimerNameSx = {
  color: 'text.primary',
};

const disclaimerNameAcceptedSx = {
  color: 'text.secondary',
};

export type DisclaimerListItemProps = {
  disclaimer: DisclaimerOnboardingModel;
  disclaimerIsAccepted: boolean;
  handleOpen: (disclaimer: DisclaimerOnboardingModel | null) => void;
};

export const DisclaimerListItem = ({
  disclaimer,
  disclaimerIsAccepted,
  handleOpen,
}: DisclaimerListItemProps): ReactElement => {
  const disclaimerSx = disclaimerIsAccepted ? disclaimerNameAcceptedSx : disclaimerNameSx;

  return (
    <ListItem sx={listItemSx}>
      <Stack sx={{ width: '100%' }} direction="row" alignItems="center" justifyContent="space-between">
        <LocalizedTypography variant="h6" sx={disclaimerSx}>
          {disclaimer.displayName}
        </LocalizedTypography>
        <DisclaimerListButton
          disclaimer={disclaimer}
          disclaimerIsAccepted={disclaimerIsAccepted}
          handleOpen={handleOpen}
        />
      </Stack>
    </ListItem>
  );
};
