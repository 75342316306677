import { ReactElement, ReactNode } from 'react';

import { dynamicFiltersClearAtom } from '@halo-atoms/common';
import { EmptyResultsCard, EmptyResultsCardProps } from '@halo-common/components';
import { SideBarFilterChips } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { BrandIcon, LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useSetAtom } from 'jotai';

export type DynamicFiltersEmptyResultProps = Partial<EmptyResultsCardProps> & {
  tag: string;
  slots?: { action?: ReactNode };
  finalOfferingsDescription?: string;
  isFiltered?: boolean;
};

export const DynamicFiltersEmptyResult = ({
  tag,
  slots,
  finalOfferingsDescription = translations.dynamicFilters.common.finalOfferingsDescription,
  isFiltered = false,
  ...rest
}: DynamicFiltersEmptyResultProps): ReactElement => {
  const onClear = useSetAtom(dynamicFiltersClearAtom);

  const handleClear = () => onClear({ tag, action: 'all' });

  const finalizingOfferings = (
    <EmptyResultsCard
      brandIcon={<BrandIcon variant="finalizedCalendar" color="primary" />}
      title={translations.dynamicFilters.common.weAreFinalizingThisMonthsOfferings}
      description={finalOfferingsDescription}
      action={slots?.action}
      testId="finalizing-offerings-card"
      {...rest}
    />
  );

  const action = (
    <Stack direction="column" sx={{ gap: 2 }}>
      <SideBarFilterChips tag={tag} />
      <LocalizedButton variant="outlined" onClick={handleClear}>
        {translations.dynamicFilters.common.clearAllFiltersAndSearches}
      </LocalizedButton>
      <LocalizedTypography variant="caption" color="text.disabled">
        {translations.dynamicFilters.common.clearFiltersDialogNote}
      </LocalizedTypography>
    </Stack>
  );

  const noProductResults = (
    <EmptyResultsCard
      brandIcon={<BrandIcon variant="search" color="primary" />}
      title={translations.dynamicFilters.common.noProductResults}
      description={translations.dynamicFilters.common.thereAreNoResultsForYourCurrentFilters}
      action={action}
      testId="no-product-results-card"
      {...rest}
    />
  );

  return isFiltered ? noProductResults : finalizingOfferings;
};
