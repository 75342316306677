import { PortfolioCashflowEventsPayload, PortfolioCashflowEventPeriodModel } from '@halo-common/models';
import { ApiPortfolioCashflowEventsRequestModel } from '@halo-data-sources/models';
import { BarChartPoint } from '@halodomination/halo-fe-common';

export const PortfolioCashflowEventsMapper = {
  toApiPortfolioCashflowEventsRequestModel: (
    model: PortfolioCashflowEventsPayload,
  ): ApiPortfolioCashflowEventsRequestModel => {
    return {
      account_selector: {
        account_ids: model.accountIds,
        advisee_ids: model.householdId ? [model.householdId] : undefined,
        synced: model.synced ?? null,
      },
      reporting_currency_id: model.currencyId,
      start_date: model.startDate,
      end_date: model.endDate,
      frequency: model.frequency,
    };
  },
  toCashflowChartData: (model: Array<PortfolioCashflowEventPeriodModel>): Array<BarChartPoint> => {
    const initialChartData: Array<BarChartPoint> = [];

    const chartData = model.reduce((data, next) => {
      const point: BarChartPoint = { name: next.period };
      const events = next.events;
      events.forEach((event) => {
        const label = event.label;
        if (event.value > 0) {
          point[label] = event.value;
        }
      });

      return [...data, point];
    }, initialChartData);

    return chartData;
  },
};
