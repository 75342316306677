import { ReactElement } from 'react';

import { OrderTicketFormModelFields } from '@halo-common/modals/OrderTicketModal/OrderTicketModal';
import { Collapsible, Stack } from '@halodomination/halo-fe-common';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export type NoteSectionProps = {
  bulk?: boolean;
};

export const NoteSection = ({ bulk }: NoteSectionProps): ReactElement => {
  const { register, formState } = useFormContext<OrderTicketFormModelFields>();

  const disableLegend1 = Boolean(formState.defaultValues?.legend1);
  const shrinkLegend1 = disableLegend1 ? true : undefined;

  const { ref: legend1Ref, ...legend1InputProps } = register('legend1');
  const { ref: legend2Ref, ...legend2InputProps } = register('legend2');
  const { ref: trailer1Ref, ...trailer1InputProps } = register('trailer1');
  const { ref: trailer2Ref, ...trailer2InputProps } = register('trailer2');
  const { ref: trailer3Ref, ...trailer3InputProps } = register('trailer3');

  return (
    <Collapsible title="Notes" subtitle="optional" defaultExpanded alwaysExpanded={bulk}>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={2} sm={[6, 6, 12]} wrap="wrap">
          <Stack direction="row" spacing={2} xs={6}>
            <TextField
              {...legend1InputProps}
              inputRef={legend1Ref}
              label="Legend"
              fullWidth
              size="large"
              disabled={disableLegend1}
              slotProps={{ inputLabel: { shrink: shrinkLegend1 }, htmlInput: { maxLength: 1 } }}
            />
            <TextField
              {...legend2InputProps}
              inputRef={legend2Ref}
              label="Legend"
              fullWidth
              size="large"
              slotProps={{ htmlInput: { maxLength: 1 } }}
            />
          </Stack>
          <TextField
            {...trailer1InputProps}
            inputRef={trailer1Ref}
            label="Trailer 1"
            fullWidth
            size="large"
            slotProps={{ htmlInput: { maxLength: 30 } }}
          />
          <Stack direction="row" spacing={2} xs={6}>
            <TextField
              {...trailer2InputProps}
              inputRef={trailer2Ref}
              label="Trailer 2"
              fullWidth
              size="large"
              slotProps={{ htmlInput: { maxLength: 30 } }}
            />
            <TextField
              {...trailer3InputProps}
              inputRef={trailer3Ref}
              label="Trailer 3"
              fullWidth
              size="large"
              slotProps={{ htmlInput: { maxLength: 30 } }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Collapsible>
  );
};
