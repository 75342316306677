import { ReactElement } from 'react';

import { ProductDetailModalLayoutHeader } from '@halo-common/layouts';
import {
  ExecutionFixedIncomeDetailModalHeaderActions,
  ExecutionCalendarDetailModalHeaderId,
  V2PostTradeNoteDetailModalHeaderFeatures,
  V2PostTradeNoteDetailModalHeaderSolvableParameter,
} from '@halo-common/modals';
import { CalendarModel } from '@halo-common/models';

export type ExecutionFixedIncomeDetailModalHeaderProps = {
  product?: CalendarModel | null;
  loading?: boolean;
  onClose?: () => void;
  onSubmitIndication: () => void;
};

export const ExecutionFixedIncomeDetailModalHeader = ({
  product,
  loading = true,
  onClose,
  onSubmitIndication,
}: ExecutionFixedIncomeDetailModalHeaderProps): ReactElement => {
  const note = product?.noteV2;
  const noteType = note?.type;
  const features = note?.features;
  const solvableParameter = note?.payoffDetails?.solvableParameter;

  const slots = {
    features: (
      <V2PostTradeNoteDetailModalHeaderFeatures
        loading={loading}
        features={features}
        type={noteType}
        descriptionAsLabel
      />
    ),
    productId: <ExecutionCalendarDetailModalHeaderId loading={loading} product={product} />,
    parameter: <V2PostTradeNoteDetailModalHeaderSolvableParameter loading={loading} parameter={solvableParameter} />,
    actions: (
      <ExecutionFixedIncomeDetailModalHeaderActions
        loading={loading}
        product={product}
        onSubmitIndication={onSubmitIndication}
      />
    ),
  };

  return <ProductDetailModalLayoutHeader onClose={onClose} slots={slots} />;
};
