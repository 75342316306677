import { ReactElement, ReactNode } from 'react';

import { LexicalTypography } from '@halo-common/components';
import { NoteProductTypeEnum, NoteTypeEnum, ProductTypeEnum } from '@halo-common/enums';
import {
  ConditionalWrapper,
  Iconography,
  IconographyProps,
  Tooltip,
  TooltipProps,
} from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Chip, Stack, SxProps } from '@mui/material';
import { ITranslateParams } from '@transifex/native';

const iconSx = {
  fontSize: 16,
  display: 'block',
};

const nameSx = {
  fontSize: 12,
  lineHeight: 1,
  fontWeight: 600,
  letterSpacing: 0.16,
};

const defaultSx = { borderRadius: 1, fontSize: 12 };
const defaultChipColorSx = { backgroundColor: 'common.charts.accent1.background' };
const growthChipColorSx = { backgroundColor: 'common.charts.accent2.background' };
const snowballChipColorSx = { backgroundColor: 'common.charts.accent3.background' };
const digitalChipColorSx = { backgroundColor: 'common.charts.accent4.background' };
const digitalPlusChipColorSx = { backgroundColor: 'common.charts.accent5.background' };
const absoluteChipColorSx = { backgroundColor: 'common.charts.accent6.background' };
const ppnChipColorSx = { backgroundColor: 'common.charts.accent7.background' };
const generalChipColorSx = { backgroundColor: 'common.charts.accent8.background' };
const annuityChipColorSx = { backgroundColor: 'common.charts.accent11.background' };

export type ProductFeatureListItem = {
  name: string;
  description?: string;
  dynamicContent?: ITranslateParams;
  icon?: IconographyProps['iconName'];
};

export type ProductFeatureListProps = {
  children?: ReactNode;
  features: Array<ProductFeatureListItem>;
  descriptionAsLabel?: boolean;
  sx?: SxProps<HaloTheme>;
  type?: string;
  subtype?: string;
};

export const ProductFeatureList = ({
  children,
  features,
  descriptionAsLabel = false,
  sx,
  type = '',
  subtype = '',
}: ProductFeatureListProps): ReactElement => {
  const typeColor = {
    [NoteTypeEnum.Income]: defaultChipColorSx,
    [NoteTypeEnum.Growth]: growthChipColorSx,
    [NoteTypeEnum.Digital]: digitalChipColorSx,
    [NoteTypeEnum.Absolute]: absoluteChipColorSx,
    [NoteTypeEnum.General]: generalChipColorSx,
    [ProductTypeEnum.annuity]: annuityChipColorSx,
  }[type];

  const subtypeColor = {
    [NoteProductTypeEnum.Snowball]: snowballChipColorSx,
    [NoteProductTypeEnum.DigitalPlus]: digitalPlusChipColorSx,
    [NoteProductTypeEnum.PrincipalProtected]: ppnChipColorSx,
  }[subtype.toLowerCase()];

  const featureColor = subtypeColor ?? typeColor ?? defaultChipColorSx;
  const chipSx = { ...defaultSx, ...featureColor };

  return (
    <Stack sx={sx} direction="row" spacing={1} flexWrap="wrap" useFlexGap>
      {children}
      {features.map(({ name, description, dynamicContent, icon }) => {
        const hasDescription = Boolean(description);

        const enableTooltip = !descriptionAsLabel && hasDescription;
        const label = descriptionAsLabel ? description : name;
        const localization = dynamicContent ? { title: { dynamicContent } } : undefined;

        const tooltipIcon = enableTooltip ? (
          <Iconography prefix="far" iconName="circle-info" color="text.secondary" sx={iconSx} />
        ) : null;

        const iconComponent = icon ? <Iconography iconName={icon} color="primary.contrastText" /> : undefined;

        const labelComponent = (
          <Stack direction="row" alignItems="center" spacing={1}>
            <LexicalTypography sx={nameSx} variant="body2" color="textSecondary" dynamicContent={dynamicContent}>
              {label}
            </LexicalTypography>
            {tooltipIcon}
          </Stack>
        );

        return (
          <ConditionalWrapper<TooltipProps>
            key={name}
            condition={hasDescription}
            placement="top"
            title={description}
            Localization={localization}
            Wrapper={Tooltip}
          >
            <Chip size="small" icon={iconComponent} sx={chipSx} label={labelComponent} />
          </ConditionalWrapper>
        );
      })}
    </Stack>
  );
};
