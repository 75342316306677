import { CSSProperties, ReactElement, ReactNode } from 'react';

import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { ListItem, ListItemText } from '@mui/material';

export type EducationListItemProps = {
  children: ReactNode;
  bold?: boolean;
  bullet?: CSSProperties['listStyleType'];
  italics?: boolean;
};

export const EducationListItem = ({
  children,
  bold = false,
  bullet = 'bullet',
  italics = false,
}: EducationListItemProps): ReactElement => {
  const listItemSx = {
    display: 'list-item',
    listStyleType: bullet === 'bullet' ? 'disc' : 'decimal',
    fontStyle: italics ? 'italic' : 'normal',
    fontWeight: bold ? 'fontWeightBold' : 'normal',
  };

  const listItemTextSx = {
    fontWeight: bold ? 'fontWeightBold' : 'fontWeightRegular',
  };

  return (
    <ListItem disableGutters sx={listItemSx}>
      <ListItemText>
        <LocalizedTypography sx={listItemTextSx} color="textPrimary" variant="body1">
          {children}
        </LocalizedTypography>
      </ListItemText>
    </ListItem>
  );
};
