import { useMemo } from 'react';

import { COMBINED_DATE_TIME_ZONE_FORMAT } from '@halo-common/constants';
import { OrderBookJanneyStatusEnum } from '@halo-common/enums';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { CalendarModel, OrderBookCalendarDetails } from '@halo-common/models';
import {
  CancelJanneyOrderButton,
  OrderBookCalendarsViewOrderDetailsButton,
  OrderBookJanneyStatus,
  ViewApproveOrderModalButton,
} from '@halo-modules/app';
import { HaloDataGridProps, mapNumberToLocalCurrency, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Box } from '@mui/material';
import { DateTime } from 'luxon';

const actionCellSx = {
  alignItems: 'center',
  display: 'flex',
  gap: 1,
  height: '100%',
  width: 'fit-content',
  '& button': { maxWidth: 90, width: 'fit-content' },
};

const commonCellProps = {
  editable: false,
  disableColumnMenu: true,
  disableReorder: true,
  flex: 1,
};

const formatCurrency = (value: number) => useCurrencyConverter(value);

export const useOrderBookJanneyTableColumns = (): HaloDataGridProps['columns'] => {
  const configureDateTime = useWhiteLabelDateConfiguration();

  const columns: HaloDataGridProps['columns'] = useMemo(
    () => [
      {
        ...commonCellProps,
        field: 'executionOrderId',
        headerName: 'JANNEY Order #',
        valueGetter: (_, row: OrderBookCalendarDetails) => row?.allocation?.executionOrderId,
      },
      {
        ...commonCellProps,
        field: 'household',
        headerName: 'Rep Code',
        valueGetter: (_, row: OrderBookCalendarDetails) => row?.allocation?.account?.household,
      },
      {
        ...commonCellProps,
        flex: 1,
        field: 'accountName',
        headerName: 'account name',
        valueGetter: (_, row: OrderBookCalendarDetails) => row.allocation?.account.name,
      },
      {
        ...commonCellProps,
        flex: 1,
        field: 'accountId',
        headerName: 'account #',
        valueGetter: (_, row: OrderBookCalendarDetails) => row.allocation?.account.accountId,
      },
      {
        ...commonCellProps,
        field: 'designation',
        translateCell: true,
        headerName: 'Account Type',
        valueGetter: (_, row: OrderBookCalendarDetails) => row?.allocation?.account?.designation,
      },
      {
        ...commonCellProps,
        field: 'user',
        headerName: 'User Name',
        valueGetter: (_, row: OrderBookCalendarDetails) => row?.allocation?.user?.name,
      },
      {
        ...commonCellProps,
        field: 'calendar',
        headerName: 'CUSIP',
        valueGetter: (_, row: OrderBookCalendarDetails) => row.calendar?.cusip || 'N/A',
        renderCell: ({ row, value }) => {
          return <OrderBookCalendarsViewOrderDetailsButton cusip={value} calendarId={row.calendar.id} />;
        },
      },
      {
        ...commonCellProps,
        field: 'tradables',
        headerName: 'Underlying',
        sortable: false,
        valueGetter: (_, row: OrderBookCalendarDetails) => {
          const tradables = (row?.calendar as CalendarModel)?.note?.tradables ?? [];
          return tradables.map((tradable) => tradable.name).join(', ');
        },
      },
      {
        ...commonCellProps,
        field: 'issuer',
        headerName: 'Issuer',
        valueGetter: (_, row: OrderBookCalendarDetails) => (row?.calendar as CalendarModel)?.issuer?.name,
      },
      {
        ...commonCellProps,
        field: 'amount',
        headerName: 'Principal',
        valueGetter: (_, row: OrderBookCalendarDetails) => formatCurrency(row?.allocation?.amount),
        align: 'right',
      },
      {
        ...commonCellProps,
        align: 'right',
        field: 'janney.commission',
        headerName: 'commission',
        valueGetter: (_, row: OrderBookCalendarDetails) => {
          return mapNumberToLocalCurrency(row.transactionSummary?.salesCredit);
        },
      },
      {
        ...commonCellProps,
        field: 'updatedOn',
        headerName: 'Last Update',
        valueGetter: (_, row: OrderBookCalendarDetails) => {
          const lastUpdatedDateTime = configureDateTime(row?.allocation?.updatedOn);
          return lastUpdatedDateTime?.toFormat(COMBINED_DATE_TIME_ZONE_FORMAT);
        },
        flex: 1.5,
      },
      {
        ...commonCellProps,
        field: 'status',
        headerName: 'Status',
        minWidth: 135,
        renderCell: ({ row }) => (
          <OrderBookJanneyStatus value={row.allocation.status} message={row.allocation.reason} />
        ),
      },
      {
        ...commonCellProps,
        flex: undefined,
        field: 'actions',
        headerName: '',
        sortable: false,
        resizable: false,
        minWidth: 150,
        renderCell: ({ row }) => {
          const expirationDateTime = configureDateTime(row.allocation.expirationDate);
          const nowDateTime = configureDateTime(DateTime.local().toISO());

          const isAccepted = row.allocation.status === OrderBookJanneyStatusEnum.accepted;
          const isExpired = expirationDateTime && nowDateTime && expirationDateTime < nowDateTime;
          const hideCancelButton = !isAccepted || isExpired;

          const cancelButton = !hideCancelButton ? <CancelJanneyOrderButton allocation={row.allocation} /> : null;

          return (
            <Box sx={actionCellSx}>
              <ViewApproveOrderModalButton allocation={row.allocation} />
              {cancelButton}
            </Box>
          );
        },
      },
    ],
    [configureDateTime],
  );

  return columns;
};
