export enum FitchCreditRatingEnum {
  Aaa = 'AAA',
  Aap = 'AA+',
  Aa = 'AA',
  Aan = 'AA-',
  Ap = 'A+',
  A = 'A',
  An = 'A-',
  Bbbp = 'BBB+',
  Bbb = 'BBB',
  Bbbn = 'BBB-',
}

export enum MoodyCreditRatingEnum {
  Aaa = 'Aaa',
  Aa1 = 'Aa1',
  Aa2 = 'Aa2',
  Aa3 = 'Aa3',
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  Baa1 = 'Baa1',
  Baa2 = 'Baa2',
  Baa3 = 'Baa3',
}

export enum SPCreditRatingEnum {
  Aaa = 'AAA',
  Aap = 'AA+',
  Aa = 'AA',
  Aan = 'AA-',
  Ap = 'A+',
  A = 'A',
  An = 'A-',
  Bbbp = 'BBB+',
  Bbb = 'BBB',
  Bbbn = 'BBB-',
}
