import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { CollapsibleFilters, PopoverRangeFilter } from '@halo-common/components';
import { useSliderText } from '@halo-common/hooks';
import { DynamicTypeFiltersSideBarTitlesDict } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { useT } from '@transifex/react';
import { useAtom } from 'jotai';

export const TERM_SLIDER_MIN_VALUE = 0;
export const TERM_SLIDER_MAX_VALUE = 60;

const DEFAULT_SLIDER_DATA = {
  min: TERM_SLIDER_MIN_VALUE,
  max: TERM_SLIDER_MAX_VALUE,
};

export type TermSliderFiltersProps = {
  tag: string;
};

export const TermSliderFilters = ({ tag }: TermSliderFiltersProps): ReactElement => {
  const translator = useT();
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);
  const { term } = sideBarFilters;

  const [sliderData, setSliderData] = useState(DEFAULT_SLIDER_DATA);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const sliderMin = sliderData?.min ?? TERM_SLIDER_MIN_VALUE;
  const sliderMax = sliderData?.max ?? TERM_SLIDER_MAX_VALUE;
  const sliderValue = [sliderMin, sliderMax];

  const isSliderMinValue = sliderMin === TERM_SLIDER_MIN_VALUE;
  const isSliderMaxValue = sliderMax === TERM_SLIDER_MAX_VALUE;

  const open = Boolean(anchorEl);
  const id = open ? 'term-popover' : undefined;

  const minInputValue = !isSliderMinValue ? sliderMin : '';
  const maxInputValue = !isSliderMaxValue ? sliderMax : '';

  const suffix = translator(translations.dynamicFilters.common.dateSliderSuffix);

  const formattedButtonText = useSliderText({
    minValue: TERM_SLIDER_MIN_VALUE,
    maxValue: TERM_SLIDER_MAX_VALUE,
    suffix,
    leftSliderValue: sliderMin,
    rightSliderValue: sliderMax,
  });

  const handlePopoverOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleDoneClick = () => {
    if (isSliderMinValue && isSliderMaxValue) setSideBarFilters({ tag, term: null });
    else setSideBarFilters({ tag, term: { min: sliderMin, max: sliderMax } });
    handlePopoverClose();
  };

  const handleSliderChange = (_: unknown, value: number | Array<number>) => {
    const updatedSliderMin = typeof value === 'number' ? value : value[0];
    const updatedSliderMax = typeof value === 'number' ? value : value[1];
    setSliderData({ min: updatedSliderMin, max: updatedSliderMax });
  };

  const handleMinInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const inputValue = value ? parseInt(event.target.value) : TERM_SLIDER_MIN_VALUE;

    const filteredValue = inputValue <= TERM_SLIDER_MIN_VALUE ? TERM_SLIDER_MIN_VALUE : inputValue;

    setSliderData({ min: filteredValue, max: sliderMax });
  };

  const handleMaxInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const inputValue = value ? parseInt(event.target.value) : TERM_SLIDER_MAX_VALUE;

    const filteredValue = inputValue >= TERM_SLIDER_MAX_VALUE ? TERM_SLIDER_MAX_VALUE : inputValue;

    setSliderData({ min: sliderMin, max: filteredValue });
  };

  useEffect(() => {
    const resetValue = term === null;
    const hasValueChanged = term?.max !== sliderData.max || term?.min !== sliderData.min;
    if (resetValue) setSliderData(DEFAULT_SLIDER_DATA);
    else if (hasValueChanged) setSliderData(term);
  }, [term]);

  return (
    <CollapsibleFilters title={DynamicTypeFiltersSideBarTitlesDict.termSlider}>
      <PopoverRangeFilter
        suffix={suffix}
        maxInputValue={maxInputValue}
        minInputValue={minInputValue}
        PopoverProps={{
          id,
          open,
          anchorEl,
          onClose: handlePopoverClose,
        }}
        SliderProps={{
          onChange: handleSliderChange,
          max: TERM_SLIDER_MAX_VALUE,
          min: TERM_SLIDER_MIN_VALUE,
          step: 1,
          value: sliderValue,
        }}
        buttonText={formattedButtonText}
        handleMinInputChange={handleMinInputChange}
        handleMaxInputChange={handleMaxInputChange}
        handlePopoverOpen={handlePopoverOpen}
        handleDoneClick={handleDoneClick}
      />
    </CollapsibleFilters>
  );
};
