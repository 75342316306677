import { ReactElement, useEffect } from 'react';

import { assetIdSearchValueAtom } from '@halo-atoms/portfolio';
import { PortfolioPositionReferenceModel } from '@halo-common/models';
import { AddPositionToMultipleAccountsFormFields } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { usePortfolioAssetExistenceQuery } from '@halo-data-sources/queries';
import { AccountAllocationListItem } from '@halo-modules/app';
import {
  Iconography,
  LocalizedButton,
  LocalizedTypography,
  useCurrencyConverter,
} from '@halodomination/halo-fe-common';
import { Divider, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

export const ChooseAccountsModalLayout = (): ReactElement => {
  const assetIdValue = useAtomValue(assetIdSearchValueAtom);

  const { setValue, control } = useFormContext<AddPositionToMultipleAccountsFormFields>();
  const { fields, append, remove } = useFieldArray<AddPositionToMultipleAccountsFormFields>({
    name: 'positions',
    control,
    rules: { minLength: 1 },
  });

  const assetIds = assetIdValue ? [assetIdValue] : undefined;
  const queryEnabled = Boolean(assetIds?.length);

  const { data } = usePortfolioAssetExistenceQuery(assetIds, { enabled: queryEnabled });

  const selectedAsset = data?.[0];
  const assetId = selectedAsset?.assetId;
  const selectedAssetIdType = selectedAsset?.assetIdType;

  const defaultPosition: PortfolioPositionReferenceModel = {
    termsheetId: selectedAsset?.termsheetId ?? -1,
    accountId: undefined,
    notional: undefined,
    assetId: selectedAsset?.assetId,
    location: selectedAsset?.location,
    assetIdType: selectedAsset?.assetIdType,
  };

  const formValues: Array<PortfolioPositionReferenceModel> = useWatch({ name: 'positions', control });
  const totalNotional = formValues?.reduce((total: number, current) => total + (current?.notional ?? 0), 0);
  const totalNotionalConverted = useCurrencyConverter(totalNotional ?? 0);

  useEffect(() => {
    if (selectedAsset && fields.length < 1) setValue('positions', [defaultPosition]);
  }, [fields, selectedAsset]);

  const buttonIcon = <Iconography iconName="plus" color="primary.main" />;

  const accountAllocationList = fields.map((field, index) => (
    <AccountAllocationListItem
      key={field.id}
      selectedAssetId={assetId}
      index={index}
      location={field?.location}
      assetIdType={selectedAssetIdType}
      handleDelete={remove}
    />
  ));

  return (
    <Stack direction="column" spacing={3}>
      {accountAllocationList}
      <Divider />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <LocalizedButton startIcon={buttonIcon} variant="text" color="primary" onClick={() => append(defaultPosition)}>
          {translations.portfolio.addPositionToAccountsModal.addAccountTitle}
        </LocalizedButton>
        <Stack direction="row" alignItems="center">
          <LocalizedTypography variant="body1">{translations.common.totalAmount}</LocalizedTypography>
          <Typography variant="body1" fontWeight="bold">
            {`: ${totalNotionalConverted}`}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
