export const getQueryParams = (): { [key: string]: string } => {
  const queryStringKeyValue = window.parent.location.search.replace('?', '').split('&');

  const queryObject = queryStringKeyValue.reduce((acc, curr) => {
    const [key, value] = curr.split('=');
    return {
      ...acc,
      [key]: decodeURIComponent(value),
    };
  }, {});

  return queryObject;
};
