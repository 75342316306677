import { ReactElement } from 'react';

import { StatusChip, StatusChipProps } from '@halo-common/components';
import { OrderBookCalendarDisplayEnum, OrderBookCalendarStatusEnum } from '@halo-common/enums';

const getStatus = (status: OrderBookCalendarStatusEnum): StatusChipProps['status'] => {
  switch (status) {
    case OrderBookCalendarStatusEnum.closed:
      return 'completed';
    case OrderBookCalendarStatusEnum.canceled:
      return 'closed';
    default:
      return 'pending';
  }
};

export type OrderBookCalendarsStatusProps = {
  value: string;
  message?: string;
};

export const OrderBookCalendarsStatus = ({ value, message }: OrderBookCalendarsStatusProps): ReactElement => {
  const calendarLabel = OrderBookCalendarDisplayEnum[value as keyof typeof OrderBookCalendarDisplayEnum];
  const calendarStatus = OrderBookCalendarStatusEnum[value as keyof typeof OrderBookCalendarStatusEnum];

  const status = getStatus(calendarStatus);

  return <StatusChip label={calendarLabel} status={status} message={message} />;
};
