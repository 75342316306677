import { ReactElement, useEffect } from 'react';

import { adminUserActivityFilterAtom } from '@halo-atoms/adminUser';
import { COMBINED_DATE_TIME_ZONE_FORMAT } from '@halo-common/constants';
import { useDateFormatter } from '@halo-common/hooks';
import { UserActivityAction, UserActivityModel, UserModel, UserRelationshipManagerModel } from '@halo-common/models';
import { RelationshipManagerTypeAhead, UserActivityActionTypeAhead, UserTypeAhead } from '@halo-common/smartComponents';
import { useAdminUserActivitiesInfiniteQuery } from '@halo-data-sources/queries';
import { HaloDataGrid, HaloDataGridProps, Stack, useSnackbar } from '@halodomination/halo-fe-common';
import { Button, Paper, Typography } from '@mui/material';
import { useAtom } from 'jotai';

const containerSx = { p: 3, m: 3 };

export const AdminUserActivityTable = (): ReactElement => {
  const formatDate = useDateFormatter();

  const { enqueuePendingEvent, enqueueSuccessEvent, closeSnackbar } = useSnackbar();

  const [filters, setFilters] = useAtom(adminUserActivityFilterAtom);

  const { data, isPending, fetchNextPage, refetch, isRefetching, isSuccess, isFetchingNextPage, hasNextPage } =
    useAdminUserActivitiesInfiniteQuery(filters);

  const rows = data?.pages.flatMap((page) => page.activities) ?? [];

  const height = rows.length >= 15 ? 470 : 'auto';

  const isTableLoading = isFetchingNextPage || isPending;

  const actionFilters = {
    userIds: filters.users?.map(({ id }) => id) ?? [],
    managerIds: filters.relationshipManagers?.map(({ id }) => id) ?? [],
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      field: 'id',
      flex: 1,
      headerName: 'activity id',
    },
    {
      field: 'haloUser.name',
      flex: 1,
      headerName: 'name',
      valueGetter: (_, row: UserActivityModel) => row?.haloUser?.name,
    },
    {
      field: 'organization.name',
      flex: 1,
      headerName: 'organization',
      valueGetter: (_, row: UserActivityModel) => row?.organization?.name,
    },
    {
      field: 'haloUser.relationshipManager.name',
      flex: 1,
      headerName: 'relationship manager',
      valueGetter: (_, row: UserActivityModel) => row?.haloUser?.relationshipManager?.name,
    },
    {
      field: 'receivedAt',
      flex: 1,
      headerName: 'time',
      valueFormatter: (value: string) => formatDate(value, COMBINED_DATE_TIME_ZONE_FORMAT),
    },
    {
      field: 'verb',
      flex: 1,
      headerName: 'action',
    },
    {
      field: 'model',
      flex: 1,
      headerName: 'details',
      valueFormatter: (value: string, row: UserActivityModel) => (value ? `${value}: ${row?.modelId}` : null),
    },
  ];

  const handleInfiniteScroll = () => {
    const loadMoreContent = !isFetchingNextPage && hasNextPage;
    if (loadMoreContent) void fetchNextPage();
  };

  const handleUserChange = (option: UserModel | Array<UserModel> | null) => {
    const isArray = Array.isArray(option);
    setFilters({ ...filters, users: isArray ? option : option ? [option] : [] });
  };

  const handleRelationshipManagerChange = (
    option: UserRelationshipManagerModel | Array<UserRelationshipManagerModel> | null,
  ) => {
    const isArray = Array.isArray(option);
    setFilters({ ...filters, relationshipManagers: isArray ? option : option ? [option] : [] });
  };

  const handleUserActionChange = (option: UserActivityAction | Array<UserActivityAction> | null) => {
    const isArray = Array.isArray(option);
    setFilters({ ...filters, actions: isArray ? option : option ? [option] : [] });
  };

  const handleRefresh = () => void refetch();
  const handleReset = () => void setFilters();

  useEffect(() => {
    closeSnackbar();

    const successfullyFetchedData = !isRefetching && isSuccess;
    if (isRefetching) enqueuePendingEvent({ message: 'Reloading user activity data.' });
    else if (successfullyFetchedData) enqueueSuccessEvent({ message: 'Reloaded user activity data.' });
  }, [isRefetching]);

  return (
    <Paper variant="outlined" elevation={0} sx={containerSx}>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={1} xs={[2, 10]} wrap="wrap">
          <Typography variant="h6" fontWeight="fontWeightBold">
            Activities
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1} xs={12} md={3} wrap="wrap">
            <Stack direction="row" justify="flex-end" alignItems="center" spacing={1}>
              <Button onClick={handleRefresh}>Refresh</Button>
              <Button onClick={handleReset}>Reset</Button>
            </Stack>
            <UserTypeAhead fullWidth multiple limitTags={1} onChange={handleUserChange} value={filters.users} />
            <RelationshipManagerTypeAhead
              fullWidth
              multiple
              limitTags={1}
              onChange={handleRelationshipManagerChange}
              value={filters.relationshipManagers}
            />
            <UserActivityActionTypeAhead
              fullWidth
              multiple
              limitTags={1}
              filters={actionFilters}
              onChange={handleUserActionChange}
              value={filters.actions}
            />
          </Stack>
        </Stack>
        <HaloDataGrid
          height={height}
          columns={columns}
          rows={rows}
          rowCount={rows.length}
          loading={isTableLoading}
          density="compact"
          noResultsMessage="No User Activity Found"
          paginationMode="server"
          onRowsScrollEnd={handleInfiniteScroll}
        />
      </Stack>
    </Paper>
  );
};
