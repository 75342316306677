import { ReactElement } from 'react';

import { AuctionStatusEnum } from '@halo-common/enums';
import { AuctionAdminModel } from '@halo-common/models';
import { AUCTION_DATE_FORMAT, AUCTION_DATE_TIME_FORMAT } from '@halo-data-sources/mappers';
import { TackOnTable, TackOnTableLayout } from '@halo-modules/admin';
import { OrdersSelectors } from '@halo-stores/Orders';
import { GridColDef, GridRenderCellParams, useGridApiRef } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, SxProps } from '@mui/material';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';

const dataSx = {
  display: 'flex',
  alignItems: 'center',
  py: 0,
  px: 3,
  width: '100%',
  height: '100%',
  fontWeight: 600,
};

const cellSx = {
  borderBottom: '1px solid',
  borderLeftColor: 'grey.300',
  '&:focus': {
    outline: 'none !important',
  },
};

const clickableCellSx = {
  '&:hover': {
    backgroundColor: 'background.default',
    cursor: 'pointer',
    transition: 'background-color 150ms linear',
  },
};

export type InProgressTableProps = {
  hideFooter?: boolean;
  autoPageSize?: boolean;
  limit?: number;
};

export const InProgressTable = ({
  hideFooter = false,
  autoPageSize = false,
  limit,
}: InProgressTableProps): ReactElement => {
  const gridRef = useGridApiRef();

  const auctions = useSelector(OrdersSelectors.selectAuctions);

  const description = `Tack ons that are currently live and available for users to purchase.`;

  const termsAccepted = auctions[AuctionStatusEnum.TermsAccepted] ?? [];
  const termsPending = auctions[AuctionStatusEnum.TermsPending] ?? [];
  const termsReviewed = auctions[AuctionStatusEnum.TermsReviewed] ?? [];

  const totalAuctions = [...termsAccepted, ...termsPending, ...termsReviewed];

  const inProgressAuctions = totalAuctions.length ? totalAuctions.filter((auction) => auction.tackOn) : [];
  const data = inProgressAuctions?.slice(0, limit ?? inProgressAuctions.length);

  const renderDateTime = (value?: DateTime, isEditable?: boolean) => {
    const parsedValue = value?.toFormat(AUCTION_DATE_FORMAT);
    const shouldHaveClickableStyling = parsedValue && isEditable ? clickableCellSx : null;
    const combinedCellStyling: SxProps<HaloTheme> = [cellSx, dataSx, shouldHaveClickableStyling];

    return <Box sx={combinedCellStyling}>{parsedValue}</Box>;
  };

  const columnHeaders: Array<GridColDef<AuctionAdminModel>> = [
    {
      field: 'id',
      align: 'left',
      headerName: 'AUCTION ID',
      editable: false,
    },
    {
      field: 'noteId',
      align: 'left',
      headerName: 'NOTE ID',
    },
    {
      field: 'cusip',
      align: 'left',
      headerName: 'CUSIP',
    },
    {
      field: 'isin',
      align: 'left',
      headerName: 'ISIN',
    },
    {
      field: 'startShowingDate',
      type: 'dateTime',
      headerName: 'TACK ON STARTED',
      valueGetter: (_, row) =>
        row.tackOn?.startShowingDate ? DateTime.fromISO(row.tackOn.startShowingDate) : DateTime.now(),
      valueFormatter: (value: DateTime | null) => value?.toFormat(AUCTION_DATE_TIME_FORMAT) ?? '--',
      renderCell: ({ value, isEditable }: GridRenderCellParams) => renderDateTime(value, isEditable),
    },
    {
      field: 'expirationDate',
      type: 'dateTime',
      headerName: 'TACK ON EXPIRES',
      valueGetter: (_, row) =>
        row.tackOn?.expirationDate ? DateTime.fromISO(row.tackOn.expirationDate) : DateTime.now(),
      valueFormatter: (value: DateTime | null) => value?.toFormat(AUCTION_DATE_TIME_FORMAT) ?? '--',
      renderCell: ({ value, isEditable }: GridRenderCellParams) => renderDateTime(value, isEditable),
    },
    {
      field: 'targetTradeDate',
      type: 'dateTime',
      headerName: 'TRADE DATE',
      valueGetter: (value) => DateTime.fromISO(value),
      valueFormatter: (value: DateTime | null) => value?.toFormat(AUCTION_DATE_FORMAT) ?? '--',
      renderCell: ({ value, isEditable }: GridRenderCellParams) => renderDateTime(value, isEditable),
    },
    {
      field: 'targetStrikeDate',
      type: 'date',
      headerName: 'STRIKE DATE',
      valueGetter: (value) => DateTime.fromISO(value),
      valueFormatter: (value: DateTime | null) => value?.toFormat(AUCTION_DATE_FORMAT) ?? '--',
      renderCell: ({ value, isEditable }: GridRenderCellParams) => renderDateTime(value, isEditable),
    },
    {
      field: 'targetSettleDate',
      type: 'date',
      headerName: 'SETTLE DATE',
      valueGetter: (value) => DateTime.fromISO(value),
      valueFormatter: (value: DateTime | null) => value?.toFormat(AUCTION_DATE_FORMAT) ?? '--',
      renderCell: ({ value, isEditable }: GridRenderCellParams) => renderDateTime(value, isEditable),
    },
  ];

  return (
    <TackOnTableLayout description={description} title="In Progress">
      <TackOnTable
        DataGridProps={{
          data,
          hideFooter,
          autoPageSize,
          cta: {
            icon: 'pen-to-square',
            label: 'Edit Tack On',
            color: 'purple',
          },
        }}
        gridRef={gridRef}
        columnHeaders={columnHeaders}
        inProgress
      />
    </TackOnTableLayout>
  );
};
