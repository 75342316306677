import { ReactElement, useEffect } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { translations } from '@halo-common/translations';
import { useCreateWatchlistMutation } from '@halo-data-sources/mutations';
import {
  CreateWatchlistModalFooter,
  DEFAULT_WATCHLIST_ICON,
  WatchlistContentForm,
  WatchlistContentFormValues,
  watchlistValidationSchema,
} from '@halo-modules/app';
import { Modal } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtomValue } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';

export type CreateWatchlistModalProps = {
  onClose: () => void;
};

export const CreateWatchlistModal = ({ onClose }: CreateWatchlistModalProps): ReactElement => {
  const modalMap = useAtomValue(modalAtom);

  const { mutate, error, isError, isSuccess, isPending, reset } = useCreateWatchlistMutation({
    navigateToWatchlist: true,
  });

  const formMethods = useForm<WatchlistContentFormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(watchlistValidationSchema),
    defaultValues: {
      watchlistName: '',
      watchlistIcon: DEFAULT_WATCHLIST_ICON,
    },
  });

  const { handleSubmit: submitHandler, reset: handleFormReset } = formMethods;
  const errorMessage = isError ? (error?.message ?? translations.watchlist.common.defaultCreateError) : undefined;
  const open = Boolean(modalMap?.createWatchlist);

  const handleClose = () => {
    onClose();
    handleFormReset();
    reset();
  };

  const handleSubmit = submitHandler((data: WatchlistContentFormValues) => {
    mutate({ name: data.watchlistName.trim(), icon: data.watchlistIcon });
  });

  useEffect(() => {
    if (isSuccess) handleClose();
  }, [isSuccess]);

  return (
    <FormProvider {...formMethods}>
      <Modal
        icon="add"
        footer={<CreateWatchlistModalFooter loading={isPending} onSubmit={handleSubmit} />}
        ContentProps={{ sx: { py: 3 } }}
        title={translations.watchlist.common.createWatchlistModalTitle}
        size="small"
        subtitle={translations.watchlist.common.createWatchlistModalSubtitle}
        open={open}
        onClose={handleClose}
      >
        <WatchlistContentForm loading={isPending} error={errorMessage} />
      </Modal>
    </FormProvider>
  );
};
