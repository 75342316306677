import { ReactElement } from 'react';

import { orderBookApprovalModalAtom } from '@halo-atoms/orderbook';
import { AllocationModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { CalendarActions } from '@halo-stores/Calendar';
import { FixActions } from '@halo-stores/Fix';
import { PershingActions } from '@halo-stores/Pershing';
import { LocalizedButton } from '@halodomination/halo-fe-common';
import { useSetAtom } from 'jotai';
import { batch, useDispatch } from 'react-redux';

export type ViewApproveOrderModalButtonProps = {
  allocation: AllocationModel;
};

export const ViewApproveOrderModalButton = ({ allocation }: ViewApproveOrderModalButtonProps): ReactElement => {
  const dispatch = useDispatch();

  const setOrderBookApprovalModal = useSetAtom(orderBookApprovalModalAtom);

  const onClick = () => {
    batch(() => {
      dispatch(CalendarActions.fetchCalendar(String(allocation.calendarId)));
      dispatch(FixActions.fetchFixOrder(allocation.id));
      dispatch(PershingActions.selectAllocation({ allocation }));
    });

    setOrderBookApprovalModal({ open: true, allocation });
  };

  return (
    <LocalizedButton color="primary" type="button" variant="outlined" onClick={onClick} fullWidth size="extraSmall">
      {translations.common.view}
    </LocalizedButton>
  );
};
