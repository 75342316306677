import { ReactElement, useState, ChangeEvent } from 'react';

import { useDebounced } from '@halo-common/hooks';
import { LocalizedTextField } from '@halodomination/halo-fe-common';
import { TextFieldProps } from '@mui/material';

export type DebouncedTextFieldProps = TextFieldProps & {
  wait?: number;
};

export const DebouncedTextField = ({ wait = 300, onChange, ...props }: DebouncedTextFieldProps): ReactElement => {
  const [value, setValue] = useState(props.value);

  const debouncedOnChange = useDebounced(onChange, wait);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    debouncedOnChange(event);
  };

  return <LocalizedTextField {...props} value={value} onChange={handleChange} />;
};
