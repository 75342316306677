import { DEFAULT_ORDERBOOK_QUERY_FIELDS, OrderBookQuery } from '@halo-atoms/orderbook';
import {
  OrderBookAccountCapacityEnum,
  OrderBookAuctionSortColumns,
  OrderBookAuctionSortColumnsEnum,
  OrderBookCalendarSortColumns,
  OrderBookCalendarSortColumnsEnum,
  OrderBookCalendarStatusEnum,
  OrderBookCalendarStatuses,
  OrderBookPershingSortColumns,
  OrderBookPershingSortColumnsEnum,
  OrderBookTimeFrame,
  SortModelDirectionEnum,
} from '@halo-common/enums';
import {
  GridPaginationModel,
  OrderBookAuctionFilterFields,
  OrderBookAuctionModel,
  OrderBookAuctionPayoffModel,
  OrderBookAuctionQueryFields,
  OrderBookCalendarModel,
  OrderBookCalendarPayoffModel,
  OrderBookCalendarsFilterFields,
  OrderBookCalendarsQueryFields,
  OrderBookNoteTypeModel,
  OrderBookNoteTypesModel,
  OrderBookPershingFilterFields,
  OrderBookPershingQueryFields,
  OrderBookSettingSetModel,
} from '@halo-common/models';
import { PaginationMapper } from '@halo-data-sources/mappers';
import {
  ApiGetOrderBookAuctionsResponseModel,
  ApiGetOrderBookCalendarsResponseModel,
  ApiGetOrderBookCSVRequestModel,
  ApiGetOrderBookNoteTypeResponseModel,
  ApiGetOrderBookOptionsModel,
  ApiGetOrderBookPageModel,
  ApiOrderBookAuctionModel,
  ApiOrderBookAuctionPayoffModel,
  ApiOrderBookAuctionsFiltersModel,
  ApiOrderBookCalendarModel,
  ApiOrderBookCalendarPayoffModel,
  ApiOrderBookCalendarsFiltersModel,
  ApiOrderBookNoteTypeModel,
  ApiOrderBookPershingFiltersModel,
  ApiOrderBookSettingSetModel,
  ApiOrderBookSortModel,
  ApiSortModel,
} from '@halo-data-sources/models';
import { OrderBookAuctionsQueryResult, OrderBookCalendarsQueryResult } from '@halo-data-sources/queries';
import { GridSortItem } from '@mui/x-data-grid-pro';
import { DateTime } from 'luxon';

export const apiSortList: { [key: string]: string } = {
  executionOrderId: 'exec_order.id',
  household: 'advisees.name',
  designation: 'accounts.designation',
  user: 'user_info.full_name',
  calendar: 'calendar_notes.cusip',
  issuer: 'issuers.name',
  updatedOn: 'calendar_allocations.updated_at',
  status: 'exec_order.status',
  accountName: 'accounts.name',
  accountId: 'accounts.account_identifier',
  amount: 'exec_order.quantity',
  ['janney.commission']: 'janney_computed.sales_credit',
};

export const ApiOrderBookMapper = {
  toOrderBookSettingSet: (model: ApiOrderBookSettingSetModel): OrderBookSettingSetModel => ({
    tabs: model.tabs,
  }),
  toOrderBookCalendarPayoff: (model: ApiOrderBookCalendarPayoffModel): OrderBookCalendarPayoffModel => {
    return {
      parameter: model.parameter,
      payoffString: model.payoff_string,
      value: model.value,
    };
  },
  toOrderBookCalendar: (model: ApiOrderBookCalendarModel): OrderBookCalendarModel => {
    return {
      id: model.calendar_note_id,
      closes: model.closes,
      cusip: model.cusip,
      noteType: model.note_type,
      notional: Number(model.notional),
      payoff: ApiOrderBookMapper.toOrderBookCalendarPayoff(model.payoff),
      status: OrderBookCalendarStatusEnum[model.status as OrderBookCalendarStatuses],
      underlying: model.underlying,
      showAsNme: model.show_as_nme,
    };
  },
  toOrderBookCalendarsQueryResult: (model: ApiGetOrderBookCalendarsResponseModel): OrderBookCalendarsQueryResult => {
    return {
      calendars: model.rows?.map(ApiOrderBookMapper.toOrderBookCalendar),
      pagination: model.pagination ? PaginationMapper.toPaginationModel(model.pagination) : undefined,
    };
  },
  toOrderBookAuctionsQueryResult: (model: ApiGetOrderBookAuctionsResponseModel): OrderBookAuctionsQueryResult => {
    return {
      auctions: model.rows?.map(ApiOrderBookMapper.toOrderBookAuction),
      pagination: model.pagination ? PaginationMapper.toPaginationModel(model.pagination) : undefined,
    };
  },
  toOrderBookAuctionPayoff: (model: ApiOrderBookAuctionPayoffModel): OrderBookAuctionPayoffModel => {
    return {
      parameter: model.parameter,
      value: model.value,
      payoffString: model.payoff_string,
    };
  },
  toOrderBookAuction: (model: ApiOrderBookAuctionModel): OrderBookAuctionModel => {
    return {
      id: model.pending_note_order_id,
      noteId: model.note_id,
      noteType: model.note_type,
      issuer: model.issuer,
      numberOfIssuers: model.number_of_issuers,
      numberIssuersResponded: model.number_issuers_responded,
      underlying: model.underlying,
      payoff: ApiOrderBookMapper.toOrderBookAuctionPayoff(model.payoff),
      notional: Number(model.notional),
      endDate: model.end_date,
      tradeDate: model.trade_date,
      status: model.status as OrderBookCalendarStatuses,
    };
  },
  toOrderBookNoteType: (model: ApiOrderBookNoteTypeModel): OrderBookNoteTypeModel => {
    return {
      noteProduct: model.note_product,
      noteProductUserFormatted: model.note_product_user_formatted,
    };
  },
  toOrderBookNoteTypesModel: (model: ApiGetOrderBookNoteTypeResponseModel): OrderBookNoteTypesModel => {
    const noteTypes = model.rows.map(ApiOrderBookMapper.toOrderBookNoteType);

    return {
      noteProductTypes: noteTypes,
    };
  },
};

export const OrderBookMapper = {
  toApiOrderBookSortList: (params: OrderBookQuery): Array<ApiSortModel> => {
    const sortBy = params.sortBy.length ? params.sortBy : DEFAULT_ORDERBOOK_QUERY_FIELDS.sortBy;

    const sortList = sortBy.map(({ sort, field }) => ({
      direction: sort as SortModelDirectionEnum,
      field: apiSortList[field as keyof typeof apiSortList],
    }));

    return sortList;
  },
  toApiOrderBookTimeFrameString: (timeFrame: OrderBookTimeFrame): string => {
    return {
      oneDay: DateTime.local({ zone: 'utc' }).minus({ days: 1 }).toISO(),
      sevenDays: DateTime.local({ zone: 'utc' }).minus({ days: 7 }).toISO(),
      thirtyDays: DateTime.local({ zone: 'utc' }).minus({ days: 30 }).toISO(),
      ninetyDays: DateTime.local({ zone: 'utc' }).minus({ days: 90 }).toISO(),
      oneYear: DateTime.local({ zone: 'utc' }).minus({ years: 1 }).toISO(),
    }[timeFrame];
  },
  toApiOrderBookPershingFilters: (model: OrderBookPershingFilterFields): ApiOrderBookPershingFiltersModel => ({
    account_id: model.account?.account?.id || undefined,
    ibd: model.ibd || undefined,
    user_name: model.userName || undefined,
    cusip: model.cusip || undefined,
    issuer_id: model.issuer || undefined,
    category: model.capacity !== OrderBookAccountCapacityEnum.all ? model.capacity : undefined,
    pershing_order_book_status: model.status || undefined,
    updated_since: model.timeFrame
      ? OrderBookMapper.toApiOrderBookTimeFrameString(model.timeFrame as OrderBookTimeFrame)
      : undefined,
    is_open_pershing: model.is_open_pershing ?? undefined,
  }),
  toApiOrderBookPagination: (model: GridPaginationModel): ApiGetOrderBookPageModel => {
    return {
      page: model.page,
      page_length: model.pageSize,
    };
  },
  toApiOrderBookCalendarsFilters: (model: OrderBookCalendarsFilterFields): ApiOrderBookCalendarsFiltersModel => {
    const mappedUnderlyings = model.underlying?.map((underlying) => underlying.name);

    return {
      cusip: model.cusip || undefined,
      note_type: model.noteType || undefined,
      underlying: mappedUnderlyings || undefined,
      status: model.status || undefined,
      timeframe: model.timeFrame
        ? OrderBookMapper.toApiOrderBookTimeFrameString(model.timeFrame as OrderBookTimeFrame)
        : undefined,
      is_open: model.is_open || undefined,
    };
  },
  toApiOrderBookPershingSort: (sortBy: GridSortItem): ApiOrderBookSortModel => {
    const field = OrderBookPershingSortColumnsEnum[sortBy.field as OrderBookPershingSortColumns];
    return {
      field,
      direction: sortBy.sort,
    };
  },
  toApiOrderBookCalendarSort: (sortBy: GridSortItem): ApiOrderBookSortModel => {
    const field = OrderBookCalendarSortColumnsEnum[sortBy.field as OrderBookCalendarSortColumns];
    return {
      field,
      direction: sortBy.sort,
    };
  },
  toApiOrderBookSort: (sortBy: GridSortItem): ApiOrderBookSortModel => {
    const field = OrderBookAuctionSortColumnsEnum[sortBy.field as OrderBookAuctionSortColumns];

    return {
      field,
      direction: sortBy.sort,
    };
  },
  toApiOrderBookAuctionsFilters: (model: OrderBookAuctionFilterFields): ApiOrderBookAuctionsFiltersModel => {
    const mappedUnderlyings = model.underlying?.map((underlying) => underlying.name);

    return {
      issuer_id: model.issuer || undefined,
      note_type: model.noteType || undefined,
      status: model.status || undefined,
      underlying: mappedUnderlyings || undefined,
      timeframe: model.timeFrame
        ? OrderBookMapper.toApiOrderBookTimeFrameString(model.timeFrame as OrderBookTimeFrame)
        : undefined,
      is_open: model.is_open || undefined,
    };
  },
  toApiPershingSearchParams: (searchParams: OrderBookPershingQueryFields): ApiGetOrderBookOptionsModel => {
    const sortBy = searchParams.sortBy.map(OrderBookMapper.toApiOrderBookPershingSort);
    return {
      ...OrderBookMapper.toApiOrderBookPagination(searchParams),
      sort_by: sortBy && sortBy.length ? sortBy : undefined,
      filters: searchParams.filters ? OrderBookMapper.toApiOrderBookPershingFilters(searchParams.filters) : undefined,
    };
  },
  toApiCalendarSearchParams: (searchParams: OrderBookCalendarsQueryFields): ApiGetOrderBookOptionsModel => {
    const sortBy = searchParams.sortBy.map(OrderBookMapper.toApiOrderBookCalendarSort);
    return {
      ...OrderBookMapper.toApiOrderBookPagination(searchParams),
      sort_by: sortBy && sortBy.length ? sortBy : undefined,
      filters: searchParams.filters ? OrderBookMapper.toApiOrderBookCalendarsFilters(searchParams.filters) : undefined,
    };
  },
  toApiAuctionSearchParams: (searchParams: OrderBookAuctionQueryFields): ApiGetOrderBookOptionsModel => {
    const sortBy = searchParams.sortBy.map(OrderBookMapper.toApiOrderBookSort);
    return {
      ...OrderBookMapper.toApiOrderBookPagination(searchParams),
      sort_by: sortBy && sortBy.length ? sortBy : undefined,
      filters: searchParams.filters ? OrderBookMapper.toApiOrderBookAuctionsFilters(searchParams.filters) : undefined,
    };
  },
  toApiPershingOrderBookCSVRequestModel: (
    searchParams: OrderBookPershingQueryFields,
  ): ApiGetOrderBookCSVRequestModel => {
    const sortBy = searchParams.sortBy.map(OrderBookMapper.toApiOrderBookPershingSort);
    return {
      sort_by: sortBy && sortBy.length ? sortBy : undefined,
      filters: searchParams.filters ? OrderBookMapper.toApiOrderBookPershingFilters(searchParams.filters) : undefined,
    };
  },
  toApiCalendarOrderBookCSVRequestModel: (
    searchParams: OrderBookCalendarsQueryFields,
  ): ApiGetOrderBookCSVRequestModel => {
    const sortBy = searchParams.sortBy.map(OrderBookMapper.toApiOrderBookCalendarSort);
    return {
      sort_by: sortBy && sortBy.length ? sortBy : undefined,
      filters: searchParams.filters ? OrderBookMapper.toApiOrderBookCalendarsFilters(searchParams.filters) : undefined,
    };
  },
  toApiAuctionOrderBookCSVRequestModel: (searchParams: OrderBookAuctionQueryFields): ApiGetOrderBookCSVRequestModel => {
    const sortBy = searchParams.sortBy.map(OrderBookMapper.toApiOrderBookSort);
    return {
      sort_by: sortBy && sortBy.length ? sortBy : undefined,
      filters: searchParams.filters ? OrderBookMapper.toApiOrderBookAuctionsFilters(searchParams.filters) : undefined,
    };
  },
};
