import {
  AnnuityApprovedJurisdictionModel,
  AnnuityCarrierModel,
  AnnuityFeatureModel,
  AnnuityInvestmentOptionModel,
  AnnuityLayeredInvestmentOptionModel,
  AnnuityModel,
  AnnuityProductModel,
  AnnuityRiderModel,
  AnnuitySurrenderChargeModel,
  AnnuityUnderlyingModel,
} from '@halo-common/models';
import { ApiUnderlyingMapper } from '@halo-data-sources/mappers';
import {
  ApiAnnuityApprovedJurisdictionModel,
  ApiAnnuityCarrierModel,
  ApiAnnuityFeatureModel,
  ApiAnnuityInvestmentOptionModel,
  ApiAnnuityLayeredInvestmentOptionModel,
  ApiAnnuityModel,
  ApiAnnuityProductModel,
  ApiAnnuityRiderModel,
  ApiAnnuitySurrenderChargesModel,
  ApiAnnuityUnderlyingModel,
} from '@halo-data-sources/models';

export const ApiAnnuityMapper = {
  toAnnuityCarrier: (model: ApiAnnuityCarrierModel): AnnuityCarrierModel => ({
    id: model.id,
    name: model.name,
    fitchRating: model.fitch_rating,
    moodysRating: model.moodys_rating,
    spRating: model.sp_rating,
    amBestRating: model.am_best_rating,
    dtccMemberCode: model.dtcc_member_code,
  }),
  toAnnuityRider: (model: ApiAnnuityRiderModel): AnnuityRiderModel => ({
    cusip: model.cusip,
    fee: model.fee,
    description: model.description,
    name: model.name,
    id: model.id.toString(),
    features: model.features?.map(ApiAnnuityMapper.toAnnuityFeature),
  }),
  toAnnuityLayeredInvestmentOption: (
    model: ApiAnnuityLayeredInvestmentOptionModel,
  ): AnnuityLayeredInvestmentOptionModel => ({
    annuityInvestment: ApiAnnuityMapper.toAnnuityInvestmentOption(model.annuity_investment),
  }),
  toAnnuityUnderlying: (model: ApiAnnuityUnderlyingModel): AnnuityUnderlyingModel => ({
    id: model.id,
    name: model.name,
    description: model.description,
    exchange: model.exchange ? ApiUnderlyingMapper.toExchangeModel(model.exchange) : null,
    asset: model.asset,
    assetType: model.asset_type ? ApiUnderlyingMapper.toTradableAssetTypeEnum(model.asset_type.toLowerCase()) : null,
    isin: model.isin,
    reuters: model.reuters,
    bloomberg: model.bloomberg,
  }),
  toAnnuityInvestmentOption: (model: ApiAnnuityInvestmentOptionModel): AnnuityInvestmentOptionModel => ({
    price: model.price,
    investmentRate: model.investment_rate,
    protectionType: model.protection_type,
    id: model.id,
    term: model.term,
    productType: model.product_type,
    anniversaryTerm: model.anniversary_term,
    tradable: ApiUnderlyingMapper.toUnderlyingModel(model.tradable),
    protectionPercent: model.protection_percent,
    subAdvisor: model.sub_advisor,
    parameter: model.parameter,
  }),
  toAnnuitySurrenderCharges: (model: ApiAnnuitySurrenderChargesModel): AnnuitySurrenderChargeModel => ({
    1: model[1],
    2: model[2],
    3: model[3],
    4: model[4],
    5: model[5],
    6: model[6],
    7: model[7],
    8: model[8],
    9: model[9],
    10: model[10],
  }),
  toAnnuityFeature: (model: ApiAnnuityFeatureModel): AnnuityFeatureModel => ({
    haloName: model.halo_name,
    fee: model.fee,
    description: model.description,
    riderName: model.rider_name,
    haloCategoryName: model.halo_category_name,
    id: model.id,
    name: model.name,
  }),
  toAnnuityApprovedJurisdiction: (model: ApiAnnuityApprovedJurisdictionModel): AnnuityApprovedJurisdictionModel => ({
    code: model.code,
    id: model.id,
    name: model.name,
  }),
  toAnnuityProductModel: (model: ApiAnnuityProductModel): AnnuityProductModel => ({
    id: model.id.toString(),
    active: model.active,
    source: model.source,
    name: model.name,
    cusip: model.cusip,
    carrier: ApiAnnuityMapper.toAnnuityCarrier(model.carrier),
    carrierSvg: model.carrier_svg,
    svgUrl: model.svg_url,
    productType: model.product_type,
    description: model.description,
    dtccInsuranceType: model.dtcc_insurance_type,
    compensationType: model.compensation_type,
    adminFee: model.admin_fee,
    baseFee: model.base_fee,
    maintenanceFee: model.maintenance_fee,
    maxAge: model.max_age,
    minMotional: model.min_notional,
    maxNotional: model.max_notional,
    investmentOptions: model.investment_options?.map(ApiAnnuityMapper.toAnnuityInvestmentOption),
    surrenderCharges: model.surrender_chargers?.map(ApiAnnuityMapper.toAnnuitySurrenderCharges),
    features: model.features?.map(ApiAnnuityMapper.toAnnuityFeature),
    approvedJurisdictions: model.approved_jurisdictions?.map(ApiAnnuityMapper.toAnnuityApprovedJurisdiction),
    riders: model.riders?.map(ApiAnnuityMapper.toAnnuityRider),
    lastPriceDate: model.last_price_date,
    subAdvisors: model.subadvisors,
    underlyings: model.underlyings?.map(ApiAnnuityMapper.toAnnuityUnderlying),
  }),
  toAnnuityModel: (model: ApiAnnuityModel): AnnuityModel => ({
    approvedJurisdictions: model.approved_jurisdictions,
    expenses: model.expenses,
    id: model.id,
    investmentOptions: model.investment_options.map(ApiAnnuityMapper.toAnnuityLayeredInvestmentOption),
    isWeightedBasket: model.is_weighted_basket,
    product: ApiAnnuityMapper.toAnnuityProductModel(model.product),
  }),
};
