import { FixActions } from '@halo-stores/Fix';
import { createSlice } from '@reduxjs/toolkit';

import { CalendarState, CalendarStatusEnum } from './CalendarModel';
import {
  createCalendarAllocationRequest,
  fetchAdminCalendarPages,
  fetchCalendar,
  fetchCalendarAllocations,
  fetchCalendarPageIssuers,
  updateCalendarAllocationRequest,
  uploadCalendar,
} from './CalendarThunks';

const INITIAL_STATE: CalendarState = {
  adminCalendarPages: [],
  issuers: [],
  parentChildPages: [],
  selectedAllocation: null,
  selectedCalendar: null,
  status: CalendarStatusEnum.idle,
  allocations: [],
};

export const CalendarDuck = createSlice({
  name: 'Calendar',
  initialState: INITIAL_STATE,
  reducers: {
    resetError(state) {
      return {
        ...state,
        error: INITIAL_STATE.error,
      };
    },
    resetStatus(state) {
      return {
        ...state,
        status: INITIAL_STATE.status,
      };
    },
    selectAllocation(state, action) {
      return {
        ...state,
        error: INITIAL_STATE.error,
        selectedCalendar: action?.payload?.calendar,
        selectedAllocation: action?.payload?.allocation,
      };
    },
    selectCalendar(state, action) {
      return {
        ...state,
        error: INITIAL_STATE.error,
        selectedCalendar: action?.payload?.calendar,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminCalendarPages.fulfilled, (state, action) => {
        state.status = CalendarStatusEnum.successFetchAdminCalendarPages;
        state.error = INITIAL_STATE.error;

        state.adminCalendarPages = action?.payload?.pages;
        state.parentChildPages = action?.payload?.parentChildPages;
      })
      .addCase(fetchAdminCalendarPages.pending, (state) => {
        state.status = CalendarStatusEnum.requestFetchAdminCalendarPages;
      })
      .addCase(fetchAdminCalendarPages.rejected, (state, action) => {
        state.status = CalendarStatusEnum.failureFetchAdminCalendarPages;
        state.error = action?.payload?.message;
      })
      .addCase(fetchCalendar.pending, (state) => {
        state.status = CalendarStatusEnum.requestFetchCalendar;
      })
      .addCase(fetchCalendar.rejected, (state, action) => {
        state.status = CalendarStatusEnum.failureFetchCalendar;
        state.error = action?.payload?.message;

        state.selectedCalendar = INITIAL_STATE.selectedCalendar;
      })
      .addCase(fetchCalendar.fulfilled, (state, action) => {
        state.status = CalendarStatusEnum.successFetchCalendar;
        state.error = INITIAL_STATE.error;

        state.selectedCalendar = action?.payload.calendar;
      })
      .addCase(fetchCalendarPageIssuers.fulfilled, (state, action) => {
        state.status = CalendarStatusEnum.successFetchCalendarPageIssuers;
        state.error = INITIAL_STATE.error;

        state.issuers = action?.payload?.issuers;
      })
      .addCase(fetchCalendarPageIssuers.pending, (state) => {
        state.status = CalendarStatusEnum.requestFetchCalendarPageIssuers;
      })
      .addCase(fetchCalendarPageIssuers.rejected, (state, action) => {
        state.status = CalendarStatusEnum.failureFetchCalendarPageIssuers;
        state.error = action?.payload?.message;
      })
      .addCase(uploadCalendar.fulfilled, (state) => {
        state.status = CalendarStatusEnum.successUploadCalendar;
        state.error = INITIAL_STATE.error;
      })
      .addCase(uploadCalendar.pending, (state) => {
        state.status = CalendarStatusEnum.requestUploadCalendar;
      })
      .addCase(uploadCalendar.rejected, (state, action) => {
        state.status = CalendarStatusEnum.failureUploadCalendar;
        state.error = action?.payload?.message;
      })
      .addCase(FixActions.fetchFixOrderAndAllocation.pending, (state) => {
        state.status = CalendarStatusEnum.requestFetchFixOrderAndAllocation;
      })
      .addCase(FixActions.fetchFixOrderAndAllocation.rejected, (state) => {
        state.status = CalendarStatusEnum.failureFetchFixOrderAndAllocation;

        state.selectedAllocation = INITIAL_STATE.selectedAllocation;
      })
      .addCase(FixActions.fetchFixOrderAndAllocation.fulfilled, (state, action) => {
        state.status = CalendarStatusEnum.successFetchFixOrderAndAllocation;

        state.selectedAllocation = action?.payload.allocation;
      })
      .addCase(fetchCalendarAllocations.fulfilled, (state, action) => {
        state.status = CalendarStatusEnum.successFetchCalendarAllocations;
        state.error = INITIAL_STATE.error;

        state.allocations = action?.payload.allocations;
      })
      .addCase(fetchCalendarAllocations.pending, (state) => {
        state.status = CalendarStatusEnum.requestFetchCalendarAllocations;
      })
      .addCase(fetchCalendarAllocations.rejected, (state, action) => {
        state.status = CalendarStatusEnum.failureFetchCalendarAllocations;

        state.error = action?.payload?.message;
      })
      .addCase(createCalendarAllocationRequest.fulfilled, (state, action) => {
        state.status = CalendarStatusEnum.successCreateCalendarAllocationRequest;
        state.error = INITIAL_STATE.error;

        if (state.selectedCalendar) state.selectedCalendar = action?.payload.calendar;
      })
      .addCase(createCalendarAllocationRequest.pending, (state) => {
        state.status = CalendarStatusEnum.requestCreateCalendarAllocationRequest;
      })
      .addCase(createCalendarAllocationRequest.rejected, (state, action) => {
        state.status = CalendarStatusEnum.failureCreateCalendarAllocationRequest;

        state.error = action?.payload?.message;
      })
      .addCase(updateCalendarAllocationRequest.fulfilled, (state, action) => {
        state.status = CalendarStatusEnum.successUpdateCalendarAllocationRequest;
        state.error = INITIAL_STATE.error;

        if (state.selectedCalendar) state.selectedCalendar.allocations = action?.payload;
      })
      .addCase(updateCalendarAllocationRequest.pending, (state) => {
        state.status = CalendarStatusEnum.requestUpdateCalendarAllocationRequest;
      })
      .addCase(updateCalendarAllocationRequest.rejected, (state, action) => {
        state.status = CalendarStatusEnum.failureUpdateCalendarAllocationRequest;

        state.error = action?.payload?.message;
      });
  },
});
