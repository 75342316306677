import { CalendarDuck } from './CalendarDuck';
import * as selectors from './CalendarSelectors';
import * as thunks from './CalendarThunks';

export const CalendarSelectors = selectors;

export const CalendarActions = {
  ...CalendarDuck.actions,
  ...thunks,
};

export const CalendarDuckName = CalendarDuck.name;

export const CalendarReducer = CalendarDuck.reducer;

export * from './CalendarModel';
