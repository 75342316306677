import { ReactElement } from 'react';

import { AuctionStrikeStyleEnum, AuctionStyleEnum } from '@halo-common/enums';
import { ExecutionAuctionReviewDetailFormFields, ExecutionAuctionReviewOffersFormFields } from '@halo-common/modals';
import { AuctionAdminModel, IssuerModel } from '@halo-common/models';
import { Box, Container } from '@mui/material';

const containerSx = {
  backgroundColor: 'background.default',
  display: 'flex',
  justifyContent: 'center',
  minWidth: 915,
  mx: 0,
  pb: 1,
  px: 3,
  width: '100%',
};

const formSx = {
  backgroundColor: 'background.default',
  display: 'flex',
  flexDirection: 'column',
  m: 0,
  width: '100%',
};

export type ExecutionAuctionReviewUpdateFields = {
  targetSolvableParameter?: string | null;
  price?: string | null;
  style?: AuctionStyleEnum;
  strikeStyle?: AuctionStrikeStyleEnum;
  noteId?: string;
  additionalDetails?: string;
  isTopOfBook?: 'yes' | 'no';
  offers?: Array<IssuerModel>;
  notional?: string;
};

export type ExecutionAuctionReviewEditTabProps = {
  product?: AuctionAdminModel | null;
  loading: boolean;
};

export const ExecutionAuctionReviewEditTab = ({
  product,
  loading,
}: ExecutionAuctionReviewEditTabProps): ReactElement => (
  <Container sx={containerSx} maxWidth="sm" disableGutters>
    <Box component="form" sx={formSx}>
      <ExecutionAuctionReviewDetailFormFields product={product} loading={loading} />
      <ExecutionAuctionReviewOffersFormFields loading={loading} />
    </Box>
  </Container>
);
