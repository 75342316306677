import { ReactElement, useState } from 'react';

// import { historicalPerformanceFormFieldsAtom } from '@halo-atoms/historicalPerformance';
import { EmptyResultsCard } from '@halo-common/components';
import { useBreakpointView } from '@halo-common/hooks';
// import { useHistoricalPerformanceQuery } from '@halo-data-sources/queries';
import { BrandIcon, HaloDataGrid, useCombinedStyling } from '@halodomination/halo-fe-common';
import { Box } from '@mui/material';
import { GridSortCellParams, GridSortModel } from '@mui/x-data-grid-pro';
// import { useAtomValue } from 'jotai';

type HistoricalPerformanceRow = {
  scenarios: number;
  oneYear: number;
  threeYear: number;
  fiveYear: number;
};

export const HistoricalPerformanceResultsTable = (): ReactElement => {
  // TODO: Fix this
  // const form = useAtomValue(historicalPerformanceFormFieldsAtom);
  // const { data: tableData } = useHistoricalPerformanceQuery(form);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'scenarios',
      sort: 'asc',
    },
  ]);

  const rows: Array<HistoricalPerformanceRow> = [];
  const hasContent = rows.length;

  const isDesktop = !useBreakpointView('md');
  const mobileBoxSx = !isDesktop ? { p: 0 } : null;
  const combinedBoxStyling = useCombinedStyling({ width: '100%' }, mobileBoxSx);

  const title = 'No Results';
  const description = 'Use the above criteria to measure the effectiveness of protection based on historical results.';

  const brandIcon = <BrandIcon variant="search" />;

  if (!hasContent) {
    return <EmptyResultsCard showBorder={isDesktop} title={title} description={description} brandIcon={brandIcon} />;
  }

  const historicalPerformanceColumns = [
    {
      field: 'scenarios',
      headerName: 'Scenarios',
      flex: 6,
      sortComparator: (_v1: string, _v2: string, param1: GridSortCellParams, param2: GridSortCellParams) =>
        param1.api.getCellParams(param1.id, 'order').row.order - param2.api.getCellParams(param2.id, 'order').row.order,
    },
    {
      field: 'oneYear',
      headerName: '1 Year',
      flex: 2,
    },
    {
      field: 'threeYear',
      headerName: '3 Year',
      flex: 2,
    },
    {
      field: 'fiveYear',
      headerName: '5 Year',
      flex: 2,
    },
  ];

  return (
    <Box sx={combinedBoxStyling}>
      <HaloDataGrid
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        columns={historicalPerformanceColumns}
        rows={rows}
        density="compact"
        hideFooter
      />
    </Box>
  );
};
