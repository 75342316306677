import { ReactElement } from 'react';

import {
  DEFAULT_PRODUCT_DETAIL_MODAL_ANALYTICS_TAB_STATE,
  productDetailModalAnalyticsTabStateAtom,
} from '@halo-atoms/pdm';
import { ProductDetailModalLayout, ProductDetailModalLayoutProps } from '@halo-common/layouts';
import { ModalBase } from '@halodomination/halo-fe-common';
import { useSetAtom } from 'jotai';

export type ProductDetailModalProps = ProductDetailModalLayoutProps & {
  onClose: () => void;
  open: boolean;
  className?: string;
};

export const ProductDetailModal = ({
  children,
  onClose,
  open,
  className,
  ...props
}: ProductDetailModalProps): ReactElement => {
  const setState = useSetAtom(productDetailModalAnalyticsTabStateAtom);

  const handleClose = () => {
    setState(DEFAULT_PRODUCT_DETAIL_MODAL_ANALYTICS_TAB_STATE);
    onClose();
  };

  return (
    <ModalBase size="large" open={open} onClose={handleClose} className={className}>
      <ProductDetailModalLayout {...props}>{children}</ProductDetailModalLayout>
    </ModalBase>
  );
};
