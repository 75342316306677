import { ReactElement } from 'react';

import { DateRangePicker } from '@halo-common/components';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { usePershingAdminContext } from '@halo-modules/admin';
import { CalendarSelectors } from '@halo-stores/Calendar';
import { Stack } from '@halodomination/halo-fe-common';
import { Button, MenuItem, TextField } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

export type ExecutionHubFilterSchema = {
  cusip?: string;
  account?: string;
  ip?: string;
  issuer?: string;
};

export type ExecutionHubFiltersBarProps = {
  expirationDates: Array<DateTime | null>;
  onClear: () => void;
  onDatesChange: (dates: DateRange<DateTime>) => void;
  onSearch: (params?: ExecutionHubFilterSchema) => void;
};

export const ExecutionHubFiltersBar = ({
  expirationDates = [],
  onClear,
  onDatesChange,
  onSearch,
}: ExecutionHubFiltersBarProps): ReactElement => {
  const { disableFilters, pageLoading } = usePershingAdminContext();

  const issuers = useSelector(CalendarSelectors.selectCalendarPageIssuers);

  const { control, register, handleSubmit } = useFormContext<ExecutionHubFilterSchema>();

  const { ref: cusipRef, ...cusipProps } = register('cusip');
  const { ref: ipRef, ...ipProps } = register('ip');
  const { ref: accountRef, ...accountProps } = register('account');

  const disableActions = pageLoading || disableFilters;

  const submitHandler = handleSubmit((data: ExecutionHubFilterSchema) => {
    const params = {
      cusip: data.cusip,
      accountNumber: data.account,
      ip: data.ip,
      startDate: expirationDates[0],
      endDate: expirationDates[1],
      issuerId: data.issuer !== 'default' ? data.issuer : undefined,
    };

    onSearch(params);
  });

  return (
    <Stack direction="row" alignItems="center" spacing={2} xs={3} wrap="wrap">
      <TextField inputRef={cusipRef} label="CUSIP" fullWidth {...cusipProps} />
      <Controller
        name="issuer"
        control={control}
        defaultValue="default"
        render={({ field: { ref, ...fieldProps } }) => (
          <TextField {...fieldProps} inputRef={ref} label="Issuer" fullWidth select>
            <MenuItem value="default">Select an Issuer...</MenuItem>
            {issuers?.map((issuer) => (
              <MenuItem key={issuer.id} value={issuer.id}>
                {issuer.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <TextField inputRef={ipRef} label="IBD/ORG" fullWidth {...ipProps} />
      <TextField inputRef={accountRef} label="Account" fullWidth {...accountProps} />
      <DateRangePicker
        label="Expiration Date"
        startDate={expirationDates[0]}
        endDate={expirationDates[1]}
        format={MONTH_DAY_YEAR_DATE_FORMAT}
        onChange={onDatesChange}
      />
      <Stack direction="row" spacing={2}>
        <Button disabled={disableActions} color="primary" variant="contained" onClick={submitHandler}>
          Search
        </Button>
        <Button disabled={disableActions} color="primary" variant="outlined" onClick={onClear}>
          Clear
        </Button>
      </Stack>
    </Stack>
  );
};
