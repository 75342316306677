import { ReactElement } from 'react';

export type BackgroundEllipseProps = {
  color: string | undefined;
  width?: number;
  height?: number;
};

export const BackgroundEllipse = ({ color, height = 407, width = 374 }: BackgroundEllipseProps): ReactElement => (
  <svg width={width} height={height} viewBox="0 0 399 416" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="199.768" cy="207.732" rx="194.725" ry="211.737" transform="rotate(30 199.768 207.732)" fill={color} />
  </svg>
);
