import { CommonUserSettingGroupSetting, useCommonUserSettingsQuery } from '@halo-data-sources/queries';

export const useCommonUserSetting = (field: string, group?: string): CommonUserSettingGroupSetting['value'] => {
  const { data: settings } = useCommonUserSettingsQuery();

  if (!settings) return undefined;

  if (!group) {
    const foundValues = settings.settingGroups.reduce(
      (prev, settingGroup) => {
        const value = settingGroup.settings.find((setting) => setting.field === field)?.value;
        if (value !== undefined) return [...prev, value];
        else return prev;
      },
      [] as Array<CommonUserSettingGroupSetting['value']>,
    );
    return foundValues[0];
  } else {
    const groupSettings = settings.settingGroups.find((settingGroup) => settingGroup.name === group);
    if (!groupSettings) return undefined;
    return groupSettings.settings.find((setting) => setting.field === field)?.value;
  }
};
