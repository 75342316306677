import { ReactElement } from 'react';

import { RepSettingInputFieldSchema } from '@halo-common/formComponents';
import { Iconography, LocalizedTextField, LocalizedTextFieldProps } from '@halodomination/halo-fe-common';
import { Box, IconButton, Stack } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

const inputSx = {
  mb: 1,
  flex: 3,
};

const removeButtonSx = {
  display: 'flex',
  justifyContent: 'flex-end',
};

export type RepSettingInputProps = {
  label: string;
  positionIndex: number;
  fieldIndex: number;
  onRemove: (index: number) => void;
  dynamicContent: LocalizedTextFieldProps['dynamicContent'];
};

export const RepSettingInput = ({
  label,
  positionIndex,
  onRemove,
  dynamicContent,
  fieldIndex,
}: RepSettingInputProps): ReactElement => {
  const {
    control,
    formState: { errors },
  } = useFormContext<RepSettingInputFieldSchema>();

  const { field } = useController({ control, name: `repSettingArrayField.${fieldIndex}.${positionIndex}.value` });

  const hasError = Boolean(errors?.repSettingArrayField?.[fieldIndex]?.[positionIndex]?.value);
  const error = errors?.repSettingArrayField?.[fieldIndex]?.[positionIndex]?.value?.message;
  const isNotFirstPosition = positionIndex !== 0;

  const handleRemove = () => {
    onRemove(positionIndex);
  };

  const removeButton = isNotFirstPosition ? (
    <Box sx={removeButtonSx}>
      <IconButton onClick={handleRemove}>
        <Iconography iconName="trash" />
      </IconButton>
    </Box>
  ) : null;

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <LocalizedTextField
        {...field}
        size="large"
        dynamicContent={dynamicContent}
        error={hasError}
        helperText={error}
        sx={inputSx}
        fullWidth
        label={label}
      />
      {removeButton}
    </Stack>
  );
};
