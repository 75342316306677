import { OrderBookAuctionQueryFields } from '@halo-common/models';
import { getOrderBookAuctionCSVDownload } from '@halo-data-sources/clients';
import { OrderBookMapper } from '@halo-data-sources/mappers';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

export type UseOrderBookDownloadAuctionCSVMutationResult = UseMutationResult<void, Error, OrderBookAuctionQueryFields>;

const orderBookDownloadAuctionCSVMutationFn = async (searchParams: OrderBookAuctionQueryFields) => {
  const data = await getOrderBookAuctionCSVDownload(OrderBookMapper.toApiAuctionOrderBookCSVRequestModel(searchParams));
  if (data.tmplink) window.open(data.tmplink, '_blank')?.focus();
};

export const useOrderBookDownloadAuctionCSVMutation = (): UseOrderBookDownloadAuctionCSVMutationResult =>
  useMutation<void, Error, OrderBookAuctionQueryFields>({
    mutationFn: orderBookDownloadAuctionCSVMutationFn,
  });
