import { GetEducationHubDetailsRequestModel } from '@halo-common/models';
import { downloadFile } from '@halo-common/utils';
import { getEducationHubCSV } from '@halo-data-sources/clients';
import { EducationHubMapper } from '@halo-data-sources/mappers';
import { EducationHubQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export type EducationHubCSVQueryResult = {
  message: string;
};

const getEducationHubCSVQueryFn = async (filters: GetEducationHubDetailsRequestModel) => {
  const payload = EducationHubMapper.toEducationHubCSVRequest(filters);
  const response = await getEducationHubCSV(payload);

  downloadFile({ fileName: response.filename, downloadFilePath: response.tmplink });

  return { message: 'successfully downloaded education hub csv.' };
};

export const useEducationHubCSVQuery = (
  filters: GetEducationHubDetailsRequestModel,
): UseQueryResult<EducationHubCSVQueryResult, Error> =>
  useQuery<EducationHubCSVQueryResult, Error>({
    queryKey: EducationHubQueryKeyFactory.adminCSV(filters),
    queryFn: () => getEducationHubCSVQueryFn(filters),
    enabled: false,
    retry: false,
  });
