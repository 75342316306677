import { DocumentBucketModel } from '@halo-common/models';
import { generateDocumentUrl } from '@halo-data-sources/clients';
import { isPlainObject } from 'lodash';

export type DownloadFileProps = {
  fileName: string;
  downloadFilePath: string;
  onFinish?: (finished: boolean) => void;
};

export const downloadFile = ({ fileName, downloadFilePath, onFinish }: DownloadFileProps): void => {
  const request = new XMLHttpRequest();

  request.responseType = 'blob';
  request.open('GET', downloadFilePath);
  request.addEventListener('error', () => onFinish?.(false));
  request.addEventListener('load', () => {
    const { response } = request;

    const url = URL.createObjectURL(response as Blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();

    onFinish?.(true);
  });

  request.send();
};

export const isDocumentFromS3 = (value: unknown): value is DocumentBucketModel =>
  isPlainObject(value) && Object.prototype.hasOwnProperty.call(value, 'bucket');

export const isFileOrFromS3 = (value: unknown): value is File | DocumentBucketModel =>
  value instanceof File || isDocumentFromS3(value);

export const fetchDocumentFromS3 = async (document: File | DocumentBucketModel): Promise<File> => {
  if (document instanceof File) return document;

  const { download_url } = await generateDocumentUrl(document);

  const response = await fetch(download_url);
  const blob = await response.blob();
  const file = new File([blob], document.filename, { type: blob.type });

  return file;
};
