export enum NoteTypeEnum {
  Income = 'income',
  Growth = 'growth',
  Digital = 'digital',
  Absolute = 'absolute',
  Catapult = 'catapult',
  General = 'general',
}

export enum NoteProductTypeEnum {
  MLCD = 'MLCD',
  DigitalPlus = 'digital plus',
  PrincipalProtected = 'ppn',
  Snowball = 'snowball',
}

export enum NoteProtectionTypeEnum {
  Continuous = 'continuous',
  Daily = 'daily',
  Hard = 'hard',
  Soft = 'soft',
}

export enum NoteCouponTypeEnum {
  Contingent = 'contingent',
  Guaranteed = 'guaranteed',
  Memory = 'memory',
  Recall = 'recall',
}

export enum NoteCouponPeriodEnum {
  Annually = 'annually',
  Quarterly = 'quarterly',
  Monthly = 'monthly',
  Semiannually = 'semiannually',
}

export enum NoteCouponsPerYearEnum {
  annually = 1,
  quarterly = 4,
  monthly = 12,
  semiannually = 2,
}

export enum NoteCallInformationTypeEnum {
  AutoCall = 'autocall',
  Custom = 'custom',
  Fixed = 'fixed',
  Issuer = 'issuer',
  NonCall = 'noncall',
  StepDown = 'stepdown',
  StepUp = 'stepup',
  Step = 'step',
}

export enum NoteCallInformationFrequencyEnum {
  Annually = 'annually',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Semiannually = 'semiannually',
}

export enum NoteParameterEnum {
  AnnualCoupon = 'annual_coupon',
  DigitalCoupon = 'digital_coupon',
  Participation = 'participation',
  MaxReturn = 'max_return',
  Price = 'price',
}

export enum NoteMaxReturnTypeEnum {
  Capped = 'capped',
  Uncapped = 'uncapped',
}
