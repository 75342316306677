import { ReactElement } from 'react';

import { ActionButton } from '@halo-common/components';
import { Iconography } from '@halodomination/halo-fe-common';
import { IconButton, Popover, Stack, Typography } from '@mui/material';

const popoverPaperProps = {
  sx: {
    p: 2,
    width: 548,
  },
};

const iconButtonSx = {
  mt: -1,
  mr: -1,
};

export type AuctionDetailsRejectTermsPopover = {
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  loading: boolean;
  onReject: () => void;
  onClose: () => void;
};

export const AuctionDetailsRejectTermsPopover = ({
  open,
  anchorEl,
  loading,
  onReject,
  onClose,
}: AuctionDetailsRejectTermsPopover): ReactElement => {
  const rejectIcon = <Iconography iconName="check" color="common.white" />;

  return (
    <Popover
      PaperProps={popoverPaperProps}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Stack direction="column" spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Confirm Termsheet Rejection</Typography>
          <IconButton sx={iconButtonSx} onClick={onClose}>
            <Iconography iconName="xmark" color="text.secondary" />
          </IconButton>
        </Stack>
        <Typography variant="body2">
          Please confirm that you would like to reject the termsheet provided. This will terminate the Auction
          immediately, and cannot be undone.
        </Typography>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <ActionButton variant="outlined" onClick={onClose} loading={loading}>
            Go Back
          </ActionButton>
          <ActionButton startIcon={rejectIcon} variant="contained" color="error" onClick={onReject} loading={loading}>
            Confirm Rejection
          </ActionButton>
        </Stack>
      </Stack>
    </Popover>
  );
};
