import { ReactElement } from 'react';

export type ForegroundEllipseProps = {
  color: string;
  width?: number;
  height?: number;
};

export const ForegroundEllipse = ({ color, height = 400, width = 400 }: ForegroundEllipseProps): ReactElement => (
  <svg width={width} height={height} viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="200" cy="200" r="200" fill={color} />
  </svg>
);
