import { CalendarIssuerQueryModel } from '@halo-common/models';
import { getCalendarIssuer } from '@halo-data-sources/clients';
import { ApiCalendarsMapper } from '@halo-data-sources/mappers';
import { ApiGetCalendarDetailsRequestModel } from '@halo-data-sources/models';
import { CalendarsQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export type GetCalendarIssuerPayloadModel = {
  endDate: string;
  startDate: string;
  approved?: boolean | null;
  showAsNME?: boolean;
};

const getCalendarIssuerFn = async ({
  approved = true,
  startDate,
  endDate,
  showAsNME,
}: GetCalendarIssuerPayloadModel) => {
  const payload: ApiGetCalendarDetailsRequestModel = {
    end_date: endDate,
    start_date: startDate,
    filters: {},
  };

  if (approved !== undefined) payload.filters.approved = approved;
  if (showAsNME !== undefined) payload.filters.show_as_nme = showAsNME;

  const response = await getCalendarIssuer(payload);

  const issuers = response.issuers
    .map(ApiCalendarsMapper.toCalendarIssuerModel)
    .sort((a, b) => a.name.localeCompare(b.name));

  return issuers;
};

export const useCalendarIssuersQuery = (
  payload: GetCalendarIssuerPayloadModel,
): UseQueryResult<Array<CalendarIssuerQueryModel>, Error> =>
  useQuery<Array<CalendarIssuerQueryModel>, Error>({
    queryKey: CalendarsQueryKeyFactory.issuers(payload),
    queryFn: () => getCalendarIssuerFn(payload),
  });
