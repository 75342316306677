import { UnderlyingSearchModel } from '@halo-common/models';
import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type UnderlyingQueryKeys = {
  all: QueryKeyFactoryFn;
  search: QueryKeyFactoryFn;
  infiniteSearch: QueryKeyFactoryFn;
};

export const UnderlyingQueryKeyFactory: UnderlyingQueryKeys = {
  all: () => ['underlying'],
  search: (payload: UnderlyingSearchModel) => [...UnderlyingQueryKeyFactory.all(), 'search', hash(payload)],
  infiniteSearch: (variant, underlying, query) => [
    ...UnderlyingQueryKeyFactory.all(),
    'infiniteSearch',
    variant,
    underlying,
    hash(query),
  ],
};
