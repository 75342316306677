import { ReactElement } from 'react';

import { HaloLink } from '@halo-common/components';
import { useRecordActivityMutation } from '@halo-data-sources/mutations';
import { Iconography } from '@halodomination/halo-fe-common';
import { Alert, Button, Typography } from '@mui/material';

const alertSx = { mt: 2 };

export const PortfolioPositionsBetaAlert = (): ReactElement => {
  const baseUrl = window?.location?.origin;
  const legacyPortfolioUrl = `${baseUrl}/portfolio?legacy=true`;

  const { mutate: recordActivity } = useRecordActivityMutation();

  const shareIcon = <Iconography iconName="arrow-up-right-from-square" />;

  const handleActivityTrack = () => {
    recordActivity({
      activity: [{ verb: 'navigated from react portfolio to legacy portfolio', model_name: 'Portfolio' }],
      fromIFrame: false,
    });
  };

  return (
    <Alert severity="info" variant="filled" sx={alertSx}>
      <Typography>
        BETA: You are viewing an updated look and feel for your new portfolio. Temporarily, the old view is still
        accessible.
      </Typography>
      <Button
        onClick={handleActivityTrack}
        variant="text"
        component={HaloLink}
        startIcon={shareIcon}
        href={legacyPortfolioUrl}
        target="_parent"
      >
        View Old Portfolio
      </Button>
    </Alert>
  );
};
