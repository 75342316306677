import { HTMLAttributes, ReactElement } from 'react';

import { CustodianFormTextField } from '@halo-common/formComponents';
import { CustodianModel } from '@halo-common/models';
import { useCustodianQuery } from '@halo-data-sources/queries';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Autocomplete, Box, SxProps, TextFieldProps, Typography } from '@mui/material';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';

export type CustodianFormFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  id?: string;
  label: string;
  helperText?: TextFieldProps['helperText'];
  variant?: TextFieldProps['variant'];
  sx?: SxProps<HaloTheme>;
  disabled?: boolean;
  name: TName;
  control: UseControllerProps<TFieldValues>['control'];
  rules?: UseControllerProps<TFieldValues>['rules'];
};

export const CustodianFormField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  helperText,
  label,
  disabled,
  sx,
  control,
  rules,
  name,
}: CustodianFormFieldProps<TFieldValues, TName>): ReactElement => {
  const { data, isPending } = useCustodianQuery();

  const custodianFieldMethods = useController({ control, name, rules });

  const custodians = data ?? [];
  const error = custodianFieldMethods.fieldState.error;

  const handleRenderOption = (props: HTMLAttributes<HTMLLIElement>, option: CustodianModel) => (
    <Box component="li" {...props}>
      <Typography>{option.name}</Typography>
    </Box>
  );

  const getOptionLabel = (option: string | CustodianModel) => (typeof option === 'string' ? option : option.name);

  const handleOptionEqualToValue = (option: CustodianModel, value: CustodianModel) => option.id === value.id;

  return (
    <Autocomplete
      {...custodianFieldMethods.field}
      openOnFocus
      autoHighlight
      autoComplete
      freeSolo
      loading={isPending}
      disabled={disabled}
      options={custodians}
      renderInput={(params) => (
        <CustodianFormTextField {...params} error={error} label={label} helperText={helperText} />
      )}
      sx={sx}
      isOptionEqualToValue={handleOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      renderOption={handleRenderOption}
      onChange={(_, data) => custodianFieldMethods.field.onChange(data)}
    />
  );
};
