import type { DocumentBucketModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { generateDocumentUrl } from '@halo-data-sources/clients';
import type { ApiGenerateDocumentUrlResponseModel } from '@halo-data-sources/models';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';

export type usePreviewS3DocumentMutationVariables = DocumentBucketModel;

export type usePreviewS3DocumentMutationOptions = UseMutationOptions<
  ApiGenerateDocumentUrlResponseModel,
  Error,
  usePreviewS3DocumentMutationVariables
>;

export type usePreviewS3DocumentMutationResult = UseMutationResult<
  ApiGenerateDocumentUrlResponseModel,
  Error,
  usePreviewS3DocumentMutationVariables
>;

const previewS3Document = async (document: DocumentBucketModel) => {
  const res = await generateDocumentUrl(document);
  const { preview_url } = res;
  navigateParentTo(preview_url, true);
  return res;
};

export const usePreviewS3DocumentMutation = (
  options?: usePreviewS3DocumentMutationOptions,
): usePreviewS3DocumentMutationResult => {
  const { enqueueErrorEvent, closeSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: previewS3Document,
    ...options,
    onError: (...props) => {
      closeSnackbar();
      enqueueErrorEvent({ message: translations.messages.error });
      options?.onError?.(...props);
    },
  });
};
