import { ReactElement, useEffect } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import {
  bulkOrderTicketManagerAtom,
  bulkOrderTicketReceiptAtom,
  orderTicketStepAtom,
  setFixTimeoutAtom,
} from '@halo-atoms/orderTicket';
import {
  EstimatedTransactionSection,
  OrderReviewAllocationListSection,
  OrderReviewCard,
  OrderReviewNoteListSection,
  OrderReviewSection,
} from '@halo-common/components';
import { HOUR_MINUTE_SECONDS_TIME_ZONE_FORMAT, MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { OrganizationCapacityEnum } from '@halo-common/enums';
import { useCalendarOrderNotes, useNoteDetailCaption, useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { CalendarModel, FixOrderFeeModel } from '@halo-common/models';
import { PERSHING_ALLOCATIONS_FORM_KEY } from '@halo-data-sources/switches';
import { Stack } from '@halodomination/halo-fe-common';
import { Alert } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';

export type OrderTicketBulkOrderReceiptProps = {
  orderReceiptTime: string | null;
};

export const OrderTicketBulkOrderReceipt = ({ orderReceiptTime }: OrderTicketBulkOrderReceiptProps): ReactElement => {
  const { watch } = useFormContext();

  const configureDateTime = useWhiteLabelDateConfiguration();

  const { pendingSubmittedAllocations, submittedAllocations, failedAllocations, fixSubmissionErrors } =
    useAtomValue(bulkOrderTicketManagerAtom);

  const { bulkOrders, totalShares } = useAtomValue(bulkOrderTicketReceiptAtom);
  const setFixTimeout = useSetAtom(setFixTimeoutAtom);
  const handleNextStep = useSetAtom(orderTicketStepAtom.handleNextAtom);
  const selectedCalendar = useAtomValue(calendarSelectedAtom) ?? ({} as CalendarModel);

  const { order: fixOrder } = bulkOrders?.[0] ?? {};

  const { cusip = '', note, issuer, category } = selectedCalendar;
  const { solicited, discretion, accountType, quantity } = watch();

  const reviewCardSubtitle = useNoteDetailCaption({ note, issuer, category });
  const stipulations = useCalendarOrderNotes(fixOrder?.stipulations);

  const orderDate = configureDateTime(orderReceiptTime);

  const notes = stipulations ?? [];
  const orderDateString = orderDate?.toFormat(`${MONTH_DAY_YEAR_DATE_FORMAT} ${HOUR_MINUTE_SECONDS_TIME_ZONE_FORMAT}`);
  const underlyings = note?.tradables?.map((tradable) => tradable.name).join(', ') ?? '';
  const reviewCardTitle = `Buy ${totalShares} Bond(s) of ${underlyings}`;

  const { capacity, commission } = fixOrder ?? {};

  const miscFees = bulkOrders.flatMap((bulkOrder) => bulkOrder.order.miscFees) as Array<FixOrderFeeModel>;
  const fixOrderCommission = commission ?? 0;
  const miscFeesSalesCredit = miscFees?.filter((fee) => fee?.type === 'SALES_CREDIT') ?? [];

  const isCommission = Boolean(capacity && OrganizationCapacityEnum[capacity] === OrganizationCapacityEnum.AGENCY);
  const hasSalesCredit = Boolean(miscFeesSalesCredit.length && miscFeesSalesCredit[0].amount && totalShares);

  const salesCredit = hasSalesCredit ? parseFloat(miscFeesSalesCredit[0].amount) * totalShares * 10 : 0;

  const miscFeesOther = miscFees?.filter((fee) => fee.type === 'OTHER') ?? [];
  const miscFeesOtherAmount = miscFeesOther.reduce((total, fee) => total + parseFloat(fee.amount), 0);
  const fixOrderFees = miscFeesOther.length ? miscFeesOtherAmount : 0;
  const fixOrderPrincipal = totalShares ? totalShares * 1000 : 0;

  const commissionOrSalesCredit = isCommission ? fixOrderCommission : salesCredit;
  const total = fixOrderPrincipal + (isCommission ? commissionOrSalesCredit : 0) + fixOrderFees;

  const errorCount = failedAllocations.length;
  const errorMsg = `${errorCount} allocation${errorCount > 1 ? 's' : ''} could not be processed 
    and ${errorCount > 1 ? 'were' : 'was'} not submitted. Estimated transaction totals may be affected. 
    Please see orderbook for more accurate measures. Please see the error details below, and submit a 
    new order once they have been corrected. All other orders have been submitted.`;

  const errorAlert = errorCount ? (
    <Alert severity="error" variant="filled">
      {errorMsg}
    </Alert>
  ) : null;

  const timeoutErrorMsg = fixSubmissionErrors[PERSHING_ALLOCATIONS_FORM_KEY];

  const timeoutAlert = timeoutErrorMsg ? (
    <Alert severity="error" variant="outlined">
      {timeoutErrorMsg}
    </Alert>
  ) : null;

  const pendingAllocationCount = pendingSubmittedAllocations.length || null;
  const submissionFinished = submittedAllocations.length + failedAllocations.length === pendingAllocationCount;
  const submissionSuccess = submittedAllocations.length;

  const successMsg = `Submitted on on ${orderDateString}. Please monitor your order blotter for execution.`;
  const successAlert = submissionSuccess ? (
    <Alert severity="success" variant="filled">
      {successMsg}
    </Alert>
  ) : null;

  useEffect(() => {
    if (submissionFinished) {
      setFixTimeout();
      handleNextStep();
    }
  }, [submissionFinished]);

  return (
    <Stack direction="column" spacing={2}>
      {timeoutAlert}
      {successAlert}
      {errorAlert}
      <Stack direction="row" spacing={4} xs={[8, 4]}>
        <OrderReviewCard title={reviewCardTitle} subtitle={reviewCardSubtitle}>
          <OrderReviewAllocationListSection />
          <OrderReviewSection header="Account Type">{accountType}</OrderReviewSection>
          <OrderReviewSection header="CUSIP">{cusip}</OrderReviewSection>
          <OrderReviewSection header="Transaction">{fixOrder?.side}</OrderReviewSection>
          <OrderReviewSection header="Offering Price">Par</OrderReviewSection>
          <OrderReviewSection header="Solicited">{solicited}</OrderReviewSection>
          <OrderReviewSection header="Discretion">{discretion}</OrderReviewSection>
          <OrderReviewSection header="Quantity (in Thousands)">{quantity}</OrderReviewSection>
          <OrderReviewSection header="Time Received">{orderDateString}</OrderReviewSection>
          <OrderReviewSection header="Order Received From">{fixOrder?.orderReceiveFrom}</OrderReviewSection>
          <OrderReviewNoteListSection notes={notes} />
        </OrderReviewCard>
        <EstimatedTransactionSection
          commission={isCommission}
          principal={fixOrderPrincipal}
          compensation={salesCredit}
          miscFees={fixOrderFees}
          total={total}
        />
      </Stack>
    </Stack>
  );
};
