export enum ExecutionHubTraceEnum {
  'No Trace' = 'No Trace',
  T = 'T',
  W = 'W',
  P1 = 'P',
}

export enum ExecutionHubJanneyStatusEnum {
  active = 'active',
  canceled = 'canceled',
  filled = 'filled',
}
