import { ReactElement } from 'react';

import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { useOrderBookAuctionNoteTypeQuery } from '@halo-data-sources/queries';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useAtom } from 'jotai';

export const AuctionNoteTypeField = (): ReactElement => {
  const { data } = useOrderBookAuctionNoteTypeQuery();

  const [searchFields, setSearchFields] = useAtom(orderBookQueryAtom);

  const labelString = 'Note Type';

  const handleNoteTypeChange = (ev: SelectChangeEvent<string>) => {
    setSearchFields({
      filters: {
        noteType: ev.target.value,
      },
    });
  };

  return (
    <FormControl fullWidth>
      <InputLabel shrink>{labelString}</InputLabel>
      <Select
        label={labelString}
        size="large"
        value={searchFields.filters.noteType}
        onChange={handleNoteTypeChange}
        displayEmpty
        notched
      >
        <MenuItem value="">All</MenuItem>
        {data?.noteProductTypes.map((entry) => (
          <MenuItem key={entry.noteProduct} value={entry.noteProduct}>
            {entry.noteProduct}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
