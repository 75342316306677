import { ReactElement } from 'react';

import { portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import { translations } from '@halo-common/translations';
import {
  usePortfolioPositionAggregationQuery,
  usePortfolioPositionAggregationTemplatesQuery,
  usePortfolioPositionsQuery,
  useUserInfoQuery,
} from '@halo-data-sources/queries';
import { CompositionEnum, usePortfolioPositionsDetailData } from '@halo-modules/app';
import { LocalizedTypography, NestedTabModel, NestedTabs } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useAtom } from 'jotai';

const combinedContainerSx = {
  backgroundColor: 'background.default',
  position: 'sticky',
  py: 0,
  pb: 2,
  top: 0,
  zIndex: 'appBar',
  overflowX: 'auto',
};

const filterTitleSx = {
  whiteSpace: 'nowrap',
};

export const PortfolioPositionsTypeFilterBar = (): ReactElement => {
  const [positionsData, setPositionsData] = useAtom(portfolioPositionsManagerAtom);

  const { detailsQuery, query, filters, showAggregate } = positionsData;
  const { composition, noteFeatures } = filters;

  const { data: userInfo } = useUserInfoQuery();

  const positionsQueryEnabled = Boolean(CompositionEnum.Product in detailsQuery.filters && userInfo);
  const positionsQueryOptions = { enabled: positionsQueryEnabled };

  const { data: templateMeta, isPending: templateLoading } = usePortfolioPositionAggregationTemplatesQuery();
  const { data: aggregationMeta, isPending: aggregationLoading } = usePortfolioPositionAggregationQuery(query);
  const { isPending: isPositionInitialLoading } = usePortfolioPositionsQuery(detailsQuery, positionsQueryOptions);
  const { features: featuresMeta } = usePortfolioPositionsDetailData();

  const lexicon = userInfo?.lexicon ?? {};
  const selectedTabs = positionsData.filters.selectedTabs ?? [];
  const templates = templateMeta ?? [];
  const aggregations = aggregationMeta?.aggregations ?? [];
  const features = featuresMeta ?? [];

  const isFilteredByNoteFeatures = noteFeatures.length > 0;
  const isLoadingPositions = positionsQueryEnabled && isPositionInitialLoading;
  const tabsLoading = templateLoading || aggregationLoading || isLoadingPositions;

  const allCount = aggregations.reduce((total, tab) => total + tab.positionCount, 0);
  const localization = { rootLabel: { count: allCount.toString() } };

  const currentTabs = showAggregate
    ? aggregations.map((aggregation) => ({
        name: aggregation.name ? (lexicon[aggregation.name] ?? aggregation.name) : 'N/A',
        value: aggregation.filterKey,
        count: aggregation.positionCount,
      }))
    : !isFilteredByNoteFeatures
      ? features.map((feature) => ({
          name: feature.name ? (lexicon[feature.name] ?? feature.name) : 'N/A',
          value: feature.value,
          count: feature.count,
        }))
      : [];

  const buildFeatureStateUpdate = (tab: NestedTabModel, deselect = false) => {
    const deselectedNoteFeatureIndex = noteFeatures.findIndex((name) => name === tab.value);
    const selectedNoteFeatureIndex = features.findIndex((feature) => feature.value === tab.value);

    const isDeselectingNoteFeature = deselectedNoteFeatureIndex > -1;
    const isSelectingNoteFeature = selectedNoteFeatureIndex > -1;

    if (isSelectingNoteFeature || isDeselectingNoteFeature) {
      return { selectedFeatureTabs: { tab, deselect } };
    }
    const selectedTemplate = templates.find((template) => template.name === composition);
    return { selectedNoteTab: { tab, template: selectedTemplate, deselect } };
  };

  const handleSelectionChange = (tab: NestedTabModel) => {
    const updatedState = buildFeatureStateUpdate(tab);
    setPositionsData(updatedState);
  };

  const handleDeselectionChange = (tab: NestedTabModel) => {
    const updatedState = buildFeatureStateUpdate(tab, true);
    setPositionsData(updatedState);
  };

  return (
    <Stack
      direction={{ md: 'row', xs: 'column' }}
      spacing={2}
      alignItems={{ md: 'center', xs: 'left' }}
      sx={combinedContainerSx}
    >
      <LocalizedTypography sx={filterTitleSx} variant="subtitle1">
        {translations.portfolio.positions.positionsFilterByType}
      </LocalizedTypography>
      <NestedTabs
        loading={tabsLoading}
        rootTabName={translations.portfolio.positions.allTabLabel}
        onSelect={handleSelectionChange}
        onDeselect={handleDeselectionChange}
        current={currentTabs}
        selected={selectedTabs}
        slotProps={{ stack: { flexWrap: { md: 'nowrap', xs: 'wrap' } } }}
        Localization={localization}
      />
    </Stack>
  );
};
