import { CalendarQueryModel } from '@halo-atoms/calendars';
import { useHaloInfiniteQuery } from '@halo-common/hooks';
import { CalendarModel, InfinitePaginationModel, InfiniteQueryOptions } from '@halo-common/models';
import { getCalendarOfferings } from '@halo-data-sources/clients';
import { ApiCalendarsMapper, CalendarsMapper, PaginationMapper } from '@halo-data-sources/mappers';
import { CalendarsQueryKeyFactory } from '@halo-data-sources/queries';
import { keepPreviousData } from '@tanstack/react-query';

export type GetCalendarsInfiniteResult = {
  calendars: Array<CalendarModel>;
  pagination: InfinitePaginationModel;
};

const calendarsInfiniteQueryFn = async (calendarFilters: CalendarQueryModel, pageParam: InfinitePaginationModel) => {
  const paginatedRequest = {
    ...calendarFilters,
    approved: true,
    isVisible: true,
    showAsNME: false,
    page: pageParam.next,
  };

  const mappedFilters = CalendarsMapper.toCalendarsRequest(paginatedRequest);

  const response = await getCalendarOfferings(mappedFilters);

  const mappedResponse = response.calendars.map(ApiCalendarsMapper.toCalendarModel);
  const mappedPagination = PaginationMapper.toInfinitePaginationModel(response.pagination);

  return { calendars: mappedResponse, pagination: mappedPagination };
};

export const useCalendarsInfiniteQuery = (
  payload: CalendarQueryModel,
  options?: InfiniteQueryOptions<GetCalendarsInfiniteResult>,
) =>
  useHaloInfiniteQuery<GetCalendarsInfiniteResult>({
    queryKey: CalendarsQueryKeyFactory.infinite(payload),
    queryFn: ({ pageParam }) => calendarsInfiniteQueryFn(payload, pageParam),
    placeholderData: keepPreviousData,
    staleTime: 0,
    ...options,
  });
