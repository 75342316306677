import { ReactElement } from 'react';

import { useUserInfoQuery } from '@halo-data-sources/queries';
import { EducationHubPercentageCell } from '@halo-modules/admin';
import { Stack } from '@halodomination/halo-fe-common';
import { Box, Typography } from '@mui/material';

const panelListSx = {
  height: 32,
  backgroundColor: 'grey.100',
  borderBottom: '1px solid',
  borderBottomColor: 'grey.300',
};

const positionListItemSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 50,
};

const percentageSx = {
  pl: 2.5,
};

export type EducationHubTableDetailPanelRowProps = {
  id: number;
  title: string;
  percentComplete: number;
};

export const EducationHubTableDetailPanelRow = ({
  id,
  title,
  percentComplete,
}: EducationHubTableDetailPanelRowProps): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();

  const showEducationHubToggles = userInfo?.settings?.showEducationHubToggles;

  const largeSx = { flex: 3, px: 1.25 };
  const smallSx = { flex: 1, px: 1.25 };
  const iconSx = { width: 50 };
  const toggleSx = { width: 150 };
  const elementSx = [iconSx, largeSx, largeSx, smallSx, smallSx, toggleSx, toggleSx];

  const ignoredCellPlaceholder = <Box width="100%" height="100%" />;

  return (
    <Stack direction="row" sx={panelListSx} alignItems="center" elementStyling={elementSx}>
      <Typography variant="body2" sx={positionListItemSx}>
        {id}.
      </Typography>
      <Typography variant="body2">{title}</Typography>
      {ignoredCellPlaceholder}
      <EducationHubPercentageCell sx={percentageSx} percentComplete={percentComplete} />
      {ignoredCellPlaceholder}
      {showEducationHubToggles ? <Box width={150} height="100%" /> : null}
      {showEducationHubToggles ? <Box width={150} height="100%" /> : null}
    </Stack>
  );
};
