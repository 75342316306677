import { ReactElement, useEffect, useState } from 'react';

import { DisclaimerModal } from '@halo-common/modals';
import { DisclaimerOnboardingModel } from '@halo-common/models';
import { navigateParentTo } from '@halo-common/utils';
import { DisclaimerListItem } from '@halo-modules/app';
import { OnboardingActions, OnboardingSelectors } from '@halo-stores/Onboarding';
import { Stack } from '@halodomination/halo-fe-common';
import { Container, Typography, List, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

const containerStyling = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  overflow: 'hidden',
  position: 'relative',
  height: '100%',
  padding: 0,
};
const disclaimerListStyling = { width: '100%' };
const titleStyling = { mt: 0, mb: 3, mx: 0 };
const subtitleStyling = {
  color: 'text.secondary',
  width: '100%',
  align: 'center',
  paddingBottom: 3,
  borderBottom: '1px solid',
  borderColor: 'grey.100',
};
const mainButtonStyling = {
  width: '100%',
  height: 42,
  marginTop: 3,
};

export type DisclaimerListProps = {
  disclaimers: Array<DisclaimerOnboardingModel>;
};

export type DisclaimerAcceptanceSchema = {
  disclaimerId: number;
};

export const DisclaimerList = ({ disclaimers }: DisclaimerListProps): ReactElement => {
  const dispatch = useDispatch();

  const acceptedDisclaimers = useSelector(OnboardingSelectors.selectAcceptedDisclaimers);
  const acceptedDisclaimerKeys = Object.keys(acceptedDisclaimers);
  const acceptedDisclaimerValues = Object.values(acceptedDisclaimers);

  const [selectedDisclaimer, setSelectedDisclaimer] = useState<DisclaimerOnboardingModel | null>(null);
  const open = Boolean(selectedDisclaimer);
  const selectedDisclaimerIsAccepted = selectedDisclaimer
    ? acceptedDisclaimers[selectedDisclaimer.id.toString()]
    : false;

  const [allDisclaimersAccepted, setAllDisclaimersAccepted] = useState(false);

  const handleOpen = (disclaimer: DisclaimerOnboardingModel | null) => {
    setSelectedDisclaimer(disclaimer);
  };

  const handleClose = () => {
    setSelectedDisclaimer(null);
  };

  const handleDisclaimerAcceptance = (id: number) => {
    dispatch(OnboardingActions.acceptDisclaimer({ disclaimerId: id }));
    handleClose();
  };

  const handleFinish = () => {
    dispatch(OnboardingActions.reset);
    navigateParentTo('/');
  };

  useEffect(() => {
    if (acceptedDisclaimerValues.every((value) => value)) {
      setAllDisclaimersAccepted(true);
    } else {
      setAllDisclaimersAccepted(false);
    }
  });

  return (
    <Container sx={containerStyling}>
      <Typography variant="h4" align="center" sx={titleStyling}>
        Please carefully read and agree to the following documents.
      </Typography>
      <Typography variant="subtitle2" align="center" sx={subtitleStyling}>
        You must open each to continue.
      </Typography>
      <List sx={disclaimerListStyling}>
        <Stack direction="column">
          {disclaimers.map((disclaimer) => {
            const acceptedDisclaimerKey = disclaimer.id.toString();
            const acceptedDisclaimerIndex = acceptedDisclaimerKeys.indexOf(acceptedDisclaimerKey);
            const disclaimerIsAccepted = acceptedDisclaimerValues[acceptedDisclaimerIndex];

            return (
              <DisclaimerListItem
                key={acceptedDisclaimerKey}
                disclaimer={disclaimer}
                disclaimerIsAccepted={disclaimerIsAccepted}
                handleOpen={handleOpen}
              />
            );
          })}
        </Stack>
      </List>
      <Button
        sx={mainButtonStyling}
        variant="contained"
        color="primary"
        disabled={!allDisclaimersAccepted}
        onClick={handleFinish}
      >
        Finish
      </Button>
      <DisclaimerModal
        disclaimer={selectedDisclaimer}
        open={open}
        disclaimerIsAccepted={selectedDisclaimerIsAccepted}
        handleDisclaimerAcceptance={handleDisclaimerAcceptance}
        handleClose={handleClose}
      />
    </Container>
  );
};
