import { translations } from '@halo-common/translations';
import { downloadFile } from '@halo-common/utils';
import { downloadPortfolioExcel } from '@halo-data-sources/clients';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

const pendingMsg = translations.portfolio.positions.generatePortfolioReportPendingMessage;
const errorMsg = translations.portfolio.positions.generatePortfolioReportErrorMessage;
const successMsg = translations.portfolio.positions.generatePortfolioReportSuccessMessage;

export type GeneratePortfolioReportOptions = {
  accountId?: number;
  householdId?: number;
  includeInactive?: boolean;
  fileName: string;
  synced?: boolean;
};

const generatePortfolioReportMutationFn = async (options: GeneratePortfolioReportOptions) => {
  const result = await downloadPortfolioExcel({
    account_selector: {
      account_ids: options.accountId ? [options.accountId] : undefined,
      advisee_ids: options.householdId ? [options.householdId] : undefined,
      synced: options.synced ?? null,
    },
    filename: options.fileName,
    include_inactive: options.includeInactive,
  });

  const fileName = result.filename;
  const downloadFilePath = result.tmplink;

  downloadFile({ fileName, downloadFilePath });
};

export const useGeneratePortfolioReportMutation = (): UseMutationResult<
  void,
  Error,
  GeneratePortfolioReportOptions
> => {
  const { enqueueSuccessEvent, enqueuePendingEvent, enqueueWarningEvent, closeSnackbar } = useSnackbar();

  return useMutation<void, Error, GeneratePortfolioReportOptions>({
    mutationFn: generatePortfolioReportMutationFn,
    onMutate: () => {
      enqueuePendingEvent({ persist: true, message: pendingMsg });
    },
    onError: () => {
      closeSnackbar();
      enqueueWarningEvent({ message: errorMsg });
    },
    onSuccess: () => {
      closeSnackbar();
      enqueueSuccessEvent({ message: successMsg });
    },
  });
};
