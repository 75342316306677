import { PortfolioPerformanceReportModel } from '@halo-common/models';
import { ApiPortfolioPerformanceReportModel } from '@halo-data-sources/models';

export const ApiDocGenMapper = {
  toPortfolioPerformanceReport: (model: ApiPortfolioPerformanceReportModel): PortfolioPerformanceReportModel => ({
    id: model.id,
    description: model.description,
    ergId: model.report_ergonomic_id,
    enabled: model.enabled,
    type: model.report_type,
  }),
};
