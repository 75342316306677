import { AllocationStatusEnum } from '@halo-common/enums';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { AllocationModel, CalendarModel } from '@halo-common/models';
import { DateTime } from 'luxon';

export type UseCalendarApprovalStatusesResult = {
  isAccepted: boolean;
  isApproved: boolean;
  isCanceled: boolean;
  isDenied: boolean;
  isExpired: boolean;
  isFilled: boolean;
  isPending: boolean;
  isRejected: boolean;
  needsReview: boolean;
  isPershingPending: boolean;
};

export const useCalendarApprovalStatuses = (
  approved?: AllocationModel['approved'],
  status?: AllocationModel['status'],
  expirationDate?: CalendarModel['expirationDate'],
): UseCalendarApprovalStatusesResult => {
  const configureDateTime = useWhiteLabelDateConfiguration();

  const expirationDateTime = configureDateTime(expirationDate);
  const needsReview = Boolean(expirationDateTime && expirationDateTime.plus({ hours: 1 }) > DateTime.now());

  const isExpired = !needsReview;

  const isCanceled = status === AllocationStatusEnum.canceled;
  const isFilled = status === AllocationStatusEnum.filled;
  const isRejected = status === AllocationStatusEnum.rejected;
  const isAccepted = status === AllocationStatusEnum.accepted;
  const isPending = status === AllocationStatusEnum.pending;

  const isApproved = approved === true;
  const isDenied = approved === false;
  const isPershingPending = isPending && approved === null;

  return {
    isAccepted,
    isApproved,
    isCanceled,
    isDenied,
    isExpired,
    isFilled,
    isPending,
    isRejected,
    needsReview,
    isPershingPending,
  };
};
