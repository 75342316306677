import { DEFAULT_INFINITE_QUERY_NEXT_PAGINATION } from '@halo-common/constants';
import { useHaloInfiniteQuery } from '@halo-common/hooks';
import { CalendarSearchAssetModel, InfinitePaginationModel, InfiniteQueryOptions } from '@halo-common/models';
import { getCalendarsSearchAsset } from '@halo-data-sources/clients';
import { ApiAssetIdentifierEnum } from '@halo-data-sources/enums';
import { ApiCalendarsMapper, PaginationMapper } from '@halo-data-sources/mappers';
import { CalendarsQueryKeyFactory } from '@halo-data-sources/queries';

export type GetCalendarsAssetSearchInfiniteResult = {
  calendars: Array<CalendarSearchAssetModel>;
  pagination: InfinitePaginationModel;
};

export type GetCalendarAssetSearchInfinitePayload = {
  assetIdentifiers: Array<string>;
  assetId: string;
  approved: boolean | null;
  pageLength?: number;
  showAsNME?: boolean | null;
};

const DEFAULT_PAGINATION = {
  ...DEFAULT_INFINITE_QUERY_NEXT_PAGINATION,
  pageLength: 8,
};

const getCalendarAssetSearchQueryFn = async (
  payload: GetCalendarAssetSearchInfinitePayload,
  pageParam: InfinitePaginationModel,
) => {
  const assetIdentifiers = payload.assetIdentifiers.map(
    (assetId: string) => ApiAssetIdentifierEnum[assetId as keyof typeof ApiAssetIdentifierEnum],
  );

  const calendarSearchPayload = {
    asset_id: payload.assetId.toLowerCase(),
    asset_identifiers: assetIdentifiers,
    page: pageParam.next,
    page_length: payload.pageLength,
    filters: {
      approved: payload.approved,
      show_as_nme: payload.showAsNME,
    },
  };

  const response = await getCalendarsSearchAsset(calendarSearchPayload);

  const mappedResponse = response.calendars.map(ApiCalendarsMapper.toCalendarAssetSearchModel);
  const mappedPagination = PaginationMapper.toInfinitePaginationModel(response.pagination);

  return { calendars: mappedResponse, pagination: mappedPagination };
};

export const useCalendarAssetSearchInfiniteQuery = (
  payload: GetCalendarAssetSearchInfinitePayload,
  options?: InfiniteQueryOptions<GetCalendarsAssetSearchInfiniteResult>,
) =>
  useHaloInfiniteQuery<GetCalendarsAssetSearchInfiniteResult>({
    initialPageParam: DEFAULT_PAGINATION,
    queryKey: CalendarsQueryKeyFactory.search(payload),
    enabled: Boolean(payload.assetId),
    queryFn: ({ pageParam }) => getCalendarAssetSearchQueryFn(payload, pageParam),
    ...options,
  });
