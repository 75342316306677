import { calendarManagerAtom, calendarSelectedAtom } from '@halo-atoms/calendars';
import { AllocationStatusEnum } from '@halo-common/enums';
import { AllocationModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { putCalendarAllocationRequest } from '@halo-data-sources/clients';
import { ApiAllocationMapper } from '@halo-data-sources/mappers';
import {
  CalendarsQueryKeyFactory,
  FeaturedCalendarQueryKeyFactory,
  OrderBookCalendarQueryKeyFactory,
  ProductApprovalCalendarResult,
} from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import {
  InfiniteData,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';

export type EditCalendarAllocationMutationOptions = {
  id: number;
  status: string;
  amount?: number;
};

export type UseEditCalendarAllocationMutationOptions = UseMutationOptions<
  AllocationModel | null,
  Error,
  EditCalendarAllocationMutationOptions
>;

export type UseEditCalendarAllocationMutationResult = UseMutationResult<
  AllocationModel | null,
  Error,
  EditCalendarAllocationMutationOptions
>;

const editCalendarAllocationMutation = async (options: EditCalendarAllocationMutationOptions) => {
  if (typeof options.id !== 'number' || !options.status) return null;

  const { id, status, amount } = options;

  const response = await putCalendarAllocationRequest(id.toString(), status, amount);

  return ApiAllocationMapper.toAllocationModel(response.allocation);
};

export const useEditCalendarAllocationMutation = (
  options?: UseEditCalendarAllocationMutationOptions,
): UseEditCalendarAllocationMutationResult => {
  const queryClient = useQueryClient();

  const { query } = useAtomValue(calendarManagerAtom);
  const [selectedCalendar, setSelectedCalendar] = useAtom(calendarSelectedAtom);

  const { enqueueSuccessEvent, enqueueErrorEvent } = useSnackbar();

  return useMutation({
    mutationFn: editCalendarAllocationMutation,
    ...options,
    onSettled: (...props) => {
      const [data, error] = props;
      if (error) enqueueErrorEvent({ message: translations.common.error });
      else if (data) enqueueSuccessEvent({ message: 'Allocation successfully updated.' });
      void queryClient.invalidateQueries({ queryKey: OrderBookCalendarQueryKeyFactory.all() });
      options?.onSettled?.(...props);
    },
    onSuccess: (...props) => {
      const [data] = props;

      options?.onSuccess?.(...props);

      if (!data) return undefined;

      const calendarKey = CalendarsQueryKeyFactory.calendar(data.calendarId);
      void queryClient.invalidateQueries({ queryKey: calendarKey });

      const featuredCalendarKey = FeaturedCalendarQueryKeyFactory.query();
      void queryClient.invalidateQueries({ queryKey: featuredCalendarKey });

      const allocationsKey = CalendarsQueryKeyFactory.allocations(selectedCalendar?.id);
      queryClient.setQueryData<Array<AllocationModel>>(allocationsKey, (prev) => {
        if (!prev) return undefined;
        const updatedAllocations = [...prev];
        const allocationIndex = updatedAllocations.findIndex((allocation) => allocation.id === data.id);
        updatedAllocations.splice(allocationIndex, 1, data);
        return updatedAllocations;
      });

      const calendarsKey = CalendarsQueryKeyFactory.infinite(query);
      queryClient.setQueryData<InfiniteData<ProductApprovalCalendarResult>>(calendarsKey, (prev) => {
        if (!prev) return undefined;

        const { id, calendarId } = data;

        const pageIndex = prev.pages.findIndex(({ calendars }) =>
          calendars.find((calendar) => calendar.id === calendarId),
        );

        const page = prev.pages?.[pageIndex];

        if (!page) return undefined;

        const updatedPageCalendars = [...(page?.calendars ?? [])];
        const calendarIndex = updatedPageCalendars.findIndex((calendar) => calendar.id === calendarId);
        const updatedPageCalendar = updatedPageCalendars[calendarIndex];

        const updatedAllocations = [...updatedPageCalendar.allocations];
        const allocationIndex = updatedAllocations.findIndex((allocation) => allocation.id === id);
        const updatedAllocation = { ...updatedAllocations[allocationIndex], ...data };
        updatedAllocations.splice(allocationIndex, 1, updatedAllocation);

        const isCancelled = (data.status as AllocationStatusEnum) === AllocationStatusEnum.canceled;

        const updatedCalendar = {
          ...updatedPageCalendar,
          allocations: updatedAllocations,
          activeAllocationCount: isCancelled
            ? updatedPageCalendar.activeAllocationCount - 1
            : updatedPageCalendar.activeAllocationCount,
        };

        updatedPageCalendars.splice(calendarIndex, 1, updatedCalendar);

        const updatedPage = { ...page, calendars: updatedPageCalendars };
        const updatedPages = [...prev.pages];
        updatedPages.splice(pageIndex, 1, updatedPage);

        setSelectedCalendar(updatedCalendar);

        return { ...prev, pages: updatedPages };
      });
    },
  });
};
