/* eslint-disable @typescript-eslint/no-explicit-any */

export class HttpValidationError extends Error {
  status: number;
  details: any;

  constructor(status: number, message: string, details: any) {
    super(message);
    this.status = status;
    this.name = `HttpValidationError (${status})`;
    this.details = details;
  }
}

/* eslint-enable @typescript-eslint/no-explicit-any */
