import {
  DocusignBankTypeEnum,
  DocusignBooleanEnum,
  DocusignComplianceEnum,
  DocusignExperienceEnum,
  DocusignRegulatorEnum,
} from '@halo-common/enums/docusign';
import { translations } from '@halo-common/translations';
import { DateTime } from 'luxon';
import * as yup from 'yup';

const fromEnum = <T extends object>(enumObj: T) =>
  yup
    .string()
    .transform((value) => value || null)
    .oneOf([...Object.values(enumObj), null]);

const yesNo = fromEnum(DocusignBooleanEnum);

const regulator = fromEnum(DocusignRegulatorEnum);

const experienceInYears = fromEnum(DocusignExperienceEnum);

const complianceType = fromEnum(DocusignComplianceEnum);

const bankType = fromEnum(DocusignBankTypeEnum);

export const validIsoDate = yup
  .mixed()
  .nullable()
  .test('is-valid-date', translations.messages.invalidDate, (value) => {
    if (value === null || value === undefined) return true;
    if (typeof value === 'string') return true;
    if (value instanceof DateTime && value.isValid) return true;
    return false;
  })
  .transform((value) => {
    if (value instanceof DateTime && value.isValid) return value.toISODate();
    return value;
  });

const authorizedPerson = yup.object().shape({
  crd_number: yup.string().nullable(),
  date_of_birth: validIsoDate,
  name: yup.string().nullable(),
  position: yup.string().nullable(),
});

const authorizedSigner = authorizedPerson.shape({
  email_address: yup.string().email(translations.messages.validEmail).nullable(),
});

const address = yup.object().shape({
  apt_no: yup.string().nullable(),
  city: yup.string().nullable(),
  country: yup.string().nullable(),
  state: yup.string().nullable(),
  street: yup.string().nullable(),
  zip_code: yup
    .string()
    .transform((value) => value || null)
    .matches(/^\d{5}$/, translations.onboarding.suitability.zipCodeMustBeExactly5Digits)
    .nullable(),
});

const contact = yup.object().shape({
  email_address: yup.string().email(translations.messages.validEmail).nullable(),
  name: yup.string().nullable(),
  phone_number: yup
    .string()
    .transform((value) => value || null)
    .matches(/^\d{7,}$/, translations.onboarding.suitability.phoneNumberMustBeExactly7Digits)
    .nullable(),
  position: yup.string().nullable(),
});

const compliance = yup.object().shape({
  aml_policies_radio: yesNo.nullable(),
  compliance_type_radio: complianceType.nullable(),
  compliance_type_outsourced_text: yup.string().nullable(),
  kyc_aml_delegation_radio: yesNo.nullable(),
  sanctions_radio: yesNo.nullable(),
  sanctions_yes_text: yup.string().nullable(),
  suitability_assessment_radio: yesNo.nullable(),
  training_radio: yesNo.nullable(),
});

const firmDetails = yup.object().shape({
  authorized_persons: yup
    .object()
    .shape({
      authorized_signer: authorizedSigner.nullable(),
      authorized_person_2: authorizedPerson.nullable(),
      authorized_person_3: authorizedPerson.nullable(),
      authorized_person_4: authorizedPerson.nullable(),
    })
    .nullable(),
  entity_type_checkbox_broker: yup.boolean().nullable(),
  entity_type_checkbox_family_office: yup.boolean().nullable(),
  entity_type_checkbox_bank: yup.boolean().nullable(),
  entity_type_bank_type_radio: bankType.nullable(),
  entity_type_checkbox_ria: yup.boolean().nullable(),
  experience_years_radio: experienceInYears.nullable(),
  is_firm_regulated_radio: yesNo.nullable(),
  legal_name: yup.string().nullable(),
  mailing_address: address.nullable(),
  permanent_address: address.nullable(),
  previous_name: yup.string().nullable(),
  principal_contacts: yup
    .object()
    .shape({
      contact_1: contact.nullable(),
      contact_2: contact.nullable(),
    })
    .nullable(),
  registration_number: yup.string().nullable(),
  regulator_radio: regulator.nullable(),
  regulator_yes_other_text: yup.string().nullable(),
  structure_checkbox_c_corp: yup.boolean().nullable(),
  structure_checkbox_llc: yup.boolean().nullable(),
  structure_checkbox_other: yup.boolean().nullable(),
  structure_other_text: yup.string().nullable(),
  structure_checkbox_partnership: yup.boolean().nullable(),
  structure_checkbox_s_corp: yup.boolean().nullable(),
  structure_checkbox_trust: yup.boolean().nullable(),
  tax_id_or_ssn: yup.string().nullable(),
});

const traidingDetails = yup.object().shape({
  completion_with_rvp_radio: yesNo.nullable(),
  delivery_process_checkbox_prime_brokerage: yup.boolean().nullable(),
  delivery_process_checkbox_dvp_rvp: yup.boolean().nullable(),
  delivery_process_text: yup.string().nullable(),
  disable_paper_confirmations_checkbox: yup.boolean().nullable(),
  disable_paper_statements_checkbox: yup.boolean().nullable(),
  performance_review_radio: yesNo.nullable(),
  product_approval_process_radio: yesNo.nullable(),
});

export const suitabilityFormSchema = (requireAuthorizedSigner = false): yup.AnyObjectSchema =>
  yup
    .object()
    .shape({
      compliance: compliance,
      firm_details: firmDetails,
      trading_details: traidingDetails,
    })
    .test('require-signer', translations.onboarding.suitability.pleaseEnterAtLeastOneAuthorizedSigner, (value) => {
      if (requireAuthorizedSigner) {
        const authorizedSigner = value?.firm_details?.authorized_persons?.authorized_signer;
        const hasValidSigner = !!authorizedSigner && !!authorizedSigner.name && !!authorizedSigner.email_address;
        return hasValidSigner;
      }
      return true;
    });
