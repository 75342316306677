import { fetchBAAIntegrationSession } from '@halo-data-sources/clients';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

const startCustodianIntegrationFn = async () => {
  const response = await fetchBAAIntegrationSession();

  const { application_url, session_id, csrf_token } = response;

  const sso = window.open('about:blank', 'myChild');

  const html = `
    <html>
      <head>
        <title>BAA SSO</title>
      </head>
      <body>
        <form id="baaForm" method="post" action="${application_url}">
          <input type="hidden" name="SESSION_ID" value="${session_id}" />
          <input type="hidden" name="CSRF_TOKEN" value="${csrf_token}" />
        </form>
        <script>
          const form = document.getElementById("baaForm");
          form.submit();
        </script>
      </body>
    </html>
  `;

  sso?.document.write(html);
};

export const useCustodianIntegrationMutation = (): UseMutationResult<void, Error, void> =>
  useMutation<void, Error, void>({
    mutationFn: startCustodianIntegrationFn,
  });
