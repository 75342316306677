import { ReactElement } from 'react';

import { Stack } from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';

export type OrderTicketSuccessFooterProps = {
  onClose: () => void;
};

export const OrderTicketSuccessFooter = ({ onClose }: OrderTicketSuccessFooterProps): ReactElement => (
  <Stack direction="row" justify="flex-end">
    <Button type="button" variant="contained" color="primary" onClick={onClose}>
      Close
    </Button>
  </Stack>
);
