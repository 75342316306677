import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type AnnuityQueryKeys = {
  all: QueryKeyFactoryFn;
  fetch: QueryKeyFactoryFn;
};

export const AnnuityQueryKeyFactory: AnnuityQueryKeys = {
  all: () => ['annuity'],
  fetch: (id: number) => [...AnnuityQueryKeyFactory.all(), id],
};
