import { modalAtom } from '@halo-atoms/common';
import { portfolioFiltersAtom } from '@halo-atoms/portfolio';
import { SortModelDirectionEnum, SortModelNullSortEnum } from '@halo-common/enums';
import { AccountModel, PaginationModel, SortModel } from '@halo-common/models';
import { PortfolioAccountQueryModel } from '@halo-data-sources/queries';
import { ChipModel, createStepAtoms } from '@halodomination/halo-fe-common';
import { atom } from 'jotai';

export type PortfolioAccountManagerPayload = {
  sort?: Array<SortModel>;
  pagination?: Partial<PaginationModel>;
};

export type AddPositionsToAccountModelAtomPayload = {
  account?: AccountModel | null;
  open?: boolean;
  addedAssets?: Array<ChipModel>;
  removedAssets?: Array<ChipModel>;
};

export type AccountModalAtomPayload = {
  account?: AccountModel | null;
  open?: boolean;
};

export type AddCustodianPositionsAtomPayload = {
  open?: boolean;
  name?: string | null;
  link?: string | null;
};

const DEFAULT_SORT = [
  {
    field: 'advisee_name',
    direction: SortModelDirectionEnum.asc,
    nulls: SortModelNullSortEnum.first,
  },
];

const _accountTableSortAtom = atom<Array<SortModel>>(DEFAULT_SORT);
const _accountTablePaginationAtom = atom<PaginationModel>({
  page: 1,
  resultsPerPage: 25,
  totalResults: 0,
  totalPages: 0,
});

const _selectedCustodianNameAtom = atom<string | null>(null);
const _selectedCustodianLinkAtom = atom<string | null>(null);

export const ADD_CUSTODIAN_POSITIONS_MODAL = 'add_custodian_positions_modal';
export const addCustodianPositionsAtom = atom(
  (get) => ({
    showModal: get(modalAtom)[ADD_CUSTODIAN_POSITIONS_MODAL],
    selectedCustodian: {
      name: get(_selectedCustodianNameAtom),
      link: get(_selectedCustodianLinkAtom),
    },
  }),
  (_, set, payload?: AddCustodianPositionsAtomPayload) => {
    if (!payload) {
      set(modalAtom, { [ADD_CUSTODIAN_POSITIONS_MODAL]: false });
      set(_selectedCustodianNameAtom, null);
      set(_selectedCustodianLinkAtom, null);
    }

    if (payload?.open !== undefined) set(modalAtom, { [ADD_CUSTODIAN_POSITIONS_MODAL]: payload.open });
    if (payload?.name !== undefined) set(_selectedCustodianNameAtom, payload.name);
    if (payload?.link !== undefined) set(_selectedCustodianLinkAtom, payload.link);
  },
);

const _selectedAccountAtom = atom<AccountModel | null>(null);
const addAccountModalStepMap = createStepAtoms({});

export const ACCOUNT_MODAL = 'accountModal';
export const accountModalAtom = atom(
  (get) => {
    const selectedAccountAtom = get(_selectedAccountAtom);
    const modalMap = get(modalAtom);

    return { modalStepMap: { ...addAccountModalStepMap }, selectedAccountAtom, open: modalMap[ACCOUNT_MODAL] };
  },
  (_, set, payload?: AccountModalAtomPayload) => {
    if (!payload) {
      set(_selectedAccountAtom, null);
      set(modalAtom, { [ACCOUNT_MODAL]: false });
    } else {
      const updatedOpen = Boolean(payload.open);
      const updatedAccount = payload?.account ?? null;
      set(_selectedAccountAtom, updatedAccount);
      set(modalAtom, { [ACCOUNT_MODAL]: updatedOpen });
    }
  },
);

const _addPositionsToAccountAtom = atom<AccountModel | null>(null);

const addPositionsToAccountStepMap = createStepAtoms({});
const _selectedAssetsAtom = atom<Array<ChipModel>>([]);

export const ADD_POSITIONS_ACCOUNT_MODAL = 'addPositionsToAccount';
export const addPositionsToAccountModalAtom = atom(
  (get) => {
    const selectedAccountAtom = get(_addPositionsToAccountAtom);
    const selectedAssetsAtom = get(_selectedAssetsAtom);
    const modalMap = get(modalAtom);

    return {
      modalStepMap: { ...addPositionsToAccountStepMap },
      selectedAccountAtom,
      selectedAssetsAtom,
      open: modalMap[ADD_POSITIONS_ACCOUNT_MODAL],
    };
  },
  (_, set, payload?: AddPositionsToAccountModelAtomPayload) => {
    if (!payload) {
      set(_selectedAssetsAtom, []);
      set(_addPositionsToAccountAtom, null);
      set(modalAtom, { [ADD_POSITIONS_ACCOUNT_MODAL]: false });
    }

    if (payload?.addedAssets) set(_selectedAssetsAtom, (prev) => [...prev, ...(payload.addedAssets ?? [])]);
    else if (payload?.removedAssets) set(_selectedAssetsAtom, payload.removedAssets);

    if (payload?.account !== undefined) set(_addPositionsToAccountAtom, payload.account);
    if (payload?.open !== undefined) set(modalAtom, { [ADD_POSITIONS_ACCOUNT_MODAL]: payload.open });
  },
);

export const portfolioAccountManagerAtom = atom(
  (get) => {
    const portfolioFilters = get(portfolioFiltersAtom);
    const accountTableSorting = get(_accountTableSortAtom);
    const accountTablePagination = get(_accountTablePaginationAtom);

    const accountId = portfolioFilters.accountOption?.account?.id;
    const householdId = portfolioFilters.accountOption?.household?.id;
    const synced = portfolioFilters.synced;
    const currency = portfolioFilters.currency;

    const sort = accountTableSorting.map((sortModel) => ({
      field: sortModel.field,
      sort: sortModel.direction,
    }));

    const query: PortfolioAccountQueryModel = {
      page: accountTablePagination.page,
      pageLength: accountTablePagination.resultsPerPage,
      sort: accountTableSorting,
      accountIds: accountId ? [accountId] : undefined,
      householdIds: householdId ? [householdId] : undefined,
      currencyId: currency.id,
      synced,
    };

    return { sort, pagination: accountTablePagination, filters: portfolioFilters, query };
  },
  (_, set, payload?: PortfolioAccountManagerPayload) => {
    if (!payload) set(_accountTableSortAtom, DEFAULT_SORT);
    else if (payload.sort) set(_accountTableSortAtom, payload.sort);
    else if (payload.pagination) set(_accountTablePaginationAtom, (prev) => ({ ...prev, ...payload.pagination }));
  },
);
