import { modalAtom } from '@halo-atoms/common';
import { debouncedAtom } from '@halo-atoms/debounce';
import { portfolioFiltersAtom } from '@halo-atoms/portfolio';
import { ProductTypeEnum, SortModelDirectionEnum, SortModelNullSortEnum } from '@halo-common/enums';
import {
  AccountModel,
  AssetPositionModel,
  PaginationModel,
  PortfolioPositionTemplate,
  SortModel,
} from '@halo-common/models';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { CompositionEnum } from '@halo-modules/app/portfolio';
import { GridSortModel, NestedTabModel, createStepAtoms } from '@halodomination/halo-fe-common';
import { atom } from 'jotai';

export type PortfolioPositionsFiltersModel = {
  composition: CompositionEnum;
  groupBy: string | null;
  includeInactive: boolean;
  productId?: number;
  productType?: string;
  selectedTabs: Array<NestedTabModel>;
  templateFilterIndex: number;
  noteFeatures: Array<string>;
  templateFilters: {
    [key: string]: string | number;
  };
};

export type PortfolioSelectedPositionModel = {
  type: ProductTypeEnum;
  id: number;
  contractId?: string;
  migrated?: boolean;
  currencyId?: number;
};

export type PositionToMultipleAccountsPayload = {
  asset?: AssetPositionModel | null;
  open?: boolean;
  hideBackButtonOnStep?: number;
};

export type PortfolioNoteTabSelectionUpdatePayload = {
  deselect?: boolean;
  tab: NestedTabModel;
  template?: PortfolioPositionTemplate;
};

export type PortfolioFeatureTabSelectionUpdatePayload = {
  deselect?: boolean;
  tab: NestedTabModel;
};

export type PortfolioPositionFilterManagerPayload = {
  asset?: AssetPositionModel | null;
  composition?: PortfolioPositionsFiltersModel['composition'];
  includeInactive?: PortfolioPositionsFiltersModel['includeInactive'];
  selectedFeatureTabs?: PortfolioFeatureTabSelectionUpdatePayload;
  selectedNoteTab?: PortfolioNoteTabSelectionUpdatePayload;
  sorting?: { allPositions: GridSortModel };
  selectedPosition?: PortfolioSelectedPositionModel;
  positionDetailsSort?: Array<SortModel>;
  positionDetailsPagination?: PaginationModel;
};

export type PortfolioPositionQueryModel = {
  accountIds?: Array<number>;
  composition: string;
  householdIds?: Array<number>;
  features: Array<string>;
  filters: PortfolioPositionsFiltersModel['templateFilters'];
  includeInactive: PortfolioPositionsFiltersModel['includeInactive'];
  reportingCurrencyId: number;
  synced: boolean | null;
};

export type PortfolioPositionDetailsQueryModel = PortfolioPositionQueryModel & {
  sort: Array<SortModel>;
  page: number;
  pageLength: number;
};

export type PortfolioPostitionsColumnWidthDictionary = Record<string, Record<string, number | undefined>>;
export type ColumnWidthDictionaryUpdatePayload = { name: string; entry: Record<string, number | undefined> };

const DEFAULT_PORTFOLIO_POSITIONS_FILTERS: PortfolioPositionsFiltersModel = {
  composition: 'template_fullname' as CompositionEnum,
  groupBy: null,
  includeInactive: false,
  noteFeatures: [],
  selectedTabs: [],
  templateFilterIndex: 0,
  templateFilters: {},
};

const DEFAULT_ALL_POSITIONS_TABLE_SORTING: Array<SortModel> = [
  { field: 'template_display_name', direction: SortModelDirectionEnum.asc, nulls: SortModelNullSortEnum.first },
  { field: 'short_name', direction: SortModelDirectionEnum.asc, nulls: SortModelNullSortEnum.first },
];

const DEFAULT_DETAILS_TABLE_SORTING: Array<SortModel> = [
  { field: 'product_type', direction: SortModelDirectionEnum.asc, nulls: SortModelNullSortEnum.first },
];

const DEFAULT_DETAILS_TABLE_PAGINATION: PaginationModel = {
  page: 1,
  resultsPerPage: 20,
  totalResults: 0,
  totalPages: 0,
};

const ALL_POSITION_SORT_FIELD_MAP: Record<string, string> = {
  cusip: 'cusip',
  isin: 'isin',
  fundserv: 'fundservcode',
  shortName: 'short_name',
  issuerName: 'issuer_name',
  notional: 'notional',
  valuation: 'valuation',
  pctBreach: 'percent_to_breach',
  costBasis: 'cost_basis',
  noteReturn: 'note_return',
  marketChange: 'market_change',
  marketValue: 'market_value',
  maturityDate: 'maturity_date',
  templateDisplayName: 'template_display_name',
  underlyingReturn: 'underlying_return',
};

const _assetAtom = atom<AssetPositionModel | null>(null);
const _allPositionsSortingAtom = atom<Array<SortModel>>(DEFAULT_ALL_POSITIONS_TABLE_SORTING);
const { debouncedValueAtom, currentValueAtom } = debouncedAtom<string | null>(null);

const _positionDetailsSort = atom<Array<SortModel>>(DEFAULT_DETAILS_TABLE_SORTING);

const _positionDetailsPagination = atom<PaginationModel>(DEFAULT_DETAILS_TABLE_PAGINATION);

export const currentAssetIdSearchAtom = currentValueAtom;
export const debouncedAssetIdSearchAtom = debouncedValueAtom;
export const assetIdSearchValueAtom = atom<string | null>(null);

const _selectedPositionAtom = atom<PortfolioSelectedPositionModel | undefined>(undefined);

export const POSITIONS_DETAILS_MODAL = 'positionDetailsModal';
export const portfolioPositionDetailsModalAtom = atom(
  (get) => {
    const selectedPositionAtom = get(_selectedPositionAtom);
    const modalMap = get(modalAtom);

    return { modalMap, selectedPositionAtom };
  },
  (get, set, selectedPosition?: PortfolioSelectedPositionModel) => {
    const modalAtomMap = get(modalAtom);
    set(_selectedPositionAtom, selectedPosition);
    set(modalAtom, { [POSITIONS_DETAILS_MODAL]: !modalAtomMap[POSITIONS_DETAILS_MODAL] });
    if (!selectedPosition) set(portfolioPositionPopoverAtom);
  },
);

export const addPositionSelectedAccountsAtom = atom<Array<AccountModel>>([]);
export const addPositionsAssetSubmittedAtom = atom<boolean>(false);
const _addPositionToMultipleAccounts = atom<AssetPositionModel | null>(null);
const addPositionToMultipleAccountStepMap = createStepAtoms({});
const _hideBackButtonOnStepAtom = atom<number | undefined>(undefined);

export const ADD_POSITION_TO_MULTIPLE_ACCOUNTS_NAME = 'addPositionToAccounts';
export const addPositionToMultipleAccountModalAtom = atom(
  (get) => {
    const selectedAssetAtom = get(_addPositionToMultipleAccounts);
    const modalMap = get(modalAtom);
    const hideBackButtonOnStep = get(_hideBackButtonOnStepAtom);

    return {
      selectedAssetAtom,
      modalStepMap: { ...addPositionToMultipleAccountStepMap },
      open: modalMap[ADD_POSITION_TO_MULTIPLE_ACCOUNTS_NAME],
      hideBackButtonOnStep,
    };
  },
  (_, set, payload?: PositionToMultipleAccountsPayload) => {
    if (!payload) {
      set(debouncedAssetIdSearchAtom, null);
      set(assetIdSearchValueAtom, null);
      set(_addPositionToMultipleAccounts, null);
      set(modalAtom, { [ADD_POSITION_TO_MULTIPLE_ACCOUNTS_NAME]: false });
      set(_hideBackButtonOnStepAtom, undefined);
    }

    if (payload?.asset === null) set(debouncedAssetIdSearchAtom, null);
    if (payload?.asset !== undefined) set(_addPositionToMultipleAccounts, payload.asset);
    if (payload?.open !== undefined) set(modalAtom, { [ADD_POSITION_TO_MULTIPLE_ACCOUNTS_NAME]: payload.open });
    if (payload?.hideBackButtonOnStep !== undefined) set(_hideBackButtonOnStepAtom, payload.hideBackButtonOnStep);
  },
);

const _portfolioAllPositionsSortingAtom = atom(
  (get) => get(_allPositionsSortingAtom),
  (_, set, model: GridSortModel) => {
    const updatedSort = model.map((option) => {
      const newSortField = ALL_POSITION_SORT_FIELD_MAP[option.field] ?? ALL_POSITION_SORT_FIELD_MAP.shortName;
      const newSortDirection = option.sort ? SortModelDirectionEnum[option.sort] : SortModelDirectionEnum.desc;
      return { field: newSortField, direction: newSortDirection, nulls: SortModelNullSortEnum.first };
    });

    set(_allPositionsSortingAtom, updatedSort);
  },
);

const _portfolioPositionsFiltersAtom = atom<PortfolioPositionsFiltersModel>(DEFAULT_PORTFOLIO_POSITIONS_FILTERS);

const _portfolioNoteTypeSelectionUpdateAtom = atom(
  null,
  (get, set, payload: PortfolioNoteTabSelectionUpdatePayload) => {
    const { deselect, template, tab } = payload;

    const filters = get(_portfolioPositionsFiltersAtom);
    const { templateFilters, templateFilterIndex, selectedTabs } = filters;

    set(_assetAtom, null);

    const updatedFilters = { ...filters };

    const compositionTemplateFilters = template?.filters ?? [];

    if (deselect) {
      const templateFilterKeys = Object.keys(templateFilters);
      const templateFilterName = templateFilterKeys.find((key) => templateFilters[key] === tab.value);
      const compositionTemplateGroupingKey = templateFilterName ?? CompositionEnum.Product;

      const previousTraversalIndex = compositionTemplateFilters?.findIndex(
        (filter) => filter.grouping === compositionTemplateGroupingKey,
      );

      const updateFilters = previousTraversalIndex !== -1;
      const updatedFiltersIndex = updateFilters ? previousTraversalIndex : templateFilterIndex;

      const templateFilterSubset = compositionTemplateFilters?.slice(0, updatedFiltersIndex) ?? [];
      const allPreviousTraversals = [...templateFilterSubset];
      const previousGroupNames = allPreviousTraversals.map((next) => next.grouping);
      const updatedFilterFields = previousGroupNames.reduce(
        (acc, groupName) => ({ ...acc, [groupName]: templateFilters[groupName] }),
        {},
      );

      const selectedTabIndex = selectedTabs.findIndex((selectedTab) => selectedTab.value === tab.value);
      const updatedSelectedTabs = selectedTabs.slice(0, selectedTabIndex);

      updatedFilters.templateFilters = updatedFilterFields;
      updatedFilters.templateFilterIndex = updatedFiltersIndex;
      updatedFilters.groupBy = compositionTemplateGroupingKey;
      updatedFilters.noteFeatures = [];
      updatedFilters.selectedTabs = [...updatedSelectedTabs];
    } else {
      const numberOfFilters = compositionTemplateFilters?.length ?? 0;
      const nextFiltersIndex = templateFilterIndex + 1;
      const updateFilters = numberOfFilters > nextFiltersIndex;
      const updatedFiltersIndex = updateFilters ? nextFiltersIndex : templateFilterIndex;

      const filterFieldGroupBy = compositionTemplateFilters?.[updatedFiltersIndex]?.grouping;
      const filterFieldFilterBy = compositionTemplateFilters?.[templateFilterIndex]?.grouping;

      const updatedTemplateFilters = { ...templateFilters, [filterFieldFilterBy]: tab.value };

      updatedFilters.groupBy = filterFieldGroupBy;
      updatedFilters.templateFilters = updatedTemplateFilters;
      updatedFilters.templateFilterIndex = updatedFiltersIndex;
      updatedFilters.selectedTabs = [...selectedTabs, tab];
    }

    set(_portfolioPositionsFiltersAtom, updatedFilters);
  },
);

const _portfolioNoteFeatureSelectionUpdateAtom = atom(
  null,
  (get, set, payload: PortfolioFeatureTabSelectionUpdatePayload) => {
    const { deselect, tab } = payload;

    const filters = get(_portfolioPositionsFiltersAtom);
    const { selectedTabs, noteFeatures } = filters;

    const updatedFilters = { ...filters };
    const deselectedNoteFeatureIndex = deselect ? noteFeatures.findIndex((name) => name === tab.name) : undefined;
    const updatedNoteFeatureTabs = deselect
      ? noteFeatures.slice(0, deselectedNoteFeatureIndex)
      : [...noteFeatures, tab.value.toString()];
    const deselectedIndex = deselect ? selectedTabs.findIndex(({ name }) => name === tab.name) : undefined;
    const updatedTabs = deselect ? selectedTabs.slice(0, deselectedIndex) : [...selectedTabs, tab];
    updatedFilters.selectedTabs = updatedTabs;
    updatedFilters.noteFeatures = updatedNoteFeatureTabs;

    if (!updatedFilters.selectedTabs.length) set(_assetAtom, null);

    set(_portfolioPositionsFiltersAtom, updatedFilters);
  },
);

export const portfolioPositionsManagerAtom = atom(
  (get) => {
    const asset = get(_assetAtom);
    const portfolioFilters = get(portfolioFiltersAtom);
    const allPositionsSorting = get(_allPositionsSortingAtom);
    const positionFilters = get(_portfolioPositionsFiltersAtom);
    const selectedPosition = get(_selectedPositionAtom);
    const positionDetailsSort = get(_positionDetailsSort);
    const pagination = get(_positionDetailsPagination);

    const { groupBy, templateFilters, composition, includeInactive, noteFeatures } = positionFilters;

    const currencyId = portfolioFilters.currency?.id;
    const accountId = portfolioFilters.accountOption?.account?.id;
    const householdId = portfolioFilters.accountOption?.household?.id;
    const synced = portfolioFilters.synced;
    const accountIds = accountId ? [accountId] : undefined;
    const householdIds = householdId ? [householdId] : undefined;
    const group = groupBy ?? composition;
    const showAggregate = !(CompositionEnum.Product in templateFilters);

    const sorting = {
      allPositions: allPositionsSorting,
    };

    const query: PortfolioPositionQueryModel = {
      accountIds,
      householdIds,
      reportingCurrencyId: currencyId,
      filters: templateFilters,
      includeInactive,
      features: noteFeatures,
      composition: group,
      synced,
    };

    const detailsQuery: PortfolioPositionDetailsQueryModel = {
      ...query,
      sort: positionDetailsSort,
      page: pagination.page,
      pageLength: pagination.resultsPerPage,
    };

    const filters = {
      ...positionFilters,
      ...portfolioFilters,
    };

    return { asset, showAggregate, filters, query, sorting, selectedPosition, detailsQuery, pagination };
  },
  (get, set, payload?: PortfolioPositionFilterManagerPayload) => {
    const filters = get(_portfolioPositionsFiltersAtom);

    if (!payload) {
      set(_assetAtom, null);
      set(_portfolioPositionsFiltersAtom, DEFAULT_PORTFOLIO_POSITIONS_FILTERS);
    } else if (payload.composition) {
      set(_portfolioPositionsFiltersAtom, { ...DEFAULT_PORTFOLIO_POSITIONS_FILTERS, composition: payload.composition });
    } else if (typeof payload.includeInactive === 'boolean') {
      set(_portfolioPositionsFiltersAtom, { ...filters, includeInactive: payload.includeInactive });
    } else if (payload.selectedFeatureTabs) {
      set(_portfolioNoteFeatureSelectionUpdateAtom, payload.selectedFeatureTabs);
      set(_positionDetailsPagination, DEFAULT_DETAILS_TABLE_PAGINATION);
    } else if (payload.selectedNoteTab) {
      if (payload.selectedNoteTab.deselect) set(_positionDetailsSort, DEFAULT_DETAILS_TABLE_SORTING);
      set(_positionDetailsPagination, DEFAULT_DETAILS_TABLE_PAGINATION);
      set(_portfolioNoteTypeSelectionUpdateAtom, payload.selectedNoteTab);
    } else if (payload.sorting?.allPositions) {
      set(_portfolioAllPositionsSortingAtom, payload.sorting.allPositions);
    } else if (payload.asset) {
      const selectedTab = {
        count: 1,
        name: payload.asset.displayName,
        value: payload.asset.templateName,
      };

      const updatedTemplateFilters = {
        template_fullname: payload.asset.templateName,
        product_id: payload.asset.id,
        product_type: payload.asset.productType,
      };

      set(_assetAtom, payload.asset);
      set(_portfolioPositionsFiltersAtom, {
        ...DEFAULT_PORTFOLIO_POSITIONS_FILTERS,
        groupBy: CompositionEnum.Product,
        includeInactive: !payload.asset.isActive,
        templateFilters: updatedTemplateFilters,
        selectedTabs: [selectedTab],
      });
    } else if (payload.selectedPosition) {
      set(_selectedPositionAtom, payload.selectedPosition);
    } else if (payload.positionDetailsSort) {
      set(_positionDetailsSort, payload.positionDetailsSort);
    } else if (payload.positionDetailsPagination) {
      set(_positionDetailsPagination, (prev) => ({ ...prev, ...payload.positionDetailsPagination }));
    }

    if (payload?.composition === CompositionEnum.Product) {
      set(_assetAtom, null);
    }
  },
);

const _portfolioPositionsColumnWidthAtom = atom<PortfolioPostitionsColumnWidthDictionary>({});

export const portfolioPositionsColumnWidthAtom = atom(
  (get) => get(_portfolioPositionsColumnWidthAtom),
  (get, set, payload: ColumnWidthDictionaryUpdatePayload) => {
    const currentColumnDictMap = get(_portfolioPositionsColumnWidthAtom);
    const columnDict = currentColumnDictMap[payload.name] ?? {};
    set(_portfolioPositionsColumnWidthAtom, {
      ...currentColumnDictMap,
      [payload.name]: { ...columnDict, ...payload.entry },
    });
  },
);

type portfolioPositionPopoverAtomModel = {
  termsheetId?: number;
  notePositionId?: number;
  accountOption?: AccountTypeAheadOption;
  notional?: number;
};

const _portfolioPositionPopoverTermsheetId = atom<number | null>(null);
const _portfolioPositionPopoverNotePositionId = atom<number | null>(null);
const _portfolioPositionPopoverAccountOption = atom<AccountTypeAheadOption | null>(null);
const _portfolioPositionPopoverAllocationAmount = atom<number | null>(null);

export const portfolioPositionPopoverAtom = atom(
  (get) => {
    return {
      termsheetId: get(_portfolioPositionPopoverTermsheetId),
      notePositionId: get(_portfolioPositionPopoverNotePositionId),
      accountOption: get(_portfolioPositionPopoverAccountOption),
      notional: get(_portfolioPositionPopoverAllocationAmount),
    };
  },
  (get, set, payload?: portfolioPositionPopoverAtomModel) => {
    if (payload?.termsheetId) set(_portfolioPositionPopoverTermsheetId, payload?.termsheetId);
    if (payload?.notePositionId) set(_portfolioPositionPopoverNotePositionId, payload?.notePositionId);
    if (payload?.accountOption) set(_portfolioPositionPopoverAccountOption, payload?.accountOption);
    if (payload?.notional) set(_portfolioPositionPopoverAllocationAmount, payload?.notional);
    if (!payload) {
      set(_portfolioPositionPopoverTermsheetId, null);
      set(_portfolioPositionPopoverNotePositionId, null);
      set(_portfolioPositionPopoverAccountOption, null);
      set(_portfolioPositionPopoverAllocationAmount, null);
    }
  },
);
