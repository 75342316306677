export const DASHBOARD_EDUCATION_TRANSLATIONS = {
  displaysIfYouHaveAnyIncompleteCourses: 'Displays if you have any incomplete education courses.',
  navTitle: 'Education Center',
  courses: 'Courses',
  glossaryTitle: 'Glossary',
  overallProgress: 'Overall Progress:',
  searchEducationCenter: 'Search Education Center',
  noGlossaryResult: 'No Glossary Results',
  educationPlaceholder: 'Search Glossary Term',
  education: 'Education',
  educationCourses: 'Education Courses',
  startCourse: 'Start Course',
  reviewCourse: 'Review Course',
  continueCourse: 'Continue Course',
};
