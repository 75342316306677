import { translations } from '@halo-common/translations';
import { OnboardingLayout } from '@halo-modules/app/v2/onboarding';
import { Iconography, LocalizedTypography, PageComponent, PageMeta } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';
import { useRouter } from 'next/router';

const ApprovalRequiredPage: PageComponent = () => {
  const router = useRouter();

  const email = (router.query.email ?? '') as string;

  const emailDynamicContent = { email };

  const fallback = [
    <Skeleton key="approvalRequiredContent1" width={40} height={40} />,
    <Skeleton key="approvalRequiredContent2" width="100%" height={36} />,
    <Skeleton key="approvalRequiredContent3" width="100%" height={36} />,
  ];

  return (
    <OnboardingLayout variant="small" fallback={fallback}>
      <Iconography color="common.black" iconName="envelope-open-text" prefix="fal" size="3x" />
      <LocalizedTypography mt={2} variant="h4">
        {translations.onboarding.recoverPassword.registrationApproval}
      </LocalizedTypography>
      <LocalizedTypography
        textAlign="center"
        mt={1}
        variant="subtitle2"
        color="text.secondary"
        dynamicContent={emailDynamicContent}
      >
        {translations.onboarding.recoverPassword.registrationApprovalText}
      </LocalizedTypography>
    </OnboardingLayout>
  );
};

export const ApprovalRequiredMeta: PageMeta = {
  pageName: 'ApprovalRequired',
  route: '/app/v2/onboarding/approval-required',
  iconName: 'mailbox',
};

ApprovalRequiredPage.pageName = ApprovalRequiredMeta.pageName;
ApprovalRequiredPage.route = ApprovalRequiredMeta.route;
ApprovalRequiredPage.iconName = ApprovalRequiredMeta.iconName;

export default ApprovalRequiredPage;
