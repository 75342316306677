import { API_ARBITRARY_LENGTH_TO_GET_ALL_DATA, API_ARBITRARY_PAGE_TO_GET_ALL_DATA } from '@halo-common/constants';
import {
  LifecycleAllEventTypeEnum,
  LifecycleEventTypeEnum,
  LifecycleProductTypeEnum,
  NoteTypeEnum,
  PortfolioEventSortDropdownValueEnum,
} from '@halo-common/enums';
import { PortfolioOrderModel } from '@halo-common/models';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { getPortfolioLifecycleEvents } from '@halo-data-sources/clients';
import { PortfolioLifecycleMapper } from '@halo-data-sources/mappers';
import { PDMKeyFactory, PortfolioQueryKeyFactory, UserInfoModel } from '@halo-data-sources/queries';
import { PDMSwitchKeyFactory, PostTradeProductDetailsDataSwitchResult } from '@halo-data-sources/switches';
import { UseQueryResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';

const AUTOCALL_EVENT_TYPES = ['AUTOCALL_OBS', 'ISSUER_CALL_OBS'];

export type PostTradeProductDetailsEarlyRedemptionPayload = {
  dates?: Array<string>;
  accountId?: number;
  householdId?: number;
  returns?: Array<number>;
  user?: UserInfoModel;
  type?: NoteTypeEnum;
};

export type PostTradeAutocallLifecycleEvent = {
  id: string;
  date: string;
  amount?: number;
  status: string;
  level?: number;
  type?: LifecycleEventTypeEnum;
  return?: number;
};

export type PostTradeProductDetailsEarlyRedemptionQueryResult = {
  events?: Array<PostTradeAutocallLifecycleEvent>;
};

export const getTradeProductDetailsEarlyRedemptionQueryFn = async (
  id?: number,
  additionalData?: PostTradeProductDetailsEarlyRedemptionPayload,
): Promise<PostTradeProductDetailsEarlyRedemptionQueryResult | null> => {
  if (!id || !additionalData?.dates || !additionalData?.returns) return null;

  const { dates, accountId, householdId, returns } = additionalData;

  const requestBody = PortfolioLifecycleMapper.toApiLifecycleModel({
    accountId,
    householdId,
    sortField: PortfolioEventSortDropdownValueEnum.DESC_EVENT_DATE,
    eventTypes: LifecycleAllEventTypeEnum.ALL_CALL_OBSERVATION,
    page: API_ARBITRARY_PAGE_TO_GET_ALL_DATA,
    pageLength: API_ARBITRARY_LENGTH_TO_GET_ALL_DATA,
    productId: id,
    productType: LifecycleProductTypeEnum.NOTE,
    isPreviousThirtyDays: false,
    synced: null,
  });

  const response = await getPortfolioLifecycleEvents(requestBody);

  const lifecycleEvents = response.data.map((event) => ({
    id: uuid(),
    date: event.event_date,
    amount: event.paid ? event.cash_flow_amount : undefined,
    paid: event.paid,
    level: event.barrier_pct,
    type: event.event_type,
  }));

  const events = lifecycleEvents.reduce((events: Array<PostTradeAutocallLifecycleEvent>, event) => {
    const isAutocallType = AUTOCALL_EVENT_TYPES.includes(event.type);

    if (!isAutocallType) return events;

    const eventDate = DateTime.fromISO(event.date);
    const inPast = eventDate.startOf('day') < DateTime.now().startOf('day');
    const returnIndex = dates.findIndex((date) => DateTime.fromISO(date).equals(eventDate));
    const returnValue = returns[returnIndex];

    const status = event.paid ? 'called' : inPast ? 'not called' : '';
    const formattedStatus = status.split(' ').map(capitalize).join(' ');

    return [...events, { ...event, status: formattedStatus, return: returnValue }];
  }, []);

  return { events };
};

export const usePostTradeProductDetailsEarlyRedemptionQuery = (
  id?: number,
  accountDetails?: AccountTypeAheadOption | null,
): UseQueryResult<PostTradeProductDetailsEarlyRedemptionQueryResult | null, Error> => {
  const queryClient = useQueryClient();

  const accountId = accountDetails?.account?.id;
  const householdId = accountDetails?.household?.id;

  const detailsKey = PDMSwitchKeyFactory.postTrade(id);
  const details = queryClient.getQueryData<PostTradeProductDetailsDataSwitchResult>(detailsKey);

  const productKey = PortfolioQueryKeyFactory.termsheet(id);
  const product = queryClient.getQueryData<PortfolioOrderModel | null>(productKey);

  const dates = details?.history.dates;
  const returns = details?.history.returns;
  const type = product?.note.type;

  const additionalData = { accountId, householdId, dates, returns, type };

  return useQuery<PostTradeProductDetailsEarlyRedemptionQueryResult | null, Error>({
    queryKey: PDMKeyFactory.earlyRedemptionDetails(id, additionalData),
    queryFn: () => getTradeProductDetailsEarlyRedemptionQueryFn(id, additionalData),
  });
};
