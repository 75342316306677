import { ReactElement } from 'react';

import { StatusChip, StatusChipProps } from '@halo-common/components';
import { OrderBookJanneyStatusDisplayEnum, OrderBookJanneyStatusEnum } from '@halo-common/enums';

const getStatus = (status: OrderBookJanneyStatusEnum): StatusChipProps['status'] => {
  switch (status) {
    case OrderBookJanneyStatusEnum.filled:
      return 'completed';
    case OrderBookJanneyStatusEnum.rejected:
    case OrderBookJanneyStatusEnum.canceled:
      return 'closed';
    default:
      return 'pending';
  }
};

export type OrderBookJanneyStatusProps = {
  value: string;
  message?: string;
};

export const OrderBookJanneyStatus = ({ value, message }: OrderBookJanneyStatusProps): ReactElement => {
  const janneyStatusDisplay = OrderBookJanneyStatusDisplayEnum[value as keyof typeof OrderBookJanneyStatusDisplayEnum];
  const janneyStatus = OrderBookJanneyStatusEnum[value as keyof typeof OrderBookJanneyStatusEnum];

  const status = getStatus(janneyStatus);

  return <StatusChip label={janneyStatusDisplay} status={status} message={message} />;
};
