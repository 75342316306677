import { request, getClientConfiguration } from '@halo-data-sources/clients';
import { ApiFetchHistoricalPerformanceResponseModel } from '@halo-data-sources/models';

const HISTORICAL_PERFORMANCE_V1_PATH = '/issuer/v1/data';

export const submitHistoricalPerformanceSearch = async (
  query: string,
): Promise<ApiFetchHistoricalPerformanceResponseModel> => {
  const clientConfiguration = await getClientConfiguration(HISTORICAL_PERFORMANCE_V1_PATH);

  const response = await request<ApiFetchHistoricalPerformanceResponseModel>(
    `${clientConfiguration.basePath}/stats?${query}`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};
