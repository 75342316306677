import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';
import { QueryKey } from '@tanstack/react-query';

type AdminUserQueryKeys = {
  all: QueryKeyFactoryFn;
  activity: QueryKeyFactoryFn;
  manage: QueryKeyFactoryFn;
};

export const AdminUserQueryKeyFactory: AdminUserQueryKeys = {
  all: (): QueryKey => ['admin', 'users'],
  activity: (payload): QueryKey => [...UserQueryKeyFactory.all(), 'activity', hash(payload)],
  manage: (payload): QueryKey => [...UserQueryKeyFactory.all(), 'manage', hash(payload)],
};

type UserQueryKeys = {
  all: QueryKeyFactoryFn;
  activity: QueryKeyFactoryFn;
  info: QueryKeyFactoryFn;
  files: QueryKeyFactoryFn;
  disclaimers: QueryKeyFactoryFn;
  alerts: QueryKeyFactoryFn;
  search: QueryKeyFactoryFn;
  tools: QueryKeyFactoryFn;
  webHooks: QueryKeyFactoryFn;
};

export const UserQueryKeyFactory: UserQueryKeys = {
  all: (): QueryKey => ['users'],
  activity: (query): QueryKey => [...UserQueryKeyFactory.all(), 'activity', hash(query)],
  info: (): QueryKey => [...UserQueryKeyFactory.all(), 'info'],
  files: (): QueryKey => [...UserQueryKeyFactory.all(), 'files'],
  disclaimers: (): QueryKey => [...UserQueryKeyFactory.all(), 'disclaimers'],
  alerts: (): QueryKey => [...UserQueryKeyFactory.all(), 'alerts'],
  search: (query): QueryKey => [...UserQueryKeyFactory.all(), 'search', hash(query)],
  tools: (): QueryKey => [...UserQueryKeyFactory.all(), 'tools'],
  webHooks: (id): QueryKey => [...UserQueryKeyFactory.all(), 'user', id, 'webHooks'],
};
