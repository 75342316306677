import { ReactElement } from 'react';

import { productApprovalEditCloseDateModalAtom, productApprovalManagerAtom } from '@halo-atoms/productApproval';
import { useCommonUserSetting } from '@halo-common/hooks';
import { CalendarModel } from '@halo-common/models';
import { useCalendarVisibilityMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { GridFooter, Iconography, Stack, useGridApiContext } from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';

export const ProductApprovalTableFooter = (): ReactElement => {
  const { query } = useAtomValue(productApprovalManagerAtom);
  const setEditCloseDateModalState = useSetAtom(productApprovalEditCloseDateModalAtom);

  const apiRef = useGridApiContext();

  const { data: userInfo } = useUserInfoQuery();
  const { mutate } = useCalendarVisibilityMutation(query);

  const showEdit = useCommonUserSetting('PA_Edit_Closing_Time', 'Calendar Related Toggles');

  const calendarPageId = userInfo?.organization?.calendarPageId;
  const hasRowSelected = Array.from(apiRef.current.getSelectedRows()?.values())?.length;

  const handleDisableAll = () => {
    const calendarIds = Array.from(apiRef.current.getSelectedRows()?.keys()) as Array<number>;
    const update = { removeCalendarIds: calendarIds };
    mutate({ calendarPageId, ...update });
    apiRef.current.setRowSelectionModel([]);
  };

  const handleEnableAll = () => {
    const calendarIds = Array.from(apiRef.current.getSelectedRows()?.keys()) as Array<number>;
    const update = { addCalendarIds: calendarIds };
    mutate({ calendarPageId, ...update });
    apiRef.current.setRowSelectionModel([]);
  };

  const handleEditAll = () => {
    const keys = apiRef.current.getSelectedRows()?.keys();
    const values = apiRef.current.getSelectedRows()?.values();

    const calendarIds = Array.from(keys) as Array<number>;
    const calendars = Array.from(values) as Array<CalendarModel>;
    const isMultiple = calendarIds?.length > 1;
    const calendar = !isMultiple ? calendars?.[0] : undefined;

    setEditCloseDateModalState({ calendar, calendarIds, open: true, multiple: isMultiple, tableRef: apiRef });
  };

  const editButton = showEdit ? (
    <Button size="extraSmall" variant="text" startIcon={<Iconography iconName="pencil" />} onClick={handleEditAll}>
      Edit
    </Button>
  ) : null;

  const actions = hasRowSelected ? (
    <Stack sx={{ px: 2, pb: 1 }} direction="row" alignItems="center" spacing={1}>
      <Button
        size="extraSmall"
        variant="text"
        startIcon={<Iconography iconName="toggle-on" />}
        onClick={handleEnableAll}
      >
        Enable
      </Button>
      <Button
        size="extraSmall"
        variant="text"
        startIcon={<Iconography iconName="toggle-off" />}
        onClick={handleDisableAll}
      >
        Disable
      </Button>
      {editButton}
    </Stack>
  ) : null;

  return (
    <>
      <GridFooter />
      {actions}
    </>
  );
};
