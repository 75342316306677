import { ReactElement } from 'react';

import { calendarManagerAtom } from '@halo-atoms/calendars';
import { CalendarProductCard, InfiniteScroller } from '@halo-common/components';
import { useCalendarsInfiniteQuery } from '@halo-data-sources/queries';
import { CalendarEmptyResult } from '@halo-modules/app';
import { useCalendarProductCardActions } from '@halo-modules/app/dashboard/hooks';
import { Stack } from '@halodomination/halo-fe-common';
import { CircularProgress } from '@mui/material';
import { useAtomValue } from 'jotai';

const loadingSx = {
  width: '100%',
  mt: 4,
};

export const CalendarsList = (): ReactElement => {
  const { query } = useAtomValue(calendarManagerAtom);

  const {
    data = { pages: [] },
    isFetchingNextPage,
    fetchNextPage,
    isFetching,
    hasNextPage,
  } = useCalendarsInfiniteQuery(query);

  const hasMore = Boolean(hasNextPage);
  const totalResults = data.pages[0]?.pagination.totalResults ?? 0;
  const isEmpty = totalResults === 0;

  const actions = useCalendarProductCardActions();

  const isLoading = isFetching && !isFetchingNextPage;

  if (isLoading) {
    return (
      <Stack sx={loadingSx} direction="row" justify="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (isEmpty) return <CalendarEmptyResult />;

  return (
    <Stack direction="column">
      <InfiniteScroller loading={isFetchingNextPage} hasMore={hasMore} next={fetchNextPage}>
        {data.pages.flatMap((page) =>
          page.calendars.map((calendar) => (
            <CalendarProductCard key={calendar.id} calendar={calendar} loading={isLoading} actions={actions} />
          )),
        )}
      </InfiniteScroller>
    </Stack>
  );
};
