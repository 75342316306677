import { ReactElement, ReactNode } from 'react';

import { navigateParentTo } from '@halo-common/utils';
import {
  Iconography,
  LocalizedButton,
  LocalizedButtonProps,
  LocalizedTypography,
  LocalizedTypographyProps,
} from '@halodomination/halo-fe-common';
import { Box, Stack } from '@mui/material';

import { WidgetOpenCustomizationModalButton } from './WidgetOpenCustomizationModalButton';

type Localization = {
  title?: LocalizedTypographyProps;
  subTitle?: LocalizedTypographyProps;
  actionButton?: LocalizedButtonProps;
};

export interface WidgetProps {
  id?: string;
  title: ReactNode;
  subTitle?: ReactNode;
  filters?: ReactNode;
  children?: ReactNode;
  className?: string;
  navigateButtonHref: string;
  navigateButtonLabel: ReactNode;
  navigateButtonClassName?: string;
  Localization?: Localization;
  showCustomizeButton?: boolean;
}

const headerSx = {
  gap: 2,
  marginBottom: 2,
};

const navigateButtonSx = {
  flex: '0 0 auto',
  ml: 'auto',
};

export const Widget = ({
  id,
  title,
  subTitle,
  filters,
  children,
  className,
  navigateButtonHref,
  navigateButtonLabel,
  navigateButtonClassName,
  Localization,
  showCustomizeButton = true,
}: WidgetProps): ReactElement => {
  const customizeButton = showCustomizeButton ? <WidgetOpenCustomizationModalButton /> : null;

  const widgetTitle = (
    <LocalizedTypography variant="h6" color="text.primary" {...Localization?.title}>
      {title}
    </LocalizedTypography>
  );

  const widgetSubTitle = subTitle ? (
    <LocalizedTypography variant="body1" color="text.secondary" {...Localization?.subTitle}>
      {subTitle}
    </LocalizedTypography>
  ) : null;

  const navigateButton = (
    <LocalizedButton
      variant="text"
      size="small"
      color="primary"
      sx={navigateButtonSx}
      className={navigateButtonClassName}
      endIcon={<Iconography iconName="arrow-right" />}
      onClick={() => navigateParentTo(navigateButtonHref)}
      {...Localization?.actionButton}
    >
      {navigateButtonLabel}
    </LocalizedButton>
  );

  return (
    <Box id={id} className={className}>
      <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={headerSx}>
        {customizeButton}
        {widgetTitle}
        {widgetSubTitle}
        {filters}
        {navigateButton}
      </Stack>
      <Box>{children}</Box>
    </Box>
  );
};
