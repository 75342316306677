import { forwardRef, ReactElement, Ref } from 'react';

import { HaloTheme, HaloThemeColors } from '@halodomination/halo-fe-theme';
import { Chip as MuiChip, SxProps, ChipProps as MuiChipProps } from '@mui/material';

export type ChipColors = HaloThemeColors | 'grey.100' | 'grey.200' | 'grey.300' | 'grey.400' | 'grey.500';

export type ChipographyProps = Omit<MuiChipProps, 'color' | 'sx'> & {
  color?: ChipColors;
  contrastColor?: ChipColors | null;
  sx?: SxProps<HaloTheme>;
};

const ChipographyComponent = (props: ChipographyProps, ref?: Ref<HTMLDivElement>): ReactElement | null => {
  const { color = 'primary', contrastColor = 'primary.background', ...additionalProps } = props;

  const chipSx = {
    margin: 0.375,
    color: contrastColor,
    backgroundColor: color,
    '& .MuiChip-deleteIcon': {
      color: contrastColor,
    },
  };

  return <MuiChip ref={ref} sx={chipSx} color="default" {...additionalProps} />;
};

export const Chipography = forwardRef<HTMLDivElement, ChipographyProps>(ChipographyComponent);
