import { FixedIncomeQueryModel } from '@halo-atoms/fixedIncome';
import { useHaloInfiniteQuery } from '@halo-common/hooks';
import { CalendarModel, InfinitePaginationModel, InfiniteQueryOptions } from '@halo-common/models';
import { getCalendarOfferings } from '@halo-data-sources/clients';
import { ApiCalendarsMapper, CalendarsMapper, PaginationMapper } from '@halo-data-sources/mappers';
import { FixedIncomeQueryKeyFactory } from '@halo-data-sources/queries';

export type GetFixedIncomeInfiniteResult = {
  calendars: Array<CalendarModel>;
  pagination: InfinitePaginationModel;
};

const getFixedIncomeCalendarsQueryFn = async (
  calendarFilters: FixedIncomeQueryModel,
  pageParam: InfinitePaginationModel,
) => {
  const paginatedRequest = {
    ...calendarFilters,
    page: pageParam.next,
  };

  const mappedFilters = CalendarsMapper.toFixedIncomeFilterPayload(paginatedRequest);

  const response = await getCalendarOfferings(mappedFilters);

  const mappedResponse = response.calendars.map(ApiCalendarsMapper.toCalendarModel);
  const mappedPagination = PaginationMapper.toInfinitePaginationModel(response.pagination);

  return { calendars: mappedResponse, pagination: mappedPagination };
};

export const useFixedIncomeCalendarsQuery = (
  payload: FixedIncomeQueryModel,
  options?: InfiniteQueryOptions<GetFixedIncomeInfiniteResult>,
) =>
  useHaloInfiniteQuery<GetFixedIncomeInfiniteResult>({
    queryKey: FixedIncomeQueryKeyFactory.fixedIncomeList(payload),
    queryFn: ({ pageParam }) => getFixedIncomeCalendarsQueryFn(payload, pageParam),
    staleTime: 0,
    ...options,
  });
