import { ReactElement } from 'react';

import { CalendarPointModel } from '@halo-common/models';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Link } from '@mui/material';
import { sanitize } from 'dompurify';

const containerSx = { width: '100%' };
const LINK_KEY = 'link';

export type CalendarPointProps = Omit<CalendarPointModel, 'id' | 'position'>;

export const CalendarPoint = ({ label, value }: CalendarPointProps): ReactElement => {
  if (label.toLowerCase() === LINK_KEY) {
    return (
      <Stack direction="row" sx={containerSx} spacing={2}>
        <LocalizedTypography variant="body1" color="text.secondary">
          {label}
        </LocalizedTypography>
        <Link href={value} rel="noopener" target="_blank">
          {value}
        </Link>
      </Stack>
    );
  }

  const sanitizedValue = sanitize(value);

  return (
    <Stack direction="row" sx={containerSx} spacing={2}>
      <LocalizedTypography variant="body1" color="text.secondary">
        {label}
      </LocalizedTypography>
      <LocalizedTypography variant="body1" fontWeight="bold" dangerouslySetInnerHTML={{ __html: sanitizedValue }} />
    </Stack>
  );
};
