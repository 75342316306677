import { postContactHalo } from '@halo-data-sources/clients';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

export type HaloContactUsMutationModel = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  companyName: string;
  message: string;
};

const contactUsMutationFn = async (payload: HaloContactUsMutationModel) => {
  await postContactHalo(payload);
};

export const useHaloContactUsMutation = (): UseMutationResult<void, Error, HaloContactUsMutationModel> =>
  useMutation<void, Error, HaloContactUsMutationModel>({
    mutationFn: contactUsMutationFn,
  });
