import { ReactElement } from 'react';

import { QueuedTooltip } from '@halo-common/components';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack, SxProps, Typography } from '@mui/material';

export type AllocationDetailsColumnProps = {
  label?: string;
  value?: string | number;
  showTooltip?: boolean;
  isQueued?: boolean;
  sx?: SxProps;
};

export const AllocationDetailsColumn = ({
  label,
  value,
  showTooltip = false,
  isQueued = false,
  sx,
}: AllocationDetailsColumnProps): ReactElement => {
  const valueSx = { display: 'flex', alignItems: 'center', height: isQueued ? 32 : 'inherit', wordBreak: 'break-word' };

  const queuedTooltip = showTooltip ? <QueuedTooltip /> : null;

  const valueContent = isQueued ? (
    <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap">
      <Typography variant="h6">{value}</Typography>
      {queuedTooltip}
    </Stack>
  ) : (
    <Typography variant="h6" sx={valueSx}>
      {value}
    </Typography>
  );

  return (
    <Stack sx={sx} direction="column" spacing={1}>
      <LocalizedTypography variant="overline" color="text.secondary">
        {label}
      </LocalizedTypography>
      {valueContent}
    </Stack>
  );
};
