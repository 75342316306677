import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiBulkFillResponse,
  ApiFixFomCheckRequestModel,
  ApiFixResponseModel,
  ApiGetFixOrdersResponseModel,
} from '@halo-data-sources/models';

const ISSUER_V1_PATH = '/issuer/v1';
const FIX_PATH = `${ISSUER_V1_PATH}/fix`;

export const postFixOrderValidation = async (
  calendarId: string,
  body: ApiFixFomCheckRequestModel,
): Promise<ApiFixResponseModel> => {
  const clientConfiguration = await getClientConfiguration(FIX_PATH);

  const response = await request<ApiFixResponseModel>(
    `${clientConfiguration.basePath}/calendar/allocation/${calendarId}`,
    {
      ...clientConfiguration.requestInit,
      method: 'POST',
      body: JSON.stringify({ ...body, _csrf_token: clientConfiguration.csrfToken }),
    },
  );

  return response;
};

export const putFixOrderCancellation = async (allocationId: number): Promise<ApiFixResponseModel> => {
  const clientConfiguration = await getClientConfiguration(FIX_PATH);

  return await request<ApiFixResponseModel>(
    `${clientConfiguration.basePath}/calendar/allocation/${allocationId}/cancel`,
    {
      ...clientConfiguration.requestInit,
      failOnError: false,
      method: 'PUT',
      body: JSON.stringify({ _csrf_token: clientConfiguration.csrfToken }),
    },
  );
};

export const getFixOrderAndAllocation = async (id: number): Promise<ApiFixResponseModel> => {
  const clientConfiguration = await getClientConfiguration(FIX_PATH);

  const path = `${clientConfiguration.basePath}/calendar/allocation/${id}`;

  const response = await request<ApiFixResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const getFixOrders = async (ids: Array<number>): Promise<ApiGetFixOrdersResponseModel> => {
  const clientConfiguration = await getClientConfiguration(FIX_PATH);

  const path = `${clientConfiguration.basePath}/orders`;

  const response = await request<ApiGetFixOrdersResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      orderIds: ids,
    }),
  });

  return response;
};

export const putAllocationsFixOrderRejection = async (id: number): Promise<ApiFixResponseModel> => {
  const clientConfiguration = await getClientConfiguration(FIX_PATH);

  const path = `${clientConfiguration.basePath}/calendar/allocation/${id}/cancel`;

  const response = await request<ApiFixResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
  });

  return response;
};

export const postFixOrderBulkFill = async (calendarId: number, ids: Array<number>): Promise<ApiBulkFillResponse> => {
  const clientConfiguration = await getClientConfiguration(FIX_PATH);

  const path = `${clientConfiguration.basePath}/calendar/${calendarId}/fill/bulk`;

  const response = await request<ApiBulkFillResponse>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      orders: ids,
    }),
  });

  return response;
};

export const postFixOrderFilled = async (id: number, wholesalerId: number): Promise<ApiFixResponseModel> => {
  const clientConfiguration = await getClientConfiguration(FIX_PATH);

  const path = `${clientConfiguration.basePath}/calendar/allocation/${id}/fill`;

  const response = await request<ApiFixResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      wholesaler: wholesalerId,
    }),
  });

  return response;
};
