import { ReactElement } from 'react';

import { productDetailModalAnalyticsTabStateAtom } from '@halo-atoms/pdm';
import {
  ProductDetailModalAnalyticsRollingReturnsStatisticsTableLayout,
  ProductDetailModalAnalyticsRollingReturnsSummary,
  ProductDetailModalAnalyticsRollingReturnsTable,
  ProductDetailModalAnalyticsRollingReturnsTableProps,
} from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { RollingReturnsStatisticsQueryResult } from '@halo-data-sources/queries';
import { Box, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';

export type ProductDetailModalAnalyticsRollingReturnsPerformanceTableProps = {
  data?: RollingReturnsStatisticsQueryResult | null;
  loading?: boolean;
  title: string;
  showTreeData: boolean;
  showAgainstNote: boolean;
};

export const ProductDetailModalAnalyticsRollingReturnsPerformanceTable = ({
  data,
  loading = false,
  title,
  showTreeData,
  showAgainstNote,
}: ProductDetailModalAnalyticsRollingReturnsPerformanceTableProps): ReactElement => {
  const state = useAtomValue(productDetailModalAnalyticsTabStateAtom);

  const { daysBetween: days, dateRangeText } = state;

  const rows = data?.performance ?? [];

  const underlyingData = rows[0];
  const noteData = rows[1];

  const noteAverage = noteData?.average;
  const noteWorst = noteData?.worst;
  const noteBest = noteData?.best;
  const underlyingAverage = underlyingData?.average;
  const underlyingWorst = underlyingData?.worst;
  const underlyingBest = underlyingData?.best;

  const hasColumnOneData = typeof noteBest === 'number' && typeof underlyingBest === 'number';
  const hasColumnTwoData = typeof noteAverage === 'number' && typeof underlyingAverage === 'number';
  const hasColumnThreeData = typeof noteWorst === 'number' && typeof underlyingWorst === 'number';

  const columnOneDifference = hasColumnOneData ? noteBest - underlyingBest : undefined;
  const columnTwoDifference = hasColumnTwoData ? noteAverage - underlyingAverage : undefined;
  const columnThreeDifference = hasColumnThreeData ? noteWorst - underlyingWorst : undefined;

  const formattedTitle = showAgainstNote
    ? translations.pdm.rollingReturns.noteVsPerformanceTitle
    : translations.pdm.rollingReturns.performanceTitle;

  const columns: ProductDetailModalAnalyticsRollingReturnsTableProps['columns'] = [
    {
      field: 'best',
      headerName: 'Best',
      headerInfo: `The best return at maturity when analyzing the ${days} Rolling Return windows between ${dateRangeText}.`,
    },
    {
      field: 'average',
      headerName: 'Average',
      headerInfo: `The average return at maturity when analyzing the ${days} Rolling Return windows between ${dateRangeText}.`,
    },
    {
      field: 'worst',
      headerName: 'Worst',
      headerInfo: `The worst return at maturity when analyzing the ${days} Rolling Return windows between ${dateRangeText}.`,
    },
  ];

  const comparisons = showAgainstNote ? (
    <Stack direction="row" alignItems="center" justifyContent="flex-end">
      <Box sx={{ flex: 0.4 }} />
      <ProductDetailModalAnalyticsRollingReturnsSummary
        label={translations.common.difference}
        value={columnOneDifference}
        loading={loading}
      />
      <ProductDetailModalAnalyticsRollingReturnsSummary
        label={translations.common.difference}
        value={columnTwoDifference}
        loading={loading}
      />
      <ProductDetailModalAnalyticsRollingReturnsSummary
        label={translations.common.difference}
        value={columnThreeDifference}
        loading={loading}
      />
    </Stack>
  ) : null;

  return (
    <ProductDetailModalAnalyticsRollingReturnsStatisticsTableLayout
      title={formattedTitle}
      loading={loading}
      dynamicContent={{ title }}
    >
      <ProductDetailModalAnalyticsRollingReturnsTable
        columns={columns}
        rows={rows}
        loading={loading}
        treeData={showTreeData}
      />
      {comparisons}
    </ProductDetailModalAnalyticsRollingReturnsStatisticsTableLayout>
  );
};
