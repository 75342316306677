import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';

import { useUserInfoQuery } from '@halo-data-sources/queries';
import {
  AnimationLayer,
  DocumentLinks,
  LogoRow,
  ONBOARDING_CONTENT_MIN_HEIGHT,
  PageMessageBackground,
} from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, Drawer, Typography, useTheme } from '@mui/material';

const mainStackStyling = (theme: HaloTheme) => ({ height: '100%', zIndex: theme.zIndex.drawer });

const mainContentSx = {
  alignItems: { xs: 'center', md: 'unset' },
  display: { xs: 'flex', md: 'unset' },
  flexDirection: { xs: 'column', md: 'unset' },
};

const disclaimerContentSx = {
  alignSelf: 'flex-start',
  mt: 6,
  mb: 5,
};

const welcomeMessageSx = {
  color: 'common.white',
  mb: { md: 8, xs: 5 },
  ['@media (max-height:1000px)']: { mb: { md: 4, xs: 3 } },
};

const mobileSx = {
  display: { xs: 'block', md: 'none' },
};

export type OnBoardingProps = {
  screenHeight: number;
  primary: string;
  list?: ReactNode;
  mobileContent?: ReactNode;
  loading?: boolean;
};

export const OnboardingDrawer = ({
  screenHeight,
  primary,
  list,
  mobileContent,
  loading = false,
}: OnBoardingProps): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const isMobile = Boolean(mobileContent);

  const { data: userInfo } = useUserInfoQuery();

  const onboardingDisclaimerContent = userInfo?.settings?.onboarding?.onboardingDisclaimerContent;

  const [mobileHeight, setMobileHeight] = useState(isMobile ? screenHeight : null);

  useEffect(() => {
    if (isMobile) {
      const elementHeight = ref?.current ? ref.current.clientHeight : screenHeight;
      const useScreenHeight = elementHeight < screenHeight;

      const mobileHeight = useScreenHeight ? screenHeight : elementHeight;
      const mobileOffset = parseInt(useScreenHeight ? theme.spacing(10) : theme.spacing(35));

      setMobileHeight(mobileHeight + mobileOffset);
    }
  }, [isMobile, ref?.current?.clientHeight]);

  const adjustedHeight = screenHeight > ONBOARDING_CONTENT_MIN_HEIGHT ? screenHeight : ONBOARDING_CONTENT_MIN_HEIGHT;
  const height = mobileHeight ?? adjustedHeight;
  const welcomeMessageComponentType = isMobile ? 'h5' : 'h1';
  const welcomeMessageAlignment = isMobile ? 'center' : 'left';

  const drawerContentStyling = (theme: HaloTheme) => ({
    alignItems: { md: 'unset', xs: 'center' },
    display: 'flex',
    flexDirection: 'column',
    background: !loading
      ? `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`
      : null,
    height: { md: '100vh', xs: 'none' },
    maxWidth: { md: 488, xs: 'unset' },
    minHeight: height,
    overflow: 'auto',
    pb: { md: 6, xs: 0 },
    pt: { md: 6, xs: 3 },
    px: { md: 5, xs: 3 },
    position: 'relative',
    width: { md: 488, xs: '100%' },
  });

  const disclaimerContent = isMobile ? (
    <Typography sx={disclaimerContentSx} variant="body2" color="primary.contrastText" fontSize="14px">
      {onboardingDisclaimerContent}
    </Typography>
  ) : null;

  const alternateAnimationLayer = <AnimationLayer contentPositionY={height} />;

  const mainStack = !loading ? (
    <Stack sx={mainStackStyling} justify="space-between" direction="column">
      <Box sx={mainContentSx} ref={ref}>
        <LogoRow />
        <Typography
          sx={welcomeMessageSx}
          align={welcomeMessageAlignment}
          variant={welcomeMessageComponentType}
          component={welcomeMessageComponentType}
        >
          {primary}
        </Typography>
        <Box sx={mobileSx}>{mobileContent}</Box>
        {list}
      </Box>
      <DocumentLinks />
      {disclaimerContent}
    </Stack>
  ) : null;

  return (
    <Drawer elevation={0} PaperProps={{ sx: drawerContentStyling }} anchor="left" variant="permanent">
      <PageMessageBackground
        contentPositionY={height}
        zIndex={theme.zIndex.drawer}
        alternateBackground={alternateAnimationLayer}
        loading={loading}
      />
      {mainStack}
    </Drawer>
  );
};
