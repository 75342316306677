import { ReactElement, useEffect, useState } from 'react';

import { LexicalTypography } from '@halo-common/components';
import { COMBINED_DATE_TIME_ZONE_FORMAT } from '@halo-common/constants';
import { useNoteDetailCaption } from '@halo-common/hooks';
import {
  EditCalendarModalErrorMessage,
  EditCalendarModalFooter,
  EditCalendarModalWholesalerColumn,
} from '@halo-modules/admin';
import { PershingSelectors, PershingStatusEnum } from '@halo-stores/Pershing';
import { Modal, Stack } from '@halodomination/halo-fe-common';
import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';

const dateSx = {
  maxWidth: 350,
  width: '100%',
};

const largeCellSx = {
  alignItems: 'center',
  display: 'flex',
  height: 48,
};

// TODO: Remove this when we move to dynamic wholesaler columns.
const WHITE_LISTED_WHOLESALERS = ['halo', 'ftlp', 'issuer'];

export type WholeSalerColumnMap = {
  [key: string]: {
    header: string;
    price: string | undefined;
    reoffer: string | undefined;
  };
};

export type EditCalendarFormSchema = {
  closeDate: DateTime | null;
};

export type EditCalendarModalProps = {
  open: boolean;
  onClose: () => void;
};

export const EditCalendarModal = ({ open = false, onClose }: EditCalendarModalProps): ReactElement => {
  const calendar = useSelector(PershingSelectors.selectCalendar);
  const status = useSelector(PershingSelectors.selectStatus);
  const error = useSelector(PershingSelectors.selectError);

  const [date, setDate] = useState<DateTime | null>(null);

  const { id, issuer, note, expirationDate } = calendar ?? {};

  const issuerName = issuer?.name ?? '';
  const subtitle = useNoteDetailCaption({ note, issuer, emphasis: 'issuer' });
  const editCalendarModalTitle = `${calendar?.cusip ?? 'Calendar'} Update`;

  const wholesalerColumnMap = WHITE_LISTED_WHOLESALERS.reduce((columns: WholeSalerColumnMap, wholesalerCode) => {
    const wholesaler = calendar?.wholesalers?.find((next) => next.code === wholesalerCode);

    if (!wholesaler) {
      return {
        ...columns,
        [wholesalerCode]: {
          header: wholesalerCode.toUpperCase(),
          price: undefined,
          reoffer: undefined,
        },
      };
    }

    const { code, price, salePrice } = wholesaler;

    const updatedColumns = { ...columns };

    updatedColumns[code] = {
      header: wholesalerCode.toUpperCase(),
      price: price ? `${price.toFixed(3)}%` : undefined,
      reoffer: salePrice ? `${salePrice.toFixed(3)}%` : undefined,
    };

    return updatedColumns;
  }, {});

  const issuerColumn = wholesalerColumnMap?.['issuer'];
  const ftlpColumn = wholesalerColumnMap?.['ftlp'];
  const haloColumn = wholesalerColumnMap?.['halo'];

  const alert = error ? <EditCalendarModalErrorMessage message={error} /> : null;

  const handleDateChange = (value: DateTime | null) => {
    setDate(value);
  };

  useEffect(() => {
    if (expirationDate) {
      const formattedDate = DateTime.fromISO(expirationDate);
      setDate(formattedDate);
    }
  }, [expirationDate]);

  useEffect(() => {
    if (status === PershingStatusEnum.successUpdatePershingCalendar) onClose();
  }, [status]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={editCalendarModalTitle}
      subtitle={subtitle}
      footer={<EditCalendarModalFooter id={id} date={date} onClose={onClose} />}
    >
      <form>
        <Stack direction="column" spacing={3} xs={12}>
          {alert}
          <Stack direction="row" alignItems="flex-start" spacing={3} xs={[3, 6]}>
            <Stack direction="column" spacing={2}>
              <LexicalTypography variant="overline" color="text.secondary">
                Issuer
              </LexicalTypography>
              <LexicalTypography sx={largeCellSx}>{issuerName}</LexicalTypography>
            </Stack>
            <Stack direction="column" spacing={2} sx={dateSx}>
              <LexicalTypography variant="overline" color="text.secondary">
                Closes
              </LexicalTypography>
              <DateTimePicker
                onChange={handleDateChange}
                value={date}
                format={COMBINED_DATE_TIME_ZONE_FORMAT}
                slots={{ textField: (props) => <TextField size="large" fullWidth {...props} /> }}
              />
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="flex-start" spacing={3} xs={4}>
            <EditCalendarModalWholesalerColumn
              header={issuerColumn?.header}
              buy={issuerColumn?.price}
              reoffer={issuerColumn?.reoffer}
            />
            <EditCalendarModalWholesalerColumn
              header={ftlpColumn?.header}
              buy={ftlpColumn?.price}
              reoffer={ftlpColumn?.reoffer}
            />
            <EditCalendarModalWholesalerColumn
              header={haloColumn?.header}
              buy={haloColumn?.price}
              reoffer={haloColumn?.reoffer}
            />
          </Stack>
        </Stack>
      </form>
    </Modal>
  );
};
