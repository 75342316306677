import { ReactElement } from 'react';

import { portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import { translations } from '@halo-common/translations';
import { usePortfolioPositionAggregationQuery } from '@halo-data-sources/queries';
import {
  LocalizedTypography,
  mapNumberToLocalCurrency,
  Stack,
  useCombinedStyling,
} from '@halodomination/halo-fe-common';
import { Skeleton, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';

const footerSx = {
  py: 2,
  borderRadius: '0 0 8px 8px',
  backgroundColor: 'background.default',
  borderTop: '1px solid',
  borderColor: 'grey.300',
};

const footerCellSx = {
  flex: 1,
  minWidth: 150,
};

const positiveGrowthSx = {
  color: 'common.charts.positive.main',
};

const negativeGrowthSx = {
  color: 'common.charts.negative.main',
};

const cellSx = {
  fontWeight: 600,
  pr: 1.25,
};

const labelSx = {
  fontWeight: 600,
  pl: 1.25,
};

const labelLoadingSx = {
  ml: 1.25,
};

const loadingCellSx = {
  textAlign: 'right',
  mr: 1.25,
};

export const PortfolioPositionsAggregateTableFooter = (): ReactElement => {
  const { filters, query } = useAtomValue(portfolioPositionsManagerAtom);
  const { currency } = filters;

  const { data, isPending: aggregateLoading } = usePortfolioPositionAggregationQuery(query);

  const totalNotional = data?.tableDetails?.totalNotional ?? 0;
  const marketValue = data?.tableDetails?.marketValue ?? 0;
  const marketChange = data?.tableDetails?.marketChange ?? 0;

  const roundFooterValue = (value: number) => {
    const roundedValue = Math.round(value);
    return roundedValue === 0 ? 0 : roundedValue;
  };

  const roundedTotalNotional = roundFooterValue(totalNotional);
  const roundedMarketValue = roundFooterValue(marketValue);
  const roundedMarketChange = roundFooterValue(marketChange);

  const positiveSx = roundedMarketChange > 0 ? positiveGrowthSx : undefined;
  const negativeSx = roundedMarketChange < 0 ? negativeGrowthSx : undefined;
  const coloredTextSx = positiveSx ?? negativeSx;

  const marketChangeSx = useCombinedStyling(cellSx, coloredTextSx);

  const currencyOptions = { currency: currency?.code, maximumFractionDigits: 0 };
  const currencySymbol = currency?.symbol;

  const totalNotionalText = mapNumberToLocalCurrency(roundedTotalNotional, currencyOptions, currencySymbol);
  const marketValueText = mapNumberToLocalCurrency(roundedMarketValue, currencyOptions, currencySymbol);
  const marketChangeCurrencyText = mapNumberToLocalCurrency(roundedMarketChange, currencyOptions, currencySymbol);

  const marketChangeText = marketChange > 0 ? `+${marketChangeCurrencyText}` : marketChangeCurrencyText;

  return aggregateLoading ? (
    <Stack
      direction="row"
      sx={footerSx}
      elementStyling={footerCellSx}
      justifyElements={[undefined, 'flex-end', 'flex-end', 'flex-end']}
    >
      <Skeleton sx={labelLoadingSx} width={90} height={24} variant="rounded" />
      <Skeleton sx={loadingCellSx} width={90} height={24} variant="rounded" />
      <Skeleton sx={loadingCellSx} width={90} height={24} variant="rounded" />
      <Skeleton sx={loadingCellSx} width={90} height={24} variant="rounded" />
      <>{/* Placeholder column to align footer to table */}</>
    </Stack>
  ) : (
    <Stack direction="row" sx={footerSx} elementStyling={footerCellSx}>
      <LocalizedTypography sx={labelSx} variant="body2">
        {translations.common.total}
      </LocalizedTypography>
      <Typography sx={cellSx} variant="body2" align="right">
        {totalNotionalText}
      </Typography>
      <Typography sx={cellSx} variant="body2" align="right">
        {marketValueText}
      </Typography>
      <Typography sx={marketChangeSx} variant="body2" align="right">
        {marketChangeText}
      </Typography>
      <>{/* Placeholder column to align footer to table */}</>
    </Stack>
  );
};
