import { CSSProperties, ReactElement } from 'react';

import { EducationListItem } from '@halo-modules/app';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { List, Stack } from '@mui/material';

const listSx = { ml: 2 };

export type EducationListProps = {
  items: Array<string>;
  title?: string;
  bold?: boolean;
  italics?: boolean;
  bullet?: CSSProperties['listStyleType'];
};

export const EducationList = ({ title, items = [], ...props }: EducationListProps): ReactElement => {
  const titleContent = title ? (
    <LocalizedTypography color="textPrimary" variant="h5">
      {title}
    </LocalizedTypography>
  ) : null;

  return (
    <Stack direction="column" justifyContent="space-between" spacing={1} sx={{ width: '100%' }}>
      {titleContent}
      <List sx={listSx}>
        {items.map((item) => (
          <EducationListItem key={item} {...props}>
            {item}
          </EducationListItem>
        ))}
      </List>
    </Stack>
  );
};
