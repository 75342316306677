import {
  AllocationSubmissionModel,
  DocumentBucketModel,
  SMAAllocationModel,
  SMAFirmModel,
  SMAStrategyModel,
} from '@halo-common/models';
import { ApiAccountMapper, mapApiDocumentModelToS3DocumentModel } from '@halo-data-sources/mappers';
import {
  ApiDocumentModel,
  ApiSMAAllocationModel,
  ApiSMAFirmModel,
  ApiSMAStrategyModel,
} from '@halo-data-sources/models';

interface IApiSMAMapper {
  toSMAFirmModel: (model: ApiSMAFirmModel) => SMAFirmModel;
  toSMADocumentModel: (model: ApiDocumentModel | null, caption: string) => DocumentBucketModel | null;
  toSMAStrategyModel: (response: Array<ApiSMAStrategyModel>) => Array<SMAStrategyModel>;
  toSMAAllocationModel: (response: ApiSMAAllocationModel) => SMAAllocationModel;
}

export const ApiSMAMapper: IApiSMAMapper = {
  toSMAFirmModel: (model) => ({
    ...model,
    logoImage: model.logo_image,
  }),
  toSMADocumentModel: (model, caption) => {
    if (!model) return null;

    return {
      ...mapApiDocumentModelToS3DocumentModel(model),
      caption,
    };
  },
  toSMAStrategyModel: (response) => {
    const captionDictionary = {
      pitchBook: 'PitchBook',
      suitability: 'Suitability',
      insideTheStrategy: 'Inside the Strategy',
      factSheet: 'Fact Sheet',
    };

    return response.map((strategy) => {
      const {
        sma_firm,
        pitch_book,
        optional,
        inside_the_strategy,
        fact_sheet,
        content_blocks,
        minimum_investment_amount,
        subtitle,
        custodians,
        ...other
      } = strategy;

      return {
        ...other,
        firm: ApiSMAMapper.toSMAFirmModel(sma_firm),
        pitchBook: ApiSMAMapper.toSMADocumentModel(pitch_book, captionDictionary.pitchBook),
        factSheet: ApiSMAMapper.toSMADocumentModel(fact_sheet, captionDictionary.factSheet),
        suitability: ApiSMAMapper.toSMADocumentModel(optional, captionDictionary.suitability),
        insideTheStrategy: ApiSMAMapper.toSMADocumentModel(inside_the_strategy, captionDictionary.insideTheStrategy),
        contentBlocks: content_blocks,
        minimumInvestmentAmount: minimum_investment_amount,
        custodians: custodians.map((custodian) => ({ id: custodian.id, name: custodian.name.trim() })),
        name: subtitle,
      };
    });
  },
  toSMAAllocationModel: (response) => {
    const { account, amount, id, status, strategy_id, advisor_name } = response;
    const smaAccount = ApiAccountMapper.toAccountModel(account);

    return {
      account: smaAccount,
      amount,
      id,
      status,
      strategyId: strategy_id,
      advisorName: advisor_name,
    };
  },
};

interface ISMAAllocationMapper {
  toAllocationSubmissionModel: (model: SMAAllocationModel) => AllocationSubmissionModel;
}

export const SMAAllocationMapper: ISMAAllocationMapper = {
  toAllocationSubmissionModel: (model) => ({
    id: model.id,
    account: model.account,
    amount: model.amount,
    status: model.status,
  }),
};
