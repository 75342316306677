import { ReactElement } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { addCustodianPositionsAtom } from '@halo-atoms/portfolio';
import { CustodianModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { AddAccountOrPositionModalCustodianButton } from '@halo-modules/app';
import { Iconography, LocalizedTypography, Tooltip } from '@halodomination/halo-fe-common';
import { Skeleton, Stack } from '@mui/material';
import { useSetAtom } from 'jotai';

export type AddAccountOrPositionModalCustodianListProps = {
  custodians?: Array<CustodianModel>;
  loading?: boolean;
};

export const AddAccountOrPositionModalCustodianList = ({
  custodians,
  loading,
}: AddAccountOrPositionModalCustodianListProps): ReactElement | null => {
  const onModalToggle = useSetAtom(modalAtom);
  const setAddCustodianPositionsOptions = useSetAtom(addCustodianPositionsAtom);

  if (loading) {
    return (
      <Stack direction="column" spacing={3}>
        <Stack direction="column" spacing={1}>
          <Skeleton variant="rounded" width="100%" height={22} />
          <Skeleton variant="rounded" width="100%" height={56} />
        </Stack>
        <Stack direction="row" spacing={3} flexWrap="wrap">
          <Skeleton variant="rounded" width="100%" height={65} />
          <Skeleton variant="rounded" width="100%" height={65} />
          <Skeleton variant="rounded" width="100%" height={65} />
        </Stack>
      </Stack>
    );
  }

  if (!custodians?.length) return null;

  const groupedCustodians = custodians?.reduce((list: Array<Array<CustodianModel>>, custodian, index) => {
    const isNewRow = index % 4 === 0;
    const updatedList = [...list];

    if (isNewRow) updatedList.push([custodian]);
    else updatedList[updatedList.length - 1].push(custodian);

    return updatedList;
  }, []);

  const custodianButtons = groupedCustodians.map((custodianGroup) => {
    const rowId = `row_${custodianGroup.map(({ id }) => id).join('_')}`;
    const custodianButtonSx = { flexBasis: `${100 / custodianGroup.length}%` };

    const buttons = custodianGroup.map(({ id, name, integrationLink = '' }) => {
      const handleClick = () => {
        setAddCustodianPositionsOptions({ open: true, name, link: integrationLink });
        onModalToggle({ addAccountOrPositionsModal: false });
      };

      return (
        <AddAccountOrPositionModalCustodianButton key={id} name={name} onClick={handleClick} sx={custodianButtonSx} />
      );
    });

    return (
      <Stack key={rowId} direction="row" spacing={3}>
        {buttons}
      </Stack>
    );
  });

  return (
    <Stack direction="column" spacing={3}>
      <Stack direction="row" spacing={1} alignItems="center">
        <LocalizedTypography variant="h6">
          {translations.portfolio.addAccountOrPositionsModal.custodianSyncMessage}
        </LocalizedTypography>
        <Tooltip placement="top" title={translations.portfolio.addAccountOrPositionsModal.custodianTooltip}>
          <Iconography prefix="fal" iconName="info-circle" />
        </Tooltip>
      </Stack>
      <Stack direction="column" spacing={3}>
        {custodianButtons}
      </Stack>
    </Stack>
  );
};
