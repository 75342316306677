import { getClientConfiguration, request } from '@halo-data-sources/clients';
import { ApiAccountAllCustodianModel } from '@halo-data-sources/models';

export const COMMON_CUSTODIAN_PATH = '/common/custodians';

export const getCustodians = async (): Promise<ApiAccountAllCustodianModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_CUSTODIAN_PATH);

  const response = await request<ApiAccountAllCustodianModel>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};
