import { ReactElement } from 'react';

import { LexicalTypography, ProductDetailTable } from '@halo-common/components';
import { SHORT_MONTH_SHORT_DAY_YEAR_FORMAT } from '@halo-common/constants';
import { useTranslatePdmDates } from '@halo-common/hooks';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { PostTradeV2Event, usePostTradeV2EventsQuery } from '@halo-data-sources/queries';
import { CallEventCalledStatus } from '@halo-modules/app';
import { GridColDef, HaloDataGridProps, Iconography } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Stack, SxProps, Typography } from '@mui/material';

const emphasisSx = {
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  width: '100%',
};

const iconSx = {
  marginRight: 1.625,
};

const calledSx = {
  color: 'success.dark',
};

const emptyDataSx = {
  color: 'text.secondary',
};

const statusContainerSx = {
  height: '100%',
};

const commonColumnProps: Partial<GridColDef> = {
  align: 'left',
  flex: 1,
};

export type V2PostTradeNoteDetailModalEarlyRedemptionTableProps = {
  loading?: boolean;
  account?: AccountTypeAheadOption | null;
  id?: number;
};

export const V2PostTradeNoteDetailModalEarlyRedemptionTable = ({
  loading,
  id,
  account,
}: V2PostTradeNoteDetailModalEarlyRedemptionTableProps): ReactElement => {
  const translateDate = useTranslatePdmDates();

  const { data: events, isPending: isEventsLoading } = usePostTradeV2EventsQuery(id, account);

  const rows = events?.callEvents ?? [];
  const height = rows.length >= 16 ? 600 : 'auto';

  const isLoading = loading || isEventsLoading;

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonColumnProps,
      field: 'eventDate',
      headerName: translations.common.date,
      valueFormatter: (value: string) => translateDate(value, SHORT_MONTH_SHORT_DAY_YEAR_FORMAT),
    },
    {
      ...commonColumnProps,
      field: 'barrierPct',
      headerName: translations.common.level,
      renderCell: ({ value }) => {
        const parsedValue = value ? `${value}%` : '';

        return (
          <Typography sx={emphasisSx} variant="body2">
            {parsedValue}
          </Typography>
        );
      },
    },
    {
      ...commonColumnProps,
      field: 'return',
      headerName: translations.common.return,
      renderCell: ({ value }) => {
        const returnValue = value ? value.toFixed(2) : '';
        const parsedValue = returnValue ? `${returnValue}%` : '-';

        const cellSx: SxProps = [emphasisSx, !value ? emptyDataSx : null];

        return (
          <Typography sx={cellSx} variant="body2">
            {parsedValue}
          </Typography>
        );
      },
    },
    {
      ...commonColumnProps,
      field: 'called',
      headerName: translations.common.status,
      renderCell: ({ value }) => {
        const isCalled = value === CallEventCalledStatus.CALLED;

        const cellSx: SxProps<HaloTheme> = [calledSx, iconSx];
        const statusSx = isCalled ? calledSx : emptyDataSx;

        const icon = isCalled ? <Iconography sx={cellSx} iconName="check" /> : null;

        return (
          <Stack sx={statusContainerSx} direction="row" alignItems="center">
            {icon}
            <LexicalTypography variant="body2" sx={statusSx} translationKey={value}>
              {value}
            </LexicalTypography>
          </Stack>
        );
      },
    },
  ];

  return <ProductDetailTable<PostTradeV2Event> height={height} columns={columns} rows={rows} loading={isLoading} />;
};
