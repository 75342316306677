import { ReactElement } from 'react';

import { Typography } from '@mui/material';

const headlineSx = {
  width: '100%',
  minHeight: 80,
};

export type EducationHeadlineProps = {
  children?: string;
};

export const EducationHeadline = ({ children }: EducationHeadlineProps): ReactElement => (
  <Typography color="textPrimary" variant="h3" sx={headlineSx}>
    {children}
  </Typography>
);
