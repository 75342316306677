import { ReactElement } from 'react';

import { Modal, Stack } from '@halodomination/halo-fe-common';
import { Box, CircularProgress, Button } from '@mui/material';

const modalStyling = {
  height: '100%',
  padding: 0,
};

const paperStyling = {
  backgroundColor: 'background.paper',
  padding: 0,
  width: '100%',
  minHeight: '300px',
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};
const iframeStyling = {
  height: '70vh',
  width: '100%',
  border: 'none',
};

export type PdfViewerModalProps = {
  open: boolean;
  path: string;
  onClose?: () => void;
  isLoading?: boolean;
  onDownload?: () => void;
  overline?: string;
  title?: string;
  enableDownload?: boolean;
};

export const PdfViewerModal = ({
  open,
  path,
  onClose = () => null,
  isLoading,
  overline,
  title,
  onDownload,
  enableDownload = true,
}: PdfViewerModalProps): ReactElement => {
  const loadingIndicator = <CircularProgress color="primary" />;
  const pdfViewer = <iframe role="document" style={iframeStyling} src={`${path}#navpanes=0&zoom=135`} />;

  const mainContent = !isLoading ? pdfViewer : loadingIndicator;

  const downloadButton =
    onDownload && enableDownload ? (
      <Button variant="contained" onClick={onDownload}>
        Download
      </Button>
    ) : null;

  const closeButton = enableDownload ? <Button onClick={onClose}>Close</Button> : null;

  const modalFooter = (
    <Stack direction="row" justify="space-between">
      {closeButton}
      {downloadButton}
    </Stack>
  );

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      size="large"
      sx={modalStyling}
      overline={overline}
      title={title}
      open={open}
      ContentProps={{ sx: { padding: 0 } }}
      onClose={onClose}
      footer={modalFooter}
    >
      <Box sx={paperStyling}>{mainContent}</Box>
    </Modal>
  );
};
