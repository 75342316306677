import { useEffect } from 'react';

import { useOnboardingDisclaimers, useUserInfoQuery } from '@halo-data-sources/queries';
import { DisclaimerList, OnboardingLayout } from '@halo-modules/app';
import { OnboardingActions, OnboardingSelectors } from '@halo-stores/Onboarding';
import { PageComponent, PageMeta } from '@halodomination/halo-fe-common';
import { useDispatch, useSelector } from 'react-redux';

const DisclaimerPage: PageComponent = () => {
  const dispatch = useDispatch();

  const { data: userInfo } = useUserInfoQuery();
  const { data: disclaimers = [] } = useOnboardingDisclaimers();

  const user = useSelector(OnboardingSelectors.selectUserIdentification);

  const userFacingName = userInfo?.whiteLabel.userFacingName;
  const userFacingNameString = userFacingName ? `access ${userFacingName}` : 'get started';
  const messageString = user.userName
    ? `${user.userName}, there are a few things to sign before you can ${userFacingNameString}.`
    : `There are a few things to sign before you can ${userFacingNameString}.`;

  useEffect(() => {
    if (disclaimers.length === 0) dispatch(OnboardingActions.getDisclaimers());
  }, []);

  return (
    <OnboardingLayout pageName={DisclaimerPageMeta.pageName} welcomeMessage={messageString} listVariant="noListVariant">
      <DisclaimerList disclaimers={disclaimers} />
    </OnboardingLayout>
  );
};

export const DisclaimerPageMeta: PageMeta = {
  pageName: 'Disclaimers',
  route: '/app/onboarding/disclaimers',
};

DisclaimerPage.pageName = DisclaimerPageMeta.pageName;
DisclaimerPage.route = DisclaimerPageMeta.route;

export default DisclaimerPage;
