import { putUserSetting } from '@halo-data-sources/clients';
import {
  ApiUserSettingOrderHubOrderedColumnsTablesModel,
  ApiUserSettingOrderHubPinnedColumnsModel,
  ApiUserSettingSalesforceRequestModel,
} from '@halo-data-sources/models';
import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';

export type UserSettingMutationPayload = {
  key: string;
  value:
    | ApiUserSettingSalesforceRequestModel
    | ApiUserSettingOrderHubPinnedColumnsModel
    | ApiUserSettingOrderHubOrderedColumnsTablesModel;
};

export type UserSettingMutationResult = {
  [key: string]: string;
};

const userSettingMutationFn = async (payload: UserSettingMutationPayload) => {
  return await putUserSetting(payload);
};

export const useUserSettingMutation = (
  options?: Partial<UseMutationOptions<UserSettingMutationResult, Error, UserSettingMutationPayload>>,
): UseMutationResult<UserSettingMutationResult, Error, UserSettingMutationPayload> =>
  useMutation<UserSettingMutationResult, Error, UserSettingMutationPayload>({
    mutationFn: userSettingMutationFn,
    ...options,
  });
