import { useEffect } from 'react';

import { AccountDesignationEnum, UserRoleEnum } from '@halo-common/enums';
import { useWhiteLabelRole } from '@halo-common/hooks';
import { OrderTicketFormModelFields } from '@halo-common/modals';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { useFormContext } from 'react-hook-form';

const ACCOUNT_DESIGNATION_ROLES = [UserRoleEnum.JanneyOrderSender];
const ACCOUNT_TYPE_ROLES = [UserRoleEnum.PershingOrderSender, UserRoleEnum.DevPershingTester];
const COMPENSATION_SECTION_ROLES = [UserRoleEnum.PershingOrderSender, UserRoleEnum.DevPershingTester];
const DISCLAIMER_ROLES = [UserRoleEnum.JanneyOrderSender];
const DISCRETION_ROLES = [UserRoleEnum.PershingOrderSender, UserRoleEnum.DevPershingTester];
const NOTE_SECTION_ROLES = [UserRoleEnum.PershingOrderSender, UserRoleEnum.DevPershingTester];
const PAR_ROLES = [UserRoleEnum.PershingOrderSender, UserRoleEnum.DevPershingTester];
const SALES_PRICE_ROLES = [UserRoleEnum.JanneyOrderSender];
const TTO_REP_CODE_ROLES = [UserRoleEnum.JanneyOrderSender];
const SOLICITED_ROLES = [
  UserRoleEnum.PershingOrderSender,
  UserRoleEnum.JanneyOrderSender,
  UserRoleEnum.DevPershingTester,
];

export type OrderTicketContentPickerResult = {
  calculateSalesPrice: boolean;
  hardcodePar: boolean;
  isJanney: boolean;
  isPershing: boolean;
  isPershingDev: boolean;
  shouldDefaultDiscretion: boolean;
  hasDisclaimerValidation: boolean;
  isAdvisoryAccountSelected: boolean;
  isBrokerageAccountSelected: boolean;
  needsValidationApproval: boolean;
  showAccountTypeDropdown: boolean;
  showCompensation: boolean;
  showDesignation: boolean;
  showDisclaimer: boolean;
  showDiscretionDropdown: boolean;
  showNotes: boolean;
  showTTORepCodeTypeAhead: boolean;
  showSolicitedDropdown: boolean;
};

export const useOrderTicketContentPicker = (): OrderTicketContentPickerResult => {
  const { data: user } = useUserInfoQuery();

  const role = useWhiteLabelRole();
  const formContext = useFormContext<OrderTicketFormModelFields>();

  const roles = user?.details.roles ?? [];
  const userFullName = user?.details?.name;
  const account = formContext?.watch('account.account');

  const isJanney = role === 'janney';
  const isPershing = role === 'pershing';
  const isPershingDev = role === 'pershingDev';

  const calculateSalesPrice = roles.some((role) => SALES_PRICE_ROLES.includes(role));
  const hardcodePar = roles.some((role) => PAR_ROLES.includes(role));
  const showAccountTypeDropdown = roles.some((role) => ACCOUNT_TYPE_ROLES.includes(role));
  const showCompensation = roles.some((role) => COMPENSATION_SECTION_ROLES.includes(role));
  const showDesignation = Boolean(account && roles.some((role) => ACCOUNT_DESIGNATION_ROLES.includes(role)));
  const showDisclaimer = roles.some((role) => DISCLAIMER_ROLES.includes(role));
  const showNotes = roles.some((role) => NOTE_SECTION_ROLES.includes(role));
  const showSolicitedDropdown = roles.some((role) => SOLICITED_ROLES.includes(role));
  const showTTORepCodeTypeAhead = roles.some((role) => TTO_REP_CODE_ROLES.includes(role));

  const isAdvisoryAccountSelected = account?.designation === AccountDesignationEnum.Advisory;
  const isBrokerageAccountSelected = account?.designation === AccountDesignationEnum.Brokerage;

  const isJanneyAdvisory = isJanney && isAdvisoryAccountSelected;
  const showDiscretionDropdown = roles.some((role) => DISCRETION_ROLES.includes(role)) || isJanneyAdvisory;

  useEffect(() => {
    const updateOrderFrom = isJanney && userFullName;
    if (updateOrderFrom) formContext?.setValue('orderFrom', userFullName, { shouldDirty: true });
  }, [isJanney, userFullName, formContext?.setValue]);

  return {
    isJanney,
    isPershing,
    isPershingDev,
    calculateSalesPrice,
    hardcodePar,
    hasDisclaimerValidation: showDisclaimer,
    shouldDefaultDiscretion: isJanneyAdvisory,
    isAdvisoryAccountSelected,
    isBrokerageAccountSelected,
    needsValidationApproval: isJanney,
    showAccountTypeDropdown,
    showCompensation,
    showDesignation,
    showDisclaimer,
    showDiscretionDropdown,
    showNotes,
    showSolicitedDropdown,
    showTTORepCodeTypeAhead,
  };
};
