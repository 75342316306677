import { ReactElement } from 'react';

import { EducationHubUserModule } from '@halo-common/models';
import { EducationHubTableDetailPanelRow } from '@halo-modules/admin';
import { Stack } from '@halodomination/halo-fe-common';
import { Typography } from '@mui/material';

const panelListTitleSx = {
  backgroundColor: 'grey.100',
  display: 'flex',
  alignItems: 'center',
  height: 32,
  pl: 7.5,
  width: '100%',
  borderBottom: '1px solid',
  borderBottomColor: 'grey.300',
};

export type EducationHubTableDetailPanelProps = {
  id: string | number;
  moduleProgress?: Array<EducationHubUserModule>;
};

export const EducationHubTableDetailPanel = ({
  id,
  moduleProgress = [],
}: EducationHubTableDetailPanelProps): ReactElement => (
  <Stack direction="column" xs={12}>
    <Typography variant="overline" color="textSecondary" sx={panelListTitleSx}>
      Module Name
    </Typography>
    {moduleProgress.map(({ name, completionPercentage }, index) => {
      const key = `${id}-${name}`;
      const moduleId = index + 1;

      return (
        <EducationHubTableDetailPanelRow key={key} id={moduleId} title={name} percentComplete={completionPercentage} />
      );
    })}
  </Stack>
);
