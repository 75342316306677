import { HistoricalPerformanceModel } from '@halo-common/models';
import { ApiFetchHistoricalPerformanceResponseModel } from '@halo-data-sources/models';

export const apiHistoricalPerformanceMapper = {
  toHistoricalPerformanceResponseModel: (
    model: ApiFetchHistoricalPerformanceResponseModel,
  ): HistoricalPerformanceModel => {
    const historicalPerformanceKeys = Object.keys(model);
    const keyRegex = /(?<name>[\w_]+)_(?<year>(?:five|three|one)_year)/;

    const responseModel: HistoricalPerformanceModel = {};

    historicalPerformanceKeys.forEach((key) => {
      const keyMatches = keyRegex.exec(key);

      if (!keyMatches) return;

      const name = keyMatches?.groups ? keyMatches?.groups['name'] : '';
      const year = keyMatches?.groups ? keyMatches?.groups['year'] : '';

      const camelName = name.replace(/_([a-z0-9])/g, function (g) {
        return g[1].toLocaleUpperCase();
      });

      const camelYear = year.replace(/_([a-z0-9])/g, function (g) {
        return g[1].toLocaleUpperCase();
      });

      if (!Object.prototype.hasOwnProperty.call(responseModel, camelName)) {
        responseModel[camelName] = {};
      }

      responseModel[camelName][camelYear] = model[key];
    });

    return responseModel;
  },
};
