import { ReactElement } from 'react';

import { orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { COMBINED_DATE_TIME_ZONE_FORMAT, MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { useDateFormatter } from '@halo-common/hooks';
import { useBuyerAuctionQuery } from '@halo-data-sources/queries';
import { Stack } from '@halodomination/halo-fe-common';
import { Divider, Skeleton, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';

export const AuctionDetailsDates = (): ReactElement => {
  const formatDate = useDateFormatter({ ignoreWhiteLabel: true, timeZoneOverride: 'system' });

  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);
  const auctionId = selectedAuctionId?.toString();

  const { data: auction } = useBuyerAuctionQuery(auctionId);

  if (!auction) return <Skeleton variant="rounded" height={160} />;

  const dates = [
    { label: 'Auction End Time', value: formatDate(auction?.endDateTime, COMBINED_DATE_TIME_ZONE_FORMAT) },
    { label: 'Auction Start Time', value: formatDate(auction?.startDateTime, COMBINED_DATE_TIME_ZONE_FORMAT) },
    { label: 'Strike Date', value: `${formatDate(auction?.targetStrikeDate, MONTH_DAY_YEAR_DATE_FORMAT)} (Close)` },
    { label: 'Trade Date', value: formatDate(auction?.tradeDate, MONTH_DAY_YEAR_DATE_FORMAT) },
    { label: 'Settlement Date', value: formatDate(auction?.targetSettleDate, MONTH_DAY_YEAR_DATE_FORMAT) },
  ];

  const dateElements = dates.map(({ label, value }, index) => (
    <Stack key={`auction-date-${index}`} direction="row" xs={6}>
      <Stack direction="column">
        <Typography variant="body2">{label}</Typography>
      </Stack>
      <Stack direction="column">
        <Typography variant="body2" fontWeight="bold">
          {value}
        </Typography>
      </Stack>
    </Stack>
  ));

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" xs={[4, 8]} alignItems="center">
        <Stack direction="column">
          <Typography variant="subtitle2">Important Dates</Typography>
        </Stack>
        <Stack direction="column">
          <Divider />
        </Stack>
      </Stack>
      {dateElements}
    </Stack>
  );
};
