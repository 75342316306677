import { ReactElement } from 'react';

import { useUserInfoQuery } from '@halo-data-sources/queries';
import { EducationBanner, EducationModuleGroup, EducationModuleGroupProps, useCourseManager } from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';
import { Container } from '@mui/material';

export type EducationLandingProps = {
  loading: boolean;
};

export const EducationLanding = (): ReactElement => {
  const { data } = useUserInfoQuery();
  const showEducationBanner = data?.settings.showEducationBanner;

  const { courseGroups, courseCompletion, onStart, onContinue, onSelection } = useCourseManager();

  const banner = showEducationBanner ? <EducationBanner progress={courseCompletion} /> : null;

  return (
    <Container maxWidth="lg">
      <Stack direction="column" justifyElements="center" spacing={2}>
        {banner}
        {courseGroups.map((group) => {
          const handleStart: EducationModuleGroupProps['onStart'] = (reason) =>
            reason === 'continue' ? onContinue(group) : onStart(group);

          const handleSelection: EducationModuleGroupProps['onSelection'] = (module, step) =>
            onSelection(group, module, step);

          return (
            <EducationModuleGroup
              key={group.id}
              title={group.title}
              subtitle={group.description}
              progress={group.percentageComplete}
              modules={group.modules}
              onStart={handleStart}
              onSelection={handleSelection}
            />
          );
        })}
      </Stack>
    </Container>
  );
};
