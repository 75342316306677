import { ChangeEvent, ReactElement } from 'react';

import { AccountFormField } from '@halo-common/formComponents';
import { GenerateReportFormFields } from '@halo-common/schemas';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedAlert, LocalizedTextField, Stack } from '@halodomination/halo-fe-common';
import { MenuItem } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const alertSx = {
  backgroundColor: 'error.background',
};

const inputLabelProps = {
  shrink: true,
};

export type GenerateReportFormProps = {
  onAccountChange?: (option?: AccountTypeAheadOption | null) => void;
  onCurrencyChange?: (id: number) => void;
  invalid?: boolean;
  error?: Error | null;
};

export const GenerateReportForm = ({
  onAccountChange,
  onCurrencyChange,
  invalid = false,
  error,
}: GenerateReportFormProps): ReactElement => {
  const { data: user } = useUserInfoQuery();

  const { control, formState } = useFormContext<GenerateReportFormFields>();
  const { errors } = formState;

  const filterAccount = user?.whiteLabel.filterAccountDropdown;
  const hasPositions = filterAccount ? filterAccount : undefined;
  const currencies = user?.whiteLabel.currencies ?? [];
  const disableCurrencyDropdown = !user?.settings.hasMultiCurrencyRole;
  const hasHouseholdReportTemplate = user?.settings.hasReportTemplate;
  const accountIdError = errors.accountOption?.message;
  const hasAccountOrHouseholdError = Boolean(accountIdError);

  const accountDropdownLabel = translations.portfolio.generateReportModal.selectAccountOrHousehold;
  const currencyInputLabel = translations.portfolio.generateReportModal.reportingCurrency;

  const errorAlert = invalid ? (
    <LocalizedAlert sx={alertSx} severity="error" variant="outlined">
      {error?.message || translations.portfolio.generateReportModal.downloadErrorAlert}
    </LocalizedAlert>
  ) : null;

  return (
    <Stack direction="column" spacing={4}>
      {errorAlert}
      <Stack direction="row" spacing={2} xs={[8, 4]}>
        <AccountFormField
          label={accountDropdownLabel}
          control={control}
          name="accountOption"
          onSelect={onAccountChange}
          error={hasAccountOrHouseholdError}
          helperText={accountIdError}
          rules={{
            disableHouseholds: !hasHouseholdReportTemplate,
            enabledPositionTypeahead: true,
            hasPositions,
          }}
        />
        <Controller
          control={control}
          name="currencyId"
          render={({ field: { onChange, ref, value } }) => (
            <LocalizedTextField
              onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                void onChange(ev);
                const selectedCurrency = parseInt(ev.target.value);
                onCurrencyChange?.(selectedCurrency);
              }}
              inputRef={ref}
              slotProps={{ inputLabel: inputLabelProps }}
              label={currencyInputLabel}
              size="large"
              select
              fullWidth
              value={value}
              disabled={disableCurrencyDropdown}
            >
              {currencies.map(({ id, code }) => (
                <MenuItem key={id} value={id}>
                  {code}
                </MenuItem>
              ))}
            </LocalizedTextField>
          )}
        />
      </Stack>
    </Stack>
  );
};
