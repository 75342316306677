import { HOUR_MINUTE_TIME_ZONE_FORMAT, MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { CalendarOrderStatusEnum } from '@halo-common/enums';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { CalendarModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { ProgressBarEnum } from '@halodomination/halo-fe-common';
import { ITranslateParams } from '@transifex/native';
import { DateTime } from 'luxon';

export type UseProductProgressPropTypes = {
  calendar?: CalendarModel | null;
  isFixedIncome?: boolean;
};

export type UseProductProgressReturnTypes = {
  percentFilled: number;
  variant: ProgressBarEnum;
  label: string;
  status: CalendarOrderStatusEnum;
  dynamicContent: ITranslateParams;
};

export const useProductProgress = ({
  calendar,
  isFixedIncome,
}: UseProductProgressPropTypes): UseProductProgressReturnTypes => {
  const configureDateTime = useWhiteLabelDateConfiguration();

  const todaysDateTime = configureDateTime(DateTime.utc().toISO()) as DateTime;
  const expirationDateTime = configureDateTime(calendar?.expirationDate) ?? todaysDateTime;
  const startShowingDateTime = configureDateTime(calendar?.startShowingDate) ?? todaysDateTime;

  const formattedDate = expirationDateTime?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
  const formattedTime = expirationDateTime?.toFormat(HOUR_MINUTE_TIME_ZONE_FORMAT);

  const isCalendarClosed = expirationDateTime && todaysDateTime > expirationDateTime;

  const hasSameDay = todaysDateTime.hasSame(expirationDateTime, 'day');
  const hasSameMonth = todaysDateTime.hasSame(expirationDateTime, 'month');
  const hasSameYear = todaysDateTime.hasSame(expirationDateTime, 'year');

  const isSevenDaysBeforeClosingDay = expirationDateTime.minus({ days: 7 });
  const isExpirationIsToday = hasSameDay && hasSameMonth && hasSameYear;
  const isClosingToday = isExpirationIsToday && expirationDateTime.diffNow('milliseconds').milliseconds > 0;

  const progress = {
    label: '',
    variant: ProgressBarEnum.PRIMARY,
    percentFilled: 100,
    status: CalendarOrderStatusEnum.Open,
    dynamicContent: {
      date: formattedDate,
      time: formattedTime,
    },
  };

  if (isCalendarClosed) {
    progress.label = isFixedIncome
      ? translations.calendars.common.closedDate
      : translations.calendars.common.closedDateTime;
    progress.variant = ProgressBarEnum.DISABLED;
    progress.status = CalendarOrderStatusEnum.Closed;
  } else if (calendar?.paused) {
    progress.label = translations.calendars.common.offeringPaused;
    progress.variant = ProgressBarEnum.DISABLED;
  } else if (isClosingToday) {
    progress.label = isFixedIncome
      ? translations.calendars.common.closesToday
      : translations.calendars.common.closesTodayTime;
    progress.variant = ProgressBarEnum.ERROR;
    progress.percentFilled = 95;
    progress.status = CalendarOrderStatusEnum.Closing;
  } else if (todaysDateTime > isSevenDaysBeforeClosingDay) {
    const daysPassed = startShowingDateTime.diffNow('days').days;
    const totalDays = expirationDateTime.diff(startShowingDateTime, 'days').toObject().days ?? 1;

    progress.percentFilled = Math.abs(daysPassed / totalDays) * 100;
    progress.label = isFixedIncome
      ? translations.calendars.common.closesDate
      : translations.calendars.common.closesDateTime;
  } else {
    const daysPassed = startShowingDateTime.diffNow('days').days;
    const totalDays = expirationDateTime.diff(startShowingDateTime, 'days').toObject().days ?? 1;

    progress.percentFilled = Math.abs(daysPassed / totalDays) * 100;
    progress.label = translations.calendars.common.closesDate;
  }

  return progress;
};
