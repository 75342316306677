import { ReactElement } from 'react';

import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { UnderlyingAutocomplete } from '@halo-common/components';
import { UnderlyingModel } from '@halo-common/models';
import { useSetAtom } from 'jotai';

export type UnderlyingFieldProps = {
  handleChange: (value: string | Array<UnderlyingModel>, key: string) => void;
};

export const UnderlyingField = (): ReactElement => {
  const setSearchFields = useSetAtom(orderBookQueryAtom);

  const handleUnderlyingChange = (selection: Array<UnderlyingModel>) => {
    setSearchFields({
      filters: {
        underlying: selection,
      },
    });
  };

  return (
    <UnderlyingAutocomplete label="Search By Underlying Asset(s)" name="tradables" onChange={handleUnderlyingChange} />
  );
};
