import { ReactElement } from 'react';

import { notificationFiltersAtom } from '@halo-atoms/profile';
import { DateRangePicker } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { NotificationTypeModel } from '@halo-data-sources/models';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack, Switch } from '@mui/material';
import type { DateRange } from '@mui/x-date-pickers-pro';
import { useAtom } from 'jotai';
import { DateTime } from 'luxon';

import { NotificationTypeAutocomplete } from '../../NotificationTypesAutocomplete';

const underlyingAutocompleteChipSx = {
  color: 'primary.main',
  backgroundColor: 'primary.background',
  '& .MuiChip-deleteIcon': {
    color: 'primary.main',
  },
};

export const UserProfileNotificationsFilters = (): ReactElement => {
  const [notificationsFilters, setNotificationsFilters] = useAtom(notificationFiltersAtom);

  const { endDate, startDate, notificationTypes } = notificationsFilters;

  const handleTypesAutocompleteChange = (selections: Array<NotificationTypeModel>) => {
    setNotificationsFilters({ notificationTypes: selections });
  };

  const handleDateRangeChange = (value: DateRange<DateTime>) => {
    const newStartDate = value[0];
    const formattedStartDate = newStartDate ? newStartDate.toFormat('yyyy-LL-dd') : startDate;

    const newEndDate = value[1];
    const formattedEndDate = newEndDate ? newEndDate.toFormat('yyyy-LL-dd') : endDate;

    setNotificationsFilters({ startDate: formattedStartDate, endDate: formattedEndDate });
  };

  const handleOnlyUnreadChange = () => {
    setNotificationsFilters({ onlyUnread: !notificationsFilters.onlyUnread });
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} sx={{ mb: 2, width: { lg: '50%', xs: '100%' } }}>
        <NotificationTypeAutocomplete
          sx={{ flex: 2 }}
          label={translations.profile.notifications.searchByType}
          ChipProps={{ sx: underlyingAutocompleteChipSx, size: 'small' }}
          variant="outlined"
          value={notificationTypes}
          onChange={handleTypesAutocompleteChange}
        />
        <DateRangePicker
          sx={{ flex: 1 }}
          label={translations.profile.notifications.notificationDatePickerLabel}
          onChange={handleDateRangeChange}
          startDate={startDate}
          endDate={endDate}
        />
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Switch onChange={handleOnlyUnreadChange} color="primary" checked={notificationsFilters.onlyUnread} />
        <LocalizedTypography>{translations.profile.notifications.onlyRead}</LocalizedTypography>
      </Stack>
    </Stack>
  );
};
