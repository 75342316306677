import { ReactElement, ReactNode } from 'react';

import { Iconography, IconographyProps, Stack } from '@halodomination/halo-fe-common';
import { ListItem, ListItemIcon, ListItemText, Slide } from '@mui/material';

const containerStyling = {
  marginBottom: 4,
  justifyContent: { xs: 'center', md: 'unset' },
};
const iconStyling = {
  marginTop: 0,
  minWidth: 48,
};
const reminderStyling = {
  margin: 0,
  maxWidth: { xs: 247, md: 'unset' },
};

export type ReminderProps = {
  message: string;
  icon: IconographyProps['iconName'];
  timeout?: number;
  children?: ReactNode;
};

export const Reminder = ({ children, message, icon, timeout = 750 }: ReminderProps): ReactElement => {
  const hasChildren = Boolean(children);

  const messageText = (
    <ListItemText sx={reminderStyling} primaryTypographyProps={{ variant: 'h6' }} primary={message} />
  );

  return (
    <Slide direction="right" in timeout={timeout}>
      <ListItem disableGutters alignItems="flex-start" sx={containerStyling}>
        <ListItemIcon sx={iconStyling}>
          <Iconography color="secondary.main" iconName={icon} size="1.25x" />
        </ListItemIcon>
        {hasChildren ? (
          <Stack direction="column" spacing={3}>
            {messageText}
            {children}
          </Stack>
        ) : (
          messageText
        )}
      </ListItem>
    </Slide>
  );
};
