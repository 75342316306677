import { ReactElement } from 'react';

import { useModalState } from '@halo-common/hooks';
import { MUST_COMPLETE_ONBOARDING_MODAL } from '@halo-common/modals';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Iconography } from '@halodomination/halo-fe-common';
import { Button, ButtonProps } from '@mui/material';

const buttonSx = {
  minWidth: 'unset',
};

export type OrderBookAuctionCreateSimilarButtonProps = Partial<ButtonProps> & {
  noteId: number;
};

export const OrderBookAuctionCreateSimilarButton = ({
  noteId,
  ...props
}: OrderBookAuctionCreateSimilarButtonProps): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();
  const { setOpen } = useModalState(MUST_COMPLETE_ONBOARDING_MODAL);

  const isOnboardingCompleted = userInfo?.organization?.isOnboardingCompleted;

  const openOnboardingModal = () => setOpen(true);

  const openNoteCreator = () => {
    const url = `/note_creator?note=${noteId}`;
    window.open(url, '_blank');
  };

  const onClick = () => {
    if (!isOnboardingCompleted) openOnboardingModal();
    else openNoteCreator();
  };

  return (
    <Button
      sx={buttonSx}
      color="primary"
      type="button"
      variant="outlined"
      onClick={onClick}
      size="extraSmall"
      aria-label={translations.pdm.common.createSimilarButton}
      {...props}
    >
      <Iconography sx={{ m: `0 !important` }} iconName="redo" />
    </Button>
  );
};
