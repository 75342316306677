import { translations } from '@halo-common/translations';
import {
  DisclaimerText,
  DocumentLinks,
  OnboardingLayout,
  RegisterForm,
  RegisterFormSkeleton,
} from '@halo-modules/app/v2/onboarding';
import { LocalizedTypography, PageComponent, PageMeta } from '@halodomination/halo-fe-common';

const RegisterPage: PageComponent = () => (
  <OnboardingLayout variant="small" fallback={<RegisterFormSkeleton />}>
    <LocalizedTypography mb={6} variant="h4">
      {translations.onboarding.register.createYourAccount}
    </LocalizedTypography>
    <RegisterForm />
    <DisclaimerText />
    <DocumentLinks />
  </OnboardingLayout>
);

export const RegisterPageMeta: PageMeta = {
  pageName: 'Register',
  route: '/app/v2/onboarding/register',
  iconName: 'question-circle',
};

RegisterPage.pageName = RegisterPageMeta.pageName;
RegisterPage.route = RegisterPageMeta.route;
RegisterPage.iconName = RegisterPageMeta.iconName;

export default RegisterPage;
