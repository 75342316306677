import { getClientConfiguration, request } from '@halo-data-sources/clients';
import { ApiCountryModel } from '@halo-data-sources/models/General/Country';

const BASE_PATH = '';
const COUNTRY_PATH = `${BASE_PATH}/country`;

export const fetchCountries = async (): Promise<Array<ApiCountryModel>> => {
  const clientConfiguration = await getClientConfiguration(COUNTRY_PATH);

  const response = await request<Array<ApiCountryModel>>(`${clientConfiguration.basePath}/list`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};
