import { ReactElement } from 'react';

import { DisclaimerUserModel } from '@halo-common/models';
import {
  useUserDisclaimerAcceptanceDownloadMutation,
  useUserDisclaimerDownloadMutation,
} from '@halo-data-sources/mutations';
import { AgreedIcon } from '@halo-modules/app';
import { Iconography, Modal, Stack } from '@halodomination/halo-fe-common';
import { Button, Typography } from '@mui/material';

const contentSx = {
  py: 3,
  pl: 3,
  pr: 4,
  '& > *': {
    margin: 1,
  },
};

export type CustodyFormDownloadModalProps = {
  open: boolean;
  custodyForm: DisclaimerUserModel;
  onClose: () => void;
};

export const CustodyFormDownloadModal = ({
  open,
  custodyForm,
  onClose,
}: CustodyFormDownloadModalProps): ReactElement => {
  const { mutate: downloadDisclaimer } = useUserDisclaimerDownloadMutation();
  const { mutate: downloadDisclaimerAcceptance } = useUserDisclaimerAcceptanceDownloadMutation();

  const completed = Boolean(custodyForm?.lastAcceptance);
  const modalTitle = `Download ${custodyForm?.name ?? 'Unknown'}`;

  const subtitle = completed
    ? 'This document has already been completed. If you need to make changes you can re-download the template and upload a revised version.'
    : '';

  const completedAt = custodyForm?.lastAcceptance
    ? new Date(custodyForm?.lastAcceptance?.createdAt).toLocaleDateString()
    : null;

  const handleDownloadAcceptance = () => {
    downloadDisclaimerAcceptance({
      disclaimerId: custodyForm.lastAcceptance?.id,
      filename: custodyForm.lastAcceptance?.document.filename,
    });
  };

  const handleDownloadTemplate = () => {
    downloadDisclaimer({ disclaimerId: custodyForm.id, filename: custodyForm.document.filename });
  };

  const footerMessage = completed ? (
    <Typography variant="body1">
      <AgreedIcon agreed />
      Document Completed On ({completedAt})
    </Typography>
  ) : null;

  return (
    <Modal
      title={modalTitle}
      subtitle={subtitle}
      footer={
        <Stack direction="row" justify="space-between">
          {footerMessage}
          <Button color="primary" variant="contained" onClick={onClose}>
            Cancel
          </Button>
        </Stack>
      }
      onClose={onClose}
      open={open}
      ContentProps={{ sx: contentSx }}
    >
      <>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleDownloadAcceptance}
          endIcon={<Iconography iconName="arrow-alt-circle-down" color="primary.main" />}
          component="span"
          disabled={!completed}
        >
          Download Completed Document
        </Button>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          disabled={!custodyForm}
          onClick={handleDownloadTemplate}
          endIcon={<Iconography iconName="file-alt" color="primary.main" />}
          component="span"
        >
          Download Template
        </Button>
      </>
    </Modal>
  );
};
