import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type WholesalersQueryKeys = {
  all: QueryKeyFactoryFn;
  wholesalers: QueryKeyFactoryFn;
};

export const WholesalersQueryKeyFactory: WholesalersQueryKeys = {
  all: () => ['wholesalers'],
  wholesalers: (payload) => [...WholesalersQueryKeyFactory.all(), 'admin', hash(payload)],
};
