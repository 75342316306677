import { ReactElement, ReactNode } from 'react';

import { Box, Container } from '@mui/material';

export type SubNavProps = {
  name: string;
  noPadding?: boolean;
  children: ReactNode;
};

export const SubNav = ({ children, noPadding = false }: SubNavProps): ReactElement => {
  const contentSx = {
    pt: noPadding ? 0 : 3,
    pb: 0,
    px: 0,
    width: '100%',
  };

  return (
    <Container maxWidth="xl">
      <Box sx={contentSx}>{children}</Box>
    </Container>
  );
};
