export const PRODUCT_DETAILS_MODAL_POST_TRADE = {
  documentNameHistoricalReport: 'Historical Report',
  documentNameTermsheet: 'Termsheet',
  documentNameValuationHistory: 'Valuation History',
  generatePerformanceReportError: 'There is no historical report available for the requested note type at this time',
  emptyAnalytics:
    'Market data not available or is limited for this Note. If you need assistance, please reach out to your platform contact.',
  lifecycleFieldFinalValuation: 'Final Valuation',
  lifecycleFieldInitialDate: 'Initial Date',
  lifecycleFieldIssueDate: 'Issue Date',
  month: 'Month',
  months: 'Months',
  noTerm: 'No Term',
  noteValueChartYAxis: 'Note Level',
  currentLevel: 'Current Level',
  initialLevel: 'Initial Level',
  change: 'Change',
  barrierLevel: 'Barrier Level',
  distToBarrier: 'Dist. To Barrier',
  noteValue: 'Note Value',
  annualizedYield: 'Annualized Yield',
  earlyRedemption: 'Early Redemption',
  commission: 'Commission',
  couponFrequency: 'Coupon Frequency',
  couponProtection: 'Coupon Proteciton',
  couponType: 'Coupon Type',
  digitalCoupon: 'Digital Coupon',
  maxReturn: 'Max Return',
  participation: 'Participation',
  guaranteedCouponMonthly: 'Fixed Coupons Monthly',
  guaranteedCouponQuarterly: 'Fixed Coupons Quarterly',
  guaranteedCouponSemiannually: 'Fixed Coupons Semiannually',
  guaranteedCouponAnnually: 'Fixed Coupons Annually',
  recallCouponFeature: 'Coupon Paid When Called',
  memoryCouponMonthly: 'Memory Coupons Monthly',
  memoryCouponQuarterly: 'Memory Coupons Quarterly',
  memoryCouponsSemiannually: 'Memory Coupons Semiannually',
  memoryCouponsAnnuall: 'Memory Coupons Annually',
  contingentCouponMonthly: 'Contingent Coupons Monthly',
  contingentCouponQuarterly: 'Contingent Coupons Quarterly',
  contingentCouponSemiannually: 'Contingent Coupons Semiannually',
  contingentCouponAnnually: 'Contingent Coupons Annually',
  defaultCouponMonthly: 'Coupons Monthly',
  defaultCouponQuarterly: 'Coupons Quarterly',
  defaultCouponSemiannually: 'Coupons Semiannually',
  defaultCouponAnnually: 'Coupons Annually',
  autocallMonthly: 'Autocallable Monthly',
  autocallQuarterly: 'Autocallable Quarterly',
  autocallableSemiannually: 'Autocallable Semiannually',
  autocallableAnnually: 'Autocallable Annually',
  issuerCallMonthly: 'Issuer Callable Monthly',
  issuerCallQuarterly: 'Issuer Callable Quarterly',
  issuerCallSemiannually: 'Issuer Callable Semiannually',
  issuerCallAnnually: 'Issuer Callable Annually',
  customAutoCallMonthly: 'Custom Autocallable Monthly: {callabilitycustomtriggers}',
  customAutoCallQuarterly: 'Custom Autocallable Quarterly: {callabilitycustomtriggers}',
  customAutoCallSemiannually: 'Custom Autocallable Semiannually: {callabilitycustomtriggers}',
  customAutoCallAnnually: 'Custom Autocallable Annually: {callabilitycustomtriggers}',
  customIssuerCallMonthly: 'Custom Issuer Callable Monthly: {callabilitycustomtriggers}',
  customIssuerCallQuarterly: 'Custom Issuer Callable Quarterly: {callabilitycustomtriggers}',
  customIssuerCallSemiannually: 'Custom Issuer Callable Semiannually: {callabilitycustomtriggers}',
  customIssuerCallAnnually: 'Custom Issuer Callable Annually: {callabilitycustomtriggers}',
  nonCallMonths: 'Not Callable for {callabilitynoncallperiod, plural, one {# Month} other {# Months}}',
  gearedPercent: 'Geared {percent}%',
  viewingPositionsOf: 'Viewing positions of',
  termMonths: '{term, plural, one {# Month} other {# Months}}',
  termYears: '{term, plural, one {# Year} other {# Years}}',
  autoCallableQuarterly: 'AutoCallable Quarterly',
  autoCallableMonthly: 'AutoCallable Monthly',
  autoCallableSemiannually: 'AutoCallable Semiannually',
  autoCallableAnnually: 'AutoCallable Annually',
  monthlyCoupon: 'Monthly Coupon',
  quarterlyCoupon: 'Quarterly Coupon',
  semiannualCoupon: 'Semiannual Coupon',
  annualCoupon: 'Annual Coupon',
  allocationAmount: 'Allocation Amount',
  changeAllocationTitle: 'Change Allocation',
  editPositionAllocationAmountError: 'Allocation amount must be greater or equal to 1.',
  allocationAddSuccess: 'Successfully added allocation.',
  allocationEditSuccess: 'Successfully edited allocation.',
  allocationDeleteSuccess: 'Successfully deleted allocation.',
  pendingExcelExport: 'Exporting Position Allocation details',
  successExcelExport: 'Successfully generated Position Allocation details',
};
