import { accountAllocationCSVUploadModel } from '@halo-data-sources/mutations';
import { useSnackbar } from '@halodomination/halo-fe-common';
import * as ExcelJS from 'exceljs';

type ExcelFileUploadFunction = (data: Array<Record<string, string>>) => void;

export const useExcelFileUploader = (onUpload: ExcelFileUploadFunction, headerNames: [string, string]) => {
  const { enqueueErrorEvent } = useSnackbar();

  return (selectedFile: File) => {
    const workbook = new ExcelJS.Workbook();
    const reader = new FileReader();
    const fileType = selectedFile.name.split('.').at(-1);
    const unsupportedFileMessage = `Unsupported file type: ${fileType}. Please upload a .csv, .xls, or .xlsx file.`;

    const handleCSV = () => {
      reader.onload = (e) => {
        const text = String(e?.target?.result || '');
        const lines = text.split('\r\n');

        const csvData = lines.map((line) => {
          const [accountId, quantity] = line.split(',');
          if (accountId === headerNames[0]) return null;
          const id = accountId.replace(/[^A-Za-z0-9]/g, '');
          if (!id && !quantity) return null;
          return { accountId: id, quantity };
        });

        const allocationData = csvData.filter(Boolean) as accountAllocationCSVUploadModel;

        onUpload(allocationData);
      };

      reader.readAsText(selectedFile);
    };

    const handleXLSX = () => {
      reader.onload = (e) => {
        const data = e?.target?.result;

        if (!data) return;

        const allocationData = [] as accountAllocationCSVUploadModel;

        void workbook.xlsx.load(data as Buffer).then((book) => {
          book.eachSheet((sheet) => {
            const rows = sheet
              .getRows(1, sheet.rowCount)
              ?.map((row) => {
                const accountId = row.getCell(1).value;
                if (accountId === headerNames[0]) return null;
                const quantity = row.getCell(2).value;
                if (!accountId && !quantity) return null;
                const id = String(accountId).replace(/[^A-Za-z0-9]/g, '');
                return { accountId: id, quantity: String(quantity) };
              })
              .filter(Boolean) as accountAllocationCSVUploadModel;

            allocationData.push(...rows);
          });

          onUpload(allocationData);
        });
      };

      reader.readAsArrayBuffer(selectedFile);
    };

    if (fileType === 'csv') handleCSV();
    else if (fileType === 'xls') handleXLSX();
    else if (fileType === 'xlsx') handleXLSX();
    else enqueueErrorEvent({ message: unsupportedFileMessage });
  };
};
