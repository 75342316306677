import { Children, isValidElement, ReactElement, ReactNode } from 'react';

import { useLegacyRouter } from '@halo-common/providers';
import { Jumbotron, JumbotronProps, Tabs, TabsProps } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, Container, Stack, tabsClasses } from '@mui/material';

const containerSx = (theme: HaloTheme) => ({
  background: `linear-gradient(270deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
  width: '100%',
});

const stackSx = {
  gap: 4,
  py: 4,
  width: '100%',
  '& > div': {
    flex: '0 1 auto',
    width: 'fit-content',
  },
};

const jumbotronSx = {
  backgroundColor: 'transparent',
  p: 0,
  '& > *:last-child': {
    pb: 0,
  },
};

const tabsSx = (theme: HaloTheme) => ({
  background: `linear-gradient(270deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
  justifyContent: 'center',
  px: 0,
  pl: { xl: 6, sm: 2 },
  [`& >.${tabsClasses.scroller}`]: {
    maxWidth: 'xl',
  },
});

const noTabsContentContainerSx = {
  pt: 3,
  pb: 0,
  px: 0,
  width: '100%',
};

export type SubNavLayoutStyleProps = {
  hasDescription?: boolean;
};

export type SubNavLayoutProps = {
  title: string;
  description?: string;
  hideTabs?: boolean;
  hideContentPadding?: boolean;
  children: ReactNode;
  TabsProps?: Partial<TabsProps>;
  Localization?: JumbotronProps['Localization'];
  right?: ReactNode;
};

export const SubNavLayout = ({
  children,
  title,
  description,
  right,
  hideTabs = false,
  hideContentPadding = false,
  Localization,
  TabsProps,
}: SubNavLayoutProps): ReactElement => {
  const { combinedQueryParams, query } = useLegacyRouter();

  const components = Children.map(children, (child) => (isValidElement(child) && child.props.name) ?? '');
  const tabNames = components?.filter(Boolean) ?? [];

  const hasTabs = !hideTabs && tabNames.length;

  const rightContentSx = hasTabs ? { '@media (min-width: 800px)': { transform: 'translateY(20px)' } } : undefined;

  const queryTab = query?.tab as string | undefined;
  const hasDefaultTab = Boolean(hasTabs && queryTab);
  const foundTab = tabNames.findIndex((tabName) => tabName.toLowerCase() === queryTab?.toLowerCase());
  const selectedTab = foundTab !== -1 ? foundTab : 0;
  const defaultTab = hasDefaultTab ? selectedTab : 0;

  const handleTabChange = (index: number) => {
    const tab = tabNames[index].toLowerCase();
    const query = new URLSearchParams({ tab });
    combinedQueryParams(query);
    TabsProps?.onChange?.(index);
  };

  const tabs = hasTabs ? (
    <Tabs
      tabs={tabNames}
      defaultTab={defaultTab}
      aria-label="page sub navigation"
      color="primary.dark"
      value={selectedTab}
      slotProps={{ tabs: { sx: tabsSx } }}
      {...TabsProps}
      onChange={handleTabChange}
    >
      {children}
    </Tabs>
  ) : null;

  const content = hideTabs ? (
    hideContentPadding ? (
      <Box>{children}</Box>
    ) : (
      <Container maxWidth="xl">
        <Box sx={noTabsContentContainerSx}>{children}</Box>
      </Container>
    )
  ) : (
    tabs
  );

  return (
    <>
      <Box id="haloPageHeader" sx={containerSx}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between" flexWrap="wrap" sx={stackSx}>
            <Jumbotron subtitle={description} Localization={Localization} sx={jumbotronSx}>
              {title}
            </Jumbotron>
            <Box sx={rightContentSx}>{right}</Box>
          </Stack>
        </Container>
      </Box>
      {content}
    </>
  );
};
