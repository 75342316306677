import { PortfolioPositionDetailsQueryModel } from '@halo-atoms/portfolio';
import { AssetIdentifierEnum } from '@halo-common/enums';
import {
  PaginationModel,
  PortfolioPositionsNoteFeaturesModel,
  PositionsDetailsSectionModel,
} from '@halo-common/models';
import { getPortfolioNoteFeatures, getPortfolioPositions } from '@halo-data-sources/clients';
import { ApiPositionsMapper, PaginationMapper } from '@halo-data-sources/mappers';
import { PortfolioQueryKeyFactory, useUserInfoQuery } from '@halo-data-sources/queries';
import { UseQueryOptions, UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

export type PortfolioPositionsQueryResult = {
  sections: Array<PositionsDetailsSectionModel>;
  features: Array<PortfolioPositionsNoteFeaturesModel>;
  filters: Array<string>;
  pagination: PaginationModel;
};

const getPortfolioPositionsQueryFn = async (
  options: PortfolioPositionDetailsQueryModel,
  primaryAssetIdentifier?: AssetIdentifierEnum,
) => {
  const {
    accountIds,
    householdIds,
    filters,
    includeInactive = false,
    features,
    synced,
    sort,
    page,
    pageLength,
  } = options;

  const hasNoteFeatures = features && features.length;
  const noteFeaturePayload = hasNoteFeatures ? { note_features: features } : undefined;

  const updatedFiltersPayload = Object.keys(filters).map((filterKey) => ({
    field: filterKey,
    value: filters[filterKey],
  }));

  const sharedPayload = {
    account_selector: {
      account_ids: accountIds,
      advisee_ids: householdIds,
      synced: synced ?? null,
    },
    filters: [...updatedFiltersPayload],
    ...noteFeaturePayload,
    include_inactive: includeInactive,
  };

  const detailPayload = {
    ...sharedPayload,
    page,
    page_length: pageLength,
    sort,
  };

  const response = await getPortfolioPositions(detailPayload);
  const noteFeaturesResponse = await getPortfolioNoteFeatures(sharedPayload);

  const sections = ApiPositionsMapper.toPositionsSections({
    positions: response.positions,
    sections: response.sections,
    primaryAssetIdentifier,
  });

  const pagination = PaginationMapper.toPaginationModel(response.pagination);

  const updateNoteFeatures = noteFeaturesResponse.note_features
    .map(ApiPositionsMapper.toPositionsNoteFeature)
    .filter((feature) => feature.count > 0);

  const updatedFilters = [...new Set(sections.reduce((acc: string[], section) => [...acc, section.name], []))];

  return { sections, filters: updatedFilters, features: updateNoteFeatures, pagination };
};

export const usePortfolioPositionsQuery = (
  payload: PortfolioPositionDetailsQueryModel,
  options?: Partial<UseQueryOptions<PortfolioPositionsQueryResult, Error>>,
): UseQueryResult<PortfolioPositionsQueryResult, Error> => {
  const { data: user } = useUserInfoQuery();

  const primaryAssetIdentifier = user?.whiteLabel.primaryAssetIdentifier;

  return useQuery<PortfolioPositionsQueryResult, Error>({
    queryKey: PortfolioQueryKeyFactory.positionsV2(payload),
    queryFn: () => getPortfolioPositionsQueryFn(payload, primaryAssetIdentifier),
    placeholderData: keepPreviousData,
    ...options,
  });
};
