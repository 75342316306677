import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';

import { AuctionAdminModel, IssuerModel, OrderHubOrderedColumnsModel } from '@halo-common/models';
import { AUCTION_DATE_FORMAT, AUCTION_END_TIME_FORMAT } from '@halo-data-sources/mappers';
import { useIssuersQuery } from '@halo-data-sources/queries';
import {
  getIssuerCellClassName,
  getParticipatingIssuerQuote,
  OrderHubAuctionContext,
  useOrderHubEditMode,
} from '@halo-modules/admin';
import { OrdersActions } from '@halo-stores/Orders';
import {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  HaloDataGrid,
  HaloDataGridProps,
  mapNumberToLocalCurrency,
} from '@halodomination/halo-fe-common';
import { SxProps } from '@mui/material';
import { GridPinnedColumnFields } from '@mui/x-data-grid-pro';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';

import { AuctionTableIssuerCell } from './AuctionTableIssuerCell';
import { AuctionTableWhoCell, AuctionTableWhoCellProps } from './AuctionTableWhoCell';

const rowSx: SxProps = {
  ['& .who-cell']: {
    backgroundColor: 'background.default',
  },
  ['& .issuer-empty']: {
    backgroundColor: 'background.default',
  },
  ['& .issuer-non-participant']: {
    backgroundColor: 'grey.300',
    '&:hover': {
      backgroundColor: 'grey.300',
      cursor: 'pointer',
      transition: 'background-color 150ms linear',
    },
  },
  ['& .issuer-comment']: {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    position: 'relative',
    '&:hover': {
      backgroundColor: 'primary.dark',
      cursor: 'pointer',
      transition: 'background-color 150ms linear',
    },
  },
  ['& .issuer-passed']: {
    backgroundColor: 'grey.400',
    position: 'relative',
  },
  ['& .issuer-winner']: {
    backgroundColor: 'success.main',
    color: 'success.contrastText',
    position: 'relative',
  },
  // [`& .${gridClasses}`]: {
  //   '&:hover': {
  //     backgroundColor: 'transparent !important',
  //   },
  // },
};

export type AuctionDetailsColumnHeader = {
  [fieldName: string]: GridColDef;
};

const columnHeaders: AuctionDetailsColumnHeader = {
  id: {
    field: 'id',
    align: 'left',
    headerName: 'AUCTION ID',
  },
  cusip: {
    field: 'cusip',
    align: 'left',
    headerName: 'CUSIP',
  },
  isin: {
    field: 'isin',
    align: 'left',
    headerName: 'ISIN',
  },
  notional: {
    field: 'notional',
    align: 'left',
    headerName: 'NOTIONAL',
  },
  endDateTime: {
    field: 'endDateTime',
    headerName: 'END TIME',
  },
  targetTradeDate: {
    field: 'targetTradeDate',
    headerName: 'TRADE DATE',
  },
  targetStrikeDate: {
    field: 'targetStrikeDate',
    headerName: 'STRIKE DATE',
  },
  targetSettleDate: {
    field: 'targetSettleDate',
    headerName: 'SETTLE DATE',
  },
  submittedDate: {
    field: 'submittedDate',
    headerName: 'SUBMITTED DATE',
  },
};

export type OrderedColumn = {
  field: string;
  targetIndex: number;
  oldIndex: number;
};

export type AuctionTableDataGridProps = Partial<HaloDataGridProps> & {
  isLoading?: boolean;
  cta: AuctionTableWhoCellProps['cta'];
  data: Array<AuctionAdminModel>;
};

export type AuctionTableProps = {
  DataGridProps: AuctionTableDataGridProps;
  gridRef: HaloDataGridProps['apiRef'];
  issuersVisible?: boolean;
  onColumnPin: (updatedPinnedColumns: GridPinnedColumnFields) => void;
  onColumnReorder: (updatedColumnOrder: OrderHubOrderedColumnsModel) => void;
  defaultPinnedColumns?: GridPinnedColumnFields;
  defaultOrderedColumns?: OrderHubOrderedColumnsModel;
};

export const AuctionTable = ({
  DataGridProps,
  gridRef,
  issuersVisible = false,
  onColumnPin,
  onColumnReorder,
  defaultPinnedColumns,
  defaultOrderedColumns,
}: AuctionTableProps): ReactElement => {
  const dispatch = useDispatch();

  const { data: allowedIssuers, isPending } = useIssuersQuery();

  const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumnFields | undefined>(defaultPinnedColumns);

  const { height, autoPageSize, hideFooter } = useContext(OrderHubAuctionContext);
  const canEdit = useOrderHubEditMode();

  const { cta, data = [] } = DataGridProps;
  const issuers = allowedIssuers ?? [];

  // TODO: Fix
  const isLoading = false;

  const defaultDetailsOrder = [
    'id',
    'cusip',
    'isin',
    'notional',
    'endDateTime',
    'targetTradeDate',
    'targetStrikeDate',
    'targetSettleDate',
    'submittedDate',
  ];

  const detailsHeadersOrder = defaultOrderedColumns?.orderedDetails.length
    ? [...defaultOrderedColumns.orderedDetails]
    : [...defaultDetailsOrder];

  const defaultIssuersOrder = issuers.map((issuer) => issuer.name);

  const issuersHeadersOrder = defaultOrderedColumns?.orderedIssuers.length
    ? [...defaultOrderedColumns.orderedIssuers]
    : defaultIssuersOrder;

  defaultIssuersOrder.forEach((issuer) => {
    if (!issuersHeadersOrder.includes(issuer)) {
      issuersHeadersOrder.push(issuer);
    }
  });

  const issuerMap: { [key: string]: IssuerModel } = issuers.reduce(
    (map, issuer) => ({ ...map, [issuer.name.toLowerCase()]: issuer }),
    {},
  );

  const handleReorder = (list: Array<string>, value: string, targetIndex: number) => {
    const updatedList = [...list];
    const oldIndex = updatedList.indexOf(value);

    if (targetIndex > oldIndex) {
      for (let i = oldIndex; i <= targetIndex; i++) updatedList[i] = updatedList[i + 1];
    } else {
      for (let i = oldIndex; i >= targetIndex; i--) updatedList[i] = updatedList[i - 1];
    }

    updatedList[targetIndex] = value;

    return updatedList;
  };

  const handleIssuersReorder = (updatedColumn: string, givenIndex: number) => {
    const targetIndex = givenIndex - 2;
    const updatedColumnOrder = handleReorder(issuersHeadersOrder, updatedColumn, targetIndex);
    onColumnReorder?.({ orderedDetails: detailsHeadersOrder, orderedIssuers: updatedColumnOrder });
  };

  const handleDetailsReorder = (updatedColumn: string, givenIndex: number) => {
    const targetIndex = givenIndex - 1;
    const updatedColumnOrder = handleReorder(detailsHeadersOrder, updatedColumn, targetIndex);
    onColumnReorder?.({ orderedDetails: updatedColumnOrder, orderedIssuers: issuersHeadersOrder });
  };

  const handleColumnReorder: GridEventListener<'columnHeaderDragEnd'> = (params) => {
    const { field } = params;

    const targetIndex = gridRef?.current.getColumnIndex(field);

    if (typeof targetIndex !== 'number') return;
    else if (issuersVisible) handleIssuersReorder(field, targetIndex);
    else handleDetailsReorder(field, targetIndex);
  };

  const commonDataColumnDefinitions: Partial<HaloDataGridProps['columns'][0]> = {
    editable: false,
    minWidth: 150,
    flex: 1,
    valueGetter: (_, row, definition) => {
      const { note } = row;
      const { currencyCode, currencySymbol } = note;

      const field = definition.field;

      const isNotional = field === 'notional';
      const isDateTime = field?.includes('Date');
      const isEndTime = field === 'endDateTime';
      const isDate = isEndTime || isDateTime;

      const format = isEndTime ? AUCTION_END_TIME_FORMAT : AUCTION_DATE_FORMAT;
      const value = isDate ? DateTime.fromISO(row[field]).toFormat(format) : row[field];

      return isNotional ? mapNumberToLocalCurrency(value, { currency: currencyCode }, currencySymbol) : value;
    },
  };

  const orderedIssuersHeaders: Array<IssuerModel> = issuersHeadersOrder
    .map((header) => issuerMap[header.toLowerCase()])
    .filter((header) => header !== undefined);

  const orderedDetailsHeaders: HaloDataGridProps['columns'] = detailsHeadersOrder
    .map((header) => (columnHeaders[header] ? { ...commonDataColumnDefinitions, ...columnHeaders[header] } : null))
    .filter((header) => header !== null);

  const issuerHeaders = useMemo(
    () =>
      [
        {
          ...commonDataColumnDefinitions,
          align: 'left',
          field: 'id',
          headerName: 'AUCTION ID',
        },
        ...orderedIssuersHeaders.map(({ name: header }) => ({
          ...commonDataColumnDefinitions,
          align: 'left',
          field: header,
          headerName: header,
          cellClassName: getIssuerCellClassName,
          renderCell: ({ field, row }: GridRenderCellParams<AuctionAdminModel>) => {
            const issuerName = field.toLowerCase();
            const { issuer, quote } = getParticipatingIssuerQuote(issuerName, row);
            return <AuctionTableIssuerCell issuer={issuer} quote={quote} loading={isLoading} />;
          },
        })),
      ] as HaloDataGridProps['columns'],
    [orderedIssuersHeaders],
  );

  const dataGridSpecificColumns = issuersVisible ? issuerHeaders : orderedDetailsHeaders;

  const columns: HaloDataGridProps['columns'] = [
    {
      field: 'who',
      headerName: 'WHO',
      minWidth: 300,
      cellClassName: 'who-cell',
      renderCell: ({ row }: GridRenderCellParams<AuctionAdminModel, AuctionAdminModel>) => {
        const { organizationName, salesRepresentative, buyer, note } = row;

        const underliers = note?.tradables?.map(({ name }) => name?.toUpperCase())?.join('; ');

        return (
          <AuctionTableWhoCell
            row={row}
            buyerName={buyer}
            salesRepName={salesRepresentative}
            underliers={underliers}
            orgName={organizationName}
            loading={isLoading}
            cta={cta}
          />
        );
      },
    },
    ...dataGridSpecificColumns,
  ];

  const handleCellClick: GridEventListener<'cellClick'> | undefined = issuersVisible
    ? ({ row, field }) => {
        const issuerName = field.toLowerCase();

        const { issuer: participatingIssuer, quote } = getParticipatingIssuerQuote(issuerName, row);

        if (canEdit && participatingIssuer && !participatingIssuer.passed) {
          dispatch(OrdersActions.selectQuote({ auction: row, quote, issuer: participatingIssuer }));
        }
      }
    : undefined;

  useEffect(() => {
    return gridRef?.current.subscribeEvent('columnHeaderDragEnd', handleColumnReorder);
  }, [gridRef, issuersVisible]);

  return (
    <HaloDataGrid
      autoPageSize={autoPageSize}
      hideFooter={hideFooter}
      apiRef={gridRef}
      rows={data}
      sx={rowSx}
      columns={columns}
      onCellClick={handleCellClick}
      disableRowSelectionOnClick
      height={height}
      rowHeight={165}
      pinnedColumns={pinnedColumns}
      onPinnedColumnsChange={(updatedPinnedColumns) => {
        setPinnedColumns(updatedPinnedColumns);
        onColumnPin(updatedPinnedColumns);
      }}
      disableColumnReorder={isPending}
    />
  );
};
