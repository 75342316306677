import { ReactElement } from 'react';

import { calendarManagerAtom } from '@halo-atoms/calendars';
import { ActionButton } from '@halo-common/components';
import { useCommonUserSetting } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { useGenerateCalendarPDFMutation } from '@halo-data-sources/mutations';
import { useCalendarsInfiniteQuery } from '@halo-data-sources/queries';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';

const stackSx = {
  gap: 2,
  marginBottom: 2,
};

const buttonSx = {
  textDecoration: 'underline',
  padding: 0,
};

export const CalendarExportButton = (): ReactElement => {
  const showExport = useCommonUserSetting('Show_Calendar_PDF_Export', 'Calendar Related Toggles');

  const { query } = useAtomValue(calendarManagerAtom);
  const { data } = useCalendarsInfiniteQuery(query);

  const totalResults = data?.pages?.[0]?.pagination.totalResults ?? 0;

  const { mutate, isPending } = useGenerateCalendarPDFMutation();

  const handlePDFExport = () => {
    mutate({ totalResults, filters: query, approved: true });
  };

  const dynamicContent = { count: totalResults };
  const exportButton = showExport ? (
    <ActionButton variant="text" onClick={handlePDFExport} loading={isPending} sx={buttonSx}>
      {translations.common.exportResults}
    </ActionButton>
  ) : null;

  return (
    <Stack direction="row" alignItems="center" sx={stackSx}>
      <LocalizedTypography variant="body2" dynamicContent={dynamicContent}>
        {translations.common.totalResults}
      </LocalizedTypography>
      {exportButton}
    </Stack>
  );
};
