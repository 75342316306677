import { ReactElement, ReactNode } from 'react';

import { portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { translations } from '@halo-common/translations';
import { hash } from '@halo-common/utils';
import {
  usePortfolioPositionAggregationQuery,
  usePortfolioPositionAggregationTemplatesQuery,
} from '@halo-data-sources/queries';
import { PortfolioPositionsAggregateTableFooter, PortfolioPositionsExcelExportButton } from '@halo-modules/app';
import {
  HaloDataGrid,
  HaloDataGridProps,
  LocalizedButton,
  LocalizedTypography,
  mapNumberToLocalCurrency,
} from '@halodomination/halo-fe-common';
import { Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { DateTime } from 'luxon';

const positiveGrowthSx = {
  color: 'common.charts.positive.main',
};

const negativeGrowthSx = {
  color: 'common.charts.negative.main',
};

const customSx = {
  display: 'flex',
  height: '100%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'flex-end',
};

const noteTypeBtnSx = {
  height: 'unset',
  minWidth: 'unset',
  p: 0,
  '&:hover': { backgroundColor: 'transparent' },
};

export type PortfolioPositionsAggregateProps = {
  loading: boolean;
};

export const PortfolioPositionsAggregateTable = ({ loading }: PortfolioPositionsAggregateProps): ReactElement => {
  const [positionData, setPositionData] = useAtom(portfolioPositionsManagerAtom);

  const { filters, query } = positionData;

  const { data: aggregateMeta, isPending: aggregateLoading } = usePortfolioPositionAggregationQuery(query);
  const { data: templateMeta } = usePortfolioPositionAggregationTemplatesQuery();

  const templates = templateMeta ?? [];

  const aggregate = aggregateMeta?.aggregations ?? [];
  const currencyCode = filters.currency.code;
  const currencySymbol = filters.currency.symbol;

  const isTableLoading = loading || aggregateLoading;

  const renderCurrencyCell = (value = 0, isStyledColumn = false): ReactNode => {
    if (value === undefined) return '--';

    const roundedValue = Math.round(value);
    const parsedValue = roundedValue === 0 ? 0 : roundedValue;
    const currencyValue = mapNumberToLocalCurrency(
      parsedValue,
      { currency: currencyCode, maximumFractionDigits: 0 },
      currencySymbol,
    );

    const isValuePositive = parsedValue > 0;

    const coloredTextSx = isValuePositive ? positiveGrowthSx : negativeGrowthSx;
    const shouldStyle = isStyledColumn && parsedValue !== 0;
    const colorSx = shouldStyle ? coloredTextSx : undefined;
    const cellSx = { ...customSx, ...colorSx };

    const shouldFormat = isStyledColumn && isValuePositive;
    const formattedValue = shouldFormat ? `+${currencyValue}` : currencyValue;

    return (
      <Typography sx={cellSx} variant="body2">
        {formattedValue}
      </Typography>
    );
  };

  const aggregateColumns: HaloDataGridProps['columns'] = [
    {
      field: 'name',
      disableColumnMenu: true,
      headerName: translations.portfolio.positions.aggregateTableProductTypeHeader,
      renderCell: ({ row, value }) => {
        const buttonText = value ?? 'N/A';
        const filterKey = row.filterKey;

        const convertedTab = { name: value, value: filterKey, count: row.positionCount };

        const selectedTemplate = templates.find((template) => template.name === filters?.composition);

        const handleClick = () => {
          setPositionData({ selectedNoteTab: { template: selectedTemplate, tab: convertedTab } });
        };

        return (
          <LocalizedButton sx={noteTypeBtnSx} variant="text" color="primary" disableRipple onClick={handleClick}>
            {buttonText}
          </LocalizedButton>
        );
      },
    },
    {
      field: 'totalNotional',
      disableColumnMenu: true,
      headerName: translations.portfolio.positions.aggregateTableTotalNotionalHeader,
      align: 'right',
      renderCell: ({ value }) => renderCurrencyCell(value),
    },
    {
      field: 'marketValue',
      disableColumnMenu: true,
      headerName: translations.common.marketValue,
      align: 'right',
      renderCell: ({ value }) => renderCurrencyCell(value),
    },
    {
      field: 'marketChange',
      disableColumnMenu: true,
      headerName: translations.portfolio.positions.aggregateTableMarketChangeHeader,
      align: 'right',
      renderCell: ({ value }) => {
        const isDefined = value !== undefined;
        const shouldStyle = isDefined && value !== 0;
        return renderCurrencyCell(value, shouldStyle);
      },
    },
    {
      field: 'nextEventDate',
      disableColumnMenu: true,
      headerName: translations.portfolio.positions.aggregateTableNextEventDateHeader,
      align: 'right',
      valueFormatter: (value: string) => (value ? DateTime.fromISO(value).toFormat(MONTH_DAY_YEAR_DATE_FORMAT) : '--'),
    },
  ];

  const slots = aggregate.length ? { footer: () => <PortfolioPositionsAggregateTableFooter /> } : undefined;

  const styledColumns = aggregateColumns.map((column) => ({ ...column, flex: 1, minWidth: 150 }));

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <LocalizedTypography variant="h6">{translations.portfolio.positions.aggregateTableTitle}</LocalizedTypography>
        <PortfolioPositionsExcelExportButton />
      </Stack>
      <HaloDataGrid
        density="compact"
        loading={isTableLoading}
        getRowId={(row) => hash(row)}
        columns={styledColumns}
        rows={aggregate}
        slots={slots}
        noResultsMessage={translations.messages.noResults}
      />
    </Stack>
  );
};
