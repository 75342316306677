import { ReactElement } from 'react';

import { AllocationModalFormModel } from '@halo-common/modals';
import { translations } from '@halo-common/translations';
import { LocalizedTypography, Stack, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Divider, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export type AllocationTotalRowProps = {
  rules?: {
    maxNotional?: number | null;
    inventoryRemaining?: number | null;
  };
};

export const AllocationTotalRow = ({ rules }: AllocationTotalRowProps): ReactElement => {
  const { watch } = useFormContext<AllocationModalFormModel>();

  const allocations = watch('allocations');

  const allocationTotal = allocations.reduce((total: number, { amount }) => total + Number(amount), 0);

  const enableInventoryText = typeof rules?.inventoryRemaining === 'number';
  const [formattedAllocationTotal] = useCurrencyConverter(allocationTotal ?? 0);
  const [inventoryRemainingFormatted] = useCurrencyConverter(rules?.inventoryRemaining ?? 0);

  const isOverAllocated = typeof rules?.inventoryRemaining === 'number' && allocationTotal > rules.inventoryRemaining;
  const totalAllocationColor = isOverAllocated ? 'error' : 'common.black';

  const inventoryComponent = enableInventoryText ? (
    <Stack direction="row" spacing={3}>
      <Divider orientation="vertical" />
      <Stack direction="column" alignItems="flex-end" spacing={1}>
        <Typography variant="h6">{inventoryRemainingFormatted}</Typography>
        <LocalizedTypography variant="overline" color="text.secondary">
          {translations.allocationsModal.fixedIncome.inventoryRemainingMessage}
        </LocalizedTypography>
      </Stack>
    </Stack>
  ) : null;

  return (
    <Stack direction="row" spacing={3}>
      <LocalizedTypography variant="h6" color="text.secondary">
        {translations.common.totalAmount}
      </LocalizedTypography>
      <Stack direction="column" alignItems="flex-end" spacing={1}>
        <Typography variant="h6" color={totalAllocationColor}>
          {formattedAllocationTotal}
        </Typography>
        <LocalizedTypography variant="overline" color="text.secondary">
          {translations.common.allocated}
        </LocalizedTypography>
      </Stack>
      {inventoryComponent}
    </Stack>
  );
};
