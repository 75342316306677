import { UserPreferencesModel } from '@halo-common/models';
import { saveUserPreferences } from '@halo-data-sources/clients';
import { HttpError } from '@halo-data-sources/errors';
import { ApiUserPreferencesMapper } from '@halo-data-sources/mappers';
import type { ApiPostUserPreferencesRequestModel, ApiUserPreferencesContentModel } from '@halo-data-sources/models';
import { UserInfoModel, UserQueryKeyFactory } from '@halo-data-sources/queries';
import { UseMutationOptions, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

export type UpdateUserPreferencesMutationPayload = ApiPostUserPreferencesRequestModel;

export type UpdateUserPreferencesMutationResult = UserPreferencesModel;

export type UseUpdateUserPreferencesMutationOptions = UseMutationOptions<
  UpdateUserPreferencesMutationResult,
  Error,
  UpdateUserPreferencesMutationPayload
>;

export type UseUpdateUserPreferencesMutationResult = UseMutationResult<
  UpdateUserPreferencesMutationResult,
  Error,
  UpdateUserPreferencesMutationPayload
>;

export type UpdateUserPreferencesMutationContentType = ApiUserPreferencesContentModel;

const updateUserPreferencesMutationFn = async (
  payload: UpdateUserPreferencesMutationPayload,
): Promise<UpdateUserPreferencesMutationResult> => {
  if (!payload?.tag || !payload?.content) throw new HttpError(400, 'Invalid Preferences Model');
  const data = await saveUserPreferences(payload);
  return ApiUserPreferencesMapper.toUserPreferences(data.user_ui_preferences);
};

export const useUpdateUserPreferencesMutation = (
  options?: UseUpdateUserPreferencesMutationOptions,
): UseUpdateUserPreferencesMutationResult => {
  const { onSuccess, ...rest } = options || {};
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateUserPreferencesMutationFn,
    onSuccess: (...props) => {
      const newPreferences = props[0];
      const userInfoKey = UserQueryKeyFactory.info();
      queryClient.setQueryData<UserInfoModel>(userInfoKey, (prev) => {
        if (!prev || !newPreferences) return prev;
        return { ...prev, preferences: newPreferences };
      });
      onSuccess?.(...props);
    },
    ...rest,
  });
};
