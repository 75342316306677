import { ReactElement, useEffect } from 'react';

import { orderBookCancelModalAtom } from '@halo-atoms/orderbook';
import { ActionButton } from '@halo-common/components';
import { useCancelFixOrderMutation } from '@halo-data-sources/mutations';
import {
  PershingActions,
  PershingSelectors,
  PershingStatusEnum as ReduxPershingStatusEnum,
} from '@halo-stores/Pershing';
import { Iconography, Modal, Stack, useSnackbar } from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';
import { useAtom } from 'jotai';
import { useDispatch, useSelector } from 'react-redux';

export const OrderBookPershingCancelOrderModal = (): ReactElement => {
  const dispatch = useDispatch();
  const orderbook = useSelector(PershingSelectors.selectOrderBook);

  const fixCancellationRequesting = Boolean(orderbook.fixOrderQueue.length);
  const fixCancellationSuccess = orderbook.status === ReduxPershingStatusEnum.successCancelOrderWebsocketEvent;

  const { enqueueErrorEvent } = useSnackbar();

  const [orderBookCancelModal, setOrderBookCancelModal] = useAtom(orderBookCancelModalAtom);

  const { mutate, isPending, isSuccess, isError, error } = useCancelFixOrderMutation({
    onMutate: () => {
      if (selectedAllocation) {
        dispatch(PershingActions.addToOrderBookOrderQueue({ orderId: selectedAllocation.id }));
      }
    },
  });

  const { open, allocation: selectedAllocation } = orderBookCancelModal;

  const isFinished = isSuccess || isError;
  const closeModal = fixCancellationSuccess && isFinished && open;
  const canceling = isPending || fixCancellationRequesting;

  const selectedAccountId = selectedAllocation?.account?.accountId;
  const title = `Are you sure you would like to cancel the indication of interest for account ${selectedAccountId}?`;

  const onClose = () => {
    if (closeModal) setOrderBookCancelModal({ open: false, allocation: null });
  };

  const onCancel = () => {
    if (selectedAllocation) mutate({ allocationId: selectedAllocation.id });
  };

  const footer = (
    <Stack direction="row" justify="space-between">
      <Button
        type="button"
        variant="text"
        color="primary"
        onClick={onClose}
        startIcon={<Iconography iconName="times" />}
        disabled={canceling}
      >
        Don&apos;t Cancel
      </Button>
      <ActionButton type="button" variant="contained" color="primary" onClick={onCancel} loading={canceling}>
        Cancel Indication
      </ActionButton>
    </Stack>
  );

  useEffect(() => {
    if (isError) {
      dispatch(PershingActions.removeFromOrderBookOrderQueue({ orderId: selectedAllocation?.id }));
      enqueueErrorEvent({ message: error.message });
    }

    if (closeModal) {
      setOrderBookCancelModal({ open: false, allocation: null });
    }
  }, [closeModal, isError]);

  return <Modal size="small" open={open} title={title} icon="question" footer={footer} onClose={onClose} />;
};
