import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Chip, Skeleton } from '@mui/material';

const messageSx = { fontWeight: 'fontWeightBold' };
const requirementSx = { backgroundColor: 'primary.background', color: 'text.secondary' };

export type QuizManagerRequirementProps = {
  threshold?: number;
  questionCount?: number;
  loading?: boolean;
};

export const QuizManagerRequirement = ({
  threshold = 0,
  questionCount = 0,
  loading,
}: QuizManagerRequirementProps): ReactElement => {
  if (loading) {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Skeleton variant="rounded" width={375} height={22} />
        <Skeleton variant="rounded" width={154} height={22} />
      </Stack>
    );
  }

  const dynamicContent = {
    threshold,
    questionCount,
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <LocalizedTypography variant="h6" color="textSecondary" sx={messageSx}>
        {translations.education.educationQuizManager.answerCorrectly}
      </LocalizedTypography>
      <Chip
        sx={requirementSx}
        label={
          <LocalizedTypography dynamicContent={dynamicContent} variant="overline">
            {translations.education.educationQuizManager.requirement}
          </LocalizedTypography>
        }
      />
    </Stack>
  );
};
