import { ReactElement } from 'react';

import { usePrimaryAssetIdentifier } from '@halo-common/hooks';
import { PortfolioOrderModel } from '@halo-common/models';
import { Stack } from '@halodomination/halo-fe-common';
import { Skeleton, Typography } from '@mui/material';

export type PostTradeNoteDetailModalHeaderIdProps = {
  product?: PortfolioOrderModel | null;
  loading: boolean;
};

export const PostTradeNoteDetailModalHeaderId = ({
  product,
  loading,
}: PostTradeNoteDetailModalHeaderIdProps): ReactElement => {
  if (loading) {
    return (
      <Stack direction="row" spacing={2}>
        <Skeleton variant="rounded" width={75} height={24} />
        {'|'}
        <Skeleton variant="rounded" width={75} height={24} />
      </Stack>
    );
  }

  const { identifier, identifierText, fallback } = usePrimaryAssetIdentifier(product?.termsheet);
  const primaryAssetIdentifierText = identifier ? identifierText : fallback.identifierText;
  const noteIdentifierText = product?.note?.id ? ` | ID# ${product?.note?.id}` : '';

  const parsedIdentifierText = `${primaryAssetIdentifierText}${noteIdentifierText}`;

  return (
    <Typography variant="overline" color="textSecondary">
      {parsedIdentifierText}
    </Typography>
  );
};
