import { ReactElement } from 'react';

import { notionalAtom, selectedIssuersAtom } from '@halo-atoms/auctions';
import { Iconography } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';

import { AuctionStepperStep } from '../AuctionStepperStep';

const icon = <Iconography iconName="list-check" prefix="far" />;

export const AuctionIssuersStep = (): ReactElement => {
  const selectedIssuers = useAtomValue(selectedIssuersAtom);
  const notional = useAtomValue(notionalAtom);

  const isNotionalValueValid = notional && notional >= 0;
  const selectedIssuersDisplayValue = selectedIssuers.length.toString() || '-';

  return (
    <AuctionStepperStep
      step={2}
      displayValue={selectedIssuersDisplayValue}
      icon={icon}
      label="Issuers Selected"
      disabled={!isNotionalValueValid}
      trackingVerb="select auction issuers"
    />
  );
};
