import { ReactElement } from 'react';

import { LexicalTypography } from '@halo-common/components';
import { Stack } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';
import { ITranslateParams } from '@transifex/native';

const descriptionSx = {
  whiteSpace: 'pre-wrap',
};

export type ProductDetailPanelSectionFieldProps = {
  label: string;
  description?: string;
  translations?: { label?: string; description?: string };
  loading?: boolean;
  dynamicContent?: ITranslateParams;
};

export const ProductDetailPanelSectionField = ({
  label,
  description = '-',
  translations,
  dynamicContent,
  loading = false,
}: ProductDetailPanelSectionFieldProps): ReactElement => {
  if (loading) {
    return (
      <Stack direction="row" xs={6}>
        <Skeleton width="75%" height={20} />
        <Skeleton width="50%" height={20} />
      </Stack>
    );
  }

  return (
    <Stack direction="row" xs={6}>
      <LexicalTypography variant="body2" color="textSecondary" translationKey={translations?.label}>
        {label}
      </LexicalTypography>
      <LexicalTypography
        sx={descriptionSx}
        fontWeight="fontWeightBold"
        variant="body2"
        translationKey={translations?.description}
        dynamicContent={dynamicContent}
      >
        {description}
      </LexicalTypography>
    </Stack>
  );
};
