import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type PortfolioQueryKeys = {
  all: QueryKeyFactoryFn;
  asset: QueryKeyFactoryFn;
  summaries: QueryKeyFactoryFn;
  order: QueryKeyFactoryFn;
  termsheet: QueryKeyFactoryFn;
  positions: QueryKeyFactoryFn;
  positionsV2: QueryKeyFactoryFn;
  accountMaintenanceInfo: QueryKeyFactoryFn;
  evaluation: QueryKeyFactoryFn;
  underlying: QueryKeyFactoryFn;
  underlyingChartData: QueryKeyFactoryFn;
  underlyingTableData: QueryKeyFactoryFn;
  assetExistence: QueryKeyFactoryFn;
  summary: QueryKeyFactoryFn;
  couponSummary: QueryKeyFactoryFn;
  autocallSummary: QueryKeyFactoryFn;
  positionSummary: QueryKeyFactoryFn;
  aggregate: QueryKeyFactoryFn;
  aggregationSummary: QueryKeyFactoryFn;
  aggregateTemplates: QueryKeyFactoryFn;
};

export const PortfolioQueryKeyFactory: PortfolioQueryKeys = {
  all: () => ['portfolio'],
  asset: (payload) => [...PortfolioQueryKeyFactory.positions(), 'asset', 'search', hash(payload)],
  summaries: (payload) => [...PortfolioQueryKeyFactory.positions(), 'summaries', hash(payload)],
  order: () => [...PortfolioQueryKeyFactory.all(), 'order'],
  termsheet: (id: number) => [...PortfolioQueryKeyFactory.order(), id],
  positions: (payload) => [...PortfolioQueryKeyFactory.all(), 'positions', hash(payload)],
  positionsV2: (payload) => [...PortfolioQueryKeyFactory.all(), 'positionsV2', hash(payload)],
  accountMaintenanceInfo: (ids = []) => [...PortfolioQueryKeyFactory.all(), 'account', 'maintenance', 'info', ...ids],
  evaluation: (id) => [...PortfolioQueryKeyFactory.order(), 'evaluation', id],
  underlying: () => [...PortfolioQueryKeyFactory.order(), 'underlying'],
  underlyingChartData: (id) => [...PortfolioQueryKeyFactory.underlying(), 'chart', id],
  underlyingTableData: (id) => [...PortfolioQueryKeyFactory.underlying(), 'table', id],
  assetExistence: (ids = []) => [...PortfolioQueryKeyFactory.all(), 'assets', ...ids, 'existence'],
  summary: () => [...PortfolioQueryKeyFactory.order(), 'summary'],
  couponSummary: (id) => [...PortfolioQueryKeyFactory.summary(), 'coupon', id],
  autocallSummary: (id) => [...PortfolioQueryKeyFactory.summary(), 'autocall', id],
  positionSummary: (payload) => [...PortfolioQueryKeyFactory.positions(), 'summary', hash(payload)],
  aggregate: () => [...PortfolioQueryKeyFactory.all(), 'aggregate'],
  aggregationSummary: (payload) => [...PortfolioQueryKeyFactory.aggregate(), 'summary', hash(payload)],
  aggregateTemplates: () => [...PortfolioQueryKeyFactory.aggregate(), 'templates'],
};
