import { useMemo } from 'react';

import { executionHubQueryAtom } from '@halo-atoms/executionHub';
import { COMBINED_DATE_TIME_ZONE_FORMAT } from '@halo-common/constants';
import { AccountDesignationEnum, ExecutionHubJanneyStatusEnum } from '@halo-common/enums';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { ExecutionHubOrder } from '@halo-data-sources/queries';
import {
  GridColDef,
  HaloDataGridProps,
  mapLocalCurrencyToNumber,
  mapNumberToLocalCurrency,
} from '@halodomination/halo-fe-common';
import { GridComparatorFn, gridStringOrNumberComparator } from '@mui/x-data-grid-pro';
import { useAtomValue } from 'jotai';

export const useEHOrderColumns = (columns: HaloDataGridProps['columns'] = []): HaloDataGridProps['columns'] => {
  const executionHubQuery = useAtomValue(executionHubQueryAtom);

  const configureDateTime = useWhiteLabelDateConfiguration();

  const currentStatus = executionHubQuery.status;

  const handleSorting: GridComparatorFn<ExecutionHubOrder> = (v1, v2, p1, p2) => {
    if (p1.id === 'footer') return 1;
    else if (p2.id === 'footer') return -1;
    else return gridStringOrNumberComparator(v1, v2, p1, p2);
  };

  const commonColumnProps = {
    editable: false,
    resizable: false,
    disableReorder: true,
    flex: 1,
    sortComparator: handleSorting,
  };

  return useMemo(() => {
    const conditionalColumn: GridColDef = {
      ...commonColumnProps,
      flex: 1.25,
      field: 'canceledOn',
      headerName: 'Canceled On',
      valueGetter: (_, row) => row.allocation?.updatedOn,
      valueFormatter: (value) => configureDateTime(value)?.toFormat(COMBINED_DATE_TIME_ZONE_FORMAT),
    };

    if (currentStatus === ExecutionHubJanneyStatusEnum.active) {
      conditionalColumn.field = 'submittedOn';
      conditionalColumn.headerName = 'Submitted On';
      conditionalColumn.valueGetter = (_, row) => row.allocation?.createdOn;
    } else if (currentStatus === ExecutionHubJanneyStatusEnum.filled) {
      conditionalColumn.field = 'filledOn';
      conditionalColumn.headerName = 'Filled On';
    }

    return [
      ...columns,
      {
        ...commonColumnProps,
        flex: 0.5,
        field: 'orderId',
        headerName: 'order #',
        valueGetter: (_, row) => row.executionOrder?.id,
      },
      {
        ...commonColumnProps,
        flex: 0.5,
        field: 'repCode',
        headerName: 'rep code',
        valueGetter: (_, row) => row.allocation?.account.household,
      },
      {
        ...commonColumnProps,
        flex: 1,
        field: 'accountName',
        headerName: 'account name',
        valueGetter: (_, row) => row.allocation?.account.name,
      },
      {
        ...commonColumnProps,
        flex: 1,
        field: 'accountId',
        headerName: 'account #',
        valueGetter: (_, row) => row.allocation?.account.accountId,
      },
      {
        ...commonColumnProps,
        field: 'submitted',
        headerName: 'submitted by',
        valueGetter: (_, row) => row.executionOrder?.orderReceiveFrom,
      },
      conditionalColumn,
      {
        ...commonColumnProps,
        field: 'category',
        headerName: 'account type',
        headerClassName: 'executionHubDividerHeader',
        cellClassName: 'executionHubDividerCell',
        translateCell: true,
        valueGetter: (_, row) => row.allocation?.account.designation,
      },
      {
        ...commonColumnProps,
        field: 'soldPrice',
        align: 'right',
        headerName: 'issuer reoffer',
        valueGetter: (_, row) => {
          if (!row.allocation) return row.reoffer;
          const isBrokerage = row.allocation.account.designation === AccountDesignationEnum.Brokerage;
          return isBrokerage ? row.allocation.soldPrice : row.allocation.boughtPrice;
        },
      },
      {
        ...commonColumnProps,
        align: 'right',
        field: 'quantity',
        headerName: 'quantity',
        valueGetter: (_, row) => row.executionOrder?.quantity ?? row.quantity,
      },
      {
        ...commonColumnProps,
        align: 'right',
        field: 'commission',
        headerName: 'commission',
        sortComparator: (v1, v2) => {
          const v1Numerical = mapLocalCurrencyToNumber(v1);
          const v2Numerical = mapLocalCurrencyToNumber(v2);

          if (v1Numerical === null) return -1;
          if (v2Numerical === null) return 1;

          return v1Numerical > v2Numerical ? 1 : v2Numerical > v1Numerical ? -1 : 0;
        },
        valueGetter: (_, row) => mapNumberToLocalCurrency(row.transactionSummary?.salesCredit),
      },
    ];
  }, [columns, currentStatus, configureDateTime]);
};
