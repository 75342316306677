import type { FieldRender, FieldType, SelectFieldRender } from '@halo-common/components';
import { useWhiteLabelDateFormat } from '@halo-common/hooks';
import { LocalizedFormControlLabel, LocalizedTextField } from '@halodomination/halo-fe-common';
import { Checkbox, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { useT } from '@transifex/react';
import { DateTime } from 'luxon';

import { renderAutocomplete } from '../renderAutocomplete';
import { renderFileInput } from '../renderFileInput';

const common = {
  fullWidth: true,
  variant: 'outlined',
  size: 'large',
} as const;

export const renderTextFieldInput: FieldRender = ({ field: { ref, ...field }, fieldState, type, props }) => {
  const value = props?.value ?? field.value ?? '';
  const helperText = fieldState.error?.message ?? props?.helperText;
  return (
    <LocalizedTextField
      {...common}
      {...field}
      {...props}
      value={value}
      inputRef={ref}
      error={!!fieldState.error}
      helperText={helperText}
      type={type}
    />
  );
};

export const renderSelectInput: SelectFieldRender = ({ field: { ref, ...field }, fieldState, options = [], props }) => {
  const value = props?.value ?? field.value ?? '';
  const helperText = fieldState.error?.message ?? props?.helperText;
  return (
    <LocalizedTextField
      {...common}
      {...field}
      {...props}
      value={value}
      inputRef={ref}
      error={!!fieldState.error}
      helperText={helperText}
      select
    >
      {options.map(({ label, value }) => (
        <MenuItem value={value} key={value}>
          {label}
        </MenuItem>
      ))}
    </LocalizedTextField>
  );
};

export const renderDatePickerInput: FieldRender = ({ field: { value, onChange, ...field }, fieldState, props }) => {
  const t = useT();
  const whiteLabelDateFormat = useWhiteLabelDateFormat();
  const parsedValue = typeof value === 'string' ? DateTime.fromISO(value) : value;

  const { label = '', helperText = '', ...rest } = props || {};

  return (
    <DatePicker
      slotProps={{
        field: {
          clearable: true,
        },
        textField: {
          ...common,
          ...field,
          ...rest,
          error: !!fieldState.error,
          helperText: fieldState.error?.message ?? t(helperText),
          type: null,
        },
      }}
      format={whiteLabelDateFormat}
      label={t(label)}
      value={parsedValue}
      onChange={onChange}
    />
  );
};

export const renderCheckboxInput: FieldRender = ({ field: { ref, value, ...field }, props }) => {
  const { label, sx } = props || {};
  return (
    <LocalizedFormControlLabel
      control={<Checkbox {...field} checked={value ?? false} inputRef={ref} />}
      label={label}
      sx={sx}
    />
  );
};

export const getRenderFunction = (type: FieldType): FieldRender | null => {
  const renderFunction = {
    text: renderTextFieldInput,
    number: renderTextFieldInput,
    date: renderDatePickerInput,
    select: renderSelectInput,
    checkbox: renderCheckboxInput,
    autocomplete: renderAutocomplete,
    file: renderFileInput,
  }[type];

  return renderFunction || null;
};
