import { ReactElement } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { portfolioAccountManagerAtom, portfolioLifecycleManagerAtom } from '@halo-atoms/portfolio';
import { PortfolioTabEnum } from '@halo-common/enums';
import { useLegacyRouter } from '@halo-common/providers';
import { translations } from '@halo-common/translations';
import { useAccountPagedQuery, useLifecycleEventsQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import {
  PortfolioAccountTypeAhead,
  PortfolioAssetSearchTypeAhead,
  PortfolioCompositionDropdown,
  PortfolioCurrencyDropdown,
  PortfolioGenerateReportButton,
} from '@halo-modules/app';
import { Box, Container, Stack } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';

const leftSideContainerSx = {
  flex: 3,
  width: '100%',
};

const assetSearchContainerSx = {
  display: 'flex',
  flex: 1,
  width: '100%',
};

const currencyDropdownContainerSx = {
  flex: 1,
};

const compositionDropdownContainerSx = {
  flex: 1,
};

const accountTypeAheadContainerSx = {
  flex: 2,
};

export type PortfolioDropdownFiltersProps = {
  showCompositionDropdown?: boolean;
  showChartCurrencyLabel?: boolean;
  showAssetSearchBar?: boolean;
};

export const PortfolioDropdownFilters = ({
  showCompositionDropdown = false,
  showChartCurrencyLabel = false,
  showAssetSearchBar = false,
}: PortfolioDropdownFiltersProps): ReactElement => {
  const router = useLegacyRouter();

  const selectedTabQuery = router.query.tab;
  const isSelectedTabString = typeof selectedTabQuery === 'string';
  const selectedTab = isSelectedTabString ? (selectedTabQuery as PortfolioTabEnum) : PortfolioTabEnum.POSITION_TAB;

  const onModalToggle = useSetAtom(modalAtom);

  const { query: accountQuery } = useAtomValue(portfolioAccountManagerAtom);
  const { query: lifecycleQuery } = useAtomValue(portfolioLifecycleManagerAtom);

  const { data: userInfo } = useUserInfoQuery();

  const filterAccounts = userInfo?.whiteLabel.filterAccountDropdown;
  const hasPositions = filterAccounts ? filterAccounts : undefined;
  const accountsPagedQuery = { ...accountQuery, hasPositions };

  const isAccountsQueryEnabled = selectedTab === PortfolioTabEnum.ACCOUNTS_TAB && Boolean(userInfo?.details.id);
  const isLifecycleQueryEnabled = selectedTab === PortfolioTabEnum.LIFECYCLE_TAB;

  const { isPending: accountsLoading } = useAccountPagedQuery(accountsPagedQuery, { enabled: isAccountsQueryEnabled });
  const { isPending: lifecycleLoading } = useLifecycleEventsQuery(lifecycleQuery, { enabled: isLifecycleQueryEnabled });

  const { data: user } = useUserInfoQuery();

  const isAccountDataLoading = isAccountsQueryEnabled && accountsLoading;
  const isLifecycleDataLoading = isLifecycleQueryEnabled && lifecycleLoading;
  const loading = isAccountDataLoading || isLifecycleDataLoading;

  const showGenerateReportButton = user?.settings.hasReportTemplate && showCompositionDropdown;
  const showCurrencyDropdown = user?.settings.hasMultiCurrencyRole;

  const currencyDropdownLabel = showChartCurrencyLabel
    ? translations.portfolio.common.currencyDropdownLabel
    : undefined;

  const handleGenerateReportModalOpen = () => void onModalToggle({ generateReportModal: true });

  const compositionDropdown = showCompositionDropdown ? <PortfolioCompositionDropdown loading={loading} /> : null;
  const assetSearchBar = showAssetSearchBar ? <PortfolioAssetSearchTypeAhead loading={loading} /> : null;

  const currencyDropdown = showCurrencyDropdown ? (
    <Box sx={currencyDropdownContainerSx}>
      <PortfolioCurrencyDropdown loading={loading} label={currencyDropdownLabel} />
    </Box>
  ) : null;

  const generatePerfReportButton = showGenerateReportButton ? (
    <PortfolioGenerateReportButton handleClick={handleGenerateReportModalOpen} loading={loading} />
  ) : null;

  return (
    <Container maxWidth="xl">
      <Stack direction={{ md: 'row', xs: 'column' }} alignItems={{ md: 'center', xs: 'flex-start' }} spacing={2}>
        <Stack
          sx={leftSideContainerSx}
          direction={{ sm: 'row', xs: 'column' }}
          alignItems={{ sm: 'center', xs: 'flex-start' }}
          spacing={2}
        >
          <Box sx={accountTypeAheadContainerSx}>
            <PortfolioAccountTypeAhead loading={loading} />
          </Box>
          {currencyDropdown}
          <Box sx={compositionDropdownContainerSx}>{compositionDropdown}</Box>
        </Stack>
        <Box sx={assetSearchContainerSx}>{assetSearchBar}</Box>
        {generatePerfReportButton}
      </Stack>
    </Container>
  );
};
