import { ReactElement } from 'react';

import { Reminder } from '@halo-modules/app';

const TIMEOUT = 750;

export const InformativeReminder = (): ReactElement => (
  <>
    <Reminder timeout={TIMEOUT * 1.15} icon="compass" message="Explore structured notes, and annuities." />
    <Reminder
      timeout={TIMEOUT * 1.65}
      icon="gavel"
      message="Receive competitive bids from a number of issuers within minutes."
    />
    <Reminder
      timeout={TIMEOUT * 2.2}
      icon="chart-line"
      message="Manage and track your clients’ portfolios in one place."
    />
  </>
);
