import { useRef, useMemo, useState, MutableRefObject } from 'react';

export type UseRefProxyReturn<T extends HTMLElement = HTMLElement> = readonly [MutableRefObject<T | null>, T | null];

export const useRefProxy = <T extends HTMLElement = HTMLElement>(): UseRefProxyReturn<T> => {
  const ref = useRef<T | null>(null);
  const [refElement, setRefElement] = useState<T | null>(null);

  const refProxy = useMemo(
    () =>
      new Proxy(ref, {
        set(target, prop, value) {
          if (prop === 'current') {
            const node = value instanceof HTMLElement ? (value as T) : null;
            setRefElement(node);
            target.current = node;
          } else {
            (target as unknown as T)[prop as keyof T] = value;
          }
          return true;
        },
        apply(target, _, [value]) {
          const node = value instanceof HTMLElement ? (value as T) : null;
          if (node !== refElement) {
            setRefElement(node);
            target.current = node;
          }
          return undefined;
        },
      }),
    [refElement],
  );

  return [refProxy, refElement] as const;
};
