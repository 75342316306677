import { ConditionalField, Input, Row, Select } from '@halo-common/components/FormBuilder';
import { STATE_LIST } from '@halo-common/constants';
import { translations } from '@halo-common/translations';
import { OnboardingSuitabilityForm, SuitabilityStepModel } from '@halo-modules/app/v2/onboarding';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';

const suitability = translations.onboarding.suitability;

const states = STATE_LIST.filter((state) => state.value && state.value !== 'non-us');

const AddressFields = ({ prefix }: { prefix: string }) => (
  <>
    <Row columns={[2, 1]}>
      <Input label={suitability.streetAddress} name={`${prefix}.street`} />
      <Input label={suitability.aptSuiteNo} name={`${prefix}.apt_no`} />
    </Row>
    <Input label={suitability.city} name={`${prefix}.city`} />
    <Select label={suitability.state} name={`${prefix}.state`} options={states} />
    <Row columns={[1, 1]}>
      <Input label={suitability.country} name={`${prefix}.country`} />
      <Input label={suitability.zipCode} name={`${prefix}.zip_code`} />
    </Row>
  </>
);

const FirmDetailsForm = () => (
  <OnboardingSuitabilityForm>
    <Stack spacing={2}>
      <Input label={suitability.fullLegalName} name="firm_details.legal_name" />
      <Input type="checkbox" label={suitability.hasFirmGoneByAnotherName} name="hasGoneByAnotherName" />
    </Stack>

    <ConditionalField field="hasGoneByAnotherName">
      <Input label={suitability.previousLegalNames} name="firm_details.previous_name" />
    </ConditionalField>

    <Input label={suitability.taxIdOrSsn} name="firm_details.tax_id_or_ssn" />

    <LocalizedTypography variant="h6" fontWeight={600}>
      {suitability.permanentAddress}
    </LocalizedTypography>

    <AddressFields prefix="firm_details.permanent_address" />

    <Input type="checkbox" label={suitability.mailingAddressDifferent} name="mailingAddressDifferentThanPermanent" />

    <ConditionalField field="mailingAddressDifferentThanPermanent">
      <LocalizedTypography variant="h6" fontWeight={600}>
        {suitability.mailingAddress}
      </LocalizedTypography>
      <AddressFields prefix="firm_details.mailing_address" />
    </ConditionalField>
  </OnboardingSuitabilityForm>
);

export const firmDetailsStep: SuitabilityStepModel = {
  id: '1-1',
  title: suitability.tellUsMoreAboutYourFirm,
  component: <FirmDetailsForm />,
  isCompleted: (formData) => {
    const firmDetails = formData?.firm_details || {};
    return Boolean(firmDetails.legal_name && firmDetails.permanent_address?.street && firmDetails.tax_id_or_ssn);
  },
};
