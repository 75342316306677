import { ReactElement, useEffect, useState } from 'react';

import { HOUR_MINUTE_SECONDS_TIME_FORMAT, YEAR_MONTH_DAY_DATE_FORMAT } from '@halo-common/constants';
import { TackOnFormSchema, tackOnFormSchema } from '@halo-common/schemas';
import { ConfirmDeleteTackOn, EditTackOn, TackOnContext } from '@halo-modules/admin';
import { CalendarActions } from '@halo-stores/Calendar';
import { OrdersActions, OrdersSelectors } from '@halo-stores/Orders';
import { useSteps } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { capitalize } from '@mui/material';
import { DateTime } from 'luxon';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

export type EditTackOnModalProps = {
  open: boolean;
  onClose: () => void;
};

export const EditTackOnModal = ({ open, onClose }: EditTackOnModalProps): ReactElement => {
  const dispatch = useDispatch();
  const { step, handleNext, handleBack, handleReset } = useSteps({ max: 1 });

  const auction = useSelector(OrdersSelectors.selectSelectedAuction);

  const defaultPages = auction?.tackOn?.pages?.map((page) => page.id) ?? [];
  const defaultIssuer = auction?.tackOn?.issuer?.id;
  const defaultCategory = auction?.tackOn?.category;

  const formMethods = useForm<TackOnFormSchema>({
    mode: 'onChange',
    resolver: yupResolver<TackOnFormSchema>(tackOnFormSchema),
    defaultValues: {
      category: defaultCategory,
      calendarPages: defaultPages,
      issuer: defaultIssuer,
    },
  });

  const { handleSubmit, reset } = formMethods;

  const defaultStartShowing = auction?.tackOn?.startShowingDate
    ? DateTime.fromISO(auction?.tackOn?.startShowingDate)
    : DateTime.now();
  const defaultExpiration = auction?.tackOn?.expirationDate
    ? DateTime.fromISO(auction?.tackOn?.expirationDate)
    : DateTime.now().plus({ day: 1 });

  const [startShowingDate, setStartShowingDate] = useState<DateTime>(defaultStartShowing);
  const [startShowingTime, setStartShowingTime] = useState<DateTime>(defaultStartShowing);
  const [expirationDate, setExpirationDate] = useState<DateTime>(defaultExpiration);
  const [expirationTime, setExpirationTime] = useState<DateTime>(defaultExpiration);

  const submitForm = handleSubmit((data: TackOnFormSchema) => {
    if (auction) {
      const parsedStartShowingDate = startShowingDate ?? DateTime.now();
      const parsedStartShowingTime = startShowingTime ?? DateTime.now();
      const parsedExpirationDate = expirationDate ?? DateTime.now().plus({ days: 1 });
      const parsedExpirationTime = expirationTime ?? DateTime.now().plus({ days: 1 });

      const formatStartShowingDate = parsedStartShowingDate.toFormat(YEAR_MONTH_DAY_DATE_FORMAT);
      const formatStartShowingTime = parsedStartShowingTime.toFormat(HOUR_MINUTE_SECONDS_TIME_FORMAT);
      const formatExpirationDate = parsedExpirationDate.toFormat(YEAR_MONTH_DAY_DATE_FORMAT);
      const formatExpirationTime = parsedExpirationTime.toFormat(HOUR_MINUTE_SECONDS_TIME_FORMAT);

      const submitData = {
        id: auction.id,
        internalName: data.internalName,
        externalName: data.externalName,
        reoffer: data.reoffer,
        pages: data.calendarPages as Array<number>,
        startShowing: `${formatStartShowingDate}T${formatStartShowingTime}.00Z`,
        expiration: `${formatExpirationDate}T${formatExpirationTime}.00Z`,
        issuer: data.issuer ?? null,
        category: data.category,
      };

      dispatch(OrdersActions.editAuctionTackOn(submitData));
    }

    handleClose();
  });

  useEffect(() => {
    if (auction?.tackOn) {
      const { category, issuer } = auction.tackOn;
      const defaultCategory = category ? capitalize(category) : '';

      formMethods.setValue('category', defaultCategory);
      formMethods.setValue('issuer', issuer.id);
    }
  }, [auction?.id]);

  const handleClose = () => {
    reset();
    handleReset();
    onClose();
  };

  useEffect(() => {
    dispatch(CalendarActions.fetchAdminCalendarPages());
  }, []);

  return (
    <TackOnContext.Provider
      value={{
        startShowingDate,
        setStartShowingDate,
        startShowingTime,
        setStartShowingTime,
        expirationDate,
        setExpirationDate,
        expirationTime,
        setExpirationTime,
      }}
    >
      <FormProvider {...formMethods}>
        {
          [
            <EditTackOn
              open={open}
              key="edit-or-delete-tack-on-step"
              auction={auction}
              onClose={handleClose}
              onNext={handleNext}
              onSubmit={submitForm}
            />,
            <ConfirmDeleteTackOn
              open={open}
              key="delete-tack-on-step"
              id={auction?.id}
              onClose={handleClose}
              onBack={handleBack}
            />,
          ][step]
        }
      </FormProvider>
    </TackOnContext.Provider>
  );
};
