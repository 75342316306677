import { ConfirmationEnum } from '@halo-common/enums';
import { CalendarModel, OrderTicketReceiptModel } from '@halo-common/models';
import { SingleOrderTicketFormFields } from '@halo-common/schemas';
import { validateCalendarOrder } from '@halo-data-sources/clients';
import { ApiExecOrderMapper } from '@halo-data-sources/mappers';
import { ApiValidateOrderRequestModel } from '@halo-data-sources/models';

export const janneyOrderTicketValidationSwitchFn = async (
  calendar: CalendarModel,
  data?: SingleOrderTicketFormFields,
): Promise<OrderTicketReceiptModel | null> => {
  if (!data || !data.account?.account) return null;

  const custom: ApiValidateOrderRequestModel['custom'] = {
    order_receipt_time: data.orderDate,
    order_receive_from: data.orderFrom,
  };

  if (calendar.price) {
    custom.original_offering_price = calendar.price;
  }

  if (data.solicited) {
    custom.solicited = data.solicited.toUpperCase();
  }

  if (data.discretion) {
    custom.DiscretionUsed = data.discretion === ConfirmationEnum.Yes;
  }

  if (data.ttoRepCode) {
    custom.tto_rep_code = data.ttoRepCode.toUpperCase();
    // TODO: Uncomment when rep code data is complete on BE
    // custom.tto_rep_code = data.ttoRepCode.name;
  }

  const response = await validateCalendarOrder({
    account_id: data.account.account.id,
    calendar_id: calendar.id,
    custom,
    quantity: parseInt(data.quantity),
  });

  const order = response.exec_order;
  const summary = response.extra;

  return {
    status: 'success',
    order: ApiExecOrderMapper.toExecutionOrder(order),
    summary: {
      principal: summary.principal,
      commission: summary.commission,
      otherMiscFees: summary.other_misc_fees,
      salesCredit: summary.sales_credit,
      total: summary.total,
    },
  };
};
