export const roundValue = (value: string | number = '', decimalPlaces = 2): string => {
  if (decimalPlaces < 0) {
    throw new Error('Decimal places must be a non-negative integer');
  }

  if (typeof value === 'string') {
    if (value.match(/[a-zA-Z]/g)) return value;

    const hasPercentage = value.trim().endsWith('%');
    const numericValue = parseFloat(hasPercentage ? value.slice(0, -1) : value);

    if (isNaN(numericValue)) {
      return value;
    }

    const roundedValue = numericValue.toFixed(decimalPlaces);
    return hasPercentage ? `${roundedValue}%` : roundedValue;
  }

  return value.toFixed(decimalPlaces);
};
