import { SortModelDirectionEnum, SortModelNullSortEnum } from '@halo-common/enums';
import { AccountModel, PaginationModel, SortModel } from '@halo-common/models';
import { getProductAccounts } from '@halo-data-sources/clients';
import { ApiAccountMapper, PaginationMapper } from '@halo-data-sources/mappers';
import { ApiGetAccountProductRequestModel } from '@halo-data-sources/models';
import { AccountQueryKeyFactory, useUserInfoQuery } from '@halo-data-sources/queries';
import { UseQueryOptions, UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

export type PortfolioAccountQueryModel = {
  hasPositions?: boolean;
  accountIds?: Array<number>;
  householdIds?: Array<number>;
  synced: boolean | null;
  page: number;
  pageLength: number;
  sort?: Array<SortModel>;
  currencyId?: number;
};

export type PortfolioAccountQueryResult = {
  accounts: Array<AccountModel>;
  pagination: PaginationModel;
};

const DEFAULT_SORT = [
  {
    field: 'advisee_name',
    direction: SortModelDirectionEnum.asc,
    nulls: SortModelNullSortEnum.first,
  },
];

const getAccountPagedQueryFn = async (payload: PortfolioAccountQueryModel) => {
  const filters = {
    has_positions: payload.hasPositions,
  };

  const account_selector = {
    account_ids: payload.accountIds,
    advisee_ids: payload.householdIds,
    synced: payload.synced,
  };

  const request: ApiGetAccountProductRequestModel = {
    filters,
    account_selector,
    page: payload.page,
    reporting_currency_id: payload.currencyId,
    page_length: payload.pageLength,
    sort: payload?.sort ?? DEFAULT_SORT,
  };

  const response = await getProductAccounts(request);

  const accounts = response.accounts.map(ApiAccountMapper.toAccountModel);
  const pagination = PaginationMapper.toPaginationModel(response.pagination);

  return { accounts, pagination };
};

export const useAccountPagedQuery = (
  payload: PortfolioAccountQueryModel,
  options?: Partial<UseQueryOptions<PortfolioAccountQueryResult, Error>>,
): UseQueryResult<PortfolioAccountQueryResult, Error> => {
  const { data: user } = useUserInfoQuery();

  const filterAccount = user?.whiteLabel.filterAccountDropdown;
  const hasPositions = filterAccount ? filterAccount : undefined;

  return useQuery<PortfolioAccountQueryResult, Error>({
    queryKey: AccountQueryKeyFactory.paged(payload),
    queryFn: () => getAccountPagedQueryFn({ ...payload, hasPositions }),
    placeholderData: keepPreviousData,
    ...options,
  });
};
