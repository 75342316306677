import { ReactElement } from 'react';

import { useUserInfoQuery } from '@halo-data-sources/queries';
import { AppBar, Box, Stack } from '@mui/material';

const logoSx = { display: 'block', maxWidth: 150 };
const logoSmallSx = { maxWidth: 95 };

export const OnboardingNavBar = (): ReactElement => {
  const { data: user } = useUserInfoQuery();

  const settings = user?.settings?.onboarding;
  const poweredByHalo = settings?.showPoweredByHalo;
  const backgroundImage = settings?.drawerBackground?.path;
  const isLightBackground = settings?.isLightBackground;
  const brandLogo = isLightBackground ? settings?.logoDark : settings?.logoLight;
  const poweredByHaloPath = isLightBackground
    ? '/assets/images/logo_poweredbyhalo.svg'
    : '/assets/images/logo_poweredbyhalo_light.svg';

  const appBarSx = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: 'common.black',
    backgroundSize: 'cover',
    backgroundPosition: 'top left',
    padding: 3,
  };

  const logo = brandLogo ? (
    <Box sx={logoSx} data-testid="brand-logo" component="img" src={brandLogo?.path} alt="Brand Logo" />
  ) : null;

  const poweredByHaloLogo = poweredByHalo ? (
    <Box sx={logoSmallSx} data-testid="power-logo" component="img" src={poweredByHaloPath} alt="Powered by Halo Logo" />
  ) : null;

  return (
    <AppBar position="sticky" sx={appBarSx}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {logo}
        {poweredByHaloLogo}
      </Stack>
    </AppBar>
  );
};
