import { ChangeEvent, KeyboardEvent, ReactElement } from 'react';

import { bulkOrderTicketManagerAtom } from '@halo-atoms/orderTicket';
import { ActionButton } from '@halo-common/components';
import { useDebounced } from '@halo-common/hooks';
import { useWebSocketContext } from '@halo-common/providers';
import { BulkOrderTicketFormFields } from '@halo-common/schemas';
import { AccountTypeAhead, AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { Iconography, LocalizedTextField, useCombinedStyling } from '@halodomination/halo-fe-common';
import { FormGroup, Stack, TextFieldProps, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Controller, useFormContext } from 'react-hook-form';

const noRightBorderRadius = {
  ['& fieldset']: {
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
  },
};

const noLeftBorderRadius = {
  ['& fieldset']: {
    borderLeft: 0,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  },
};

const accountTypeAheadSx = {
  width: '100%',
};

const AccountNotionalInputFormGroupSx = {
  flexWrap: 'nowrap',
};

const actionButtonSx = {
  p: 3,
};

const statusIconSx = {
  my: 1.5,
  mx: 2.5,
};

const accountErrorPaddingSx = {
  px: 1.5,
  py: 0.5,
};

export type AccountNotionalInputProps = {
  index: number;
  onDelete: () => void;
  StartInputFieldProps?: TextFieldProps;
  EndInputFieldProps?: TextFieldProps;
};

export const AccountNotionalInput = ({
  index,
  onDelete,
  StartInputFieldProps,
  EndInputFieldProps,
}: AccountNotionalInputProps): ReactElement => {
  const { control, setValue, getValues, formState, trigger } = useFormContext<BulkOrderTicketFormFields>();
  const { errors } = formState;
  const { events } = useWebSocketContext();

  const { pendingValidateAllocations, validatedAllocations, invalidAllocations } =
    useAtomValue(bulkOrderTicketManagerAtom);

  const accountError = errors?.allocations?.[index]?.message;
  const hasAccountError = Boolean(accountError);
  const accountIdError = errors?.allocations?.[index]?.accountOption?.message;
  const hasAccountIdError = Boolean(hasAccountError || accountIdError);
  const quantityError = errors?.allocations?.[index]?.quantity?.message;
  const hasQuantityError = Boolean(hasAccountError || quantityError);

  const accountSx = useCombinedStyling(StartInputFieldProps?.sx, noRightBorderRadius, accountTypeAheadSx);
  const notionalSx = useCombinedStyling(EndInputFieldProps?.sx, noLeftBorderRadius);

  const accountFieldName = `allocations.${index}.accountOption` as const;
  const quantityFieldName = `allocations.${index}.quantity` as const;

  const [allocations, accountValue, quantityValue] = getValues(['allocations', accountFieldName, quantityFieldName]);

  const shrink = typeof quantityValue === 'number' || false;
  const qtyLabelProps = { shrink };
  const accountId = accountValue?.account?.id;
  const validatingFixAllocation = events.fix.contains(accountId);
  const isValid = accountId ? validatedAllocations.includes(accountId) : false;
  const isInvalid = accountId ? invalidAllocations.includes(accountId) : false;

  const validateAllAllocations = () => void trigger('allocations');
  const debouncedValidateAllocations = useDebounced(validateAllAllocations, 300);

  const handleDelete = () => {
    onDelete();
    validateAllAllocations();
  };

  const selectAccountHandler = (value: AccountTypeAheadOption | null) => {
    setValue(accountFieldName, value);
    validateAllAllocations();
  };

  const handleChangeQuantity = (event: ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = event.target.value.replace(/[^0-9]/g, '');
    setValue(quantityFieldName, Number(sanitizedValue));
    debouncedValidateAllocations();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = /^[0-9]|Tab|Backspace|Delete|Enter|Left|Right$/;
    if (!allowedKeys.test(event.key)) event.preventDefault();
  };

  const handleAccountFormat = (option: AccountTypeAheadOption) => {
    const { account, household, action, clearedOnSelection } = option;

    if (action) return !clearedOnSelection ? action : '';
    else if (household) return household.name;
    else {
      const name = account?.name ?? '--';
      const accountId = account?.accountId ?? '--';
      const nameAndIdMatch = name === accountId;
      return nameAndIdMatch ? name : `${name} - #${accountId}`;
    }
  };

  const showActionButton = allocations.length > 1;
  const isValidating = Boolean(pendingValidateAllocations?.length && validatingFixAllocation);

  let inputComponent = null;

  if (isValid) {
    inputComponent = <Iconography iconName="check" color="success.main" sx={statusIconSx} size="1.5x" />;
  } else if (isInvalid) {
    inputComponent = <Iconography iconName="exclamation" color="error.main" sx={statusIconSx} size="1.5x" />;
  } else if (showActionButton || isValidating) {
    inputComponent = (
      <ActionButton size="medium" onClick={handleDelete} loading={isValidating} sx={actionButtonSx}>
        <Iconography iconName="trash-alt" />
      </ActionButton>
    );
  }

  const hasInputComponent = inputComponent !== null;

  const additionalErrorMarginSx = hasInputComponent ? { ml: 8 } : {};
  const accountErrorSx = useCombinedStyling(accountErrorPaddingSx, additionalErrorMarginSx);

  const accountErrorContent = hasAccountError ? (
    <Typography color="error.main" variant="caption" sx={accountErrorSx}>
      {accountError}
    </Typography>
  ) : null;

  return (
    <Stack direction="column">
      <FormGroup row sx={AccountNotionalInputFormGroupSx}>
        {inputComponent}
        <Controller
          render={({ field: { ref, ...options } }) => (
            <AccountTypeAhead
              {...options}
              value={accountValue}
              sx={accountSx}
              ref={ref}
              onSelect={selectAccountHandler}
              label={translations.common.account}
              rules={{ disableHouseholds: true }}
              helperText={accountIdError}
              error={hasAccountIdError}
              disableClearable
              accountFormatter={handleAccountFormat}
            />
          )}
          control={control}
          name={accountFieldName}
        />
        <Controller
          render={({ field: { ref, ...options } }) => (
            <LocalizedTextField
              {...options}
              size="large"
              sx={notionalSx}
              inputRef={ref}
              variant="outlined"
              type="text"
              slotProps={{ inputLabel: qtyLabelProps }}
              label={translations.allocationsModal.common.qtyThousand}
              helperText={quantityError}
              error={hasQuantityError}
              value={quantityValue}
              onChange={handleChangeQuantity}
              onKeyDown={handleKeyDown}
            />
          )}
          control={control}
          name={quantityFieldName}
        />
      </FormGroup>
      {accountErrorContent}
    </Stack>
  );
};
