import { AccountModel, CustodianModel, HouseholdBasicModel, HouseholdModel } from '@halo-common/models';
import {
  ApiAccountAdviseeModel,
  ApiAccountCustodianModel,
  ApiAccountModel,
  ApiBulkAllocationRequest,
} from '@halo-data-sources/models';

export const AccountMapper = {
  toBulkAccountsRequest: (accountIdentifiers: Array<string>): ApiBulkAllocationRequest => {
    return {
      filters: { account_identifiers: accountIdentifiers },
      page_length: accountIdentifiers.length,
    };
  },
};

export const ApiAccountMapper = {
  toAccountModel: (model: ApiAccountModel): AccountModel => {
    const custodianId = model.custodian_id ?? model.custodian?.id;
    const custodianName = model.custodian_name ?? model.custodian?.name;

    return {
      accountId: model.account_identifier,
      designation: model.designation,
      availableCash: model.cash_available,
      custodian: { id: custodianId, name: custodianName },
      faNumber: model.fa_number,
      id: model.id,
      isActive: model.is_active,
      isFixEnabled: model.isfixenabled,
      marketValue: model.market_value,
      numOfProducts: model.num_products,
      name: model.name,
      nonCashAssetValue: model.non_cash_asset_value,
      organization: model.organization,
      household: model.advisee_name ?? model.owner?.name ?? model.advisee?.name ?? null,
      householdId: model.advisee_id ?? model.owner?.id ?? model.advisee?.id,
      taxId: model.tax_id,
      title: model.title,
      source: model.source,
    };
  },
  toHouseholdModel: (model: ApiAccountAdviseeModel): HouseholdModel => {
    return {
      id: model.id,
      name: model.name,
      accounts: model?.accounts?.map(ApiAccountMapper.toAccountModel) ?? [],
    };
  },
  toHouseholdBasicModel: (model: ApiAccountModel | ApiAccountAdviseeModel): HouseholdBasicModel | null => {
    const household = {
      id: (model as ApiAccountModel)?.advisee_id ?? model?.id,
      name: (model as ApiAccountModel)?.advisee_name ?? model?.name,
    };

    return household?.id && household?.name ? household : null;
  },
  toCustodianModel: (model: ApiAccountCustodianModel): CustodianModel => {
    return {
      id: model.id,
      name: model.name,
      integrationLink: model.baa_instruction_link ?? undefined,
    };
  },
};
