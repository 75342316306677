export const PRODUCT_DETAILS_ROLLING_RETURNS = {
  analyzingWindow: `Analyzing Rolling Return Windows...`,
  averageAnnualYield: 'Average Realized Annual Yield',
  averageAnnualYieldTooltip:
    'The average realized yield the note produced annually, considering the callability features of the note, when analyzing the {days} Rolling Return windows between {dateRange}.',
  averageLifetime: 'Average Lifetime',
  averageLifetimeMonths: '{months, plural, one {# Month} other {# Months}}',
  averageLifetimeTooltip:
    'The average lifetime of the note, considering the callability features of the note, when analyzing the {days} Rolling Return windows between {dateRange}.',
  averagePrincipalReturned: 'Average Principal Returned',
  averagePrincipalReturndTooltip:
    'The average return of principal at maturity when analyzing the {days} Rolling Return windows between {dateRange}.',
  averageReturnTooltip:
    'The average return at maturity when analyzing the {days} Rolling Return windows between {dateRange}.',
  bestReturnTooltip:
    'The best return at maturity when analyzing the {days} Rolling Return windows between {dateRange}.',
  frequencyTitle: '{title} Frequency',
  negativeFrequencyTooltip: 'The percentage of outcomes that had negative returns {when}',
  negativeTotalReturnsTooltip: 'The worst return {when}',
  noteCouponSummary: 'Note Coupon Summary',
  noteVsFrequencyTitle: 'Note vs. {title} Frequency Comparison',
  noteVsPerformanceTitle: 'Note vs. {title} Performance',
  noteVsPrincipalReturnedTitle: 'Note vs. {title} Principal Returned',
  noteVsTotalReturnTitle: 'Note vs. {title} Total Return',
  numberOfCouponsPaid: '# of Coupons Paid',
  numberOfCouponsPaidTooltip:
    'The average number of coupons paid and potential coupons being available, considering the callability features of the note, when analyzing the {days} Rolling Return windows between {dateRange}.',
  percentOfCouponsPaid: '% of Coupons Paid',
  percentOfCouponsPaidTooltip:
    'The percentage of coupons paid, considering the callability features of the note, when analyzing the {days} Rolling Return windows between {dateRange}.',
  percentTimeFullPrincipal: '% Time Full Principal Returned',
  percentTimeFullPrincipalTooltip:
    'The percentage of outcomes where the full principal was returned at maturity when analyzing the {days} Rolling Return windows between {dateRange}.',
  performanceTitle: '{title} Performance',
  principalReturned: 'Principal Returned',
  principalReturnedTitle: '{title} Principal Returned',
  positiveFrequencyTooltip: 'The percentage of outcomes that had positive returns {when}',
  positiveTotalReturnsTooltip: 'The best return {when}',
  tooltipWhen: 'at maturity when analyzing the {days} Rolling Return windows between {dateRange}.',
  totalReturn: 'Total Return',
  totalReturnTitle: '{title} Total Return',
  weightedUnderlyingBasket: 'Weighted Underlying Basket',
  worstOfBasket: 'Worst-Of Underlying Basket',
  worstPrincipalReturned: 'Worst Principal Returned',
  worstPrincipalReturnedTooltip:
    'The worst return of principal at maturity when analyzing the {days} Rolling Return windows between {dateRangeText}.',
  worstReturnTooltip:
    'The worst return at maturity when analyzing the {days} Rolling Return windows between {dateRange}.',
  zeroFrequencyTooltip: 'The percentage of outcomes that had zero returns {when}',
  zeroTotalReturnsTooltip: 'The average return {when}',
};
