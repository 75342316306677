import { ReactElement } from 'react';

import { startAuctionFromLifecycleAtom } from '@halo-atoms/auctions';
import { lifecyclePretradeModalAtom } from '@halo-atoms/portfolio';
import { ProductCard } from '@halo-common/components';
import { ProductTypeEnum } from '@halo-common/enums';
import { useCommonUserSetting } from '@halo-common/hooks';
import type { NoteModel, PortfolioLifecycleRecommendationEnhancedModel, ProductModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { useRecordActivityMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Iconography, LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useSetAtom } from 'jotai';

const containerSx = {
  mt: 2,
  flexBasis: '40%',
};

export type PortfolioEventsAuctionReinvestmentProps = {
  reinvestment?: PortfolioLifecycleRecommendationEnhancedModel;
};

export const PortfolioEventsAuctionReinvestment = ({
  reinvestment,
}: PortfolioEventsAuctionReinvestmentProps): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();
  const { mutate: recordActivity } = useRecordActivityMutation();

  const isEnabledToAuction = useCommonUserSetting('enabled_to_start_auction', 'Auction');

  const setLifecyclePretradeModal = useSetAtom(lifecyclePretradeModalAtom);
  const setStartAuctionFromLifecycle = useSetAtom(startAuctionFromLifecycleAtom);

  const note = reinvestment?.note ?? undefined;

  // TODO: Remove this and simply set auctionDisabled to !isEnabledToAuction once the common user settings are fully implemented
  const auctionDisabled =
    isEnabledToAuction !== undefined ? !isEnabledToAuction : !userInfo?.settings?.isEnabledToAuction;

  const handleNavigation = () => {
    const verb = `started auction reinvestment with note ${note?.id}`;
    const modelName = 'Lifecycle';

    recordActivity({ activity: [{ verb, model_name: modelName }], fromIFrame: false });
    setStartAuctionFromLifecycle(true);
    navigateParentTo(`/auction/${note?.id}`);
  };

  const handleCardClick = (id: number, type: ProductTypeEnum, product: ProductModel) => {
    setLifecyclePretradeModal({ event: { id, note: product as NoteModel }, open: true });
  };

  return (
    <Stack sx={containerSx} direction="column" alignItems="center" spacing={2}>
      <LocalizedTypography variant="subtitle1">{translations.portfolio.lifecycle.seeSimilarNote}</LocalizedTypography>
      <ProductCard product={note} type={ProductTypeEnum.note} onClick={handleCardClick} />
      <LocalizedButton
        variant="contained"
        color="primary"
        onClick={handleNavigation}
        startIcon={<Iconography iconName="gavel" color="common.white" />}
        disabled={auctionDisabled}
      >
        {translations.portfolio.lifecycle.sendToAuction}
      </LocalizedButton>
    </Stack>
  );
};
