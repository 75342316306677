import { MouseEvent, ReactElement, useEffect, useMemo } from 'react';

import { fixValidationErrorsAtom, orderTicketStepAtom } from '@halo-atoms/orderTicket';
import { useOrderTicketContentPicker } from '@halo-common/hooks';
import {
  BulkOrderTicketFormFields,
  SingleOrderTicketFormFields,
  buildOrderTicketFormResolver,
  buildOrderTicketFormSchema,
} from '@halo-common/schemas';
import { useUserWebHooksQuery } from '@halo-data-sources/queries';
import { ElementSelector, ModalBase } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtomValue, useSetAtom } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';

import { OrderTicketReview } from './OrderTicketReview';
import { OrderTicketSubmission } from './OrderTicketSubmission';
import { OrderTicketSuccess } from './OrderTicketSuccess';

const BULK_DEFAULTS: Pick<BulkOrderTicketFormFields, 'allocations' | 'capacityType' | 'solicited'> = {
  allocations: [{ accountOption: null }],
  capacityType: null,
  solicited: null,
};

const SINGLE_DEFAULTS: Pick<SingleOrderTicketFormFields, 'account' | 'discretion' | 'ttoRepCode'> = {
  account: null,
  discretion: null,
  ttoRepCode: '',
  // TODO: Uncomment when rep code data is complete on BE
  // ttoRepCode: null,
};

export type OrderTicketFormModelFields = BulkOrderTicketFormFields | SingleOrderTicketFormFields;

export type OrderTicketModalProps = {
  open: boolean;
  bulk?: boolean;
  onClose: () => void;
  onUpdateOrder?: () => void;
};

export const OrderTicketModal = ({
  open,
  bulk = false,
  onClose,
  onUpdateOrder,
}: OrderTicketModalProps): ReactElement => {
  const { hasDisclaimerValidation } = useOrderTicketContentPicker();
  const { data: webhooks } = useUserWebHooksQuery();

  const step = useAtomValue(orderTicketStepAtom.currentPageAtom);
  const handleResetStep = useSetAtom(orderTicketStepAtom.handleResetAtom);
  const fixErrors = useAtomValue(fixValidationErrorsAtom);

  const schema = buildOrderTicketFormSchema(bulk, hasDisclaimerValidation);

  const overrideLegend1 = Boolean(webhooks?.['react:soe:legend_code']);

  const defaultValues = useMemo(
    () => ({
      ...(bulk ? BULK_DEFAULTS : SINGLE_DEFAULTS),
      legend1: overrideLegend1 ? '8' : '',
    }),
    [bulk, overrideLegend1],
  );

  const formMethods = useForm<OrderTicketFormModelFields>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    resolver: bulk ? buildOrderTicketFormResolver(schema, fixErrors) : yupResolver(schema),
    defaultValues,
  });

  const { reset: resetForm } = formMethods;

  const handleClose = () => {
    resetForm(defaultValues);
    handleResetStep();
    onClose();
  };

  const handleModalClose = (_: MouseEvent<HTMLElement>, reason: string) => {
    if (reason !== 'backdropClick') handleClose();
  };

  useEffect(() => {
    resetForm(defaultValues);
  }, [defaultValues, resetForm]);

  return (
    <FormProvider {...formMethods}>
      <ModalBase open={open} onClose={handleModalClose}>
        <ElementSelector selected={step}>
          <OrderTicketSubmission bulk={bulk} onClose={handleClose} onUpdateOrder={onUpdateOrder} />
          <OrderTicketReview bulk={bulk} onClose={handleClose} onUpdateOrder={onUpdateOrder} />
          <OrderTicketSuccess bulk={bulk} onClose={handleClose} />
        </ElementSelector>
      </ModalBase>
    </FormProvider>
  );
};
