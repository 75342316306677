import { ReactElement } from 'react';

import { ActionButton } from '@halo-common/components';
import { Iconography, Stack } from '@halodomination/halo-fe-common';
import { Button, useMediaQuery, useTheme } from '@mui/material';

export type DeleteWatchlistModalFooterProps = {
  loading?: boolean;
  onBack: () => void;
  onDelete: () => void;
};

export const DeleteWatchlistModalFooter = ({
  loading = false,
  onBack,
  onDelete,
}: DeleteWatchlistModalFooterProps): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints?.down('md'));

  const direction = isMobile ? 'column-reverse' : 'row';
  const justify = isMobile ? 'flex-start' : 'space-between';
  const cols = isMobile ? 12 : undefined;
  const spacing = isMobile ? 2 : undefined;

  return (
    <Stack direction={direction} justify={justify} xs={cols} spacing={spacing}>
      <Button
        variant="text"
        size="large"
        disableRipple
        disableElevation
        startIcon={<Iconography iconName="arrow-left" />}
        onClick={onBack}
        fullWidth={isMobile}
      >
        Go Back
      </Button>
      <ActionButton
        variant="contained"
        size="large"
        color="primary"
        disableRipple
        disableElevation
        onClick={onDelete}
        disabled={loading}
        loading={loading}
        fullWidth={isMobile}
      >
        Confirm Delete
      </ActionButton>
    </Stack>
  );
};
