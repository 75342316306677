import { UnderlyingBasketDisplayEnum, UnderlyingBasketEnum } from '@halo-common/enums';
import {
  NoteEvaluationModel,
  PortfolioOrderModel,
  PortfolioOrderUnderlyingPerformanceChartModel,
  PortfolioOrderUnderlyingPerformanceDataAverages,
  PostTradePriceHistoryDetails,
  PostTradeProductDetails,
  UnderlyingPerformanceTableDataModel,
  V2NoteModel,
} from '@halo-common/models';
import {
  fetchPortfolioOrder,
  fetchPortfolioOrderEvaluation,
  fetchPortfolioOrderTermsheetPriceHistory,
} from '@halo-data-sources/clients';
import { mapApiPortfolioOrderModelToPortfolioOrderModel } from '@halo-data-sources/mappers';
import { ApiFetchPortfolioOrderResponseModel } from '@halo-data-sources/models';
import { PDMSwitchKeyFactory } from '@halo-data-sources/switches';
import {
  isInDevBuildEnv,
  LineChartDataPoint,
  LineChartLineDefinition,
  LineChartReferenceLine,
  LineChartReferenceLineTypeEnum,
} from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { darken, useTheme } from '@mui/material';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { DateTime } from 'luxon';

type GenerateUnderlyingAnalyticsParams = {
  data: PostTradePriceHistoryDetails;
  basket: V2NoteModel['underlyings'];
  protection: number;
  noteEvaluation: NoteEvaluationModel;
  refColor?: string;
};

export type PostTradeProductDetailsDataSwitchPayload = {
  nme: boolean;
  refColor?: string;
};

export type PostTradeProductChartAnalytics = {
  lines: Array<LineChartLineDefinition>;
  references: Array<LineChartReferenceLine>;
  data: Array<LineChartDataPoint>;
};

export type PostTradeProductDetailsAnalyticsTableDetails = {
  rows: Array<UnderlyingPerformanceTableDataModel>;
};

export type PostTradeProductDetailsAnalytics = {
  chart: PostTradeProductChartAnalytics;
  table?: PostTradeProductDetailsAnalyticsTableDetails;
};

export type PostTradeProductDetailsDataSwitchResult = {
  product?: V2NoteModel;
  history: PostTradePriceHistoryDetails;
  underlyingAnalytics: PostTradeProductDetailsAnalytics;
  noteAnalytics: PostTradeProductDetailsAnalytics;
};

const getUnderlyingPriceHistoryFn = async (id: number): Promise<PostTradePriceHistoryDetails> => {
  try {
    const response = await fetchPortfolioOrderTermsheetPriceHistory(id);

    const underlyings = response.tradables;
    const dates = response.dates;
    const returns = response.index;

    return { dates, returns, underlyings };
  } catch (e) {
    if (isInDevBuildEnv()) console.log(e);
    return { dates: [], returns: [], underlyings: [] };
  }
};

const generateUnderlyingAnalytics = ({
  data,
  basket,
  protection,
  noteEvaluation,
  refColor,
}: GenerateUnderlyingAnalyticsParams) => {
  const { dates, underlyings } = data;

  const { dates: noteDates = [], noteReturns = [] } = noteEvaluation;
  const formattedNoteDates = noteDates.map((date) => DateTime.fromISO(date).toSeconds());

  if (!underlyings?.length || !dates?.length) {
    return { table: { rows: [] }, chart: { data: [], lines: [], references: [] } };
  }

  const underlyingNames = underlyings.map(({ name }) => name);

  let basketType = UnderlyingBasketEnum.SINGLE;
  if (basket.some((underlying) => underlying.weight)) basketType = UnderlyingBasketEnum.WEIGHTED;
  else if (basket.length > 1) basketType = UnderlyingBasketEnum.WORST_OF;

  const calculatePerformance = (underlying: string) => {
    const values = underlyings.find(({ name }) => name === underlying)?.prices ?? [];
    const marketPrice = values[values.length - 1];
    const initialFixingLevel = values[0];
    const performance = ((marketPrice - initialFixingLevel) / initialFixingLevel) * 100;
    const barrierLevel = initialFixingLevel * (1 - protection / 100);
    const distanceToBarrier = ((barrierLevel - marketPrice) / marketPrice) * 100;

    return { marketPrice, initialFixingLevel, performance, barrierLevel, distanceToBarrier };
  };

  const calculateWeightedAverages = (totals: PortfolioOrderUnderlyingPerformanceDataAverages, underlying: string) => {
    const weight = basket?.find((item) => item.ticker === underlying)?.weight;
    const weightedValue = weight ? weight / 100 : 1;

    const data = calculatePerformance(underlying);

    const currentPerformance = totals.performance ?? 0;
    const updatedMarketPrice = totals.marketPrice + data.marketPrice * weightedValue;
    const updatedInitialLevel = totals.initialFixingLevel + data.initialFixingLevel * weightedValue;
    const updatedPerformance = currentPerformance + data.performance * weightedValue;
    const updatedBarrierLevel = totals.barrierLevel + data.barrierLevel * weightedValue;
    const updatedDistanceToBarrier = totals.distanceToBarrier + data.distanceToBarrier * weightedValue;

    return {
      marketPrice: updatedMarketPrice,
      initialFixingLevel: updatedInitialLevel,
      performance: updatedPerformance,
      barrierLevel: updatedBarrierLevel,
      distanceToBarrier: updatedDistanceToBarrier,
    };
  };

  const findWorstOfIndex = (currentIndex: number, underlying: string, index: number) => {
    const data = calculatePerformance(underlying);
    const isWorse = underlyingNames.every((item) => data.performance <= calculatePerformance(item).performance);
    return !isWorse ? currentIndex : index;
  };

  const generateTableData = (underlyingNames: Array<string>) => {
    const rows: Array<UnderlyingPerformanceTableDataModel> = underlyingNames.map((underlyingName, index) => {
      const name = underlyingName.toUpperCase();
      return { id: name, empty: false, colorId: index, name, ...calculatePerformance(name) };
    });

    if (basketType === UnderlyingBasketEnum.WEIGHTED) {
      const name = UnderlyingBasketDisplayEnum[basketType];

      const weightedDefaults = {
        marketPrice: 0,
        initialFixingLevel: 0,
        performance: 0,
        barrierLevel: 0,
        distanceToBarrier: 0,
      };

      const performance = underlyingNames.reduce(calculateWeightedAverages, weightedDefaults);

      rows.push({ id: basketType, name, colorId: underlyingNames.length, ...performance });
    } else if (basketType === UnderlyingBasketEnum.WORST_OF) {
      const worstOfIndex = underlyingNames.reduce(findWorstOfIndex, 0);
      rows[worstOfIndex].id = basketType;
    }

    return { rows: rows.map((row) => ({ ...row, colorId: row.colorId + 1 })) };
  };

  const generateChartData = (underlyingNames: Array<string>) => {
    const chartLineDefinitions: Array<LineChartLineDefinition> = underlyingNames.map((name) => ({
      label: name.toUpperCase(),
    }));

    if (basketType === UnderlyingBasketEnum.WEIGHTED) chartLineDefinitions.push({ label: parsedBasketName });

    const chartDataMap = underlyingNames.reduce(
      (map, underlying) => {
        const values = underlyings.find(({ name }) => name === underlying)?.returns ?? [];
        const name = underlying.toUpperCase();

        return values.reduce((valueMap: PortfolioOrderUnderlyingPerformanceChartModel['data'], value, index) => {
          const underlyingValue = parseFloat(value.toFixed(2));
          const underlyingDate = DateTime.fromISO(dates[index]).toSeconds();

          const noteDateIndex = formattedNoteDates.findIndex((date) => date === underlyingDate);
          const noteReturn = noteDateIndex !== -1 ? noteReturns[noteDateIndex] : null;
          const noteValue = noteReturn !== null ? parseFloat(noteReturn?.toFixed(2)) : null;

          const updatedMap = {
            ...valueMap,
            [index]: {
              ...valueMap[index],
              x: underlyingDate,
              [name]: underlyingValue,
              ['Note Value']: noteValue,
            },
          };

          if (basketType === UnderlyingBasketEnum.WEIGHTED) {
            const currentValue = updatedMap[index]?.[parsedBasketName] ?? 0;
            const weight = basket?.find((item) => item.ticker === underlying)?.weight;
            const weightedValue = weight ? weight / 100 : 1;
            const updatedBasketValue = currentValue + underlyingValue * weightedValue;
            updatedMap[index][parsedBasketName] = parseFloat(updatedBasketValue.toFixed(2));
          }

          return updatedMap;
        }, map);
      },
      {} as PortfolioOrderUnderlyingPerformanceChartModel['data'],
    );

    const noteValueLineColor = refColor ? darken(refColor, 0.05) : '';
    const noteValueLine = { label: 'Note Value', area: true, color: noteValueLineColor };

    return {
      data: Object.values(chartDataMap),
      lines: [noteValueLine, ...chartLineDefinitions],
      references: [] as Array<LineChartReferenceLine>,
    };
  };

  const parsedBasketName = UnderlyingBasketDisplayEnum[basketType];
  const tableData = generateTableData(underlyingNames);
  const chartData = generateChartData(underlyingNames);

  return { table: tableData, chart: chartData };
};

const getNoteEvaluationFn = async (id: number) => {
  const response = await fetchPortfolioOrderEvaluation(id.toString());

  const { dates, values: returns, returns: noteReturns } = response;

  return { dates, returns, noteReturns };
};

const generateNoteAnalytics = (dates: Array<string>, returns: Array<number>, cap: number) => {
  const capLine: LineChartReferenceLine = { point: cap, label: 'Breach Level', orientation: 'y' };

  const valueChartData = dates.map((date, index) => ({
    x: DateTime.fromISO(date).toSeconds(),
    'Note Value': returns?.[index] ? parseFloat(returns?.[index]?.toFixed(2)) : null,
  }));

  return {
    chart: {
      lines: [{ label: 'Note Value' }] as Array<LineChartLineDefinition>,
      data: valueChartData as Array<LineChartDataPoint>,
      references: [capLine],
    },
  };
};

const getPostTradeProductAnalyticsDataFn = async (
  id: number,
  details: PostTradeProductDetails,
): Promise<PostTradeProductDetailsDataSwitchResult> => {
  const protection = details.protection;
  const basket = details.basket;
  const cap = details.cap;
  const refColor = details.refColor;

  const history = await getUnderlyingPriceHistoryFn(id);
  const noteEvaluation = await getNoteEvaluationFn(id);

  const underlyingAnalytics = generateUnderlyingAnalytics({
    data: history,
    basket,
    protection,
    noteEvaluation,
    refColor,
  });

  const noteAnalytics = generateNoteAnalytics(noteEvaluation.dates, noteEvaluation.returns, cap);

  return { history, noteAnalytics, underlyingAnalytics };
};

const getV2PostTradeProductDetailsQueryFn = async (
  note: V2NoteModel,
  refColor?: PostTradeProductDetailsDataSwitchPayload['refColor'],
): Promise<PostTradeProductDetailsDataSwitchResult | null> => {
  const protection = note?.protection?.value ? parseFloat(note.protection?.value) : 0;

  const responses = [
    getPostTradeProductAnalyticsDataFn(note.id, {
      basket: note.underlyings,
      protection,
      type: note.type,
      productType: note.type,
      cap: protection * -1,
      refColor,
    }),
    fetchPortfolioOrder(note.id.toString()),
  ] as const;

  const response: [PostTradeProductDetailsDataSwitchResult, ApiFetchPortfolioOrderResponseModel] =
    await Promise.all(responses);

  const data = response[0];
  const apiPortfolioOrder = response[1];

  const underlyingAnalytics = { ...response[0].underlyingAnalytics };

  const lineTypes: LineChartReferenceLineTypeEnum[] = [
    LineChartReferenceLineTypeEnum.dash,
    LineChartReferenceLineTypeEnum.dot,
    LineChartReferenceLineTypeEnum.solid,
  ];
  if (note.triggerLevels) {
    note.triggerLevels.forEach((triggerLevel, index) => {
      const pointValue = Number(triggerLevel.value);

      if (!triggerLevel.value || Number.isNaN(pointValue)) return;

      const referenceLine: LineChartReferenceLine = {
        point: pointValue,
        label: triggerLevel.name,
        orientation: 'y',
        type: lineTypes[index % lineTypes.length],
      };

      underlyingAnalytics.chart.references.push(referenceLine);
    });
  }

  const order = mapApiPortfolioOrderModelToPortfolioOrderModel(apiPortfolioOrder);
  const product = { ...note, noteId: order?.termsheet?.noteId || note.noteId };

  return { ...data, underlyingAnalytics, product };
};

const getPostTradeProductDetailsQueryFn = async (
  order: PortfolioOrderModel,
  refColor?: PostTradeProductDetailsDataSwitchPayload['refColor'],
): Promise<PostTradeProductDetailsDataSwitchResult | null> => {
  const id = order.termsheet?.id;
  const protection = order.note.protectionPercent;
  const couponProtection = order.note.coupon?.protectionPercent;
  const maxReturn = order.note.maxReturn;

  if (!id) return null;

  const underlyings = order.note.tradables.map(({ name }) => ({
    ticker: name,
    value: name,
    weight: order.note.tradableWeights?.[name],
  }));

  const data = await getPostTradeProductAnalyticsDataFn(id, {
    basket: underlyings,
    protection,
    type: order.note.type,
    productType: order.note.productType,
    cap: order.note.protectionPercent * -1,
    refColor,
  });

  const underlyingAnalytics = { ...data.underlyingAnalytics };

  const hasValidBreachLevel = typeof protection === 'number';
  const showProtectionRefLine = hasValidBreachLevel && protection !== 100;

  const isPrincipalDifferentFromCouponProtection = couponProtection !== protection;
  const hasCouponProtection = typeof couponProtection === 'number';
  const showCouponProtectionRefLine = hasCouponProtection && isPrincipalDifferentFromCouponProtection;

  const showMaxReturnRefLine = typeof maxReturn === 'number';

  if (showProtectionRefLine) {
    underlyingAnalytics.chart.references = [
      ...underlyingAnalytics.chart.references,
      { point: -protection, label: 'Breach Level', orientation: 'y', type: 'dash' },
    ];
  }

  if (showCouponProtectionRefLine) {
    underlyingAnalytics.chart.references = [
      ...underlyingAnalytics.chart.references,
      { point: -couponProtection, label: 'Coupon Protection', orientation: 'y', type: 'dot' },
    ];
  }

  if (showMaxReturnRefLine) {
    underlyingAnalytics.chart.references = [
      ...underlyingAnalytics.chart.references,
      { point: maxReturn, label: 'Max Return', orientation: 'y' },
    ];
  }

  return { ...data, underlyingAnalytics };
};

const postTradeProductDetailsQueryDataSwitchFn = (
  product: V2NoteModel | PortfolioOrderModel | null,
  payload: PostTradeProductDetailsDataSwitchPayload,
) => {
  if (!product) return null;
  else if (payload.nme) return getV2PostTradeProductDetailsQueryFn(product as V2NoteModel, payload?.refColor);
  else return getPostTradeProductDetailsQueryFn(product as PortfolioOrderModel, payload?.refColor);
};

export const usePostTradeProductDetailsDataSwitch = (
  product: V2NoteModel | PortfolioOrderModel | null,
  options: { nme?: boolean; enabled: boolean },
): UseQueryResult<PostTradeProductDetailsDataSwitchResult | null, Error> => {
  const theme = useTheme<HaloTheme>();
  const refColor = theme.palette?.common.charts?.accent1.background;

  const nme = Boolean(options?.nme);
  const v1ProductId = !nme ? (product as PortfolioOrderModel)?.termsheet?.id : undefined;
  const v2ProductId = nme ? (product as V2NoteModel)?.id : undefined;
  const id = v1ProductId ?? v2ProductId;

  return useQuery<PostTradeProductDetailsDataSwitchResult | null, Error>({
    queryKey: PDMSwitchKeyFactory.postTrade(id),
    queryFn: () => postTradeProductDetailsQueryDataSwitchFn(product, { refColor, nme }),
    enabled: Boolean(id) && options.enabled,
  });
};
