import { ReactElement } from 'react';

import { NoteSpecificDetailsField } from '@halo-common/hooks';
import { ProductDetailPanelSection, ProductDetailPanelSectionField } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';

export type ProductDetailPanelCommissionSectionProps = {
  field?: NoteSpecificDetailsField;
  loading: boolean;
};

export const ProductDetailPanelCommissionSection = ({
  field,
  loading,
}: ProductDetailPanelCommissionSectionProps): ReactElement | null => {
  const label = field?.label ?? '';
  const description = field?.text ?? '';

  return (
    <ProductDetailPanelSection title={translations.pdm.common.commissionSection} loading={loading}>
      <ProductDetailPanelSectionField label={label} description={description} loading={loading} />
    </ProductDetailPanelSection>
  );
};
