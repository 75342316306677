import { calendarManagerAtom, calendarSelectedAtom } from '@halo-atoms/calendars';
import { CalendarFavoriteModel, CalendarModel } from '@halo-common/models';
import { deleteCalendarFavorite, postCalendarFavorite } from '@halo-data-sources/clients';
import { ApiCalendarsMapper } from '@halo-data-sources/mappers';
import {
  CalendarsQueryKeyFactory,
  FeaturedCalendarQueryKeyFactory,
  FeaturedCalendarResponseModel,
  PortfolioLifecycleQueryKeyFactory,
  ProductApprovalCalendarResult,
} from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { InfiniteData, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';

export type UseCalendarFavoriteMutationParams = {
  id: number;
  favorite: boolean;
  assetIdentifier: string;
  assetIdentifierType: string;
};

const updateCalendarFavoriteFn = async (payload: UseCalendarFavoriteMutationParams) => {
  const { favorite, id } = payload;

  const response = !favorite ? await deleteCalendarFavorite(id) : await postCalendarFavorite(id);

  return ApiCalendarsMapper.toCalendarFavoriteModel(response);
};

export const useCalendarFavoriteMutation = (
  termsheetId?: number,
): UseMutationResult<CalendarFavoriteModel, Error, UseCalendarFavoriteMutationParams> => {
  const queryClient = useQueryClient();

  const { query: calendarQuery } = useAtomValue(calendarManagerAtom);

  const [selectedCalendar, setSelectedCalendar] = useAtom(calendarSelectedAtom);

  const calendarsQueryKey = CalendarsQueryKeyFactory.infinite(calendarQuery);
  const calendarQueryKey = CalendarsQueryKeyFactory.calendar(selectedCalendar?.id);
  const calendarReinvestmentQueryKey = PortfolioLifecycleQueryKeyFactory.calendar(termsheetId);
  const featuredCalendarQueryKey = FeaturedCalendarQueryKeyFactory.query();

  const { enqueueErrorEvent } = useSnackbar();

  const updateCalendarFavoriteCache = (id: number, isFavorite: boolean) => {
    setSelectedCalendar((prev) => {
      if (!prev) return prev;
      return { ...prev, isFavorite };
    });

    queryClient.setQueryData<CalendarModel>(calendarReinvestmentQueryKey, (prev) => {
      if (!prev) return prev;
      return { ...prev, isFavorite };
    });

    queryClient.setQueryData<CalendarModel>(calendarQueryKey, (prev) => {
      if (!prev) return prev;
      return { ...prev, isFavorite };
    });

    queryClient.setQueryData<InfiniteData<ProductApprovalCalendarResult>>(calendarsQueryKey, (prev) => {
      if (!prev) return prev;

      const updatedPages = prev.pages.map((page) => {
        const updatedCalendars = page.calendars.map((calendar) => {
          if (calendar.id === id) return { ...calendar, isFavorite };
          return calendar;
        });

        return { ...page, calendars: updatedCalendars };
      });

      return { ...prev, pages: updatedPages };
    });

    queryClient.setQueryData<FeaturedCalendarResponseModel>(featuredCalendarQueryKey, (prev) => {
      if (!prev) return prev;

      const updatedOfferings = prev.map((item) => {
        if (item.calendar.id !== id) return item;
        const updatedCalendar = { ...item.calendar, isFavorite };
        return { ...item, calendar: updatedCalendar };
      });

      return updatedOfferings;
    });
  };

  return useMutation<CalendarFavoriteModel, Error, UseCalendarFavoriteMutationParams>({
    mutationFn: updateCalendarFavoriteFn,
    onMutate: (payload) => {
      if (payload?.id) updateCalendarFavoriteCache(payload.id, payload.favorite);
    },
    onError: (_, payload) => {
      const message = `There was an error when trying to favorite ${payload?.assetIdentifierType}: ${payload?.assetIdentifier}, please refresh the page and try again.`;
      enqueueErrorEvent({ message });

      if (payload?.id) updateCalendarFavoriteCache(payload.id, !payload.favorite);
    },
  });
};
