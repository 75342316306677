import { AssetIdentifierEnum } from '@halo-common/enums';
import { useUserInfoQuery } from '@halo-data-sources/queries';

type assetIdentifier = {
  identifier: string;
  identifierType: AssetIdentifierEnum;
  identifierText: string;
};

type primaryAssetIdentifier = assetIdentifier & {
  identifiers: Array<string>;
  identifierText: string;
  fallback: assetIdentifier;
};

type ProductType = Partial<{
  fundserv?: string;
  isin?: string;
  cusip?: string;
}>;

export const usePrimaryAssetIdentifier = (product?: ProductType | null): primaryAssetIdentifier => {
  const { data } = useUserInfoQuery();

  const primaryAssetIdentifier = data?.whiteLabel?.primaryAssetIdentifier;
  const assetIdentifiers = data?.whiteLabel?.assetIdentifiers;

  const isFundServePrimaryId = primaryAssetIdentifier === AssetIdentifierEnum.FUNDSERVCODE;
  const isIsinPrimaryId = primaryAssetIdentifier === AssetIdentifierEnum.ISIN;

  const fundserv = product?.fundserv ?? '';
  const isin = product?.isin ?? '';
  const cusip = product?.cusip ?? '';

  const defaultAssetIdentifier = {
    identifier: '',
    identifiers: [],
    identifierType: AssetIdentifierEnum.NONE,
    identifierText: '',
  };

  const result: primaryAssetIdentifier = {
    ...defaultAssetIdentifier,
    fallback: defaultAssetIdentifier,
  };

  if (isFundServePrimaryId) {
    result.identifier = fundserv;
    result.identifierType = AssetIdentifierEnum.FUNDSERVCODE;
    result.fallback.identifier = isin ?? cusip;
    result.fallback.identifierType = isin ? AssetIdentifierEnum.ISIN : AssetIdentifierEnum.CUSIP;
  } else if (isIsinPrimaryId) {
    result.identifier = isin;
    result.identifierType = AssetIdentifierEnum.ISIN;
    result.fallback.identifier = cusip ?? fundserv;
    result.fallback.identifierType = cusip ? AssetIdentifierEnum.CUSIP : AssetIdentifierEnum.FUNDSERVCODE;
  } else {
    result.identifier = cusip;
    result.identifierType = AssetIdentifierEnum.CUSIP;
    result.fallback.identifier = isin ?? fundserv;
    result.fallback.identifierType = isin ? AssetIdentifierEnum.ISIN : AssetIdentifierEnum.FUNDSERVCODE;
  }

  result.identifiers = assetIdentifiers ?? [result.identifierType, result.fallback.identifierType];
  result.identifierText = `${result.identifierType.toUpperCase()} #${result.identifier}`;
  result.fallback.identifierText = `${result.fallback.identifierType.toUpperCase()}# ${result.fallback.identifier}`;

  return result;
};
