import { ConfirmationEnum } from '@halo-common/enums';
import { CalendarModel, ExecutionOrderModel, OrderTicketReceiptModel } from '@halo-common/models';
import { SingleOrderTicketFormFields } from '@halo-common/schemas';
import { submitCalendarOrder } from '@halo-data-sources/clients';
import { ApiExecOrderMapper } from '@halo-data-sources/mappers';
import { ApiSubmissionOrderRequestModel } from '@halo-data-sources/models';
import { DateTime } from 'luxon';

export const janneyOrderTicketSubmissionSwitchFn = async (
  calendar: CalendarModel,
  order?: ExecutionOrderModel,
  data?: SingleOrderTicketFormFields,
): Promise<OrderTicketReceiptModel | null> => {
  if (!data || !order || !data.account?.account) return null;

  const orderDate = DateTime.local().toISO();

  const custom: ApiSubmissionOrderRequestModel['custom'] = {
    order_receipt_time: orderDate,
    order_receive_from: data.orderFrom,
  };

  if (calendar.price) {
    custom.original_offering_price = calendar.price;
  }

  if (data.solicited) {
    custom.solicited = data.solicited.toUpperCase();
  }

  if (data.discretion) {
    custom.DiscretionUsed = data.discretion === ConfirmationEnum.Yes;
  }

  if (data.ttoRepCode) {
    custom.tto_rep_code = data.ttoRepCode.toUpperCase();
    // TODO: Uncomment when rep code data is complete on BE
    // custom.tto_rep_code = data.ttoRepCode.name;
  }

  const response = await submitCalendarOrder({
    account_id: data.account.account.id,
    calendar_id: calendar.id,
    custom,
    exec_order_id: order.id,
    quantity: parseInt(data.quantity),
  });

  const execOrder = response.exec_order;
  const summary = response.extra;

  return {
    status: 'success',
    order: ApiExecOrderMapper.toExecutionOrder(execOrder),
    summary: {
      principal: summary.principal,
      commission: summary.commission,
      otherMiscFees: summary.other_misc_fees,
      salesCredit: summary.sales_credit,
      total: summary.total,
    },
  };
};
