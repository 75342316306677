import { ReactElement } from 'react';

import { Box } from '@mui/material';
import Slider, { Settings } from 'react-slick';

import { NotesCarouselNextArrow } from './NotesCarouselNextArrow';
import { NotesCarouselPrevArrow } from './NotesCarouselPrevArrow';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const defaultSettings: Settings = {
  dots: false,
  infinite: false,
  speed: 400,
  initialSlide: 0,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  nextArrow: <NotesCarouselNextArrow />,
  prevArrow: <NotesCarouselPrevArrow />,
};

// Refs: https://github.com/akiran/react-slick/issues/1940#issuecomment-739693766
const sx = {
  '& .slick-list': {
    overflow: 'visible',
  },
  '& .slick-track': {
    minHeight: 360,
    display: 'flex',
    alignItems: 'stretch',
    paddingY: 2,
    '& .slick-slide': {
      height: 'auto',
      display: 'flex',
      alignItems: 'stretch',
      '& > div': {
        height: 'auto',
        display: 'flex',
        alignItems: 'stretch',
        '& > div': {
          display: 'flex !important',
          '& > :first-of-type': {
            flex: '288 0 auto',
          },
          '& > :nth-of-type(2)': {
            flex: '48 0 48px',
          },
        },
      },
    },
  },
};

export type NotesCarouselProps = Partial<Settings>;

export const NotesCarousel = ({ children, ...passedSettings }: NotesCarouselProps): ReactElement => {
  const settings = {
    ...defaultSettings,
    ...passedSettings,
  };

  return (
    <Box sx={sx}>
      <Slider {...settings}>{children}</Slider>
    </Box>
  );
};
