import { ReactElement } from 'react';

import { ActionButton } from '@halo-common/components';
import { UploadFileModalStatuses } from '@halo-common/modals';
import { translations } from '@halo-common/translations';
import { LocalizedButton, Stack } from '@halodomination/halo-fe-common';

export type UploadFileModalFooterStepOptions = {
  action: () => void;
  disabled?: boolean;
  loading?: boolean;
  text?: string;
};

export type UploadFileModalFooterProps = {
  approvalStep?: UploadFileModalFooterStepOptions;
  firstStep?: UploadFileModalFooterStepOptions;
  onBack: () => void;
  onClose: () => void;
  status?: keyof typeof UploadFileModalStatuses;
};

export const UploadFileModalFooter = ({
  approvalStep,
  firstStep,
  onBack,
  onClose,
  status,
}: UploadFileModalFooterProps): ReactElement => {
  const isError = status === UploadFileModalStatuses.error;
  const isPending = status === UploadFileModalStatuses.pending;
  const isSuccess = status === UploadFileModalStatuses.success;
  const isApproval = status === UploadFileModalStatuses.approval;

  if (isError) {
    return (
      <Stack direction="row" justify="flex-end" spacing={2}>
        <LocalizedButton type="button" variant="outlined" color="primary" onClick={onBack}>
          {translations.profile.documents.backToUpload}
        </LocalizedButton>
      </Stack>
    );
  } else if (isPending || isSuccess) {
    return (
      <Stack direction="row" justify="flex-end" spacing={2}>
        <LocalizedButton type="button" variant="outlined" color="primary" onClick={onClose}>
          {translations.common.close}
        </LocalizedButton>
      </Stack>
    );
  }

  const step = isApproval ? approvalStep : firstStep;

  return (
    <Stack direction="row" justify="space-between" spacing={2}>
      <LocalizedButton type="button" onClick={onClose}>
        {translations.common.cancel}
      </LocalizedButton>
      <ActionButton
        type="button"
        variant="contained"
        color="primary"
        onClick={step?.action}
        disabled={step?.disabled}
        loading={step?.loading}
      >
        {step?.text ?? translations.common.accept}
      </ActionButton>
    </Stack>
  );
};
