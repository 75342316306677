import { hash } from '@halo-common/utils';
import type { QueryKeyFactoryFn, TradingDaysQueryPayload } from '@halo-data-sources/queries';

type TradingDaysQueryKeys = {
  all: QueryKeyFactoryFn;
  range: QueryKeyFactoryFn;
};

export const TradingDaysQueryKeyFactory: TradingDaysQueryKeys = {
  all: () => ['trading-days'],
  range: (payload: TradingDaysQueryPayload) => [...TradingDaysQueryKeyFactory.all(), 'range', hash(payload)],
};
