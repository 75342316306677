import { ReactElement, useState } from 'react';

import { CalendarPageModel } from '@halo-common/models';
import { CalendarPageAccordionChild } from '@halo-modules/admin';
import { Iconography } from '@halodomination/halo-fe-common';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Stack } from '@mui/material';

const accordionStyling = {
  borderBottom: 'none',
};

const summaryStyling = {
  my: 0,
  height: 36,
};

export type CalendarPageAccordionProps = {
  page: CalendarPageModel;
  defaultPages?: Array<number>;
  onCheck: (pages: Array<number>, checked: boolean) => void;
};

export const CalendarPageAccordion = ({ page, defaultPages, onCheck }: CalendarPageAccordionProps): ReactElement => {
  const defaultParentCheckedStatus = Boolean(defaultPages?.includes(page.id));

  const defaultChildrenCheckedStatus: Array<boolean | undefined> = page.children.map((page) =>
    defaultPages?.includes(page.id),
  );
  const [checkedPages, setCheckedPages] = useState({
    parentChecked: defaultParentCheckedStatus,
    checkedChildren: defaultChildrenCheckedStatus,
  });

  const handleParentCheck = () => {
    const updatedStatus = !checkedPages.parentChecked;
    const updatedChildren = page.children.map(() => updatedStatus);
    const childIds = page.children.map((child) => child.id);
    const pageIds = [page.id, ...childIds];
    onCheck(pageIds, updatedStatus);
    setCheckedPages({ parentChecked: updatedStatus, checkedChildren: updatedChildren });
  };

  const parentPageName = `${page.id} ${page.organization?.name} - ${page.title}`;
  const parentId = page.id;

  const childPages = page.children.map((child, index) => {
    const childKey = `child-page-${child.id}`;
    const handleCheck = () => {
      const updatedValue = !checkedPages.checkedChildren[index];
      const updatedChildren = { ...checkedPages.checkedChildren, [index]: updatedValue };
      setCheckedPages({ ...checkedPages, checkedChildren: updatedChildren });
      onCheck([child.id], updatedValue);
    };
    return (
      <CalendarPageAccordionChild
        key={childKey}
        child={child}
        isChecked={checkedPages.checkedChildren[index] ?? false}
        onCheck={handleCheck}
      />
    );
  });

  const endIcon = page.children.length ? <Iconography iconName="angle-down" prefix="fal" color="text.faded" /> : null;

  return (
    <Accordion sx={accordionStyling} disableGutters square>
      <AccordionSummary sx={summaryStyling} expandIcon={endIcon}>
        <FormControlLabel
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <Checkbox
              checked={checkedPages.parentChecked}
              color="primary"
              value={parentId}
              onChange={handleParentCheck}
            />
          }
          label={parentPageName}
        />
      </AccordionSummary>
      {childPages ? (
        <AccordionDetails>
          <Stack direction="column">{childPages}</Stack>
        </AccordionDetails>
      ) : null}
    </Accordion>
  );
};
