export const DOCUMENTS_TERMSHEETS_TRANSLATIONS = {
  bondCode: 'Bond Code',
  confirmDeleteTitle: 'Deleting a Termsheet will permanently remove it for everyone.',
  confirmDeleteDescription: 'Be sure this is correct before deleting it.',
  cusip: 'CUSIP',
  currency: 'Currency',
  currencyId: 'Currency ID',
  description: 'Description',
  editTermsheet: 'Edit Termsheet',
  isin: 'ISIN',
  issuanceSize: 'Issuance Size',
  issueDate: 'Issue Date',
  issuer: 'Issuer',
  issuerId: 'Issuer ID',
  maturityDate: 'Maturity Date',
  noteType: 'Note Type',
  notional: 'Notional',
  primaryAssetIdentifier: 'Primary Asset Identifier',
  productType: 'Product Type',
  secondaryAssetIdentifiers: 'Secondary Asset Identifiers',
  uploadNew: 'Upload New',
  uploadNewTermsheet: 'Upload New Termsheet',
  weFoundMatchingTermsheet:
    'We found a {matchingTermsheet} in the database, please review the filled out fields for accuracy before saving.',
  matchingTermsheet: 'matching termsheet',
  createTermsheetSuccessMessage: 'Termsheet created successfully.',
  deleteTermsheetSuccessMessage: 'Termsheet deleted successfully.',
  deleteTermsheetsSuccessMessage: 'Termsheets deleted successfully.',
  updateTermsheetSuccessMessage: 'Termsheet updated successfully.',
  termsheetsSelected: '{count, plural, one {# Termsheet selected} other {# Termsheets selected}}',
};
