import { ReactElement } from 'react';

import { InstitutionalLineChart } from '@halo-common/components';
import { NoteModel, WatchlistDetailsProductModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useQuoteHistoryQuery } from '@halo-data-sources/queries';
import { WatchlistProductCard } from '@halo-modules/app';
import { Iconography, LocalizedAlert, LocalizedButton, Stack } from '@halodomination/halo-fe-common';

export type TargetedWatchlistCardProps = {
  id: WatchlistDetailsProductModel['id'];
  product: WatchlistDetailsProductModel['product'];
  type: WatchlistDetailsProductModel['type'];
  onRemove: (targetNoteId?: number) => void;
};

export const TargetedWatchlistCard = ({ id, product, type, onRemove }: TargetedWatchlistCardProps): ReactElement => {
  const { data, isFetching, isFetched } = useQuoteHistoryQuery(product as NoteModel);

  const handleRemove = () => void onRemove(id);

  const lines = data?.chart?.lines ?? [];
  const chartData = data?.chart?.data ?? [];
  const references = data?.chart?.references;

  const noQuoteHistory = !data || !chartData?.length;
  const showAlert = isFetched && noQuoteHistory;

  const alignment = !showAlert ? 'center' : 'flex-start';
  const elementSx = !showAlert ? [undefined, { flex: 1 }] : undefined;

  const handleTooltipValueFormat = (value: number) => {
    return `${value.toFixed(2)}%`;
  };

  const chart = showAlert ? (
    <LocalizedAlert severity="warning" variant="filled">
      {translations.watchlist.common.noQuotesFound}
    </LocalizedAlert>
  ) : (
    <InstitutionalLineChart
      height={370}
      lines={lines}
      data={chartData}
      loading={isFetching}
      referenceLines={references}
      xLabel="Time"
      yLabel="Quote Value"
      dataType="date"
      zoomUpdateTrigger="all"
      TooltipProps={{ formatter: handleTooltipValueFormat }}
    />
  );

  return (
    <Stack direction="row" alignItems={alignment} spacing={1} wrap="nowrap" elementStyling={elementSx}>
      <Stack direction="column" spacing={1}>
        <WatchlistProductCard product={product} type={type} />
        <LocalizedButton
          startIcon={<Iconography iconName="trash" color="primary.main" />}
          onClick={handleRemove}
          type="button"
          variant="text"
          fullWidth
        >
          {translations.common.remove}
        </LocalizedButton>
      </Stack>
      {chart}
    </Stack>
  );
};
