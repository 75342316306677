import { AllocationModel } from '@halo-common/models';
import { getCalendarAllocations } from '@halo-data-sources/clients';
import { ApiAllocationMapper } from '@halo-data-sources/mappers';
import { CalendarsQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getCalendarAllocationsFn = async (id?: number) => {
  if (typeof id !== 'number') return [];

  const response = await getCalendarAllocations(id.toString());

  return response.calendar.allocations.map((allocation) => ApiAllocationMapper.toAllocationModel(allocation));
};

export const useCalendarAllocationsQuery = (id?: number): UseQueryResult<Array<AllocationModel>, Error> =>
  useQuery<Array<AllocationModel>, Error>({
    queryKey: CalendarsQueryKeyFactory.allocations(id),
    queryFn: () => getCalendarAllocationsFn(id),
    retryOnMount: false,
  });
