import { getTermsheetRepoDocs } from '@halo-data-sources/clients';
import { ApiTermsheetRepoMapper } from '@halo-data-sources/mappers';
import type { ApiTermsheetRepoDocsOptionsModel, TermsheetRepoDocsResponseModel } from '@halo-data-sources/models';
import { TermsheetRepoQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryOptions, UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

export type UseTermsheetRepoDocsQueryOptions = UseQueryOptions<TermsheetRepoDocsResponseModel, Error>;
export type UseTermsheetRepoDocsQueryResult = UseQueryResult<TermsheetRepoDocsResponseModel, Error>;

export const getTermsheetRepoDocsQueryFn = async (
  searchParams: ApiTermsheetRepoDocsOptionsModel,
): Promise<TermsheetRepoDocsResponseModel> => {
  const data = await getTermsheetRepoDocs(searchParams);
  return ApiTermsheetRepoMapper.toTermsheetRepoDocsModel(data);
};

export const useTermsheetRepoDocsQuery = (
  searchParams: ApiTermsheetRepoDocsOptionsModel,
  options?: Partial<UseTermsheetRepoDocsQueryOptions>,
): UseTermsheetRepoDocsQueryResult =>
  useQuery({
    queryKey: TermsheetRepoQueryKeyFactory.docs(searchParams),
    queryFn: () => getTermsheetRepoDocsQueryFn(searchParams),
    placeholderData: keepPreviousData,
    ...options,
  });
