import { ReactElement } from 'react';

import { orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { AuctionStatusEnum } from '@halo-common/enums';
import { useBuyerAuctionQuery } from '@halo-data-sources/queries';
import { Iconography } from '@halodomination/halo-fe-common';
import { Stepper, StepConnector, stepConnectorClasses, Step, StepLabel, Box } from '@mui/material';
import { useAtomValue } from 'jotai';

const stepperSx = {
  width: '60%',
  ml: -1,
  mt: 1,
  mb: 3,
};

const connectorSx = {
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 4,
  },
};

const iconContainerSx = {
  alignItems: 'center',
  backgroundColor: 'primary.main',
  color: 'common.white',
  borderRadius: '50%',
  display: 'flex',
  height: 24,
  justifyContent: 'center',
  width: 24,
  zIndex: 1,
};

export const AuctionDetailsModalStepper = (): ReactElement => {
  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);

  const auctionId = selectedAuctionId?.toString();

  const { data: auction } = useBuyerAuctionQuery(auctionId);

  const stepStatus = auction?.status ?? 'noStatus';
  const step = {
    noStatus: -1,
    [AuctionStatusEnum.Canceled]: 3,
    [AuctionStatusEnum.ChooseWinner]: 0,
    [AuctionStatusEnum.InAuction]: 0,
    [AuctionStatusEnum.NotWin]: -1,
    [AuctionStatusEnum.OpsApproval]: 0,
    [AuctionStatusEnum.PreApproval]: 0,
    [AuctionStatusEnum.Purchased]: 3,
    [AuctionStatusEnum.TermsAccepted]: 2,
    [AuctionStatusEnum.TermsPending]: 1,
    [AuctionStatusEnum.TermsReviewed]: 1,
  }[stepStatus];

  const noWinnerString = 'No Winner Chosen';
  const finalStepString = stepStatus === AuctionStatusEnum.NotWin ? noWinnerString : 'Complete';
  const steps = [
    {
      id: 'issuerBids',
      stepLabel: 'Issuer Bids',
    },
    {
      id: 'termsheet',
      stepLabel: 'Termsheet',
    },
    {
      id: 'booking',
      stepLabel: 'Booking',
    },
    {
      id: 'complete',
      stepLabel: finalStepString,
    },
  ];

  const stepElements = steps.map(({ id, stepLabel }, index) => {
    const isNoWinner = stepLabel === noWinnerString;
    const icon = isNoWinner ? (
      <Box sx={iconContainerSx}>
        <Iconography color="common.white" iconName="check" />
      </Box>
    ) : undefined;

    return (
      <Step key={id} index={index}>
        <StepLabel icon={icon}>{stepLabel}</StepLabel>
      </Step>
    );
  });

  return (
    <Stepper activeStep={step} sx={stepperSx} connector={<StepConnector sx={connectorSx} />}>
      {stepElements}
    </Stepper>
  );
};
