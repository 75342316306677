import { ReactElement } from 'react';

import { UnderlyingGlyphs } from '@halo-common/components';
import { useIdFromQueryParams, useNoteSpecificDetails } from '@halo-common/hooks';
import { ProductDetailPanelLayout, ProductDetailPanelPrincipalSection } from '@halo-common/layouts';
import { PreTradeNoteDetailModalHeaderFeatures } from '@halo-common/modals';
import { useNoteQuery } from '@halo-data-sources/queries';
import { AuctionNotePayoffDetails } from '@halo-modules/app';

export const AuctionNoteDetailsPanel = (): ReactElement => {
  const { id: noteId } = useIdFromQueryParams('noteId', { useErrorBoundary: true });

  const { data: note, isPending: loading } = useNoteQuery(noteId, { throwOnError: true });
  const { protectionDetails, term } = useNoteSpecificDetails(note);

  const localization = {
    protection: protectionDetails?.[0]?.dynamicContent,
    term: term.dynamicContent,
    gearing: protectionDetails?.[1]?.dynamicContent,
  };

  const principalProtection = protectionDetails?.[0];

  const slots = {
    highlight: <PreTradeNoteDetailModalHeaderFeatures product={note} loading={loading} />,
    underlyings: (
      <UnderlyingGlyphs centered underlyings={note?.tradables} weights={note?.tradableWeights} loading={loading} />
    ),
  };

  return (
    <ProductDetailPanelLayout slots={slots} loading={loading}>
      <ProductDetailPanelPrincipalSection
        term={term?.text}
        protection={principalProtection?.text}
        protectionType={note?.protectionType}
        currency={note?.currencyCode}
        loading={loading}
        Localization={localization}
      />
      <AuctionNotePayoffDetails product={note} loading={loading} />
    </ProductDetailPanelLayout>
  );
};
