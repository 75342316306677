import { portfolioAccountManagerAtom } from '@halo-atoms/portfolio';
import { AccountModel } from '@halo-common/models';
import { postAccount } from '@halo-data-sources/clients';
import { HttpError } from '@halo-data-sources/errors';
import { ApiAccountMapper } from '@halo-data-sources/mappers';
import { AccountQueryKeyFactory } from '@halo-data-sources/queries';
import { AccountDataSwitchKeyFactory } from '@halo-data-sources/switches';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

export type AddAccountToExistingHouseholdPayload = {
  ownerId?: number;
  accountName: string;
  accountNumber: string;
  custodianId: number;
};

type onSuccessCallback = () => void;

const addAccountToExistingHouseholdFn = async (payload?: AddAccountToExistingHouseholdPayload) => {
  if (!payload) return null;

  const result = await postAccount(payload);

  const newAccount = result?.accounts?.[0];

  return newAccount ? ApiAccountMapper.toAccountModel(newAccount) : null;
};

export const useAddAccountToExistingHouseholdMutation = (
  onSuccess?: onSuccessCallback,
): UseMutationResult<AccountModel | null, HttpError, AddAccountToExistingHouseholdPayload | undefined> => {
  const queryClient = useQueryClient();

  const { query } = useAtomValue(portfolioAccountManagerAtom);

  return useMutation<AccountModel | null, HttpError, AddAccountToExistingHouseholdPayload | undefined>({
    mutationFn: addAccountToExistingHouseholdFn,
    onSuccess: async (data) => {
      if (!data) return undefined;

      const key = AccountDataSwitchKeyFactory.infinite();
      await queryClient.refetchQueries({ queryKey: key });

      const pageAccountKey = AccountQueryKeyFactory.paged(query);
      await queryClient.refetchQueries({ queryKey: pageAccountKey });

      onSuccess?.();
    },
  });
};
