import { ReactElement } from 'react';

import { UnderlyingGlyphAnnuityTooltip } from '@halo-common/components';
import { UnderlyingFormatTypeEnum } from '@halo-common/enums';

export type UnderlyingGlyphTooltipProps = {
  ticker?: string | null;
  format?: string | null;
  description?: string | null;
  units?: number | null;
  value?: number | null;
  weight?: number;
};

export const UnderlyingGlyphTooltip = ({ format, ...props }: UnderlyingGlyphTooltipProps): ReactElement => {
  const isAnnuity = format === UnderlyingFormatTypeEnum.annuity;
  return isAnnuity ? <UnderlyingGlyphAnnuityTooltip {...props} /> : <></>;
};
