import { ReactElement, useState } from 'react';

import { AddAccountModal } from '@halo-common/modals';
import { AccountModel } from '@halo-common/models';
import { AuctionDetailsAllocationFields, auctionDetailsAllocationSchema } from '@halo-common/schemas';
import { Stack } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { AddAllocationsForm } from './AddAllocationsForm';
import { SubmittedAllocationsTable } from './SubmittedAllocationsTable';

const containerSx = { px: 16 };

export const DEFAULT_ALLOCATION_ENTRY = {
  accountOption: null,
  quantity: 10000,
};

const ALLOCATIONS_DEFAULTS: AuctionDetailsAllocationFields = {
  allocations: [DEFAULT_ALLOCATION_ENTRY],
};

export const AuctionDetailsAllocationsTab = (): ReactElement => {
  const formMethods = useForm<AuctionDetailsAllocationFields>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    resolver: yupResolver<AuctionDetailsAllocationFields>(auctionDetailsAllocationSchema),
    defaultValues: ALLOCATIONS_DEFAULTS,
  });

  const [allocationCreateAccountEntry, setAllocationCreateAccountEntry] = useState<number | undefined>();

  const { setValue } = formMethods;

  const open = typeof allocationCreateAccountEntry === 'number';

  const handleCreateAccountToggle = (index?: number) => void setAllocationCreateAccountEntry(index);
  const handleCreateAccount = (account?: AccountModel) => {
    const updateValue = open && account;
    if (updateValue) setValue(`allocations.${allocationCreateAccountEntry}.accountOption`, { account });
    handleCreateAccountToggle();
  };

  return (
    <FormProvider {...formMethods}>
      <Stack direction="column" spacing={1} sx={containerSx}>
        <AddAllocationsForm onCreateAccount={handleCreateAccountToggle} />
        <SubmittedAllocationsTable />
        <AddAccountModal
          onSuccess={handleCreateAccount}
          onClose={handleCreateAccountToggle}
          open={open}
          variant="simple"
        />
      </Stack>
    </FormProvider>
  );
};
