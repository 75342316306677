export enum ProductTypeEnum {
  annuity = 'annuity',
  auction = 'auction',
  note = 'note',
}

export enum ProductActionTypeEnum {
  watch = 'watch',
  delete = 'delete',
  none = 'none',
}
