import { EducationHubUser, GetEducationHubDetailsRequestModel, PaginationModel } from '@halo-common/models';
import { getEducationHubDetails } from '@halo-data-sources/clients';
import { ApiEducationHubMapper, EducationHubMapper, PaginationMapper } from '@halo-data-sources/mappers';
import { EducationHubQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

export type EducationHubUsersQueryResult = {
  users: Array<EducationHubUser>;
  pagination: PaginationModel;
};

const getEducationHubUsersQueryFn = async (filters: GetEducationHubDetailsRequestModel) => {
  const payload = EducationHubMapper.toEducationHubRequest(filters);
  const response = await getEducationHubDetails(payload);
  const users = response?.education.map((user) => ApiEducationHubMapper.toEducationHubUser(user)) ?? [];
  const pagination = PaginationMapper.toPaginationModel(response.pagination);

  return { users, pagination };
};

export const useEducationHubUsersQuery = (
  filters: GetEducationHubDetailsRequestModel,
): UseQueryResult<EducationHubUsersQueryResult, Error> =>
  useQuery<EducationHubUsersQueryResult, Error>({
    queryKey: EducationHubQueryKeyFactory.admin(filters),
    queryFn: () => getEducationHubUsersQueryFn(filters),
    placeholderData: keepPreviousData,
  });
