import { ReactElement, useMemo } from 'react';

import { WidgetEnginesEnum } from '@halo-common/enums';
import { useDesktopView } from '@halo-common/hooks';
import type { DashboardWidgetModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { EducationModuleGroup, EducationModuleGroupProps, useCourseManager, Widget } from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';

const engine = WidgetEnginesEnum.EDUCATION;

const title = translations.dashboard.educationCourses.educationCourses;
const description = translations.dashboard.educationCourses.displaysIfYouHaveAnyIncompleteCourses;

const sx = {
  '& > div': {
    display: 'flex',
    alignItems: 'stretch',
  },
};

export const EducationCourses = (): ReactElement | null => {
  const isDesktop = useDesktopView();

  const { courseGroups, courseCompletion, onStart, onContinue, onSelection, isPending } = useCourseManager();

  const visibleGroups = useMemo(
    () =>
      [...courseGroups]
        .sort((a, b) => {
          const getPriority = (percentageComplete: number): number => {
            if (percentageComplete >= 100) return 2;
            if (percentageComplete > 0) return 0;
            return 1;
          };

          const aPriority = getPriority(a.percentageComplete);
          const bPriority = getPriority(b.percentageComplete);

          if (aPriority !== bPriority) return aPriority - bPriority;

          return a.position - b.position;
        })
        .slice(0, 3),
    [courseGroups],
  );

  if (courseCompletion >= 100) return null;
  if (visibleGroups.length === 0 && !isPending) return null;

  const layout = isDesktop ? 'row' : 'column';
  const sm = isDesktop ? [4, 4, 4] : undefined;

  const body = isPending
    ? new Array(3).fill(null).map((_, index) => <Skeleton variant="rounded" width="100%" height={200} key={index} />)
    : visibleGroups.map((group) => {
        const handleStart: EducationModuleGroupProps['onStart'] = (reason) =>
          reason === 'continue' ? onContinue(group) : onStart(group);

        const handleSelection: EducationModuleGroupProps['onSelection'] = (module, step) =>
          onSelection(group, module, step);

        return (
          <EducationModuleGroup
            key={group.id}
            title={group.title}
            subtitle={group.description}
            progress={group.percentageComplete}
            modules={group.modules}
            onStart={handleStart}
            onSelection={handleSelection}
            variant="compact"
            actionButtonClassName="wm-dashboard-edu-course"
            stepClassName="wm-dashboard-edu-topic"
          />
        );
      });

  return (
    <Widget
      id={engine}
      title={title}
      className="wm-dashboard-edu"
      navigateButtonHref="/education"
      navigateButtonLabel={translations.dashboard.educationCourses.courses}
      navigateButtonClassName="wm-dashboard-edu-viewall"
    >
      <Stack direction={layout} sm={sm} spacing={3} sx={sx}>
        {body}
      </Stack>
    </Widget>
  );
};

const widget: DashboardWidgetModel = {
  engine,
  title,
  description,
  component: EducationCourses,
};

export default widget;
