import { ReactElement } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { CollapsibleFilters, GroupedCheckbox } from '@halo-common/components';
import { DynamicTypeFiltersSideBarTitlesDict } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { useAtom } from 'jotai';

export type SettlementTypeFiltersProps = {
  tag: string;
};

export const SettlementTypeFilters = ({ tag }: SettlementTypeFiltersProps): ReactElement => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);

  const handleCheckboxChange = (value: string) => {
    setSideBarFilters({ tag, checkbox: value });
  };

  const settlementTypeCheckboxes = [
    {
      isChecked: sideBarFilters.checkboxes.cashSettlement,
      value: 'cashSettlement',
      name: translations.dynamicFilters.common.cash,
    },
    {
      isChecked: sideBarFilters.checkboxes.physicalSettlement,
      value: 'physicalSettlement',
      name: translations.dynamicFilters.common.physical,
    },
  ];

  return (
    <CollapsibleFilters title={DynamicTypeFiltersSideBarTitlesDict.settlementType}>
      <GroupedCheckbox onChange={handleCheckboxChange} values={settlementTypeCheckboxes} />
    </CollapsibleFilters>
  );
};
