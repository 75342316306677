import { CalendarQueryModel } from '@halo-atoms/calendars';
import { downloadFile } from '@halo-common/utils';
import { getCalendarOfferings, getDocgenToken, postRequestBuildDoc } from '@halo-data-sources/clients';
import { CalendarsMapper } from '@halo-data-sources/mappers';
import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';

export type GenerateFixedIncomePDFPayload = {
  whiteLabelId?: number;
  totalResults: number;
  filters: CalendarQueryModel;
};

const generateFixedIncomePDFMutationFn = async (payload: GenerateFixedIncomePDFPayload) => {
  if (!payload.whiteLabelId) return undefined;

  const calendarsRequest = {
    ...payload.filters,
    pageLength: payload.totalResults,
    page: 1,
  };

  const mappedRequest = CalendarsMapper.toFixedIncomeFilterPayload(calendarsRequest);
  const calendarsResponse = await getCalendarOfferings(mappedRequest);

  const calendarIds = calendarsResponse.calendars.map((calendar) => calendar.id);

  const buildQueryString = 'endpoint=documents/generate/first-successful/calendar_export&ergonomic_id=calendar_export';

  const buildToken = await getDocgenToken(buildQueryString);
  const response = await postRequestBuildDoc(buildToken.url, {
    white_label_id: payload.whiteLabelId,
    calendar_note_ids: [...calendarIds],
  });

  const downloadQueryString = 'endpoint=history/download/&ergonomic_id=calendar_export';
  const downloadToken = await getDocgenToken(downloadQueryString);

  const fileName = response.file_name;
  const filePath = response.file_path;
  const downloadFilePath = `${downloadToken.url}&file_key=${filePath}`;

  downloadFile({ fileName, downloadFilePath });
};

export const useGenerateFixedIncomePDFMutation = (
  options?: UseMutationOptions<void, Error, GenerateFixedIncomePDFPayload>,
): UseMutationResult<void, Error, GenerateFixedIncomePDFPayload> =>
  useMutation<void, Error, GenerateFixedIncomePDFPayload>({
    mutationFn: generateFixedIncomePDFMutationFn,
    ...options,
  });
