import { getCalendarName } from '@halo-data-sources/clients';
import { CalendarsQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const calendarNameQueryFn = async (id?: number | null) => {
  if (typeof id !== 'number') return null;

  const response = await getCalendarName([id]);

  return response.calendar_names[0]?.name ?? null;
};

export const useCalendarNameQuery = (id?: number | null): UseQueryResult<string | null, Error> =>
  useQuery<string | null, Error>({
    queryKey: CalendarsQueryKeyFactory.calendarName(id),
    queryFn: () => calendarNameQueryFn(id),
    enabled: typeof id === 'number',
  });
