import type { OrderBookQuery } from '@halo-atoms/orderbook';
import { OrderBookAuctionQueryFields, OrderBookCalendarsQueryFields } from '@halo-common/models';
import { atom } from 'jotai';

export const DEFAULT_AUCTIONS_SEARCH_FIELDS = {
  page: 1,
  pageSize: 5,
  sortBy: [
    {
      field: 'endDate',
      sort: 'asc',
    },
  ],
  filters: {
    timeFrame: 'thirtyDays',
    is_open: true,
  },
} as OrderBookAuctionQueryFields;

export const myOpenAuctionsSearchFieldsAtom = atom<OrderBookAuctionQueryFields>(DEFAULT_AUCTIONS_SEARCH_FIELDS);

export const DEFAULT_CALENDARS_SEARCH_FIELDS = {
  page: 1,
  pageSize: 5,
  sortBy: [
    {
      field: 'closes',
      sort: 'asc',
    },
  ],
  filters: {
    timeFrame: 'thirtyDays',
    is_open: true,
  },
} as OrderBookCalendarsQueryFields;

export const myOpenCalendarsSearchFieldsAtom = atom<OrderBookCalendarsQueryFields>(DEFAULT_CALENDARS_SEARCH_FIELDS);

export const DEFAULT_JANNEY_SEARCH_FIELDS = {
  page: 1,
  pageSize: 5,
  sortBy: [],
  filters: {
    timeFrame: 'thirtyDays',
    is_open_janney: true,
  },
} as OrderBookQuery;

export const myOpenOrdersJanneySearchFieldsAtom = atom<OrderBookQuery>(DEFAULT_JANNEY_SEARCH_FIELDS);

export const DEFAULT_PERSHING_SEARCH_FIELDS = {
  page: 1,
  pageSize: 5,
  sortBy: [],
  filters: {
    timeFrame: 'thirtyDays',
    is_open_pershing: true,
  },
} as OrderBookQuery;

export const myOpenOrdersPershingSearchFieldsAtom = atom<OrderBookQuery>(DEFAULT_PERSHING_SEARCH_FIELDS);
