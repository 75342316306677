import { ReactElement, useMemo } from 'react';

import { auctionStepAtoms } from '@halo-atoms/auctions';
import { useIdFromQueryParams } from '@halo-common/hooks';
import { useAtomValue } from 'jotai';

import { AuctionDatePage } from '../AuctionDatePage';
import { AuctionIssuersPage } from '../AuctionIssuersPage';
import { AuctionNotionalPage } from '../AuctionNotionalPage';

export const AuctionSubpage = (): ReactElement => {
  const step = useAtomValue(auctionStepAtoms.currentPageAtom);

  const { id: noteId } = useIdFromQueryParams('noteId', { useErrorBoundary: true });

  const pages = useMemo(
    () => [
      <AuctionNotionalPage key={0} noteId={noteId} />,
      <AuctionDatePage key={1} />,
      <AuctionIssuersPage key={2} noteId={noteId} />,
    ],
    [noteId],
  );

  return pages[step];
};
