import { ReactElement, useEffect } from 'react';

import { EducationLanding, ModuleExplorer, ModuleFinishedPage, QuizManager, useCourseManager } from '@halo-modules/app';
import { ElementSelector } from '@halodomination/halo-fe-common';

export type CoursePageState = { step: number; loaded: boolean };

export enum CourseModeEnum {
  Landing,
  Explorer,
  QuizManager,
  Finish,
}

export const EducationCoursesPage = (): ReactElement => {
  const { progress } = useCourseManager();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [progress.step?.id]);

  return (
    <ElementSelector selected={progress.mode}>
      <EducationLanding />
      <ModuleExplorer />
      <QuizManager />
      <ModuleFinishedPage />
    </ElementSelector>
  );
};
