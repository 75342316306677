import { ReactElement, ReactNode } from 'react';

import { dynamicFiltersHeaderManagerAtom } from '@halo-atoms/common';
import { DynamicFiltersSortByEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { LocalizedInputLabel, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useAtom } from 'jotai';

const controlSx = { minWidth: 180, maxWidth: 360 };

const itemSx = { minWidth: 180, maxWidth: 360 };

const labelSx = {
  flex: '1 1 auto',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const sortByOptions = [
  {
    value: DynamicFiltersSortByEnum.expirationDateAsc,
    label: translations.dynamicFilters.common.sortByCloseTimeSoonest,
  },
  {
    value: DynamicFiltersSortByEnum.expirationDateDesc,
    label: translations.dynamicFilters.common.sortByCloseTimeLatest,
  },
  {
    value: DynamicFiltersSortByEnum.termDesc,
    label: translations.dynamicFilters.common.sortByTermLongest,
  },
  {
    value: DynamicFiltersSortByEnum.termAsc,
    label: translations.dynamicFilters.common.sortByTermShortest,
  },
  {
    value: DynamicFiltersSortByEnum.payoffDesc,
    label: translations.dynamicFilters.common.sortByPayoffHighest,
  },
  {
    value: DynamicFiltersSortByEnum.protectionDesc,
    label: translations.dynamicFilters.common.sortByProtectionHighest,
  },
  {
    value: DynamicFiltersSortByEnum.protectionAsc,
    label: translations.dynamicFilters.common.sortByProtectionLowest,
  },
];

export type DynamicSearchFiltersSortByProps = {
  tag: string;
  label?: ReactNode;
};

export const DynamicSearchFiltersSortBy = ({
  tag,
  label = translations.common.sortBy,
}: DynamicSearchFiltersSortByProps): ReactElement => {
  const [getHeaderFilters, setHeaderFilters] = useAtom(dynamicFiltersHeaderManagerAtom);

  const { sortByType } = getHeaderFilters(tag);

  const handleSortByChange = (e: SelectChangeEvent<DynamicFiltersSortByEnum | null>) => {
    const sortByType = e.target.value as DynamicFiltersSortByEnum;
    setHeaderFilters({ tag, sortByType });
  };

  const value = sortByType || null;
  const labelId = label ? 'dynamicSearchFilterSortByLabel' : undefined;

  const labelContent = label ? (
    <LocalizedInputLabel id="dynamicSearchFilterSortByLabel">{label}</LocalizedInputLabel>
  ) : null;

  return (
    <FormControl variant="standard" sx={controlSx}>
      {labelContent}
      <Select
        id="dynamicSearchFilterSortBy"
        labelId={labelId}
        value={value}
        fullWidth
        size="large"
        onChange={handleSortByChange}
      >
        {sortByOptions.map((option) => (
          <MenuItem value={option.value} sx={itemSx} key={option.value}>
            <Box sx={labelSx}>
              <LocalizedTypography>{option.label}</LocalizedTypography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
