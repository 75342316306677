import { ChangeEvent, ReactElement, useState } from 'react';

import {
  HistoricalPerformanceFormFields,
  historicalPerformanceFormFieldsAtom,
} from '@halo-atoms/historicalPerformance';
import { DateRangePicker, UnderlyingAutocomplete } from '@halo-common/components';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { UnderlyingModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useHistoricalPerformanceQuery } from '@halo-data-sources/queries';
import { Box, Button, InputAdornment, Stack, TextField } from '@mui/material';
import { useAtom } from 'jotai';
import { DateTime, WeekdayNumbers } from 'luxon';
import { useController, useFormContext } from 'react-hook-form';

const protectionFieldSx = {
  pr: 0,
};

const protectionInputAdornmentSx = {
  maxHeight: null,
  height: null,
};

const protectionEndAdornmentSx = {
  width: '44px',
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'primary.background',
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
};

export const HistoricalPerformanceSearchForm = (): ReactElement => {
  const [dirty, setDirty] = useState(false);

  const [form, setForm] = useAtom(historicalPerformanceFormFieldsAtom);

  const {
    register,
    handleSubmit: onSubmit,
    setValue,
    formState,
    control,
  } = useFormContext<HistoricalPerformanceFormFields>();

  const { field: dateFieldProps, ...dateField } = useController({ name: 'date', control, rules: { required: true } });
  const { ref: protectionLevelRef, ...protectionLevelInputProps } = register('protectionLevel', { required: true });

  useHistoricalPerformanceQuery(form);

  const dateTimeNow = DateTime.now();
  const currentWeekDay = Math.min(dateTimeNow.weekday, 4) as WeekdayNumbers;
  const today = dateTimeNow.set({ weekday: currentWeekDay });

  const yearAgoUnadjusted = today.minus({ years: 1, days: 1 });
  const yearAgoWeekDay = Math.min(yearAgoUnadjusted.weekday, 4) as WeekdayNumbers;
  const yearAgo = yearAgoUnadjusted.set({ weekday: yearAgoWeekDay });

  const { errors } = formState;

  const startDate = dateFieldProps.value?.[0] ?? yearAgo;
  const endDate = dateFieldProps.value?.[1] ?? today;
  const underlyingsError = errors.underlyings?.message;
  const dateRangeError = dateField.fieldState.error ? translations.messages.requiredField : undefined;
  const protectionLevelError = errors.protectionLevel?.message;

  const protectionLevelChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setValue('protectionLevel', parseFloat(ev.target.value));
  };

  const handleChange = (selection: Array<UnderlyingModel>) => {
    if (!dirty) setDirty(true);

    const underlyingNames = selection.map(({ name }) => name);

    setValue('underlyings', underlyingNames);
  };

  const isInvalidDate = (date: DateTime, position: 'start' | 'end') => {
    const weekend = date.weekday === 6 || date.weekday === 7;
    const invalidStartDate = position === 'start' && endDate.minus({ years: 1, days: 1 }) <= date && date <= endDate;
    const invalidEndDate = position === 'end' && startDate <= date && date <= startDate.plus({ years: 1, days: 1 });

    if (weekend || invalidStartDate || invalidEndDate) return true;
    return false;
  };

  const handleSubmit = void onSubmit(setForm);

  const percentageGrayPercentageSquare = (
    <InputAdornment sx={protectionInputAdornmentSx} position="end" variant="outlined">
      <Box sx={protectionEndAdornmentSx}>%</Box>
    </InputAdornment>
  );

  const protectionLevelFieldInputProps = {
    sx: protectionFieldSx,
    endAdornment: percentageGrayPercentageSquare,
  };

  return (
    <Box component="form">
      <Stack direction="row">
        <UnderlyingAutocomplete
          label="Underlying"
          name="Underlying"
          size="large"
          error={Boolean(underlyingsError)}
          helperText={underlyingsError}
          onChange={handleChange}
        />
        <DateRangePicker
          disableFuture
          disableHighlightToday
          shouldDisableDate={isInvalidDate}
          startDate={startDate}
          endDate={endDate}
          helperText={dateRangeError}
          format={MONTH_DAY_YEAR_DATE_FORMAT}
          onChange={dateFieldProps.onChange}
        />
        <TextField
          sx={protectionFieldSx}
          label="Protection Level"
          size="large"
          type="number"
          fullWidth
          inputRef={protectionLevelRef}
          {...protectionLevelInputProps}
          error={Boolean(protectionLevelError)}
          helperText={protectionLevelError}
          onChange={protectionLevelChange}
          slotProps={{ input: protectionLevelFieldInputProps }}
        />
        <Button
          component="a"
          size="large"
          type="button"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          fullWidth
        >
          Submit
        </Button>
      </Stack>
    </Box>
  );
};
