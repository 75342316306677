import { ReactElement, ReactNode } from 'react';

import { translations } from '@halo-common/translations';
import { LocalizedTypography, LocalizedTypographyProps, useCombinedStyling } from '@halodomination/halo-fe-common';
import { Paper, SxProps, Stack, Theme, Box } from '@mui/material';

const paperSx = { display: 'flex', minHeight: 450, px: 2, py: 4 };

const containerSx: SxProps<Theme> = (theme) => ({
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  gap: 5,
  m: 'auto',
  width: '100%',

  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
    justifyItems: 'center',
    flexDirection: 'column',
    gap: 1,
    maxWidth: 320,
  },

  '& > div > h2 + p + *': {
    marginTop: 3,
  },
});

const iconSx = {
  flex: '0 0 auto',
  '& > svg': {
    width: 80,
    height: 'auto',
  },
};

type Localization = { title?: LocalizedTypographyProps; description?: LocalizedTypographyProps };

export type EmptyResultsCardProps = {
  description: ReactNode;
  title?: string;
  brandIcon?: ReactElement;
  action?: ReactNode;
  showBorder?: boolean;
  sx?: SxProps<Theme>;
  Localization?: Localization;
  testId?: string;
};

export const EmptyResultsCard = ({
  description,
  title = translations.messages.noResults,
  brandIcon,
  action,
  showBorder = true,
  sx,
  Localization,
  testId,
}: EmptyResultsCardProps): ReactElement => {
  const borderSx = !showBorder ? { border: 0 } : undefined;
  const combinedPaperSx = useCombinedStyling(paperSx, borderSx, sx);

  return (
    <Paper variant="outlined" sx={combinedPaperSx} className="empty-results-card" data-testid={testId}>
      <Stack sx={containerSx}>
        <Box sx={iconSx}>{brandIcon}</Box>
        <Stack direction="column" spacing={1} alignItems="flex-start" flex="1 1 auto" maxWidth={480}>
          <LocalizedTypography variant="h2" color="textPrimary" {...Localization?.title}>
            {title}
          </LocalizedTypography>
          <LocalizedTypography variant="body2" color="textSecondary" {...Localization?.description}>
            {description}
          </LocalizedTypography>
          {action}
        </Stack>
      </Stack>
    </Paper>
  );
};
