import { ReactElement } from 'react';

import { portfolioAccountManagerAtom, portfolioFiltersAtom } from '@halo-atoms/portfolio';
import { ACCOUNT_NO_HOUSEHOLD_OPTION, AccountTypeAhead, AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { IconographyProps } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';

export const ACCOUNT_TYPE_AHEAD_ALL_OPTION = translations.portfolio.common.accountTypeAheadAllOption;
export const ACCOUNT_TYPE_AHEAD_SYNCED_OPTION = translations.portfolio.common.accountTypeAheadSyncedOption;
export const ACCOUNT_TYPE_AHEAD_UNSYNCED_OPTION = translations.portfolio.common.accountTypeAheadUnsyncedOption;

export type PortfolioAccountTypeAheadProps = {
  loading?: boolean;
};

export const PortfolioAccountTypeAhead = ({ loading }: PortfolioAccountTypeAheadProps): ReactElement => {
  const { data: user } = useUserInfoQuery();
  const filterAccount = user?.whiteLabel.filterAccountDropdown;
  const hasPositions = filterAccount ? filterAccount : undefined;

  const [filters, setFilters] = useAtom(portfolioFiltersAtom);
  const setAccountsData = useSetAtom(portfolioAccountManagerAtom);

  const { accountOption } = filters;

  const actions = [
    {
      label: ACCOUNT_TYPE_AHEAD_ALL_OPTION,
      icon: 'universal-access' as IconographyProps['iconName'],
      clearedOnSelection: false,
    },
    {
      label: ACCOUNT_TYPE_AHEAD_SYNCED_OPTION,
      clearedOnSelection: false,
    },
    {
      label: ACCOUNT_TYPE_AHEAD_UNSYNCED_OPTION,
      clearedOnSelection: false,
    },
  ];

  // TODO: Remove when BE is ready to handle no household query
  const disabledActions = [ACCOUNT_NO_HOUSEHOLD_OPTION];

  const handleSelect = (option: AccountTypeAheadOption | null) => {
    setFilters({ accountOption: option });
    setAccountsData({ pagination: { page: 1 } });
  };

  return loading ? (
    <Skeleton width="100%" height={48} variant="rounded" />
  ) : (
    <AccountTypeAhead
      sx={{ width: '100%' }}
      disableClearable
      actions={actions}
      value={accountOption}
      label={translations.portfolio.common.accountTypeAheadLabel}
      onSelect={handleSelect}
      rules={{
        disabledOptions: disabledActions,
        enabledPositionTypeahead: true,
        hasPositions,
      }}
    />
  );
};
