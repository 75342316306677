import { getCsrfToken } from '@halo-data-sources/clients';
import { ClientConfigurationModel } from '@halo-data-sources/models';

export const institutionalApiUrl = process.env.NEXT_PUBLIC_INSTITUTIONAL_API_URL ?? '';

export const getClientConfiguration = async (path = '', options?: RequestInit): Promise<ClientConfigurationModel> => {
  const csrfToken = await getCsrfToken();

  const config = {
    requestDomain: institutionalApiUrl,
    appDescription: process.env.NEXT_PUBLIC_APP_DESCRIPTION ?? '',
    appTitle: process.env.NEXT_PUBLIC_APP_TITLE ?? '',
    company: process.env.NEXT_PUBLIC_COMPANY ?? '',
    staticAssetUrl: process.env.NEXT_PUBLIC_STATIC_ASSET_URL ?? '',
    host: process.env.NEXT_PUBLIC_INSTITUTIONAL_API_HOST ?? location.host,
  };

  const requestInit = {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    ...options,
  } as RequestInit;

  return {
    ...config,
    basePath: `${config.requestDomain}${path}`,
    csrfToken,
    requestInit,
  };
};
