import { ChangeEvent, ReactElement } from 'react';

import { executionHubEditReofferModalAtom } from '@halo-atoms/executionHub';
import { translations } from '@halo-common/translations';
import { LocalizedTextField, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useController, useFormContext } from 'react-hook-form';

export const V2ExecutionHubEditReofferModalForm = (): ReactElement => {
  const modalState = useAtomValue(executionHubEditReofferModalAtom);

  const { control, register } = useFormContext();

  const { ref: priceRef, ...priceProps } = register('price');

  const isBrokerageMethods = useController({
    control,
    name: 'isBrokerage',
    defaultValue: false,
  });

  const { onChange: onBrokerageChange, value: isBrokerageValue } = isBrokerageMethods.field;

  const handleBrokerageChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onBrokerageChange(checked);
  };

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" justify="space-between">
        <Stack direction="column">
          <LocalizedTypography>
            {translations.executionHub.reofferModal.originalReofferTextfieldLabel}
          </LocalizedTypography>
          <Typography>{modalState?.reoffer ?? '-'}</Typography>
        </Stack>
        <LocalizedTextField
          label={translations.executionHub.reofferModal.reofferTextfieldLabel}
          inputRef={priceRef}
          {...priceProps}
        />
      </Stack>
      <FormControl component="fieldset">
        <FormLabel component="legend">{translations.executionHub.reofferModal.accountTypeLabel}</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox name="advisory" checked disabled />}
            label={<LocalizedTypography>{translations.common.advisory}</LocalizedTypography>}
          />
          <FormControlLabel
            control={<Checkbox name="brokerage" checked={isBrokerageValue} onChange={handleBrokerageChange} />}
            label={<LocalizedTypography>{translations.common.brokerage}</LocalizedTypography>}
          />
        </FormGroup>
      </FormControl>
    </Stack>
  );
};
