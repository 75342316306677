import { portfolioPositionPopoverAtom } from '@halo-atoms/portfolio';
import { PortfolioPositionReferenceModel, PortfolioTransactionModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { createPositions, createQueuedPositions } from '@halo-data-sources/clients';
import { ApiTransactionMapper, PositionAllocationMapper } from '@halo-data-sources/mappers';
import { PDMKeyFactory } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

export type CreatePositionsRequest = {
  positions?: Array<PortfolioPositionReferenceModel>;
  queuedPositions?: Array<PortfolioPositionReferenceModel>;
};

export type CreatePositionsPayload = () => void;

export type CreatePositionsResponse = Array<PortfolioTransactionModel>;

const createPositionsMutationFn = async (request: CreatePositionsRequest) => {
  let positionsResponse: Array<PortfolioTransactionModel> = [];
  let queuedPositionsResponse: Array<PortfolioTransactionModel> = [];

  const positionMappedRequest = request?.positions?.map(PositionAllocationMapper.toApiModel);
  const filteredPositions = positionMappedRequest?.filter((position) => !!position);

  if (filteredPositions?.length) {
    const positionResult = await createPositions(filteredPositions);
    positionsResponse = positionResult.positions?.map(ApiTransactionMapper.toPortfolioTransactionModel);
  }

  const positionQueuedMappedRequest = request.queuedPositions?.map(PositionAllocationMapper.toQueuedApiModel);
  const filteredQueuedPositions = positionQueuedMappedRequest?.filter((position) => !!position);

  if (filteredQueuedPositions?.length) {
    const queuedPositionResult = await createQueuedPositions(filteredQueuedPositions);
    queuedPositionsResponse = queuedPositionResult.map(ApiTransactionMapper.toQueuedPortfolioTransactionModel);
  }

  return [...positionsResponse, ...queuedPositionsResponse];
};

export const useCreatePositionsMutation = (
  handleNext: CreatePositionsPayload,
): UseMutationResult<CreatePositionsResponse, Error, CreatePositionsRequest> => {
  const queryClient = useQueryClient();
  const { enqueueErrorEvent, enqueueSuccessEvent } = useSnackbar();
  const { termsheetId } = useAtomValue(portfolioPositionPopoverAtom);

  return useMutation<CreatePositionsResponse, Error, CreatePositionsRequest>({
    mutationFn: createPositionsMutationFn,
    onSuccess: (response) => {
      handleNext();
      if (termsheetId) {
        const queryKey = PDMKeyFactory.allocations({ termsheetId });
        void queryClient.invalidateQueries({ queryKey });
      }
      enqueueSuccessEvent({ message: translations.pdm.postTrade.allocationAddSuccess });

      return response;
    },
    onError: () => enqueueErrorEvent({ message: translations.messages.error }),
  });
};
