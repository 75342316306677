import { ReactElement } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { bulkOrderTicketReceiptAtom, orderTicketReceiptAtom, orderTicketStepAtom } from '@halo-atoms/orderTicket';
import { ActionButton } from '@halo-common/components';
import { OrderTicketFormModelFields } from '@halo-common/modals';
import { useWebSocketContext } from '@halo-common/providers';
import { BulkOrderTicketFormFields, SingleOrderTicketFormFields } from '@halo-common/schemas';
import { useOrderTicketSubmissionSwitch } from '@halo-data-sources/switches';
import { Stack } from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';

export type OrderTicketReviewFooterProps = {
  bulk?: boolean;
  onUpdateOrder?: () => void;
};

export const OrderTicketReviewFooter = ({
  bulk = false,
  onUpdateOrder,
}: OrderTicketReviewFooterProps): ReactElement => {
  const { events } = useWebSocketContext();

  const { handleSubmit: handleSingleOrder } = useFormContext<OrderTicketFormModelFields>();
  const { handleSubmit: handleBulkOrder } = useFormContext<BulkOrderTicketFormFields>();

  const calendar = useAtomValue(calendarSelectedAtom);
  const [orderTicketReceipt, setOrderTicketReceipt] = useAtom(orderTicketReceiptAtom);
  const handleBack = useSetAtom(orderTicketStepAtom.handlePrevAtom);
  const setBulkOrderTicketReceipt = useSetAtom(bulkOrderTicketReceiptAtom);

  const { mutate, isPending } = useOrderTicketSubmissionSwitch({
    onSuccess: (data) => {
      setOrderTicketReceipt(data);
      onUpdateOrder?.();
    },
  });

  const bulkPending = bulk && events.fix.queue.length > 0;
  const isLoading = isPending || bulkPending;

  const onSubmitSingleOrder = handleSingleOrder((data: OrderTicketFormModelFields) => {
    mutate({ calendar, order: orderTicketReceipt?.order, singleData: data as SingleOrderTicketFormFields });
  });

  const onSubmitBulkOrder = handleBulkOrder((data: OrderTicketFormModelFields) => {
    mutate({ calendar, bulkData: data as BulkOrderTicketFormFields });
  });

  const onSubmitOrder = bulk ? onSubmitBulkOrder : onSubmitSingleOrder;

  const onBack = () => {
    handleBack();
    setBulkOrderTicketReceipt();
  };

  return (
    <Stack direction="row" justify="space-between">
      <Button type="button" variant="outlined" color="primary" onClick={onBack} disabled={isLoading}>
        Edit Allocations
      </Button>
      <ActionButton type="button" variant="contained" color="primary" onClick={onSubmitOrder} loading={isLoading}>
        Acknowledge &amp; Submit Order
      </ActionButton>
    </Stack>
  );
};
