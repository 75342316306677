import { ReactElement, useState } from 'react';

import { Dropzone } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { LoginPageManagementFormFields } from '@halo-modules/admin';
import { LocalizedButton, LocalizedTextField, LocalizedTypography, Tabs } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

const textInputSx = {
  width: 395,
};

const imageInputSx = {
  justifySelf: 'center',
};

const MEDIA_CONTENT_TABS = [
  translations.contentManagement.loginPageManagement.image,
  translations.contentManagement.loginPageManagement.videoLink,
];

const ACCEPTED_MEDIA_CONTENT = {
  'image/*': ['.jpg', '.jpeg', '.png', '.svg'],
};

export const MediaContentTabbedInputs = (): ReactElement => {
  const formMethods = useFormContext<LoginPageManagementFormFields>();
  const { control, resetField, setValue } = formMethods;

  const imageField = useController({ control, name: 'image' });
  const videoField = useController({ control, name: 'videoLink' });

  const defaultTab = videoField.field.value ? 1 : 0;
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  const fileTypes = ACCEPTED_MEDIA_CONTENT['image/*'].join(', ');
  const dynamicContent = { fileTypes };

  const file = imageField.field.value;
  const fileHandler = (file: Array<File>) => imageField.field.onChange(file?.[0]);
  const fileError = imageField.fieldState.error;

  const onTabChange = (tab: number) => {
    if (tab === 1) {
      resetField('videoLink');
      resetField('image', { defaultValue: null });
    } else {
      resetField('image');
      resetField('videoLink', { defaultValue: '' });
    }
    setSelectedTab(tab);
  };

  const handleImgReset = () => {
    setValue('image', undefined);
  };

  return (
    <Tabs disableGutters tabs={MEDIA_CONTENT_TABS} variant="pills" value={selectedTab} onChange={onTabChange}>
      <Stack direction="column" spacing={2}>
        <LocalizedTypography color="text.secondary" variant="caption" dynamicContent={dynamicContent}>
          {translations.contentManagement.loginPageManagement.acceptedFileTypes}
        </LocalizedTypography>
        <Dropzone
          accept={ACCEPTED_MEDIA_CONTENT}
          onDrop={fileHandler}
          file={file}
          error={fileError?.message}
          sx={imageInputSx}
        />
        <LocalizedButton onClick={handleImgReset} variant="text">
          {translations.common.remove}
        </LocalizedButton>
      </Stack>
      <LocalizedTextField
        {...videoField.field}
        size="large"
        sx={textInputSx}
        label={translations.contentManagement.loginPageManagement.videoLink}
      />
    </Tabs>
  );
};
