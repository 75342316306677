import { COMBINED_DATE_TIME_FORMAT } from '@halo-common/constants';
import { downloadFile } from '@halo-common/utils';
import {
  getAdminCalendarsCsv,
  getCalendarPageCSV,
  postBatchAdminCalendars,
  postPendingAdminCalendars,
  putAdminCalendar,
} from '@halo-data-sources/clients';
import { ApiCalendarsMapper, CalendarsMapper } from '@halo-data-sources/mappers';
import { ApiPostAdminCalendarsOptionsModel } from '@halo-data-sources/models';
import { ExecutionHubStatusEnum } from '@halo-modules/admin';
import { createThunk } from '@halo-stores/createThunk';
import {
  DownloadPershingCalendarCsvThunkAction,
  FetchCalendarsForExecutionHubInfiniteScrollThunkAction,
  FetchCalendarsForExecutionHubInfiniteScrollThunkResult,
  FetchExecutionHubCalendarsThunkAction,
  FetchExecutionHubCalendarsThunkResult,
  FetchLoadingDockCalendarsThunkAction,
  FetchLoadingDockCalendarsThunkResult,
  UpdatePershingCalendarThunkAction,
  UpdatePershingCalendarThunkResult,
} from '@halo-stores/Pershing';
import { WebsocketActions } from '@halo-stores/Websocket';

export const fetchExecutionHubCalendars = createThunk<
  FetchExecutionHubCalendarsThunkResult,
  FetchExecutionHubCalendarsThunkAction
>('pershing/eh/calendars/get', async (action, { getState }) => {
  const { Pershing } = getState();
  const { calendars, totals } = Pershing;

  const { params: queryParams, updateTotalsFor, updateFilteredTotals = false, updateAllTotals = true } = action;

  const params = queryParams.map((query) => ({
    query: CalendarsMapper.toApiPostAdminCalendarsQueryParams(query),
    sorting: [
      { field: 'expiration_date', direction: 'asc' },
      { field: 'cusip', direction: 'asc' },
    ],
  }));

  const results = await postBatchAdminCalendars(params);

  const { calendarMap, calendarTotals } = ApiCalendarsMapper.toCalendarBatchResultMap(
    results,
    calendars,
    totals,
    queryParams,
  );

  const updatedTotals = updateAllTotals
    ? calendarTotals
    : updateTotalsFor
      ? { ...totals, [updateTotalsFor]: calendarTotals[updateTotalsFor] }
      : undefined;

  const updatedFilteredTotals = updateFilteredTotals ? calendarTotals : undefined;

  return { filteredTotals: updatedFilteredTotals, totals: updatedTotals, calendars: calendarMap };
});

export const fetchCalendarsForExecutionHubInfiniteScroll = createThunk<
  FetchCalendarsForExecutionHubInfiniteScrollThunkResult,
  FetchCalendarsForExecutionHubInfiniteScrollThunkAction
>('pershing/eh/calendar/scroll', async ({ status, params }, { getState }) => {
  const { Pershing } = getState();
  const { calendars: oldCalendars, totals } = Pershing;

  const queryParams = params.map((query) => ({
    query: CalendarsMapper.toApiPostAdminCalendarsQueryParams(query),
    sorting: [
      { field: 'expiration_date', direction: 'asc' },
      { field: 'cusip', direction: 'asc' },
    ],
  }));

  const results = await postBatchAdminCalendars(queryParams);

  const { calendarMap } = ApiCalendarsMapper.toCalendarBatchResultMap(results, oldCalendars, totals, params);

  const updatedCalendars = { calendars: { ...oldCalendars } };

  if (status === ExecutionHubStatusEnum.available) {
    updatedCalendars.calendars.available = [...oldCalendars.available, ...calendarMap.available];
    updatedCalendars.calendars.filled = [...oldCalendars.filled, ...calendarMap.filled];
    updatedCalendars.calendars.pending = [...oldCalendars.pending, ...calendarMap.pending];
  } else if (status === ExecutionHubStatusEnum.filled) {
    updatedCalendars.calendars.filled = [...oldCalendars.filled, ...calendarMap.filled];
  } else if (status === ExecutionHubStatusEnum.pending) {
    updatedCalendars.calendars.pending = [...oldCalendars.pending, ...calendarMap.pending];
  } else {
    updatedCalendars.calendars.archived = [...oldCalendars.archived, ...calendarMap.archived];
  }

  return updatedCalendars;
});

export const resolveExecutionHubBulkFill = createThunk<void, void>(
  'pershing/eh/bulk/fill/resolve',
  (_, { getState, dispatch }) => {
    const { Pershing } = getState();
    const { fixOrderQueue } = Pershing;

    if (!fixOrderQueue.length) dispatch(WebsocketActions.handleResolved());
  },
);

export const fetchLoadingDockCalendars = createThunk<
  FetchLoadingDockCalendarsThunkResult,
  FetchLoadingDockCalendarsThunkAction
>('pershing/ld/calendars/get', async (action, { getState }) => {
  const { Pershing } = getState();
  const { calendars, totals } = Pershing;

  const { available, pending, archived } = action;
  const { updateFilteredTotals = false } = action;

  const options: Array<ApiPostAdminCalendarsOptionsModel> = [];

  if (available) {
    options.push({
      query: CalendarsMapper.toApiPostAdminCalendarsQueryParams(available.query),
      sorting: CalendarsMapper.toApiCalendarSortingOptions(available.sorting),
    });
  }

  if (archived) {
    options.push({
      query: CalendarsMapper.toApiPostAdminCalendarsQueryParams(archived.query),
      sorting: CalendarsMapper.toApiCalendarSortingOptions(archived.sorting),
    });
  }

  const apiCalendars = await postBatchAdminCalendars(options);

  const params = [];
  if (available?.query) params.push(available.query);
  if (archived?.query) params.push(archived.query);

  const calendarBatchResultMap = ApiCalendarsMapper.toCalendarBatchResultMap(apiCalendars, calendars, totals, params);

  const { calendarMap, calendarTotals } = calendarBatchResultMap;

  const updatedCalendars = {
    archived: archived ? calendarMap.archived : undefined,
    available: available ? calendarMap.available : undefined,
  };

  const updatedTotals = {
    available: available ? calendarTotals.available : totals.available,
    archived: archived ? calendarTotals.archived : totals.archived,
  };

  const updatedFilteredTotals = updateFilteredTotals
    ? { available: available ? calendarTotals.available : 0, archived: archived ? calendarTotals.archived : 0 }
    : undefined;

  const thunkResult: FetchLoadingDockCalendarsThunkResult = {
    calendars: updatedCalendars,
    totals: updatedTotals,
    filteredTotals: updatedFilteredTotals,
  };

  if (pending) {
    const pendingParams = CalendarsMapper.toApiPostAdminCalendarsQueryParams(pending.query);
    const sortingParams = CalendarsMapper.toApiCalendarSortingOptions(pending.sorting);

    const apiPendingCalendars = await postPendingAdminCalendars({ query: pendingParams, sorting: sortingParams });

    const pendingCalendars = apiPendingCalendars?.pending.map(ApiCalendarsMapper.toCalendarPendingModel) ?? [];
    const pendingFilteredTotals = updateFilteredTotals ? apiPendingCalendars.total : undefined;

    const updatedPendingTotals = { ...thunkResult.totals, pending: apiPendingCalendars.total };
    const updatedPendingFilterTotals = updateFilteredTotals
      ? { ...thunkResult.filteredTotals, pending: pendingFilteredTotals }
      : undefined;

    thunkResult.calendars.pending = pendingCalendars;
    thunkResult.totals = updatedPendingTotals;
    thunkResult.filteredTotals = updatedPendingFilterTotals;
  }

  return thunkResult;
});

export const downloadPershingCalendarCsv = createThunk<void, DownloadPershingCalendarCsvThunkAction>(
  'pershing/csv/download',
  async ({ organization, route, params }) => {
    let result;

    if (route === 'compliance') {
      const calendarPageId = organization?.calendarPageId;
      const query = CalendarsMapper.toApiPostCalendarPageQueryParamsString(params);
      result = await getCalendarPageCSV(calendarPageId, route, query);
    } else {
      const query = CalendarsMapper.toApiPostAdminCalendarsQueryParamsString(params);
      result = await getAdminCalendarsCsv(route, query);
    }

    downloadFile({ fileName: result.filename, downloadFilePath: result.tmplink });
  },
);

export const updatePershingCalendar = createThunk<UpdatePershingCalendarThunkResult, UpdatePershingCalendarThunkAction>(
  'pershing/calendar/put',
  async ({ id, date }, { getState }) => {
    const { Pershing } = getState();
    const { calendars } = Pershing;

    const timezone = date.zoneName;
    const closingDate = date.toFormat(COMBINED_DATE_TIME_FORMAT);

    const result = await putAdminCalendar(id, { closingDate, timezone });

    const updatedCalendar = ApiCalendarsMapper.toCalendarModel(result.calendar);

    const updatedCalendars = [...calendars.archived];
    const index = updatedCalendars.findIndex((calendar) => calendar?.id === updatedCalendar.id);

    updatedCalendars.splice(index, 1, updatedCalendar);

    return { calendars: { ...calendars, archived: updatedCalendars } };
  },
);
