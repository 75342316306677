import { ReactElement, useEffect } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { selectedTargetedNoteIdAtom } from '@halo-atoms/watchlists';
import { ActionButton } from '@halo-common/components';
import { useRemoveTargetedNoteFromWatchlistMutation } from '@halo-data-sources/mutations';
import { Iconography, Modal, Stack } from '@halodomination/halo-fe-common';
import { Alert, Button } from '@mui/material';
import { useAtomValue } from 'jotai';

export type RemoveTargetedNoteModalProps = {
  onClose: () => void;
};

export const RemoveTargetedNoteModal = ({ onClose }: RemoveTargetedNoteModalProps): ReactElement => {
  const modalMap = useAtomValue(modalAtom);
  const selectedTargetedNoteId = useAtomValue(selectedTargetedNoteIdAtom);

  const { mutate, isError, isSuccess, isPending } = useRemoveTargetedNoteFromWatchlistMutation();

  const open = Boolean(modalMap?.removeTargeted);

  const handleRemove = () => void mutate(selectedTargetedNoteId);

  const errorAlert = isError ? (
    <Alert severity="error" variant="filled">
      Something went wrong. Please try submitting again shortly.
    </Alert>
  ) : null;

  useEffect(() => {
    if (isSuccess) onClose();
  }, [isSuccess]);

  return (
    <Modal
      title="Are you sure you want to remove the targeted note?"
      icon="trash"
      open={open}
      size="small"
      onClose={onClose}
      ContentProps={{ sx: { py: 3 } }}
      footer={
        <Stack direction="row" justify="flex-end" alignItems="center" spacing={2}>
          <Button
            variant="text"
            size="large"
            disableRipple
            disableElevation
            startIcon={<Iconography iconName="times" />}
            onClick={onClose}
          >
            Cancel
          </Button>
          <ActionButton
            variant="contained"
            size="large"
            color="primary"
            disableRipple
            disableElevation
            onClick={handleRemove}
            disabled={isPending}
            loading={isPending}
          >
            Confirm Remove
          </ActionButton>
        </Stack>
      }
    >
      {errorAlert}
    </Modal>
  );
};
