import { portfolioFiltersAtom } from '@halo-atoms/portfolio';
import {
  UserCalendarFilterSettingModel,
  CalendarPageModel,
  DashboardWidgetSetModel,
  OrganizationModel,
  UserGeographicSettingModel,
  UserLexicon,
  UserModel,
  UserPreferencesModel,
  UserSettingsModel,
  WhiteLabelModel,
} from '@halo-common/models';
import { fetchUserInfo, getClientConfiguration } from '@halo-data-sources/clients';
import { ApiUserMapper } from '@halo-data-sources/mappers';
import { ClientConfigurationModel } from '@halo-data-sources/models';
import { UserQueryKeyFactory } from '@halo-data-sources/queries';
import { HaloThemeOptions, ThemeContextResult, useHaloTheme } from '@halodomination/halo-fe-theme';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';

export const USER_TOKEN_KEY = 't-pub';
export const USER_LOCAL_KEY = 't-loc';

export type UserInfoThemeModel = {
  options: HaloThemeOptions;
};

export type UserInfoModel = {
  authenticated: boolean;
  calendarPage: CalendarPageModel | null;
  calendarPageFilterSettingSet: UserCalendarFilterSettingModel | null;
  dashboardWidgetSet: DashboardWidgetSetModel | null;
  details: UserModel;
  geographicSettingSet: UserGeographicSettingModel | null;
  organization: OrganizationModel | null;
  preferences: UserPreferencesModel;
  settings: Partial<UserSettingsModel>;
  theme: UserInfoThemeModel;
  whiteLabel: WhiteLabelModel;
  lexicon?: UserLexicon | null;
};

export type ApplicationUserInfoModel = UserInfoModel & {
  configuration: ClientConfigurationModel;
};

export type UserInfoQueryResult = UseQueryResult<ApplicationUserInfoModel, Error>;

const fetchUserInfoQueryFn = async (themeManager: ThemeContextResult) => {
  const response = await fetchUserInfo();
  const configuration = await getClientConfiguration();

  const user = ApiUserMapper.toUserInfo(response);

  const localizationPublicKey = user.whiteLabel.localizationPublicKey ?? window.localStorage.getItem(USER_TOKEN_KEY);
  const preferredLanguage = user.details.preferredLanguage ?? window.localStorage.getItem(USER_LOCAL_KEY);

  if (localizationPublicKey) window.localStorage.setItem(USER_TOKEN_KEY, localizationPublicKey);
  if (preferredLanguage) window.localStorage.setItem(USER_LOCAL_KEY, preferredLanguage);
  if (user.theme) themeManager.setTheme(user.theme.options);

  return { ...user, configuration };
};

export const useUserInfoQuery = (): UserInfoQueryResult => {
  const theme = useHaloTheme();

  const setPortfolioFilters = useSetAtom(portfolioFiltersAtom);

  return useQuery<unknown, Error, ApplicationUserInfoModel>({
    queryKey: UserQueryKeyFactory.info(),
    queryFn: async () => {
      const data = await fetchUserInfoQueryFn(theme);

      const defaultCurrency = data?.whiteLabel?.defaultCurrency;
      if (defaultCurrency) setPortfolioFilters({ currency: defaultCurrency });

      return data;
    },
  });
};
