import { ReactElement } from 'react';

import { orderBookApprovalModalAtom } from '@halo-atoms/orderbook';
import { ApproveOrderModal } from '@halo-common/modals';
import { CalendarSelectors, CalendarStatusEnum } from '@halo-stores/Calendar';
import { FixSelectors, FixStatusEnum } from '@halo-stores/Fix';
import { PershingSelectors } from '@halo-stores/Pershing';
import { useAtom } from 'jotai';
import { useSelector } from 'react-redux';

// TODO: When Pershing is migrated to react query we need to rework this entire modal
export const OrderBookPershingApproveOrderModal = (): ReactElement => {
  const [orderApprovalModal, setOrderBookApprovalModal] = useAtom(orderBookApprovalModalAtom);

  const { open } = orderApprovalModal;

  const calendarStatus = useSelector(CalendarSelectors.selectStatus);
  const fixStatus = useSelector(FixSelectors.selectStatus);

  const calendar = useSelector(PershingSelectors.selectCalendar) ?? undefined;
  const allocation = useSelector(PershingSelectors.selectAllocation) ?? undefined;
  const fixOrder = useSelector(FixSelectors.selectFixOrder) ?? undefined;

  const isFetchingCalendar = calendarStatus === CalendarStatusEnum.requestFetchCalendar;
  const isFetchingFixOrder = fixStatus === FixStatusEnum.requestFetchFixOrder;
  const loading = isFetchingCalendar || isFetchingFixOrder;

  const handleCloseApproveOrderModal = () => {
    setOrderBookApprovalModal({ open: false, allocation: null });
  };

  return (
    <ApproveOrderModal
      calendar={calendar}
      allocation={allocation}
      fixOrder={fixOrder}
      open={open}
      onClose={handleCloseApproveOrderModal}
      loading={loading}
      statusModifiable={false}
    />
  );
};
