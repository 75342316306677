import { PendingAction } from '@halo-stores/types';
import { createSlice } from '@reduxjs/toolkit';

import { WebsocketState, WebsocketStatusEnum } from './WebsocketModel';

const INITIAL_STATE: WebsocketState = {
  status: WebsocketStatusEnum.idle,
  timeout: {
    delay: 15000,
    reference: undefined,
  },
};

export const WebsocketDuck = createSlice({
  name: 'Websocket',
  initialState: INITIAL_STATE,
  reducers: {
    setTimeoutReference(state, action) {
      return {
        ...state,
        timeout: {
          ...state.timeout,
          reference: action?.payload.reference,
        },
      };
    },
    setTimeoutDelay(state, action) {
      return {
        ...state,
        timeout: {
          ...state.timeout,
          delay: action?.payload.delay,
        },
      };
    },
    handleTimeout(state) {
      const { timeout } = state;

      if (timeout) clearTimeout(timeout.reference);

      return {
        ...state,
        status: WebsocketStatusEnum.timeout,
      };
    },
    handleResolved(state) {
      const { timeout } = state;

      if (timeout) clearTimeout(timeout.reference);

      return {
        ...state,
        status: INITIAL_STATE.status,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action): action is PendingAction => action.type.includes('/ws/') && action.type.includes('/pending'),
      (state) => {
        state.status = WebsocketStatusEnum.listening;
      },
    );
  },
});
