import { ReactElement, useState } from 'react';

import { CalendarProductCard } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { FeaturedCalendarNoteResponseModel } from '@halo-data-sources/queries';
import { useCalendarProductCardActions } from '@halo-modules/app/dashboard/hooks';
import { Iconography, LocalizedButton, Modal } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';

const buttonSx = {
  background: 'white',
  mt: 2,
  width: 'fit-content',
};

const iframeStyling = {
  aspectRatio: '1056 / 632',
  border: 'none',
  borderRadius: '16px',
  maxWidth: '1056px',
  marginTop: '8px',
  width: '100%',
};

export interface WatchFeaturedNoteVideoModalProps {
  note: FeaturedCalendarNoteResponseModel;
}

export const WatchFeaturedNoteVideoModal = ({ note }: WatchFeaturedNoteVideoModalProps): ReactElement | null => {
  const actions = useCalendarProductCardActions();
  const [isOpen, setIsOpen] = useState(false);

  if (!note || !note.video_link) return null;

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => setIsOpen(false);

  const footer = (
    <Stack direction="row" justifyContent="flex-end" alignItems="center">
      <LocalizedButton onClick={handleClose} variant="contained" color="primary" size="large">
        {translations.common.close}
      </LocalizedButton>
    </Stack>
  );

  const { title, overline, description, calendar } = note;

  const calendarCard = calendar ? <CalendarProductCard calendar={calendar} actions={actions} /> : null;

  return (
    <>
      <LocalizedButton
        startIcon={<Iconography iconName="circle-caret-right" />}
        size="small"
        color="primary"
        variant="outlined"
        onClick={handleOpen}
        sx={buttonSx}
      >
        {translations.dashboard.featuredNotes.watchVideo}
      </LocalizedButton>

      <Modal
        open={isOpen}
        onClose={handleClose}
        size="large"
        title={title}
        overline={overline}
        subtitle={description}
        footer={footer}
      >
        {calendarCard}
        <Stack justifyContent="center" alignItems="center">
          <iframe
            src={note.video_link}
            title={title}
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            allowFullScreen
            style={iframeStyling}
          />
        </Stack>
      </Modal>
    </>
  );
};
