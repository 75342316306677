import { ReactElement } from 'react';

import { useDesktopView } from '@halo-common/hooks';
import { Iconography } from '@halodomination/halo-fe-common';
import { Box, Button } from '@mui/material';

const arrowSx = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '48px',
  height: '100%',
  backgroundColor: 'grey.50',
  border: 'none',
  zIndex: 2,
  transition: 'opacity 0.3s',
  opacity: 1,
  pointerEvents: 'auto',
  '& > button': {
    position: 'relative',
    zIndex: 3,
  },
  '&:has(button:disabled)': {
    opacity: 0,
    pointerEvents: 'none',
  },
};

const blindSx = {
  backgroundColor: 'inherit',
  position: 'absolute',
  top: '-16px',
  bottom: '-48px',
  pointerEvents: 'inherit',
  zIndex: 1,
};

const buttonSx = {
  minHeight: 40,
  minWidth: 40,
  borderRadius: '50%',
};

export interface NotesCarouselNextArrowProps {
  currentSlide?: number;
  onClick?: () => void;
  slideCount?: number;
}

export const NotesCarouselNextArrow = ({
  currentSlide = 0,
  slideCount = 0,
  onClick,
}: NotesCarouselNextArrowProps): ReactElement => {
  const isDesktop = useDesktopView();
  const visibleCards = isDesktop ? 4 : 2;
  const canScrollRight = currentSlide <= slideCount - visibleCards;

  const containerSx = {
    ...arrowSx,
    borderLeft: '1px solid',
    borderColor: 'grey.300',
    right: 0,
    justifyContent: 'flex-end',
  };

  const offsetSx = {
    ...blindSx,
    left: '0',
    right: '-100vw',
  };

  return (
    <Box sx={containerSx}>
      <Box sx={offsetSx} />
      <Button onClick={onClick} disabled={!canScrollRight} sx={buttonSx} className="wm-carousel-right">
        <Iconography iconName="chevron-right" />
      </Button>
    </Box>
  );
};
