import { MouseEvent, ReactElement } from 'react';

import {
  LexicalTypography,
  ProductCardNoteExpandedDetailsRow,
  ProductCardNoteExpandedDetailsSolvableParameterRow,
} from '@halo-common/components';
import { NoteTypeEnum } from '@halo-common/enums';
import { useNoteSpecificDetails } from '@halo-common/hooks';
import { NoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { Box, Divider, Stack } from '@mui/material';

const containerSx = {
  display: 'flex',
  height: '100%',
  position: 'relative',
};

const containerLayoutSx = {
  height: '100%',
  minHeight: 280,
  width: '100%',
};

const featureContainerSx = {
  maxHeight: 185,
  overflowY: 'scroll',
};

const backButtonSx = {
  position: 'absolute',
  bottom: -16,
  right: -8,
  [`&:hover`]: {
    backgroundColor: 'unset',
  },
};

export type ProductCardNoteExpandedDetailsProps = {
  note: NoteModel;
  onBack: (ev: MouseEvent) => void;
  overrides?: { quote?: number };
};

export const ProductCardNoteExpandedDetails = ({
  note,
  onBack,
  overrides,
}: ProductCardNoteExpandedDetailsProps): ReactElement => {
  const { id, features = [], productType, type } = note;

  const { underlyings, solvableParameter, term, protectionDetails, fields } = useNoteSpecificDetails(note);

  const solvableParameterValue = overrides?.quote ?? solvableParameter?.original;
  const principalProtection = protectionDetails?.[0];
  const termText = term?.text;
  const productDisplayType = productType ?? type;
  const underlyingText = underlyings?.text;
  const isGeneralNote = type === NoteTypeEnum.General;

  const subtitle = solvableParameter ? (
    <ProductCardNoteExpandedDetailsSolvableParameterRow
      id={id}
      type={productDisplayType}
      label={solvableParameter.label}
      value={solvableParameterValue}
      isGeneralNote={isGeneralNote}
    />
  ) : null;

  const underlyingsRow = underlyingText ? (
    <ProductCardNoteExpandedDetailsRow icon={underlyings.icon} text={underlyingText} />
  ) : null;

  const termRow = termText ? (
    <ProductCardNoteExpandedDetailsRow
      icon={term.icon}
      text={termText}
      label={term.label}
      localization={term.dynamicContent}
    />
  ) : null;

  const protectionRow = principalProtection ? (
    <ProductCardNoteExpandedDetailsRow
      icon={principalProtection.icon}
      text={principalProtection.text}
      label={principalProtection.label}
      localization={principalProtection.dynamicContent}
    />
  ) : null;

  const fieldRows = fields.map(({ label, alt, text, icon, dynamicContent }) => {
    const description = alt ?? text;

    return (
      <ProductCardNoteExpandedDetailsRow
        key={label}
        icon={icon}
        text={description}
        label={label}
        localization={dynamicContent}
      />
    );
  });

  const featureRows = features?.map(({ name, icon, description, dynamicContent }) => {
    return description ? (
      <ProductCardNoteExpandedDetailsRow key={name} icon={icon} text={description} localization={dynamicContent} />
    ) : null;
  });

  if (isGeneralNote)
    featureRows.push(
      <ProductCardNoteExpandedDetailsRow
        key={type}
        icon={solvableParameter?.icon}
        text={solvableParameter?.text ?? ''}
      />,
    );

  return (
    <Box sx={containerSx}>
      <Stack sx={containerLayoutSx} direction="column" spacing={2}>
        <Stack direction="column" spacing={0.5}>
          <LexicalTypography variant="overline" textTransform="uppercase" color="textSecondary">
            {translations.components.productCardBackTitle}
          </LexicalTypography>
          {subtitle}
        </Stack>
        <Stack sx={featureContainerSx} direction="column" spacing={0.5} divider={<Divider flexItem />}>
          {underlyingsRow}
          {termRow}
          {protectionRow}
          {fieldRows}
          {featureRows}
        </Stack>
      </Stack>
      <LocalizedButton
        sx={backButtonSx}
        variant="text"
        onClick={onBack}
        endIcon={<Iconography iconName="arrow-right" />}
      >
        {translations.common.back}
      </LocalizedButton>
    </Box>
  );
};
