import { MouseEvent, ReactElement, useEffect } from 'react';

import { SubmittedAllocationsTable, SubmittedAllocationsTableProps } from '@halo-common/components';
import { AllocationStatusEnum, SMAAllocationStatusEnum } from '@halo-common/enums';
import {
  AllocationModalFooter,
  AllocationModalFooterProps,
  AllocationModalForm,
  AllocationModalFormModel,
  AllocationModalFormProps,
} from '@halo-common/modals';
import { AllocationModel, AllocationSubmissionModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { HaloDataGridProps, ModalProps, TabbedModal, TabsProps } from '@halodomination/halo-fe-common';
import { Typography, capitalize } from '@mui/material';
import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';

export const PENDING_ALLOCATION_STATUSES: Array<string> = [
  AllocationStatusEnum.accepted,
  AllocationStatusEnum.pending,
  AllocationStatusEnum.new,
  SMAAllocationStatusEnum.approved,
  SMAAllocationStatusEnum.pending,
  SMAAllocationStatusEnum.submitted,
];

export type AllocationModalContainerProps = Omit<ModalProps, 'children' | 'FooterProps'> & {
  allocations?: Array<AllocationSubmissionModel>;
  disableEdits?: boolean;
  disableSubmit?: boolean;
  hideForm?: boolean;
  hidePrimaryAction?: boolean;
  status?: 'idle' | 'loading' | 'success' | 'error';
  message?: string;
  TabsProps?: Partial<TabsProps>;
  TableProps?: Partial<Omit<SubmittedAllocationsTableProps, 'height'>>;
  FooterProps?: Partial<AllocationModalFooterProps>;
  FormProps?: Partial<AllocationModalFormProps>;
  onCancel?: () => void;
  onSubmit: (data: AllocationModalFormModel) => void;
  onCancelAllocation: (id: number) => void;
  onEditAllocation?: HaloDataGridProps['onCellEditStop'];
};

export const AllocationModalContainer = ({
  allocations = [],
  disableEdits = false,
  disableSubmit = false,
  hideForm = false,
  hidePrimaryAction = false,
  status = 'idle',
  message,
  onClose,
  onSubmit,
  onEditAllocation,
  onCancelAllocation,
  TabsProps,
  FooterProps,
  FormProps,
  TableProps,
  ...ModalProps
}: AllocationModalContainerProps): ReactElement => {
  const { handleSubmit: submitHandler, reset, watch } = useFormContext<AllocationModalFormModel>();

  const loading = status === 'loading';
  const success = status === 'success';

  const activeAllocationCount = allocations.filter((allocation) =>
    PENDING_ALLOCATION_STATUSES.includes(allocation.status),
  ).length;

  const allocationsFormValue = watch('allocations');

  const allocationsTotal = allocationsFormValue.reduce((total: number, { amount }) => total + Number(amount), 0);
  const allocationsOverInventory =
    typeof FormProps?.rules?.inventoryRemaining === 'number'
      ? allocationsTotal > FormProps.rules.inventoryRemaining
      : false;

  const submitDisabled = loading || disableSubmit || allocationsOverInventory;

  const tabs: TabsProps['tabs'] = [{ label: 'Submitted Allocations', count: activeAllocationCount }];
  if (!hideForm) tabs.unshift({ label: 'New Allocations' });

  const handleSubmit = submitHandler(onSubmit);

  const handleClose = () => {
    onClose?.();
    reset();
  };

  const handleModalClose = (_: MouseEvent<HTMLElement>, reason: string) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      field: 'name',
      headerName: translations.common.name,
      flex: 1.5,
      editable: false,
      valueFormatter: (_, row: AllocationModel) => {
        const name = row.account?.name;
        const custodian = row.account?.custodian;
        const custodianName = custodian?.name ?? '--';
        return `${name} - ${custodianName}`;
      },
    },
    {
      field: 'accountNumber',
      headerName: translations.common.account,
      flex: 1,
      editable: false,
      valueGetter: (_, row: AllocationModel) => row?.account?.accountId,
    },
    {
      field: 'status',
      headerName: translations.common.status,
      flex: 1,
      editable: false,
      valueFormatter: (value: string) => {
        const status = value ?? '';
        return capitalize(status);
      },
    },
    {
      field: 'amount',
      headerName: translations.common.amount,
      flex: 1,
      editable: disableEdits,
      valueFormatter: (value: number) => new Intl.NumberFormat().format(value),
    },
  ];

  const formMessage = message ? <Typography>{message}</Typography> : null;
  const form = !hideForm ? (
    <AllocationModalForm {...FormProps} loading={loading}>
      {formMessage}
    </AllocationModalForm>
  ) : null;

  useEffect(() => {
    if (success) reset();
  }, [success]);

  return (
    <TabbedModal
      {...ModalProps}
      tabs={tabs}
      onClose={handleModalClose}
      TabsProps={{ ...TabsProps, loading }}
      footer={
        <AllocationModalFooter
          {...FooterProps}
          disabled={submitDisabled}
          onSubmit={handleSubmit}
          onClose={handleClose}
          hidePrimaryAction={hidePrimaryAction}
        >
          {FooterProps?.children}
        </AllocationModalFooter>
      }
    >
      {form}
      <SubmittedAllocationsTable
        {...TableProps}
        loading={loading}
        allocations={allocations}
        columns={columns}
        onCancel={onCancelAllocation}
        onCellEditStop={onEditAllocation}
        isCellEditable={(params) => {
          const isActive = DateTime.fromISO(params?.row?.expirationDate) > DateTime.now();
          const editable = Boolean(params.isEditable && isActive);
          return editable;
        }}
      />
    </TabbedModal>
  );
};
