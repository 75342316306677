import { ReactElement } from 'react';

import { roundValue } from '@halo-common/utils';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack, Typography, capitalize } from '@mui/material';

const typographySx = {
  textAlign: 'center',
};

const conjunctionSx = {
  textAlign: 'center',
  fontWeight: 600,
};

export type PayoffLabelProps = {
  name: string;
  value: string;
  when?: string;
  label?: string;
  conjunction?: string;
  showConjunction?: boolean;
};

export const PayoffLabel = ({
  name,
  value,
  when,
  label = '',
  conjunction,
  showConjunction = false,
}: PayoffLabelProps): ReactElement => {
  const nameText = `${name}${when ? ` ${when}` : ''}`;

  const enableConjunctionText = showConjunction && conjunction;

  const conjunctionText = enableConjunctionText ? (
    <Typography sx={conjunctionSx} variant="caption" color="textSecondary">
      {capitalize(conjunction)}
    </Typography>
  ) : null;

  const roundedValue = roundValue(value);

  return (
    <Stack key={name} direction="row" alignItems="center" spacing={2}>
      <Stack direction="column" alignItems="center">
        <Typography sx={typographySx} variant="h2" color="textPrimary">
          {roundedValue}
        </Typography>
        <LocalizedTypography sx={typographySx} variant="caption" color="textSecondary">
          {nameText}
        </LocalizedTypography>
        <LocalizedTypography sx={typographySx} variant="caption" color="textSecondary">
          {label}
        </LocalizedTypography>
      </Stack>
      {conjunctionText}
    </Stack>
  );
};
