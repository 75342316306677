import { ChangeEvent, DragEvent, MutableRefObject, useRef, useState } from 'react';

export type UseFileUploadState = {
  file?: File | null;
  hovered: boolean;
};

export type UseFileUploadHookResult = {
  file: UseFileUploadState['file'];
  fileSelected: boolean;
  fileInputRef: MutableRefObject<HTMLInputElement | null>;
  fileSectionHovered: UseFileUploadState['hovered'];
  onFileSelection: () => void;
  onFileChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  onDropEvent: (ev: DragEvent) => void;
  onDragOverEvent: (ev: DragEvent) => void;
  onDragLeaveEvent: (ev: DragEvent) => void;
};

export type UseFileUploadOptions = {
  onFileChange?: (file?: File) => void;
};

export const useFileUpload = ({ onFileChange }: UseFileUploadOptions): UseFileUploadHookResult => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [state, setState] = useState<UseFileUploadState>({ hovered: false });

  const fileSelected = Boolean(state.file);

  const handleFileSelection = () => void fileInputRef.current?.click();

  const handleFileChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const { files } = ev.target;
    const selectedFile = files?.[0];

    setState({ ...state, file: selectedFile });

    onFileChange?.(selectedFile);
  };

  const handleDropEvent = (ev: DragEvent) => {
    ev.preventDefault();

    const { dataTransfer } = ev;
    const { files } = dataTransfer;

    setState({ ...state, file: files.item(0), hovered: false });
  };

  const handleDragOverEvent = (ev: DragEvent) => {
    ev.preventDefault();

    if (!state.hovered) setState({ ...state, hovered: true });
  };

  const handleDragLeaveEvent = (ev: DragEvent) => {
    ev.preventDefault();

    if (state.hovered) setState({ ...state, hovered: false });
  };

  return {
    file: state.file,
    fileSelected,
    fileInputRef,
    fileSectionHovered: state.hovered,
    onFileSelection: handleFileSelection,
    onFileChange: handleFileChange,
    onDropEvent: handleDropEvent,
    onDragOverEvent: handleDragOverEvent,
    onDragLeaveEvent: handleDragLeaveEvent,
  };
};
