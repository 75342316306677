import { ReactElement } from 'react';

import { ActionButton } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { Stack, useMediaQuery, useTheme } from '@mui/material';

const deleteButtonSx = {
  color: 'error.dark',
};

export type EditWatchlistFooterProps = {
  onSave: () => void;
  onDelete: () => void;
  onClose: () => void;
  loading?: boolean;
  isDefault?: boolean;
};

export const EditWatchlistFooter = ({
  onSave,
  onDelete,
  onClose,
  loading = false,
  isDefault = false,
}: EditWatchlistFooterProps): ReactElement => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme?.breakpoints?.down('md'));

  const displayDeleteButton = isDefault ? (
    <LocalizedButton variant="outlined" size="large" disabled disableRipple disableElevation fullWidth>
      {translations.watchlist.common.cantDelete}
    </LocalizedButton>
  ) : (
    <LocalizedButton
      variant="outlined"
      size="large"
      disableRipple
      disableElevation
      color="error"
      sx={deleteButtonSx}
      onClick={onDelete}
      fullWidth
    >
      {translations.watchlist.common.deleteWatchlist}
    </LocalizedButton>
  );

  return isMobile ? (
    <Stack direction="column" spacing={2}>
      <ActionButton
        variant="contained"
        size="large"
        color="primary"
        disableRipple
        disableElevation
        onClick={onSave}
        disabled={loading}
        loading={loading}
        fullWidth
      >
        {translations.common.save}
      </ActionButton>
      {displayDeleteButton}
      <LocalizedButton
        variant="text"
        size="large"
        disableRipple
        disableElevation
        startIcon={<Iconography iconName="arrow-left" />}
        onClick={onClose}
        fullWidth
      >
        {translations.common.cancel}
      </LocalizedButton>
    </Stack>
  ) : (
    <Stack direction="row" justifyContent="space-between">
      <LocalizedButton
        variant="text"
        size="large"
        disableRipple
        disableElevation
        startIcon={<Iconography iconName="arrow-left" />}
        onClick={onClose}
      >
        {translations.common.cancel}
      </LocalizedButton>
      <Stack direction="row" spacing={2}>
        {displayDeleteButton}
        <ActionButton
          variant="contained"
          size="large"
          color="primary"
          disableRipple
          disableElevation
          onClick={onSave}
          disabled={loading}
          loading={loading}
        >
          {translations.common.save}
        </ActionButton>
      </Stack>
    </Stack>
  );
};
