import { ReactElement } from 'react';

import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { OrderBookTimeFrameEnum } from '@halo-common/enums';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useAtom } from 'jotai';

export const TimeFrameField = (): ReactElement => {
  const [searchFields, setSearchFields] = useAtom(orderBookQueryAtom);

  const labelString = 'Time Frame';

  const handleTimeFrameChange = (ev: SelectChangeEvent<string>) => {
    const value = ev.target.value;

    setSearchFields({
      filters: {
        timeFrame: value,
      },
    });
  };

  return (
    <FormControl fullWidth>
      <InputLabel shrink>{labelString}</InputLabel>
      <Select
        label={labelString}
        size="large"
        value={searchFields.filters.timeFrame}
        onChange={handleTimeFrameChange}
        displayEmpty
        notched
      >
        <MenuItem value="">All</MenuItem>
        {Object.entries(OrderBookTimeFrameEnum).map((entry) => (
          <MenuItem key={entry[0]} value={entry[0]}>
            {entry[1]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
