import { ExecutionHubQuery } from '@halo-atoms/executionHub';
import { postCalendarAdminOrderFiltersQuery } from '@halo-data-sources/clients';
import { ApiComparisonOptionEnum } from '@halo-data-sources/enums';
import { ExecutionHubMapper } from '@halo-data-sources/mappers';
import { OrdersClientQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

export type CalendarOrderByStatusQueryResult = {
  active: number;
  canceled: number;
  filled: number;
};

const calendarOrderByStatusQueryFn = async (query: ExecutionHubQuery) => {
  const comparisons = ExecutionHubMapper.toApiFiltersComparisonList(query);

  const response = await postCalendarAdminOrderFiltersQuery({
    filters: [
      {
        field: 'exec_order.status',
        type: 'VALUES',
      },
    ],
    comparisons: [
      {
        field: 'exec_order.status',
        op: ApiComparisonOptionEnum.IN,
        value: ['ACTIVE', 'FILLING', 'FILLED_DK', 'CANCELED', 'FILLED'],
      },
      ...comparisons,
    ],
  });

  const defaultCountMap = { active: 0, canceled: 0, filled: 0 };

  return (
    response.filter_values[0]?.values?.reduce((map, { value, count }) => {
      const isActiveStatus = ['ACTIVE', 'FILLING', 'FILLED_DK'].includes(value as string);
      if (isActiveStatus) return { ...map, active: map.active + count };
      else return { ...map, [(value as string).toLowerCase()]: count };
    }, defaultCountMap) ?? defaultCountMap
  );
};

export const useCalendarOrderByStatusQuery = (
  query: ExecutionHubQuery,
): UseQueryResult<CalendarOrderByStatusQueryResult, Error> =>
  useQuery<CalendarOrderByStatusQueryResult, Error>({
    queryKey: OrdersClientQueryKeyFactory.calendarOrderByStatus(query),
    queryFn: () => calendarOrderByStatusQueryFn(query),
    placeholderData: keepPreviousData,

    retry: false,
  });
