import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

export type ProductsQueryKeys = {
  all: QueryKeyFactoryFn;
  byAssetId: QueryKeyFactoryFn;
};

export const ProductsQueryKeyFactory: ProductsQueryKeys = {
  all: () => ['products'],
  byAssetId: (id, query) => [...ProductsQueryKeyFactory.all(), 'products', id, hash(query)],
};
