import { MutableRefObject, useCallback, useEffect, useState } from 'react';

import { useRefProxy } from '../useRefProxy';

export interface UseDetectEllipsisReturn<T extends HTMLElement = HTMLElement> {
  ref: MutableRefObject<T | null>;
  hasEllipsis: boolean;
}

const getHasOverflow = (element: HTMLElement, threshold = 1): boolean => {
  const hasVerticalOverflow = element.scrollHeight > element.clientHeight + threshold;
  const hasHorizontalOverflow = element.scrollWidth > element.clientWidth + threshold;
  return hasVerticalOverflow || hasHorizontalOverflow;
};

export const useDetectEllipsis = <T extends HTMLElement = HTMLElement>(): UseDetectEllipsisReturn<T> => {
  const [ref, element] = useRefProxy<T>();

  const [hasEllipsis, setHasEllipsis] = useState(false);

  const checkEllipsis = useCallback(() => {
    if (element) {
      const hasOverflow = getHasOverflow(element);
      setHasEllipsis(hasOverflow);
    } else {
      setHasEllipsis(false);
    }
  }, [element]);

  useEffect(() => {
    checkEllipsis();

    if (!element) return;

    const observer = new ResizeObserver(checkEllipsis);
    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, [element, checkEllipsis]);

  return { ref, hasEllipsis };
};
