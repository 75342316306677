import { ReactElement, ReactNode } from 'react';

import {
  CalendarIssuerChips,
  CalendarProductTypeChips,
  CheckboxChips,
  CurrencyChips,
  NonCallPeriodChip,
  NoteTypeChips,
  PrincipalProtectionChip,
  TermChip,
  ToggleChips,
  UnderlyingCountChip,
} from '@halo-common/layouts';
import { useCombinedStyling } from '@halodomination/halo-fe-common';
import { Stack, SxProps, chipClasses } from '@mui/material';

const containerSx: SxProps = {
  '&:empty': {
    display: 'none',
  },
};

const chipSx: SxProps = {
  backgroundColor: 'primary.background',
  color: 'primary.main',
  mx: 0.25,
  my: 0.35,
  width: 'fit-content',
  [`& .${chipClasses.deleteIcon}`]: {
    color: 'primary.main',
  },
};

export type SideBarFilterChipsProps = {
  tag: string;
  children?: ReactNode;
  sx?: SxProps;
};

export const SideBarFilterChips = ({ tag, children, sx }: SideBarFilterChipsProps): ReactElement => {
  const combinedSx = useCombinedStyling(containerSx, sx);

  return (
    <Stack direction="row" sx={combinedSx} flexWrap="wrap">
      {children}
      <CheckboxChips tag={tag} sx={chipSx} />
      <CurrencyChips tag={tag} sx={chipSx} />
      <CalendarIssuerChips tag={tag} sx={chipSx} />
      <NoteTypeChips tag={tag} sx={chipSx} />
      <CalendarProductTypeChips tag={tag} sx={chipSx} />
      <NonCallPeriodChip tag={tag} sx={chipSx} />
      <PrincipalProtectionChip tag={tag} sx={chipSx} />
      <TermChip tag={tag} sx={chipSx} />
      <UnderlyingCountChip tag={tag} sx={chipSx} />
      <ToggleChips tag={tag} sx={chipSx} />
    </Stack>
  );
};
