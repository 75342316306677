import { ReactElement } from 'react';

import { UnderlyingFormatTypeEnum } from '@halo-common/enums';
import { ProductDetailPanelLayout, ProductDetailPanelUnderlyingSection } from '@halo-common/layouts';
import {
  PostTradeAnnuityDetailModalPanelContractSection,
  PostTradeAnnuityDetailModalPanelIssuerSection,
} from '@halo-common/modals';
import { AnnuityOrderModel } from '@halo-common/models';

export type PostTradeAnnuityDetailModalPanelProps = {
  product?: AnnuityOrderModel | null;
  loading: boolean;
};

export const PostTradeAnnuityDetailModalPanel = ({
  product,
  loading,
}: PostTradeAnnuityDetailModalPanelProps): ReactElement => {
  const underlyings = product?.underlyings ?? [];

  const underlyingGlyphs = underlyings.map((tradable, index) => ({
    id: String(index),
    name: tradable.fundUnderlyingSecurityType,
    ticker: tradable.fundUnderlyingSecurityType,
    description: tradable.fundUnderlyingSecurityName,
    units: tradable.fundUnits,
    value: tradable.fundValue,
    format: UnderlyingFormatTypeEnum.annuity,
  }));

  const weights = underlyings.reduce(
    (map, tradable) => ({ ...map, [tradable.fundUnderlyingSecurityType]: tradable.fundPercentage }),
    {},
  );

  const showContractDetail = loading || product;
  const contractDetailSection = showContractDetail ? (
    <PostTradeAnnuityDetailModalPanelContractSection product={product} loading={loading} />
  ) : null;

  const slots = {
    highlight: <PostTradeAnnuityDetailModalPanelIssuerSection loading={loading} product={product} />,
    underlyings: (
      <ProductDetailPanelUnderlyingSection loading={loading} underlyings={underlyingGlyphs} weights={weights} />
    ),
  };

  return (
    <ProductDetailPanelLayout slots={slots} loading={loading}>
      {contractDetailSection}
    </ProductDetailPanelLayout>
  );
};
