import { ReactElement } from 'react';

import { AuctionStatusEnum, AuctionStatusKeyEnum } from '@halo-common/enums';
import { OrderHubOrderedColumnsModel } from '@halo-common/models';
import { useUserSettingMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import {
  AuctionTable,
  AuctionTableLayout,
  OH_ORDERED_COLUMNS_KEY,
  OH_PINNED_COLUMNS_KEY,
  useAuctionTableData,
} from '@halo-modules/admin';
import { GridPinnedColumnFields } from '@mui/x-data-grid-pro';

import { TermsPendingModal } from './TermsPendingModal';

export const TermsPendingTable = (): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();
  const { mutate: updateUserSetting } = useUserSettingMutation();
  const { data, gridRef, issuersVisible, handleDataSwitch } = useAuctionTableData(AuctionStatusEnum.TermsPending);

  const orderHubPinnedColumns = userInfo?.settings?.orderHubPinnedColumns;
  const orderHubOrderedColumns = userInfo?.settings?.orderHubOrderedColumns;

  const selectOrderHubPinnedColumns = orderHubPinnedColumns?.[AuctionStatusKeyEnum.TermsPending];
  const selectOrderHubOrderedColumns = orderHubOrderedColumns?.[AuctionStatusKeyEnum.TermsPending];

  const handleColumnPin = (updatedGridPinnedColumns: GridPinnedColumnFields) => {
    updateUserSetting({
      key: OH_PINNED_COLUMNS_KEY,
      value: { ...orderHubPinnedColumns, inAuction: updatedGridPinnedColumns },
    });
  };

  const handleColumnReorder = (updatedColumnOrders: OrderHubOrderedColumnsModel) => {
    updateUserSetting({
      key: OH_ORDERED_COLUMNS_KEY,
      value: { ...orderHubOrderedColumns, inAuction: updatedColumnOrders },
    });
  };

  const description = `These orders were successful and are awaiting the Preliminary Termsheet from the winning issuer.`;

  return (
    <AuctionTableLayout
      description={description}
      title="Waiting for Terms"
      onIssuersToggle={handleDataSwitch}
      onExport={gridRef.current.exportDataAsCsv}
    >
      <AuctionTable
        onColumnPin={handleColumnPin}
        onColumnReorder={handleColumnReorder}
        defaultPinnedColumns={selectOrderHubPinnedColumns}
        defaultOrderedColumns={selectOrderHubOrderedColumns}
        issuersVisible={issuersVisible}
        DataGridProps={{
          data,
          cta: {
            icon: 'file-upload',
            label: 'Upload Terms',
            color: 'purple',
          },
        }}
        gridRef={gridRef}
      />
      <TermsPendingModal icon="file-upload" />
    </AuctionTableLayout>
  );
};
