import { AuctionDateTypeEnum } from '@halo-common/enums';
import { AuctionDefaultDatesModel } from '@halo-common/models';
import { getAuctionDefaults } from '@halo-data-sources/clients';
import { ApiAuctionDefaultsMapper, AuctionDefaultsMapper } from '@halo-data-sources/mappers';
import { AuctionQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

export type GetAuctionDefaultDatesRequestModel = {
  date: DateTime;
  dateType: AuctionDateTypeEnum;
};

const getAuctionDateDefaultsFn = async (payload: GetAuctionDefaultDatesRequestModel) => {
  const request = AuctionDefaultsMapper.toApiUserAuctionDefaultsRequest(payload);

  const response = await getAuctionDefaults(request);

  return ApiAuctionDefaultsMapper.toAuctionDateDefaults(response);
};

export const useAuctionDateDefaultsQuery = (
  payload: GetAuctionDefaultDatesRequestModel,
): UseQueryResult<AuctionDefaultDatesModel, Error> =>
  useQuery<AuctionDefaultDatesModel, Error>({
    queryKey: AuctionQueryKeyFactory.dateDefaults(payload.date),
    queryFn: () => getAuctionDateDefaultsFn(payload),
  });
