import { ExecutionHubQuery } from '@halo-atoms/executionHub';
import { ApiComparisonOptionEnum, ApiComparisonTypeEnum } from '@halo-data-sources/enums';
import { ApiComparisonModel } from '@halo-data-sources/models';

interface ExecutionHubMapper {
  toApiStatusComparisonList: (status: string) => Array<ApiComparisonModel>;
  toApiCalendarComparisonList: (query: ExecutionHubQuery) => Array<ApiComparisonModel>;
  toApiFiltersComparisonList: (query: ExecutionHubQuery) => Array<ApiComparisonModel>;
  toApiCalendarOrderComparisonList: (query: ExecutionHubQuery) => Array<ApiComparisonModel>;
}

export const ExecutionHubMapper: ExecutionHubMapper = {
  toApiStatusComparisonList: (status: string): Array<ApiComparisonModel> => {
    const statusValue = {
      active: ['ACTIVE', 'FILLING', 'FILLED_DK'],
      canceled: ['CANCELED'],
      filled: ['FILLED'],
    }[status] as Array<string>;

    return [
      {
        field: 'exec_order.status',
        op: ApiComparisonOptionEnum.IN,
        value: statusValue,
      },
    ];
  },
  toApiFiltersComparisonList: (query: ExecutionHubQuery): Array<ApiComparisonModel> => {
    const comparisons: Array<ApiComparisonModel> = [];

    if (query.accountType) {
      comparisons.push({
        field: 'accounts.designation',
        op: ApiComparisonOptionEnum.ILIKE,
        value: query.accountType,
      });
    }

    if (query.issuer) {
      comparisons.push({
        field: 'issuers.id',
        op: ApiComparisonOptionEnum.EQ,
        value: parseInt(query.issuer),
      });
    }

    if (query.account) {
      comparisons.push({
        comparison_type: ApiComparisonTypeEnum.OR,
        comparisons: [
          {
            field: 'accounts.name',
            op: ApiComparisonOptionEnum.ILIKE,
            value: query.account,
          },
          {
            field: 'accounts.account_identifier',
            op: ApiComparisonOptionEnum.ILIKE,
            value: query.account,
          },
        ],
      });
    }

    if (query.repCode) {
      comparisons.push({
        field: 'advisees.name',
        op: ApiComparisonOptionEnum.ILIKE,
        value: query.repCode,
      });
    }

    if (query.orderNumber) {
      comparisons.push({
        field: 'exec_order.id',
        op: ApiComparisonOptionEnum.EQ,
        value: query.orderNumber,
      });
    }

    if (query.expirationStartDate && query.expirationEndDate) {
      comparisons.push({
        field: 'calendar_notes.expiration_date::date',
        op: ApiComparisonOptionEnum.GTE,
        value: query.expirationStartDate.toISO(),
      });
      comparisons.push({
        field: 'calendar_notes.expiration_date::date',
        op: ApiComparisonOptionEnum.LTE,
        value: query.expirationEndDate.toISO(),
      });
    }

    return comparisons;
  },
  toApiCalendarOrderComparisonList: (query: ExecutionHubQuery): Array<ApiComparisonModel> => {
    const statusComparison = ExecutionHubMapper.toApiStatusComparisonList(query.status);
    const comparisons = [...statusComparison, ...ExecutionHubMapper.toApiFiltersComparisonList(query)];

    if (query.calendar) {
      comparisons.push({ field: 'calendar_notes.id', op: ApiComparisonOptionEnum.EQ, value: query.calendar.id });
    }

    return comparisons;
  },
  toApiCalendarComparisonList: (query: ExecutionHubQuery): Array<ApiComparisonModel> => {
    if (query.calendar) {
      return [{ field: 'calendar_notes.id', op: ApiComparisonOptionEnum.EQ, value: query.calendar.id }];
    }

    return ExecutionHubMapper.toApiCalendarOrderComparisonList(query);
  },
};
