import { DocumentUserFile } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { deleteUserFile } from '@halo-data-sources/clients';
import { UserQueryKeyFactory } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

const removeUserFileMutationFn = async (payload?: DocumentUserFile) => {
  if (!payload) return;

  await deleteUserFile(payload.id);
};

export const useRemoveUserFileMutation = (): UseMutationResult<void, Error, DocumentUserFile> => {
  const queryClient = useQueryClient();
  const { enqueueSuccessEvent, enqueueErrorEvent } = useSnackbar();

  return useMutation<void, Error, DocumentUserFile>({
    mutationFn: removeUserFileMutationFn,
    onSuccess: (_, payload) => {
      if (!payload) return undefined;

      enqueueSuccessEvent({ message: translations.profile.documents.removeSuccess });

      const key = UserQueryKeyFactory.files();
      queryClient.setQueryData<Array<DocumentUserFile>>(key, (prev) => {
        if (!prev) return undefined;
        const files = [...prev];

        const fileIndex = files.findIndex((file) => file.id === payload.id);
        if (fileIndex > -1) files.splice(fileIndex, 1);

        return files;
      });
    },
    onError: () => {
      enqueueErrorEvent({ message: translations.profile.documents.removeError });
    },
  });
};
