import { NoteTypeEnum, UserRoleEnum } from '@halo-common/enums';
import { getTermsheetExistence } from '@halo-data-sources/clients';
import { PDMKeyFactory, usePortfolioOrderQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getIsNoteMigratedQueryFn = async (id?: number, noteType?: NoteTypeEnum, isBetaTester = false) => {
  if (!id) return false;

  const isGeneralNote = noteType === NoteTypeEnum.General;
  const searchNME = isBetaTester || isGeneralNote;

  if (searchNME) {
    const validationResponse = await getTermsheetExistence([id]);
    return Array.isArray(validationResponse) && validationResponse[0].status === 'PUBLIC';
  }

  return false;
};

export const useIsNoteMigratedQuery = (id?: number): UseQueryResult<boolean, Error> => {
  const { data: user } = useUserInfoQuery();
  const { data: order } = usePortfolioOrderQuery(id);

  const isBetaTester = user?.details?.roles?.includes(UserRoleEnum.BetaTester);

  const noteId = order?.note.id;
  const noteType = order?.note.type;

  return useQuery<boolean, Error>({
    queryKey: PDMKeyFactory.isMigrated(id, noteId),
    queryFn: () => getIsNoteMigratedQueryFn(id, noteType, isBetaTester),
    retry: false,
    enabled: Boolean(order),
  });
};
