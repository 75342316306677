import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { BrandIcon, Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { useRouter } from 'next/router';

import { EmptyResultsCard } from '../EmptyResultsCard';

export const DefaultErrorPage = (): ReactElement => {
  const router = useRouter();

  const onButtonClick = () => router.reload();

  return (
    <EmptyResultsCard
      brandIcon={<BrandIcon variant="maintenance" color="primary" />}
      title="Pardon our error."
      description="Something went wrong when trying to build this page. Please wait a moment and then try again."
      action={
        <LocalizedButton
          variant="contained"
          startIcon={<Iconography iconName="arrows-rotate" color="inherit" />}
          onClick={onButtonClick}
        >
          {translations.common.refresh}
        </LocalizedButton>
      }
    />
  );
};
