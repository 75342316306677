import { ReactElement } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { Chip, SxProps } from '@mui/material';
import { useAtom } from 'jotai';

export type CalendarIssuerChipsProps = {
  tag: string;
  sx: SxProps;
};

export const CalendarIssuerChips = ({ tag, sx }: CalendarIssuerChipsProps): ReactElement => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);

  const handleDynamicIssuersChipDelete = (value: string) => {
    const updatedIssuers = sideBarFilters.issuers.filter((issuer) => issuer.id !== value);
    setSideBarFilters({ tag, issuers: updatedIssuers });
  };

  const chips = sideBarFilters.issuers.map((issuer) => (
    <Chip
      sx={sx}
      size="small"
      variant="outlined"
      key={issuer.id}
      label={issuer.name}
      onDelete={() => handleDynamicIssuersChipDelete(issuer.id)}
    />
  ));

  return <>{chips}</>;
};
