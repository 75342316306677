export const GENERATE_REPORT_TRANSLATIONS = {
  downloadErrorAlert: 'An error occurred when generating this report. Please try again momentarily.',
  downloadErrorMessage: 'There was an issue generating your portfolio historical report at this time.',
  downloadPendingMessage: 'Generating portfolio historical report...',
  downloadSuccessMessage: 'Successfully generated the portfolio historical report.',
  subtitle: `Receive a PDF that outlines all the positions within a client's account with summaries and individual position details.`,
  selectAccountOrHousehold: 'Select Account or Household',
  reportingCurrency: 'Reporting Currency',
  accountOptionRequired: 'Account or Household name is required.',
  currencyIdRequired: 'You must select a currency.',
};
