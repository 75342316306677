import { YEAR_MONTH_DAY_DATE_FORMAT } from '@halo-common/constants';
import { TradingDaysModel } from '@halo-common/models';
import { ApiTradingDaysRequestModel, ApiTradingDaysResponseModel } from '@halo-data-sources/models';
import { TradingDaysQueryPayload } from '@halo-data-sources/queries/tradingDays';

export interface ApiTradingDaysMapperModel {
  toTradingDaysModel: (model: ApiTradingDaysResponseModel) => TradingDaysModel;
}

export const ApiTradingDaysMapper: ApiTradingDaysMapperModel = {
  toTradingDaysModel(model) {
    return { nonTradingDays: model.non_trading_days, tradingDays: model.trading_days };
  },
};

export interface TradingDaysMapperModel {
  toApiTradingDaysRequestModel: (model: TradingDaysQueryPayload) => ApiTradingDaysRequestModel;
}

export const TradingDaysMapper: TradingDaysMapperModel = {
  toApiTradingDaysRequestModel(model) {
    return {
      end_date: model.endDate.toFormat(YEAR_MONTH_DAY_DATE_FORMAT),
      start_date: model.startDate.toFormat(YEAR_MONTH_DAY_DATE_FORMAT),
    };
  },
};
