import { ReactElement } from 'react';

import {
  AuctionIssuerField,
  AuctionNoteTypeField,
  AuctionStatusField,
  TimeFrameField,
  UnderlyingField,
} from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';

export const OrderBookAuctionFilters = (): ReactElement => (
  <Stack direction="column" spacing={2}>
    <Stack direction="row" spacing={1} xs={3} wrap="wrap">
      <UnderlyingField />
      <AuctionNoteTypeField />
      <AuctionIssuerField />
      <AuctionStatusField />
      <TimeFrameField />
    </Stack>
  </Stack>
);
