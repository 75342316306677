import { DEFAULT_INFINITE_QUERY_NEXT_PAGINATION } from '@halo-common/constants';
import { InfinitePaginationModel, NextInfiniteQueryParamModel, PaginationModel } from '@halo-common/models';
import { ApiPaginationModel } from '@halo-data-sources/models';

export interface PaginationMapper {
  toPaginationModel(model: ApiPaginationModel): PaginationModel;
  toInfinitePaginationModel(model: ApiPaginationModel): InfinitePaginationModel;
  toNextInfiniteQueryPaginationModel(model?: NextInfiniteQueryParamModel): InfinitePaginationModel | undefined;
}

export const PaginationMapper: PaginationMapper = {
  toPaginationModel: (model) => {
    return {
      totalResults: model.items_total_count,
      resultsPerPage: model.page_length,
      page: model.page,
      totalPages: model.page_count,
    };
  },
  toInfinitePaginationModel: (model) => {
    return {
      totalResults: model.items_total_count,
      resultsPerPage: model.page_length,
      page: model.page,
      next: model.page + 1,
      totalPages: model.page_count,
    };
  },
  toNextInfiniteQueryPaginationModel: (model) => {
    if (!model) return DEFAULT_INFINITE_QUERY_NEXT_PAGINATION;

    const { pagination } = model;
    const hasNextPage = pagination.next <= pagination.totalPages;

    if (hasNextPage) return pagination;
    else return undefined;
  },
};
