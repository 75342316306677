import { ReactElement, useEffect } from 'react';

import { adminAuctionUpdateFormDatesAtom } from '@halo-atoms/auctions';
import {
  ExecutionAuctionReviewDetailModalFooter,
  ExecutionAuctionReviewDetailModalHeader,
  ExecutionAuctionReviewDetailModalPanel,
  ExecutionAuctionReviewDetailsTab,
  ExecutionAuctionReviewEditTab,
  ExecutionAuctionReviewHistoryTab,
  ExecutionAuctionReviewUpdateFields,
  ProductDetailModal,
} from '@halo-common/modals';
import { AuctionAdminModel } from '@halo-common/models';
import { useOrderHubEditMode } from '@halo-modules/admin';
import { useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import { FormProvider, useForm } from 'react-hook-form';

const DOCUMENTS_TAB = 'Documents';
const AUCTION_HISTORY_TAB = 'Auction History';
const AUCTION_DETAILS_TAB = 'Auction Details';
const EDIT_AUCTION_TAB = 'Edit';

export type ExecutionAuctionReviewDetailModalProps = {
  open: boolean;
  onClose: () => void;
  product?: AuctionAdminModel | null;
};

export const ExecutionAuctionReviewDetailModal = ({
  onClose,
  open,
  product,
}: ExecutionAuctionReviewDetailModalProps): ReactElement => {
  const setAdminAuctionUpdateFormData = useSetAtom(adminAuctionUpdateFormDatesAtom);

  const formMethods = useForm<ExecutionAuctionReviewUpdateFields>({ mode: 'onChange' });

  const loading = !product;
  const isEditModeTurnedOn = useOrderHubEditMode();
  const editable = isEditModeTurnedOn && !loading;

  const tabs = [AUCTION_DETAILS_TAB, AUCTION_HISTORY_TAB, DOCUMENTS_TAB];
  if (isEditModeTurnedOn) tabs.unshift(EDIT_AUCTION_TAB);

  // const tabs = [ANALYTICS_TAB];
  // if (!isDesktopView) tabs.push(DETAILS_TAB);
  // if (showCouponTab) tabs.push(COUPON_SUMMARY_TAB);
  // if (showAutocallTab) tabs.push(AUTOCALL_SUMMARY_TAB);
  // tabs.push(DOCUMENTS_TAB);

  // const trackTab = (value: number) => {
  //   dispatch(
  //     UserActions.trackUserActivity({
  //       verb: `clicked ${tabs[value]} Tab`,
  //       name: 'NoteOrder',
  //       id: Number(referenceId),
  //     }),
  //   );
  // };

  const slots = {
    header: <ExecutionAuctionReviewDetailModalHeader product={product} loading={loading} onClose={onClose} />,
    panel: <ExecutionAuctionReviewDetailModalPanel product={product?.note} loading={loading} />,
    footer: editable ? <ExecutionAuctionReviewDetailModalFooter product={product} loading={loading} /> : null,
  };

  const auctionNoteDetailsEditTab = isEditModeTurnedOn ? (
    <ExecutionAuctionReviewEditTab product={product} loading={loading} />
  ) : null;

  useEffect(() => {
    const targetSolvableParameter = product?.targetSolvableParameterValue?.toFixed(2) ?? undefined;
    const isTopOfBook = product?.isTopOfBook ? 'yes' : 'no';

    formMethods.setValue('noteId', product?.noteId?.toString());
    formMethods.setValue('targetSolvableParameter', targetSolvableParameter);
    formMethods.setValue('price', product?.price?.toString());
    formMethods.setValue('style', product?.style);
    formMethods.setValue('strikeStyle', product?.strikeStyle);
    formMethods.setValue('offers', product?.issuers);
    formMethods.setValue('additionalDetails', product?.details);
    formMethods.setValue('notional', product?.notional?.toString());
    formMethods.setValue('isTopOfBook', isTopOfBook);

    setAdminAuctionUpdateFormData((prev) => ({
      endDate: product?.endDateTime ? DateTime.fromISO(product.endDateTime) : prev.endDate,
      strikeDate: product?.targetStrikeDate ? DateTime.fromISO(product.targetStrikeDate) : prev.strikeDate,
      tradeDate: product?.targetTradeDate ? DateTime.fromISO(product.targetTradeDate) : prev.tradeDate,
      settleDate: product?.targetSettleDate ? DateTime.fromISO(product.targetSettleDate) : prev.settleDate,
    }));
  }, [product]);

  return (
    <FormProvider {...formMethods}>
      <ProductDetailModal open={open} onClose={onClose} tabs={tabs} slots={slots} className="wm-pdm-execution">
        {auctionNoteDetailsEditTab}
        <ExecutionAuctionReviewDetailsTab product={product} />
        <ExecutionAuctionReviewHistoryTab history={product?.history} />
      </ProductDetailModal>
    </FormProvider>
  );
};
