import {
  getFixOrderAndAllocation,
  getFixOrders,
  postFixOrderBulkFill,
  postFixOrderFilled,
  putFixOrderCancellation,
} from '@halo-data-sources/clients';
import {
  ApiAllocationMapper,
  mapApiFixOrderModelToFixOrderModel,
  updateCalendarAllocations,
} from '@halo-data-sources/mappers';
import { ApiBulkFillFailureResponseModel } from '@halo-data-sources/models';
import { AdminCalendarAllocationFilledStatusEnum } from '@halo-modules/admin';
import { createThunk } from '@halo-stores/createThunk';
import {
  BulkFillFixOrderThunkAction,
  BulkFillFixOrderThunkResult,
  FetchFixOrderAndAllocationThunkResult,
  FetchFixOrdersThunkResult,
  FillFixOrderThunkAction,
  FixOrderThunkResult,
} from '@halo-stores/Fix';

export const cancelFixOrder = createThunk<FixOrderThunkResult, number>('fix/order/ws/cancel', async (allocationId) => {
  const result = await putFixOrderCancellation(allocationId);

  return { fixOrder: mapApiFixOrderModelToFixOrderModel(result.order) };
});

export const bulkFillFixOrder = createThunk<
  BulkFillFixOrderThunkResult,
  BulkFillFixOrderThunkAction,
  BulkFillFixOrderThunkResult
>(
  'fix/order/ws/fill/all',
  async ({ calendarId, allocationIds }, { getState }) => {
    if (!calendarId) throw Error('Error: Could not bulk fill orders because no calendar was selected.');

    const results = await postFixOrderBulkFill(calendarId, allocationIds);

    const { Pershing } = getState();
    const { calendars } = Pershing;

    const failureResults = results.filter((result) => {
      const status = result.status.toLowerCase() as AdminCalendarAllocationFilledStatusEnum;
      return status === AdminCalendarAllocationFilledStatusEnum.failed;
    }) as Array<ApiBulkFillFailureResponseModel>;

    const updatedCalendars = updateCalendarAllocations(
      calendars,
      AdminCalendarAllocationFilledStatusEnum.warning,
      calendarId,
      failureResults,
    );

    return { calendars: updatedCalendars };
  },
  ({ calendarId, allocationIds }, { getState }, { message }) => {
    const { Pershing } = getState();
    const { calendars } = Pershing;

    if (!calendarId) throw Error('Error: Could not bulk fill orders because no calendar was selected.');

    const failureResults = allocationIds.map((id) => ({ id, message }));

    const updatedCalendars = updateCalendarAllocations(
      calendars,
      AdminCalendarAllocationFilledStatusEnum.warning,
      calendarId,
      failureResults,
    );

    return { calendars: updatedCalendars ?? calendars };
  },
);

export const fillFixOrder = createThunk<FixOrderThunkResult, FillFixOrderThunkAction>(
  'fix/order/ws/fill',
  async ({ allocationId, wholesalerId }) => {
    const result = await postFixOrderFilled(allocationId, wholesalerId);

    return { fixOrder: mapApiFixOrderModelToFixOrderModel(result.order) };
  },
);

export const fetchFixOrder = createThunk<FetchFixOrderAndAllocationThunkResult, number>(
  'fix/order/get',
  async (allocationId) => {
    const result = await getFixOrderAndAllocation(allocationId);

    return { fixOrder: mapApiFixOrderModelToFixOrderModel(result.order) };
  },
);

export const fetchFixOrderAndAllocation = createThunk<FetchFixOrderAndAllocationThunkResult, number>(
  'fix/order/allocation/get',
  async (allocationId) => {
    const result = await getFixOrderAndAllocation(allocationId);

    return {
      fixOrder: mapApiFixOrderModelToFixOrderModel(result.order),
      allocation: ApiAllocationMapper.toAllocationModel(result.allocation),
    };
  },
);

export const fetchFixOrders = createThunk<FetchFixOrdersThunkResult, Array<number>>(
  'fix/order/get/all',
  async (allocationIds) => {
    const result = await getFixOrders(allocationIds);

    return { orders: result.orders.map(mapApiFixOrderModelToFixOrderModel) };
  },
);
