import { Dispatch, ReactElement } from 'react';

import { DEFAULT_LIFECYCLE_FILTERS } from '@halo-atoms/portfolio';
import { PortfolioEventsListItem, PortfolioEventsLoadingSkeleton } from '@halo-common/components';
import { hash } from '@halo-common/utils';
import {
  PortfolioLifecycleEmptyMessage,
  PortfolioLifecycleWidgetTab,
  useGetEnhancedEventsList,
} from '@halo-modules/app';
import { Tabs } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { SetStateAction } from 'jotai';
import { DateTime } from 'luxon';

import { PortfolioLifecycleViewMoreButton } from './PortfolioLifecycleViewMoreButton';

const limit = 4;

export type PortfolioLifecycleEventsListProps = {
  tabs: PortfolioLifecycleWidgetTab[];
  tabIndex: number;
  setTabIndex: Dispatch<SetStateAction<number>>;
  loading?: boolean;
};

export const PortfolioLifecycleEventsList = ({
  tabs,
  tabIndex,
  setTabIndex,
  loading,
}: PortfolioLifecycleEventsListProps): ReactElement => {
  const tab = tabs[tabIndex] || tabs[0];

  const {
    events,
    reinvestments,
    filterTypes,
    isPending: loadingEvents,
  } = useGetEnhancedEventsList({
    synced: null,
    ...DEFAULT_LIFECYCLE_FILTERS,
    startDate: DateTime.now().toFormat('yyyy-LL-dd'),
    endDate: DateTime.now().plus({ years: 5 }).toFormat('yyyy-LL-dd'),
    pageLength: limit,
    ...tab.filters,
  });

  const isLoading = loading || loadingEvents;
  const hasMore = events.pages[0]?.pagination?.totalResults > limit;

  const enhancedEvents = events.pages
    .flatMap((page) =>
      page.events.map((event, index) => (
        <PortfolioEventsListItem
          key={hash(event)}
          event={event}
          reinvestment={reinvestments[index]}
          filterTypes={filterTypes}
          variant="non-expandable"
          actionButtonClassName="wm-dashboard-lifecycle-viewdetails"
        />
      )),
    )
    .slice(0, limit);

  const viewMoreButton = hasMore ? <PortfolioLifecycleViewMoreButton filters={tab.filters} /> : null;

  let content = (
    <Stack direction="column">
      {enhancedEvents}
      {viewMoreButton}
    </Stack>
  );

  if (isLoading) content = <PortfolioEventsLoadingSkeleton items={limit} />;
  else if (enhancedEvents.length === 0) content = <PortfolioLifecycleEmptyMessage tab={tab} />;

  return (
    <Stack direction="column" spacing={2}>
      <Tabs tabs={tabs} variant="pills" value={tabIndex} onChange={setTabIndex} />
      {content}
    </Stack>
  );
};
