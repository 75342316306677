import { ChangeEvent, ReactElement } from 'react';

import { detailsAtom } from '@halo-atoms/auctions';
import { TextField, Typography } from '@mui/material';
import { useAtom } from 'jotai';

const textFieldSx = {
  mt: 2,
};

export const AuctionDetailsInput = (): ReactElement => {
  const [details, setDetails] = useAtom(detailsAtom);

  const detailsValue = details || '';

  const handleChange = (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDetails(ev.target.value);
  };

  return (
    <>
      <Typography variant="h6">What else can we do for your note auction?</Typography>
      <TextField
        sx={textFieldSx}
        value={detailsValue}
        variant="outlined"
        placeholder="Additional details"
        rows={4}
        multiline
        fullWidth
        onChange={handleChange}
      />
    </>
  );
};
