import { ReactElement, useEffect } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { ContentHider } from '@halo-common/components';
import { OrganizationCapacityEnum } from '@halo-common/enums';
import { useDebounced } from '@halo-common/hooks';
import { OrderTicketFormModelFields } from '@halo-common/modals';
import { useCalendarQuery } from '@halo-data-sources/queries';
import { Collapsible, CurrencyInput, Stack } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';
import { Controller, useFormContext } from 'react-hook-form';

import { IpField } from './ipField';

export type CompensationSectionProps = {
  bulk?: boolean;
};

export const CompensationSection = ({ bulk = false }: CompensationSectionProps): ReactElement => {
  const selectedCalendar = useAtomValue(calendarSelectedAtom);
  const { data: calendar } = useCalendarQuery(selectedCalendar?.id);

  const { control, formState, watch, trigger, setValue } = useFormContext<OrderTicketFormModelFields>();
  const { errors } = formState;

  const [allocations, singleQuantity, capacityType, ip1, ip2, ip1Percent, ip2Percent] = watch([
    'allocations',
    'quantity',
    'capacityType',
    'ip1',
    'ip2',
    'ip1Percent',
    'ip2Percent',
  ]);

  const quantity = bulk
    ? allocations.reduce((prev, field) => {
        const qty = field.quantity && Number(field.quantity);
        if (!qty) return prev;
        return prev + qty;
      }, 0)
    : singleQuantity;

  const isCommission = capacityType && OrganizationCapacityEnum[capacityType] === OrganizationCapacityEnum.AGENCY;
  const isSalesCredit = !isCommission;

  const title = isCommission ? 'Commission' : 'Sales Credit';
  const label = isCommission ? 'Commission Value' : 'Sales Credit';
  const compensationError = errors?.compensation?.message;
  const calendarPrice = calendar?.wholesaler?.salePrice ?? calendar?.note?.price ?? 100;

  const validateFields = async (
    fieldNames?: keyof OrderTicketFormModelFields | Array<keyof OrderTicketFormModelFields>,
  ) => (fieldNames ? await trigger(fieldNames) : await trigger());

  const debouncedValidateFields = useDebounced(validateFields, 300);

  useEffect(() => {
    void debouncedValidateFields(['compensation', 'ip1', 'ip2', 'ip1Percent', 'ip2Percent']);
  }, [capacityType, ip1, ip2, ip1Percent, ip2Percent]);

  useEffect(() => {
    const isCommission = capacityType && OrganizationCapacityEnum[capacityType] === OrganizationCapacityEnum.AGENCY;
    const shouldClearCompensation = !isCommission && typeof quantity !== 'number';
    const shouldUpdateCompensation = !isCommission && typeof quantity === 'number';

    if (shouldClearCompensation) {
      setValue('compensation', '0', { shouldValidate: true, shouldDirty: true });
    } else if (shouldUpdateCompensation) {
      const salesCredit = 10 * quantity * (100 - calendarPrice);
      setValue('compensation', salesCredit.toString(), { shouldValidate: true, shouldDirty: true });
    }
  }, [quantity, capacityType, calendarPrice]);

  return (
    <ContentHider hook="react:soe:compensation-section">
      <Collapsible title={title} subtitle="optional" defaultExpanded alwaysExpanded={bulk}>
        <Stack direction="row" spacing={2} xs={12} sm={4} wrap="wrap">
          <Controller
            render={({ field: { ref, value, ...field } }) => {
              const parsedValue = isSalesCredit && value ? parseFloat(value).toFixed(2) : value;

              return (
                <CurrencyInput
                  {...field}
                  value={parsedValue}
                  inputRef={ref}
                  label={label}
                  fullWidth
                  size="large"
                  valueType="string"
                  disabled={isSalesCredit}
                  helperText={compensationError}
                  error={Boolean(compensationError)}
                />
              );
            }}
            control={control}
            name="compensation"
          />
          <IpField fieldName="ip1" />
          <IpField fieldName="ip2" />
        </Stack>
      </Collapsible>
    </ContentHider>
  );
};
