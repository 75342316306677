import { calendarManagerAtom, CalendarQueryModel } from '@halo-atoms/calendars';
import { fixedIncomeManagerAtom, FixedIncomeQueryModel } from '@halo-atoms/fixedIncome';
import {
  CALENDAR_PREFERENCE_TAG,
  DEFAULT_PREFERENCE_TAG,
  FIXED_INCOME_PREFERENCE_TAG,
  PRODUCT_APPROVAL_PREFERENCE_TAG,
} from '@halo-common/constants';
import { useHaloInfiniteQuery } from '@halo-common/hooks';
import { InfinitePaginationModel, InfiniteQueryOptions, UnderlyingModel } from '@halo-common/models';
import {
  UnderlyingQueryKeyFactory,
  useCalendarUnderlyingInfiniteQueryFn,
  useFixedIncomeUnderlyingInfiniteQueryFn,
  useUnderlyingInfiniteQueryCommonFn,
} from '@halo-data-sources/queries';
import { useAtomValue } from 'jotai';

export type GetUnderlyingInfiniteQueryDataSwitchResult = {
  underlyings: Array<UnderlyingModel>;
  pagination: InfinitePaginationModel;
};

const getUnderlyingInfiniteQueryDataSwitchFn = (
  tag: string,
  underlying: string,
  query: CalendarQueryModel | FixedIncomeQueryModel | undefined,
  pageParam: InfinitePaginationModel,
) => {
  const isCalendar = tag === CALENDAR_PREFERENCE_TAG;
  const isCalendarBased = isCalendar || tag === PRODUCT_APPROVAL_PREFERENCE_TAG;

  const approved = isCalendar ? true : undefined;

  if (!query) return useUnderlyingInfiniteQueryCommonFn(underlying, pageParam);
  else if (isCalendarBased) return useCalendarUnderlyingInfiniteQueryFn(underlying, query, pageParam, approved);
  else return useFixedIncomeUnderlyingInfiniteQueryFn(underlying, query, pageParam);
};

export const useUnderlyingInfiniteQueryDataSwitch = (
  underlying: string,
  tag = DEFAULT_PREFERENCE_TAG,
  options?: InfiniteQueryOptions<GetUnderlyingInfiniteQueryDataSwitchResult>,
) => {
  const { query: calendarQuery } = useAtomValue(calendarManagerAtom);
  const { query: fixedIncomeQuery } = useAtomValue(fixedIncomeManagerAtom);

  const query = {
    [DEFAULT_PREFERENCE_TAG]: undefined,
    [CALENDAR_PREFERENCE_TAG]: calendarQuery,
    [FIXED_INCOME_PREFERENCE_TAG]: fixedIncomeQuery,
  }[tag];

  return useHaloInfiniteQuery<GetUnderlyingInfiniteQueryDataSwitchResult>({
    queryKey: UnderlyingQueryKeyFactory.infiniteSearch(tag, underlying, query),
    queryFn: ({ pageParam }) => {
      return getUnderlyingInfiniteQueryDataSwitchFn(tag, underlying, query, pageParam);
    },
    ...options,
  });
};
