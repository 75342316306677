import { ReactElement } from 'react';

import { OrganizationOnboardingStatusEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Iconography, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Box, Stepper, Step, StepLabel, stepClasses, stepLabelClasses, StepIconProps } from '@mui/material';

import { FinishOnboardingButton } from './FinishOnboardingButton';

const suitability = translations.onboarding.suitability;

const stepperSx = {
  width: '100%',
  [`& .${stepClasses.root}`]: {
    [`& .${stepLabelClasses.disabled}`]: {
      opacity: 0.4,
    },

    [`&.${stepClasses.completed} .${stepLabelClasses.labelContainer}`]: {
      opacity: 0.4,
    },

    [`& .${stepLabelClasses.labelContainer}`]: {
      alignItems: 'center',
      display: 'flex',
      gap: 2,
      justifyContent: 'space-between',
      flexDirection: 'row',
      flex: '1 1 auto',
      [`& .${stepLabelClasses.label}`]: {
        display: 'contents',
      },
    },
  },
};

const iconSx = {
  width: 40,
  height: 40,
  borderRadius: '50%',
  border: '1px solid currentColor',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: '0 0 auto',
  color: 'primary.main',
  '&[data-completed="true"]': {
    backgroundColor: 'primary.background',
    borderColor: 'primary.background',
  },
};

const StepIcon = ({ active, completed, icon, className }: StepIconProps) => (
  <Box className={className} data-active={active} data-completed={completed} sx={iconSx}>
    {completed ? <Iconography iconName="check" color="inherit" /> : icon}
  </Box>
);

export const OnboardingSteps = (): ReactElement | null => {
  const { data: userInfo } = useUserInfoQuery();

  const organization = userInfo?.details?.organization;

  if (!organization) return null;

  const { name, onboardingStatus } = organization;

  const activeStep = [
    OrganizationOnboardingStatusEnum.FORM_NOT_COMPLETED,
    OrganizationOnboardingStatusEnum.DOCS_NOT_SIGNED,
    OrganizationOnboardingStatusEnum.UNDER_HALO_REVIEW,
  ].findIndex((status) => status === onboardingStatus);

  const finishSuitabilityButton =
    onboardingStatus === OrganizationOnboardingStatusEnum.FORM_NOT_COMPLETED ? (
      <FinishOnboardingButton
        variant="contained"
        endIcon={<Iconography iconName="arrow-right" color="inherit" />}
        size="small"
      >
        {translations.common.finishNow}
      </FinishOnboardingButton>
    ) : null;

  return (
    <Stepper activeStep={activeStep} orientation="vertical" sx={stepperSx} connector={null}>
      <Step>
        <StepLabel StepIconComponent={StepIcon}>
          <LocalizedTypography variant="subtitle1">{suitability.completeFirmSuitability}</LocalizedTypography>
          {finishSuitabilityButton}
        </StepLabel>
      </Step>

      <Step>
        <StepLabel StepIconComponent={StepIcon}>
          <LocalizedTypography variant="subtitle1" dynamicContent={{ firm: name }}>
            {suitability.authorizedSignerReviewsAndSignsPaperwork}
          </LocalizedTypography>
        </StepLabel>
      </Step>

      <Step>
        <StepLabel StepIconComponent={StepIcon}>
          <LocalizedTypography variant="subtitle1">{suitability.haloReviewsPaperwork}</LocalizedTypography>
        </StepLabel>
      </Step>
    </Stepper>
  );
};
