import { ReactElement } from 'react';

import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';

const boxStyling = {
  background: 'background.paper',
  padding: 5,
  marginTop: 1,
  borderRadius: 1,
  '& > div': {
    maxWidth: 350,
  },
};
const subtitleStyling = {
  color: 'grey.600',
  marginTop: 1,
};

export type EmptyBoxProps = {
  title: string;
  subtitle: string;
};

export const EmptyBox = ({ title, subtitle: subTitle }: EmptyBoxProps): ReactElement => (
  <Stack direction="column" alignItems="center" justify="center" sx={boxStyling}>
    <LocalizedTypography variant="h4">{title}</LocalizedTypography>
    <LocalizedTypography variant="body1" sx={subtitleStyling}>
      {subTitle}
    </LocalizedTypography>
  </Stack>
);
