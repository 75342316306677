import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { useUserInfoQuery } from '@halo-data-sources/queries';

export const useAuctionDateFormat = (): string => {
  const { data: userInfo } = useUserInfoQuery();

  const orgDateFormat = userInfo?.organization?.dateFormat;
  const dateFormat = orgDateFormat ?? MONTH_DAY_YEAR_DATE_FORMAT;

  return dateFormat;
};
