import { ReactElement } from 'react';

import { FindPriceButton } from '@halo-common/components';
import { useQuoteSelector } from '@halo-common/hooks';
import { CalendarModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';

export type ExecutionCalendarDetailModalHeaderSolvableParameterFindPriceButtonProps = {
  product?: CalendarModel['note'] | null;
  label?: string;
  disableQuoteRefresh?: boolean;
};

export const ExecutionCalendarDetailModalHeaderSolvableParameterFindPriceButton = ({
  label,
  product,
  disableQuoteRefresh = false,
}: ExecutionCalendarDetailModalHeaderSolvableParameterFindPriceButtonProps): ReactElement | null => {
  const productId = product?.id;

  const quote = useQuoteSelector(productId);

  if (disableQuoteRefresh) return null;

  if (!quote) return <FindPriceButton productId={productId} label={label} />;

  return (
    <FindPriceButton productId={productId} variant="icon">
      {translations.components.findPriceButtonRefreshText}
    </FindPriceButton>
  );
};
