export enum AssetIdentifierEnum {
  NONE = 'none',
  CUSIP = 'cusip',
  ISIN = 'isin',
  FUNDSERVCODE = 'fundserv',
}

export enum AssetIdStatusEnum {
  pending = 'pending',
  resolved = 'resolved',
  unresolved = 'unresolved',
}

export enum AssetIdLocationEnum {
  halo = 'Halo',
  edgar = 'EDGAR',
}
