import { Key, ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { DocumentsTableRow, DocumentsTableRowMapFunction, DocumentsTableInputModel } from '@halo-modules/app';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';

const rowStyling = { pt: 2, pb: 0, px: 3 };

export type DocumentsTableProps<T> = {
  models: Array<T>;
  mapValues: DocumentsTableRowMapFunction<T>;
  getId: (model: T) => Key;
  onDownload?: (model: T) => void;
  onUpload?: (model: T) => void;
  onRemove?: (model: T) => void;
};

export const DocumentsTable = <T extends DocumentsTableInputModel>({
  models,
  mapValues,
  getId,
  onDownload,
  onUpload,
  onRemove,
}: DocumentsTableProps<T>): ReactElement => (
  <Stack direction="column" spacing={2}>
    <Stack
      direction="row"
      alignItems="center"
      justify="space-between"
      xs={[3, 4, 2, 3]}
      md={[4, 4, 2, 2]}
      sx={rowStyling}
      justifyElements={[undefined, undefined, undefined, 'flex-end']}
    >
      <LocalizedTypography variant="subtitle1">{translations.common.name}</LocalizedTypography>
      <LocalizedTypography variant="subtitle1">{translations.common.description}</LocalizedTypography>
      <LocalizedTypography variant="subtitle1">{translations.profile.documents.fileType}</LocalizedTypography>
      <LocalizedTypography variant="subtitle1" align="right">
        {translations.common.actions}
      </LocalizedTypography>
    </Stack>
    <Stack direction="column">
      {models.map((model) => (
        <DocumentsTableRow
          key={getId(model)}
          mapValues={mapValues}
          model={model}
          onDownload={onDownload}
          onUpload={onUpload}
          onRemove={onRemove}
        />
      ))}
    </Stack>
  </Stack>
);
