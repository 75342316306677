import { UserRoleEnum } from '@halo-common/enums';
import { CalendarModel, ExecutionOrderModel, OrderTicketReceiptModel } from '@halo-common/models';
import { BulkOrderTicketFormFields, SingleOrderTicketFormFields } from '@halo-common/schemas';
import { BulkAllocationError } from '@halo-data-sources/errors';
import { CalendarsQueryKeyFactory, useUserInfoQuery } from '@halo-data-sources/queries';
import { UseMutationOptions, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

import { janneyOrderTicketSubmissionSwitchFn } from './janneyOrderTicketSubmissionSwitchFn';
import { pershingOrderTicketBulkSubmissionSwitchFn } from './pershingOrderTicketBulkSubmissionSwitchFn';
import { useOrderTicketSubmissionSwitchHandlers } from './useOrderTicketSubmissionSwitchHandlers';

export type OrderTicketSubmissionSwitchPayload = {
  calendar: CalendarModel | null;
  order?: ExecutionOrderModel;
  singleData?: SingleOrderTicketFormFields;
  bulkData?: BulkOrderTicketFormFields;
};

export type OrderTicketSubmissionSwitchErrors = {
  [key: string]: string | undefined;
};

const orderTicketSubmissionSwitchFn = async (
  payload: OrderTicketSubmissionSwitchPayload,
  roles?: Array<UserRoleEnum>,
): Promise<OrderTicketReceiptModel | null> => {
  const { calendar, singleData, order, bulkData } = payload;

  if (!calendar) return null;

  const isPershing = roles?.includes(UserRoleEnum.PershingOrderSender);

  if (isPershing) return pershingOrderTicketBulkSubmissionSwitchFn(calendar, bulkData);
  return janneyOrderTicketSubmissionSwitchFn(calendar, order, singleData);
};

export const useOrderTicketSubmissionSwitch = (
  options?: UseMutationOptions<OrderTicketReceiptModel | null, BulkAllocationError, OrderTicketSubmissionSwitchPayload>,
): UseMutationResult<OrderTicketReceiptModel | null, BulkAllocationError, OrderTicketSubmissionSwitchPayload> => {
  const queryClient = useQueryClient();

  const { data: user } = useUserInfoQuery();

  const roles = user?.details.roles;

  const { onSuccess, onMutate, onError } = useOrderTicketSubmissionSwitchHandlers();

  return useMutation<OrderTicketReceiptModel | null, BulkAllocationError, OrderTicketSubmissionSwitchPayload>({
    mutationFn: (payload) => orderTicketSubmissionSwitchFn(payload, roles),
    onMutate: (...args) => {
      options?.onMutate?.(...args);
      onMutate?.(...args);
    },
    onError: (...args) => {
      options?.onError?.(...args);
      onError?.(...args);
    },
    onSuccess: (...args) => {
      const calendarId = args[1]?.calendar?.id;
      if (calendarId) void queryClient.refetchQueries({ queryKey: CalendarsQueryKeyFactory.allocations(calendarId) });
      options?.onSuccess?.(...args);
      onSuccess?.(...args);
    },
  });
};
