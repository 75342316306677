import { Input } from '@halo-common/components/FormBuilder';
import { translations } from '@halo-common/translations';
import { OnboardingSuitabilityForm, SuitabilityStepModel } from '@halo-modules/app/v2/onboarding';
import { Stack } from '@mui/material';

const suitability = translations.onboarding.suitability;

const PaperStatemenetsForm = () => (
  <OnboardingSuitabilityForm>
    <Stack spacing={1}>
      <Input
        type="checkbox"
        label={suitability.disablePaperStatements}
        name="trading_details.disable_paper_statements_checkbox"
      />
      <Input
        type="checkbox"
        label={suitability.disablePaperConfirmations}
        name="trading_details.disable_paper_confirmations_checkbox"
      />
    </Stack>
  </OnboardingSuitabilityForm>
);

export const optOutOfPaperStatementsStep: SuitabilityStepModel = {
  id: '3-4',
  title: suitability.optOutOfPaperStatements,
  component: <PaperStatemenetsForm />,
};
