import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { DASHBOARD_PREFERENCE_TAG } from '@halo-common/constants';
import { WidgetEnginesEnum } from '@halo-common/enums';
import type { DashboardWidgetModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useUpdateUserPreferencesMutation } from '@halo-data-sources/mutations';
import { useNoteflixCategoriesQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { NoteflixDetails, NoteflixSkeleton, Widget } from '@halo-modules/app';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';

const engine = WidgetEnginesEnum.NOTEFLIX;

const title = translations.common.noteflix;
const description = translations.dashboard.noteflix.listOfNotesForSelectedCategory;

const controlSx = {
  minWidth: 180,
  maxWidth: 360,
};

const itemSx = {
  minWidth: 180,
  maxWidth: 360,
};

const labelSx = {
  flex: '1 1 auto',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const Noteflix = (): ReactElement => {
  const { data } = useNoteflixCategoriesQuery();
  const { isPending, data: userInfo } = useUserInfoQuery();
  const { mutate: updatePreferences } = useUpdateUserPreferencesMutation();

  const { preferences } = userInfo || {};
  const defaultNoteflixId = preferences?.dashboard_preferences?.noteflixId ?? null;

  const [noteflixId, setNoteflixId] = useState<number | null>(defaultNoteflixId);

  const categories = useMemo(() => data?.pages?.flatMap((page) => page?.categories ?? []) ?? [], [data]);
  const activeCategory = categories.find((category) => category.id === noteflixId);

  const onNoteflixChange = useCallback(
    (e: SelectChangeEvent<number | null>) => {
      const id = e.target.value as number;
      setNoteflixId(id);
      updatePreferences({
        tag: DASHBOARD_PREFERENCE_TAG,
        content: {
          ...(preferences?.dashboard_preferences ?? {}),
          noteflixId: id,
        },
      });
    },
    [updatePreferences, preferences],
  );

  useEffect(() => {
    const hasData = !isPending && categories && categories.length > 0;
    const shouldSetNoteflixId = !noteflixId || !categories.some((category) => category.id === noteflixId);
    if (hasData && shouldSetNoteflixId) setNoteflixId(categories[0].id || null);
  }, [noteflixId, isPending, categories]);

  const body = activeCategory ? (
    <NoteflixDetails
      id={activeCategory.id}
      notes={activeCategory.members}
      notesFrom={activeCategory.notesFrom}
      enableCategoryQuotes={activeCategory.enableCategoryQuotes}
      key={activeCategory.id}
    />
  ) : (
    <NoteflixSkeleton />
  );

  return (
    <Widget
      id={engine}
      title={title}
      filters={
        <FormControl variant="standard" sx={controlSx}>
          <Select
            value={noteflixId}
            fullWidth
            size="large"
            onChange={onNoteflixChange}
            className="wm-dashboard-noteflix-selectcategory"
          >
            {categories.map((category) => (
              <MenuItem value={category.id} sx={itemSx} key={category.id}>
                <Box sx={labelSx}>{category.name}</Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
      className="wm-dashboard-noteflix"
      navigateButtonHref="/noteflix"
      navigateButtonLabel={translations.common.noteflix}
      navigateButtonClassName="wm-dashboard-noteflix-viewall"
    >
      {body}
    </Widget>
  );
};

const widget: DashboardWidgetModel = {
  engine,
  title,
  description,
  component: Noteflix,
};

export default widget;
