import { NoteParameterEnum } from '@halo-common/enums';
import { NoteModel, QuoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { getHistoricalQuotes, getMostRecentQuotes, getWatchlistTargetedNotes } from '@halo-data-sources/clients';
import { PDMKeyFactory } from '@halo-data-sources/queries';
import { LineChartDataPoint, LineChartLineDefinition, LineChartReferenceLine } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { useTheme } from '@mui/material';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

const QUOTE_HISTORY_LINE_NAME = 'Quote History';

export type QuoteHistoryQueryPayload = {
  isTargeted?: boolean;
  refColor?: string;
  isCommission?: boolean;
};

export type QuoteHistoryQueryResult = {
  chart: {
    lines: Array<LineChartLineDefinition>;
    data: Array<LineChartDataPoint>;
    references?: Array<LineChartReferenceLine>;
  };
  table: {
    data: Array<Partial<QuoteModel>>;
  };
};

const getQuoteHistoryQueryFn = async (id?: number, payload?: QuoteHistoryQueryPayload) => {
  if (!id) return null;

  const chartResponse = await getHistoricalQuotes(id);

  const lines: Array<LineChartLineDefinition> = [{ label: QUOTE_HISTORY_LINE_NAME }];
  const chartData: Array<LineChartDataPoint> = chartResponse.quotes
    .sort((a, b) => {
      const firstDate = DateTime.fromISO(a.date).toSeconds();
      const secondDate = DateTime.fromISO(b.date).toSeconds();
      return firstDate < secondDate ? -1 : firstDate > secondDate ? 1 : 0;
    })
    .map((quote) => ({
      x: DateTime.fromISO(quote.date).toSeconds(),
      [QUOTE_HISTORY_LINE_NAME]: payload?.isCommission ? 100 - quote.value : quote.value,
    }));

  const references: Array<LineChartReferenceLine> = [];

  if (payload?.isTargeted) {
    const response = await getWatchlistTargetedNotes();

    const target = response.targets.find((target) => target.note.id === id);

    references.push({
      point: target?.target_level,
      label: translations.common.target,
      color: payload?.refColor,
      type: 'dash',
      orientation: 'y',
    });
  }

  const tableResponse = await getMostRecentQuotes([id]);
  const quoteInfo = tableResponse.data[0];

  const tableData = quoteInfo.quotes
    .sort((a, b) => {
      const firstDate = DateTime.fromISO(a.received_at).toSeconds();
      const secondDate = DateTime.fromISO(b.received_at).toSeconds();
      return firstDate > secondDate ? -1 : firstDate < secondDate ? 1 : 0;
    })
    .map((quote) => ({
      id: quote.note_quote_id,
      issuer: quote.issuer_name,
      value: payload?.isCommission ? 100 - quote.value : quote.value,
      receivedAt: quote.received_at,
      featured: quote.note_quote_id === quoteInfo.featured?.note_quote_id,
    }));

  return {
    chart: { lines, data: chartData, references },
    table: { data: tableData },
  };
};

export const useQuoteHistoryQuery = (
  product?: NoteModel | null,
): UseQueryResult<QuoteHistoryQueryResult | null, Error> => {
  const theme = useTheme<HaloTheme>();

  const id = product?.id;
  const isTargeted = product?.meta.isTargeted;
  const isCommission = product?.parameter === NoteParameterEnum.Price;
  const refColor = theme.palette?.common.charts?.positive?.main;

  return useQuery<QuoteHistoryQueryResult | null, Error>({
    queryKey: PDMKeyFactory.quoteHistory(id),
    queryFn: () => getQuoteHistoryQueryFn(id, { isTargeted, isCommission, refColor }),
  });
};
