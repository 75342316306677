import { ReactElement, ReactNode } from 'react';

import { AnalyticsHeader, AnalyticsHeaderProps, PageFooterLayout } from '@halo-common/layouts';
import { Stack, useCombinedStyling } from '@halodomination/halo-fe-common';
import { Container, Paper, useMediaQuery, useTheme } from '@mui/material';

const paperStyling = {
  mt: 3,
  py: 6,
  px: 14,
  display: 'flex',
};

const containerStyling = {
  p: 0,
};

export type AnalyticsPageLayoutProps = {
  pageIcon: AnalyticsHeaderProps['iconName'];
  pageTitle: AnalyticsHeaderProps['title'];
  pageDescription: AnalyticsHeaderProps['description'];
  showBackButton?: AnalyticsHeaderProps['showBackButton'];
  children: ReactNode;
};

export const AnalyticsPageLayout = ({
  pageIcon,
  pageTitle,
  pageDescription,
  children,
  showBackButton = true,
}: AnalyticsPageLayoutProps): ReactElement => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const mobilePaperStyling = isMobile ? { px: 3 } : undefined;

  const combinedPaperStyling = useCombinedStyling(paperStyling, mobilePaperStyling);

  return (
    <PageFooterLayout>
      <Container sx={containerStyling} maxWidth="xl">
        <Stack direction="column">
          <AnalyticsHeader
            iconName={pageIcon}
            title={pageTitle}
            description={pageDescription}
            showBackButton={showBackButton}
          />
          <Paper sx={combinedPaperStyling} elevation={10}>
            <Stack direction="column" spacing={4}>
              {children}
            </Stack>
          </Paper>
        </Stack>
      </Container>
    </PageFooterLayout>
  );
};
