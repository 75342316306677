import { QueryKeyFactoryFn } from '@halo-data-sources/queries';
import { DateTime } from 'luxon';

type AuctionQueryKeys = {
  all: QueryKeyFactoryFn;
  adminAuction: QueryKeyFactoryFn;
  buyerAuction: QueryKeyFactoryFn;
  dateDefaults: QueryKeyFactoryFn;
};

export const AuctionQueryKeyFactory: AuctionQueryKeys = {
  all: () => ['auctions'],
  adminAuction: (id: string) => [...AuctionQueryKeyFactory.all(), 'admin', id],
  buyerAuction: (id: string) => [...AuctionQueryKeyFactory.all(), 'buyer', id],
  dateDefaults: (strikeDate?: DateTime | null) => [
    ...AuctionQueryKeyFactory.all(),
    'dateDefaults',
    strikeDate?.toISO(),
  ],
};
