import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedButton } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { AppBar, Box } from '@mui/material';

const headerSx = (theme: HaloTheme) => ({
  background: `linear-gradient(270deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
  boxShadow: 'none',
  minHeight: '64px',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  gap: '32px',

  '& > div': {
    display: 'flex',
    alignItems: 'center',
    gap: '32px',
  },

  padding: '0 32px',
});

const logoSx = { display: 'block', maxWidth: '150px' };

const logoSmallSx = { maxWidth: '95px' };

export const PageHeader = (): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();

  const brand = userInfo?.whiteLabel?.brand;
  const poweredByHalo = brand?.showPoweredByHalo;

  const brandLogo = brand?.logoLight;
  const poweredByHaloPath = '/assets/images/logo_poweredbyhalo_light.svg';

  const logo = brandLogo ? (
    <Box sx={logoSx} data-testid="brand-logo" component="img" src={brandLogo?.path} alt="Brand Logo" />
  ) : null;

  const poweredByHaloLogo = poweredByHalo ? (
    <Box sx={logoSmallSx} data-testid="power-logo" component="img" src={poweredByHaloPath} alt="Powered by Halo Logo" />
  ) : null;

  const isAuthenticated = userInfo?.authenticated;

  const redirectToLogin = () => navigateParentTo('/react/app/onboarding/login');

  const loginButton = !isAuthenticated ? (
    <LocalizedButton onClick={redirectToLogin} variant="text" color="inherit">
      {translations.onboarding.login.login}
    </LocalizedButton>
  ) : null;

  return (
    <AppBar position="sticky" sx={headerSx}>
      <div>
        {logo}
        {poweredByHaloLogo}
      </div>
      <div>{loginButton}</div>
    </AppBar>
  );
};
