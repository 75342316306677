import { PageFooterLayout, SubNav, SubNavLayout } from '@halo-common/layouts';
import { CalendarAllocationsModal, MustCompleteOnboardingModal } from '@halo-common/modals';
import { useLegacyRouter } from '@halo-common/providers';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import {
  CalendarDetailsModal,
  CalendarPdfViewerModal,
  CalendarsListPage,
  FixedIncomeAllocationsModal,
  FixedIncomeDetailsModal,
  FixedIncomeListPage,
} from '@halo-modules/app';
import { PageComponent, PageMeta } from '@halodomination/halo-fe-common';

const CALENDARS_TAB = translations.calendars.common.calendars;
const FIXED_INCOME_TAB = translations.calendars.common.fixedIncome;

const CalendarsPage: PageComponent = () => {
  const { isReady } = useLegacyRouter();
  const { data } = useUserInfoQuery();

  const calendarsTab = (
    <SubNav key="calendar-tab" name={CALENDARS_TAB} noPadding>
      {isReady ? <CalendarsListPage /> : null}
    </SubNav>
  );

  const fixedIncomeTab = (
    <SubNav key="fixed-income-tab" name={FIXED_INCOME_TAB} noPadding>
      {isReady ? <FixedIncomeListPage /> : null}
    </SubNav>
  );

  const tabs = [calendarsTab];

  const showFixedIncomeTab = data?.settings.showFixedIncomeTab;

  if (showFixedIncomeTab) tabs.push(fixedIncomeTab);

  return (
    <PageFooterLayout>
      <SubNavLayout title={translations.calendars.common.title}>{tabs}</SubNavLayout>
      <CalendarAllocationsModal />
      <CalendarPdfViewerModal />
      <CalendarDetailsModal />
      <FixedIncomeAllocationsModal />
      <FixedIncomeDetailsModal />
      <MustCompleteOnboardingModal />
    </PageFooterLayout>
  );
};

export const CalendarsPageMeta: PageMeta = {
  pageName: 'Calendars',
  route: 'app/calendar',
  routeExpression: '/calendar',
};

CalendarsPage.pageName = CalendarsPageMeta.pageName;
CalendarsPage.route = CalendarsPageMeta.route;

export default CalendarsPage;
