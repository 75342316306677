import { orderTicketCSVUploadModalAtom } from '@halo-atoms/orderTicket';
import { AccountModel } from '@halo-common/models';
import { AuctionDetailsAllocationFields, BulkOrderTicketFormFields } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { getBulkAccounts } from '@halo-data-sources/clients';
import { AccountMapper, ApiAccountMapper } from '@halo-data-sources/mappers';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';

export type accountAllocationCSVUploadModel = Array<Record<string, string>>;

const accountAllocationCSVUploadMutationFn = async (payload: accountAllocationCSVUploadModel) => {
  if (!payload) return [];

  const accountIds = payload.map((row) => row?.accountId);
  const mappedPayload = AccountMapper.toBulkAccountsRequest(accountIds);
  const result = await getBulkAccounts(mappedPayload);

  return result.accounts.map(ApiAccountMapper.toAccountModel);
};

export const useAccountAllocationCSVUploadMutation = (): UseMutationResult<
  Array<AccountModel>,
  Error,
  accountAllocationCSVUploadModel
> => {
  const { enqueueErrorEvent } = useSnackbar();

  const { trigger, setValue, setError } = useFormContext<BulkOrderTicketFormFields | AuctionDetailsAllocationFields>();

  const setCSVUploadModal = useSetAtom(orderTicketCSVUploadModalAtom);

  return useMutation<Array<AccountModel>, Error, accountAllocationCSVUploadModel>({
    mutationFn: accountAllocationCSVUploadMutationFn,
    onSuccess: (accounts, payload) => {
      const accountFields = payload.map((row) => {
        const { accountId, quantity } = row ?? {};
        const account = accounts.find((a) => a.accountId === accountId);
        return {
          accountOption: account ? { account } : null,
          quantity: Number(quantity) ?? 0,
        };
      });

      setValue('allocations', accountFields);

      void trigger('allocations').then(() => {
        accountFields.forEach((row, index) => {
          if (!row.accountOption) {
            const providedAccountId = payload[index]?.accountId;
            setError(`allocations.${index}.accountOption`, { message: `${providedAccountId} Account not found.` });
          }
        });
      });

      setCSVUploadModal(false);
    },
    onError: () => {
      enqueueErrorEvent({ message: translations.common.error });
    },
  });
};
