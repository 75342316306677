import { anchorElAtom } from '@halo-atoms/common';
import { EditPositionPopoverAnchor } from '@halo-common/modals';
import { EditPositionPayload, PortfolioTransactionModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { putPortfolioPosition } from '@halo-data-sources/clients';
import { ApiTransactionMapper } from '@halo-data-sources/mappers';
import { PDMKeyFactory } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

const editPositionMutationFn = async (payload: EditPositionPayload) => {
  const response = await putPortfolioPosition({ position_id: payload.positionId, notional: payload.notional });
  return ApiTransactionMapper.toPortfolioTransactionModel(response.position);
};

export const useEditPositionMutation = (): UseMutationResult<PortfolioTransactionModel, Error, EditPositionPayload> => {
  const queryClient = useQueryClient();
  const [anchorElMap, setAnchorElMap] = useAtom(anchorElAtom);
  const { enqueueErrorEvent, enqueueSuccessEvent } = useSnackbar();

  return useMutation<PortfolioTransactionModel, Error, EditPositionPayload>({
    mutationFn: editPositionMutationFn,
    onSuccess: (data, payload) => {
      if (!data) return;

      const termsheetId = payload.termsheetId;
      const queryKey = PDMKeyFactory.allocations({ termsheetId });
      void queryClient.invalidateQueries({ queryKey });

      setAnchorElMap({ ...anchorElMap, [EditPositionPopoverAnchor]: null });
      enqueueSuccessEvent({ message: translations.pdm.postTrade.allocationEditSuccess });
    },
    onError: () => enqueueErrorEvent({ message: translations.messages.error }),
  });
};
