import { ReactElement } from 'react';

import { LocalizedTypography, LocalizedTypographyProps, Stack, StackProps } from '@halodomination/halo-fe-common';
import { Divider, Skeleton, Typography, TypographyProps } from '@mui/material';

export type ProductDetailModalAnalyticsRollingReturnsNoteSummaryLineProps = {
  label?: string;
  value?: number;
  loading?: boolean;
  colorBasedOnValue?: boolean;
  LabelProps?: Partial<LocalizedTypographyProps>;
  ValueProps?: Partial<TypographyProps>;
  ContainerProps?: Partial<StackProps>;
};

export const ProductDetailModalAnalyticsRollingReturnsNoteSummaryLine = ({
  label,
  value,
  colorBasedOnValue = false,
  loading = false,
  LabelProps,
  ValueProps,
  ContainerProps,
}: ProductDetailModalAnalyticsRollingReturnsNoteSummaryLineProps): ReactElement | null => {
  if (typeof label !== 'string' || typeof value !== 'number') return null;

  if (loading) {
    return (
      <Stack direction="row" justify="space-between" alignItems="center">
        <Skeleton variant="rounded" width={250} height={32} />
        <Skeleton variant="rounded" width={50} height={32} />
      </Stack>
    );
  }

  const featureType = label?.toLowerCase();
  const isDigitalCouponFeature = featureType === 'digital coupon';
  const isGearingFeature = featureType === 'gearing';
  const isActualTermInMonths = featureType === 'actual note term in months';
  const isTotalCouponPaid = featureType === 'total coupon(s) paid';

  const showPercentage = !isActualTermInMonths && !isTotalCouponPaid;
  const parsedValue = value.toFixed(2);

  const isNegative = value < 0;
  const isPositive = value > 0;

  const color = isPositive ? 'common.charts.positive.main' : isNegative ? 'common.charts.negative.main' : 'grey.600';
  const textColor = colorBasedOnValue ? color : isDigitalCouponFeature ? 'common.charts.positive.main' : 'grey.600';

  const formattedPercentageValue = isDigitalCouponFeature
    ? `+${parsedValue}%`
    : isGearingFeature
      ? `x${parsedValue}%`
      : `${parsedValue}%`;

  const formattedValue = showPercentage ? formattedPercentageValue : value;

  const valueSx = { ...ValueProps?.sx, color: textColor };
  const containerSx = { ...ContainerProps?.sx, px: 1, py: 0.5, width: '100%' };

  return (
    <Stack direction="column">
      <Stack {...ContainerProps} direction="row" justify="space-between" alignItems="center" sx={containerSx}>
        <LocalizedTypography {...LabelProps}>{label}</LocalizedTypography>
        <Typography {...ValueProps} sx={valueSx}>
          {formattedValue}
        </Typography>
      </Stack>
      <Divider />
    </Stack>
  );
};
