import { UserRoleEnum } from '@halo-common/enums';
import { CalendarModel, OrderTicketReceiptModel } from '@halo-common/models';
import { BulkOrderTicketFormFields, SingleOrderTicketFormFields } from '@halo-common/schemas';
import { BulkAllocationError } from '@halo-data-sources/errors';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

import { janneyOrderTicketValidationSwitchFn } from './janneyOrderTicketValidationSwitchFn';
import { pershingOrderTicketBulkValidationSwitchFn } from './pershingOrderTicketBulkValidationSwitchFn';
import { useOrderTicketValidationSwitchHandlers } from './useOrderTicketValidationSwitchHandlers';

export type OrderTicketValidationSwitchPayload = {
  calendar: CalendarModel | null;
  singleData?: SingleOrderTicketFormFields;
  bulkData?: BulkOrderTicketFormFields;
};

export type OrderTicketValidationSwitchErrors = {
  [key: string]: string | undefined;
};

const orderTicketValidationSwitchFn = async (
  payload: OrderTicketValidationSwitchPayload,
  roles?: Array<UserRoleEnum>,
): Promise<OrderTicketReceiptModel | null> => {
  const { calendar, bulkData, singleData } = payload;

  if (!calendar) return null;

  const isPershing = roles?.includes(UserRoleEnum.PershingOrderSender);

  if (isPershing) return pershingOrderTicketBulkValidationSwitchFn(calendar, bulkData);
  return janneyOrderTicketValidationSwitchFn(calendar, singleData);
};

export const useOrderTicketValidationSwitch = (): UseMutationResult<
  OrderTicketReceiptModel | null,
  BulkAllocationError,
  OrderTicketValidationSwitchPayload
> => {
  const { data: user } = useUserInfoQuery();

  const roles = user?.details.roles;

  const { onSuccess, onMutate, onError } = useOrderTicketValidationSwitchHandlers();

  return useMutation<OrderTicketReceiptModel | null, BulkAllocationError, OrderTicketValidationSwitchPayload>({
    mutationFn: (payload) => orderTicketValidationSwitchFn(payload, roles),
    onMutate,
    onError,
    onSuccess,
  });
};
