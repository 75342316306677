import { ReactElement } from 'react';

import { IssuerModel, QuoteAuctionModel } from '@halo-common/models';
import { getIssuerCellStatusFromAuctionDetails } from '@halo-modules/admin';
import { Iconography, useCombinedStyling } from '@halodomination/halo-fe-common';
import { Skeleton, Stack, SxProps, Theme, Typography } from '@mui/material';

const clickableCellSx = {
  '&:hover': {
    backgroundColor: 'background.default',
    cursor: 'pointer',
    transition: 'background-color 150ms linear',
  },
};

const commentCellSx = {
  backgroundColor: 'primary.main',
  color: 'primary.contrastText',
  position: 'relative',
  '&:hover': {
    backgroundColor: 'primary.dark',
    cursor: 'pointer',
    transition: 'background-color 150ms linear',
  },
};

const commentIconSx = {
  alignItems: 'flex-end',
  bottom: 0,
  color: 'common.white',
  display: 'flex',
  position: 'absolute',
  right: 0,
};

const commentTextSx = {
  '-webkit-line-clamp': 3,
  '-webkit-box-orient': 'vertical',
  display: '-webkit-box',
  hyphens: 'auto',
  maxWidth: 150,
  overflow: 'hidden',
  overflowWrap: 'break-word',
  textOverflow: 'ellipsis',
  whiteSpace: 'break-spaces',
  wordBreak: 'break-word',
};

const dataSx = {
  alignItems: 'center',
  display: 'flex',
  width: '100%',
};

const emptyCellSx = {
  backgroundColor: 'grey.300',
};

const passedCellSx = {
  backgroundColor: 'grey.400',
  position: 'relative',
};

const winnerCellSx = {
  backgroundColor: 'success.main',
  color: 'success.contrastText',
  position: 'relative',
};

const cellTextSx = {
  alignItems: 'flex-end',
  bottom: 0,
  display: 'flex',
  position: 'absolute',
  right: 0,
};

export type AuctionTableIssuerCellProps = {
  issuer?: IssuerModel;
  quote?: QuoteAuctionModel;
  loading: boolean;
};

export const AuctionTableIssuerCell = ({ issuer, quote, loading }: AuctionTableIssuerCellProps): ReactElement => {
  const isIssuerParticipating = Boolean(issuer);

  const {
    passed: showPassedContent,
    winner: showWinnerContent,
    comment: showCommentContent,
    clickable: isClickableCell,
  } = getIssuerCellStatusFromAuctionDetails(issuer, quote);

  const combinedSx: SxProps<Theme> = useCombinedStyling(
    dataSx,
    !isIssuerParticipating ? emptyCellSx : null,
    isClickableCell ? clickableCellSx : null,
    showWinnerContent ? winnerCellSx : null,
    showCommentContent ? commentCellSx : null,
    showPassedContent ? passedCellSx : null,
  );

  const isLoading = loading && isIssuerParticipating;

  if (isLoading) {
    return <Skeleton sx={combinedSx} width="100%" height={20} />;
  }

  const value = quote?.value ? `${quote?.value}%` : '';
  const comment = showPassedContent ? issuer?.reason : quote?.comment?.comment;

  return (
    <>
      <Stack direction="column" spacing={1}>
        <Typography variant="body1">{value}</Typography>
        <Typography sx={commentTextSx} component="p" variant="caption">
          {comment}
        </Typography>
      </Stack>
      {showCommentContent ? <Iconography iconName="comment-dots" sx={commentIconSx} /> : null}
      {showPassedContent ? (
        <Typography sx={cellTextSx} variant="body2">
          PASS
        </Typography>
      ) : null}
      {showWinnerContent ? (
        <Typography sx={cellTextSx} variant="body2">
          WINNER
        </Typography>
      ) : null}
    </>
  );
};
