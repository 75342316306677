import { MouseEvent, ReactElement, useState } from 'react';

import { selectedWatchlistAtom } from '@halo-atoms/watchlists';
import { ActionButton } from '@halo-common/components/ActionButton';
import { ProductTypeEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { useRemoveProductFromWatchlistMutation } from '@halo-data-sources/mutations';
import { Iconography, LocalizedButton, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Popover } from '@mui/material';
import { useAtomValue } from 'jotai';

const popoverSx = {
  p: 2,
  maxWidth: 600,
};

export type ProductCardRemoveButtonProps = {
  productType: ProductTypeEnum;
  productId: number;
};

export const ProductCardRemoveButton = ({ productId, productType }: ProductCardRemoveButtonProps): ReactElement => {
  const watchlist = useAtomValue(selectedWatchlistAtom);

  const [shareAnchorEl, setShareAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { mutate, isPending } = useRemoveProductFromWatchlistMutation({
    onSuccess: () => setShareAnchorEl(null),
  });

  const open = Boolean(shareAnchorEl);

  const handleSubmit = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    if (watchlist) mutate({ watchlistId: watchlist.id, productId, productType });
  };

  const handleOpenPopover = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    setShareAnchorEl(ev.currentTarget);
  };

  const handleClosePopover = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    setShareAnchorEl(null);
  };

  return (
    <>
      <LocalizedButton
        className="wm-watchlist-remove-product-button"
        startIcon={<Iconography iconName="trash" color="primary.main" />}
        onClick={handleOpenPopover}
        type="button"
        variant="text"
      >
        Remove
      </LocalizedButton>
      <Popover
        open={open}
        anchorEl={shareAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        disableAutoFocus
        disableEnforceFocus
        onClose={handleClosePopover}
      >
        <Stack direction="column" spacing={2} sx={popoverSx}>
          <LocalizedTypography variant="h6">{translations.components.productCardRemoveTitle}</LocalizedTypography>
          <LocalizedTypography variant="body2">{translations.components.productCardRemoveMessage}</LocalizedTypography>
          <Stack direction="row" justify="flex-end" spacing={2}>
            <LocalizedButton onClick={handleClosePopover} variant="outlined">
              {translations.common.cancel}
            </LocalizedButton>
            <ActionButton autoFocus loading={isPending} onClick={handleSubmit} variant="contained">
              {translations.common.confirmDelete}
            </ActionButton>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};
