import { ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
  type DragEndEvent,
  type UniqueIdentifier,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { ActionButton } from '@halo-common/components';
import { DASHBOARD_PREFERENCE_TAG } from '@halo-common/constants';
import { useModalState } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { useUpdateUserPreferencesMutation } from '@halo-data-sources/mutations';
import { useDashboardConfigQuery } from '@halo-modules/app/dashboard/hooks';
import { widgetsMap } from '@halo-modules/app/dashboard/widgets';
import { Iconography, LocalizedButton, Modal, ModalProps } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';

import { ConfigureWidgetsRow } from './ConfigureWidgetsRow';

export type ConfigureWidgetsModalProps = Partial<ModalProps>;

export const CONFIGURE_WIDGETS_MODAL_ID = 'configure-widgets-modal';

export const ConfigureWidgetsModal = (props: ConfigureWidgetsModalProps): ReactElement => {
  const { open, setOpen } = useModalState(CONFIGURE_WIDGETS_MODAL_ID);
  const { widgets, userDashboardPreferences } = useDashboardConfigQuery();

  const [positions, setPositions] = useState(userDashboardPreferences?.widgetsPosition ?? {});
  const [visibility, setVisibility] = useState<Record<string, boolean>>(
    userDashboardPreferences?.widgetsVisibility ?? {},
  );

  useEffect(() => {
    if (userDashboardPreferences?.widgetsPosition) {
      setPositions(userDashboardPreferences.widgetsPosition);
    }
    if (userDashboardPreferences?.widgetsVisibility) {
      setVisibility(userDashboardPreferences.widgetsVisibility);
    }
  }, [userDashboardPreferences]);

  const onClose = useCallback(() => {
    setOpen(false);
    setPositions(userDashboardPreferences?.widgetsPosition ?? {});
    setVisibility(userDashboardPreferences?.widgetsVisibility ?? {});
  }, [setOpen, userDashboardPreferences]);

  const { mutate: updatePreferences, isPending } = useUpdateUserPreferencesMutation({
    onSuccess: () => setOpen(false),
  });

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor), useSensor(KeyboardSensor));

  const sortedWidgets = useMemo(
    () =>
      [...widgets].sort((a, b) => (positions[a.engine] ?? a.position ?? 0) - (positions[b.engine] ?? b.position ?? 0)),
    [widgets, positions],
  );

  const dataIds = sortedWidgets.map((item) => item.engine) as UniqueIdentifier[];

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    const shouldUpdate = active && over && active.id !== over.id;
    if (shouldUpdate) {
      const oldIndex = dataIds.indexOf(active.id);
      const newIndex = dataIds.indexOf(over.id);
      const newItems = arrayMove(dataIds, oldIndex, newIndex);
      const newPositions = newItems.reduce<Record<string, number>>((acc, id, index) => {
        acc[id] = index;
        return acc;
      }, {});
      setPositions(newPositions);
    }
  }

  const saveChanges = () =>
    updatePreferences({
      tag: DASHBOARD_PREFERENCE_TAG,
      content: {
        ...(userDashboardPreferences ?? {}),
        widgetsPosition: positions,
        widgetsVisibility: visibility,
      },
    });

  const footer = (
    <Stack direction="row">
      <LocalizedButton onClick={onClose} variant="text" size="large" startIcon={<Iconography iconName="arrow-left" />}>
        {translations.common.cancel}
      </LocalizedButton>
      <ActionButton
        onClick={saveChanges}
        loading={isPending}
        variant="contained"
        size="large"
        color="primary"
        sx={{ ml: 'auto' }}
        data-testid="save-changes"
      >
        {translations.common.save}
      </ActionButton>
    </Stack>
  );

  const widgetsList = sortedWidgets.map((widget) => {
    const id = widget.engine;
    const name = widgetsMap?.[id]?.title || id;
    const description = widgetsMap?.[id]?.description;
    const visible = visibility[id] ?? widget.visibleByDefault ?? true;
    const onVisibilityChange = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setVisibility((prev) => ({
        ...prev,
        [id]: checked,
      }));
    };

    return (
      <ConfigureWidgetsRow
        id={id}
        name={name}
        description={description}
        visible={visible}
        onVisibilityChange={onVisibilityChange}
        key={id}
      />
    );
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      icon="gear"
      title={translations.dashboard.common.customizeHome}
      subtitle={translations.dashboard.common.dragAndDropToReoder}
      footer={footer}
      {...props}
    >
      <Stack direction="column" spacing={2} alignItems="stretch">
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
            {widgetsList}
          </SortableContext>
        </DndContext>
      </Stack>
    </Modal>
  );
};
