import { hash } from '@halo-common/utils';
import { ApiQueryRequestModel } from '@halo-data-sources/models';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type OrdersClientQueryKeys = {
  all: QueryKeyFactoryFn;
  query: QueryKeyFactoryFn;
};

export const FeaturedCalendarQueryKeyFactory: OrdersClientQueryKeys = {
  all: () => ['featuredCalendar'],
  query: (searchParams?: ApiQueryRequestModel) => [
    ...FeaturedCalendarQueryKeyFactory.all(),
    'query',
    hash(searchParams),
  ],
};
