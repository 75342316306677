import { ReactElement, ReactNode } from 'react';

import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { navigateParentTo } from '@halo-common/utils';
import { Iconography } from '@halodomination/halo-fe-common';
import { Button, Paper, Typography } from '@mui/material';

const containerSx = {
  backgroundColor: 'success.background',
  borderColor: 'success.border',
  borderRadius: '4px 4px 8px 8px',
  color: 'success.main',
  minHeight: 48,
  width: '100%',
  padding: '8px 16px',

  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: 2,
};

const textSx = {
  flex: '1 1 auto',
};

const buttonSx = {
  minWidth: 32,
  minHeight: 32,
  flex: '0 0 auto',
  padding: 1,
};

const createSimilarSx = {
  ...buttonSx,
  backgroundColor: 'common.white',
};

const iconSx = {
  flex: '0 0 auto',
};

export interface ProductCardRibbonProps {
  id: number;
  icon?: IconName;
  children: ReactNode;
  createSimilarClassName?: string;
  startAuctionClassName?: string;
}

export const ProductCardRibbon = ({
  id,
  icon,
  children,
  createSimilarClassName,
  startAuctionClassName,
}: ProductCardRibbonProps): ReactElement | null => {
  if (!children) return null;

  const inlineIcon = icon ? <Iconography iconName={icon} sx={iconSx} /> : null;

  return (
    <Paper variant="outlined" sx={containerSx}>
      {inlineIcon}
      <Typography variant="caption" fontWeight="bold" sx={textSx}>
        {children}
      </Typography>
      <Button
        size="small"
        color="primary"
        variant="text"
        className={createSimilarClassName}
        onClick={() => navigateParentTo(`/note_creator?note=${encodeURIComponent(id)}`, true)}
        aria-label="Create similar"
        sx={createSimilarSx}
      >
        <Iconography iconName="arrow-rotate-right" />
      </Button>
      <Button
        size="small"
        color="primary"
        variant="contained"
        className={startAuctionClassName}
        onClick={() => navigateParentTo(`/auction/${encodeURIComponent(id)}`)}
        aria-label="Start auction"
        sx={buttonSx}
      >
        <Iconography iconName="gavel" color="common.white" />
      </Button>
    </Paper>
  );
};
