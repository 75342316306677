import { ReactElement } from 'react';

import { productDetailModalAnalyticsTabStateAtom } from '@halo-atoms/pdm';
import {
  ProductDetailModalAlert,
  ProductDetailModalAnalyticsRollingReturnsChart,
  ProductDetailModalAnalyticsRollingReturnsDisclaimer,
  ProductDetailModalAnalyticsRollingReturnsFilters,
  ProductDetailModalAnalyticsRollingReturnsTables,
  ProductDetailModalAnalyticsRollingReturnsUnderlyingModal,
} from '@halo-common/layouts';
import { NoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useRollingReturnsChartQuery } from '@halo-data-sources/queries';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { useAtom } from 'jotai';

const tabContainerSx = {
  minHeight: 901,
};

export type ProductDetailModalAnalyticsTabProps = {
  product?: NoteModel | null;
  loading?: boolean;
  onRefresh?: () => void;
};

export const ProductDetailModalAnalyticsTab = ({
  product,
  loading = false,
  onRefresh,
}: ProductDetailModalAnalyticsTabProps): ReactElement => {
  const [state, setState] = useAtom(productDetailModalAnalyticsTabStateAtom);

  const { isPending: filtersLoading, error } = useRollingReturnsChartQuery(product);

  const isLoading = loading || filtersLoading;
  const open = state.underlyingModalOpen;

  const handleCloseModal = () => {
    setState((prev) => ({
      ...prev,
      underlyingModalOpen: false,
      underlyingModalStartDate: null,
      underlyingModalEndDate: null,
    }));
  };

  const filters = !error ? (
    <ProductDetailModalAnalyticsRollingReturnsFilters product={product} loading={isLoading} />
  ) : null;

  const chart = !error ? (
    <ProductDetailModalAnalyticsRollingReturnsChart product={product} loading={isLoading} />
  ) : null;

  const tables = !error ? (
    <ProductDetailModalAnalyticsRollingReturnsTables product={product} loading={isLoading} />
  ) : null;

  return (
    <span>
      <Stack sx={tabContainerSx} direction="column" spacing={3} justify="space-between">
        <Stack direction="column" spacing={3}>
          <ProductDetailModalAlert product={product} onRefresh={onRefresh} />
          <LocalizedTypography id="preTradeRollingReturnsTabHeader" variant="h5">
            {translations.pdm.preTrade.rollingReturnsTabTitle}
          </LocalizedTypography>
          {filters}
          {chart}
          {tables}
        </Stack>
        <ProductDetailModalAnalyticsRollingReturnsDisclaimer />
      </Stack>
      <ProductDetailModalAnalyticsRollingReturnsUnderlyingModal
        product={product}
        open={open}
        onClose={handleCloseModal}
      />
    </span>
  );
};
