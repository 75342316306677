import { useHaloInfiniteQuery } from '@halo-common/hooks';
import { AccountModel, InfinitePaginationModel, InfiniteQueryOptions, SortModel } from '@halo-common/models';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { getPortfolioAccountsBreakdown } from '@halo-data-sources/clients';
import { mapApiPortfolioAccountsBreakdownResponseToPortfolioAccountsBreakdown } from '@halo-data-sources/mappers';
import { PDMKeyFactory } from '@halo-data-sources/queries';

export type PortfolioAccountBreakdownModel = {
  id: number;
  account: AccountModel;
  notional: number;
};

export type PortfolioAccountsBreakdownResult = {
  data: Array<PortfolioAccountBreakdownModel>;
  pagination: InfinitePaginationModel;
};

export type PortfolioAccountsBreakdownPayload = {
  termsheetId?: number;
  account?: AccountTypeAheadOption | null;
  synced: boolean | null;
  page?: number;
  pageLength?: number;
  sort?: Array<SortModel>;
};

const getPostTradeAllocationsFn = async (
  payload: PortfolioAccountsBreakdownPayload,
  pageParam: InfinitePaginationModel,
) => {
  const { termsheetId, account, synced, sort, pageLength } = payload;
  const accountId = account?.account?.id;
  const householdId = account?.household?.id;

  const response = await getPortfolioAccountsBreakdown({
    term_sheet_id: termsheetId,
    account_selector: {
      synced,
      account_ids: accountId ? [accountId] : undefined,
      advisee_ids: householdId ? [householdId] : undefined,
    },
    sort,
    page: pageParam.next,
    page_length: pageLength ?? 20,
  });

  const accountsBreakdown = mapApiPortfolioAccountsBreakdownResponseToPortfolioAccountsBreakdown(response);

  return accountsBreakdown;
};

export const usePostTradeAllocationsQuery = (
  payload: PortfolioAccountsBreakdownPayload,
  options?: InfiniteQueryOptions<PortfolioAccountsBreakdownResult>,
) =>
  useHaloInfiniteQuery<PortfolioAccountsBreakdownResult>({
    queryKey: PDMKeyFactory.allocationsFiltered(payload),
    queryFn: ({ pageParam }) => getPostTradeAllocationsFn(payload, pageParam),
    ...options,
  });
