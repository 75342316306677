import { ReactElement, useState } from 'react';

import { CalendarPageModel } from '@halo-common/models';
import { Stack } from '@halodomination/halo-fe-common';
import { Box, Typography } from '@mui/material';

import { CalendarPageAccordion } from './CalendarPageAccordion';

const boxStyling = {
  border: '1px solid',
  borderColor: 'grey.400',
  borderRadius: 1,
  mt: 1,
  overflow: 'auto',
  maxHeight: 340,
};

export type CalendarPageListProps = {
  calendarPages: Array<CalendarPageModel>;
  defaultValues: Array<number>;
  onPageCheck: (pages: Array<number>) => void;
};

export const CalendarPageList = ({
  calendarPages,
  onPageCheck,
  defaultValues,
}: CalendarPageListProps): ReactElement => {
  const [selectedPages, setSelectedPages] = useState<Array<number>>(defaultValues);

  const handlePageCheck = (pages: Array<number>, checked: boolean) => {
    if (!checked) {
      const filteredPages = selectedPages.filter((selectedPage) => !pages.includes(selectedPage));
      setSelectedPages(filteredPages);
      onPageCheck(filteredPages);
    } else {
      const updatedPages = [...selectedPages, ...pages];
      setSelectedPages(updatedPages);
      onPageCheck(updatedPages);
    }
  };
  const parentPages = calendarPages.filter((page) => !page.parentId);
  const checkListItems = parentPages.map((page) => {
    const parentKey = `parent-page-${page.id}`;
    return <CalendarPageAccordion key={parentKey} page={page} onCheck={handlePageCheck} defaultPages={defaultValues} />;
  });

  return (
    <Box sx={boxStyling}>
      {checkListItems.length ? (
        <Stack direction="column">{checkListItems}</Stack>
      ) : (
        <Typography variant="body1" color="text.faded">
          No Calendar Pages Found
        </Typography>
      )}
    </Box>
  );
};
