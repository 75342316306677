import { ReactElement } from 'react';

import { StatusChip } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { Box } from '@mui/material';
import { camelCase } from 'lodash';

type CamelCaseSMAStatus = 'submitted' | 'pendingApproval' | 'canceled' | 'approved' | 'rejected';

type IconStatuses = 'completed' | 'pending' | 'closed';

type SMASubmittedAllocationStatusesProps = {
  value: string;
};

export const SMASubmittedAllocationStatuses = ({ value }: SMASubmittedAllocationStatusesProps): ReactElement => {
  const camelCaseValue = camelCase(value) as CamelCaseSMAStatus;

  const statusMap: Record<CamelCaseSMAStatus, IconStatuses> = {
    submitted: 'pending',
    pendingApproval: 'pending',
    canceled: 'closed',
    approved: 'completed',
    rejected: 'closed',
  };

  return (
    <Box>
      <StatusChip
        label={translations.common[camelCaseValue]}
        status={statusMap[camelCaseValue]}
        sx={{ p: '0 0 0 1' }}
        outerSx={{ br: 1 }}
      />
    </Box>
  );
};
