import { getClientConfiguration, request } from '@halo-data-sources/clients';
import { ApiFetchAnnuityContractResponseModel } from '@halo-data-sources/models';

const ANNUITY_PATH = '/posttrade/annuity';

export const fetchPortfolioAnnuityOrder = async (id: string): Promise<ApiFetchAnnuityContractResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ANNUITY_PATH);

  const formData = new FormData();
  formData.append('contract_number', id);
  formData.append('_csrf_token', clientConfiguration.csrfToken as string);

  const response = await request<ApiFetchAnnuityContractResponseModel>(`${clientConfiguration.basePath}/contract`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    body: formData,
  });

  return response;
};
