import { PaginationModel, PortfolioLifecycleEventModel } from '@halo-common/models';
import { getPostTradeAccountBreakdown } from '@halo-data-sources/clients/portfolioLifecycle';
import { ApiPortfolioLifecycleMapper, PaginationMapper, PortfolioLifecycleMapper } from '@halo-data-sources/mappers';
import { PortfolioLifecycleQueryKeyFactory } from '@halo-data-sources/queries';
import { GridSortModel } from '@halodomination/halo-fe-common';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export type GetLifecycleEventDetailPayload = {
  accountIds?: Array<number>;
  householdId?: number;
  cashFlowAmount: number;
  page: number;
  pageLength: number;
  productId: number;
  totalNotional: number;
  synced?: boolean;
  sort: GridSortModel;
};

export type GetLifecycleEventDetailResult = {
  data: Array<PortfolioLifecycleEventModel>;
  pagination: PaginationModel;
};

const getLifecycleEventBreakdownQueryFn = async (payload: GetLifecycleEventDetailPayload) => {
  const mappedRequest = PortfolioLifecycleMapper.toApiLifecycleEventBreakdownModel(payload);

  const data = await getPostTradeAccountBreakdown(mappedRequest);

  const mappedResponse = data.data.map((model) =>
    ApiPortfolioLifecycleMapper.toLifecycleEventBreakdownModel(model, payload.cashFlowAmount, payload.totalNotional),
  );

  const mappedPagination = PaginationMapper.toPaginationModel(data.pagination);

  return { data: mappedResponse, pagination: mappedPagination };
};

export const useLifecycleEventBreakdownQuery = (
  payload: GetLifecycleEventDetailPayload,
): UseQueryResult<GetLifecycleEventDetailResult, Error> =>
  useQuery<GetLifecycleEventDetailResult, Error>({
    queryKey: PortfolioLifecycleQueryKeyFactory.paginate(payload),
    queryFn: () => getLifecycleEventBreakdownQueryFn(payload),
  });
