import { ReactElement, useEffect, useMemo } from 'react';

import { AllocationStatusEnum } from '@halo-common/enums';
import { AdminCalendarAllocationFilledStatusEnum } from '@halo-modules/admin/calendars/enums';
import { FixActions } from '@halo-stores/Fix';
import { PershingActions, PershingSelectors } from '@halo-stores/Pershing';
import { Iconography, Modal, Stack } from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';
import { batch, useDispatch, useSelector } from 'react-redux';

export type FillOrderModalProps = {
  open: boolean;
  onClose: () => void;
};

export const FillOrderModal = ({ open, onClose }: FillOrderModalProps): ReactElement => {
  const dispatch = useDispatch();

  const calendar = useSelector(PershingSelectors.selectCalendar);

  const allocationIds = useMemo(
    () =>
      calendar?.adminAllocations
        ?.filter((allocation) => {
          const allocationStatus = allocation.status as AllocationStatusEnum | AdminCalendarAllocationFilledStatusEnum;

          return (
            allocationStatus === AllocationStatusEnum.pending ||
            allocationStatus === AdminCalendarAllocationFilledStatusEnum.warning
          );
        })
        .map((allocation) => allocation.id) ?? [],
    [calendar],
  );

  const handleSubmit = () => {
    batch(() => {
      dispatch(PershingActions.setFillOrderPending({ calendarId: calendar?.id }));
      dispatch(FixActions.bulkFillFixOrder({ calendarId: calendar?.id, allocationIds }));
    });
    onClose();
  };

  const handleClose = () => {
    batch(() => {
      dispatch(FixActions.removeFromOrderQueue({ calendarId: calendar?.id }));
      dispatch(PershingActions.removeFromOrderQueue({ calendarId: calendar?.id }));
    });
    onClose();
  };

  useEffect(() => {
    if (allocationIds.length) {
      batch(() => {
        const orders = allocationIds.map((id) => ({ calendarId: calendar?.id, orderId: id }));
        dispatch(FixActions.updateOrderQueue({ orders }));
        dispatch(PershingActions.updateOrderQueue({ orders }));
      });
    }
  }, [allocationIds]);

  return (
    <Modal
      size="small"
      open={open}
      onClose={handleClose}
      footer={
        <Stack direction="row" justify="space-between">
          <Button variant="text" color="primary" startIcon={<Iconography iconName="times" />} onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Fill All
          </Button>
        </Stack>
      }
      icon="list-check"
      title="Are you sure you would like to fill all indications for this CUSIP?"
    />
  );
};
