import { ReactElement } from 'react';

import { EducationModuleGroup } from '@halo-common/models';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Container, Paper } from '@mui/material';

const containerSpacing = { pt: 9 };
const containerStyling = { py: 5, px: 12 };

export type ModuleStartStepProps = {
  group?: EducationModuleGroup | null;
};

export const ModuleStartStep = ({ group }: ModuleStartStepProps): ReactElement => (
  <Container maxWidth="md" sx={containerSpacing}>
    <Paper sx={containerStyling}>
      <Stack direction="column" justify="space-between" spacing={3}>
        <LocalizedTypography variant="h1"> {group?.title}</LocalizedTypography>
        <LocalizedTypography color="textPrimary">{group?.description}</LocalizedTypography>
      </Stack>
    </Paper>
  </Container>
);
