import { ReactElement } from 'react';

import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedTypography } from '@halodomination/halo-fe-common';

export const DisclaimerText = (): ReactElement | null => {
  const { data: user } = useUserInfoQuery();

  const onboardingDisclaimerContent = user?.settings?.onboarding?.onboardingDisclaimerContent;

  return onboardingDisclaimerContent ? (
    <LocalizedTypography maxWidth={464} mt={3} mb={5} variant="body2" color="grey.600" fontSize="14px">
      {onboardingDisclaimerContent}
    </LocalizedTypography>
  ) : null;
};
