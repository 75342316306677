import { ReactElement } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { CollapsibleFilters, GroupedCheckbox } from '@halo-common/components';
import { DynamicTypeFiltersSideBarTitlesDict } from '@halo-common/layouts';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { useAtom } from 'jotai';

export type IssuerFiltersProps = {
  tag: string;
  filterValues?: (string | number)[];
};

export const IssuerFilters = ({ tag, filterValues }: IssuerFiltersProps): ReactElement => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const { data: userInfo } = useUserInfoQuery();
  const issuers = userInfo?.whiteLabel.issuers || [];

  const sideBarFilters = getSideBarFilters(tag);
  const selectedIssuers = sideBarFilters.issuers;

  const isChecked = (id: number | string) =>
    selectedIssuers.some((selectedIssuer) => selectedIssuer.id === id.toString());

  const handleIssuerChange = (id: string) => {
    const isInFilter = isChecked(id);
    const issuerName = issuers.find((issuer) => issuer.id.toString() === id)?.name || '';
    if (isInFilter) {
      const updatedIssuers = selectedIssuers.filter((issuer) => issuer.id !== id);
      setSideBarFilters({ tag, issuers: updatedIssuers });
    } else {
      const newIssuer = {
        id,
        name: issuerName || '',
      };
      const updatedIssuers = [...selectedIssuers, newIssuer];
      setSideBarFilters({ tag, issuers: updatedIssuers });
    }
  };

  const issuerCheckboxes = issuers
    .filter((issuer) => filterValues?.includes(issuer.id) ?? true)
    .map((issuer) => ({
      isChecked: isChecked(issuer.id),
      name: issuer.name,
      value: issuer.id.toString(),
    }));

  return (
    <CollapsibleFilters title={DynamicTypeFiltersSideBarTitlesDict.issuer}>
      <GroupedCheckbox disableLocalization onChange={handleIssuerChange} values={issuerCheckboxes} />
    </CollapsibleFilters>
  );
};
