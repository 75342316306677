import { ReactElement } from 'react';

import { auctionDefaultsQueryAtom, notionalAtom } from '@halo-atoms/auctions';
import { useAuctionDateFormat } from '@halo-common/hooks';
import { useAuctionDateDefaultsQuery } from '@halo-data-sources/queries';
import { Iconography } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';

import { AuctionStepperStep } from '../AuctionStepperStep';

const icon = <Iconography iconName="clock" prefix="far" />;

export const AuctionCreatedAtStep = (): ReactElement => {
  const dateFormat = useAuctionDateFormat();

  const query = useAtomValue(auctionDefaultsQueryAtom);
  const notional = useAtomValue(notionalAtom);

  const { data: auctionDefaults } = useAuctionDateDefaultsQuery(query);

  const auctionStrikeDateTime = DateTime.fromISO(auctionDefaults?.strikeDate || '');
  const strikeDateDisplayValue = auctionStrikeDateTime.isValid ? auctionStrikeDateTime.toFormat(dateFormat) : '';

  const isNotionalValueValid = notional && notional >= 0;

  return (
    <AuctionStepperStep
      step={1}
      displayValue={strikeDateDisplayValue}
      icon={icon}
      label="Expected Strike Date"
      disabled={!isNotionalValueValid}
      trackingVerb="set auction dates"
    />
  );
};
