import { ReactElement } from 'react';

import { TileGrid, TileGridTile } from '@halo-common/components';
import { navigateParentTo } from '@halo-common/utils';
import { useUserToolsQuery } from '@halo-data-sources/queries';
import { PageLayout } from '@halodomination/halo-fe-common';

const contentProps = {
  sx: {
    pt: 3,
  },
};

export type TileLayoutProps = {
  tilePaths: Array<string>;
};

export const TileLayout = ({ tilePaths }: TileLayoutProps): ReactElement => {
  const { data: tools = [], isPending } = useUserToolsQuery(tilePaths);

  const tiles = tools.map((tool) => ({
    description: tool.description,
    icon: tool.icon,
    name: tool.name,
    position: tool.position,
    value: tool.url,
  }));

  const handleTileClick = (tile: TileGridTile<string>) => navigateParentTo(tile.value);

  return (
    <PageLayout ContentProps={contentProps}>
      <TileGrid tiles={tiles} loading={isPending} onClick={handleTileClick} />
    </PageLayout>
  );
};
