import { UserToolModel } from '@halo-common/models';
import { ApiUserMenuItemModel } from '@halo-data-sources/models';

export const ApiUserMenuItemMapper = {
  toUserTool: (menuItem: ApiUserMenuItemModel): UserToolModel => {
    const iconParts = /fal fa-(.+)/.exec(menuItem.icon ?? '');
    const iconName = (iconParts?.[1] as UserToolModel['icon']) ?? 'arrow-right';

    return {
      description: menuItem?.description,
      icon: iconName,
      name: menuItem?.name,
      position: menuItem?.position,
      url: menuItem?.url,
    };
  },
};
