import { ReactElement } from 'react';

import { useIssuersQuery } from '@halo-data-sources/queries';
import { Stack } from '@halodomination/halo-fe-common';
import { Box, Typography } from '@mui/material';

const titleStyling = {
  fontSize: 14,
  fontWeight: 'fontWeightBold',
};

const subTitleStyling = {
  fontSize: 12,
};

const gridItemStyling = {
  display: 'flex',
  flexDirection: 'row',
  justifyElement: 'space-between',
  alignItems: 'center',

  borderBottom: '1px solid',
  borderColor: 'divider',
  p: 1,
};

const nameStyling = {
  flexGrow: 1,
};

const DEFAULT_MIN_INVESTMENT_SIZE = 'N/A';

export const AuctionIssuerMinimums = (): ReactElement | null => {
  const { data: issuers, isSuccess } = useIssuersQuery(true);

  if (isSuccess) {
    return (
      <Stack direction="column" spacing={2}>
        <Stack direction="column" spacing={1}>
          <Typography sx={titleStyling}>Issuer Minimum Required Amounts</Typography>
          <Typography sx={subTitleStyling}>Some issuers require minimums to bid on an auction.</Typography>
        </Stack>
        <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" columnGap={4}>
          {issuers.map(({ id, name, minInvestmentSize }) => {
            const minDisplayValue =
              minInvestmentSize?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              }) || DEFAULT_MIN_INVESTMENT_SIZE;

            return (
              <Box key={id} sx={gridItemStyling}>
                <Typography sx={nameStyling}>{name}</Typography>
                <Typography>{minDisplayValue}</Typography>
              </Box>
            );
          })}
        </Box>
      </Stack>
    );
  }

  return null;
};
