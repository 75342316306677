import { ReactElement, ReactNode } from 'react';

import { ProductCardNoteBase } from '@halo-common/components';
import { useNoteSpecificDetails } from '@halo-common/hooks';
import { AuctionBuyerModel } from '@halo-common/models';
import { Stack, Typography } from '@mui/material';

export type ProductCardAuctionDetailsProps = {
  action: ReactNode;
  auction: AuctionBuyerModel;
};

export const ProductCardAuctionDetails = ({ action, auction }: ProductCardAuctionDetailsProps): ReactElement => {
  const { note, winningQuote } = auction;

  const { highlights, solvableParameter, term, protectionDetails } = useNoteSpecificDetails(note);

  const solvableParameterLabel = solvableParameter?.label ?? '';
  const principalProtection = protectionDetails?.[0];

  const hasQuote = Boolean(winningQuote?.value);

  const quoteText = hasQuote ? (
    <Stack direction="row" alignItems="flex-start">
      <Typography variant="h4" color="textPrimary">
        {winningQuote?.value}
      </Typography>
      <Typography variant="subtitle2" color="textPrimary">
        %
      </Typography>
    </Stack>
  ) : (
    <Typography variant="h4" color="textPrimary">
      ?
    </Typography>
  );

  const parameterContent = (
    <Stack direction="column" alignItems="center">
      <Stack direction="row" alignItems="center">
        {quoteText}
      </Stack>
      <Typography variant="subtitle2" color="textSecondary">
        {solvableParameterLabel}
      </Typography>
    </Stack>
  );

  return (
    <ProductCardNoteBase
      action={action}
      highlights={highlights}
      note={note}
      principalProtection={principalProtection}
      term={term}
      slots={{ payoff: parameterContent }}
    />
  );
};
