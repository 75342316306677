import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

export type FixQueryKeys = {
  all: QueryKeyFactoryFn;
  order: QueryKeyFactoryFn;
};

export const FixQueryKeyFactory: FixQueryKeys = {
  all: () => ['fix'],
  order: (allocationId: number) => [...FixQueryKeyFactory.all(), 'order', allocationId],
};
