import { ComplianceApprovalQuery } from '@halo-atoms/complianceApproval';
import { getAdminCalendarAllocationFiltersQuery } from '@halo-data-sources/clients';
import { ComplianceApprovalMapper } from '@halo-data-sources/mappers';
import { AdminCalendarAllocationsQueryFilters, IssuerQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryOptions, UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

export type ComplianceApprovalIssuersQueryResult = Array<{
  id: number;
  name: string;
}>;

const getComplianceApprovalIssuersQueryFn = async (filters: AdminCalendarAllocationsQueryFilters) => {
  const comparisons = ComplianceApprovalMapper.toIssuersQueryComparisons(filters);

  const response = await getAdminCalendarAllocationFiltersQuery({
    filters: [
      {
        field: 'issuers.id',
        type: 'OBJECTS',
        object_fields: ['issuers.name'],
      },
    ],
    comparisons,
  });

  return (
    response.filter_values[0]?.objects?.map(({ object }) => ({
      id: object['issuers.id'] as number,
      name: object['issuers.name'] as string,
    })) ?? []
  );
};

export const useComplianceApprovalIssuersQuery = (
  query: ComplianceApprovalQuery,
  options?: Partial<UseQueryOptions<ComplianceApprovalIssuersQueryResult, Error>>,
): UseQueryResult<ComplianceApprovalIssuersQueryResult, Error> =>
  useQuery<ComplianceApprovalIssuersQueryResult, Error>({
    queryKey: IssuerQueryKeyFactory.byAdminAllocation(query),
    queryFn: () => getComplianceApprovalIssuersQueryFn(query),
    placeholderData: keepPreviousData,
    retry: false,
    ...options,
  });
