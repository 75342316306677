import { GridInitialState, GridSortDirection } from '@mui/x-data-grid-pro';

export type DataGridSortOptions = {
  initialSortColumn: string;
  sortingOrder?: GridSortDirection[];
};

export type DataGridSortResult = {
  initialSorting: GridInitialState;
  sortingOrder: GridSortDirection[];
};

export const useDataGridSort = ({
  initialSortColumn,
  sortingOrder = [null, 'asc', 'desc'],
}: DataGridSortOptions): DataGridSortResult => {
  const initialSorting = {
    sorting: {
      sortModel: [{ field: initialSortColumn, sort: sortingOrder[0] }],
    },
  } as GridInitialState;

  return { initialSorting, sortingOrder };
};
