import { ReactElement, useEffect, useState } from 'react';

import {
  AdminCalendarCsvButton,
  AdminCalendarStatusFilter,
  ExecutionHubFiltersBar,
  ExecutionHubFilterSchema,
  ExecutionHubStatusEnum,
  PershingAdminQueryParams,
  usePershingAdminContext,
} from '@halo-modules/admin';
import { PershingActions, PershingSelectors } from '@halo-stores/Pershing';
import { Stack, Tabs } from '@halodomination/halo-fe-common';
import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

const headerSx = { mb: 2 };
const filterTabsStyling = { mt: 2 };
const topRowStyling = { pl: 1, pr: 2 };
const bottomRowStyling = { px: 2 };

const FORM_DEFAULT_VALUES = { cusip: '', account: '', ip: '', issuer: 'default' };

export const ExecutionHubPageHeader = (): ReactElement => {
  const dispatch = useDispatch();

  const [expirationDates, setExpirationDates] = useState<Array<DateTime | null>>([null, null]);

  const context = usePershingAdminContext();
  const { disableFilters, pageLoading, queryParams, onStatusFilterChange, statusFilter, onQueryChange } = context;

  const totals = useSelector(PershingSelectors.selectTotals);

  const disableTabs = disableFilters || pageLoading;

  const tabs = [
    {
      label: 'Pending',
      value: ExecutionHubStatusEnum.pending,
      disabled: disableTabs,
      count: totals.pending,
    },
    {
      label: 'Filled',
      value: ExecutionHubStatusEnum.filled,
      disabled: disableTabs,
      count: totals.filled,
    },
    {
      label: 'Active',
      value: ExecutionHubStatusEnum.available,
      disabled: disableTabs,
      count: totals.available,
    },
    {
      label: 'Archived',
      value: ExecutionHubStatusEnum.archived,
      disabled: disableTabs,
      count: totals.archived,
    },
  ];

  const formMethods = useForm<ExecutionHubFilterSchema>({ defaultValues: FORM_DEFAULT_VALUES });

  const generateRequestParams = (status: AdminCalendarStatusFilter, params?: PershingAdminQueryParams) => {
    const updateParams = { ...params, hasOrders: true, hasFixOrders: true, approved: true, passedFomsCheck: true };

    switch (status) {
      case ExecutionHubStatusEnum.available:
        return {
          ...updateParams,
          status: ExecutionHubStatusEnum.available,
          orderStatuses: [ExecutionHubStatusEnum.filled, ExecutionHubStatusEnum.pending],
        };
      case ExecutionHubStatusEnum.archived:
        return {
          ...updateParams,
          status,
          orderStatuses: [ExecutionHubStatusEnum.filled, ExecutionHubStatusEnum.pending],
        };
      default:
        return {
          ...updateParams,
          orderStatuses: [status],
        };
    }
  };

  const handleSearchRequest = (status: AdminCalendarStatusFilter, params?: PershingAdminQueryParams) => {
    const requestParams = generateRequestParams(status, params);

    dispatch(
      PershingActions.fetchExecutionHubCalendars({
        params: [requestParams],
        updateAllTotals: false,
        updateTotalsFor: status,
        updateFilteredTotals: Boolean(params),
      }),
    );
  };

  const handleFormReset = () => {
    setExpirationDates([null, null]);
    formMethods.reset(FORM_DEFAULT_VALUES);
  };

  const handleClear = () => {
    onQueryChange();
    handleFormReset();
  };

  const handleFilterChange = (params?: ExecutionHubFilterSchema) => {
    onQueryChange(params);
  };

  const handleStatusFilterChange = (index: number) => {
    const status = tabs[index].value;

    onStatusFilterChange(status);
    handleFormReset();
  };

  const handleDownload = () => {
    const requestParams = generateRequestParams(statusFilter);
    const updatedParams = { ...queryParams, ...requestParams };

    dispatch(PershingActions.downloadPershingCalendarCsv({ route: 'orders', params: updatedParams }));
  };

  useEffect(() => {
    if (!pageLoading) handleSearchRequest(statusFilter, queryParams);
  }, [queryParams, statusFilter]);

  return (
    <FormProvider {...formMethods}>
      <Stack direction="column" spacing={2} sx={headerSx}>
        <Stack sx={topRowStyling} direction="row" justify="space-between" alignItems="flex-end">
          <Tabs
            variant="scrollablePills"
            tabs={tabs}
            onChange={handleStatusFilterChange}
            defaultTab={0}
            slotProps={{ tabs: { sx: filterTabsStyling } }}
          />
          <AdminCalendarCsvButton onDownload={handleDownload} />
        </Stack>
        <Box sx={bottomRowStyling}>
          <ExecutionHubFiltersBar
            expirationDates={expirationDates}
            onClear={handleClear}
            onSearch={handleFilterChange}
            onDatesChange={setExpirationDates}
          />
        </Box>
      </Stack>
    </FormProvider>
  );
};
