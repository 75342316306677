import { ReactElement } from 'react';

import { orderBookAuctionIssuerSortAtom, orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { AuctionDetailsIssuerSortOptionsEnum } from '@halo-common/enums';
import { useNoteSpecificDetails } from '@halo-common/hooks';
import { AuctionDetailsIssuerSortOptions, IssuerModel } from '@halo-common/models';
import { useBuyerAuctionQuery } from '@halo-data-sources/queries';
import { AuctionDetailsIssuerPanel, AuctionDetailsTargetMet, CommentPopover } from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';
import { InputLabel, MenuItem, Select, SelectChangeEvent, Skeleton, Typography } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';

export const AuctionDetailsIssuerBids = (): ReactElement => {
  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);
  const [sortValue, setSortValue] = useAtom(orderBookAuctionIssuerSortAtom);
  const auctionId = selectedAuctionId?.toString();

  const { data: auction } = useBuyerAuctionQuery(auctionId);
  const noteDetails = useNoteSpecificDetails(auction?.note);

  const solvableParameter = noteDetails?.solvableParameter?.label;
  const target = auction?.targetSolvableParameterValue.toFixed(2);

  const targetContent = auction ? (
    <Stack direction="column">
      <Stack direction="row" alignItems="center" justify="flex-end" spacing={1}>
        <AuctionDetailsTargetMet variant="target" />
        <Typography variant="h3" component="span">
          {target}%
        </Typography>
      </Stack>
      <Typography variant="body2" textAlign="right" color="text.secondary">
        {solvableParameter}
      </Typography>
    </Stack>
  ) : (
    <Stack direction="column">
      <Stack direction="row" justify="flex-end">
        <Skeleton width={160} height={32} />
      </Stack>
      <Stack direction="row" justify="flex-end">
        <Skeleton width={120} height={20} />
      </Stack>
    </Stack>
  );

  const getQuote = (issuer: IssuerModel) => {
    const quotes = auction?.quotes || [];
    const foundQuote = quotes.findIndex((quote) => quote.issuer.id === issuer.id);
    return foundQuote >= 0 ? quotes[foundQuote] : undefined;
  };

  const sortMethod = (a: IssuerModel, b: IssuerModel) => {
    if (AuctionDetailsIssuerSortOptionsEnum[sortValue] === AuctionDetailsIssuerSortOptionsEnum.bid) {
      const quoteA = getQuote(a);
      const quoteB = getQuote(b);
      const quoteAVal = quoteA?.value ?? 0;
      const quoteBVal = quoteB?.value ?? 0;
      return quoteAVal < quoteBVal ? 1 : quoteAVal > quoteBVal ? -1 : 0;
    }
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
  };
  const sortedIssuers = auction?.issuers.sort(sortMethod) ?? [];
  const issuerPanels = sortedIssuers.map((issuer) => {
    const quote = getQuote(issuer);
    return <AuctionDetailsIssuerPanel key={`issuer-${issuer.name}`} issuer={issuer} quote={quote} />;
  });

  const handleSortChange = (ev: SelectChangeEvent<AuctionDetailsIssuerSortOptions>) => {
    setSortValue(ev.target.value as AuctionDetailsIssuerSortOptions);
  };

  const sortByOptions = Object.entries(AuctionDetailsIssuerSortOptionsEnum).map(([backendVal, userVal]) => (
    <MenuItem key={backendVal} value={backendVal}>
      {userVal}
    </MenuItem>
  ));

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" justify="space-between" xs={12} sm={6} lg={4} wrap="wrap">
        <Stack direction="column">
          <InputLabel>Sort by</InputLabel>
          <Select label="Sort by" variant="standard" fullWidth value={sortValue} onChange={handleSortChange}>
            {sortByOptions}
          </Select>
        </Stack>
        {targetContent}
      </Stack>
      {issuerPanels}
      <CommentPopover />
    </Stack>
  );
};
