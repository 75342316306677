import { ChangeEvent, ReactElement, ReactNode } from 'react';

import {
  currentGlossarySearchAtom,
  debouncedGlossarySearchAtom,
  selectedGlossaryLetterAtom,
} from '@halo-atoms/education';
import { translations } from '@halo-common/translations';
import { useEducationGlossaryQuery } from '@halo-data-sources/queries';
import { GlossaryEmptyMessage, GlossarySection } from '@halo-modules/app';
import { LocalizedTextField, LocalizedTypography, Tabs } from '@halodomination/halo-fe-common';
import { Box, Container, Skeleton, capitalize } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

const LETTERS = [
  'all',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

const titleSx = { mb: 3, mt: -2, width: '100%' };
const flexContainerSx = { display: 'flex', justifyContent: 'center' };
const textFieldSx = { maxWidth: 750 };
const filterTabContainerSx = { pt: 4, display: 'flex', justifyContent: 'center' };

export const EducationGlossaryPage = (): ReactElement => {
  const { data: dictionary = {}, isPending } = useEducationGlossaryQuery();

  const [selectedLetterIndex, setSelectedLetterIndex] = useAtom(selectedGlossaryLetterAtom);
  const search = useAtomValue(currentGlossarySearchAtom);
  const setDebouncedSearch = useSetAtom(debouncedGlossarySearchAtom);

  const onSearchInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setDebouncedSearch(ev.target.value);
  };

  const filteredAlphabet = LETTERS.filter((letter) => letter === 'all' || dictionary[letter]);
  const tabs = filteredAlphabet.map((letter) => ({ label: capitalize(letter) }));

  const parsedSearch = search.toLowerCase();
  const selectedLetter = filteredAlphabet[selectedLetterIndex];
  const glossary = selectedLetter === 'all' ? dictionary : { [selectedLetter]: dictionary[selectedLetter] };

  const glossarySections = Object.entries(glossary).reduce((elements: Array<ReactNode>, [letter, terms]) => {
    if (!letter) return elements;
    if (!search) return [...elements, <GlossarySection key={letter} letter={letter} terms={terms} />];
    const filteredTerms = terms.filter(({ title }) => title.toLowerCase().includes(parsedSearch));
    if (!filteredTerms.length) return elements;
    else return [...elements, <GlossarySection key={letter} letter={letter} terms={filteredTerms} />];
  }, []);

  if (isPending) {
    return (
      <Container maxWidth="lg">
        <Box sx={flexContainerSx}>
          <Skeleton sx={titleSx} variant="rounded" width={277} height={32} />
        </Box>
        <Box sx={flexContainerSx}>
          <Skeleton variant="rounded" width={750} height={40} />
        </Box>
        <Box sx={filterTabContainerSx}>
          <Tabs variant="scrollablePills" tabs={tabs} loading />
        </Box>
        <GlossarySection loading />
        <GlossarySection loading />
      </Container>
    );
  }

  const content = !glossarySections.length ? <GlossaryEmptyMessage /> : glossarySections;

  return (
    <Container maxWidth="lg">
      <LocalizedTypography sx={titleSx} color="primary" align="center" variant="h5">
        {translations.dashboard.educationCourses.searchEducationCenter}
      </LocalizedTypography>
      <Box sx={flexContainerSx}>
        <LocalizedTextField
          fullWidth
          sx={textFieldSx}
          value={search}
          placeholder={translations.dashboard.educationCourses.educationPlaceholder}
          onChange={onSearchInputChange}
        />
      </Box>
      <Box sx={filterTabContainerSx}>
        <Tabs variant="scrollablePills" onChange={setSelectedLetterIndex} tabs={tabs} />
      </Box>
      {content}
    </Container>
  );
};
