import {
  LifecycleAllEventTypeEnum,
  LifecycleConditionalEventTypeEnum,
  LifecycleEventTypeEnum,
  PortfolioCashflowEventEnum,
  PortfolioEventSortDropdownValueEnum,
} from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { GetPortfolioLifecycleEventsQueryFilterModel } from '@halo-data-sources/queries';
import { DateTime } from 'luxon';

export * from './PortfolioLifecycle';
export { default } from './PortfolioLifecycle';

export * from './PortfolioLifecycleCashflowChart';
export * from './PortfolioLifecycleEmptyMessage';
export * from './PortfolioLifecycleEventsList';

export type PortfolioLifecycleWidgetTab = {
  id: string;
  label: string;
  namespace?: string;
  filters: Partial<GetPortfolioLifecycleEventsQueryFilterModel>;
  activeCategory?: string | string[];
};

export const portfolioLifecycleWidgetTabs: PortfolioLifecycleWidgetTab[] = [
  {
    id: 'all',
    label: translations.dashboard.portfolioLifecycle.allEvents,
    filters: {},
  },
  {
    id: 'maturity',
    label: translations.dashboard.portfolioLifecycle.maturity,
    filters: {
      eventTypes: LifecycleEventTypeEnum.EXPIRATION,
    },
    activeCategory: PortfolioCashflowEventEnum.Expiration,
  },
  {
    id: 'coupons',
    label: translations.dashboard.portfolioLifecycle.allCoupons,
    filters: {
      eventTypes: LifecycleAllEventTypeEnum.ALL_COUPONS,
    },
    activeCategory: [
      PortfolioCashflowEventEnum.FixedCoupon,
      PortfolioCashflowEventEnum.ConditionalCoupon,
      PortfolioCashflowEventEnum.MemoryCoupon,
    ],
  },
  {
    id: 'autocall',
    label: translations.dashboard.portfolioLifecycle.autocallAboveCallLevel,
    filters: {
      eventTypes: LifecycleConditionalEventTypeEnum.ABOVE_CALL_LEVEL,
    },
    activeCategory: PortfolioCashflowEventEnum.Autocall,
  },
  {
    id: 'recentEvents',
    label: translations.dashboard.portfolioLifecycle.recentEvents,
    filters: {
      sortField: PortfolioEventSortDropdownValueEnum.DESC_EVENT_DATE,
      startDate: DateTime.now().minus({ years: 1 }).toFormat('yyyy-LL-dd'),
      endDate: DateTime.now().minus({ days: 1 }).toFormat('yyyy-LL-dd'),
    },
  },
].map((t) => ({ ...t, namespace: 'wm-dashboard-lifecycle' }));
