import { ReactElement } from 'react';

import { DEFAULT_ASSET_ID } from '@halo-common/constants';
import { AccountModel, PortfolioTransactionModel } from '@halo-common/models';
import { AllocatedAccountListItem } from '@halo-modules/app';
import { Stack } from '@mui/material';

type EnhancedTransactionModel = Array<PortfolioTransactionModel & { assetIdType: string; assetId: string }>;

export type SuccessAddPositionsToAccountsModalLayoutProps = {
  transactions?: EnhancedTransactionModel;
  accounts?: Array<AccountModel>;
};

export const SuccessAddPositionsToAccountsModalLayout = ({
  transactions,
  accounts = [],
}: SuccessAddPositionsToAccountsModalLayoutProps): ReactElement => {
  const renderedAllocations = transactions?.map((transaction, index) => {
    const matchingAccount = accounts.find((account) => account.id === transaction.accountId);
    const custodian = matchingAccount?.custodian?.name;
    const assetIdType = transaction?.assetIdType ?? DEFAULT_ASSET_ID;
    const assetId = transaction?.assetId ?? '-';

    const key = `position-${index}`;

    return (
      <AllocatedAccountListItem
        key={key}
        name={transaction.accountName}
        accountId={transaction.accountDisplayName}
        custodian={custodian}
        amount={transaction.amount}
        isQueued={transaction.isQueued}
        showDivider={index !== 0}
        assetIdType={assetIdType}
        assetId={assetId}
      />
    );
  });

  return (
    <Stack direction="column" spacing={3}>
      {renderedAllocations}
    </Stack>
  );
};
