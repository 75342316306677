import { translations } from '@halo-common/translations';

import { amlPoliciesStep } from './amlPoliciesStep';
import { complaintsSanctionsWarningsStep } from './complaintsSanctionsWarningsStep';
import { complianceFunctionsStep } from './complianceFunctionsStep';
import { delegateKycAmlChecksStep } from './delegateKycAmlChecksStep';
import { delegateSuitabilityAssessmentStep } from './delegateSuitabilityAssessmentStep';
import { trainingOfFinancialAdvisorsStep } from './trainingOfFinancialAdvisorsStep';

export const complianceSection = {
  id: '2',
  label: translations.onboarding.suitability.compliance,
  steps: [
    complianceFunctionsStep,
    amlPoliciesStep,
    delegateKycAmlChecksStep,
    delegateSuitabilityAssessmentStep,
    complaintsSanctionsWarningsStep,
    trainingOfFinancialAdvisorsStep,
  ],
};
