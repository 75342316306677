import { ReactElement } from 'react';

import { IconName } from '@fortawesome/fontawesome-svg-core';
import { orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { AuctionIssuerBidStatusEnum, AuctionStatusEnum } from '@halo-common/enums';
import { useAuctionIssuerBidStatus } from '@halo-common/hooks';
import { IssuerModel, QuoteAuctionModel } from '@halo-common/models';
import { useBuyerAuctionQuery } from '@halo-data-sources/queries';
import { Iconography, IconographyColors, Stack } from '@halodomination/halo-fe-common';
import { CircularProgress, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';

export type IssuerBidStatusProps = {
  issuer: IssuerModel;
  quote?: QuoteAuctionModel;
};

export const IssuerBidStatus = ({ issuer, quote }: IssuerBidStatusProps): ReactElement => {
  const issuerBidStatus = useAuctionIssuerBidStatus({ issuer, quote });
  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);
  const auctionId = selectedAuctionId?.toString();

  const { data: auction } = useBuyerAuctionQuery(auctionId);

  const noBidStatuses = [AuctionStatusEnum.InAuction, AuctionStatusEnum.OpsApproval, AuctionStatusEnum.PreApproval];
  const isBiddingInProgress = auction?.status && noBidStatuses.includes(auction.status);

  const statusElement = (iconName: IconName, status: string) => {
    const color = {
      [AuctionIssuerBidStatusEnum.waitingForBid]: 'text.secondary',
      [AuctionIssuerBidStatusEnum.received]: 'success.main',
      [AuctionIssuerBidStatusEnum.receivedWithComment]: 'success.main',
      [AuctionIssuerBidStatusEnum.declinedToBid]: 'text.secondary',
      [AuctionIssuerBidStatusEnum.declinedByYou]: 'success.main',
      [AuctionIssuerBidStatusEnum.acceptedByYou]: 'success.main',
      [AuctionIssuerBidStatusEnum.winningQuote]: 'success.main',
    }[issuerBidStatus] as IconographyColors;

    const spinnerSx = {
      color: color,
    };

    const spinner = <CircularProgress size={14} sx={spinnerSx} />;
    const iconography = <Iconography iconName={iconName} prefix="far" color={color} />;
    const showSpinner = isBiddingInProgress && issuerBidStatus === AuctionIssuerBidStatusEnum.waitingForBid;
    const iconElement = showSpinner ? spinner : iconography;

    return (
      <Stack direction="row" spacing={1} alignItems="center">
        {iconElement}
        <Typography variant="caption" component="p" color={color}>
          {status}
        </Typography>
      </Stack>
    );
  };

  const isCanceled = auction?.status === AuctionStatusEnum.Canceled;
  if (isCanceled) return statusElement('xmark', 'Canceled');

  const waitingForBidContent = isBiddingInProgress
    ? statusElement('spinner', 'Waiting for bid')
    : statusElement('xmark', 'No bid received');

  return {
    [AuctionIssuerBidStatusEnum.waitingForBid]: waitingForBidContent,
    [AuctionIssuerBidStatusEnum.received]: statusElement('check', 'Received'),
    [AuctionIssuerBidStatusEnum.receivedWithComment]: statusElement('comment-exclamation', 'Received with comment'),
    [AuctionIssuerBidStatusEnum.declinedToBid]: statusElement('thumbs-down', 'Declined to bid'),
    [AuctionIssuerBidStatusEnum.declinedByYou]: statusElement('comment-xmark', 'Received with comment'),
    [AuctionIssuerBidStatusEnum.acceptedByYou]: statusElement('comment-check', 'Received with comment'),
    [AuctionIssuerBidStatusEnum.winningQuote]: statusElement('trophy', 'Winner'),
  }[issuerBidStatus];
};
