export enum OrganizationCapacityEnum {
  PRINCIPAL = 'PRINCIPAL',
  AGENCY = 'AGENCY',
  INDIVIDUAL = 'INDIVIDUAL',
  PROPRIETARY = 'PROPRIETARY',
}

export enum OrganizationCapacityLabelEnum {
  PRINCIPAL = 'Principal',
  AGENCY = 'Agent',
  INDIVIDUAL = 'Individual',
  PROPRIETARY = 'Proprietary',
}

export enum OrganizationOnboardingStatusEnum {
  FORM_NOT_APPLICABLE = 'FORM_NOT_APPLICABLE',
  FORM_NOT_COMPLETED = 'FORM_NOT_COMPLETED',
  DOCS_NOT_SIGNED = 'DOCS_NOT_SIGNED',
  UNDER_HALO_REVIEW = 'UNDER_HALO_REVIEW',
  ONBOARDED = 'ONBOARDED',
}
