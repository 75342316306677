import { ForwardedRef, forwardRef, ReactElement } from 'react';

import { AllocationModel, WholesalerModel } from '@halo-common/models';
import { useWholesalersQuery } from '@halo-data-sources/queries';
import { usePershingAdminContext } from '@halo-modules/admin';
import { Box, Stack, Skeleton, Typography } from '@mui/material';

const footerSx = {
  backgroundColor: 'grey.50',
  borderRadius: 1,
  minHeight: 56,
  overflowX: 'scroll',
  overflowY: 'hidden',
  width: '100%',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

const footerStatusColumnPlaceholderSx = {
  width: 50,
};

const footerTextColumnPlaceholderSx = {
  width: 200,
};

export type WholesalerDetailsMap = {
  [wholesalerCode: string]: {
    bonds: number;
    price: number;
  };
};

export type ExecutionHubCardFooterProps = {
  allocations?: Array<AllocationModel>;
  wholesalers?: Array<WholesalerModel>;
  onScroll?: () => void;
};

const ExecutionHubCardFooterComponent = (
  { allocations = [], wholesalers = [], onScroll }: ExecutionHubCardFooterProps,
  ref: ForwardedRef<HTMLDivElement>,
): ReactElement => {
  const { pageLoading } = usePershingAdminContext();
  const { data: fixEnabledWholesalers } = useWholesalersQuery();

  // TODO: Update this to be dynamic based on the allocations' wholesalers
  const wholesalerDetails = wholesalers.reduce((details: WholesalerDetailsMap, wholesaler: WholesalerModel) => {
    const { code } = wholesaler;

    const wholesalerBonds = allocations
      .filter((allocation) => allocation.wholesaler?.code === code)
      .reduce((total, allocation) => allocation.amount / 1000 + total, 0);

    const wholesalerPrice = wholesaler.price ?? 0;

    return { ...details, [code]: { price: wholesalerPrice, bonds: wholesalerBonds } };
  }, {});

  // TODO: Swap this function out with a reducer that changes a calendars wholesalers into sections
  const buildWholesalerFooterSection = (code: string, isLast = false): ReactElement => {
    const parsedCode = code.toLowerCase();

    const details = wholesalerDetails[parsedCode];
    const price = details?.price;
    const totalBonds = details?.bonds;

    const hideSection = Boolean(!price || !totalBonds);

    const priceText = !hideSection ? `Source ${price?.toFixed(3)}%` : '';
    const bondsText = !hideSection ? totalBonds : '';

    const wholesalerSectionTextSx = { width: 175, textAlign: 'right' };
    const wholesalerBondsTextSx = { ...wholesalerSectionTextSx, pl: 2, pr: !isLast ? 2 : 1 };
    const wholesalerSectionSx = {
      display: 'flex',
      height: 56,
      alignItems: 'center',
      width: 350,
      borderRight: !isLast ? '1px solid rgba(224, 224, 224, 1)' : 'unset',
    };

    return (
      <Box key={parsedCode} sx={wholesalerSectionSx}>
        <Typography sx={wholesalerSectionTextSx} variant="body2">
          {priceText}
        </Typography>
        <Typography sx={wholesalerBondsTextSx} fontWeight={600} variant="body2" color="primary">
          {bondsText}
        </Typography>
      </Box>
    );
  };

  const wholesalerFooterSections =
    fixEnabledWholesalers?.flatMap(({ code }, index) => {
      const isLast = index === fixEnabledWholesalers.length - 1;
      return buildWholesalerFooterSection(code, isLast);
    }) ?? [];

  return pageLoading ? (
    <Stack sx={footerSx} direction="row" alignItems="center">
      <Skeleton sx={{ flex: 1 }} width={100} height={50} />
      <Skeleton sx={{ flex: 1 }} width={100} height={50} />
    </Stack>
  ) : (
    <Box ref={ref} sx={footerSx} onScroll={onScroll}>
      <Stack sx={{ width: (wholesalerFooterSections?.length ?? 0) * 350 + 450 }} direction="row" alignItems="center">
        <Box sx={footerStatusColumnPlaceholderSx} />
        <Box sx={footerTextColumnPlaceholderSx} />
        <Box sx={footerTextColumnPlaceholderSx} />
        {wholesalerFooterSections}
      </Stack>
    </Box>
  );
};

export const ExecutionHubCardFooter = forwardRef<HTMLDivElement, ExecutionHubCardFooterProps>(
  ExecutionHubCardFooterComponent,
);
