import { ReactElement } from 'react';

import { V2NoteModel } from '@halo-common/models';
import { Skeleton, Stack } from '@mui/material';

import { PayoffLabel } from './PayoffLabel';

export type V2ProductSolvableParameterProps = {
  parameter?: V2NoteModel['payoffDetails']['solvableParameter'] | null;
  loading?: boolean;
};

export const V2ProductSolvableParameter = ({
  parameter,
  loading = true,
}: V2ProductSolvableParameterProps): ReactElement => {
  if (loading) return <Skeleton width={200} height={80} />;

  const payoffs = parameter?.payoffs ?? [];
  const conjunction = parameter?.conjunction;

  const columns = payoffs?.map(({ name, value, when, label }, index) => {
    const showConjunction = index !== payoffs.length - 1;

    return (
      <PayoffLabel
        key={name}
        name={name}
        value={value}
        when={when}
        label={label}
        conjunction={conjunction}
        showConjunction={showConjunction}
      />
    );
  });

  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-evenly">
      {columns}
    </Stack>
  );
};
