import { ReactElement } from 'react';

import { auctionStepAtoms, notionalAtom } from '@halo-atoms/auctions';
import { navigateParentTo } from '@halo-common/utils';
import { useRecordActivityMutation } from '@halo-data-sources/mutations';
import { useNoteQuery } from '@halo-data-sources/queries';
import { AdornedCurrencyInput, Iconography, Stack } from '@halodomination/halo-fe-common';
import { Button, Typography } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';

import { AuctionIssuerMinimums } from './AuctionIssuerMinimums';

const cancelIcon = <Iconography iconName="times" />;

export type AuctionNotionalPageProps = {
  noteId?: number;
};

export const AuctionNotionalPage = ({ noteId }: AuctionNotionalPageProps): ReactElement => {
  const router = useRouter();

  const { data: note, isSuccess } = useNoteQuery(noteId, { throwOnError: true });
  const { mutate: recordActivity } = useRecordActivityMutation();

  const handleNext = useSetAtom(auctionStepAtoms.handleNextAtom);
  const [notional, setNotional] = useAtom(notionalAtom);

  const notionalLessThanOne = notional && notional <= 0;
  const errorMessage = notionalLessThanOne || notional === null ? 'Value must be greater than zero' : undefined;
  const isNextDisabled = errorMessage !== undefined;
  const endIconColor = isNextDisabled ? 'text.faded' : 'text.primary';

  const nextIcon = <Iconography iconName="arrow-right" color={endIconColor} />;

  const onNotionalChange = (newNotional: number | null) => setNotional(newNotional);

  const handleActivity = (verb: string) => {
    recordActivity({
      activity: [{ verb }],
      fromIFrame: true,
    });
  };

  const currencyInput = isSuccess ? (
    <AdornedCurrencyInput
      value={notional}
      errorMessage={errorMessage}
      onChange={onNotionalChange}
      currencyCode={note?.currencyCode}
      currencySymbol={note?.currencySymbol}
    />
  ) : null;

  const onCancel = () => {
    handleActivity('auction canceled');

    const referredByInternalSource = window.parent.document.referrer;

    if (referredByInternalSource) router.back();
    else navigateParentTo('/note_creator');
  };

  const onNext = () => {
    handleActivity('set auction dates');
    handleNext();
  };

  return (
    <Stack direction="column" spacing={15}>
      <Stack direction="column" spacing={6}>
        <Stack direction="column" spacing={2}>
          <Typography variant="h6">How much notional would you like to invest?</Typography>
          <Typography variant="body1" color="text.secondary">
            This is the total notional across all of your clients, which you can allocate to individual client accounts
            later. To launch an auction, issuers typically require a minimum of $250,000 notional to invest. You may
            choose to set a lower amount of notional to invest; however in doing so may result in not receiving a bid.
            You will need to provide the final notional at the close of the auction.
          </Typography>
        </Stack>
        {currencyInput}
      </Stack>
      <Stack direction="row" justify="space-between">
        <Button onClick={onCancel} startIcon={cancelIcon}>
          Cancel
        </Button>
        <Button onClick={onNext} variant="outlined" endIcon={nextIcon} disabled={isNextDisabled}>
          Next: Timing
        </Button>
      </Stack>
      <AuctionIssuerMinimums />
    </Stack>
  );
};
