import {
  orderBookAuctionCommentPopoverAtom,
  orderBookAuctionSearchFieldsAtom,
  orderBookSelectedAuctionIdAtom,
} from '@halo-atoms/orderbook';
import { AuctionAdminModel, AuctionBuyerModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { cancelBuyerAuction } from '@halo-data-sources/clients';
import { ApiAuctionMapper } from '@halo-data-sources/mappers';
import {
  AuctionQueryKeyFactory,
  OrderBookAuctionQueryKeyFactory,
  OrderBookAuctionsQueryResult,
} from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';

const cancelAuctionMutationFn = async (id: number) => {
  const response = await cancelBuyerAuction(id);

  return ApiAuctionMapper.toAdminAuctionModel(response);
};

export const useCancelAuctionMutation = (): UseMutationResult<AuctionAdminModel, Error, number> => {
  const queryClient = useQueryClient();

  const { enqueueErrorEvent } = useSnackbar();

  const orderBookAuctionSearchParams = useAtomValue(orderBookAuctionSearchFieldsAtom);
  const setOrderBookAuctionCommentPopover = useSetAtom(orderBookAuctionCommentPopoverAtom);
  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);

  const auctionId = selectedAuctionId?.toString();

  return useMutation({
    mutationFn: cancelAuctionMutationFn,
    onSuccess: (data) => {
      if (data) {
        const auctionKey = AuctionQueryKeyFactory.buyerAuction(auctionId);
        queryClient.setQueryData<AuctionBuyerModel>(auctionKey, (prev) => {
          if (!prev) return undefined;
          const updatedAuction = { ...prev, status: data.status, ergStatus: data.ergStatus };
          return updatedAuction;
        });

        const orderBookAuctionsKey = OrderBookAuctionQueryKeyFactory.auctions(orderBookAuctionSearchParams);
        queryClient.setQueryData<OrderBookAuctionsQueryResult>(orderBookAuctionsKey, (prev) => {
          if (!prev) return undefined;
          const auctions = prev.auctions;
          const auctionIndex = auctions?.findIndex((auction) => auction.id === data.id);
          const updatedAuctions = [...(auctions ?? [])];

          if (!auctionIndex) return prev;
          const updatedAuction = { ...updatedAuctions[auctionIndex], status: data.status };
          updatedAuctions.splice(auctionIndex, 1, updatedAuction);

          return { ...prev, auctions: updatedAuctions };
        });

        // TODO: Update this when order hub is using react query
        // const adminAuctions = queryClient.getQueryData<Array<AdminAuctionModel>>(AuctionQueryKeyFactory.admin()) ?? [];
        // const auctionIndex = adminAuctions.findIndex((adminAuction) => adminAuction.id === data.id);
        // const updatedData = [...adminAuctions];

        // updatedData.splice(auctionIndex, 1, data);

        // queryClient.setQueryData(AuctionQueryKeyFactory.admin(), updatedData);
      }
    },
    onSettled: (_, error) => {
      if (error) enqueueErrorEvent({ message: translations.common.error });
      setOrderBookAuctionCommentPopover({ anchor: null });
    },
  });
};
