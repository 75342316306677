import { ReactElement, useState } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { portfolioLifecycleManagerAtom, portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import { DateRangePicker } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { useGeneratePortfolioEventsReportMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { useIsAccountTypeAheadSyncedOption } from '@halo-modules/app';
import { LocalizedAlert, LocalizedButton, LocalizedTypography, Modal, Stack } from '@halodomination/halo-fe-common';
import { Typography } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useAtom, useAtomValue } from 'jotai';
import { DateTime } from 'luxon';

const alertSx = { p: 1 };
const headerSx = { width: '100%' };
const footerSx = { width: '100%' };

export type LifecycleExcelExportDates = { startDate: string; endDate: string };

export const PORTFOLIO_EVENTS_EXCEL_DL_MODAL_NAME = 'portfolio-events-excel-dl-modal';

export const PortfolioEventsExcelDownloadModal = (): ReactElement => {
  const { data: user } = useUserInfoQuery();
  const { mutate: downloadCSV } = useGeneratePortfolioEventsReportMutation();

  const { filters } = useAtomValue(portfolioPositionsManagerAtom);
  const { filters: lifecycleFilters } = useAtomValue(portfolioLifecycleManagerAtom);

  const { sortField } = lifecycleFilters;

  const [modalMap, setModalMap] = useAtom(modalAtom);

  const now = DateTime.now();
  const startDateText = now.minus({ days: 30 }).toISODate();
  const endDateText = now.plus({ days: 30 }).toISODate();

  const [{ startDate, endDate }, setDates] = useState<LifecycleExcelExportDates>({
    startDate: startDateText,
    endDate: endDateText,
  });

  const action = filters.accountOption?.action;

  const household = filters.accountOption?.household;
  const householdText = household?.name;
  const householdId = household?.id;

  const account = filters.accountOption?.account;
  const name = account?.name ?? '--';
  const accountId = account?.accountId ?? '--';
  const custodian = account?.custodian?.name ?? '--';
  const accountText = `${name} - ${custodian} #${accountId}`;

  const displayText = account ? accountText : household ? householdText : action;
  const titleText = account ? translations.common.account : household ? translations.common.household : null;

  const modalOpen = modalMap[PORTFOLIO_EVENTS_EXCEL_DL_MODAL_NAME] ?? false;

  const isSyncedOption = useIsAccountTypeAheadSyncedOption(action);

  const handleDownload = () => {
    const accountId = account?.id;

    const isFilteredByHousehold = typeof householdId === 'number';
    const isFilteredByAccount = typeof accountId === 'number';

    let accountOrAdviseeName = action?.replace(' ', '').toUpperCase() ?? 'ALLACCOUNTS';
    if (isFilteredByHousehold) accountOrAdviseeName = household?.name ?? '';
    else if (isFilteredByAccount) accountOrAdviseeName = account?.name ?? '';

    const todaysDate = DateTime.now().toISODate();

    const fileName = `${user?.details.name?.toUpperCase()}_${accountOrAdviseeName}_${todaysDate}.xlsx`;
    const cleanFileName = fileName.replace(/[|&;$%@"<>()+,/:?*]/g, '-');

    downloadCSV({
      accountId,
      householdId,
      fileName: cleanFileName,
      synced: isSyncedOption,
      startDate,
      endDate,
      sortField,
    });
  };

  const handleClose = () => {
    setModalMap({ [PORTFOLIO_EVENTS_EXCEL_DL_MODAL_NAME]: false });
  };

  const onDatePickerChange = (value: DateRange<DateTime>) => {
    const updatedStartDate = value[0]?.toISODate();
    const updatedEndDate = value[1]?.toISODate();

    setDates((prev) => ({
      startDate: updatedStartDate ?? prev.startDate,
      endDate: updatedEndDate ?? prev.endDate,
    }));
  };

  const title = titleText ? <LocalizedTypography variant="overline">{titleText}</LocalizedTypography> : null;

  return (
    <Modal
      open={modalOpen}
      icon="file-chart-column"
      footer={
        <Stack direction="row" justify="flex-end" sx={footerSx}>
          <LocalizedButton onClick={handleDownload} type="button" variant="contained" color="primary">
            {translations.common.download}
          </LocalizedButton>
        </Stack>
      }
      overline={translations.portfolio.common.portfolioDocumentOverline}
      title={translations.portfolio.lifecycle.lifecycleCSVDownloadTitle}
      subtitle={translations.portfolio.lifecycle.lifecycleCSVDownloadSubtitle}
      onClose={handleClose}
    >
      <Stack direction="column" spacing={2}>
        <Stack direction="row" sx={headerSx} alignItems="center" justify="space-between" wrap="wrap" spacing={2}>
          <Stack direction="column">
            {title}
            <Typography variant="h6">{displayText}</Typography>
          </Stack>
          <DateRangePicker
            onChange={onDatePickerChange}
            startDate={startDate}
            endDate={endDate}
            label={translations.portfolio.lifecycle.eventDatePickerLabel}
          />
        </Stack>
        <LocalizedAlert sx={alertSx} severity="info" variant="filled">
          {translations.portfolio.lifecycle.lifecycleCSVDownloadAlert}
        </LocalizedAlert>
      </Stack>
    </Modal>
  );
};
