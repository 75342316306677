import { ReactElement } from 'react';

import { calendarPDFViewerAtom } from '@halo-atoms/calendars';
import { modalAtom } from '@halo-atoms/common';
import { fixedIncomeManagerAtom, fixedIncomeSelectedAtom } from '@halo-atoms/fixedIncome';
import { FixedIncomeProductCard, InfiniteScroller } from '@halo-common/components';
import { FIXED_INCOME_PREFERENCE_TAG } from '@halo-common/constants';
import { DynamicFiltersEmptyResult } from '@halo-common/layouts';
import { CalendarModel } from '@halo-common/models';
import { useRecordActivityMutation } from '@halo-data-sources/mutations';
import { useFixedIncomeCalendarsQuery } from '@halo-data-sources/queries';
import {
  CALENDAR_PDF_VIEWER_MODAL_NAME,
  FIXED_INCOME_ALLOCATION_MODAL_NAME,
  FIXED_INCOME_DETAIL_MODAL_NAME,
} from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';
import { CircularProgress } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';

const loadingSx = {
  width: '100%',
  mt: 4,
};

export const FixedIncomeList = (): ReactElement => {
  const { query, isFiltered } = useAtomValue(fixedIncomeManagerAtom);

  const setModalMap = useSetAtom(modalAtom);
  const setSelectedFixedIncome = useSetAtom(fixedIncomeSelectedAtom);
  const setContentDisposition = useSetAtom(calendarPDFViewerAtom);

  const { mutate: recordActivity } = useRecordActivityMutation();

  const {
    data = { pages: [] },
    isFetchingNextPage,
    fetchNextPage,
    isFetching,
    hasNextPage,
  } = useFixedIncomeCalendarsQuery(query);

  const hasMore = Boolean(hasNextPage);
  const totalResults = data.pages[0]?.pagination.totalResults ?? 0;
  const isEmpty = totalResults === 0;

  const actions = {
    onOfferingDoc: (calendar: CalendarModel) => {
      setContentDisposition({ calendar, disposition: 'inline' });
      setModalMap({ [CALENDAR_PDF_VIEWER_MODAL_NAME]: true });
    },
    onSubmitIndication: (calendar: CalendarModel) => {
      setSelectedFixedIncome(calendar);
      setModalMap({ [FIXED_INCOME_ALLOCATION_MODAL_NAME]: true });

      const activity = [{ verb: 'opened submit allocation modal via calendar reinvestment', model_name: 'Lifecycle' }];
      recordActivity({ activity, fromIFrame: false });
    },
    onDetails: (calendar: CalendarModel) => {
      setSelectedFixedIncome(calendar);
      setModalMap({ [FIXED_INCOME_DETAIL_MODAL_NAME]: true });
    },
  };

  const isLoading = isFetching && !isFetchingNextPage;

  if (isLoading) {
    return (
      <Stack sx={loadingSx} direction="row" justify="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (isEmpty) return <DynamicFiltersEmptyResult tag={FIXED_INCOME_PREFERENCE_TAG} isFiltered={isFiltered} />;

  return (
    <Stack direction="column">
      <InfiniteScroller loading={isFetchingNextPage} hasMore={hasMore} next={fetchNextPage}>
        {data.pages.flatMap((page) =>
          page.calendars.map((calendar) => (
            <FixedIncomeProductCard calendar={calendar} key={calendar.id} loading={isLoading} actions={actions} />
          )),
        )}
      </InfiniteScroller>
    </Stack>
  );
};
