import { AllocationModel, CalendarFomsCheckModel, FixOrderModel } from '@halo-common/models';
import { PershingCalendarMap } from '@halo-stores/Pershing';
import { HaloSliceState } from '@halo-stores/types';

export enum FixStatusEnum {
  failureFetchFixOrder = 'failureFetchFixOrder',
  failureCancelFixOrder = 'failureCancelFixOrder',
  failureFetchFixOrders = 'failureFetchFixOrders',
  failureFetchFixOrderAndAllocation = 'failureFetchFixOrderAndAllocation',
  failureFillFixOrder = 'failureFillFixOrder',
  failureBulkFillFixOrder = 'failureBulkFillFixOrder',
  failureFixOrderEvent = 'failureFixOrderEvent',
  failureInitiateFixOrderValidation = 'failureInitiateFixOrderValidation',
  failureSubmitFixOrder = 'failureSubmitFixOrder',
  failureSubmitFixOrderRejection = 'failureSubmitFixOrderRejection',
  idle = 'idle',
  requesting = 'requesting',
  requestFetchFixOrder = 'requestFetchFixOrder',
  requestCancelFixOrder = 'requestCancelFixOrder',
  requestFetchFixOrders = 'requestFetchFixOrders',
  requestFetchFixOrderAndAllocation = 'requestFetchFixOrderAndAllocation',
  requestFillFixOrder = 'requestFillFixOrder',
  requestBulkFillFixOrder = 'requestBulkFillFixOrder',
  requestInitiateFixOrderValidation = 'requestInitiateFixOrderValidation',
  requestSubmitFixOrder = 'requestSubmitFixOrder',
  requestSubmitOrderRejection = 'requestSubmitOrderRejection',
  success = 'success',
  successFetchFixOrder = 'successFetchFixOrder',
  successFixOrderCanceled = 'successFixOrderCanceled',
  successCancelFixOrder = 'successCancelFixOrder',
  successFetchFixOrders = 'successFetchFixOrders',
  successFetchFixOrderAndAllocation = 'successFetchFixOrderAndAllocation',
  successFillFixOrder = 'successFillFixOrder',
  successBulkFillFixOrder = 'successBulkFillFixOrder',
  successFixOrderSubmissionEvent = 'successFixOrderSubmissionEvent',
  successFixOrderValidationEvent = 'successFixOrderValidationEvent',
  successInitiateFixOrderValidation = 'successInitiateFixOrderValidation',
  successSubmitFixOrder = 'successSubmitFixOrder',
  successSubmitFixOrderRejection = 'successSubmitFixOrderRejection',
  successBulkFillOrderWebsocketEvent = 'successBulkFillOrderWebsocketEvent',
  websocketTimeout = 'websocketTimeout',
}

export type FixStatus = keyof typeof FixStatusEnum;

export type QueuedFixOrder = {
  orderId: number;
  calendarId?: number;
};

export type FetchFixOrderAndAllocationThunkResult = {
  fixOrder?: FixOrderModel | null;
  allocation?: AllocationModel | null;
};

export type FetchFixOrdersThunkResult = {
  orders: Array<FixOrderModel>;
};

export type FillFixOrderThunkAction = {
  allocationId: number;
  wholesalerId: number;
};

export type FixOrderThunkResult = {
  fixOrder: FixOrderModel;
};

export type BulkFillFixOrderThunkAction = {
  calendarId?: number;
  allocationIds: Array<number>;
};

export type BulkFillFixOrderThunkResult = {
  calendars?: PershingCalendarMap;
};

export type SubmitOrderRejectionThunkResult = {
  reason: string;
  fixOrder: FixOrderModel;
};

export type SubmitOrderRejectionThunkAction = {
  allocation: AllocationModel;
  reason: string;
};

export type FixSliceState = {
  orderQueue: Array<QueuedFixOrder>;
  orders: Array<FixOrderModel>;
  rejectionReason?: string | null;
  selectedOrder?: FixOrderModel | null;
  status: FixStatus;
  validationResult?: CalendarFomsCheckModel | null;
};

export type FixState = FixSliceState & Omit<HaloSliceState, 'status'>;
