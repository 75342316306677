import { ReactElement } from 'react';

import { Input, InputProps, SelectOption } from '@halo-common/components';

export type SelectProps = Omit<InputProps, 'type' | 'options'> & {
  name: InputProps['name'];
  options: Array<SelectOption>;
};

export const Select = (props: SelectProps): ReactElement | null => <Input {...props} type="select" />;
