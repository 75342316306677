import { ReactElement } from 'react';

import { AssetIdLocationEnum } from '@halo-common/enums';
import { AddPositionsToAccountFormFields } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { validateFundServCode } from '@halo-common/utils';
import { usePortfolioAssetExistenceQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { useAssetIdListText } from '@halo-modules/app';
import {
  ChipInput,
  ChipInputProps,
  ChipModel,
  LocalizedAlert,
  LocalizedAlertTitle,
  Stack,
} from '@halodomination/halo-fe-common';
import { Chip, CircularProgress, chipClasses } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const chipErrorSx = {
  color: 'warning.dark',
  backgroundColor: 'warning.light',
  [`& .${chipClasses.deleteIcon}`]: {
    color: 'warning.dark',
  },
};

const edgarChipSx = {
  color: 'success.dark',
  border: '1px solid',
  borderColor: 'success.dark',
  backgrounColor: 'common.white',
  [`& .${chipClasses.deleteIcon}`]: {
    color: 'success.dark',
  },
};

const chipSuccessSx = {
  color: 'success.dark',
  backgroundColor: 'success.background',
  [`& .${chipClasses.deleteIcon}`]: {
    color: 'success.dark',
  },
};

enum ChipStatusEnum {
  unresolved = 'unresolved',
  pending = 'pending',
  resolved = 'resolved',
}

export type AddPositionsToAccountChipFieldProps = {
  assets: Array<ChipModel>;
  onAdd: (assets: Array<ChipModel>) => void;
  onRemove: (assets: Array<ChipModel>) => void;
};

export const AddPositionsToAccountChipField = ({
  assets,
  onAdd,
  onRemove,
}: AddPositionsToAccountChipFieldProps): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();
  const whiteLabel = userInfo?.whiteLabel;

  const { formState, clearErrors } = useFormContext<AddPositionsToAccountFormFields>();
  const { errors } = formState;

  const assetIds = assets.map((asset) => asset.label);
  const { data } = usePortfolioAssetExistenceQuery(assetIds, { enabled: assetIds.length >= 1 });

  const assetList = data ?? [];

  const existingAssets = assetList.map((asset) => ({ ...asset, id: asset.id, label: asset.assetId }));
  const unresolvedAssetIdList = assetList.filter(
    (asset) => asset.status === ChipStatusEnum.unresolved && !asset.location,
  );
  const edgarAssetIdList = assetList.filter((asset) => asset.location === AssetIdLocationEnum.edgar);
  const jhnList = assetList.filter((asset) => validateFundServCode(asset.assetId, asset.assetIdType));

  const currentAssets = assets.map((asset) => {
    const data = existingAssets.find((existingAsset) => existingAsset.label === asset.label);
    return { ...asset, status: data?.status ?? asset.status };
  });

  const showJhnAlert = jhnList && jhnList?.length > 0;
  const showQueueAlert = edgarAssetIdList && edgarAssetIdList?.length > 0;

  const assetIdentifiers = whiteLabel?.assetIdentifiers ?? [];
  const assetIdTextList = useAssetIdListText(assetIdentifiers, true);
  const informativeText = translations.portfolio.addPositionsToAccountModal.multipleAssetHelperText;

  const isError = Boolean(unresolvedAssetIdList.length > 0 || errors?.positions?.message);
  const helperText = errors?.positions?.message ?? informativeText;

  const helperTextLocalization = { helperText: { dynamicContent: { assetIds: assetIdTextList } } };

  const handleAdd = (chips: Array<ChipModel>, chip?: ChipModel) => {
    clearErrors();

    if (chip) {
      const updatedChips = [{ ...chip, label: chip.label.trim().toUpperCase(), status: ChipStatusEnum.pending }];

      onAdd(updatedChips);
    } else {
      const chipMap = chips.reduce(
        (map, chip) => {
          const updatedChip = { ...chip, label: chip.label.trim().toUpperCase(), status: ChipStatusEnum.pending };
          return { ...map, [chip.label]: updatedChip };
        },
        {} as { [label: string]: ChipModel },
      );

      const uniqueChips = Object.values(chipMap).filter((uniqueChip) =>
        existingAssets?.every((existingAsset) => existingAsset.label.toUpperCase() !== uniqueChip.label.toUpperCase()),
      );

      onAdd(uniqueChips);
    }
  };

  const handleDelete = (chips: Array<ChipModel>): void => {
    clearErrors();
    onRemove(chips);
  };

  const renderChip = (chips: ChipInputProps['value']) => {
    return chips?.map((chip: ChipModel) => {
      const { label, id, status, location } = chip;

      const isLoading = status === ChipStatusEnum.pending;
      const isResolved = status === ChipStatusEnum.resolved;
      const isUnresolved = status === ChipStatusEnum.unresolved;
      const isHalo = (location as string)?.toLowerCase() === 'halo';
      const isEdgar = edgarAssetIdList.some((asset) => asset.assetId === label);

      const icon = isLoading ? <CircularProgress color="primary" size={15} sx={{ ml: 1 }} /> : undefined;
      const variant = isLoading || !isHalo ? 'outlined' : 'filled';

      const chipStyling = isUnresolved ? chipErrorSx : isEdgar ? edgarChipSx : isResolved ? chipSuccessSx : undefined;

      const handleDeleteChip = () => {
        const filteredChips = chips.filter((nextChip) => nextChip.id !== id);
        handleDelete(filteredChips);
      };

      return (
        <Chip sx={chipStyling} key={id} label={label} variant={variant} onDelete={handleDeleteChip} deleteIcon={icon} />
      );
    });
  };

  const unresolvedCusipError = unresolvedAssetIdList?.length ? (
    <LocalizedAlert severity="warning" variant="filled" disableLocalization>
      <LocalizedAlertTitle>
        {translations.portfolio.addPositionsToAccountModal.multipleAssetAssetIdError}
      </LocalizedAlertTitle>
      {unresolvedAssetIdList.map(({ assetId }) => assetId).join(', ')}
    </LocalizedAlert>
  ) : null;

  const queueAlert = showQueueAlert ? (
    <LocalizedAlert severity="info" variant="filled" disableLocalization>
      <LocalizedAlertTitle>
        {translations.portfolio.addPositionsToAccountModal.multipleAssetQueueAlert}
      </LocalizedAlertTitle>
      {edgarAssetIdList.map(({ assetId }) => assetId).join(', ')}
    </LocalizedAlert>
  ) : null;

  const jhnAlert = showJhnAlert ? (
    <LocalizedAlert severity="error" variant="filled" disableLocalization>
      <LocalizedAlertTitle>
        {translations.portfolio.addPositionsToAccountModal.multipleAssetJhnAlert}
      </LocalizedAlertTitle>
      {jhnList.map(({ assetId }) => assetId).join(', ')}
    </LocalizedAlert>
  ) : null;

  return (
    <Stack direction="column" spacing={3}>
      {unresolvedCusipError}
      {jhnAlert}
      {queueAlert}
      <ChipInput
        chipRenderer={renderChip}
        fullWidth
        helperText={helperText}
        value={currentAssets}
        onAdd={handleAdd}
        onRemove={handleDelete}
        separators={[' ', ',', ';', '\\n']}
        error={isError}
        Localization={helperTextLocalization}
      />
    </Stack>
  );
};
