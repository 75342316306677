import { ReactElement } from 'react';

import { LexicalTypography, ProductDetailTable } from '@halo-common/components';
import { SHORT_MONTH_SHORT_DAY_YEAR_FORMAT } from '@halo-common/constants';
import { useTranslatePdmDates } from '@halo-common/hooks';
import { PostTradeNoteDetailModalProps } from '@halo-common/modals';
import { translations } from '@halo-common/translations';
import {
  PostTradeCouponLifecycleEvent,
  usePostTradeProductDetailsCouponDataQuery,
  useUserInfoQuery,
} from '@halo-data-sources/queries';
import { GridColDef, HaloDataGridProps, Iconography, mapNumberToLocalCurrency } from '@halodomination/halo-fe-common';
import { Stack, Typography, capitalize } from '@mui/material';

const customCellSx = {
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  width: '100%',
};

const iconSx = {
  marginRight: 1.625,
};

const emphasisSx = {
  fontWeight: 600,
  ...customCellSx,
};

const paidSx = {
  color: 'success.dark',
};

const missedSx = {
  color: 'error.main',
};

const pendingSx = {
  color: 'text.secondary',
};

const unknownSx = {
  ...pendingSx,
  ...customCellSx,
};

const statusContainerSx = {
  height: '100%',
};

const commonColumnProps: Partial<GridColDef> = {
  align: 'left',
  flex: 1,
};

export type PostTradeNoteDetailModalCouponTableProps = {
  termsheetId?: number;
  filters?: PostTradeNoteDetailModalProps['filters'];
  currencySymbol?: string;
  currencyCode?: string;
  isFixed?: boolean;
};

export const PostTradeNoteDetailModalCouponTable = ({
  termsheetId,
  filters,
  currencyCode,
  currencySymbol,
  isFixed,
}: PostTradeNoteDetailModalCouponTableProps): ReactElement => {
  const translateDate = useTranslatePdmDates();

  const { data: user } = useUserInfoQuery();
  const { data, isPending } = usePostTradeProductDetailsCouponDataQuery(termsheetId, filters?.accountOption);

  const rows = data?.events ?? [];
  const height = rows.length >= 16 ? 600 : 'auto';
  // TODO: allocation && notional ? allocation / notional : 1;
  const factor = 1;

  const columns: HaloDataGridProps['columns'] = [];

  if (user?.settings?.couponSummary?.showDate) {
    columns.push({
      ...commonColumnProps,
      field: 'date',
      headerName: isFixed ? translations.common.date : translations.common.observationDate,
      valueFormatter: (value: string) => translateDate(value, SHORT_MONTH_SHORT_DAY_YEAR_FORMAT),
    });
  }

  if (user?.settings?.couponSummary?.showAmount) {
    columns.push({
      ...commonColumnProps,
      field: 'amount',
      headerName: translations.common.amount,
      renderCell: ({ value }) => {
        // TODO: Add in factor notional / allocation or something like that
        const parsedValue = value ? parseFloat(value) * factor : null;

        const valueAsCurrency = mapNumberToLocalCurrency(parsedValue, { currency: currencyCode }, currencySymbol);

        return (
          <Typography sx={emphasisSx} variant="body2">
            {valueAsCurrency}
          </Typography>
        );
      },
    });
  }

  if (user?.settings?.couponSummary?.showStatus) {
    columns.push({
      ...commonColumnProps,
      field: 'status',
      headerName: translations.common.paid,
      renderCell: ({ row, value }) => {
        const text = capitalize(value);
        const comparatorValue = value.toLowerCase();
        const isPaid = comparatorValue === 'paid';
        const isMissed = comparatorValue === 'missed';
        const isPending = comparatorValue === 'pending';

        const statusUnknown = !row.amount && !value;

        if (statusUnknown) {
          return (
            <LexicalTypography sx={unknownSx} variant="body2" translationKey={value}>
              N/A
            </LexicalTypography>
          );
        }

        const statusStyling = [];
        if (isPaid) statusStyling.push(paidSx);
        if (isMissed) statusStyling.push(missedSx);
        if (isPending) statusStyling.push(pendingSx);

        let icon = <Iconography sx={iconSx} color="text.secondary" iconName="calendar" />;
        if (isPaid) icon = <Iconography sx={iconSx} color="success.dark" iconName="check" />;
        if (isMissed) icon = <Iconography sx={iconSx} color="error.main" iconName="times" />;

        return (
          <Stack sx={statusContainerSx} direction="row" alignItems="center">
            {icon}
            <LexicalTypography variant="body2" sx={statusStyling} translationKey={value}>
              {text}
            </LexicalTypography>
          </Stack>
        );
      },
    });
  }

  return (
    <ProductDetailTable<PostTradeCouponLifecycleEvent>
      height={height}
      columns={columns}
      rows={rows}
      loading={isPending}
      initialState={{
        sorting: {
          sortModel: [{ field: 'date', sort: 'asc' }],
        },
      }}
    />
  );
};
