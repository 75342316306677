import { ReactElement } from 'react';

import { productDetailModalAnalyticsTabStateAtom } from '@halo-atoms/pdm';
import {
  ProductDetailModalAnalyticsRollingReturnsStatisticsTableLayout,
  ProductDetailModalAnalyticsRollingReturnsSummary,
  ProductDetailModalAnalyticsRollingReturnsTable,
  ProductDetailModalAnalyticsRollingReturnsTableProps,
} from '@halo-common/layouts';
import { RollingReturnsStatisticsQueryResult } from '@halo-data-sources/queries';
import { Box } from '@mui/material';
import { useAtomValue } from 'jotai';

const dummySummarySx = { display: 'flex', flex: 1 };
const summaryContainerSx = { display: 'flex', alignItems: 'center', justifyContent: 'right' };

export type ProductDetailModalAnalyticsRollingReturnsTotalReturnsTableProps = {
  data?: RollingReturnsStatisticsQueryResult | null;
  loading?: boolean;
  title: string;
  showTreeData: boolean;
  showAgainstNote: boolean;
  showAverageLife: boolean;
};

export const ProductDetailModalAnalyticsRollingReturnsTotalReturnsTable = ({
  data,
  loading = false,
  title,
  showTreeData,
  showAgainstNote,
  showAverageLife,
}: ProductDetailModalAnalyticsRollingReturnsTotalReturnsTableProps): ReactElement => {
  const state = useAtomValue(productDetailModalAnalyticsTabStateAtom);

  const { daysBetween: days, dateRangeText } = state;

  const rows = data?.performance ?? [];

  const underlyingData = rows[0];
  const noteData = rows[1];

  const notePositive = noteData?.best;
  const noteZero = noteData?.average;
  const noteNegative = noteData?.worst;
  const underlyingPositive = underlyingData?.best;
  const underlyingZero = underlyingData?.average;
  const underlyingNegative = underlyingData?.worst;

  const hasColumnOneData = typeof notePositive === 'number' && typeof underlyingPositive === 'number';
  const hasColumnTwoData = typeof noteZero === 'number' && typeof underlyingZero === 'number';
  const hasColumnThreeData = typeof noteNegative === 'number' && typeof underlyingNegative === 'number';

  const columnOneDifference = hasColumnOneData ? notePositive - underlyingPositive : undefined;
  const columnTwoDifference = hasColumnTwoData ? noteZero - underlyingZero : undefined;
  const columnThreeDifference = hasColumnThreeData ? noteNegative - underlyingNegative : undefined;

  const formattedTitle = showAgainstNote ? `Note vs. ${title} Total Return Comparison` : `${title} Total Return`;

  const when = `at maturity when analyzing the ${days} Rolling Return windows between ${dateRangeText}.`;

  const positiveTooltipText = `The best return ${when}`;
  const zeroTooltipText = `The average return ${when}`;
  const negativeTooltipText = `The worst return ${when}`;

  const defaultColumns: ProductDetailModalAnalyticsRollingReturnsTableProps['columns'] = [
    {
      field: 'best',
      headerName: 'Best',
      headerInfo: positiveTooltipText,
    },
    {
      field: 'average',
      headerName: 'Average',
      headerInfo: zeroTooltipText,
    },
    {
      field: 'worst',
      headerName: 'Worst',
      headerInfo: negativeTooltipText,
    },
  ];

  const columns: ProductDetailModalAnalyticsRollingReturnsTableProps['columns'] = showAverageLife
    ? [
        {
          field: 'lifeSpan',
          headerName: 'Average Lifetime',
          headerInfo: `The average lifetime of the note, considering the callability features of the note, when analyzing the ${days} Rolling Return windows between ${dateRangeText}.`,
          valueFormatter: (value: number) => {
            const isSingular = value === 1;
            const monthText = isSingular ? 'Month' : 'Months';
            return value ? `${value} ${monthText}` : 'N/A';
          },
        },
        ...defaultColumns,
      ]
    : defaultColumns;

  const comparisons = showAgainstNote ? (
    <Box sx={summaryContainerSx}>
      <Box sx={dummySummarySx} />
      {showAverageLife ? <Box sx={dummySummarySx} /> : null}
      <ProductDetailModalAnalyticsRollingReturnsSummary
        label="Difference"
        value={columnOneDifference}
        loading={loading}
      />
      <ProductDetailModalAnalyticsRollingReturnsSummary
        label="Difference"
        value={columnTwoDifference}
        loading={loading}
      />
      <ProductDetailModalAnalyticsRollingReturnsSummary
        label="Difference"
        value={columnThreeDifference}
        loading={loading}
      />
    </Box>
  ) : null;

  return (
    <ProductDetailModalAnalyticsRollingReturnsStatisticsTableLayout title={formattedTitle} loading={loading}>
      <ProductDetailModalAnalyticsRollingReturnsTable
        columns={columns}
        rows={rows}
        loading={loading}
        treeData={showTreeData}
      />
      {comparisons}
    </ProductDetailModalAnalyticsRollingReturnsStatisticsTableLayout>
  );
};
