import { ReactElement } from 'react';

import { NotificationTypeModel } from '@halo-data-sources/models';
import { getIconColor, getIconName } from '@halo-modules/app/account';
import { Iconography, IconographyColors, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';

const imageSx = {
  width: 32,
  height: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export type NotificationTypeAutocompleteOptionProps = {
  type: NotificationTypeModel;
};

export const NotificationTypeAutocompleteOption = ({ type }: NotificationTypeAutocompleteOptionProps): ReactElement => {
  const iconName = getIconName(type.icon);
  const iconColor = getIconColor(type.color as IconographyColors);

  return (
    <Stack alignItems="center" spacing={1} direction="row">
      <Iconography sx={imageSx} iconName={iconName} color={iconColor} />
      <LocalizedTypography variant="overline" color="text.secondary">
        {type.name}
      </LocalizedTypography>
    </Stack>
  );
};
