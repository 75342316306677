import { ChangeEvent, ReactElement, useState } from 'react';

import { portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import { translations } from '@halo-common/translations';
import { usePortfolioPositionSummaryQuery } from '@halo-data-sources/queries';
import { useAllPositionsTableColumns } from '@halo-modules/app';
import {
  GridColumnResizeParams,
  GridSortModel,
  HaloDataGrid,
  HaloDataGridProps,
  LocalizedFormControlLabel,
  LocalizedTypography,
  Stack,
} from '@halodomination/halo-fe-common';
import { Checkbox } from '@mui/material';
import { useAtom } from 'jotai';

const INACTIVE_POSITION_CLASS_NAME = 'inactive-position';

const DEFAULT_COLUMN_WIDTH_DICTIONARY = {
  templateDisplayName: undefined,
  shortName: undefined,
  cusip: undefined,
  isin: undefined,
  fundserv: undefined,
  issuerName: undefined,
  notional: undefined,
  marketValue: undefined,
  costBasis: undefined,
  marketChange: undefined,
  underlyingReturn: undefined,
  noteReturn: undefined,
  pctBreach: undefined,
  valuation: undefined,
  maturityDate: undefined,
};

const tableSx = {
  [`& .${INACTIVE_POSITION_CLASS_NAME}`]: {
    backgroundColor: 'grey.200',
  },
};

export type PortfolioAllPositionsTableProps = {
  loading: boolean;
};

export const PortfolioAllPositionsTable = ({ loading }: PortfolioAllPositionsTableProps): ReactElement => {
  const [positionsData, setPositionsData] = useAtom(portfolioPositionsManagerAtom);
  const [columnWidths, setColumnWidths] = useState<Record<string, number | undefined>>(DEFAULT_COLUMN_WIDTH_DICTIONARY);

  const { filters, query, sorting } = positionsData;
  const { includeInactive } = filters;

  const payload = { ...query, sort: sorting.allPositions };

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, isPending } = usePortfolioPositionSummaryQuery(payload);

  const pages = data?.pages ?? [];
  const rows = pages.flatMap((page) => page.positionSummaries.map((summary) => summary));
  const height = rows.length >= 15 ? 579 : 'auto';
  const tableDataLoading = loading || isPending || isFetchingNextPage;

  const columns: HaloDataGridProps['columns'] = useAllPositionsTableColumns({ columnWidths });

  const handleInactiveChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setPositionsData({ includeInactive: checked });
  };

  const handleSortChange = (updatedSortModel: GridSortModel) => {
    setPositionsData({ sorting: { allPositions: updatedSortModel } });
  };

  const handleRowsScrollEnd = () => {
    const loadMoreContent = !isFetchingNextPage && hasNextPage;
    if (loadMoreContent) void fetchNextPage();
  };

  const handleColumnWidthChange = (params: GridColumnResizeParams) => {
    const key = params.colDef.field;
    const width = params.width;
    const newColumnWidthDict = { ...columnWidths, [key]: width };
    setColumnWidths(newColumnWidthDict);
  };

  return (
    <Stack direction="column" xs={12} spacing={2}>
      <Stack direction="row" justify="space-between" alignItems="center">
        <LocalizedTypography variant="h6">
          {translations.portfolio.positions.allPositionsTableTitle}
        </LocalizedTypography>
        <LocalizedFormControlLabel
          control={<Checkbox checked={includeInactive} onChange={handleInactiveChange} />}
          label={translations.portfolio.positions.includeInactiveFilter}
        />
      </Stack>
      <HaloDataGrid
        height={height}
        noResultsMessage={translations.messages.noResults}
        rows={rows}
        getRowId={(row) => row.uid ?? row.id}
        getRowClassName={(data) => (!tableDataLoading && !data.row.isActive ? INACTIVE_POSITION_CLASS_NAME : '')}
        columns={columns}
        density="compact"
        loading={tableDataLoading}
        sortingMode="server"
        onSortModelChange={handleSortChange}
        onRowsScrollEnd={handleRowsScrollEnd}
        sx={tableSx}
        onColumnWidthChange={handleColumnWidthChange}
      />
    </Stack>
  );
};
