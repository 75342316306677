import { downloadFile } from '@halo-common/utils';
import { getDisclaimerAcceptanceRaw } from '@halo-data-sources/clients';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

export type UserDisclaimerAcceptanceDownloadMutationPayload = {
  filename?: string;
  disclaimerId?: string;
};

const userDisclaimerAcceptanceDownloadMutationFn = async (payload: UserDisclaimerAcceptanceDownloadMutationPayload) => {
  if (!payload.filename || !payload.disclaimerId) return;

  const { filename, disclaimerId } = payload;

  const response = await getDisclaimerAcceptanceRaw(disclaimerId);

  downloadFile({ fileName: filename, downloadFilePath: response });
};

export const useUserDisclaimerAcceptanceDownloadMutation = (): UseMutationResult<
  void,
  Error,
  UserDisclaimerAcceptanceDownloadMutationPayload
> =>
  useMutation<void, Error, UserDisclaimerAcceptanceDownloadMutationPayload>({
    mutationFn: userDisclaimerAcceptanceDownloadMutationFn,
  });
