import { QuoteValueMap, quoteValueMapAtom } from '@halo-atoms/quotes';
import { QuoteWebsocketEvent } from '@halo-common/hooks';
import { useWebSocketContext } from '@halo-common/providers';
import { getMostRecentQuotes, postNoteRFQ } from '@halo-data-sources/clients';
import { ApiQuoteMapper } from '@halo-data-sources/mappers';
import { ApiMostRecentQuotesResponseModel } from '@halo-data-sources/models';
import { QuoteQueryKeyFactory } from '@halo-data-sources/queries';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';

const TEN_MINUTE_TIMEOUT = 600000;

const quoteRefreshMutationFn = async (noteId?: number) => {
  if (!noteId) return null;

  const response = await postNoteRFQ(noteId);

  return response.id;
};

const getRecentQuotesQueryFn = async (
  ids?: Array<number>,
): Promise<ApiMostRecentQuotesResponseModel['data'] | null> => {
  if (!ids?.length) return null;

  const response = await getMostRecentQuotes(ids);

  return response?.data;
};

export const useQuoteRefreshMutation = (): UseMutationResult<number | null, Error, number | undefined> => {
  const queryClient = useQueryClient();

  const setQuoteValueMap = useSetAtom(quoteValueMapAtom);

  const { events } = useWebSocketContext();

  let quoteTimeout: NodeJS.Timeout;

  return useMutation<number | null, Error, number | undefined>({
    mutationFn: quoteRefreshMutationFn,
    onMutate: (noteId) => {
      if (!noteId) return undefined;

      const websocketCallback = ({ note_id }: QuoteWebsocketEvent) => {
        if (noteId === note_id) {
          const ids = [note_id];

          void Promise.resolve(
            queryClient.fetchQuery({
              queryKey: QuoteQueryKeyFactory.byNoteIds(ids),
              queryFn: () => getRecentQuotesQueryFn(ids),
            }),
          ).then((data) => {
            setQuoteValueMap((prev) => {
              if (!data) return prev;

              const recentQuotes = data.reduce((quoteValueMap: QuoteValueMap, { featured, note_id, quotes = [] }) => {
                const currentQuote = featured?.value;
                const updatedQuote = quoteValueMap[noteId]?.recent?.value;
                const quoteUpdated = currentQuote !== updatedQuote;

                const filteredPastQuotes = prev[note_id].past.filter((oldQuote) =>
                  quotes.every((quote) => quote.issuer_id !== oldQuote.issuerId),
                );

                return {
                  ...quoteValueMap,
                  [note_id]: {
                    updated: quoteUpdated,
                    recent: featured ? ApiQuoteMapper.toQuoteModel(featured) : null,
                    past: [...filteredPastQuotes, ...quotes.map(ApiQuoteMapper.toQuoteModel)],
                  },
                };
              }, prev);

              return recentQuotes;
            });
          });
        }
      };

      events.quote.add(noteId, websocketCallback);

      quoteTimeout = global.setTimeout(() => void events.quote.remove(noteId), TEN_MINUTE_TIMEOUT);
    },
    onError: (_, noteId) => {
      if (!noteId) return undefined;
      events.quote.remove(noteId);
      clearTimeout(quoteTimeout);
    },
  });
};
