import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiAssetIdExistenceModel,
  ApiTermsheetV2ExistenceResponseModel,
  ApiTermsheetV2ResponseModel,
} from '@halo-data-sources/models';

const TERMSHEET_NME_PATH = '/posttrade/nme';
const POST_TRADE_TERMSHEET_PATH = '/posttrade/termsheets';

export const queryAssetExistence = async (ids: Array<string>): Promise<Array<ApiAssetIdExistenceModel>> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_TERMSHEET_PATH);

  const path = `${clientConfiguration.basePath}/exists`;

  const response = await request<Array<ApiAssetIdExistenceModel>>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ids: ids,
    }),
  });

  return response;
};

export const getTermsheetExistence = async (ids: Array<number>): Promise<ApiTermsheetV2ExistenceResponseModel> => {
  const clientConfiguration = await getClientConfiguration(TERMSHEET_NME_PATH);

  const response = await request<ApiTermsheetV2ExistenceResponseModel>(`${clientConfiguration.basePath}/search`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    failOnError: false,
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      termsheet_ids: ids,
    }),
  });

  return response;
};

export const getTermsheet = async (id: number): Promise<ApiTermsheetV2ResponseModel> => {
  const clientConfiguration = await getClientConfiguration(TERMSHEET_NME_PATH);

  const response = await request<ApiTermsheetV2ResponseModel>(`${clientConfiguration.basePath}`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      termsheet_ids: [id],
    }),
  });

  return response;
};
