import { AllocationModel, CalendarModel, CalendarPageModel } from '@halo-common/models';
import { ApiAllocationModel } from '@halo-data-sources/models';
import { AdminCalendarStatusFilter } from '@halo-modules/admin';
import { HaloSliceState } from '@halo-stores/types';
import { DateTime } from 'luxon';

export enum CalendarStatusEnum {
  failureFetchAdminCalendarPages = 'failureFetchAdminCalendarPages',
  failureFetchCalendar = 'failureFetchCalendar',
  failureFetchCalendarPageIssuers = 'failureFetchCalendarPageIssuers',
  failureFetchFixOrderAndAllocation = 'failureFetchFixOrderAndAllocation',
  failureUploadCalendar = 'failureUploadCalendar',
  failureFetchCalendarAllocations = 'failureFetchCalendarAllocations',
  failureCreateCalendarAllocationRequest = 'failureCreateCalendarAllocationRequest',
  failureUpdateCalendarAllocationRequest = 'failureUpdateCalendarAllocationRequest',
  idle = 'idle',
  requestFetchAdminCalendarPages = 'requestFetchAdminCalendarPages',
  requestFetchCalendar = 'requestFetchCalendar',
  requestFetchCalendarPageIssuers = 'requestFetchCalendarPageIssuers',
  requestFetchFixOrderAndAllocation = 'requestFetchFixOrderAndAllocation',
  requestUploadCalendar = 'requestUploadCalendar',
  requestFetchCalendarAllocations = 'requestFetchCalendarAllocations',
  requestCreateCalendarAllocationRequest = 'requestCreateCalendarAllocationRequest',
  requestUpdateCalendarAllocationRequest = 'requestUpdateCalendarAllocationRequest',
  successFetchAdminCalendarPages = 'successFetchAdminCalendarPages',
  successFetchCalendar = 'successFetchCalendar',
  successFetchCalendarPageIssuers = 'successFetchCalendarPageIssuers',
  successFetchFixOrderAndAllocation = 'successFetchFixOrderAndAllocation',
  successUploadCalendar = 'successUploadCalendar',
  successFetchCalendarAllocations = 'successFetchCalendarAllocations',
  successCreateCalendarAllocationRequest = 'successCreateCalendarAllocationRequest',
  successUpdateCalendarAllocationRequest = 'successUpdateCalendarAllocationRequest',
}

export type CalendarStatus = keyof typeof CalendarStatusEnum;

export type CalendarQueryParams = {
  accountNumber?: string;
  cusip?: string;
  date?: DateTime | null;
  endDate?: DateTime | null;
  hasOrders?: boolean;
  hasFixOrders?: boolean;
  ip?: string;
  passedFomsCheck?: boolean;
  issuerId?: string | null;
  orderStatuses?: Array<AdminCalendarStatusFilter>;
  organization?: string;
  page?: number | null;
  pagesize?: number;
  startDate?: DateTime | null;
  status?: AdminCalendarStatusFilter;
  approved?: boolean | null;
};

export type CalendarPageQueryParams = {
  accountNumber?: string;
  approved?: boolean | null;
  closing?: DateTime | null;
  cusip?: string;
  hasOrders?: boolean;
  ip?: string;
  issuerId?: string | null;
  noteType?: string | null;
  noteTerm?: string | null;
  orderStatuses?: Array<AdminCalendarStatusFilter>;
  page?: number | null;
  parent?: boolean;
  passedFomsCheck?: boolean;
  startDate?: DateTime | null;
  endDate?: DateTime | null;
  status?: AdminCalendarStatusFilter;
};

export type CalendarIssuersQueryParams = {
  parent?: boolean;
};

export type CalendarSortingColumnNames = 'cusip' | 'expirationDate' | 'settleDate';

export type CalendarSortingDirections = 'desc' | 'asc' | null;

export type CalendarSorting = {
  field: CalendarSortingColumnNames;
  direction?: CalendarSortingDirections;
};

export type CalendarSortingOptions = Array<CalendarSorting>;

export type CalendarPageIssuerModel = {
  id: number;
  name: string;
};

export type CalendarAllocationModel = {
  account: number;
  quantity: number;
  attestation?: boolean;
};

export type FetchCalendarThunkResult = {
  calendar: CalendarModel;
};

export type FetchCalendarPageIssuersThunkAction = {
  calendarPageId?: number | null;
  params?: CalendarIssuersQueryParams;
};

export type FetchCalendarPageIssuersThunkResult = {
  issuers: Array<CalendarPageIssuerModel>;
};

export type FetchAdminCalendarPagesThunkResult = {
  pages: Array<CalendarPageModel>;
  parentChildPages: Array<CalendarPageModel>;
};

export type UploadCalendarThunkAction = {
  file: File;
};

export type FetchCalendarAllocationsThunkAction = {
  calendarId: string;
};

export type FetchCalendarAllocationsThunkResult = {
  allocations: Array<AllocationModel>;
};

export type CreateCalendarAllocationResponse = {
  calendar: CalendarModel;
};

export type UpdateCalendarAllocationResponse = {
  allocation: AllocationModel;
};

export type ApiUpdateCalendarAllocationThunkAction = {
  id: string;
  status: string;
  amount?: number;
};

export type ApiUpdateCalendarAllocationThunkResult = {
  allocation: ApiAllocationModel;
};

export type CalendarSliceState = {
  adminCalendarPages: Array<CalendarPageModel>;
  issuers: Array<CalendarPageIssuerModel>;
  parentChildPages: Array<CalendarPageModel>;
  selectedAllocation?: AllocationModel | null;
  selectedCalendar?: CalendarModel | null;
  allocations: Array<AllocationModel>;
  status: CalendarStatus;
};

export type CalendarState = CalendarSliceState & Omit<HaloSliceState, 'status'>;
