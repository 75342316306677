import { ReactElement } from 'react';

import { productDetailModalAnalyticsTabStateAtom } from '@halo-atoms/pdm';
import { NoteTypeEnum } from '@halo-common/enums';
import { useQuoteSelector } from '@halo-common/hooks';
import {
  ProductDetailModalAnalyticsRollingReturnsCouponTable,
  ProductDetailModalAnalyticsRollingReturnsFrequencyTable,
  ProductDetailModalAnalyticsRollingReturnsPerformanceTable,
  ProductDetailModalAnalyticsRollingReturnsPrincipalReturnTable,
  ProductDetailModalAnalyticsRollingReturnsTotalReturnsTable,
} from '@halo-common/layouts';
import { NoteModel } from '@halo-common/models';
import { useRollingReturnsStatisticsQuery } from '@halo-data-sources/queries';
import { LoadingIndicator, Stack, Tabs } from '@halodomination/halo-fe-common';
import { Box, Typography } from '@mui/material';
import { useAtom } from 'jotai';

const containerSx = { minHeight: 323 };

export type ProductDetailModalAnalyticsRollingReturnsTablesProps = {
  product?: NoteModel | null;
  loading?: boolean;
};

export const ProductDetailModalAnalyticsRollingReturnsTables = ({
  product,
  loading = false,
}: ProductDetailModalAnalyticsRollingReturnsTablesProps): ReactElement | null => {
  const [state, setState] = useAtom(productDetailModalAnalyticsTabStateAtom);

  const { endDate, startDate, selectedStatsTab } = state;

  const productId = product?.id;
  const productType = product?.type;
  const underliers = product?.tradables;

  const isRecall = product?.coupon?.isRecall;
  const isIssuerCall = product?.callInformation?.isIssuerCall;
  const isGeneralNote = productType === NoteTypeEnum.General;
  const isIncome = productType === NoteTypeEnum.Income;

  const basket = product?.tradableWeights;
  const isSingleBasket = Boolean(underliers?.length && underliers?.length === 1);
  const isWeightedBasket = Boolean(basket);
  const showTreeData = !isSingleBasket;

  const quote = useQuoteSelector(productId);

  const hasQuote = Boolean(quote);
  const showAgainstNote = hasQuote && !isIssuerCall && !isRecall && !isGeneralNote;

  let title = 'Worst-Of Underlying Basket';
  if (isSingleBasket) title = `${underliers?.[0].name}`;
  else if (isWeightedBasket) title = 'Weighted Underlying Basket';

  const filters = { endDate, startDate, basket, showAgainstNote };

  const { data, isPending, isFetched } = useRollingReturnsStatisticsQuery(product, filters);

  const noData = isFetched && !data;
  const isLoading = loading || isPending;
  const showCouponTab = Boolean(showAgainstNote && data?.coupon);

  const handleTabChange = (tab: number) => {
    setState((prev) => ({ ...prev, selectedStatsTab: tab }));
  };

  if (isLoading) {
    return (
      <Stack direction="column" sx={containerSx} spacing={1} alignItems="center" justify="center">
        <LoadingIndicator show />
        <Typography variant="caption">{`Analyzing Rolling Return Windows...`}</Typography>
      </Stack>
    );
  }

  if (noData) return null;

  const tabs = !isIncome
    ? ['Performance', 'Frequency', 'Principal Returned']
    : showCouponTab
      ? ['Coupon', 'Principal', 'Total Return']
      : ['Principal', 'Total Return'];

  const primaryTable = !isIncome ? (
    <ProductDetailModalAnalyticsRollingReturnsPerformanceTable
      key="ProductDetailModalAnalyticsRollingReturnsPerformanceTable"
      data={data}
      title={title}
      loading={isLoading}
      showTreeData={showTreeData}
      showAgainstNote={showAgainstNote}
    />
  ) : showCouponTab ? (
    <ProductDetailModalAnalyticsRollingReturnsCouponTable
      key="ProductDetailModalAnalyticsRollingReturnsCouponTable"
      data={data}
      loading={isLoading}
    />
  ) : (
    <ProductDetailModalAnalyticsRollingReturnsPrincipalReturnTable
      data={data}
      title={title}
      loading={isLoading}
      showTreeData={showTreeData}
      showAgainstNote={showAgainstNote}
      showAverageLife={isIncome}
    />
  );

  const secondaryTable = !isIncome ? (
    <ProductDetailModalAnalyticsRollingReturnsFrequencyTable
      data={data}
      title={title}
      loading={isLoading}
      showTreeData={showTreeData}
      showAgainstNote={showAgainstNote}
      showAverageLife={isIncome}
    />
  ) : showCouponTab ? (
    <ProductDetailModalAnalyticsRollingReturnsPrincipalReturnTable
      data={data}
      title={title}
      loading={isLoading}
      showTreeData={showTreeData}
      showAgainstNote={showAgainstNote}
      showAverageLife={isIncome}
    />
  ) : (
    <ProductDetailModalAnalyticsRollingReturnsTotalReturnsTable
      data={data}
      title={title}
      loading={isLoading}
      showTreeData={showTreeData}
      showAgainstNote={showAgainstNote}
      showAverageLife={isIncome}
    />
  );

  const ternaryTable = !isIncome ? (
    <ProductDetailModalAnalyticsRollingReturnsPrincipalReturnTable
      data={data}
      title={title}
      loading={isLoading}
      showTreeData={showTreeData}
      showAgainstNote={showAgainstNote}
      showAverageLife={isIncome}
    />
  ) : showCouponTab ? (
    <ProductDetailModalAnalyticsRollingReturnsTotalReturnsTable
      data={data}
      title={title}
      loading={isLoading}
      showTreeData={showTreeData}
      showAgainstNote={showAgainstNote}
      showAverageLife={isIncome}
    />
  ) : null;

  return (
    <Box sx={containerSx}>
      <Tabs
        tabs={tabs}
        variant="pills"
        onChange={handleTabChange}
        value={selectedStatsTab}
        slotProps={{ tab: { walkMeNamespace: 'wm-rr' } }}
        disableGutters
      >
        {primaryTable}
        {secondaryTable}
        {ternaryTable}
      </Tabs>
    </Box>
  );
};
