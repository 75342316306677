import { useUserInfoQuery } from '@halo-data-sources/queries';

export type MoneynessResult = Array<number | null | undefined>;
export type MoneynessOptions = number | Array<number | null | undefined> | null;

export const useMoneyness = (value?: MoneynessOptions): MoneynessResult => {
  const { data: user } = useUserInfoQuery();

  const moneynessEnabled = Boolean(user?.settings?.moneynessEnabled);

  if (!value) return [];

  if (Array.isArray(value)) {
    return moneynessEnabled ? value.map((v) => (typeof v === 'number' ? 100 - v : null)) : value;
  }

  return moneynessEnabled ? [100 - value] : [value];
};
