import { modalAtom } from '@halo-atoms/common';
import { CALENDAR_ALLOCATION_MODAL_NAME } from '@halo-common/modals';
import { BulkOrderTicketReceiptModel, OrderTicketReceiptModel } from '@halo-common/models';
import { OrderTicketValidationSwitchErrors } from '@halo-data-sources/switches';
import { createStepAtoms } from '@halodomination/halo-fe-common';
import { atom } from 'jotai';

export const ORDER_TICKET_VALIDATION_APPROVAL_MODAL = 'order_ticket_validation_approval_modal';
export const orderTicketValidationApprovalModalAtom = atom(
  (get) => get(modalAtom)[ORDER_TICKET_VALIDATION_APPROVAL_MODAL],
  (_, set, open?: boolean) => {
    set(modalAtom, { [ORDER_TICKET_VALIDATION_APPROVAL_MODAL]: Boolean(open) });
  },
);

export const ORDER_TICKET_CSV_UPLOAD_MODAL = 'order_ticket_csv_upload_modal';
export const orderTicketCSVUploadModalAtom = atom(
  (get) => get(modalAtom)[ORDER_TICKET_CSV_UPLOAD_MODAL],
  (_, set, open?: boolean) => {
    set(modalAtom, { [ORDER_TICKET_CSV_UPLOAD_MODAL]: Boolean(open) });
  },
);

export const orderTicketModalAtom = atom(
  (get) => get(modalAtom)[CALENDAR_ALLOCATION_MODAL_NAME],
  (_, set, open?: boolean) => {
    set(modalAtom, { [CALENDAR_ALLOCATION_MODAL_NAME]: Boolean(open) });
  },
);

export type bulkOrderTicketManagerModel = {
  pendingValidateAllocations: Array<number>;
  validatedAllocations: Array<number>;
  invalidAllocations: Array<number>;
  pendingSubmittedAllocations: Array<number>;
  submittedAllocations: Array<number>;
  failedAllocations: Array<number>;
  fixSubmissionErrors: OrderTicketValidationSwitchErrors;
  lastSubmittedOrder: BulkOrderTicketReceiptModel | null;
};

type orderTicketManagerPayload = {
  setPendingValidateAllocationIds?: Array<number>;
  validatedAllocationIds?: number;
  invalidAllocationIds?: number;
  fixSubmissionErrors?: OrderTicketValidationSwitchErrors;
  setPendingSubmittedAllocationIds?: Array<number>;
  submittedAllocationIds?: number;
  failedAllocationIds?: number;
  lastSubmittedOrder?: BulkOrderTicketReceiptModel;
};

const _pendingValidateAllocations = atom<Array<number>>([]);
const _validatedAllocations = atom<Array<number>>([]);
const _invalidAllocations = atom<Array<number>>([]);
const _pendingSubmittedAllocations = atom<Array<number>>([]);
const _submittedAllocations = atom<Array<number>>([]);
const _failedAllocations = atom<Array<number>>([]);
const _fixSubmissionErrors = atom<OrderTicketValidationSwitchErrors>({});
const _lastSubmittedOrder = atom<BulkOrderTicketReceiptModel | null>(null);
const _fixValidationErrors = atom<OrderTicketValidationSwitchErrors>({});

export const fixValidationErrorsAtom = atom(
  (get) => get(_fixValidationErrors),
  (_, set, payload?: OrderTicketValidationSwitchErrors) => {
    if (payload) set(_fixValidationErrors, (prev) => ({ ...prev, ...payload }));
    else set(_fixValidationErrors, {});
  },
);

export const bulkOrderTicketManagerAtom = atom(
  (get) => ({
    pendingValidateAllocations: get(_pendingValidateAllocations),
    validatedAllocations: get(_validatedAllocations),
    invalidAllocations: get(_invalidAllocations),
    pendingSubmittedAllocations: get(_pendingSubmittedAllocations),
    submittedAllocations: get(_submittedAllocations),
    failedAllocations: get(_failedAllocations),
    fixSubmissionErrors: get(_fixSubmissionErrors),
    lastSubmittedOrder: get(_lastSubmittedOrder),
  }),
  (_, set, payload?: orderTicketManagerPayload) => {
    const setPendingValidateAllocationIds = payload?.setPendingValidateAllocationIds;
    const validatedAllocationIds = payload?.validatedAllocationIds;
    const invalidAllocationIds = payload?.invalidAllocationIds;
    const setPendingSubmittedAllocationIds = payload?.setPendingSubmittedAllocationIds;
    const submittedAllocationIds = payload?.submittedAllocationIds;
    const failedAllocationIds = payload?.failedAllocationIds;
    const fixSubmissionErrors = payload?.fixSubmissionErrors;
    const lastSubmittedOrder = payload?.lastSubmittedOrder;

    if (setPendingValidateAllocationIds) set(_pendingValidateAllocations, () => setPendingValidateAllocationIds);
    if (validatedAllocationIds) set(_validatedAllocations, (prev) => [...prev, validatedAllocationIds]);
    if (invalidAllocationIds) set(_invalidAllocations, (prev) => [...prev, invalidAllocationIds]);
    if (setPendingSubmittedAllocationIds) set(_pendingSubmittedAllocations, () => setPendingSubmittedAllocationIds);
    if (submittedAllocationIds) set(_submittedAllocations, (prev) => [...prev, submittedAllocationIds]);
    if (failedAllocationIds) set(_failedAllocations, (prev) => [...prev, failedAllocationIds]);
    if (fixSubmissionErrors) set(_fixSubmissionErrors, (prev) => ({ ...prev, ...fixSubmissionErrors }));
    if (lastSubmittedOrder) set(_lastSubmittedOrder, lastSubmittedOrder);

    if (!payload) {
      set(_pendingValidateAllocations, []);
      set(_validatedAllocations, []);
      set(_invalidAllocations, []);
      set(_pendingSubmittedAllocations, []);
      set(_submittedAllocations, []);
      set(_failedAllocations, []);
      set(_fixSubmissionErrors, {});
      set(_lastSubmittedOrder, null);
    }
  },
);

export const fixTimeoutErrorAtom = atom<boolean>(false);

const _fixTimeoutAtom = atom<NodeJS.Timeout | null>(null);

export const setFixTimeoutAtom = atom(null, (get, set, timeout?: NodeJS.Timeout) => {
  if (timeout) set(_fixTimeoutAtom, timeout);
  else {
    const fixTimeout = get(_fixTimeoutAtom);
    if (fixTimeout) clearTimeout(fixTimeout);
    set(_fixTimeoutAtom, null);
  }
});

const _bulkOrdersAtom = atom<Array<BulkOrderTicketReceiptModel>>([]);

export const bulkOrderTicketReceiptAtom = atom(
  (get) => ({
    bulkOrders: get(_bulkOrdersAtom),
    totalShares: get(_bulkOrdersAtom).reduce((total, bulkOrder) => total + Number(bulkOrder.order.shares), 0),
  }),
  (_, set, payload?: BulkOrderTicketReceiptModel) => {
    if (!payload) set(_bulkOrdersAtom, []);
    else set(_bulkOrdersAtom, (prev) => [...prev, payload]);
  },
);

export const orderTicketReceiptAtom = atom<OrderTicketReceiptModel | null>(null);

export const orderTicketStepAtom = createStepAtoms({ max: 2 });

export const resetBulkOrderTicketAtom = atom(null, (_, set) => {
  set(fixValidationErrorsAtom);
  set(bulkOrderTicketManagerAtom);
  set(bulkOrderTicketReceiptAtom);
  set(setFixTimeoutAtom);
  set(fixTimeoutErrorAtom, false);
  set(orderTicketStepAtom.handleResetAtom);
});
