import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { SMAAllocationModalFormModel } from '@halo-modules/app';
import {
  CurrencyMapperOptions,
  LocalizedTypography,
  mapNumberToLocalCurrency,
  Stack,
} from '@halodomination/halo-fe-common';
import { Divider, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export type SMAAllocationTotalRowProps = {
  rules?: {
    maxNotional?: number | null;
    inventoryRemaining?: number | null;
  };
};

export const SMAAllocationTotalRow = ({ rules }: SMAAllocationTotalRowProps): ReactElement => {
  const { watch } = useFormContext<SMAAllocationModalFormModel>();

  const allocations = watch('allocations');

  const allocationTotal = allocations.reduce((total: number, { amount }) => total + Number(amount), 0);

  const enableInventoryText = typeof rules?.inventoryRemaining === 'number';
  const formattedAllocationTotal = mapNumberToLocalCurrency(allocationTotal ?? 0, {
    trailingZeroDisplay: 'stripIfInteger',
  } as CurrencyMapperOptions);
  const inventoryRemainingFormatted = mapNumberToLocalCurrency(rules?.inventoryRemaining ?? 0, {
    trailingZeroDisplay: 'stripIfInteger',
  } as CurrencyMapperOptions);

  const sx = {
    display: 'flex',
    flexDirection: 'row-reverse',
  };

  const isOverAllocated = typeof rules?.inventoryRemaining === 'number' && allocationTotal > rules.inventoryRemaining;
  const totalAllocationColor = isOverAllocated ? 'error' : 'common.black';

  const inventoryComponent = enableInventoryText ? (
    <Stack direction="row" spacing={3}>
      <Divider orientation="vertical" />
      <Stack direction="column" alignItems="flex-end" spacing={1}>
        <Typography variant="h6">{inventoryRemainingFormatted}</Typography>
        <LocalizedTypography variant="overline" color="text.secondary">
          {translations.allocationsModal.fixedIncome.inventoryRemainingMessage}
        </LocalizedTypography>
      </Stack>
    </Stack>
  ) : null;

  return (
    <Stack direction="row" spacing={3}>
      <Stack direction="column">
        <LocalizedTypography variant="caption" color="text.secondary" sx={sx}>
          {translations.common.totalAmount}
        </LocalizedTypography>
        <Typography variant="h6" color={totalAllocationColor} sx={sx}>
          {formattedAllocationTotal}
        </Typography>
      </Stack>
      {inventoryComponent}
    </Stack>
  );
};
