import { ReactElement } from 'react';

import { COMBINED_DATE_TIME_ZONE_FORMAT } from '@halo-common/constants';
import { useLiveTime } from '@halo-common/hooks';
import { OrderTicketFormModelFields } from '@halo-common/modals';
import { Collapsible, Stack } from '@halodomination/halo-fe-common';
import { TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export type OrderDetailSectionProps = {
  bulk?: boolean;
};

export const OrderDetailSection = ({ bulk }: OrderDetailSectionProps): ReactElement => {
  const { register, formState } = useFormContext<OrderTicketFormModelFields>();

  const { errors } = formState;
  const orderFromError = errors?.orderFrom?.message;
  const hasOrderFromError = Boolean(orderFromError);

  const orderFromFieldProps = register('orderFrom');

  const orderDate = useLiveTime();
  const orderDateText = orderDate.toFormat(COMBINED_DATE_TIME_ZONE_FORMAT);

  return (
    <Collapsible title="Order Details" defaultExpanded alwaysExpanded={bulk}>
      <Stack direction="row" spacing={2} xs={4} wrap="wrap">
        <Stack direction="column">
          <Typography variant="caption">Order Receipt Date & Time</Typography>
          <Typography variant="h6">{orderDateText}</Typography>
        </Stack>
        <TextField
          {...orderFromFieldProps}
          label="Order Received From"
          fullWidth
          size="large"
          helperText={orderFromError}
          error={hasOrderFromError}
        />
      </Stack>
    </Collapsible>
  );
};
