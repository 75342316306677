export * from './OrderBook';
export { default } from './OrderBook';

export * from './DashboardOrderBookAuctionsTable';
export * from './DashboardOrderBookCalendarsTable';
export * from './DashboardOrderBookJanneyTable';
export * from './DashboardOrderBookPershingTable';

export const orderBookWidgetPageSizeOptions = [5, 10, 25, 50, 100];

export const orderBookWidgetDataGridProps = {
  pagination: true,
  paginationMode: 'server',
  sortingMode: 'server',
  density: 'standard',
  disableRowSelectionOnClick: true,
  pageSizeOptions: orderBookWidgetPageSizeOptions,
} as const;
