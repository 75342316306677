import { ChangeEvent, ReactElement, useEffect } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { ContentHider } from '@halo-common/components';
import { OrganizationCapacityEnum, OrganizationCapacityLabelEnum } from '@halo-common/enums';
import { BulkOrderTicketFormFields } from '@halo-common/schemas';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { MenuItem, TextField } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Controller, useFormContext } from 'react-hook-form';

export const CapacityTypeDropdown = (): ReactElement => {
  const { data: user } = useUserInfoQuery();
  const { control, formState, watch, getValues, setValue } = useFormContext();

  const selectedCalendar = useAtomValue(calendarSelectedAtom);

  const { errors } = formState;
  const capacityType = watch('capacityType');

  const capacities = user?.organization?.capacities;
  const capacityTypeError = errors?.capacityType?.message as string;
  const allocations = getValues('allocations') as BulkOrderTicketFormFields['allocations'];
  const quantity = allocations.reduce((prev, { quantity }) => prev + (quantity ?? 0), 0);

  const agentPrincipleSelectHandler = () => {
    const notePrice = selectedCalendar?.note?.price ?? 0;
    const fieldValue = quantity * 10 * (100 - notePrice);
    const options = { shouldValidate: true, shouldDirty: true };

    setValue('compensation', fieldValue.toString(), options);
  };

  useEffect(() => {
    const isSalesCredit = capacityType === OrganizationCapacityEnum.PRINCIPAL;
    if (isSalesCredit) agentPrincipleSelectHandler();
  }, [capacityType]);

  return (
    <ContentHider hook="react:soe:capacity-type-dropdown">
      <Controller
        render={({ field: { ref, onChange, ...field } }) => {
          const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
            onChange(ev);

            const value = ev.target.value as OrganizationCapacityEnum;
            const isSalesCredit = value === OrganizationCapacityEnum.PRINCIPAL;

            if (isSalesCredit) agentPrincipleSelectHandler();
          };

          return (
            <TextField
              {...field}
              inputRef={ref}
              fullWidth
              size="large"
              select
              label="Principal or Agent"
              onChange={handleChange}
              helperText={capacityTypeError}
              error={Boolean(capacityTypeError)}
              slotProps={{
                inputLabel: {
                  shrink: capacityType !== null,
                },
              }}
            >
              {capacities?.map((capacity) => (
                <MenuItem key={capacity} value={capacity}>
                  {OrganizationCapacityLabelEnum[capacity]}
                </MenuItem>
              ))}
            </TextField>
          );
        }}
        control={control}
        name="capacityType"
      />
    </ContentHider>
  );
};
