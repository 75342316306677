import { ReactElement } from 'react';

import { ActionButton } from '@halo-common/components';
import { Stack } from '@halodomination/halo-fe-common';
import { useMediaQuery, useTheme } from '@mui/material';

export type CreateWatchlistModalFooterProps = {
  loading?: boolean;
  onSubmit: () => void;
};

export const CreateWatchlistModalFooter = ({
  loading = false,
  onSubmit,
}: CreateWatchlistModalFooterProps): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints?.down('md'));

  return (
    <Stack direction="row" justify="flex-end">
      <ActionButton
        className="wm-new-watchlist-modal-create-button"
        disabled={loading}
        loading={loading}
        type="submit"
        onClick={onSubmit}
        variant="contained"
        size="large"
        color="primary"
        fullWidth={isMobile}
      >
        Create
      </ActionButton>
    </Stack>
  );
};
