import { ReactElement, useEffect, useState } from 'react';

import { FormBuilderField, ModalFormBuilder } from '@halo-common/components';
import { AssetIdentifierEnum, SortModelDirectionEnum, SortModelNullSortEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { ApiAssetIdentifierEnum, ApiComparisonOptionEnum } from '@halo-data-sources/enums';
import { useCreateTermsheetRepoDocMutation } from '@halo-data-sources/mutations';
import { useTermsheetRepoDocsQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import {
  Iconography,
  LocalizedAlert,
  LocalizedButton,
  LocalizedLink,
  LocalizedTypography,
} from '@halodomination/halo-fe-common';
import type { SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';

import { TermsheetFormSchema, TermsheetModalForm, useTermsheetToDefaultValues } from '../TermsheetModalForm';

const alertSx = { backgroundColor: 'success.background', marginBottom: 5 };

const requiredText = 'This field is required.';

type PrimaryIdentifierFormSchema = {
  asset_identifier: string;
};

export const CreateTermsheetModal = (): ReactElement | null => {
  const [open, setOpen] = useState(false);
  const [identifier, setIdentifier] = useState('');
  const { data: userInfo } = useUserInfoQuery();

  const primaryAssetIdentifier = userInfo?.whiteLabel?.primaryAssetIdentifier || AssetIdentifierEnum.CUSIP;

  const field = `asset_identifiers.${ApiAssetIdentifierEnum[primaryAssetIdentifier].toLowerCase()}`;

  const termsheetQuery = {
    page: 1,
    page_length: 1,
    sort: [{ field, direction: SortModelDirectionEnum.asc, nulls: SortModelNullSortEnum.first }],
    comparisons: [{ field, op: ApiComparisonOptionEnum.ILIKE, value: identifier }],
  };

  const searchByPrimaryIdentifierMutation = useTermsheetRepoDocsQuery(termsheetQuery, { enabled: false });

  const createMutation = useCreateTermsheetRepoDocMutation({
    onSuccess: () => {
      createMutation.reset();
      setOpen(false);
      setIdentifier('');
    },
  });

  const onClose = () => {
    createMutation.reset();
    setOpen(false);
    setIdentifier('');
  };

  const matchedTermsheet = searchByPrimaryIdentifierMutation.data?.results[0];
  const defaultValues = useTermsheetToDefaultValues(matchedTermsheet);

  let modalForm: ReactElement;

  if (searchByPrimaryIdentifierMutation.isSuccess) {
    const tmplink = matchedTermsheet?.document?.tmplink;

    const matchingTermsheet = tmplink ? (
      <LocalizedLink href={tmplink} target="_blank">
        {translations.documents.termsheets.matchingTermsheet}
      </LocalizedLink>
    ) : (
      <LocalizedTypography sx={{ font: 'inherit', lineHeight: 'inherit' }} component="span">
        {translations.documents.termsheets.matchingTermsheet}
      </LocalizedTypography>
    );

    const alert = matchedTermsheet ? (
      <LocalizedAlert
        severity="success"
        variant="filled"
        icon={<Iconography iconName="thumbs-up" color="inherit" />}
        dynamicContent={{
          matchingTermsheet,
        }}
        sx={alertSx}
      >
        {translations.documents.termsheets.weFoundMatchingTermsheet}
      </LocalizedAlert>
    ) : null;

    const createTermsheet: SubmitHandler<TermsheetFormSchema> = (fields) => {
      createMutation.mutate(fields);
    };

    const fallbackDefaultValues = { asset_identifiers: { [primaryAssetIdentifier]: identifier } };

    modalForm = (
      <TermsheetModalForm
        title={translations.documents.termsheets.uploadNewTermsheet}
        open={open}
        onClose={onClose}
        onSubmit={createTermsheet}
        isPending={createMutation.isPending}
        defaultValues={defaultValues || fallbackDefaultValues}
      >
        {alert}
      </TermsheetModalForm>
    );
  } else {
    const primaryIdentifierFormFields: FormBuilderField[] = [];

    if (primaryAssetIdentifier) {
      primaryIdentifierFormFields.push({
        label: `Search by ${primaryAssetIdentifier.toUpperCase()}`,
        name: 'asset_identifier',
        type: 'text',
        validation: yup.string().required(requiredText),
        required: true,
      });
    }

    const searchByPrimaryIdentifier: SubmitHandler<PrimaryIdentifierFormSchema> = ({ asset_identifier }) => {
      setIdentifier(asset_identifier);
    };

    modalForm = (
      <ModalFormBuilder<PrimaryIdentifierFormSchema>
        title={translations.documents.termsheets.uploadNewTermsheet}
        open={open}
        onClose={onClose}
        onSubmit={searchByPrimaryIdentifier}
        isPending={createMutation.isPending}
        fields={primaryIdentifierFormFields}
        submitLabel={translations.common.continue}
      />
    );
  }

  useEffect(() => {
    if (identifier) void searchByPrimaryIdentifierMutation.refetch();
  }, [identifier]);

  return (
    <>
      <LocalizedButton
        variant="contained"
        color="primary"
        size="medium"
        startIcon={<Iconography iconName="upload" color="inherit" />}
        onClick={() => setOpen(true)}
        data-testid="upload-termsheet-button"
      >
        {translations.documents.termsheets.uploadNew}
      </LocalizedButton>
      {modalForm}
    </>
  );
};
