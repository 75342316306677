import { AuctionAdminModel, IssuerModel, QuoteAuctionModel } from '@halo-common/models';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

export const getParticipatingIssuerQuote = (
  issuerName: string,
  auction: AuctionAdminModel,
): { quote?: QuoteAuctionModel; issuer?: IssuerModel } => {
  const { issuers: auctionIssuers = [], quotes = [] } = auction;

  const participatingIssuer = auctionIssuers.find((issuer) => issuer?.name?.toLowerCase() === issuerName);
  const participatingIssuerQuote = participatingIssuer
    ? quotes?.find((quote) => quote?.issuer?.name?.toLowerCase() === issuerName)
    : undefined;

  return {
    quote: participatingIssuerQuote,
    issuer: participatingIssuer,
  };
};

export const getIssuerCellStatusFromAuctionDetails = (
  issuer?: IssuerModel,
  quote?: QuoteAuctionModel,
): { passed: boolean; comment: boolean; winner: boolean; clickable: boolean } => {
  const passed = issuer?.passed;
  const approved = quote?.approved;
  const isWinner = quote?.isWinningQuote;

  const showPassedContent = Boolean(passed && !isWinner);
  const showWinnerContent = Boolean(isWinner && !passed);

  const isClickableCell = Boolean(issuer && !passed && !approved && !isWinner);
  const comment = showPassedContent ? issuer?.reason : quote?.comment?.comment;
  const showCommentContent = Boolean(comment && isClickableCell);

  return {
    comment: showCommentContent,
    passed: showPassedContent,
    winner: showWinnerContent,
    clickable: isClickableCell,
  };
};

export const getIssuerCellClassName = ({
  field,
  row,
}: GridRenderCellParams<AuctionAdminModel, AuctionAdminModel>): string => {
  const issuerName = field.toLowerCase();

  const { issuer, quote } = getParticipatingIssuerQuote(issuerName, row);

  const { passed, comment, winner, clickable } = getIssuerCellStatusFromAuctionDetails(issuer, quote);

  if (passed) return 'issuer-passed';
  else if (comment) return 'issuer-comment';
  else if (winner) return 'issuer-winner';
  else if (!clickable) return 'issuer-non-participant';
  else return 'issuer-empty';
};
