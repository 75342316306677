import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

export type ProductApprovalQueryKeys = {
  all: QueryKeyFactoryFn;
  products: QueryKeyFactoryFn;
};

export const ProductApprovalQueryKeyFactory: ProductApprovalQueryKeys = {
  all: () => ['product', 'approval'],
  products: (query) => [...ProductApprovalQueryKeyFactory.all(), 'products', hash(query)],
};
