import { ReactElement, useEffect } from 'react';

import { navigateParentTo } from '@halo-common/utils';
import { useLoginUserMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { ForgotPasswordPageMeta } from '@halo-pages/app/onboarding/forgot-password';
import { Stack } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, CircularProgress, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Maybe, object as yupObject, string as yupString } from 'yup';

const forgotPasswordLinkSx = {
  fontSize: 14,
};

const schema = yupObject().shape({
  email: yupString().email('Please enter a valid email address.').required('Please enter a valid email address.'),
  password: yupString().required('This field is required.'),
  next: yupString().notRequired(),
});

const loginResolver = yupResolver<LoginFormSchema>(schema);

export type LoginFormSchema = {
  email: string;
  password: string;
  next?: Maybe<string | undefined>;
};

export type LoginFormProps = {
  onError: (val: boolean) => void;
};

export const LoginForm = ({ onError }: LoginFormProps): ReactElement => {
  const { data: loginResult, mutate: onLogin, isSuccess, isError, error } = useLoginUserMutation();

  const { data: userInfo } = useUserInfoQuery();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const destination = loginResult?.destination;
  const userFacingName = userInfo?.whiteLabel.userFacingName;
  const loginText = `Log in to ${userFacingName}`;

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<LoginFormSchema>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    resolver: loginResolver,
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { ref: emailRef, ...emailProps } = register('email');
  const { ref: passwordRef, ...passwordProps } = register('password');

  const onSubmit = (data: LoginFormSchema) => {
    const urlParams = new URLSearchParams(window.parent.location.search);
    const nextUrl = urlParams.get('next') ?? '';

    onLogin({
      username: data.email,
      password: data.password,
      next: nextUrl,
    });
  };

  const forgotPasswordNavigationHandler = () => void navigateParentTo(`/react${ForgotPasswordPageMeta.route}`);
  const getStartedNavigationHandler = () => void navigateParentTo('/register');

  const errorMessage = error?.message ?? 'You entered an incorrect email, password, or both.';

  const errorAlert = isError ? (
    <Alert severity="error" variant="filled">
      <Typography variant="body2" component="span">
        {errorMessage}
      </Typography>
    </Alert>
  ) : null;

  useEffect(() => {
    if (isError) onError(true);
    else if (isSuccess) navigateParentTo(process.env.NEXT_PUBLIC_LOCAL_REDIRECT || destination);
  }, [isSuccess, isError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={4}>
        <Stack direction="column" spacing={3}>
          <Typography align="center" component="h4" variant="h4">
            {loginText}
          </Typography>
          {errorAlert}
          <TextField
            fullWidth
            size="large"
            label="Email Address"
            inputRef={emailRef}
            {...emailProps}
            error={Boolean(errors?.email)}
            helperText={errors?.email?.message}
          />
          <Stack direction="column" spacing={3}>
            <TextField
              fullWidth
              size="large"
              label="Password"
              type="password"
              inputRef={passwordRef}
              {...passwordProps}
              error={Boolean(errors?.password)}
              helperText={errors?.password?.message}
            />
            <Button sx={forgotPasswordLinkSx} color="primary" onClick={forgotPasswordNavigationHandler}>
              Forgot Password?
            </Button>
          </Stack>
        </Stack>
        <Stack direction="column" spacing={3}>
          <Button
            startIcon={isSubmitting && <CircularProgress size="1rem" />}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
          >
            Log In
          </Button>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body2">Don&apos;t have an account?</Typography>
            <Button
              disabled={isSubmitting}
              type="button"
              color="primary"
              variant="outlined"
              fullWidth={isMobile}
              onClick={getStartedNavigationHandler}
            >
              Get Started
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};
