import { ChangeEvent, ReactElement } from 'react';

import {
  complianceApprovalAccountSearchAtom,
  complianceApprovalCusipSearchAtom,
  ComplianceApprovalFilters,
  complianceApprovalFiltersAtom,
  complianceApprovalIPSearchAtom,
  complianceApprovalQueryAtom,
} from '@halo-atoms/complianceApproval';
import { DateRangePicker } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { useComplianceApprovalIssuersQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedMenuItem, LocalizedTextField } from '@halodomination/halo-fe-common';
import { MenuItem, Stack, StackProps, TextField } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useAtom, useAtomValue } from 'jotai';
import { DateTime } from 'luxon';

const itemSx = { flexBasis: { md: '20%', xs: '100%' }, width: '100%' };
const containerDirection: StackProps['direction'] = { md: 'row', xs: 'column' };
const containerAlignment = { md: 'center', xs: 'flex-start' };

export const ComplianceApprovalFiltersBar = (): ReactElement => {
  const [account, setAccount] = useAtom(complianceApprovalAccountSearchAtom);
  const [cusip, setCusip] = useAtom(complianceApprovalCusipSearchAtom);
  const [ip, setIP] = useAtom(complianceApprovalIPSearchAtom);
  const [filters, setFilters] = useAtom(complianceApprovalFiltersAtom);
  const complianceApprovalQuery = useAtomValue(complianceApprovalQueryAtom);

  const { data: userInfo } = useUserInfoQuery();

  const calendarPageId = userInfo?.organization?.calendarPageId;
  const query = { ...complianceApprovalQuery, calendarPageId };

  const { data: issuers } = useComplianceApprovalIssuersQuery(query, { enabled: Boolean(userInfo) });

  const startDate = filters.expirationStartDate?.toISO() ?? null;
  const endDate = filters.expirationEndDate?.toISO() ?? null;

  const handleAccountChange = (ev: ChangeEvent<HTMLInputElement>) => setAccount(ev.target.value);
  const handleCusipChange = (ev: ChangeEvent<HTMLInputElement>) => setCusip(ev.target.value);
  const handleIPChange = (ev: ChangeEvent<HTMLInputElement>) => setIP(ev.target.value);

  const handleIssuerChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    setFilters((prev) => ({ ...prev, issuer: value }));
  };

  const handleDateChange = (value: DateRange<DateTime>) => {
    const startDate = value[0];
    const endDate = value[1];

    const filters: Partial<ComplianceApprovalFilters> = {};

    if (startDate) filters.expirationStartDate = startDate;
    else filters.expirationStartDate = null;

    if (endDate) filters.expirationEndDate = endDate;
    else filters.expirationEndDate = null;

    setFilters((prev) => ({ ...prev, ...filters }));
  };

  return (
    <Stack direction={containerDirection} alignItems={containerAlignment} spacing={2}>
      <TextField sx={itemSx} label="Issuer" fullWidth select value={filters.issuer} onChange={handleIssuerChange}>
        <LocalizedMenuItem value={translations.common.all}>{translations.common.all}</LocalizedMenuItem>
        {issuers?.map((issuer) => (
          <MenuItem key={issuer.id} value={issuer.id}>
            {issuer.name}
          </MenuItem>
        ))}
      </TextField>
      <LocalizedTextField
        sx={itemSx}
        label={translations.common.account}
        fullWidth
        value={account}
        onChange={handleAccountChange}
      />
      <LocalizedTextField sx={itemSx} label="Search by IP" fullWidth value={ip} onChange={handleIPChange} />
      <LocalizedTextField sx={itemSx} label="Search by CUSIP" fullWidth value={cusip} onChange={handleCusipChange} />
      <DateRangePicker label="Expiration Date" onChange={handleDateChange} startDate={startDate} endDate={endDate} />
    </Stack>
  );
};
