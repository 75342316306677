import { ReactElement, useEffect, useRef, useState } from 'react';

import { ActionButton } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { LocalizedTextField, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';

const searchByIdFieldSx = {
  width: 225,
};

const inputLabelProps = {
  shrink: true,
};

export type RollingReturnsSearchFieldProps = {
  onSelect: (id: string) => void;
  loading: boolean;
  productId?: number | null;
  error?: boolean;
};

export const RollingReturnsSearchField = ({
  onSelect,
  loading,
  productId,
  error = false,
}: RollingReturnsSearchFieldProps): ReactElement => {
  const viewInputRef = useRef<HTMLInputElement | null>(null);

  const [formInvalid, setFormInvalid] = useState(false);

  const productIdSearchParam = new URL(parent.location.href)?.searchParams?.get('productId');
  const queryProductId = productId ?? productIdSearchParam ?? null;

  const isSearchInvalid = error || formInvalid;
  const searchErrorMessage = isSearchInvalid ? translations.analytics.rollingReturns.searchByIdInvalidMessage : '';

  const handleSearch = () => {
    const inputValue = viewInputRef?.current?.value;

    setFormInvalid(!inputValue);

    if (inputValue) onSelect(inputValue);
  };

  const handleChange = () => {
    if (formInvalid) setFormInvalid(false);
  };

  useEffect(() => {
    if (queryProductId && viewInputRef.current) viewInputRef.current.value = queryProductId as string;
  }, [queryProductId, viewInputRef.current]);

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="column">
        <LocalizedTypography variant="h6">{translations.analytics.rollingReturns.findByIDTitle}</LocalizedTypography>
        <LocalizedTypography variant="caption" color="textSecondary">
          {translations.analytics.rollingReturns.findByIDSubTitle}
        </LocalizedTypography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <LocalizedTextField
          inputRef={viewInputRef}
          sx={searchByIdFieldSx}
          helperText={searchErrorMessage}
          error={isSearchInvalid}
          slotProps={{ inputLabel: inputLabelProps }}
          label={translations.analytics.rollingReturns.findByIDSearchLabel}
          onChange={handleChange}
        />
        <ActionButton variant="contained" color="primary" onClick={handleSearch} loading={loading}>
          {translations.common.view}
        </ActionButton>
      </Stack>
    </Stack>
  );
};
