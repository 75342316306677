import { MouseEvent, ReactElement, useEffect, useState } from 'react';

import { anchorElAtom } from '@halo-atoms/common';
import { ActionButton } from '@halo-common/components';
import { ProductTypeEnum } from '@halo-common/enums';
import { WatchlistPopover, watchlistTypeaheadSelectedAtom } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { useWatchlistNoteQuery, useWatchlistTargetedNoteQuery, useWatchlistsQuery } from '@halo-data-sources/queries';
import { Iconography } from '@halodomination/halo-fe-common';
import { useAtom, useSetAtom } from 'jotai';
import { uniqBy } from 'lodash';

export type WatchlistButtonProps = {
  productId: number;
  productType: ProductTypeEnum;
};

export const WatchlistButton = ({ productId, productType }: WatchlistButtonProps): ReactElement => {
  const [selectedTab, setSelectedTab] = useState(0);

  const watchlistsQuery = useWatchlistsQuery();
  const watchlistNoteQuery = useWatchlistNoteQuery(productId, productType);
  const watchlistTargetedNoteQuery = useWatchlistTargetedNoteQuery(productId);

  const loading = watchlistsQuery.isPending || watchlistNoteQuery.isPending || watchlistTargetedNoteQuery.isPending;
  const isWatched = Boolean(watchlistNoteQuery.data?.length);
  const targetLevel = watchlistTargetedNoteQuery.data?.target?.targetLevel;

  const [anchorElMap, setAnchorElMap] = useAtom(anchorElAtom);
  const setSelectedWatchlists = useSetAtom(watchlistTypeaheadSelectedAtom);

  const anchorEl = anchorElMap[productId];
  const open = Boolean(anchorEl);

  const isTargeted = typeof targetLevel === 'number';
  const isSaved = isWatched || isTargeted;
  const productIsSaved = !loading && isSaved;

  const watchIcon = isWatched ? 'check' : 'eye';
  const watchText = isWatched ? translations.common.watching : translations.common.watch;
  const watchColor = productIsSaved ? 'success' : 'primary';
  const watchIconColor = productIsSaved ? 'success.main' : 'primary.main';

  const buttonIcon = isTargeted ? 'bullseye-arrow' : watchIcon;
  const buttonText = isTargeted ? translations.components.watchlistPopoverTargetingMessage : watchText;

  const dynamicContent = isTargeted ? { targetedQuote: targetLevel.toFixed(2) } : undefined;

  const walkMeClassName = isTargeted ? 'wm-set-target-button' : 'wm-add-to-watchlist-button';

  const startIcon = !loading ? <Iconography iconName={buttonIcon} color={watchIconColor} /> : undefined;

  const handleOpenPopover = (ev: MouseEvent<HTMLButtonElement>) => {
    ev?.stopPropagation();
    setAnchorElMap({ [productId]: ev.currentTarget });
    if (watchlistNoteQuery.data) setSelectedWatchlists(uniqBy(watchlistNoteQuery.data, 'id'));
  };

  const handleClosePopover = (ev: MouseEvent<HTMLButtonElement>) => {
    ev?.stopPropagation();
    setAnchorElMap({ [productId]: null });
  };

  const handleTabChange = (tab: number) => setSelectedTab(tab);

  useEffect(() => {
    const isTargetedTab = selectedTab === 1;
    const showTargetedTab = isTargeted && open;
    const showWatchlistTab = isTargetedTab && open;
    if (showTargetedTab) setSelectedTab(1);
    else if (showWatchlistTab) setSelectedTab(0);
  }, [isTargeted, open]);

  return (
    <>
      <ActionButton
        className={walkMeClassName}
        startIcon={startIcon}
        onClick={handleOpenPopover}
        dynamicContent={dynamicContent}
        type="button"
        variant="text"
        color={watchColor}
        disabled={loading}
        loading={loading}
      >
        {buttonText}
      </ActionButton>
      <WatchlistPopover
        open={open}
        anchorEl={anchorEl}
        productId={productId}
        productType={productType}
        onClose={handleClosePopover}
        selectedTab={selectedTab}
        onTabChange={handleTabChange}
      />
    </>
  );
};
