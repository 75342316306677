import { DocumentUserFile } from '@halo-common/models';
import { downloadFile } from '@halo-common/utils';
import { getUserFileRaw } from '@halo-data-sources/clients';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

const userFileDownloadMutationFn = async (payload?: DocumentUserFile) => {
  if (!payload) return;

  const response = await getUserFileRaw(payload.id);

  downloadFile({ fileName: payload.document.filename, downloadFilePath: response });
};

export const useUserFileDownloadMutation = (): UseMutationResult<void, Error, DocumentUserFile> =>
  useMutation<void, Error, DocumentUserFile>({
    mutationFn: userFileDownloadMutationFn,
  });
