import { IssuerModel } from '@halo-common/models';
import { getOrderBookAuctionIssuers } from '@halo-data-sources/clients';
import { ApiIssuerMapper } from '@halo-data-sources/mappers';
import { OrderBookAuctionQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getOrderBookAuctionIssuersFn = async () => {
  const response = await getOrderBookAuctionIssuers();
  const issuers = response.rows?.map((entry) => ApiIssuerMapper.toIssuerModel(entry.issuer));

  return issuers;
};

export const useOrderBookAuctionIssuersQuery = (): UseQueryResult<Array<IssuerModel>, Error> =>
  useQuery<Array<IssuerModel>, Error>({
    queryKey: OrderBookAuctionQueryKeyFactory.issuers(),
    queryFn: () => getOrderBookAuctionIssuersFn(),
  });
