import { atom } from 'jotai';
import { DateTime } from 'luxon';

export type AdminAuctionUpdateFormDates = {
  endDate?: DateTime;
  strikeDate?: DateTime;
  tradeDate?: DateTime;
  settleDate?: DateTime;
};

export const adminAuctionUpdateFormDatesAtom = atom<AdminAuctionUpdateFormDates>({
  endDate: DateTime.now(),
  strikeDate: DateTime.now(),
  tradeDate: DateTime.now(),
  settleDate: DateTime.now(),
});
