import { ReactElement, ReactNode } from 'react';

import { Alert } from '@mui/material';

const statusStyling = {
  success: { backgroundColor: 'success.background' },
  warning: { backgroundColor: 'warning.background' },
  error: { backgroundColor: 'error.background' },
};

export type OrderTicketAlertProps = {
  children: ReactNode;
  status: 'success' | 'warning' | 'error';
};

export const OrderTicketAlert = ({ children, status }: OrderTicketAlertProps): ReactElement => {
  const OrderTicketAlertStyling = statusStyling[status];

  return (
    <Alert severity={status} variant="outlined" sx={OrderTicketAlertStyling}>
      {children}
    </Alert>
  );
};
