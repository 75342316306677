import { GetEducationHubDetailsRequestModel } from '@halo-common/models';
import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type EducationHubQueryKeys = {
  all: QueryKeyFactoryFn;
  admin: QueryKeyFactoryFn;
  adminCSV: QueryKeyFactoryFn;
};

export const EducationHubQueryKeyFactory: EducationHubQueryKeys = {
  all: () => ['education'],
  admin: (filters: GetEducationHubDetailsRequestModel) => [...EducationHubQueryKeyFactory.all(), 'hub', hash(filters)],
  adminCSV: (filters: GetEducationHubDetailsRequestModel) => [...EducationHubQueryKeyFactory.admin(filters), 'csv'],
};
