import { ReactElement } from 'react';

import { Iconography, IconographyProps, useCombinedStyling } from '@halodomination/halo-fe-common';
import { Box, Radio } from '@mui/material';

export type WatchlistContentFormRadioGroupButtonProps = {
  icon: IconographyProps['iconName'];
  value: IconographyProps['iconName'];
  loading?: boolean;
};

export const WatchlistContentFormRadioGroupButton = ({
  icon,
  value,
  loading = false,
}: WatchlistContentFormRadioGroupButtonProps): ReactElement => {
  const radioSx = { p: 0 };

  const disabledColor = 'grey.400';
  const iconColor = loading ? disabledColor : 'text.secondary';
  const selectedIconColor = loading ? disabledColor : 'primary.dark';
  const selectedBackgroundColor = loading ? 'rgba(200, 200, 200, 0.2)' : 'primary.background';

  const iconSx = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '1.5rem',
    width: '1.5rem',
  };

  const iconContainerSx = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '2.75rem',
    width: '2.75rem',
  };

  const selectedContainerSx = useCombinedStyling(iconContainerSx, {
    borderColor: selectedIconColor,
    backgroundColor: selectedBackgroundColor,
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
  });

  return (
    <Radio
      disabled={loading}
      sx={radioSx}
      checkedIcon={
        <Box sx={selectedContainerSx}>
          <Iconography sx={iconSx} size="1.5x" iconName={icon} prefix="fal" color={selectedIconColor} />
        </Box>
      }
      icon={
        <Box sx={iconContainerSx}>
          <Iconography sx={iconSx} size="1.5x" iconName={icon} prefix="fal" color={iconColor} />
        </Box>
      }
      value={value}
    />
  );
};
