export enum ConfirmationEnum {
  Yes = 'yes',
  No = 'no',
}

export enum SortModelDirectionEnum {
  asc = 'asc',
  desc = 'desc',
}

export enum SortModelNullSortEnum {
  first = 'first',
  last = 'last',
}
