import { ReactElement } from 'react';

import { myOpenOrdersPershingSearchFieldsAtom } from '@halo-atoms/dashboard';
import { GridPaginationModel } from '@halo-common/models';
import { useOrderBookPershingQuery } from '@halo-data-sources/queries';
import {
  OrderBookPershingApproveOrderModal,
  OrderBookPershingCancelOrderModal,
  orderBookWidgetDataGridProps,
  useOrderBookPershingTableColumns,
} from '@halo-modules/app';
import { HaloDataGrid } from '@halodomination/halo-fe-common';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { useAtom } from 'jotai';

export interface DashboardOrderBookPershingTableProps {
  loading?: boolean;
}

export const DashboardOrderBookPershingTable = ({
  loading = false,
}: DashboardOrderBookPershingTableProps): ReactElement => {
  const [searchFields, setSearchFields] = useAtom(myOpenOrdersPershingSearchFieldsAtom);

  const { data, ...query } = useOrderBookPershingQuery(searchFields);

  const isLoading = loading || query.isPending || query.isPlaceholderData;

  const rows = data?.details || [];
  const page = data?.pagination?.page ?? searchFields.page;
  const pageSize = data?.pagination?.resultsPerPage ?? searchFields.pageSize;
  const totalResults = data?.pagination?.totalResults ?? 0;
  const paginationModel = { page: page - 1, pageSize };

  const handlePaginationChange = (model: GridPaginationModel) => {
    setSearchFields((previous) => ({
      ...previous,
      page: model.page + 1,
      pageSize: model.pageSize,
    }));
  };

  const handleSortChange = (model: GridSortModel) => {
    setSearchFields((previous) => ({
      ...previous,
      sortBy: model,
    }));
  };

  const columns = useOrderBookPershingTableColumns();
  const filteredColumns = columns.filter((column) => !['user', 'category'].includes(column.field));

  return (
    <>
      <HaloDataGrid
        {...orderBookWidgetDataGridProps}
        columns={filteredColumns}
        rows={rows}
        loading={isLoading}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationChange}
        onSortModelChange={handleSortChange}
        rowCount={totalResults}
      />
      <OrderBookPershingApproveOrderModal />
      <OrderBookPershingCancelOrderModal />
    </>
  );
};
