import { ReactElement, ReactNode, useMemo } from 'react';

import { Box } from '@mui/material';

export interface RowProps {
  columns: number[];
  children: ReactNode;
}

export const Row = ({ columns, children }: RowProps): ReactElement => {
  const gridTemplateColumns = columns.map((col) => `minmax(0, ${col}fr)`).join(' ');
  const sx = useMemo(
    () => ({
      display: 'grid',
      gridTemplateColumns: gridTemplateColumns,
      gap: '8px',
    }),
    [gridTemplateColumns],
  );
  return <Box sx={sx}>{children}</Box>;
};
