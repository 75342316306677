import { ReactNode } from 'react';

import { DocumentBucketModel, WhiteLabelImageModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { Iconography, LocalizedTypography } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, SxProps, Typography } from '@mui/material';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

// Refs: https://halodomination.atlassian.net/browse/HADT-16684
const successBorder = '#9DCED1';
const errorBorder = '#F9A19A';

const hoverSx = {
  borderColor: 'primary.main',
};

const rootSx: SxProps = {
  background: 'transparent',
  border: (theme) => `2px dashed ${(theme as HaloTheme).palette.common.black}00026`,
  borderRadius: 2,
  color: 'text.secondary',
  cursor: 'pointer',
  display: 'flex',
  gap: '10px',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 280,
  marginX: 'auto !important',
  textAlign: 'center',
  paddingY: 2,
  paddingX: 6,
  outline: 'none',
  transition: '0.15s ease-in-out',
  transitionProperty: 'border-color, background-color, color',
  '&[data-state="error"]': {
    backgroundColor: 'error.background',
    borderColor: errorBorder,
    color: 'error.main',
  },
  '&[data-state="success"]': {
    backgroundColor: 'success.background',
    borderColor: successBorder,
    color: 'success.main',
  },
  '&:hover': hoverSx,
  '&:focus-visible': hoverSx,
  '&:focus-within': hoverSx,
  '&[data-drag-active="true"]': {
    backgroundColor: 'background.mask',
    borderColor: 'primary.main',
    color: 'primary.main',
  },
};

const nameSx = {
  wordBreak: 'break-word',
};

const actionSx = {
  textDecoration: 'underline',
};

interface DropzoneProps extends DropzoneOptions {
  file?: WhiteLabelImageModel | DocumentBucketModel | File | null;
  error?: ReactNode;
  sx?: SxProps;
}

export const Dropzone = ({ file, error: passedError, multiple = false, sx, ...props }: DropzoneProps) => {
  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({ multiple, ...props });

  const containerSx = { ...rootSx, ...sx };

  const error = passedError || fileRejections?.[0]?.errors?.[0]?.message;
  const actionMessage = file
    ? translations.profile.documents.replaceFromComputer
    : translations.profile.documents.chooseFromComputer;

  let state = '';
  if (file) state = 'success';
  if (error) state = 'error';

  const iconName = file ? 'file-check' : 'file-upload';
  const fileName =
    (file as WhiteLabelImageModel)?.fileName ||
    (file as DocumentBucketModel)?.filename ||
    (file as File)?.name ||
    'Unknown file';

  const fileNameLabel = file ? (
    <Typography variant="body1" sx={nameSx}>
      {fileName}
    </Typography>
  ) : (
    <LocalizedTypography variant="body1">{translations.profile.documents.dragAndDropFileOr}</LocalizedTypography>
  );

  const errorMessage = error ? <LocalizedTypography variant="body2">{error}</LocalizedTypography> : null;

  return (
    <Box sx={containerSx} {...getRootProps()} data-drag-active={isDragActive} data-state={state}>
      <input {...getInputProps()} />
      <Iconography prefix="fal" iconName={iconName} color="inherit" size="2x" />
      {fileNameLabel}
      <LocalizedTypography sx={actionSx} color="primary">
        {actionMessage}
      </LocalizedTypography>
      {errorMessage}
    </Box>
  );
};
