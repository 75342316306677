import { CourseManagerAction, courseManagerAtom } from '@halo-atoms/education';
import { EducationModuleGroup, QuizAttemptAnswerModel } from '@halo-common/models';
import { getEducationModuleGroup, updateEducationQuizProgress } from '@halo-data-sources/clients';
import { ApiEducationMapper } from '@halo-data-sources/mappers';
import { EducationModuleGroupsQueryResult, EducationQueryKeyFactory } from '@halo-data-sources/queries';
import { QuizSchema } from '@halo-modules/app';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

export type EducationQuizSubmissionMutationPayload = {
  quizId?: number;
  answers?: QuizSchema;
};

export type EducationQuizSubmissionMutationResult = {
  quizId: number;
  overallCompletion: number;
  passed: boolean;
  attempt: QuizAttemptAnswerModel;
  group: EducationModuleGroup;
};

const postEducationQuizSubmissionFn = async (
  quizId?: number,
  answers?: QuizSchema,
  group?: EducationModuleGroup | null,
) => {
  if (typeof quizId !== 'number' || !group || !answers) return null;

  const { response } = await updateEducationQuizProgress(quizId, answers);
  const attempt = ApiEducationMapper.toSubmittedAnswerModel(response.quiz, response.attempt.submitted_answers);

  const { group: groupResponse } = await getEducationModuleGroup(group.id);
  const updatedGroup = ApiEducationMapper.toModuleGroup(groupResponse, group.position);

  return {
    quizId,
    overallCompletion: Math.floor(response.pct_complete),
    passed: response.passed,
    attempt,
    group: updatedGroup,
  };
};

export const useEducationQuizSubmissionMutation = (): UseMutationResult<
  EducationQuizSubmissionMutationResult | null,
  Error,
  EducationQuizSubmissionMutationPayload
> => {
  const [courseProgress, setCourseProgress] = useAtom(courseManagerAtom);

  const groupProgress = courseProgress.group;
  const moduleProgress = courseProgress.module;
  const stepProgress = courseProgress.step;

  const queryClient = useQueryClient();

  return useMutation<EducationQuizSubmissionMutationResult | null, Error, EducationQuizSubmissionMutationPayload>({
    mutationFn: ({ quizId, answers }) => postEducationQuizSubmissionFn(quizId, answers, groupProgress),
    onSettled: (data) => {
      if (!data) return undefined;
      const key = EducationQueryKeyFactory.quizAttempts(data.quizId);
      void queryClient.invalidateQueries({ queryKey: key, exact: true, refetchType: 'active' });
    },
    onSuccess: (data) => {
      if (!data) return undefined;

      const key = EducationQueryKeyFactory.moduleGroups();

      queryClient.setQueryData<EducationModuleGroupsQueryResult>(key, (prev) => {
        if (!prev?.groups) return undefined;

        const { group, quizId, overallCompletion, passed } = data;

        const groups = prev?.groups ? [...prev.groups] : [];
        const foundGroupIndex = groups?.findIndex(({ id }) => id === group?.id) ?? -1;
        const foundGroup = { ...groups?.[foundGroupIndex] };

        const foundModules = foundGroup?.modules;
        const foundModuleIndex = foundModules.findIndex(({ steps }) => steps.some(({ id }) => id === quizId)) ?? -1;
        const foundModule = { ...foundModules?.[foundModuleIndex] };

        const foundSteps = foundModule?.steps;
        const foundStepIndex = foundSteps.findIndex(({ id }) => id === quizId) ?? -1;
        const foundStep = { ...foundSteps?.[foundStepIndex] };

        const updatedStep = { ...foundStep, completed: passed };

        const updatedModule = { ...foundModule };
        updatedModule.steps.splice(foundStepIndex, 1, updatedStep);

        const updatedGroup = { ...foundGroup, ...data.group };
        updatedGroup.modules.splice(foundModuleIndex, 1, updatedModule);

        const updatedGroups = [...prev.groups];
        updatedGroups.splice(foundGroupIndex, 1, updatedGroup);

        const updatedProgress: Partial<CourseManagerAction['progress']> = { overallCompletion };

        if (groupProgress?.id === foundGroup.id) updatedProgress.group = updatedGroup;
        if (moduleProgress?.id === foundModule.id) updatedProgress.module = updatedModule;
        if (stepProgress?.id === foundStep.id) updatedProgress.step = updatedStep;

        setCourseProgress({ progress: updatedProgress, type: 'set' });

        return { ...prev, groups: updatedGroups };
      });
    },
  });
};
