import { ApiFixEventTypeEnum } from '@halo-data-sources/enums';
import {
  mapApiFixOrderWebsocketCancellationResultToPershingState,
  mapApiFixOrderWebsocketSubmissionResultToPershingState,
  updateCalendarAllocations,
} from '@halo-data-sources/mappers';
import { ApiFixOrderWebsocketResult } from '@halo-data-sources/models';
import { AdminCalendarAllocationFilledStatusEnum } from '@halo-modules/admin';
import { CalendarActions } from '@halo-stores/Calendar';
import { BulkFillFixOrderThunkResult, FixActions } from '@halo-stores/Fix';
import { WebsocketActions } from '@halo-stores/Websocket';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { PershingState, PershingStatusEnum, PershingTotalsMap, SelectCalendarActionPayload } from './PershingModel';
import {
  downloadPershingCalendarCsv,
  fetchCalendarsForExecutionHubInfiniteScroll,
  fetchExecutionHubCalendars,
  fetchLoadingDockCalendars,
  updatePershingCalendar,
} from './PershingThunk';

const INITIAL_TOTALS = {
  available: 0,
  confirmed: 0,
  denied: 0,
  filled: 0,
  archived: 0,
  pending: 0,
};

const INITIAL_CALENDAR_STATUS_MAP = {
  available: [],
  archived: [],
  filled: [],
  confirmed: [],
  denied: [],
  pending: [],
};

const INITIAL_CALENDAR_MAP = {
  calendars: [],
  filteredTotals: null,
  total: 0,
};

const INITIAL_ORDERBOOK_MAP = {
  status: PershingStatusEnum.idle,
  fixOrderQueue: [],
  allocations: {
    calendarAllocations: [],
    pagination: null,
    canceledOrderId: null,
  },
};

const INITIAL_STATE: PershingState = {
  orderBook: INITIAL_ORDERBOOK_MAP,
  calendars: INITIAL_CALENDAR_STATUS_MAP,
  childPageCalendarMap: INITIAL_CALENDAR_MAP,
  filteredTotals: null,
  fixOrderQueue: [],
  parentPageCalendarMap: INITIAL_CALENDAR_MAP,
  pendingCalendars: INITIAL_CALENDAR_MAP,
  selectedAllocation: null,
  selectedAccount: null,
  selectedCalendar: null,
  status: PershingStatusEnum.idle,
  totals: INITIAL_TOTALS,
  allocationTotals: INITIAL_TOTALS,
  visibleParentCalendarIds: [],
};

export const PershingDuck = createSlice({
  name: 'Pershing',
  initialState: INITIAL_STATE,
  reducers: {
    handleWebsocketEvent(state, action: PayloadAction<ApiFixOrderWebsocketResult>) {
      const payload = action?.payload;

      const { allocation, event, order } = payload ?? {};
      const { selectedCalendar, fixOrderQueue, calendars, status } = state;

      const matchesQueuedFixOrder = fixOrderQueue.some(
        ({ orderId }) => orderId === order?.id || orderId === allocation?.id,
      );

      if (!matchesQueuedFixOrder) return state;

      const isFillErrorEventType = event === ApiFixEventTypeEnum.DONTKNOWTRADEDK;
      const isFillEventType = event === ApiFixEventTypeEnum.TRADE && status !== PershingStatusEnum.failureFillFixOrder;
      const isCancelEventType = event === ApiFixEventTypeEnum.CANCELED;
      const isSubmissionEventType = event === ApiFixEventTypeEnum.PENDING_NEW;

      const foundOrder = fixOrderQueue.find(({ orderId }) => orderId === order?.id || orderId === allocation?.id);
      const updatedFixOrderQueue = fixOrderQueue.filter(
        ({ orderId }) => orderId !== order?.id && orderId !== allocation?.id,
      );

      if (isFillErrorEventType) {
        const updatedCalendars = updateCalendarAllocations(
          state.calendars,
          AdminCalendarAllocationFilledStatusEnum.warning,
          foundOrder?.calendarId,
        );

        return {
          ...state,
          status: PershingStatusEnum.failureFillOrderWebsocketEvent,
          calendars: updatedCalendars ?? state.calendars,
          fixOrderQueue: updatedFixOrderQueue,
        };
      } else if (isFillEventType) {
        const updatedCalendars = updateCalendarAllocations(
          state.calendars,
          AdminCalendarAllocationFilledStatusEnum.filled,
          foundOrder?.calendarId,
          [{ id: payload.allocation.id }],
        );

        return {
          ...state,
          status: updatedFixOrderQueue.length ? state.status : PershingStatusEnum.successFillOrderWebsocketEvent,
          calendars: updatedCalendars ?? state.calendars,
          fixOrderQueue: updatedFixOrderQueue,
        };
      } else if (isCancelEventType) {
        const updatedState = mapApiFixOrderWebsocketCancellationResultToPershingState(payload, selectedCalendar);

        return {
          ...state,
          status: PershingStatusEnum.successCancelOrderWebsocketEvent,
          selectedCalendar: updatedState?.updatedCalendar ?? state.selectedCalendar,
          selectedAllocation: updatedState?.updatedAllocation ?? state.selectedAllocation,
          fixOrderQueue: updatedFixOrderQueue,
        };
      } else if (isSubmissionEventType) {
        return {
          ...state,
          ...mapApiFixOrderWebsocketSubmissionResultToPershingState(payload, calendars, selectedCalendar),
          fixOrderQueue: updatedFixOrderQueue,
        };
      }

      return state;
    },
    handleOrderBookWebsocketEvent(state, action) {
      const payload = action?.payload;

      const { allocation, event } = payload ?? {};
      const { orderBook } = state;

      const pendingCancelEvent = event === ApiFixEventTypeEnum.PENDING_CANCEL;
      const cancelEvent = event === ApiFixEventTypeEnum.CANCELED;
      const handleEvent = pendingCancelEvent || cancelEvent;
      if (!handleEvent) return state;

      const matchesQueuedFixOrder = orderBook.fixOrderQueue.some(({ orderId }) => orderId === allocation?.id);
      if (!matchesQueuedFixOrder || !allocation) return state;

      const updatedFixOrderQueue = orderBook.fixOrderQueue.filter(({ orderId }) => orderId !== allocation.id);

      return {
        ...state,
        orderBook: {
          ...state.orderBook,
          status: PershingStatusEnum.successCancelOrderWebsocketEvent,
          fixOrderQueue: updatedFixOrderQueue,
        },
      };
    },
    addToOrderBookOrderQueue(state, action) {
      return {
        ...state,
        orderBook: {
          ...state.orderBook,
          status: PershingStatusEnum.idle,
          fixOrderQueue: [...state.orderBook.fixOrderQueue, action?.payload],
        },
      };
    },
    removeFromOrderBookOrderQueue(state, action) {
      const removeAllocationId = action?.payload.allocationId;
      const fixOrderQueue = state.orderBook.fixOrderQueue;
      const updatedFixOrderQueue = fixOrderQueue.filter(({ orderId }) => orderId !== removeAllocationId);

      return {
        ...state,
        orderBook: {
          ...state.orderBook,
          status: PershingStatusEnum.idle,
          fixOrderQueue: updatedFixOrderQueue,
        },
      };
    },

    setFillOrderPending(state, action) {
      const { calendars } = state;

      const updatedCalendars = updateCalendarAllocations(
        calendars,
        AdminCalendarAllocationFilledStatusEnum.websocket,
        action?.payload.calendarId,
      );

      return {
        ...state,
        calendars: updatedCalendars ?? calendars,
      };
    },
    setFillOrderFailure(state, action) {
      const { calendars } = state;

      const updatedCalendars = updateCalendarAllocations(
        calendars,
        AdminCalendarAllocationFilledStatusEnum.warning,
        action?.payload.calendarId,
      );

      return {
        ...state,
        calendars: updatedCalendars ?? calendars,
        selectedCalendar: INITIAL_STATE.selectedCalendar,
      };
    },
    resetError(state) {
      return {
        ...state,
        error: INITIAL_STATE.error,
      };
    },
    resetStatus(state) {
      return {
        ...state,
        status: INITIAL_STATE.status,
      };
    },
    resetOrder(state) {
      return {
        ...state,
        error: INITIAL_STATE.error,
        status: INITIAL_STATE.status,
        selectedCalendar: INITIAL_STATE.selectedCalendar,
        selectedAllocation: INITIAL_STATE.selectedAllocation,
        selectedAccount: INITIAL_STATE.selectedAccount,
      };
    },
    selectAllocation(state, action) {
      return {
        ...state,
        error: INITIAL_STATE.error,
        selectedCalendar: action?.payload?.calendar,
        selectedAllocation: action?.payload?.allocation,
      };
    },
    selectCalendar(state, action: SelectCalendarActionPayload) {
      return {
        ...state,
        error: INITIAL_STATE.error,
        selectedCalendar: action?.payload?.calendar,
      };
    },
    selectAccount(state, action) {
      return {
        ...state,
        selectedAccount: action?.payload?.account,
      };
    },
    removeFromOrderQueue(state, action) {
      return {
        ...state,
        fixOrderQueue: state.fixOrderQueue.filter((order) => order.calendarId !== action.payload.calendarId),
      };
    },
    updateOrderQueue(state, action) {
      const orders = action?.payload.orders ?? [];

      return {
        ...state,
        fixOrderQueue: [...state.fixOrderQueue, ...orders],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadPershingCalendarCsv.pending, (state) => {
        state.status = PershingStatusEnum.requestDownloadPershingCalendarCsv;
      })
      .addCase(downloadPershingCalendarCsv.rejected, (state) => {
        state.status = PershingStatusEnum.failureDownloadPershingCalendarCsv;
      })
      .addCase(downloadPershingCalendarCsv.fulfilled, (state) => {
        state.status = PershingStatusEnum.successDownloadPershingCalendarCsv;
      })
      .addCase(updatePershingCalendar.pending, (state) => {
        state.status = PershingStatusEnum.requestUpdatePershingCalendar;
      })
      .addCase(updatePershingCalendar.rejected, (state, action) => {
        state.status = PershingStatusEnum.failureUpdatePershingCalendar;
        state.error = action?.payload?.message;
      })
      .addCase(updatePershingCalendar.fulfilled, (state, action) => {
        state.status = PershingStatusEnum.successUpdatePershingCalendar;
        state.error = INITIAL_STATE.error;

        state.calendars = action.payload.calendars;
      })
      .addCase(fetchCalendarsForExecutionHubInfiniteScroll.pending, (state) => {
        state.status = PershingStatusEnum.requestFetchExecutionHubCalendars;
      })
      .addCase(fetchCalendarsForExecutionHubInfiniteScroll.rejected, (state, action) => {
        state.status = PershingStatusEnum.failureFetchExecutionHubCalendars;
        state.error = action?.payload?.message;
      })
      .addCase(fetchCalendarsForExecutionHubInfiniteScroll.fulfilled, (state, action) => {
        state.status = PershingStatusEnum.successFetchExecutionHubCalendars;
        state.error = INITIAL_STATE.error;

        state.calendars = { ...state.calendars, ...action.payload.calendars };
      })
      .addCase(fetchExecutionHubCalendars.pending, (state) => {
        state.status = PershingStatusEnum.requestFetchExecutionHubCalendars;
      })
      .addCase(fetchExecutionHubCalendars.rejected, (state, action) => {
        state.status = PershingStatusEnum.failureFetchExecutionHubCalendars;
        state.error = action?.payload?.message;
      })
      .addCase(fetchExecutionHubCalendars.fulfilled, (state, action) => {
        state.status = PershingStatusEnum.successFetchExecutionHubCalendars;
        state.error = INITIAL_STATE.error;

        const payload = action?.payload;

        state.calendars = { ...state.calendars, ...payload?.calendars };
        state.totals = { ...state.totals, ...payload?.totals };
        state.filteredTotals = payload?.filteredTotals ?? null;
      })
      .addCase(fetchLoadingDockCalendars.pending, (state) => {
        state.status = PershingStatusEnum.requestFetchLoadingDockCalendars;
      })
      .addCase(fetchLoadingDockCalendars.rejected, (state, action) => {
        state.status = PershingStatusEnum.failureFetchLoadingDockCalendars;
        state.error = action?.payload?.message;
      })
      .addCase(fetchLoadingDockCalendars.fulfilled, (state, action) => {
        state.status = PershingStatusEnum.successFetchLoadingDockCalendars;
        state.error = INITIAL_STATE.error;

        const calendars = action?.payload?.calendars;
        const totals = action?.payload?.totals;
        const filteredTotals = action?.payload?.filteredTotals;

        state.filteredTotals = (filteredTotals as PershingTotalsMap) ?? null;

        state.calendars = {
          ...state.calendars,
          available: calendars?.available ?? state.calendars.available,
          archived: calendars?.archived ?? state.calendars.archived,
        };
        state.totals = {
          ...state.totals,
          available: totals?.available ?? state.totals.available,
          archived: totals?.archived ?? state.totals.archived,
        };
        state.pendingCalendars = {
          ...state.pendingCalendars,
          calendars: calendars?.pending ?? state.pendingCalendars.calendars,
          filteredTotal: filteredTotals?.pending ?? state.pendingCalendars.filteredTotal,
          total: totals?.pending ?? state.pendingCalendars.total,
        };
      })
      .addCase(CalendarActions.fetchCalendar.pending, (state) => {
        state.status = PershingStatusEnum.requestFetchCalendar;
      })
      .addCase(CalendarActions.fetchCalendar.rejected, (state, action) => {
        state.status = PershingStatusEnum.failureFetchCalendar;
        state.error = action?.payload?.message;

        state.selectedCalendar = INITIAL_STATE.selectedCalendar;
      })
      .addCase(CalendarActions.fetchCalendar.fulfilled, (state, action) => {
        state.status = PershingStatusEnum.successFetchCalendar;
        state.error = INITIAL_STATE.error;

        state.selectedCalendar = action?.payload.calendar;
      })
      .addCase(CalendarActions.uploadCalendar.pending, (state) => {
        state.status = PershingStatusEnum.requestUploadCalendar;
      })
      .addCase(CalendarActions.uploadCalendar.rejected, (state, action) => {
        state.status = PershingStatusEnum.failureUploadCalendar;
        state.error = action?.payload?.message;
      })
      .addCase(CalendarActions.uploadCalendar.fulfilled, (state) => {
        state.status = PershingStatusEnum.successUploadCalendar;
        state.error = INITIAL_STATE.error;
      })
      .addCase(FixActions.cancelFixOrder.fulfilled, (state, action) => {
        state.status = PershingStatusEnum.successFetchFixOrder;
        state.error = INITIAL_STATE.error;

        state.fixOrderQueue = [...state.fixOrderQueue, { orderId: action.payload.fixOrder.id }];
      })
      .addCase(FixActions.fillFixOrder.rejected, (state) => {
        state.status = PershingStatusEnum.failureFillFixOrder;
      })
      .addCase(FixActions.fillFixOrder.fulfilled, (state, action) => {
        state.status = PershingStatusEnum.successFillFixOrder;

        state.fixOrderQueue = [...state.fixOrderQueue, { orderId: action.payload.fixOrder.id }];
      })
      .addCase(FixActions.fetchFixOrderAndAllocation.pending, (state) => {
        state.status = PershingStatusEnum.requestFetchFixOrderAndAllocation;
      })
      .addCase(FixActions.fetchFixOrderAndAllocation.rejected, (state) => {
        state.status = PershingStatusEnum.failureFetchFixOrderAndAllocation;

        state.selectedAllocation = INITIAL_STATE.selectedAllocation;
      })
      .addCase(FixActions.fetchFixOrderAndAllocation.fulfilled, (state, action) => {
        state.status = PershingStatusEnum.successFetchFixOrderAndAllocation;

        state.selectedAllocation = action?.payload.allocation;
      })
      .addCase(FixActions.bulkFillFixOrder.pending, (state) => {
        state.status = PershingStatusEnum.requestBulkFillFixOrder;
      })
      .addCase(FixActions.bulkFillFixOrder.rejected, (state, action) => {
        state.status = PershingStatusEnum.failureBulkFillFixOrder;

        const payload = action?.payload as BulkFillFixOrderThunkResult;

        state.fixOrderQueue = INITIAL_STATE.fixOrderQueue;
        state.calendars = payload?.calendars ?? state.calendars;
      })
      .addCase(FixActions.bulkFillFixOrder.fulfilled, (state, action) => {
        state.status = PershingStatusEnum.successBulkFillFixOrder;
        state.error = INITIAL_STATE.error;

        state.calendars = action?.payload.calendars ?? state.calendars;
      })
      .addCase(WebsocketActions.handleTimeout, (state) => {
        state.status = PershingStatusEnum.websocketTimeout;
        state.fixOrderQueue = INITIAL_STATE.fixOrderQueue;
      });
  },
});
