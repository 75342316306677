import { ReactElement } from 'react';

import { detailPanelExpandedRowIdListAtom, educationHubFiltersAtom } from '@halo-atoms/education';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { UserRoleEnum } from '@halo-common/enums';
import { useEducationHubUserRoleMutation } from '@halo-data-sources/mutations';
import { useEducationHubUsersQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import {
  EducationHubPercentageCell,
  EducationHubTableDetailPanel,
  EducationHubTableInfoHeader,
  EducationHubTableSwitch,
} from '@halo-modules/admin';
import { GridColDef, HaloDataGrid, HaloDataGridProps, Iconography } from '@halodomination/halo-fe-common';
import { useAtom } from 'jotai';
import { DateTime } from 'luxon';

export const EducationHubTable = (): ReactElement => {
  const [filters, setFilters] = useAtom(educationHubFiltersAtom);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useAtom(detailPanelExpandedRowIdListAtom);

  const { data: userInfo } = useUserInfoQuery();
  const { data, isPending, isPlaceholderData } = useEducationHubUsersQuery(filters);
  const { mutate } = useEducationHubUserRoleMutation(filters);

  const loading = isPending || isPlaceholderData;

  const rows = data?.users ?? [];
  const pagination = data?.pagination;
  const totalRows = pagination?.totalResults ?? 0;

  const showEducationHubToggles = userInfo?.settings?.showEducationHubToggles;

  const columns: HaloDataGridProps['columns'] = [
    {
      flex: 3,
      field: 'name',
      headerName: 'name',
    },
    {
      flex: 3,
      field: 'organization',
      headerName: 'organization',
    },
    {
      flex: 1,
      field: 'completionPercentage',
      headerName: 'status',
      sortable: false,
      renderCell: ({ value }) => <EducationHubPercentageCell percentComplete={value} />,
    },
    {
      flex: 1,
      field: 'completionDate',
      headerName: 'completion date',
      valueGetter: (value) => (value ? DateTime.fromISO(value).toFormat(MONTH_DAY_YEAR_DATE_FORMAT) : '-'),
    },
  ];

  if (showEducationHubToggles) {
    const commonToggleOptions: Partial<GridColDef> = {
      width: 150,
      align: 'center',
      sortable: false,
    };

    columns.push({
      ...commonToggleOptions,
      field: 'canTrade',
      headerName: 'Trading',
      renderHeader: (header) => (
        <EducationHubTableInfoHeader
          name={header.colDef.headerName}
          info="When OFF, the platform will not allow users to buy products."
        />
      ),
      renderCell: ({ row, value }) => {
        const handleUpdate = (checked: boolean) => {
          mutate({ userId: row.id, role: UserRoleEnum.Buyer, addRole: checked });
        };

        return <EducationHubTableSwitch onUpdate={handleUpdate} defaultChecked={value} />;
      },
    });

    columns.push({
      ...commonToggleOptions,
      field: 'canBuy',
      headerName: 'Platform',
      renderHeader: (header) => (
        <EducationHubTableInfoHeader
          name={header.colDef.headerName}
          info="When OFF, the platform will only show the education pages."
        />
      ),
      renderCell: ({ row, value }) => {
        const handleUpdate = (checked: boolean) => {
          mutate({ userId: row.id, role: UserRoleEnum.EducationLock, addRole: !checked });
        };

        return <EducationHubTableSwitch onUpdate={handleUpdate} defaultChecked={value} />;
      },
    });
  }

  return (
    <HaloDataGrid
      columns={columns}
      density="compact"
      disableColumnMenu
      disableColumnReorder
      disableRowSelectionOnClick
      getDetailPanelContent={({ id, row }) => <EducationHubTableDetailPanel id={id} moduleProgress={row?.modules} />}
      getDetailPanelHeight={({ row }) => (((row?.modules?.length ?? 0) as number) + 1) * 32}
      detailPanelExpandedRowIds={detailPanelExpandedRowIds}
      onDetailPanelExpandedRowIdsChange={(newIds) => void setDetailPanelExpandedRowIds(newIds)}
      loading={loading}
      noResultsMessage="No users could be found at this time. Please try again later."
      onPaginationModelChange={({ pageSize, page }) => {
        setFilters((prev) => {
          const pageSizeChange = pageSize !== prev.resultsPerPage;
          const updatedPage = pageSizeChange ? 0 : page + 1;

          setDetailPanelExpandedRowIds([]);

          return { ...prev, page: updatedPage, resultsPerPage: pageSize };
        });
      }}
      sortModel={filters.sort}
      onSortModelChange={(model) => void setFilters((prev) => ({ ...prev, sort: model }))}
      pagination
      paginationMode="server"
      rowCount={totalRows}
      rows={rows}
      slots={{
        detailPanelExpandIcon: () => <Iconography iconName="chevron-down" color="text.secondary" />,
        detailPanelCollapseIcon: () => <Iconography iconName="chevron-up" color="text.secondary" />,
      }}
      sortingMode="server"
    />
  );
};
