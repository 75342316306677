import { translations } from '@halo-common/translations';
import { putCalendarOfferingDoc } from '@halo-data-sources/clients';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

const accountAllocationCSVUploadMutationFn = async (file: File) => {
  if (!file) throw new Error(translations.common.noFileError);
  await putCalendarOfferingDoc(file);
};

export const useCalendarOfferingUploadMutation = (): UseMutationResult<void, Error, File> => {
  const { enqueueErrorEvent } = useSnackbar();

  return useMutation<void, Error, File>({
    mutationFn: accountAllocationCSVUploadMutationFn,
    onError: (error) => {
      const message = error.message === translations.common.noFileError ? error.message : translations.common.error;
      enqueueErrorEvent({ message });
    },
  });
};
