import { ReactElement } from 'react';

import { OrganizationOnboardingStatusEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { FinishOnboardingButton } from '@halo-modules/app';
import { suitabilityAlertSx } from '@halo-modules/app/v2/onboarding/subPages/suitability';
import { Iconography } from '@halodomination/halo-fe-common';
import { Alert, Typography } from '@mui/material';
import { useT } from '@transifex/react';

const suitability = translations.onboarding.suitability;

const getNextStep = (onboardingStatus: OrganizationOnboardingStatusEnum): string => {
  switch (onboardingStatus) {
    case OrganizationOnboardingStatusEnum.DOCS_NOT_SIGNED:
      return suitability.authorizedSignerMustReviewAndSignPaperwork;
    case OrganizationOnboardingStatusEnum.UNDER_HALO_REVIEW:
      return suitability.complianceTeamIsReviewingFirmsPaper;
    default:
      return suitability.youMustCompleteOnboardingSuitabilityBeforeTrading;
  }
};

export type MustCompleteOnboardingAlertProps = {
  feature: string;
};

export const MustCompleteOnboardingAlert = ({ feature }: MustCompleteOnboardingAlertProps): ReactElement | null => {
  const translator = useT();
  const { data: userInfo } = useUserInfoQuery();

  const organization = userInfo?.organization;

  if (!organization) return null;

  const { onboardingStatus, isOnboardingCompleted } = organization;

  if (isOnboardingCompleted) return null;

  const getTitle = () => {
    const nextStep = translator(getNextStep(onboardingStatus), {
      firm: organization.name,
    });

    const useIsForDemoPurposes = translator(suitability.useIsForDemoPurposeOnly, {
      feature: translator(feature),
    });

    return (
      <>
        {nextStep} {useIsForDemoPurposes}
      </>
    );
  };

  const title = getTitle();

  const finishNowButton =
    onboardingStatus === OrganizationOnboardingStatusEnum.FORM_NOT_COMPLETED ? (
      <FinishOnboardingButton variant="text" color="warning">
        {translations.common.finishNow}
      </FinishOnboardingButton>
    ) : null;

  return (
    <Alert
      icon={<Iconography iconName="triangle-exclamation" color="inherit" />}
      severity="warning"
      variant="filled"
      sx={suitabilityAlertSx}
    >
      <Typography variant="body2">{title}</Typography>
      {finishNowButton}
    </Alert>
  );
};
