import { ReactElement } from 'react';

import { V2ProductSolvableParameter } from '@halo-common/components';
import { ProductDetailModalLayoutHeader } from '@halo-common/layouts';
import { V2PostTradeNoteDetailModalHeaderFeatures, V2PostTradeNoteDetailModalHeaderId } from '@halo-common/modals';
import { V2NoteModel } from '@halo-common/models';

export type V2PostTradeNoteDetailModalHeaderProps = {
  product?: V2NoteModel | null;
  loading?: boolean;
  onClose?: () => void;
};

export const V2PostTradeNoteDetailModalHeader = ({
  product,
  loading = true,
  onClose,
}: V2PostTradeNoteDetailModalHeaderProps): ReactElement => {
  const noteType = product?.type;
  const features = product?.features;
  const solvableParameter = product?.payoffDetails?.solvableParameter;

  const slots = {
    features: <V2PostTradeNoteDetailModalHeaderFeatures loading={loading} features={features} type={noteType} />,
    productId: <V2PostTradeNoteDetailModalHeaderId loading={loading} product={product} />,
    parameter: <V2ProductSolvableParameter loading={loading} parameter={solvableParameter} />,
  };

  return <ProductDetailModalLayoutHeader onClose={onClose} slots={slots} />;
};
