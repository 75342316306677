import { ReactElement } from 'react';

import { productDetailModalAnalyticsTabStateAtom } from '@halo-atoms/pdm';
import {
  ProductDetailModalAnalyticsRollingReturnsStatisticsTableLayout,
  ProductDetailModalAnalyticsRollingReturnsSummary,
  ProductDetailModalAnalyticsRollingReturnsTable,
  ProductDetailModalAnalyticsRollingReturnsTableProps,
} from '@halo-common/layouts';
import { RollingReturnsStatisticsQueryResult } from '@halo-data-sources/queries';
import { Box, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';

export type ProductDetailModalAnalyticsRollingReturnsFrequencyTableProps = {
  data?: RollingReturnsStatisticsQueryResult | null;
  loading?: boolean;
  title: string;
  showTreeData: boolean;
  showAgainstNote: boolean;
  showAverageLife: boolean;
};

export const ProductDetailModalAnalyticsRollingReturnsFrequencyTable = ({
  data,
  loading = false,
  title,
  showTreeData,
  showAgainstNote,
  showAverageLife,
}: ProductDetailModalAnalyticsRollingReturnsFrequencyTableProps): ReactElement => {
  const state = useAtomValue(productDetailModalAnalyticsTabStateAtom);

  const { daysBetween: days, dateRangeText } = state;

  const rows = data?.frequency ?? [];

  const underlyingData = rows[0];
  const noteData = rows[1];

  const notePositive = noteData?.positive;
  const noteZero = noteData?.zero;
  const noteNegative = noteData?.negative;
  const underlyingPositive = underlyingData?.positive;
  const underlyingZero = underlyingData?.zero;
  const underlyingNegative = underlyingData?.negative;

  const hasColumnOneData = typeof notePositive === 'number' && typeof underlyingPositive === 'number';
  const hasColumnTwoData = typeof noteZero === 'number' && typeof underlyingZero === 'number';
  const hasColumnThreeData = typeof noteNegative === 'number' && typeof underlyingNegative === 'number';

  const columnOneDifference = hasColumnOneData ? notePositive - underlyingPositive : undefined;
  const columnTwoDifference = hasColumnTwoData ? noteZero - underlyingZero : undefined;
  const columnThreeDifference = hasColumnThreeData ? noteNegative - underlyingNegative : undefined;

  const formattedTitle = showAgainstNote ? `Note vs. ${title} Frequency Comparison` : `${title} Frequency`;

  const when = `at maturity when analyzing the ${days} Rolling Return windows between ${dateRangeText}.`;

  const positiveTooltipText = `The percentage of outcomes that had positive returns ${when}`;
  const zeroTooltipText = `The percentage of outcomes that had zero returns ${when}`;
  const negativeTooltipText = `The percentage of outcomes that had negative returns ${when}`;

  const defaultColumns: ProductDetailModalAnalyticsRollingReturnsTableProps['columns'] = [
    {
      field: 'positive',
      headerName: 'Positive',
      headerInfo: positiveTooltipText,
    },
    {
      field: 'zero',
      headerName: 'Zero',
      headerInfo: zeroTooltipText,
    },
    {
      field: 'negative',
      headerName: 'Negative',
      headerInfo: negativeTooltipText,
    },
  ];

  const columns: ProductDetailModalAnalyticsRollingReturnsTableProps['columns'] = showAverageLife
    ? [
        {
          field: 'lifeSpan',
          headerName: 'Average Lifetime',
          headerInfo: `The average lifetime of the note, considering the callability features of the note, when analyzing the ${days} Rolling Return windows between ${dateRangeText}.`,
          valueFormatter: (value: number) => {
            const isSingular = value === 1;
            const monthText = isSingular ? 'Month' : 'Months';
            return value ? `${value} ${monthText}` : 'N/A';
          },
        },
        ...defaultColumns,
      ]
    : defaultColumns;

  const comparisons = showAgainstNote ? (
    <Stack direction="row" alignItems="center" justifyContent="flex-end">
      <Box sx={{ flex: 0.4 }} />
      {showAverageLife ? <Box sx={{ flex: 1 }} /> : null}
      <ProductDetailModalAnalyticsRollingReturnsSummary
        label="Difference"
        value={columnOneDifference}
        loading={loading}
      />
      <ProductDetailModalAnalyticsRollingReturnsSummary
        label="Difference"
        value={columnTwoDifference}
        loading={loading}
      />
      <ProductDetailModalAnalyticsRollingReturnsSummary
        label="Difference"
        value={columnThreeDifference}
        loading={loading}
        reverseColoring
      />
    </Stack>
  ) : null;

  return (
    <ProductDetailModalAnalyticsRollingReturnsStatisticsTableLayout title={formattedTitle} loading={loading}>
      <ProductDetailModalAnalyticsRollingReturnsTable
        columns={columns}
        rows={rows}
        loading={loading}
        treeData={showTreeData}
      />
      {comparisons}
    </ProductDetailModalAnalyticsRollingReturnsStatisticsTableLayout>
  );
};
