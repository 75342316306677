import type { DashboardWidgetModel } from '@halo-common/models';

import EducationCourses from './EducationCourses';
import Noteflix from './Noteflix';
import OrderBook from './OrderBook';
import PortfolioLifecycle from './PortfolioLifecycle';
import PortfolioPositions from './PortfolioPositions';
import ProductShelf from './ProductShelf';
import Watchlist from './Watchlist';

export const widgetsArray = [
  OrderBook,
  PortfolioLifecycle,
  PortfolioPositions,
  ProductShelf,
  Watchlist,
  Noteflix,
  EducationCourses,
];

export const widgetsMap = widgetsArray.reduce<Record<string, DashboardWidgetModel>>((acc, widget) => {
  acc[widget.engine] = widget;
  return acc;
}, {});
