import { ReactElement, ReactNode, createContext, useContext, useEffect, useState } from 'react';

import {
  FixWebsocketEventQueue,
  WebsocketExecOrderEventQueue,
  WebsocketQuoteEventQueue,
  WebsocketToastListenerEvent,
  useExecOrderEventQueue,
  useFixAllocationEventQueue,
  useFixEventQueue,
  useQuoteEventQueue,
  useToastEventListener,
} from '@halo-common/hooks';
import { socket, useLegacyRouter } from '@halo-common/providers';
import { ComplianceApprovalPageMeta } from '@halo-pages/admin/calendars/compliance-approval';
import { LoadingDockPageMeta } from '@halo-pages/admin/calendars/loading-dock';
import { ExecutionHubV2Meta } from '@halo-pages/admin/v2/calendars/execution-hub';
import { CalendarsPageMeta } from '@halo-pages/app/calendars';
import { CalendarPageMeta } from '@halo-pages/app/calendars/[calendarId]';
import { DashboardPageMeta } from '@halo-pages/app/dashboard';
import { NotePageMeta } from '@halo-pages/app/note/[noteId]';
import { OrderBookPageMeta } from '@halo-pages/app/order-book';
import { PortfolioPageMeta } from '@halo-pages/app/portfolio';
import { RollingReturnsPageMeta } from '@halo-pages/app/rolling-returns';
import { WatchlistPageMeta } from '@halo-pages/app/watchlist';
import { isInDevBuildEnv } from '@halodomination/halo-fe-common';

const SUPPRESS_WEBSOCKET_CONNECTION = process.env.NEXT_PUBLIC_ENV === 'local';

const PRE_TRADE_PATHS = [
  CalendarsPageMeta.routeExpression,
  CalendarPageMeta.routeExpression,
  DashboardPageMeta.routeExpression,
  OrderBookPageMeta.routeExpression,
  PortfolioPageMeta.routeExpression,
  RollingReturnsPageMeta.routeExpression,
  WatchlistPageMeta.routeExpression,
  NotePageMeta.routeExpression,
  ComplianceApprovalPageMeta.routeExpression,
];

const EXECUTION_PATHS = [ExecutionHubV2Meta.routeExpression, LoadingDockPageMeta.routeExpression];

const NO_OP = () => {
  return false;
};

const DEFAULT_EVENT_LISTENER_CONTEXT = {
  current: null,
};

const DEFAULT_EVENT_QUEUE_CONTEXT = {
  queue: [],
  add: NO_OP,
  clear: NO_OP,
  remove: NO_OP,
  contains: NO_OP,
  pending: NO_OP,
  replace: NO_OP,
};

export type WebSocketProviderProps = {
  children: ReactNode;
};

export type WebSocketProviderContext = {
  connected: boolean;
  events: {
    toast: WebsocketToastListenerEvent;
    execOrder: WebsocketExecOrderEventQueue;
    fix: FixWebsocketEventQueue;
    fixAllocation: FixWebsocketEventQueue;
    quote: WebsocketQuoteEventQueue;
  };
};

export const WebSocketContext = createContext<WebSocketProviderContext>({
  connected: false,
  events: {
    execOrder: { name: 'exec-order', ...DEFAULT_EVENT_QUEUE_CONTEXT },
    // TODO: Combined by allowing for multiple comparison keys
    fix: { name: 'fix-message', ...DEFAULT_EVENT_QUEUE_CONTEXT },
    fixAllocation: { name: 'fix-message', ...DEFAULT_EVENT_QUEUE_CONTEXT },
    quote: { name: 'quote', ...DEFAULT_EVENT_QUEUE_CONTEXT },
    toast: { name: 'toast', ...DEFAULT_EVENT_LISTENER_CONTEXT },
  },
});

export const useWebSocketContext = (): WebSocketProviderContext => {
  return useContext<WebSocketProviderContext>(WebSocketContext);
};

export const matchLegacyRouteRegExp = (path: string, routeList: Array<string | undefined>): boolean => {
  return routeList.some((routeRegExp) => {
    if (!routeRegExp) return false;
    const expression = new RegExp(routeRegExp);
    return expression.test(path);
  });
};

export const WebSocketProvider = ({ children }: WebSocketProviderProps): ReactElement => {
  const router = useLegacyRouter();

  const [connected, setConnected] = useState(socket.connected);

  const [toastSocket, toastEventContext] = useToastEventListener();
  const [quoteSocket, quoteEventContext] = useQuoteEventQueue();
  const [fixSocket, fixEventContext] = useFixEventQueue();
  const [fixAllocationSocket, fixAllocationContext] = useFixAllocationEventQueue();
  const [execOrderSocket, execOrderEventContext] = useExecOrderEventQueue();

  const context = {
    connected,
    events: {
      quote: quoteEventContext,
      fix: fixEventContext,
      fixAllocation: fixAllocationContext,
      execOrder: execOrderEventContext,
      toast: toastEventContext,
    },
  };

  useEffect(() => {
    const onConnect = () => {
      if (isInDevBuildEnv()) console.log('Socket IO: Connected');
      setConnected(true);
    };

    const onDisconnect = () => {
      if (isInDevBuildEnv()) console.log('Socket IO: Disconnected');
      setConnected(false);
    };

    const onError = (error: Error) => {
      if (isInDevBuildEnv()) console.log('error', error.message);
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('connect_error', onError);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('connect_error', onError);
    };
  }, []);

  useEffect(() => {
    const shouldHandleWebsocket = !SUPPRESS_WEBSOCKET_CONNECTION && router.isReady;

    if (shouldHandleWebsocket) {
      const path = router.path;
      const isExecutionWebsocketPath = matchLegacyRouteRegExp(path, EXECUTION_PATHS);
      const isPreTradeWebsocketPath = matchLegacyRouteRegExp(path, PRE_TRADE_PATHS);
      const shouldConnect = isExecutionWebsocketPath || isPreTradeWebsocketPath;

      if (isPreTradeWebsocketPath) socket.io.opts.path = '/pretrade/socket.io';
      if (isExecutionWebsocketPath) socket.io.opts.path = '/execution/socket.io';
      if (shouldConnect) socket.connect();
    }
  }, [router.isReady]);

  useEffect(() => {
    socket.on(toastSocket.name, toastSocket.handler);
    socket.on(quoteSocket.name, quoteSocket.handler);
    socket.on(fixSocket.name, fixSocket.handler);
    socket.on(fixAllocationSocket.name, fixAllocationSocket.handler);
    socket.on(execOrderSocket.name, execOrderSocket.handler);

    return () => {
      socket.off(toastSocket.name, toastSocket.handler);
      socket.off(quoteSocket.name, quoteSocket.handler);
      socket.off(fixSocket.name, fixSocket.handler);
      socket.off(fixAllocationSocket.name, fixAllocationSocket.handler);
      socket.off(execOrderSocket.name, execOrderSocket.handler);
    };
  }, [
    toastSocket.handler,
    quoteSocket.handler,
    fixSocket.handler,
    fixAllocationSocket.handler,
    execOrderSocket.handler,
  ]);

  return <WebSocketContext.Provider value={context}>{children}</WebSocketContext.Provider>;
};
