import { ReactElement } from 'react';

import { Paper, Skeleton, Stack } from '@mui/material';

const loadingContainerSx = {
  py: 5,
  px: 3,
  overflowY: 'auto',
  mb: { xs: 2, sm: 8 },
  minWidth: { xs: 343, sm: 'default' },
  width: { xs: '100%', sm: 450 },
};

export const LoginFormSkeleton = (): ReactElement => (
  <Paper sx={loadingContainerSx} variant="outlined">
    <Stack direction="column">
      <Stack direction="column">
        <Skeleton height={72} />
        <Stack direction="column">
          <Skeleton height={72} />
          <Skeleton width={68} height={60} />
        </Stack>
      </Stack>
      <Stack direction="column">
        <Skeleton height={60} />
        <Stack direction="row" alignItems="center" spacing={1}>
          <Skeleton width={170} height={36} />
          <Skeleton width={68} height={60} />
        </Stack>
      </Stack>
    </Stack>
  </Paper>
);
