import { ReactElement } from 'react';

import { orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { useNoteSpecificDetails } from '@halo-common/hooks';
import { QuoteAuctionModel } from '@halo-common/models';
import { useBuyerAuctionQuery } from '@halo-data-sources/queries';
import { Stack } from '@halodomination/halo-fe-common';
import { Typography } from '@mui/material';
import { useAtomValue } from 'jotai';

import { AuctionDetailsTargetMet } from '../../../AuctionDetailsTargetMet';

export type IssuerBidQuoteProps = {
  quote?: QuoteAuctionModel;
};

export const IssuerBidQuote = ({ quote }: IssuerBidQuoteProps): ReactElement | null => {
  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);
  const auctionId = selectedAuctionId?.toString();

  const { data: auction } = useBuyerAuctionQuery(auctionId);

  const noteDetails = useNoteSpecificDetails(auction?.note);

  const solvableParameter = noteDetails?.solvableParameter?.label;

  return quote ? (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" alignItems="center" justify="flex-end" spacing={1}>
        <AuctionDetailsTargetMet variant="bid" quote={quote} />
        <Typography variant="h3" component="span">
          {quote.value}%
        </Typography>
      </Stack>
      <Typography variant="body2" textAlign="right" color="text.secondary">
        {solvableParameter}
      </Typography>
    </Stack>
  ) : null;
};
