import { Fragment } from 'react';

import { Input, Row } from '@halo-common/components/FormBuilder';
import { translations } from '@halo-common/translations';
import {
  OnboardingSuitabilityForm,
  SuitabilityStepModel,
  suitabilityAlertSx,
  fallbackPerson,
} from '@halo-modules/app/v2/onboarding';
import {
  Iconography,
  LocalizedButton,
  LocalizedFormControlLabel,
  LocalizedTypography,
} from '@halodomination/halo-fe-common';
import { Divider, Checkbox, Alert } from '@mui/material';
import { ITranslateParams } from '@transifex/native';
import { useT } from '@transifex/react';
import { useFormContext, useWatch } from 'react-hook-form';

const suitability = translations.onboarding.suitability;

const SignerFields = ({
  title,
  prefix,
  email = false,
  dynamicContent,
}: {
  title: string;
  prefix: string;
  email?: boolean;
  dynamicContent?: ITranslateParams;
}) => (
  <>
    <LocalizedTypography variant="caption" color="text.secondary" dynamicContent={dynamicContent}>
      {title}
    </LocalizedTypography>
    <Input label={suitability.fullName} name={`${prefix}.name`} />
    {email ? <Input label={suitability.emailAddress} name={`${prefix}.email_address`} /> : null}
    <Input label={suitability.position} name={`${prefix}.position`} />
    <Row columns={[1, 1]}>
      <Input label={suitability.crd} name={`${prefix}.crd_number`} />
      <Input type="date" label={suitability.dateOfBirth} name={`${prefix}.date_of_birth`} />
    </Row>
  </>
);

const SignerIsRequiredAlert = () => {
  const { formState } = useFormContext();

  const showAlert = formState.errors['']?.type === 'require-signer';

  if (showAlert) {
    return (
      <Alert
        icon={<Iconography iconName="hexagon-exclamation" color="inherit" />}
        severity="error"
        variant="filled"
        sx={suitabilityAlertSx}
      >
        <LocalizedTypography variant="body2">{suitability.pleaseEnterAtLeastOneAuthorizedSigner}</LocalizedTypography>
      </Alert>
    );
  }

  return null;
};

const AdditionalAuthorizedPersons = () => {
  const maxPersons = 4;
  const form = useFormContext();
  const numberOfAuthorizedPersons: number = useWatch({ name: 'numberOfAuthorizedPersons' }) || 1;

  const addTrader = () => {
    if (numberOfAuthorizedPersons >= maxPersons) return;
    form.setValue('numberOfAuthorizedPersons', numberOfAuthorizedPersons + 1);
  };

  const addButton =
    numberOfAuthorizedPersons < maxPersons ? (
      <LocalizedButton variant="outlined" startIcon={<Iconography iconName="plus" />} fullWidth onClick={addTrader}>
        {suitability.addAnotherTrader}
      </LocalizedButton>
    ) : null;

  const additionalPersonsFields = Array.from({ length: numberOfAuthorizedPersons }).map((_, personIndex) => {
    if (personIndex === 0) return null;

    const personNumber = personIndex + 1;

    const removeTrader = () => {
      for (let i = personNumber; i < maxPersons; i++) {
        const nextPerson = form.getValues(`firm_details.authorized_persons.authorized_person_${i + 1}`);
        form.setValue(`firm_details.authorized_persons.authorized_person_${i}`, nextPerson);
      }
      form.setValue(`firm_details.authorized_persons.authorized_person_${maxPersons}`, fallbackPerson);
      form.setValue('numberOfAuthorizedPersons', numberOfAuthorizedPersons - 1);
    };

    return (
      <Fragment key={personNumber}>
        <SignerFields
          title={suitability.authorizedPerson}
          prefix={`firm_details.authorized_persons.authorized_person_${personNumber}`}
          dynamicContent={{ number: personNumber, subtitle: '' }}
        />
        <LocalizedButton variant="text" startIcon={<Iconography iconName="trash" />} fullWidth onClick={removeTrader}>
          {suitability.removeAuthorizedPerson}
        </LocalizedButton>
        <Divider />
      </Fragment>
    );
  });

  return (
    <>
      {additionalPersonsFields}
      {addButton}
    </>
  );
};

const AuthorizedPersonsForm = () => {
  const translator = useT();
  return (
    <OnboardingSuitabilityForm requireAuthorizedSigner>
      <SignerIsRequiredAlert />
      <SignerFields
        title={suitability.authorizedPerson}
        prefix="firm_details.authorized_persons.authorized_signer"
        dynamicContent={{ number: '1', subtitle: `: ${translator(suitability.authorizedSigner)}` }}
        email
      />
      <LocalizedFormControlLabel
        control={<Checkbox checked />}
        label={suitability.thisPersonIsAuthorizedToSignLegalDocuments}
        disabled
      />
      <Divider />
      <AdditionalAuthorizedPersons />
    </OnboardingSuitabilityForm>
  );
};

export const authorizedPersonsStep: SuitabilityStepModel = {
  id: '1-5',
  title: suitability.whoWillBeTheAuthorizedTraders,
  subtitle: suitability.includeAtleastOneAuthorizedSigner,
  component: <AuthorizedPersonsForm />,
  isCompleted: (formData) => {
    const authorizedSigner = formData?.firm_details?.authorized_persons?.authorized_signer || {};
    return Boolean(
      authorizedSigner.name &&
        authorizedSigner.position &&
        authorizedSigner.email_address &&
        authorizedSigner.crd_number &&
        authorizedSigner.date_of_birth,
    );
  },
};
