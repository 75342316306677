import { ReactElement } from 'react';

import {
  CapacityField,
  CusipField,
  IbdField,
  OrderAccountField,
  PershingIssuerField,
  PershingStatusField,
  TimeFrameField,
  UserNameField,
} from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';

export const OrderBookPershingFilters = (): ReactElement => (
  <Stack direction="column" spacing={2}>
    <Stack direction="row" spacing={1} xs={3} wrap="wrap">
      <IbdField />
      <OrderAccountField />
      <UserNameField />
      <CusipField />
      <PershingIssuerField />
      <CapacityField />
      <PershingStatusField />
      <TimeFrameField />
    </Stack>
  </Stack>
);
