import { ReactElement } from 'react';

import { productDetailModalAnalyticsTabStateAtom } from '@halo-atoms/pdm';
import {
  ProductDetailModalAnalyticsRollingReturnsStatisticsTableLayout,
  ProductDetailModalAnalyticsRollingReturnsTable,
  ProductDetailModalAnalyticsRollingReturnsTableProps,
} from '@halo-common/layouts';
import { RollingReturnsStatisticsQueryResult } from '@halo-data-sources/queries';
import { useAtomValue } from 'jotai';

export type ProductDetailModalAnalyticsRollingReturnsCouponTableProps = {
  data?: RollingReturnsStatisticsQueryResult | null;
  loading?: boolean;
};

export const ProductDetailModalAnalyticsRollingReturnsCouponTable = ({
  data,
  loading = false,
}: ProductDetailModalAnalyticsRollingReturnsCouponTableProps): ReactElement => {
  const state = useAtomValue(productDetailModalAnalyticsTabStateAtom);

  const { daysBetween: days, dateRangeText } = state;

  const rows = data?.coupon ?? [];

  const columns: ProductDetailModalAnalyticsRollingReturnsTableProps['columns'] = [
    {
      field: 'lifeSpan',
      headerName: 'Average Lifetime',
      headerInfo: `The average lifetime of the note, considering the callability features of the note, when analyzing the ${days} Rolling Return windows between ${dateRangeText}.`,
      valueFormatter: (value: number) => {
        const isSingular = value === 1;
        const monthText = isSingular ? 'Month' : 'Months';
        return value ? `${value} ${monthText}` : 'N/A';
      },
    },
    {
      field: 'numberCoupons',
      headerName: '# of Coupons Paid',
      headerInfo: `The average number of coupons paid and potential coupons being available, considering the callability features of the note, when analyzing the ${days} Rolling Return windows between ${dateRangeText}.`,
    },
    {
      field: 'percentCoupons',
      headerName: '% of Coupons Paid',
      headerInfo: `The percentage of coupons paid, considering the callability features of the note, when analyzing the ${days} Rolling Return windows between ${dateRangeText}.`,
    },
    {
      field: 'annualizedYield',
      headerName: 'Average Realized Annual Yield',
      headerInfo: `The average realized yield the note produced annually, considering the callability features of the note, when analyzing the ${days} Rolling Return windows between ${dateRangeText}.`,
    },
  ];

  return (
    <ProductDetailModalAnalyticsRollingReturnsStatisticsTableLayout title="Note Coupon Summary" loading={loading}>
      <ProductDetailModalAnalyticsRollingReturnsTable columns={columns} rows={rows} loading={loading} />
    </ProductDetailModalAnalyticsRollingReturnsStatisticsTableLayout>
  );
};
