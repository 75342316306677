import { ReactElement } from 'react';

import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { usePershingAdminContext } from '@halo-modules/admin';
import { Stack } from '@halodomination/halo-fe-common';
import { Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';

export type LoadingDockFilterSchema = {
  cusip?: string;
};

export type LoadingDockFiltersBarProps = {
  expirationDate: DateTime | null;
  onClear: () => void;
  onDateChange: (dates: DateTime | null) => void;
  onSearch: (params?: LoadingDockFilterSchema) => void;
};

export const LoadingDockFiltersBar = ({
  expirationDate,
  onClear,
  onDateChange,
  onSearch,
}: LoadingDockFiltersBarProps): ReactElement => {
  const { disableFilters, pageLoading } = usePershingAdminContext();

  const { register, handleSubmit } = useFormContext<LoadingDockFilterSchema>();

  const { ref: cusipRef, ...cusipProps } = register('cusip');

  const disableActions = pageLoading || disableFilters;

  const submitHandler = handleSubmit((data: LoadingDockFilterSchema) => {
    const params = { cusip: data.cusip, startDate: expirationDate, endDate: expirationDate };
    onSearch(params);
  });

  return (
    <Stack direction="row" spacing={2} wrap="wrap">
      <TextField inputRef={cusipRef} label="Search by CUSIP" fullWidth {...cusipProps} />
      <DatePicker
        onChange={onDateChange}
        label="Filter by Closing"
        format={MONTH_DAY_YEAR_DATE_FORMAT}
        slotProps={{
          field: { clearable: true },
          textField: { fullWidth: true },
        }}
        value={expirationDate}
      />
      <Stack direction="row" spacing={2}>
        <Button disabled={disableActions} color="primary" variant="contained" onClick={submitHandler}>
          Search
        </Button>
        <Button disabled={disableActions} color="primary" variant="outlined" onClick={onClear}>
          Clear
        </Button>
      </Stack>
    </Stack>
  );
};
