import { ReactElement } from 'react';

import { ProductDetailModalCreateSimilarButton } from '@halo-common/layouts';
import { Stack } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';

export type PostTradeNoteDetailModalHeaderActionsProps = {
  noteId?: number;
  termsheetId?: number;
  loading?: boolean;
  hideCreateSimilar?: boolean;
};

export const PostTradeNoteDetailModalHeaderActions = ({
  noteId,
  termsheetId,
  loading = true,
  hideCreateSimilar = false,
}: PostTradeNoteDetailModalHeaderActionsProps): ReactElement | null => {
  if (loading) {
    return (
      <Stack direction="row">
        <Skeleton width={100} height={42} />
      </Stack>
    );
  }

  if (!termsheetId) return null;

  const containerSx = {
    justifyContent: { md: 'flex-end', xs: 'flex-start' },
  };

  const subContainerSx = {
    pt: { md: 0, xs: 2 },
    pb: { md: 2, xs: 0 },
    pr: { md: 0, xs: 2 },
    pl: { md: 2, xs: 0 },
  };

  const createSimilarBtn = !hideCreateSimilar ? <ProductDetailModalCreateSimilarButton noteId={noteId} /> : null;

  return (
    <Stack direction="row" sx={containerSx} elementStyling={subContainerSx} wrap="wrap">
      {createSimilarBtn}
    </Stack>
  );
};
