import { ReactElement } from 'react';

import { OrdersActions } from '@halo-stores/Orders';
import { BrandIcon, Iconography, Modal, Stack } from '@halodomination/halo-fe-common';
import { Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

export type ConfirmDeleteTackOnProps = {
  open: boolean;
  id: number | null | undefined;
  onClose: () => void;
  onBack: () => void;
};

export const ConfirmDeleteTackOn = ({ open, id, onClose, onBack }: ConfirmDeleteTackOnProps): ReactElement => {
  const dispatch = useDispatch();

  const handleDeleteClick = () => {
    if (id) {
      dispatch(OrdersActions.deleteAuctionTackOn({ id }));
      onClose();
    }
  };

  return (
    <Modal
      size="small"
      open={open}
      onClose={onClose}
      hideHeader
      footer={
        <Stack direction="row" justify="space-between">
          <Button onClick={onBack} color="primary" startIcon={<Iconography iconName="arrow-left" />}>
            Go Back
          </Button>
          <Button onClick={handleDeleteClick} variant="contained" color="primary">
            Confirm Delete
          </Button>
        </Stack>
      }
    >
      <Stack direction="row" spacing={2}>
        <BrandIcon variant="confirm" />
        <Stack direction="column" spacing={2}>
          <Typography variant="h5">Confirm Tack On Deletion</Typography>
          <Typography variant="body1">
            This tack on will no longer show up on any calendar pages, and any allocations may be lost. This action can
            not be undone.
          </Typography>
        </Stack>
      </Stack>
    </Modal>
  );
};
