import { ReactElement } from 'react';

import { ProductDetailModalLayoutHeader } from '@halo-common/layouts';
import {
  PreTradeNoteDetailModalHeaderActions,
  PreTradeNoteDetailModalHeaderFeatures,
  PreTradeNoteDetailModalHeaderId,
  PreTradeNoteDetailModalHeaderSolvableParameter,
} from '@halo-common/modals';
import { NoteModel } from '@halo-common/models';

export type PreTradeNoteDetailModalHeaderProps = {
  product?: NoteModel | null;
  loading?: boolean;
  onClose?: () => void;
};

export const PreTradeNoteDetailModalHeader = ({
  product,
  loading = true,
  onClose,
}: PreTradeNoteDetailModalHeaderProps): ReactElement => {
  const slots = {
    features: <PreTradeNoteDetailModalHeaderFeatures loading={loading} product={product} />,
    productId: <PreTradeNoteDetailModalHeaderId productId={product?.id} loading={loading} />,
    parameter: <PreTradeNoteDetailModalHeaderSolvableParameter loading={loading} product={product} />,
    actions: (
      <PreTradeNoteDetailModalHeaderActions productId={product?.id} productType={product?.type} loading={loading} />
    ),
  };

  return <ProductDetailModalLayoutHeader onClose={onClose} slots={slots} />;
};
