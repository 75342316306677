import { ReactElement, useEffect } from 'react';

import { addPositionsToAccountModalAtom } from '@halo-atoms/portfolio';
import { DEFAULT_ASSET_ID } from '@halo-common/constants';
import { AssetIdLocationEnum } from '@halo-common/enums';
import { PortfolioPositionReferenceModel } from '@halo-common/models';
import { AddPositionsToAccountFormFields } from '@halo-common/schemas';
import { usePortfolioAssetExistenceQuery } from '@halo-data-sources/queries';
import { PositionAllocationListItem } from '@halo-modules/app';
import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useFieldArray, useFormContext } from 'react-hook-form';

export const AllocateNotionalModalLayout = (): ReactElement => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<AddPositionsToAccountFormFields>();

  const { selectedAccountAtom, selectedAssetsAtom } = useAtomValue(addPositionsToAccountModalAtom);

  const assetIds = selectedAssetsAtom.map(({ label }) => label);
  const { data: assets } = usePortfolioAssetExistenceQuery(assetIds);

  const { fields, remove } = useFieldArray<AddPositionsToAccountFormFields>({ name: 'positions', control });

  const assetList = assets ?? [];

  const accountAllocationList = fields.map((field, index) => {
    const termsheetId = getValues(`positions.${index}.termsheetId` as const);
    const assetIdValue = getValues(`positions.${index}.assetId` as const);

    const selectedAssetIdObject = assetList?.find((asset) => {
      if (asset.location === AssetIdLocationEnum.edgar) return asset.assetId === assetIdValue;
      else return asset.termsheetId === termsheetId;
    });

    const selectedAssetId = selectedAssetIdObject?.assetId;
    const assetIdType = selectedAssetIdObject?.assetIdType ?? DEFAULT_ASSET_ID;

    const notionalError = errors?.['positions']?.[index]?.notional?.message;
    const notionalFieldName = `positions.${index}.notional` as const;

    return (
      <PositionAllocationListItem
        key={field.id}
        assetId={selectedAssetId}
        showDivider={index !== 0}
        error={notionalError}
        name={notionalFieldName}
        location={selectedAssetIdObject?.location}
        assetIdType={assetIdType}
      />
    );
  });

  useEffect(() => {
    fields.forEach((field, index) => {
      const match = assetList?.some((asset) => {
        const isEdgarLocation = field.location === AssetIdLocationEnum.edgar;
        const isSameCusip = field.assetId === asset.assetId;
        const isEdgarCusip = isEdgarLocation && isSameCusip;
        return isEdgarCusip || field.termsheetId === asset.termsheetId;
      });

      if (!match) remove(index);
    });

    const updatedFields = assetList?.reduce((list: Array<PortfolioPositionReferenceModel>, asset) => {
      const position = {
        termsheetId: asset.termsheetId ?? -1,
        accountId: selectedAccountAtom?.id.toString(),
        notional: undefined,
        location: asset.location,
        assetId: asset.assetId,
      };

      const duplicates = fields.some((field) => {
        const isEdgarLocation = field.location === AssetIdLocationEnum.edgar;
        const isSameCusip = field.assetId === asset.assetId;
        const isEdgarCusip = isEdgarLocation && isSameCusip;
        return isEdgarCusip || field.termsheetId === asset.termsheetId;
      });

      if (!duplicates) return [...list, position];
      else return list;
    }, []);

    if (updatedFields.length) setValue('positions', updatedFields);
  }, [assetList]);

  return (
    <Stack sx={{ width: '100%' }} direction="column" spacing={3}>
      {accountAllocationList}
    </Stack>
  );
};
