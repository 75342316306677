import { ReactElement } from 'react';

import { CustodianFormField, HouseholdFormField } from '@halo-common/formComponents';
import { CustodianModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { LocalizedTextField, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Alert } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export type AddAccountSimpleFormFields = {
  accountHousehold?: string | null;
  accountName: string;
  custodian: CustodianModel;
  accountNumber: string;
};

export type AddAccountSimpleFormProps = {
  error?: string;
};

export const AddAccountSimpleForm = ({ error }: AddAccountSimpleFormProps): ReactElement => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<AddAccountSimpleFormFields>();

  const { accountName: accountNameError, accountNumber: accountNumberError } = errors ?? {};

  const accountNameProps = register('accountName', {
    required: translations.portfolio.addAccountModal.formNameRequiredError,
  });

  const accountNumberProps = register('accountNumber', {
    required: translations.portfolio.addAccountModal.formNumberRequiredError,
  });

  const accountNumberFieldError = Boolean(accountNumberError);
  const accountNameHelperMessage = accountNameError?.message;
  const accountNumberHelperMessage = accountNumberError?.message;

  const alertMessage = error ? (
    <Alert severity="error" variant="filled">
      <LocalizedTypography>{error}</LocalizedTypography>
    </Alert>
  ) : null;

  return (
    <Stack direction="column" justify="space-between">
      {alertMessage}
      <HouseholdFormField control={control} label={translations.common.accountHousehold} name="accountHousehold" />
      <LocalizedTextField
        {...accountNameProps}
        name="accountName"
        required
        error={Boolean(accountNameError)}
        label={translations.common.accountName}
        helperText={accountNameHelperMessage}
        fullWidth
        size="large"
      />
      <CustodianFormField<AddAccountSimpleFormFields>
        label={translations.common.custodian}
        control={control}
        name="custodian"
        rules={{
          required: translations.portfolio.addAccountModal.formCustodianRequiredError,
          validate: {
            required: (v?: string | number | CustodianModel | null) => {
              if (typeof v === 'string' || typeof v === 'number') return true;
              return Boolean(v && v.id > -1);
            },
          },
        }}
      />
      <LocalizedTextField
        {...accountNumberProps}
        name="accountNumber"
        error={accountNumberFieldError}
        required
        helperText={accountNumberHelperMessage}
        label={translations.common.accountNumber}
        fullWidth
        size="large"
      />
    </Stack>
  );
};
