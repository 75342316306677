import { ReactElement } from 'react';

import { ActionButton } from '@halo-common/components';
import { PershingActions, PershingSelectors, PershingStatusEnum } from '@halo-stores/Pershing';
import { Stack } from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';

export type EditCalendarModalFooterProps = {
  date: DateTime | null;
  id?: number;
  onClose: () => void;
};

export const EditCalendarModalFooter = ({ id, date, onClose }: EditCalendarModalFooterProps): ReactElement => {
  const dispatch = useDispatch();

  const pershingStatus = useSelector(PershingSelectors.selectStatus);

  const isLoading = pershingStatus === PershingStatusEnum.requestUpdatePershingCalendar;

  const disabled = !date;

  const handleSubmit = () => {
    const hasData = id && date && date.isValid;

    if (hasData) dispatch(PershingActions.updatePershingCalendar({ id, date }));
  };

  return (
    <Stack direction="row" justify="space-between" spacing={2}>
      <Button type="button" onClick={onClose}>
        Cancel
      </Button>
      <ActionButton
        type="button"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={disabled}
        loading={isLoading}
      >
        Update
      </ActionButton>
    </Stack>
  );
};
