import { ReactElement } from 'react';

import {
  V2PostTradeNoteDetailModalNoteValueChart,
  V2PostTradeNoteDetailModalProps,
  V2PostTradeNoteDetailModalUnderlyingPerformance,
} from '@halo-common/modals';
import { PostTradeProductDetailsDataSwitchResult } from '@halo-data-sources/switches';
import { ConditionalWrapper, Tabs, TabsProps } from '@halodomination/halo-fe-common';

import { V2PostTradeNoteDetailModalAnalyticsTabAlert } from './V2PostTradeNoteDetailModalAnalyticsTabAlert';

export type V2PostTradeNoteDetailModalAnalyticsTabProps = {
  termsheetId?: number;
  noteAnalytics?: PostTradeProductDetailsDataSwitchResult['noteAnalytics'];
  underlyingAnalytics?: PostTradeProductDetailsDataSwitchResult['underlyingAnalytics'];
  filters: V2PostTradeNoteDetailModalProps['filters'];
  loading?: boolean;
};

export const V2PostTradeNoteDetailModalAnalyticsTab = ({
  termsheetId,
  noteAnalytics,
  underlyingAnalytics,
  filters,
  loading = true,
}: V2PostTradeNoteDetailModalAnalyticsTabProps): ReactElement => {
  const tabs = ['Underlying Returns', 'Note Value'];

  const slotProps: TabsProps['slotProps'] = {
    tabs: {
      allowScrollButtonsMobile: true,
      scrollButtons: 'auto',
    },
  };

  // const handleChange = (index: number) => {
  //   dispatch(
  //     UserActions.trackUserActivity({
  //       verb: `clicked ${tabs[index]} Tab`,
  //       name: modelName,
  //       id: Number(referenceId),
  //     }),
  //   );
  // };

  return (
    <Tabs disableGutters variant="scrollablePills" slotProps={slotProps} tabs={tabs} loading={loading}>
      <ConditionalWrapper
        Wrapper={V2PostTradeNoteDetailModalAnalyticsTabAlert}
        condition={!loading && !underlyingAnalytics?.chart.data.length}
      >
        <V2PostTradeNoteDetailModalUnderlyingPerformance
          termsheetId={termsheetId}
          underlyingAnalytics={underlyingAnalytics}
          filters={filters}
          loading={loading}
        />
      </ConditionalWrapper>

      <ConditionalWrapper
        Wrapper={V2PostTradeNoteDetailModalAnalyticsTabAlert}
        condition={!loading && !noteAnalytics?.chart.data.length}
      >
        <V2PostTradeNoteDetailModalNoteValueChart noteAnalytics={noteAnalytics} loading={loading} />
      </ConditionalWrapper>
    </Tabs>
  );
};
