import { ReactElement } from 'react';

import { EducationGlossaryTerm } from '@halo-common/models';
import { GlossarySectionHeader, GlossaryTerm } from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';

export type GlossarySectionProps = {
  letter?: string;
  terms?: Array<EducationGlossaryTerm>;
  loading?: boolean;
};

export const GlossarySection = ({ letter = '', terms = [], loading = false }: GlossarySectionProps): ReactElement => {
  if (loading) {
    return (
      <Stack direction="column">
        <GlossarySectionHeader loading />
        <Stack direction="column" spacing={1}>
          <GlossaryTerm loading />
          <GlossaryTerm loading />
          <GlossaryTerm loading />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack direction="column">
      <GlossarySectionHeader letter={letter} />
      <Stack direction="column" spacing={1}>
        {terms.map((term) => (
          <GlossaryTerm key={term.id} title={term.title} definition={term.definition} video={term.video} />
        ))}
      </Stack>
    </Stack>
  );
};
