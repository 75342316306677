import { ConditionalField, Input } from '@halo-common/components/FormBuilder';
import { translations } from '@halo-common/translations';
import { OnboardingSuitabilityForm, SuitabilityStepModel } from '@halo-modules/app/v2/onboarding';
import { Stack } from '@mui/material';

const suitability = translations.onboarding.suitability;

const FirmStructureForm = () => (
  <OnboardingSuitabilityForm>
    <Stack spacing={1}>
      <Input type="checkbox" label={suitability.cCorp} name="firm_details.structure_checkbox_c_corp" />
      <Input type="checkbox" label={suitability.sCorp} name="firm_details.structure_checkbox_s_corp" />
      <Input type="checkbox" label={suitability.partnership} name="firm_details.structure_checkbox_partnership" />
      <Input type="checkbox" label={suitability.LLC} name="firm_details.structure_checkbox_llc" />
      <Input type="checkbox" label={suitability.trust} name="firm_details.structure_checkbox_trust" />
      <Input type="checkbox" label={suitability.other} name="firm_details.structure_checkbox_other" />
      <ConditionalField field="firm_details.structure_checkbox_other">
        <Input label={suitability.pleaseExplain} name="firm_details.structure_other_text" />
      </ConditionalField>
    </Stack>
  </OnboardingSuitabilityForm>
);

export const structureTypeStep: SuitabilityStepModel = {
  id: '1-3',
  title: suitability.whatTypeOfStructureIsThisFirm,
  subtitle: suitability.selectAllThatApply,
  component: <FirmStructureForm />,
  isCompleted: (formData) => {
    const firmDetails = formData?.firm_details || {};
    return Boolean(
      firmDetails.structure_checkbox_c_corp ||
        firmDetails.structure_checkbox_s_corp ||
        firmDetails.structure_checkbox_partnership ||
        firmDetails.structure_checkbox_llc ||
        firmDetails.structure_checkbox_trust ||
        (firmDetails.structure_checkbox_other && firmDetails.structure_other_text),
    );
  },
};
