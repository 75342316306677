import { UserRoleEnum } from '@halo-common/enums';
import { GetEducationHubDetailsRequestModel } from '@halo-common/models';
import { updateUserRoles } from '@halo-data-sources/clients';
import { ApiUserRoleEnum } from '@halo-data-sources/enums';
import { EducationHubQueryKeyFactory, EducationHubUsersQueryResult } from '@halo-data-sources/queries';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

export type EducationHubUserRoleMutationPayload = {
  userId?: number;
  role?: UserRoleEnum;
  addRole: boolean;
};

export type EducationHubUserRoleMutationResult = {
  userId: number;
  role: UserRoleEnum;
  addRole: boolean;
  message: string;
};

const updateEducationHubUserRoleMutationFn = async ({ userId, role, addRole }: EducationHubUserRoleMutationPayload) => {
  if (!userId || !role) return null;

  const apiRole = ApiUserRoleEnum[role];
  await updateUserRoles(userId, apiRole, addRole);

  return {
    userId,
    role,
    addRole,
    message: 'Successfully updated user role',
  };
};

export const useEducationHubUserRoleMutation = (
  filters: GetEducationHubDetailsRequestModel,
): UseMutationResult<EducationHubUserRoleMutationResult | null, Error, EducationHubUserRoleMutationPayload> => {
  const queryClient = useQueryClient();

  return useMutation<EducationHubUserRoleMutationResult | null, Error, EducationHubUserRoleMutationPayload>({
    mutationFn: updateEducationHubUserRoleMutationFn,
    onSuccess: (data) => {
      const key = EducationHubQueryKeyFactory.admin(filters);
      queryClient.setQueryData<EducationHubUsersQueryResult>(key, (prev) => {
        if (!prev || !data) return undefined;

        const { userId, role, addRole } = data;

        const users = prev?.users ?? [];
        const updatedUsers = [...users];
        const updatedUserIndex = updatedUsers.findIndex((user) => user?.id === userId);
        const updatedUser = updatedUsers[updatedUserIndex];

        if (role === UserRoleEnum.Buyer) updatedUser.canTrade = addRole;
        else updatedUser.canBuy = addRole;

        updatedUsers.splice(updatedUserIndex, 1, updatedUser);

        return { ...prev, users: updatedUsers };
      });
    },
  });
};
