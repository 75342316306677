import { PershingStatusIndicator } from '@halo-common/components';
import { AllocationStatusEnum, PershingStatusEnum } from '@halo-common/enums';
import { CalendarModel } from '@halo-common/models';
import { GridColDef } from '@mui/x-data-grid-pro';

import { ViewComplianceApprovalModalButton } from './ViewComplianceApprovalModalButton';

export const useComplianceApprovalColumns = (calendar?: CalendarModel): Array<GridColDef> => {
  const commonCellProps = {
    editable: false,
    resizable: false,
    disableReorder: true,
  };

  return [
    {
      ...commonCellProps,
      field: 'accountName',
      headerName: 'ACCOUNT',
      flex: 1,
      valueGetter: (_, row) => {
        return row?.account?.accountId;
      },
    },
    {
      ...commonCellProps,
      field: 'userName',
      headerName: 'IP',
      flex: 1,
      valueGetter: (_, row) => {
        return row?.user?.name;
      },
    },
    {
      ...commonCellProps,
      field: 'status',
      headerName: 'STATUS',
      flex: 0.5,
      renderCell: ({ row }) => {
        const { approved, pershingOrderBookStatus } = row;

        const isDenied = approved === false;
        const isCanceled = pershingOrderBookStatus === AllocationStatusEnum.canceled;

        let indicatorValue = PershingStatusEnum['Needs Review'];

        if (isCanceled) indicatorValue = PershingStatusEnum.Canceled;
        else if (isDenied) indicatorValue = PershingStatusEnum.Denied;
        else if (approved) indicatorValue = PershingStatusEnum.Confirmed;

        return <PershingStatusIndicator value={indicatorValue} enableActionRequiredStyling />;
      },
    },
    {
      ...commonCellProps,
      field: 'actions',
      headerName: '',
      sortable: false,
      resizable: false,
      renderCell: ({ row }) => <ViewComplianceApprovalModalButton allocation={row} calendar={calendar} />,
    },
  ];
};
