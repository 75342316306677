import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { PopoverRangeFilter } from '@halo-common/components';
import { useSliderText } from '@halo-common/hooks';
import { useAtom } from 'jotai';

export const PRINCIPAL_PROTECTION_SLIDER_MIN_VALUE = 0;
export const PRINCIPAL_PROTECTION_SLIDER_MAX_VALUE = 100;

const DEFAULT_SLIDER_DATA = {
  min: PRINCIPAL_PROTECTION_SLIDER_MIN_VALUE,
  max: PRINCIPAL_PROTECTION_SLIDER_MAX_VALUE,
};

export type PrincipalProtectionSliderFilterProps = {
  tag: string;
};

export const PrincipalProtectionSliderFilter = ({ tag }: PrincipalProtectionSliderFilterProps): ReactElement => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);
  const { protectionAmount } = sideBarFilters;

  const [sliderData, setSliderData] = useState(DEFAULT_SLIDER_DATA);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const sliderMin = sliderData?.min ?? PRINCIPAL_PROTECTION_SLIDER_MIN_VALUE;
  const sliderMax = sliderData?.max ?? PRINCIPAL_PROTECTION_SLIDER_MAX_VALUE;
  const sliderValue = [sliderMin, sliderMax];

  const isSliderMinValue = sliderMin === PRINCIPAL_PROTECTION_SLIDER_MIN_VALUE;
  const isSliderMaxValue = sliderMax === PRINCIPAL_PROTECTION_SLIDER_MAX_VALUE;

  const open = Boolean(anchorEl);
  const id = open ? 'protection-amount-popover' : undefined;

  const minInputValue = !isSliderMinValue ? sliderMin : '';
  const maxInputValue = !isSliderMaxValue ? sliderMax : '';

  const formattedButtonText = useSliderText({
    minValue: PRINCIPAL_PROTECTION_SLIDER_MIN_VALUE,
    maxValue: PRINCIPAL_PROTECTION_SLIDER_MAX_VALUE,
    suffix: '%',
    leftSliderValue: sliderMin,
    rightSliderValue: sliderMax,
  });

  const handlePopoverOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleDoneClick = () => {
    if (isSliderMinValue && isSliderMaxValue) setSideBarFilters({ tag, protectionAmount: null });
    else setSideBarFilters({ tag, protectionAmount: { min: sliderMin, max: sliderMax } });
    handlePopoverClose();
  };

  const handleSliderChange = (_: unknown, value: number | Array<number>) => {
    const updatedSliderMin = typeof value === 'number' ? value : value[0];
    const updatedSliderMax = typeof value === 'number' ? value : value[1];
    setSliderData({ min: updatedSliderMin, max: updatedSliderMax });
  };

  const handleMinInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const inputValue = value ? parseInt(event.target.value) : PRINCIPAL_PROTECTION_SLIDER_MIN_VALUE;

    const isLessThanMin = inputValue <= PRINCIPAL_PROTECTION_SLIDER_MIN_VALUE;
    const filteredValue = isLessThanMin ? PRINCIPAL_PROTECTION_SLIDER_MIN_VALUE : inputValue;

    setSliderData({ min: filteredValue, max: sliderMax });
  };

  const handleMaxInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const inputValue = value ? parseInt(event.target.value) : PRINCIPAL_PROTECTION_SLIDER_MAX_VALUE;

    const isGreaterThanMax = inputValue >= PRINCIPAL_PROTECTION_SLIDER_MAX_VALUE;
    const filteredValue = isGreaterThanMax ? PRINCIPAL_PROTECTION_SLIDER_MAX_VALUE : inputValue;

    setSliderData({ min: sliderMin, max: filteredValue });
  };

  useEffect(() => {
    const resetValue = protectionAmount === null;
    const hasValueChanged = protectionAmount?.max !== sliderData.max || protectionAmount?.min !== sliderData.min;
    if (resetValue) setSliderData(DEFAULT_SLIDER_DATA);
    else if (hasValueChanged) setSliderData(protectionAmount);
  }, [protectionAmount]);

  return (
    <PopoverRangeFilter
      suffix="%"
      maxInputValue={maxInputValue}
      minInputValue={minInputValue}
      PopoverProps={{
        id,
        open,
        anchorEl,
        onClose: handlePopoverClose,
      }}
      SliderProps={{
        onChange: handleSliderChange,
        max: PRINCIPAL_PROTECTION_SLIDER_MAX_VALUE,
        min: PRINCIPAL_PROTECTION_SLIDER_MIN_VALUE,
        step: 1,
        value: sliderValue,
      }}
      buttonText={formattedButtonText}
      handleMinInputChange={handleMinInputChange}
      handleMaxInputChange={handleMaxInputChange}
      handlePopoverOpen={handlePopoverOpen}
      handleDoneClick={handleDoneClick}
    />
  );
};
