import { ReactElement } from 'react';

import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';

export type EducationParagraphProps = {
  title?: string;
  children?: string;
  bold?: boolean;
  italics?: boolean;
};

export const EducationParagraph = ({
  title,
  children,
  bold = false,
  italics = false,
}: EducationParagraphProps): ReactElement => {
  const contentSx = {
    fontStyle: italics ? 'italic' : 'normal',
    fontWeight: bold ? 'fontWeightBold' : 'fontWeightRegular',
  };

  const titleContent = title ? (
    <LocalizedTypography color="textPrimary" variant="h5">
      {title}
    </LocalizedTypography>
  ) : null;

  return (
    <Stack direction="column" justifyContent="space-between" spacing={1}>
      {titleContent}
      <LocalizedTypography sx={contentSx} color="textPrimary" data-testid="education-paragraph">
        {children}
      </LocalizedTypography>
    </Stack>
  );
};
