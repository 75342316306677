import { ReactElement } from 'react';

import { NoteSpecificDetailsField } from '@halo-common/hooks';
import { Stack } from '@halodomination/halo-fe-common';
import { Skeleton, Typography } from '@mui/material';

export type ExecutionAuctionReviewDetailModalHeaderSolvableParameterProps = {
  parameter?: NoteSpecificDetailsField | null;
  loading?: boolean;
};

export const ExecutionAuctionReviewDetailModalHeaderSolvableParameter = ({
  parameter,
  loading = true,
}: ExecutionAuctionReviewDetailModalHeaderSolvableParameterProps): ReactElement => {
  if (loading) return <Skeleton width={200} height={80} />;

  if (!parameter) {
    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        <Stack direction="column">
          <Typography variant="h2" color="textPrimary">
            --
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Stack direction="column">
        <Typography variant="h2" color="textPrimary">
          {parameter?.text}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {parameter?.label}
        </Typography>
      </Stack>
    </Stack>
  );
};
