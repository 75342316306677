import { OrderBookAccountCapacityEnum } from '@halo-common/enums';
import { UnderlyingModel } from '@halo-common/models';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { GridPaginationModel, GridSortModel } from '@halodomination/halo-fe-common';
import { atom } from 'jotai';

export enum OrderBookModalsEnum {
  auctionDetails = 'auctionDetails',
}

export type OrderBookFilters = Partial<{
  account: AccountTypeAheadOption | null;
  capacity: string;
  cusip: string;
  ibd: string;
  issuer: string;
  status: string;
  orderNumber: string;
  timeFrame: string;
  userName: string;
  noteType: string;
  underlying: Array<UnderlyingModel>;
  is_open_janney: boolean;
  is_open_pershing: boolean;
}>;

export type OrderBookQuery = GridPaginationModel & {
  sortBy: GridSortModel;
  filters: OrderBookFilters;
};

export type OrderBookQueryPayload = Partial<
  GridPaginationModel & {
    sortBy: GridSortModel;
    filters: Partial<OrderBookFilters>;
  }
>;

export const DEFAULT_ORDERBOOK_QUERY_FIELDS = {
  page: 1,
  pageSize: 25,
  sortBy: [{ sort: 'desc', field: 'updatedOn' }] as GridSortModel,
  filters: {
    account: null,
    capacity: OrderBookAccountCapacityEnum.all,
    cusip: '',
    ibd: '',
    issuer: '',
    orderNumber: '',
    status: '',
    timeFrame: 'thirtyDays',
    userName: '',
    noteType: '',
    underlying: [],
  },
};

export const _orderBookQueryAtom = atom<OrderBookQuery>(DEFAULT_ORDERBOOK_QUERY_FIELDS);
export const orderBookQueryAtom = atom(
  (get) => get(_orderBookQueryAtom),
  (_, set, payload?: OrderBookQueryPayload) => {
    if (payload) {
      set(_orderBookQueryAtom, (prev) => ({
        ...prev,
        ...payload,
        filters: { ...prev.filters, ...payload.filters },
      }));
    } else {
      set(_orderBookQueryAtom, DEFAULT_ORDERBOOK_QUERY_FIELDS);
    }
  },
);
