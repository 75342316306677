import { downloadFile } from '@halo-common/utils';
import { getAdminCalendarAllocationCSVDownload } from '@halo-data-sources/clients';
import { ComplianceApprovalMapper } from '@halo-data-sources/mappers';
import { AdminCalendarAllocationsQueryFilters } from '@halo-data-sources/queries';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { DateTime } from 'luxon';

const complianceApprovalDownloadCSVMutationFn = async (filters: AdminCalendarAllocationsQueryFilters) => {
  const comparisons = ComplianceApprovalMapper.toCalendarListQueryComparisons(filters);

  const response = await getAdminCalendarAllocationCSVDownload({
    comparisons,
    page: null,
    page_length: null,
  });

  const csvDownload = new Blob([response], { type: 'text/csv' });
  const url = URL.createObjectURL(csvDownload);

  const date = DateTime.local().toFormat('MMddyyyy_hhmmss');
  const fileName = `orders.${date}.csv`;

  downloadFile({ fileName, downloadFilePath: url });
};

export const useComplianceApprovalDownloadCSVMutation = (): UseMutationResult<
  void,
  Error,
  AdminCalendarAllocationsQueryFilters
> =>
  useMutation<void, Error, AdminCalendarAllocationsQueryFilters>({
    mutationFn: complianceApprovalDownloadCSVMutationFn,
  });
