import { portfolioLifecycleManagerAtom, portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import { PortfolioLifecycleRecommendationEnhancedModel } from '@halo-common/models';
import type {
  GetPortfolioLifecycleEventsQueryFilterModel,
  GetPortfolioLifecycleInfiniteResult,
} from '@halo-data-sources/queries';
import {
  useLifecycleEventsQuery,
  useLifecycleRecommendationsQuery,
  useRecentQuotesQuery,
} from '@halo-data-sources/queries';
import type { InfiniteData } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

const DEFAULT_EVENTS_DATA: InfiniteData<GetPortfolioLifecycleInfiniteResult> = { pages: [], pageParams: [] };

export type EnhancedEventsListResult = {
  events: InfiniteData<GetPortfolioLifecycleInfiniteResult>;
  reinvestments: Array<PortfolioLifecycleRecommendationEnhancedModel | undefined>;
  filterTypes: Array<string>;
  isPending: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
};

export const useGetEnhancedEventsList = (
  eventsPayload: GetPortfolioLifecycleEventsQueryFilterModel,
): EnhancedEventsListResult => {
  const { data = DEFAULT_EVENTS_DATA, ...lifecycleQuery } = useLifecycleEventsQuery(eventsPayload);

  const recommendationPayload = data.pages.flatMap((page) => page.events);
  const { data: recommendationData } = useLifecycleRecommendationsQuery(recommendationPayload);

  const reinvestments = recommendationData?.reinvestments ?? [];
  const recommendedFilterTypes = recommendationData?.filterTypes ?? [];

  const recommendedReinvestments = data.pages.flatMap((page) =>
    page.events.map((event) => {
      return reinvestments.find((reinvestment) => {
        const matchingEventType = reinvestment.eventType === event.eventType;
        const matchingEventDate = reinvestment.eventDate === event.eventDate;
        const matchingProductId = reinvestment.productId === event.productId;
        const matchingCashFlowAmount = reinvestment.totalNotional === Math.trunc(event.cashFlowAmount);

        const matchingReinvestment =
          matchingEventType &&
          matchingEventDate &&
          matchingProductId &&
          matchingCashFlowAmount &&
          Boolean(reinvestment?.recommendationType);

        if (matchingReinvestment) return reinvestment;
      });
    }),
  );

  useRecentQuotesQuery(reinvestments.map(({ note }) => note?.id));

  return {
    events: data,
    reinvestments: recommendedReinvestments,
    filterTypes: recommendedFilterTypes,
    isPending: lifecycleQuery.isPending,
    isFetchingNextPage: lifecycleQuery.isFetchingNextPage,
    hasNextPage: lifecycleQuery.hasNextPage,
    fetchNextPage: lifecycleQuery.fetchNextPage,
  };
};

export const useEnhancedEventsList = (): EnhancedEventsListResult => {
  const { query: lifecycleFilters } = useAtomValue(portfolioLifecycleManagerAtom);
  const { query: portfolioFilters } = useAtomValue(portfolioPositionsManagerAtom);

  const { search } = lifecycleFilters;

  return useGetEnhancedEventsList({ ...lifecycleFilters, ...portfolioFilters, search });
};
