import { CountryModel } from '@halo-common/models';
import { fetchCountries } from '@halo-data-sources/clients';
import { ApiCountryMapper } from '@halo-data-sources/mappers';
import { CommonQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export type CountryQueryResult = Array<CountryModel>;

const useCountryQueryFn = async () => {
  const response = await fetchCountries();

  return response.map(ApiCountryMapper.toCountry);
};

export const useCountryQuery = (): UseQueryResult<CountryQueryResult, Error> =>
  useQuery<CountryQueryResult, Error>({
    queryFn: useCountryQueryFn,
    queryKey: CommonQueryKeyFactory.countries(),
  });
