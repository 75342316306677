import { ReactElement, useCallback } from 'react';

import { useModalState } from '@halo-common/hooks';
import { CONFIGURE_WIDGETS_MODAL_ID } from '@halo-common/modals';
import { useDashboardConfigQuery } from '@halo-modules/app';
import { Iconography } from '@halodomination/halo-fe-common';
import { IconButton, IconButtonProps } from '@mui/material';

export type WidgetOpenCustomizationModalButtonProps = IconButtonProps;

export const WidgetOpenCustomizationModalButton = (
  props: WidgetOpenCustomizationModalButtonProps,
): ReactElement | null => {
  const { widgetsAreHidable, widgetsAreReorderable } = useDashboardConfigQuery();
  const { setOpen } = useModalState(CONFIGURE_WIDGETS_MODAL_ID);

  const openModal = useCallback(() => setOpen(true), [setOpen]);

  if (!widgetsAreHidable && !widgetsAreReorderable) return null;

  return (
    <IconButton onClick={openModal} className="wb-dashboard-customizewidget" {...props}>
      <Iconography iconName="gear" color="text.secondary" />
    </IconButton>
  );
};
