import { ReactElement, useState } from 'react';

import { HALO_ORGANIZATION_DETAILS } from '@halo-common/constants';
import { UploadFileModal } from '@halo-common/modals';
import { DocumentUserFile } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import {
  useRemoveUserFileMutation,
  useUploadUserFileMutation,
  useUserFileDownloadMutation,
} from '@halo-data-sources/mutations';
import { useUserFilesQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { AccountCard, DocumentsTable, DocumentsTableSkeleton, EmptyBox } from '@halo-modules/app';
import { LocalizedButton, LocalizedTextField } from '@halodomination/halo-fe-common';
import { useForm } from 'react-hook-form';

export const MyFilesSection = (): ReactElement => {
  const { data: user } = useUserInfoQuery();
  const { data: files = [], isPending: loadingFiles } = useUserFilesQuery();

  const { mutate: uploadFile, isPending: uploadingFile } = useUploadUserFileMutation();
  const { mutate: downloadUserFile } = useUserFileDownloadMutation();
  const { mutate: removeUserFile } = useRemoveUserFileMutation();

  const [open, setOpen] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty },
  } = useForm<{ description: string | null }>({
    mode: 'onChange',
    defaultValues: {
      description: '',
    },
  });

  const userFacingName = user?.whiteLabel?.userFacingName ?? HALO_ORGANIZATION_DETAILS.name;
  const localization = { subtitle: { dynamicContent: { orgName: userFacingName } } };

  const descriptionError = errors?.description?.message;
  const hasDescriptionError = Boolean(descriptionError);

  const loading = loadingFiles || uploadingFile;
  const disabled = !isDirty || loading || hasDescriptionError;

  const { ref: descriptionRef, ...descriptionProps } = register('description', { required: 'This field is required.' });

  const getId = (model: DocumentUserFile) => model.id;

  const handleFileUpload = (file: File) => {
    void handleSubmit(({ description }) => uploadFile({ file, notes: description }))();
    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const handleMapValues = (model: DocumentUserFile) => ({
    id: model.id,
    name: model.document.filename,
    description: model.notes,
    extension: model.document.extension ?? '',
  });

  const actions = (
    <LocalizedButton color="primary" variant="contained" size="small" onClick={handleOpen}>
      {translations.profile.documents.addNewFile}
    </LocalizedButton>
  );

  const content =
    !files.length && !loadingFiles ? (
      <EmptyBox
        title={translations.profile.documents.noFiles}
        subtitle={translations.profile.documents.noFilesSubtitle}
      />
    ) : loadingFiles ? (
      <DocumentsTableSkeleton />
    ) : (
      <DocumentsTable
        getId={getId}
        mapValues={handleMapValues}
        models={files}
        onDownload={downloadUserFile}
        onRemove={removeUserFile}
      />
    );

  return (
    <>
      <AccountCard
        title={translations.profile.documents.myFiles}
        subtitle={translations.profile.documents.myFilesSubtitle}
        actions={actions}
      >
        {content}
      </AccountCard>
      <UploadFileModal
        open={open}
        onSubmit={handleFileUpload}
        subtitle={translations.profile.documents.uploadFile}
        title={translations.profile.documents.modalTitle}
        loading={loading}
        Localization={localization}
        approvalStep={{
          disabled,
          subtitle: translations.profile.documents.modalSubtitle,
          content: (
            <LocalizedTextField
              {...descriptionProps}
              inputRef={descriptionRef}
              label={translations.profile.documents.fileDescription}
              multiline
              fullWidth
              rows={3}
              error={hasDescriptionError}
              helperText={descriptionError}
            />
          ),
        }}
        onClose={handleClose}
      />
    </>
  );
};
