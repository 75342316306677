export enum ExecutionHubStatusEnum {
  archived = 'archived',
  available = 'available',
  filled = 'filled',
  pending = 'pending',
}

export enum LoadingDockStatusEnum {
  active = 'active',
  available = 'available',
  archived = 'archived',
  pending = 'pending',
}

export enum AdminCalendarStatusEnum {
  active = 'active',
  available = 'available',
  archived = 'archived',
  pending = 'pending',
}

export enum AdminCalendarCSVQueryParameterEnum {
  activePending = 'activePending',
}

export enum AdminCalendarAllocationStatusEnum {
  accepted = 'accepted',
  canceled = 'canceled',
  pending = 'pending',
  filled = 'filled',
}

export enum AdminCalendarAllocationFilledStatusEnum {
  success = 'success',
  failed = 'failed',
  warning = 'warning',
  websocket = 'websocket',
  filled = 'filled',
  pending = 'pending',
}

export type AdminCalendarStatusFilter =
  | keyof typeof AdminCalendarCSVQueryParameterEnum
  | keyof typeof AdminCalendarAllocationStatusEnum
  | keyof typeof AdminCalendarStatusEnum
  | keyof typeof ExecutionHubStatusEnum;
