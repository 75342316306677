export enum LifecycleEventEnum {
  EMPTY = 'EMPTY',
  EXPIRATION = 'Maturity',
  FIXED_COUPON = 'Fixed Coupon',
  CONDITIONAL_COUPON = 'Conditional Coupon',
  MEMORY_COUPON = 'Memory Coupon',
  AUTOCALL_OBS = 'Autocall',
  ISSUER_CALL_OBS = 'Issuer Call',
}

export enum LifecycleEventTypeEnum {
  EMPTY = 'EMPTY',
  EXPIRATION = 'EXPIRATION',
  FIXED_COUPON = 'FIXED_COUPON',
  CONDITIONAL_COUPON = 'CONDITIONAL_COUPON',
  MEMORY_COUPON = 'MEMORY_COUPON',
  AUTOCALL_OBS = 'AUTOCALL_OBS',
  ISSUER_CALL_OBS = 'ISSUER_CALL_OBS',
}

export enum LifecycleAllEventTypeEnum {
  ALL_COUPONS = 'ALL_COUPONS',
  ALL_CALL_OBSERVATION = 'ALL_CALL_OBSERVATION',
  ALL_COUPON_AND_CALL_OBSERVATIONS = 'ALL_COUPON_AND_CALL_OBSERVATIONS',
}

export enum LifecycleConditionalEventTypeEnum {
  ABOVE_CALL_LEVEL = 'ABOVE_CALL_LEVEL',
  BELOW_PROTECTION = 'BELOW_PROTECTION',
}

export enum LifecycleRecommendationTypeEnum {
  AUCTION = 'AUCTION',
  CALENDAR = 'CALENDAR',
}

export enum LifecycleSortEnum {
  name = 'composite_name',
  totalNotional = 'notional',
  cashFlowAmount = 'notional',
}

export enum LifecycleProductTypeEnum {
  NOTE = 'NOTE',
  ANNUITY = 'ANNUITY',
}
