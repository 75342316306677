import { ReactElement } from 'react';

import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { usePershingOrderBookIssuersQuery } from '@halo-data-sources/queries';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useAtom } from 'jotai';

export const PershingIssuerField = (): ReactElement => {
  const [searchFields, setSearchFields] = useAtom(orderBookQueryAtom);

  const { data } = usePershingOrderBookIssuersQuery(searchFields);

  const labelString = 'Issuer';
  const issuers = data ?? [];

  const handleIssuerChange = (ev: SelectChangeEvent<string>) => {
    const value = ev.target.value;

    setSearchFields({
      filters: {
        issuer: value,
      },
    });
  };

  return (
    <FormControl fullWidth>
      <InputLabel shrink>{labelString}</InputLabel>
      <Select
        label={labelString}
        size="large"
        value={searchFields.filters.issuer}
        onChange={handleIssuerChange}
        displayEmpty
        notched
      >
        <MenuItem value="">All</MenuItem>
        {issuers.map((issuer) => (
          <MenuItem key={issuer.id} value={issuer.id}>
            {issuer.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
