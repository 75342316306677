import { NoteModel } from '@halo-common/models';
import { getNote } from '@halo-data-sources/clients';
import { mapApiNoteModelToNoteModel } from '@halo-data-sources/mappers';
import { NoteQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

const getNoteQueryFn = async (id?: number | string | null) => {
  if (!id) return null;
  const data = await getNote(id);
  return mapApiNoteModelToNoteModel(data.note);
};

export const useNoteQuery = (
  id?: number | string | null,
  options?: Partial<UseQueryOptions<NoteModel | null, Error>>,
): UseQueryResult<NoteModel | null, Error> =>
  useQuery<NoteModel | null, Error>({
    ...options,
    queryKey: NoteQueryKeyFactory.note(id),
    queryFn: () => getNoteQueryFn(id),
  });
