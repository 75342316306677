import { ReactElement } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Chip, SxProps } from '@mui/material';
import { useAtom } from 'jotai';

export type CurrencyChipsProps = {
  tag: string;
  sx: SxProps;
};

export const CurrencyChips = ({ tag, sx }: CurrencyChipsProps): ReactElement => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);

  const { data } = useUserInfoQuery();

  const userCurrencies = data?.whiteLabel?.currencies;

  const handleDynamicCurrencyChipDelete = (value: string) => {
    const updatedCurrencies = sideBarFilters.currencies.filter((currency) => currency !== value);
    setSideBarFilters({ tag, currencies: updatedCurrencies });
  };

  const chips = sideBarFilters.currencies.map((currency) => {
    const selectedCurrency = userCurrencies?.find((userCurrency) => userCurrency.id === parseInt(currency));

    if (!selectedCurrency) return null;

    return (
      <Chip
        sx={sx}
        size="small"
        variant="outlined"
        key={selectedCurrency.id}
        label={selectedCurrency.code}
        onDelete={() => handleDynamicCurrencyChipDelete(currency)}
      />
    );
  });

  return <>{chips}</>;
};
