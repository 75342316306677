import { ReactElement } from 'react';

import { assetIdSearchValueAtom } from '@halo-atoms/portfolio';
import { translations } from '@halo-common/translations';
import { validateFundServCode } from '@halo-common/utils';
import { usePortfolioAssetExistenceQuery } from '@halo-data-sources/queries';
import { LocalizedAlertTitle } from '@halodomination/halo-fe-common';
import { Alert, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';

export const ChooseAssetAlerts = (): ReactElement => {
  const assetIdValue = useAtomValue(assetIdSearchValueAtom);
  const assetIds = assetIdValue ? [assetIdValue] : undefined;

  const { data: assets, isFetching } = usePortfolioAssetExistenceQuery(assetIds);

  const selectedAsset = assets?.[0];
  const assetId = selectedAsset?.assetId;

  const assetIdExists = Boolean(selectedAsset?.location);

  const isAssetJHN = validateFundServCode(assetId, selectedAsset?.assetIdType);

  const showAssetNotFoundAlert = !assetIdExists && Boolean(selectedAsset) && assetIdValue && !isFetching;
  const showJHNAlert = isAssetJHN && assetIdExists && Boolean(selectedAsset) && !isFetching;

  const assetNotFoundAlert = showAssetNotFoundAlert ? (
    <Alert severity="warning" variant="filled">
      <LocalizedAlertTitle>
        {translations.portfolio.addPositionToAccountsModal.assetSearchAssetNotFoundError}
      </LocalizedAlertTitle>
      {assetId}
    </Alert>
  ) : null;

  const jhnAlert = showJHNAlert ? (
    <Alert severity="error" variant="filled">
      <LocalizedAlertTitle>
        {translations.portfolio.addPositionToAccountsModal.assetSearchUnauthorizedError}
      </LocalizedAlertTitle>
      {assetId}
    </Alert>
  ) : null;

  return (
    <Stack direction="column" spacing={1}>
      {assetNotFoundAlert}
      {jhnAlert}
    </Stack>
  );
};
