import { ReactElement, useEffect } from 'react';

import { adminUserFilterAtom } from '@halo-atoms/adminUser';
import { COMBINED_DATE_TIME_ZONE_FORMAT } from '@halo-common/constants';
import { useDateFormatter } from '@halo-common/hooks';
import { UserModel, UserRelationshipManagerModel } from '@halo-common/models';
import { RelationshipManagerTypeAhead, UserTypeAhead } from '@halo-common/smartComponents';
import { useAdminUserMutation } from '@halo-data-sources/mutations';
import { useAdminUserManagementInfiniteQuery } from '@halo-data-sources/queries';
import { GridSortModel, HaloDataGrid, HaloDataGridProps, Stack, useSnackbar } from '@halodomination/halo-fe-common';
import { Box, Button, Paper, Typography, outlinedInputClasses } from '@mui/material';
import { useAtom } from 'jotai';

const ADMIN_USER_CELL_NAME = 'admin-user-action-cell';

const containerSx = { p: 3, m: 3 };
const tableSx = {
  [`& .${ADMIN_USER_CELL_NAME}`]: {
    padding: 0,
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: 'unset',
  },
};

export const AdminUserTable = (): ReactElement => {
  const formatDate = useDateFormatter();

  const { enqueuePendingEvent, enqueueSuccessEvent, closeSnackbar } = useSnackbar();

  const [filters, setFilters] = useAtom(adminUserFilterAtom);

  const { data, isPending, fetchNextPage, refetch, isRefetching, isSuccess, isFetchingNextPage, hasNextPage } =
    useAdminUserManagementInfiniteQuery(filters);

  const { isPending: updatingUser, mutate } = useAdminUserMutation();

  const rows = data?.pages.flatMap((page) => page.users) ?? [];

  const height = rows.length >= 15 ? 470 : 'auto';

  const isTableLoading = isPending || isFetchingNextPage;

  const columns: HaloDataGridProps['columns'] = [
    {
      field: 'name',
      flex: 1,
      headerName: 'name',
    },
    {
      field: 'email',
      sortable: false,
      flex: 1,
      headerName: 'email',
    },
    {
      field: 'phone',
      sortable: false,
      flex: 1,
      headerName: 'phone',
    },
    {
      field: 'organization.name',
      flex: 1,
      headerName: 'organization',
      valueGetter: (_, row: UserModel) => row?.organization?.name,
    },
    {
      field: 'createdAt',
      flex: 1,
      headerName: 'created at',
      valueFormatter: (value: string) => formatDate(value, COMBINED_DATE_TIME_ZONE_FORMAT),
    },
    {
      field: 'lastLogin',
      flex: 1,
      headerName: 'last login at',
      valueFormatter: (value: string) => formatDate(value, COMBINED_DATE_TIME_ZONE_FORMAT),
    },
    {
      field: 'relationshipManager',
      flex: 1,
      headerName: 'relationship manager',
      cellClassName: ADMIN_USER_CELL_NAME,
      renderCell: ({ row }) => (
        <Box sx={{ width: '100%' }}>
          <RelationshipManagerTypeAhead
            value={row?.relationshipManager}
            fullWidth
            TextFieldProps={{ label: undefined }}
            loading={updatingUser}
            onChange={(option) => {
              const shouldUpdate = option && !Array.isArray(option);
              if (shouldUpdate) mutate({ userId: row.id, userName: row.name, managerId: option.id });
            }}
          />
        </Box>
      ),
    },
  ];

  const handleInfiniteScroll = () => {
    const loadMoreContent = !isFetchingNextPage && hasNextPage;
    if (loadMoreContent) void fetchNextPage();
  };

  const handleSort = (model: GridSortModel) => {
    setFilters({ orderBy: model.map(({ field }) => field) });
  };

  const handleUserChange = (option: UserModel | Array<UserModel> | null) => {
    const isArray = Array.isArray(option);
    setFilters({ ...filters, users: isArray ? option : option ? [option] : [] });
  };

  const handleRelationshipManagerChange = (
    option: UserRelationshipManagerModel | Array<UserRelationshipManagerModel> | null,
  ) => {
    const isArray = Array.isArray(option);
    setFilters({ ...filters, relationshipManagers: isArray ? option : option ? [option] : [] });
  };

  const handleRefresh = () => void refetch();
  const handleReset = () => void setFilters();

  useEffect(() => {
    if (isRefetching) enqueuePendingEvent({ message: 'Reloading admin user data.' });
    if (!isRefetching && isSuccess) {
      closeSnackbar();
      enqueueSuccessEvent({ message: 'Reloaded admin user data.' });
    }
  }, [isRefetching]);

  return (
    <Paper variant="outlined" elevation={0} sx={containerSx}>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={1} xs={[2, 10]} wrap="wrap">
          <Typography variant="h6" fontWeight="fontWeightBold">
            Users
          </Typography>
          <Stack direction="row" alignItems="center" justify="flex-end" spacing={1} xs={12} md={3} wrap="wrap">
            <Stack direction="row" justify="flex-end" alignItems="center" spacing={1}>
              <Button onClick={handleRefresh}>Refresh</Button>
              <Button onClick={handleReset}>Reset</Button>
            </Stack>
            <UserTypeAhead fullWidth multiple limitTags={1} onChange={handleUserChange} value={filters.users} />
            <RelationshipManagerTypeAhead
              fullWidth
              multiple
              limitTags={1}
              onChange={handleRelationshipManagerChange}
              value={filters.relationshipManagers}
            />
          </Stack>
        </Stack>
        <HaloDataGrid
          height={height}
          sx={tableSx}
          columns={columns}
          rows={rows}
          rowCount={rows.length}
          loading={isTableLoading}
          density="compact"
          sortingMode="server"
          noResultsMessage="No Users Found"
          paginationMode="server"
          onSortModelChange={handleSort}
          onRowsScrollEnd={handleInfiniteScroll}
        />
      </Stack>
    </Paper>
  );
};
