import { useT } from '@transifex/react';
import { DateTime } from 'luxon';

export type UseTranslateMonthlyLabelReturnType = (value: string) => string;

export const useTranslateMonthlyLabel = (): UseTranslateMonthlyLabelReturnType => {
  const translator = useT();

  const translateMonthlyLabel = (label: string) => {
    const formattedDate = DateTime.fromFormat(label, 'yyyy-LL').toFormat('LLL yyyy');
    const splitDate = formattedDate.split(' ');
    const month = splitDate[0];
    const year = splitDate[1];
    const translatedMonth = translator(month);
    return `${translatedMonth} ${year}`;
  };

  return translateMonthlyLabel;
};
