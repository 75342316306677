import { ReactElement, ReactNode, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { keyframes } from '@mui/system';

const iconBounce = keyframes`
0% {
  padding-top: 0;
}
20% {
  padding-top: 9px;
}
90% {
  padding-top: 6px;
}
100% {
  padding-top: 8px;
}
`;

const iconUp = keyframes`
0% {
  padding-top: 8px;
}
30% {
  padding-top: 0px;
}
90% {
  padding-top: 2px;
}
100% {
  padding-top: 0px;
}
`;

const iconDownSpin = keyframes`
0% {
  transform: rotate(0deg);
}
30% {
  transform: rotate(196deg);
}
90% {
  transform: rotate(173deg);
}
100% {
  transform: rotate(180deg);
}
`;

const iconUpSpin = keyframes`
0% {
  transform: rotate(180deg);
}
30% {
  transform: rotate(-16deg);
}
90% {
  transform: rotate(7deg);
}
100% {
  transform: rotate(0deg);
}
`;

export type IconBounceRotateProps = {
  children: ReactNode;
  active?: boolean;
  rotated?: boolean;
  showIcon?: boolean;
  activeAnimationSpeed?: number;
  rotationAnimationSpeed?: number;
};

export const IconBounceRotate = ({
  children,
  active,
  rotated,
  showIcon = true,
  activeAnimationSpeed = 400,
  rotationAnimationSpeed = 300,
}: IconBounceRotateProps): ReactElement | null => {
  const [{ isActive, isRotated }, setState] = useState({ isActive: active, isRotated: active });

  useEffect(() => {
    setState({ isActive: active, isRotated: rotated });
  }, [active, rotated]);

  const iconSpinDirection = isRotated ? iconDownSpin : iconUpSpin;
  const iconRotateAnimation = `${iconSpinDirection} ${activeAnimationSpeed}ms linear`;

  const directionAnimations = {
    over: !isRotated ? iconBounce : iconUp,
    out: !isRotated ? iconUp : iconBounce,
  };

  const { over, out } = directionAnimations;
  const iconAnimationSelection = isActive ? over : out;
  const iconDirectionAnimation = `${iconAnimationSelection} ${rotationAnimationSpeed}ms linear`;

  const IconDirectionStyling = {
    animation: iconDirectionAnimation,
    animationFillMode: 'forwards',
  };
  const IconRotationStyling = {
    animation: iconRotateAnimation,
    animationFillMode: 'forwards',
  };

  return showIcon ? (
    <Box sx={IconDirectionStyling}>
      <Box sx={IconRotationStyling}>{children}</Box>
    </Box>
  ) : null;
};
