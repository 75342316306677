import { ReactElement, useCallback, useState } from 'react';

import { useDynamicSidebarFiltersListener } from '@halo-atoms/common';
import { termsheetsManagerAtom, useTermsheetsSearchFiltersListener } from '@halo-atoms/documentsRepository';
import { TERMSHEET_PREFERENCE_TAG } from '@halo-common/constants';
import { DynamicTypeFiltersSideBarFiltersEnum } from '@halo-common/enums';
import { DynamicFiltersLayout } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import {
  useTermsheetRepoConfigQuery,
  useTermsheetRepoDocsQuery,
  useTermsheetRepoFiltersQuery,
  useUserInfoQuery,
} from '@halo-data-sources/queries';
import {
  CreateTermsheetModal,
  DeleteTermsheetModal,
  SearchFilters,
  SearchFiltersState,
  useIsDocumentRepositoryManager,
  useTermsheetsDataGridColumns,
} from '@halo-modules/app/documents/repository';
import {
  GridPaginationModel,
  GridSortModel,
  HaloDataGrid,
  HaloDataGridProps,
  LocalizedButton,
  Stack,
} from '@halodomination/halo-fe-common';
import { CircularProgress } from '@mui/material';
import { GRID_DEFAULT_LOCALE_TEXT, GridInputRowSelectionModel } from '@mui/x-data-grid-pro';
import { useAtom } from 'jotai';

const loaderSx = {
  py: 2,
  width: '100%',
};

const deleteButtonSx = {
  ml: 3,
};

const Loader = () => (
  <Stack direction="column" alignItems="center" justify="center" sx={loaderSx}>
    <CircularProgress />
  </Stack>
);

export const TermsheetsPage = (): ReactElement => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [termsheetsState, setTermsheetsState] = useAtom(termsheetsManagerAtom);

  const isAdmin = useIsDocumentRepositoryManager();

  const { tableState, searchFilters, queryPayload } = termsheetsState;

  const { data: userInfo, isPending: userLoading } = useUserInfoQuery();
  const { data: config, error: configError, isPending: configLoading } = useTermsheetRepoConfigQuery();
  const { data: termsheetDocs, isPending: docsLoading, isPlaceholderData } = useTermsheetRepoDocsQuery(queryPayload);

  const { data: filterValues } = useTermsheetRepoFiltersQuery();

  const sortModel = tableState.sortBy;
  const rowSelectionModel = tableState.rowSelectionModel;
  const paginationModel = { page: tableState.page - 1, pageSize: tableState.pageSize };

  const handlePaginationChange = useCallback(
    (model: GridPaginationModel) => {
      setTermsheetsState({
        tableState: {
          page: model.page + 1,
          pageSize: model.pageSize,
        },
      });
    },
    [setTermsheetsState],
  );

  const handleSortChange = useCallback(
    (model: GridSortModel) => {
      setTermsheetsState({
        tableState: {
          sortBy: model,
        },
      });
    },
    [setTermsheetsState],
  );

  const handleSearchFiltersChange = useCallback(
    (newFilters: Partial<SearchFiltersState>) => {
      setTermsheetsState({
        searchFilters: newFilters,
      });
    },
    [setTermsheetsState],
  );

  const resetCurrentPage = useCallback(() => {
    setTermsheetsState({
      tableState: {
        page: 1,
      },
    });
  }, [setTermsheetsState]);

  const onRowSelectionModelChange: HaloDataGridProps['onRowSelectionModelChange'] = useCallback(
    (newModel: GridInputRowSelectionModel | undefined) => {
      setTermsheetsState({
        tableState: {
          rowSelectionModel: newModel,
        },
      });
    },
    [setTermsheetsState],
  );

  const resetSelection = useCallback(() => {
    setTermsheetsState({
      tableState: {
        rowSelectionModel: [],
      },
    });
  }, [setTermsheetsState]);

  const openDeleteModal = useCallback(() => {
    setDeleteModalOpen(true);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setDeleteModalOpen(false);
  }, []);

  useDynamicSidebarFiltersListener(resetCurrentPage);
  useTermsheetsSearchFiltersListener(resetCurrentPage);

  const columns = useTermsheetsDataGridColumns(config);

  if (configLoading || userLoading) return <Loader />;

  const isPublic = config?.isPublic;
  const isAuthenticated = userInfo?.authenticated;
  const isUnauthorizedToAccessConfig = configError?.status === 403;
  const isUnauthenticatedAndPageIsPrivate = isPublic === false && isAuthenticated === false;
  const shouldRedirectToLogin = isUnauthorizedToAccessConfig || isUnauthenticatedAndPageIsPrivate;

  if (shouldRedirectToLogin) {
    const next = encodeURIComponent(window?.location.pathname || '');
    const url = `/react/app/onboarding/login?next=${next}`;
    navigateParentTo(url);

    return <Loader />;
  }

  const rows = termsheetDocs?.results ?? [];
  const rowCount = termsheetDocs?.pagination?.totalResults ?? 0;
  const filters = columns.map((column) => column.filter).filter(Boolean) as Array<DynamicTypeFiltersSideBarFiltersEnum>;

  const isLoading = docsLoading || isPlaceholderData;
  const showMatures = columns.some((column) => column.id === 'termsheet_repo_docs.maturity_date');
  const showIssued = columns.some((column) => column.id === 'termsheet_repo_docs.issue_date');

  const extraProps = isAdmin ? { rowSelectionModel, onRowSelectionModelChange } : {};
  const actions = isAdmin ? <CreateTermsheetModal /> : null;

  const slots = {
    searchFilters: (
      <SearchFilters
        state={searchFilters}
        onChange={handleSearchFiltersChange}
        showMatures={showMatures}
        showIssued={showIssued}
        actions={actions}
      />
    ),
  };

  const localeText = {
    footerRowSelected: (count: number) => {
      if (count < 1) return null;

      return (
        <>
          {GRID_DEFAULT_LOCALE_TEXT.footerRowSelected(count)}
          <LocalizedButton variant="outlined" color="error" size="small" sx={deleteButtonSx} onClick={openDeleteModal}>
            {translations.common.delete}
          </LocalizedButton>
          <DeleteTermsheetModal
            ids={rowSelectionModel as number[]}
            open={deleteModalOpen}
            onClose={closeDeleteModal}
            onSuccess={resetSelection}
          />
        </>
      );
    },
  };

  return (
    <DynamicFiltersLayout tag={TERMSHEET_PREFERENCE_TAG} filters={filters} filterValues={filterValues} slots={slots}>
      <HaloDataGrid
        columns={columns}
        rows={rows}
        loading={isLoading}
        pagination
        paginationMode="server"
        sortingMode="server"
        density="compact"
        paginationModel={paginationModel}
        sortModel={sortModel}
        onPaginationModelChange={handlePaginationChange}
        onSortModelChange={handleSortChange}
        rowCount={rowCount}
        rowSelection={isAdmin}
        checkboxSelection={isAdmin}
        disableRowSelectionOnClick
        localeText={localeText}
        {...extraProps}
      />
    </DynamicFiltersLayout>
  );
};
