import { HTMLAttributes, ReactElement } from 'react';

import { usePrimaryAssetIdentifier } from '@halo-common/hooks';
import { AssetPositionModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';

interface PortfolioAssetSearchTypeAheadOptionProps extends HTMLAttributes<HTMLLIElement> {
  option: AssetPositionModel;
}

export const PortfolioAssetSearchTypeAheadOption = (props: PortfolioAssetSearchTypeAheadOptionProps): ReactElement => {
  const { option } = props;
  const { assetIds } = option;

  const { identifierType } = usePrimaryAssetIdentifier();

  const foundAssetId = assetIds.find((assetId) => assetId.type === identifierType)?.value;
  const defaultAssetId = assetIds[0]?.value;

  const label = foundAssetId ?? defaultAssetId ?? translations.portfolio.common.assetSearchNoMatchMessage;

  return (
    <Stack direction="column">
      <LocalizedTypography variant="overline" color="text.secondary">
        {label}
      </LocalizedTypography>
      <LocalizedTypography variant="subtitle2" color="text.primary">
        {option.shortName}
      </LocalizedTypography>
    </Stack>
  );
};
