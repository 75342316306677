import { HaloAppState } from '@halo-stores/types';
import { createSelector } from '@reduxjs/toolkit';

export const selectState = createSelector(
  ({ Onboarding }: HaloAppState) => Onboarding,
  (state) => state,
);

export const selectUserIdentification = createSelector(
  ({ Onboarding }: HaloAppState) => Onboarding,
  ({ userEmail, userName }) => ({ userEmail, userName }),
);

export const selectPasswordSet = createSelector(
  ({ Onboarding }: HaloAppState) => Onboarding.passwordSet,
  (passwordSet) => passwordSet,
);

export const selectEmailSent = createSelector(
  ({ Onboarding }: HaloAppState) => Onboarding.emailSent,
  (cusipsLinked) => cusipsLinked,
);

// TODO: Update return value type to be an enum
export const selectStatus = createSelector(
  ({ Onboarding }: HaloAppState) => Onboarding.status,
  (status) => status,
);

export const selectError = createSelector(
  ({ Onboarding }: HaloAppState) => Onboarding.error,
  (error) => error,
);

export const selectDisclaimers = createSelector(
  ({ Onboarding }: HaloAppState) => Onboarding.disclaimers,
  (disclaimers) => disclaimers,
);

export const selectAcceptedDisclaimers = createSelector(
  ({ Onboarding }: HaloAppState) => Onboarding.acceptedDisclaimers,
  (acceptedDisclaimers) => acceptedDisclaimers,
);
