import { QueryKeyFactoryFn } from '@halo-data-sources/queries';
import { QueryKey } from '@tanstack/react-query';

type WatchlistQueryKeys = {
  all: QueryKeyFactoryFn;
  watchlist: QueryKeyFactoryFn;
  product: QueryKeyFactoryFn;
  products: QueryKeyFactoryFn;
  targets: QueryKeyFactoryFn;
  target: QueryKeyFactoryFn;
  targetHistory: QueryKeyFactoryFn;
};

export const WatchlistQueryKeyFactory: WatchlistQueryKeys = {
  all: (): QueryKey => ['watchlist'],
  watchlist: (id?: number): QueryKey => [...WatchlistQueryKeyFactory.all(), id],
  product: (id?: number, type?: string): QueryKey => [...WatchlistQueryKeyFactory.all(), 'product', id, 'type', type],
  products: (id?: number): QueryKey => [...WatchlistQueryKeyFactory.watchlist(id), 'products'].filter(Boolean),
  targets: (): QueryKey => [...WatchlistQueryKeyFactory.all(), 'targets'],
  target: (id?: number): QueryKey => [...WatchlistQueryKeyFactory.targets(), id],
  targetHistory: (id?: number): QueryKey => [...WatchlistQueryKeyFactory.targets(), id, 'history'],
};
