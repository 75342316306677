import { ReactElement } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { selectedWatchlistNoteProductAtom } from '@halo-atoms/watchlists';
import { ProductCard, ProductCardProps } from '@halo-common/components';
import { ProductActionTypeEnum, ProductTypeEnum } from '@halo-common/enums';
import { AnnuityModel, NoteModel } from '@halo-common/models';
import { navigateParentTo } from '@halo-common/utils';
import { Box } from '@mui/material';
import { useSetAtom } from 'jotai';

const cardContainerSx = { p: 1 };

export type WatchlistProductCardProps = {
  product: ProductCardProps['product'];
  removable?: boolean;
  type: ProductTypeEnum;
};

export const WatchlistProductCard = ({
  product,
  type,
  removable = false,
}: WatchlistProductCardProps): ReactElement | null => {
  const setModalMap = useSetAtom(modalAtom);
  const setSelectedWatchlistNoteProductAtom = useSetAtom(selectedWatchlistNoteProductAtom);

  const commonProps = {
    action: removable ? ProductActionTypeEnum.delete : ProductActionTypeEnum.watch,
    isLoading: false,
  };

  const productCardType = type ?? ProductTypeEnum.note;

  const handleNavigate = () => {
    const url = '/annuity/shelf';
    navigateParentTo(url);
  };

  const card = {
    [ProductTypeEnum.note]: (
      <ProductCard
        {...commonProps}
        type={ProductTypeEnum.note}
        product={product as NoteModel}
        onClick={(_id, _type, product) => {
          const parsedProduct = product as NoteModel;
          setModalMap({ pdm: true });
          setSelectedWatchlistNoteProductAtom(parsedProduct);
        }}
      />
    ),
    [ProductTypeEnum.auction]: null,
    [ProductTypeEnum.annuity]: (
      <ProductCard
        {...commonProps}
        type={ProductTypeEnum.annuity}
        product={product as AnnuityModel}
        onClick={handleNavigate}
      />
    ),
  }[productCardType];

  return <Box sx={cardContainerSx}>{card}</Box>;
};
