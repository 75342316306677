import { CalendarProductQueryModel } from '@halo-common/models';
import { getCalendarQueryProducts } from '@halo-data-sources/clients';
import { ApiCalendarsMapper } from '@halo-data-sources/mappers';
import { ApiGetCalendarDetailsRequestModel } from '@halo-data-sources/models';
import { CalendarsQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export type GetCalendarProductQueryFnPayload = {
  endDate: string;
  startDate: string;
  approved?: boolean | null;
  showAsNME?: boolean;
};

const getCalendarProductFn = async ({
  approved = true,
  startDate,
  endDate,
  showAsNME,
}: GetCalendarProductQueryFnPayload) => {
  const payload: ApiGetCalendarDetailsRequestModel = {
    end_date: endDate,
    start_date: startDate,
    filters: {},
  };

  if (approved !== undefined) payload.filters.approved = approved;
  if (showAsNME !== undefined) payload.filters.show_as_nme = showAsNME;

  const response = await getCalendarQueryProducts(payload);

  return response.products.map(ApiCalendarsMapper.toCalendarQueryProductModel);
};

export const useCalendarProductQuery = (
  payload: GetCalendarProductQueryFnPayload,
): UseQueryResult<Array<CalendarProductQueryModel>, Error> =>
  useQuery<Array<CalendarProductQueryModel>, Error>({
    queryKey: CalendarsQueryKeyFactory.product(payload),
    queryFn: () => getCalendarProductFn(payload),
  });
