import { ReactElement } from 'react';

import { ModuleStepModel } from '@halo-common/models';
import { useCourseManager } from '@halo-modules/app/education/hooks';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { Box, Step, StepProps, StepLabel } from '@mui/material';

export type EducationModuleStepProps = Partial<StepProps> & {
  step: ModuleStepModel;
  onSelection: (step: ModuleStepModel) => void;
  showCurrent?: boolean;
};

export const EducationModuleStep = ({
  step,
  onSelection,
  showCurrent = false,
  ...props
}: EducationModuleStepProps): ReactElement => {
  const { progress } = useCourseManager();

  const selectedStep = progress.step;

  const completed = step?.completed;
  const type = step?.type;
  const title = step?.title;
  const id = step?.id;

  const isQuiz = type === 'quiz';
  const isCurrentStep = id === selectedStep?.id && type === selectedStep?.type;
  const showStepIndicator = showCurrent && isCurrentStep;

  const stepBackgroundColor = completed ? 'primary.background' : 'primary.contrastText';
  const stepIcon = isQuiz ? 'pencil' : 'book-open';
  const icon = completed ? 'check' : stepIcon;

  const currentStepIcon = showStepIndicator ? 'arrow-right' : icon;
  const currentStepIconColor = showStepIndicator ? 'primary.contrastText' : 'primary.dark';
  const currentStepBackgroundColor = showStepIndicator ? 'primary.dark' : stepBackgroundColor;

  const moduleStepIconContainerSx = {
    alignItems: 'center',
    backgroundColor: currentStepBackgroundColor,
    border: '1px solid',
    borderColor: 'primary.dark',
    borderRadius: '50%',
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    width: 40,
    zIndex: 1,
  };

  const stepLabelIcon = () => (
    <Box sx={moduleStepIconContainerSx}>
      <Iconography color={currentStepIconColor} prefix="fal" iconName={currentStepIcon} size="1.25x" />
    </Box>
  );

  const handleNavigation = () => void onSelection(step);

  return (
    <Step {...props}>
      <StepLabel StepIconComponent={stepLabelIcon}>
        <LocalizedButton variant="text" color="primary" onClick={handleNavigation}>
          {title}
        </LocalizedButton>
      </StepLabel>
    </Step>
  );
};
