import { ReactElement, ReactNode } from 'react';

import { ProductCardHeader } from '@halo-common/components';
import { ProductTypeEnum } from '@halo-common/enums';
import { AnnuityModel } from '@halo-common/models';
import { navigateParentTo } from '@halo-common/utils';
import { Stack } from '@halodomination/halo-fe-common';
import { Button, Typography } from '@mui/material';

const titleSx = { maxWidth: 224, textAlign: 'center' };
const containerLayoutSx = { height: '100%', minHeight: 280 };
const buttonSx = { my: 1 };

export type ProductCardAnnuityDetailsProps = {
  action: ReactNode;
  annuity: AnnuityModel;
};

export const ProductCardAnnuityDetails = ({ action, annuity }: ProductCardAnnuityDetailsProps): ReactElement => {
  const handleNavigate = () => void navigateParentTo('/annuity_creator');

  return (
    <Stack sx={containerLayoutSx} direction="column" justify="space-between">
      <ProductCardHeader action={action} type={ProductTypeEnum.annuity} />
      <Stack direction="column" alignItems="center">
        <Typography sx={titleSx}>{annuity.product.name}</Typography>
        <Typography sx={titleSx} variant="subtitle1">
          {annuity.product.description}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justify="center">
        <Button className="wm-annuity-customize-button" sx={buttonSx} onClick={handleNavigate}>
          Customize
        </Button>
      </Stack>
      <></>
    </Stack>
  );
};
