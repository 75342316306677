import { createContext, Dispatch, SetStateAction } from 'react';

import { DateTime } from 'luxon';

export const TackOnContext = createContext<{
  startShowingDate?: DateTime;
  setStartShowingDate?: Dispatch<SetStateAction<DateTime>>;
  startShowingTime?: DateTime;
  setStartShowingTime?: Dispatch<SetStateAction<DateTime>>;
  expirationDate?: DateTime;
  setExpirationDate?: Dispatch<SetStateAction<DateTime>>;
  expirationTime?: DateTime;
  setExpirationTime?: Dispatch<SetStateAction<DateTime>>;
}>({});
