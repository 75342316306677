import { createContext, ReactElement, useEffect } from 'react';

import { AuctionStatusEnum } from '@halo-common/enums';
import {
  AuctionAlertSnackbar,
  AuctionNoteDetailsModal,
  CanceledTable,
  ChooseWinnerTable,
  InAuctionTable,
  IssuerCommentModal,
  NoWinnerTable,
  OpsApprovalTable,
  PreApprovalTable,
  PurchasedTable,
  TermsAcceptedTable,
  TermsPendingTable,
  TermsReviewTable,
  useOrderHubFilters,
} from '@halo-modules/admin';
import { OrdersActions, OrdersSelectors, OrderStatusEnum } from '@halo-stores/Orders';
import { ElementSelector, Tabs } from '@halodomination/halo-fe-common';
import { useDispatch, useSelector } from 'react-redux';

const tabsSx = { marginBottom: 5 };

export type OrderHubAuctionContextModel = {
  height?: number;
  hideFooter?: boolean;
  autoPageSize?: boolean;
  limit?: number;
  handleCloseModal?: () => void;
};

export const USER_INFO_KEY = 'userInfo';
export const OH_PINNED_COLUMNS_KEY = 'orderHubPinnedColumns';
export const OH_ORDERED_COLUMNS_KEY = 'orderHubOrderedColumns';

export const OrderHubAuctionContext = createContext<OrderHubAuctionContextModel>({});

export const OrderHubAuctionPage = (): ReactElement => {
  const dispatch = useDispatch();

  const auctions = useSelector(OrdersSelectors.selectAuctions);
  const orderStatus = useSelector(OrdersSelectors.selectStatus);
  const totalAuctions = useSelector(OrdersSelectors.selectTotalAuctions);

  const tabs = [
    { label: 'All', count: totalAuctions },
    {
      label: 'Pre-Approval',
      count: auctions[AuctionStatusEnum.PreApproval]?.length ?? 0,
    },
    {
      label: 'Ops-Approval',
      count: auctions[AuctionStatusEnum.OpsApproval]?.length ?? 0,
    },
    {
      label: 'In Auction',
      count: auctions[AuctionStatusEnum.InAuction]?.length ?? 0,
    },
    {
      label: 'Choose Winner',
      count: auctions[AuctionStatusEnum.ChooseWinner]?.length ?? 0,
    },
    {
      label: 'Waiting for Terms',
      count: auctions[AuctionStatusEnum.TermsPending]?.length ?? 0,
    },
    {
      label: 'Review Terms',
      count: auctions[AuctionStatusEnum.TermsReviewed]?.length ?? 0,
    },
    {
      label: 'Terms Accepted',
      count: auctions[AuctionStatusEnum.TermsAccepted]?.length ?? 0,
    },
    {
      label: 'Purchased',
      count: auctions[AuctionStatusEnum.Purchased]?.length ?? 0,
    },
    {
      label: 'No Winner',
      count: auctions[AuctionStatusEnum.NotWin]?.length ?? 0,
    },
    {
      label: 'Canceled',
      count: auctions[AuctionStatusEnum.Canceled]?.length ?? 0,
    },
  ];

  const { selectedTab, handleFilterChange, showAll, status } = useOrderHubFilters(tabs);

  const showNoteDetailsModal = orderStatus === OrderStatusEnum.showDetails;
  const showIssuerCommentModal = orderStatus === OrderStatusEnum.showIssuerComment;

  const handleCloseModal = () => {
    dispatch(OrdersActions.resetSelected());
  };

  const context = {
    height: showAll ? 400 : 800,
    hideFooter: showAll,
    autoResize: showAll,
    limit: showAll ? 5 : undefined,
    handleCloseModal,
  };

  useEffect(() => {
    dispatch(OrdersActions.getOrderAuctions());
  }, [status]);

  return (
    <OrderHubAuctionContext.Provider value={context}>
      <Tabs variant="scrollablePills" onChange={handleFilterChange} tabs={tabs} slotProps={{ tabs: { sx: tabsSx } }} />
      <ElementSelector selected={selectedTab} showAll={showAll}>
        <PreApprovalTable />
        <OpsApprovalTable />
        <InAuctionTable />
        <ChooseWinnerTable />
        <TermsPendingTable />
        <TermsReviewTable />
        <TermsAcceptedTable />
        <PurchasedTable />
        <NoWinnerTable />
        <CanceledTable />
      </ElementSelector>
      <AuctionNoteDetailsModal open={showNoteDetailsModal} onClose={handleCloseModal} />
      <IssuerCommentModal open={showIssuerCommentModal} />
      <AuctionAlertSnackbar />
    </OrderHubAuctionContext.Provider>
  );
};
