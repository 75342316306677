export enum PortfolioPeriodEnum {
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Annually = 'annually',
}

export enum PortfolioCashflowEventEnum {
  Expiration = 'EXPIRATION',
  FixedCoupon = 'FIXED_COUPON',
  ConditionalCoupon = 'CONDITIONAL_COUPON',
  MemoryCoupon = 'MEMORY_COUPON',
  Autocall = 'AUTOCALL_OBS',
}

export enum PortfolioTabEnum {
  POSITION_TAB = 'positions',
  LIFECYCLE_TAB = 'lifecycle',
  ACCOUNTS_TAB = 'accounts',
}

export enum PortfolioPositionsTableSearchNameEnum {
  jhnCode = 'JHN Code',
  cusip = 'CUSIP',
  isin = 'ISIN',
  issuerName = 'Issuer',
  shortName = 'Short Name',
  underlyings = 'Underlyings',
  breached = 'Breached',
  couponType = 'Coupon Type',
  protectionType = 'ProtectionType',
  principalProtectionType = 'Principal Protection Type',
  payoff = 'Payoff',
  notetypecell = 'notetypecell',
}

export enum PortfolioEventSortDropdownValueEnum {
  ASC_EVENT_DATE = 'asc_event_date',
  DESC_EVENT_DATE = 'desc_event_date',
  DESC_TOTAL_NOTIONAL = 'desc_total_notional',
  ASC_TOTAL_NOTIONAL = 'asc_total_notional',
}
