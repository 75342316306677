import { ReactElement, ReactNode } from 'react';

import { PortfolioPositionsCard, PortfolioPositionsTooltip } from '@halo-modules/app';
import { PieChart, PieChartLegend, PieChartProps } from '@halodomination/halo-fe-common';

export interface PortfolioPositionsPieChartCardProps {
  title: ReactNode;
  data: PieChartProps['data'];
  nameKey?: PieChartProps['nameKey'];
  dataKey?: PieChartProps['dataKey'];
  tooltipContent?: PieChartProps['tooltipContent'];
}

export const PortfolioPositionsPieChartCard = ({
  title,
  data,
  nameKey,
  dataKey,
  tooltipContent = PortfolioPositionsTooltip,
}: PortfolioPositionsPieChartCardProps): ReactElement | null => {
  if (!Array.isArray(data) || data.length === 0) return null;
  const chartProps = {
    data,
    nameKey,
    dataKey,
  };
  return (
    <PortfolioPositionsCard
      title={title}
      leftContent={<PieChartLegend {...chartProps} />}
      rightContent={<PieChart {...chartProps} width={148} height={148} tooltipContent={tooltipContent} />}
    />
  );
};
