import { ReactElement } from 'react';

import { executionHubCancelModalAtom, executionHubOrderTicketModalAtom } from '@halo-atoms/executionHub';
import { CalendarModel } from '@halo-common/models';
import { useWebSocketContext } from '@halo-common/providers';
import { ExecutionHubOrder } from '@halo-data-sources/queries';
import { Iconography, Tooltip, TooltipProps } from '@halodomination/halo-fe-common';
import { CircularProgress, IconButton, Stack } from '@mui/material';
import { useSetAtom } from 'jotai';

const tooltipProps: Partial<TooltipProps> = {
  placement: 'right',
  variant: 'haloBlue',
};

export type V2ExecutionHubStatusIndicatorProps = {
  calendar?: CalendarModel;
  order?: ExecutionHubOrder;
  hideAction?: boolean;
};

export const V2ExecutionHubStatusIndicator = ({
  calendar,
  order,
  hideAction = false,
}: V2ExecutionHubStatusIndicatorProps): ReactElement => {
  const setModalState = useSetAtom(executionHubCancelModalAtom);
  const setExecutionHubOrderTicketModalAtom = useSetAtom(executionHubOrderTicketModalAtom);

  const { events } = useWebSocketContext();

  const loading = events.execOrder.pending(order?.executionOrder.id);
  const status = order?.executionOrder.status ?? 'failure';
  const comment = order?.executionOrder.comment;

  if (loading) {
    return (
      <Tooltip {...tooltipProps} title="Waiting for counter party response">
        <CircularProgress color="primary" size={16} />
      </Tooltip>
    );
  }

  const isActiveStatus = status === 'pending' || status === 'warning';
  const showActionButton = !hideAction && isActiveStatus;

  const fillMessage = comment ?? 'Filled.';
  const errorMessage = comment ?? 'Filling this order is taking longer than expected. Please check back later.';

  const tooltipMessage =
    {
      canceled: 'Canceled',
      filled: fillMessage,
      pending: 'Pending Fill',
    }[status] ?? errorMessage;

  const handleCancel = () => {
    setModalState({ open: true, order, calendar });
  };

  const handleView = () => {
    if (calendar) {
      setExecutionHubOrderTicketModalAtom({ open: true, calendar, order });
    }
  };

  const icon = {
    canceled: <Iconography iconName="times-circle" color="grey.500" />,
    filled: <Iconography iconName="check-circle" color="success.main" />,
    pending: <Iconography iconName="clock" color="grey.300" />,
  }[status] ?? <Iconography iconName="triangle-exclamation" color="warning.main" />;

  const actionButton = (
    <IconButton sx={{ display: showActionButton ? 'inherit' : 'none' }} onClick={handleCancel}>
      <Iconography iconName="trash-can" color="primary.main" />
    </IconButton>
  );

  const viewButton = (
    <IconButton onClick={handleView}>
      <Iconography iconName="eye" color="primary.main" />
    </IconButton>
  );

  return (
    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
      <Tooltip {...tooltipProps} title={tooltipMessage}>
        {icon}
      </Tooltip>
      {viewButton}
      {actionButton}
    </Stack>
  );
};
