import { ReactElement } from 'react';

import { executionHubCancelModalAtom, executionHubQueryAtom } from '@halo-atoms/executionHub';
import { ActionButton } from '@halo-common/components';
import { useCancelExecCalendarAdminOrderMutation } from '@halo-data-sources/mutations';
import { CalendarOrderInfiniteQueryResult, OrdersClientQueryKeyFactory } from '@halo-data-sources/queries';
import {
  HaloDataGrid,
  HaloDataGridProps,
  Iconography,
  Modal,
  Stack,
  useSnackbar,
} from '@halodomination/halo-fe-common';
import { Button, TextField } from '@mui/material';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';
import { useForm } from 'react-hook-form';

export const V2ExecutionHubCancelModal = (): ReactElement => {
  const queryClient = useQueryClient();

  const { enqueueSuccessEvent } = useSnackbar();

  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      reason: '',
    },
  });

  const [modalState, setModalState] = useAtom(executionHubCancelModalAtom);
  const executionHubQuery = useAtomValue(executionHubQueryAtom);

  const overline = `Order #${modalState.order?.executionOrder.id}`;

  const { ref: reasonRef, ...reasonProps } = register('reason');
  const rows = [modalState.order];

  const columns: HaloDataGridProps['columns'] = [
    {
      flex: 1,
      field: 'accountNumber',
      headerName: 'account number',
      valueGetter: (_, row) => row.executionOrder.accountId,
    },
    {
      flex: 1,
      field: 'accountDesignation',
      headerName: 'account type',
      translateCell: true,
      valueGetter: (_, row) => row.allocation?.account.designation,
    },
    {
      flex: 1,
      field: 'reoffer',
      headerName: 'issuer reoffer',
      align: 'right',
      valueGetter: (_, row) => row.executionOrder.price,
    },
    {
      flex: 1,
      field: 'quantity',
      headerName: 'quantity',
      align: 'right',
      valueGetter: (_, row) => row.executionOrder.quantity,
    },
  ];

  const handleClose = () => {
    setModalState({ open: false });
    reset();
  };

  const { mutate, isPending } = useCancelExecCalendarAdminOrderMutation({
    onSuccess: (data) => {
      if (!data) return undefined;

      const execOrderId = data.execOrder.id;
      const updatedExecOrderStatus = data.execOrder.status;

      const key = OrdersClientQueryKeyFactory.calendarOrders(executionHubQuery);
      queryClient.setQueryData<InfiniteData<CalendarOrderInfiniteQueryResult>>(key, (prev) => {
        if (!prev?.pages) return prev;

        const updatedPages = [...prev.pages];
        const pageIndex = updatedPages.findIndex(({ orders }) =>
          orders.some(({ orders }) => orders.some((order) => order.executionOrder.id === execOrderId)),
        );

        if (pageIndex < 0) return prev;

        const updatedPage = { ...updatedPages[pageIndex] };

        const updatedOrderPairings = [...updatedPage.orders];
        const orderPairingIndex = updatedOrderPairings.findIndex(({ orders }) =>
          orders.some((order) => order.executionOrder.id === execOrderId),
        );

        const updatedOrders = [...updatedOrderPairings[orderPairingIndex].orders];
        const orderIndex = updatedOrders.findIndex((order) => order.executionOrder.id === execOrderId);

        const updatedOrder = { ...updatedOrders[orderIndex] };
        const updatedExecOrder = { ...updatedOrder.executionOrder };
        updatedExecOrder.status = updatedExecOrderStatus;

        updatedOrders.splice(orderIndex, 1, {
          ...updatedOrder,
          executionOrder: updatedExecOrder,
        });

        updatedOrderPairings.splice(orderPairingIndex, 1, {
          ...updatedOrderPairings[orderPairingIndex],
          orders: updatedOrders,
        });

        updatedPages.splice(pageIndex, 1, {
          ...updatedPages[pageIndex],
          orders: updatedOrderPairings,
        });

        return { ...prev, pages: updatedPages };
      });

      enqueueSuccessEvent({ message: 'Canceled Order', subMessage: `Order #${execOrderId}` });

      handleClose();
    },
  });

  const handleCancel = handleSubmit((data) => {
    mutate({ allocationId: modalState.order.allocation.id, reason: data.reason });
  });

  return (
    <Modal
      title="Cancel Order"
      open={modalState.open}
      overline={overline}
      size="small"
      onClose={handleClose}
      icon="trash-can"
      footer={
        <Stack direction="row" justify="space-between" alignItems="center">
          <Button
            variant="text"
            color="primary"
            startIcon={<Iconography iconName="arrow-left" />}
            onClick={handleClose}
          >
            Go Back
          </Button>
          <ActionButton color="primary" onClick={handleCancel} loading={isPending}>
            Confirm Cancelation
          </ActionButton>
        </Stack>
      }
    >
      <Stack direction="column" spacing={2}>
        <HaloDataGrid columns={columns} rows={rows} density="compact" />
        <TextField inputRef={reasonRef} label="Comment" multiline rows={3} fullWidth {...reasonProps} />
      </Stack>
    </Modal>
  );
};
