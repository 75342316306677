import { ReactElement } from 'react';

export type MainEllipseProps = {
  color: string;
  width?: number;
  height?: number;
};

export const MainEllipse = ({ color, height = 407, width = 374 }: MainEllipseProps): ReactElement => (
  <svg width={width} height={height} viewBox="0 0 374 407" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="186.912" cy="203.425" rx="177.384" ry="211.017" transform="rotate(-30 186.912 203.425)" fill={color} />
  </svg>
);
