import {
  dynamicAssetSearchOptionAtom,
  dynamicFiltersAssetSearchAtom,
  dynamicFiltersHeaderManagerAtom,
  dynamicSideBarManagerAtom,
  initialHeaderFilters,
  initialSideBarFilters,
  underlyingInputSelectionAtom,
} from '@halo-atoms/common';
import { atom } from 'jotai';

export const dynamicFiltersClearAtom = atom(
  null,
  (get, set, payload: { tag: string; action: 'sidebar' | 'header' | 'asset' | 'all' }) => {
    const { action, tag } = payload;

    const clearAll = action === 'all';
    const clearHeader = clearAll || action === 'header';
    const clearSideBar = clearAll || action === 'sidebar';
    const clearAssetSearch = clearAll || clearHeader || action === 'asset';

    const sideBarDefaults = { ...initialSideBarFilters, sortBy: undefined };

    const assetSearchAtomMap = get(dynamicFiltersAssetSearchAtom);
    const assetSearchAtoms = assetSearchAtomMap(tag);

    set(underlyingInputSelectionAtom, []);

    if (clearSideBar) set(dynamicSideBarManagerAtom, { tag, ...sideBarDefaults });

    if (clearHeader) set(dynamicFiltersHeaderManagerAtom, { tag, ...initialHeaderFilters });

    if (clearAssetSearch) {
      set(assetSearchAtoms.debouncedValueAtom, '');
      set(dynamicAssetSearchOptionAtom, { tag, value: null });
    }
  },
);
