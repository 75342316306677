import { ReactElement } from 'react';

import { useUserWebHooksQuery } from '@halo-data-sources/queries';

export type ContentHiderProps = {
  hook: string;
  showByDefault?: boolean;
  children: ReactElement;
};

export const ContentHider = ({ hook, showByDefault = true, children }: ContentHiderProps): ReactElement | null => {
  const { data: webhooks } = useUserWebHooksQuery();

  const webHook = webhooks?.[hook];
  const contentEnabled = webHook?.reactFeatureEnabled;

  const webHookDoesNotExist = webHook === undefined || webHook === null;
  const visibleByDefault = webHookDoesNotExist && showByDefault;

  const showContent = contentEnabled || visibleByDefault;

  if (showContent) return children;
  else return null;
};
