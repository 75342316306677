import { ReactElement } from 'react';

import { productDetailModalAnalyticsTabStateAtom } from '@halo-atoms/pdm';
import { quoteValueMapAtom } from '@halo-atoms/quotes';
import { NoteTypeEnum } from '@halo-common/enums';
import { useBreakpointView, useQuoteSelector } from '@halo-common/hooks';
import { ProductDetailModalAnalyticsTab } from '@halo-common/layouts';
import {
  PreTradeNoteDetailModalCouponScheduleTable,
  PreTradeNoteDetailModalDocumentTable,
  PreTradeNoteDetailModalEarlyRedemptionTable,
  PreTradeNoteDetailModalHeader,
  PreTradeNoteDetailModalNotePayoffChart,
  PreTradeNoteDetailModalPanel,
  PreTradeNoteDetailModalQuotesTab,
  ProductDetailModal,
} from '@halo-common/modals';
import { NoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import {
  useRollingReturnsChartQuery,
  useRollingReturnsStatisticsQuery,
  useUserInfoQuery,
} from '@halo-data-sources/queries';
import { useAtom, useAtomValue } from 'jotai';

const ANALYTICS_TAB = translations.common.analytics;
const DETAILS_TAB = translations.common.details;
const PAYOFF_TAB = translations.pdm.preTrade.analyticsTabPayoffChart;
const QUOTES_TAB = translations.common.quotes;
const COUPON_SCHEDULE_TAB = translations.common.couponSchedule;
const EARLY_REDEMPTION_TAB = translations.common.earlyRedemption;
const DOCUMENTS_TAB = translations.common.documents;

export type PreTradeNoteDetailModalProps = {
  open: boolean;
  onClose: () => void;
  loading?: boolean;
  product?: NoteModel | null;
};

export const PreTradeNoteDetailModal = ({
  onClose,
  open,
  loading = false,
  product,
}: PreTradeNoteDetailModalProps): ReactElement => {
  const state = useAtomValue(productDetailModalAnalyticsTabStateAtom);
  const [quoteValueMap, setQuoteValueMap] = useAtom(quoteValueMapAtom);

  const isTabletView = useBreakpointView('md', 'up');

  const { data: user, isPending: isUserInfoLoading } = useUserInfoQuery();

  const productId = product?.id;
  const isIssuerCall = product?.callInformation?.isIssuerCall;
  const isLoading = isUserInfoLoading || loading;

  const quote = useQuoteSelector(productId);

  const isIncomeNote = product?.type === NoteTypeEnum.Income;

  const hasQuote = Boolean(quote);
  const hasIncomeData = isIncomeNote && hasQuote;
  const showCouponScheduleTab = isIncomeNote && !product?.coupon?.isRecall;
  const showAnalyticsTab = user?.settings?.showPreTradeRRTab;
  const showAgainstNote = hasQuote && !isIssuerCall;
  const showDetailsTab = !isTabletView;

  const hasUpdatedQuote = Boolean(productId && quoteValueMap[productId]?.updated);

  const { endDate, startDate } = state;
  const filters = { endDate, startDate, basket: product?.tradableWeights, showAgainstNote };
  const areAnalyticQueriesEnabled = { enabled: Boolean(showAnalyticsTab && productId) };

  const { refetch: handleChartRefetch } = useRollingReturnsChartQuery(product, areAnalyticQueriesEnabled);
  const { refetch: handleTableRefetch } = useRollingReturnsStatisticsQuery(product, filters, areAnalyticQueriesEnabled);

  const handleRollingReturnsDataRefresh = () => {
    setQuoteValueMap((prev) => {
      const parsedProductId = productId as number;
      return { ...prev, [parsedProductId]: { ...prev[parsedProductId], updated: false } };
    });

    if (hasUpdatedQuote) {
      void handleChartRefetch();
      void handleTableRefetch();
    }
  };

  const handleClose = () => {
    handleRollingReturnsDataRefresh();
    onClose();
  };

  const tabs = [];
  if (showAnalyticsTab) tabs.push(ANALYTICS_TAB);
  if (showDetailsTab) tabs.push(DETAILS_TAB);
  tabs.push(QUOTES_TAB);
  if (hasIncomeData) tabs.push(PAYOFF_TAB);
  if (showCouponScheduleTab) tabs.push(COUPON_SCHEDULE_TAB);
  if (isIncomeNote) tabs.push(EARLY_REDEMPTION_TAB);
  tabs.push(DOCUMENTS_TAB);

  const analyticsTab = showAnalyticsTab ? (
    <ProductDetailModalAnalyticsTab product={product} loading={isLoading} onRefresh={handleRollingReturnsDataRefresh} />
  ) : null;

  const panelContent = <PreTradeNoteDetailModalPanel product={product} loading={isLoading} />;
  const detailsTab = showDetailsTab ? panelContent : null;

  const payoffTab = hasIncomeData ? (
    <PreTradeNoteDetailModalNotePayoffChart product={product} loading={isLoading} />
  ) : null;

  const couponScheduleTab = showCouponScheduleTab ? (
    <PreTradeNoteDetailModalCouponScheduleTable product={product} loading={isLoading} />
  ) : null;

  const earlyRedemptionTab = isIncomeNote ? (
    <PreTradeNoteDetailModalEarlyRedemptionTable product={product} loading={isLoading} />
  ) : null;

  const slots = {
    header: <PreTradeNoteDetailModalHeader product={product} onClose={handleClose} loading={isLoading} />,
    panel: panelContent,
  };

  return (
    <ProductDetailModal
      open={open}
      onClose={handleClose}
      tabs={tabs}
      slots={slots}
      TabProps={{ onChange: handleRollingReturnsDataRefresh }}
      className="wm-pdm-pretrade"
    >
      {analyticsTab}
      {detailsTab}
      <PreTradeNoteDetailModalQuotesTab
        product={product}
        loading={isLoading}
        onRefresh={handleRollingReturnsDataRefresh}
      />
      {payoffTab}
      {couponScheduleTab}
      {earlyRedemptionTab}
      <PreTradeNoteDetailModalDocumentTable product={product} loading={isLoading} />
    </ProductDetailModal>
  );
};
