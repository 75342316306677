import { ReactElement } from 'react';

import { ConfirmationEnum } from '@halo-common/enums';
import { OrderTicketFormModelFields } from '@halo-common/modals';
import { MenuItem, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

type SolicitedDropdownProps = {
  bulk?: boolean;
};

export const SolicitedDropdown = ({ bulk }: SolicitedDropdownProps): ReactElement => {
  const { control, formState } = useFormContext<OrderTicketFormModelFields>();
  const { errors } = formState;

  const solicitedError = errors?.solicited?.message;
  const label = bulk ? 'Solicited?' : 'Solicited';

  return (
    <Controller
      render={({ field: { ref, ...field } }) => (
        <TextField
          {...field}
          inputRef={ref}
          fullWidth
          size="large"
          select
          label={label}
          helperText={solicitedError}
          error={Boolean(solicitedError)}
        >
          <MenuItem value={ConfirmationEnum.Yes}>Yes</MenuItem>
          <MenuItem value={ConfirmationEnum.No}>No</MenuItem>
        </TextField>
      )}
      control={control}
      name="solicited"
    />
  );
};
