import { ReactElement } from 'react';

import { DocumentBucketModel } from '@halo-common/models';
import { downloadFile } from '@halo-common/utils';
import { generateDocumentUrl } from '@halo-data-sources/clients';
import { Iconography, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Box, SxProps } from '@mui/material';

const stackSx: SxProps = {
  background: 'rgba(227, 226, 239, 0.5)',
  borderRadius: '4px',
  cursor: 'pointer',
  width: 192,
  height: 48,
  ml: 1.5,
  p: 1,
};

const elementSx: SxProps = {
  textAlign: 'center',
};

export type StrategyClickableDocumentProps = {
  text?: string;
  hyperlink?: string;
  document?: DocumentBucketModel | undefined | null;
};

export const StrategyClickableDocument = ({
  text,
  hyperlink,
  document,
}: StrategyClickableDocumentProps): ReactElement => {
  const handleDocumentDownload = async (document: DocumentBucketModel) => {
    const { download_url } = await generateDocumentUrl(document);
    downloadFile({ downloadFilePath: download_url, fileName: document.filename ?? text });
  };

  const handleClick = async () => {
    if (hyperlink) void window.open(hyperlink, '_blank')?.focus();
    if (document) await handleDocumentDownload(document);
  };

  return (
    <Box role="button" onClick={handleClick}>
      <Stack direction="row" justify="center" alignItems="center" elementStyling={elementSx} sx={stackSx}>
        <Iconography
          sx={{ pr: 1 }}
          iconName={hyperlink ? 'arrow-up-right-from-square' : 'file-download'}
          color="primary.main"
        />
        <LocalizedTypography fontWeight={600} fontSize={13} color="primary.main">
          {text}
        </LocalizedTypography>
      </Stack>
    </Box>
  );
};
