export enum ApiUserRoleEnum {
  AccountManager = 'AccountManager',
  AllocationEditor = 'AllocationEditor',
  Admin = 'Admin',
  AuctionAdmin = 'AuctionAdmin',
  BetaTester = 'BetaTester',
  Broker = 'Broker',
  BrokerAdmin = 'BrokerAdmin',
  Buyer = 'Buyer',
  CalendarAdmin = 'CalendarAdmin',
  CalendarManager = 'CalendarManager',
  CalendarNoteViewer = 'CalendarNoteViewer',
  CalendarOrderSender = 'CalendarOrderSender',
  DailyAdmin = 'DailyAdmin',
  DealableQuoteViewer = 'DealableQuoteViewer',
  DevPershingTester = 'DevPershingTester',
  DocumentUploader = 'DocumentUploader',
  DocumentRepositoryManager = 'DocumentRepositoryManager',
  EducationLock = 'EducationLock',
  ExecutingBroker = 'ExecutingBroker',
  GhostUser = 'GhostUser',
  HybridBuyer = 'HybridBuyer',
  HaloAdmin = 'HaloAdmin',
  IndicativeTermsheetViewer = 'IndicativeTermsheetViewer',
  Issuer = 'Issuer',
  IssuerOrderReceiver = 'IssuerOrderReceiver',
  JanneyOrderSender = 'JanneyOrderSender',
  LambdaAuthor = 'LambdaAuthor',
  PershingOrderSender = 'PershingOrderSender',
  PortfolioAdmin = 'PortfolioAdmin',
  PortfolioAnalysisAdmin = 'PortfolioAnalysisAdmin',
  PrimaryOrderSender = 'PrimaryOrderSender',
  Public = 'Public',
  QuoteViewer = 'QuoteViewer',
  ReadEducationHub = 'ReadEducationHub',
  RFQDashboardViewer = 'RFQDashboardViewer',
  RIAAdmin = 'RIAAdmin',
  SecondaryOrderSender = 'SecondaryOrderSender',
  SuperGhostUser = 'SuperGhostUser',
  TemplateDebugger = 'TemplateDebugger',
  TermsheetParser = 'TermsheetParser',
  TermsheetRequester = 'TermsheetRequester',
  TermSheetReviewer = 'TermSheetReviewer',
  UserManager = 'UserManager',
  WhiteLabelAdmin = 'WhiteLabelAdmin',
  WhiteLabel2FAUserOps = 'WhiteLabel2FAUserOps',
  WhiteLabelUserOps = 'WhiteLabelUserOps',
  WriteEducationHub = 'WriteEducationHub',
}
