import { ReactElement, ReactNode } from 'react';

import { useDesktopView } from '@halo-common/hooks';
import { Iconography, IconographyProps, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { ITranslateParams } from '@transifex/native';

const iconLabelSx = {
  textTransform: 'capitalize',
  ml: 0.5,
};

export type CalendarProductCardDetailColumnProps = {
  label?: string;
  icon?: IconographyProps['iconName'];
  children: ReactNode;
  dynamicContent?: ITranslateParams;
};

export const CalendarProductCardDetailColumn = ({
  children,
  label = '',
  icon = 'info-circle',
  dynamicContent,
}: CalendarProductCardDetailColumnProps): ReactElement => {
  const isDesktop = useDesktopView();

  const desktopLayout = isDesktop ? 'column' : 'row';
  const justifyElement = isDesktop ? undefined : 'center';

  return (
    <Stack direction={desktopLayout} spacing={1} justifyElements={justifyElement}>
      <Stack direction="row" alignItems="center" justifyElements="flex-start">
        <Iconography iconName={icon} prefix="fal" color="text.disabled" />
        <LocalizedTypography sx={iconLabelSx} variant="subtitle2" dynamicContent={dynamicContent}>
          {children}
        </LocalizedTypography>
      </Stack>
      <LocalizedTypography variant="caption" color="text.secondary">
        {label}
      </LocalizedTypography>
    </Stack>
  );
};
