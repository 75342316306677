import { ChangeEvent, ReactElement } from 'react';

import { portfolioFiltersAtom } from '@halo-atoms/portfolio';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedMenuItem, LocalizedTextField } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';
import { useAtom } from 'jotai';

const inputLabelProps = {
  shrink: true,
};

export type PortfolioCurrencyDropdownProps = {
  label?: string;
  loading?: boolean;
  disabled?: boolean;
};

export const PortfolioCurrencyDropdown = ({
  label = translations.portfolio.common.defaultCurrencyDropdownLabel,
  disabled = false,
  loading,
}: PortfolioCurrencyDropdownProps): ReactElement => {
  const [filters, setFilters] = useAtom(portfolioFiltersAtom);

  const { currency } = filters;

  const { data: user, isPending } = useUserInfoQuery();
  const currencies = user?.whiteLabel?.currencies ?? [];
  const isLoading = loading || isPending;

  if (isLoading) return <Skeleton width="100%" height={48} variant="rounded" />;

  const handleCurrencyChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const selectedId = parseInt(ev.target.value);
    const selectedCurrency = currencies.find((currency) => currency.id === selectedId);
    setFilters({ currency: selectedCurrency });
  };

  return (
    <LocalizedTextField
      slotProps={{ inputLabel: inputLabelProps }}
      label={label}
      size="large"
      select
      fullWidth
      value={currency?.id}
      onChange={handleCurrencyChange}
      disabled={disabled}
    >
      {currencies.map(({ id, code }) => (
        <LocalizedMenuItem key={id} value={id} disableLocalization>
          {code}
        </LocalizedMenuItem>
      ))}
    </LocalizedTextField>
  );
};
