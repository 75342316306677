export const getDeviceType = (): string => {
  const userAgent = navigator.userAgent;
  return /Mobi|Android|iPhone|iPad|iPod/i.test(userAgent) ? 'Mobile' : 'Desktop';
};

export const getOSName = (): string => {
  const userAgent = navigator.userAgent;

  if (/iPhone|iPad|iPod|iOS/i.test(userAgent)) return 'iOS';
  if (/Android/i.test(userAgent)) return 'Android';
  if (/Windows/i.test(userAgent)) return 'Windows';
  if (/Mac OS/i.test(userAgent)) return 'macOS';
  if (/Linux/i.test(userAgent)) return 'Linux';
  if (/CrOS/i.test(userAgent)) return 'Chrome OS';

  return 'Unknown';
};

export const getEngine = (): string => {
  // TODO: 'userAgentData' is experimental. Remove type override when stable
  // Refs: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData
  const userAgentData = (navigator as Navigator & { userAgentData?: { brands?: { brand: string }[] } }).userAgentData;
  if (userAgentData?.brands?.length) {
    return userAgentData.brands[0].brand;
  }
  const userAgent = navigator.userAgent;
  if (/Chrome/i.test(userAgent)) return 'Blink';
  if (/Firefox/i.test(userAgent)) return 'Gecko';
  if (/Safari/i.test(userAgent) && !/Chrome/i.test(userAgent)) return 'WebKit';
  return 'Unknown';
};
