import { ProductApprovalQueryModel } from '@halo-atoms/productApproval';
import { putAddRemoveCalendars } from '@halo-data-sources/clients';
import { ProductApprovalCalendarResult, ProductApprovalQueryKeyFactory } from '@halo-data-sources/queries';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

export type CalendarVisibilityPayload = {
  calendarPageId?: number | null;
  addCalendarIds?: Array<number>;
  removeCalendarIds?: Array<number>;
};

export type CalendarVisibilityResult = {
  addedCalendarIds: Array<number>;
  removedCalendarIds: Array<number>;
};

const updateCalendarFavoriteFn = async (payload: CalendarVisibilityPayload) => {
  if (!payload.calendarPageId) return null;

  const response = await putAddRemoveCalendars(payload.calendarPageId, {
    add: payload.addCalendarIds,
    remove: payload.removeCalendarIds,
  });

  return { addedCalendarIds: response.added, removedCalendarIds: response.removed };
};

export const useCalendarVisibilityMutation = (
  query: ProductApprovalQueryModel,
): UseMutationResult<CalendarVisibilityResult | null, Error, CalendarVisibilityPayload> => {
  const queryClient = useQueryClient();

  return useMutation<CalendarVisibilityResult | null, Error, CalendarVisibilityPayload>({
    mutationFn: updateCalendarFavoriteFn,
    onMutate: (payload) => {
      if (!payload) return undefined;

      const key = ProductApprovalQueryKeyFactory.products(query);
      queryClient.setQueryData<ProductApprovalCalendarResult>(key, (prev) => {
        if (!prev) return prev;

        const { calendars } = prev;
        const { addCalendarIds = [], removeCalendarIds = [] } = payload;

        const updatedCalendars = [...calendars].map((calendar) => {
          const isApproved = addCalendarIds.some((id) => calendar.id === id);
          const isDenied = !isApproved && removeCalendarIds.some((id) => calendar.id === id);

          const approved = isApproved ? true : isDenied ? false : calendar.approved;

          return { ...calendar, approved };
        });

        return { ...prev, calendars: updatedCalendars };
      });
    },
  });
};
