import { ReactElement } from 'react';

import { EmptyResultsCard } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { BrandIcon, LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';

const actionContainerSx = {
  pt: 3,
};

export const TargetedWatchlistEmptyMessage = (): ReactElement => {
  const onNoteFlixClick = () => navigateParentTo('/noteflix');
  const onCreateNoteClick = () => navigateParentTo('/note_creator');

  return (
    <EmptyResultsCard
      brandIcon={<BrandIcon variant="trackEvents" color="primary" height={96} width={96} />}
      title={translations.dashboard.noteflix.trackNoteValueOverTime}
      description={translations.dashboard.noteflix.addNotesToSetATargetAndObserveTrendsInValue}
      action={
        <Stack direction="column" spacing={3} sx={actionContainerSx}>
          <LocalizedTypography variant="h6">
            {translations.dashboard.noteflix.hereAre2WaysToGetStarted}
          </LocalizedTypography>
          <Stack direction="row" spacing={3}>
            <LocalizedButton variant="contained" size="large" onClick={onNoteFlixClick}>
              {translations.dashboard.noteflix.visitNoteflix}
            </LocalizedButton>
            <LocalizedButton variant="outlined" size="large" onClick={onCreateNoteClick}>
              {translations.dashboard.noteflix.createNote}
            </LocalizedButton>
          </Stack>
        </Stack>
      }
    />
  );
};
