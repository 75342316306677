import { ReactElement, useEffect, useState } from 'react';

import {
  AdminCalendarCsvButton,
  AdminCalendarCSVQueryParameterEnum,
  AdminCalendarStatusFilter,
  LoadingDockFiltersBar,
  LoadingDockFilterSchema,
  LoadingDockStatusEnum,
  PershingAdminQueryParams,
  usePershingAdminContext,
} from '@halo-modules/admin';
import { PershingActions, PershingSelectors } from '@halo-stores/Pershing';
import { Stack, Tabs } from '@halodomination/halo-fe-common';
import { DateTime } from 'luxon';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

const filterTabsStyling = { marginTop: 2 };

const FORM_DEFAULT_VALUES = { cusip: '' };

export const LoadingDockPageHeader = (): ReactElement => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState(false);

  const [expirationDate, setExpirationDate] = useState<DateTime | null>(null);

  const {
    disableFilters,
    pageLoading,
    queryParams,
    onStatusFilterChange,
    onQueryChange,
    statusFilter,
    tableConfiguration,
  } = usePershingAdminContext();

  const { sortMap } = tableConfiguration;

  const formMethods = useForm<LoadingDockFilterSchema>({ defaultValues: FORM_DEFAULT_VALUES });

  const totals = useSelector(PershingSelectors.selectTotals);
  const pendingTotal = useSelector(PershingSelectors.selectPendingTotal);

  const disableTabs = disableFilters || pageLoading;

  const tabs = [
    {
      label: 'Active Offerings',
      value: LoadingDockStatusEnum.active,
      disabled: disableTabs,
      count: totals.available + pendingTotal,
    },
    {
      label: 'Available Offerings',
      value: LoadingDockStatusEnum.available,
      disabled: disableTabs,
      count: totals.available,
    },
    {
      label: 'Pending Offerings',
      value: LoadingDockStatusEnum.pending,
      disabled: disableTabs,
      count: pendingTotal,
    },
    {
      label: 'Archived Offerings',
      value: LoadingDockStatusEnum.archived,
      disabled: disableTabs,
      count: totals.archived,
    },
  ];

  const generateRequestParams = (status: AdminCalendarStatusFilter, params?: PershingAdminQueryParams) => {
    const isActiveStatus = status === LoadingDockStatusEnum.active;
    const isPendingStatus = status === LoadingDockStatusEnum.pending;

    const defaultParams = { updateFilteredTotals: Boolean(params) };

    if (isPendingStatus) {
      return {
        ...defaultParams,
        pending: { query: params ?? {}, sorting: sortMap.pending },
      };
    } else if (isActiveStatus) {
      return {
        ...defaultParams,
        available: { query: { ...params, status: LoadingDockStatusEnum.available }, sorting: sortMap.available },
        pending: { query: { ...params, status: LoadingDockStatusEnum.pending }, sorting: sortMap.pending },
      };
    } else {
      return {
        ...defaultParams,
        [status]: { query: { ...params, status }, sorting: sortMap[status] },
      };
    }
  };

  const handleSearchRequest = (status: AdminCalendarStatusFilter, params?: PershingAdminQueryParams) => {
    const requestParams = generateRequestParams(status, params);
    dispatch(PershingActions.fetchLoadingDockCalendars(requestParams));
  };

  const handleFormReset = () => {
    setExpirationDate(null);
    formMethods.reset(FORM_DEFAULT_VALUES);
  };

  const handleClear = () => {
    setSearch(true);
    onQueryChange();
    handleFormReset();
  };

  const handleFilterChange = (params?: LoadingDockFilterSchema) => {
    setSearch(true);
    onQueryChange(params);
  };

  const handleStatusFilterChange = (index: number) => {
    const status = tabs[index].value;

    setSearch(true);
    onStatusFilterChange(status);
    handleFormReset();
  };

  const handleDownload = () => {
    const isActiveStatus = statusFilter === LoadingDockStatusEnum.active;
    const isPendingStatus = statusFilter === LoadingDockStatusEnum.pending;

    const route = isPendingStatus ? 'products/pending' : 'products';

    let csvStatus = statusFilter;
    if (isActiveStatus) csvStatus = AdminCalendarCSVQueryParameterEnum.activePending;

    dispatch(PershingActions.downloadPershingCalendarCsv({ route, params: { ...queryParams, status: csvStatus } }));
  };

  useEffect(() => {
    if (search && !pageLoading) {
      setSearch(false);
      handleSearchRequest(statusFilter, queryParams);
    }
  }, [search, pageLoading, queryParams, statusFilter]);

  return (
    <FormProvider {...formMethods}>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" justify="space-between" alignItems="flex-end">
          <Tabs
            variant="scrollablePills"
            tabs={tabs}
            onChange={handleStatusFilterChange}
            defaultTab={0}
            slotProps={{ tabs: { sx: filterTabsStyling } }}
          />
          <AdminCalendarCsvButton onDownload={handleDownload} />
        </Stack>
        <LoadingDockFiltersBar
          expirationDate={expirationDate}
          onClear={handleClear}
          onSearch={handleFilterChange}
          onDateChange={setExpirationDate}
        />
      </Stack>
    </FormProvider>
  );
};
