import { ReactElement, ReactNode, useEffect, useMemo } from 'react';

import { ActionButton, useStepWizardContext } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { useUpdateOnboardingDocusignMutation } from '@halo-data-sources/mutations';
import { useOnboardingDocusignFormDataQuery } from '@halo-data-sources/queries';
import { Iconography } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { isEqualWith } from 'lodash';
import { FormProvider, SubmitHandler, useForm, UseFormProps } from 'react-hook-form';

import { suitabilityFormSchema } from './schema';
import { Schema } from './types';
import { formatData, toDefaultValues } from './utils';

const isNullish = (value: unknown) => ['', null, undefined].includes(value as string);

export interface OnboardingSuitabilityFormProps extends UseFormProps<Schema> {
  children: ReactNode;
  requireAuthorizedSigner?: boolean;
}

export const OnboardingSuitabilityForm = ({
  children,
  requireAuthorizedSigner = false,
  ...formProps
}: OnboardingSuitabilityFormProps): ReactElement => {
  const { data } = useOnboardingDocusignFormDataQuery();

  const { json_data: formData = null } = data || {};

  const { goToNextStep } = useStepWizardContext();

  const { mutate, isPending } = useUpdateOnboardingDocusignMutation({
    onSuccess: ({ updated_at }) => {
      if (updated_at) localStorage.setItem('suitability_form_updated_at', updated_at);
      goToNextStep();
    },
  });

  const saveValues: SubmitHandler<Schema> = (fields) => {
    const updatedFormData = formatData(fields);

    const hasChanges = !isEqualWith(updatedFormData, formData, (a, b) => {
      if (isNullish(a) && isNullish(b)) return true;
    });

    if (!hasChanges) return goToNextStep();

    mutate(updatedFormData);
  };

  const defaultValues = useMemo(() => toDefaultValues(formData), [formData]);

  const resolver = useMemo(
    () => yupResolver(suitabilityFormSchema(requireAuthorizedSigner)),
    [requireAuthorizedSigner],
  );

  const formMethods = useForm<Schema>({
    defaultValues,
    resolver,
    ...formProps,
  });

  useEffect(() => {
    formMethods.reset(defaultValues);
  }, [defaultValues]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(saveValues)}>
        <Stack spacing={3}>
          {children}
          <ActionButton
            variant="contained"
            color="primary"
            endIcon={<Iconography iconName="arrow-right" color="inherit" />}
            fullWidth
            type="submit"
            loading={isPending}
          >
            {translations.common.next}
          </ActionButton>
        </Stack>
      </form>
    </FormProvider>
  );
};
