import { HTMLAttributes, ReactNode, SyntheticEvent } from 'react';

import type { AutocompleteFieldRender } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { LocalizedTextField } from '@halodomination/halo-fe-common';
import { Autocomplete, AutocompleteRenderInputParams, createFilterOptions, FilterOptionsState } from '@mui/material';
import { useT } from '@transifex/react';

type CreateOption = { label: ReactNode; value: string };

type Option = string | CreateOption;

const filter = createFilterOptions<Option>();

const common = {
  fullWidth: true,
  variant: 'outlined',
  size: 'large',
} as const;

export const renderAutocomplete: AutocompleteFieldRender = ({ field, fieldState, options = [], props }) => {
  const translator = useT();

  const onChange = (_event: SyntheticEvent, option: string | CreateOption | null) => {
    const value = (option as CreateOption)?.value || option || null;
    field.onChange(value);
  };

  const filterOptions = (options: Array<Option>, params: FilterOptionsState<Option>) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    const isNotInOptions = !options.includes(inputValue);
    if (inputValue && isNotInOptions) {
      filtered.push({
        value: inputValue,
        label: translator(translations.components.addOption, {
          value: inputValue,
        }),
      });
    }

    return filtered;
  };

  const getOptionLabel = (option: Option) => {
    if (typeof option === 'string') return option;
    return option.value;
  };

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: Option) => {
    const label = typeof option === 'string' ? option : option.label;
    return <li {...props}>{label}</li>;
  };

  const renderInput = (inputParams: AutocompleteRenderInputParams) => (
    <LocalizedTextField
      {...field}
      {...inputParams}
      {...common}
      {...props}
      error={!!fieldState.error}
      helperText={fieldState.error?.message ?? props?.helperText}
    />
  );

  return (
    <Autocomplete
      {...field}
      clearOnBlur
      selectOnFocus
      handleHomeEndKeys
      options={options}
      onChange={onChange}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={renderInput}
      freeSolo
    />
  );
};
