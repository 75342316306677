import { ReactElement, ReactNode } from 'react';

import { PageFooter, PageFooterProps } from '@halo-common/layouts';
import { Box, Stack } from '@mui/material';

const wrapperSx = {
  direction: 'column',
  overflowX: 'hidden',
  width: '100%',
};

const contentSx = {
  pb: 6,
  minHeight: '100vh',
  width: '100%',
};

export type PageFooterLayoutProps = PageFooterProps & {
  children: ReactNode;
};

export const PageFooterLayout = ({ children, ...FooterProps }: PageFooterLayoutProps): ReactElement => (
  <Stack sx={wrapperSx}>
    <Box sx={contentSx}>{children}</Box>
    <PageFooter {...FooterProps} />
  </Stack>
);
