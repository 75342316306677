import { ReactElement } from 'react';

import { useRemainingHeight } from '@halo-common/hooks';
import {
  AuctionDetailsModal,
  OrderBookAuctionDownloadCSVButton,
  OrderBookAuctionFilters,
  OrderBookAuctionTable,
} from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';

export const OrderBookAuctionsPage = (): ReactElement => {
  const remainingHeight = useRemainingHeight();

  return (
    <Stack direction="column" spacing={2} sx={{ height: remainingHeight }}>
      <OrderBookAuctionFilters />
      <Stack direction="row" justify="flex-end">
        <OrderBookAuctionDownloadCSVButton />
      </Stack>
      <OrderBookAuctionTable />
      <AuctionDetailsModal />
    </Stack>
  );
};
