import { ReactElement } from 'react';

import { StatusChip, StatusChipProps } from '@halo-common/components';
import { PershingStatusEnum } from '@halo-common/enums';
import { capitalize } from '@mui/material';

const getStatus = (status: PershingStatusEnum, isActionRequired = false): StatusChipProps['status'] => {
  switch (status) {
    case PershingStatusEnum['Filled']:
      return 'completed';

    case PershingStatusEnum['Denied']:
    case PershingStatusEnum['Rejected']:
    case PershingStatusEnum['Canceled']:
      return 'closed';

    case PershingStatusEnum['Confirmed']:
      return isActionRequired ? 'completed' : 'pending';

    case PershingStatusEnum['Needs Review']:
      return isActionRequired ? 'action-required' : 'pending';

    default:
      return 'pending';
  }
};

export type PershingStatusIndicatorProps = {
  value: string;
  message?: string;
  enableActionRequiredStyling?: boolean;
};

export const PershingStatusIndicator = ({
  value,
  message,
  enableActionRequiredStyling = false,
}: PershingStatusIndicatorProps): ReactElement => {
  const pershingStatus = PershingStatusEnum[value as keyof typeof PershingStatusEnum];

  const label = capitalize(value);
  const status = getStatus(pershingStatus, enableActionRequiredStyling);

  return <StatusChip label={label} status={status} message={message} />;
};
