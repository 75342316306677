import { ChangeEvent, ReactElement, useEffect } from 'react';

import { educationHubFiltersAtom } from '@halo-atoms/education';
import { ActionButton } from '@halo-common/components';
import { useDebounced } from '@halo-common/hooks';
import { useEducationHubCSVQuery } from '@halo-data-sources/queries';
import { Stack, useSnackbar } from '@halodomination/halo-fe-common';
import { TextField } from '@mui/material';
import { useAtom } from 'jotai';

const DEBOUNCE_WAIT = 300;

const textFieldSx = {
  minWidth: 320,
};

export const EducationHubFilterBar = (): ReactElement => {
  const { enqueueErrorEvent } = useSnackbar();

  const [filters, setFilters] = useAtom(educationHubFiltersAtom);

  const { refetch, isFetching, isError } = useEducationHubCSVQuery(filters);

  const handleCSVDownload = () => void refetch();

  const handleUserSearch = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;

    setFilters((prev) => ({ ...prev, filters: { ...prev.filters, userName: value } }));
  };

  const debouncedUserSearchHandler = useDebounced(handleUserSearch, DEBOUNCE_WAIT);

  const handleOrgSearch = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;

    setFilters((prev) => ({ ...prev, filters: { ...prev.filters, organizationName: value } }));
  };

  const debouncedOrgSearchHandler = useDebounced(handleOrgSearch, DEBOUNCE_WAIT);

  useEffect(() => {
    if (isError) enqueueErrorEvent({ message: 'There was an issue generating the csv. Please try again later.' });
  }, [isError]);

  return (
    <Stack direction="row" justify="space-between" alignItems="center">
      <Stack direction="row" spacing={2}>
        <TextField sx={textFieldSx} label="Search by user" fullWidth onChange={debouncedUserSearchHandler} />
        <TextField sx={textFieldSx} onChange={debouncedOrgSearchHandler} label="Search by organization" fullWidth />
      </Stack>
      <ActionButton color="primary" onClick={handleCSVDownload} loading={isFetching}>
        Download CSV
      </ActionButton>
    </Stack>
  );
};
