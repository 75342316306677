import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type AccountQueryKeys = {
  all: QueryKeyFactoryFn;
  households: QueryKeyFactoryFn;
  paged: QueryKeyFactoryFn;
};

export const AccountQueryKeyFactory: AccountQueryKeys = {
  all: () => ['accounts'],
  households: () => [...AccountQueryKeyFactory.all(), 'households'],
  paged: (payload) => [...AccountQueryKeyFactory.all(), 'paged', hash(payload)],
};
