import { ReactElement } from 'react';

import { Stack } from '@halodomination/halo-fe-common';
import { Alert, Typography } from '@mui/material';

const alertBoxSx = {
  paddingBottom: 2,
};

export type EditCalendarModalErrorMessageProps = {
  message?: string;
};

export const EditCalendarModalErrorMessage = ({
  message = 'An unexpected error has occurred please try again later.',
}: EditCalendarModalErrorMessageProps): ReactElement => {
  const alertSx = { backgroundColor: 'error.background' };

  const alertText = message ? <Typography>{message}</Typography> : null;

  return (
    <Stack direction="column" sx={alertBoxSx}>
      <Alert severity="error" variant="outlined" sx={alertSx}>
        <Typography>There was an error updating this calendar.</Typography>
        {alertText}
      </Alert>
    </Stack>
  );
};
