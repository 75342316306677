import { ReactElement } from 'react';

import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { OrderBookAuctionStatusDropdownEnum } from '@halo-common/enums';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useAtom } from 'jotai';

export const AuctionStatusField = (): ReactElement => {
  const items = Object.entries(OrderBookAuctionStatusDropdownEnum);

  const [searchFields, setSearchFields] = useAtom(orderBookQueryAtom);

  const labelString = 'Status';

  const handleStatusChange = (ev: SelectChangeEvent<string>) => {
    setSearchFields({
      filters: {
        status: ev.target.value,
      },
    });
  };

  return (
    <FormControl fullWidth>
      <InputLabel shrink>{labelString}</InputLabel>
      <Select
        label={labelString}
        size="large"
        value={searchFields.filters.status}
        onChange={handleStatusChange}
        displayEmpty
        notched
      >
        <MenuItem value="">All</MenuItem>
        {items.map((entry) => (
          <MenuItem key={entry[1]} value={entry[1]}>
            {entry[0]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
