import { AnchorHTMLAttributes, ForwardedRef, forwardRef, ReactElement, Ref } from 'react';

import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

type ComposedLinkProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & NextLinkProps;

const ComposedLink = forwardRef<HTMLAnchorElement, ComposedLinkProps>(
  ({ as, href, replace, scroll, passHref, shallow, prefetch, ...other }, ref) => (
    <NextLink
      as={as}
      href={href}
      passHref={passHref}
      prefetch={prefetch}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
    >
      <a ref={ref} {...other} />
    </NextLink>
  ),
);

export type HaloLinkPropsBaseProps = {
  activeClassName?: string;
  innerRef?: Ref<HTMLAnchorElement>;
};

export type HaloLinkProps = HaloLinkPropsBaseProps & ComposedLinkProps & Omit<MuiLinkProps, 'href'>;

const Link = ({ href, ...other }: HaloLinkProps, ref: ForwardedRef<HTMLAnchorElement>): ReactElement => {
  return <MuiLink underline="none" component={ComposedLink} href={href as string} ref={ref} {...other} />;
};

export const HaloLink = forwardRef<HTMLAnchorElement, HaloLinkProps>(Link);
