import { ReactElement } from 'react';

import { dynamicFiltersHeaderManagerAtom, dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { CollapsibleFilters, GroupedCheckbox, GroupedCheckboxProps } from '@halo-common/components';
import { DynamicTypeFiltersSideBarTitlesDict } from '@halo-common/layouts';
import { useCalendarIssuersQuery } from '@halo-data-sources/queries';
import { useAtom, useAtomValue } from 'jotai';

export type CalendarIssuerFiltersProps = {
  approved?: boolean | null;
  tag: string;
};

export const CalendarIssuerFilters = ({ approved, tag }: CalendarIssuerFiltersProps): ReactElement | null => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);
  const getHeaderFilters = useAtomValue(dynamicFiltersHeaderManagerAtom);

  const headerFilters = getHeaderFilters(tag);
  const { endDate, startDate } = headerFilters;

  const { data } = useCalendarIssuersQuery({ approved, endDate, startDate, showAsNME: false });

  const sideBarFilters = getSideBarFilters(tag);
  const issuers = sideBarFilters.issuers;

  const handleIssuerChange = (value: string) => {
    const isInFilter = issuers.some((issuer) => issuer.id === value);
    const calendarIssuer = data?.find((issuer) => issuer.id === parseInt(value));

    if (isInFilter) {
      const updatedIssuers = issuers.filter((issuer) => issuer.id !== value);
      setSideBarFilters({ tag, issuers: updatedIssuers });
    } else if (calendarIssuer) {
      const updatedIssuers = [...issuers, { name: calendarIssuer?.name, id: value }];
      setSideBarFilters({ tag, issuers: updatedIssuers });
    }
  };

  const issuerCheckboxes: GroupedCheckboxProps['values'] = data?.map((issuer) => ({
    isChecked: issuers.some(({ id }) => issuer.id === parseInt(id)),
    name: issuer.name,
    value: issuer.id.toString(),
  }));

  return issuerCheckboxes?.length ? (
    <CollapsibleFilters title={DynamicTypeFiltersSideBarTitlesDict.issuer}>
      <GroupedCheckbox onChange={handleIssuerChange} values={issuerCheckboxes} />
    </CollapsibleFilters>
  ) : null;
};
