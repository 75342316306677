import { ReactElement, SyntheticEvent, useRef, useState } from 'react';

import { portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import { usePrimaryAssetIdentifier } from '@halo-common/hooks';
import { useDebounced } from '@halo-common/hooks/useDebounced';
import { AssetPositionModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { usePortfolioPositionAssetSearchQuery } from '@halo-data-sources/queries';
import { Iconography, LocalizedTextField } from '@halodomination/halo-fe-common';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  CircularProgress,
  Skeleton,
  autocompleteClasses,
} from '@mui/material';
import { useT } from '@transifex/react';
import { useAtom } from 'jotai';

import { PortfolioAssetSearchTypeAheadOption } from './PortfolioAssetSearchTypeAheadOption';

const autocompleteSx = {
  width: '100%',
  [`& .${autocompleteClasses.popupIndicator}`]: {
    transform: 'none',
  },
};

const itemSx = {
  '&:hover': {
    backgroundColor: 'grey.300',
  },
};

const loadingIndicatorContainerSx = {
  p: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export type PortfolioAssetSearchTypeAheadStateModel = {
  search: string;
  selectedOption: AssetPositionModel | null;
};

export type PortfolioAssetSearchTypeAheadProps = {
  loading?: boolean;
};

export type PortfolioAssetSearchOptionType = AssetPositionModel;

export const PortfolioAssetSearchTypeAhead = ({ loading }: PortfolioAssetSearchTypeAheadProps): ReactElement => {
  const translator = useT();
  const inputRef = useRef<HTMLDivElement>(null);

  const [search, setSearch] = useState<string>('');
  const [{ asset, query }, setPositionData] = useAtom(portfolioPositionsManagerAtom);

  const { identifierType, identifiers } = usePrimaryAssetIdentifier();

  const payload = { assetSearch: search.toUpperCase(), assetIdentifiers: identifiers, ...query };

  const {
    data: searchResults,
    isPending: searching,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = usePortfolioPositionAssetSearchQuery(payload);

  const options = searchResults?.pages.flatMap((page) => page.positions) ?? [];

  const translationOptions = { assetId: identifierType.toUpperCase() };
  const inputLabel = translator(translations.portfolio.common.assetSearchLabel, translationOptions);
  const noOptionsText = search.length ? translator(translations.portfolio.common.assetSearchEmptyMessage) : inputLabel;

  const debouncedSearchHandler = useDebounced(setSearch, 500);

  const handleSearch = (_: unknown, value: string, reason: AutocompleteInputChangeReason) => {
    const isInputChange = reason === 'input';
    if (isInputChange) debouncedSearchHandler(value);
  };

  const handleSelect = (
    _: unknown,
    option: PortfolioAssetSearchOptionType | null,
    reason: AutocompleteChangeReason,
  ) => {
    const isClearTextFieldAction = reason === 'clear' || option === null;
    const payload = !isClearTextFieldAction ? { asset: option } : undefined;

    setSearch('');
    setPositionData(payload);
  };

  const handleOptionLabel = (asset: PortfolioAssetSearchOptionType) => {
    const { assetIds, shortName } = asset;

    if (shortName) return shortName;
    else return assetIds[0]?.value ?? translator(translations.portfolio.common.assetSearchNoMatchMessage);
  };

  const optionValueComparator = (option: AssetPositionModel, val: AssetPositionModel) => option.id === val.id;

  const textFieldInputLoadingIndicator = searching ? (
    <Box sx={loadingIndicatorContainerSx}>
      <CircularProgress size={20} />
    </Box>
  ) : null;

  return loading ? (
    <Skeleton width="100%" height={48} variant="rounded" />
  ) : (
    <Autocomplete<PortfolioAssetSearchOptionType, undefined, boolean>
      sx={autocompleteSx}
      clearOnBlur
      blurOnSelect
      slotProps={{ popper: { keepMounted: true } }}
      ListboxProps={{
        role: 'list-box',
        onScroll: (event: SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          const isBottom = listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight * 0.95;
          const loadMoreContent = !searching && !isFetchingNextPage && hasNextPage && isBottom;
          if (loadMoreContent) void fetchNextPage();
        },
      }}
      options={options}
      filterOptions={(options, { inputValue }) =>
        options.filter((option) => {
          const matchesShortName = option.shortName.toLowerCase().includes(inputValue.toLowerCase());
          const matchesId = option.assetIds.some(({ value }) => value.toLowerCase().includes(inputValue.toLowerCase()));

          return matchesShortName || matchesId;
        })
      }
      onChange={handleSelect}
      value={asset}
      onInputChange={handleSearch}
      isOptionEqualToValue={optionValueComparator}
      getOptionLabel={handleOptionLabel}
      noOptionsText={noOptionsText}
      popupIcon={<Iconography iconName="magnifying-glass" />}
      loading={searching}
      loadingText={textFieldInputLoadingIndicator}
      renderInput={(props) => (
        <LocalizedTextField {...props} fullWidth size="large" label={inputLabel} inputRef={inputRef} />
      )}
      renderOption={(props, option, { index }) => {
        const assetId = option.id;
        const id = option.assetIds[0]?.value;
        const key = `${assetId}-${id}-${index}`;

        return (
          <Box {...props} key={key} component="li" sx={itemSx}>
            <PortfolioAssetSearchTypeAheadOption option={option} />
          </Box>
        );
      }}
    />
  );
};
