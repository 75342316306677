import {
  acceptBuyerAuctionTerms,
  cancelBuyerAuction,
  createIssuerQuote,
  createOrderAuctionQuoteComment,
  declineIssuerQuote,
  deleteTackOn,
  fetchAuction,
  fetchBuyerPrelimDocument,
  fetchOrderAuction,
  fetchOrderAuctions,
  postTackOn,
  putFinalPrelimTerms,
  putPrelimTerms,
  putTackOn,
  rejectBuyerAuctionTerms,
  requestOrderAuctionChooseWinner,
  requestOrderAuctionOpsApproval,
  requestOrderAuctionOpsApprovalPreview,
  requestOrderAuctionPreApproval,
  requestOrderAuctionQuoteCommentApproval,
  sendOrderAuctionTestEmail,
  updateAdminAuction,
} from '@halo-data-sources/clients';
import { ApiAuctionMapper, AuctionMapper } from '@halo-data-sources/mappers';
import { createThunk } from '@halo-stores/createThunk';

import {
  AuctionsThunkResult,
  AuctionThunkResult,
  CancelOrderAuctionThunkAction,
  ChooseInstantWinnerOrderAuctionThunkAction,
  ChooseWinnerOrderAuctionThunkAction,
  CreateTackOnThunkAction,
  DeclineAuctionCommentThunkAction,
  DeleteTackOnThunkAction,
  EditTackOnThunkAction,
  GetAuctionThunkAction,
  IssuerAuctionCommentThunkAction,
  IssuerAuctionQuoteThunkAction,
  OpsApproveOrderAuctionThunkAction,
  OpsApproveTestEmailOrderAuctionThunkAction,
  UpdateAuctionStatusThunkResult,
  UpdateAuctionThunkResult,
  UpdateOrderAuctionThunkAction,
  UploadFinalPrelimTermsThunkAction,
  UploadPrelimTermsThunkAction,
} from './OrdersModel';

export const getOrderAuctions = createThunk<AuctionsThunkResult, void>('orders/auction/all/get', async () => {
  const response = await fetchOrderAuctions();

  const auctions = response.pending_note_orders.map((auction) => ApiAuctionMapper.toAdminAuctionModel(auction));

  return { auctions };
});

export const getPrelimTerms = createThunk<void, number>('orders/auction/terms/get', async (id) => {
  const url = await fetchBuyerPrelimDocument(id);

  window.open(url, '_blank');
});

export const uploadPrelimTerms = createThunk<AuctionThunkResult, UploadPrelimTermsThunkAction>(
  'orders/auction/terms/put',
  async ({ auction, ...data }) => {
    const requestBody = {
      auctionId: auction.id,
      ...data,
    };

    await putPrelimTerms(requestBody);

    const response = await fetchOrderAuction(auction.id);

    const parsedAuction = ApiAuctionMapper.toAdminAuctionModel(response.result.pending_note_order);

    return { auction: parsedAuction };
  },
);

export const uploadFinalPrelimTerms = createThunk<AuctionThunkResult, UploadFinalPrelimTermsThunkAction>(
  'orders/auction/terms/final/put',
  async ({ auction, ...data }) => {
    const requestBody = {
      auctionId: auction.id,
      ...data,
    };

    await putFinalPrelimTerms(requestBody);

    const response = await fetchOrderAuction(auction.id);

    const parsedAuction = ApiAuctionMapper.toAdminAuctionModel(response.result.pending_note_order);

    return { auction: parsedAuction };
  },
);

export const rejectPrelimTerms = createThunk<AuctionThunkResult, number>('orders/auction/terms/reject', async (id) => {
  const response = await rejectBuyerAuctionTerms(id);

  const auction = ApiAuctionMapper.toAdminAuctionModel(response);

  return { auction };
});

export const acceptPrelimTerms = createThunk<AuctionThunkResult, number>('orders/auction/terms/accept', async (id) => {
  const response = await acceptBuyerAuctionTerms(id);

  const auction = ApiAuctionMapper.toAdminAuctionModel(response);

  return { auction };
});

export const approveOrderAuction = createThunk<AuctionThunkResult, number>(
  'orders/auction/status/pre-approve/complete',
  async (id) => {
    const response = await requestOrderAuctionPreApproval({
      pending_note_order_id: id,
    });

    const auction = ApiAuctionMapper.toAdminAuctionModel(response.result.pending_note_order);

    return { auction };
  },
);

export const sendOpsApprovalTestEmail = createThunk<void, OpsApproveTestEmailOrderAuctionThunkAction>(
  'orders/auction/send/ops-approve/email',
  async ({ subject, autoReply, tableData, id }) => {
    const response = await requestOrderAuctionOpsApprovalPreview({
      id,
      auto_reply: autoReply,
      table_data: tableData.map(({ name, value }) => ({ key: name, value: value.toString() })),
    });

    await sendOrderAuctionTestEmail(id, {
      subject,
      body: response.html,
    });
  },
);

export const opsApproveOrderAuction = createThunk<AuctionThunkResult, OpsApproveOrderAuctionThunkAction>(
  'orders/auction/status/ops-approve/complete',
  async ({ to, cc, autoReply, tableData, id, subject }) => {
    const response = await requestOrderAuctionOpsApproval({
      id,
      subject,
      issuers: to.map((id) => parseInt(id)),
      cc_addresses: cc,
      auto_reply: autoReply,
      table_data: tableData.map(({ name, value }) => ({ key: name, value: value.toString() })),
    });

    const auction = ApiAuctionMapper.toAdminAuctionModel(response.result.pending_note_order);

    return { auction };
  },
);

export const chooseWinnerOrderAuction = createThunk<AuctionThunkResult, ChooseWinnerOrderAuctionThunkAction>(
  'orders/auction/status/choose-winner/complete',
  async ({ id, quote }) => {
    const response = await requestOrderAuctionChooseWinner({
      payload: {
        auction_id: id,
        quote_id: quote,
      },
    });

    const auction = ApiAuctionMapper.toAdminAuctionModel(response.result.pending_note_order);

    return { auction };
  },
);

export const chooseInstantWinnerOrderAuction = createThunk<
  UpdateAuctionStatusThunkResult,
  ChooseInstantWinnerOrderAuctionThunkAction
>('orders/auction/status/choose-winner/instant/complete', async ({ id, quoteId, status }) => {
  const response = await requestOrderAuctionChooseWinner({
    payload: {
      auction_id: id,
      quote_id: quoteId,
      force: true,
    },
  });

  const auction = ApiAuctionMapper.toAdminAuctionModel(response.result.pending_note_order);

  return { auction, oldStatus: status };
});

export const cancelOrderAuction = createThunk<UpdateAuctionStatusThunkResult, CancelOrderAuctionThunkAction>(
  'orders/auction/status/canceled/complete',
  async ({ id, status }) => {
    const response = await cancelBuyerAuction(id);

    const auction = ApiAuctionMapper.toAdminAuctionModel(response);

    return { auction, oldStatus: status };
  },
);

export const updateOrderAuction = createThunk<UpdateAuctionThunkResult, UpdateOrderAuctionThunkAction>(
  'orders/auction/update',
  async (auction) => {
    const { id, status, ...data } = auction;

    const requestBody = AuctionMapper.toApiPatchOrderAuctionRequestModel(data);

    const response = await updateAdminAuction(id, requestBody);

    const updatedAuction = ApiAuctionMapper.toAdminAuctionModel(response.result.pending_note_order);

    return { id, status, data: updatedAuction };
  },
);

export const createIssuerQuoteForOrderAuction = createThunk<AuctionThunkResult, IssuerAuctionQuoteThunkAction>(
  'orders/auction/quote/create',
  async (payload) => {
    const { issuerId, auction, quote, comment } = payload;
    const { id: auctionId } = auction;

    await createIssuerQuote(auctionId, issuerId, parseFloat(quote), comment);

    const response = await fetchOrderAuction(auctionId);

    const parsedAuction = ApiAuctionMapper.toAdminAuctionModel(response.result.pending_note_order);

    return { auction: parsedAuction };
  },
);

export const createIssuerQuoteCommentForOrderAuction = createThunk<AuctionThunkResult, IssuerAuctionCommentThunkAction>(
  'orders/auction/quote/comment/create',
  async ({ quoteId, comment }) => {
    const response = await createOrderAuctionQuoteComment(quoteId, comment);

    const auction = ApiAuctionMapper.toAdminAuctionModel(response.result.pending_note_order);

    return { auction };
  },
);

export const approveIssuerQuoteCommentForOrderAuction = createThunk<
  AuctionThunkResult,
  IssuerAuctionCommentThunkAction
>('orders/auction/quote/comment/approve', async ({ quoteId, comment }) => {
  const response = await requestOrderAuctionQuoteCommentApproval(quoteId, comment);

  const auction = ApiAuctionMapper.toAdminAuctionModel(response.result.pending_note_order);

  return { auction };
});

export const declineIssuerQuoteCommentForOrderAuction = createThunk<
  AuctionThunkResult,
  DeclineAuctionCommentThunkAction
>('orders/auction/quote/comment/decline', async ({ id, issuerId, comment }) => {
  const response = await declineIssuerQuote(issuerId, id, comment);

  const auction = ApiAuctionMapper.toAdminAuctionModel(response.result.pending_note_order);

  return { auction };
});

export const createAuctionTackOn = createThunk<AuctionThunkResult, CreateTackOnThunkAction>(
  'orders/auction/tackon/create',
  async ({ id, reoffer, pages, externalName, internalName, issuer, category, startShowing, expiration }) => {
    const response = await postTackOn(
      id,
      reoffer,
      pages,
      externalName,
      internalName,
      issuer,
      category,
      startShowing,
      expiration,
    );

    const auction = ApiAuctionMapper.toAdminAuctionModel(response.auction);

    return { auction };
  },
);

export const editAuctionTackOn = createThunk<AuctionThunkResult, EditTackOnThunkAction>(
  'orders/auction/tackon/edit',
  async ({ id, reoffer, pages, externalName, internalName, issuer, category, startShowing, expiration }) => {
    const response = await putTackOn(
      id,
      reoffer,
      pages,
      externalName,
      internalName,
      issuer,
      category,
      startShowing,
      expiration,
    );

    const auction = ApiAuctionMapper.toAdminAuctionModel(response.auction);

    return { auction };
  },
);

export const deleteAuctionTackOn = createThunk<AuctionThunkResult, DeleteTackOnThunkAction>(
  'orders/auction/tackon/delete',
  async ({ id }) => {
    const response = await deleteTackOn(id);

    const auction = ApiAuctionMapper.toAdminAuctionModel(response.auction);

    return { auction };
  },
);

export const getAuction = createThunk<AuctionThunkResult, GetAuctionThunkAction>(
  'orders/auction/allocations/get',
  async ({ auctionId }) => {
    const response = await fetchAuction(auctionId);

    const auction = ApiAuctionMapper.toAdminAuctionModel(response.auction);

    return { auction };
  },
);
