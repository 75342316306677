import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiUserAlertModel,
  ApiUserAlertPostRequestModel,
  ApiUserAlertPutRequestModel,
} from '@halo-data-sources/models';

const COMMON_USER_ALERTS_PATH = '/common/user/alerts';

export const getUserAlerts = async (): Promise<Array<ApiUserAlertModel>> => {
  const clientConfiguration = await getClientConfiguration(COMMON_USER_ALERTS_PATH);

  return await request<Array<ApiUserAlertModel>>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });
};

export const postUserAlerts = async (data: ApiUserAlertPostRequestModel): Promise<ApiUserAlertModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_USER_ALERTS_PATH);

  return await request<ApiUserAlertModel>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...data,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};

export const putUserAlerts = async (
  userAlertId: string,
  body: Partial<ApiUserAlertPutRequestModel>,
): Promise<ApiUserAlertModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_USER_ALERTS_PATH);

  return await request<ApiUserAlertModel>(`${clientConfiguration.basePath}/${userAlertId}`, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      ...body,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};
