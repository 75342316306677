import { ReactElement } from 'react';

import { Stack } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';

const rowStyling = {
  borderTop: '1px solid',
  borderTopColor: 'grey.300',
  padding: 3,
  '&:hover': {
    backgroundColor: 'grey.100',
    cursor: 'default',
  },
};

export const DocumentsTableSkeletonRow = (): ReactElement => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justify="space-between"
      xs={[3, 4, 2, 3]}
      md={[2, 6, 2, 2]}
      sx={rowStyling}
      justifyElements={[undefined, undefined, undefined, 'flex-end']}
    >
      <Skeleton width={90} height={32} variant="rounded" />
      <Skeleton width={90} height={32} variant="rounded" />
      <Skeleton width={90} height={32} variant="rounded" />
      <Skeleton width={200} height={32} variant="rounded" />
    </Stack>
  );
};
