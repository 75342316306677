import { SortModel } from '@halo-common/models';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { downloadFile } from '@halo-common/utils';
import { fetchPortfolioPositionsDownloadDocument } from '@halo-data-sources/clients';
import { mapApiDocumentModelToS3DocumentModel } from '@halo-data-sources/mappers';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

export type PortfolioPositionsDownloadPayload = {
  termsheetId?: number;
  account?: AccountTypeAheadOption | null;
  fileName?: string;
  synced: boolean | null;
  sort?: Array<SortModel>;
};

const getPortfolioPositionsDownloadMutationFn = async (payload: PortfolioPositionsDownloadPayload) => {
  const { termsheetId, account, synced, sort } = payload;
  const accountId = account?.account?.id;
  const householdId = account?.household?.id;

  const response = await fetchPortfolioPositionsDownloadDocument({
    term_sheet_id: termsheetId,
    account_selector: {
      synced,
      account_ids: accountId ? [accountId] : undefined,
      advisee_ids: householdId ? [householdId] : undefined,
    },
    filename: payload.fileName,
    sort,
  });

  const document = mapApiDocumentModelToS3DocumentModel(response);

  const fileName = document.filename;
  const downloadFilePath = document.tmplink;

  downloadFile({ fileName, downloadFilePath });
};

export const usePortfolioPositionsDownloadMutation = (): UseMutationResult<
  void,
  Error,
  PortfolioPositionsDownloadPayload
> => {
  const { enqueueSuccessEvent, enqueuePendingEvent, enqueueErrorEvent, closeSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: getPortfolioPositionsDownloadMutationFn,
    onMutate: () => {
      enqueuePendingEvent({ message: translations.pdm.postTrade.pendingExcelExport });
    },
    onSuccess: () => {
      closeSnackbar();
      enqueueSuccessEvent({ message: translations.pdm.postTrade.successExcelExport });
    },
    onError: () => {
      closeSnackbar();
      enqueueErrorEvent({ message: translations.messages.error });
    },
  });
};
