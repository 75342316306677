import { ReactElement, useState } from 'react';

import { translations } from '@halo-common/translations';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { Box, InputAdornment, TextField } from '@mui/material';

const copySx = { p: 1, mr: -1.75, height: 48 };
const fullHeightSx = { height: '100%' };

export type PreTradeNoteDetailModalHeaderSharePopoverButtonCopyFieldProps = {
  url: string;
};

export const PreTradeNoteDetailModalHeaderSharePopoverButtonCopyField = ({
  url,
}: PreTradeNoteDetailModalHeaderSharePopoverButtonCopyFieldProps): ReactElement => {
  const [isCopied, setIsCopied] = useState(false);

  const copyText = () => {
    void navigator.clipboard.writeText(url);
    setIsCopied(true);
  };

  const copyButton = isCopied ? (
    <InputAdornment position="end">
      <Box sx={copySx}>
        <LocalizedButton
          startIcon={<Iconography iconName="check" color="primary.main" />}
          type="button"
          variant="text"
          color="primary"
          fullWidth
          sx={fullHeightSx}
        >
          {translations.common.copied}
        </LocalizedButton>
      </Box>
    </InputAdornment>
  ) : (
    <InputAdornment position="end">
      <Box sx={copySx}>
        <LocalizedButton sx={fullHeightSx} size="small" variant="contained" onClick={copyText}>
          {translations.common.copy}
        </LocalizedButton>
      </Box>
    </InputAdornment>
  );

  return <TextField fullWidth defaultValue={url} slotProps={{ input: { readOnly: true, endAdornment: copyButton } }} />;
};
