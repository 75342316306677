export const PRODUCT_APPROVAL_EDIT_CLOSE_DATE_MODAL_TRANSLATIONS = {
  closeTimeTitle: 'Close Time',
  issuerCloseTimeTitle: 'Issuer Close Time',
  modifyCloseDateLabel: 'Modify Close Date/Time',
  modifyCloseDaysPriorOption0: '0 Business Days Prior',
  modifyCloseDaysPriorOption1: '1 Business Day Prior',
  modifyCloseDaysPriorOption2: '2 Business Days Prior',
  modifyCloseDaysPriorOption3: '3 Business Days Prior',
  modifyCloseDaysPriorOption4: '4 Business Days Prior',
  modifyCloseDaysPriorOption5: '5 Business Days Prior',
  modifyCloseDaysPriorOption6: '6 Business Days Prior',
  modifyCloseDaysPriorOption7: '7 Business Days Prior',
  modifyCloseDaysPriorSelectHelperText: `Sets all selected products to close relative to the Issuer-provided close time.`,
  modifyCloseTimeLabel: 'Modify Close Time',
  overlineMultiple: '{count, plural, one {# Selected Product} other {# Selected Products}}',
  titleMultiple: 'Edit Multiple Product Listings',
  titleSingle: 'Edit Product Listing Details',
};
