import { ReactElement } from 'react';

import { InstitutionalLineChart } from '@halo-common/components';
import { AUTO_CALL_LEVEL_LINE_NAME, MAX_RETURN_LINE_NAME, useUnderlyingChartEnhancement } from '@halo-common/hooks';
import { V2PostTradeNoteDetailModalProps } from '@halo-common/modals';
import { translations } from '@halo-common/translations';
import { LineChartDataPoint, LineChartLineDefinition, LineChartReferenceLine } from '@halodomination/halo-fe-common';

export type V2PostTradeNoteDetailModalUnderlyingPerformanceChartProps = {
  termsheetId?: number;
  lines?: Array<LineChartLineDefinition>;
  data?: Array<LineChartDataPoint>;
  references?: Array<LineChartReferenceLine>;
  referenceLines?: Array<LineChartReferenceLine>;
  hiddenLines: Array<string>;
  filters: V2PostTradeNoteDetailModalProps['filters'];
  loading: boolean;
};

export const V2PostTradeNoteDetailModalUnderlyingPerformanceChart = ({
  termsheetId,
  lines = [],
  data = [],
  references = [],
  hiddenLines = [],
  filters,
  loading,
}: V2PostTradeNoteDetailModalUnderlyingPerformanceChartProps): ReactElement => {
  const enhancedChartMeta = useUnderlyingChartEnhancement(termsheetId, lines, data, references, filters?.accountOption);

  const chartLines = enhancedChartMeta?.lines ?? lines ?? [];
  const chartReferences = enhancedChartMeta?.references ?? references ?? [];
  const chartData = enhancedChartMeta?.data ?? data ?? [];

  const filteredLines = chartLines.map((line) => ({
    ...line,
    hidden: hiddenLines.includes(line.label.toLowerCase()),
  }));

  const yLabelFormatter = (value: string) => {
    return `${value}%`;
  };

  const tooltipFormatter = (value: string | number, name: string) => {
    const isAutocallLine = name === AUTO_CALL_LEVEL_LINE_NAME;
    const isMaxReturnLine = name === MAX_RETURN_LINE_NAME;
    const noFormat = !isAutocallLine && !isMaxReturnLine;
    if (noFormat) return `${value}%`;
    else if (isMaxReturnLine) {
      const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
      return `${parsedValue.toFixed(2)}%`;
    } else if (typeof value === 'number') return `${(value + 100).toFixed(2)}%`;
    else return `${(parseFloat(value.replace('%', '')) + 100).toFixed(2)}%`;
  };

  return (
    <InstitutionalLineChart
      data={chartData}
      lines={filteredLines}
      loading={loading}
      referenceLines={chartReferences}
      yLabel={{ label: translations.common.performance, formatter: yLabelFormatter }}
      xLabel={translations.common.date}
      dataType="date"
      TooltipProps={{ formatter: tooltipFormatter }}
    />
  );
};
