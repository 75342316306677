import { useMemo } from 'react';

import type { TermsheetRepoConfigModel } from '@halo-common/models';
import { useUserInfoQuery } from '@halo-data-sources/queries';

import { ActionsColumn, columnsMap, getIdentifierColumn, TermsheetRepoColumnModel } from './columns';

export const useTermsheetsDataGridColumns = (
  config: TermsheetRepoConfigModel | null | undefined,
): TermsheetRepoColumnModel[] => {
  const { data: userInfo } = useUserInfoQuery();

  const columns = useMemo(() => {
    const columns: TermsheetRepoColumnModel[] = [];

    const { assetIdentifiers = [], primaryAssetIdentifier } = userInfo?.whiteLabel || {};

    const secondaryIdentifiers = assetIdentifiers.filter((identifier) => identifier !== primaryAssetIdentifier);

    config?.columns?.forEach((column) => {
      switch (column.type) {
        case 'primary_asset_identifier':
          if (primaryAssetIdentifier) {
            columns.push(getIdentifierColumn(primaryAssetIdentifier));
          }
          break;
        case 'secondary_asset_identifiers':
          columns.push(...secondaryIdentifiers.map(getIdentifierColumn));
          break;
        default:
          if (columnsMap[column.type]) {
            columns.push(columnsMap[column.type]);
          }
          break;
      }
    });

    columns.push(ActionsColumn);

    return columns;
  }, [config, userInfo]);

  return columns;
};
