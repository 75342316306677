import { ReactElement, SyntheticEvent } from 'react';

import { STATE_LIST } from '@halo-common/constants';
import { RegisterFormSchema } from '@halo-modules/app';
import { Autocomplete, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

type StateModel = {
  label: string;
  value: string;
};

export const StateSelect = (): ReactElement => {
  const {
    control,
    formState: { errors },
  } = useFormContext<RegisterFormSchema>();

  const stateError = errors?.state?.message;

  return (
    <Controller
      render={({ field: { onChange, value, ...field } }) => {
        const stateValue = STATE_LIST.find((state) => state.value === value);

        const selectHandler = (_: SyntheticEvent, data: string | StateModel | null) => {
          const selectedValue = typeof data === 'string' ? data : data?.value;
          const selectedState = STATE_LIST.find((state) => state.value === selectedValue);
          onChange(selectedState?.value);
        };

        return (
          <Autocomplete
            {...field}
            openOnFocus
            value={stateValue}
            options={STATE_LIST}
            onChange={selectHandler}
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                slotProps={{ htmlInput: inputProps }}
                inputRef={params.InputProps.ref}
                size="large"
                label="State"
                fullWidth
                helperText={stateError}
                error={Boolean(stateError)}
              />
            )}
          />
        );
      }}
      defaultValue={''}
      control={control}
      name="state"
    />
  );
};
