import { ReactElement, useEffect, useMemo, useState } from 'react';

import { auctionDefaultsQueryAtom, settlementDateAtom, strikeDateAtom, tradeDateAtom } from '@halo-atoms/auctions';
import { YEAR_MONTH_DAY_DATE_FORMAT } from '@halo-common/constants';
import { AuctionDateTypeEnum } from '@halo-common/enums';
import { useAuctionDateFormat } from '@halo-common/hooks';
import { useAuctionDateDefaultsQuery, useTradingDaysQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { Button, Stack, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { useAtom } from 'jotai';
import { DateTime } from 'luxon';

type AuctionDateType = 'strikeDate' | 'tradeDate' | 'settlementDate';

type DateInputs = Record<
  AuctionDateType,
  {
    title: string;
    description: string;
    date: DateTime | null;
    setDate: (date: DateTime | null) => void;
    dateType: AuctionDateTypeEnum;
    minDate?: DateTime;
    maxDate?: DateTime;
  }
>;

type DateButtons = Array<{
  id: AuctionDateType;
  label: string;
  enabled: boolean;
}>;

const textFieldStyling = {
  width: 300,
  input: {
    typography: 'h1',
  },
};

export const AuctionDateControls = (): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();

  const auctionDatePermissions = userInfo?.settings?.auctionDatePermissions;
  const canSetTradeDate = !!auctionDatePermissions?.canSetTradeDate;
  const canSetSettlementDate = !!auctionDatePermissions?.canSetSettlementDate;

  const [auctionDefaultsQuery, setAuctionDefaultsQuery] = useAtom(auctionDefaultsQueryAtom);
  const [strikeDate, setStrikeDate] = useAtom(strikeDateAtom);
  const [tradeDate, setTradeDate] = useAtom(tradeDateAtom);
  const [settlementDate, setSettlementDate] = useAtom(settlementDateAtom);

  const startDate = DateTime.now().startOf('day');
  const endDate = settlementDate ? settlementDate.plus({ year: 1, month: 3 }) : startDate.plus({ year: 1, month: 3 });
  const tradingDaysQuery = { endDate, startDate };

  const { data: tradingDays } = useTradingDaysQuery(tradingDaysQuery);
  const nonTradingDays = tradingDays?.nonTradingDays ?? [];

  const { data: auctionDefaults } = useAuctionDateDefaultsQuery(auctionDefaultsQuery);
  const defaultStrikeDate = auctionDefaults?.strikeDate;
  const defaultTradeDate = auctionDefaults?.tradeDate;
  const defaultSettlementDate = auctionDefaults?.settlementDate;

  const getValidMinDate = (date: DateTime) => {
    const weekday = date.weekday;
    if (weekday === 6) return date.plus({ day: 2 });
    if (weekday === 7) return date.plus({ day: 1 });
    return date;
  };

  const getValidMaxDate = (date: DateTime) => {
    const weekday = date.weekday;
    if (weekday === 6) return date.minus({ day: 1 });
    if (weekday === 7) return date.minus({ day: 2 });
    return date;
  };

  const defaultStrikeMinDate = DateTime.now().startOf('day');
  const strikeMinDate = getValidMinDate(defaultStrikeMinDate);

  const tradeMinDate = useMemo(() => {
    const newTradeMinDate = strikeDate || strikeMinDate.plus({ day: 1 });
    const validTradeMinDate = getValidMinDate(newTradeMinDate);
    return validTradeMinDate;
  }, [strikeDate]);

  const tradeMaxDate = useMemo(() => {
    const newTradeMaxDate = settlementDate?.minus({ day: 1 });
    const validTradeMaxDate = newTradeMaxDate ? getValidMaxDate(newTradeMaxDate) : undefined;
    return validTradeMaxDate;
  }, [settlementDate]);

  const settlementMinDate = useMemo(() => {
    const newSettlementMinDate = tradeDate ? tradeDate.plus({ day: 1 }) : tradeMinDate.plus({ day: 1 });
    const validSettlementMinDate = getValidMinDate(newSettlementMinDate);
    return validSettlementMinDate;
  }, [tradeDate]);

  const dateFormat = useAuctionDateFormat();

  const dateInputs: DateInputs = {
    strikeDate: {
      title: 'What date would you like to strike on?',
      description:
        'The date in which the underlying assets’ initial values are set. The Strike Date must either be prior to or in conjunction with the trade date.',
      date: strikeDate,
      setDate: setStrikeDate,
      minDate: strikeMinDate,
      dateType: AuctionDateTypeEnum.Strike,
    },
    tradeDate: {
      title: 'What date would you like to trade on?',
      description: 'The date in which the Note will be executed in the market.',
      date: tradeDate,
      setDate: setTradeDate,
      minDate: tradeMinDate,
      maxDate: tradeMaxDate,
      dateType: AuctionDateTypeEnum.Trade,
    },
    settlementDate: {
      title: 'What date would you like to settle on?',
      description:
        'The date in which the trade is final, and the buyer must make payment to the seller while the seller delivers assets to the buyer. [The Settlement Date must be after the Trade Date.]',
      date: settlementDate,
      setDate: setSettlementDate,
      minDate: settlementMinDate,
      dateType: AuctionDateTypeEnum.Settlement,
    },
  };

  const [dateButtons, setDateButtons] = useState<DateButtons>([
    {
      id: 'tradeDate',
      label: 'Change Trade Date?',
      enabled: canSetTradeDate,
    },
    {
      id: 'settlementDate',
      label: 'Change Settlement Date?',
      enabled: canSetSettlementDate,
    },
  ]);

  const [dateControlsToRender, setDateControlsToRender] = useState<AuctionDateType[]>(['strikeDate']);

  const shouldDisableDates = (date: DateTime) => {
    return nonTradingDays.some((day) => day === date.toFormat(YEAR_MONTH_DAY_DATE_FORMAT));
  };

  const onDateControlClicked = (id: AuctionDateType) => {
    setDateButtons((prev) => prev.filter((button) => id !== button.id));
    setDateControlsToRender((prev) => prev.concat(id));
  };

  useEffect(() => {
    if (defaultStrikeDate) setStrikeDate(DateTime.fromISO(defaultStrikeDate));
    if (defaultTradeDate) setTradeDate(DateTime.fromISO(defaultTradeDate));
    if (defaultSettlementDate) setSettlementDate(DateTime.fromISO(defaultSettlementDate));
  }, [defaultStrikeDate, defaultTradeDate, defaultSettlementDate]);

  return (
    <Stack direction="column" spacing={10}>
      {dateControlsToRender.map((id, index) => {
        const { title, description, date, setDate, minDate, maxDate, dateType } = dateInputs[id];
        const isLastInput = index === dateControlsToRender.length - 1;

        const buttonList = isLastInput ? (
          <Stack direction="row">
            {dateButtons
              .filter(({ enabled }) => enabled)
              .map(({ id, label }) => (
                <Button key={id} onClick={() => onDateControlClicked(id)}>
                  {label}
                </Button>
              ))}
          </Stack>
        ) : null;

        return (
          <Stack key={id} direction="column" spacing={4}>
            <Stack direction="column" spacing={2}>
              <Typography variant="body1" fontWeight="bold">
                {title}
              </Typography>
              <Typography variant="body1">{description}</Typography>
            </Stack>
            <Stack direction="column" spacing={4}>
              <DatePicker
                value={date}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(newValue) => {
                  setDate(newValue);
                  setAuctionDefaultsQuery({ date: newValue ?? DateTime.now().startOf('day'), dateType });
                }}
                shouldDisableDate={shouldDisableDates}
                slotProps={{
                  field: { clearable: true },
                  textField: {
                    variant: 'standard',
                    sx: textFieldStyling,
                    slotProps: { input: { readOnly: true } },
                  },
                }}
                format={dateFormat}
              />
              {buttonList}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};
