import { TabProps } from '@halodomination/halo-fe-common';
import { useRouter } from 'next/router';

export type UseOrderHubFiltersResult = {
  handleFilterChange: (index: number) => void;
  selectedTab: number;
  showAll: boolean;
  status: string;
};

export const useOrderHubFilters = (tabs: Array<TabProps>): UseOrderHubFiltersResult => {
  const router = useRouter();

  const { pathname, query } = router;
  const { status } = query;

  const isAllStatus = status === undefined;
  const selectedTab = isAllStatus
    ? -1
    : tabs.findIndex((tab) => tab.label.toLowerCase().replaceAll(' ', '-') === status) - 1;

  const handleFilterChange = (index: number) => {
    const queryParameters = `?status=${tabs[index].label.toLowerCase().replaceAll(' ', '-')}`;

    void router.push(`${pathname}${queryParameters}`, undefined, { shallow: true });
  };

  return {
    handleFilterChange,
    selectedTab,
    showAll: selectedTab <= -1,
    status: status as string,
  };
};
