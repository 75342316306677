import { ReactElement } from 'react';

import { ActionButton } from '@halo-common/components';
import { DocumentBucketModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { HttpValidationError } from '@halo-data-sources/errors';
import { useOnboardingConfigurationMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { MediaContentTabbedInputs, OnboardingSidebarPreview } from '@halo-modules/admin/content-management';
import { LocalizedTextField, LocalizedTypography, useSnackbar } from '@halodomination/halo-fe-common';
import { Divider, Paper, Stack } from '@mui/material';
import { FormProvider, useController, useForm } from 'react-hook-form';

const textInputSx = {
  width: 395,
};

const formContainerSx = {
  padding: 3,
};

const buttonSx = {
  width: 154,
};

const MAX_CHARACTERS_ALLOWED = 210;

const isValidURL = (value?: string) => {
  if (!value) return true;
  try {
    new URL(value);
    return true;
  } catch {
    return false;
  }
};

export type LoginPageManagementFormFields = {
  overline?: string;
  headline?: string;
  subtitle?: string;
  description?: string;
  image?: File | DocumentBucketModel | null;
  videoLink?: string;
  ctaText?: string;
  ctaLink?: string;
};

export const LoginPageManagementForm = (): ReactElement => {
  const { data: user } = useUserInfoQuery();

  const { enqueueErrorEvent, enqueueSuccessEvent } = useSnackbar();

  const { mutate: setOnboardingSettingSet, isPending } = useOnboardingConfigurationMutation({
    onSuccess: () => {
      enqueueSuccessEvent({
        message: translations.common.success,
        subMessage: translations.contentManagement.loginPageManagement.successfulUpdate,
      });
    },
    onError: (error: HttpValidationError) => {
      const message = error?.details?.files?.image?.[0] ?? translations.messages.error;

      enqueueErrorEvent({
        message: translations.common.error,
        subMessage: message,
      });
    },
  });

  const onboardingSetting = user?.settings?.onboarding;
  const overline = onboardingSetting?.overline ?? '';
  const headline = onboardingSetting?.title ?? '';
  const subtitle = onboardingSetting?.subtitle ?? '';
  const description = onboardingSetting?.body ?? '';
  const videoLink = onboardingSetting?.video ?? '';
  const image = onboardingSetting?.image as LoginPageManagementFormFields['image'];
  const ctaText = onboardingSetting?.buttonText ?? '';
  const ctaLink = onboardingSetting?.buttonLink ?? '';

  const formMethods = useForm<LoginPageManagementFormFields>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    defaultValues: {
      overline: '',
      headline: '',
      subtitle: '',
      description: '',
      videoLink: '',
      ctaText: '',
      ctaLink: '',
    },
    values: {
      overline,
      headline,
      subtitle,
      image,
      description,
      videoLink,
      ctaText,
      ctaLink,
    },
  });

  const { handleSubmit, control, getValues } = formMethods;

  const { field: overlineField } = useController({ control, name: 'overline' });
  const { field: headlineField } = useController({ control, name: 'headline' });
  const { field: subtitleField } = useController({ control, name: 'subtitle' });
  const { field: descriptionField } = useController({ control, name: 'description' });
  const { field: ctaTextField } = useController({ control, name: 'ctaText' });

  const { field: ctaLinkField, fieldState: ctaFieldState } = useController({
    control,
    name: 'ctaLink',
    rules: {
      validate: (value) => isValidURL(value) || translations.contentManagement.loginPageManagement.ctaInvalidLink,
    },
  });

  const descriptionHelperTextCharactersRemaining = MAX_CHARACTERS_ALLOWED - (getValues('description')?.length ?? 0);
  const descriptionHelperText = translations.contentManagement.loginPageManagement.maxCharacters;
  const descriptionHelperTextLocalization = {
    helperText: { characterRemaining: descriptionHelperTextCharactersRemaining },
  };

  const onSubmit = handleSubmit((data: LoginPageManagementFormFields) => {
    setOnboardingSettingSet(data);
  });

  return (
    <FormProvider {...formMethods}>
      <Stack direction="row" spacing={6}>
        <Paper variant="outlined" sx={formContainerSx}>
          <Stack direction="column" spacing={2}>
            <LocalizedTypography variant="h6">
              {translations.contentManagement.loginPageManagement.sidebarTitle}
            </LocalizedTypography>
            <Divider />
            <LocalizedTextField
              {...overlineField}
              sx={textInputSx}
              size="large"
              label={translations.contentManagement.loginPageManagement.overline}
            />
            <LocalizedTextField
              {...headlineField}
              sx={textInputSx}
              size="large"
              multiline
              label={translations.contentManagement.loginPageManagement.headline}
            />
            <LocalizedTextField
              {...subtitleField}
              size="large"
              sx={textInputSx}
              label={translations.contentManagement.loginPageManagement.subtitle}
            />
            <LocalizedTextField
              {...descriptionField}
              size="large"
              sx={textInputSx}
              slotProps={{ htmlInput: { maxLength: MAX_CHARACTERS_ALLOWED } }}
              multiline
              helperText={descriptionHelperText}
              dynamicContent={descriptionHelperTextLocalization}
              label={translations.contentManagement.loginPageManagement.description}
            />
            <MediaContentTabbedInputs />
            <LocalizedTextField
              {...ctaTextField}
              size="large"
              sx={textInputSx}
              label={translations.contentManagement.loginPageManagement.ctaText}
            />
            <LocalizedTextField
              {...ctaLinkField}
              size="large"
              sx={textInputSx}
              error={Boolean(ctaFieldState.error)}
              helperText={ctaFieldState.error?.message}
              label={translations.contentManagement.loginPageManagement.ctaLink}
            />
            <ActionButton loading={isPending} size="large" onClick={onSubmit} variant="contained" sx={buttonSx}>
              {translations.contentManagement.loginPageManagement.savePublish}
            </ActionButton>
          </Stack>
        </Paper>
        <OnboardingSidebarPreview />
      </Stack>
    </FormProvider>
  );
};
