import { ReactElement } from 'react';

import { useDetectEllipsis } from '@halo-common/hooks';
import {
  ConditionalWrapper,
  LocalizedTypography,
  LocalizedTypographyProps,
  Tooltip,
  TooltipProps,
} from '@halodomination/halo-fe-common';
import { tooltipClasses } from '@mui/material';
import type { Modifier, Obj } from '@popperjs/core';

const topLeftCorner: Modifier<'topLeftCorner', Obj> = {
  name: 'topLeftCorner',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.margin = `-${state.rects.reference.height}px 0px`;
  },
  effect: ({ state }) => {
    const reference = state.elements.reference as HTMLElement;
    state.elements.popper.style.margin = `-${reference.clientHeight}px 0px`;
  },
};

const sameWidth: Modifier<'sameWidth', Obj> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    const referenceWidth = state.rects.reference.width ?? 0;
    state.styles.popper.width = `${referenceWidth}px`;
  },
  effect: ({ state }) => {
    const reference = state.elements.reference as HTMLElement;
    state.elements.popper.style.width = `${reference.offsetWidth}px`;
  },
};

const ellipsisSx = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
};

const tooltipSx = {
  [`&.${tooltipClasses.popper}[data-popper-placement] .${tooltipClasses.tooltip}`]: {
    margin: '0',
    maxWidth: 'unset',
  },
};

const PopperProps = {
  modifiers: [topLeftCorner, sameWidth],
  sx: tooltipSx,
};

export type LocalizedTypographyWithTooltipProps = Omit<LocalizedTypographyProps, 'children' | 'ref'> & {
  children: string;
};

export const LocalizedTypographyWithTooltip = ({
  children,
  ...props
}: LocalizedTypographyWithTooltipProps): ReactElement => {
  const { ref, hasEllipsis } = useDetectEllipsis();

  return (
    <ConditionalWrapper<TooltipProps>
      placement="bottom-start"
      Wrapper={Tooltip}
      condition={hasEllipsis}
      title={children}
      PopperProps={PopperProps}
      arrow={false}
    >
      <LocalizedTypography sx={ellipsisSx} {...props} ref={ref}>
        {children}
      </LocalizedTypography>
    </ConditionalWrapper>
  );
};
