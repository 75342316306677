import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiAllAccountAdviseeModel,
  ApiCreateHouseholdResponseModel,
  ApiGetAccountProductRequestModel,
  ApiGetAccountsByAdviseeResponseModel,
  ApiGetPositionsByUserAccountResponseModel,
  ApiGetSortedAccountsRequestModel,
  ApiGetSortedAccountsResponseModel,
  ApiPostAccountRequestModel,
  ApiUpdateAccountRequestModel,
  ApiUpdateAccountResponseModel,
  ApiUserAccountsResponseModel,
} from '@halo-data-sources/models';

const USER_ACCOUNTS_PATH = '/issuer/v1/user/accounts';
const POST_TRADE_ACCOUNTS = '/posttrade/portfolio/accounts';

const COMMON_USER_ACCOUNTS_PATH = '/common/accounts';
const COMMON_ADVISEE_PATH = '/common/advisees';

export const getAccountsByAdvisee = async (queryParams: string): Promise<ApiGetAccountsByAdviseeResponseModel> => {
  const clientConfiguration = await getClientConfiguration(USER_ACCOUNTS_PATH);

  const path = `${clientConfiguration.basePath}/details${queryParams}`;

  const response = await request<ApiGetAccountsByAdviseeResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const getAccountPositions = async (queryParams?: string): Promise<ApiGetPositionsByUserAccountResponseModel> => {
  const clientConfiguration = await getClientConfiguration(USER_ACCOUNTS_PATH);

  const path = `${clientConfiguration.basePath}/positionsbytsid${queryParams}`;

  const response = await request<ApiGetPositionsByUserAccountResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const getHouseholds = async (): Promise<ApiAllAccountAdviseeModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_ADVISEE_PATH);

  const response = await request<ApiAllAccountAdviseeModel>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const postAccount = async (data: ApiPostAccountRequestModel): Promise<ApiUserAccountsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_USER_ACCOUNTS_PATH);

  const response = await request<ApiUserAccountsResponseModel>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      accounts: [
        {
          name: data.accountName,
          account_identifier: data.accountNumber,
          custodian_id: data.custodianId,
          owner_id: data?.ownerId,
        },
      ],
    }),
  });

  return response;
};

export const updateAccount = async (
  accountId: number,
  data: ApiUpdateAccountRequestModel,
): Promise<ApiUpdateAccountResponseModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_USER_ACCOUNTS_PATH);

  const url = `${clientConfiguration.basePath}/${encodeURIComponent(accountId)}`;

  const response = await request<ApiUpdateAccountResponseModel>(url, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      advisee_id: data.adviseeId,
      name: data.accountName,
    }),
  });

  return response;
};

export const createHousehold = async (householdName: string): Promise<ApiCreateHouseholdResponseModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_ADVISEE_PATH);

  const response = await request<ApiCreateHouseholdResponseModel>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      name: householdName,
    }),
  });

  return response;
};

export const getSortedAccounts = async (
  requestModel: ApiGetSortedAccountsRequestModel,
): Promise<ApiGetSortedAccountsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_USER_ACCOUNTS_PATH);

  const path = `${clientConfiguration.basePath}/details`;

  const response = await request<ApiGetSortedAccountsResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...requestModel,
    }),
  });

  return response;
};

export const getProductAccounts = async (
  requestModel: ApiGetAccountProductRequestModel,
): Promise<ApiGetSortedAccountsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_ACCOUNTS);

  const path = `${clientConfiguration.basePath}/details`;

  const response = await request<ApiGetSortedAccountsResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...requestModel,
    }),
  });

  return response;
};
