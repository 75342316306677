import { ReactElement } from 'react';

import { ExecutionAuctionReviewDetailModal } from '@halo-common/modals';
import { OrdersSelectors } from '@halo-stores/Orders';
import { useSelector } from 'react-redux';

export type AuctionNoteDetailsModalProps = {
  open: boolean;
  onClose: () => void;
};

export const AuctionNoteDetailsModal = ({ open, onClose }: AuctionNoteDetailsModalProps): ReactElement => {
  const auction = useSelector(OrdersSelectors.selectSelectedAuction);

  return <ExecutionAuctionReviewDetailModal product={auction} open={open} onClose={onClose} />;
};
