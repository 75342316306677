import { translations } from '@halo-common/translations';
import { InferType, boolean as yupBoolean, object as yupObject, string as yupString } from 'yup';

export const setPasswordFormSchema = yupObject().shape({
  confirmCurrentPassword: yupBoolean(),
  oldPassword: yupString().when('confirmCurrentPassword', {
    is: true,
    then: (schema) => schema.required(translations.onboarding.setPassword.oldPassword),
  }),
  password: yupString().required(translations.onboarding.setPassword.enterPassword),
  confirmPassword: yupString().required(translations.onboarding.setPassword.reenterPassword),
});

export interface SetPasswordFormFields extends InferType<typeof setPasswordFormSchema> {}
