import { downloadFile } from '@halo-common/utils';
import { getCalendarsTermsheetDownload } from '@halo-data-sources/clients';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { CalendarsQueryKeyFactory } from '../keys';

export type GetCalendarTermsheetDownloadPayloadModel = {
  contentDisposition: 'inline' | 'attachment' | '';
  calendarId?: number;
  fileName: string;
};

const getCalendarTermsheetDownloadQueryFn = async (payload: GetCalendarTermsheetDownloadPayloadModel) => {
  if (!payload.calendarId) return '';

  const response = await getCalendarsTermsheetDownload(payload);

  if (payload.contentDisposition === 'attachment') {
    downloadFile({ fileName: payload.fileName, downloadFilePath: response });
  }

  return response;
};

export const useCalendarTermsheetDownloadQuery = (
  payload: GetCalendarTermsheetDownloadPayloadModel,
): UseQueryResult<string, Error> =>
  useQuery({
    queryKey: CalendarsQueryKeyFactory.download(payload),
    queryFn: () => getCalendarTermsheetDownloadQueryFn(payload),
    enabled: Boolean(payload.contentDisposition),
  });
