import { postCalendarAdminOrderFiltersQuery } from '@halo-data-sources/clients';
import { ApiComparisonOptionEnum } from '@halo-data-sources/enums';
import { ApiQueryRequestFilterModel } from '@halo-data-sources/models';
import { ProductsQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

export type ProductByAssetModel = { id: number; assetId: string };
export type SearchForProductsByAssetIdQueryResult = Array<ProductByAssetModel>;

const calendarAssetSearchQueryFn = async (id: string, assetType: string) => {
  const assetId = 'calendar_notes.id';
  const assetField = `asset_identifiers.${assetType.toLowerCase()}`;

  const filters: Array<ApiQueryRequestFilterModel> = [
    {
      field: assetField,
      type: 'OBJECTS',
      object_fields: [assetId],
    },
  ];

  const comparisons = [
    {
      field: assetField,
      op: ApiComparisonOptionEnum.ILIKE,
      value: id,
    },
  ];

  const response = await postCalendarAdminOrderFiltersQuery({ filters, comparisons });

  return (
    response.filter_values[0]?.objects?.map(({ object }) => ({
      assetId: object[assetField] as string,
      id: object[assetId] as number,
    })) ?? []
  );
};

export const useCalendarAssetSearchQuery = (
  id: string,
  assetType: string,
): UseQueryResult<SearchForProductsByAssetIdQueryResult, Error> =>
  useQuery<SearchForProductsByAssetIdQueryResult, Error>({
    queryKey: ProductsQueryKeyFactory.byAssetId(id, assetType),
    queryFn: () => calendarAssetSearchQueryFn(id, assetType),
    placeholderData: keepPreviousData,
  });
