// TODO: This API is legacy. We need to migrate to a new one in the future.
// It was designed for AJAX requests with server-side rendering.
// Some of the props are not relevant anymore.

import { getClientConfiguration, request } from '@halo-data-sources/clients';
import type { ApiGetNoteflixCategoriesResult, ApiGetNoteflixNotesResult } from '@halo-data-sources/models';

const NOTEFLIX_PATH = '/issuer/v1/noteflix';

export const getNoteflixCategories = async (categories_from = 0): Promise<ApiGetNoteflixCategoriesResult> => {
  const clientConfiguration = await getClientConfiguration(NOTEFLIX_PATH);

  const queryParams = new URLSearchParams();
  queryParams.append('categories_from', categories_from.toString());

  const url = `${clientConfiguration.basePath}/ajax?${queryParams.toString()}`;

  const response = await request<ApiGetNoteflixCategoriesResult>(url, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const getNoteflixNotes = async (id: number, from = 0): Promise<ApiGetNoteflixNotesResult> => {
  const clientConfiguration = await getClientConfiguration(NOTEFLIX_PATH);

  const queryParams = new URLSearchParams();
  queryParams.append('category_id', id.toString());
  queryParams.append('notes_from', from.toString());

  const response = await request<ApiGetNoteflixNotesResult>(
    `${clientConfiguration.basePath}/ajax?${queryParams.toString()}`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};
