import { CSSProperties, ReactElement } from 'react';

import { useUserInfoQuery } from '@halo-data-sources/queries';
import { HaloLogo, useCombinedStyling } from '@halodomination/halo-fe-common';
import { Box, SxProps, Theme } from '@mui/material';

const logoSx = {
  marginTop: 6,
  marginLeft: 4,
  position: 'relative',
  zIndex: 'modal',
};

export type WhiteLabelLogoProps = {
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  sx?: SxProps<Theme>;
};

export const WhiteLabelLogo = ({ sx, ...props }: WhiteLabelLogoProps): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();

  const logoSrc = userInfo?.whiteLabel.brand?.logoLight?.path;

  const combinedLogoStyle = useCombinedStyling(logoSx, sx);

  return logoSrc ? (
    <Box sx={combinedLogoStyle} component="img" src={logoSrc} {...props} />
  ) : (
    <HaloLogo slotProps={{ root: { sx: combinedLogoStyle } }} />
  );
};
