import { ReactElement } from 'react';

import {
  EstimatedTransactionSection,
  OrderReviewCard,
  OrderReviewNoteListSection,
  OrderReviewSection,
} from '@halo-common/components';
import { HOUR_MINUTE_SECONDS_TIME_ZONE_FORMAT, MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { OrganizationCapacityEnum } from '@halo-common/enums';
import { useCalendarOrderNotes, useNoteDetailCaption, useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { ApproveOrderModalFields } from '@halo-common/modals';
import { AllocationModel, CalendarModel, FixOrderModel } from '@halo-common/models';
import { ModalLayout, ModalLayoutProps, Stack, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Alert, TextField, textFieldClasses, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';

import { ApproveCalendarOrderAlert } from './ApproveCalendarOrderAlert';

const reasonInputSx = { [`& ${textFieldClasses.root}`]: { backgroundColor: 'transparent' } };

export type ApproveCalendarOrderLayoutProps = Omit<ModalLayoutProps, 'ContentProps' | 'title'> & {
  allocation?: AllocationModel | null;
  calendar?: CalendarModel | null;
  fixOrder?: FixOrderModel;
  loading?: boolean;
  readOnly?: boolean;
  showReason?: boolean;
  error?: string;
};

// TODO: When Pershing is migrated to react query we need to rework this entire modal
export const ApproveCalendarOrderLayout = ({
  allocation,
  calendar,
  fixOrder,
  loading = false,
  readOnly = false,
  showReason = true,
  error,
  ...modalLayoutProps
}: ApproveCalendarOrderLayoutProps): ReactElement => {
  const configureDate = useWhiteLabelDateConfiguration();

  const { register, watch, formState } = useFormContext<ApproveOrderModalFields>();

  const { ref: reasonRef, ...reasonInputProps } = register('reason');
  const reasonError = formState?.errors?.reason?.message;

  const stipulations = useCalendarOrderNotes(fixOrder?.stipulations);

  const fixOrderNotes = stipulations ?? [];
  const calendarNote = calendar?.note;
  const calendarIssuer = calendar?.issuer;
  const calendarCategory = calendar?.category;

  const cusip = fixOrder?.cusip;
  const underlyings = calendarNote?.tradables?.map((tradeable) => tradeable.name).join(', ') ?? '';

  const orderDate = fixOrder?.orderReceiptTime
    ? configureDate(fixOrder.orderReceiptTime)
    : configureDate(DateTime.local().toISO());

  const formattedOrderDate = orderDate?.toFormat(
    `${MONTH_DAY_YEAR_DATE_FORMAT} ${HOUR_MINUTE_SECONDS_TIME_ZONE_FORMAT}`,
  );

  const lastUpdatedDate = allocation?.updatedOn
    ? configureDate(allocation.updatedOn)
    : configureDate(DateTime.local().toISO());

  const reasonFieldHeaderText = showReason ? 'Add Reason' : 'Reason';
  const title = `Buy ${fixOrder?.shares ?? 0} Bond(s) of ${underlyings}`;
  const subtitle = useNoteDetailCaption({
    note: calendarNote,
    issuer: calendarIssuer,
    category: calendarCategory,
  });

  const formattedQuantity = fixOrder?.shares ? parseInt(fixOrder?.shares) : undefined;
  const calendarPrice = calendar?.wholesaler?.salePrice ?? calendar?.note?.price ?? 0;
  const parsedCalendarPrice = parseFloat(calendarPrice.toFixed(3));
  const [offeringPrice] = useCurrencyConverter(parsedCalendarPrice, { currencyCode: 'USD' });

  const { capacity, commission, miscFees, shares } = fixOrder ?? {};

  const fixOrderCommission = commission ?? 0;
  const miscFeesSalesCredit = miscFees?.filter((fee) => fee.type === 'SALES_CREDIT') ?? [];

  const isCommission = Boolean(capacity && OrganizationCapacityEnum[capacity] === OrganizationCapacityEnum.AGENCY);
  const hasSalesCredit = Boolean(miscFeesSalesCredit.length && miscFeesSalesCredit[0].amount && shares);
  const hasReason = showReason || watch('reason');
  const showReasonSection = !readOnly && hasReason;

  const salesCredit = hasSalesCredit
    ? parseFloat(miscFeesSalesCredit[0].amount) * parseFloat(shares as string) * 10
    : 0;

  const miscFeesOther = miscFees?.filter((fee) => fee.type === 'OTHER') ?? [];
  const fixOrderFees = miscFeesOther.length ? parseFloat(miscFeesOther[0].amount) : 0;
  const fixOrderPrincipal = shares ? parseInt(shares) * 1000 : 0;

  const commissionOrSalesCredit = isCommission ? fixOrderCommission : salesCredit;
  const total = fixOrderPrincipal + (isCommission ? commissionOrSalesCredit : 0) + fixOrderFees;

  const ContentProps = { sx: { paddingTop: 3 } };

  const formErrorAlert = error ? (
    <Alert severity="error" variant="filled">
      {error}
    </Alert>
  ) : null;

  const reasonSection = showReasonSection ? (
    <Stack direction="column" spacing={1} xs={12}>
      <Typography variant="h6">{reasonFieldHeaderText}</Typography>
      <TextField
        {...reasonInputProps}
        disabled={!showReason}
        sx={reasonInputSx}
        inputRef={reasonRef}
        multiline
        helperText={reasonError}
        error={Boolean(reasonError)}
        placeholder="Please add a reason to deny."
        variant="standard"
        fullWidth
      />
    </Stack>
  ) : null;

  return (
    <ModalLayout {...modalLayoutProps} title={cusip} ContentProps={ContentProps}>
      <Stack direction="column" spacing={2}>
        {formErrorAlert}
        <ApproveCalendarOrderAlert loading={loading} allocation={allocation} date={lastUpdatedDate} />
        <Stack direction="row" spacing={4} xs={[8, 4]}>
          <OrderReviewCard title={title} subtitle={subtitle}>
            <OrderReviewSection header="Account">{allocation?.account.accountId}</OrderReviewSection>
            <OrderReviewSection header="Account Type">{fixOrder?.accountType}</OrderReviewSection>
            <OrderReviewSection header="CUSIP">{cusip}</OrderReviewSection>
            <OrderReviewSection header="Transaction">{fixOrder?.side}</OrderReviewSection>
            <OrderReviewSection header="Offering Price">{offeringPrice}</OrderReviewSection>
            <OrderReviewSection header="Solicited">{fixOrder?.solicited}</OrderReviewSection>
            <OrderReviewSection header="Discretion">{fixOrder?.discretionUsed}</OrderReviewSection>
            <OrderReviewSection header="Quantity (in Thousands)">{formattedQuantity}</OrderReviewSection>
            <OrderReviewSection header="Time Received">{formattedOrderDate}</OrderReviewSection>
            <OrderReviewSection header="Order Received From">{fixOrder?.orderReceiveFrom}</OrderReviewSection>
            <OrderReviewNoteListSection notes={fixOrderNotes} />
          </OrderReviewCard>
          <Stack direction="column" spacing={6} xs={12}>
            <EstimatedTransactionSection
              loading={loading}
              commission={isCommission}
              principal={fixOrderPrincipal}
              compensation={salesCredit}
              miscFees={fixOrderFees}
              total={total}
            />
            {reasonSection}
          </Stack>
        </Stack>
      </Stack>
    </ModalLayout>
  );
};
