import { ReactElement } from 'react';

import { usePrimaryAssetIdentifier } from '@halo-common/hooks';
import { CalendarModel } from '@halo-common/models';
import { Skeleton, Typography } from '@mui/material';

export type ExecutionCalendarDetailModalHeaderIdProps = {
  product?: CalendarModel | null;
  loading: boolean;
};

export const ExecutionCalendarDetailModalHeaderId = ({
  product,
  loading,
}: ExecutionCalendarDetailModalHeaderIdProps): ReactElement => {
  const { identifier, identifierText, fallback } = usePrimaryAssetIdentifier(product);

  if (loading) return <Skeleton variant="rounded" width={75} height={24} />;

  const primaryAssetId = identifier ? identifierText : fallback.identifierText;
  const productId = product?.id ? `ID# ${product.id}` : '';
  const idText = primaryAssetId ? `${primaryAssetId} | ${productId}` : productId;

  return (
    <Typography variant="overline" color="textSecondary">
      {idText}
    </Typography>
  );
};
