import { ReactElement } from 'react';

import { IssuerModel } from '@halo-common/models';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Box, Skeleton } from '@mui/material';

const logoImageSx = {
  backgroundColor: 'transparent',
  height: '25px',
  maxWidth: '100px',
};

const boxSx = {
  alignItems: 'center',
  display: 'flex',
};

export type FixedIncomeProductCardIssuerPropTypes = {
  issuer: IssuerModel;
  loading: boolean;
};

export const FixedIncomeProductCardIssuer = ({
  issuer,
  loading,
}: FixedIncomeProductCardIssuerPropTypes): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();

  const staticAssetUrl = userInfo?.configuration.staticAssetUrl;
  const issuerId = issuer.ergonomicId;
  const issuerLogoUrl = `${staticAssetUrl}/assets/images/svg/other/${issuerId}.svg`;

  const issuerContent = loading ? (
    <Skeleton width={150} height={40} variant="rounded" />
  ) : (
    <Box component="img" sx={logoImageSx} alt={issuerId} src={issuerLogoUrl} />
  );

  return <Box sx={boxSx}>{issuerContent}</Box>;
};
