import { ReactElement, useCallback } from 'react';

import { useModalState } from '@halo-common/hooks';
import { CONFIGURE_WIDGETS_MODAL_ID } from '@halo-common/modals';
import { translations } from '@halo-common/translations';
import { useDashboardConfigQuery } from '@halo-modules/app';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { ButtonProps } from '@mui/material';

export type OpenCustomizeWidgetsModalButtonProps = Partial<ButtonProps>;

export const OpenCustomizeWidgetsModalButton = ({
  ...props
}: OpenCustomizeWidgetsModalButtonProps): ReactElement | null => {
  const { widgetsAreHidable, widgetsAreReorderable } = useDashboardConfigQuery();
  const { setOpen } = useModalState(CONFIGURE_WIDGETS_MODAL_ID);

  const openModal = useCallback(() => setOpen(true), [setOpen]);

  if (!widgetsAreHidable && !widgetsAreReorderable) return null;

  return (
    <LocalizedButton
      size="large"
      color="secondary"
      variant="contained"
      className="wm-dashboard-customizeall"
      startIcon={<Iconography iconName="gear" color="inherit" />}
      onClick={openModal}
      {...props}
    >
      {translations.dashboard.common.customizeHome}
    </LocalizedButton>
  );
};
