export const PROFILE_PASSWORD_TRANSLATIONS = {
  password: 'Password',
  oldPassword: 'Old Password',
  confirmPassword: 'Confirm Password',
  error: `We weren’t able to update your password. Make sure your new password is unique, and isn’t the same as a previous password.`,
  passwordLength: '8 characters long',
  uppercase: 'Uppercase letter',
  lowercase: 'Lowercase letter',
  number: 'Numeric character',
  matching: 'Passwords must match',
  setPassword: 'Set your password',
  oldPasswordError: 'The old password you have entered is incorrect. Please enter your current password and try again.',
  successSetPassword: 'You have successfully updated your password',
  veryWeak: 'Very Weak',
  weak: 'Weak',
  medium: 'Medium',
  strong: 'Strong',
  veryStrong: 'Very Strong',
};
