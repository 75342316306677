import { ReactElement } from 'react';

import { ProductDetailTable } from '@halo-common/components';
import { V2CalendarCouponEvent } from '@halo-common/models';
import { GridColDef, HaloDataGridProps } from '@halodomination/halo-fe-common';

const commonColumnProps: Partial<GridColDef> = {
  align: 'left',
  maxWidth: 280,
  flex: 1,
};

export type ExecutionFixedIncomeDetailModalCouponTableProps = {
  events?: Array<V2CalendarCouponEvent>;
  loading?: boolean;
};

export const ExecutionFixedIncomeDetailModalCouponTable = ({
  events,
  loading = true,
}: ExecutionFixedIncomeDetailModalCouponTableProps): ReactElement => {
  const rows = events ?? [];

  const height = rows.length >= 16 ? 600 : 'auto';

  const showSubjectTo = events?.some(
    (event) => Boolean(typeof event.couponFloor === 'number') || Boolean(typeof event.couponCap === 'number'),
  );

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonColumnProps,
      field: 'month',
      headerName: 'period',
      valueFormatter: (value: string) => `${value} Months`,
    },
    {
      ...commonColumnProps,
      field: 'couponPct',
      headerName: 'coupon amount',
    },
  ];

  if (showSubjectTo) {
    columns.push({
      ...commonColumnProps,
      field: 'trigger',
      headerName: 'subject to',
      valueGetter: (_, row: V2CalendarCouponEvent) => {
        const hasCap = typeof row.couponCap === 'number';
        const hasFloor = typeof row.couponFloor === 'number';

        if (hasCap && hasFloor) {
          return `${row.couponFloor?.toFixed(2)}% Floor, ${row.couponCap?.toFixed(2)}% Cap per annum`;
        } else if (hasCap) {
          return `${row.couponCap?.toFixed(2)}% Cap per annum`;
        } else if (hasFloor) {
          return `${row.couponFloor?.toFixed(2)}% Floor per annum`;
        }

        return '--';
      },
    });
  }

  const maxWidth = columns.length * 280;
  const tableSx = { maxWidth };

  return (
    <ProductDetailTable<V2CalendarCouponEvent>
      sx={tableSx}
      height={height}
      columns={columns}
      rows={rows}
      loading={loading}
    />
  );
};
