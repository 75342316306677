import { ReactElement } from 'react';

import { OrderReviewSection } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { Stack } from '@halodomination/halo-fe-common';

export type OrderReviewNoteListSectionProps = {
  notes: Array<string>;
  loading?: boolean;
};

export const OrderReviewNoteListSection = ({
  notes,
  loading = false,
}: OrderReviewNoteListSectionProps): ReactElement => {
  const hasNoTicketNotes = notes === undefined || !notes?.length;

  return hasNoTicketNotes ? (
    <OrderReviewSection loading={loading} header="Notes">
      {translations.messages.noNotes}
    </OrderReviewSection>
  ) : (
    <Stack direction="column">
      {notes.map((text) => (
        <OrderReviewSection key={text} loading={loading} header="Notes">
          {text}
        </OrderReviewSection>
      ))}
    </Stack>
  );
};
