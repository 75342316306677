import { ReactElement, ReactNode, useState } from 'react';

import { selectedStrategyAtom } from '@halo-atoms/sma';
import { AddAccountModal } from '@halo-common/modals';
import { AccountModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import {
  SMAAllocationCustodianWarning,
  SMAAllocationModalFormModel,
  SMAAllocationOrderDetails,
  SMAAllocationRow,
  SMAAllocationTotalRow,
} from '@halo-modules/app';
import { Iconography, LocalizedButton, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Alert, Divider, Skeleton, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useFieldArray, useFormContext } from 'react-hook-form';

const topStackSx = { pb: 2 };
const contentStackSx = { py: 2 };
const allocationButtonStackSx = { pt: 2, pb: 5 };

export type SMAAllocationModalFormProps = {
  rules?: {
    maxNotional?: number | null;
    inventoryRemaining?: number | null;
  };
  checkboxLabel?: string;
  children?: ReactNode;
  loading?: boolean;
};

export const SMAAllocationModalForm = ({
  children,
  rules,
  checkboxLabel,
  loading = false,
}: SMAAllocationModalFormProps): ReactElement => {
  const selectedStrategy = useAtomValue(selectedStrategyAtom);
  const [accountCreationRowId, setAccountCreationRowId] = useState<number | undefined>();

  const { control, setValue, watch } = useFormContext<SMAAllocationModalFormModel>();
  const { append, fields, remove } = useFieldArray<SMAAllocationModalFormModel>({
    control,
    name: 'allocations',
    shouldUnregister: false,
    rules: { required: true, minLength: 1 },
  });

  const dividerSx = {
    py: checkboxLabel ? 0 : 1,
  };

  const allocations = watch('allocations');

  const allocationTotal = allocations.reduce((total: number, { amount }) => total + Number(amount), 0);

  const allocationsOverInventory =
    typeof rules?.inventoryRemaining === 'number' && allocationTotal > rules?.inventoryRemaining;

  const formattedInventoryRemaining = useCurrencyConverter(rules?.inventoryRemaining ?? 0);
  const errorText = `Allocated Notional exceeds the ${formattedInventoryRemaining} remaining inventory for this product. Please reduce allocations.`;

  const allocationsError = allocationsOverInventory ? (
    <Alert severity="error" variant="outlined">
      {errorText}
    </Alert>
  ) : null;

  const custodians =
    selectedStrategy && selectedStrategy.custodians.length > 0 ? (
      <SMAAllocationCustodianWarning custodians={selectedStrategy.custodians} />
    ) : null;

  if (loading) {
    return (
      <Stack direction="column" sx={topStackSx}>
        <Stack direction="column" sx={contentStackSx} spacing={2}>
          <Stack direction="column" spacing={3}>
            <Stack direction="row" spacing={2}>
              <Skeleton variant="rounded" width="100%" height={48} />
              <Skeleton variant="rounded" width="100%" height={48} />
              <Skeleton variant="rounded" width={32} height={32} />
            </Stack>
            <Divider />
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={allocationButtonStackSx}>
            <Skeleton variant="rounded" width={146} height={48} />
            <Skeleton variant="rounded" width={173} height={32} />
          </Stack>
        </Stack>
      </Stack>
    );
  }

  const rows = fields ?? [];
  const open = typeof accountCreationRowId === 'number';

  const handleAddAllocation = () =>
    void append({ accountOption: null, amount: null, checkbox: false }, { shouldFocus: false });
  const handleCreateAccountToggle = (id?: number) => void setAccountCreationRowId(id);
  const handleCreateAccount = (account?: AccountModel) => {
    const updateValue = open && account;
    if (updateValue) setValue(`allocations.${accountCreationRowId}.accountOption`, { account });
    handleCreateAccountToggle();
  };

  return (
    <Stack direction="column">
      <Stack direction="column">
        {allocationsError}
        {custodians}
        <Stack direction="column" spacing={1}>
          {rows.map((row, index) => (
            <SMAAllocationRow
              key={row.id}
              id={index}
              onDelete={remove}
              onCreateAccount={handleCreateAccountToggle}
              checkboxLabel={checkboxLabel}
            />
          ))}
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={allocationButtonStackSx}>
          <LocalizedButton
            variant="text"
            size="large"
            startIcon={<Iconography iconName="plus" />}
            onClick={handleAddAllocation}
          >
            {translations.allocationsModal.common.buttonAddAllocation}
          </LocalizedButton>
          <SMAAllocationTotalRow rules={rules} />
        </Stack>
        <SMAAllocationOrderDetails />
        <Divider sx={dividerSx} />
        {children}
      </Stack>
      <AddAccountModal
        onSuccess={handleCreateAccount}
        onClose={handleCreateAccountToggle}
        open={open}
        variant="simple"
      />
    </Stack>
  );
};
