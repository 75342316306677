import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { Iconography, LocalizedButton, Modal } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';

const continueButtonSx = { ml: 'auto' };

const suitability = translations.onboarding.suitability;

export type ConcurrentEditWarningModalProps = {
  open: boolean;
  onClose: () => void;
};

export const ConcurrentEditWarningModal = ({ open, onClose }: ConcurrentEditWarningModalProps): ReactElement => {
  const returnToDashboard = () => navigateParentTo('/user/dashboard');

  const footer = (
    <Stack direction="row">
      <LocalizedButton
        onClick={returnToDashboard}
        variant="text"
        size="large"
        startIcon={<Iconography iconName="arrow-left" />}
      >
        {translations.common.goBack}
      </LocalizedButton>
      <LocalizedButton variant="contained" size="large" color="primary" onClick={onClose} sx={continueButtonSx}>
        {translations.common.continue}
      </LocalizedButton>
    </Stack>
  );

  return (
    <Modal
      open={open}
      title={suitability.concurrentEditWarning.title}
      subtitle={suitability.concurrentEditWarning.subtitle}
      dismissible={false}
      footer={footer}
      size="small"
    />
  );
};
