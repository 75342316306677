// TODO: https://halodomination.atlassian.net/browse/HADT-17799
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */

import { ReactElement, ReactNode } from 'react';

import { LocalizedButton, LocalizedButtonProps } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { CircularProgress } from '@mui/material';

export type ActionButtonProps = LocalizedButtonProps & {
  loadingIndicator?: ReactNode;
  loading?: boolean;
};

export const ActionButton = ({
  children,
  loading = false,
  loadingIndicator,
  disabled = false,
  color,
  variant,
  sx,
  ...props
}: ActionButtonProps): ReactElement => {
  const progressStyling = (theme: HaloTheme) => {
    if (variant !== 'contained') return {};
    else if (color === 'secondary') return { color: theme.palette.secondary.contrastText };
    else return { color: theme.palette.primary.contrastText };
  };

  const actionButtonSx = { minWidth: 'fit-content', ...sx };
  const indicator = loadingIndicator ?? <CircularProgress size={20} sx={progressStyling} />;
  const content = loading ? indicator : children;

  const isDisabled = disabled || loading;

  return (
    <LocalizedButton {...props} sx={actionButtonSx} disabled={isDisabled} color={color} variant={variant}>
      {content}
    </LocalizedButton>
  );
};
