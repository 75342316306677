import { WatchlistDetailsProductModel } from '@halo-common/models';
import { getWatchlistTargetedNotes } from '@halo-data-sources/clients';
import { ApiWatchlistMapper } from '@halo-data-sources/mappers';
import { WatchlistQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const fetchWatchlistsQueryFn = async () => {
  const response = await getWatchlistTargetedNotes();

  return response.targets.map((target) => ApiWatchlistMapper.toWatchlistTargetedNoteModel(target));
};

export const useWatchlistTargetedNotesQuery = (): UseQueryResult<Array<WatchlistDetailsProductModel>, Error> =>
  useQuery<Array<WatchlistDetailsProductModel>, Error>({
    queryKey: WatchlistQueryKeyFactory.targets(),
    queryFn: () => fetchWatchlistsQueryFn(),
  });
