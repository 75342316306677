import { ChangeEvent, ReactElement } from 'react';

import { OrderTicketFormModelFields } from '@halo-common/modals';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Stack, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const adornmentSx = {
  alignItems: 'center',
  background: 'background.action',
  borderRadius: (theme: HaloTheme) => `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
  border: `1px solid rgba(0, 0, 0, 0.23)`,
  borderLeft: 0,
  display: 'flex',
  fontWeight: 'fontWeightBold',
  height: '100%',
  py: 0,
  px: 1,
};

const adornmentFieldSx = {
  borderRadius: (theme: HaloTheme) => `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
  '& > input::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& > input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& > input[type=number]': {
    '-moz-appearance': 'textfield',
  },
};

const ipMessageSx = {
  display: 'inline-block',
  width: '100%',
  textAlign: 'right',
};

export enum IpPercentFieldNameEnum {
  ip1Percent = 'ip1Percent',
  ip2Percent = 'ip2Percent',
}

export type IpPercentFieldNames = keyof typeof IpPercentFieldNameEnum;

export type IpFieldProps = {
  fieldName: 'ip1' | 'ip2';
};

export const IpField = ({ fieldName }: IpFieldProps): ReactElement => {
  const { register, setValue, control, formState } = useFormContext<OrderTicketFormModelFields>();
  const { errors } = formState;

  const ipError = errors?.[fieldName]?.message;
  const ipPercentError = errors?.ip1Percent?.message;
  const label = fieldName.toLocaleUpperCase();
  const name = `${fieldName.toLocaleLowerCase()}Percent` as IpPercentFieldNames;

  const { ref: ipRef, ...ipInputProps } = register(fieldName);

  const oppositeFieldName = {
    [IpPercentFieldNameEnum.ip1Percent]: IpPercentFieldNameEnum.ip2Percent,
    [IpPercentFieldNameEnum.ip2Percent]: IpPercentFieldNameEnum.ip1Percent,
  }[name] as IpPercentFieldNames;

  const handlePercentageUpdate = (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    callback: (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  ) => {
    const { name: fieldName, value } = ev.target;
    const parsedValue = parseInt(value);

    const ip1Max = fieldName === 'ip1Percent' && parsedValue === 100;
    const valueWithinRange = !ip1Max && parsedValue > 0 && parsedValue <= 100;
    const emptyString = value === '';

    if (emptyString) {
      callback(ev);
      setValue(oppositeFieldName, '', { shouldValidate: true, shouldDirty: true });
    } else if (ip1Max) {
      callback(ev);
      setValue('ip2Percent', '', { shouldValidate: true, shouldDirty: true });
    } else if (valueWithinRange) {
      const fieldValue = 100 - parsedValue;
      callback(ev);
      setValue(oppositeFieldName, fieldValue.toString(), { shouldValidate: true, shouldDirty: true });
    } else {
      ev.preventDefault();
    }
  };

  return (
    <Stack direction="column" spacing={0.5}>
      <Stack direction="row" spacing={1}>
        <TextField
          {...ipInputProps}
          inputRef={ipRef}
          label={label}
          fullWidth
          size="large"
          slotProps={{ htmlInput: { maxLength: 4 } }}
          helperText={ipError}
          error={Boolean(ipError)}
        />
        <Stack direction="row">
          <Controller
            render={({ field: { ref, onChange, ...field } }) => (
              <TextField
                {...field}
                inputRef={ref}
                onChange={(ev) => handlePercentageUpdate(ev, onChange)}
                type="number"
                fullWidth
                size="large"
                slotProps={{ input: { sx: adornmentFieldSx } }}
                error={Boolean(ipPercentError)}
              />
            )}
            control={control}
            name={name}
          />
          <Typography sx={adornmentSx}>%</Typography>
        </Stack>
      </Stack>
      <Typography variant="caption" sx={ipMessageSx}>
        Total must equal 100%
      </Typography>
    </Stack>
  );
};
