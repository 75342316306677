import { ReactElement } from 'react';

import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { ProductTypeChip } from '@halo-common/components';
import { COMBINED_DATE_TIME_ZONE_FORMAT } from '@halo-common/constants';
import { ProductTypeEnum } from '@halo-common/enums';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { GridPaginationModel, NoteModel, OrderBookCalendarModel } from '@halo-common/models';
import { useOrderBookCalendarsQuery } from '@halo-data-sources/queries';
import {
  OrderBookCalendarsAllocationButton,
  OrderBookCalendarsStatus,
  OrderBookCalendarsViewOrderDetailsButton,
} from '@halo-modules/app';
import { HaloDataGrid, HaloDataGridProps, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Box } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { useAtom } from 'jotai';

const actionCellSx = {
  display: 'flex',
  alignItems: 'center',
  flex: '50%',
  '& :first-child': { mr: 1 },
  '& button': { maxWidth: 90 },
};

export const OrderBookCalendarsTable = (): ReactElement => {
  const configureDateTime = useWhiteLabelDateConfiguration();

  const [searchFields, setSearchFields] = useAtom(orderBookQueryAtom);

  const { isFetching, data } = useOrderBookCalendarsQuery(searchFields);

  const rows = data?.calendars ?? [];
  const page = data?.pagination?.page ?? searchFields.page;
  const pageSize = data?.pagination?.resultsPerPage ?? searchFields.pageSize;
  const totalResults = data?.pagination?.totalResults ?? 0;
  const paginationModel = { page: page - 1, pageSize };

  const formatCurrency = (value: number) => useCurrencyConverter(value);

  const handlePaginationChange = (model: GridPaginationModel) => {
    if (!isFetching) {
      setSearchFields({
        page: model.page + 1,
        pageSize: model.pageSize,
      });
    }
  };

  const handleSortChange = (model: GridSortModel) => {
    setSearchFields({
      sortBy: model,
    });
  };

  const commonCellProps = {
    editable: false,
    disableColumnMenu: true,
    disableReorder: true,
    flex: 1,
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      field: 'noteType',
      headerName: 'Note Type',
      renderCell: ({ value }) => {
        const note = { type: value, productType: value } as NoteModel;
        return <ProductTypeChip product={note} type={ProductTypeEnum.note} />;
      },
    },
    {
      ...commonCellProps,
      field: 'underlying',
      headerName: 'Underlying',
      sortable: false,
      valueFormatter: (value: OrderBookCalendarModel['underlying']) => {
        return value?.join(', ');
      },
    },
    {
      ...commonCellProps,
      field: 'payoff',
      headerName: 'Payoff Value',
      sortable: false,
      valueGetter: (value: OrderBookCalendarModel['payoff']) => {
        return value?.payoffString;
      },
    },
    {
      ...commonCellProps,
      field: 'cusip',
      headerName: 'CUSIP',
      valueGetter: (value: OrderBookCalendarModel['cusip']) => {
        return value || 'N/A';
      },
      renderCell: ({ row, value }) => {
        return <OrderBookCalendarsViewOrderDetailsButton cusip={value} calendarId={row.id} />;
      },
    },
    {
      ...commonCellProps,
      field: 'notional',
      headerName: 'Notional',
      valueFormatter: (value: OrderBookCalendarModel['notional']) => {
        return formatCurrency(value);
      },
    },
    {
      ...commonCellProps,
      field: 'closes',
      headerName: 'Closes',
      sortable: false,
      valueFormatter: (value: OrderBookCalendarModel['closes']) => {
        const closeDateTime = configureDateTime(value);
        return closeDateTime?.toFormat(COMBINED_DATE_TIME_ZONE_FORMAT);
      },
      flex: 1.5,
    },
    {
      ...commonCellProps,
      field: 'status',
      headerName: 'Status',
      sortable: false,
      renderCell: ({ value }) => {
        return <OrderBookCalendarsStatus value={value} />;
      },
    },
    {
      ...commonCellProps,
      field: 'action',
      headerName: '',
      resizable: false,
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <Box sx={actionCellSx}>
          <OrderBookCalendarsAllocationButton calendar={row} />
        </Box>
      ),
    },
  ];

  return (
    <HaloDataGrid
      columns={columns}
      rows={rows}
      height="fill"
      loading={isFetching}
      pagination
      paginationMode="server"
      sortingMode="server"
      density="compact"
      pageSizeOptions={[1]}
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationChange}
      onSortModelChange={handleSortChange}
      rowCount={totalResults}
      disableRowSelectionOnClick
    />
  );
};
