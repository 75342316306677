import { DEFAULT_INFINITE_QUERY_NEXT_PAGINATION } from '@halo-common/constants';
import { getUnderlyingSearch } from '@halo-data-sources/clients';
import { ApiUnderlyingMapper, PaginationMapper } from '@halo-data-sources/mappers';
import { GetUnderlyingInfiniteQueryDataSwitchResult } from '@halo-data-sources/queries';

export const useUnderlyingInfiniteQueryCommonFn = async (
  search: string,
  pageParam = DEFAULT_INFINITE_QUERY_NEXT_PAGINATION,
): Promise<GetUnderlyingInfiniteQueryDataSwitchResult> => {
  const response = await getUnderlyingSearch({
    search_string: search,
    page: pageParam.next,
    page_length: 10,
  });

  const mappedUnderlyings = response.tradables.map(ApiUnderlyingMapper.toUnderlyingModel);

  const mappedPagination = PaginationMapper.toInfinitePaginationModel(response.pagination);

  return { underlyings: mappedUnderlyings, pagination: mappedPagination };
};
