import { getOnboardingDocusignFormData } from '@halo-data-sources/clients';
import { HttpError } from '@halo-data-sources/errors';
import { ApiOnboardingDocusignFormDataResponseModel } from '@halo-data-sources/models/Docusign/DocusignResponses';
import { DocusignQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

export type UseOnboardingDocusignFormDataQueryOptions = UseQueryOptions<
  ApiOnboardingDocusignFormDataResponseModel,
  Error
>;

export type UseOnboardingDocusignFormDataQueryResult = UseQueryResult<
  ApiOnboardingDocusignFormDataResponseModel,
  Error
>;

const getOnboardingDocusignFormDataFn = async (): Promise<ApiOnboardingDocusignFormDataResponseModel> => {
  try {
    return await getOnboardingDocusignFormData();
  } catch (error) {
    if (error instanceof HttpError) {
      const shouldReturnDefaultData = error.status === 404 || error.status === 500;
      if (shouldReturnDefaultData) return { json_data: null, created_at: null, updated_at: null };
    }

    throw error;
  }
};

export const useOnboardingDocusignFormDataQuery = (
  options?: Partial<UseOnboardingDocusignFormDataQueryOptions>,
): UseOnboardingDocusignFormDataQueryResult =>
  useQuery({
    queryKey: DocusignQueryKeyFactory.formData(),
    queryFn: getOnboardingDocusignFormDataFn,
    staleTime: 20000,
    ...options,
  });
