import { ReactElement } from 'react';

import { Iconography, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Chip, Tooltip } from '@mui/material';

const chipSx = {
  backgroundColor: 'success.background',
  color: 'success.main',
};

const iconographySx = {
  display: 'block',
};

export const QueuedTooltip = (): ReactElement => {
  const labelComponent = (
    <Stack direction="row" alignItems="center">
      <LocalizedTypography variant="body1">Queued&nbsp;</LocalizedTypography>
      <Tooltip
        placement="top"
        title="This asset wasn't found in our database, it will take up to 48 hours for the termsheet to show up in your portfolio."
      >
        <Iconography sx={iconographySx} prefix="fal" iconName="info-circle" color="success.main" />
      </Tooltip>
    </Stack>
  );

  return <Chip sx={chipSx} label={labelComponent} />;
};
