export const LOGIN_MANAGEMENT_TRANSLATIONS = {
  subNavTitle: 'Log In Page',
  sidebarTitle: 'Sidebar',
  savePublish: 'Save & Publish',
  overline: 'Overline',
  headline: 'Headline',
  subtitle: 'Subtitle',
  description: 'Description',
  videoLink: 'Video Link',
  image: 'Image',
  ctaText: 'CTA Text',
  ctaLink: 'CTA Link',
  maxCharacters: 'Max Characters Remaining: {characterRemaining}',
  successfulUpdate: 'Successfully updated onboarding setting set.',
  ctaInvalidLink: 'Invalid link.',
  acceptedFileTypes: 'Accepted File Types: {fileTypes}',
  errorCtaValidation: 'Either both or neither of CTA Text and CTA Link must be filled',
};
