import { AllocationModel } from '@halo-common/models';
import { postMessageService } from '@halo-common/utils';
import {
  getAdminCalendarPages,
  getAdminIssuersByCalendarPage,
  getCalendar,
  getCalendarAllocations,
  postCalendarAllocationRequest,
  // TODO: Add back in (removing the other function) when HADT-18520 is ready to be worked on
  // putCalendarOfferingDoc,
  putAdminCalendarFile,
  putCalendarAllocationRequest,
  submitCalendarAllocationRequest,
} from '@halo-data-sources/clients';
import { ApiAllocationMapper, ApiCalendarsMapper, CalendarsMapper } from '@halo-data-sources/mappers';
import { ApiCreateCalendarAllocationRequestModel } from '@halo-data-sources/models';
import {
  ApiUpdateCalendarAllocationThunkAction,
  CreateCalendarAllocationResponse,
  FetchAdminCalendarPagesThunkResult,
  FetchCalendarAllocationsThunkAction,
  FetchCalendarAllocationsThunkResult,
  FetchCalendarPageIssuersThunkAction,
  FetchCalendarPageIssuersThunkResult,
  FetchCalendarThunkResult,
  UploadCalendarThunkAction,
} from '@halo-stores/Calendar';
import { createThunk } from '@halo-stores/createThunk';

export const fetchCalendar = createThunk<FetchCalendarThunkResult, string>('calendar/get', async (calendarId) => {
  const result = await getCalendar(calendarId);

  return { calendar: ApiCalendarsMapper.toCalendarModel(result.calendar) };
});

export const fetchAdminCalendarPages = createThunk<FetchAdminCalendarPagesThunkResult, void>(
  'calendar/pages/get',
  async () => {
    const result = await getAdminCalendarPages();

    const pages = result.pages.map(ApiCalendarsMapper.toCalendarPageModel);
    const parentChildPages = CalendarsMapper.toParentPages(pages);

    return { pages, parentChildPages };
  },
);

export const uploadCalendar = createThunk<void, UploadCalendarThunkAction>('calendar/file/upload', async ({ file }) => {
  // TODO: Add back in (removing the other function) when HADT-18520 is ready to be worked on
  // await putCalendarOfferingDoc(file);
  await putAdminCalendarFile(file);
});

export const fetchCalendarPageIssuers = createThunk<
  FetchCalendarPageIssuersThunkResult,
  FetchCalendarPageIssuersThunkAction
>('calendar/page/issuers/get', async ({ calendarPageId, params }) => {
  const query = CalendarsMapper.toApiGetAdminIssuersByCalendarPageParamString(params);

  const id = calendarPageId?.toString() ?? '';
  const result = await getAdminIssuersByCalendarPage(id, query);

  const issuers = result.issuers.map((issuer) => ({ id: issuer[0], name: issuer[1] }));

  return { issuers };
});

export const fetchCalendarAllocations = createThunk<
  FetchCalendarAllocationsThunkResult,
  FetchCalendarAllocationsThunkAction
>(`calendar/allocations/get`, async (request) => {
  const response = await getCalendarAllocations(request.calendarId);

  const allocations = response.calendar.allocations.map((model) => ApiAllocationMapper.toAllocationModel(model));

  return { allocations };
});

export const createCalendarAllocationRequest = createThunk<
  CreateCalendarAllocationResponse,
  ApiCreateCalendarAllocationRequestModel
>(`calendar/allocations/create`, async (request) => {
  const createResponse = await postCalendarAllocationRequest(request);

  const newStatusResponse = await submitCalendarAllocationRequest(
    createResponse.calendar.id,
    createResponse.allocations,
  );

  const pendingAllocations = createResponse.allocations.map((allocation) => {
    return {
      ...allocation,
      status: 'pending',
    };
  });

  const pendingStatusResponse = await submitCalendarAllocationRequest(
    newStatusResponse.calendar.id,
    pendingAllocations,
  );

  const mappedResponse = ApiCalendarsMapper.toCalendarModel(pendingStatusResponse.calendar);

  postMessageService(window.parent).publish({
    type: 'CALENDAR_ORDER_SUBMISSION_SUCCESS',
    payload: { calendarId: Number(request.calendar_id) },
  });

  return { calendar: mappedResponse };
});

export const updateCalendarAllocationRequest = createThunk<
  Array<AllocationModel>,
  ApiUpdateCalendarAllocationThunkAction
>('calendar/allocations/put', async (request, { getState }) => {
  const { Calendar } = getState();
  const { selectedCalendar } = Calendar;

  const response = await putCalendarAllocationRequest(request.id, request.status, request.amount);

  const newAllocation = ApiAllocationMapper.toAllocationModel(response.allocation);

  const updatedAllocations = [...(selectedCalendar?.allocations ?? [])];

  const index = updatedAllocations.findIndex((allocation) => allocation.id === newAllocation.id);

  updatedAllocations.splice(index, 1, newAllocation);

  return updatedAllocations;
});
