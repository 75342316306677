import { ReactElement } from 'react';

import { portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import { ActionButton } from '@halo-common/components';
import { YEAR_MONTH_DAY_DATE_FORMAT } from '@halo-common/constants';
import { translations } from '@halo-common/translations';
import { useGeneratePortfolioReportMutation, useRecordActivityMutation } from '@halo-data-sources/mutations';
import { usePortfolioPositionAggregationQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { useIsAccountTypeAheadSyncedOption } from '@halo-modules/app';
import { Iconography } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';
import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';

export type PortfolioPositionsExcelExportButtonProps = {
  loading?: boolean;
};

export const PortfolioPositionsExcelExportButton = ({
  loading = false,
}: PortfolioPositionsExcelExportButtonProps): ReactElement => {
  const { filters, query } = useAtomValue(portfolioPositionsManagerAtom);

  const { isPending: isPositionPageLoaded } = usePortfolioPositionAggregationQuery(query);

  const { mutate: recordActivity } = useRecordActivityMutation();
  const { mutate: downloadPortfolioExcel, isPending } = useGeneratePortfolioReportMutation();

  const { accountOption, ...otherFilters } = filters;

  const accountId = accountOption?.account?.id;
  const householdId = accountOption?.household?.id;
  const action = accountOption?.action;

  const { data: user } = useUserInfoQuery();

  const userName = user?.details?.userName;

  const isSyncedOption = useIsAccountTypeAheadSyncedOption(action);

  const handleDownload = () => {
    const isFilteredByHousehold = typeof householdId === 'number';
    const isFilteredByAccount = typeof accountId === 'number';

    let accountOrAdviseeName = action?.replace(' ', '').toUpperCase() ?? 'ALLACCOUNTS';
    if (isFilteredByHousehold) accountOrAdviseeName = accountOption?.household?.name ?? '';
    else if (isFilteredByAccount) accountOrAdviseeName = accountOption?.account?.name ?? '';

    const todaysDate = DateTime.now().toFormat(YEAR_MONTH_DAY_DATE_FORMAT);

    const fileName = `${userName?.toUpperCase()}_${accountOrAdviseeName}_${todaysDate}.xlsx`;
    const cleanFileName = fileName.replace(/[|&;$%@"<>()+,/:?*]/g, '-');
    const payload = { ...otherFilters, accountId, householdId, synced: isSyncedOption, fileName: cleanFileName };

    const verb = `downloaded xlsx for ${accountOrAdviseeName}`;

    recordActivity({ activity: [{ verb, model_name: 'Portfolio' }], fromIFrame: false });

    downloadPortfolioExcel(payload);
  };

  const iconDisabled = isPending || loading;
  const iconColor = iconDisabled ? 'text.disabled' : 'primary.main';
  const downloadIcon = <Iconography iconName="download" color={iconColor} />;

  return isPositionPageLoaded ? (
    <Skeleton width={135} height={40} variant="rounded" />
  ) : (
    <ActionButton
      disabled={loading}
      variant="outlined"
      onClick={handleDownload}
      startIcon={downloadIcon}
      loading={isPending}
    >
      {translations.common.excelExport}
    </ActionButton>
  );
};
