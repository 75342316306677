import { ReactElement } from 'react';

import { Box, Divider } from '@mui/material';

const containerSx = { display: 'inline-block', py: 3, px: 0, width: '100%', height: 'auto' };
const dividerSx = { color: 'grey.300' };

export const EducationDivider = (): ReactElement => (
  <Box sx={containerSx}>
    <Divider sx={dividerSx}></Divider>
  </Box>
);
