import { ReactElement } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { CollapsibleFilters, GroupedCheckbox } from '@halo-common/components';
import { DynamicTypeFiltersSideBarTitlesDict } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { useAtom } from 'jotai';

export type MyOfferingFiltersProps = {
  tag: string;
};

export const MyOfferingFilters = ({ tag }: MyOfferingFiltersProps): ReactElement => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);

  const handleCheckboxChange = (value: string) => {
    setSideBarFilters({ tag, checkbox: value });
  };

  const myOfferingCheckboxes = [
    {
      isChecked: sideBarFilters.checkboxes.starred,
      value: 'starred',
      name: translations.dynamicFilters.common.starred,
    },
    {
      isChecked: sideBarFilters.checkboxes.submittedIndication,
      value: 'submittedIndication',
      name: translations.dynamicFilters.common.submittedIndication,
    },
  ];

  return (
    <CollapsibleFilters title={DynamicTypeFiltersSideBarTitlesDict.myOffering}>
      <GroupedCheckbox onChange={handleCheckboxChange} values={myOfferingCheckboxes} />
    </CollapsibleFilters>
  );
};
