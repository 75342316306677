import { ReactElement, useContext, useEffect, useState } from 'react';

import { AuctionAdminModel } from '@halo-common/models';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { TackOnAlert, TackOnAuctionDetails, TackOnContext, TackOnForm, TackOnHeader } from '@halo-modules/admin';
import { OrdersSelectors } from '@halo-stores/Orders';
import { Iconography, IconographyColors, Modal, Stack } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Button, Divider, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const deleteButtonSx = {
  border: '1px solid',
  borderColor: 'error.dark',
  color: 'error.dark',
};

const fullWidthSx = (theme: HaloTheme) => ({
  width: `calc(100% - ${theme.spacing(3)}px)`,
});

export type EditTackOnProps = {
  open: boolean;
  auction: AuctionAdminModel | null | undefined;
  onNext: () => void;
  onClose: () => void;
  onSubmit: () => void;
};

export const EditTackOn = ({ open, auction, onNext, onClose, onSubmit }: EditTackOnProps): ReactElement => {
  const [errorStatus, setErrorStatus] = useState<string | undefined>(undefined);
  const error = useSelector(OrdersSelectors.selectError);

  const { data: userInfo } = useUserInfoQuery();

  const issuers = userInfo?.whiteLabel.issuers ?? [];
  const overline = auction?.id ? `Auction ${auction.id}` : '';

  const { startShowingDate, startShowingTime, expirationDate, expirationTime } = useContext(TackOnContext);

  const { formState } = useFormContext();
  const { isValid } = formState;

  const disabled = !isValid || !startShowingDate || !startShowingTime || !expirationDate || !expirationTime;

  const headerProps = {
    AdditionalContent: <TackOnHeader note={auction?.note} />,
  };

  const errorMessage = errorStatus ? (
    <TackOnAlert>
      <Typography>Tack On Creation was not completed due to an error.</Typography>
      <Typography>{errorStatus}</Typography>
    </TackOnAlert>
  ) : null;

  const iconColor: IconographyColors = disabled ? 'text.disabled' : 'common.white';

  useEffect(() => {
    setErrorStatus(error);
  }, [error]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="large"
      overline={overline}
      title="Edit Tack On Opportunity"
      HeaderProps={headerProps}
      footer={
        <Stack direction="row" justify="flex-end">
          <Stack direction="row" justify="space-between" spacing={2}>
            <Button
              sx={deleteButtonSx}
              onClick={onNext}
              variant="outlined"
              startIcon={<Iconography iconName="trash" color="error.dark" />}
            >
              Delete Tack On
            </Button>
            <Button
              disabled={disabled}
              onClick={onSubmit}
              variant="contained"
              color="primary"
              startIcon={<Iconography iconName="check" color={iconColor} />}
            >
              Save Tack On Edits
            </Button>
          </Stack>
        </Stack>
      }
    >
      <Stack direction="column" spacing={2}>
        <TackOnAuctionDetails auction={auction} note={auction?.note} />
        <Divider sx={fullWidthSx} />
        {errorMessage}
        <TackOnForm auction={auction} issuers={issuers} />
      </Stack>
    </Modal>
  );
};
