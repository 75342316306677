import type { Schema } from '../types';

export const fallbackPerson = {
  crd_number: null,
  date_of_birth: null,
  name: null,
  position: null,
};

export const fallbackContact = {
  email_address: null,
  name: null,
  phone_number: null,
  position: null,
};

export const fallbackAddress = {
  apt_no: null,
  city: null,
  country: 'United States',
  state: null,
  street: null,
  zip_code: null,
};

export const fallbackValues: Schema = {
  compliance: {
    aml_policies_radio: null,
    compliance_type_radio: null,
    compliance_type_outsourced_text: null,
    kyc_aml_delegation_radio: null,
    sanctions_radio: null,
    sanctions_yes_text: null,
    suitability_assessment_radio: null,
    training_radio: null,
  },
  firm_details: {
    authorized_persons: {
      authorized_signer: {
        ...fallbackPerson,
        email_address: null,
      },
      authorized_person_2: fallbackPerson,
      authorized_person_3: fallbackPerson,
      authorized_person_4: fallbackPerson,
    },
    entity_type_checkbox_broker: null,
    entity_type_checkbox_family_office: null,
    entity_type_checkbox_bank: null,
    entity_type_bank_type_radio: null,
    entity_type_checkbox_ria: null,
    experience_years_radio: null,
    is_firm_regulated_radio: null,
    legal_name: null,
    mailing_address: fallbackAddress,
    permanent_address: fallbackAddress,
    previous_name: null,
    principal_contacts: {
      contact_1: fallbackContact,
      contact_2: fallbackContact,
    },
    registration_number: null,
    regulator_radio: null,
    regulator_yes_other_text: null,
    structure_checkbox_c_corp: null,
    structure_checkbox_llc: null,
    structure_checkbox_other: null,
    structure_other_text: null,
    structure_checkbox_partnership: null,
    structure_checkbox_s_corp: null,
    structure_checkbox_trust: null,
    tax_id_or_ssn: null,
  },
  trading_details: {
    completion_with_rvp_radio: null,
    delivery_process_checkbox_prime_brokerage: null,
    delivery_process_checkbox_dvp_rvp: null,
    delivery_process_text: null,
    disable_paper_confirmations_checkbox: true,
    disable_paper_statements_checkbox: true,
    performance_review_radio: null,
    product_approval_process_radio: null,
  },
};
