import { MouseEvent, ReactElement, useEffect, useState } from 'react';

import { anchorElAtom } from '@halo-atoms/common';
import {
  addPositionToMultipleAccountModalAtom,
  assetIdSearchValueAtom,
  portfolioFiltersAtom,
  portfolioPositionPopoverAtom,
} from '@halo-atoms/portfolio';
import { SortModelDirectionEnum, SortModelNullSortEnum, UserRoleEnum } from '@halo-common/enums';
import { SortModel } from '@halo-common/models';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { PortfolioPositionsDownloadPayload } from '@halo-data-sources/mutations';
import { usePostTradeAllocationsQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import {
  GridColumnResizeParams,
  GridSortModel,
  HaloDataGrid,
  HaloDataGridProps,
  Iconography,
  LocalizedButton,
  LocalizedTypography,
  mapNumberToLocalCurrency,
} from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { DeletePositionPopover, DeletePositionPopoverAnchor } from './DeletePositionPopover';
import { DownloadSpreadsheetButton } from './DownloadSpreadsheetButton';
import { EditPositionPopover, EditPositionPopoverAnchor } from './EditPositionPopover';

const buttonSx = {
  px: 1,
  minWidth: 'auto',
};

const sortFieldDictionary: Record<string, string> = {
  advisee_name: 'advisee_name',
  composite_name: 'composite_name',
  notional: 'notional',
};

const DEFAULT_SORT = [
  {
    field: 'advisee_name',
    direction: SortModelDirectionEnum.asc,
    nulls: SortModelNullSortEnum.first,
  },
];

const DEFAULT_COLUMN_WIDTH_DICTIONARY = {
  advisee_name: undefined,
  composite_name: undefined,
  notional: undefined,
  actions: undefined,
};

export type PostTradeNoteDetailModalAllocationsTabProps = {
  identifier: string;
  termsheetId?: number;
  currencyCode?: string;
  currencySymbol?: string;
};

export const PostTradeNoteDetailModalAllocationsTab = ({
  identifier,
  termsheetId,
  currencyCode,
  currencySymbol,
}: PostTradeNoteDetailModalAllocationsTabProps): ReactElement => {
  const [sort, setSort] = useState<Array<SortModel>>(DEFAULT_SORT);
  const [columnWidths, setColumnWidths] = useState<Record<string, number | undefined>>(DEFAULT_COLUMN_WIDTH_DICTIONARY);

  const { data: user } = useUserInfoQuery();

  const [anchorElMap, setAnchorElMap] = useAtom(anchorElAtom);
  const [addPositionToMultipleAccount, setPositionToMultipleAccount] = useAtom(addPositionToMultipleAccountModalAtom);
  const setPosition = useSetAtom(portfolioPositionPopoverAtom);
  const setAssetIdSearchValue = useSetAtom(assetIdSearchValueAtom);
  const { synced, accountOption } = useAtomValue(portfolioFiltersAtom);

  const { modalStepMap } = addPositionToMultipleAccount;
  const setStep = useSetAtom(modalStepMap.setCurrentPageAtom);

  const allocationsPayload: PortfolioPositionsDownloadPayload = {
    termsheetId,
    account: accountOption,
    synced,
    sort,
  };

  const {
    data: allocations,
    isPending,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = usePostTradeAllocationsQuery(allocationsPayload);

  const isUserAllocationEditor = user?.details.roles.includes(UserRoleEnum.AllocationEditor);

  const isTableLoading = isPending || isFetching || isFetchingNextPage;
  const rows = allocations?.pages.flatMap((page) => page.data) ?? [];
  const disableExport = rows.length === 0;
  const headerText = accountOption?.action ?? accountOption?.household?.name ?? accountOption?.account?.name;
  const height = rows.length >= 16 ? 600 : 'auto';

  const handleInfiniteScroll = () => {
    const loadMoreContent = !isFetchingNextPage && hasNextPage;
    if (loadMoreContent) void fetchNextPage();
  };

  const handleColumnResize = (params: GridColumnResizeParams) => {
    const key = params.colDef.field;
    const width = params.width;
    const newColumnWidthDict = { ...columnWidths, [key]: width };
    setColumnWidths(newColumnWidthDict);
  };

  // TODO: Implement download functionality once BE is in place
  // const handleDownload = () => {};

  const commonCellProps = {
    editable: false,
    disableColumnMenu: true,
    sortable: true,
    resizable: true,
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      headerName: translations.common.household,
      field: 'advisee_name',
      width: columnWidths['advisee_name'],
      ...(!columnWidths['advisee_name'] && { flex: 1 }),
      valueGetter: (_, row) => row.account?.household,
    },
    {
      ...commonCellProps,
      headerName: translations.common.account,
      field: 'composite_name',
      width: columnWidths['composite_name'],
      ...(!columnWidths['composite_name'] && { flex: 1 }),
      valueGetter: (_, row) => {
        const name = row.account?.name ?? '--';
        const accountId = row.account?.accountId ?? '--';
        const custodian = row.account?.custodian?.name ?? '--';
        const source = row.account?.source ? ` - ${row.account?.source}` : '';
        return `${name} - ${custodian} #${accountId}${source}`;
      },
    },
    {
      ...commonCellProps,
      headerName: translations.common.notional,
      field: 'notional',
      align: 'right',
      width: columnWidths['notional'],
      ...(!columnWidths['notional'] && { flex: 1 }),
      valueGetter: (value: number) => {
        const maximumFractionDigits = value < 1 ? 2 : 0;
        return value && value !== 0
          ? mapNumberToLocalCurrency(value, { currency: currencyCode, maximumFractionDigits }, currencySymbol)
          : '-';
      },
    },
  ];

  const actionsColumn: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      headerName: translations.common.actions,
      field: 'actions',
      align: 'center',
      width: columnWidths['actions'],
      ...(!columnWidths['actions'] && { flex: 0.6 }),
      sortable: false,
      renderCell: ({ row }) => {
        const handleEdit = (event: MouseEvent<HTMLButtonElement>) => {
          const { id: notePositionId, notional } = row;
          const account = { account: row.account } as AccountTypeAheadOption;

          setPosition({ notePositionId, accountOption: account, notional });
          setAnchorElMap({ ...anchorElMap, [EditPositionPopoverAnchor]: event.currentTarget });
        };

        const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
          const { id: notePositionId, notional } = row;
          const account = { account: row.account } as AccountTypeAheadOption;

          setPosition({ notePositionId, accountOption: account, notional });
          setAnchorElMap({ ...anchorElMap, [DeletePositionPopoverAnchor]: event.currentTarget });
        };

        return (
          <Stack direction="row" justifyContent="space-evenly" spacing={1}>
            <LocalizedButton sx={buttonSx} variant="text" size="small" onClick={handleEdit}>
              <Iconography iconName="pencil" />
            </LocalizedButton>
            <LocalizedButton sx={buttonSx} variant="text" size="small" onClick={handleDelete}>
              <Iconography iconName="trash" />
            </LocalizedButton>
          </Stack>
        );
      },
    },
  ];

  if (isUserAllocationEditor) columns.push(...actionsColumn);

  const handleOpenAdd = () => {
    setAssetIdSearchValue(identifier);
    setStep(1);
    setPositionToMultipleAccount({ open: true, hideBackButtonOnStep: 1 });
  };

  const handleSortChange = (model: GridSortModel) => {
    if (!model.length) return;
    const newSortModel = model.map((option) => {
      const newSortField = sortFieldDictionary[option.field] ?? 'advisee_name';
      const newSortDirection = (option.sort as SortModelDirectionEnum) ?? SortModelDirectionEnum.desc;
      const newSortAscending = newSortDirection === SortModelDirectionEnum.asc;
      const newSortNulls = newSortAscending ? SortModelNullSortEnum.first : SortModelNullSortEnum.last;
      return { field: newSortField, direction: newSortDirection, nulls: newSortNulls };
    });

    setSort(newSortModel);
  };

  const addIcon = <Iconography iconName="plus" />;
  const addButton = isUserAllocationEditor ? (
    <LocalizedButton onClick={handleOpenAdd} variant="outlined" startIcon={addIcon}>
      {translations.portfolio.common.addNewPosition}
    </LocalizedButton>
  ) : null;

  useEffect(() => {
    if (termsheetId) setPosition({ termsheetId });
  }, [termsheetId]);

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column" spacing={1}>
          <LocalizedTypography variant="overline">{translations.pdm.postTrade.viewingPositionsOf}</LocalizedTypography>
          <LocalizedTypography variant="h6">{headerText}</LocalizedTypography>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <DownloadSpreadsheetButton payload={allocationsPayload} disabled={disableExport} />
          {addButton}
        </Stack>
      </Stack>
      <HaloDataGrid
        rows={rows}
        columns={columns}
        height={height}
        density="compact"
        paginationMode="server"
        sortingMode="server"
        loading={isTableLoading}
        onRowsScrollEnd={handleInfiniteScroll}
        onSortModelChange={handleSortChange}
        rowCount={rows.length}
        onColumnWidthChange={handleColumnResize}
      />
      <EditPositionPopover termsheetId={termsheetId} currencyCode={currencyCode} currencySymbol={currencySymbol} />
      <DeletePositionPopover currencyCode={currencyCode} currencySymbol={currencySymbol} />
    </Stack>
  );
};
