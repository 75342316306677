export * from './filters';

export const COMMON_TRANSLATIONS = {
  appliedSavedFilters: 'Applied Saved Filters',
  calendars: 'Calendars',
  checkYourBrowsersDownloadedFilesMenu: "Check your browser's downloaded files menu.",
  closedDate: 'Closed {date}',
  closedDateTime: 'Closed {date} at {time}',
  closesDate: 'Closes {date}',
  closesDateTime: 'Closes {date} at {time}',
  closesToday: 'Closes Today',
  closesTodayTime: 'Closes Today {time}',
  commission: 'Commission',
  cusipsProcessed: '{processed} of {total, plural, one {# CUSIP} other {# CUSIPs}} processed',
  emailSubscriptionText: ' Add yourself to the email list to get notified when they go live.',
  enterEmail: 'Enter Email',
  fixedIncome: 'Fixed Income',
  indicationButtonChangeIndication: 'Change Indication',
  indicationButtonClosedOffer: 'Closed Offer',
  indicationButtonSubmitIndication: 'Submit Indication',
  indicationButtonViewOrder: 'View Order',
  inventoryRemaining: '{inventory} Remaining',
  inventoryTooltip:
    'The remaining notional available for the product offering. Once exhausted, further allocations cannot be submitted unless additional inventory becomes available.',
  newOfferingsText: 'New Offerings are typically added in the first week of the new Month.',
  offeringClosed: 'Offering Closed',
  offeringDoc: 'Offering Doc',
  offeringPaused: 'Offering Paused',
  pdfViewerModalTitle: 'Offering Document',
  price: 'Price',
  saveFilters: 'Save Filters',
  subscribeToCalendarEmailList: 'Subscribe to Calendar email list',
  successfullyExportedPDF: 'Successfully exported PDF.',
  successfullySavedFilters: 'Successfully saved filters.',
  somethingWentWrongGeneratingThePDF: 'Something went wrong generating the PDF.',
  thereWasAnErrorSavingYourFiltersPleaseTryAgain: 'There was an error saving your filters. Please try again.',
  title: 'Product Offerings',
  tryAgainInAMomentOrWithDifferentFilters: 'Try again in a moment or with different filters.',
};
