import { DEFAULT_INFINITE_QUERY_NEXT_PAGINATION } from '@halo-common/constants';
import { InfinitePaginationModel, InfiniteQueryOptions, NextInfiniteQueryParamModel } from '@halo-common/models';
import { PaginationMapper } from '@halo-data-sources/mappers';
import { InfiniteData, QueryKey, useInfiniteQuery } from '@tanstack/react-query';

export type HaloInfiniteQueryOptions<QueryResult extends NextInfiniteQueryParamModel> = Omit<
  InfiniteQueryOptions<QueryResult>,
  'initialPageParam' | 'getNextPageParam'
> & {
  initialPageParam?: InfiniteQueryOptions<QueryResult>['initialPageParam'];
  getNextPageParam?: InfiniteQueryOptions<QueryResult>['getNextPageParam'];
};

export const useHaloInfiniteQuery = <QueryResult extends NextInfiniteQueryParamModel>({
  initialPageParam,
  getNextPageParam,
  ...options
}: HaloInfiniteQueryOptions<QueryResult>) =>
  useInfiniteQuery<QueryResult, Error, InfiniteData<QueryResult>, QueryKey, InfinitePaginationModel>({
    initialPageParam: initialPageParam ?? DEFAULT_INFINITE_QUERY_NEXT_PAGINATION,
    getNextPageParam: getNextPageParam ?? ((lastPage) => PaginationMapper.toNextInfiniteQueryPaginationModel(lastPage)),
    ...options,
  });
