import { ReactElement } from 'react';

import { Stack } from '@halodomination/halo-fe-common';
import { Box, Button, Popover, Typography } from '@mui/material';

const boxStyling = {
  pt: 2,
  pr: 1,
  pl: 3,
  pb: 1,
};

const typographyStyling = {
  pr: 8,
};

export type ExecutionAuctionReviewDetailModalFooterPopoverProps = {
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  onBack: () => void;
  onCancel: () => void;
};

export const ExecutionAuctionReviewDetailModalFooterPopover = ({
  open,
  anchorEl,
  onBack,
  onCancel,
}: ExecutionAuctionReviewDetailModalFooterPopoverProps): ReactElement => (
  <Popover
    open={open}
    anchorEl={anchorEl}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    disableAutoFocus
    disableEnforceFocus
  >
    <Box sx={boxStyling}>
      <Stack direction="column" spacing={1}>
        <Stack sx={typographyStyling} direction="column" spacing={2}>
          <Typography variant="h6">Cancel Auction</Typography>
          <Typography variant="body1">
            Canceling this auction will notify the user and the issuers that it is no longer live.
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} justify="right">
          <Button color="primary" onClick={onBack}>
            Go Back
          </Button>
          <Button variant="contained" color="primary" onClick={onCancel}>
            Confirm Cancellation
          </Button>
        </Stack>
      </Stack>
    </Box>
  </Popover>
);
