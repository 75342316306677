import { ReactElement } from 'react';

import { useOrderTicketContentPicker } from '@halo-common/hooks';
import { OrderTicketAlert } from '@halo-common/modals';
import { Stack } from '@halodomination/halo-fe-common';
import { Typography } from '@mui/material';

import { AccountSection } from './AccountSection';
import { BulkAllocationSection } from './BulkAllocationSection';
import { CompensationSection } from './CompensationSection';
import { DisclaimerSection } from './DisclaimerSection';
import { NoteSection } from './NoteSection';
import { OrderDetailSection } from './OrderDetailSection';
import { TicketSection } from './TicketSection';

export type OrderTicketSubmissionFormProps = {
  bulk?: boolean;
};

export const OrderTicketSubmissionForm = ({ bulk = false }: OrderTicketSubmissionFormProps): ReactElement => {
  const { showCompensation, showNotes, showDisclaimer } = useOrderTicketContentPicker();

  const compensationSection = showCompensation ? <CompensationSection bulk={bulk} /> : null;
  const disclaimerSection = showDisclaimer ? <DisclaimerSection /> : null;
  const noteSection = showNotes ? <NoteSection bulk={bulk} /> : null;

  const topSection = bulk ? <BulkAllocationSection /> : <AccountSection />;

  // TODO: Flesh this logic out.
  const error = null;
  const alert = error ? (
    <OrderTicketAlert status="error">
      <Typography>Indication of interest was not completed due to an error.</Typography>
      <Typography>{error}</Typography>
    </OrderTicketAlert>
  ) : null;

  return (
    <Stack direction="column" spacing={3}>
      {alert}
      <form>
        {topSection}
        <TicketSection bulk={bulk} />
        {compensationSection}
        {noteSection}
        <OrderDetailSection bulk={bulk} />
        {disclaimerSection}
      </form>
    </Stack>
  );
};
