import { adminUserFilterAtom } from '@halo-atoms/adminUser';
import { UserModel } from '@halo-common/models';
import { updateUserRelationshipManager } from '@halo-data-sources/clients';
import { ApiUserManagementMapper } from '@halo-data-sources/mappers';
import { AdminUserManagementInfiniteQueryResult, AdminUserQueryKeyFactory } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { InfiniteData, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

export type UseAdminUserMutationPayload = {
  userId: number;
  userName: string;
  managerId: number;
};

const updateAdminUserFn = async (payload: UseAdminUserMutationPayload) => {
  const { userId, managerId } = payload;

  const response = await updateUserRelationshipManager(userId, managerId);

  return ApiUserManagementMapper.toUser(response);
};

export const useAdminUserMutation = (): UseMutationResult<UserModel, Error, UseAdminUserMutationPayload> => {
  const queryClient = useQueryClient();

  const filters = useAtomValue(adminUserFilterAtom);

  const { enqueueSuccessEvent, enqueuePendingEvent, enqueueErrorEvent, closeSnackbar } = useSnackbar();

  return useMutation<UserModel, Error, UseAdminUserMutationPayload>({
    mutationFn: updateAdminUserFn,
    onMutate: (payload) => {
      enqueuePendingEvent({ message: `Updating relationship manager for ${payload.userName}.` });
    },
    onError: (_, payload) => {
      closeSnackbar();
      enqueueErrorEvent({ message: `There was an error updating the relationship manager for ${payload.userName}.` });
    },
    onSuccess: (data, payload) => {
      closeSnackbar();
      enqueueSuccessEvent({ message: `Successfully updated the relationship manager for ${payload.userName}.` });

      const key = AdminUserQueryKeyFactory.manage(filters);
      queryClient.setQueryData<InfiniteData<AdminUserManagementInfiniteQueryResult>>(key, (prev) => {
        if (!prev) return prev;

        const { pages } = prev;

        const updatedPages = [...pages];
        const foundUserPageIndex = updatedPages.findIndex((page) => {
          return page.users.some((user) => user.id === data.id);
        });

        const updatedPage = { ...updatedPages[foundUserPageIndex] };
        const updatedUsers = [...updatedPage.users];
        const foundUserIndex = updatedUsers.findIndex((user) => {
          return user.id === data.id;
        });

        const updatedUser = { ...updatedUsers[foundUserIndex] };
        updatedUser.relationshipManager = data.relationshipManager;

        updatedUsers.splice(foundUserIndex, 1, updatedUser);
        updatedPage.users = updatedUsers;
        updatedPages.splice(foundUserPageIndex, 1, updatedPage);

        return { ...prev, pages: updatedPages };
      });
    },
  });
};
