import { ChangeEventHandler, ReactElement } from 'react';

import { portfolioLifecycleManagerAtom } from '@halo-atoms/portfolio';
import { PortfolioEventSortDropdownValueEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { LocalizedInputLabel, LocalizedMenuItem, LocalizedTextField } from '@halodomination/halo-fe-common';
import { FormControl, FormControlProps } from '@mui/material';
import { useAtom } from 'jotai';

export type PortfolioEventsListSortByFilterProps = Partial<FormControlProps>;

export const PortfolioEventsListSortByFilter = (props: PortfolioEventsListSortByFilterProps): ReactElement => {
  const [lifecycleFilters, setLifecycleFilters] = useAtom(portfolioLifecycleManagerAtom);

  const { filters } = lifecycleFilters;
  const { sortField } = filters;

  const label = translations.common.sortBy;

  const handleSortDirectionChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    const sortValue = e.target.value as PortfolioEventSortDropdownValueEnum;
    setLifecycleFilters({ filters: { sortField: sortValue } });
  };

  return (
    <FormControl fullWidth {...props}>
      <LocalizedInputLabel id="sortByLabelId" shrink>
        {label}
      </LocalizedInputLabel>
      <LocalizedTextField
        select
        variant="outlined"
        SelectProps={{ labelId: 'sortByLabelId', displayEmpty: true }}
        label={label}
        value={sortField}
        size="large"
        fullWidth
        onChange={handleSortDirectionChange}
      >
        <LocalizedMenuItem value={PortfolioEventSortDropdownValueEnum.ASC_EVENT_DATE}>
          {translations.portfolio.lifecycle.sortSoonestByMenuItem}
        </LocalizedMenuItem>
        <LocalizedMenuItem value={PortfolioEventSortDropdownValueEnum.DESC_EVENT_DATE}>
          {translations.portfolio.lifecycle.sortLatestByMenuItem}
        </LocalizedMenuItem>
        <LocalizedMenuItem value={PortfolioEventSortDropdownValueEnum.DESC_TOTAL_NOTIONAL}>
          {translations.portfolio.lifecycle.sortLargestByMenuItem}
        </LocalizedMenuItem>
        <LocalizedMenuItem value={PortfolioEventSortDropdownValueEnum.ASC_TOTAL_NOTIONAL}>
          {translations.portfolio.lifecycle.sortSmallestByMenuItem}
        </LocalizedMenuItem>
      </LocalizedTextField>
    </FormControl>
  );
};
