import { ReactElement } from 'react';

import { EmptyResultsCard, EmptyResultsCardProps } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { BrandIcon, Iconography, LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';

const iconSx = {
  display: 'inline',
  verticalAlign: 'middle',
};

const actionContainerSx = {
  pt: 3,
};

export type WatchlistDetailsEmptyMessageProps = Partial<EmptyResultsCardProps>;

export const WatchlistDetailsEmptyMessage = (props: WatchlistDetailsEmptyMessageProps): ReactElement => {
  const onNoteFlixClick = () => navigateParentTo('/noteflix');
  const onCreateNoteClick = () => navigateParentTo('/note_creator');

  const eyeIcon = <Iconography sx={iconSx} iconName="eye" color="text.secondary" prefix="far" size="1.25x" />;

  const Localization = {
    description: {
      dynamicContent: { eyeIcon },
    },
  };

  return (
    <EmptyResultsCard
      brandIcon={<BrandIcon variant="trackEvents" color="primary" height={96} width={96} />}
      title={translations.dashboard.noteflix.trackNoteValueOverTime}
      description={translations.dashboard.noteflix.addProductsToAWatchlistToTrackIdeas}
      Localization={Localization}
      action={
        <Stack direction="column" spacing={3} sx={actionContainerSx}>
          <LocalizedTypography variant="h6">
            {translations.dashboard.noteflix.hereAre2WaysToGetStarted}
          </LocalizedTypography>
          <Stack direction="row" spacing={3}>
            <LocalizedButton variant="contained" size="large" onClick={onNoteFlixClick}>
              {translations.dashboard.noteflix.visitNoteflix}
            </LocalizedButton>
            <LocalizedButton variant="outlined" size="large" onClick={onCreateNoteClick}>
              {translations.dashboard.noteflix.createNote}
            </LocalizedButton>
          </Stack>
        </Stack>
      }
      {...props}
    />
  );
};
