import { ApiAllocationModel, ApiFixOrderModel } from '@halo-data-sources/models';
import { AdminCalendarAllocationFilledStatusEnum } from '@halo-modules/admin';

export const CANNOT_CANCEL_ORDER_CANCELED = 'Cannot CANCEL order in state CANCELED';

export type ApiBulkFillResponseModel = {
  id: number;
  message: string | null;
};

export type ApiBulkFillSuccessResponseModel = ApiBulkFillResponseModel & {
  order: ApiFixOrderModel;
  allocation: ApiAllocationModel;
  status: AdminCalendarAllocationFilledStatusEnum.success;
};

export type ApiBulkFillFailureResponseModel = ApiBulkFillResponseModel & {
  order: null;
  allocation: null;
  status: AdminCalendarAllocationFilledStatusEnum.failed;
};

export type ApiBulkFillResponse = Array<ApiBulkFillSuccessResponseModel | ApiBulkFillFailureResponseModel>;

export type ApiFixResponseModel = {
  allocation: ApiAllocationModel;
  order: ApiFixOrderModel;
  message?: string;
};

export type ApiGetFixOrdersResponseModel = {
  orders: Array<ApiFixOrderModel>;
};
