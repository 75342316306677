import { ReactElement } from 'react';

import { AuctionAdminModel } from '@halo-common/models';
import { OrdersActions } from '@halo-stores/Orders';
import { Iconography, IconographyColors, IconographyProps } from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';

const ctaSx = {
  backgroundColor: 'common.white',
  border: 'unset',
  width: '100%',
  '&:not(:last-child)': {
    border: 'unset',
  },
};

const greenButtonSx = {
  color: 'success.dark',
  borderColor: 'success.dark',
  border: 1,
  marginTop: 1,
};

const purpleButtonSx = {
  color: 'primary.light',
  borderColor: 'primary.light',
  border: 1,
  marginTop: 1,
};

export type TackOnTableActionProps = {
  inProgress?: boolean;
  data: AuctionAdminModel;
  icon?: IconographyProps['iconName'];
  label: string;
  disabled?: boolean;
  color?: 'purple' | 'green';
};

export const TackOnTableAction = ({
  data,
  icon,
  label,
  color = 'purple',
  inProgress = false,
}: TackOnTableActionProps): ReactElement => {
  const dispatch = useDispatch();

  const buttonSx = {
    green: greenButtonSx,
    purple: purpleButtonSx,
  }[color];

  const mergedButtonSx = { ...ctaSx, ...buttonSx };

  const iconColor = (buttonSx?.color as IconographyColors) ?? undefined;
  const startIcon = icon ? <Iconography iconName={icon} color={iconColor} /> : null;

  const handleCreateTackOnClick = () => {
    void dispatch(OrdersActions.showCreateTackOn({ auction: data }));
  };

  const handleEditTackOnClick = () => {
    void dispatch(OrdersActions.showEditTackOn({ auction: data }));
  };

  const handleClick = inProgress ? handleEditTackOnClick : handleCreateTackOnClick;

  return (
    <Button
      sx={mergedButtonSx}
      fullWidth
      size="extraSmall"
      variant="outlined"
      startIcon={startIcon}
      onClick={handleClick}
    >
      {label}
    </Button>
  );
};
