import { ChangeEvent, ReactElement } from 'react';

import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { OrderBookAccountCapacityEnum } from '@halo-common/enums';
import { LocalizedMenuItem, LocalizedTextField } from '@halodomination/halo-fe-common';
import { FormControl, InputLabel } from '@mui/material';
import { useAtom } from 'jotai';

export const CapacityField = (): ReactElement => {
  const labelString = 'Capacity';

  const [searchFields, setSearchFields] = useAtom(orderBookQueryAtom);

  const handleCapacityChange = (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = ev.target.value;

    setSearchFields({
      filters: {
        capacity: value,
      },
    });
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="orderbookCapacityDropdown" shrink>
        {labelString}
      </InputLabel>
      <LocalizedTextField
        select
        variant="outlined"
        SelectProps={{ labelId: 'orderbookCapacityDropdown', displayEmpty: true }}
        label={labelString}
        value={searchFields.filters.capacity}
        size="large"
        onChange={handleCapacityChange}
      >
        {Object.entries(OrderBookAccountCapacityEnum).map((entry) => (
          <LocalizedMenuItem key={entry[0]} value={entry[1]}>
            {entry[1]}
          </LocalizedMenuItem>
        ))}
      </LocalizedTextField>
    </FormControl>
  );
};
