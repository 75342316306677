import { ReactElement, useState } from 'react';

import { WidgetEnginesEnum } from '@halo-common/enums';
import type { DashboardWidgetModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { portfolioLifecycleWidgetTabs, PortfolioProductDetailsModal, Widget } from '@halo-modules/app';
import { Box, SxProps, Theme } from '@mui/material';

import { PortfolioLifecycleCashflowChart } from './PortfolioLifecycleCashflowChart';
import { PortfolioLifecycleEventsList } from './PortfolioLifecycleEventsList';

const engine = WidgetEnginesEnum.PORTFOLIO_LIFECYCLE;

const title = translations.dashboard.portfolioLifecycle.portfolioLifecycle;
const description = translations.dashboard.portfolioLifecycle.chartAndListOfUpcomingEvents;

const stackSx: SxProps<Theme> = (theme) => ({
  alignItems: 'stretch',
  display: 'grid',
  gap: 3,
  gridTemplateColumns: '1fr 2fr',

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
});

export const PortfolioLifecycle = (): ReactElement => {
  const [tabIndex, setTabIndex] = useState(0);

  const tab = portfolioLifecycleWidgetTabs[tabIndex] || portfolioLifecycleWidgetTabs[0];

  return (
    <>
      <Widget
        id={engine}
        title={title}
        subTitle={translations.dashboard.portfolioLifecycle.allAccounts}
        className="wm-dashboard-lifecycle"
        navigateButtonClassName="wm-dashboard-lifecycle-seeall"
        navigateButtonHref="/portfolio?tab=lifecycle"
        navigateButtonLabel={translations.dashboard.portfolioLifecycle.lifecycle}
      >
        <Box sx={stackSx}>
          <PortfolioLifecycleCashflowChart activeCategory={tab?.activeCategory} />
          <PortfolioLifecycleEventsList
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            tabs={portfolioLifecycleWidgetTabs}
          />
        </Box>
      </Widget>
      <PortfolioProductDetailsModal />
    </>
  );
};

const widget: DashboardWidgetModel = {
  engine,
  title,
  description,
  component: PortfolioLifecycle,
};

export default widget;
