import { WatchlistModel } from '@halo-common/models';
import { getUserWatchlists } from '@halo-data-sources/clients';
import { ApiWatchlistMapper } from '@halo-data-sources/mappers';
import { WatchlistQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const fetchWatchlistsQueryFn = async () => {
  const response = await getUserWatchlists();

  return response?.watchlists?.map((watchlist) => ApiWatchlistMapper.toWatchlistModel(watchlist)) ?? [];
};

export const useWatchlistsQuery = (): UseQueryResult<Array<WatchlistModel>, Error> =>
  useQuery<Array<WatchlistModel>, Error>({
    queryKey: WatchlistQueryKeyFactory.all(),
    queryFn: () => fetchWatchlistsQueryFn(),
  });
