import { ChangeEvent, ReactElement, useState } from 'react';

import { IssuerModel } from '@halo-common/models';
import { OrderStatusEnum, OrdersActions, OrdersSelectors } from '@halo-stores/Orders';
import { Iconography, IconographyProps } from '@halodomination/halo-fe-common';
import { Box, Button, FormControlLabel, Radio } from '@mui/material';
import { DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';

import { AuctionTableModal } from '../../AuctionTableModal';

const tableSx = {
  backgroundColor: 'common.white',
  height: 350,
};

export type WinnerModel = IssuerModel & { quoteId: string; quoteValue: string };

export type ChooseWinnerModalProps = {
  icon?: IconographyProps['iconName'];
};

export const ChooseWinnerModal = ({ icon = 'info-circle' }: ChooseWinnerModalProps): ReactElement => {
  const dispatch = useDispatch();

  const selectedAuction = useSelector(OrdersSelectors.selectSelectedAuction);
  const orderStatus = useSelector(OrdersSelectors.selectStatus);

  const modalOpen = orderStatus === OrderStatusEnum.showChooseWinnerModal;
  const auctionQuotes = selectedAuction?.quotes ?? [];
  const auctionIssuers = selectedAuction?.issuers ?? [];
  const potentialWinners =
    auctionIssuers
      ?.filter((issuer) => !issuer.passed)
      .map((issuer) => {
        const quote = auctionQuotes.find((quote) => quote.issuer.id === issuer.id);

        return {
          quoteId: quote?.id,
          quoteValue: quote?.value ? `${quote?.value}%` : '',
          ...issuer,
        };
      }) ?? [];

  const [winner, setWinner] = useState<WinnerModel | null>(null);

  const handleModalClose = () => {
    setWinner(null);
    dispatch(OrdersActions.selectAuction({ auction: null }));
  };

  const handleModalAction = () => {
    void dispatch(
      OrdersActions.chooseWinnerOrderAuction({
        id: selectedAuction?.id,
        quote: parseInt(winner?.quoteId as string),
      }),
    );
  };

  const chooseWinnerColumns = [
    {
      field: 'name',
      headerName: 'ISSUER',
      width: 225,
      editable: false,
      filterable: false,
      resizable: false,
      renderCell: (params: GridRenderCellParams) => {
        const row = params.row as WinnerModel;
        const id = row.id;
        const name = row.name;
        const isSelected = winner?.id === id;

        const handleWinnerChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
          if (checked) setWinner(row);
        };

        return (
          <FormControlLabel
            name="choose-winner-radio-group"
            value={id}
            control={<Radio color="primary" onChange={handleWinnerChange} />}
            label={name}
            checked={isSelected}
          />
        );
      },
    },
    {
      field: 'quoteValue',
      headerName: 'ISSUER OFFERS',
      width: 200,
    },
    {
      field: 'indexes.fitch',
      headerName: 'Fitch',
      renderCell: ({ row }: GridRenderCellParams) => row.indexes?.fitch,
      width: 125,
    },
    {
      field: 'indexes.moody',
      headerName: `MOODY'S`,
      renderCell: ({ row }: GridRenderCellParams) => row.indexes?.moody,
      width: 150,
    },
    {
      field: 'indexes.sap',
      headerName: 'S&P',
      renderCell: ({ row }: GridRenderCellParams) => row.indexes?.sap,
      width: 125,
    },
  ];

  return (
    <AuctionTableModal
      onClose={handleModalClose}
      open={modalOpen}
      title="Choose Winner"
      auctionId={selectedAuction?.id}
      buyer={selectedAuction?.buyer}
      organizationName={selectedAuction?.organizationName}
      footer={
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleModalAction}
          disabled={!winner}
          startIcon={<Iconography iconName={icon} color="primary.main" />}
        >
          Choose Winner
        </Button>
      }
    >
      <Box sx={tableSx}>
        <DataGridPro
          hideFooter
          rows={potentialWinners}
          disableColumnMenu
          disableMultipleRowSelection
          columns={chooseWinnerColumns}
          columnHeaderHeight={56}
        />
      </Box>
    </AuctionTableModal>
  );
};
