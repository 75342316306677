import { FixStipulationEnum } from '@halo-common/enums';
import { FixOrderStipulationModel } from '@halo-common/models';

export const useCalendarOrderNotes = (stipulations: Array<FixOrderStipulationModel> | undefined): Array<string> => {
  if (!stipulations) return [];

  return stipulations
    .filter((stipulation) => {
      if (
        stipulation.type.startsWith(FixStipulationEnum.legend) ||
        stipulation.type.startsWith(FixStipulationEnum.trailerComment)
      ) {
        return Boolean(stipulation.value);
      }
    })
    .map((stipulation) => {
      return stipulation.value;
    });
};
