import { ComplianceApprovalQuery } from '@halo-atoms/complianceApproval';
import { API_ARBITRARY_LENGTH_TO_GET_ALL_DATA, API_ARBITRARY_PAGE_TO_GET_ALL_DATA } from '@halo-common/constants';
import { SortModelDirectionEnum } from '@halo-common/enums';
import { useHaloInfiniteQuery } from '@halo-common/hooks';
import { AllocationModel, CalendarModel, InfinitePaginationModel, InfiniteQueryOptions } from '@halo-common/models';
import {
  getAdminCalendarAllocationFiltersQuery,
  getAdminCalendarAllocationsQuery,
  getCalendars,
} from '@halo-data-sources/clients';
import { ApiComparisonOptionEnum } from '@halo-data-sources/enums';
import { ApiAllocationMapper, ApiCalendarsMapper } from '@halo-data-sources/mappers';
import { ComplianceApprovalMapper } from '@halo-data-sources/mappers';
import { AllocationClientQueryKeyFactory } from '@halo-data-sources/queries';
import { keepPreviousData } from '@tanstack/react-query';

export type AdminCalendarAllocationsQueryFilters = ComplianceApprovalQuery & {
  calendarPageId?: number | null;
};

export type AdminCalendarAllocationsQueryResult = {
  details: Array<{
    id: number;
    calendar: CalendarModel;
    allocations: Array<AllocationModel>;
  }>;
  pagination: InfinitePaginationModel;
};

const DEFAULT_PAGINATION: InfinitePaginationModel = {
  totalResults: 0,
  resultsPerPage: 5,
  page: 1,
  totalPages: 0,
  next: 1,
};

const adminCalendarAllocationsQueryFn = async (
  filters: AdminCalendarAllocationsQueryFilters,
  pageParam: InfinitePaginationModel,
) => {
  const comparisons = ComplianceApprovalMapper.toCalendarListQueryComparisons(filters);

  const orderMetaResponse = await getAdminCalendarAllocationFiltersQuery({
    filters: [
      {
        field: 'calendar_notes.id',
        type: 'OBJECTS',
        object_fields: ['calendar_notes.expiration_date'],
        sort: [{ direction: SortModelDirectionEnum.asc, field: 'calendar_notes.expiration_date' }],
      },
    ],
    comparisons,
    page: pageParam.next,
    page_length: pageParam.resultsPerPage,
  });

  const result = orderMetaResponse?.filter_values?.[0];
  const totalPages = result?.page_count ?? 0;
  const totalResults = result?.items_total_count ?? 0;
  const calendarIds = result?.objects?.map(({ object }) => object['calendar_notes.id'] as number) ?? [];

  const [calendarResponse, allocationResponse] = await Promise.all([
    getCalendars({
      comparisons: [
        {
          field: 'calendar_notes.id',
          value: calendarIds,
          op: ApiComparisonOptionEnum.IN,
        },
      ],
      sort: [
        {
          direction: SortModelDirectionEnum.asc,
          field: 'calendar_notes.expiration_date',
        },
      ],
    }),
    getAdminCalendarAllocationsQuery({
      comparisons,
      page: API_ARBITRARY_PAGE_TO_GET_ALL_DATA,
      page_length: API_ARBITRARY_LENGTH_TO_GET_ALL_DATA,
    }),
  ]);

  const calendars = calendarResponse?.calendars ?? [];
  const calendarAllocations = allocationResponse?.results ?? [];

  const calendarAllocationMappings = calendars?.map((calendar) => {
    const allocations = calendarAllocations.filter(({ calendar_id }) => calendar_id === calendar.id);

    return {
      id: calendar.id,
      calendar: ApiCalendarsMapper.toCalendarModel(calendar),
      allocations: allocations?.map((a) => ApiAllocationMapper.toAllocationModel(a)) ?? [],
    };
  });

  return {
    details: calendarAllocationMappings,
    pagination: {
      totalResults,
      totalPages,
      page: orderMetaResponse.page,
      resultsPerPage: orderMetaResponse.page_length,
      next: pageParam.next + 1,
    },
  };
};

export const useAdminCalendarAllocationsQuery = (
  filters: AdminCalendarAllocationsQueryFilters,
  options?: Partial<InfiniteQueryOptions<AdminCalendarAllocationsQueryResult>>,
) =>
  useHaloInfiniteQuery<AdminCalendarAllocationsQueryResult>({
    initialPageParam: DEFAULT_PAGINATION,
    queryKey: AllocationClientQueryKeyFactory.adminInfinite(filters),
    queryFn: ({ pageParam }) => adminCalendarAllocationsQueryFn(filters, pageParam),
    placeholderData: keepPreviousData,
    ...options,
  });
