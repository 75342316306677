import { ReactElement } from 'react';

import {
  EmptyResultsCard,
  InfiniteScroller,
  PortfolioEventsListItem,
  PortfolioEventsLoadingSkeleton,
} from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { hash } from '@halo-common/utils';
import { LIFECYCLE_EVENTS_QUERY_PAGE_LENGTH } from '@halo-data-sources/queries';
import { useEnhancedEventsList } from '@halo-modules/app';

export type PortfolioEventsListProps = {
  loading?: boolean;
};

export const PortfolioEventsList = ({ loading }: PortfolioEventsListProps): ReactElement => {
  const {
    events,
    reinvestments,
    filterTypes,
    isPending: loadingEvents,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useEnhancedEventsList();

  const isLoading = loading || loadingEvents;

  if (isLoading) return <PortfolioEventsLoadingSkeleton />;

  const enhancedEvents = events.pages.flatMap((page, pageIndex) =>
    page.events.map((event, index) => {
      const key = hash(event);
      const indexAdjustment = LIFECYCLE_EVENTS_QUERY_PAGE_LENGTH * pageIndex;
      const reinvestmentIndex = indexAdjustment + index;

      return (
        <PortfolioEventsListItem
          key={key}
          event={event}
          reinvestment={reinvestments[reinvestmentIndex]}
          filterTypes={filterTypes}
        />
      );
    }),
  );

  if (enhancedEvents.length === 0) {
    return (
      <EmptyResultsCard
        title={translations.portfolio.lifecycle.emptyListTitle}
        description={translations.portfolio.lifecycle.emptyListMessage}
      />
    );
  }

  return (
    <InfiniteScroller loading={isFetchingNextPage} hasMore={Boolean(hasNextPage)} next={fetchNextPage}>
      {enhancedEvents}
    </InfiniteScroller>
  );
};
