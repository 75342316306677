export const POSITIONS_PAGE_TRANSLATIONS = {
  aggregateTableMarketChangeHeader: 'Market Change',
  aggregateTableNextEventDateHeader: 'Next Event Date',
  aggregateTableProductTypeHeader: 'Product Type',
  aggregateTableTitle: 'Position Details',
  aggregateTableTotalNotionalHeader: 'Total Notional',
  allPositionsTableHeaderBreach: '% To Breach',
  allPositionsTableHeaderCostBasis: 'Cost Basis',
  allPositionsTableHeaderIssuer: 'Issuer',
  allPositionsTableHeaderMaturityDate: 'Maturity Date',
  allPositionsTableHeaderNoteReturn: 'Note Return',
  allPositionsTableHeaderProductType: 'Product Type',
  allPositionsTableHeaderShortName: 'Short Name',
  allPositionsTableHeaderTotalNotional: 'Total Notional',
  allPositionsTableHeaderUnderlyingReturn: 'Underlying Return',
  allPositionsTableHeaderUnrealizedGL: 'Unrealized G/L',
  allPositionsTableHeaderValuation: 'Valuation',
  allPositionsTableTitle: 'All Notes',
  assetIdSearchPlaceholder: 'Search by Asset ID',
  downloadTermsheetFailure: 'There was an error downloading the termsheet.',
  downloadTermsheetPending: 'Downloading termsheet...',
  downloadTermsheetSuccess: 'Successfully downloaded the termsheet.',
  generatePortfolioReportErrorMessage: 'Failed to generate report for portfolio.',
  generatePortfolioReportPendingMessage: 'Generating report for portfolio.',
  generatePortfolioReportSuccessMessage: 'Successfully generated report for portfolio.',
  generateReportSnackbarError: 'There is no historical report available for the requested note type at this time',
  includeInactiveFilter: 'Include Inactive',
  positionsAllTypeFilter: `All ({count})`,
  positionsBetaAction: 'View Old Portfolio',
  positionsBetaAlert: `BETA: You are viewing an updated look and feel for your new portfolio. Temporarily, the old view is still accessible.`,
  positionsFilterByType: 'Filter By Type:',
  positionsTableActionDownloadTermsheet: 'Download Termsheet',
  positionsTableActionGenerateHistoricalReport: 'Generate Historical Report',
  positionsTableTitle: 'Position Details',
  selectAnAccount: 'Select An Account',
  allTabLabel: 'All ({count})',
  allInfo: 'All Info',
  monthsCell: '{months, plural, one {# Month} other {# Months}}',
  yearsCell: '{years, plural, one {# Year} other {# Years}}',
  daysCell: '{days, plural, one {# Day} other {# Days}}',
  couponSummaryCell: '{paid} of {total} paid',
};
