import {
  DynamicFiltersHeaderFiltersModel,
  DynamicFiltersMinMaxModel,
  DynamicFiltersSideBarFiltersModel,
  DynamicFiltersToggleModel,
  dynamicAssetSearchOptionAtom,
  dynamicFiltersClearAtom,
  dynamicFiltersHeaderManagerAtom,
  dynamicFiltersInitialLoadAtom,
  dynamicSideBarManagerAtom,
  initialHeaderFilters,
} from '@halo-atoms/common';
import { productApprovalDetailsModalAtom, productApprovalPaginationAtom } from '@halo-atoms/productApproval';
import { PRODUCT_APPROVAL_PREFERENCE_TAG } from '@halo-common/constants';
import { SortModelDirectionEnum } from '@halo-common/enums';
import { DynamicSearchAssetTypeAheadOption } from '@halo-common/layouts';
import { SortModel } from '@halo-common/models';
import { atom } from 'jotai';

export type ProductApprovalsCheckboxFilterModel = {
  starred: boolean;
  submittedIndication: boolean;
  advisory: boolean;
  highlighted: boolean;
  brokerage: boolean;
  softProtection: boolean;
  hardProtection: boolean;
  dailyProtection: boolean;
  continuousProtection: boolean;
  autocallable: boolean;
  issuerCallable: boolean;
  notCallable: boolean;
  fixed: boolean;
  contingent: boolean;
  memory: boolean;
  capped: boolean;
  uncapped: boolean;
};

export type ProductApprovalQueryModel = Omit<DynamicFiltersHeaderFiltersModel, 'sortBy'> &
  DynamicFiltersSideBarFiltersModel & {
    page: number;
    pageLength: number;
    sort: Array<SortModel>;
    calendarNoteId: number | null;
    asset: DynamicSearchAssetTypeAheadOption | null;
    approved: null;
    isVisible?: boolean;
    showAsNME?: boolean;
  };

export type ProductApprovalManagerModel = {
  query: ProductApprovalQueryModel;
  isFiltered: boolean;
  isInitialLoad: boolean;
};

export type ProductApprovalQueryPayload = {
  sortBy?: string;
  filters?: Partial<DynamicFiltersHeaderFiltersModel>;
  toggles?: Partial<DynamicFiltersToggleModel>;
  checkboxes?: Partial<ProductApprovalsCheckboxFilterModel>;
  checkbox?: string;
  toggle?: string;
  protectionAmount?: DynamicFiltersMinMaxModel | null;
  term?: DynamicFiltersMinMaxModel | null;
  currencies?: Array<string>;
  issuers?: Array<string>;
  productTypes?: Array<string>;
  initialPageLoad?: boolean;
};

const DEFAULT_CALENDAR_SORT: Array<SortModel> = [
  { field: 'expirationDate', direction: SortModelDirectionEnum.asc },
  { field: 'term', direction: SortModelDirectionEnum.asc },
];

export const productApprovalHeaderFiltersAtom = atom(
  (get) => get(dynamicFiltersHeaderManagerAtom),
  (_, set, payload: ProductApprovalQueryPayload['filters']) => {
    const updatedContent = payload ?? initialHeaderFilters;
    set(dynamicFiltersHeaderManagerAtom, { tag: PRODUCT_APPROVAL_PREFERENCE_TAG, ...updatedContent });
  },
);

export const productApprovalTableSortAtom = atom<Array<SortModel>>([]);

export const productApprovalManagerAtom = atom((get): ProductApprovalManagerModel => {
  const getProductApprovalSideBarFilters = get(dynamicSideBarManagerAtom);
  const getProductApprovalHeaderFilters = get(productApprovalHeaderFiltersAtom);
  const getProductApprovalSelectedAsset = get(dynamicAssetSearchOptionAtom);

  const productApprovalTableSorting = get(productApprovalTableSortAtom);
  const productApprovalSelectedAsset = getProductApprovalSelectedAsset(PRODUCT_APPROVAL_PREFERENCE_TAG);
  const productApprovalSideBarFilters = getProductApprovalSideBarFilters(PRODUCT_APPROVAL_PREFERENCE_TAG);
  const productApprovalHeaderFilters = getProductApprovalHeaderFilters(PRODUCT_APPROVAL_PREFERENCE_TAG);
  const productApprovalPagination = get(productApprovalPaginationAtom);
  const productApprovalInitialPageLoad = get(dynamicFiltersInitialLoadAtom);

  const productApprovalSortBy = productApprovalHeaderFilters?.sortByParameters?.length
    ? productApprovalHeaderFilters?.sortByParameters
    : DEFAULT_CALENDAR_SORT;

  const sorting = [...productApprovalTableSorting, ...productApprovalSortBy];

  const query = {
    ...productApprovalHeaderFilters,
    ...productApprovalSideBarFilters,
    asset: productApprovalSelectedAsset,
    approved: null,
    page: productApprovalPagination.page,
    pageLength: productApprovalPagination.resultsPerPage,
    sort: sorting,
  };

  const isFiltered = productApprovalSideBarFilters.isFiltered || productApprovalHeaderFilters.isFiltered;

  return { query, isFiltered, isInitialLoad: productApprovalInitialPageLoad };
});

export const clearProductApprovalFiltersAtom = atom(null, (_, set) => {
  set(dynamicFiltersClearAtom, { tag: PRODUCT_APPROVAL_PREFERENCE_TAG, action: 'all' });
  set(productApprovalDetailsModalAtom);
});
