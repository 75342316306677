import { ReactElement, useEffect, useRef } from 'react';

import { ActionButton, AdminCalendarCard } from '@halo-common/components';
import { COMBINED_DATE_TIME_ZONE_FORMAT } from '@halo-common/constants';
import { useDateFormatter } from '@halo-common/hooks';
import { CalendarModel } from '@halo-common/models';
import { navigateParentTo } from '@halo-common/utils';
import {
  AdminAllocationTable,
  AdminCalendarAllocationFilledStatusEnum,
  ExecutionHubCardFooter,
  ExecutionHubStatusEnum,
  buildExecutionHubColumns,
  usePershingAdminContext,
} from '@halo-modules/admin';
import { FixActions } from '@halo-stores/Fix';
import { PershingActions, PershingSelectors } from '@halo-stores/Pershing';
import { WebsocketSelectors, WebsocketStatusEnum } from '@halo-stores/Websocket';
import { Iconography } from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { batch, useDispatch, useSelector } from 'react-redux';

const cardSx = { m: 2 };
const tableSx = {
  '& .eh-ibd-cell': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 200,
  },
  '& .eh-bonds-cell': {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    pr: 2,
  },
  '& .eh-reoffer-cell': {
    pr: 2,
  },
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

export type ExecutionHubCalendarCardProps = {
  calendar?: CalendarModel;
};

export const ExecutionHubCalendarCard = ({ calendar }: ExecutionHubCalendarCardProps): ReactElement | null => {
  const dispatch = useDispatch();

  const formatDate = useDateFormatter();

  const footerRef = useRef<HTMLDivElement | null>(null);
  const tableRef = useGridApiRef();

  const orderQueue = useSelector(PershingSelectors.selectFixOrderQueue);
  const websocketStatus = useSelector(WebsocketSelectors.selectStatus);

  const { actions = {}, pageLoading, statusFilter, tableConfiguration } = usePershingAdminContext();
  const { contentLoading } = tableConfiguration;
  const { onOpenFillOrderModal } = actions;

  const loading = pageLoading || contentLoading;

  const adminAllocations = calendar?.adminAllocations ?? [];
  const wholesalers = calendar?.wholesalers ?? [];
  const calendarId = calendar?.id;

  const allocations = adminAllocations.filter((allocation) => {
    const allocationStatus = allocation.status as AdminCalendarAllocationFilledStatusEnum;

    const isFilledStatus = statusFilter === ExecutionHubStatusEnum.filled;
    const isPendingStatus = statusFilter === ExecutionHubStatusEnum.pending;
    const statusFilterUsed = isFilledStatus || isPendingStatus;

    const isBeingFilled = Object.values(AdminCalendarAllocationFilledStatusEnum).some(
      (filledStatus) => allocationStatus === filledStatus,
    );

    if (isBeingFilled) return true;
    else if (statusFilterUsed) return allocationStatus === statusFilter;
    else return true;
  });

  const closeDate = formatDate(calendar?.expirationDate, COMBINED_DATE_TIME_ZONE_FORMAT);

  const onArchivedTab = statusFilter === ExecutionHubStatusEnum.archived;
  const calendarFillStillPending = orderQueue.some((order) => order.calendarId === calendar?.id);
  const allOrdersFilled = allocations.every(
    (allocation) => (allocation.status as ExecutionHubStatusEnum) === ExecutionHubStatusEnum.filled,
  );

  const disableButton = onArchivedTab || allOrdersFilled || calendarFillStillPending;

  const handleFillAll = () => {
    if (calendar && onOpenFillOrderModal) {
      onOpenFillOrderModal(calendar);
    }
  };

  const onTableScroll = () => {
    if (footerRef.current && tableRef.current) {
      footerRef.current.scrollLeft = tableRef.current.getScrollPosition().left;
    }
  };

  const onFooterScroll = () => {
    if (footerRef.current && tableRef.current) {
      tableRef.current.scroll({ left: footerRef.current.scrollLeft });
    }
  };

  const columns = buildExecutionHubColumns(loading, wholesalers);

  const tableComponents = {
    footer: () => (
      <ExecutionHubCardFooter
        ref={footerRef}
        allocations={allocations}
        wholesalers={wholesalers}
        onScroll={onFooterScroll}
      />
    ),
  };

  useEffect(() => {
    if (tableRef.current) tableRef.current.subscribeEvent('scrollPositionChange', onTableScroll);
  }, [tableRef, onTableScroll]);

  useEffect(() => {
    const isTimeout = websocketStatus === WebsocketStatusEnum.timeout;
    const isStillFillingOrders = orderQueue.some(({ calendarId }) => calendarId === calendar?.id);
    const shouldMarkOrdersAsFailure = isTimeout && isStillFillingOrders;

    if (shouldMarkOrdersAsFailure) {
      batch(() => {
        dispatch(FixActions.removeFromOrderQueue({ calendarId: calendar?.id }));
        dispatch(PershingActions.removeFromOrderQueue({ calendarId: calendar?.id }));
        dispatch(PershingActions.setFillOrderFailure({ calendarId: calendar?.id }));
      });
    }
  }, [websocketStatus]);

  return (
    <AdminCalendarCard
      sx={cardSx}
      loading={loading}
      issuer={calendar?.issuer}
      cusip={calendar?.cusip}
      note={calendar?.note}
      closeDate={closeDate}
      category={calendar?.category}
      actions={[
        <Button
          key={`${calendarId}-preview-button`}
          type="button"
          variant="text"
          color="primary"
          disabled={typeof calendarId !== 'number'}
          onClick={() => navigateParentTo(`/calendar/preview/${calendarId}`)}
          startIcon={<Iconography prefix="fas" iconName="eye" color="primary.main" />}
        >
          Preview
        </Button>,
        <ActionButton
          key={`${calendarId}-fill-button`}
          variant="outlined"
          color="primary"
          disabled={disableButton}
          onClick={handleFillAll}
        >
          Fill All Indications
        </ActionButton>,
      ]}
    >
      <AdminAllocationTable
        allocations={allocations}
        columns={columns}
        slots={tableComponents}
        gridRef={tableRef}
        loading={loading}
        sx={tableSx}
      />
    </AdminCalendarCard>
  );
};
