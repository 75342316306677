import { API_ARBITRARY_LENGTH_TO_GET_ALL_DATA, API_ARBITRARY_PAGE_TO_GET_ALL_DATA } from '@halo-common/constants';
import {
  LifecycleAllEventTypeEnum,
  LifecycleEventTypeEnum,
  LifecycleProductTypeEnum,
  PortfolioEventSortDropdownValueEnum,
} from '@halo-common/enums';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { getPortfolioLifecycleEvents } from '@halo-data-sources/clients';
import { PortfolioLifecycleMapper } from '@halo-data-sources/mappers';
import { PDMKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';

export type PostTradeCouponLifecycleEvent = {
  id: string;
  date: string;
  amount?: number;
  status: string;
  level?: number;
  type?: LifecycleEventTypeEnum;
};

export type PostTradeProductDetailsCouponQueryResult = {
  events?: Array<PostTradeCouponLifecycleEvent>;
};

export const getTradeProductDetailsCouponDataQueryFn = async (
  id?: number,
  accountId?: number,
  householdId?: number,
): Promise<PostTradeProductDetailsCouponQueryResult | null> => {
  if (!id) return null;

  const requestBody = PortfolioLifecycleMapper.toApiLifecycleModel({
    accountId,
    householdId,
    sortField: PortfolioEventSortDropdownValueEnum.DESC_EVENT_DATE,
    eventTypes: LifecycleAllEventTypeEnum.ALL_COUPONS,
    page: API_ARBITRARY_PAGE_TO_GET_ALL_DATA,
    pageLength: API_ARBITRARY_LENGTH_TO_GET_ALL_DATA,
    productId: id,
    productType: LifecycleProductTypeEnum.NOTE,
    isPreviousThirtyDays: false,
    synced: null,
  });

  const response = await getPortfolioLifecycleEvents(requestBody);

  const lifecycleEvents = response.data.map((event) => ({
    id: uuid(),
    date: event.event_date,
    currencyCode: event.currency_iso_code,
    amount: event.paid ? event.cash_flow_amount : undefined,
    paid: event.paid,
    level: event.barrier_pct,
    type: event.event_type,
  }));

  const events = lifecycleEvents.reduce((events: Array<PostTradeCouponLifecycleEvent>, event) => {
    const couponEventTypes = ['FIXED_COUPON', 'CONDITIONAL_COUPON', 'MEMORY_COUPON'];
    const isCouponType = couponEventTypes.includes(event.type);

    if (!isCouponType) return events;

    const status = event.paid === null ? 'pending' : event.paid ? 'paid' : 'missed';

    return [...events, { ...event, status }];
  }, []);

  return { events };
};

export const usePostTradeProductDetailsCouponDataQuery = (
  id?: number,
  accountDetails?: AccountTypeAheadOption | null,
): UseQueryResult<PostTradeProductDetailsCouponQueryResult | null, Error> => {
  const accountId = accountDetails?.account?.id;
  const householdId = accountDetails?.household?.id;

  return useQuery<PostTradeProductDetailsCouponQueryResult | null, Error>({
    queryKey: PDMKeyFactory.couponDetails(id, { accountId, householdId }),
    queryFn: () => getTradeProductDetailsCouponDataQueryFn(id, accountId, householdId),
  });
};
