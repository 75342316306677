import { ChangeEvent, ReactElement } from 'react';

import { PortfolioPeriodEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { LocalizedMenuItem, LocalizedTextField } from '@halodomination/halo-fe-common';
import { capitalize } from '@mui/material';

export interface PortfolioCashflowChartPeriodDropdownFilterProps {
  period: PortfolioPeriodEnum;
  setPeriod: (period: PortfolioPeriodEnum) => void;
}

export const PortfolioCashflowChartPeriodDropdownFilter = ({
  period,
  setPeriod,
}: PortfolioCashflowChartPeriodDropdownFilterProps): ReactElement => {
  const handlePeriodChange = (ev: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newPeriod = ev.target.value;
    setPeriod(newPeriod as PortfolioPeriodEnum);
  };

  const menuItems = Object.values(PortfolioPeriodEnum).map((portfolioPeriod) => {
    const option = capitalize(portfolioPeriod);
    return (
      <LocalizedMenuItem key={portfolioPeriod} value={portfolioPeriod}>
        {option}
      </LocalizedMenuItem>
    );
  });

  return (
    <LocalizedTextField
      label={translations.portfolio.lifecycle.scaleLabel}
      value={period}
      fullWidth
      size="large"
      select
      onChange={handlePeriodChange}
    >
      {menuItems}
    </LocalizedTextField>
  );
};
