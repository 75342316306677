import { ReactElement } from 'react';

import { addPositionSelectedAccountsAtom } from '@halo-atoms/portfolio';
import { QueuedTooltip } from '@halo-common/components/QueuedTooltip';
import { AssetIdentifierEnum, AssetIdLocationEnum } from '@halo-common/enums';
import { AccountFormField } from '@halo-common/formComponents';
import { AssetIdLocation } from '@halo-common/models';
import { AddPositionToMultipleAccountsFormFields } from '@halo-common/schemas';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { CurrencyInput, Iconography, Stack } from '@halodomination/halo-fe-common';
import { Divider, IconButton, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { Controller, useFormContext } from 'react-hook-form';

export type AccountAllocationListItemProps = {
  index: number;
  handleDelete: (index: number) => void;
  selectedAssetId?: string;
  location?: AssetIdLocation;
  assetIdType?: AssetIdentifierEnum;
};

export const AccountAllocationListItem = ({
  selectedAssetId,
  index,
  location,
  assetIdType,
  handleDelete,
}: AccountAllocationListItemProps): ReactElement => {
  const [selectedAccounts, setSelectedAccounts] = useAtom(addPositionSelectedAccountsAtom);
  const {
    control,
    formState: { errors },
  } = useFormContext<AddPositionToMultipleAccountsFormFields>();

  const accountError = errors?.['positions']?.[index]?.account?.message;
  const hasAccountError = Boolean(accountError);

  const accountFieldName: `positions.${number}.account` = `positions.${index}.account`;
  const notionalFieldName: `positions.${number}.notional` = `positions.${index}.notional`;

  const notionalError = errors?.['positions']?.[index]?.notional?.message;
  const hasNotionalError = Boolean(notionalError);

  const assetIdTypeText = assetIdType === AssetIdentifierEnum.FUNDSERVCODE ? 'FundServ' : assetIdType;

  const notFirstListItem = index !== 0;
  const divider = notFirstListItem ? <Divider /> : null;

  const isEdgarLocation = location === AssetIdLocationEnum.edgar;
  const queuedAssetText = isEdgarLocation ? <QueuedTooltip /> : null;

  const label = translations.portfolio.positions.selectAnAccount;

  const handleAccountSelect = (value: AccountTypeAheadOption | null) => {
    if (value?.account) setSelectedAccounts([...selectedAccounts, value?.account]);
  };

  return (
    <Stack direction="column" spacing={3}>
      {divider}
      <Stack direction="row" justify="space-between" alignItems="center" spacing={3} sm={[3.5, 3.5, 4, 1]}>
        <Stack direction="column">
          <Typography variant="overline" color="text.secondary">
            {assetIdTypeText}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h6">{selectedAssetId}</Typography>
            {queuedAssetText}
          </Stack>
        </Stack>
        <AccountFormField
          rules={{ disableHouseholds: true }}
          label={label}
          control={control}
          name={accountFieldName}
          error={hasAccountError}
          helperText={accountError}
          onSelect={handleAccountSelect}
        />
        <Controller
          render={({ field: { ref, ...options } }) => (
            <CurrencyInput
              {...options}
              size="large"
              inputRef={ref}
              fullWidth
              valueType="number"
              label={translations.common.notional}
              error={hasNotionalError}
              helperText={notionalError}
            />
          )}
          control={control}
          name={notionalFieldName}
        />
        <IconButton onClick={() => handleDelete(index)}>
          <Iconography iconName="trash-alt" color="primary.main" />
        </IconButton>
      </Stack>
    </Stack>
  );
};
