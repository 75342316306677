import { modalAtom } from '@halo-atoms/common';
import { AllocationModel, CalendarModel } from '@halo-common/models';
import { atom } from 'jotai';

type ComplianceApprovalModalOptions = {
  open: boolean;
  calendar?: CalendarModel | null;
  allocation?: AllocationModel | null;
};

const _selectedCalendarAtom = atom<CalendarModel | null>(null);
const _selectedAllocationAtom = atom<AllocationModel | null>(null);

const COMPLIANCE_APPROVAL_MODAL = 'complianceApprovalModal';
export const complianceApprovalModalAtom = atom(
  (get) => {
    const modalMap = get(modalAtom);
    const calendar = get(_selectedCalendarAtom);
    const allocation = get(_selectedAllocationAtom);

    return { open: modalMap[COMPLIANCE_APPROVAL_MODAL], calendar, allocation };
  },
  (_, set, payload: ComplianceApprovalModalOptions) => {
    set(modalAtom, { [COMPLIANCE_APPROVAL_MODAL]: payload.open });
    set(_selectedCalendarAtom, payload.calendar ?? null);
    set(_selectedCalendarAtom, payload.calendar ?? null);
    set(_selectedAllocationAtom, payload.allocation ?? null);
  },
);
