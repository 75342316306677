import { CalendarModel, CalendarPendingModel, WholesalerModel } from '@halo-common/models';
import { HaloAppState } from '@halo-stores/types';
import { createSelector } from '@reduxjs/toolkit';

export const selectStatus = createSelector(
  ({ Pershing }: HaloAppState) => Pershing.status,
  (status) => status,
);

export const selectError = createSelector(
  ({ Pershing }: HaloAppState) => Pershing.error,
  (error) => error,
);

export const selectCalendar = createSelector(
  ({ Pershing }: HaloAppState) => Pershing.selectedCalendar,
  (calendar) => calendar,
);

export const selectOrderBook = createSelector(
  ({ Pershing }: HaloAppState) => Pershing.orderBook,
  (orderBook) => orderBook,
);

export const selectSortedCalendarPoints = createSelector(selectCalendar, (calendar) => {
  const points = calendar?.points ? [...calendar.points] : [];
  const sortedPoints = points.sort((a, b) => a.position - b.position) || [];
  return sortedPoints;
});

export const selectCalendars = createSelector(
  ({ Pershing }: HaloAppState) => Pershing.calendars,
  (calendars) => calendars,
);

export const selectPendingTotal = createSelector(
  ({ Pershing }: HaloAppState) => Pershing.pendingCalendars,
  (pending) => pending.total,
);

export const selectPendingFilteredTotal = createSelector(
  ({ Pershing }: HaloAppState) => Pershing.pendingCalendars,
  (pending) => pending.filteredTotal,
);

export const selectPendingCalendars = createSelector(
  ({ Pershing }: HaloAppState) => Pershing.pendingCalendars,
  (pending) => pending.calendars,
);

export const selectTotals = createSelector(
  ({ Pershing }: HaloAppState) => Pershing.totals,
  (totals) => totals,
);

export const selectAllocationTotals = createSelector(
  ({ Pershing }: HaloAppState) => Pershing.allocationTotals,
  (allocationTotals) => allocationTotals,
);

export const selectFilteredTotals = createSelector(
  ({ Pershing }: HaloAppState) => Pershing.filteredTotals,
  (filteredTotals) => filteredTotals,
);

export const selectFixOrderQueue = createSelector(
  ({ Pershing }: HaloAppState) => Pershing.fixOrderQueue,
  (fixOrderQueue) => fixOrderQueue,
);

export const selectParentPageCalendarTotal = createSelector(
  ({ Pershing }: HaloAppState) => Pershing.parentPageCalendarMap,
  (parentPageCalendarMap) => parentPageCalendarMap.total,
);

export const selectParentPageCalendarFilteredTotal = createSelector(
  ({ Pershing }: HaloAppState) => Pershing.parentPageCalendarMap,
  (parentPageCalendarMap) => parentPageCalendarMap.filteredTotal,
);

export const selectParentPageCalendars = createSelector(
  ({ Pershing }: HaloAppState) => Pershing.parentPageCalendarMap,
  (parentPageCalendarMap) => parentPageCalendarMap.calendars,
);

export const selectAllocation = createSelector(
  ({ Pershing }: HaloAppState) => Pershing.selectedAllocation,
  (selectedAllocation) => selectedAllocation,
);

export const selectAccount = createSelector(
  ({ Pershing }: HaloAppState) => Pershing.selectedAccount,
  (selectedAccount) => selectedAccount,
);

export const selectVisibleCalendarIds = createSelector(
  ({ Pershing }: HaloAppState) => Pershing.visibleParentCalendarIds,
  (visibleParentCalendarIds) => visibleParentCalendarIds,
);

export const selectCalendarsByStatus = createSelector(selectCalendars, (calendars) => ({
  archived: calendars?.archived ?? [],
  available: calendars?.available ?? [],
  confirmed: calendars?.confirmed ?? [],
  denied: calendars?.denied ?? [],
  filled: calendars?.filled ?? [],
  pending: calendars?.pending ?? [],
}));

export const selectCalendarsForLoadingDock = createSelector(
  selectCalendarsByStatus,
  selectPendingCalendars,
  (calendarMap, pendingCalendars) => ({
    archived: calendarMap.archived,
    available: calendarMap.available,
    pending: pendingCalendars ?? [],
  }),
);

export const selectCalendarsForExecutionHub = createSelector(selectCalendarsByStatus, (calendarMap) => ({
  archived: calendarMap.archived,
  available: calendarMap.available,
  filled: calendarMap.filled,
  pending: calendarMap.pending,
}));

export const selectCalendarsForComplianceApproval = createSelector(selectCalendarsByStatus, (calendarMap) => ({
  archived: calendarMap.archived,
  available: calendarMap.available,
  pending: calendarMap.pending,
  confirmed: calendarMap.confirmed,
  denied: calendarMap.denied,
}));

export const selectLoadingDockWholesalerMaps = createSelector(
  selectCalendarsForLoadingDock,
  ({ archived, available, pending }) => {
    const STATIC_WHOLESALER_CODES = ['issuer', 'ftlp', 'halo'];
    const staticWholesalersMap = STATIC_WHOLESALER_CODES.reduce(
      (lookup: { [key: string]: Record<string, never> }, code) => ({
        ...lookup,
        [code]: {},
      }),
      {},
    );

    const createWholesalerMap = (calendars: Array<CalendarModel | CalendarPendingModel>) => {
      const allWholesalers = calendars.reduce(
        (list: Array<WholesalerModel & { calendarId: number }>, calendar) => [
          ...list,
          ...(calendar?.wholesalers?.map((wholesaler) => ({ ...wholesaler, calendarId: calendar.id })) ?? []),
        ],
        [],
      );

      return allWholesalers.reduce(
        (lookup: { [wholesalerCode: string]: { [calendarId: number]: WholesalerModel } }, wholesaler) => {
          const { code, fixEnabled } = wholesaler;

          const isStaticWholesaler = STATIC_WHOLESALER_CODES.includes(code.toLowerCase());
          if (!fixEnabled && !isStaticWholesaler) return lookup;

          return {
            ...lookup,
            [code]: { ...lookup[code], [wholesaler.calendarId]: wholesaler },
          };
        },
        staticWholesalersMap,
      );
    };

    return {
      archived: createWholesalerMap(archived),
      available: createWholesalerMap(available),
      pending: createWholesalerMap(pending),
    };
  },
);
