import { getClientConfiguration, request } from '@halo-data-sources/clients';
import type {
  ApiOnboardingDocusignFormDataResponseModel,
  DocusignSendForSignaturesPayloadModel,
  PartialDocusignFormDataModel,
} from '@halo-data-sources/models';

const DOCUSIGN_ROUTE = '/pretrade/onboarding/docusign';

export const getOnboardingDocusignFormData = async (): Promise<ApiOnboardingDocusignFormDataResponseModel> => {
  const clientConfiguration = await getClientConfiguration(DOCUSIGN_ROUTE);

  const response = await request<ApiOnboardingDocusignFormDataResponseModel>(
    `${clientConfiguration.basePath}/form-data`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};

export const putOnboardingDocusignFormData = async (
  formData: PartialDocusignFormDataModel,
): Promise<ApiOnboardingDocusignFormDataResponseModel> => {
  const clientConfiguration = await getClientConfiguration(DOCUSIGN_ROUTE);

  const body = { json_data: formData, _csrf_token: clientConfiguration.csrfToken };

  const response = await request<ApiOnboardingDocusignFormDataResponseModel>(
    `${clientConfiguration.basePath}/form-data`,
    {
      ...clientConfiguration.requestInit,
      body: JSON.stringify(body),
      method: 'PUT',
    },
  );

  return response;
};

export const sendForSignatures = async (
  payload: DocusignSendForSignaturesPayloadModel,
): Promise<DocusignSendForSignaturesPayloadModel> => {
  const clientConfiguration = await getClientConfiguration(DOCUSIGN_ROUTE);

  const body = { ...payload, _csrf_token: clientConfiguration.csrfToken };

  const res = await request<DocusignSendForSignaturesPayloadModel>(
    `${clientConfiguration.basePath}/send-for-signatures`,
    {
      ...clientConfiguration.requestInit,
      body: JSON.stringify(body),
      method: 'POST',
    },
  );

  return res;
};
