import { ProductApprovalQueryModel } from '@halo-atoms/productApproval';
import { DynamicFiltersUnderlyingMatchEnum } from '@halo-common/enums';
import { ApiComparisonOptionEnum, ApiComparisonTypeEnum } from '@halo-data-sources/enums';
import { CalendarsMapper } from '@halo-data-sources/mappers';
import { ApiComparisonModel } from '@halo-data-sources/models';
import { capitalize } from 'lodash';

const buildRangeComparison = (field: string, min: number, max: number): ApiComparisonModel => {
  const rangeComparisons: Array<ApiComparisonModel> = [];

  if (min) rangeComparisons.push({ field, op: ApiComparisonOptionEnum.GTE, value: min });
  if (max) rangeComparisons.push({ field, op: ApiComparisonOptionEnum.LTE, value: max });

  return {
    comparisons: rangeComparisons,
    comparison_type: ApiComparisonTypeEnum.AND,
  };
};

export const ProductApprovalMapper = {
  toProductQuery: (query: ProductApprovalQueryModel): Array<ApiComparisonModel> => {
    if (query.asset) {
      return [
        {
          field: 'calendar_notes.cusip',
          op: ApiComparisonOptionEnum.IN,
          value: query.asset.assetIds.map((assetId) => assetId.value),
        },
      ];
    }

    const protectionTypes = CalendarsMapper.toCalendarsProtectionTypePayload(query);
    const calendarTypes = CalendarsMapper.toCalendarOrderCategory(query);
    const term = CalendarsMapper.toCalendarsTermFilterPayload(query.term);
    const protectionAmount = CalendarsMapper.toCalendarsProtectionAmountFilterPayload(query.protectionAmount);
    const nonCallMonths = CalendarsMapper.toCalendarsNonCallPeriodFilterPayload(query.nonCallPeriod);
    const isCapped = CalendarsMapper.toCalendarsCappedPayload(query);
    const tradeables = CalendarsMapper.toCalendarsTradablePayload(query);
    const callTypes = CalendarsMapper.toCalendarsCallTypePayload(query);
    const couponTypes = CalendarsMapper.toCalendarsCouponTypePayload(query);
    const settlementTypes = CalendarsMapper.toCalendarsSettlementTypePayload(query);

    const comparisons: Array<ApiComparisonModel> = [];

    if (protectionTypes?.length) {
      comparisons.push({
        field: 'notes.protection_type',
        op: ApiComparisonOptionEnum.IN,
        value: protectionTypes.map((type) => type.toLowerCase()),
      });
    }
    if (calendarTypes?.length) {
      comparisons.push({
        field: 'calendar_notes.category',
        op: ApiComparisonOptionEnum.IN,
        value: calendarTypes.map((type) => capitalize(type)),
      });
    }
    if (query.issuers?.length) {
      comparisons.push({
        field: 'issuers.id',
        op: ApiComparisonOptionEnum.IN,
        value: query.issuers.map(({ id }) => parseInt(id)),
      });
    }
    if (query.currencies?.length) {
      comparisons.push({
        field: 'currencies.id',
        op: ApiComparisonOptionEnum.IN,
        value: query.currencies.map((currencyId) => parseInt(currencyId)),
      });
    }
    if (query.checkboxes.starred) {
      comparisons.push({
        field: 'computed.is_favorite',
        op: ApiComparisonOptionEnum.EQ,
        value: query.checkboxes.starred,
      });
    }
    if (query.endDate) {
      comparisons.push({
        field: 'calendar_offering.start_date::date',
        op: ApiComparisonOptionEnum.LTE,
        value: query.endDate,
      });
    }
    if (query.startDate) {
      comparisons.push({
        field: 'calendar_offering.expiration_date::date',
        op: ApiComparisonOptionEnum.GTE,
        value: query.startDate,
      });
    }
    if (term) {
      comparisons.push(buildRangeComparison('terms.months', term.min, term.max));
    }
    if (protectionAmount !== undefined) {
      comparisons.push(buildRangeComparison('notes.protection_percent', protectionAmount.min, protectionAmount.max));
    }
    if (nonCallMonths !== undefined) {
      comparisons.push(buildRangeComparison('notes.non_call_months', nonCallMonths.min, nonCallMonths.max));
    }
    if (isCapped !== undefined) {
      comparisons.push({
        field: 'notes.has_cap',
        op: ApiComparisonOptionEnum.EQ,
        value: isCapped,
      });
    }
    if (tradeables?.names?.length) {
      const isAnyFilterSelected = tradeables?.matches === DynamicFiltersUnderlyingMatchEnum.ANY;

      if (isAnyFilterSelected) {
        comparisons.push({
          comparisons: tradeables.names.map((name) => ({
            field: 'computed.tradable_names',
            op: ApiComparisonOptionEnum.CONTS,
            value: [name],
          })),
          comparison_type: ApiComparisonTypeEnum.OR,
        });
      } else {
        const isExactlyFilterSelected = tradeables?.matches === DynamicFiltersUnderlyingMatchEnum.EXACTLY;
        const tradeableIdsOperation = isExactlyFilterSelected
          ? ApiComparisonOptionEnum.AEQ
          : ApiComparisonOptionEnum.CONTS;

        comparisons.push({
          field: 'computed.tradable_names',
          op: tradeableIdsOperation,
          value: tradeables.names,
        });
      }
    }
    if (tradeables?.basket_style?.length) {
      comparisons.push({
        field: 'computed.tradable_basket_style',
        op: ApiComparisonOptionEnum.IN,
        value: tradeables?.basket_style,
      });
    }
    if (tradeables?.basket_size) {
      const basketSize = tradeables?.basket_size;
      comparisons.push(buildRangeComparison('computed.tradable_count', basketSize?.min, basketSize?.max));
    }
    if (query.isVisible !== undefined) {
      comparisons.push({
        field: 'calendar_notes.is_visible',
        op: ApiComparisonOptionEnum.EQ,
        value: query.isVisible,
      });
    }
    if (query.showAsNME !== undefined) {
      comparisons.push({
        field: 'calendar_notes.show_as_nme',
        op: ApiComparisonOptionEnum.EQ,
        value: query.showAsNME,
      });
    }
    if (query.toggles.propIndices) {
      comparisons.push({
        field: 'computed.tradable_sub_asset_classes',
        op: ApiComparisonOptionEnum.NCONTS,
        value: ['PROP_INDICES'],
      });
    }
    if (query.checkboxes.submittedIndication) {
      comparisons.push({
        field: 'computed.num_active_allocations',
        op: ApiComparisonOptionEnum.GT,
        value: 0,
      });
    }
    if (query.productTypes?.length) {
      comparisons.push({
        field: 'notes.product',
        op: ApiComparisonOptionEnum.IN,
        value: query.productTypes,
      });
    }
    if (callTypes?.length) {
      comparisons.push({
        field: 'computed.call_type',
        op: ApiComparisonOptionEnum.IN,
        value: callTypes,
      });
    }
    if (couponTypes?.length) {
      comparisons.push({
        field: 'computed.coupon_type',
        op: ApiComparisonOptionEnum.IN,
        value: couponTypes,
      });
    }
    if (settlementTypes?.length) {
      comparisons.push({
        field: 'calendar_notes.settlement_type',
        op: ApiComparisonOptionEnum.IN,
        value: settlementTypes,
      });
    }
    if (query.approved !== undefined && query.approved !== null) {
      comparisons.push({
        field: 'calendar_offering.approved',
        op: ApiComparisonOptionEnum.EQ,
        value: query.approved,
      });
    }

    return comparisons;
  },
};
