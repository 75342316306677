import { ReactElement } from 'react';

import { LocalizedFormControlLabel, LocalizedFormControlLabelProps } from '@halodomination/halo-fe-common';
import { Radio as MuiRadio, RadioGroup as MuiRadioGroup, RadioGroupProps as MuiRadioGroupProps } from '@mui/material';
import { useController, UseControllerProps } from 'react-hook-form';

export type RadioGroupProps = UseControllerProps & Omit<MuiRadioGroupProps, 'name'>;

export const RadioGroup = ({
  name,
  control,
  defaultValue,
  disabled,
  rules,
  shouldUnregister,

  ...props
}: RadioGroupProps): ReactElement => {
  const { field } = useController({
    name,
    control,
    defaultValue,
    disabled,
    rules,
    shouldUnregister,
  });

  return <MuiRadioGroup {...field} {...props} />;
};

export type RadioProps = Omit<LocalizedFormControlLabelProps, 'control' | 'ref'>;

export const Radio = (props: RadioProps): ReactElement => (
  <LocalizedFormControlLabel control={<MuiRadio />} {...props} />
);
