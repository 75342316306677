import { ChangeEvent, ReactElement } from 'react';

import { portfolioPositionsManagerAtom } from '@halo-atoms/portfolio';
import { translations } from '@halo-common/translations';
import { CompositionEnum } from '@halo-modules/app';
import { LocalizedMenuItem, LocalizedTextField } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';
import { useAtom } from 'jotai';

export type PortfolioCompositionDropdownProps = {
  loading?: boolean;
};

export const PortfolioCompositionDropdown = ({ loading }: PortfolioCompositionDropdownProps): ReactElement => {
  const [positionData, setPositionData] = useAtom(portfolioPositionsManagerAtom);

  const composition = positionData?.filters?.composition;

  const handleCompositionChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const selectedComposition = ev.target.value as CompositionEnum;
    setPositionData({ composition: selectedComposition });
  };

  return loading ? (
    <Skeleton width="100%" height={48} variant="rounded" />
  ) : (
    <LocalizedTextField
      select
      value={composition}
      label={translations.portfolio.common.compositionTypeLabel}
      onChange={handleCompositionChange}
      fullWidth
      size="large"
    >
      <LocalizedMenuItem value={CompositionEnum.Product}>{translations.common.product}</LocalizedMenuItem>
      <LocalizedMenuItem value={CompositionEnum.IssuerName}>{translations.common.issuerName}</LocalizedMenuItem>
      <LocalizedMenuItem value={CompositionEnum.IssuerCreditRating}>
        {translations.common.spCreditRating}
      </LocalizedMenuItem>
      <LocalizedMenuItem value={CompositionEnum.Currency}>{translations.common.currency}</LocalizedMenuItem>
    </LocalizedTextField>
  );
};
