import { getClientConfiguration, request } from '@halo-data-sources/clients';
import { ApiBulkAllocationRequest, ApiGetSortedAccountsResponseModel } from '@halo-data-sources/models';

const ACCOUNTS_PATH = '/common/accounts';

export const getBulkAccounts = async (
  payload: ApiBulkAllocationRequest,
): Promise<ApiGetSortedAccountsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ACCOUNTS_PATH);

  const path = `${clientConfiguration.basePath}/details`;

  const response = await request<ApiGetSortedAccountsResponseModel>(path, {
    ...clientConfiguration.requestInit,
    failOnError: false,
    method: 'POST',
    body: JSON.stringify({
      ...payload,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};
