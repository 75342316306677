import { alertClasses, buttonClasses } from '@mui/material';

export const suitabilityAlertSx = {
  color: 'warning.dark',
  backgroundColor: 'warning.background',
  mb: 3,
  py: 1,
  [`&.${alertClasses.colorError}`]: {
    color: 'error.dark',
    backgroundColor: 'error.background',
  },
  [`& > .${alertClasses.icon}`]: {
    alignSelf: 'flex-start',
    opacity: 0.75,
  },
  [`& .${alertClasses.message} .${buttonClasses.text}`]: {
    color: 'inherit',
    height: 'auto',
    minHeight: 'unset',
    minWidth: 'unset',
    mt: 1,
    padding: 0,
    textDecoration: 'underline',
  },
  '&:last-child': {
    mb: 0,
  },
};
