import { ReactElement, ReactNode } from 'react';

import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';

const dataSx = {
  fontWeight: 'fontWeightBold',
};

export type EstimatedTransactionRowProps = {
  loading: boolean;
  header: string;
  children: ReactNode;
};

export const EstimatedTransactionRow = ({ loading, header, children }: EstimatedTransactionRowProps): ReactElement => {
  const headerContent = loading ? (
    <Skeleton width={80} height={24} />
  ) : (
    <LocalizedTypography variant="body1" color="textSecondary">
      {header}
    </LocalizedTypography>
  );

  const sectionContent = loading ? (
    <Skeleton width={80} height={24} />
  ) : (
    <LocalizedTypography variant="body1" sx={dataSx} align="right">
      {children}
    </LocalizedTypography>
  );

  return (
    <Stack direction="row" xs={6} justify="space-between">
      {headerContent}
      {sectionContent}
    </Stack>
  );
};
