import { ActionButton, useStepWizardContext } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { DocusignAuthorizedSignerModel } from '@halo-data-sources/models';
import { useSendForSignaturesMutation } from '@halo-data-sources/mutations/docusign';
import {
  SuitabilityStepModel,
  suitabilityAlertSx,
  authorizedPersonsStep,
  firmDetailsStep,
  useSuitabilityFormManager,
} from '@halo-modules/app/v2/onboarding';
import { Iconography, LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Alert, Stack, Typography } from '@mui/material';

const flex1 = { flex: '1 1 auto' };

const flex0 = { flex: '0 0 auto' };

const finalize = translations.onboarding.suitability.final;

const Signer = ({ authorizedSigner }: { authorizedSigner: DocusignAuthorizedSignerModel }) => {
  const { setStep } = useStepWizardContext();

  const returnToAuthorizedPersonsStep = () => setStep(authorizedPersonsStep.id);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      borderTop="1px solid"
      borderBottom="1px solid"
      borderColor="divider"
      spacing={1}
      my={3}
      py={2}
    >
      <Stack spacing="4px" sx={flex1}>
        <LocalizedTypography variant="overline" color="text.secondary">
          {finalize.principalContact}
        </LocalizedTypography>
        <Typography variant="h6">{authorizedSigner.name}</Typography>
        <Typography variant="caption" color="text.secondary">
          {authorizedSigner.email_address}
        </Typography>
        <Stack direction="row" alignItems="center" spacing="4px">
          <Iconography iconName="signature-lock" color="success.main" />
          <LocalizedTypography variant="caption" color="success.main">
            {finalize.authorizedSigner}
          </LocalizedTypography>
        </Stack>
      </Stack>

      <LocalizedButton
        variant="outlined"
        color="primary"
        endIcon={<Iconography iconName="pencil" color="inherit" />}
        onClick={returnToAuthorizedPersonsStep}
        sx={flex0}
      >
        {translations.common.edit}
      </LocalizedButton>
    </Stack>
  );
};

const SendForSignatures = () => {
  const { setStep } = useStepWizardContext();

  const { formData, isFullyFilled, unansweredSteps, firstUnansweredStep } = useSuitabilityFormManager();

  const { mutateAsync, isPending } = useSendForSignaturesMutation({
    onSuccess: () => setStep('completed'),
  });

  const reviewUnansweredQuestions = () => setStep(firstUnansweredStep?.id || firmDetailsStep.id);

  const authorizedSigner = formData?.firm_details?.authorized_persons
    ?.authorized_signer as DocusignAuthorizedSignerModel;

  const hasValidSigner = !!authorizedSigner?.name && !!authorizedSigner.email_address;

  if (!hasValidSigner) {
    return (
      <Alert
        icon={<Iconography iconName="triangle-exclamation" color="inherit" />}
        severity="warning"
        variant="filled"
        sx={suitabilityAlertSx}
      >
        <LocalizedTypography variant="body2">{finalize.noAuthorizedSignerFound}</LocalizedTypography>
        <LocalizedButton
          onClick={reviewUnansweredQuestions}
          variant="text"
          color="warning"
          dynamicContent={{
            count: unansweredSteps.length,
          }}
        >
          {finalize.reviewUnfinishedQuestions}
        </LocalizedButton>
      </Alert>
    );
  }

  const sendForSignatures = () => {
    void mutateAsync({
      name: authorizedSigner.name,
      email: authorizedSigner.email_address,
    });
  };

  const notFullyFilledAlert = isFullyFilled ? null : (
    <Alert
      icon={<Iconography iconName="triangle-exclamation" color="inherit" />}
      severity="warning"
      variant="filled"
      sx={suitabilityAlertSx}
    >
      <LocalizedTypography variant="body2">{finalize.someQuestionsWereLeftUnanswered}</LocalizedTypography>
      <LocalizedButton
        onClick={reviewUnansweredQuestions}
        variant="text"
        color="warning"
        dynamicContent={{
          count: unansweredSteps.length,
        }}
      >
        {finalize.reviewUnfinishedQuestions}
      </LocalizedButton>
    </Alert>
  );

  return (
    <>
      {notFullyFilledAlert}
      <LocalizedTypography variant="h6" align="center">
        {finalize.hereIsWhereWeWillSendTheFinalDocuments}
      </LocalizedTypography>
      <Signer authorizedSigner={authorizedSigner} />
      <ActionButton
        variant="contained"
        color="primary"
        endIcon={<Iconography iconName="arrow-right" color="inherit" />}
        fullWidth
        onClick={sendForSignatures}
        loading={isPending}
      >
        {finalize.sendForSignatures}
      </ActionButton>
    </>
  );
};

export const sendForSignaturesStep: SuitabilityStepModel = {
  id: 'finalize',
  title: translations.onboarding.suitability.reviewAndMakeChanges,
  subtitle: translations.onboarding.suitability.reviewSubtitle,
  component: <SendForSignatures />,
};
