import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { LocalizedMenuItem, LocalizedTextField } from '@halodomination/halo-fe-common';
import { useController, useFormContext } from 'react-hook-form';

import { ProductApprovalEditCloseDateFormFields } from '../../ProductApprovalEditCloseDateModal';

export const DaysPriorDropdown = (): ReactElement => {
  const formMethods = useFormContext<ProductApprovalEditCloseDateFormFields>();

  const dayModMethods = useController({
    control: formMethods.control,
    name: 'dayModification',
    defaultValue: '0',
  });

  const { onChange: onDayModChange, value: selectedMod } = dayModMethods.field;

  return (
    <LocalizedTextField
      fullWidth
      select
      value={selectedMod}
      size="large"
      helperText={translations.productApproval.editCloseDateModal.modifyCloseDaysPriorSelectHelperText}
      onChange={onDayModChange}
    >
      <LocalizedMenuItem value={0}>
        {translations.productApproval.editCloseDateModal.modifyCloseDaysPriorOption0}
      </LocalizedMenuItem>
      <LocalizedMenuItem value={1}>
        {translations.productApproval.editCloseDateModal.modifyCloseDaysPriorOption1}
      </LocalizedMenuItem>
      <LocalizedMenuItem value={2}>
        {translations.productApproval.editCloseDateModal.modifyCloseDaysPriorOption2}
      </LocalizedMenuItem>
      <LocalizedMenuItem value={3}>
        {translations.productApproval.editCloseDateModal.modifyCloseDaysPriorOption3}
      </LocalizedMenuItem>
      <LocalizedMenuItem value={4}>
        {translations.productApproval.editCloseDateModal.modifyCloseDaysPriorOption4}
      </LocalizedMenuItem>
      <LocalizedMenuItem value={5}>
        {translations.productApproval.editCloseDateModal.modifyCloseDaysPriorOption5}
      </LocalizedMenuItem>
      <LocalizedMenuItem value={6}>
        {translations.productApproval.editCloseDateModal.modifyCloseDaysPriorOption6}
      </LocalizedMenuItem>
      <LocalizedMenuItem value={7}>
        {translations.productApproval.editCloseDateModal.modifyCloseDaysPriorOption7}
      </LocalizedMenuItem>
    </LocalizedTextField>
  );
};
