import { ReactElement } from 'react';

import { notionalAtom } from '@halo-atoms/auctions';
import { useIdFromQueryParams } from '@halo-common/hooks';
import { useNoteQuery } from '@halo-data-sources/queries';
import { Iconography, mapNumberToLocalCurrency } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';

import { AuctionStepperStep } from '../AuctionStepperStep';

const icon = <Iconography iconName="dollar-sign" prefix="far" />;

export const AuctionNotionalStep = (): ReactElement => {
  const notional = useAtomValue(notionalAtom);
  const isNotionalValueNull = notional === null;
  const isNotionalValueValid = notional && notional >= 0;

  const { id: noteId } = useIdFromQueryParams('noteId');
  const { data: note } = useNoteQuery(noteId);

  const notionalDisplayValue = isNotionalValueNull
    ? '-'
    : mapNumberToLocalCurrency(
        notional,
        {
          currency: note?.currencyCode ?? 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        },
        note?.currencySymbol,
      );
  return (
    <AuctionStepperStep
      step={0}
      displayValue={notionalDisplayValue}
      icon={icon}
      label="Notional"
      disabled={!isNotionalValueValid}
      trackingVerb="set auction notional"
    />
  );
};
