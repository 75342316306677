import { AssetIdentifierEnum } from '@halo-common/enums';

export const validateFundServCode = (assetId?: string, assetIdType?: AssetIdentifierEnum): boolean => {
  if (!assetId) return false;

  const isFundServ = assetIdType === AssetIdentifierEnum.FUNDSERVCODE;
  const isJHN = assetId?.startsWith('JHN');

  return isFundServ && isJHN && assetId.length >= 6 && assetId.length <= 10;
};

export const validateAssetIdLength = (
  assetId: string | undefined,
  assetIdentifiers: Array<AssetIdentifierEnum>,
): boolean => {
  if (!assetId) return false;

  const hasCusipIdentifier = assetIdentifiers.some((identifier) => identifier === AssetIdentifierEnum.CUSIP);
  const hasIsinIdentifier = assetIdentifiers.some((identifier) => identifier === AssetIdentifierEnum.ISIN);
  const hasFundServIdentifier = assetIdentifiers.some((identifier) => identifier === AssetIdentifierEnum.FUNDSERVCODE);

  const hasValidCusipId = hasCusipIdentifier && assetId.length === 9;
  const hasValidIsinId = hasIsinIdentifier && assetId.length === 12;
  const hasValidFundServId = hasFundServIdentifier && validateFundServCode(assetId, AssetIdentifierEnum.FUNDSERVCODE);

  return Boolean(hasValidCusipId || hasValidIsinId || hasValidFundServId);
};
