import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiCommonUserSettings,
  ApiPostUserBAAIntegrationSessionResponseModel,
  ApiPostUserPreferencesRequestModel,
  ApiPostUserPreferencesResponseModel,
  ApiPutUserSettingResponseModel,
  ApiUserAccountsResponseModel,
  ApiUserInfoResponseModel,
  ApiUserModel,
  ApiUserNavResponseModel,
  ApiUserPasswordUpdate,
  ApiUserPasswordUpdateResponse,
  ApiUserSettingRequestModel,
  ApiUserUpdatableModel,
  ApiUserWebHookResponseModel,
} from '@halo-data-sources/models';

const BASE_PATH = '/issuer/v1';
const USER_PATH = `${BASE_PATH}/user`;
const COMMON_USER_PATH = '/common/user';
const COMMON_AUTH_PATH = '/common/auth/password';
const USER_INFO_KEY = 'userInfo';
const POST_TRADE_USERS = '/posttrade/users';

export const setUserInfo = (value: string): void => window.sessionStorage.setItem(USER_INFO_KEY, value);

export const fetchUserInfo = async (): Promise<ApiUserInfoResponseModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_USER_PATH);

  const response = await request<ApiUserInfoResponseModel>(`${clientConfiguration.basePath}/info`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const fetchUserTools = async (): Promise<ApiUserNavResponseModel> => {
  const clientConfiguration = await getClientConfiguration(USER_PATH);

  const response = await request<ApiUserNavResponseModel>(`${clientConfiguration.basePath}/nav`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const getUserAccounts = async (queryParams?: {
  accountName?: string;
  limit?: number;
}): Promise<ApiUserAccountsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(USER_PATH);

  const params: { name?: string; limit?: string; active: string } = { active: 'true' };
  if (queryParams?.accountName) params.name = queryParams.accountName;
  if (queryParams?.limit) params.limit = queryParams.limit.toString();

  const query = new URLSearchParams(params);
  const queryString = query.toString() ? `?${query.toString()}` : '';
  const path = `${clientConfiguration.basePath}/accounts/search${queryString}`;

  const response = await request<ApiUserAccountsResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const putUserSetting = async ({
  key,
  value,
}: ApiUserSettingRequestModel): Promise<ApiPutUserSettingResponseModel> => {
  const clientConfiguration = await getClientConfiguration(USER_PATH);

  const requestBody = {
    application: key,
    value,
    _csrf_token: clientConfiguration.csrfToken,
  };

  const settings = await request<ApiPutUserSettingResponseModel>(`${clientConfiguration.basePath}/settings`, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify(requestBody),
  });

  setUserInfo(JSON.stringify(settings));

  return settings;
};

export const obtainUserSettings = async (): Promise<{ [key: string]: string }> => {
  const clientConfiguration = await getClientConfiguration(USER_PATH);

  const settings = await request<{ [key: string]: string }>(`${clientConfiguration.basePath}/settings`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  setUserInfo(JSON.stringify(settings));

  return settings;
};

export const uploadUserSettingSalesforce = async (key: string): Promise<void> => {
  const clientConfiguration = await getClientConfiguration(USER_PATH);

  const requestBody = {
    application: 'user-settings',
    payload: key,
    _csrf_token: clientConfiguration.csrfToken,
  };

  await request<void>(`${clientConfiguration.basePath}/salesforce-upload`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify(requestBody),
  });
};

export const postUserProfile = async (data: Partial<ApiUserUpdatableModel>): Promise<ApiUserModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_USER_PATH);

  const requestBody = {
    ...data,
    _csrf_token: clientConfiguration.csrfToken,
  };

  const resp = await request<ApiUserModel>(`${clientConfiguration.basePath}/info`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify(requestBody),
  });

  return resp;
};

export const postPasswordChange = async (data: ApiUserPasswordUpdate): Promise<ApiUserPasswordUpdateResponse> => {
  const clientConfiguration = await getClientConfiguration(COMMON_AUTH_PATH);

  const requestBody = {
    ...data,
    _csrf_token: clientConfiguration.csrfToken,
  };

  const resp = await request<ApiUserPasswordUpdateResponse>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: JSON.stringify(requestBody),
  });

  return resp;
};

export const saveUserPreferences = async (
  data: ApiPostUserPreferencesRequestModel,
): Promise<ApiPostUserPreferencesResponseModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_USER_PATH);

  return await request<ApiPostUserPreferencesResponseModel>(`${clientConfiguration.basePath}/ui-preferences`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...data,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};

export const fetchUserWebHooks = async (): Promise<ApiUserWebHookResponseModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_USER_PATH);

  const response = await request<ApiUserWebHookResponseModel>(`${clientConfiguration.basePath}/webhooks`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const fetchBAAIntegrationSession = async (): Promise<ApiPostUserBAAIntegrationSessionResponseModel> => {
  const clientConfiguration = await getClientConfiguration(POST_TRADE_USERS);

  const response = await request<ApiPostUserBAAIntegrationSessionResponseModel>(
    `${clientConfiguration.basePath}/baa-sso`,
    {
      ...clientConfiguration.requestInit,
      method: 'POST',
      body: JSON.stringify({
        _csrf_token: clientConfiguration.csrfToken,
      }),
    },
  );

  return response;
};

export const fetchUserCommonSettings = async (): Promise<ApiCommonUserSettings> => {
  const clientConfiguration = await getClientConfiguration(COMMON_USER_PATH);

  const response = await request<ApiCommonUserSettings>(`${clientConfiguration.basePath}/settings`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};
