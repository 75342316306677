import { ReactElement } from 'react';

import {
  auctionItemAtom,
  auctionStepAtoms,
  selectedIssuersAtom,
  startAuctionFromLifecycleAtom,
} from '@halo-atoms/auctions';
import { AuctionStyleEnum } from '@halo-common/enums';
import { useModalState } from '@halo-common/hooks';
import { MUST_COMPLETE_ONBOARDING_MODAL } from '@halo-common/modals';
import { startAuctionSchema } from '@halo-common/schemas';
import { StrikeStyleEnum } from '@halo-data-sources/enums';
import { useRecordActivityMutation, useStartAuctionMutation } from '@halo-data-sources/mutations';
import { useIssuersQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { Iconography, Stack, useSnackbar } from '@halodomination/halo-fe-common';
import { Alert, Button, Typography } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { ValidationError } from 'yup';

import { AuctionDetailsInput } from './AuctionDetailsInput';
import { AuctionIssuersTable } from './AuctionIssuersTable';

const previousIcon = <Iconography iconName="arrow-left" />;
const nextIcon = <Iconography iconName="arrow-right" color="common.white" />;

export type AuctionIssuersPageProps = {
  noteId?: number;
};

export const AuctionIssuersPage = ({ noteId }: AuctionIssuersPageProps): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();
  const { error } = useIssuersQuery(true);

  const { mutate: startAuction, isPending, isSuccess } = useStartAuctionMutation();
  const { mutate: recordActivity } = useRecordActivityMutation();

  const { enqueueErrorEvent } = useSnackbar();

  const handlePrevious = useSetAtom(auctionStepAtoms.handlePrevAtom);
  const auctionItem = useAtomValue(auctionItemAtom);
  const selectedIssuers = useAtomValue(selectedIssuersAtom);
  const [startAuctionFromLifecycle, setStartAuctionFromLifecycle] = useAtom(startAuctionFromLifecycleAtom);

  const userOrganization = userInfo?.details.organization;

  // TODO: update to show success page with panel instead of redirecting to auciton page
  // once the panel component is merged in
  const shouldRedirect = isPending || isSuccess;
  const disableSubmit = shouldRedirect || selectedIssuers.length === 0;

  const canWriteAuctionDetails = userOrganization?.canWriteAuctionDetails;
  const auctionDetails = canWriteAuctionDetails ? <AuctionDetailsInput /> : null;

  const handleActivity = (verb: string) => {
    recordActivity({
      activity: [{ verb }],
      fromIFrame: true,
    });
  };

  const onPrevious = () => {
    handleActivity('set auction dates');
    handlePrevious();
  };

  const { isOnboardingCompleted } = userInfo?.organization || {};

  const { setOpen } = useModalState(MUST_COMPLETE_ONBOARDING_MODAL);

  const onSubmit = async () => {
    if (!isOnboardingCompleted) {
      setOpen(true);
      return;
    }

    const postData = {
      ...auctionItem,
      noteId,
      auctionStyle: AuctionStyleEnum.Choose,
      strikeMode: StrikeStyleEnum.CLOSE,
      allocations: [] as Array<number>,
    };

    try {
      const validPostData = await startAuctionSchema.validate(postData);
      startAuction(validPostData);
      if (startAuctionFromLifecycle) {
        handleActivity('submitted auction via reinvestment');
        setStartAuctionFromLifecycle(false);
      }
    } catch (_: unknown) {
      const { message } = _ as ValidationError;
      enqueueErrorEvent({ message });
    }
  };

  if (error) {
    return (
      <Alert severity="error" variant="outlined">
        {error.message}
      </Alert>
    );
  }

  return (
    <Stack direction="column" spacing={15}>
      <Stack direction="column" spacing={5}>
        <Stack direction="column" spacing={3}>
          <Typography variant="h4">Which issuers would you like to include in the auction?</Typography>
        </Stack>
        <AuctionIssuersTable />
        {auctionDetails}
      </Stack>
      <Stack direction="row" justify="space-between">
        <Button onClick={onPrevious} startIcon={previousIcon}>
          Change Trade Date
        </Button>
        <Button variant="contained" endIcon={nextIcon} onClick={onSubmit} disabled={disableSubmit}>
          Send to auction
        </Button>
      </Stack>
    </Stack>
  );
};
