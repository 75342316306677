import { ReactElement } from 'react';

import { V2AdminCalendarCard } from '@halo-common/components';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { AllocationModel, CalendarModel } from '@halo-common/models';
import { navigateParentTo } from '@halo-common/utils';
import { useComplianceApprovalColumns } from '@halo-modules/admin';
import { HaloDataGrid, Iconography } from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';

const cardSx = { m: 2 };

const tableSx = {
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  [`& .executionHubDividerHeader`]: {
    borderRightWidth: 2,
  },
  [`& .executionHubDividerCell`]: {
    borderRightWidth: 2,
  },
  [`& .MuiDataGrid-row--lastVisible`]: {
    backgroundColor: 'background.default',
  },
};

export type ComplianceApprovalCalendarCardProps = {
  calendar?: CalendarModel;
  allocations?: Array<AllocationModel>;
  loading?: boolean;
};

export const ComplianceApprovalCalendarCard = ({
  calendar,
  loading,
  allocations,
}: ComplianceApprovalCalendarCardProps): ReactElement => {
  const formatDate = useWhiteLabelDateConfiguration();

  const columns = useComplianceApprovalColumns(calendar);

  const calendarId = calendar?.id;
  const closeDate = formatDate(calendar?.expirationDate);
  const settleDate = formatDate(calendar?.settleDate);

  const formattedCloseDate = closeDate?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
  const formattedSettleDate = settleDate?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);

  const calendarCardColumns = [];
  if (formattedCloseDate) calendarCardColumns.push({ label: 'Closes', value: formattedCloseDate });
  if (formattedSettleDate) calendarCardColumns.push({ label: 'Settles', value: formattedSettleDate });

  const actions = (
    <Button
      type="button"
      variant="text"
      color="primary"
      disabled={typeof calendarId !== 'number'}
      onClick={() => navigateParentTo(`/calendar/preview/${calendarId}`)}
      startIcon={<Iconography prefix="fas" iconName="eye" color="primary.main" />}
    >
      Preview
    </Button>
  );

  const table = allocations?.length ? (
    <HaloDataGrid
      sx={tableSx}
      columns={columns}
      loading={loading}
      rows={allocations}
      density="compact"
      disableRowSelectionOnClick
      disableColumnSelector
    />
  ) : null;

  return (
    <V2AdminCalendarCard
      sx={cardSx}
      calendar={calendar}
      loading={loading}
      columns={calendarCardColumns}
      actions={actions}
    >
      {table}
    </V2AdminCalendarCard>
  );
};
