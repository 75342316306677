import { ReactElement } from 'react';

import { Stack } from '@halodomination/halo-fe-common';
import { Divider, Skeleton, useMediaQuery, useTheme } from '@mui/material';

const dividerSx = { mt: 4 };

export const TargetedWatchlistSkeleton = (): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dummyArray = [...Array(4).keys()];

  const titleSpacing = isMobile ? 0 : 1;

  return (
    <Stack direction="column" spacing={3}>
      <Stack direction="row" spacing={1}>
        <Skeleton variant="circular" width={80} height={80} />
        <Stack direction="column" spacing={titleSpacing}>
          <Skeleton variant="rounded" width={272} height={38} />
          <Skeleton variant="rounded" width={150} height={22} />
        </Stack>
      </Stack>
      <Stack direction="column" spacing={2}>
        {dummyArray.map((key, index) => (
          <Stack key={key} direction="column">
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              wrap="nowrap"
              elementStyling={[undefined, { flex: 1 }]}
            >
              <Skeleton variant="rounded" width={348} height={330} />
              <Skeleton variant="rounded" width="100%" height={352} />
            </Stack>
            {index !== dummyArray.length - 1 ? <Divider sx={dividerSx} /> : null}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
