import { ReactElement } from 'react';

import { productDetailModalAnalyticsTabStateAtom } from '@halo-atoms/pdm';
import {
  ProductDetailModalAnalyticsRollingReturnsStatisticsTableLayout,
  ProductDetailModalAnalyticsRollingReturnsSummary,
  ProductDetailModalAnalyticsRollingReturnsTable,
  ProductDetailModalAnalyticsRollingReturnsTableProps,
} from '@halo-common/layouts';
import { RollingReturnsStatisticsQueryResult } from '@halo-data-sources/queries';
import { Box, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';

export type ProductDetailModalAnalyticsRollingReturnsPrincipalReturnTableProps = {
  data?: RollingReturnsStatisticsQueryResult | null;
  loading?: boolean;
  title: string;
  showTreeData: boolean;
  showAgainstNote: boolean;
  showAverageLife: boolean;
};

export const ProductDetailModalAnalyticsRollingReturnsPrincipalReturnTable = ({
  data,
  title,
  loading = false,
  showTreeData,
  showAgainstNote,
  showAverageLife,
}: ProductDetailModalAnalyticsRollingReturnsPrincipalReturnTableProps): ReactElement => {
  const state = useAtomValue(productDetailModalAnalyticsTabStateAtom);

  const { daysBetween: days, dateRangeText } = state;

  const rows = data?.principalReturns ?? [];

  const underlyingData = rows[0];
  const noteData = rows[1];

  const noteFull = noteData?.full;
  const noteAverage = noteData?.average;
  const noteWorst = noteData?.worst;
  const underlyingFull = underlyingData?.full;
  const underlyingAverage = underlyingData?.average;
  const underlyingWorst = underlyingData?.worst;

  const hasColumnOneData = typeof noteFull === 'number' && typeof underlyingFull === 'number';
  const hasColumnTwoData = typeof noteAverage === 'number' && typeof underlyingAverage === 'number';
  const hasColumnThreeData = typeof noteWorst === 'number' && typeof underlyingWorst === 'number';

  const columnOneDifference = hasColumnOneData ? noteFull - underlyingFull : undefined;
  const columnTwoDifference = hasColumnTwoData ? noteAverage - underlyingAverage : undefined;
  const columnThreeDifference = hasColumnThreeData ? noteWorst - underlyingWorst : undefined;

  const formattedTitle = showAgainstNote
    ? `Note vs. ${title} Principal Returned Comparison`
    : `${title} Principal Returned`;

  const defaultColumns: ProductDetailModalAnalyticsRollingReturnsTableProps['columns'] = [
    {
      field: 'full',
      headerName: '% Time Full Principal Returned',
      headerInfo: `The percentage of outcomes where the full principal was returned at maturity when analyzing the ${days} Rolling Return windows between ${dateRangeText}.`,
    },
    {
      field: 'average',
      headerName: 'Average Principal Returned',
      headerInfo: `The average return of principal at maturity when analyzing the ${days} Rolling Return windows between ${dateRangeText}.`,
    },
    {
      field: 'worst',
      headerName: 'Worst Principal Returned',
      headerInfo: `The worst return of principal at maturity when analyzing the ${days} Rolling Return windows between ${dateRangeText}.`,
    },
  ];

  const columns: ProductDetailModalAnalyticsRollingReturnsTableProps['columns'] = showAverageLife
    ? [
        {
          field: 'lifeSpan',
          headerName: 'Average Lifetime',
          headerInfo: `The average lifetime of the note, considering the callability features of the note, when analyzing the ${days} Rolling Return windows between ${dateRangeText}.`,
          valueFormatter: (value: number) => {
            const isSingular = value === 1;
            const monthText = isSingular ? 'Month' : 'Months';
            return value ? `${value} ${monthText}` : 'N/A';
          },
        },
        ...defaultColumns,
      ]
    : defaultColumns;

  const comparisons = showAgainstNote ? (
    <Stack direction="row" alignItems="center" justifyContent="flex-end">
      <Box sx={{ flex: 0.4 }} />
      {showAverageLife ? <Box sx={{ flex: 1 }} /> : null}
      <ProductDetailModalAnalyticsRollingReturnsSummary
        label="Difference"
        value={columnOneDifference}
        loading={loading}
      />
      <ProductDetailModalAnalyticsRollingReturnsSummary
        label="Difference"
        value={columnTwoDifference}
        loading={loading}
      />
      <ProductDetailModalAnalyticsRollingReturnsSummary
        label="Difference"
        value={columnThreeDifference}
        loading={loading}
      />
    </Stack>
  ) : null;

  return (
    <ProductDetailModalAnalyticsRollingReturnsStatisticsTableLayout title={formattedTitle} loading={loading}>
      <ProductDetailModalAnalyticsRollingReturnsTable
        columns={columns}
        rows={rows}
        loading={loading}
        treeData={showTreeData}
      />
      {comparisons}
    </ProductDetailModalAnalyticsRollingReturnsStatisticsTableLayout>
  );
};
