import { ReactElement } from 'react';

import { quoteValueMapAtom } from '@halo-atoms/quotes';
import { NoteTypeEnum } from '@halo-common/enums';
import { useQuoteSelector } from '@halo-common/hooks';
import { NoteModel } from '@halo-common/models';
import { useWebSocketContext } from '@halo-common/providers';
import { translations } from '@halo-common/translations';
import { useQuoteRefreshMutation } from '@halo-data-sources/mutations';
import { useRollingReturnsChartQuery } from '@halo-data-sources/queries';
import { LocalizedAlert, LocalizedButton, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { CircularProgress } from '@mui/material';
import { useAtomValue } from 'jotai';

const actionBasedAlertSx = { alignItems: 'flex-start', padding: 1 };
const textBasedAlertSx = { alignItems: 'center', padding: 1 };

export type ProductDetailModalAlertProps = {
  product?: NoteModel | null;
  onRefresh?: () => void;
  hideErrorMessage?: boolean;
  hideFetchingQuoteMessage?: boolean;
  hideIssuerCallMessage?: boolean;
  hideNoPriceMessage?: boolean;
  hideRecallMessage?: boolean;
  hideGeneralNoteMessage?: boolean;
};

export const ProductDetailModalAlert = ({
  product,
  onRefresh,
  hideErrorMessage = false,
  hideFetchingQuoteMessage = false,
  hideIssuerCallMessage = false,
  hideNoPriceMessage = false,
  hideRecallMessage = false,
  hideGeneralNoteMessage = false,
}: ProductDetailModalAlertProps): ReactElement | null => {
  const quoteRefreshedMap = useAtomValue(quoteValueMapAtom);

  const { error } = useRollingReturnsChartQuery(product);

  const productId = product?.id;
  const isRecall = product?.coupon?.isRecall;
  const isIssuerCall = product?.callInformation?.isIssuerCall;
  const isGeneralNote = product?.type === NoteTypeEnum.General;

  const { mutate } = useQuoteRefreshMutation();
  const { events } = useWebSocketContext();
  const quote = useQuoteSelector(productId);

  if (!productId) return null;

  const isFetchingQuote = events.quote.contains(productId);
  const hasUpdatedQuote = Boolean(quote && quoteRefreshedMap[productId]?.updated);
  const hasNoPrice = !quote && !hasUpdatedQuote;

  const showErrorMessage = !hideErrorMessage && error;
  const showGeneralNoteMessage = !hideGeneralNoteMessage && isGeneralNote;
  const showRecallMessage = !hideRecallMessage && isRecall;
  const showIssuerCallMessage = !hideIssuerCallMessage && isIssuerCall;
  const showFetchingQuoteMessage = !hideFetchingQuoteMessage && !isFetchingQuote;
  const showNoPriceMessage = !hideNoPriceMessage && hasNoPrice && !isGeneralNote;

  const handleRefreshChart = () => void onRefresh?.();
  const handleGetPrice = () => mutate(productId);

  const loadingIndicator = isFetchingQuote ? <CircularProgress size={16} /> : undefined;

  const errorAlert = showErrorMessage ? (
    <LocalizedAlert sx={textBasedAlertSx} severity="warning" variant="filled">
      {error.message}
    </LocalizedAlert>
  ) : null;

  const generalNoteAlert = showGeneralNoteMessage ? (
    <LocalizedAlert sx={textBasedAlertSx} severity="info" variant="filled">
      {translations.pdm.preTrade.rollingReturnsGeneralSupportMessage}
    </LocalizedAlert>
  ) : null;

  const recallAlert = showRecallMessage ? (
    <LocalizedAlert sx={textBasedAlertSx} severity="info" variant="filled">
      {translations.pdm.preTrade.rollingReturnsRecallSupportMessage}
    </LocalizedAlert>
  ) : null;

  const issuerCallAlert = showIssuerCallMessage ? (
    <LocalizedAlert sx={textBasedAlertSx} severity="info" variant="filled">
      {translations.pdm.preTrade.issuerCallVisibilityMessage}
    </LocalizedAlert>
  ) : null;

  const getPriceButton = showFetchingQuoteMessage ? (
    <LocalizedButton
      disableRipple
      className="wm-rr-find-price-button"
      variant="text"
      size="small"
      onClick={handleGetPrice}
    >
      {translations.pdm.preTrade.getPrice}
    </LocalizedButton>
  ) : null;

  const noPriceAlert = showNoPriceMessage ? (
    <LocalizedAlert
      sx={!isFetchingQuote ? actionBasedAlertSx : textBasedAlertSx}
      severity="info"
      variant="filled"
      icon={loadingIndicator}
    >
      <Stack direction="column" justify="center">
        <LocalizedTypography variant="body2">{translations.pdm.preTrade.thisNoteHasNoPrice}</LocalizedTypography>
        {getPriceButton}
      </Stack>
    </LocalizedAlert>
  ) : null;

  const updatedPriceAlert = hasUpdatedQuote ? (
    <LocalizedAlert sx={actionBasedAlertSx} severity="warning" variant="filled">
      <Stack direction="column" justify="center">
        <LocalizedTypography variant="body2">{translations.pdm.preTrade.thisNoteHasAnUpdatedPrice}</LocalizedTypography>
        <LocalizedButton variant="text" size="small" onClick={handleRefreshChart}>
          {translations.pdm.preTrade.refreshChart}
        </LocalizedButton>
      </Stack>
    </LocalizedAlert>
  ) : null;

  return recallAlert ?? generalNoteAlert ?? issuerCallAlert ?? errorAlert ?? noPriceAlert ?? updatedPriceAlert ?? null;
};
