import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { LocalizedButton } from '@halodomination/halo-fe-common';

export const DownloadTemplateButton = (): ReactElement => {
  const handleDownloadTemplate = () => {
    const fileUrl = 'https://notes.haloinvesting.com/assets/public/bulk_allocation_upload_orderbook_template.xlsx';
    window.open(fileUrl, '_blank');
  };

  return (
    <LocalizedButton variant="text" color="primary" onClick={handleDownloadTemplate}>
      {translations.orderBook.auctions.downloadTemplate}
    </LocalizedButton>
  );
};
