import { AuctionAdminModel, AuctionBuyerModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { rejectBuyerAuctionTerms } from '@halo-data-sources/clients';
import { ApiAuctionMapper } from '@halo-data-sources/mappers';
import { AuctionQueryKeyFactory } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

const buyerAuctionRejectTermsMutationFn = async (auctionId: number): Promise<AuctionAdminModel> => {
  const response = await rejectBuyerAuctionTerms(auctionId);
  return ApiAuctionMapper.toAdminAuctionModel(response);
};

export const useBuyerAuctionRejectTermsMutation = (): UseMutationResult<AuctionAdminModel, Error, number> => {
  const queryClient = useQueryClient();
  const { enqueueErrorEvent } = useSnackbar();

  return useMutation<AuctionAdminModel, Error, number>({
    mutationFn: buyerAuctionRejectTermsMutationFn,
    onSuccess: (data) => {
      const auctionQueryKey = AuctionQueryKeyFactory.buyerAuction(data.id?.toString());
      queryClient.setQueryData<AuctionBuyerModel>(auctionQueryKey, (prev) => {
        if (!prev) return undefined;
        return { ...prev, status: data.status, ergStatus: data.ergStatus };
      });
    },
    onError: () => {
      enqueueErrorEvent({ message: translations.common.error });
    },
  });
};
