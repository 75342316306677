import { ReactElement } from 'react';

import { useCombinedStyling } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { SxProps, Typography } from '@mui/material';

export type EducationHubPercentageCellProps = {
  sx?: SxProps<HaloTheme>;
  percentComplete: number;
};

export const EducationHubPercentageCell = ({ percentComplete, sx }: EducationHubPercentageCellProps): ReactElement => {
  const completed = percentComplete >= 100;
  const styling = { color: completed ? 'success.main' : 'secondary.text' };

  const combinedStyling = useCombinedStyling(styling, sx);

  return (
    <Typography variant="body2" sx={combinedStyling}>
      {percentComplete.toFixed(2)}%
    </Typography>
  );
};
