import { ReactElement, ReactNode } from 'react';

import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Box, Paper, Stack, StackProps } from '@mui/material';

const paperSx = {
  p: 3,
  width: '100%',
  height: '100%',
};

const stackSx = {
  height: '100%',
};

const rightContentSx = {
  alignSelf: 'center',
};

const direction: StackProps['direction'] = {
  lg: 'row',
  md: 'column',
  sm: 'row',
  xs: 'column',
};

export interface PortfolioPositionsCardProps {
  title?: ReactNode;
  leftContent?: ReactNode;
  rightContent?: ReactNode;
}

export const PortfolioPositionsCard = ({
  title,
  leftContent,
  rightContent,
}: PortfolioPositionsCardProps): ReactElement | null => {
  const cardTitle = title ? (
    <LocalizedTypography variant="subtitle1" mb={3} fontWeight={600}>
      {title}
    </LocalizedTypography>
  ) : null;

  return (
    <Paper variant="outlined" sx={paperSx}>
      <Stack direction={direction} alignItems="flex-start" justifyContent="space-between" spacing={2} sx={stackSx}>
        <Stack direction="column">
          {cardTitle}
          {leftContent}
        </Stack>
        <Box sx={rightContentSx}>{rightContent}</Box>
      </Stack>
    </Paper>
  );
};
