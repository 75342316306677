import { useCallback } from 'react';

import { modalAtom, ModalAtomModel } from '@halo-atoms/common';
import { useAtom } from 'jotai';

export type useModalStateReturn = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  modalMap: ModalAtomModel;
  setModalMap: (map: ModalAtomModel) => void;
};

export const useModalState = (id: string): useModalStateReturn => {
  const [modalMap, setModalMap] = useAtom(modalAtom);

  const open = !!modalMap[id];

  const setOpen = useCallback(
    (isOpen: boolean) =>
      setModalMap({
        [id]: isOpen,
      }),
    [id, setModalMap],
  );

  return {
    open,
    setOpen,
    modalMap,
    setModalMap,
  };
};
