import { useUserInfoQuery } from '@halo-data-sources/queries';

export type LexiconTranslatorValue = Array<string | undefined> | string | undefined;

export type LexiconTranslatorOptions = {
  translationKeyMap?: {
    [originalText: string]: string;
  };
  overriddenLexicon?: {
    [textToTranslate: string]: string;
  };
};

export const useLexiconTranslator = (
  value: LexiconTranslatorValue,
  { translationKeyMap, overriddenLexicon }: LexiconTranslatorOptions = {},
): Array<string> => {
  const { data: user } = useUserInfoQuery();
  const userLexicon = user?.lexicon ?? {};

  const lexicon = overriddenLexicon ?? userLexicon;

  const translate = (text?: string) => {
    if (!text) return '';

    const translationKey = translationKeyMap ? translationKeyMap[text] : text;
    const translation = lexicon[translationKey];

    if (!translation) return text;
    else if (translationKeyMap) return text.replace(translationKey, translation);
    else return translation;
  };

  if (Array.isArray(value)) {
    return value.map(translate);
  }

  return [translate(value)];
};
