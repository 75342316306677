import { useCallback } from 'react';

import { hasTimeComponent } from '@halo-common/utils';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { DateTime } from 'luxon';

/**
 * @deprecated When Pershing is migrated to new data management flow
 * - Use useWhiteLabelDateConfiguration instead with any new development
 */
export const useDateFormatter = (options?: {
  ignoreWhiteLabel?: boolean;
  ignoreWhiteLabelDateFormat?: boolean;
  ignoreWhiteLabelTimeZone?: boolean;
  timeZoneOverride?: string;
}): ((date?: string | null, format?: string) => string) => {
  const { data: user } = useUserInfoQuery();

  const timeZoneOverride = options?.timeZoneOverride;
  const ignoreWhiteLabelTimeZone = options?.ignoreWhiteLabelTimeZone;
  const ignoreWhiteLabelDateFormat = options?.ignoreWhiteLabelDateFormat;
  const ignoreWhiteLabel = options?.ignoreWhiteLabel;

  return (date?: string | null, format?: string) => {
    if (!date) return '';

    const shouldIgnoreWhiteLabelTimeZone = ignoreWhiteLabel || ignoreWhiteLabelTimeZone || timeZoneOverride;
    const timezone = shouldIgnoreWhiteLabelTimeZone ? timeZoneOverride : user?.whiteLabel.timezoneIANA;

    const shouldIgnoreWhiteLabelDateFormat = ignoreWhiteLabel || ignoreWhiteLabelDateFormat || format;
    const dateFormat = shouldIgnoreWhiteLabelDateFormat ? format : user?.whiteLabel.dateFormat;

    const isDateMissingTimeOffset = timezone && date.includes('T') && !date.includes('Z');

    const offsetRegex = /[-+]\d\d:\d\d/;
    const hasTimeZoneOffset = offsetRegex.test(date);
    const shouldAddZulu = isDateMissingTimeOffset && !hasTimeZoneOffset;

    const parsedDate = shouldAddZulu ? `${date}Z` : date;
    const dateObject = timezone ? DateTime.fromISO(parsedDate).setZone(timezone) : DateTime.fromISO(parsedDate);

    return dateFormat ? dateObject.toFormat(dateFormat) : dateObject.toLocaleString();
  };
};

export type WhiteLabelDateConfigurationFn = (
  date?: string | null,
  options?: { disableUTCConversion?: boolean },
) => DateTime | null;

/**
 * Hook to convert an ISO date string into a DateTime object that adheres to the configuration of a white label.
 * @returns {WhiteLabelDateConfigurationFn} A function that takes in a ISO date string, converts it to an object,
 * and updates it to use the white label's date configuration.
 * - Keep in mind if a white label doesn't have a timezone configured it will use local.
 * - Additionally, the BE _usually_ sends the data in UTC. However, this isn't always true. If you are seeing
 *   issues with the date conversion utilize the `disableUTCConversion` option in the function.
 */
export const useWhiteLabelDateConfiguration = (): WhiteLabelDateConfigurationFn => {
  const { data: user } = useUserInfoQuery();

  return useCallback(
    (date, options) => {
      if (!date) return null;

      const enableUTCConversion = !options?.disableUTCConversion && hasTimeComponent(date);
      const timezone = user?.whiteLabel?.timezoneIANA ?? 'local';
      const isoDateOptions = enableUTCConversion ? { zone: 'utc' } : undefined;

      return DateTime.fromISO(date, isoDateOptions).setZone(timezone);
    },
    [user?.whiteLabel?.timezoneIANA],
  );
};
