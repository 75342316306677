import { ReactElement } from 'react';

import { addPositionsToAccountModalAtom } from '@halo-atoms/portfolio';
import { AssetIdentifierEnum, AssetIdLocationEnum } from '@halo-common/enums';
import { AssetIdLocation } from '@halo-common/models';
import { AddPositionsToAccountFormFields } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { AllocationDetailsColumn } from '@halo-modules/app/portfolio/components';
import { CurrencyInput } from '@halodomination/halo-fe-common';
import { Divider, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useController, useFormContext } from 'react-hook-form';

export type PositionAllocationListItemProps = {
  assetId?: string;
  location?: AssetIdLocation;
  assetIdType?: string;
  showDivider?: boolean;
  error?: string;
  name: `positions.${number}.notional`;
};

export const PositionAllocationListItem = ({
  assetId,
  assetIdType,
  location,
  error,
  name,
  showDivider = false,
}: PositionAllocationListItemProps): ReactElement => {
  const { selectedAccountAtom } = useAtomValue(addPositionsToAccountModalAtom);

  const { control } = useFormContext<AddPositionsToAccountFormFields>();

  const {
    field: { ref: currencyFieldRef, ...currencyFieldProps },
  } = useController({ name, control, rules: { required: true, min: 1 } });

  const accountName = selectedAccountAtom?.name;
  const accountId = selectedAccountAtom?.accountId;
  const custodian = selectedAccountAtom?.custodian;

  const nameOrYourAccount = accountName ?? translations.portfolio.common.yourAccountTitle;
  const hasAccountTextRequirements = accountName && accountId && custodian?.name;
  const fullAccountText = hasAccountTextRequirements ? `${accountName} - ${custodian.name} #${accountId}` : accountName;

  const accountText = hasAccountTextRequirements ? fullAccountText : nameOrYourAccount;

  const assetIdTypeText = assetIdType === AssetIdentifierEnum.FUNDSERVCODE ? 'FundServ' : assetIdType;
  const isEdgarLocation = location === AssetIdLocationEnum.edgar;
  const hasError = Boolean(error);

  const divider = showDivider ? <Divider /> : null;

  return (
    <Stack direction="column" spacing={3}>
      {divider}
      <Stack direction="row" spacing={3} alignItems="center">
        <AllocationDetailsColumn
          label={assetIdTypeText}
          value={assetId}
          isQueued={isEdgarLocation}
          showTooltip={isEdgarLocation}
          sx={{ flex: 1 }}
        />
        <AllocationDetailsColumn
          label={translations.common.account}
          value={accountText}
          isQueued={isEdgarLocation}
          sx={{ flex: 2 }}
        />
        <CurrencyInput
          {...currencyFieldProps}
          size="large"
          inputRef={currencyFieldRef}
          fullWidth
          label={translations.common.notional}
          valueType="number"
          error={hasError}
          helperText={error}
          sx={{ flex: 1 }}
        />
      </Stack>
    </Stack>
  );
};
