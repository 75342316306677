import { ReactElement } from 'react';

import { ProductDetailPanelSection } from '@halo-common/layouts';
import { ExecutionCalendarDetailLifecycleSectionField } from '@halo-common/modals';
import { CalendarModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';

export type ExecutionCalendarDetailLifecycleSectionProps = {
  product?: CalendarModel | null;
  loading?: boolean;
};

export const ExecutionCalendarDetailLifecycleSection = ({
  product,
  loading = true,
}: ExecutionCalendarDetailLifecycleSectionProps): ReactElement | null => {
  const settlementDate = product?.settleDate;
  const tradeDate = product?.tradeDate;

  const hideSection = !loading && !settlementDate && !tradeDate;

  if (hideSection) return null;

  const showSettlementDateField = loading || settlementDate;
  const settlementDateField = showSettlementDateField ? (
    <ExecutionCalendarDetailLifecycleSectionField
      title={translations.pdm.common.settlementDate}
      value={settlementDate}
      loading={loading}
    />
  ) : null;

  const showTradeDateField = loading || tradeDate;
  const tradeDateField = showTradeDateField ? (
    <ExecutionCalendarDetailLifecycleSectionField
      title={translations.pdm.common.tradeDate}
      value={tradeDate}
      loading={loading}
    />
  ) : null;

  return (
    <ProductDetailPanelSection title={translations.common.lifecycle} loading={loading}>
      {settlementDateField}
      {tradeDateField}
    </ProductDetailPanelSection>
  );
};
