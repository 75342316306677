import { ReactElement, useEffect, useMemo, useState } from 'react';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import { PageSubLayout } from '@halo-common/layouts';
import {
  LegacyRouterProvider,
  LegacyWebSocketProvider,
  UserProvider,
  WalkMeProvider,
  WebSocketProvider,
} from '@halo-common/providers';
import { postMessageService } from '@halo-common/utils';
import { setConfirmPasswordToken, setCsrfToken, setUserInfo } from '@halo-data-sources/clients';
import { configureStore } from '@halo-stores/configureStore';
import { PageMeta, SnackbarProvider, publicPath } from '@halodomination/halo-fe-common';
import { ThemeProvider } from '@halodomination/halo-fe-theme';
import { Link } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { LicenseInfo } from '@mui/x-license';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { NextComponentType } from 'next';
import { AppProps as NextAppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';
import { Provider } from 'react-redux';

const TransifexProvider = dynamic(
  () => import('../common/providers/TransifexProvider').then((module) => module.TransifexProvider),
  { ssr: false },
);

const QueryClientProvider = dynamic(
  () => import('@tanstack/react-query').then((module) => module.QueryClientProvider),
  { ssr: false },
);

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_DATA_GRID as string);
const skipToMainContentLinkSx = {
  '&:not(:focus):not(:focus-within)': {
    border: 0,
    padding: 0,
    margin: 0,
    position: 'absolute !important',
    height: '1px',
    width: '1px',
    overflow: 'hidden',
    clip: 'rect(1px 1px 1px 1px)',
    clipPath: 'inset(50%)',
    whiteSpace: 'nowrap',
  },
};

export type AppProps = Omit<NextAppProps, 'Component'> & { Component: NextComponentType & PageMeta };

const Application = ({ Component, pageProps }: AppProps): ReactElement => {
  const { store } = useMemo(() => configureStore(), []);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            staleTime: 60000,
          },
        },
      }),
  );

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    jssStyles?.parentElement?.removeChild(jssStyles);

    // Load EventListener for IFrame
    postMessageService(window).subscribe({
      AUTHENTICATION: (payload?: { csrfToken?: string }) => {
        if (payload?.csrfToken) setCsrfToken(payload.csrfToken);
      },
      SET_PASSWORD_TOKEN: (payload?: { token?: string; userInfo?: string; whiteLabel?: string }) => {
        if (payload?.token) setConfirmPasswordToken(payload.token);
        if (payload?.userInfo) setUserInfo(payload.userInfo);
      },
    });
  }, []);

  return (
    <>
      <Link sx={skipToMainContentLinkSx} href="#main">
        Skip to main content
      </Link>
      <Head>
        <meta charSet="utf-8" />
        <meta content="width=device-width,initial-scale=1" name="viewport" />
        <title>Halo Investing</title>
      </Head>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Provider store={store}>
          <ThemeProvider>
            <UserProvider>
              <TransifexProvider>
                <WalkMeProvider>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <LegacyRouterProvider>
                      <WebSocketProvider>
                        <LegacyWebSocketProvider>
                          <SnackbarProvider>
                            <PageSubLayout Component={Component} pageProps={pageProps} />
                          </SnackbarProvider>
                        </LegacyWebSocketProvider>
                      </WebSocketProvider>
                    </LegacyRouterProvider>
                  </LocalizationProvider>
                </WalkMeProvider>
              </TransifexProvider>
            </UserProvider>
          </ThemeProvider>
        </Provider>
      </QueryClientProvider>
      <Script defer src={publicPath('/scripts/iframeResizer.contentWindow.min.js')} type="text/javascript" />
    </>
  );
};

export default Application;
