import { useEffect, useRef } from 'react';

import { useMobileView } from '@halo-common/hooks';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { PageMessageLayout } from '@halo-modules/app';
import { OnboardingActions } from '@halo-stores/Onboarding';
import { Iconography, PageComponent, PageMeta } from '@halodomination/halo-fe-common';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';

const mainSx = {
  background: 'linear-gradient(90deg, #3F4494 0%, #252561 100%)',
  height: '100vh',
  width: '100vw',
};

const AwaitRegistration: PageComponent = () => {
  const dispatch = useDispatch();

  const isSmallScreen = useMobileView();

  const { data: userInfo, isPending } = useUserInfoQuery();

  const registerRef = useRef<HTMLDivElement | null>(null);

  const whiteLabel = userInfo?.whiteLabel;
  const userFacingName = whiteLabel?.userFacingName;

  const iconColor = isSmallScreen ? 'primary.contrastText' : 'primary.main';
  const subText = `A member of ${userFacingName} team will reach out soon to complete your registration process.`;

  useEffect(() => {
    dispatch(OnboardingActions.resetStatus());
    registerRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  return (
    <Box ref={registerRef} component="main" sx={mainSx}>
      <PageMessageLayout
        buttonText="Refresh Page"
        icon={<Iconography iconName="file-user" color={iconColor} size="5x" />}
        subText={subText}
        titleText="We’re processing your registration!"
        loading={isPending}
      />
    </Box>
  );
};

export const AwaitRegistrationPageMeta: PageMeta = {
  pageName: 'AwaitRegistration',
  route: '/app/onboarding/await-registration',
  iconName: 'mailbox',
};

AwaitRegistration.pageName = AwaitRegistrationPageMeta.pageName;
AwaitRegistration.route = AwaitRegistrationPageMeta.route;
AwaitRegistration.iconName = AwaitRegistrationPageMeta.iconName;

export default AwaitRegistration;
