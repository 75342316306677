import { translations } from '@halo-common/translations';
import { downloadFile } from '@halo-common/utils';
import { getPortfolioPositionPerformanceReport } from '@halo-data-sources/clients';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';

const pendingMsg = translations.messages.generateHistoricalReportPending;
const successMsg = translations.messages.generateHistoricalReportSuccess;
const errorMsg = translations.pdm.postTrade.generatePerformanceReportError;

export type GeneratePositionHistoricalReportQuery = {
  account_id?: string;
  advisee_id?: string;
  term_sheet_id?: string;
};

export type GeneratePositionHistoricalReportRequest = {
  accountId?: number;
  adviseeId?: number;
  termsheetId?: number;
  reportingCurrencyId?: number;
  assetIdentifier: string;
  productDescriptor: string;
  userId?: number;
};

const generateHistoricalReportMutationFn = async (request: GeneratePositionHistoricalReportRequest) => {
  const { accountId, adviseeId, termsheetId, userId, productDescriptor, assetIdentifier } = request;

  if (!accountId && !adviseeId && !userId) return null;

  const payload = {
    account_selector: {
      account_ids: accountId ? [accountId] : undefined,
      advisee_ids: adviseeId ? [adviseeId] : undefined,
      synced: null,
    },
    term_sheet_id: termsheetId,
  };

  const response = await getPortfolioPositionPerformanceReport(payload);

  const fileName = `${assetIdentifier}_Report_${uuid().substring(0, 7)}`;

  downloadFile({ fileName, downloadFilePath: response.download_url });

  return productDescriptor;
};

export const useGenerateHistoricalReportMutation = (): UseMutationResult<
  string | null,
  Error,
  GeneratePositionHistoricalReportRequest
> => {
  const { enqueueSuccessEvent, enqueuePendingEvent, enqueueWarningEvent, closeSnackbar } = useSnackbar();

  return useMutation<string | null, Error, GeneratePositionHistoricalReportRequest>({
    mutationFn: generateHistoricalReportMutationFn,
    onMutate: (request) => {
      enqueuePendingEvent({ message: pendingMsg, subMessage: request.productDescriptor });
    },
    onError: (_, request) => {
      closeSnackbar();
      enqueueWarningEvent({ message: errorMsg, subMessage: request.productDescriptor });
    },
    onSuccess: (productDescriptor) => {
      closeSnackbar();
      enqueueSuccessEvent({ message: successMsg, subMessage: productDescriptor ?? undefined });
    },
  });
};
