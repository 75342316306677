import { ReactElement } from 'react';

import { Iconography } from '@halodomination/halo-fe-common';
import { Alert } from '@mui/material';

const alertSx = {
  px: 2,
  py: 1,
};

export type PassedReasonFooterProps = {
  reason?: string;
};

export const PassedReasonFooter = ({ reason }: PassedReasonFooterProps): ReactElement | null => {
  if (!reason) return null;

  const commentIcon = <Iconography iconName="comment" />;

  return (
    <Alert severity="info" variant="filled" icon={commentIcon} sx={alertSx}>
      {reason}
    </Alert>
  );
};
