import { calendarManagerAtom, calendarSelectedAtom } from '@halo-atoms/calendars';
import { AllocationModel, CalendarModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { postCalendarAllocationRequest, submitCalendarAllocationRequest } from '@halo-data-sources/clients';
import { ApiCalendarsMapper } from '@halo-data-sources/mappers';
import { ApiCalendarAllocationModel } from '@halo-data-sources/models';
import {
  CalendarsQueryKeyFactory,
  FeaturedCalendarQueryKeyFactory,
  OrderBookCalendarQueryKeyFactory,
  ProductApprovalCalendarResult,
} from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import {
  InfiniteData,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';

export type CalendarAllocationModel = {
  account?: number;
  quantity?: string | number;
  attestation?: boolean;
};

export type CalendarAllocationMutationOptions = {
  calendarId?: number | null;
  calendarPageId?: number | null;
  allocations: Array<CalendarAllocationModel>;
};

export type useCalendarAllocationMutationOptions = UseMutationOptions<
  CalendarModel | null,
  Error,
  CalendarAllocationMutationOptions
>;

export type useCalendarAllocationMutationResult = UseMutationResult<
  CalendarModel | null,
  Error,
  CalendarAllocationMutationOptions
>;

const submitCalendarAllocationMutation = async (options: CalendarAllocationMutationOptions) => {
  if (typeof options.calendarId !== 'number' || typeof options.calendarPageId !== 'number') return null;

  const formattedAllocations = options.allocations.filter(
    (allocation) => typeof allocation.account === 'number' && typeof allocation.quantity === 'number',
  );

  const request = {
    calendar_id: options.calendarId.toString(),
    calendar_page_id: options.calendarPageId?.toString(),
    allocations: formattedAllocations as Array<ApiCalendarAllocationModel>,
  };

  const createResponse = await postCalendarAllocationRequest(request);
  const pendingAllocations = createResponse.allocations.map((allocation) => ({
    ...allocation,
    status: 'pending',
  }));

  const pendingAllocationsResponse = await submitCalendarAllocationRequest(
    createResponse.calendar.id,
    pendingAllocations,
  );

  return ApiCalendarsMapper.toCalendarModel(pendingAllocationsResponse.calendar);
};

export const useCalendarAllocationMutation = (
  options?: useCalendarAllocationMutationOptions,
): useCalendarAllocationMutationResult => {
  const queryClient = useQueryClient();

  const { query } = useAtomValue(calendarManagerAtom);

  const [selectedCalendar, setSelectedCalendar] = useAtom(calendarSelectedAtom);

  const { enqueueSuccessEvent, enqueueErrorEvent } = useSnackbar();

  return useMutation({
    mutationFn: submitCalendarAllocationMutation,
    ...options,
    onSettled: (...props) => {
      const [data, error] = props;
      if (error) enqueueErrorEvent({ message: translations.common.error });
      else if (data) enqueueSuccessEvent({ message: 'Allocations successfully allocated to calendar.' });
      void queryClient.invalidateQueries({ queryKey: OrderBookCalendarQueryKeyFactory.all() });
      options?.onSettled?.(...props);
    },
    onSuccess: (...props) => {
      const [data, payload] = props;

      options?.onSuccess?.(...props);

      if (!data) return undefined;

      const calendarKey = CalendarsQueryKeyFactory.calendar(payload.calendarId);
      void queryClient.invalidateQueries({ queryKey: calendarKey });

      const featuredCalendarKey = FeaturedCalendarQueryKeyFactory.query();
      void queryClient.invalidateQueries({ queryKey: featuredCalendarKey });

      const allocationsKey = CalendarsQueryKeyFactory.allocations(selectedCalendar?.id);
      queryClient.setQueryData<Array<AllocationModel>>(allocationsKey, (prev) => {
        if (!prev) return undefined;
        return [...data.allocations];
      });

      const calendarsKey = CalendarsQueryKeyFactory.infinite(query);
      queryClient.setQueryData<InfiniteData<ProductApprovalCalendarResult>>(calendarsKey, (prev) => {
        if (!prev) return prev;

        const { id } = data;

        const updatedPages = [...prev.pages];
        const pageIndex = updatedPages.findIndex(({ calendars }) => calendars.some((calendar) => calendar.id === id));

        if (pageIndex < 0) return prev;

        const updatedPage = { ...updatedPages[pageIndex] };

        const updatedPageCalendars = [...updatedPage.calendars];
        const allocationsTotal = data.allocations.reduce((total, { amount }) => total + Number(amount), 0);
        const updatedInventoryConsumed = data.inventoryConsumed ? data.inventoryConsumed + allocationsTotal : 0;
        const updatedInventoryRemaining = data.inventoryRemaining
          ? data.inventoryRemaining - allocationsTotal
          : undefined;

        const calendarIndex = updatedPageCalendars.findIndex((calendar) => calendar.id === id);
        const updatedCalendar = {
          ...updatedPageCalendars[calendarIndex],
          allocations: data.allocations,
          activeAllocationCount: data.allocations.length,
          inventoryConsumed: updatedInventoryConsumed,
          inventoryRemaining: updatedInventoryRemaining,
        };

        updatedPageCalendars.splice(calendarIndex, 1, updatedCalendar);

        updatedPage.calendars = updatedPageCalendars;
        updatedPages.splice(pageIndex, 1, updatedPage);

        setSelectedCalendar(updatedCalendar);

        return { ...prev, pages: updatedPages };
      });
    },
  });
};
