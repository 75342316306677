import { ReactElement, useEffect } from 'react';

import { UserModel } from '@halo-common/models';
import { ContactHaloSchema } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedAlert, LocalizedTextField, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Box, ButtonProps } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const contactFormSx = { maxWidth: 457 };
const alertSx = { alignItems: 'flex-start' };

export type ContactHaloButtonProps = ButtonProps & {
  user?: UserModel | null;
};

export type ContactFormProps = {
  user: UserModel | null;
  hasError: boolean;
  onSubmit: (data: ContactHaloSchema) => void;
};

export const ContactForm = ({ user, hasError = false }: ContactFormProps): ReactElement => {
  const {
    register,
    reset,
    getValues,
    formState: { errors },
  } = useFormContext<ContactHaloSchema>();

  const { data } = useUserInfoQuery();

  const requireCompanyName = data?.settings?.onboarding?.requireCompanyName ?? false;

  useEffect(() => {
    if (user) {
      reset({
        ...getValues(),
        emailAddress: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
      });
    }
  }, [user]);

  const errorMessage = hasError ? (
    <LocalizedAlert sx={alertSx} severity="error" variant="filled">
      <LocalizedTypography variant="body2" component="span">
        {translations.messages.error}
      </LocalizedTypography>
    </LocalizedAlert>
  ) : null;

  const { ref: firstNameRef, ...firstNameInputProps } = register('firstName', {
    required: { value: true, message: translations.components.contactFormFirstNameRequired },
  });
  const { ref: lastNameRef, ...lastNameInputProps } = register('lastName', {
    required: { value: true, message: translations.components.contactFormLastNameRequired },
  });
  const { ref: emailRef, ...emailInputProps } = register('emailAddress', {
    required: { value: true, message: translations.components.contactFormEmailRequired },
  });
  const { ref: companyNameRef, ...companyNameInputProps } = register('companyName', {
    required: { value: true, message: translations.components.contactFormCompanyRequired },
  });
  const { ref: messageRef, ...messageInputProps } = register('message', {
    required: { value: true, message: translations.components.contactFormMessageRequired },
  });

  const companyNameField = requireCompanyName ? (
    <LocalizedTextField
      fullWidth
      label="Company"
      {...companyNameInputProps}
      inputRef={companyNameRef}
      error={Boolean(errors?.companyName)}
      helperText={errors?.companyName?.message}
    />
  ) : null;

  return (
    <form>
      <Box component="form" sx={contactFormSx}>
        <Stack direction="column" spacing={3}>
          {errorMessage}
          <Stack direction="row" spacing={2} xs={6}>
            <LocalizedTextField
              fullWidth
              label={translations.common.firstName}
              {...firstNameInputProps}
              inputRef={firstNameRef}
              error={Boolean(errors?.firstName)}
              helperText={errors?.firstName?.message}
            />
            <LocalizedTextField
              fullWidth
              label={translations.common.lastName}
              {...lastNameInputProps}
              inputRef={lastNameRef}
              error={Boolean(errors?.lastName)}
              helperText={errors?.lastName?.message}
            />
          </Stack>
          <LocalizedTextField
            fullWidth
            label={translations.common.workEmailAddress}
            {...emailInputProps}
            inputRef={emailRef}
            error={Boolean(errors?.emailAddress)}
            helperText={errors?.emailAddress?.message}
          />
          {companyNameField}
          <LocalizedTextField
            fullWidth
            label={translations.common.message}
            {...messageInputProps}
            inputRef={messageRef}
            multiline
            rows={4}
            error={Boolean(errors.message)}
            helperText={errors.message?.message}
          />
        </Stack>
      </Box>
    </form>
  );
};
