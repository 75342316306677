import { ReactElement } from 'react';

import { anchorElAtom } from '@halo-atoms/common';
import { orderBookAuctionCommentPopoverAtom } from '@halo-atoms/orderbook';
import { ActionButton } from '@halo-common/components';
import { AuctionIssuerBidStatusEnum } from '@halo-common/enums';
import { useAuctionIssuerBidStatus } from '@halo-common/hooks';
import { useReviewCommentMutation } from '@halo-data-sources/mutations';
import { Iconography } from '@halodomination/halo-fe-common';
import { Alert, IconButton, Popover, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';

const popoverPaperProps = {
  sx: {
    p: 2,
    width: 548,
  },
};

const iconButtonSx = {
  mt: -1,
  mr: -1,
};

const alertSx = {
  px: 2,
  py: 1,
};

export const AuctionDetailsReviewCommentPopover = 'reviewAuctionCommentPopover';

export const CommentPopover = (): ReactElement | null => {
  const [anchorElMap, updateAnchorElMap] = useAtom(anchorElAtom);
  const [popoverData, setPopoverData] = useAtom(orderBookAuctionCommentPopoverAtom);

  const { auctionId, quote, commentMap } = popoverData;

  const comment = quote?.comment;
  const commentId = comment?.id;
  const commentText = comment?.comment;
  const isLoading = comment && commentMap ? commentMap?.[comment.id] : false;

  const { mutate: putReviewComment } = useReviewCommentMutation(auctionId, commentId);

  const status = useAuctionIssuerBidStatus({ quote });

  const popoverName = AuctionDetailsReviewCommentPopover;
  const anchorEl = anchorElMap[popoverName];
  const open = Boolean(anchorElMap[popoverName]);

  const handlePopoverClose = () => {
    updateAnchorElMap({ [popoverName]: null });
    setPopoverData({ quote: undefined });
  };

  const handleAcceptComment = () => {
    if (commentId) {
      putReviewComment({ commentId, approved: true });
      setPopoverData({ commentMap: { [commentId]: true } });
      handlePopoverClose();
    }
  };
  const handleRejectComment = () => {
    if (commentId) {
      putReviewComment({ commentId, approved: false });
      setPopoverData({ commentMap: { [commentId]: true } });
      handlePopoverClose();
    }
  };

  const commentIcon = <Iconography iconName="comment" />;
  const alert = (
    <Alert severity="info" variant="filled" icon={commentIcon} sx={alertSx}>
      {commentText}
    </Alert>
  );
  const rejectIcon = <Iconography iconName="xmark" color="primary.contrastText" />;
  const acceptIcon = <Iconography iconName="check" color="primary.contrastText" />;
  const reviewPopoverContent = (
    <Stack direction="column" spacing={1}>
      <Typography variant="caption">
        Note that by clicking &quot;Accept with Changes&quot;, you are not necessarily choosing a winner for this
        auction, but you are acknowledging that if you do move forward with this particular offer from this Issuing
        Entity, the terms of the product will be altered based on the comments above.
      </Typography>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        <ActionButton startIcon={rejectIcon} variant="contained" onClick={handleRejectComment} loading={isLoading}>
          Reject Changes
        </ActionButton>
        <ActionButton startIcon={acceptIcon} variant="contained" onClick={handleAcceptComment} loading={isLoading}>
          Accept Changes
        </ActionButton>
      </Stack>
    </Stack>
  );

  const commentApproved = comment?.buyerApproved === true;
  const commentRejected = comment?.buyerApproved === false;
  const commentReviewed = commentApproved || commentRejected;
  const receivedWithComment = status === AuctionIssuerBidStatusEnum.receivedWithComment;
  const commentNeedsReview = receivedWithComment && !commentReviewed;

  let approvalText = '';
  if (commentApproved) approvalText = 'approved';
  else if (commentRejected) approvalText = 'declined';

  const viewCommentContent = approvalText ? (
    <Stack direction="column" spacing={1}>
      <Typography>You {approvalText} these comments.</Typography>
    </Stack>
  ) : null;

  const popoverContent = commentNeedsReview ? reviewPopoverContent : viewCommentContent;
  return (
    <Popover
      id="comment-popover"
      PaperProps={popoverPaperProps}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Stack direction="column" spacing={1}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Review the comment from {quote?.issuer.name}</Typography>
          <IconButton sx={iconButtonSx} onClick={handlePopoverClose}>
            <Iconography iconName="xmark" color="text.secondary" />
          </IconButton>
        </Stack>
        {alert}
        {popoverContent}
      </Stack>
    </Popover>
  );
};
