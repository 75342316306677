import { ReactElement } from 'react';

import { Iconography, IconographyProps, Stack } from '@halodomination/halo-fe-common';
import { Button, Typography } from '@mui/material';
import { useRouter } from 'next/router';

const typographyStackSx = { maxWidth: 950 };
const containerSx = { mt: 1 };

export type AnalyticsHeaderProps = {
  iconName: IconographyProps['iconName'];
  title: string;
  description: string;
  showBackButton?: boolean;
};

export const AnalyticsHeader = ({
  iconName,
  title,
  description,
  showBackButton = true,
}: AnalyticsHeaderProps): ReactElement => {
  const router = useRouter();

  const handleBack = () => {
    router.back();
  };

  const topPadding = !showBackButton ? { pt: 4 } : null;

  const backButton = showBackButton ? (
    <Button
      color="primary"
      size="large"
      startIcon={<Iconography iconName="arrow-left" color="primary.main" />}
      variant="text"
      onClick={handleBack}
    >
      Return to All Analytics
    </Button>
  ) : null;

  return (
    <Stack direction="column" spacing={1} sx={containerSx}>
      {backButton}
      <Stack sx={topPadding} direction="row" spacing={3}>
        <Iconography iconName={iconName} prefix="fal" size="2x" color="primary.dark" flair />
        <Stack sx={typographyStackSx} direction="column" spacing={1}>
          <Typography variant="h2">{title}</Typography>
          <Typography variant="body1" color="textSecondary">
            {description}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
