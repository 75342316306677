import { ReactElement, useEffect, useRef } from 'react';

import { translations } from '@halo-common/translations';
import { LocalizedTypography, LocalizedTypographyProps } from '@halodomination/halo-fe-common';
import { Box, CircularProgress, CircularProgressProps, Stack, StackProps } from '@mui/material';
import { IntersectionOptions, useInView } from 'react-intersection-observer';

const bottomContentContainerSx = {
  width: '100%',
  textAlign: 'center',
  py: 5,
};

export type InfiniteScrollerSlotProps = {
  container?: StackProps;
  list?: StackProps;
  circularProgress?: CircularProgressProps;
  message?: LocalizedTypographyProps;
};

export type InfiniteScrollerProps = {
  children: Array<ReactElement>;
  hasMore: boolean;
  intersectionOptions?: IntersectionOptions;
  loading?: boolean;
  next: () => Promise<unknown> | void;
  grid?: boolean;
  slotProps?: InfiniteScrollerSlotProps;
};

export const InfiniteScroller = ({
  children,
  hasMore,
  intersectionOptions,
  loading,
  next,
  grid = false,
  slotProps,
}: InfiniteScrollerProps): ReactElement => {
  const listRef = useRef(children);

  const wrap = grid ? 'wrap' : undefined;
  const direction = grid ? 'row' : 'column';
  const { ref, inView } = useInView({ rootMargin: '100px', ...intersectionOptions });

  const loader = loading ? <CircularProgress {...slotProps?.circularProgress} /> : null;

  const endMessage = !hasMore ? (
    <Box ref={ref} sx={bottomContentContainerSx}>
      <LocalizedTypography color="text.disabled" variant="overline" {...slotProps?.message}>
        {translations.messages.endOfResults}
      </LocalizedTypography>
    </Box>
  ) : null;

  const loadingIndicator = (
    <Box ref={ref} sx={bottomContentContainerSx}>
      {loader}
    </Box>
  );

  const bottomContent = endMessage ?? loadingIndicator;

  useEffect(() => {
    listRef.current = children;
  }, [listRef]);

  useEffect(() => {
    const loadMore = !loading && hasMore && inView && listRef.current.length;
    if (loadMore) void next();
  }, [hasMore, listRef, loading, inView]);

  return (
    <Stack
      data-testid="infinite-scroller"
      direction="column"
      alignItems="stretch"
      justifyContent="center"
      {...slotProps?.container}
    >
      <Stack direction={direction} justifyContent="center" flexWrap={wrap} {...slotProps?.list}>
        {children}
      </Stack>
      {bottomContent}
    </Stack>
  );
};
