import { courseManagerAtom } from '@halo-atoms/education';
import { EducationModuleGroup } from '@halo-common/models';
import { getEducationModuleGroup, updateTopic } from '@halo-data-sources/clients';
import { ApiEducationMapper } from '@halo-data-sources/mappers';
import { EducationModuleGroupsQueryResult, EducationQueryKeyFactory } from '@halo-data-sources/queries';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

export type EducationModuleProgressResult = {
  topicId: number;
  overallCompletion: number;
  group: EducationModuleGroup;
};

const updateEducationModuleProgressFn = async (topicId?: number, group?: EducationModuleGroup | null) => {
  if (typeof topicId !== 'number' || !group) return null;

  const result = await updateTopic(topicId);

  const { group: groupResponse } = await getEducationModuleGroup(group.id);
  const updatedGroup = ApiEducationMapper.toModuleGroup(groupResponse, group.position);

  return {
    topicId,
    overallCompletion: Math.floor(result.response.pct_complete),
    group: updatedGroup,
  };
};

export const useEducationModuleProgressMutation = (): UseMutationResult<
  EducationModuleProgressResult | null,
  Error,
  number | undefined
> => {
  const [courseProgress, setCourseProgress] = useAtom(courseManagerAtom);

  const groupProgress = courseProgress.group;

  const queryClient = useQueryClient();

  return useMutation<EducationModuleProgressResult | null, Error, number | undefined>({
    mutationFn: (topicId) => updateEducationModuleProgressFn(topicId, groupProgress),
    onSuccess: (data) => {
      if (data === null) return undefined;

      const key = EducationQueryKeyFactory.moduleGroups();

      queryClient.setQueryData<EducationModuleGroupsQueryResult>(key, (prev) => {
        if (!prev?.groups) return undefined;

        const { group, topicId, overallCompletion } = data;

        const groups = prev?.groups ? [...prev.groups] : [];
        const foundGroupIndex = groups?.findIndex(({ id }) => id === group?.id) ?? -1;
        const foundGroup = { ...groups?.[foundGroupIndex] };

        const foundModules = foundGroup?.modules;
        const foundModuleIndex = foundModules.findIndex(({ steps }) => steps.some(({ id }) => id === topicId)) ?? -1;
        const foundModule = { ...foundModules?.[foundModuleIndex] };

        const foundSteps = foundModule?.steps;
        const foundStepIndex = foundSteps.findIndex(({ id }) => id === topicId) ?? -1;
        const foundStep = { ...foundSteps?.[foundStepIndex] };
        const updatedStep = { ...foundStep, completed: true };

        const updatedModule = { ...foundModule };
        updatedModule.steps.splice(foundStepIndex, 1, updatedStep);

        const updatedGroup = { ...foundGroup, ...data.group };
        updatedGroup.modules.splice(foundModuleIndex, 1, updatedModule);

        const updatedGroups = [...prev.groups];
        updatedGroups.splice(foundGroupIndex, 1, updatedGroup);

        setCourseProgress({ progress: { group: updatedGroup, overallCompletion }, type: 'set' });

        return { ...prev, groups: updatedGroups };
      });
    },
  });
};
