import { ReactElement } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { OrderBookModalsEnum, orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { UserAuctionAccessEnum } from '@halo-common/enums';
import { MUST_COMPLETE_ONBOARDING_MODAL } from '@halo-common/modals';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Iconography } from '@halodomination/halo-fe-common';
import { Button, ButtonProps } from '@mui/material';
import { useT } from '@transifex/react';
import { useSetAtom } from 'jotai';

const buttonSx = {
  minWidth: 'unset',
};

export type OrderBookAuctionViewButtonProps = Partial<ButtonProps> & {
  auctionId: number;
};

export const OrderBookAuctionViewButton = ({
  auctionId,
  size = 'medium',
  ...props
}: OrderBookAuctionViewButtonProps): ReactElement => {
  const translator = useT();
  const { data: user } = useUserInfoQuery();
  const setModalMap = useSetAtom(modalAtom);
  const setSelectedAuctionId = useSetAtom(orderBookSelectedAuctionIdAtom);

  const userAuctionSetting = user?.details?.manageAuctionModalSetting;
  const isOnboardingCompleted = user?.organization?.isOnboardingCompleted;

  const openOnboardingModal = () => setModalMap({ [MUST_COMPLETE_ONBOARDING_MODAL]: true });

  const openAuctionDetailsModal = () => {
    setSelectedAuctionId(auctionId);
    setModalMap({ [OrderBookModalsEnum.auctionDetails]: true });
  };

  const openAuctionDetailsPage = () => {
    const url = `/buyer/auctions/${auctionId}`;
    window.open(url, '_blank');
  };

  const onClick = () => {
    if (!isOnboardingCompleted) openOnboardingModal();
    else if (userAuctionSetting === UserAuctionAccessEnum.REACT) openAuctionDetailsModal();
    else openAuctionDetailsPage();
  };

  if (size === 'small') {
    return (
      <Button
        color="primary"
        type="button"
        variant="outlined"
        onClick={onClick}
        size="extraSmall"
        aria-label={translator(translations.common.viewAuction)}
        sx={buttonSx}
        {...props}
      >
        <Iconography iconName="magnifying-glass" />
      </Button>
    );
  }

  return (
    <Button color="primary" type="button" variant="contained" onClick={onClick} size="extraSmall" {...props}>
      {translator(translations.common.viewAuction)}
    </Button>
  );
};
