import { ChangeEvent, ReactElement, ReactNode } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useDashboardConfigQuery } from '@halo-modules/app/dashboard/hooks';
import { Iconography, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Card, IconButton, Stack, Switch, SxProps, Theme, useTheme } from '@mui/material';

const flex0 = {
  flex: '0 0 auto',
};

const flex1 = {
  flex: '1 1 auto',
};

export interface ConfigureWidgetsRowProps {
  id: string;
  name: ReactNode;
  description?: ReactNode;
  visible: boolean;
  onVisibilityChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const ConfigureWidgetsRow = ({
  id,
  name,
  description,
  visible,
  onVisibilityChange,
}: ConfigureWidgetsRowProps): ReactElement | null => {
  const theme = useTheme();
  const { widgetsAreHidable, widgetsAreReorderable } = useDashboardConfigQuery();

  const { transform, transition, setNodeRef, isDragging, attributes, listeners } = useSortable({
    id,
    disabled: !widgetsAreReorderable,
  });

  const sx: SxProps<Theme> = {
    transform: CSS.Translate.toString(transform),
    transition: transition,
    zIndex: isDragging ? 1 : 0,
    backgroundColor: isDragging ? theme.palette.primary.background : undefined,
    borderColor: isDragging ? 'primary.main' : undefined,
    position: 'relative',
  };

  if (!id) return null;

  const draggableButton = widgetsAreReorderable ? (
    <IconButton {...attributes} {...listeners} sx={flex0}>
      <Iconography iconName="arrows-from-dotted-line" />
    </IconButton>
  ) : null;

  const widgetDescription = description ? (
    <LocalizedTypography variant="body2" color="textSecondary">
      {description}
    </LocalizedTypography>
  ) : null;

  const visibilitySwitch = widgetsAreHidable ? (
    <Switch
      checked={visible}
      onChange={onVisibilityChange}
      inputProps={{ 'aria-label': 'Widget Visibility' }}
      sx={flex0}
    />
  ) : null;

  return (
    <Card variant="outlined" sx={sx} ref={setNodeRef}>
      <Stack direction="row" alignItems="center" py={1} px={2} spacing={3}>
        {draggableButton}
        <Stack direction="column" justifyContent="center" sx={flex1}>
          <LocalizedTypography variant="subtitle1" color="textPrimary">
            {name}
          </LocalizedTypography>
          {widgetDescription}
        </Stack>
        {visibilitySwitch}
      </Stack>
    </Card>
  );
};
