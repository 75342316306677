export const LIFECYCLE_PAGE_TRANSLATIONS = {
  aboveCallLevel: 'Above Call Level',
  allEventTypeMenuItem: 'All Events',
  autocallAboveEventTypeMenuItem: 'Autocall (Above Call Level)',
  autocallObservationEventTypeMenuItem: 'Autocall Observation',
  belowCallLevel: 'Below Call Level',
  callObservationsEventTypeMenuItem: 'All Call Observations',
  chartHeading: 'Potential Cash Flow',
  chartYLabel: '{currencySymbol} (Thousands)',
  couponProtectionRemaining: 'Coupon Protection Remaining',
  couponsBelowEventTypeMenuItem: 'All Coupons (Below Protection)',
  couponsEventTypeMenuItem: 'All Coupons',
  emptyListMessage: `Please adjust your filters or add your portfolio positions to track observation dates, performance, and client allocations.`,
  emptyListTitle: 'No Events To Show',
  eventDatePickerLabel: 'Event Date',
  eventListFilterByLabel: 'Filter By Type',
  generatePortfolioEventsReportErrorMessage: 'Failed to generate report for portfolio events.',
  generatePortfolioEventsReportPendingMessage: 'Generating report for portfolio events.',
  generatePortfolioEventsReportSuccessMessage: 'Successfully generated report for portfolio events.',
  investmentOpportunity: 'Investment Opportunity',
  issuerCallObservationEventTypeMenuItem: 'Issuer Call Observation',
  lifecycleCSVDownloadAlert: `To change the selected account, close this window and select a different account from the top of your portfolio.`,
  lifecycleCSVDownloadSubtitle: `Receive an Excel file that includes one line per event for the selected timeline and account.`,
  lifecycleCSVDownloadTitle: 'Export Lifecycle Events',
  listHeading: 'Event Details',
  maturityValue: 'Maturity Value',
  paymentBreakdown: 'Payment Breakdown',
  noPaymentBreakdownResult: 'No Payment Breakdown Found',
  protectionRemaining: 'Protection Remaining',
  reinvestPopoverCurrency: 'Currency: {currency}',
  reinvestPopoverIssuer: 'Issuer: {issuer}',
  reinvestPopoverNoteType: 'Note Type: {noteType}',
  reinvestPopoverProtectionLevel: 'Protection Level: {protectionLevel}',
  reinvestPopoverProtectionType: 'Protection Type: {protectionType}',
  reinvestPopoverTerm: 'Term: {term}',
  reinvestPopoverUnderlyings: 'Underlyings: {underlyings}',
  scaleLabel: 'Set Scale',
  seeSimilarCalendar: 'See Similar Calendar Offering',
  seeSimilarNote: 'See Similar Note:',
  sendToAuction: 'Send To Auction',
  sortLargestByMenuItem: 'Notional Amount (Largest)',
  sortLatestByMenuItem: 'Date (Latest)',
  sortSmallestByMenuItem: 'Notional Amount (Smallest)',
  sortSoonestByMenuItem: 'Date (Soonest)',
  thisMatchesYourCalendar: 'This Calendar best matches your previously-held Calendar based on:',
  totalNotional: 'Total Notional',
  viewCalendarOfferings: 'View Calendar Offerings',
};
