import { SMAStrategyModel } from '@halo-common/models';
import { fetchSMAStrategies } from '@halo-data-sources/clients';
import { ApiSMAMapper } from '@halo-data-sources/mappers';
import { SMAQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getSMAStrategiesFn = async () => {
  const response = await fetchSMAStrategies();

  return ApiSMAMapper.toSMAStrategyModel(response);
};

export const useSMAStrategiesQuery = (): UseQueryResult<Array<SMAStrategyModel>, Error> =>
  useQuery<Array<SMAStrategyModel>, Error>({
    queryKey: SMAQueryKeyFactory.strategies(),
    queryFn: () => getSMAStrategiesFn(),
  });
