import { ReactElement } from 'react';

import { courseManagerAtom } from '@halo-atoms/education';
import { translations } from '@halo-common/translations';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';

import { EducationProgressBar } from '../EducationProgressBar';

export const EducationOverallProgressIndicator = (): ReactElement => {
  const courseProgress = useAtomValue(courseManagerAtom);

  const progress = courseProgress.overallCompletion ?? 0;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <LocalizedTypography color="primary.contrastText">
        {translations.dashboard.educationCourses.overallProgress}
      </LocalizedTypography>
      <EducationProgressBar
        offsetBackground={2}
        backgroundThickness={2}
        color="secondary"
        progress={progress}
        size="large"
      />
    </Stack>
  );
};
