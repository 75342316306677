import { ReactElement, ReactNode } from 'react';

import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Skeleton, capitalize } from '@mui/material';

const dataSx = { fontWeight: 'fontWeightBold' };

export type OrderReviewSectionProps = {
  header: string;
  loading?: boolean;
  children?: ReactNode;
};

export const OrderReviewSection = ({
  header,
  loading = false,
  children,
}: OrderReviewSectionProps): ReactElement | null => {
  if (!children) return null;

  const text = typeof children === 'string' ? capitalize(children) : children;

  const headerContent = loading ? (
    <Skeleton data-testid="header-loading" width={80} height={24} />
  ) : (
    <LocalizedTypography variant="body1" color="textSecondary">
      {header}
    </LocalizedTypography>
  );

  const sectionContent = loading ? (
    <Skeleton data-testid="body-loading" width={80} height={24} />
  ) : (
    <LocalizedTypography variant="body1" sx={dataSx}>
      {text}
    </LocalizedTypography>
  );

  return (
    <Stack direction="row" xs={6}>
      {headerContent}
      {sectionContent}
    </Stack>
  );
};
