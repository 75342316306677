import { DisclaimerOnboardingModel, DisclaimerFileUploadSectionModel, DisclaimerUserModel } from '@halo-common/models';
import { ApiUserMapper, mapApiDocumentModelToS3DocumentModel } from '@halo-data-sources/mappers';
import { ApiDisclaimerAcceptanceModel, ApiDisclaimerModel, ApiFileUploadSectionModel } from '@halo-data-sources/models';

interface IApiDisclaimerMapper {
  toUserDisclaimer: (model: ApiDisclaimerModel) => DisclaimerUserModel;
  toOnboardingDisclaimer: (model: ApiDisclaimerModel) => DisclaimerOnboardingModel;
}

export const ApiDisclaimerMapper: IApiDisclaimerMapper = {
  toUserDisclaimer: (model: ApiDisclaimerModel): DisclaimerUserModel => {
    const lastAcceptance = model.acceptances.sort(
      (a, b) => new Date(b.created_at_iso).getTime() - new Date(a.created_at_iso).getTime(),
    )[model.acceptances.length - 1];

    const mapFileUploadSection = (model: ApiFileUploadSectionModel): DisclaimerFileUploadSectionModel => ({
      id: model.id,
      description: model.description,
      name: model.name,
    });

    const mapDisclaimerAcceptance = (acceptance: ApiDisclaimerAcceptanceModel) => ({
      id: acceptance.id.toString(),
      payload: acceptance.payload,
      createdAt: acceptance.created_at,
      haloUser: ApiUserMapper.toUser(acceptance.halo_user),
      document: mapApiDocumentModelToS3DocumentModel(acceptance.document),
    });

    return {
      id: model.id.toString(),
      isActive: model.is_active,
      fileUploadDescription: model.file_upload_description,
      fileUploadSection: model.file_upload_section ? mapFileUploadSection(model.file_upload_section) : null,
      requiresConfirmation: model.requires_confirmation,
      requiresFileUpload: model.requires_file_upload,
      requiresUserInformation: model.requires_user_information,
      lastAcceptance: lastAcceptance ? mapDisclaimerAcceptance(lastAcceptance) : undefined,
      name: model.name,
      displayName: model.display_name,
      urgency: model.urgency,
      content: model.content,
      prompt: model.prompt,
      document: mapApiDocumentModelToS3DocumentModel(model.document),
      acceptances: model.acceptances.map(mapDisclaimerAcceptance),
    };
  },
  toOnboardingDisclaimer: (model: ApiDisclaimerModel): DisclaimerOnboardingModel => {
    return {
      id: model.id,
      name: model.name,
      displayName: model.display_name,
      content: model.content,
      urgency: model.urgency,
      isAccepted: false,
    };
  },
};
