import { ReactElement } from 'react';

import { AccountTypeAhead, AccountTypeAheadProps } from '@halo-common/smartComponents';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

export type AccountFormFieldValue = AccountTypeAheadProps['value'];

export type AccountFormFieldProps<
  AccountFormFieldValue extends FieldValues = FieldValues,
  TName extends FieldPath<AccountFormFieldValue> = FieldPath<AccountFormFieldValue>,
> = Omit<AccountTypeAheadProps, 'value' | 'onSelect'> & {
  onSelect?: AccountTypeAheadProps['onSelect'];
  control: Control<AccountFormFieldValue>;
  name: TName;
};

export const AccountFormField = <
  AccountFormFieldValue extends FieldValues,
  TName extends FieldPath<AccountFormFieldValue> = FieldPath<AccountFormFieldValue>,
>({
  control,
  name,
  rules,
  onSelect,
  ...props
}: AccountFormFieldProps<AccountFormFieldValue, TName>): ReactElement => (
  <Controller<AccountFormFieldValue>
    render={({ field: { onChange, ref, value } }) => (
      <AccountTypeAhead
        {...props}
        ref={ref}
        value={value}
        rules={rules}
        onSelect={(value, reason) => {
          onChange(value);
          onSelect?.(value, reason);
        }}
      />
    )}
    control={control}
    name={name}
  />
);
