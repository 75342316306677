import { ReactElement } from 'react';

import { ActionButton } from '@halo-common/components';
import { forgotPasswordSchema } from '@halo-common/schemas';
import { ConnectedContactButton } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { useSendResetPasswordEmailMutation } from '@halo-data-sources/mutations/onboarding';
import { LoginPageMeta } from '@halo-pages/app/v2/onboarding/login';
import {
  Iconography,
  LocalizedAlert,
  LocalizedButton,
  LocalizedTextField,
  LocalizedTypography,
} from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { Paper, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';

const linkSx = {
  alignItems: 'center',
  display: 'flex',
  my: 3,
  width: 'fit-content',
};

const iconSx = {
  alignItems: 'center',
  display: 'flex',
  height: '1.5rem',
  width: '1.5rem',
};

const formContainerSx = {
  padding: 3,
  overflowY: 'auto',
  maxWidth: 450,
  minWidth: { xs: 343, sm: 'default' },
  width: '100%',
};

const contactButtonSx = {
  color: 'info.dark',
  pl: 0,
  textDecoration: 'underline',
};

const alertSx = {
  alignItems: 'flex-start',
};

export type ForgotPasswordFormSchema = {
  email: string;
};

export const ForgotPasswordForm = (): ReactElement => {
  const { mutate: sendResetPassword, error, isError, isPending } = useSendResetPasswordEmailMutation(true);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ForgotPasswordFormSchema>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    resolver: yupResolver<ForgotPasswordFormSchema>(forgotPasswordSchema),
    defaultValues: {
      email: '',
    },
  });

  const { ref: emailRef, ...emailProps } = register('email');

  const userNotFoundError = /not found/i.test(error?.message ?? '');
  const showError = isError && !userNotFoundError;

  const onSubmit = handleSubmit((data: ForgotPasswordFormSchema) => {
    sendResetPassword({ email: data.email });
  });

  const navigationHandler = () => {
    window.location.assign(`/react${LoginPageMeta.route}${window.location.search}`);
  };

  const errorMessage = showError ? (
    <LocalizedAlert sx={alertSx} severity="error" variant="filled">
      <LocalizedTypography variant="body2" component="span">
        {error?.message}
      </LocalizedTypography>
    </LocalizedAlert>
  ) : null;

  const contactUsAlert = (
    <LocalizedAlert sx={alertSx} severity="info" variant="filled">
      <LocalizedTypography color="info.dark" variant="body2">
        {translations.onboarding.recoverPassword.contactUsMessage}
      </LocalizedTypography>
      <ConnectedContactButton size="small" sx={contactButtonSx} />
    </LocalizedAlert>
  );

  return (
    <Stack direction="column" alignItems="flex-start">
      <Paper variant="outlined" sx={formContainerSx}>
        <Stack direction="column" spacing={3}>
          {errorMessage}
          {contactUsAlert}
          <LocalizedTextField
            id="main"
            size="large"
            fullWidth
            label={translations.common.emailAddress}
            inputRef={emailRef}
            {...emailProps}
            error={Boolean(errors?.email)}
            helperText={errors?.email?.message}
          />
          <ActionButton loading={isPending} onClick={onSubmit} fullWidth color="primary" variant="contained">
            {translations.common.sendLink}
          </ActionButton>
        </Stack>
      </Paper>
      <LocalizedButton
        color="primary"
        sx={linkSx}
        size="medium"
        variant="outlined"
        onClick={navigationHandler}
        startIcon={<Iconography iconName="arrow-left" sx={iconSx} color="primary.main" />}
      >
        {translations.common.back}
      </LocalizedButton>
    </Stack>
  );
};
