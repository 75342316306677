import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiUserAuctionDefaults,
  ApiAdminAuctionPutFinalPrelimTermsResponseModel,
  ApiAdminAuctionPutPrelimTermsRequestModel,
  ApiAdminAuctionPutPrelimTermsResponseModel,
  ApiUserAuctionDefaultsRequest,
} from '@halo-data-sources/models';

const AUCTION_PATH = '/issuer/v1/auction';
const EXECUTION_AUCTION_PATH = '/execution/auction';

export const putPrelimTerms = async ({
  auctionId,
  termSheet,
  isin,
  cusip,
}: ApiAdminAuctionPutPrelimTermsRequestModel): Promise<ApiAdminAuctionPutPrelimTermsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(AUCTION_PATH);

  const formData = new FormData();

  formData.append('term_sheet', termSheet);
  formData.append('_csrf_token', clientConfiguration.csrfToken as string);

  if (isin) formData.append('isin', isin);
  if (cusip) formData.append('cusip', cusip);

  const response = await request<ApiAdminAuctionPutPrelimTermsResponseModel>(
    `${clientConfiguration.basePath}/terms/${auctionId}`,
    {
      ...clientConfiguration.requestInit,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      body: formData,
    },
  );

  return response;
};

export const putFinalPrelimTerms = async ({
  auctionId,
  termSheet,
  isin,
  cusip,
}: ApiAdminAuctionPutPrelimTermsRequestModel): Promise<ApiAdminAuctionPutFinalPrelimTermsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(AUCTION_PATH);

  const formData = new FormData();

  formData.append('term_sheet', termSheet);
  formData.append('_csrf_token', clientConfiguration.csrfToken as string);

  if (isin) formData.append('isin', isin);
  if (cusip) formData.append('cusip', cusip);

  const response = await request<ApiAdminAuctionPutFinalPrelimTermsResponseModel>(
    `${clientConfiguration.basePath}/terms/final/${auctionId}`,
    {
      ...clientConfiguration.requestInit,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      body: formData,
    },
  );

  return response;
};

export const getAuctionDefaults = async (payload: ApiUserAuctionDefaultsRequest): Promise<ApiUserAuctionDefaults> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_PATH);

  const path = `${clientConfiguration.basePath}/date`;

  const response = await request<ApiUserAuctionDefaults>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });

  return response;
};
