import { CustodianModel } from '@halo-common/models';
import { getCustodians } from '@halo-data-sources/clients';
import { ApiAccountMapper } from '@halo-data-sources/mappers';
import { CustodianQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

const getCustodiansFn = async () => {
  const result = await getCustodians();

  const custodians = result.custodians.map(ApiAccountMapper.toCustodianModel);

  return custodians;
};

export const useCustodianQuery = (): UseQueryResult<Array<CustodianModel>, Error> =>
  useQuery<Array<CustodianModel>, Error>({
    queryKey: CustodianQueryKeyFactory.all(),
    queryFn: () => getCustodiansFn(),
    placeholderData: keepPreviousData,
  });
