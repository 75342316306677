import { anchorElAtom } from '@halo-atoms/common';
import {
  AuctionDetailsIssuerSortOptions,
  IssuerModel,
  OrderBookAuctionQueryFields,
  QuoteAuctionModel,
} from '@halo-common/models';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { atom } from 'jotai';

const DEFAULT_SEARCH_FIELDS = {
  page: 0,
  pageSize: 25,
  sortBy: [],
  filters: {
    issuer: '',
    noteType: '',
    status: '',
    timeFrame: 'thirtyDays',
    underlying: [],
  },
};

export const orderBookSelectedAuctionIdAtom = atom<number | undefined>(undefined);

export const orderBookAuctionQuoteAtom = atom<QuoteAuctionModel | undefined>(undefined);
export const orderBookAuctionIssuerSortAtom = atom<AuctionDetailsIssuerSortOptions>('bid');
export const orderBookAuctionSearchFieldsAtom = atom<OrderBookAuctionQueryFields>(DEFAULT_SEARCH_FIELDS);
export const orderBookAuctionIssuerAtom = atom<IssuerModel | undefined>(undefined);

export const _orderBookAuctionCommentMapAtom = atom<{ [key: string]: boolean } | null>(null);

export type OrderBookAuctionCommentPopoverPayload = {
  anchor?: HTMLElement | null;
  auctionId?: number;
  quote?: QuoteAuctionModel;
  commentMap?: { [key: string]: boolean } | null;
  issuer?: IssuerModel;
};

export const cancelAuctionPopoverName = 'cancel_auction_popover';
export const orderBookAuctionCommentPopoverAtom = atom(
  (get) => ({
    anchor: get(anchorElAtom)[cancelAuctionPopoverName],
    auctionId: get(orderBookSelectedAuctionIdAtom),
    quote: get(orderBookAuctionQuoteAtom),
    commentMap: get(_orderBookAuctionCommentMapAtom),
    issuer: get(orderBookAuctionIssuerAtom),
  }),
  (_, set, payload: OrderBookAuctionCommentPopoverPayload) => {
    if (payload.anchor !== undefined) set(anchorElAtom, { [cancelAuctionPopoverName]: payload.anchor });
    if (payload.auctionId) set(orderBookSelectedAuctionIdAtom, payload.auctionId);
    if (payload.quote) set(orderBookAuctionQuoteAtom, payload.quote);
    if (payload.commentMap) {
      set(_orderBookAuctionCommentMapAtom, (prev) => ({ ...prev, ...payload.commentMap }));
    }
    if (payload.issuer) {
      set(orderBookAuctionIssuerAtom, payload.issuer);
    }
  },
);

const _orderbookAuctionAllocationsAllocationIdAtom = atom<number | undefined>(undefined);
const _orderbookAuctionAllocationsAccountOptionAtom = atom<AccountTypeAheadOption | null>(null);
const _orderbookAuctionAllocationsNotionalAtom = atom<number | undefined>(undefined);

export type OrderBookAuctionAllocationEditDeletePayload = {
  allocationId?: number;
  accountOption?: AccountTypeAheadOption;
  notional?: number;
};

export const orderBookAuctionAllocationEditDeleteAtom = atom(
  (get) => ({
    allocationId: get(_orderbookAuctionAllocationsAllocationIdAtom),
    accountOption: get(_orderbookAuctionAllocationsAccountOptionAtom),
    notional: get(_orderbookAuctionAllocationsNotionalAtom),
  }),
  (_, set, payload?: OrderBookAuctionAllocationEditDeletePayload) => {
    if (payload?.allocationId) set(_orderbookAuctionAllocationsAllocationIdAtom, payload?.allocationId);
    if (payload?.accountOption) set(_orderbookAuctionAllocationsAccountOptionAtom, payload?.accountOption);
    if (payload?.notional) set(_orderbookAuctionAllocationsNotionalAtom, payload?.notional);
    if (!payload) {
      set(_orderbookAuctionAllocationsAllocationIdAtom, undefined);
      set(_orderbookAuctionAllocationsAccountOptionAtom, null);
      set(_orderbookAuctionAllocationsNotionalAtom, undefined);
    }
  },
);
