import { ReactElement } from 'react';

import { useRecentQuotesQuery, useWatchlistNotesQuery } from '@halo-data-sources/queries';
import {
  DashboardWatchlistSkeleton,
  NotesCarousel,
  renderNoteCardSkeletons,
  WatchlistDetailsEmptyMessage,
  WatchlistProductCard,
} from '@halo-modules/app';
import { Stack } from '@mui/material';

export type DashboardWatchlistDetailsProps = {
  id: number;
};

export const DashboardWatchlistDetails = ({ id }: DashboardWatchlistDetailsProps): ReactElement => {
  const query = useWatchlistNotesQuery(id);

  const { data, isPending, isFetchingNextPage, isSuccess, isError, hasNextPage, fetchNextPage } = query;

  const pages = data?.pages ?? [];
  const products = pages.flatMap((page) => page?.products ?? []) ?? [];
  const productIds = pages[pages.length - 1]?.products.map(({ product }) => product.id) ?? [];

  useRecentQuotesQuery(productIds);

  if (isPending) return <DashboardWatchlistSkeleton />;

  const loaded = isError || isSuccess;
  const isEmptyWatchlist = loaded && products.length === 0;

  if (isEmptyWatchlist) return <WatchlistDetailsEmptyMessage />;

  const lazyLoadNextPage = (currentSlide: number) => {
    const loadingOffset = 4;
    const scrolledToTheEnd = currentSlide >= products.length - loadingOffset;
    const shouldLoadNextPage = scrolledToTheEnd && hasNextPage && !isFetchingNextPage;
    if (shouldLoadNextPage) void fetchNextPage();
  };

  return (
    <NotesCarousel afterChange={lazyLoadNextPage}>
      {products.map(({ product, type }) => (
        <Stack width={360} px={1} direction="column" spacing={2} key={product.id}>
          <WatchlistProductCard product={product} type={type} removable />
        </Stack>
      ))}
      {isFetchingNextPage ? renderNoteCardSkeletons() : null}
    </NotesCarousel>
  );
};
