import { ReactElement } from 'react';

import { orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { AuctionUserFacingStyleEnum } from '@halo-common/enums';
import { useBuyerAuctionQuery } from '@halo-data-sources/queries';
import { Stack, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Divider, Skeleton, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';

export const AuctionDetailsSection = (): ReactElement => {
  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);
  const auctionId = selectedAuctionId?.toString();

  const { data: auction } = useBuyerAuctionQuery(auctionId);

  if (!auction) return <Skeleton variant="rounded" height={160} />;

  const auctionIssuers = auction?.issuers.map((issuer, index) => (
    <Typography key={`details-issuer-${index}`} variant="body2" fontWeight="bold">
      {issuer.name}
    </Typography>
  ));

  const fields = [
    { label: 'Estimated Notional', value: useCurrencyConverter(auction?.notional) },
    { label: 'Auction Style', value: auction?.style ? AuctionUserFacingStyleEnum[auction.style] : null },
    { label: 'Target', value: `${auction?.targetSolvableParameterValue.toFixed(2)}%` },
    { label: 'Issuers', value: auctionIssuers },
  ];

  const detailElements = fields.map(({ label, value }, index) => (
    <Stack key={`auction-date-${index}`} direction="row" xs={6}>
      <Stack direction="column">
        <Typography variant="body2">{label}</Typography>
      </Stack>
      <Stack direction="column">
        <Typography variant="body2" fontWeight="bold">
          {value}
        </Typography>
      </Stack>
    </Stack>
  ));

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" xs={[4, 8]} alignItems="center">
        <Stack direction="column">
          <Typography variant="subtitle2">Auction Details</Typography>
        </Stack>
        <Stack direction="column">
          <Divider />
        </Stack>
      </Stack>
      {detailElements}
    </Stack>
  );
};
