import { ReactElement, ReactNode } from 'react';

import { AccountTypeAheadSelectionReasons } from '@halo-common/smartComponents';
import { Iconography, IconographyProps, LocalizedTypography } from '@halodomination/halo-fe-common';
import { List, ListItem, ListItemIcon, ListItemText, autocompleteClasses } from '@mui/material';

const groupHeadingSx = {
  mr: 1,
  minWidth: 'unset',
};

const groupListSx = {
  padding: 0,
};

const listItemStyling = {
  margin: 0,
  padding: 0,
  width: '100%',
  [`& .${autocompleteClasses.option}`]: {
    paddingLeft: 6,
    [`&.${autocompleteClasses.focused}`]: {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
};

export type AccountTypeAheadGroupedListProps = {
  group: string;
  icon: IconographyProps['iconName'] | null;
  onGroupSelect: (value: string, reason: AccountTypeAheadSelectionReasons) => void;
  children?: ReactNode;
  selected?: boolean;
  disabled?: boolean;
  hideChildren?: boolean;
};

export const AccountTypeAheadGroupedList = ({
  group,
  icon,
  children,
  onGroupSelect,
  selected = false,
  disabled = false,
  hideChildren = false,
}: AccountTypeAheadGroupedListProps): ReactElement => {
  const householdItemSx = {
    margin: 0,
    padding: 0,
    paddingY: 1,
    paddingLeft: 4,
    backgroundColor: selected ? 'primary.background' : undefined,
    color: disabled ? 'text.disabled' : selected ? 'primary.main' : undefined,
    '&:hover': !disabled ? { cursor: 'pointer', backgroundColor: 'rgba(0, 0, 0, 0.04)' } : undefined,
  };

  const textColor = disabled ? 'text.disabled' : selected ? 'primary.main' : undefined;

  const handleOnClick = !disabled ? () => onGroupSelect(group, hideChildren ? 'action' : 'household') : undefined;

  const accountList = !hideChildren ? (
    <ListItem sx={groupListSx}>
      <List sx={listItemStyling}>{children}</List>
    </ListItem>
  ) : null;

  const accountListItemIcon = icon ? (
    <ListItemIcon sx={groupHeadingSx}>
      <Iconography iconName={icon} color={textColor} prefix="fal" />
    </ListItemIcon>
  ) : null;

  return (
    <List sx={groupListSx}>
      <ListItem onClick={handleOnClick} sx={householdItemSx} role="option">
        {accountListItemIcon}
        <ListItemText color={textColor}>
          <LocalizedTypography>{group}</LocalizedTypography>
        </ListItemText>
      </ListItem>
      {accountList}
    </List>
  );
};
