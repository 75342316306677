import { notificationFiltersAtom } from '@halo-atoms/profile';
import { markNotificationsAsRead } from '@halo-data-sources/clients/profile';
import { GetNotificationsInfiniteResult, NotificationModel, PreviousData } from '@halo-data-sources/models';
import { NotificationsQueryKeyFactory } from '@halo-data-sources/queries/profile';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

export const useMarkNotificationAsRead = (): UseMutationResult<void, Error, number[]> => {
  const queryClient = useQueryClient();
  const filters = useAtomValue(notificationFiltersAtom);

  return useMutation({
    mutationFn: (notificationIds: number[]) => markNotificationsAsRead(notificationIds),
    onMutate: async (notificationIds: number[]) => {
      await queryClient.cancelQueries({ queryKey: NotificationsQueryKeyFactory.infinite(filters) });

      const previousNotifications = queryClient.getQueryData(NotificationsQueryKeyFactory.infinite(filters));

      if (previousNotifications) {
        queryClient.setQueryData(
          NotificationsQueryKeyFactory.infinite(filters),
          (prev: PreviousData | undefined): PreviousData | undefined => {
            if (!prev) return prev;
            return {
              ...prev,
              pages: prev.pages.map((page: GetNotificationsInfiniteResult) => {
                return {
                  ...page,
                  notifications: page.notifications.map((notification: NotificationModel) => {
                    return notificationIds.includes(notification.id)
                      ? { ...notification, isActive: false }
                      : notification;
                  }),
                };
              }),
            };
          },
        );
      }

      return { previousNotifications };
    },
    onError: (err, notificationIds, context) => {
      queryClient.setQueryData(NotificationsQueryKeyFactory.infinite(filters), context?.previousNotifications);
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: NotificationsQueryKeyFactory.infinite() });
      void queryClient.invalidateQueries({ queryKey: NotificationsQueryKeyFactory.allTypes() });
    },
  });
};
