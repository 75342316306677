import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Iconography, LocalizedTextField, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Box, Tooltip } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const whyDoWeNeedSx = { alignItems: 'center', display: 'flex', mt: 1 };
const infoIconSx = { ml: 1 };

export type CrdNumberFieldSchema = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  companyName: string;
  state: string;
  role: string;
  crdNumber: number | undefined;
};

export const CrdNumberField = (): ReactElement => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CrdNumberFieldSchema>();

  const { ref: crdNumberRef, ...crdNumberProps } = register('crdNumber');

  const { data: user } = useUserInfoQuery();

  const userFacingName = user?.whiteLabel?.userFacingName ?? '';

  const dynamicContent = { userFacingName };

  const tooltipContent = (
    <Box fontWeight={12}>
      <LocalizedTypography dynamicContent={dynamicContent} variant="h6" gutterBottom>
        {translations.onboarding.crd.header}
      </LocalizedTypography>
      <LocalizedTypography dynamicContent={dynamicContent} variant="body1">
        {translations.onboarding.crd.body}
      </LocalizedTypography>
    </Box>
  );

  return (
    <Stack direction="column">
      <LocalizedTextField
        fullWidth
        label={translations.onboarding.crd.crdField}
        size="large"
        inputRef={crdNumberRef}
        {...crdNumberProps}
        error={Boolean(errors?.crdNumber)}
        helperText={errors?.crdNumber?.message}
      />
      <Stack direction="row">
        <LocalizedTypography sx={whyDoWeNeedSx} color="text.secondary" variant="caption">
          {translations.onboarding.crd.need}
          <Tooltip title={tooltipContent} placement="bottom-start">
            <Iconography sx={infoIconSx} iconName="info-circle" color="text.secondary" prefix="fal" />
          </Tooltip>
        </LocalizedTypography>
      </Stack>
    </Stack>
  );
};
