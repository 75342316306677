import type { FormBuilderField } from '@halo-common/components';
import set from 'lodash/set';
import * as yup from 'yup';

interface DeepSchema {
  [key: string]: yup.AnySchema | DeepSchema;
}

export const buildSchema = (fields: FormBuilderField[], base = yup.object()): yup.AnyObjectSchema => {
  const schema: DeepSchema = {};

  fields.forEach((field) => {
    if (!field.validation) return;
    if (yup.isSchema(field.validation)) {
      if ('name' in field) {
        set(schema, field.name, field.validation);
      }
    } else if (typeof field.validation === 'object') {
      Object.entries(field.validation).forEach((validation) => {
        const key = validation[0];
        const value = validation[1];
        if (yup.isSchema(value)) {
          set(schema, key, value);
        }
      });
    }
  });

  const convert = (obj: DeepSchema): Record<string, yup.AnySchema> =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        if (typeof obj === 'object' && !yup.isSchema(value) && value !== null) {
          return [key, yup.object(convert(value as DeepSchema))];
        }
        return [key, value];
      }),
    );

  return base.shape(convert(schema));
};
