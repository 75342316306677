import { ReactElement, useEffect } from 'react';

import { quoteValueMapAtom } from '@halo-atoms/quotes';
import { CalendarPointList } from '@halo-common/components';
import { NoteTypeEnum } from '@halo-common/enums';
import { useBreakpointView } from '@halo-common/hooks';
import { ProductDetailModalAnalyticsTab } from '@halo-common/layouts';
import {
  ExecutionCalendarDetailModalDocumentTable,
  ExecutionCalendarDetailModalHeader,
  ExecutionCalendarDetailModalPanel,
  ProductDetailModal,
} from '@halo-common/modals';
import { CalendarModel, QuoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { useAtom } from 'jotai';

const ANALYTICS_TAB = translations.common.analytics;
const FROM_THE_ISSUER_TAB = translations.pdm.execution.tabFromIssuer;
const DETAILS_TAB = translations.common.details;
const DOCUMENTS_TAB = translations.common.documents;

export type ExecutionCalendarDetailModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmitIndication?: (product: CalendarModel) => void;
  product?: CalendarModel | null;
  actionable?: boolean;
};

export const ExecutionCalendarDetailModal = ({
  onClose,
  onSubmitIndication,
  open,
  product,
  actionable = true,
}: ExecutionCalendarDetailModalProps): ReactElement => {
  const [quoteValueMap, setQuoteValueMap] = useAtom(quoteValueMapAtom);

  const { data: user, isPending } = useUserInfoQuery();

  const isTabletView = useBreakpointView('md', 'up');

  const quoteId = product?.quote?.id;
  const note = product?.note;
  const noteId = note?.id;

  const calendarPoints = product?.points ?? [];
  const calendarQuote = noteId ? quoteValueMap[noteId] : undefined;

  const isGeneralNote = note?.type === NoteTypeEnum.General;
  const isEnglishLocale = user?.details.preferredLanguage?.startsWith('en') || !user?.details.preferredLanguage;

  const showDetailsTab = !isTabletView;
  const showCalendarPoints = Boolean(user?.calendarPage?.showCalendarPoints);
  const showIssuerTab = Boolean(showCalendarPoints && calendarPoints.length) && isEnglishLocale;
  const showAnalyticsTab = user?.settings.showExecutionRRTab && product && !isGeneralNote;

  const loading = !product || !calendarQuote || isPending;

  const tabs = [];
  if (showIssuerTab) tabs.push(FROM_THE_ISSUER_TAB);
  if (showAnalyticsTab) tabs.push(ANALYTICS_TAB);
  if (showDetailsTab) tabs.push(DETAILS_TAB);
  tabs.push(DOCUMENTS_TAB);

  const issuerTab = showIssuerTab ? (
    <CalendarPointList points={calendarPoints} loading={loading} title={translations.pdm.calendars.issuerTabTitle} />
  ) : null;

  const analyticsTab = showAnalyticsTab ? (
    <ProductDetailModalAnalyticsTab product={product?.note} loading={loading} />
  ) : null;

  const panelContent = <ExecutionCalendarDetailModalPanel product={product} loading={loading} />;
  const detailsTab = showDetailsTab ? panelContent : null;
  const slots = {
    header: (
      <ExecutionCalendarDetailModalHeader
        product={product}
        onSubmitIndication={onSubmitIndication}
        onClose={onClose}
        loading={loading}
        actionable={actionable}
      />
    ),
    panel: panelContent,
  };

  useEffect(() => {
    if (noteId && quoteId)
      setQuoteValueMap((prev) => ({
        ...prev,
        [noteId]: {
          updated: false,
          recent: product.quote as unknown as QuoteModel,
          past: [],
        },
      }));
  }, [noteId, quoteId]);

  return (
    <ProductDetailModal open={open} onClose={onClose} tabs={tabs} slots={slots} className="wm-pdm-execution">
      {issuerTab}
      {analyticsTab}
      {detailsTab}
      <ExecutionCalendarDetailModalDocumentTable product={product} loading={loading} />
    </ProductDetailModal>
  );
};
