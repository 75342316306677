import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type DocusignQueryKeys = {
  all: QueryKeyFactoryFn;
  formData: QueryKeyFactoryFn;
};

export const DocusignQueryKeyFactory: DocusignQueryKeys = {
  all: () => ['docusign'],
  formData: () => [...DocusignQueryKeyFactory.all(), 'formData'],
};
