import { notificationFiltersAtom } from '@halo-atoms/profile';
import { useHaloInfiniteQuery } from '@halo-common/hooks';
import { InfiniteQueryOptions } from '@halo-common/models';
import { getNotifications } from '@halo-data-sources/clients/profile';
import { ApiNotificationMapper, NotificationMapper, PaginationMapper } from '@halo-data-sources/mappers';
import { GetNotificationsInfiniteResult, NotificationsListQueryPayload } from '@halo-data-sources/models';
import { NotificationsQueryKeyFactory } from '@halo-data-sources/queries';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

const DEFAULT_PAGINATION = { next: 1 };

const notificationsInfiniteQueryFn = async (payload: NotificationsListQueryPayload, pageParam = DEFAULT_PAGINATION) => {
  const paginatedRequest = {
    ...payload,
    page: pageParam.next,
  };

  const response = await getNotifications(paginatedRequest);
  const mappedResponse = response.results.map(ApiNotificationMapper.toNotificationModel);
  const mappedPagination = PaginationMapper.toInfinitePaginationModel(response.pagination);

  return { notifications: mappedResponse, pagination: mappedPagination };
};

export type UseNotificationsInfiniteQueryResult = UseInfiniteQueryResult<GetNotificationsInfiniteResult, Error>;

export const useNotificationsInfiniteListQuery = (
  options?: Partial<InfiniteQueryOptions<GetNotificationsInfiniteResult>>,
) => {
  const filters = useAtomValue(notificationFiltersAtom);
  const payload = NotificationMapper.toApiNotificationsPayload(filters);

  return useHaloInfiniteQuery<GetNotificationsInfiniteResult>({
    queryKey: NotificationsQueryKeyFactory.infinite(filters),
    queryFn: ({ pageParam }) => notificationsInfiniteQueryFn(payload, pageParam),
    ...options,
  });
};
