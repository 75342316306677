import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { LocalizedAlertTitle } from '@halodomination/halo-fe-common';
import { Alert } from '@mui/material';
import { useT } from '@transifex/react';

export type QuizManagerErrorProps = { threshold?: number; questionCount?: number };

export const QuizManagerError = ({ threshold = 0, questionCount = 0 }: QuizManagerErrorProps): ReactElement => {
  const translator = useT();

  const dynamicContent = {
    threshold,
    questionCount,
  };

  const errorMessage = translator(translations.education.educationQuizManager.errorMessage, dynamicContent);

  return (
    <Alert severity="warning" variant="filled">
      <LocalizedAlertTitle>{translations.education.educationQuizManager.quizFailed}</LocalizedAlertTitle>
      {errorMessage}
    </Alert>
  );
};
