import { ReactElement } from 'react';

import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Box, capitalize, Skeleton } from '@mui/material';

const containerSx = { py: 4 };
const loadingIndicatorSx = { display: 'flex', flexAlign: 'center', justifyContent: 'center' };
const letterSx = { color: 'text.secondary' };
const lineStx = { borderBottom: '1px solid', borderColor: 'grey.300', width: '100%' };

export type GlossarySectionHeaderProps = {
  letter?: string;
  loading?: boolean;
};

export const GlossarySectionHeader = ({ letter = '', loading = false }: GlossarySectionHeaderProps): ReactElement => {
  if (loading) {
    return (
      <Stack direction="row" sx={containerSx} alignItems="center" xs={[1, 1, 10]}>
        <Box sx={lineStx} />
        <Box sx={loadingIndicatorSx}>
          <Skeleton variant="rounded" width={24} height={24} />
        </Box>
        <Box sx={lineStx} />
      </Stack>
    );
  }

  return (
    <Stack direction="row" sx={containerSx} alignItems="center" xs={[1, 1, 10]}>
      <Box sx={lineStx} />
      <LocalizedTypography sx={letterSx} align="center">
        {capitalize(letter)}
      </LocalizedTypography>
      <Box sx={lineStx} />
    </Stack>
  );
};
