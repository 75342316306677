import { ReactElement } from 'react';

import { executionHubEditReofferModalAtom } from '@halo-atoms/executionHub';
import { translations } from '@halo-common/translations';
import { useCalendarQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import {
  GridColDef,
  HaloDataGrid,
  HaloDataGridProps,
  Iconography,
  LocalizedAlert,
  LocalizedTypography,
  Stack,
  Tooltip,
} from '@halodomination/halo-fe-common';
import { Box, Chip, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useFormContext } from 'react-hook-form';

const commonCellProps: Partial<GridColDef> = {
  flex: 1,
  editable: false,
  resizable: true,
  disableColumnMenu: true,
  sortable: true,
};

const chipLabelSx = {
  display: 'flex',
  alignItems: 'center',
};

const chipLabelTextSx = {
  mr: 1,
};

const chipSx = {
  backgroundColor: 'primary.background',
  color: 'primary.main',
  mr: 1,
};

const reofferCellSx = {
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'flex-end',
  alignItems: 'center',
};

export const V2ExecutionHubEditReofferModalConfirm = (): ReactElement => {
  const { watch } = useFormContext();

  const modalState = useAtomValue(executionHubEditReofferModalAtom);

  const { data: user } = useUserInfoQuery();
  const { data: calendar, isPending } = useCalendarQuery(modalState.calendar?.id);

  const [price, isBrokerage] = watch(['price', 'isBrokerage']);

  const managedOriginalReoffer = calendar?.wholesaler?.price;
  const transactionalOriginalReoffer = calendar?.wholesaler?.salePrice;
  const maxCommission = user?.organization?.maxCommission;

  const maxCommissionValue = maxCommission ? 100 - maxCommission : null;
  const exceedsMaxCommission = isBrokerage && maxCommissionValue && maxCommissionValue > price;
  const exceedsTypicalDropInBip = managedOriginalReoffer && managedOriginalReoffer - price >= 0.5;
  const brokeragePrice = exceedsMaxCommission ? maxCommissionValue : price;

  const advisoryRow = {
    id: 'advisory',
    type: translations.common.advisory,
    originalReoffer: managedOriginalReoffer,
    reoffer: price,
  };

  const rows = isBrokerage
    ? [
        advisoryRow,
        {
          id: 'brokerage',
          type: translations.common.brokerage,
          originalReoffer: transactionalOriginalReoffer,
          reoffer: brokeragePrice,
        },
      ]
    : [advisoryRow];

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      headerName: 'account type',
      field: 'type',
      translateCell: true,
    },
    {
      ...commonCellProps,
      headerName: 'original reoffer',
      field: 'originalReoffer',
      align: 'right',
    },
    {
      ...commonCellProps,
      headerName: 'new reoffer',
      field: 'reoffer',
      align: 'right',
      renderCell: ({ row, value }) => {
        const showChip = row.id === 'brokerage' && exceedsMaxCommission;
        const maxCommissionText = maxCommission ?? '';

        const label = showChip ? (
          <Box sx={chipLabelSx}>
            <LocalizedTypography
              sx={chipLabelTextSx}
              variant="body2"
              color="textSecondary"
              dynamicContent={{ maxCommission: maxCommissionText }}
            >
              {translations.executionHub.reofferModal.maxCommissionColumn}
            </LocalizedTypography>
            <Tooltip title={`The maximum commission for this account type is ${maxCommission}%.`}>
              <Iconography prefix="fal" iconName="circle-info" color="text.secondary" />
            </Tooltip>
          </Box>
        ) : null;

        const chip = showChip ? <Chip sx={chipSx} label={label} size="small" /> : null;

        return (
          <Box sx={reofferCellSx}>
            {chip}
            <Typography variant="body2">{value}</Typography>
          </Box>
        );
      },
    },
  ];

  const priceDropCapWarning = exceedsTypicalDropInBip ? (
    <LocalizedAlert variant="outlined" severity="warning">
      {translations.executionHub.reofferModal.exceeds50BpsMessage}
    </LocalizedAlert>
  ) : null;

  const reofferCapWarning = exceedsMaxCommission ? (
    <LocalizedAlert variant="outlined" severity="warning" dynamicContent={{ maxCommissionValue }}>
      {translations.executionHub.reofferModal.exceedsMaxCommissionMessage}
    </LocalizedAlert>
  ) : null;

  return (
    <Stack direction="column" spacing={2}>
      {priceDropCapWarning}
      {reofferCapWarning}
      <HaloDataGrid columns={columns} rows={rows} density="compact" loading={isPending} />
    </Stack>
  );
};
