import { OrderBookAuctionQueryFields } from '@halo-common/models';
import { downloadFile } from '@halo-common/utils';
import { postCalendarOrderCSVDownload } from '@halo-data-sources/clients';
import { JanneyOrderBookMapper, OrderBookMapper } from '@halo-data-sources/mappers';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { DateTime } from 'luxon';

const orderBookDownloadJanneyCSVMutationFn = async (searchParams: OrderBookAuctionQueryFields) => {
  const sort = OrderBookMapper.toApiOrderBookSortList(searchParams);
  const comparisons = JanneyOrderBookMapper.toApiFiltersComparisonList(searchParams);

  const response = await postCalendarOrderCSVDownload({
    sort,
    comparisons,
    page: null,
    page_length: null,
  });

  const csvDownload = new Blob([response], { type: 'text/csv' });
  const url = URL.createObjectURL(csvDownload);

  const date = DateTime.local().toFormat('MMddyyyy_hhmmss');
  const fileName = `orders.${date}.csv`;

  downloadFile({ fileName, downloadFilePath: url });
};

export const useOrderBookDownloadJanneyCSVMutation = (): UseMutationResult<void, Error, OrderBookAuctionQueryFields> =>
  useMutation<void, Error, OrderBookAuctionQueryFields>({
    mutationFn: orderBookDownloadJanneyCSVMutationFn,
  });
