import { ReactElement } from 'react';

import { useRecordActivityMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { useOnboardingSettingValidation } from '@halo-modules/app/v2/onboarding';
import { Iconography, LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Box, Stack } from '@mui/material';

const bodySx = {
  my: 3,
  maxWidth: '464px',
};

const titleSx = {
  maxWidth: '560px',
};

const mediaContentSx = {
  mb: 3,
  maxWidth: '400px',
  maxHeight: '400px',
};

const imageSx = {
  maxHeight: '100%',
  maxWidth: '100%',
};

const videoSx = {
  border: 'none',
  borderRadius: '16px',
};

export const OnboardingFullDrawer = (): ReactElement | null => {
  const { data: user } = useUserInfoQuery();
  const { mutate: recordActivity } = useRecordActivityMutation();

  const onboardingSetting = user?.settings?.onboarding;
  const backgroundImage = onboardingSetting?.drawerBackground?.path;
  const isLightBackground = onboardingSetting?.isLightBackground;
  const overline = onboardingSetting?.overline ?? '';
  const title = onboardingSetting?.title ?? '';
  const subtitle = onboardingSetting?.subtitle ?? '';
  const body = onboardingSetting?.body ?? '';
  const video = onboardingSetting?.video;
  const image = onboardingSetting?.image;
  const buttonText = onboardingSetting?.buttonText ?? '';
  const buttonLink = onboardingSetting?.buttonLink ?? '';

  const hasContent = useOnboardingSettingValidation();
  const hideDrawer = !backgroundImage && !hasContent;
  if (hideDrawer) return null;

  const showButton = buttonText && buttonLink;

  const overlineTextColor = isLightBackground ? 'text.secondary' : 'secondary.main';
  const titleTextColor = isLightBackground ? 'common.black' : 'primary.contrastText';
  const subtitleTextColor = isLightBackground ? 'text.secondary' : 'grey.500';
  const bodyTextColor = isLightBackground ? 'text.secondary' : 'grey.500';
  const buttonColor = isLightBackground ? 'primary' : 'secondary';
  const buttonIconColor = isLightBackground ? 'primary.main' : 'secondary.main';

  const mainContentBackground = isLightBackground
    ? 'rgba(255, 255, 255, 0.9)'
    : 'radial-gradient(62.37% 71.99% at 0% 50%, #1E3543 0%, rgba(6, 17, 34, 0.055) 94.5%, rgba(5, 15, 32, 0) 100%)';

  const onboardingSx = {
    flexBasis: '50%',
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: 'common.black',
    backgroundSize: 'cover',
    backgroundPosition: 'top left',
    pb: { md: 12, xs: 6 },
    pt: { md: 12, xs: 9 },
    px: { md: 11, xs: 9 },
  };

  const mainContentSx = {
    flexDirection: 'column',
    display: 'flex',
    padding: 3,
    minWidth: '50%',
    borderRadius: 2,
    background: hasContent ? mainContentBackground : 'transparent',
  };

  const trackCtaLink = () => {
    recordActivity({
      activity: [{ verb: 'CTA Button link pressed', model_name: 'Onboarding' }],
      fromIFrame: false,
    });
  };

  const mediaContent = image ? (
    <img style={imageSx} src={image.path} data-testid="onboarding-img" />
  ) : video ? (
    <iframe width="350px" height="205px" style={videoSx} src={video} />
  ) : null;

  const buttonIcon = <Iconography color={buttonIconColor} iconName="arrow-right" />;

  const button = showButton ? (
    <LocalizedButton
      color={buttonColor}
      target="_blank"
      size="medium"
      variant="outlined"
      onClick={trackCtaLink}
      href={buttonLink}
      endIcon={buttonIcon}
    >
      {buttonText}
    </LocalizedButton>
  ) : null;

  return (
    <Stack direction="row" sx={onboardingSx} alignItems="center" justifyContent="center">
      <Box sx={mainContentSx}>
        <LocalizedTypography color={overlineTextColor} variant="overline">
          {overline}
        </LocalizedTypography>
        <LocalizedTypography sx={titleSx} color={titleTextColor} variant="h2">
          {title}
        </LocalizedTypography>
        <LocalizedTypography sx={titleSx} color={subtitleTextColor} variant="h5">
          {subtitle}
        </LocalizedTypography>
        <LocalizedTypography sx={bodySx} color={bodyTextColor} variant="body1">
          {body}
        </LocalizedTypography>
        <Stack direction="column" alignItems="space-between">
          <Box sx={mediaContentSx}>{mediaContent}</Box>
          {button}
        </Stack>
      </Box>
    </Stack>
  );
};
