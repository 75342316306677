import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type IssuerQueryKeys = {
  all: QueryKeyFactoryFn;
  list: QueryKeyFactoryFn;
  byAllocation: QueryKeyFactoryFn;
  byExecutionOrder: QueryKeyFactoryFn;
  byAdminAllocation: QueryKeyFactoryFn;
};

export const IssuerQueryKeyFactory: IssuerQueryKeys = {
  all: () => ['issuers'],
  list: (includeCds) => [...IssuerQueryKeyFactory.all(), 'list', includeCds],
  byAllocation: (query) => [...IssuerQueryKeyFactory.all(), 'by', 'allocation', hash(query)],
  byExecutionOrder: (query) => [...IssuerQueryKeyFactory.all(), 'by', 'exec', 'orders', hash(query)],
  byAdminAllocation: (query) => [...IssuerQueryKeyFactory.all(), 'by', 'admin', 'allocation', hash(query)],
};
