import { ReactElement } from 'react';

import { FindPriceButton } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Box, Stack, Typography } from '@mui/material';

export type ProductCardNoteExpandedDetailsSolvableParameterRowProps = {
  id: number;
  label: string;
  type: string;
  value?: string | number | null;
  isGeneralNote: boolean;
};

export const ProductCardNoteExpandedDetailsSolvableParameterRow = ({
  id,
  label,
  type,
  value,
  isGeneralNote,
}: ProductCardNoteExpandedDetailsSolvableParameterRowProps): ReactElement => {
  const noteTypeText = `${type} Note`;
  const parsedValue = typeof value === 'string' ? value : value?.toFixed(2);
  const showFindPriceButton = !parsedValue && !isGeneralNote;

  const noteTypeContent = (
    <LocalizedTypography component="span" variant="body2" fontWeight="fontWeightMedium" textTransform="capitalize">
      {noteTypeText}
    </LocalizedTypography>
  );

  const solvableParameterText = parsedValue ? (
    <Box>
      {noteTypeContent}
      <LocalizedTypography component="span">{` ${translations.common.with} `}</LocalizedTypography>
      <Typography component="span" variant="body2" fontWeight="fontWeightMedium">
        {`${parsedValue}% `}
      </Typography>
      <LocalizedTypography component="span">{label}</LocalizedTypography>
    </Box>
  ) : (
    noteTypeContent
  );

  const findPriceButton = showFindPriceButton ? <FindPriceButton productId={id} size="small" /> : null;

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {solvableParameterText}
      {findPriceButton}
    </Stack>
  );
};
