import { ReactElement } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { addCustodianPositionsAtom } from '@halo-atoms/portfolio';
import { ActionButton } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { useCustodianIntegrationMutation } from '@halo-data-sources/mutations';
import { Iconography, LocalizedButton, LocalizedTypography, Modal, Stack } from '@halodomination/halo-fe-common';
import { Box } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';

const iframeSx = {
  height: 500,
  width: '100%',
  border: 0,
  px: 1,
  pb: 2,
  backgroundColor: 'common.white',
  borderRadius: 1,
};

const actionContainerSx = {
  width: '100%',
  backgroundColor: 'info.background',
  pt: 0,
  pb: 3,
  px: 2,
  borderRadius: 1,
  m: 0,
  mt: 2,
};

export const AddCustodianPositionsModal = (): ReactElement => {
  const { mutate: initiateBAASession, isPending } = useCustodianIntegrationMutation();

  const onModalToggle = useSetAtom(modalAtom);
  const [addCustodianPositionsOptions, setAddCustodianPositionsOptions] = useAtom(addCustodianPositionsAtom);

  const { showModal, selectedCustodian } = addCustodianPositionsOptions;

  const link = selectedCustodian?.link ?? '';
  const name = selectedCustodian?.name;
  const title = `Connect with ${name}`;

  const handleClose = () => {
    setAddCustodianPositionsOptions();
  };

  const handleBack = () => {
    setAddCustodianPositionsOptions();
    onModalToggle({ addAccountOrPositionsModal: true });
  };

  const handleBAAClick = () => {
    initiateBAASession();
  };

  return (
    <Modal
      title={title}
      icon="user-plus"
      overline={translations.portfolio.custodianModal.overline}
      open={showModal}
      onClose={handleClose}
      footer={
        <LocalizedButton
          onClick={handleBack}
          startIcon={<Iconography iconName="arrow-left" color="primary.main" />}
          color="primary"
          variant="text"
        >
          {translations.common.back}
        </LocalizedButton>
      }
    >
      <Box src={link} component="iframe" sx={iframeSx} />
      <Stack direction="column" sx={actionContainerSx} spacing={2}>
        <Stack direction="column" spacing={1}>
          <LocalizedTypography variant="overline">
            {translations.portfolio.custodianModal.descriptionLine1}
          </LocalizedTypography>
          <LocalizedTypography variant="h6">
            {translations.portfolio.custodianModal.descriptionLine2}
          </LocalizedTypography>
        </Stack>
        <ActionButton
          loading={isPending}
          onClick={handleBAAClick}
          variant="contained"
          color="primary"
          endIcon={<Iconography iconName="arrow-up-right-from-square" color="primary.contrastText" />}
        >
          {translations.portfolio.custodianModal.continueButton}
        </ActionButton>
      </Stack>
    </Modal>
  );
};
