import { ReactElement, useEffect } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { orderTicketCSVUploadModalAtom, orderTicketValidationApprovalModalAtom } from '@halo-atoms/orderTicket';
import { SubmittedAllocationsTable } from '@halo-common/components';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { CalendarOrderCategoryEnum, CalendarOrderStatusEnum, OrganizationCapacityEnum } from '@halo-common/enums';
import {
  useExcelFileUploader,
  useNoteDetailCaption,
  useOrderTicketContentPicker,
  useProductProgress,
  useWhiteLabelDateConfiguration,
} from '@halo-common/hooks';
import { OrderTicketFormModelFields, UploadFileModal } from '@halo-common/modals';
import { AllocationModel } from '@halo-common/models';
import { useWebSocketContext } from '@halo-common/providers';
import { BulkOrderTicketFormFields, SingleOrderTicketFormFields } from '@halo-common/schemas';
import {
  useAccountAllocationCSVUploadMutation,
  useCancelExecCalendarOrderMutation,
  useCancelFixOrderMutation,
} from '@halo-data-sources/mutations';
import { CalendarsQueryKeyFactory, useCalendarAllocationsQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { useOrderTicketValidationSwitch } from '@halo-data-sources/switches';
import { HaloDataGridProps, TabbedModalLayout } from '@halodomination/halo-fe-common';
import { capitalize } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';

import { OrderTicketConfirmationModal } from './OrderTicketConfirmationModal';
import { OrderTicketFooter } from './OrderTicketFooter';
import { OrderTicketSubmissionForm } from './OrderTicketSubmissionForm';

const containerProps = { sx: { padding: 3 } };

export type OrderTicketSubmissionProps = {
  bulk?: boolean;
  onClose: () => void;
  onUpdateOrder?: () => void;
};

export const OrderTicketSubmission = ({
  bulk = false,
  onClose,
  onUpdateOrder,
}: OrderTicketSubmissionProps): ReactElement => {
  const queryClient = useQueryClient();
  const { events } = useWebSocketContext();

  const configureDateTime = useWhiteLabelDateConfiguration();

  const { setValue, handleSubmit, watch } = useFormContext<OrderTicketFormModelFields>();

  const { needsValidationApproval } = useOrderTicketContentPicker();

  const [csvUploadModalOpen, setCSVUploadModal] = useAtom(orderTicketCSVUploadModalAtom);
  const [approvalModalOpen, setApprovalModalOpen] = useAtom(orderTicketValidationApprovalModalAtom);
  const selectedCalendar = useAtomValue(calendarSelectedAtom);
  const { status } = useProductProgress({ calendar: selectedCalendar });

  const { data: user } = useUserInfoQuery();
  const { data: allocations = [] } = useCalendarAllocationsQuery(selectedCalendar?.id);
  const { mutate: validateData, isPending: validatingOrder } = useOrderTicketValidationSwitch();

  const { mutate: cancelFixOrder, isPending: cancellingFixOrder } = useCancelFixOrderMutation();
  const { mutate: uploadCSV, isPending: csvLoading } = useAccountAllocationCSVUploadMutation();
  const { mutate: cancelExecOrder, isPending: cancellingExecOrder } = useCancelExecCalendarOrderMutation({
    onSuccess: (data, allocationId) => {
      if (!data) return undefined;

      onUpdateOrder?.();

      const key = CalendarsQueryKeyFactory.allocations(selectedCalendar?.id);
      queryClient.setQueryData<Array<AllocationModel>>(key, (prev) => {
        if (!prev) return undefined;

        const updatedAllocations = [...prev];
        const index = updatedAllocations.findIndex(({ id }) => id === allocationId);
        updatedAllocations.splice(index, 1, data.allocation);

        return updatedAllocations;
      });
    },
  });

  const handleExcelUpload = useExcelFileUploader(uploadCSV, ['Account Number', 'Quantity in Thousands']);

  const pendingCancelFix = Boolean(events.fix.queue.length);
  const cancellingOrder = cancellingExecOrder || cancellingFixOrder || pendingCancelFix;

  const cusip = selectedCalendar?.cusip ?? '';
  const note = selectedCalendar?.note;
  const issuer = selectedCalendar?.issuer;
  const category = selectedCalendar?.category;

  const capacities = user?.organization?.capacities;
  const allocationCount = allocations?.filter((allocation) => allocation.status !== 'canceled')?.length ?? 0;

  const isClosed = status === CalendarOrderStatusEnum.Closed;
  const hideForm = isClosed || selectedCalendar?.paused;
  const newIOI = !hideForm ? [{ label: 'New IOI' }] : [];
  const tabs = [...newIOI, { label: 'Submitted IOI', count: allocationCount }];
  const title = `Submit Indication ${cusip}`;
  const uploadModalSubtitle = 'Uploading a file here will replace all previously entered spreads.';

  const subtitle = useNoteDetailCaption({ note, issuer, category });

  const selectedAccount = watch('account.account');
  const accountHasAllocations = allocations.some(
    ({ account, status }) => account.id === selectedAccount?.id && status !== 'canceled',
  );

  const showConfirmationModal = needsValidationApproval && accountHasAllocations;

  const entryFormContent = !hideForm ? <OrderTicketSubmissionForm bulk={bulk} /> : null;

  const commonCellProps = {
    editable: false,
    resizable: false,
    disableReorder: true,
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      field: 'account',
      headerName: 'ACCOUNT',
      flex: 0.9,
      valueGetter: (_, row: AllocationModel) => row?.account?.accountId,
    },
    {
      ...commonCellProps,
      field: 'amount',
      headerName: 'QUANTITY',
      flex: 0.9,
      valueGetter: (_, row: AllocationModel) => {
        return new Intl.NumberFormat().format(row.amount / 1000);
      },
    },
    {
      ...commonCellProps,
      field: 'updatedOn',
      headerName: 'DATE SUBMITTED',
      flex: 1.25,
      valueFormatter: (value: string) => {
        const date = DateTime.fromJSDate(new Date(value ?? ''), { zone: 'utc' }).toISO();
        return configureDateTime(date)?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
      },
    },
    {
      ...commonCellProps,
      field: 'status',
      headerName: 'STATUS',
      flex: 0.9,
      valueFormatter: (value: string) => {
        const status = value ?? '';
        return capitalize(status);
      },
    },
  ];

  const validationHandler = (fields: OrderTicketFormModelFields) => {
    if (bulk) validateData({ calendar: selectedCalendar, bulkData: fields as BulkOrderTicketFormFields });
    else validateData({ calendar: selectedCalendar, singleData: fields as SingleOrderTicketFormFields });
  };

  const validationSubmitHandler = (fields: OrderTicketFormModelFields) => {
    if (showConfirmationModal) setApprovalModalOpen(true);
    else validationHandler(fields);
  };

  const confirmationSubmitHandler = (fields: OrderTicketFormModelFields) => {
    setApprovalModalOpen(false);
    validationHandler(fields);
  };

  const handleCancelOrder = (orderId: number, accountId?: number) => {
    if (!bulk) cancelExecOrder(orderId);
    else cancelFixOrder({ allocationId: orderId, accountId });
  };

  const onValidationSubmit = handleSubmit(validationSubmitHandler);
  const onConfirmationSubmit = handleSubmit(confirmationSubmitHandler);
  const handleCloseCSVUpload = () => setCSVUploadModal(false);
  const closeConfirmationHandler = () => setApprovalModalOpen(false);

  useEffect(() => {
    const contentLoaded = selectedCalendar?.id && capacities?.length;

    if (contentLoaded) {
      const calendarCategory = selectedCalendar?.category?.toLowerCase() as CalendarOrderCategoryEnum;

      const capacityType = {
        [CalendarOrderCategoryEnum.advisory]: OrganizationCapacityEnum.AGENCY,
        [CalendarOrderCategoryEnum.brokerage]: OrganizationCapacityEnum.PRINCIPAL,
        [CalendarOrderCategoryEnum.exclusive]: undefined,
      }[calendarCategory];

      const index = capacities.findIndex((capacity) => capacity.toLowerCase() === capacityType?.toLowerCase());
      const selectedCapacity = capacities[index];

      setValue('capacityType', selectedCapacity, { shouldDirty: true });
    }
  }, [selectedCalendar?.id, selectedCalendar?.category, capacities?.length]);

  return (
    <>
      <TabbedModalLayout
        tabs={tabs}
        title={title}
        subtitle={subtitle}
        onClose={onClose}
        ContentProps={containerProps}
        footer={<OrderTicketFooter onSubmit={onValidationSubmit} onCancel={onClose} loading={validatingOrder} />}
      >
        {entryFormContent}
        <SubmittedAllocationsTable
          allocations={allocations}
          columns={columns}
          loading={cancellingOrder}
          onCancel={handleCancelOrder}
        />
      </TabbedModalLayout>
      <OrderTicketConfirmationModal
        open={approvalModalOpen}
        onSubmit={onConfirmationSubmit}
        onClose={closeConfirmationHandler}
      />
      <UploadFileModal
        open={csvUploadModalOpen}
        onSubmit={handleExcelUpload}
        subtitle={uploadModalSubtitle}
        title="Upload file"
        fileTypes=".csv,.xls,.xlsx"
        loading={csvLoading}
        onClose={handleCloseCSVUpload}
      />
    </>
  );
};
