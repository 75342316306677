import { IconographyProps } from '@halodomination/halo-fe-common';

export const watchlistIconNames = [
  'acorn',
  'car',
  'house',
  'plane',
  'charging-station',
  'apartment',
  'trophy-star',
  'globe',
  'gamepad-modern',
  'chart-line-up',
  'chart-line-down',
  'hospital',
  'sack-dollar',
  'money-bill',
  'shield',
  'calendar',
] as Array<IconographyProps['iconName']>;

export const DEFAULT_WATCHLIST_ICON: IconographyProps['iconName'] = 'acorn';
