import { DEFAULT_INFINITE_QUERY_NEXT_PAGINATION } from '@halo-common/constants';
import { SortModelDirectionEnum, SortModelNullSortEnum } from '@halo-common/enums';
import { HouseholdBasicModel } from '@halo-common/models';
import { getProductAccounts } from '@halo-data-sources/clients';
import { ApiAccountMapper, PaginationMapper } from '@halo-data-sources/mappers';
import { ApiGetAccountProductRequestModel } from '@halo-data-sources/models';

import {
  GetAccountInfiniteQueryDataSwitchRequest,
  GetAccountInfiniteQueryDataSwitchResult,
} from '../useAccountInfiniteQueryDataSwitch';

export const useAccountPositionsInfiniteQueryDataSwitchFn = async (
  payload: GetAccountInfiniteQueryDataSwitchRequest,
  pageParam = DEFAULT_INFINITE_QUERY_NEXT_PAGINATION,
): Promise<GetAccountInfiniteQueryDataSwitchResult> => {
  const filters = {
    search: payload.search,
    has_positions: payload.hasPositions,
    custodian_ids: payload.custodianIds,
  };

  const request: ApiGetAccountProductRequestModel = {
    filters,
    page: pageParam.next,
    page_length: payload.pageLength,
    sort: [
      { field: 'advisee_name', direction: SortModelDirectionEnum.asc, nulls: SortModelNullSortEnum.first },
      { field: 'name', direction: SortModelDirectionEnum.asc, nulls: SortModelNullSortEnum.first },
    ],
  };

  const result = await getProductAccounts(request);

  const accounts = result.accounts.map(ApiAccountMapper.toAccountModel);
  const pagination = PaginationMapper.toInfinitePaginationModel(result.pagination);
  const households = result.accounts
    .map(ApiAccountMapper.toHouseholdBasicModel)
    .filter(Boolean) as Array<HouseholdBasicModel>;

  return { accounts, households, pagination };
};
