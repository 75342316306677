import { ReactElement } from 'react';

import { HaloProgressBar } from '@halo-common/components';
import { NoteDetailsEventData, NoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useNoteIncomeOutcomesQuery } from '@halo-data-sources/queries';
import { HaloDataGrid, HaloDataGridProps } from '@halodomination/halo-fe-common';
import { useT } from '@transifex/react';

export type PreTradeNoteDetailModalEarlyRedemptionTableProps = {
  product?: NoteModel | null;
  loading?: boolean;
};

export const PreTradeNoteDetailModalEarlyRedemptionTable = ({
  product,
  loading,
}: PreTradeNoteDetailModalEarlyRedemptionTableProps): ReactElement => {
  const translator = useT();
  const id = product?.id;

  const { data, isPending: incomeOutcomesLoading } = useNoteIncomeOutcomesQuery(id);

  const isLoading = loading || incomeOutcomesLoading;

  const rows = data?.earlyRedemption ?? [];

  const commonCellProps = {
    editable: false,
    resizable: false,
    disableReorder: true,
    disableColumnMenu: true,
    flex: 1,
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      field: 'months',
      headerName: translations.common.timeElapsed,
      valueFormatter: (value: string) => {
        const monthsText = translator(translations.pdm.rollingReturns.averageLifetimeMonths, { months: value });
        return value ? monthsText : 'N/A';
      },
    },
    {
      ...commonCellProps,
      field: 'level',
      headerName: translations.common.level,
      translateCell: true,
      valueGetter: (_, row: NoteDetailsEventData) => {
        const callSchedule = product?.callInformation?.schedule;
        const levelData = callSchedule?.filter((entry) => entry.months === row.months) ?? [];

        if (levelData.length) {
          return `${levelData[0].level}%`;
        } else if (row.id === rows.length - 1) {
          return translations.pdm.preTrade.earlyRedemptionTableLevelFinalObservationDate;
        } else if (callSchedule) {
          return translations.pdm.preTrade.earlyRedemptionTableLevelNotCallable;
        }

        return '--';
      },
    },
    {
      ...commonCellProps,
      field: 'data',
      headerName: translations.common.probability,
      valueGetter: (value: number, row: NoteDetailsEventData) => {
        const callSchedule = product?.callInformation?.schedule;
        const levelData = callSchedule?.filter((entry) => entry.months === row.months) ?? [];

        if (!levelData.length) return null;
        else return value;
      },
      renderCell: ({ value }) => <HaloProgressBar value={value} />,
    },
  ];

  return <HaloDataGrid columns={columns} rows={rows} hideFooter loading={isLoading} density="compact" />;
};
