import { ReactElement, useEffect } from 'react';

import { executionHubEditReofferModalAtom, executionHubEditReofferModalStepAtom } from '@halo-atoms/executionHub';
import { ActionButton } from '@halo-common/components';
import { usePrimaryAssetIdentifier } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { useEditAdminCalendarReofferMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { ElementSelector, Iconography, LocalizedButton, Modal, Stack } from '@halodomination/halo-fe-common';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';

import { V2ExecutionHubEditReofferModalConfirm } from './V2ExecutionHubEditReofferModalConfirm';
import { V2ExecutionHubEditReofferModalForm } from './V2ExecutionHubEditReofferModalForm';

export const V2ExecutionHubEditReofferModal = (): ReactElement => {
  const { data: user } = useUserInfoQuery();

  const [modalState, setModalState] = useAtom(executionHubEditReofferModalAtom);
  const step = useAtomValue(executionHubEditReofferModalStepAtom.currentPageAtom);
  const handleNextStep = useSetAtom(executionHubEditReofferModalStepAtom.handleNextAtom);
  const handlePrevStep = useSetAtom(executionHubEditReofferModalStepAtom.handlePrevAtom);
  const handleResetStep = useSetAtom(executionHubEditReofferModalStepAtom.handleResetAtom);

  const reofferPrice = modalState?.reoffer;

  const formMethods = useForm<{ price: string; isBrokerage: boolean }>({
    defaultValues: {
      price: (reofferPrice ?? 0).toString(),
      isBrokerage: false,
    },
  });

  const { identifierText } = usePrimaryAssetIdentifier(modalState.calendar);

  const isFormStep = step === 0;
  const maxCommission = user?.organization?.maxCommission;
  const title = isFormStep
    ? translations.executionHub.reofferModal.editReofferModalTitle
    : translations.executionHub.reofferModal.confirmReofferModalTitle;

  const handleNext = () => {
    handleNextStep();
  };

  const handlePrev = () => {
    handlePrevStep();
  };

  const handleClose = () => {
    setModalState({ open: false });
    formMethods.reset();
    handleResetStep();
  };

  const { mutate, isPending } = useEditAdminCalendarReofferMutation(handleClose);

  const handleSubmit = formMethods.handleSubmit((data) => {
    const calendarId = modalState.calendar?.id;
    const parsedPrice = parseFloat(data.price);

    const advisoryPrice = parsedPrice;
    const brokeragePrice = data.isBrokerage
      ? maxCommission
        ? Math.max(100 - maxCommission, parsedPrice)
        : parsedPrice
      : undefined;

    if (calendarId) mutate({ id: calendarId, advisoryPrice, brokeragePrice });
  });

  const footer = isFormStep ? (
    <Stack direction="row" justify="space-between" alignItems="center">
      <LocalizedButton
        variant="text"
        color="primary"
        startIcon={<Iconography iconName="times" />}
        onClick={handleClose}
      >
        {translations.common.cancel}
      </LocalizedButton>
      <LocalizedButton color="primary" onClick={handleNext} variant="contained">
        {translations.common.save}
      </LocalizedButton>
    </Stack>
  ) : (
    <Stack direction="row" justify="space-between" alignItems="center">
      <LocalizedButton
        variant="text"
        color="primary"
        startIcon={<Iconography iconName="arrow-left" />}
        onClick={handlePrev}
      >
        {translations.common.back}
      </LocalizedButton>
      <ActionButton color="primary" onClick={handleSubmit} variant="contained" loading={isPending}>
        {translations.executionHub.reofferModal.confirmAndSaveButton}
      </ActionButton>
    </Stack>
  );

  useEffect(() => {
    if (reofferPrice) formMethods.setValue('price', modalState?.reoffer?.toString());
  }, [reofferPrice]);

  return (
    <FormProvider {...formMethods}>
      <Modal
        title={title}
        open={modalState.open}
        overline={identifierText}
        size="small"
        onClose={handleClose}
        icon="edit"
        footer={footer}
      >
        <ElementSelector selected={step}>
          <V2ExecutionHubEditReofferModalForm />
          <V2ExecutionHubEditReofferModalConfirm />
        </ElementSelector>
      </Modal>
    </FormProvider>
  );
};
