import { anchorElAtom } from '@halo-atoms/common';
import { WatchlistTargetQuoteDataModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { setTargetQuote } from '@halo-data-sources/clients';
import { ApiWatchlistMapper } from '@halo-data-sources/mappers';
import {
  NoteQueryKeyFactory,
  PDMKeyFactory,
  QuoteHistoryQueryResult,
  WatchlistQueryKeyFactory,
} from '@halo-data-sources/queries';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { useTheme } from '@mui/material';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';

export type UpdateWatchlistTargetQuoteMutationPayload = {
  productId: number;
  target: number;
};

export type UpdateWatchlistTargetQuoteMutationResult = {
  productId: number;
  targetQuote: WatchlistTargetQuoteDataModel;
  message: string;
};

const updateWatchlistTargetQuoteMutationFn = async (payload?: UpdateWatchlistTargetQuoteMutationPayload) => {
  if (!payload || !payload.productId) return null;

  const response = await setTargetQuote(payload.productId, payload.target);

  return {
    productId: payload.productId,
    targetQuote: ApiWatchlistMapper.toWatchlistTargetQuoteModel(response),
    message: 'Successfully updated target quote.',
  };
};

export const useUpdateWatchlistTargetQuoteMutation = (): UseMutationResult<
  UpdateWatchlistTargetQuoteMutationResult | null,
  Error,
  UpdateWatchlistTargetQuoteMutationPayload | undefined
> => {
  const queryClient = useQueryClient();
  const setAnchorElMap = useSetAtom(anchorElAtom);

  const theme = useTheme<HaloTheme>();

  const refColor = theme.palette?.common.charts?.positive?.main;

  return useMutation({
    mutationFn: updateWatchlistTargetQuoteMutationFn,
    onSuccess: async (data) => {
      if (data) {
        const targetHistoryKey = PDMKeyFactory.quoteHistory(data.productId);
        queryClient.setQueryData<QuoteHistoryQueryResult | null>(targetHistoryKey, (prev) => {
          if (!prev) return undefined;

          const targetLevel = data.targetQuote.target?.targetLevel;

          const updatedReferences = [...(prev.chart?.references ?? [])];
          const targetReferenceIndex = updatedReferences.findIndex(({ label }) => label === translations.common.target);

          updatedReferences.splice(targetReferenceIndex, 1, {
            point: targetLevel,
            label: translations.common.target,
            color: refColor,
            type: 'dash',
            orientation: 'y',
          });

          return { ...prev, chart: { ...prev.chart, references: updatedReferences } };
        });
        setAnchorElMap({ [data.productId]: null });

        const queryKey = WatchlistQueryKeyFactory.all();
        await queryClient.refetchQueries({ queryKey });

        const historyKey = NoteQueryKeyFactory.quoteHistory(data.productId);
        queryClient.setQueryData<QuoteHistoryQueryResult | null>(historyKey, (prev) => {
          if (!prev || !prev?.chart?.references?.[0]) return undefined;

          const targetLevel = data.targetQuote.target?.targetLevel;

          const updatedReferences = [{ ...prev.chart.references[0], point: targetLevel }];

          return { ...prev, chart: { ...prev.chart, references: updatedReferences } };
        });
      }
    },
  });
};
