import { ReactElement } from 'react';

import { orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { ProductCard } from '@halo-common/components';
import { AuctionStatusEnum, ProductTypeEnum } from '@halo-common/enums';
import { useCommonUserSetting } from '@halo-common/hooks';
import { useBuyerAuctionQuery } from '@halo-data-sources/queries';
import {
  AuctionDetailsActionButtons,
  AuctionDetailsCancelPopover,
  AuctionDetailsDates,
  AuctionDetailsIssuerBids,
  AuctionDetailsReviewTermsSection,
  AuctionDetailsSection,
  AuctionDetailsStatusTitle,
} from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';
import { Alert } from '@mui/material';
import { useAtomValue } from 'jotai';

export const AuctionDetailsStatus = (): ReactElement => {
  const showAllocationsTab = useCommonUserSetting('Show_Auction_Allocations_Tab', 'Auction');
  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);

  const auctionId = selectedAuctionId?.toString();

  const { data: auction, isPending = true } = useBuyerAuctionQuery(auctionId);

  const issuerBidsContent = <AuctionDetailsIssuerBids />;
  const reviewTermsContent = <AuctionDetailsReviewTermsSection />;
  const termsAcceptedContent = showAllocationsTab ? (
    <Alert severity="warning" variant="filled">
      You have not yet added any allocations to this trade. Please make sure all accounts are added to ensure smooth and
      timely delivery of this Note.
    </Alert>
  ) : null;

  const content = auction?.status
    ? {
        [AuctionStatusEnum.Canceled]: issuerBidsContent,
        [AuctionStatusEnum.ChooseWinner]: issuerBidsContent,
        [AuctionStatusEnum.InAuction]: issuerBidsContent,
        [AuctionStatusEnum.NotWin]: issuerBidsContent,
        [AuctionStatusEnum.OpsApproval]: issuerBidsContent,
        [AuctionStatusEnum.PreApproval]: issuerBidsContent,
        [AuctionStatusEnum.Purchased]: issuerBidsContent,
        [AuctionStatusEnum.TermsAccepted]: termsAcceptedContent,
        [AuctionStatusEnum.TermsPending]: auction?.prelim ? reviewTermsContent : issuerBidsContent,
        [AuctionStatusEnum.TermsReviewed]: reviewTermsContent,
      }[auction.status]
    : issuerBidsContent;

  return (
    <Stack direction="row" xs={12} md={[4, 8]} spacing={4} wrap="wrap">
      <Stack direction="column" spacing={4}>
        <Stack direction="column" alignItems="center">
          <ProductCard type={ProductTypeEnum.auction} product={auction} isLoading={isPending} />
        </Stack>
        <AuctionDetailsDates />
        <AuctionDetailsSection />
        <AuctionDetailsActionButtons />
        <AuctionDetailsCancelPopover />
      </Stack>
      <Stack direction="row" xs={12} sm={10} justify="center">
        <Stack direction="column" spacing={2}>
          <AuctionDetailsStatusTitle />
          {content}
        </Stack>
      </Stack>
    </Stack>
  );
};
