export const DEFAULT_WATCHLIST_TRANSLATIONS = {
  cantDelete: "Can't Delete Default Watchlist",
  chooseIcon: 'Choose an Icon',
  createWatchlistModalSubtitle: 'Create a watchlist to track specific positions, strategies, or underlying assets.',
  createWatchlistModalTitle: 'New Watchlist',
  defaultCreateError: 'Something went wrong. Please try submitting again in a few minutes.',
  deleteWatchlist: 'Delete Watchlist',
  editWatchlistModalSubtitle: 'Change the name, update the icon, or delete.',
  editWatchlistModalTitle: 'Edit Watchlist',
  myLists: 'My Lists',
  myTargetedProducts: 'My Targeted Products',
  noQuotesFound: 'No quotes found for this note.',
  productsCount: '{count, plural, one {{count} Product} other {{count} Products}}',
  returnToAll: 'Return to All Watchlists',
  targetedProductCount: '{count}/4 Products',
  targetedProducts: 'Targeted Products',
  updateNoteWatchlistError: 'There was a problem updating your watchlists. Please try again.',
  updateNoteWatchlistSuccess: 'Your watchlists have been successfully updated',
  updateWatchlistError: 'There was a problem updating your watchlist. Please try again.',
  updateWatchlistSuccess: 'Your watchlist has been successfully updated',
  watchlistIconError: 'Please select an icon from the list.',
  watchlistName: 'Watchlist Name',
  watchlistNameEmptyError: 'Empty watchlist names are not allowed. Please include at least one character.',
  watchlistNameError: 'Please enter a watchlist name.',
  watchlists: 'Watchlists',
};
