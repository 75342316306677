import { ReactElement } from 'react';

import { orderBookSelectedAuctionIdAtom } from '@halo-atoms/orderbook';
import { AuctionIssuerBidStatusEnum, AuctionStatusEnum } from '@halo-common/enums';
import { useAuctionIssuerBidStatus } from '@halo-common/hooks';
import { IssuerModel, QuoteAuctionModel } from '@halo-common/models';
import { useBuyerAuctionQuery } from '@halo-data-sources/queries';
import { IssuerBidFooter, IssuerBidQuote, IssuerBidStatus } from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';
import { Box, Paper } from '@mui/material';
import { useAtomValue } from 'jotai';

const issuerLogoSx = {
  maxWidth: 200,
  height: 32,
};

export type AuctionDetailsIssuerPanelProps = {
  issuer: IssuerModel;
  quote?: QuoteAuctionModel;
};

export const AuctionDetailsIssuerPanel = ({ issuer, quote }: AuctionDetailsIssuerPanelProps): ReactElement | null => {
  const bidStatus = useAuctionIssuerBidStatus({ issuer, quote });
  const selectedAuctionId = useAtomValue(orderBookSelectedAuctionIdAtom);
  const auctionId = selectedAuctionId?.toString();

  const { data: auction } = useBuyerAuctionQuery(auctionId);
  const winnerChosen = auction?.status === AuctionStatusEnum.TermsPending;
  const isWinner = bidStatus === AuctionIssuerBidStatusEnum.winningQuote;
  if (winnerChosen && !isWinner) return null;

  const biddingStatuses = [AuctionStatusEnum.InAuction, AuctionStatusEnum.OpsApproval, AuctionStatusEnum.PreApproval];
  const isBiddingInProgress = auction?.status && biddingStatuses.includes(auction.status);
  const noBidReceived = !isBiddingInProgress && bidStatus === AuctionIssuerBidStatusEnum.waitingForBid;
  const isDeclinedByIssuer = bidStatus === AuctionIssuerBidStatusEnum.declinedToBid;
  const isCanceled = auction?.status === AuctionStatusEnum.Canceled;

  const declinedBackground = isDeclinedByIssuer || isCanceled || noBidReceived ? { backgroundColor: 'grey.100' } : null;
  const paperSx = {
    px: 2,
    py: 1,
    ...declinedBackground,
  };

  const bidStatusContent = <IssuerBidStatus issuer={issuer} quote={quote} />;
  const quoteContent = !isCanceled ? <IssuerBidQuote quote={quote} /> : null;
  const issuerLogo = <Box component="img" alt={issuer.name} sx={issuerLogoSx} src={issuer.logoUrl} />;
  const footerContent = !isCanceled ? <IssuerBidFooter issuer={issuer} quote={quote} /> : null;

  return (
    <Paper variant="outlined" sx={paperSx}>
      <Stack direction="column" spacing={1}>
        <Stack direction="row" justify="space-between">
          <Stack direction="column" spacing={1}>
            {bidStatusContent}
            {issuerLogo}
          </Stack>
          {quoteContent}
        </Stack>
        {footerContent}
      </Stack>
    </Paper>
  );
};
