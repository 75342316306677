import { ReactElement, useEffect, useState } from 'react';

import { DocumentDownloadTable, ProductCard } from '@halo-common/components';
import { ProductTypeEnum, UserRoleEnum } from '@halo-common/enums';
import { useStringifyComponent } from '@halo-common/hooks';
import { PreTradeNoteDetailIndicativeTermsheetForm } from '@halo-common/modals';
import { NoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { GeneratePreTradeDocumentPayload, useGeneratePreTradeDocumentMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';

const DOCUMENT_DOWNLOAD_MAP = { termsheet: false, proposal: false };

export type PreTradeNoteDetailModalDocumentTableProps = {
  product?: NoteModel | null;
  loading?: boolean;
};

export const PreTradeNoteDetailModalDocumentTable = ({
  product,
  loading = true,
}: PreTradeNoteDetailModalDocumentTableProps): ReactElement => {
  const stringifyComponent = useStringifyComponent();

  const { data } = useUserInfoQuery();
  const { mutate, isPending } = useGeneratePreTradeDocumentMutation();

  const [documentDownloads, setDocumentDownloads] = useState(DOCUMENT_DOWNLOAD_MAP);

  const type = product?.type ?? 'default';

  const indicativeTermsheets = data?.whiteLabel.indicativeTermsheets;
  const indicativeTermsheetErgId = indicativeTermsheets?.[type] ?? '';
  const indicativeTermsheetEndpoint = `/pretrade/notes/${product?.id}/indicative-termsheet/download`;
  const hasTermsheetViewerRole = data?.details?.roles?.includes(UserRoleEnum.IndicativeTermsheetViewer);
  const showIndicativeTermsheet = Boolean(hasTermsheetViewerRole && indicativeTermsheetErgId);

  const noteProposals = data?.whiteLabel.noteProposals;
  const noteProposalErgId = noteProposals?.[type] ?? '';
  const noteProposalEndpoint = `/pretrade/notes/${product?.id}/note-proposal/download`;
  const showNoteProposal = Boolean(noteProposalErgId);

  const handleDocumentDownload = (name: string, query: Partial<GeneratePreTradeDocumentPayload>) => {
    setDocumentDownloads({ ...documentDownloads, [name]: true });

    const cardFrontHTML = stringifyComponent(
      <ProductCard type={ProductTypeEnum.note} product={product as NoteModel} />,
    );

    mutate({
      ...query,
      additionalContent: { cardFront: cardFrontHTML },
      observationType: product?.observationType,
      productId: product?.id,
      whiteLabelId: data?.whiteLabel.id,
      userId: data?.details.id,
      publicNoteId: product?.meta.shareKey,
    });
  };

  const handleIndicativeTermsheet = (graphStartDate: string, notional: string) => {
    handleDocumentDownload('termsheet', { endpoint: indicativeTermsheetEndpoint, notional, graphStartDate });
  };

  const handleNoteProposal = () => {
    handleDocumentDownload('proposal', { endpoint: noteProposalEndpoint });
  };

  const documents = [];

  if (showIndicativeTermsheet) {
    documents.push({
      id: 'termsheet',
      name: translations.pdm.preTrade.documentNameIndicativeTermsheet,
      downloading: documentDownloads.termsheet,
      popover: {
        content: (
          <PreTradeNoteDetailIndicativeTermsheetForm
            onDownload={handleIndicativeTermsheet}
            loading={isPending}
            currency={product?.currencyCode}
          />
        ),
      },
    });
  }

  if (showNoteProposal) {
    documents.push({
      id: 'proposal',
      name: translations.pdm.preTrade.documentNameNoteProposal,
      downloading: documentDownloads.proposal,
      onDownload: handleNoteProposal,
    });
  }

  useEffect(() => {
    if (!isPending) setDocumentDownloads(DOCUMENT_DOWNLOAD_MAP);
  }, [isPending]);

  return <DocumentDownloadTable loading={loading} documents={documents} />;
};
