import { ReactElement, useEffect, useState } from 'react';

import { Box, Skeleton, SxProps, Theme } from '@mui/material';
import {
  DateRangePicker as MuiDateRangePicker,
  DateRangePickerProps as MuiDateRangePickerProps,
  DateRangePickerSlotProps as MuiDateRangePickerSlotProps,
} from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';

import { DateRangePickerField, DateRangePickerFieldProps } from './DateRangePickerField';

export type DateRangePickerProps = Omit<DateRangePickerFieldProps, 'error' | 'sx'> &
  MuiDateRangePickerProps<DateTime> & {
    endDate: DateTime | string | null;
    error?: boolean;
    loading?: boolean;
    startDate: DateTime | string | null;
    sx?: SxProps<Theme>;
    walkMeNamespace?: string;
  };

export const DateRangePicker = ({
  endDate,
  helperText,
  error = false,
  open = false,
  label,
  loading = false,
  size,
  startDate,
  slots,
  slotProps,
  sx,
  walkMeNamespace = 'halo',
  ...props
}: DateRangePickerProps): ReactElement => {
  const [openModal, setOpenModal] = useState(open);

  useEffect(() => {
    if (open !== openModal) setOpenModal(open);
  }, [open]);

  if (loading) return <Skeleton variant="rounded" width="100%" height={40} />;

  const handleOpen = () => {
    setOpenModal(!openModal);
  };

  const startValue = typeof startDate === 'string' ? DateTime.fromISO(startDate) : startDate;
  const endValue = typeof endDate === 'string' ? DateTime.fromISO(endDate) : endDate;

  const id = `${walkMeNamespace}-date-range-picker`;

  const pickerSlots = {
    field: DateRangePickerField,
    ...slots,
  };

  const dateRangePickerSx = {
    minWidth: 280,
    ...sx,
  };

  const pickerSlotProps = {
    field: {
      helperText,
      error,
      size,
      label,
      fullWidth: true,
      onClick: handleOpen,
    } as MuiDateRangePickerSlotProps<DateTime, false>['field'],
    popper: {
      anchorEl: document.getElementById(id),
    },
    ...slotProps,
  };

  return (
    <Box id={id} sx={dateRangePickerSx}>
      <MuiDateRangePicker
        {...props}
        value={[startValue, endValue]}
        slots={pickerSlots}
        slotProps={pickerSlotProps}
        onClose={handleOpen}
        open={openModal}
      />
    </Box>
  );
};
