export enum CalendarOrderStatusEnum {
  Pending = 'pending',
  Inactive = 'inactive',
  Open = 'open',
  Expiring = 'expiring',
  Closed = 'closed',
  Closing = 'closing',
  Active = 'active',
}

export enum CalendarOrderCategoryEnum {
  advisory = 'advisory',
  brokerage = 'brokerage',
  exclusive = 'exclusive',
}
