import { ReactElement, useEffect } from 'react';

import { AddAccountModalFormFields, AddAccountSimpleVariant, AddAccountWizardVariant } from '@halo-common/modals';
import { AccountModel } from '@halo-common/models';
import { ModalBaseProps, ModalProps } from '@halodomination/halo-fe-common';
import { FormProvider, useForm } from 'react-hook-form';

export type FormDefaultValueType = AddAccountModalFormFields;

const FORM_DEFAULT_VALUE: FormDefaultValueType = {
  custodian: { id: -1, name: '' },
  accountName: '',
  accountNumber: '',
  accountHousehold: '',
};

export type AddAccountModalSimpleProps = {
  ModalProps?: Pick<ModalProps, 'title' | 'subtitle'>;
  onBack?: never;
  onSuccess?: (account?: AccountModel) => void;
  onClose?: () => void;
  onReset?: never;
  open?: boolean;
  selected?: never;
  step?: never;
  size?: ModalBaseProps['size'];
  variant?: 'simple';
};

export type AddAccountModalWizardProps = {
  ModalProps?: never;
  onBack?: () => void;
  onSuccess?: (account?: AccountModel) => void;
  onClose?: () => void;
  onReset?: () => void;
  open?: boolean;
  selected?: AccountModel | null;
  step?: number;
  size?: ModalBaseProps['size'];
  variant?: 'default';
};

export type AddAccountModalProps = AddAccountModalWizardProps | AddAccountModalSimpleProps;

export const AddAccountModal = ({
  onClose,
  onReset,
  onSuccess,
  selected,
  open = false,
  variant = 'default',
  ...props
}: AddAccountModalProps): ReactElement => {
  const formMethods = useForm<AddAccountModalFormFields>({ defaultValues: FORM_DEFAULT_VALUE });

  const { reset } = formMethods;

  const isDefaultVariant = variant === 'default';

  const handleClose = () => {
    onClose?.();
    reset();
  };

  const handleSuccess = (account?: AccountModel) => {
    onSuccess?.(account);
    reset();
  };

  const handleReset = () => {
    onReset?.();
    reset();
  };

  const content = isDefaultVariant ? (
    <AddAccountWizardVariant
      {...props}
      open={open}
      onClose={handleClose}
      onReset={handleReset}
      onSuccess={handleSuccess}
      selected={selected}
    />
  ) : (
    <AddAccountSimpleVariant {...props} open={open} onSuccess={handleSuccess} onClose={handleClose} />
  );

  useEffect(() => {
    const selectedCustodian = { id: selected?.custodian.id, name: selected?.custodian.name };

    reset({
      custodian: selectedCustodian.id ? selectedCustodian : FORM_DEFAULT_VALUE.custodian,
      accountName: selected?.name ?? FORM_DEFAULT_VALUE.accountName,
      accountNumber: selected?.accountId ?? FORM_DEFAULT_VALUE.accountNumber,
      accountHousehold: selected?.household ?? FORM_DEFAULT_VALUE.accountHousehold,
    });
  }, [selected]);

  return (
    <FormProvider {...formMethods}>
      <form>{content}</form>
    </FormProvider>
  );
};
