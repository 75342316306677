import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { PortfolioLifecycleWidgetTab } from '@halo-modules/app';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';

export type PortfolioLifecycleViewMoreButtonProps = {
  filters: PortfolioLifecycleWidgetTab['filters'];
};

export const PortfolioLifecycleViewMoreButton = ({ filters }: PortfolioLifecycleViewMoreButtonProps): ReactElement => {
  const viewMore = () => {
    const searchParams = new URLSearchParams();
    searchParams.set('tab', 'lifecycle');
    if (filters.startDate) searchParams.set('from_date', filters.startDate);
    if (filters.endDate) searchParams.set('to_date', filters.endDate);
    if (filters.eventTypes) searchParams.set('event_types', filters.eventTypes);
    if (filters.sortField) searchParams.set('sort_field', filters.sortField);
    const url = '/portfolio?' + searchParams.toString();
    navigateParentTo(url);
  };

  return (
    <LocalizedButton
      variant="text"
      color="primary"
      onClick={viewMore}
      endIcon={<Iconography color="inherit" iconName="arrow-right" />}
    >
      {translations.common.viewMore}
    </LocalizedButton>
  );
};
