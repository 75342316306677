import { ElementType, ReactElement } from 'react';

import { useLexiconTranslator } from '@halo-common/hooks';
import { LocalizedTypography, LocalizedTypographyProps } from '@halodomination/halo-fe-common';

export type LexicalTypographyProps = Omit<LocalizedTypographyProps, 'children'> & {
  children?: string;
  overriddenLexicon?: { [textToTranslate: string]: string };
  translationKey?: string;
  component?: ElementType;
};

export const LexicalTypography = ({
  children,
  overriddenLexicon,
  translationKey,
  ...props
}: LexicalTypographyProps): ReactElement => {
  const hasTranslationMap = children && translationKey;
  const translationKeyMap = hasTranslationMap ? { [children]: translationKey } : undefined;

  const [text] = useLexiconTranslator(children, {
    translationKeyMap,
    overriddenLexicon,
  });

  return <LocalizedTypography {...props}>{text}</LocalizedTypography>;
};
