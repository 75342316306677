import { ReactElement, useState } from 'react';

import { portfolioPositionDetailsModalAtom } from '@halo-atoms/portfolio';
import { DateTile } from '@halo-common/components';
import {
  LifecycleEventEnum,
  LifecycleEventTypeEnum,
  LifecycleRecommendationTypeEnum,
  ProductTypeEnum,
} from '@halo-common/enums';
import { useMobileView, usePrimaryAssetIdentifier } from '@halo-common/hooks';
import type { PortfolioLifecycleModel, PortfolioLifecycleRecommendationEnhancedModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useRecordActivityMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { usePortfolioLifecycleTitleNamesByType } from '@halo-modules/app';
import { Iconography, LocalizedButton, mapNumberToLocalCurrency } from '@halodomination/halo-fe-common';
import { Button, Collapse, IconButton, Paper, Stack } from '@mui/material';
import { useT } from '@transifex/react';
import { useSetAtom } from 'jotai';
import { DateTime } from 'luxon';

import { PortfolioEventsListItemColumn } from './PortfolioEventsListItemColumn';
import { PortfolioEventsPaymentBreakdownTable } from './PortfolioEventsPaymentBreakdownTable';

const portfolioEventsListItemSx = {
  padding: '8px 16px',
  marginBottom: 2,
  border: '1px solid',
  borderColor: 'grey.300',
};

const pastPortfolioEventsListItemSx = {
  ...portfolioEventsListItemSx,
  backgroundColor: 'grey.100',
};

const stackSx = {
  minHeight: 64,
};

const iconButtonSx = {
  minWidth: 32,
  minHeight: 32,
  height: 32,
  width: 32,
  flex: '0 0 auto',
  padding: 1,
};

const calendarSx = {
  flex: 0.5,
};

const featureListSx = {
  flex: 1,
};

const parseEventName = (name?: string | null, code?: string | null): string => {
  if (name && code) return `${name} - ${code}`;
  else return name || code || '';
};

export type PortfolioEventsListItemProps = {
  event: PortfolioLifecycleModel;
  reinvestment?: PortfolioLifecycleRecommendationEnhancedModel;
  filterTypes?: Array<string>;
  variant?: 'expandable' | 'non-expandable';
  actionButtonClassName?: string;
};

export const PortfolioEventsListItem = ({
  event,
  reinvestment,
  filterTypes,
  variant = 'expandable',
  actionButtonClassName,
}: PortfolioEventsListItemProps): ReactElement => {
  const translator = useT();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isMobile = useMobileView();

  const { mutate: recordActivity } = useRecordActivityMutation();
  const { data: user } = useUserInfoQuery();

  const setPositionModal = useSetAtom(portfolioPositionDetailsModalAtom);

  const {
    name,
    distancePercent,
    eventType,
    eventDate,
    currencyIsoCode,
    totalNotional,
    cashFlowAmount,
    paid,
    productId,
    numberOfCoupons,
    migrated,
  } = event;

  const { identifier } = usePrimaryAssetIdentifier(event);

  const translatedName = translator(name);
  const eventName = parseEventName(translatedName, identifier);

  const isExpired = DateTime.now().toFormat('yyyy-MM-dd') > eventDate;

  const { columnOne, columnTwo, columnThree } = usePortfolioLifecycleTitleNamesByType(isExpired, eventType);

  const columnOneTitle = columnOne(numberOfCoupons);
  const columnTwoTitle = columnTwo;
  const isDistancePercentNegative = distancePercent < 0;
  const isDistancePercentNumber = typeof distancePercent === 'number';
  const columnThreeTitle = columnThree(isDistancePercentNegative);
  const yesterday = DateTime.now().plus({ days: -1 });
  const iconName = isCollapsed ? 'chevron-up' : 'chevron-down';

  const eventTitle = LifecycleEventEnum[eventType];

  const isAutocallEvent = LifecycleEventEnum.AUTOCALL_OBS === eventTitle;
  const isIssuerCallObservation = LifecycleEventEnum.ISSUER_CALL_OBS === eventTitle;
  const isConditionalCoupon = LifecycleEventEnum.CONDITIONAL_COUPON === eventTitle;
  const isMemoryCoupon = LifecycleEventEnum.MEMORY_COUPON === eventTitle;

  const isCouponEvent = isConditionalCoupon || isMemoryCoupon;
  const isCallEvent = isAutocallEvent || isIssuerCallObservation;
  const isAboveDistancePercent = !isDistancePercentNegative && isDistancePercentNumber && isAutocallEvent;
  const isBelowDistancePercent = isDistancePercentNegative && isCouponEvent;
  const isAlertText = isAboveDistancePercent || isBelowDistancePercent;
  const isIncomeEvent = isCouponEvent || isCallEvent;
  const isEventDateInPast = DateTime.fromISO(eventDate) <= yesterday;
  const isExpandable = variant === 'expandable';

  const hasAuctionReinvestment = reinvestment?.recommendationType === LifecycleRecommendationTypeEnum.AUCTION;
  const hasCalendarReinvestment = reinvestment?.recommendationType === LifecycleRecommendationTypeEnum.CALENDAR;
  const hasReinvestment = hasAuctionReinvestment || hasCalendarReinvestment;

  const isIssuerCallObservedEvent = eventType === LifecycleEventTypeEnum.ISSUER_CALL_OBS;
  const isFixedCouponEvent = eventType === LifecycleEventTypeEnum.FIXED_COUPON;
  const isIssuerCallOrFixedCoupon = isFixedCouponEvent || isIssuerCallObservedEvent;

  let distancePercentText = '';
  if (distancePercent) distancePercentText = `${distancePercent.toFixed(2)}%`;
  else if (!isIssuerCallOrFixedCoupon) distancePercentText = 'N/A';

  const whiteLabelCurrencies = user?.whiteLabel?.currencies ?? [];
  const eventCurrency = whiteLabelCurrencies.find((currency) => currency.code === currencyIsoCode);
  const currencySymbol = eventCurrency?.symbol;

  const maximumFractionDigits = cashFlowAmount < 1 ? 2 : 0;
  const currencyMappingOptions = { currency: currencyIsoCode, maximumFractionDigits };
  const formattedCashFlowAmount = cashFlowAmount
    ? mapNumberToLocalCurrency(cashFlowAmount, currencyMappingOptions, currencySymbol)
    : '';

  const formattedTotalNotional = totalNotional
    ? mapNumberToLocalCurrency(totalNotional, currencyMappingOptions, currencySymbol)
    : '';

  const eventPaid = paid ? 'Paid' : 'Not Paid';
  const eventCalled = paid ? 'Called' : 'Not Called';
  const paidOrCalledText = isCouponEvent ? eventPaid : isCallEvent ? eventCalled : '';
  const translatedEventTitle = translator(eventTitle);
  const translatedPaidOrCalled = translator(paidOrCalledText);
  const hasStatusText = isIncomeEvent && isEventDateInPast;
  const eventTypeTitle = hasStatusText ? `${translatedEventTitle} ${translatedPaidOrCalled}` : eventTitle;
  const reinvestmentLabel = hasReinvestment ? 'Investment Opportunity' : undefined;

  const showIconActionButton = !isExpandable || isMobile;

  const paperSx = !isEventDateInPast ? portfolioEventsListItemSx : pastPortfolioEventsListItemSx;
  const iconColor = isEventDateInPast ? 'text.secondary' : undefined;

  const handleNoteDetailsClick = () => {
    setPositionModal({ id: productId, type: ProductTypeEnum.note, migrated });
  };

  const handleClick = () => {
    handleNoteDetailsClick();
  };

  const handleCollapseClick = () => {
    if (isCollapsed && hasReinvestment) {
      const recommendationType = reinvestment?.recommendationType?.toLowerCase();

      recordActivity({
        activity: [{ verb: `opened event with ${recommendationType} reinvestment`, model_name: 'Lifecycle' }],
        fromIFrame: false,
      });
    }

    setIsCollapsed(!isCollapsed);
  };

  const collapseButton = isExpandable ? (
    <IconButton onClick={handleCollapseClick} aria-label="expand" size="medium">
      <Iconography iconName={iconName} color={iconColor} />
    </IconButton>
  ) : null;

  const actionButton = showIconActionButton ? (
    <Button variant="outlined" sx={iconButtonSx} onClick={handleClick} className={actionButtonClassName}>
      <Iconography iconName="magnifying-glass" />
    </Button>
  ) : (
    <LocalizedButton variant="outlined" onClick={handleClick} className={actionButtonClassName}>
      {translations.common.noteDetails}
    </LocalizedButton>
  );

  const collapseContent = isExpandable ? (
    <Collapse mountOnEnter in={isCollapsed}>
      <PortfolioEventsPaymentBreakdownTable event={event} reinvestment={reinvestment} filterTypes={filterTypes} />
    </Collapse>
  ) : null;

  return (
    <Paper sx={paperSx} elevation={0}>
      <Stack sx={stackSx} direction="row" alignItems={{ md: 'center' }} justifyContent="space-between" spacing={1}>
        <Stack sx={{ flex: 0.9 }} direction={{ md: 'row', xs: 'column' }} alignItems={{ md: 'center' }} spacing={2}>
          <Stack sx={calendarSx} direction="row" alignItems="center" spacing={1}>
            {collapseButton}
            <Stack direction="row" alignItems="center" alignContent="center" spacing={1}>
              <DateTile eventType={eventType} date={eventDate} previousDate={isEventDateInPast} />
              <PortfolioEventsListItemColumn
                sx={{ flex: 1 }}
                previousDate={isEventDateInPast}
                title={eventTypeTitle}
                subtitle={eventName}
                chipLabel={reinvestmentLabel}
                ellipsis
              />
            </Stack>
          </Stack>
          <Stack sx={featureListSx} direction="row" alignItems="center" justifyContent="space-evenly" spacing={2}>
            <PortfolioEventsListItemColumn
              previousDate={isEventDateInPast}
              title={formattedCashFlowAmount}
              subtitle={columnOneTitle}
              ellipsis
            />
            <PortfolioEventsListItemColumn
              previousDate={isEventDateInPast}
              title={formattedTotalNotional}
              subtitle={columnTwoTitle}
              ellipsis
            />
            <PortfolioEventsListItemColumn
              sx={{ flex: 0.5 }}
              previousDate={isEventDateInPast}
              title={distancePercentText}
              subtitle={columnThreeTitle}
              ellipsis
              hasAlert={isAlertText}
            />
          </Stack>
        </Stack>
        {actionButton}
      </Stack>
      {collapseContent}
    </Paper>
  );
};
