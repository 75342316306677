import { BaseSyntheticEvent, ReactElement } from 'react';

import { bulkOrderTicketManagerAtom } from '@halo-atoms/orderTicket';
import { ActionButton } from '@halo-common/components';
import { BulkOrderTicketFormFields } from '@halo-common/schemas';
import { Iconography, Stack } from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useFormContext } from 'react-hook-form';

export type OrderTicketFooterProps = {
  loading?: boolean;
  onSubmit: (e?: BaseSyntheticEvent) => Promise<void>;
  onCancel: () => void;
};

export const OrderTicketFooter = ({ onSubmit, onCancel, loading = false }: OrderTicketFooterProps): ReactElement => {
  const { formState } = useFormContext<BulkOrderTicketFormFields>();

  const { pendingValidateAllocations } = useAtomValue(bulkOrderTicketManagerAtom);

  const isLoading = loading || Boolean(pendingValidateAllocations?.length);
  const disabled = !formState.isValid;

  const handleSubmit = () => void onSubmit();

  return (
    <Stack direction="row" justify="space-between">
      <Button
        type="button"
        variant="text"
        color="primary"
        onClick={onCancel}
        startIcon={<Iconography iconName="times" color="primary.main" />}
      >
        Cancel
      </Button>
      <ActionButton
        disabled={disabled}
        loading={isLoading}
        type="button"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Review
      </ActionButton>
    </Stack>
  );
};
