import { ReactElement } from 'react';

import { UploadFileModal } from '@halo-common/modals';
import { DisclaimerUserModel } from '@halo-common/models';
import { useUserDisclaimerAcceptanceUploadMutation } from '@halo-data-sources/mutations';

export type CustodyFormUploadModalProps = {
  open: boolean;
  custodyForm: DisclaimerUserModel;
  onClose: () => void;
};

export const CustodyFormUploadModal = ({ open, custodyForm, onClose }: CustodyFormUploadModalProps): ReactElement => {
  const { mutate } = useUserDisclaimerAcceptanceUploadMutation();

  const modalTitle = `Upload ${custodyForm?.name ?? 'Unknown'}`;

  const handleUploadAcceptance = (file: File) => {
    mutate({ file, id: custodyForm.id });
  };

  return (
    <UploadFileModal
      open={open}
      onSubmit={handleUploadAcceptance}
      subtitle="Select and upload the completed document."
      title={modalTitle}
      onClose={onClose}
    />
  );
};
