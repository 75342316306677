import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiDisclaimerAcceptanceUploadRequestModel,
  ApiDisclaimerAcceptanceUploadResponseModel,
  ApiDisclaimerModel,
} from '@halo-data-sources/models';

enum Endpoints {
  list = '/issuer/v1/user/disclaimer/list',
}

const COMMON_USER_DISCLAIMER_PATH = '/common/user/disclaimer';
const COMMON_USER_DISCLAIMERS_PATH = '/common/user/disclaimers';

export const getUserDisclaimer = async (): Promise<Array<ApiDisclaimerModel>> => {
  const clientConfiguration = await getClientConfiguration(COMMON_USER_DISCLAIMERS_PATH);

  return await request<Array<ApiDisclaimerModel>>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });
};

export const getUserDisclaimerList = async (): Promise<Array<ApiDisclaimerModel>> => {
  const clientConfiguration = await getClientConfiguration(Endpoints.list);

  return await request<Array<ApiDisclaimerModel>>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });
};

export const getDisclaimerRaw = async (disclaimerId: string): Promise<string> => {
  const clientConfiguration = await getClientConfiguration(COMMON_USER_DISCLAIMER_PATH);
  const path = `${clientConfiguration.basePath}/${encodeURIComponent(disclaimerId)}/download`;

  return await request<string>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });
};

export const getDisclaimerAcceptanceRaw = async (disclaimerAcceptanceId: string): Promise<string> => {
  const clientConfiguration = await getClientConfiguration(COMMON_USER_DISCLAIMER_PATH);
  const path = `${clientConfiguration.basePath}/acceptance/${encodeURIComponent(disclaimerAcceptanceId)}/download`;

  return await request<string>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });
};

export const putDisclaimerAcceptance = async ({
  id,
  document,
}: ApiDisclaimerAcceptanceUploadRequestModel): Promise<ApiDisclaimerAcceptanceUploadResponseModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_USER_DISCLAIMER_PATH);
  const path = `${clientConfiguration.basePath}/${encodeURIComponent(id)}/acceptance`;

  const formData = new FormData();
  formData.append('disclaimer_file', document);
  formData.append('_csrf_token', String(clientConfiguration.csrfToken));

  return await request<ApiDisclaimerAcceptanceUploadResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    headers: { 'Content-Type': 'multipart/form-data' },
    body: formData,
  });
};
