import { ReactElement } from 'react';

import { OrdersActions, OrdersSelectors, OrderStatusEnum } from '@halo-stores/Orders';
import { Iconography, IconographyProps } from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { AuctionTableModal } from '../../AuctionTableModal';

export type PreApprovalModalProps = {
  icon?: IconographyProps['iconName'];
};

export const PreApprovalModal = ({ icon = 'info-circle' }: PreApprovalModalProps): ReactElement => {
  const dispatch = useDispatch();

  const orderStatus = useSelector(OrdersSelectors.selectStatus);
  const selectedAuction = useSelector(OrdersSelectors.selectSelectedAuction);
  const modalOpen = orderStatus === OrderStatusEnum.showPreApprovalModal;

  const handleModalClose = () => void dispatch(OrdersActions.selectAuction({ auction: null }));
  const handleModalAction = () => dispatch(OrdersActions.approveOrderAuction(selectedAuction?.id));

  return (
    <AuctionTableModal
      onClose={handleModalClose}
      open={modalOpen}
      title="Confirm Auction Approval"
      subtitle="Are you sure you want to approve this Auction?"
      auctionId={selectedAuction?.id}
      buyer={selectedAuction?.buyer}
      organizationName={selectedAuction?.organizationName}
      footer={
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleModalAction}
          startIcon={<Iconography iconName={icon} color="primary.main" />}
        >
          Approve
        </Button>
      }
    />
  );
};
