import { ReactElement, useEffect, useRef } from 'react';

import { EducationModuleGroup, ModuleTopicModel } from '@halo-common/models';
import { useEducationModuleProgressMutation } from '@halo-data-sources/mutations';
import { EducationStepper, EducationTopic, ModuleStartStep, useCourseManager } from '@halo-modules/app';
import { Stack } from '@halodomination/halo-fe-common';
import { Box } from '@mui/material';

export type ModuleExplorerProps = {
  loading: boolean;
};

export const ModuleExplorer = (): ReactElement => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { progress } = useCourseManager();
  const { group, step, isStart } = progress;

  const { mutate, isPending } = useEducationModuleProgressMutation();

  const currentGroup = group as EducationModuleGroup;
  const currentStep = step as ModuleTopicModel;

  const rows = currentStep?.content ?? [];

  const content = isStart ? <ModuleStartStep group={currentGroup} /> : <EducationTopic rows={rows} />;

  useEffect(() => {
    const updateTopicProgress = currentStep && !currentStep.completed && !isPending;
    if (updateTopicProgress) mutate(currentStep.id);
  }, [currentStep]);

  return (
    <Box ref={containerRef}>
      <Stack direction="column" xs={12}>
        {content}
        <EducationStepper />
      </Stack>
    </Box>
  );
};
