export enum IssuerNames {
  BNP = 'BNP Paribas',
  BARCLAYS = 'Barclays',
  SOC_GEN = 'Société Générale',
  BMO = 'BMO',
}

export enum DealableQuoteStatus {
  PRICED = 'priced',
  ERROR = 'error',
}

export enum DQPricerStatus {
  REJECTED = 'rejected',
  ERROR = 'error',
}

export enum DealableQuoteDocumentTypes {
  KID = 'kid',
  PRIIP = 'priip',
  EURID = 'eurid',
  INDICATIVE_TERMSHEET = 'indicative_termsheet',
  FINAL_TERMSHEET = 'final_termsheet',
}

export enum WebSocketType {
  NOTE_RFQ_STATUS = 'note-rfq-status',
  DEALABLE_DOCUMENT_RESPONSE = 'dealable-document-response',
}

export enum DealableQuoteStep {
  SELECT_QUOTE = 'select-quote',
  TRADE_CONFIRMATION = 'trade-confirmation',
}

export enum DealableQuoteDocumentStatus {
  DOWNLOADING = 'DOWNLOADING',
  FAILED = 'FAILED',
  DOWNLOADED = 'DOWNLOADED',
}

export enum DealablePricerEngines {
  BARCLAYS = 'barcpricer',
  BMO = 'bmopricer',
  BNP = 'bnpapipricer',
  HALO = 'hgppricer',
  SOCGEN = 'sgap_v2',
}

export enum ApiDealableQuoteOrderStatusEnum {
  new = 'NEW',
  accepted = 'ACCEPTED',
  filled = 'FILLED',
  rejected = 'REJECTED',
}

export enum ApiRFQDealableStatusEnum {
  pending = 'PENDING',
  orderSent = 'ORDER_SENT',
  noQuotes = 'NO_QUOTES',
  expired = 'EXPIRED',
  received = 'RECEIVED',
}

export enum ClientTypeEnum {
  PUBLIC_OFFER = 'PUBLIC OFFER',
  PRIVATE_PLACEMENT = 'PRIVATE PLACEMENT',
}

export enum SettlementTypeEnum {
  CASH = 'CASH',
  PHYSICAL = 'PHYSICAL',
}

export enum JurisdictionEnum {
  CH = 'SWITZERLAND',
  DE = 'GERMANY',
  AE = 'UAE',
}

export enum DocumentLanguageEnum {
  ENG = 'ENGLISH',
  DER = 'GERMAN',
  FRE = 'FRENCH',
}

export enum StrikeStyleEnum {
  CLOSE = 'CLOSE',
  OPEN = 'OPEN',
}

export enum DeliveryMethodEnum {
  REFERRAL = 'REFERRAL',
  DIRECT = 'DIRECT',
}
