import { UserWebHooksModel } from '@halo-common/models';
import { ApiUserWebHooksModel } from '@halo-data-sources/models';

export const ApiUserWebHooksMapper = {
  toUserWebHooks: (webhooks: ApiUserWebHooksModel): UserWebHooksModel => {
    return Object.keys(webhooks).reduce((hooks, hookName) => {
      const webhook = webhooks[hookName];

      const { enabled, html_content } = webhook;

      return {
        ...hooks,
        [hookName]: {
          legacyFeatureEnabled: enabled,
          reactFeatureEnabled: html_content === 'enabled',
          html: html_content,
        },
      };
    }, {});
  },
};
