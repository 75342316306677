import { ReactElement } from 'react';

import { DisclaimerUserModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useUserDisclaimerDownloadMutation } from '@halo-data-sources/mutations';
import { useUserDisclaimersQuery } from '@halo-data-sources/queries';
import { AccountCard, CUSTODY_FORM_NAME, DocumentsTable, DocumentsTableSkeleton, EmptyBox } from '@halo-modules/app';

export const SignedDocumentsSection = (): ReactElement => {
  const { data: disclaimers = [], isPending: loadingFiles } = useUserDisclaimersQuery();

  const { mutate: downloadDisclaimer } = useUserDisclaimerDownloadMutation();

  const getId = (model: DisclaimerUserModel) => model.id;

  const handleMapValues = (model: DisclaimerUserModel) => ({
    id: model.id,
    name: model.displayName,
    description: model.fileUploadDescription,
    extension: model.document?.extension ?? '',
  });

  const handleDownload = (model: DisclaimerUserModel) =>
    downloadDisclaimer({
      disclaimerId: model.id,
      filename: model.displayName,
    });

  const files = disclaimers.filter(
    (disclaimer) => disclaimer.name !== CUSTODY_FORM_NAME && disclaimer.document.extension,
  );

  const content =
    !files.length && !loadingFiles ? (
      <EmptyBox
        title={translations.profile.documents.noFiles}
        subtitle={translations.profile.documents.noFilesSubtitle}
      />
    ) : loadingFiles ? (
      <DocumentsTableSkeleton />
    ) : (
      <DocumentsTable getId={getId} mapValues={handleMapValues} models={files} onDownload={handleDownload} />
    );

  return <AccountCard title={translations.profile.documents.signedDocs}>{content}</AccountCard>;
};
