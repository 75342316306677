import { ReactElement } from 'react';

import { Iconography, IconographyProps, LocalizedTypography } from '@halodomination/halo-fe-common';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

const itemStyling = { paddingTop: 0, paddingBottom: 0 };
const iconContainerStyling = { minWidth: 25 };
const iconStyling = { color: 'success.main' };

export type PasswordValidateItemProps = {
  icon?: IconographyProps['iconName'];
  description: string;
};

export const PasswordValidateItem = ({ icon, description }: PasswordValidateItemProps): ReactElement => {
  const validationIcon = icon ? (
    <Iconography sx={iconStyling} iconName={icon} prefix="fas" color="success.main" size="1.25x" />
  ) : null;

  return (
    <ListItem alignItems="flex-start" disableGutters sx={itemStyling}>
      <ListItemIcon sx={iconContainerStyling}>{validationIcon}</ListItemIcon>
      <ListItemText>
        <LocalizedTypography variant="subtitle2">{description}</LocalizedTypography>
      </ListItemText>
    </ListItem>
  );
};
