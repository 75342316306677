import { UserModel } from '@halo-common/models';
import { login } from '@halo-data-sources/clients';
import { ApiUserMapper } from '@halo-data-sources/mappers';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

export type LoginUserMutationModel = {
  username: string;
  password: string;
  next?: string | null;
};

export type LoginUserMutationResult = {
  authenticated: boolean;
  user: UserModel;
  destination: string;
};

const loginUserMutationFn = async (payload: LoginUserMutationModel) => {
  const response = await login(payload);

  return { authenticated: true, user: ApiUserMapper.toUser(response.user), destination: response.destination };
};

export const useLoginUserMutation = (): UseMutationResult<LoginUserMutationResult, Error, LoginUserMutationModel> =>
  useMutation<LoginUserMutationResult, Error, LoginUserMutationModel>({
    mutationFn: loginUserMutationFn,
    onSuccess: (response) => {
      const uri = process.env.NEXT_PUBLIC_LOCAL_REDIRECT || response.destination;
      window.location.assign(uri);
    },
  });
