import {
  NoteCallInformationFrequencyEnum,
  NoteCallInformationTypeEnum,
  NoteCouponPeriodEnum,
  NoteCouponTypeEnum,
  NoteMaxReturnTypeEnum,
  NoteParameterEnum,
  NoteProtectionTypeEnum,
  NoteTypeEnum,
} from '@halo-common/enums';
import {
  NoteCallInformationModel,
  NoteCallInformationScheduleModel,
  NoteCouponModel,
  NoteFeatureModel,
  NoteIncomeOutcomesModel,
  NoteIncomeStatsModel,
  NoteLookbackDataModel,
  NoteModel,
  NoteProbabilityBreachModel,
  NoteRollingReturnStatsModel,
  NoteRollingReturnsModel,
  NoteTypeModel,
  NoteUnderlyingPerformanceModel,
  NoteWithIncomeStatsModel,
} from '@halo-common/models';
import { roundFloatToSpecificDecimal } from '@halo-common/utils';
import { ApiQuoteMapper, ApiUnderlyingMapper, mapEnumValue } from '@halo-data-sources/mappers';
import {
  ApiGetNoteIncomeOutcomesModel,
  ApiGetNoteIncomeStatsModel,
  ApiGetNoteLookbackDataModel,
  ApiGetNoteProbabilityBreachModel,
  ApiGetNoteRollingReturnsModel,
  ApiGetNoteTypesModel,
  ApiGetNoteUnderlyingPerformanceModel,
  ApiIncomeStatsModel,
  ApiNoteModel,
  ApiNoteRollingReturnStatsModel,
} from '@halo-data-sources/models';
import { capitalize } from '@mui/material';
import { DateTime } from 'luxon';

export const getMaxReturnType = (model: ApiNoteModel): NoteModel['maxReturnType'] => {
  if (model.has_cap) {
    return NoteMaxReturnTypeEnum.Capped;
  }

  if (model.note_type === NoteTypeEnum.Growth && model.max_return === null) {
    return NoteMaxReturnTypeEnum.Uncapped;
  }

  return null;
};

export const mapApiNoteCallInformationModelToNoteCallInformationModel = (
  model: ApiNoteModel,
): NoteCallInformationModel | null => {
  const callInfo = model?.callinfo;

  if (!callInfo || !Object.keys(callInfo).length) return null;

  const frequency = callInfo.frequency;
  const displayText = callInfo.ui?.map(({ label, description }) => ({ label, description })) ?? [];

  const frequencyValue = mapEnumValue<NoteCallInformationFrequencyEnum>(frequency, NoteCallInformationFrequencyEnum);
  const callInfoType = mapEnumValue<NoteCallInformationTypeEnum>(callInfo.type, NoteCallInformationTypeEnum);

  const schedule =
    callInfo.schedule?.map<NoteCallInformationScheduleModel>((schedule) => ({
      months: schedule.months,
      level: schedule.level,
    })) ?? [];

  return {
    displayText,
    frequency: frequencyValue,
    level: callInfo.level,
    nonCallPeriod: callInfo.noncall,
    isIssuerCall: Boolean(model.is_issuer_call),
    period: callInfo.period,
    schedule,
    step: callInfo.step,
    type: callInfoType,
  };
};

export const mapApiNoteModelToNoteCouponTypeEnum = (model: ApiNoteModel): NoteCouponTypeEnum => {
  if (model.is_coupon_recall) return NoteCouponTypeEnum.Recall;
  if (model.is_coupon_memory) return NoteCouponTypeEnum.Memory;
  if (model.is_coupon_contingent) return NoteCouponTypeEnum.Contingent;

  return NoteCouponTypeEnum.Guaranteed;
};

export const mapApiNoteModelToNoteCouponModel = (model: ApiNoteModel): NoteCouponModel | null => {
  const type = model.note_type;

  if (type !== NoteTypeEnum.Income && type !== NoteTypeEnum.Digital) return null;

  const period = mapEnumValue<NoteCouponPeriodEnum>(model.coupon_period, NoteCouponPeriodEnum);

  return {
    currencyCode: model.coupon_currency?.iso_code ?? model.currency.iso_code,
    period,
    protectionPercent: model.coupon_protection_percent,
    type: mapApiNoteModelToNoteCouponTypeEnum(model),
    isRecall: Boolean(model.is_coupon_recall),
  };
};

export const calculateCommission = (price: number | null): number => (price === null ? 0 : 100 - price);

export const mapNoteToNoteFeatures = (note: ApiNoteModel, type: NoteTypeEnum): Array<NoteFeatureModel> => {
  const {
    callinfo,
    coupon_period,
    gearing_percent,
    has_cap,
    has_gearing,
    is_coupon_contingent,
    is_coupon_memory,
    is_coupon_recall,
    participation,
  } = note;

  const featureBuilder = {
    [NoteTypeEnum.Income]: () => {
      let features: Array<NoteFeatureModel> = [];

      const couponFrequency = capitalize(coupon_period ?? '');
      const isFixedCoupon = !is_coupon_contingent && !is_coupon_memory && !is_coupon_recall;

      if (isFixedCoupon) {
        features.push({ name: 'Guaranteed', description: `Fixed Coupons ${couponFrequency}` });
      } else if (is_coupon_recall) {
        features.push({ name: 'Recall', description: 'Coupon Paid When Called' });
      } else if (is_coupon_memory) {
        features.push({ name: 'Memory', description: `Memory Coupons ${couponFrequency}` });
      } else if (is_coupon_contingent) {
        features.push({ name: 'Contingent', description: `Contingent Coupons ${couponFrequency}` });
      } else {
        features.push({ name: 'Guaranteed', description: `Coupons ${couponFrequency}` });
      }

      if (callinfo?.ui?.length) {
        const autocallRegex = /(?:\d+\s-\s)(\d+)(?:x)(\s\D+)/i;

        const parseWords = (text: string) => {
          const words = text.split(' ');
          const isSchedule = /([0-9]+[/][0-9])*/.test(text);
          const schedule = isSchedule ? text.split(' ').filter((text) => text.includes('/'))[0] : undefined;
          const formattedSchedule = schedule ? schedule.split('/').filter((level) => level !== '') : undefined;

          return formattedSchedule
            ? formattedSchedule.reduce((text, level, index) => {
                const formattedText = index === formattedSchedule.length - 1 ? `${text}${level}%` : `${text}${level}%/`;
                const slashCount = (formattedText.match(/\//g) || []).length;
                return slashCount % 10 === 0 ? `${formattedText}\n` : formattedText;
              }, '')
            : words.map((word) => word.toLowerCase().split('-').map(capitalize).join('-')).join(' ');
        };

        const callInfoFeatures = callinfo.ui.map((info) => {
          const parsedDescription = parseWords(info.description.replace(autocallRegex, '$1%$2'));
          const colonIndex = info.description.indexOf(':');
          const description =
            colonIndex > 0
              ? `${info.description.substring(0, colonIndex + 1)} ${parsedDescription}`
              : parsedDescription;
          const dynamicContent = colonIndex
            ? { schedule: parsedDescription.substring(colonIndex + 1).trim() }
            : undefined;

          return {
            name: parseWords(info.label),
            description,
            dynamicContent,
          };
        });

        features = [...features, ...callInfoFeatures];
      } else {
        features.push({ name: 'Non Callable' });
      }

      return features;
    },
    [NoteTypeEnum.Growth]: () => [{ name: has_cap ? 'Capped' : 'Uncapped' }],
    [NoteTypeEnum.Absolute]: () => [{ name: has_cap ? 'Capped' : 'Uncapped' }],
    [NoteTypeEnum.Digital]: () => {
      const features: Array<NoteFeatureModel> = [{ name: 'Contingent', description: 'Contingent Coupon' }];
      if (typeof participation === 'number') features.push({ name: 'Uncapped' });
      return features;
    },
  }[type as string];

  const features: Array<NoteFeatureModel> = featureBuilder?.() ?? [];

  if (has_gearing) {
    features.push({
      name: 'Geared',
      description: 'Geared {percent}%',
      dynamicContent: { percent: parseInt(gearing_percent.toFixed(0)).toString() },
    });
  }

  return features;
};

export const mapApiNoteModelToNoteModel = (
  model: ApiNoteModel,
  options?: { requestForQuotesId?: string | null },
): NoteModel => {
  const noteType = model.note_type;
  const noteProductType = model.product as NoteTypeEnum;
  const requestForQuotesId = options?.requestForQuotesId;

  const hasCoupon = noteType === NoteTypeEnum.Income || noteProductType === NoteTypeEnum.Digital;
  const isCapped = !hasCoupon ? Boolean(model.has_cap) : null;

  const parameter = mapEnumValue<NoteParameterEnum>(model.parameter, NoteCouponPeriodEnum);
  const protectionType = mapEnumValue<NoteProtectionTypeEnum>(model.protection_type, NoteCouponPeriodEnum);

  const meta = {
    isTargeted: model?.meta?.is_targeted ?? false,
    isWatching: model?.meta?.is_watching ?? false,
    shareKey: model?.meta?.share_key ?? undefined,
    statsKey: model?.meta?.stats_key ?? undefined,
  };

  return {
    annualizedYield: model.annual_coupon,
    callInformation: mapApiNoteCallInformationModelToNoteCallInformationModel(model),
    capped: isCapped,
    commission: model.commission ?? calculateCommission(model.price),
    coupon: mapApiNoteModelToNoteCouponModel(model),
    currencyCode: model.currency.iso_code,
    currencySymbol: model.currency.symbol,
    digitalYield: model.digital_coupon,
    features: mapNoteToNoteFeatures(model, noteType),
    gearingMultiplier: model.gearing_multiplier,
    gearingPercent: model.gearing_percent,
    id: model.id,
    isIssuerCall: Boolean(model.is_issuer_call),
    observationType: model.puttype,
    maxReturn: model.max_return,
    maxReturnPercent: model.max_return,
    maxReturnType: getMaxReturnType(model),
    meta,
    name: model.name,
    notional: model.notional,
    payoff: model.payoff ?? null,
    parameter,
    participationPercent: model.participation,
    price: model.price,
    protectionType,
    protectionPercent: model.protection_percent,
    productType: noteProductType,
    requestForQuotesId: requestForQuotesId ?? null,
    softStrike: model.soft_strike ?? null,
    termInMonths: model.term.months,
    tradables: model.tradables?.map(ApiUnderlyingMapper.toUnderlyingModel),
    tradableWeights: model.weighted_dict,
    type: noteType,
  };
};

export const mapApiNoteRollingReturnStatsModelToNoteRollingReturnStatsModel = (
  model: ApiNoteRollingReturnStatsModel,
): NoteRollingReturnStatsModel => {
  return {
    average: model.average,
    best: model.best,
    pctBreached: model.pct_breached,
    worst: model.worst,
  };
};

export const mapApiNoteRollingReturnsModelToNoteRollingReturnsModel = (
  model: ApiGetNoteRollingReturnsModel,
): NoteRollingReturnsModel => {
  const dates = model.dates ?? [];
  const noteReturns = model.notereturns ?? [];
  const values = model.values ?? [];

  const parsedNoteReturns = noteReturns.map((value) => value * 100);
  const parsedValues = values.map((value) => value * 100);

  const data = dates.map((date, index) => {
    const point: NoteRollingReturnsModel['chart']['data'][0] = {
      x: DateTime.fromISO(date).toSeconds(),
      'Rolling Returns': parseFloat(parsedValues[index].toFixed(2)),
    };

    if (noteReturns.length) point['Note Returns'] = parseFloat(parsedNoteReturns[index].toFixed(2));

    return point;
  });

  const lines: NoteRollingReturnsModel['chart']['lines'] = [
    {
      label: 'Rolling Returns',
      hidden: false,
      description:
        'Underlying returns are the daily rolling returns of the underlying(s) over the period chosen in ‘Rolling Period’.',
    },
  ];

  if (noteReturns.length) lines.push({ label: 'Note Returns' });

  return {
    dates: model.dates,
    id: model.id,
    noteReturns: model.notereturns,
    protection: model.protection,
    stats: mapApiNoteRollingReturnStatsModelToNoteRollingReturnStatsModel(model.stats),
    values: model.values,
    chart: { data, lines },
  };
};

export const mapApiGetNoteUnderlyingPerformanceModelToNoteUnderlyingPerformanceModel = (
  model: ApiGetNoteUnderlyingPerformanceModel,
): NoteUnderlyingPerformanceModel => {
  const tradables = model.tradables;
  const returns = model.returns;
  const dates = returns.dates;

  const defaultMap = {} as { [key: string]: number };
  const firstPrices = model.first_prices.reduce((map, index) => ({ ...map, [index[0]]: index[1] }), defaultMap);
  const lastPrices = model.last_prices.reduce((map, index) => ({ ...map, [index[0]]: index[1] }), defaultMap);

  const chartDataPoints = dates.map((date) => ({
    x: DateTime.fromISO(date).toSeconds(),
  }));

  const chartData = tradables.reduce((list, tradable) => {
    const values = returns[tradable];
    const tradeableName = tradable.toUpperCase();

    return list.map((dataPoint, index) => {
      const underlyingReturn = values?.[index];
      const point = typeof underlyingReturn === 'number' ? roundFloatToSpecificDecimal(underlyingReturn) : null;
      return { ...dataPoint, [tradeableName]: point };
    });
  }, chartDataPoints);

  return {
    firstPrices,
    lastPrices,
    data: chartData,
    lines: tradables.map((line) => ({ label: line.toUpperCase(), hidden: false })),
  };
};

export const mapApiNoteIncomeOutcomesModelToNoteIncomeOutcomesModel = (
  model: ApiGetNoteIncomeOutcomesModel,
): NoteIncomeOutcomesModel => {
  const autocalls = model.autocalls ?? [];
  const coupons = model.coupons ?? [];
  const labels = model.labels ?? [];

  const earlyRedemption = autocalls.length
    ? autocalls.map((autocall, index) => {
        return { id: index, label: labels[index], months: Number(labels[index].split(' ')[0]), data: autocall };
      })
    : [];

  const couponSchedule = coupons.length
    ? coupons.map((coupon, index) => {
        return { id: index, label: labels[index], months: Number(labels[index].split(' ')[0]), data: coupon };
      })
    : [];

  return {
    couponSchedule,
    earlyRedemption,
  };
};

export const mapApiGetNoteLookbackDataModelToNoteLookbackDataModel = (
  model: ApiGetNoteLookbackDataModel,
): NoteLookbackDataModel => {
  const modelNoteBackTest = model.note_backtest;
  const noteBackTestReturns = modelNoteBackTest.returns;
  const noteBackTestDates = modelNoteBackTest.dates;

  const modelUnderlyingBackTest = model.underlying_backtest;
  const underlyingBackTestReturns = modelUnderlyingBackTest.returns;
  const underlyingBackTestDates = modelUnderlyingBackTest.dates;

  const noteBackTestData = noteBackTestReturns.map((value, index) => ({
    x: DateTime.fromISO(noteBackTestDates[index]).toSeconds(),
    'Note Return': roundFloatToSpecificDecimal(value),
  }));

  const underlyingBackTestData = underlyingBackTestReturns.map((value, index) => ({
    x: DateTime.fromISO(underlyingBackTestDates[index]).toSeconds(),
    'Total Return': roundFloatToSpecificDecimal(value),
  }));

  const combinedBackTestData = [...noteBackTestData, ...underlyingBackTestData];
  const backTestData = combinedBackTestData.reduce((list: NoteLookbackDataModel['backTestData'], backTestData) => {
    const updatedList = [...list];
    const duplicateIndex = updatedList.findIndex((data) => backTestData.x === data.x);

    if (duplicateIndex !== -1) updatedList[duplicateIndex] = { ...updatedList[duplicateIndex], ...backTestData };
    else updatedList.push(backTestData);

    return updatedList;
  }, []);

  return {
    dateFormat: model.date_format,
    id: model.id,
    note: model.note,
    protectionPercent: model.protection_percent,
    quote: model.quote,
    startDate: model.start_date,
    backTestData,
  };
};

export const mapApiGetNoteProbabilityBreachModelToNoteProbabilityBreachModel = (
  model: ApiGetNoteProbabilityBreachModel,
): NoteProbabilityBreachModel => {
  return {
    breachProbability: model['breach-probability'],
    noteId: model['note-id'],
  };
};

export const mapApiApiIncomeStatsModelToIncomeStatsModel = (model: ApiIncomeStatsModel): NoteIncomeStatsModel => {
  return {
    avgBreach: model.avgbreach,
    avgLife: model.avglife,
    pctBreached: model.pctbreached,
  };
};

export const mapApiGetNoteIncomeStatsModelToNoteIncomeStatsModel = (
  model: ApiGetNoteIncomeStatsModel,
): NoteWithIncomeStatsModel => {
  return {
    note: mapApiNoteModelToNoteModel(model.note),
    quote: model.quote ? ApiQuoteMapper.toQuoteModel(model.quote) : null,
    stats: mapApiApiIncomeStatsModelToIncomeStatsModel(model.stats),
  };
};

export const ApiNoteMapper = {
  toNote: (model: ApiNoteModel, requestForQuotesId?: string | null): NoteModel => {
    return mapApiNoteModelToNoteModel(model, { requestForQuotesId });
  },
};

export const apiNoteTypeMapper = (model: ApiGetNoteTypesModel): NoteTypeModel => {
  return {
    id: model.id,
    whiteLabelName: model.whitelabel_name,
    name: model.name,
    features: model.features.map((feature) => ({
      name: feature.feature_name,
      default: feature.default,
      values: feature.values,
    })),
  };
};
