import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { array as yupArray, boolean as yupBoolean, object as yupObject, string as yupString } from 'yup';

const accountOptionSchema = yupObject()
  .nonNullable(translations.messages.requiredField)
  .defined()
  .test('valid-account-option', translations.messages.requiredField, (value: AccountTypeAheadOption | null) =>
    Boolean(value?.account),
  );

const amountSchema = yupString()
  .test('divisible-by-thousands', translations.allocationsModal.calendars.divisibleByThousandsMessage, (value) => {
    const parsedValue = Number(value);
    if (Number.isNaN(parsedValue)) return true;
    return parsedValue >= 1000 && parsedValue % 1000 === 0;
  })
  .required(translations.allocationsModal.calendars.notionalRequiredMessage);

export const calendarAllocationSchema = yupObject().shape({
  allocations: yupArray()
    .defined()
    .of(
      yupObject().shape({
        accountOption: accountOptionSchema,
        amount: amountSchema,
      }),
    ),
});

export const calendarAttestationSchema = yupObject().shape({
  allocations: yupArray()
    .defined()
    .of(
      yupObject().shape({
        accountOption: accountOptionSchema,
        amount: amountSchema,
        checkbox: yupBoolean().isTrue(translations.allocationsModal.calendars.attestationRequiredMessage),
      }),
    ),
});
