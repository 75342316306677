import { ReactElement } from 'react';

import { LexicalTypography } from '@halo-common/components';
import { useLexiconTranslator } from '@halo-common/hooks';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { ITranslateParams } from '@transifex/native';

const dataRowSx = {
  backgroundColor: 'common.white',
  padding: 2,
};

export type ExecutionAuctionReviewDetailRowProps = {
  title: string;
  children: string;
  dynamicContent?: ITranslateParams;
};

export const ExecutionAuctionReviewDetailRow = ({
  title,
  children,
  dynamicContent,
}: ExecutionAuctionReviewDetailRowProps): ReactElement => {
  const translatedTitle = useLexiconTranslator(title);
  const titleText = '{title}:';
  const titleDynamicContent = { title: translatedTitle[0] };

  return (
    <Stack sx={dataRowSx} direction="row" xs={6}>
      <LocalizedTypography variant="body2" dynamicContent={titleDynamicContent}>
        {titleText}
      </LocalizedTypography>
      <LexicalTypography translationKey={children} variant="body2" dynamicContent={dynamicContent}>
        {children}
      </LexicalTypography>
    </Stack>
  );
};
