import { ReactElement } from 'react';

import { complianceApprovalFiltersAtom } from '@halo-atoms/complianceApproval';
import { ComplianceApprovalStatusEnum } from '@halo-common/enums';
import { Tabs } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useAtom } from 'jotai';

import { ComplianceApprovalDownloadCSVButton } from './ComplianceApprovalDownloadCSVButton';
import { ComplianceApprovalFiltersBar } from './ComplianceApprovalFiltersBar';

const headerSx = { mb: 2 };

export const ComplianceApprovalPageHeader = (): ReactElement => {
  const [complianceApprovalFilters, setComplianceApprovalFilters] = useAtom(complianceApprovalFiltersAtom);
  // const complianceApprovalQuery = useAtomValue(complianceApprovalQueryAtom);

  // const { data: userInfo } = useUserInfoQuery();

  // const calendarPageId = userInfo?.organization?.calendarPageId;
  // const query = { ...complianceApprovalQuery, calendarPageId };

  // const { data } = useAdminCalendarByStatusQuery(query);

  const pendingCount = 0; // data?.pending ?? 0;
  const confirmedCount = 0; // data?.confirmed ?? 0;
  const deniedCount = 0; // data?.denied ?? 0;
  const activeCount = 0; // data?.active ?? 0;
  const archivedCount = 0; // data?.archived ?? 0;

  const tabs = [
    {
      label: 'Pending',
      value: ComplianceApprovalStatusEnum.pending,
      count: pendingCount,
    },
    {
      label: 'Confirmed',
      value: ComplianceApprovalStatusEnum.confirmed,
      count: confirmedCount,
    },
    {
      label: 'Denied',
      value: ComplianceApprovalStatusEnum.denied,
      count: deniedCount,
    },
    {
      label: 'Active',
      value: ComplianceApprovalStatusEnum.available,
      count: activeCount,
    },
    {
      label: 'Archived',
      value: ComplianceApprovalStatusEnum.archived,
      count: archivedCount,
    },
  ];

  const activeTab = tabs.findIndex(({ value }) => complianceApprovalFilters.status === value);

  const handleStatusFilterChange = (index: number) => {
    setComplianceApprovalFilters((prev) => ({ ...prev, status: tabs[index].value }));
  };

  return (
    <Stack direction="column" spacing={2} sx={headerSx}>
      <Stack
        direction={{ sm: 'row', xs: 'column' }}
        spacing={2}
        justifyContent={{ sm: 'space-between', xs: 'flex-start' }}
        alignItems={{ sm: 'center', xs: 'flex-start' }}
      >
        <Tabs
          variant="scrollablePills"
          tabs={tabs}
          onChange={handleStatusFilterChange}
          defaultTab={activeTab}
          value={activeTab}
        />
        <ComplianceApprovalDownloadCSVButton />
      </Stack>
      <ComplianceApprovalFiltersBar />
    </Stack>
  );
};
