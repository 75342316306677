import { ReactElement, useEffect, useMemo } from 'react';

import { translations } from '@halo-common/translations';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Box, LinearProgress, useTheme } from '@mui/material';
import { Options, passwordStrength, Result } from 'check-password-strength';

const VALIDATION_LEVELS: Options<string> = [
  {
    id: 0,
    value: translations.profile.password.veryWeak,
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: translations.profile.password.weak,
    minDiversity: 2,
    minLength: 6,
  },
  {
    id: 2,
    value: translations.profile.password.medium,
    minDiversity: 3,
    minLength: 8,
  },
  {
    id: 3,
    value: translations.profile.password.strong,
    minDiversity: 4,
    minLength: 8,
  },
  {
    id: 4,
    value: translations.profile.password.veryStrong,
    minDiversity: 4,
    minLength: 10,
  },
];

export type PasswordStrengthBarProps = {
  password: string;
  onValidation: (value: Result<string>) => void;
};

export const PasswordStrengthBar = ({ password, onValidation }: PasswordStrengthBarProps): ReactElement => {
  const theme = useTheme();

  const passwordValidation = useMemo(() => passwordStrength(password, VALIDATION_LEVELS), [password]);

  const getStrengthBarProperties = (): [number, string] => {
    if (passwordValidation.value === 'Very Weak') return [20, theme.palette.error.main];
    else if (passwordValidation.value === 'Weak') return [40, theme.palette.error.dark];
    else if (passwordValidation.value === 'Medium') return [60, theme.palette.warning.main];
    else if (passwordValidation.value === 'Strong') return [80, theme.palette.success.main];
    else if (passwordValidation.value === 'Very Strong') return [100, theme.palette.success.dark];

    return [20, theme.palette.error.main];
  };

  const [value, color] = useMemo(getStrengthBarProperties, [passwordValidation]);

  const classes = {
    root: 'password-strength-root',
    bar: 'password-strength-bar',
  };

  const passwordStrengthStyling = {
    [`& .${classes.root}`]: {
      height: 10,
      borderRadius: 5,
    },
    [`& .${classes.bar}`]: {
      borderRadius: 5,
      backgroundColor: color,
    },
  };

  useEffect(() => {
    onValidation(passwordValidation);
  }, [passwordValidation]);

  return (
    <Box paddingTop={1}>
      <Stack direction="column" spacing={2}>
        <LinearProgress variant="determinate" value={value} sx={passwordStrengthStyling} classes={classes} />
        <LocalizedTypography textAlign="center" variant="subtitle2" paddingTop={0}>
          {passwordValidation.value}
        </LocalizedTypography>
      </Stack>
    </Box>
  );
};
