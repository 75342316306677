import { ReactElement } from 'react';

import { ExecutionHubTraceEnum } from '@halo-common/enums';
import { LocalizedMenuItem, LocalizedTextField } from '@halodomination/halo-fe-common';
import { useController, useFormContext } from 'react-hook-form';

export const V2ExecutionHubTraceDropdown = (): ReactElement => {
  const formMethods = useFormContext();

  const traceMethods = useController({ name: 'trace', control: formMethods.control });

  const { onChange: onTraceChange, value: selectedTrace } = traceMethods.field;

  return (
    <LocalizedTextField label="TRACE" fullWidth select value={selectedTrace} onChange={onTraceChange}>
      {Object.entries(ExecutionHubTraceEnum).map(([key, value]) => (
        <LocalizedMenuItem key={key} value={value}>
          {key}
        </LocalizedMenuItem>
      ))}
    </LocalizedTextField>
  );
};
