import { NoteParameterEnum, NoteTypeEnum } from '@halo-common/enums';
import {
  NoteSpecificDetails,
  NoteSpecificDetailsFieldMap,
  NoteSpecificDetailsResult,
  useNoteSpecificDetails,
} from '@halo-common/hooks';
import { CalendarModel, QuoteCalendarModel } from '@halo-common/models';
import { roundFloatToSpecificDecimal } from '@halo-common/utils';

export type CalendarSpecificDetailsResult = Omit<NoteSpecificDetailsResult, 'quote'> & {
  quote?: QuoteCalendarModel | null;
};

/**
 * Parse out solvable parameter (primary payoff) from calendar note object.
 * Uses: The Calendar ProductCard, and PDM headers
 * @param {NoteSpecificDetails} note - Massaged calendar note object to pull data from.
 * @param {NoteSpecificDetailsFieldMap} fieldMap - Parsed and mapped fields for calendar note specific data.
 * @param {QuoteCalendarModel | null | undefined} quote - Quote object associated with the note.
 * @return {CalendarSpecificDetailsResult['solvableParameter']} The solvable parameter (primary payoff) for a calendar note.
 */
const parseSolvableParameter = (
  note: NoteSpecificDetails,
  fieldMap: NoteSpecificDetailsFieldMap,
  quote?: QuoteCalendarModel | null,
): NoteSpecificDetailsResult['solvableParameter'] => {
  const { type, parameter } = note;
  const { participation, commission, annualizedYield, digitalYield, maxReturn } = fieldMap;

  const hasQuote = typeof quote?.value === 'number';
  const quotedValue = hasQuote ? roundFloatToSpecificDecimal(quote?.value) : undefined;
  const quotedText = hasQuote ? `${quotedValue?.toFixed(2)}%` : undefined;

  if (type === NoteTypeEnum.General) {
    const text = note?.payoff ?? '';
    return { label: '', text, original: quotedValue };
  } else if (!parameter) {
    return undefined;
  } else if (parameter === NoteParameterEnum.Price) {
    const quotedCommissionValue = quotedValue ? 100 - quotedValue : undefined;
    const quotedCommissionText = quotedCommissionValue ? `${quotedCommissionValue.toFixed(2)}%` : undefined;
    const value = quotedCommissionValue ?? quotedValue;
    const text = quotedCommissionText ?? commission.text;
    const original = roundFloatToSpecificDecimal(value);
    return { ...commission, original, text };
  } else if (parameter === NoteParameterEnum.AnnualCoupon) {
    const text = quotedText ?? annualizedYield.text;
    return { ...annualizedYield, original: quotedValue, text };
  } else if (parameter === NoteParameterEnum.DigitalCoupon) {
    const text = quotedText ?? digitalYield.text;
    return { ...digitalYield, original: quotedValue, text };
  } else if (parameter === NoteParameterEnum.Participation) {
    const text = quotedText ?? participation.text;
    return { ...participation, original: quotedValue, text };
  } else if (parameter === NoteParameterEnum.MaxReturn) {
    const text = quotedText ?? maxReturn.text;
    return { ...maxReturn, original: quotedValue, text };
  }
};

/**
 * Hook to parse out relevant information from a calendar note to display on the FE.
 * @param {CalendarModel | null | undefined} calendar - Calendar object to pull data from.
 * @return {CalendarSpecificDetailsResult} Calendar note details with human readable data to display on the FE.
 */
export const useCalendarSpecificDetails = (calendar?: CalendarModel | null): CalendarSpecificDetailsResult => {
  const calendarNote = calendar?.note;
  const calendarQuote = calendar?.quote;

  const noteDetails = useNoteSpecificDetails(calendarNote, { disableRounding: { couponProtection: true } });

  const digitalYield = calendarQuote?.value ?? calendarNote?.digitalYield;
  const couponPeriod = calendarNote?.coupon?.period ?? '';
  const couponType = calendarNote?.coupon?.type ?? '';

  const calendarNoteFields: NoteSpecificDetails = {
    ...calendarNote,
    couponType,
    couponPeriod,
    digitalYield,
  };

  const fieldMap = noteDetails.fieldMap;

  return {
    ...noteDetails,
    quote: calendarQuote,
    solvableParameter: parseSolvableParameter(calendarNoteFields, fieldMap, calendarQuote),
  };
};
