import { ReactElement } from 'react';

import { Skeleton, Stack, StackProps, SxProps, Theme, Typography } from '@mui/material';

const containerDirection: StackProps['direction'] = { md: 'column', xs: 'row' };
const containerAlignment = { md: 'inherit', xs: 'center' };
const containerSpacing = { md: 0, xs: 1 };

export type AdminCalendarCardDataColumnProps = {
  header?: string;
  value?: string;
  loading?: boolean;
  sx?: SxProps<Theme>;
};

export const AdminCalendarCardDataColumn = ({
  header,
  value,
  sx,
  loading = false,
}: AdminCalendarCardDataColumnProps): ReactElement => {
  return loading ? (
    <Stack direction={containerDirection} alignItems={containerAlignment} spacing={1} sx={sx}>
      <Skeleton width={64} height={20} />
      <Skeleton width={128} height={28} />
    </Stack>
  ) : (
    <Stack direction={containerDirection} spacing={containerSpacing} sx={sx} alignItems={containerAlignment}>
      <Typography variant="overline" color="textSecondary">
        {header}
      </Typography>
      <Typography variant="h6" color="textPrimary">
        {value}
      </Typography>
    </Stack>
  );
};
