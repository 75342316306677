import { ReactElement, ReactNode } from 'react';

import { LexicalTypography } from '@halo-common/components';
import { Stack } from '@halodomination/halo-fe-common';
import { Divider, Skeleton } from '@mui/material';

const containerSx = { my: 1, mx: 0 };

export type ProductDetailPanelSectionProps = {
  title: string;
  children: ReactNode;
  loading?: boolean;
};

export const ProductDetailPanelSection = ({
  title,
  children,
  loading,
}: ProductDetailPanelSectionProps): ReactElement => {
  if (loading) {
    return (
      <Stack sx={containerSx} direction="column" spacing={1}>
        <Stack direction="row" alignItems="center" spacing={2} elementStyling={[undefined, { flex: 1 }]}>
          <Skeleton width={100} height={20} />
          <Divider />
        </Stack>
        <Stack direction="column" spacing={1}>
          {children}
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack sx={containerSx} direction="column" spacing={1}>
      <Stack direction="row" alignItems="center" spacing={2} elementStyling={[undefined, { flex: 1 }]}>
        <LexicalTypography variant="body2" fontWeight="fontWeightBold">
          {title}
        </LexicalTypography>
        <Divider />
      </Stack>
      <Stack direction="column" spacing={1}>
        {children}
      </Stack>
    </Stack>
  );
};
