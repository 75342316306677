import { ReactElement } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { useSliderText } from '@halo-common/hooks';
import { PRINCIPAL_PROTECTION_SLIDER_MAX_VALUE, PRINCIPAL_PROTECTION_SLIDER_MIN_VALUE } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { Chip, SxProps } from '@mui/material';
import { useAtom } from 'jotai';

export type PrincipalProtectionChipProps = {
  tag: string;
  sx: SxProps;
};

export const PrincipalProtectionChip = ({ tag, sx }: PrincipalProtectionChipProps): ReactElement | null => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);

  const label = useSliderText({
    prefix: translations.common.principalProtection,
    minValue: PRINCIPAL_PROTECTION_SLIDER_MIN_VALUE,
    maxValue: PRINCIPAL_PROTECTION_SLIDER_MAX_VALUE,
    suffix: '%',
    leftSliderValue: sideBarFilters.protectionAmount?.min ?? 0,
    rightSliderValue: sideBarFilters.protectionAmount?.max ?? 100,
  });

  const handlePrincipalProtectionDelete = () => {
    setSideBarFilters({ tag, protectionAmount: null });
  };

  if (!sideBarFilters.protectionAmount) return null;

  return <Chip sx={sx} size="small" variant="outlined" onDelete={handlePrincipalProtectionDelete} label={label} />;
};
