import { PortfolioOrderModel } from '@halo-common/models';
import { fetchPortfolioOrder } from '@halo-data-sources/clients';
import { mapApiPortfolioOrderModelToPortfolioOrderModel } from '@halo-data-sources/mappers';
import { PortfolioQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

const getPortfolioOrderQueryFn = async (id?: number) => {
  if (!id || id === -1) return null;

  const response = await fetchPortfolioOrder(id.toString());

  return mapApiPortfolioOrderModelToPortfolioOrderModel(response);
};

export const usePortfolioOrderQuery = (
  id?: number,
  options?: Partial<UseQueryOptions<PortfolioOrderModel | null, Error>>,
): UseQueryResult<PortfolioOrderModel | null, Error> =>
  useQuery<PortfolioOrderModel | null, Error>({
    queryKey: PortfolioQueryKeyFactory.termsheet(id),
    queryFn: () => getPortfolioOrderQueryFn(id),
    ...options,
  });
