import { ReactElement } from 'react';

import { AddAccountModalFormFields } from '@halo-common/modals';
import { translations } from '@halo-common/translations';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Chip, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const titleSx = {
  textTransform: 'capitalize',
};

const chipSx = {
  color: 'primary.main',
  marginLeft: 1,
  backgroundColor: 'primary.background',
};

export type SuccessAccountModalLayoutProps = {
  showNewHouseholdMessage: boolean;
};

export const SuccessAccountModalLayout = ({
  showNewHouseholdMessage,
}: SuccessAccountModalLayoutProps): ReactElement => {
  const { getValues } = useFormContext<AddAccountModalFormFields>();

  const formData = getValues();

  const { accountHousehold, custodian } = formData;
  const householdName = accountHousehold ? accountHousehold : 'None';
  const custodianName = custodian ? custodian.name : 'None';

  const renderHouseholdChip = showNewHouseholdMessage ? <Chip label="New" color="default" sx={chipSx} /> : null;

  return (
    <Stack justifyContent="space-around" alignItems="center" direction="row">
      <Stack sx={{ flex: 1 }} direction="column">
        <LocalizedTypography sx={titleSx} variant="body1">
          {translations.common.household}
        </LocalizedTypography>
        <Typography variant="subtitle1">
          {householdName}
          {renderHouseholdChip}
        </Typography>
      </Stack>
      <Stack sx={{ flex: 2 }} direction="column">
        <LocalizedTypography sx={titleSx} variant="body1">
          {translations.common.accountName}
        </LocalizedTypography>
        <Typography variant="subtitle1">{formData.accountName}</Typography>
      </Stack>
      <Stack sx={{ flex: 1 }} direction="column">
        <LocalizedTypography sx={titleSx} variant="body1">
          {translations.common.custodian}
        </LocalizedTypography>
        <Typography variant="subtitle1">{custodianName}</Typography>
      </Stack>
      <Stack sx={{ flex: 1 }} direction="column">
        <LocalizedTypography sx={titleSx} variant="body1">
          {translations.common.accountNumber}
        </LocalizedTypography>
        <Typography variant="subtitle1">{formData.accountNumber}</Typography>
      </Stack>
    </Stack>
  );
};
