export enum DynamicFiltersUnderlyingMatchEnum {
  ALL = 'ALL',
  ANY = 'ANY',
  EXACTLY = 'EXACTLY',
}

export enum DynamicFiltersSortByEnum {
  expirationDateAsc = 'expiration_date:asc',
  expirationDateDesc = 'expiration_date:desc',
  termDesc = 'term:desc',
  termAsc = 'term:asc',
  payoffDesc = 'payoff:desc',
  protectionDesc = 'protection:desc',
  protectionAsc = 'protection:asc',
}
