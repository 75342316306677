import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { Iconography, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Alert } from '@mui/material';

export const V2PostTradeNoteDetailModalAnalyticsTabAlert = (): ReactElement => (
  <Alert
    severity="warning"
    variant="filled"
    icon={<Iconography iconName="triangle-exclamation" sx={{ opacity: 0.75 }} color="warning.dark" />}
    sx={{ alignItems: 'flex-start' }}
  >
    <LocalizedTypography variant="body1">{translations.pdm.postTrade.emptyAnalytics}</LocalizedTypography>
  </Alert>
);
