import { translations } from '@halo-common/translations';
import {
  DisclaimerText,
  DocumentLinks,
  ForgotPasswordForm,
  ForgotPasswordFormSkeleton,
  OnboardingLayout,
} from '@halo-modules/app/v2/onboarding';
import { LocalizedTypography, PageComponent, PageMeta } from '@halodomination/halo-fe-common';

const ForgotPasswordPage: PageComponent = () => (
  <OnboardingLayout variant="small" fallback={<ForgotPasswordFormSkeleton />}>
    <LocalizedTypography mb={1} variant="h4">
      {translations.onboarding.recoverPassword.recoverPassword}
    </LocalizedTypography>
    <LocalizedTypography mb={6} color="text.secondary" variant="subtitle2">
      {translations.onboarding.recoverPassword.enterEmailAddress}
    </LocalizedTypography>
    <ForgotPasswordForm />
    <DisclaimerText />
    <DocumentLinks />
  </OnboardingLayout>
);

export const ForgotPasswordPageMeta: PageMeta = {
  pageName: 'ForgotPassword',
  route: '/app/v2/onboarding/forgot-password',
  iconName: 'question-circle',
};

ForgotPasswordPage.pageName = ForgotPasswordPageMeta.pageName;
ForgotPasswordPage.route = ForgotPasswordPageMeta.route;
ForgotPasswordPage.iconName = ForgotPasswordPageMeta.iconName;

export default ForgotPasswordPage;
