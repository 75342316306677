import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiUserFileModel,
  ApiUserFileDownloadModel,
  ApiUserFileUploadRequestModel,
  ApiUserFileUploadResponseModel,
} from '@halo-data-sources/models';

const COMMON_USER_FILES_PATH = '/common/user/files';

export const getUserFileList = async (): Promise<Array<ApiUserFileModel>> => {
  const clientConfiguration = await getClientConfiguration(COMMON_USER_FILES_PATH);
  const path = `${clientConfiguration.basePath}/list`;

  return await request<Array<ApiUserFileModel>>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });
};

export const postUserFile = async ({ file, notes }: ApiUserFileUploadRequestModel): Promise<ApiUserFileModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_USER_FILES_PATH);
  const path = `${clientConfiguration.basePath}/add`;

  const formData = new FormData();
  formData.append('user_file', file);
  formData.append('category', 'General');
  formData.append('notes', notes ?? '');
  formData.append('_csrf_token', String(clientConfiguration.csrfToken));

  const resp = await request<ApiUserFileUploadResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    body: formData,
  });

  if (resp.object !== undefined) return resp.object;
  else throw Error(resp.message);
};

export const getUserFileRaw = async (fileId: string): Promise<string> => {
  const clientConfiguration = await getClientConfiguration(COMMON_USER_FILES_PATH);
  const path = `${clientConfiguration.basePath}/${encodeURIComponent(fileId)}/download`;

  const resp = await request<ApiUserFileDownloadModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return resp.download_url;
};

export const deleteUserFile = async (fileId: string): Promise<void> => {
  const clientConfiguration = await getClientConfiguration(COMMON_USER_FILES_PATH);
  const path = `${clientConfiguration.basePath}/${encodeURIComponent(fileId)}/delete`;

  await request<void>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });
};
