import { HaloAppState } from '@halo-stores/types';
import { createSelector } from '@reduxjs/toolkit';

// TODO: Update return value type to be an enum
export const selectStatus = createSelector(
  ({ Fix }: HaloAppState) => Fix.status,
  (status) => status,
);

export const selectError = createSelector(
  ({ Fix }: HaloAppState) => Fix.error,
  (error) => error,
);

export const selectValidationResult = createSelector(
  ({ Fix }: HaloAppState) => Fix.validationResult,
  (validationResult) => validationResult,
);

export const selectFixOrder = createSelector(
  ({ Fix }: HaloAppState) => Fix.selectedOrder,
  (selectedOrder) => selectedOrder,
);
