import { isValidElement, ReactElement } from 'react';

import {
  GridRenderCellParams,
  Iconography,
  gridDetailPanelExpandedRowsContentCacheSelector,
  useGridApiContext,
  useGridSelector,
} from '@halodomination/halo-fe-common';
import { IconButton } from '@mui/material';

export const ProductApprovalPanelToggle = (props: Pick<GridRenderCellParams, 'id' | 'value'>): ReactElement => {
  const { id, value: isExpanded } = props;

  const apiRef = useGridApiContext();
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);

  const hasDetail = isValidElement(contentCache[id]);

  return (
    <IconButton size="small" tabIndex={-1} disabled={!hasDetail}>
      <Iconography
        iconName="angle-down"
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        }}
      />
    </IconButton>
  );
};
