import { ReactNode } from 'react';

import { CalendarModel, WholesalerModel } from '@halo-common/models';
import { useWholesalersQuery } from '@halo-data-sources/queries';
import { ExecutionHubStatusIndicator } from '@halo-modules/admin';
import { Skeleton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

export const STATIC_WHOLESALER_CODES = ['issuer', 'ftlp', 'halo'];

export type WholeSalerMap = {
  [key: string]: WholesalerModel | null;
};

export const buildExecutionHubColumns = (
  loading: boolean,
  wholesalers: CalendarModel['wholesalers'] = [],
): Array<GridColDef> => {
  const { data: fixEnabledWholesalers } = useWholesalersQuery();

  const commonCellProps = {
    editable: false,
    resizable: false,
    disableReorder: true,
  };

  const wholesalerMap = wholesalers.reduce((lookup: WholeSalerMap, wholesaler) => {
    const { code } = wholesaler;

    return {
      ...lookup,
      [code]: wholesaler,
    };
  }, {});

  const renderCellContent = (content: ReactNode, width?: string | number) => {
    const parsedWidth = width ?? '30%';
    return loading ? <Skeleton width={parsedWidth} height={24} /> : content;
  };

  const buildWholesalerColumns = (code: string, isLast = false): Array<GridColDef> => {
    const parsedCode = code.toLowerCase();
    const labelCode = code.toUpperCase();

    const bondsColumnClassName = !isLast ? 'eh-bonds-cell' : undefined;

    return [
      {
        ...commonCellProps,
        field: `${code}Reoffer`,
        headerName: `${labelCode} REOFFER`,
        align: 'right',
        width: 175,
        headerClassName: 'eh-reoffer-cell',
        cellClassName: 'eh-reoffer-cell',
        valueGetter: (_, row) => {
          const { wholesaler } = row;
          const wholesalerCode = wholesaler?.code;
          const incorrectWholesaler = Boolean(
            parsedCode !== wholesalerCode?.toLowerCase() || !wholesalerMap[wholesalerCode],
          );

          if (loading || incorrectWholesaler) return null;
          return wholesalerMap[code]?.salePrice?.toFixed(3);
        },
        renderCell: ({ value }) => {
          const formattedValue = value ? `${value}%` : null;

          return renderCellContent(formattedValue);
        },
      },
      {
        ...commonCellProps,
        field: `${code}Bonds`,
        headerName: `${labelCode} BONDS`,
        align: 'right',
        width: 175,
        headerClassName: bondsColumnClassName,
        cellClassName: bondsColumnClassName,
        valueGetter: (_, row) => {
          const { amount, wholesaler } = row;
          const wholesalerCode = wholesaler?.code;
          const incorrectWholesaler = Boolean(
            parsedCode !== wholesalerCode?.toLowerCase() || !wholesalerMap[wholesalerCode],
          );

          if (loading || incorrectWholesaler) return null;
          return new Intl.NumberFormat().format(amount / 1000);
        },
        renderCell: ({ value }) => {
          return renderCellContent(value);
        },
      },
    ];
  };

  const wholesalerColumns =
    fixEnabledWholesalers?.flatMap(({ code }, index) => {
      const isLast = index === fixEnabledWholesalers.length - 1;
      return buildWholesalerColumns(code, isLast);
    }) ?? [];

  return [
    {
      ...commonCellProps,
      field: 'status',
      headerName: '',
      width: 24,
      renderCell: ({ row }) => {
        const content = <ExecutionHubStatusIndicator allocation={row} />;
        return renderCellContent(content, 24);
      },
    },
    {
      ...commonCellProps,
      field: 'accountName',
      headerName: 'ACCOUNT',
      width: 200,
      valueGetter: (_, row) => {
        return row.account?.accountId;
      },
      renderCell: ({ value }) => {
        return renderCellContent(value);
      },
    },
    {
      ...commonCellProps,
      field: 'ibd',
      headerName: 'IBD',
      width: 200,
      cellClassName: 'eh-ibd-cell',
      valueGetter: (_, row) => {
        return row.user?.organization?.name;
      },
      renderCell: ({ value }) => {
        return renderCellContent(value);
      },
    },
    ...wholesalerColumns,
  ];
};
