import { ReactElement } from 'react';

import { DynamicFiltersCheckboxModel, dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { LexicalTypography } from '@halo-common/components';
import { Chip, SxProps } from '@mui/material';
import { useAtom } from 'jotai';
import { startCase } from 'lodash';

export type CheckboxChipsProps = {
  tag: string;
  sx: SxProps;
};

export const CheckboxChips = ({ tag, sx }: CheckboxChipsProps): ReactElement | null => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);

  const handleStaticCalendarChipsDelete = (label: string) => {
    setSideBarFilters({ tag, checkbox: label });
  };

  const validChips = Object.keys(sideBarFilters.checkboxes).filter(
    (label) => sideBarFilters.checkboxes[label as keyof DynamicFiltersCheckboxModel],
  );

  if (!validChips.length) return null;

  const chips = validChips.map((label) => {
    const formattedLabel = startCase(label);
    const formattedLabelNode = <LexicalTypography variant="caption">{formattedLabel}</LexicalTypography>;

    return (
      <Chip
        key={label}
        sx={sx}
        size="small"
        variant="outlined"
        label={formattedLabelNode}
        onDelete={() => handleStaticCalendarChipsDelete(label)}
      />
    );
  });

  return <>{chips}</>;
};
