import { modalAtom } from '@halo-atoms/common';
import { AllocationModel } from '@halo-common/models';
import { atom } from 'jotai';

type OrderBookModalPayload = {
  open: boolean;
  allocation?: AllocationModel | null;
  calendarId?: number | null;
};

const ORDER_BOOK_APPROVAL_MODAL = 'order_book_approval_modal';
const _orderBookApprovalModalAllocationAtom = atom<AllocationModel | null>(null);
export const orderBookApprovalModalAtom = atom(
  (get) => {
    const modalMap = get(modalAtom);
    const allocation = get(_orderBookApprovalModalAllocationAtom);
    return { open: modalMap[ORDER_BOOK_APPROVAL_MODAL], allocation };
  },
  (_, set, payload: OrderBookModalPayload) => {
    set(modalAtom, { [ORDER_BOOK_APPROVAL_MODAL]: payload.open });
    set(_orderBookApprovalModalAllocationAtom, payload.allocation ?? null);
  },
);

const ORDER_BOOK_CANCEL_MODAL = 'order_book_cancel_modal';
export const orderBookCancelModalAtom = atom(
  (get) => {
    const modalMap = get(modalAtom);
    const allocation = get(_orderBookApprovalModalAllocationAtom);
    return { open: modalMap[ORDER_BOOK_CANCEL_MODAL], allocation };
  },
  (_, set, payload: OrderBookModalPayload) => {
    set(modalAtom, { [ORDER_BOOK_CANCEL_MODAL]: payload.open });
    set(_orderBookApprovalModalAllocationAtom, payload.allocation ?? null);
  },
);

const ORDER_BOOK_EXECUTION_DETAIL_MODAL_ATOM = 'order_book_execution_detail_modal_atom';
const _orderBookExecutionDetailModalCalendarIdAtom = atom<number | null>(null);
export const orderBookExecutionDetailModalAtom = atom(
  (get) => {
    const modalMap = get(modalAtom);
    const calendarId = get(_orderBookExecutionDetailModalCalendarIdAtom);
    return { open: modalMap[ORDER_BOOK_EXECUTION_DETAIL_MODAL_ATOM], calendarId };
  },
  (_, set, payload: Omit<OrderBookModalPayload, 'allocation'>) => {
    set(modalAtom, { [ORDER_BOOK_EXECUTION_DETAIL_MODAL_ATOM]: payload.open });
    set(_orderBookExecutionDetailModalCalendarIdAtom, payload.calendarId ?? null);
  },
);
