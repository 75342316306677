import { ReactElement } from 'react';

import { Box, BoxProps } from '@mui/material';

const containerSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '24px',
  '& button': {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    backgroundColor: 'gray',
    transition: 'background-color 0.3s',
    outline: 'none',
    flex: '0 0 auto',
    margin: 0,
    padding: 0,
    position: 'relative',
    '&:hover': {
      backgroundColor: 'black',
    },
    '&:focus-visible': {
      backgroundColor: 'gray',
    },
    '&[data-active="true"]': {
      backgroundColor: 'black',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height: '100%',
      minWidth: '32px',
      minHeight: '32px',
    },
  },
};

export type PaginationDotsProps = Omit<BoxProps, 'onClick'> & {
  total: number;
  active: number;
  onClick: (index: number) => void;
};

export const PaginationDots = ({ total, active, onClick, ...props }: PaginationDotsProps): ReactElement | null => {
  if (total <= 1) return null;

  const content = Array.from({ length: total }).map((_, index) => {
    const isActive = index === active;
    const label = `Go to slide ${index + 1}`;

    return (
      <button
        key={index}
        className="wm-carousel-page"
        type="button"
        onClick={() => onClick(index)}
        data-active={isActive}
        aria-label={label}
      />
    );
  });

  return (
    <Box sx={containerSx} {...props}>
      {content}
    </Box>
  );
};
