import { hash } from '@halo-common/utils';
import type { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type TermsheetRepoQueryKeys = {
  all: QueryKeyFactoryFn;
  config: QueryKeyFactoryFn;
  docs: QueryKeyFactoryFn;
  docsFilters: QueryKeyFactoryFn;
};

export const TermsheetRepoQueryKeyFactory: TermsheetRepoQueryKeys = {
  all: () => ['termsheet-repo'],
  config: () => [...TermsheetRepoQueryKeyFactory.all(), 'config'],
  docs: (options) => [...TermsheetRepoQueryKeyFactory.all(), 'docs', hash(options)],
  docsFilters: (options) => [...TermsheetRepoQueryKeyFactory.all(), 'docsFilters', hash(options)],
};
