import { AnnuityOrderModel } from '@halo-common/models';
import { fetchPortfolioAnnuityOrder } from '@halo-data-sources/clients';
import { ApiAnnuityOrderMapper } from '@halo-data-sources/mappers';
import { AnnuityQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const fetchAnnuityQueryFn = async (id?: string) => {
  if (!id) return null;

  const response = await fetchPortfolioAnnuityOrder(id);

  return ApiAnnuityOrderMapper.toAnnuityOrderModel(response);
};

export const useAnnuityQuery = (id?: string): UseQueryResult<AnnuityOrderModel | null, Error> =>
  useQuery<AnnuityOrderModel | null, Error>({
    queryKey: AnnuityQueryKeyFactory.fetch(id),
    queryFn: () => fetchAnnuityQueryFn(id),
  });
