import { ReactElement } from 'react';

import { Stack, useCombinedStyling } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';
import { v4 as uuid } from 'uuid';

const skeletonSx = { transform: 'none' };
const titleSx = { mb: 1 };

export type OnboardingFormLoadingProps = { fields?: number };

export const OnboardingFormLoading = ({ fields = 4 }: OnboardingFormLoadingProps): ReactElement => {
  const titleSkeletonStyling = useCombinedStyling(skeletonSx, titleSx);

  const titleSkeleton = <Skeleton sx={titleSkeletonStyling} width="160px" height="40px" />;

  const skeletons = Array(fields)
    .fill(0)
    .map(() => <Skeleton key={uuid()} sx={skeletonSx} width="100%" height="56px" />);

  return (
    <Stack direction="column" justifyElements="center" spacing={2}>
      {titleSkeleton}
      {skeletons}
    </Stack>
  );
};
