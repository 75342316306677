import {
  complianceApprovalModalAtom,
  complianceApprovalQueryAtom,
  complianceApprovalStatusChangeReason,
} from '@halo-atoms/complianceApproval';
import { AllocationStatusEnum } from '@halo-common/enums';
import { FixWebsocketEvent } from '@halo-common/hooks';
import { useWebSocketContext } from '@halo-common/providers';
import { putFixOrderCancellation } from '@halo-data-sources/clients';
import { useCalendarRejectionMutation } from '@halo-data-sources/mutations';
import { AllocationClientQueryKeyFactory, useUserInfoQuery } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';

export type CalendarFixRejectionMutationPayload = { id: number; reason: string };

const calendarFixRejectionMutationFn = async (payload: CalendarFixRejectionMutationPayload) => {
  const { id } = payload;
  await putFixOrderCancellation(id);
};

export const useCalendarFixRejectionMutation = (
  options?: Partial<UseMutationOptions<void, Error, CalendarFixRejectionMutationPayload>>,
): UseMutationResult<void, Error, CalendarFixRejectionMutationPayload> => {
  const queryClient = useQueryClient();

  const { enqueueErrorEvent } = useSnackbar();
  const { events } = useWebSocketContext();

  const { data: userInfo } = useUserInfoQuery();

  const setStatusChangeReason = useSetAtom(complianceApprovalStatusChangeReason);
  const setComplianceApprovalModal = useSetAtom(complianceApprovalModalAtom);
  const filters = useAtomValue(complianceApprovalQueryAtom);

  const calendarPageId = userInfo?.organization?.calendarPageId;
  const query = { ...filters, calendarPageId };

  const { mutate } = useCalendarRejectionMutation({
    onSuccess: (_, payload) => {
      void queryClient.refetchQueries({ queryKey: AllocationClientQueryKeyFactory.adminInfinite(query) });
      events.fixAllocation.remove(payload.id);
      setStatusChangeReason(null);
      setComplianceApprovalModal({ open: false });
    },
  });

  return useMutation({
    mutationFn: calendarFixRejectionMutationFn,
    ...options,
    onMutate: (payload) => {
      options?.onMutate?.(payload);

      const websocketCallback = (event: FixWebsocketEvent) => {
        const eventStatus = event.allocation?.status.toLowerCase() as AllocationStatusEnum;
        const canceledEvent = eventStatus === AllocationStatusEnum.canceled;
        if (canceledEvent) mutate(payload);
      };

      events.fixAllocation.add(payload.id, websocketCallback);
    },
    onError: (...args) => {
      options?.onError?.(...args);
      setStatusChangeReason(null);
      enqueueErrorEvent({ message: "Couldn't Reject!" });
    },
  });
};
