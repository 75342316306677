import { useEffect, useState } from 'react';

import { HaloAppState } from '@halo-stores/types';
import { useSelector, Selector } from 'react-redux';

export type UsePageLoadedOptions<StatusType> = {
  successStatus: StatusType;
  failureStatus: StatusType;
};

export const usePageLoaded = <StatusType extends unknown>(
  statusSelector: Selector<HaloAppState, StatusType>,
  { successStatus, failureStatus }: UsePageLoadedOptions<StatusType>,
): boolean => {
  const [pageLoaded, setPageLoaded] = useState(false);

  const status = useSelector(statusSelector);

  useEffect(() => {
    const requestFinished = status === successStatus || status === failureStatus;

    if (!pageLoaded && requestFinished) {
      setPageLoaded(true);
    }
  }, [status]);

  return pageLoaded;
};
