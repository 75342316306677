import { ReactElement } from 'react';

import { CalendarPoint } from '@halo-common/components';
import { CalendarModel } from '@halo-common/models';
import { LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';

const containerSx = { width: '100%' };

export type CalendarPointListProps = {
  title?: string;
  points?: CalendarModel['points'];
  loading?: boolean;
};

export const CalendarPointList = ({ title, points = [], loading = true }: CalendarPointListProps): ReactElement => {
  if (loading) {
    const loadingIndicator = (
      <Stack direction="row" sx={containerSx} spacing={2}>
        <Skeleton variant="rounded" width="100%" height={48} />
        <Skeleton variant="rounded" width="100%" height={48} />
      </Stack>
    );

    return (
      <Stack direction="column" spacing={1}>
        {loadingIndicator}
        {loadingIndicator}
        {loadingIndicator}
      </Stack>
    );
  }

  const header = title ? <LocalizedTypography variant="h6">{title}</LocalizedTypography> : null;

  return (
    <Stack direction="column" spacing={3}>
      {header}
      <Stack direction="column" spacing={1}>
        {points.map(({ id, ...props }) => (
          <CalendarPoint key={id} {...props} />
        ))}
      </Stack>
    </Stack>
  );
};
