import { DisclaimerOnboardingModel } from '@halo-common/models';
import { getOnboardingDisclaimers } from '@halo-data-sources/clients';
import { ApiDisclaimerMapper } from '@halo-data-sources/mappers';
import { OnboardingQueryKeyFactory } from '@halo-data-sources/queries';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export type OnboardingDisclaimerQueryResult = Array<DisclaimerOnboardingModel>;

const useOnboardingDisclaimersQueryFn = async () => {
  const response = await getOnboardingDisclaimers();

  return response.map(ApiDisclaimerMapper.toOnboardingDisclaimer);
};

export const useOnboardingDisclaimers = (): UseQueryResult<OnboardingDisclaimerQueryResult, Error> =>
  useQuery<OnboardingDisclaimerQueryResult, Error>({
    queryFn: useOnboardingDisclaimersQueryFn,
    queryKey: OnboardingQueryKeyFactory.disclaimers(),
  });
