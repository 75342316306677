import { ReactElement, useState } from 'react';

import { IconBounceRotate } from '@halo-common/components';
import { useElementHover } from '@halo-common/hooks';
import { ModuleModel, ModuleStepModel } from '@halo-common/models';
import { EducationModule, EducationModuleGroupHeader } from '@halo-modules/app';
import { Iconography, Stack, useCombinedStyling } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Collapse, Paper, SxProps } from '@mui/material';

const moduleGroupSx = { pt: 3 };
const flexContainerSx = { minWidth: 0 };
const collapsibleContentSx = {
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
  borderTopColor: 'background.action',
  m: 0,
  pb: 4,
  pt: 0,
  px: 15,
  width: '100%',
};

export type EducationModuleGroupProps = {
  title: string | null;
  subtitle?: string | null;
  modules: Array<ModuleModel>;
  progress: number;
  onStart: (reason: 'start' | 'continue' | 'review') => void;
  onSelection: (module: ModuleModel, step: ModuleStepModel) => void;
  variant?: 'default' | 'compact';
  actionButtonClassName?: string;
  stepClassName?: string;
};

export const EducationModuleGroup = ({
  title,
  subtitle,
  modules = [],
  progress,
  onStart,
  onSelection,
  variant = 'default',
  actionButtonClassName,
  stepClassName,
}: EducationModuleGroupProps): ReactElement => {
  const { hovered, onEnter, onLeave } = useElementHover();

  const [showModuleSections, setShowModuleSections] = useState(false);

  const hoverTransitionStyling = {
    '&:hover': {
      outlineWidth: '2px',
      outlineColor: (theme: HaloTheme) => theme.palette.primary.light,
    },
  };

  let moduleContainerStyling: SxProps<HaloTheme> = {
    boxSizing: 'border-box',
    padding: 0,
    width: '100%',
    borderRadius: 4,
    boxShadow: 0,
    outlineWidth: '1px',
    outlineStyle: 'solid',
    outlineColor: 'transparent',
    transition: 'outline-width, outline-color 500ms',
  };

  if (variant === 'compact') {
    moduleContainerStyling = {
      ...moduleContainerStyling,
      borderRadius: 1,
    };
  }

  if (variant === 'default') {
    moduleContainerStyling = {
      ...moduleContainerStyling,
      boxShadow: showModuleSections ? 6 : 0,
      cursor: 'pointer',
    };
  }

  const combinedContainerStyling = useCombinedStyling(
    moduleContainerStyling,
    variant === 'default' && !showModuleSections ? hoverTransitionStyling : null,
  );

  const handleToggle = () => void setShowModuleSections(!showModuleSections);

  const header = (
    <EducationModuleGroupHeader
      title={title}
      subtitle={subtitle}
      progress={progress}
      onStart={onStart}
      variant={variant}
      actionButtonClassName={actionButtonClassName}
    >
      {variant !== 'compact' && (
        <IconBounceRotate active={hovered} rotated={showModuleSections}>
          <Iconography color="text.secondary" prefix="fas" iconName="chevron-down" size="1.25x" />
        </IconBounceRotate>
      )}
    </EducationModuleGroupHeader>
  );

  const currentModule = modules.find((module) => module.steps.some((step) => !step.completed)) || modules.at(-1);

  let content = null;

  if (variant === 'compact' && currentModule) {
    const handleSelection = (step: ModuleStepModel) => void onSelection(currentModule, step);
    content = (
      <EducationModule
        title={currentModule.title}
        steps={currentModule.steps}
        onSelection={handleSelection}
        variant="compact"
        stepClassName={stepClassName}
      />
    );
  }

  if (variant === 'default') {
    content = (
      <Collapse in={showModuleSections}>
        <Stack sx={collapsibleContentSx} elementStyling={moduleGroupSx} direction="column" spacing={0} xs={12}>
          {modules.map((module) => {
            const { id, title, steps } = module;
            const handleSelection = (step: ModuleStepModel) => void onSelection(module, step);
            return (
              <EducationModule
                key={id}
                title={title}
                steps={steps}
                onSelection={handleSelection}
                stepClassName={stepClassName}
              />
            );
          })}
        </Stack>
      </Collapse>
    );
  }

  const paperProps =
    variant === 'compact'
      ? {}
      : {
          onClick: handleToggle,
          onMouseEnter: onEnter,
          onMouseLeave: onLeave,
        };

  return (
    <Paper variant="outlined" sx={combinedContainerStyling} {...paperProps}>
      <Stack direction="column" elementStyling={flexContainerSx}>
        {header}
        {content}
      </Stack>
    </Paper>
  );
};
