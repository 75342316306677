import { ReactElement, ReactNode } from 'react';

import { Iconography, IconographyProps, LocalizedTypography, Tooltip } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { ITranslateParams } from '@transifex/native';
import { useT } from '@transifex/react';

const labelSx = {
  ml: 1,
  textTransform: 'capitalize',
};

const containerSx = {
  width: 'fit-content',
};

export type ProductCardDetailRowProps = {
  tooltip?: string;
  icon?: IconographyProps['iconName'];
  children: ReactNode;
  dynamicContent?: ITranslateParams;
};

export const ProductCardDetailRow = ({
  tooltip,
  icon = 'info-circle',
  children,
  dynamicContent,
}: ProductCardDetailRowProps): ReactElement => {
  const translateTooltip = useT(tooltip);
  const translatedTooltip = translateTooltip(tooltip);

  const content = (
    <Stack direction="row" alignItems="center" sx={containerSx}>
      <Iconography iconName={icon} prefix="fal" color="text.secondary" />
      <LocalizedTypography sx={labelSx} variant="subtitle2" dynamicContent={dynamicContent}>
        {children}
      </LocalizedTypography>
    </Stack>
  );

  if (!tooltip || !translatedTooltip?.trim()) return content;

  return (
    <Tooltip placement="right" title={tooltip}>
      {content}
    </Tooltip>
  );
};
