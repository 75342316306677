import { translations } from '@halo-common/translations';
import { AnyObjectSchema, object as yupObject, string as yupString } from 'yup';

export const contactModalFormSchema = (requireCompanyName: boolean): AnyObjectSchema => {
  return yupObject().shape({
    emailAddress: yupString()
      .email(translations.components.contactFormEmailRequired)
      .required(translations.components.contactFormEmailRequired),
    firstName: yupString().required(translations.components.contactFormFirstNameRequired),
    lastName: yupString().required(translations.components.contactFormLastNameRequired),
    message: yupString().required(translations.components.contactFormMessageRequired),
    companyName: requireCompanyName
      ? yupString().required(translations.components.contactFormCompanyRequired)
      : yupString(),
  });
};

export type ContactHaloSchema = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  message: string;
  companyName: string;
};
