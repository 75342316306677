import { DocumentUserFile } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { postUserFile } from '@halo-data-sources/clients';
import { UserQueryKeyFactory } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

export type UploadUserFileMutationPayload = {
  file: File;
  category?: string;
  notes: string | null;
};

const uploadUserFileMutationFn = async (payload: UploadUserFileMutationPayload) => {
  const response = await postUserFile(payload);

  return {
    id: response.id.toString(),
    document: response.document,
    notes: response.notes,
  };
};

export const useUploadUserFileMutation = (): UseMutationResult<
  DocumentUserFile,
  Error,
  UploadUserFileMutationPayload
> => {
  const queryClient = useQueryClient();
  const { enqueueSuccessEvent, enqueueErrorEvent } = useSnackbar();

  return useMutation<DocumentUserFile, Error, UploadUserFileMutationPayload>({
    mutationFn: uploadUserFileMutationFn,
    onSuccess: (data) => {
      if (!data) return;

      const key = UserQueryKeyFactory.files();
      queryClient.setQueryData<Array<DocumentUserFile>>(key, (prev) => {
        if (!prev) return undefined;

        const files = [...prev, data];

        return files;
      });

      enqueueSuccessEvent({ message: translations.profile.documents.uploadSuccess });
    },
    onError: () => {
      enqueueErrorEvent({ message: translations.profile.documents.uploadError });
    },
  });
};
