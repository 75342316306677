import {
  PortfolioLifecycleModel,
  PortfolioLifecycleRecommendationEnhancedModel,
  PortfolioLifecycleRecommendationModel,
} from '@halo-common/models';
import { fetchPortfolioOrder, getNote, getPortfolioLifecycleRecommendations } from '@halo-data-sources/clients';
import { ApiNoteMapper, ApiPortfolioLifecycleMapper, PortfolioLifecycleMapper } from '@halo-data-sources/mappers';
import { PortfolioLifecycleQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

export type LifecycleRecommendationQueryResponse = {
  reinvestments: Array<PortfolioLifecycleRecommendationEnhancedModel>;
  filterTypes: Array<string>;
};

const lifecycleRecommendationsQueryFn = async (payload: Array<PortfolioLifecycleModel>) => {
  const requestModel = payload.map(PortfolioLifecycleMapper.toApiLifecycleRecommendationRequestModel);

  const result = await getPortfolioLifecycleRecommendations(requestModel);

  const data = result.recommendations.map(ApiPortfolioLifecycleMapper.toPortfolioLifecycleRecommendationModel);

  const enhancementRequests = data
    ?.reduce((list: Array<PortfolioLifecycleRecommendationModel>, reinvestment) => {
      if (list.every((item) => item.productId !== reinvestment.productId)) return [...list, reinvestment];
      else return list;
    }, [])
    .map(async (reinvestment) => {
      const orderResponse = await fetchPortfolioOrder(reinvestment.productId.toString());
      const noteResponse = await getNote(orderResponse.quotednote.id);
      return { ...reinvestment, note: ApiNoteMapper.toNote(noteResponse.note) };
    });

  const updatedReinvestments: Array<PortfolioLifecycleRecommendationEnhancedModel> =
    await Promise.all(enhancementRequests);

  return { reinvestments: updatedReinvestments, filterTypes: result.filter_types };
};

export const useLifecycleRecommendationsQuery = (
  payload: Array<PortfolioLifecycleModel>,
): UseQueryResult<LifecycleRecommendationQueryResponse, Error> => {
  const ids = payload.map((data) => data.productId);

  return useQuery<LifecycleRecommendationQueryResponse, Error>({
    queryKey: PortfolioLifecycleQueryKeyFactory.recommendations(ids),
    queryFn: () => lifecycleRecommendationsQueryFn(payload),
    placeholderData: keepPreviousData,
  });
};
