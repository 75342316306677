import { ReactElement } from 'react';

import { AuctionStepper } from '@halo-modules/app';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, Stack, Typography } from '@mui/material';

const boxSx = {
  width: '100%',
  height: 300,
  overflow: 'hidden',
  background: (theme: HaloTheme) =>
    `linear-gradient(270deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%);`,
};

const contentSx = {
  py: 5,
  position: 'absolute',
  width: '100%',
};

export const AuctionHeader = (): ReactElement => (
  <Box sx={boxSx}>
    <Stack direction="column" alignItems="center" spacing={2} sx={contentSx}>
      <Typography variant="h1" color="primary.contrastText">
        Start an auction in just 3 steps
      </Typography>
      <Typography variant="body1" color="primary.contrastText" fontWeight="bold">
        Choose how much you&apos;d like to invest, review your timeline, and pick the issuers you’d like to compete!
      </Typography>
      <AuctionStepper />
    </Stack>
  </Box>
);
