import { ReactElement, useEffect, useRef, useState } from 'react';

import { OrdersActions, OrdersSelectors, OrderStatusEnum } from '@halo-stores/Orders';
import { Iconography, IconographyProps, Stack } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { InferType, object as yupObject, string as yupString } from 'yup';

import { AuctionTableModal } from '../../AuctionTableModal';

const alertSx = {
  alignItems: 'center',
  paddingBottom: 1,
  paddingTop: 1,
};

const formSx = {
  display: 'flex',
  justifyContent: 'center',
};

const fieldsSx = {
  maxWidth: 375,
};

const schema = yupObject().shape(
  {
    cusip: yupString().when('isin', {
      is: (value: string) => !value,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    isin: yupString().when('cusip', {
      is: (value: string) => !value,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  },
  [['isin', 'cusip']],
);

type TermsAcceptedFormSchema = InferType<typeof schema>;

export type TermsAcceptedModalProps = {
  icon?: IconographyProps['iconName'];
};

export const TermsAcceptedModal = ({ icon = 'info-circle' }: TermsAcceptedModalProps): ReactElement => {
  const dispatch = useDispatch();

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | undefined>();

  const orderStatus = useSelector(OrdersSelectors.selectStatus);
  const selectedAuction = useSelector(OrdersSelectors.selectSelectedAuction);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isValid },
  } = useForm<TermsAcceptedFormSchema>({
    resolver: yupResolver<TermsAcceptedFormSchema>(schema),
    mode: 'onChange',
    defaultValues: {
      cusip: selectedAuction?.cusip ?? '',
      isin: selectedAuction?.isin ?? '',
    },
  });

  const modalOpen = orderStatus === OrderStatusEnum.showTermsAcceptedModal;
  const disableAction = !isValid || !selectedFile;

  const handleFileSelection = () => void fileInputRef.current?.click();
  const handleModalClose = () => {
    reset();
    setSelectedFile(undefined);
    dispatch(OrdersActions.selectAuction({ auction: null }));
  };
  const handleModalAction = handleSubmit((data: TermsAcceptedFormSchema) => {
    dispatch(
      OrdersActions.uploadFinalPrelimTerms({
        auction: selectedAuction,
        termSheet: selectedFile as File,
        ...data,
      }),
    );
    handleModalClose();
  });

  const { ref: isinInputRef, ...isinInputProps } = register('isin');
  const { ref: cusipInputRef, ...cusipInputProps } = register('cusip');

  useEffect(() => {
    if (selectedAuction) {
      setValue('cusip', selectedAuction.cusip ?? '');
      setValue('isin', selectedAuction.isin ?? '');
    }
  }, [selectedAuction?.id]);

  return (
    <AuctionTableModal
      onClose={handleModalClose}
      open={modalOpen}
      title="Upload Final Terms"
      auctionId={selectedAuction?.id}
      buyer={selectedAuction?.buyer}
      organizationName={selectedAuction?.organizationName}
      footer={
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleModalAction}
          disabled={disableAction}
          startIcon={<Iconography iconName={icon} color="primary.main" />}
        >
          Upload Final Terms
        </Button>
      }
    >
      <Box component="form" sx={formSx}>
        <Stack sx={fieldsSx} direction="column" spacing={3}>
          <TextField fullWidth label="Preliminary Terms" disabled value="SomeTerms.png" />
          <Alert sx={alertSx} variant="filled" severity="success">
            Accepted
          </Alert>
          <TextField inputRef={cusipInputRef} {...cusipInputProps} fullWidth label="Product CUSIP" />
          <TextField inputRef={isinInputRef} {...isinInputProps} fullWidth label="Product ISIN" />
          <TextField
            label="Final Terms"
            disabled
            fullWidth
            placeholder="No file chosen"
            value={selectedFile?.name}
            slotProps={{
              inputLabel: {
                shrink: Boolean(selectedFile),
              },
              input: {
                endAdornment: (
                  <Button variant="contained" color="primary" onClick={handleFileSelection}>
                    Upload
                    <input
                      ref={fileInputRef}
                      type="file"
                      hidden
                      onChange={(ev) => {
                        const { files } = ev.target;

                        setSelectedFile(files?.[0]);
                      }}
                    />
                  </Button>
                ),
              },
            }}
          />
        </Stack>
      </Box>
    </AuctionTableModal>
  );
};
