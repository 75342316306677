import { AssetIdentifierEnum } from '@halo-common/enums';
import { useT } from '@transifex/react';

export const useAssetIdListText = (list?: Array<AssetIdentifierEnum>, multiple?: boolean): string => {
  const translator = useT();

  const multipleIndicator = multiple ? 's' : '';
  const orTranslated = translator('or');

  if (!list) return `CUSIP ID${multipleIndicator} ${orTranslated} ISIN ID${multipleIndicator}`;
  const mappedList = list.map((assetId) => {
    if (assetId === AssetIdentifierEnum.FUNDSERVCODE) return `FundServ Code${multipleIndicator}`;
    else return `${assetId.toUpperCase()} ID${multipleIndicator}`;
  });

  if (mappedList.length === 1) {
    return mappedList[0];
  } else if (mappedList.length === 2) {
    return mappedList.join(` ${orTranslated} `);
  } else {
    const endString = mappedList[mappedList.length - 1];
    const beginningSlice = mappedList.slice(0, -1);
    const beginning = beginningSlice.join(', ');
    return `${beginning}, ${orTranslated} ${endString}`;
  }
};
