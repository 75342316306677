import { ReactElement } from 'react';

import { dynamicAssetSearchOptionAtom, dynamicFiltersAssetSearchAtom } from '@halo-atoms/common';
import { AssetIdentifierEnum } from '@halo-common/enums';
import { usePrimaryAssetIdentifier } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { useCalendarAssetSearchInfiniteQuery } from '@halo-data-sources/queries';
import { Iconography, LocalizedTextField } from '@halodomination/halo-fe-common';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  CircularProgress,
  autocompleteClasses,
} from '@mui/material';
import { useT } from '@transifex/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

const autocompleteSx = {
  width: { xs: '100%', md: 'fit-content' },
  minWidth: 350,
  [`& .${autocompleteClasses.popupIndicator}`]: {
    transform: 'none',
  },
};

const loadingIndicatorContainerSx = {
  p: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export type SearchAssetModel = {
  type: AssetIdentifierEnum;
  value: string;
};

export type DynamicSearchAssetTypeAheadOption = {
  assetIds: Array<SearchAssetModel>;
  noteId: number;
};

export type DynamicSearchAssetTypeAheadProps = {
  tag: string;
  approvedAssetsOnly?: boolean | null;
  nmeEnabled?: boolean | null;
};

export const DynamicSearchAssetTypeAhead = ({
  tag,
  approvedAssetsOnly = true,
  nmeEnabled = false,
}: DynamicSearchAssetTypeAheadProps): ReactElement => {
  const translator = useT();

  const [getSearchOption, setSearchOption] = useAtom(dynamicAssetSearchOptionAtom);
  const selectedSearchOption = getSearchOption(tag);

  const assetSearchAtomMap = useAtomValue(dynamicFiltersAssetSearchAtom);
  const assetSearchAtoms = assetSearchAtomMap(tag);

  const search = useAtomValue(assetSearchAtoms.currentValueAtom);
  const setDebouncedSearch = useSetAtom(assetSearchAtoms.debouncedValueAtom);

  const { identifierType, identifiers } = usePrimaryAssetIdentifier();

  const noOptionsText = search.length
    ? translations.dynamicFilters.common.noMatchingAssets
    : translations.dynamicFilters.common.assetSearchLabel;
  const translatedNoOptionsText = translator(noOptionsText, { assetId: identifierType.toUpperCase() });
  const dynamicContent = { label: { assetId: identifierType.toUpperCase() } };

  const {
    data = { pages: [] },
    isPending,
    isFetching,
  } = useCalendarAssetSearchInfiniteQuery({
    approved: approvedAssetsOnly,
    assetIdentifiers: identifiers,
    assetId: search,
    showAsNME: nmeEnabled,
  });

  const flatMappedData = data.pages
    .flatMap(({ calendars }) => calendars.map((calendar) => calendar))
    .filter(({ assetIds }) => assetIds.filter(({ type }) => identifiers.includes(type.toLowerCase())));

  const showLoading = isPending && isFetching;

  const handleSearch = (_: unknown, value: string, reason: AutocompleteInputChangeReason) => {
    const isInputChange = reason === 'input';
    const updatedValue = isInputChange ? value : '';

    if (isInputChange) setDebouncedSearch(updatedValue.toUpperCase());
  };

  const handleSelect = (
    _: unknown,
    option: DynamicSearchAssetTypeAheadOption | null,
    reason: AutocompleteChangeReason,
  ) => {
    const isClearTextFieldAction = reason === 'clear' || option === null;
    const searchText = !isClearTextFieldAction ? option.assetIds[0].value.toUpperCase() : '';
    const searchOption = !isClearTextFieldAction ? option : null;

    setDebouncedSearch(searchText);
    setSearchOption({ tag, value: searchOption });
  };

  const handleOptionLabel = (asset: DynamicSearchAssetTypeAheadOption) => {
    const { assetIds } = asset;

    const primaryIdentifierAsset = assetIds.find((assetId) => assetId.type === identifierType);

    const optionLabel = primaryIdentifierAsset ?? assetIds[0];

    return optionLabel?.value.toUpperCase();
  };

  const handleValueComparison = (option: DynamicSearchAssetTypeAheadOption, val: DynamicSearchAssetTypeAheadOption) => {
    return option.noteId === val.noteId;
  };

  const textFieldInputLoadingIndicator = showLoading ? (
    <Box sx={loadingIndicatorContainerSx}>
      <CircularProgress size={20} />
    </Box>
  ) : null;

  return (
    <Autocomplete<DynamicSearchAssetTypeAheadOption, undefined, boolean>
      options={flatMappedData}
      sx={autocompleteSx}
      clearOnBlur
      fullWidth
      onChange={handleSelect}
      value={selectedSearchOption}
      onInputChange={handleSearch}
      loading={showLoading}
      inputValue={search}
      isOptionEqualToValue={handleValueComparison}
      getOptionLabel={handleOptionLabel}
      noOptionsText={translatedNoOptionsText}
      popupIcon={<Iconography iconName="magnifying-glass" />}
      loadingText={textFieldInputLoadingIndicator}
      renderInput={(props) => (
        <LocalizedTextField
          {...props}
          fullWidth
          size="medium"
          label={translations.dynamicFilters.common.assetSearchLabel}
          dynamicContent={dynamicContent}
          variant="outlined"
        />
      )}
    />
  );
};
