import { modalAtom } from '@halo-atoms/common';
import { debouncedAtom } from '@halo-atoms/debounce';
import { portfolioFiltersAtom } from '@halo-atoms/portfolio';
import { LifecycleEventTypeEnum, PortfolioEventSortDropdownValueEnum, PortfolioPeriodEnum } from '@halo-common/enums';
import { NoteModel } from '@halo-common/models';
import {
  GetPortfolioLifecycleEventsQueryFilterModel,
  LIFECYCLE_EVENTS_QUERY_PAGE_LENGTH,
} from '@halo-data-sources/queries';
import { atom } from 'jotai';
import { DateTime } from 'luxon';

export type LifecycleReinvestmentMap = {
  [noteId: number]: number;
};

export type LifecycleFilterModel = {
  startDate: string;
  pageLength: number;
  sortField: PortfolioEventSortDropdownValueEnum;
  eventTypes: string;
  isPreviousThirtyDays: boolean;
  endDate: string;
  page?: number;
  productId?: number;
  productType?: string;
};

const DEFAULT_LIFECYCLE_INFO = { id: null, note: null };

export const DEFAULT_LIFECYCLE_FILTERS = {
  startDate: DateTime.now().toFormat('yyyy-MM-dd'),
  endDate: DateTime.now().plus({ days: 90 }).toFormat('yyyy-LL-dd'),
  pageLength: LIFECYCLE_EVENTS_QUERY_PAGE_LENGTH,
  sortField: PortfolioEventSortDropdownValueEnum.ASC_EVENT_DATE,
  eventTypes: LifecycleEventTypeEnum.EMPTY,
  isPreviousThirtyDays: false,
};

export type LifecycleQueryParameters = LifecycleFilterModel & {
  accountId?: number;
  householdId?: number;
  synced: boolean | null;
  search: string;
};

export type LifecyclePreTradeModalInfoAtomModel = {
  id: number | null;
  note: NoteModel | null;
};

export type LifecyclePreTradeModalInfoAtomModelPayload = {
  event?: LifecyclePreTradeModalInfoAtomModel;
  open?: boolean;
};

export type PortfolioLifecycleManagerPayload = {
  filters?: Partial<LifecycleFilterModel>;
  period?: PortfolioPeriodEnum;
};

const _lifecycleChartPeriodAtom = atom<PortfolioPeriodEnum>(PortfolioPeriodEnum.Monthly);
const _lifecycleFilterAtom = atom<LifecycleFilterModel>(DEFAULT_LIFECYCLE_FILTERS);

const { debouncedValueAtom, currentValueAtom } = debouncedAtom('');
export const currentLifecycleIdSearchAtom = currentValueAtom;
export const debouncedLifecycleIdSearchAtom = debouncedValueAtom;

const _lifecyclePretradeInfo = atom<LifecyclePreTradeModalInfoAtomModel>(DEFAULT_LIFECYCLE_INFO);
const LIFECYCLE_PRETRADE_MODAL = 'lifecyclePretradeModal';
export const lifecyclePretradeModalAtom = atom(
  (get) => {
    const event = get(_lifecyclePretradeInfo);
    const modalMap = get(modalAtom);

    return { event, open: modalMap[LIFECYCLE_PRETRADE_MODAL] };
  },
  (_, set, payload?: LifecyclePreTradeModalInfoAtomModelPayload) => {
    if (!payload) {
      set(_lifecyclePretradeInfo, DEFAULT_LIFECYCLE_INFO);
      set(modalAtom, { [LIFECYCLE_PRETRADE_MODAL]: false });
    }

    if (payload?.event !== undefined) set(_lifecyclePretradeInfo, payload.event);
    if (payload?.open !== undefined) set(modalAtom, { [LIFECYCLE_PRETRADE_MODAL]: payload.open });
  },
);

export const portfolioLifecycleManagerAtom = atom(
  (get) => {
    const filters = get(_lifecycleFilterAtom);
    const search = get(currentLifecycleIdSearchAtom);
    const chartPeriod = get(_lifecycleChartPeriodAtom);
    const portfolioFilters = get(portfolioFiltersAtom);

    const accountId = portfolioFilters.accountOption?.account?.id;
    const householdId = portfolioFilters.accountOption?.household?.id;
    const synced = portfolioFilters.synced;

    const query: GetPortfolioLifecycleEventsQueryFilterModel = {
      ...filters,
      accountId,
      householdId,
      synced,
      search,
    };

    return { period: chartPeriod, filters, query };
  },
  (_, set, payload?: PortfolioLifecycleManagerPayload) => {
    if (!payload) {
      set(_lifecycleFilterAtom, DEFAULT_LIFECYCLE_FILTERS);
    } else if (payload.filters) {
      set(_lifecycleFilterAtom, (prev) => ({ ...prev, ...payload.filters }));
    } else if (payload.period) {
      set(_lifecycleChartPeriodAtom, payload.period);
    }
  },
);
