import { ReactElement } from 'react';

import { EmptyResultsCard, InfiniteScroller, PortfolioEventsLoadingSkeleton } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { NotificationModel } from '@halo-data-sources/models';
import { useNotificationsInfiniteListQuery } from '@halo-data-sources/queries/profile';
import { Iconography } from '@halodomination/halo-fe-common';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';

import { UserProfileNotificationsListItem } from '../UserProfileNotificationsListItem';

const accordionSx = {
  boxShadow: 'none',
  border: 'none',
  borderRadius: 0,
  backgroundColor: 'transparent',
  marginBottom: '16px',
  '&::before': {
    display: 'none',
  },
};

const summarySx = { flexDirection: 'row-reverse' };

const groupByDate = (notifications: NotificationModel[]) => {
  return notifications.reduce((groups: { [key: string]: NotificationModel[] }, notification: NotificationModel) => {
    const date = notification.createdAt;

    if (!groups[date]) {
      groups[date] = [];
    }

    groups[date].push(notification);

    return groups;
  }, {});
};

export const UserProfileNotificationsList = (): ReactElement => {
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useNotificationsInfiniteListQuery();

  if (isLoading) return <PortfolioEventsLoadingSkeleton />;

  const allNotifications = data?.pages.flatMap((page) => page.notifications) || [];

  const groupedNotifications = groupByDate(allNotifications);

  if (!Array.isArray(allNotifications) || allNotifications.length === 0) {
    return (
      <EmptyResultsCard
        title={translations.profile.notifications.emptyListTitle}
        description={translations.profile.notifications.emptyListMessage}
      />
    );
  }

  return (
    <Stack direction="column">
      <InfiniteScroller loading={isFetchingNextPage} hasMore={Boolean(hasNextPage)} next={fetchNextPage}>
        {Object.entries(groupedNotifications).map(([date, notifications]) => (
          <Accordion key={date} disableGutters defaultExpanded sx={accordionSx}>
            <AccordionSummary sx={summarySx} aria-controls={`${date}-content`} id={`${date}-header`}>
              <Stack direction="row" spacing={1} alignItems="center" sx={{ width: '100%', overflow: 'hidden' }}>
                <Iconography iconName="chevron-down" />
                <Typography variant="h6">{DateTime.fromISO(date).toFormat('MM/dd/yyyy')}</Typography>
                <Divider sx={{ width: '100%' }} />
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              {notifications.map((notification: NotificationModel) => (
                <UserProfileNotificationsListItem key={notification.id} data={notification} />
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </InfiniteScroller>
    </Stack>
  );
};
