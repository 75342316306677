import { ReactElement } from 'react';

import { ProductDetailModalAlert } from '@halo-common/layouts';
import { NoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useNotePayoffQuery } from '@halo-data-sources/queries';
import { LineChart, LocalizedAlert } from '@halodomination/halo-fe-common';
import { Skeleton, Stack } from '@mui/material';

export type PreTradeNoteDetailModalNotePayoffChartProps = {
  product?: NoteModel | null;
  onRefresh?: () => void;
  loading?: boolean;
};

export const PreTradeNoteDetailModalNotePayoffChart = ({
  product,
  onRefresh,
  loading,
}: PreTradeNoteDetailModalNotePayoffChartProps): ReactElement => {
  const { data, isPending: chartLoading, isSuccess, isError } = useNotePayoffQuery(product);

  const isLoading = loading || chartLoading;
  const isFinished = isSuccess || isError;

  if (isFinished && !data) {
    return (
      <LocalizedAlert severity="warning" variant="filled">
        {translations.messages.marketDataUnavailable}
      </LocalizedAlert>
    );
  }

  const chartData = data?.data ?? [];
  const lines = data?.lines ?? [];
  const references = data?.references;

  const ticks = [-100, -50, 0, 50, 100];
  const axisProps = { ticks };

  const yLabelFormatter = (value: string) => {
    return `${value}%`;
  };

  const xLabelFormatter = (value: string | number) => {
    const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
    return `${parsedValue.toFixed(2)}%`;
  };

  if (isLoading) return <Skeleton variant="rounded" height={420} width="100%" />;

  return (
    <Stack direction="column">
      <ProductDetailModalAlert product={product} onRefresh={onRefresh} />
      <LineChart
        height={420}
        data={chartData}
        lines={lines}
        referenceLines={references}
        TooltipProps={{ isTitleVisible: false }}
        xLabel={{ label: translations.pdm.preTrade.payoffChartX, formatter: xLabelFormatter }}
        yLabel={{ label: translations.pdm.preTrade.payoffChartY, formatter: yLabelFormatter }}
        XAxisProps={axisProps}
        showZeroXLineHighlight
      />
    </Stack>
  );
};
