export * from './calendars';
export * from './fixedIncome';

export const ALLOCATIONS_MODAL_COMMON_TRANSLATIONS = {
  accountTypeAheadCreateAccountOption: 'Create New Client Account',
  buttonAddAllocation: 'Add Allocation',
  qtyThousand: 'Qty (1000)',
  quantityInThousands: 'Quantity (in Thousands)',
  allocationRequiredError: 'Add an allocation',
  accountRequiredError: 'Select an account',
  qtyTypeError: 'Please enter a number greater than 0',
  qtyIntegerError: 'Quantity must be a whole number',
  qtyRequiredError: 'Enter quantity greater than 0',
  disclaimerRequiredError: 'You must accept the acknowledgement to proceed.',
};
