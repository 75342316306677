import { ReactElement } from 'react';

import { calendarManagerAtom } from '@halo-atoms/calendars';
import { CALENDAR_PREFERENCE_TAG } from '@halo-common/constants';
import { DynamicFiltersEmptyResult, DynamicFiltersEmptyResultProps } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { useAtomValue } from 'jotai';

import { EmailSubscription } from './EmailSubscription';

export type CalendarEmptyResultProps = Partial<DynamicFiltersEmptyResultProps>;

export const CalendarEmptyResult = (props: CalendarEmptyResultProps): ReactElement => {
  const { isFiltered } = useAtomValue(calendarManagerAtom);

  const { data: user } = useUserInfoQuery();

  const showEmailSubscription = user?.settings?.showCalendarEmailSubscription;
  const emailSubscriptionText = translations.calendars.common.emailSubscriptionText;
  const newOfferingsText = translations.calendars.common.newOfferingsText;
  const finalizingOfferingsDescription = `${newOfferingsText}${showEmailSubscription ? emailSubscriptionText : ''}`;

  const emailAction = showEmailSubscription ? <EmailSubscription /> : null;

  return (
    <DynamicFiltersEmptyResult
      tag={CALENDAR_PREFERENCE_TAG}
      isFiltered={isFiltered}
      finalOfferingsDescription={finalizingOfferingsDescription}
      slots={{
        action: emailAction,
      }}
      {...props}
    />
  );
};
